import './app.scss';

import { useAuth0 } from '@auth0/auth0-react';
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import * as Sentry from '@sentry/react';
import { useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react';
import axios from 'axios';
import { DrawersContainer } from 'components/drawers-container';
import { SuccessConfirmationComponent } from 'components/success-confirmation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { setLogger, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  DrawerInfoHashes,
  DrawerInfoType,
  featureFlags,
  ModalType,
  RESPONSE_CODE,
  TemplateIds,
  TemplateIdsType,
} from 'shared/common.definitions';
import { isLoginMethodErrorMessage } from 'shared/helpers/common.helper';
import { isASubscription } from 'shared/logic/subscription-item.logic';
import { CreateScript } from 'shared/models/google-directory.model';
import { initializeGoogleApi, initializeGoogleIdentityServices } from 'shared/store/common/googleDirectory.slice';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { AppUrl } from 'src/constants/appurl';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { useCompanyFeatureFlag } from '../company-feature-flag.provider';
import { fetchCompany, fetchCompanyToolOwners } from '../shared/logic/company.logic';
import { fetchAllSubscriptionsAndNewSolutions } from '../shared/logic/subscriptions.logic';
import { fetchUsers } from '../shared/logic/users.logic';
import { BaseProps, Company, DispatchProps, RoutedProps, SastrifyStore, Subscription } from '../shared/models';
import { User } from '../shared/models/user.model';
import { apiService, CelloReferral } from '../shared/services';
import { SastrifyStore as store } from '../shared/store';
import { hide as hideDrawer, show as showDrawer } from '../shared/store/common/appDrawer.slice';
import { authenticate, forcedLogout, logout } from '../shared/store/user/actions';
import { AppState } from './app.state';
import {
  AsteroidsModalComponent,
  AsteroidsModalComponentProps,
  BreadcrumbsComponent,
  LoadingComponent,
  ModalComponent,
  ModalComponentProps,
  NavigationComponent,
  NotificationAlert,
  NotificationAlertComponent,
} from './components';
import { DrawerComponent } from './components/drawer';
import { NotificationAlertType } from './components/notification-alert/notification-alert.component';
import { ModalAndNotificationsContext, type MutationErrorType } from './context/modal-notification.context';
import { useExternalScript, useFeatureFlagByCompanyId, usePricingPlans, useScrollbarWidth } from './hooks';
import { useDisplayAuthErrorView } from './hooks/useDisplayAuthErrorView';
import { useEventTracking } from './hooks/useEventTracking';
import { useFetchData } from './hooks/useFetchData';
import { SastrifyRoute } from './routes/app.routes';
import { AppContainer } from './views/app-container/app-container.view';
import { AuthError } from './views/errors/auth-error/auth-error.view';

window.dataLayer = window.dataLayer || [];

const ASTEROIDS_MODAL_PROPS_INITIAL_STATE = { children: null, message: '', open: false };
const MODAL_PROPS_INITIAL_STATE = { children: null, message: '' };

const defaultAlert: NotificationAlert = {
  id: '',
  interval: window.Cypress ? 60000 : 5000,
  position: 'is-top',
  side: 'is-right',
  text: '',
  type: NotificationAlertType.Default,
};

const SastrifyAppFC: React.FC<RoutedProps> = (props: RoutedProps) => {
  const dispatch = useAppDispatch();
  const { history, user } = props;
  const [failureCount, setFailureCount] = useState<number>(0);
  const notificationId = useRef(0);
  const [notifications, setNotifications] = useState<NotificationAlert[]>([]);
  const isMounted = useRef<boolean>(false);
  const isUserAndCompanyReady = useRef<boolean>(false);
  const auth0 = useAuth0();
  const { authenticate } = props;
  const queryClient = useQueryClient();
  const company: Company | undefined = queryClient.getQueryData('company');
  const updateContext = useUnleashContext();
  const { flagsReady } = useFlagsStatus();
  const { location } = history;
  const { t } = useTranslation();
  const { pricingPlanName } = usePricingPlans();

  const appDrawerType = useAppSelector((state) => state.common.appDrawer.type);
  const isDrawerOpen = useAppSelector((state) => state.common.appDrawer.showDrawer);

  useExternalScript(process.env.CELLO_REFERRAL_COMPONENT_URL || '');
  useScrollbarWidth();

  // Feature flags by company
  const { isLoading: isLoadingCompanyFeatureFlags } = useFeatureFlagByCompanyId(
    featureFlags.COMPANY_TOOLS_PAGE_V2,
    company?.id
  );

  const isChatbotEnabled = useCompanyFeatureFlag(featureFlags.CHATBOT);

  // Google Tag Manager
  useEffect(() => {
    if (!company?.id || !user?.id) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle
    (window as any)._User = {
      companyId: company?.id,
      companyName: company?.name,
      email: user?.email,
      id: user?.id,
      name: user?.name,
    };

    const tagManagerArgs = {
      dataLayer: {
        companyId: company.id,
        userId: user.id,
      },
      gtmId: 'GTM-K9WQTP5',
    };

    TagManager.initialize(tagManagerArgs);
  }, [company, user]);

  const createScript: CreateScript = ({ onload, src }) => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = true;
    script.onload = onload;
    document.body.appendChild(script);
  };

  // Google API
  useEffect(() => {
    if (company?.id && user?.id) {
      createScript({ onload: () => initializeGoogleApi(dispatch), src: 'https://apis.google.com/js/api.js' });
      createScript({
        onload: async () => {
          await initializeGoogleIdentityServices(dispatch, company.id as string, user.id as string);
        },
        src: 'https://accounts.google.com/gsi/client',
      });
    }
  }, [company?.id, user?.id, dispatch]);

  const isChatbotInitialized = useRef<boolean>(false);

  useEffect(() => {
    if (isChatbotEnabled && !isChatbotInitialized.current) {
      // A word of comment: this is PoC code, thus I'm fine with using eslint disable.
      // Before using production release, this will be refactored.
      // eslint-disable-next-line no-underscore-dangle
      (window as any).__be = (window as any).__be || {};
      (window as any).BE_API = (window as any).BE_API || {};
      // eslint-disable-next-line no-underscore-dangle
      (window as any).__be.id = '6601911a5d69e70007ea59e2';

      (window as any).BE_API.onLoad = () => {
        (window as any).BE_API.setUserAttributes({
          companyId: company?.id,
          companyName: company?.name,
          email: user?.email,
          name: user?.name,
          role: user?.role,
          userId: user?.id,
        });
      };
      createScript({
        onload: () => {
          isChatbotInitialized.current = true;
        },
        src: 'https://cdn.chatbot.com/widget/plugin.js',
      });
    }
  }, [isChatbotEnabled]);

  const isInitialized = useRef<boolean>(false);

  const { startTracking } = useEventTracking();

  useEffect(() => {
    if (user?.id && company?.id && !isInitialized.current) {
      const isSastrifyEmailAddress = (user?.email || '').indexOf('@sastri') !== -1;

      startTracking(user.id, {
        companyId: company.id,
        companyName: company.name as string,
        email: user.email as string,
        isSastrifyEmailAddress,
        name: user.name as string,
      });
      isInitialized.current = true;
    }
  }, [user?.id, user?.email, user?.name, company?.name, company?.id, startTracking]);

  useEffect(() => {
    if (user?.id) {
      // context is updated with userId
      updateContext({ userId: user?.id });
    }
  }, [user?.id, updateContext]);

  useEffect(() => {
    if (!isUserAndCompanyReady.current && company && user) {
      Sentry.setContext('company', {
        id: company.id,
        name: company.name,
      });

      Sentry.setUser({ id: user.id });

      isUserAndCompanyReady.current = true;
    }

    return () => {
      Sentry.configureScope((scope) => scope.setUser(null));
    };
  }, [company, user]);

  const { isLoading: isLoadingUsers } = useFetchData<User[] | unknown>('users', fetchUsers, {
    enabled: (window.Cypress || auth0.isAuthenticated) && props.isAuthenticated && user?.role !== 'viewer',
    refetchOnWindowFocus: false,
  });

  // Sentry logger
  setLogger({
    error: (error) => {
      Sentry.captureException(error);
    },
    log: (message) => {
      Sentry.captureMessage(message);
    },
    warn: (message) => {
      Sentry.captureMessage(message);
    },
  });

  const { isLoading: isLoadingCompany, refetch } = useFetchData<Company[] | unknown>(
    'company',
    () => {
      const { authentication } = store.getState();
      const { user } = authentication;
      return fetchCompany(Number(user?.companyId));
    },
    {
      enabled: !!user?.companyId,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isLoadingToolOwners } = useFetchData<User[]>(
    'tool-owners',
    () => fetchCompanyToolOwners(String(company?.id)),
    {
      enabled: Boolean(company?.id),
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isLoadingSubscriptions } = useFetchData<Subscription[]>(
    'subscriptions-and-new-solutions',
    fetchAllSubscriptionsAndNewSolutions,
    {
      enabled: Boolean(user),
      refetchOnWindowFocus: false,
    }
  );

  const initialState: AppState = {
    asteroidsModalProps: {
      ...ASTEROIDS_MODAL_PROPS_INITIAL_STATE,
      // onCancelButtonClick: onCloseModal,
    },
    isAsteroidsModalActive: false,
    isModalActive: false,
    modalProps: {
      ...MODAL_PROPS_INITIAL_STATE,
      // onCancelButtonClick: onCloseModal,
    },
  };

  const [state, setState] = useState<AppState>(initialState);

  const onCloseModal = useCallback((): void => {
    setState((state) => ({
      ...state,
      asteroidsModalProps: { ...ASTEROIDS_MODAL_PROPS_INITIAL_STATE },
      isAsteroidsModalActive: false,
      isModalActive: false,
      modalProps: { ...MODAL_PROPS_INITIAL_STATE },
    }));
  }, []);

  const onConfirmModal = useCallback((): void => {
    setState((state) => ({
      ...state,
      isModalActive: false,
      modalProps: { onCancelButtonClick: onCloseModal, ...MODAL_PROPS_INITIAL_STATE },
    }));
  }, [onCloseModal]);

  const showModal = useCallback(
    (modalProps: ModalComponentProps): void => {
      if (!modalProps.onConfirmButtonClick) {
        modalProps.onConfirmButtonClick = onConfirmModal;
      }
      if (!modalProps.onCancelButtonClick) {
        modalProps.onCancelButtonClick = onCloseModal;
      }

      setState((state) => ({ ...state, isModalActive: true, modalProps }));
    },
    [onCloseModal, onConfirmModal]
  );

  const onShowAsteroidsModal = useCallback(
    (asteroidsModalProps: AsteroidsModalComponentProps): void => {
      if (!asteroidsModalProps.onConfirmButtonClick) {
        asteroidsModalProps.onConfirmButtonClick = onConfirmModal;
      }
      if (!asteroidsModalProps.onCancelButtonClick) {
        asteroidsModalProps.onCancelButtonClick = onCloseModal;
      }

      setState((state) => ({ ...state, asteroidsModalProps, isAsteroidsModalActive: true }));
    },
    [onCloseModal, onConfirmModal]
  );

  const showNotification = useCallback(
    (message: React.ReactNode, type: NotificationAlertType = NotificationAlertType.Default): void => {
      setNotifications((notifications) => {
        notificationId.current += 1;

        return [
          ...notifications,
          {
            ...defaultAlert,
            id: `${notificationId.current}`,
            text: message,
            type,
          },
        ];
      });
    },
    []
  );

  const processMutationError = useCallback(
    (error: MutationErrorType | { message: string }) => {
      if ('message' in error) {
        return showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      }

      if ('response' in error) {
        const {
          response: {
            data: { data, error: errorString },
          },
        } = error;

        if (data.length > 0) {
          const errorMessage = `${errorString}: ${data.join(', ')}`;
          showNotification?.(errorMessage, NotificationAlertType.Error);
        }
      }
    },
    [showNotification]
  );

  const auth0Cypress = useMemo(() => {
    return localStorage.getItem('auth0Cypress');
  }, []);

  const fetchData = (): void => {
    // in order to login programmatically we want to bypass the existing Auth0 mechanism using localstorage variables
    // that would make the tests more reliable but in order to do that it needs to refetch the data with relatively the
    // same behavior as the regular app that is why we added the existing if statements
    if (auth0.isAuthenticated && props.isAuthenticated && user?.companyId && !company) {
      refetch();
    }
    if (window.Cypress && auth0Cypress) {
      refetch();
    }
  };

  const registerAxiosInterceptors = useCallback((): void => {
    apiService.interceptors().response.use(
      (response) => response,
      (error) => {
        if (
          error.response?.status === RESPONSE_CODE.Unauthorized.code &&
          !isLoginMethodErrorMessage(String(error.response.data?.error))
        ) {
          return auth0.logout({
            returnTo: window.location.origin,
          });
        }
        fetchData();
        setFailureCount(failureCount + 1);

        return Promise.reject(error);
      }
    );

    apiService.interceptors().request.use((request) => {
      if (failureCount >= 2) {
        const errorExceededRetryAmount = 'Exceeded retry amount';

        showNotification(errorExceededRetryAmount);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new axios.Cancel(errorExceededRetryAmount);
      }

      return request;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0, failureCount, showNotification]);

  const onStoreChanged = useCallback((): void => {
    const { authentication } = store.getState();
    // in order to login programmatically we want to bypass the existing Auth0 mechanism using localstorage variables
    // that would make the tests more reliable but in order to do that it needs to refetch the data with relatively the
    // same behavior as the regular app that is why we added the existing if statements
    if (auth0.isAuthenticated && !authentication.isLoading && !isUserAndCompanyReady) {
      refetch();
    }
    if (window.Cypress && auth0Cypress && !isUserAndCompanyReady) {
      refetch();
    }
  }, [auth0.isAuthenticated, isUserAndCompanyReady, refetch, auth0Cypress]);

  useEffect(() => {
    if (!company) {
      registerAxiosInterceptors();
      store.subscribe(onStoreChanged);
    }
  }, [company, onStoreChanged, registerAxiosInterceptors]);

  useEffect(() => {
    if (user?.celloToken) {
      CelloReferral.init(user.celloToken);
    }
  }, [user?.celloToken]);

  useEffect(() => {
    // in order to login programmatically we want to bypass the existing Auth0 mechanism using localstorage variables
    // the window.UILogin allows us to create the ui login test cases
    if (!window.Cypress || window.UILogin) {
      if (
        auth0.isAuthenticated &&
        !auth0.isLoading &&
        !props.isAuthLoading &&
        !props.isAuthenticated &&
        !props.isAuthenticatedError
      ) {
        auth0.getAccessTokenSilently().then((token: string) => {
          authenticate?.(token, undefined);
        });
      }

      if (!auth0.isAuthenticated && !auth0.isLoading) {
        const connectionParameter = window.location.search.match(/connection=([^&]*)/);

        auth0.loginWithRedirect({
          appState: {
            returnTo: window.location.pathname + window.location.search + window.location.hash,
          },
          connection: connectionParameter ? connectionParameter[1] : undefined,
        });
      }
    }
  }, [auth0, authenticate, props.isAuthLoading, props.isAuthenticated, props.isAuthenticatedError]);

  useEffect(() => {
    // in order to login programmatically we want to bypass the existing Auth0 mechanism using localstorage variables
    // the window.UILogin allows us to create the ui login test cases which are not needed here
    if (window.Cypress && !window.UILogin) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const auth0JSON = JSON.parse(auth0Cypress!) as { body: { access_token: string } } | null;
      if (auth0JSON) authenticate?.(auth0JSON.body.access_token, undefined);
    }
  }, [authenticate, auth0Cypress]);

  const logout = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event?.preventDefault();
      // in order to login programmatically we want to bypass the existing Auth0 mechanism using localstorage variables
      // on logout we want to remove those localstorage values
      if (window.Cypress && !window.UILogin) {
        localStorage.removeItem('auth0Cypress');
      }
      auth0.logout({
        returnTo: window.location.origin,
      });
    },
    [auth0]
  );

  const onAlertClicked = useCallback((notification: NotificationAlert): void => {
    removeNotification(notification);
  }, []);

  const onAlertTimeElapsed = useCallback((notification: NotificationAlert): void => {
    removeNotification(notification);
  }, []);

  const removeNotification = (notification: NotificationAlert): void => {
    setNotifications((notifications) =>
      notifications?.filter((item: NotificationAlert) => notification.id !== item.id)
    );
  };

  const renderNotificationAlerts = (): React.ReactNode => {
    return (
      <div key='alert-container' className={`sastrify-alert-container ${defaultAlert.position} ${defaultAlert.side}`}>
        {notifications?.map((item: NotificationAlert) => (
          <NotificationAlertComponent
            key={item.id}
            alert={item}
            onClick={onAlertClicked}
            onTimeElapsed={onAlertTimeElapsed}
          />
        ))}
      </div>
    );
  };

  // Drawer toggle callback
  const toggleGlobalRequestDrawer = useCallback(
    (appDrawerInfoType?: DrawerInfoType) => {
      isMounted.current = true;

      setState({
        ...state,
        modalProps: { ...MODAL_PROPS_INITIAL_STATE },
      });

      const shouldHideDrawer =
        (![DrawerInfoType.PROCUREMENT_HELP, DrawerInfoType.SASTRIFY_APP_HELP].includes(
          appDrawerInfoType as DrawerInfoType
        ) &&
          appDrawerType) ||
        !appDrawerInfoType;

      if (shouldHideDrawer) {
        dispatch(hideDrawer());
      } else {
        dispatch(showDrawer(appDrawerInfoType));
        setState({
          ...state,
          modalProps: { ...MODAL_PROPS_INITIAL_STATE },
        });
      }
    },
    [appDrawerType, isMounted, dispatch, state]
  );

  // TODO: This seems like a workaround than the real solution and we already had additional problems with it
  // Invest some time to understand it, try to remove it and fix the original issue with drawers and hashes

  // Drawer hash control
  useEffect(() => {
    const currentHash = location.hash;

    if (!isDrawerOpen && currentHash) {
      // No drawer and has hash
      if (isMounted.current) {
        // Hash and App is mounted => Removes hash
        let newHash = currentHash;

        Object.values(DrawerInfoHashes)
          .filter((v) => !!v)
          .forEach((hash) => {
            if (
              currentHash.includes(hash) &&
              hash !== DrawerInfoHashes[DrawerInfoType.WORKFLOW_REQUEST] &&
              hash !== DrawerInfoHashes[DrawerInfoType.REQUEST_DETAILS] &&
              hash !== DrawerInfoHashes[DrawerInfoType.WORKFLOW_REQUEST_EDIT]
            ) {
              newHash = newHash.replace(hash, '');
            }
          });

        if (currentHash !== newHash) {
          history.replace(`${location.pathname}${newHash}`);
        }
      } else {
        // Hash and App is not mounted yet => Keeps current hash and shows the corresponding appDrawerType for the hash
        const nextAppDrawerType = Object.keys(DrawerInfoHashes).find((key: string) => {
          const hash = DrawerInfoHashes[key];
          return key && hash && currentHash === hash ? key : false;
        });

        if (nextAppDrawerType) {
          toggleGlobalRequestDrawer(nextAppDrawerType as DrawerInfoType);
        }
      }
    }
  }, [history, location, isDrawerOpen, isMounted, toggleGlobalRequestDrawer]);

  const openSuccessConfirmationModal = useCallback(
    (subscription: Subscription, modalType?: ModalType, templateId?: string) => {
      toggleGlobalRequestDrawer();
      let toolType = 'Subscription';
      const isSubscription = isASubscription(Number(subscription?.state));

      if (!isSubscription) {
        toolType = 'Tool requests';
      }

      const env = process.env.NODE_ENV;
      const isAppHelp = modalType === ModalType.AppHelp;

      const subscriptionDetailUrl = `${AppUrl.getToolUrl(subscription, !isSubscription, true)}#showHistory`;

      const hasSecondaryButton =
        !isAppHelp &&
        (!isSubscription ||
          (isSubscription &&
            templateId === TemplateIds[env?.toUpperCase() as unknown as keyof TemplateIdsType].FIND_ALTERNATIVE));

      const appHelpDescription = isAppHelp && t('common:modals.request_support_modal.app_help_description_message');

      const defaultDescription = hasSecondaryButton
        ? t('common:modals.request_support_modal.description_message_explore', {
            type: isSubscription ? 'subscription' : 'solution',
          })
        : t('common:modals.request_support_modal.description_message');

      const description = appHelpDescription || defaultDescription;
      const secondaryButtonAction = () => history.push('/tool-store');
      const buttonLabel = isAppHelp
        ? t('common:modals.request_support_modal.back_to_dashboard_button_label')
        : t('common:modals.create_subscription_modal.view_subscription_button_label', {
            toolType,
          });

      showModal?.({
        children: (
          <SuccessConfirmationComponent
            onButtonClick={() => {
              history.push(isAppHelp ? NAVIGATION_URLS.OVERVIEW : subscriptionDetailUrl);
            }}
            closeModal={onCloseModal}
            title={t('common:modals.request_support_modal.title')}
            description={description}
            buttonLabel={buttonLabel}
            hasSecondaryButton={hasSecondaryButton}
            secondaryButtonLabel={t('common:modals.request_support_modal.explore_button_label')}
            onSecondaryButtonClick={secondaryButtonAction}
          />
        ),
        hasCustomFooter: true,
        isSuccessModal: true,
        showHeader: false,
      });
    },
    [history, onCloseModal, showModal, t, toggleGlobalRequestDrawer]
  );

  const { authErrorProps } = useDisplayAuthErrorView();

  if (authErrorProps) {
    return (
      <AuthError
        title={authErrorProps.title}
        paragraph={authErrorProps.paragraph}
        onButtonClick={authErrorProps.onButtonClick}
      />
    );
  }

  if (!props.isAuthenticated || !user || !company) {
    return <LoadingComponent />;
  }

  const { isAsteroidsModalActive, isModalActive } = state;

  const isLoading =
    !flagsReady ||
    isLoadingCompanyFeatureFlags ||
    isLoadingUsers ||
    isLoadingCompany ||
    isLoadingToolOwners ||
    isLoadingSubscriptions;

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <>
      <ModalAndNotificationsContext.Provider
        value={{
          onCloseAsteroidsModal: onCloseModal,
          onCloseModal,
          onOpenSuccessConfirmationModal: openSuccessConfirmationModal,
          onShowAsteroidsModal,
          onShowModal: showModal,
          onShowNotification: showNotification,
          processMutationError,
        }}>
        <div key='sastrify-app' className='sastrify-app'>
          <NavigationComponent
            key='navigation'
            {...props}
            pricingPlanName={pricingPlanName}
            logout={logout as () => void}
          />

          <BreadcrumbsComponent key='breadcrumbs' />
          <AppContainer key='app-container'>
            <SastrifyRoute
              key='sastrify-route'
              {...props}
              showModal={showModal}
              closeModal={onCloseModal}
              showNotification={showNotification}
              toggleGlobalRequestDrawer={toggleGlobalRequestDrawer}
            />
          </AppContainer>
        </div>

        {renderNotificationAlerts()}

        <DrawerComponent
          key='drawer'
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleGlobalRequestDrawer}
          isSubscriptionInDiscovery={false}
          infoType={appDrawerType as DrawerInfoType}
          showNotification={showNotification}
          openSuccessConfirmationModal={openSuccessConfirmationModal}
        />

        {isModalActive && <ModalComponent key='modal-dialog' {...state.modalProps} closeModal={onCloseModal} />}
        <AsteroidsModalComponent
          key='asteroids-modal-dialog'
          {...state.asteroidsModalProps}
          open={!!isAsteroidsModalActive}
          closeModal={onCloseModal}
        />
      </ModalAndNotificationsContext.Provider>
      <DrawersContainer />
    </>
  );
};

const mapStoreToProps = (store: SastrifyStore): BaseProps => ({
  isAuthLoading: store.authentication.isLoading,
  isAuthenticated: store.authentication.isAuthenticated,
  isAuthenticatedError: store.authentication.isAuthenticatedError,
  user: store.authentication.user,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  authenticate: (token: string, avatarUrl?: string) => {
    dispatch(authenticate(token, avatarUrl));
  },
  forcedLogout: (error) => dispatch(forcedLogout(error)),
  logout: () => dispatch(logout()),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SastrifyApp = withRouter(connect(mapStoreToProps, mapDispatchToProps)(SastrifyAppFC));
