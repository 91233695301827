import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { FC } from 'react';

export const UsageAnalyticsIframeSkeleton: FC = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant='text' width={43} sx={{ fontSize: '1rem' }} />
      <Skeleton variant='rounded' width='100%' height={46} />
      <Skeleton variant='rounded' width={100} height={35} />
    </Stack>
  );
};
