import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { colors as asteroidsColors } from 'asteroids';
import classnames from 'classnames';
import React from 'react';
import { RefCallBack } from 'react-hook-form';
import { colors } from 'shared/theme';

interface InputProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  leftIcon?: React.ReactNode | string | JSX.Element;
  rightIcon?: React.ReactNode | string | JSX.Element;
  id?: string;
  inputRef?: React.RefObject<{ select?: () => void; focus?: () => void }> | RefCallBack;
  size?: 'small' | 'medium';
  placeholder?: string;
  type?: string;
  name?: string;
  rows?: number;
  maxRows?: number;
  multiline?: boolean;
  helperText?: string | null;
  error?: boolean;
  dataTestId?: string;
  inputProps?: object;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    autoFocus,
    className,
    dataTestId,
    disabled,
    error,
    fullWidth,
    helperText,
    id,
    inputProps,
    inputRef,
    leftIcon,
    maxRows,
    multiline,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    rightIcon,
    rows,
    size,
    type,
    value,
  } = props;

  return (
    <TextField
      data-testid={dataTestId}
      autoComplete='off'
      id={id}
      error={error}
      helperText={helperText}
      name={name}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      inputRef={inputRef}
      className={classnames(className, { disabled })}
      type={type}
      disabled={disabled}
      variant='outlined'
      placeholder={placeholder}
      fullWidth={fullWidth}
      size={size}
      autoFocus={autoFocus}
      minRows={rows}
      maxRows={maxRows}
      multiline={multiline}
      InputProps={{
        ...(inputProps ?? {}),
        endAdornment: rightIcon && <InputAdornment position='end'>{rightIcon}</InputAdornment>,
        startAdornment: leftIcon && <InputAdornment position='start'>{leftIcon}</InputAdornment>,
      }}
      sx={{
        '.Mui-disabled.MuiOutlinedInput-input': {
          backgroundColor: `${colors.lightGreyBackground}`,
          color: `${colors.riverBed}`,
        },
        '.MuiInputLabel-root': {
          color: `${colors.black}`,
        },
        '.MuiInputLabel-root.Mui-focused': {
          color: `${colors.black}`,
        },
        '.MuiOutlinedInput-adornedStart': {
          backgroundColor: `${colors.white}`,
        },
        '.MuiOutlinedInput-input': {
          backgroundColor: `${colors.white}`,
          color: `${colors.black}`,
        },
        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
          color: `${colors.black}`,
        },
        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: `${asteroidsColors.primary.main}`,
          boxShadow: `0 0 5px ${asteroidsColors.primary.light}`,
        },
        ':not(.disabled):hover .MuiOutlinedInput-input': {
          color: `${colors.black}`,
        },
        ':not(.disabled):hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: `${asteroidsColors.primary.main}`,
        },
      }}
    />
  );
};

Input.defaultProps = {
  autoFocus: false,
  className: undefined,
  dataTestId: undefined,
  disabled: false,
  error: false,
  fullWidth: true,
  helperText: undefined,
  id: undefined,
  inputProps: {},
  inputRef: undefined,
  leftIcon: undefined,
  maxRows: undefined,
  multiline: false,
  name: undefined,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,
  placeholder: '',
  rightIcon: undefined,
  rows: undefined,
  size: 'small',
  type: 'search',
};

export { SastrifyInput } from './sastrify-input.component';

export default Input;
