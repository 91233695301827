import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewSpendFeatureProps } from './review-spend.feature.props';

export const ReviewSpendFeature: FC<ReviewSpendFeatureProps> = ({ handleOpenReviewSpendModal }) => {
  const { t } = useTranslation();
  const spendAndInvoicesTranslationPath = 'subscription_detail_view:tabs_component_section.subscription_spend_tab';

  return (
    <Box data-testid='invoice-review'>
      <Tooltip
        title={`${t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_table.tooltip_for_review`)}`}
        placement='top'
        arrow
        className='is-flex'>
        <IconButton>
          <Icon color='error' fontSize='small'>
            info
          </Icon>
        </IconButton>
      </Tooltip>
      <Typography
        variant='body'
        sx={{ cursor: 'pointer', p: 1, textDecoration: 'underline' }}
        onClick={() => handleOpenReviewSpendModal()}>
        {t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_table.review_text`)}
      </Typography>
    </Box>
  );
};
