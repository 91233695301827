/* eslint-disable camelcase */
import { format, parse } from 'date-fns';
import { SOURCE_SYSTEM } from 'libs/enums/source-system.enum';
import { BILLING_FREQUENCIES, NANONETS_IMPORT_SUBSYSTEM_OPTIONS } from 'libs/enums/subscription.enum';
import { CodatPlatformType } from 'libs/models/codat-service.model';
import {
  ImportedMappedVendor,
  ImportedMappedVendorSpendsDto,
  ImportedVendorSpendDetails,
} from 'libs/models/imported-mapped-vendor.model';
import { capitalizeString } from 'shared/helpers/common.helper';
import { ImportedVendor } from 'views/tools-spend-importer/models/ImportedVendor';
import { ImportedVendorSpend } from 'views/tools-spend-importer/models/ImportedVendorSpend';

import { MatchedVendors } from '../../app/views/tools-spend-importer/models/ReviewVendors';
import { Interval } from '../models/interval.model';
import { Subscription } from '../models/subscription.model';
import { getPlatformName } from './connect.helper';

export const getImportSourceIconClassName = (subscription?: Subscription) => {
  const importSubsystem = subscription?.importSubsystem;

  switch (subscription?.importSource) {
    case SOURCE_SYSTEM.CODAT: {
      const codatPlatformName = getPlatformName(importSubsystem as CodatPlatformType) || 'codat';
      const platformNameTransformed = codatPlatformName.toLowerCase().replaceAll(' ', '-');

      return `import-source-${platformNameTransformed}`;
    }

    case SOURCE_SYSTEM.WORKATO: {
      const workatoIntegrationName = importSubsystem || 'default';
      const workatoIntegrationNameTransformed = workatoIntegrationName.toLowerCase().replaceAll(' ', '-');

      return `import-source-${workatoIntegrationNameTransformed}`;
    }

    case SOURCE_SYSTEM.NANONETS: {
      const nanonetSubsystem = NANONETS_IMPORT_SUBSYSTEM_OPTIONS.find((item) => importSubsystem === item) || 'nanonets';
      return `import-source-${nanonetSubsystem}`;
    }

    case SOURCE_SYSTEM.GOOGLE_INTEGRATION:
      return 'import-source-google';

    case SOURCE_SYSTEM.MICROSOFT_INTEGRATION:
      return 'import-source-microsoft';

    case SOURCE_SYSTEM.SPREADSHEET_UPLOAD:
      return 'import-source-spreadsheet';

    case SOURCE_SYSTEM.JUMPCLOUD_INTEGRATION:
      return 'import-source-jumpcloud';

    default:
      return 'import-source-default';
  }
};

export const getImportSourceName = (subscription?: Subscription) => {
  let importSource = capitalizeString(subscription?.importSource?.toLowerCase());

  if (subscription?.importSource === SOURCE_SYSTEM.GOOGLE_INTEGRATION) {
    importSource = 'Google';
  } else if (subscription?.importSource === SOURCE_SYSTEM.MICROSOFT_INTEGRATION) {
    importSource = 'Microsoft';
  } else if (subscription?.importSource === SOURCE_SYSTEM.CODAT && subscription?.importSubsystem) {
    const codatPlatformName = getPlatformName(subscription.importSubsystem as CodatPlatformType) || 'codat';
    importSource = capitalizeString(codatPlatformName);
  } else if (subscription?.importSource === SOURCE_SYSTEM.WORKATO && subscription?.importSubsystem) {
    importSource = capitalizeString(subscription.importSubsystem);
  } else if (subscription?.importSource === SOURCE_SYSTEM.NANONETS && subscription?.importSubsystem) {
    const nanonetsSubsystem =
      NANONETS_IMPORT_SUBSYSTEM_OPTIONS.find((item) => subscription?.importSubsystem === item) || 'nanonets';
    importSource = capitalizeString(nanonetsSubsystem.replace('-', ' '));
  } else if (subscription?.importSource === SOURCE_SYSTEM.SPREADSHEET_UPLOAD) {
    importSource = 'Spreadsheet upload';
  } else if (subscription?.importSource === SOURCE_SYSTEM.JUMPCLOUD_INTEGRATION) {
    importSource = 'JumpCloud';
  }

  return importSource;
};

export const mapImportedVendorsSpends = (importedVendors: MatchedVendors[]): ImportedMappedVendorSpendsDto[] => {
  return importedVendors.map((importedVendor: MatchedVendors) => {
    const importedVendorDetails = importedVendor.importedVendorData as ImportedVendorSpend[];
    const mappedImportedVendorDetails = importedVendorDetails.map((importedVendorDetail) => {
      const { amount, amount_currency, invoice_number, spend_date, vendor } =
        importedVendorDetail as ImportedVendorSpend;
      return {
        amountCents: convertToCents(amount),
        amountCurrency: amount_currency,
        date: convertDateFormat(spend_date),
        invoiceNumber: invoice_number,
        vendorName: vendor,
      } as ImportedVendorSpendDetails;
    }) as ImportedVendorSpendDetails[];

    return {
      importedVendorSpends: mappedImportedVendorDetails,
      mappedToEntity: {
        entityId: importedVendor.matched.id || undefined,
        entityType: importedVendor.matched.entityType,
        name: importedVendor.matched.name,
      },
    } as ImportedMappedVendorSpendsDto;
  });
};

export const mapImportedVendors = (importedVendors: MatchedVendors[]): ImportedMappedVendor[] => {
  return importedVendors.map((importedVendor: MatchedVendors) => {
    const {
      cancellation_period,
      current_year_cost,
      current_year_cost_currency,
      last_year_cost,
      last_year_cost_currency,
      notes_1,
      notes_2,
      notes_3,
      renewal_date,
      renewal_frequency,
      tags_1,
      tags_2,
      tags_3,
      tags_4,
      tags_5,
      tool_owner,
      vendor,
    } = importedVendor.importedVendorData as unknown as ImportedVendor;

    return {
      importedVendorDetails: {
        autoRenewal: getAutoRenewalValue(cancellation_period),
        billingFrequency: renewal_frequency ? mapRenewalFrequencyToBillingFrequency(renewal_frequency) : undefined,
        cancellationPeriod: cancellation_period ? parseInterval(cancellation_period) : undefined,
        currentYearCost: current_year_cost ? convertToCents(current_year_cost) : undefined,
        currentYearCostCurrency: current_year_cost_currency || undefined,
        lastYearCost: last_year_cost ? convertToCents(last_year_cost) : undefined,
        lastYearCostCurrency: last_year_cost_currency || undefined,
        notes: [notes_1, notes_2, notes_3].filter((note): note is string => note !== undefined && note !== ''),
        renewalDate: renewal_date ? convertDateFormat(renewal_date) : undefined,
        tags: [tags_1, tags_2, tags_3, tags_4, tags_5].filter((tag): tag is string => tag !== undefined && tag !== ''),
        toolOwner: tool_owner || undefined,
        vendorName: vendor,
      },
      mappedToEntity: {
        entityId: importedVendor.matched.id || undefined,
        entityType: importedVendor.matched.entityType,
        name: importedVendor.matched.name,
      },
    };
  });
};

const getAutoRenewalValue = (cancellationPeriod?: string): boolean | null => {
  if (cancellationPeriod === 'unknown' || !!cancellationPeriod) {
    return true;
  }

  return null;
};

export function parseInterval(input: string): Interval | null {
  const parts = input.split(' ');
  const value = parseInt(parts[0], 10);
  const unit = parts[1];

  const interval: Interval = {};

  switch (unit) {
    case 'seconds':
      interval.seconds = value;
      break;
    case 'minutes':
      interval.minutes = value;
      break;
    case 'hours':
      interval.hours = value;
      break;
    case 'days':
      interval.days = value;
      break;
    case 'months':
    case 'month':
      interval.months = value;
      break;
    default:
      return null;
  }

  if (interval.months) {
    return { days: interval.months * 30 };
  }

  return interval;
}

/**
 * Maps a renewal frequency string to a BillingFrequencyOptions enum value.
 *
 * @param {string} input - The renewal frequency as a string. Expected values are 'monthly' or 'yearly'.
 * @returns {BILLING_FREQUENCIES | null} - The corresponding BillingFrequencyOptions enum value, or null if the input does not match any known renewal frequencies.
 */
export function mapRenewalFrequencyToBillingFrequency(input: string): BILLING_FREQUENCIES | null {
  if (input === 'monthly') {
    return BILLING_FREQUENCIES.MONTHLY;
  }

  if (input === 'yearly') {
    return BILLING_FREQUENCIES.YEARLY;
  }

  return null;
}

export function convertDateFormat(inputDateString: string): string {
  const parsedDate: Date = parse(inputDateString, 'dd.MM.yyyy', new Date());
  const formattedDate = format(new Date(parsedDate), 'yyyy-MM-dd');

  return formattedDate;
}

export const convertToCents = (amount: number): number => {
  return Math.round(roundHalfToEven(amount, 2) * 100);
};

/**
 * This is implementation of the accounting rounding in case
 * we would receive decimal numbers with more than 2 decimal places.
 * This approach is known as accounting rounding or bankers' rounding or round half to even.
 *
 * This function multiplies the input value by a factor of 10 raised to the power of the desired precision,
 * rounds the result, and then divides by the same factor to get the rounded number.
 * If the rounded number is odd and the difference between it and the original number is exactly 0.5,
 * it uses Math.floor instead of Math.round to round down to the nearest even number.
 * Thus, 0.5 rounds down to 0; 1.5 rounds up to 2.
 * @param value - value that requires rounding
 * @param precision - default 2
 * @returns
 */
const roundHalfToEven = (value: number, precision = 2): number => {
  const factor = 10 ** precision;
  const tempNumber = value * factor;
  const roundedTempNumber = Math.round(tempNumber);

  if (roundedTempNumber % 2 !== 0 && Math.abs(roundedTempNumber - tempNumber) === 0.5) {
    return Math.floor(tempNumber) / factor;
  }

  return roundedTempNumber / factor;
};
