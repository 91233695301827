import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { colors } from 'shared/theme';

export const SastrifyInput = styled(TextField)({
  '& .MuiFormHelperText-root': {
    fontFamily: 'Inter',
    fontSize: '11px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.newPrimary,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.newPrimary,
    },
  },
  '& input, textarea': {
    color: 'rgba(27, 36, 43, 0.87)',
    fontFamily: 'Inter',
  },
  '& label, label.Mui-error': {
    color: 'rgba(27, 36, 43, 0.6)',
    fontFamily: 'Inter',
    fontSize: '0.95rem',
  },
  '& label.Mui-focused': {
    color: colors.newPrimary,
  },
});

SastrifyInput.defaultProps = {
  fullWidth: true,
};
