import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FileItem = styled(Box)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.highlight,
  },
  '&:hover #file-item__actions > *': {
    visibility: 'visible',
  },
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '0 !important',
  padding: theme.spacing(1),
}));

export const FileIcon = styled(Box)(({ theme }) => ({
  '& .MuiIcon-colorSuccess': {
    color: theme.palette.primary.main,
  },
  backgroundColor: theme.palette.primary.highlight,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(1),

  padding: `${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
}));

export const FlexBox = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
}));

export const FlexBoxAlignRight = styled(FlexBox)(({ theme }) => ({
  '& > *': {
    '&:hover': {
      cursor: 'pointer',
    },
    '&:last-child': {
      marginRight: theme.spacing(1),
    },
    marginRight: theme.spacing(2),
    visibility: 'hidden',
  },
  justifyContent: 'flex-end',
}));

export const FileName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
