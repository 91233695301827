import { SSOIntegrationStatus } from 'libs/enums';
import { CodatPlatformType } from 'libs/models';

export enum CodatPlatformName {
  Xero = 'xero',
  Netsuite = 'netsuite',
  Sage = 'sage',
  SageIntacct = 'sage intacct',
  Dynamics365 = 'microsoft dynamics 365',
  QuickBooksOnline = 'quickbooks',
  ZohoBooks = 'zohobooks',
  FreshBooks = 'freshbooks',
  ExactOnlineNL = 'exact online netherlands',
  ExactOnlineUK = 'exact online united kingdom',
}

export enum IntegrationStatus {
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Unknown = 'Unknown',
}

export type SSOCompanyConnectionState = {
  companyId: string;
  status: SSOIntegrationStatus;
  usageAnalyticsEnabled?: boolean; // TODO: make this required after ms and okta are migrated to the new manage drawer
};

export interface IntegrationItem {
  content?: string;
  id?: number;
  icon?: string;
  image?: string;
  linkText?: string;
  loadCommand?: string;
  title?: string;
  url?: string;
}

export interface CodatLinkUrlPayload {
  companyId: string;
  companyName: string;
}

export type CodatConnectionPayload = CodatLinkUrlPayload & {
  platformType: CodatPlatformType;
};

export type CodatConnectionPayloadV2 = CodatLinkUrlPayload & {
  platformType: CodatPlatformType;
  sastrifyConnectionName: string;
};

export type CodatConnectionPayloadV2WithRefetch = CodatConnectionPayloadV2 & {
  refetch(): Promise<any>;
};

export type CodatDisconnectPayload = {
  companyId: string;
  platformType: CodatPlatformType;
  refetch(): void;
};

export type CodatUpdateConnectionNamePayload = {
  codatCompanyId: string;
  companyId: string;
  name: string;
  refetch(): Promise<any>;
};
export interface CodatDisconnectV2Payload {
  companyId: string;
  codatCompanyId: string;
  refetch(): Promise<any>;
}

export interface CodatLinkUrlResponse {
  linkUrl: string;
}

export enum CodatConnectionStatus {
  Connected = 'connected',
  Disconnected = 'disconnected',
  Created = 'created',
}

export interface CodatIntegrationDetails {
  linkUrl: string;
  name: string;
  codatPlatformKey: CodatPlatformType;
  codatCompanyId: string;
  codatDataConnectionId: string;
  companyId: string;
  dataConnections: DataConnectionsMap;
}

export type DataConnectionsMap = {
  [key in CodatPlatformType]: CodatDataConnection;
};

export interface CodatDataConnection {
  id: string;
  status: CodatConnectionStatus;
  platformKey: CodatPlatformType;
  platformName: string;
}
