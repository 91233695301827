import { Box } from '@mui/material';
import { SOURCE_NAME, TYPE } from 'libs/constants/tool-usage.constants';
import { TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  BaseUsageAnalyticsTrackingParams,
  trackConnected,
  trackDisconnected,
  trackDisconnectedError,
} from 'shared/logic/event-tracking/usage-analytics.events';
import {
  createSsoWorkatoIntegration,
  disconnectSsoWorkatoIntegration,
  updateWorkatoSsoProvider,
} from 'shared/logic/tool-usage-analytics.logic';
import { Company } from 'shared/models';
import { queryClient } from 'shared/reactQuery';
import { RootState } from 'shared/store';
import { setEnableUsageAnalytics } from 'shared/store/common/workatoSSOConnect.slice';
import { useAppSelector } from 'shared/store/hooks';
import { useUser } from 'src/app/hooks/useUser';

import { ManageSSOCard, ManageSSOCardFooter } from '../components';

export const ManageWorkatoSSOConnection: FC = () => {
  const dispatch = useDispatch();
  const { isConnected, ssoProviderName, usageAnalyticsEnabled } = useAppSelector(
    (state: RootState) =>
      state.common.appDrawer.data as {
        isConnected: boolean;
        usageAnalyticsEnabled: boolean;
        ssoProviderName: string;
      }
  );
  const company = queryClient.getQueryData<Company>('company');

  const location = useLocation();
  const user = useUser();
  const baseTrackingEventProps: BaseUsageAnalyticsTrackingParams = {
    analyticsProviderName: ssoProviderName,
    analyticsProviderType: TYPE.SSO,
    analyticsSourceName: SOURCE_NAME.WORKATO,
    companyId: user.companyId ?? '',
    userId: user.id ?? '',
  };

  const { mutate: disconnect } = useMutation('disconnect-workato', disconnectSsoWorkatoIntegration, {
    onError: (error: unknown) => {
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = `Failed with error '${error.message}'`;
      }
      trackDisconnectedError(
        { ...baseTrackingEventProps, errorMessage: `Failed with error '${errorMessage}'` },
        location.pathname,
        usageAnalyticsEnabled
      );
    },
    onSuccess: () => {
      updateWorkatoSsoProvider(queryClient, ssoProviderName || '', TOOL_USAGE_INTEGRATION_STATE.AVAILABLE);
      trackDisconnected(baseTrackingEventProps, location.pathname, usageAnalyticsEnabled);
    },
  });

  const { mutate: createSsoIntegration } = useMutation(createSsoWorkatoIntegration);

  const toggleUsageAnalytics = (enableUsageAnalytics: boolean) => {
    dispatch(setEnableUsageAnalytics(enableUsageAnalytics));

    createSsoIntegration({
      companyId: company?.id || '',
      companyName: company?.name || '',
      ssoName: ssoProviderName,
      usageAnalyticsEnabled: enableUsageAnalytics,
    });

    if (isConnected) {
      trackConnected(baseTrackingEventProps, location.pathname, enableUsageAnalytics);
    }
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', mx: 5, pt: 8 }}>
      <ManageSSOCard onToggleUsageAnalytics={toggleUsageAnalytics} />
      <ManageSSOCardFooter
        onDisconnect={() => {
          if (company?.id) disconnect({ companyId: company.id, ssoName: ssoProviderName });
        }}
      />
    </Box>
  );
};
