// Keys from https://docs.codat.io/changelog/25191-four-letter-platform-keys#key-mappings

export enum CodatPlatformType {
  XERO = 'gbol',
  NETSUITE = 'akxx',
  SANDBOX = 'mqjo',
  SAGE = 'tgff',
  SAGE_INTACCT = 'knfz',
  DYNAMICS_365 = 'trji',
  QUICKBOOKS_ONLINE = 'qhyg',
  QUICKBOOKS_ONLINE_SANDBOX = 'ndsk',
  FRESHBOOKS = 'vxvy',
  ZOHO_BOOKS = 'rwuv',
  EXACT_ONLINE_UK = 'pbbf',
  EXACT_ONLINE_NL = 'qudb',
}

export enum CODAT_INTEGRATION_STATUS {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
}

export interface CodatIntegrationDetails {
  companyName: string;
  companyId: string;
  codatCompanyId: string;
  codatConnectionId: string;
  platformKey: CodatPlatformType;
  platformName: string;
  status: CODAT_INTEGRATION_STATUS;
  billsLastSync?: string;
  directCostsLastSync?: string;
  createdAt?: string;
  updatedAt?: string;
  sastrifyConnectionName: string;
  userEmail?: string;
  connectedAt?: string;
}

export interface CodatIntegrationDetailsRes extends CodatIntegrationDetails {
  linkUrl: string;
}
