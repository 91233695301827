import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { pathToTranslation } from '../../contact-sastrify-form.constants';
import { RequestRenewalOtherFormProps } from './request-renewal-other-form.component.props';
import { formValidationSchema } from './request-renewal-other-form.validation-scheme';

export const RequestRenewalOtherFormComponent: React.FC<RequestRenewalOtherFormProps> = ({ formRef }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      requestDetails: '',
    },
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  formRef.current = {
    getValues,
    trigger,
  };

  return (
    <Grid item xs={12}>
      <Typography component='p' variant='h3' my={2}>
        {t(`${pathToTranslation}.form_sections.questionnaire_section`)}
      </Typography>
      <Typography component='p' variant='label' mb={1.5}>
        {t(`${pathToTranslation}.fields.other_details.label`)}
      </Typography>
      <Controller
        name='requestDetails'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              id='requestDetails'
              onChange={onChange}
              value={value}
              label={t(`${pathToTranslation}.fields.other_details.placeholder`)}
              multiline
              rows={3}
              fullWidth
              error={Boolean(errors.requestDetails)}
              helperText={errors.requestDetails ? t(`${pathToTranslation}.errors.required_field`) : ''}
            />
          );
        }}
      />
    </Grid>
  );
};
