import { Box, Button } from '@mui/material';
import { useCheckUserPermission } from 'hooks/index';
import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { PERMISSION } from 'libs/enums';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { getSsoToolsList } from 'shared/logic/tool-usage-analytics.logic';
import { Company } from 'shared/models';
import { queryClient } from 'shared/reactQuery';
import { RootState } from 'shared/store';
import { useAppSelector } from 'shared/store/hooks';

import { WorkatoSSOConnectContainer } from '../providers-drawers/manage-workato-sso-connection-container.component';
import { DrawerHeader, ToolsList, UsageAnalytics } from '.';

export const ManageSSOCard: FC<{
  onToggleUsageAnalytics?: (value: boolean) => void;
  onConnect?: (enableUsageAnalytics: boolean) => void;
}> = ({ onConnect, onToggleUsageAnalytics }) => {
  const { t } = useTranslation();
  const hasManageIntegrationsPermission = useCheckUserPermission(PERMISSION.MANAGE_INTEGRATIONS);
  const company = queryClient.getQueryData<Company>('company');

  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isUsageAnalyticsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.USAGE_ANALYTICS);

  const { isConnected, shouldShowEnableToolUsage, ssoProviderName, togglingUsageAnalytics, usageAnalyticsEnabled } =
    useAppSelector(
      (state: RootState) =>
        state.common.appDrawer.data as {
          isConnected: boolean;
          ssoProviderName: string;
          togglingUsageAnalytics: boolean;
          usageAnalyticsEnabled: boolean;
          shouldShowEnableToolUsage: boolean;
        }
    );

  const { data: toolsList, isLoading: toolsListLoading } = useQuery(
    ['sso-tools-list', ssoProviderName],
    () =>
      getSsoToolsList({
        companyId: company?.id || '',
        ssoName: ssoProviderName,
      }),
    {
      enabled: Boolean(company?.id) && ssoProviderName === 'Okta',
    }
  );

  const showToolList = isConnected && ssoProviderName === 'Okta';

  const [enableUsageAnalytics, setEnableUsageAnalytics] = useState<boolean>(usageAnalyticsEnabled);

  const handleToggleUsageAnalytics = (value: boolean) => {
    setEnableUsageAnalytics(value);

    if (isConnected && onToggleUsageAnalytics) {
      onToggleUsageAnalytics(value);
    }
  };

  const handleOnConnect = () => {
    if (onConnect) {
      onConnect(enableUsageAnalytics);
    }
  };

  const getConnectSection = () => {
    switch (ssoProviderName) {
      case 'Google':
        return <Button className='google-button' onClick={handleOnConnect} />;
      case 'Microsoft':
        return (
          <Button color='primary' size='small' variant='contained' sx={{ width: '80px' }} onClick={handleOnConnect}>
            {t('connect_view:tab_section.connect')}
          </Button>
        );
      case 'Okta':
        return <WorkatoSSOConnectContainer />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
      <DrawerHeader isConnected={isConnected} ssoProviderName={ssoProviderName} />
      {isUsageAnalyticsEnabled && shouldShowEnableToolUsage && (
        <UsageAnalytics
          ssoProviderName={ssoProviderName}
          checked={enableUsageAnalytics}
          disabled={!hasManageIntegrationsPermission || togglingUsageAnalytics}
          onToggle={handleToggleUsageAnalytics}
        />
      )}
      {!isConnected && getConnectSection()}
      {showToolList && (
        <ToolsList ssoProviderName={ssoProviderName} toolsList={toolsList || []} loading={toolsListLoading} />
      )}
    </Box>
  );
};
