import React, { FC } from 'react';
import { colors } from 'shared/theme';

export const AddNewSubscriptionIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width='260' height='196' viewBox='0 0 260 196' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M135.311 179.309C107.125 180.448 88.9617 155.343 71.2476 135.636C53.3442 115.718 28.98 94.2242 36.6856 69.5855C44.2329 45.4531 78.3462 41.3689 103.896 31.2438C127.703 21.8092 151.356 6.27276 175.717 14.4881C201.916 23.3229 220.168 46.7605 223.769 71.6681C227.057 94.4073 207.689 112.279 192.524 130.733C176.13 150.683 162.904 178.194 135.311 179.309Z'
        fill={colors.aquamarineBlue}
        fillOpacity='0.22'
      />
      <path
        opacity='0.45'
        d='M132.607 179.578C190.055 179.578 236.626 173.949 236.626 167.005C236.626 160.061 190.055 154.431 132.607 154.431C75.1581 154.431 28.587 160.061 28.587 167.005C28.587 173.949 75.1581 179.578 132.607 179.578Z'
        fill={colors.mercury}
      />
      <path d='M199.745 139.134H162.091V151.077H199.745V139.134Z' fill={colors.mercury} />
      <path d='M214.955 151.285H162.091V163.228H214.955V151.285Z' fill={colors.venetianRed} />
      <path d='M184.567 29.8923H76.2515V166.998H184.567V29.8923Z' fill={colors.outerSpaceV2} />
      <path
        opacity='0.07'
        d='M169.384 36.6732H91.2145C87.8795 36.6732 85.176 39.3772 85.176 42.7129V52.6859C85.176 56.0215 87.8795 58.7256 91.2145 58.7256H169.384C172.718 58.7256 175.422 56.0215 175.422 52.6859V42.7129C175.422 39.3772 172.718 36.6732 169.384 36.6732Z'
        fill={colors.white}
      />
      <path d='M156.793 22.0192H101.66V29.8923H156.793V22.0192Z' fill={colors.aquamarineBlue} />
      <path
        d='M165.022 53.6221C168.278 53.6221 170.918 50.982 170.918 47.7254C170.918 44.4687 168.278 41.8287 165.022 41.8287C161.766 41.8287 159.126 44.4687 159.126 47.7254C159.126 50.982 161.766 53.6221 165.022 53.6221Z'
        fill={colors.aquamarineBlue}
      />
      <path d='M152.899 44.9233H94.419V50.5275H152.899V44.9233Z' fill={colors.white} />
      <path
        opacity='0.07'
        d='M169.384 70.1939H91.2145C87.8795 70.1939 85.176 72.898 85.176 76.2336V86.2066C85.176 89.5423 87.8795 92.2463 91.2145 92.2463H169.384C172.718 92.2463 175.422 89.5423 175.422 86.2066V76.2336C175.422 72.898 172.718 70.1939 169.384 70.1939Z'
        fill={colors.white}
      />
      <path
        d='M165.022 87.1429C168.278 87.1429 170.918 84.5028 170.918 81.2462C170.918 77.9895 168.278 75.3495 165.022 75.3495C161.766 75.3495 159.126 77.9895 159.126 81.2462C159.126 84.5028 161.766 87.1429 165.022 87.1429Z'
        fill={colors.aquamarineBlue}
      />
      <path d='M152.899 78.4441H94.419V84.0482H152.899V78.4441Z' fill={colors.white} />
      <path
        opacity='0.07'
        d='M169.384 103.708H91.2145C87.8795 103.708 85.176 106.412 85.176 109.748V119.721C85.176 123.057 87.8795 125.761 91.2145 125.761H169.384C172.718 125.761 175.422 123.057 175.422 119.721V109.748C175.422 106.412 172.718 103.708 169.384 103.708Z'
        fill={colors.white}
      />
      <path
        d='M165.022 120.657C168.278 120.657 170.918 118.017 170.918 114.76C170.918 111.504 168.278 108.864 165.022 108.864C161.766 108.864 159.126 111.504 159.126 114.76C159.126 118.017 161.766 120.657 165.022 120.657Z'
        fill={colors.aquamarineBlue}
      />
      <path d='M152.899 111.958H94.419V117.562H152.899V111.958Z' fill={colors.white} />
      <path
        opacity='0.07'
        d='M169.384 137.222H91.2145C87.8795 137.222 85.176 139.926 85.176 143.262V153.235C85.176 156.571 87.8795 159.275 91.2145 159.275H169.384C172.718 159.275 175.422 156.571 175.422 153.235V143.262C175.422 139.926 172.718 137.222 169.384 137.222Z'
        fill={colors.white}
      />
      <path
        d='M165.022 154.171C168.278 154.171 170.918 151.531 170.918 148.275C170.918 145.018 168.278 142.378 165.022 142.378C161.766 142.378 159.126 145.018 159.126 148.275C159.126 151.531 161.766 154.171 165.022 154.171Z'
        fill={colors.aquamarineBlue}
      />
      <path d='M152.899 145.473H94.419V151.077H152.899V145.473Z' fill={colors.white} />
      <path d='M209.69 14.3477H177.951V37.5248H209.69V14.3477Z' fill={colors.aquamarineBlue} />
      <path
        d='M191.971 31.4396H191.932C191.797 31.4334 191.664 31.3975 191.544 31.3346C191.424 31.2717 191.319 31.1833 191.237 31.0756L188.091 27.0317C187.939 26.8369 187.871 26.5898 187.901 26.3447C187.932 26.0996 188.058 25.8767 188.253 25.725C188.448 25.5732 188.695 25.5051 188.94 25.5356C189.185 25.5661 189.408 25.6927 189.56 25.8875L192.03 29.0731L198.133 22.4808C198.302 22.3074 198.532 22.2069 198.774 22.2006C199.016 22.1943 199.251 22.2827 199.429 22.4471C199.607 22.6114 199.714 22.8387 199.727 23.0806C199.739 23.3224 199.657 23.5598 199.498 23.7421L192.654 31.1406C192.566 31.2345 192.461 31.3095 192.343 31.3609C192.226 31.4124 192.099 31.4392 191.971 31.4396Z'
        fill={colors.white}
      />
      <path
        opacity='0.2'
        d='M81.3865 42.3228C81.3865 42.3228 86.944 39.8263 86.944 44.3187C86.944 48.8111 81.276 62.3663 84.396 64.2777C87.516 66.1891 100.984 62.8409 103.122 76.3506C105.261 89.8603 100.301 121.346 134.271 124.402C134.271 124.402 137.897 132.71 128.355 131.709C118.813 130.708 104.117 125.657 100.49 127.223C96.863 128.79 101.257 166.68 101.257 166.68H76.4595L76.6285 124.974L76.05 59.0376L81.3865 42.3228Z'
        fill={colors.black}
      />
      <path
        d='M80.3985 47.2898C80.3985 47.2898 80.8275 51.0736 80.2555 53.6091C80.2004 53.8554 80.0969 54.0884 79.9509 54.2944C79.805 54.5003 79.6196 54.6753 79.4055 54.809C79.1914 54.9426 78.9529 55.0324 78.7038 55.0731C78.4547 55.1138 78.2 55.1045 77.9545 55.0459C77.1531 54.9127 76.4074 54.5501 75.8076 54.0021C75.2078 53.4541 74.7795 52.744 74.5745 51.9577L73.3525 48.6616C73.1742 47.9771 73.2129 47.2541 73.4632 46.5926C73.7136 45.931 74.1632 45.3636 74.75 44.9688C76.869 43.389 80.2555 44.9688 80.3985 47.2898Z'
        fill={colors.vividTangerine}
      />
      <path
        d='M99.5345 63.387C99.5345 63.387 112.261 65.6755 109.2 90.8485C106.138 116.022 132.132 122.276 132.132 122.276C132.132 122.276 139.035 120.117 140.055 124.311C141.076 128.504 132.132 126.911 132.132 126.911C132.132 126.911 104.832 128.153 93.782 101.556C93.7885 101.563 86.944 69.7518 99.5345 63.387Z'
        fill={colors.vividTangerine}
      />
      <path
        d='M74.048 49.5912L70.9735 61.6837L77.7595 62.8994L77.272 53.6156L74.048 49.5912Z'
        fill={colors.vividTangerine}
      />
      <path
        d='M72.9365 51.6197C72.94 51.6987 72.9692 51.7745 73.0196 51.8355C73.0701 51.8965 73.139 51.9394 73.216 51.9577C73.7534 52.0995 74.3226 52.0597 74.8351 51.8445C75.3476 51.6294 75.7747 51.2509 76.05 50.768C76.3769 50.2429 76.5425 49.6335 76.5263 49.0152C76.5101 48.3969 76.3129 47.797 75.959 47.2898C77.1206 47.4822 78.3005 47.5411 79.4755 47.4653C79.8705 47.4414 80.2591 47.3536 80.626 47.2053L81.5295 44.3187C81.5295 44.3187 83.0245 41.4256 81.328 40.847C79.6315 40.2684 78.884 42.2058 77.493 40.847C76.102 39.4882 75.777 38.9811 74.3795 39.7613C72.982 40.5414 74.607 41.6336 72.2995 41.6662C69.992 41.6987 69.953 42.6479 69.953 43.7011C70.0089 44.0583 70.1695 44.3909 70.4145 44.6568C71.9866 46.6434 72.8716 49.0868 72.9365 51.6197Z'
        fill={colors.outerSpace}
      />
      <path
        d='M76.44 50.6445C76.44 50.6445 76.375 49.1817 75.2765 49.3442C74.178 49.5067 74.2625 51.6002 75.764 51.7562L76.44 50.6445Z'
        fill={colors.vividTangerine}
      />
      <path
        d='M80.548 49.6823L81.6465 51.1191C81.6999 51.2035 81.7331 51.2991 81.7436 51.3984C81.7541 51.4977 81.7415 51.5981 81.707 51.6918C81.6724 51.7854 81.6167 51.8699 81.5442 51.9386C81.4717 52.0073 81.3844 52.0583 81.289 52.0878L79.8395 52.3999L80.548 49.6823Z'
        fill={colors.vividTangerine}
      />
      <path
        opacity='0.31'
        d='M77.311 54.8508C77.311 54.8508 76.44 54.7013 74.867 53.2775C74.867 53.2775 74.646 55.761 77.467 57.5814L77.311 54.8508Z'
        fill={colors.antiqueBrass}
      />
      <path
        d='M64.038 122.302L61.75 164.554L64.363 164.515L73.7035 122.815L64.038 122.302Z'
        fill={colors.vividTangerine}
      />
      <path d='M89.44 122.302L91.728 164.554H88.8485L79.7745 122.815L89.44 122.302Z' fill={colors.vividTangerine} />
      <path
        d='M50.7 76.6822C50.4827 74.8094 50.6451 72.912 51.1773 71.1033C51.7096 69.2947 52.6009 67.6119 53.798 66.1555C54.995 64.699 56.4733 63.4989 58.1444 62.6267C59.8156 61.7545 61.6454 61.2281 63.5245 61.0791C73.7685 60.2664 88.0685 60.0519 99.5345 63.3805C119.769 69.2317 90.935 117.517 90.935 117.517L61.75 116.08C61.75 116.08 53.053 97.2524 50.7 76.6822Z'
        fill={colors.aquamarineBlue}
      />
      <path
        opacity='0.08'
        d='M67.73 68.224C67.73 68.224 74.4705 73.6916 70.98 82.2213C67.4895 90.751 61.5485 98.5266 70.98 100.035C80.4115 101.543 89.0955 91.7782 90.9415 80.0044C92.7875 68.2305 95.979 71.0196 95.979 71.0196L103.74 71.9102L100.555 94.9444L91.728 94.6908C91.728 94.6908 85.878 107.043 76.037 116.795L61.737 116.093L59.6245 111.003L51.9545 90.3804L67.73 68.224Z'
        fill={colors.black}
      />
      <path
        d='M61.75 116.08V137.762H74.3665L74.945 130.038C75.1048 127.894 75.8911 125.843 77.2062 124.142C78.5213 122.441 80.3072 121.163 82.342 120.469L90.935 117.504L61.75 116.08Z'
        fill={colors.osloGray}
      />
      <path d='M100.472 69.2269L84.3981 67.1702L81.3761 90.7985L97.4496 92.8551L100.472 69.2269Z' fill={colors.gold} />
      <path
        opacity='0.08'
        d='M99.099 80.0044C97.4894 83.1474 94.9385 85.7091 91.8025 87.3316C88.6666 88.9541 85.1023 89.5563 81.6075 89.0542L81.3865 90.7901L97.461 92.8445L99.099 80.0044Z'
        fill={colors.black}
      />
      <path
        d='M90.935 117.504L93.301 137.742H79.8395C79.8395 137.742 79.2805 129.037 75.4845 122.789L90.935 117.504Z'
        fill={colors.osloGray}
      />
      <path
        d='M67.301 76.6172C72.28 66.7937 60.3265 57.2173 51.7855 64.1932C48.0718 67.096 45.2623 70.9976 43.6865 75.4405C38.597 90.3935 33.501 114.344 55.5165 111.659C77.532 108.974 89.57 92.8445 89.57 92.8445L86.4305 88.9437C86.4305 88.9437 73.7425 100.997 60.242 97.5969C51.649 95.4515 61.906 87.2469 67.301 76.6172Z'
        fill={colors.vividTangerine}
      />
      <path
        d='M91.598 164.515C91.598 164.515 92.6705 166.465 94.549 166.303C96.4275 166.14 97.2075 167.889 95.6215 168.637C94.0355 169.384 89.3815 167.935 89.3815 167.935L88.985 164.515H91.598Z'
        fill={colors.aquamarineBlue}
      />
      <path
        d='M61.8085 164.515C61.8085 164.515 60.736 166.465 58.8575 166.303C56.979 166.14 56.199 167.889 57.785 168.637C59.371 169.384 64.025 167.935 64.025 167.935L64.4215 164.515H61.8085Z'
        fill={colors.aquamarineBlue}
      />
      <path
        d='M86.45 88.9632C86.45 88.9632 89.2515 85.2509 92.781 86.1676C96.3105 87.0843 89.5895 92.8445 89.5895 92.8445L86.45 88.9632Z'
        fill={colors.vividTangerine}
      />
    </svg>
  );
};
