import { styled } from '@mui/material/styles';

import pageBreakpoints from './helpers/page-breakpoints';

export const StyledPageContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    '& .page-container': {
      margin: '0 auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(5, 4),
      [theme.breakpoints.down('xl')]: {
        ...pageBreakpoints('sm'),
      },
      [theme.breakpoints.up('xl')]: {
        ...pageBreakpoints('xl'),
      },
    },
    '&.page-container--with-footer': {
      '& .page-container': {
        paddingBottom: '69px',
        [theme.breakpoints.down('xl')]: {
          paddingBottom: `calc(${theme.spacing(4)} + 69px)`,
        },
        [theme.breakpoints.up('xl')]: {
          paddingBottom: `calc(${theme.spacing(5)} + 69px)`,
        },
      },
    },
    width: '100%',
  };
});
