/* eslint-disable prettier/prettier */
import './subscription-task.component.scss';

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icon } from 'asteroids';
import classnames from 'classnames';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { TASK_HASH } from 'components/tabs/tabs.component';
import initHelpHero from 'helphero';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CommentDocument,
  currentDate,
  DEFAULT_SUPPORT_AGENT,
  DrawerInfoType,
  googleTagManagerEvents,
  SubscriptionStatus,
  TodoCategories,
  TodoStates,
  ToolOwnershipStep,
  UserRoles,
} from 'shared/common.definitions';
import { decodeIdFromRoute, formatDate, isNewSolutionsRoute } from 'shared/helpers/common.helper';
import { DropdownMenuIcon } from 'shared/helpers/styles.helper';
import { CreateUserData, InviteUserError, InviteUserFormValues } from 'shared/helpers/user-drawer.helper';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import {
  addSubscriptionComment,
  deleteSubscriptionTodo,
  getFormattedHTMLWithNoWhiteSpaces,
  getMarkdownFromComment,
  getSubscriptionName,
  getSubscriptionStatus,
  updateSubscription,
  updateSubscriptionTodo,
  updateSubscriptionToolOwner,
} from 'shared/logic/subscription-item.logic';
import { sendTaskDeletedEvent } from 'shared/logic/track-events.logic';
import { createUser } from 'shared/logic/users.logic';
import { AddSubscriptionCommentModel, SastrifyStore, Subscription, Todo, ToolOwner, User } from 'shared/models';
import { SubscriptionItemState } from 'shared/models/subscription-item.model';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { colors } from 'shared/theme';
import { AppUrl } from 'src/constants/appurl';
import * as yup from 'yup';

import { AssignToolOwnerFeature } from '../../features';
import { CustomTaskForm, MarkTaskAsDoneForm } from '../../features/subscription';
import { DialogComponent, ModalComponentProps, WysiwygEditorComponent } from '..';
import { InviteUserInitialValue } from '../drawer/drawer.component.props';
import { DropdownComponent } from '../drop-down';
import { CircleCheckIcon, SuccessIcon, VerticalThreeDotsIcon } from '../icons';
import Input from '../input';
import { UserNameAndAvatarComponent } from '../username-and-avatar';
import { SubscriptionTaskComponentProps } from '.';
import { useCustomTaskActions } from './hooks/use-custom-task-actions';
import { SubscriptionTaskAccordion } from './subscription-task.styles';

type GetActions = {
  [key: string]: () => void;
};

const commentSchema = yup.object().shape({
  comment: yup.string().required(),
});
type CommentFormType = {
  comment?: string;
};

type GetActionsButton = (category: number, actions?: GetActions) => JSX.Element;

const initialState = {
  modalProps: {
    cancelButtonText: 'Cancel',
    children: undefined,
    confirmButtonText: 'Confirm',
    message: '',
  },
  toolOwnerProps: {
    owner: {},
    step: ToolOwnershipStep.Add,
    subscriptionId: '',
    vendor: '',
  },
} as SubscriptionItemState;

export const SubscriptionTaskComponent: FC<SubscriptionTaskComponentProps> = (props) => {
  const {
    closeModal,
    markTodoAsDone,
    onUpdateRenewalDate,
    openInviteUserDrawer,
    showModal,
    showNotification,
    subscription,
    todo,
    updateSubscriptionTodosCache,
  } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const actionMenuRef = useRef<{ handleToggle: () => void }>();
  const customTaskRef = useRef<{ onSubmit: () => void }>();
  const markTaskasDoneRef = useRef<{ onSubmit: () => void }>();

  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const [isUploadingDocument, setIsUploadingDocument] = useState(false);

  const { hash, pathname } = location;

  const todoId = todo.id;
  const subscriptionId = decodeIdFromRoute(location.pathname);
  const pathToTranslation = 'subscription_detail_view:tabs_component_section.subscription_task_tab.tasks_sections';

  const isCompletedTodos = todo.state === TodoStates.DONE;

  const showCustomTaskActions = useCustomTaskActions(todo?.assignedToId as string);

  const paths = [
    'assign_owner_section',
    'savings_suggestion',
    'update_renewal_date',
    'upload_latest_invoice',
    'custom_todo',
    'onboarding_questionnaire',
    'expired_renewal_date',
    'verify_renewal_date',
    'renewal_questionnaire',
    'custom_task_section',
  ];

  const [
    assignOwnerPath,
    savingSuggestionsPath,
    updateRenewalPath,
    uploadLatestInvoicePath,
    customTodoPath,
    onboardingQuestionnairePath,
    expiredRenewalDatePath,
    verifyRenewalDatePath,
    renewalQuestionnairePath,
    customTaskPath,
  ] = paths;

  enum TaskTranslationKey {
    ACTION_TEXT = 'action_text',
    SUB_ACTION_TEXT = 'sub_action_text',
    TITLE_TEXT = 'title_text',
    DESCRIPTION_TEXT = 'description_text',
  }

  const getTaskActionTranslationText = (taskPath: string, key: TaskTranslationKey) =>
    t(`${pathToTranslation}.${taskPath}.action_buttons.${key}`);

  const getTaskDetailsTranslationText = (taskPath: string, key: TaskTranslationKey, name?: string) =>
    t(`${pathToTranslation}.${taskPath}.${key}`, {
      name: String(name),
    });

  const [openAssignOwnerInput, setOpenAssignOwnerInput] = useState<boolean>(false);
  const [toolOwnerName, setToolOwnerName] = useState<string>('');
  const [isUpdatingToolOwner, setIsUpdatingToolOwner] = useState<boolean>(false);
  const [state, setState] = useState<SubscriptionItemState>(initialState);
  const [isOpenTodoCommentView, setIsOpenTodoCommentView] = useState(false);
  const [todoComment, setTodoComment] = useState<string>('');
  const [savingSuggestionsActions, setSavingSuggestionsActions] = useState<'act_on_it' | 'decline'>();
  const [startCommentValidation, setStartCommentValidation] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const inviteUserFeatureRef = useRef<{ getFormValues: () => InviteUserFormValues; resetForm: () => void }>();

  const { control, getValues, watch } = useForm<CommentFormType>({
    mode: 'onChange',
    resolver: yupResolver(commentSchema),
    shouldUnregister: true,
  });

  const comment = watch('comment');

  useEffect(() => {
    if (startCommentValidation) {
      const formattedHTMLWithNoWhiteSpaces = comment ? getFormattedHTMLWithNoWhiteSpaces(comment) : undefined;
      dispatch(updateFooterState({ isFormValid: Boolean(formattedHTMLWithNoWhiteSpaces) && !isUploadingDocument }));
    }
  }, [comment, dispatch, getValues, isUploadingDocument, startCommentValidation]);

  const taskHash = hash;
  const openTaskId = taskHash?.split('-')?.[1];
  const customTodoToMarkasDone = todo.id === String(openTaskId) ? todo : undefined;

  const shouldOpenMarkasDoneModal = Boolean(openTaskId) && customTodoToMarkasDone?.state !== TodoStates.DONE;

  const isTaskMounted = useRef(false);

  const isSubscriptionInDiscovery = isNewSolutionsRoute(pathname);
  const urlPath = AppUrl.getToolUrl(subscription as Subscription, isSubscriptionInDiscovery);

  const openCustomTaskModal =
    TodoCategories.USER_CUSTOM_TODO &&
    hash &&
    shouldOpenMarkasDoneModal &&
    !isTaskMounted.current &&
    customTodoToMarkasDone;

  useEffect(() => {
    if (openCustomTaskModal) {
      showModal?.({
        cancelButtonText: t('common:modals.mark_task_as_done_feature_modal.action_button_section.cancel_button_text'),
        children: (
          <MarkTaskAsDoneForm
            ref={markTaskasDoneRef}
            showNotification={showNotification}
            closeModal={closeModal}
            subscription={subscription}
            task={customTodoToMarkasDone}
          />
        ),
        confirmButtonText: t('common:modals.mark_task_as_done_feature_modal.action_button_section.confirm_button_text'),
        onCancelButtonClick: () => closeModal?.(),
        onConfirmButtonClick: () => markTaskasDoneRef.current?.onSubmit(),
      });

      isTaskMounted.current = true;
      history.replace(`${urlPath}${TASK_HASH}`);
    }
  }, [
    closeModal,
    showModal,
    showNotification,
    customTodoToMarkasDone,
    history,
    openCustomTaskModal,
    subscription,
    t,
    urlPath,
  ]);

  type RenderSavingsSuggestionActions = {
    actOnIt: () => void;
    decline: () => void;
  };

  const { isLoading: isSubmitting, mutate: mutateSubscriptionComment } = useMutation(addSubscriptionComment, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    onMutate: async (variables) => {
      if (variables.todoId && updateSubscriptionTodosCache) {
        const { subscriptionId, todoId, todoState: state } = variables;
        updateSubscriptionTodo({
          state,
          subscriptionId,
          todoId,
        });
        updateSubscriptionTodosCache(todoId);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('subscriptionTodos');
      setTodoComment('');
      setIsOpenTodoCommentView(false);
    },
    onSuccess: () => {
      showNotification?.('Savings suggestion response updated successfully.', NotificationAlertType.Success);
      queryClient.invalidateQueries('subscription-history');
      queryClient.invalidateQueries('auditLogs');
      queryClient.invalidateQueries(['subscription', subscriptionId]);
      queryClient.invalidateQueries('subscriptions');
      queryClient.invalidateQueries('subscriptions-todos');
    },
  });

  const { isLoading: isUpdatingSubscription, mutate: mutateUpdateSubscription } = useMutation(updateSubscription, {
    onError: () => {
      showNotification?.(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
    },
  });

  const { mutate: mutateDeleteTask } = useMutation(deleteSubscriptionTodo, {
    onError: () => {
      showNotification?.(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
    },
    onSettled: () => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    },
    onSuccess: () => {
      showNotification?.(
        t('common:modals.add_custom_task_feature_modal.delete_success_message'),
        NotificationAlertType.Success
      );
      queryClient.invalidateQueries('subscriptionTodos');
      queryClient.invalidateQueries('subscriptions-todos');
      queryClient.invalidateQueries(['subscription', subscriptionId]);
      sendTaskDeletedEvent({ subscriptionName: getSubscriptionName(subscription as Subscription) as string });

      closeModal?.();
    },
  });

  const { mutate: createUserMutation } = useMutation(createUser, {
    onError: (error: InviteUserError) => {
      if (error && error.response.status === 400) {
        const email = JSON.parse(String(error.response.config?.data || ''));
        const message = error.response.data?.error;
        const errorMessage = `${message} (${email?.email})`;
        showNotification?.(errorMessage, NotificationAlertType.Warning);
      } else {
        showNotification?.(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
      }
    },
    onSettled: () => setIsUpdatingToolOwner(false),
    onSuccess: (result) => {
      queryClient.invalidateQueries('tool-owners');
      queryClient.invalidateQueries('subscription-history');
      queryClient.invalidateQueries('auditLogs');
      queryClient.invalidateQueries('subscriptionTodos');
      queryClient.invalidateQueries(['subscription', subscription?.id]);
      const ownerName = result.name;
      const vendorName = subscription.name || subscription.vendorName;

      const message = t('common:assign_tool_owner_feature.dialog_component.message', {
        ownerName,
        vendorName,
      });

      setDialogMessage(message);
      setOpenDialog(true);
    },
  });

  const openUploadLatestInvoiceHelpTour = () => {
    const helpHero = initHelpHero('CwyetCfxt8Z');
    helpHero.anonymous();
    helpHero.startTour('WBMMrrAiOe');
  };

  const renderSavingsSuggestionActions = ({ actOnIt, decline }: RenderSavingsSuggestionActions) => {
    const handleOnSubmitComment = savingSuggestionsActions === 'act_on_it' ? actOnIt : decline;
    const placeholderText =
      savingSuggestionsActions === 'act_on_it'
        ? t(`${pathToTranslation}.savings_suggestion.act_on_it_placeholder_text`)
        : t(`${pathToTranslation}.savings_suggestion.decline_placeholder_text`);

    return (
      <Box fontSize='.7rem' data-testid='savings-suggestion-input'>
        <Input
          type='text'
          value={String(todoComment)}
          rows={4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTodoComment(e.target.value)}
          placeholder={placeholderText}
          multiline
          className='text-area'
        />
        <Box display='flex' width='100%' justifyContent='end' mt='1rem'>
          <Button
            onClick={handleOnSubmitComment}
            variant='outlined'
            color='primary'
            size='small'
            data-testid='savings-suggestion-submit-button'
            disabled={isSubmitting}>
            {t(`${pathToTranslation}.savings_suggestion.add_comment_button_text`)}
          </Button>
        </Box>
      </Box>
    );
  };

  const getRenewalDateTodoDetails = () => {
    if (
      (subscription.billingFrequency === null || subscription.billingFrequency === undefined) &&
      subscription.state !== SubscriptionStatus.expired
    ) {
      return {
        actionButtonText: getTaskActionTranslationText(updateRenewalPath, TaskTranslationKey.ACTION_TEXT),
        description: getTaskDetailsTranslationText(updateRenewalPath, TaskTranslationKey.DESCRIPTION_TEXT),
        title: getTaskDetailsTranslationText(updateRenewalPath, TaskTranslationKey.TITLE_TEXT),
      };
    }
    if (todo.state === TodoStates.DONE) {
      return {
        actionButtonText: getTaskActionTranslationText(updateRenewalPath, TaskTranslationKey.ACTION_TEXT),
        description: getTaskDetailsTranslationText(updateRenewalPath, TaskTranslationKey.DESCRIPTION_TEXT),
        title: getTaskDetailsTranslationText(updateRenewalPath, TaskTranslationKey.TITLE_TEXT),
      };
    }
    return {
      actionButtonText: getTaskActionTranslationText(updateRenewalPath, TaskTranslationKey.ACTION_TEXT),
      description: getTaskDetailsTranslationText(updateRenewalPath, TaskTranslationKey.DESCRIPTION_TEXT),
      title: getTaskDetailsTranslationText(updateRenewalPath, TaskTranslationKey.TITLE_TEXT),
    };
  };

  const getAutoExtendedRenewalDateTodoDetails = () => {
    if (
      (subscription.billingFrequency !== null ||
        subscription.billingFrequency !== undefined ||
        subscription.renewalDate) &&
      subscription.state === SubscriptionStatus.expired &&
      todo.category === TodoCategories.AUTO_EXTENDED_RENEWAL_DATE &&
      todo.state === TodoStates.OPEN
    ) {
      return {
        actionButtonText: getTaskActionTranslationText(expiredRenewalDatePath, TaskTranslationKey.ACTION_TEXT),
        description: getTaskDetailsTranslationText(expiredRenewalDatePath, TaskTranslationKey.DESCRIPTION_TEXT),
        title: getTaskDetailsTranslationText(expiredRenewalDatePath, TaskTranslationKey.TITLE_TEXT),
      };
    }
    return {
      actionButtonText: getTaskActionTranslationText(verifyRenewalDatePath, TaskTranslationKey.ACTION_TEXT),
      description: getTaskDetailsTranslationText(verifyRenewalDatePath, TaskTranslationKey.DESCRIPTION_TEXT),
      title: getTaskDetailsTranslationText(verifyRenewalDatePath, TaskTranslationKey.TITLE_TEXT),
    };
  };

  const onHandleSavingsSuggestionTodo = (todoId: string, state: number) => {
    if (todoComment) {
      const data: AddSubscriptionCommentModel = {
        comment: todoComment,
        subscriptionId: subscription?.id,
        todoId,
        todoState: state,
      };

      mutateSubscriptionComment(data);
    }
  };

  const vendor = subscription?.name || subscription?.vendorName;

  const moveSubscriptionToArchive = async (subscription: Subscription) => {
    const clonedSubscription = {
      ...subscription,
      state: SubscriptionStatus.inactive,
    };

    dispatch(updateFooterState({ isFormSubmitting: true }));

    const uploadedDocuments = queryClient.getQueryData<CommentDocument[]>('uploaded-documents');
    let markDownComment: string = getValues('comment') as string;

    const generatedCommentDomFromString = new DOMParser().parseFromString(markDownComment, 'text/html');
    const htmlMentions = generatedCommentDomFromString.querySelectorAll('[data-denotation-char="@"]');

    markDownComment = await getMarkdownFromComment(markDownComment, uploadedDocuments || [], htmlMentions);

    const newComment = `### Subscription was changed from ${t(
      getSubscriptionStatus(subscription?.state).status
    )} to ${t(getSubscriptionStatus(SubscriptionStatus.inactive).status)}\n${markDownComment}`;

    const commentData: AddSubscriptionCommentModel = {
      comment: newComment,
      subscriptionId: String(subscription?.id),
    };

    mutateUpdateSubscription(clonedSubscription as Subscription, {
      onSettled: () => {
        closeModal?.();
        dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
      },
      onSuccess: async () => {
        queryClient.invalidateQueries(['subscription', subscription?.id]);
        queryClient.invalidateQueries('subscriptionTodos');
        await addSubscriptionComment(commentData);
      },
    });
  };

  const initialModalProps: ModalComponentProps = {
    cancelButtonText: t(
      'subscription_detail_view:subscription_detail_sidebar_section.renewal_date_section.move_to_archive_modal.action_buttons.cancel_button_text'
    ),
    confirmButtonText: t(
      'subscription_detail_view:subscription_detail_sidebar_section.renewal_date_section.move_to_archive_modal.action_buttons.confirm_button_text'
    ),
    onCancelButtonClick: () => closeModal?.(),
    title: t(
      'subscription_detail_view:subscription_detail_sidebar_section.renewal_date_section.move_to_archive_modal.title_text'
    ),
  };

  const openMoveToArchiveModal = (status: number) => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    setStartCommentValidation(true);

    showModal?.({
      ...initialModalProps,
      children: (
        <>
          <div className='mb-4'>
            <ReactMarkdown>
              {t(
                'subscription_detail_view:subscription_detail_sidebar_section.renewal_date_section.move_to_archive_modal.modal_body_section.main_body_text',
                {
                  status: t(getSubscriptionStatus(status).status),
                  subscription: subscription?.name || subscription?.vendorName,
                }
              )}
            </ReactMarkdown>
          </div>
          <div className='mb-4'>
            {t(
              'subscription_detail_view:subscription_detail_sidebar_section.renewal_date_section.move_to_archive_modal.modal_body_section.sub_body_text'
            )}
          </div>
          <Controller
            name='comment'
            control={control}
            render={({ field: { onChange, value } }) => (
              <WysiwygEditorComponent
                showNotification={showNotification}
                value={value || ''}
                subscriptionId={subscription ? subscription.id : ''}
                placeholder={t(
                  'subscription_detail_view:subscription_detail_header_section.subscription_status_modal.modal_body_section.textarea_placeholder_text'
                )}
                onChange={onChange}
                setIsUploadingDocument={setIsUploadingDocument}
              />
            )}
          />
        </>
      ),
      onCancelButtonClick: () => {
        queryClient.resetQueries('uploaded-documents');
        closeModal?.();
        setStartCommentValidation(false);
      },
      onConfirmButtonClick: () => {
        setStartCommentValidation(false);
        moveSubscriptionToArchive(subscription);
      },
    });
  };

  const renewalDateTodoDetails = getRenewalDateTodoDetails();
  const autoExtendedRenewalDateTodoDetails = getAutoExtendedRenewalDateTodoDetails();

  const tasksProps = {
    assignOwner: {
      dataTestId: 'assign-owner',
      description: getTaskDetailsTranslationText(assignOwnerPath, TaskTranslationKey.DESCRIPTION_TEXT),
      getActions: {
        assignToolOwner: () => setOpenAssignOwnerInput(true),
      },
      title: getTaskDetailsTranslationText(assignOwnerPath, TaskTranslationKey.TITLE_TEXT),
    },
    customTodo: {
      dataTestId: 'custom-todo',
      description: todo.message,
      getActions: {
        markAsDone: () => markTodoAsDone?.(subscriptionId, todoId),
      },
      title: todo.title,
    },
    customerCustomTask: {
      dataTestId: 'customer-custom-task',
      description: todo.message,
      getActions: {
        markAsDone: () => {
          showModal?.({
            cancelButtonText: t(
              'common:modals.mark_task_as_done_feature_modal.action_button_section.cancel_button_text'
            ),
            children: (
              <MarkTaskAsDoneForm
                ref={markTaskasDoneRef}
                showNotification={showNotification}
                closeModal={closeModal}
                subscription={subscription}
                task={todo}
              />
            ),
            confirmButtonText: t(
              'common:modals.mark_task_as_done_feature_modal.action_button_section.confirm_button_text'
            ),
            onCancelButtonClick: () => closeModal?.(),
            onConfirmButtonClick: () => markTaskasDoneRef.current?.onSubmit(),
          });
        },
      },
      title: todo.title,
    },
    onboardingQuestionnaire: {
      dataTestId: 'onboarding-questionnaire',
      description: getTaskDetailsTranslationText(onboardingQuestionnairePath, TaskTranslationKey.DESCRIPTION_TEXT),
      getActions: {},
      title: getTaskDetailsTranslationText(onboardingQuestionnairePath, TaskTranslationKey.TITLE_TEXT),
    },
    renewalQuestionnaire: {
      dataTestId: 'renewal-questionnaire',
      description: getTaskDetailsTranslationText(renewalQuestionnairePath, TaskTranslationKey.DESCRIPTION_TEXT),
      getActions: {},
      title: getTaskDetailsTranslationText(renewalQuestionnairePath, TaskTranslationKey.TITLE_TEXT),
    },
    savingsSuggestion: {
      dataTestId: 'savings-suggestion',
      description: todo.message,
      getActions: {
        actOnIt: () => onHandleSavingsSuggestionTodo(todoId, TodoStates.IN_PROGRESS),
        decline: () => onHandleSavingsSuggestionTodo(todoId, TodoStates.DONE),
      },
      title: getTaskDetailsTranslationText(savingSuggestionsPath, TaskTranslationKey.TITLE_TEXT),
    },
    updateRenewalDate: {
      dataTestId: 'update-renewal-date',
      description: renewalDateTodoDetails.description,
      getActions: {
        moveToArchive: () => openMoveToArchiveModal(Number(subscription.state)),
        setDate: () => onUpdateRenewalDate?.(todoId),
      },
      title: renewalDateTodoDetails.title,
    },
    uploadLatestInvoice: {
      dataTestId: 'upload-latest-invoice',
      description: getTaskDetailsTranslationText(uploadLatestInvoicePath, TaskTranslationKey.DESCRIPTION_TEXT, vendor),
      getActions: {
        goToSpend: () => {
          history.push({
            hash: '#showSpends',
          });
          history.location.state = { todoId, type: 'upload-latest-invoice' };
        },
        howItWork: () => openUploadLatestInvoiceHelpTour(),
      },
      title: getTaskDetailsTranslationText(uploadLatestInvoicePath, TaskTranslationKey.TITLE_TEXT),
    },
    verifyRenewalDate: {
      dataTestId: 'verify-renewal-date',
      description: autoExtendedRenewalDateTodoDetails.description,
      getActions: {
        moveToArchive: () => openMoveToArchiveModal(Number(subscription.state)),
        setDate: () => onUpdateRenewalDate?.(todoId),
      },
      title: autoExtendedRenewalDateTodoDetails.title,
    },
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getTaskToBeDisplayed = (todoCategory: number) => {
    switch (todoCategory) {
      case TodoCategories.RENEWAL_DATE:
        return tasksProps.updateRenewalDate;
      case TodoCategories.UPLOAD_INVOICE:
        return tasksProps.uploadLatestInvoice;
      case TodoCategories.CUSTOM_TODO:
        return tasksProps.customTodo;
      case TodoCategories.ONBOARDING_QUESTIONNAIRE:
        return tasksProps.onboardingQuestionnaire;
      case TodoCategories.ASSIGN_TOOL_OWNER:
        return tasksProps.assignOwner;
      case TodoCategories.SAVINGS_SUGGESTION:
        return tasksProps.savingsSuggestion;
      case TodoCategories.RENEWAL_QUESTIONNAIRE:
        return tasksProps.renewalQuestionnaire;
      case TodoCategories.USER_CUSTOM_TODO:
        return tasksProps.customerCustomTask;
      case TodoCategories.AUTO_EXTENDED_RENEWAL_DATE:
        return tasksProps.verifyRenewalDate;
      default:
        return undefined;
    }
  };

  const taskToBeDisplayed = getTaskToBeDisplayed(todo.category);

  const getActionsButton: GetActionsButton = (category, actions) => {
    const getButtonTextAndFunction = () => {
      switch (category) {
        case TodoCategories.RENEWAL_DATE:
          return {
            actionOnClick: actions?.setDate,
            actionText: renewalDateTodoDetails.actionButtonText,
            subActionOnClick: actions?.moveToArchive,
            subActionText: 'Move to Archive',
            type: TodoCategories.RENEWAL_DATE,
            url: undefined,
          };
        case TodoCategories.AUTO_EXTENDED_RENEWAL_DATE:
          return {
            actionOnClick: actions?.setDate,
            actionText: autoExtendedRenewalDateTodoDetails.actionButtonText,
            subActionOnClick: actions?.moveToArchive,
            subActionText: 'Move to Archive',
            type: TodoCategories.AUTO_EXTENDED_RENEWAL_DATE,
            url: undefined,
          };
        case TodoCategories.USER_CUSTOM_TODO:
          return {
            actionOnClick: actions?.markAsDone,
            actionText: getTaskActionTranslationText(customTaskPath, TaskTranslationKey.ACTION_TEXT),
            subActionOnClick: undefined,
            subActionText: undefined,
            type: TodoCategories.USER_CUSTOM_TODO,
            url: undefined,
          };
        case TodoCategories.UPLOAD_INVOICE:
          return {
            actionOnClick: actions?.goToSpend,
            actionText: getTaskActionTranslationText(uploadLatestInvoicePath, TaskTranslationKey.ACTION_TEXT),
            subActionOnClick: actions?.howItWork,
            subActionText: getTaskActionTranslationText(uploadLatestInvoicePath, TaskTranslationKey.SUB_ACTION_TEXT),
            type: TodoCategories.UPLOAD_INVOICE,
            url: undefined,
          };
        case TodoCategories.ONBOARDING_QUESTIONNAIRE:
          return {
            actionOnClick: undefined,
            actionText: getTaskActionTranslationText(onboardingQuestionnairePath, TaskTranslationKey.ACTION_TEXT),
            subActionOnClick: undefined,
            subActionText: undefined,
            type: TodoCategories.ONBOARDING_QUESTIONNAIRE,
            url: todo.buttonLink,
          };
        case TodoCategories.RENEWAL_QUESTIONNAIRE:
          return {
            actionOnClick: undefined,
            actionText: getTaskActionTranslationText(renewalQuestionnairePath, TaskTranslationKey.ACTION_TEXT),
            subActionOnClick: undefined,
            subActionText: undefined,
            type: TodoCategories.RENEWAL_QUESTIONNAIRE,
            url: todo.buttonLink,
          };
        case TodoCategories.CUSTOM_TODO:
          return {
            actionOnClick: undefined,
            actionText: todo.buttonLabel,
            subActionOnClick: actions?.markAsDone,
            subActionText: getTaskActionTranslationText(customTodoPath, TaskTranslationKey.SUB_ACTION_TEXT),
            type: TodoCategories.CUSTOM_TODO,
            url: todo.buttonLink,
          };
        case TodoCategories.ASSIGN_TOOL_OWNER:
          return {
            actionOnClick: actions?.assignToolOwner,
            actionText: getTaskActionTranslationText(assignOwnerPath, TaskTranslationKey.ACTION_TEXT),
            subActionOnClick: undefined,
            subActionText: undefined,
            type: TodoCategories.ASSIGN_TOOL_OWNER,
            url: undefined,
          };
        case TodoCategories.SAVINGS_SUGGESTION:
          return {
            actionOnClick: actions?.actOnIt,
            actionText: getTaskActionTranslationText(savingSuggestionsPath, TaskTranslationKey.ACTION_TEXT),
            subActionOnClick: actions?.decline,
            subActionText: getTaskActionTranslationText(savingSuggestionsPath, TaskTranslationKey.SUB_ACTION_TEXT),
            type: TodoCategories.SAVINGS_SUGGESTION,
            url: undefined,
          };
        default:
          return undefined;
      }
    };

    const buttonTextAndFunctions = getButtonTextAndFunction();

    const shouldShowSubActionButton =
      (buttonTextAndFunctions?.type !== TodoCategories.ASSIGN_TOOL_OWNER &&
        subscription?.billingFrequency !== null &&
        subscription?.billingFrequency !== undefined &&
        buttonTextAndFunctions?.type === TodoCategories.RENEWAL_DATE &&
        !isUpdatingSubscription) ||
      buttonTextAndFunctions?.type === TodoCategories.CUSTOM_TODO ||
      buttonTextAndFunctions?.type === TodoCategories.UPLOAD_INVOICE;

    const onConfirmToolOwnerChange = async (updatedToolOwner?: ToolOwner): Promise<void> => {
      const { toolOwnerProps } = state;
      const vendor = subscription?.vendorName || subscription?.name;
      const isInviteToolOwnerStep = updatedToolOwner?.step === ToolOwnershipStep.Invite;
      const isUnassignToolOwnerStep = updatedToolOwner?.step === ToolOwnershipStep.Unassign;

      if (isInviteToolOwnerStep || isUnassignToolOwnerStep) dispatch(updateFooterState({ isFormSubmitting: true }));

      if (subscription) {
        let { id: userId } = toolOwnerProps.owner;
        const { email, name } = toolOwnerProps.owner;
        const userFormValues = inviteUserFeatureRef.current?.getFormValues() as InviteUserFormValues;
        const ownerName = isInviteToolOwnerStep ? userFormValues.name : name;
        const ownerEmail = isInviteToolOwnerStep ? userFormValues.email : email;
        const ownerTools = userFormValues?.ownerOfSubscriptionIds || [];

        try {
          if (isInviteToolOwnerStep) {
            userId = (await createUser({ email: ownerEmail, name: ownerName, ownerOfSubscriptionIds: ownerTools })).id;
          }

          const updatedSubscription = await updateSubscriptionToolOwner(
            subscription,
            userId,
            toolOwnerProps.owner.name
          );

          queryClient.setQueryData(['subscription', subscriptionId], updatedSubscription);

          let message = '';

          if (
            ownerName &&
            userFormValues?.ownerOfSubscriptionIds &&
            userFormValues?.ownerOfSubscriptionIds?.length > 1
          ) {
            message = t('common:assign_tool_owner_feature.assign_tool_owners_success_message', {
              ownerName,
              toolsCount: userFormValues?.ownerOfSubscriptionIds?.length - 1,
              vendorName: vendor,
            });
          } else if (ownerName) {
            message = `${ownerName} successfully assigned as tool owner of ${vendor}.`;
            message = t('common:assign_tool_owner_feature.assign_tool_owner_success_message', {
              ownerName,
              vendorName: vendor,
            });
          } else {
            message = t('common:assign_tool_owner_feature.unassign_tool_owner_success_message', {
              ownerName: subscription?.ownerName,
              vendorName: vendor,
            });
          }

          queryClient.invalidateQueries('tool-owners');
          queryClient.invalidateQueries('subscription-history');
          queryClient.invalidateQueries('auditLogs');
          queryClient.invalidateQueries('subscriptions');
          queryClient.invalidateQueries('subscriptions-todos');
          queryClient.invalidateQueries('subscriptionTodos');

          toolOwnerProps.step = ToolOwnershipStep.Add;
          setState({ ...state, toolOwnerProps });

          inviteUserFeatureRef.current?.resetForm();
          showNotification?.(message, NotificationAlertType.Success);
          closeModal?.();
        } catch (error) {
          if (Object(error)?.response?.data?.error) {
            const responseMessage = Object(error).response.data.error;
            showNotification?.(responseMessage, NotificationAlertType.Warning);
          } else {
            const message = `${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`;
            showNotification?.(message, NotificationAlertType.Error);
          }
        } finally {
          setIsUpdatingToolOwner(false);
          dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
        }
      }
    };

    const setAndUpdateToolOwner = (toolOwner: User) => {
      const clonedToolOwnerProps = state.toolOwnerProps;
      clonedToolOwnerProps.subscriptionId = subscription.id;
      clonedToolOwnerProps.owner = { ...toolOwner };
      clonedToolOwnerProps.vendor = subscription?.vendorName || '';
      clonedToolOwnerProps.step = ToolOwnershipStep.Add;

      setIsUpdatingToolOwner(true);

      setState({ ...state, toolOwnerProps: clonedToolOwnerProps });
      onConfirmToolOwnerChange();
    };

    const handleToolOwnerSelection = (selectedOwner: User | string) => {
      const user = selectedOwner as User;
      const isGoogleDirectoryUser = user?.familyName && user?.givenName;

      if (typeof selectedOwner !== 'string' && !isGoogleDirectoryUser) {
        setAndUpdateToolOwner(selectedOwner);
      } else if (isGoogleDirectoryUser) {
        setIsUpdatingToolOwner(true);
        const inviteUserData: CreateUserData = {
          email: String(user.email),
          name: String(user.name),
          ownerOfSubscriptionIds: [subscription.id],
          role: UserRoles.Contributor,
        };
        createUserMutation(inviteUserData);
      } else {
        onInviteUserRequested(selectedOwner as string);
      }

      if (user.id) {
        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.ToolOwnerAssigned, {
          name: 'toolOwnerName',
          value: String(user.name),
        });
      } else {
        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.ToolOwnerNameAssigned, {
          name: 'toolOwnerName',
          value: String(selectedOwner),
        });
      }
    };

    const onInviteUserRequested = (selectedOwner?: string): void => {
      const name = String(selectedOwner);

      const inviteUserData: InviteUserInitialValue = {
        name,
        tool: subscription,
      };

      openInviteUserDrawer(DrawerInfoType.USER_INVITE_SUBSCRIPTION, inviteUserData);
    };

    const handleOnHideVisibility = () => {
      setOpenAssignOwnerInput(false);
    };

    const handleOpenTodoComponentView = (buttonType: 'act_on_it' | 'decline') => {
      setIsOpenTodoCommentView(true);
      setSavingSuggestionsActions(buttonType);
    };

    const actionButtonOnClick = () => {
      if (buttonTextAndFunctions?.type === TodoCategories.SAVINGS_SUGGESTION) {
        handleOpenTodoComponentView('act_on_it');
      } else {
        buttonTextAndFunctions?.actionOnClick?.();
      }
    };

    const subActionButtonOnClick = () => {
      if (buttonTextAndFunctions?.type === TodoCategories.SAVINGS_SUGGESTION) handleOpenTodoComponentView('decline');
      else buttonTextAndFunctions?.subActionOnClick?.();
    };

    const isActionButton =
      (buttonTextAndFunctions?.type === TodoCategories.CUSTOM_TODO ||
        buttonTextAndFunctions?.type === TodoCategories.ONBOARDING_QUESTIONNAIRE ||
        buttonTextAndFunctions?.type === TodoCategories.RENEWAL_QUESTIONNAIRE) &&
      !isUpdatingToolOwner;

    const handleQuestionnaireCLick = (type?: TodoCategories) => {
      const { ONBOARDING_QUESTIONNAIRE, RENEWAL_QUESTIONNAIRE } = TodoCategories;
      if (type === ONBOARDING_QUESTIONNAIRE) {
        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.OnboardingQuestionnaireLinkClicked);
      } else if (type === RENEWAL_QUESTIONNAIRE) {
        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.RenewalQuestionnaireLinkClicked);
      }
    };

    return (
      <Box display='flex' width='100%'>
        <Grid container spacing={1} justifyContent='flex-start' alignItems='center'>
          <Grid item>
            {shouldShowSubActionButton && (
              <Box
                data-testid={`${taskToBeDisplayed?.dataTestId}-sub-action-button`}
                color={colors.rollingStone}
                fontSize='0.75rem'
                fontWeight='500'
                lineHeight='1.013rem'
                fontStyle='italic'
                onClick={subActionButtonOnClick}>
                <a
                  href={
                    buttonTextAndFunctions?.type === TodoCategories.UPLOAD_INVOICE
                      ? buttonTextAndFunctions?.url
                      : undefined
                  }
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    color: colors.rollingStone,
                    textDecoration: 'underline',
                  }}>
                  {buttonTextAndFunctions?.subActionText}
                </a>
              </Box>
            )}
            {isUpdatingSubscription && (
              <Box display='flex' alignItems='center' justifyContent='center' width='5rem'>
                <CircularProgress size={20} />
              </Box>
            )}
          </Grid>
          <Grid item>
            {openAssignOwnerInput ? (
              <AssignToolOwnerFeature
                subscription={subscription}
                handleOnHideVisibility={handleOnHideVisibility}
                handleToolOwnerSelection={handleToolOwnerSelection}
                toolOwnerName={toolOwnerName}
                setToolOwnerName={setToolOwnerName}
                isUpdatingToolOwner={isUpdatingToolOwner}
                isEditingToolOwner={openAssignOwnerInput}
                setIsEditingToolOwner={setOpenAssignOwnerInput}
              />
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={actionButtonOnClick}
                size='small'
                data-testid={`${taskToBeDisplayed?.dataTestId}-action-button`}
                disabled={isUpdatingToolOwner}
                startIcon={<Icon>check</Icon>}>
                {isUpdatingToolOwner && <CircularProgress size={20} />}
                {isActionButton ? (
                  <a
                    href={buttonTextAndFunctions?.url}
                    onClick={() => handleQuestionnaireCLick(buttonTextAndFunctions?.type)}
                    target='_blank'
                    rel='noreferrer'
                    data-testid='button-link'
                    style={{
                      color: 'inherit',
                      textDecoration: 'none',
                    }}>
                    {buttonTextAndFunctions?.actionText}
                  </a>
                ) : (
                  !isUpdatingToolOwner && buttonTextAndFunctions?.actionText
                )}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const openUpdateCustomTask = (task?: Todo) => {
    showModal?.({
      cancelButtonText: t(`${pathToTranslation}.custom_task_section.modal.cancel_button_text`),
      children: (
        <CustomTaskForm
          ref={customTaskRef}
          showNotification={showNotification}
          closeModal={closeModal}
          subscription={subscription}
          initialData={task}
        />
      ),
      confirmButtonText: t(`${pathToTranslation}.custom_task_section.modal.update_button_text`),
      onCancelButtonClick: () => closeModal?.(),
      onConfirmButtonClick: () => customTaskRef.current?.onSubmit(),
      title: t(`${pathToTranslation}.custom_task_section.modal.update_title_text`),
    });
  };

  const openDeleteCustomTask = (task?: Todo) => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    showModal?.({
      cancelButtonText: t(`${pathToTranslation}.custom_task_section.modal.cancel_button_text`),
      children: <>{t(`${pathToTranslation}.custom_task_section.modal.delete_message`)}</>,
      confirmButtonColor: 'error',
      confirmButtonText: t(`${pathToTranslation}.custom_task_section.modal.delete_confirm_button_text`),
      onCancelButtonClick: () => closeModal?.(),
      onConfirmButtonClick: () => {
        dispatch(updateFooterState({ isFormSubmitting: true }));
        mutateDeleteTask({
          subscriptionId,
          todoId: task?.id as string,
        });
      },
      title: t(`${pathToTranslation}.custom_task_section.modal.delete_task_title_text`),
    });
  };

  const isSastrifyTask = [
    TodoCategories.UPLOAD_INVOICE,
    TodoCategories.SAVINGS_SUGGESTION,
    TodoCategories.CUSTOM_TODO,
    TodoCategories.ONBOARDING_QUESTIONNAIRE,
    TodoCategories.RENEWAL_QUESTIONNAIRE,
    TodoCategories.CUSTOM_TODO,
    TodoCategories.AUTO_EXTENDED_RENEWAL_DATE,
  ].includes(todo.category);
  const isSastrifyCovered = [TodoCategories.RENEWAL_DATE, TodoCategories.ASSIGN_TOOL_OWNER].includes(todo.category);

  const renderTaskTitle = () => {
    return (
      <div className='task-title-section'>
        <div className='pointer'>
          {isCompletedTodos ? (
            <CircleCheckIcon className='is-flex is-align-self-center' width='18' height='18' />
          ) : null}
        </div>
        <Box className={classnames(['task-title-text'], { completed: isCompletedTodos })}>
          {taskToBeDisplayed?.title}
        </Box>
        {isSastrifyCovered && (
            <Tooltip
              title={
                <Typography color='inherit'>{t(`${pathToTranslation}.covered_badge_tooltip_title`)}</Typography>
              }
              arrow
              placement='top'>
              <span data-testid='sastrify-badge' className='sastrify-badge' />
            </Tooltip>
          )}
      </div>
    );
  };

  const renderTaskDescription = () => {
    return (
      <>
        <div className='created-by created-by-wrapper' data-testid='task-created-by-section'>
          <Box className='created-by-title'>{t(`${pathToTranslation}.custom_task_section.created_by_text`)}:</Box>
          <UserNameAndAvatarComponent
            avatarUrl={todo?.createdByAvatarUrl || DEFAULT_SUPPORT_AGENT.avatar}
            name={
              todo?.createdByName
                ? `${todo?.createdByName} ${t(`${pathToTranslation}.custom_task_section.sastrify_text`)}`
                : DEFAULT_SUPPORT_AGENT.name
            }
            width='1.25rem'
            height='1.25rem'
            fontSize='0.813rem'
            classNames='user-avatar'
            border
          />
        </div>
        <p className='description-title-text'>
          {t(`${pathToTranslation}.custom_task_section.description_title_text`)}:
        </p>
        <div className='description-body-text'>
          <ReactMarkdown className='markdownreader-text'>{taskToBeDisplayed?.description || ''}</ReactMarkdown>
        </div>
      </>
    );
  };

  const getDropdownContent = () => {
    return (
      <DropdownComponent
        ref={actionMenuRef}
        hasPadding
        menuMinWidth='8.5rem'
        menuTriggerElement={
          <DropdownMenuIcon
            disableRipple
            data-testid='renewal-date-actions-icon'
            className='edit-renewal-icon'
            onClick={() => actionMenuRef.current?.handleToggle()}>
            <VerticalThreeDotsIcon width={20} height={20} />
          </DropdownMenuIcon>
        }>
        <Box className='actions-menu-dropdown'>
          <Box
            data-testid='edit-renewal-button'
            component={Button}
            className='dropdown-menu-text'
            width='100%'
            onClick={() => openUpdateCustomTask(todo)}>
            {t(`${pathToTranslation}.custom_task_section.dropdown_section.edit_button_text`)}
          </Box>
          <Box
            data-testid='edit-renewal-button'
            component={Button}
            className='delete-button dropdown-menu-text'
            color={colors.red}
            width='100%'
            onClick={() => openDeleteCustomTask(todo)}>
            {t(`${pathToTranslation}.custom_task_section.dropdown_section.delete_button_text`)}
          </Box>
        </Box>
      </DropdownComponent>
    );
  };

  const renderCustomerCustomTask = () => {
    const dueDate = todo?.dueDate ? formatDate(todo.dueDate as any) : undefined;
    const isTaskDue = new Date(`${todo?.dueDate}:`).getTime() < currentDate.getTime();

    return (
      <Box width='100%'>
        <div className='task-createdby-assignee-section'>
          <div className='created-by'>
            <Box className='created-by-title'>{t(`${pathToTranslation}.custom_task_section.created_by_text`)}:</Box>
            <UserNameAndAvatarComponent
              avatarUrl={todo?.createdByAvatarUrl}
              name={String(todo?.createdByName)}
              width='1.25rem'
              height='1.25rem'
              fontSize='0.813rem'
              classNames='user-avatar'
            />
          </div>
          <div className='assignee'>
            <Box className='assignee-title'>{t(`${pathToTranslation}.custom_task_section.assignee_text`)}:</Box>
            <UserNameAndAvatarComponent
              avatarUrl={todo?.assignedToAvatarUrl}
              name={
                todo?.assignedToName
                  ? todo.assignedToName
                  : t(`${pathToTranslation}.custom_task_section.not_assigned_text`)
              }
              width='1.25rem'
              height='1.25rem'
              fontSize='0.813rem'
              classNames='user-avatar'
            />
          </div>
          {dueDate && todo?.state !== TodoStates.DONE ? (
            <div className='due' data-testid='due-date'>
              <Box className='due-title'>{t(`${pathToTranslation}.custom_task_section.due_date_text`)}:</Box>
              <Box
                className={classnames('due-date', {
                  danger: isTaskDue,
                })}>
                {dueDate}
              </Box>
              {isTaskDue ? (
                <Tooltip
                  title={`${t(`${pathToTranslation}.custom_task_section.due_date_tooltip_text`)}`}
                  arrow
                  placement='top'>
                  <div className='calender-icon' data-testid='task-overdue' />
                </Tooltip>
              ) : null}
            </div>
          ) : null}
        </div>
        {taskToBeDisplayed?.description ? (
          <>
            <p className='description-title-text'>
              {t(
                `${pathToTranslation}.custom_task_section.${
                  !isCompletedTodos ? 'description_title_text' : 'completion_details'
                }`
              )}
              :
            </p>
            <div className='description-body-text'>
              <ReactMarkdown className='markdownreader-text'>{taskToBeDisplayed?.description || ''}</ReactMarkdown>
            </div>
          </>
        ) : null}
        {todo?.state === TodoStates.DONE && todo?.attachments?.length ? (
          <>
            <p className='attachment-text'>{t(`${pathToTranslation}.custom_task_section.attachment_title_text`)}:</p>
            {todo.attachments?.map((attachment) => (
              <a
                className='attachment-document-link'
                href={attachment.url}
                target='_blank'
                key={`${attachment.fileName}-${attachment.url}`}
                rel='noreferrer'>
                {attachment.fileName}
              </a>
            ))}
          </>
        ) : null}
      </Box>
    );
  };

  const renderTodos = () => {
    return (
      <SubscriptionTaskAccordion
        isCompleted={isCompletedTodos}
        defaultExpanded={(isSastrifyCovered || isSastrifyTask) && !isCompletedTodos}
        className={classnames({
          container: !isCompletedTodos,
        })}
        data-testid={`${isCompletedTodos ? 'completed' : 'open'}-${taskToBeDisplayed?.dataTestId}`}>
        <AccordionSummary
          sx={{ py: 1 }}
          expandIcon={<Icon>keyboard_arrow_down</Icon>}
          aria-controls='panel1a-content'
          id='panel1a-header'>
          {renderTaskTitle()}
        </AccordionSummary>
        <AccordionDetails>
          <Box width='100%'>
            <div className='task-content-wrapper'>
              {todo.category !== TodoCategories.USER_CUSTOM_TODO ? renderTaskDescription() : null}
              {todo.category === TodoCategories.USER_CUSTOM_TODO && isCompletedTodos
                ? renderCustomerCustomTask()
                : null}
            </div>
            <div>
              {!isCompletedTodos && !isOpenTodoCommentView
                ? getActionsButton(todo.category, taskToBeDisplayed?.getActions)
                : null}
              {!isCompletedTodos && isOpenTodoCommentView
                ? renderSavingsSuggestionActions(taskToBeDisplayed?.getActions as RenderSavingsSuggestionActions)
                : null}
            </div>
          </Box>
        </AccordionDetails>
      </SubscriptionTaskAccordion>
    );
  };

  if (todo.category === TodoCategories.USER_CUSTOM_TODO && todo?.state !== TodoStates.DONE) {
    return (
      <section className='subscription-task'>
        <div className='customer-custom-task' data-testid={`open-${taskToBeDisplayed?.dataTestId}`}>
          <div className='task-info'>
            <div className='task-body'>
              <div className='title-section'>
                {renderTaskTitle()}
                <div className='dropdown-section'>{showCustomTaskActions && getDropdownContent()}</div>
              </div>
              <div className='task-content-wrapper'>{renderCustomerCustomTask()}</div>
            </div>
          </div>
          {showCustomTaskActions && getActionsButton(todo.category, taskToBeDisplayed?.getActions)}
        </div>
      </section>
    );
  }

  return (
    <div className='subscription-task'>
      {renderTodos()}
      <DialogComponent
        open={openDialog}
        handleClose={handleCloseDialog}
        title={t('common:assign_tool_owner_feature.dialog_component.title_text')}
        message={dialogMessage}
        icon={<SuccessIcon />}
      />
    </div>
  );
};
