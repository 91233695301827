import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SubscriptionInitiativeBannerWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  maxWidth: theme.spacing(127.75),
  '@media (max-width: 1758px)': {
    maxWidth: `calc(100vw - ${theme.spacing(91.75)})`,
  },
}));

export const SubscriptionInitiativeBannerHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const NewInitiativeButton = styled(Button)(({ theme }) => ({
  marginRight: `-${theme.spacing(1)}`,
  marginTop: `-${theme.spacing(1)}`,
}));

export const SubscriptionInitiativeBannerDescription = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(15),
}));
