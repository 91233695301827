import { ConnectedSystemEntityMatchDto } from 'libs/dtos';
import { SastrifyToolEntityType } from 'libs/enums';

export interface ToolInSastrify {
  id: number | null;
  name: string;
  entityType?: SastrifyToolEntityType;
}

export type ToolMatchingRecord = Omit<ConnectedSystemEntityMatchDto, 'toolInSastrify'> & {
  toolInSastrify?: ToolInSastrify | null;
  toolInSastrifyPreviousValue?: ToolInSastrify | null;
  toolInSastrifyOriginalValue?: ToolInSastrify | null;
};

export type ToolMatchingRecords = {
  matched: ToolMatchingRecord[];
  unmatched: ToolMatchingRecord[];
  ignored: ToolMatchingRecord[];
};

export enum ToolMatchingStatusLabels {
  Matched = 'matched',
  Unmatched = 'unmatched',
  Ignored = 'ignored',
}

export enum ToolMatchingStatusValues {
  Matched = 2,
  Unmatched = 1,
  Ignored = 3,
}

export const ToolMatchingStatusMapping = {
  [ToolMatchingStatusValues.Matched]: ToolMatchingStatusLabels.Matched,
  [ToolMatchingStatusValues.Unmatched]: ToolMatchingStatusLabels.Unmatched,
  [ToolMatchingStatusValues.Ignored]: ToolMatchingStatusLabels.Ignored,
};

export enum ActionType {
  CONFIRM_BULK_UNMATCHED = 'confirm_bulk_unmatched',
  IGNORE_BULK_UNMATCHED = 'ignore_bulk_unmatched',
  IGNORE_BULK_MATCHED = 'ignore_bulk_matched',
  IGNORE_SINGLE_MATCHED = 'ignore_single_matched',
  RESTORE_BULK = 'restore_bulk',
  RESTORE_SINGLE = 'restore_single',
  REASSIGN = 'reassign',
}

export interface ConfirmBunkDialogParams {
  actionFn: () => Promise<void> | void;
  matchedRecordsCount: number;
  unmatchedRecordsCount: number;
}

export interface IgnoreBulkUnmatchedDialogParams {
  actionFn: () => Promise<void> | void;
  recordsCount: number;
}

export interface IgnoreSingleMatchedDialogParams {
  actionFn: () => Promise<void> | void;
  sourceName: string;
  sourceTool: string;
}

export interface IgnoreBulkMatchedDialogParams {
  actionFn: () => Promise<void> | void;
  recordsCount: number;
  sources: string[];
}

export interface RestoreSingleDialogParams {
  actionFn: () => Promise<void> | void;
  sourceTool: string;
}

export interface RestoreBulkDialogParams {
  actionFn: () => Promise<void> | void;
  recordsCount: number;
}

export interface ReassignmentDialogProps {
  actionFn: () => Promise<void> | void;
  newToolName: string;
  oldToolName: string;
  sourceName: string;
}

export enum ToolMatchingQuery {
  TOOL_MATCHING_RECORDS = 'tool-matching-records',
}
