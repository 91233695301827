import { Box, Checkbox, CircularProgress, ClickAwayListener, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Icon } from 'asteroids';
import { ListboxComponent } from 'components/listbox';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { NewSolutionStates, SubscriptionStates } from 'shared/common.definitions';
import { getSubscriptionName } from 'shared/logic/subscription-item.logic';
import { sortSubscriptions } from 'shared/logic/subscriptions.logic';
import { Subscription } from 'shared/models';
import { colors } from 'shared/theme';

import { SubscriptionSelectInputComponentProps } from '.';
import { SubscriptionSelectInputWrapper } from './subscription-select-input.styles';

const stringifyOption = (subscription: Subscription): string => `${subscription?.name} ${subscription?.vendorName}`;

export const SubscriptionSelectInputComponent: FC<SubscriptionSelectInputComponentProps> = ({
  accessToAllTools,
  clearValueOnUnmount = false,
  dataTestId,
  defaultData,
  disableItems = false,
  disableSelectedTools = false,
  disabled = false,
  getValues,
  handleUpdate,
  hideCheckBox = false,
  multiple = true,
  placeholder,
  selectedTools,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [value, setValue] = React.useState<Array<Subscription>>([]);
  const [pendingValue, setPendingValue] = React.useState<Array<Subscription>>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const hasSetSelectedTools = useRef<boolean>(false);

  const queryClient = useQueryClient();

  const state = queryClient.getQueryState('subscriptions-and-new-solutions');

  const tools = useMemo(() => defaultData || (state?.data as Subscription[]) || [], [defaultData, state]);

  const isLoading = state?.data === undefined;

  const sortedTools = sortSubscriptions(tools, { sortById: '5' }) || [];

  // reset input value when, the input is disabled
  useEffect(() => {
    if (disabled) {
      setValue([]);
      setPendingValue([]);
    }
  }, [disabled]);

  useEffect(() => {
    if (selectedTools && !hasSetSelectedTools.current && tools.length) {
      const settedSubscriptions = tools.filter((subscription) =>
        selectedTools?.includes(subscription.id)
      ) as Subscription[];

      setValue(settedSubscriptions);
      setPendingValue(settedSubscriptions);

      hasSetSelectedTools.current = true;
    }
  }, [tools, selectedTools]);

  // clean up effect to optionally reset input value
  useEffect(() => {
    return () => {
      if (clearValueOnUnmount) {
        setValue([]);
        setPendingValue([]);
      }
    };
  }, [clearValueOnUnmount]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }

    if (!open) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    } else {
      setOpen(false);
      setAnchorEl(undefined);
    }
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(undefined);
    setOpen(false);
  };

  const getSubscriptionType = (state: number) => {
    if (SubscriptionStates.includes(state)) {
      return `(${t('common:subscription_select_input_component.subscription_label_text')})`;
    }
    if (NewSolutionStates.includes(state)) {
      return `(${t('common:subscription_select_input_component.new_solution_label_text')})`;
    }
    return `(${t('common:subscription_select_input_component.subscription_label_text')})`;
  };

  const unCheckedIcon = <Icon>check_box_outline_blank</Icon>;
  const checkedIcon = <Icon>check_box</Icon>;

  const getOptionName = (subscription: Subscription) => {
    const name =
      !subscription?.name?.match(/^\W/) && subscription?.name ? subscription?.name : subscription?.vendorName;
    return name;
  };

  const handleOptionClick = (value: Subscription[]) => {
    if (handleUpdate) {
      const subscriptions = value.map((subscription) => ({
        id: subscription.id,
        name: subscription.name,
        vendorId: subscription.vendorId,
        vendorName: subscription.vendorName,
      }));

      handleUpdate(subscriptions);
    }

    if (multiple) {
      setPendingValue(value);
      setValue(value);
    } else {
      setPendingValue([value[value.length - 1]]);
      setValue([value[value.length - 1]]);
      getValues?.([value[value.length - 1]]);

      setAnchorEl(undefined);
      setOpen(false);
    }
  };

  // TODO needs refactoring cause it can be done in sortSubscriptions
  const sortTools = (sortedTools: Subscription[]) => {
    return sortedTools.sort((a, b) => {
      let ai = value.indexOf(a);
      let bi = value.indexOf(b);
      ai = ai === -1 ? value.length + sortedTools.indexOf(a) : ai;

      bi = bi === -1 ? value.length + sortedTools.indexOf(b) : bi;
      return ai - bi;
    });
  };

  const getSortedOptions = () => {
    if (multiple) {
      const unassignedTools = sortedTools.filter(
        (tool: Subscription) => !tool.isAssigned && !selectedTools?.includes(tool.id)
      );

      const assignedTools = sortedTools.filter(
        (tool: Subscription) => tool.isAssigned && !selectedTools?.includes(tool.id)
      );

      const activeTools = sortedTools.filter((tool: Subscription) => selectedTools?.includes(tool.id));

      const toolsList = [...sortTools(activeTools), ...sortTools(unassignedTools), ...sortTools(assignedTools)];

      return toolsList;
    }

    if (!multiple && pendingValue.length) return sortedTools?.filter((tool) => tool.id !== pendingValue[0]?.id);

    return sortedTools;
  };

  const renderInput = () => {
    if (pendingValue.length) {
      return (
        <Box display='flex' alignItems='center' color={colors.mirage} width='100%'>
          <p className='selected-items'>
            {pendingValue.length > 1 ? (
              <Trans
                i18nKey='common:subscription_select_input_component.selected_item_text'
                values={{ value: pendingValue.length }}
                components={[<span className='chip'>{pendingValue.length}</span>]}
              />
            ) : (
              getOptionName(pendingValue[0])
            )}
          </p>
        </Box>
      );
    }

    if (disabled && accessToAllTools) {
      return <p>{t('common:subscription_select_input_component.all_tools_text')}</p>;
    }

    return placeholder || <p>{t('common:subscription_select_input_component.tools_placeholder_text')}</p>;
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <SubscriptionSelectInputWrapper position='relative'>
        <div className='root'>
          <Box
            justifyContent='space-between'
            display='flex'
            className='button'
            aria-describedby='subscription-select-input'
            fontSize='0.875rem'
            color={disabled ? colors.silver : colors.dustyGray}
            onClick={handleClick}
            data-testid={dataTestId}
            alignItems='center'>
            {renderInput()}
            {isLoading ? (
              <CircularProgress color='inherit' size={20} />
            ) : (
              !disabled && <Icon>{open ? 'expand_less' : 'expand_more'}</Icon>
            )}
          </Box>
        </div>
        {open && (
          <div className='popper'>
            <Autocomplete
              open
              filterOptions={createFilterOptions({
                ignoreAccents: true,
                ignoreCase: true,
                matchFrom: 'any',
                stringify: (option: Subscription) => stringifyOption(option),
                trim: true,
              })}
              multiple
              ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
              classes={{
                option: 'option',
                paper: 'paper',
                popperDisablePortal: 'popperDisablePortal',
              }}
              loading={isLoading}
              value={pendingValue}
              onChange={(event, newValue) => {
                handleOptionClick(newValue);
              }}
              disableListWrap
              disableCloseOnSelect
              disablePortal
              renderTags={() => null}
              noOptionsText={t('common:subscription_select_input_component.no_options_text')}
              getOptionDisabled={(option) => {
                if (disableSelectedTools) {
                  return Boolean(selectedTools?.includes(option.id));
                }

                return disableItems && Boolean(option?.isAssigned) && !selectedTools?.includes(option.id);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box
                    data-testid='tools-option'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'>
                    <Box display='flex' className='avatar'>
                      <Box mr='.5rem'>
                        {!option?.vendorLogoUrl ? (
                          getSubscriptionName(option)?.slice(0, 2)
                        ) : (
                          <img src={option?.vendorLogoUrl} alt={option?.name} width='24' height='24' />
                        )}
                      </Box>
                      <Box display='flex' flexDirection='column'>
                        <Box data-testid='item-name'>{getOptionName(option)}</Box>
                        <Box mt='.5rem' fontSize='.7rem'>
                          {getSubscriptionType(option?.state as number)}
                        </Box>
                      </Box>
                    </Box>

                    {!hideCheckBox && (
                      <Checkbox
                        color='primary'
                        icon={unCheckedIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    )}
                  </Box>
                </li>
              )}
              options={getSortedOptions()}
              getOptionLabel={(option) => String(option?.name)}
              renderInput={(params) => (
                <TextField
                  ref={params.InputProps.ref}
                  {...params}
                  variant='outlined'
                  size='small'
                  InputProps={{
                    ...params.InputProps,
                    className: `${dataTestId}-filter`,
                    endAdornment: undefined,
                    startAdornment: (
                      <Icon sx={{ mr: 1 }} color='action'>
                        search
                      </Icon>
                    ),
                  }}
                  autoFocus
                  className='inputBase'
                  placeholder={t('common:subscription_select_input_component.search_input_placeholder_text')}
                  data-testid='search-input'
                />
              )}
            />
          </div>
        )}
      </SubscriptionSelectInputWrapper>
    </ClickAwayListener>
  );
};
