import { Badge, BadgeProps as MuiBadgeProps, styled } from '@mui/material';

export const StyledBadge = styled(Badge)<MuiBadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    minWidth: 8,
    height: 8,
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
}));
