/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Menu, styled } from '@mui/material';

export const StyledDropdown: any = styled(Menu)(({ theme }) => ({
  '& .MuiPopover-paper': {
    padding: 0,
  },
  '& ul.MuiList-root': {
    padding: theme.spacing(0),
    '& > .MuiBox-root': {
      padding: theme.spacing(1, 0),
    },
  },

  '&.dropdown--align': {
    margin: theme.spacing(1, 0),
  },

  '&.dropdown--position-left': {
    margin: theme.spacing(-1.5, -3),
  },

  '&.dropdown--position-right': {
    margin: theme.spacing(-1.5, 3),
  },
}));

export const StyledBox: any = styled(Box)(({ theme }) => ({
  '& li.MuiMenuItem-root': {
    padding: 0,
  },
  '& .item-box': {
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(0, 2),

    '&.item-box--no-padding': {
      padding: theme.spacing(0),
    },
    '&.item-box--condensed': {
      height: theme.spacing(4.5),
    },
  },
  '& .MuiDivider-root': {
    margin: '0 !important',
  },
}));
