import { Paper, styled } from '@mui/material';

export const InitiativesListWrapper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  maxWidth: theme.spacing(127.75),
  width: '100%',
  '@media (max-width: 1758px)': {
    maxWidth: `calc(100vw - ${theme.spacing(91.75)})`,
  },
}));
