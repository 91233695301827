import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const StyledAvatarWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
}));

export const StyledLabelsWrapper = styled(Box)(() => ({
  marginLeft: 8,
}));

export const StyledAvatarTitle: FC<TypographyProps & { component?: string }> = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  color: theme.palette.text.primary,
  lineHeight: 1.5,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const StyledAvatarSubtitle = styled('p')(({ theme }) => ({
  overflow: 'hidden',
  margin: 0,
  color: theme.palette.text.secondary,
  fontSize: 11,
  lineHeight: 1.36,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
