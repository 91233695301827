import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { NoResultFeature, NoResultFeatureProps } from '../..';
import { EmptySpendFeatureProps } from './empty-spend.feature.props';

export const EmptySpendFeature: FC<EmptySpendFeatureProps> = () => {
  const { t } = useTranslation();
  const spendAndInvoicesTranslationPath = 'subscription_detail_view:tabs_component_section.subscription_spend_tab';

  const noSpendMessage = (
    <Trans
      i18nKey={`${spendAndInvoicesTranslationPath}.no_spends_section.message_text`}
      // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
      components={[<Link data-testid='connect-link' to='/discovery' />]}
    />
  );

  const NoSpendAndInvoicesFeatureProps: NoResultFeatureProps = {
    className: 'is-white is-shadowed',
    customMessage: noSpendMessage,
    title: t(`${spendAndInvoicesTranslationPath}.no_spends_section.title_text`),
  };

  return <NoResultFeature {...NoSpendAndInvoicesFeatureProps} />;
};
