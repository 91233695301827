import { FC } from 'react';
import { colors } from 'shared/theme';

export const DocumentsIcon: FC<React.SVGProps<SVGElement>> = ({ className, fill, height, width }) => (
  <svg
    className={className || ''}
    width={width}
    height={height}
    viewBox='0 0 18 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 15.5H6a2.25 2.25 0 01-2.25-2.25v-7.5a.75.75 0 00-1.5 0v7.5A3.75 3.75 0 006 17h6a.75.75 0 100-1.5zm-4.5-5.25a.75.75 0 00.75.75H12a.75.75 0 100-1.5H8.25a.75.75 0 00-.75.75zm8.25-3.045a.982.982 0 00-.045-.202v-.068a.804.804 0 00-.143-.21l-4.5-4.5a.803.803 0 00-.21-.143.24.24 0 00-.067 0A.66.66 0 0010.537 2H7.5a2.25 2.25 0 00-2.25 2.25v7.5A2.25 2.25 0 007.5 14h6a2.25 2.25 0 002.25-2.25v-4.5-.045zm-4.5-2.647L13.193 6.5H12a.75.75 0 01-.75-.75V4.558zm3 7.192a.75.75 0 01-.75.75h-6a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h2.25v2.25c.002.256.048.51.135.75H8.25a.75.75 0 000 1.5h6v3.75z'
      fill={fill}
    />
  </svg>
);

DocumentsIcon.defaultProps = {
  fill: colors.osloGray,
  height: '19',
  width: '18',
};
