import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from 'asteroids';

export const Link = styled('a')(() => ({
  color: colors.primary.main,
  fontWeight: 400,
}));

export const PrimaryText = styled(Typography)(() => ({
  color: colors.text.primary,
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${theme.spacing(50)})`,
  justifyContent: 'center',
}));
