import { Box, Typography } from '@mui/material';
import { Alert, Autocomplete, AutocompleteOption, InputField } from 'asteroids';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DRAWER_VARIANT, featureFlags } from 'shared/common.definitions';
import { useAppSelector } from 'shared/store/hooks';
import { RequestType } from 'shared/store/views/benchmarks/benchmark-request.slice';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { NAVIGATION_URLS } from 'src/constants/navigation';
import { useWorkflowsListData } from 'views/request-workflows/hooks/use-workflows-list-data';
import { draftParamPrefix } from 'views/requests/requests.view';

import { useWorkflowInitiativeWizardContext } from '../../hooks';
import { PurchaseDefaultForm } from './components/purchase-default-form/purchase-default-form.component';
import { RenewalDefaultForm } from './components/renewal-default-form/renewal-default-form.component';

interface CraftInitiativeDefaultFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultFormRef: any;
}

const WORKFLOW_TYPE_MAPPING = {
  renewal: REQUEST_WORKFLOW_TYPE.RENEWAL,
  'tool-request': REQUEST_WORKFLOW_TYPE.APPROVAL,
};

export enum InitiativeType {
  RENEWAL = 'renewal',
  PURCHASE = 'tool-request',
}

export const CraftInitiativeDefaultForm: React.FC<CraftInitiativeDefaultFormProps | null> = ({ defaultFormRef }) => {
  const { t } = useTranslation();
  const { hash, pathname } = useLocation();

  const isProcurementSupportEnabled = !useCompanyFeatureFlag(featureFlags.PROCUREMENT_SUPPORT_EXCLUDED);

  const hasBenchmarkResults = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.hasResults);
  const benchmarkRequestType = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.benchmarkRequestType);

  const isEditMode = !!hash.includes(DRAWER_VARIANT.EDIT_REQUEST);
  const isCreateMode = !!hash.includes(DRAWER_VARIANT.NEW_REQUEST);

  const [, draftId = '']: (string | undefined)[] = (
    hash.includes(DRAWER_VARIANT.NEW_REQUEST) ? hash.split(DRAWER_VARIANT.NEW_REQUEST).slice(-1)[0] : ''
  ).split(`-${draftParamPrefix}-`);

  const workflowsListData = useWorkflowsListData().data;
  const {
    defaultForm,
    fetchedData,
    initiativeName,
    initiativeNameError,
    selectedInitiativeType,
    setCustomForm,
    setCustomFormError,
    setDefaultFormData,
    setInitiativeName,
    setInitiativeNameError,
    setIsStepperVisible,
    setSelectedInitiativeType,
    setWorkflow,
    workflow,
  } = useWorkflowInitiativeWizardContext();

  const translations = useMemo(
    () => ({
      createTitle: t('requests_view:modify_initiative_drawer.wizard.titles.create'),
      editTitle: t('requests_view:modify_initiative_drawer.wizard.titles.edit'),
      initiativeName: t('requests_view:modify_initiative_drawer.right_side.initiative_name.label'),
      initiativeNameErrorMessage: t('requests_view:modify_initiative_drawer.right_side.initiative_name.error_message'),
      initiativeNamePlaceholder: t('requests_view:modify_initiative_drawer.right_side.initiative_name.placeholder'),
      multiToolPurchaseLabel: t(
        'requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.multi_tool_purchase_label'
      ),
      renewalLabel: t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.renewal_label'),
      title: t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.title'),
      toolRequestLabel: t(
        'requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.tool_request_label'
      ),
    }),
    [t]
  );

  const getOptions = useCallback((): AutocompleteOption[] => {
    const mainPath = `/${pathname.split('/')[1]}`;
    const routesWithToolRequestEnabled = [
      '/requests',
      '/tool-store',
      '/pricing-benchmarks',
      hash.includes('#newRequest') && hash === '#newRequest' && NAVIGATION_URLS.OVERVIEW,
    ];
    const routesWithRenewalEnabled = [
      '/requests',
      NAVIGATION_URLS.SUBSCRIPTIONS,
      '/tool-details',
      '/pricing-benchmarks',
      hash.includes('#newRequest') && hash !== '#newRequest' && NAVIGATION_URLS.OVERVIEW,
    ];

    return [
      ...(routesWithToolRequestEnabled.includes(mainPath)
        ? [
            {
              id: 'tool-request',
              label: translations.multiToolPurchaseLabel,
            },
          ]
        : []),
      ...(routesWithRenewalEnabled.includes(mainPath) ? [{ id: 'renewal', label: translations.renewalLabel }] : []),
    ];
  }, [hash, pathname, translations]);

  const [requestType, setRequestType] = useState<AutocompleteOption>(
    selectedInitiativeType?.id === RequestType.RENEWAL || benchmarkRequestType === RequestType.RENEWAL
      ? getOptions()[1]
      : getOptions()[0]
  );

  const getWorkflow = useMemo(() => {
    return workflowsListData?.filter(
      (workflow) =>
        workflow.state.toLowerCase() === 'active' &&
        workflow.typeId === WORKFLOW_TYPE_MAPPING[requestType?.id as keyof typeof WORKFLOW_TYPE_MAPPING]
    )?.[0];
  }, [requestType?.id, workflowsListData]);

  useEffect(() => {
    if (draftId && selectedInitiativeType) {
      setRequestType(selectedInitiativeType);
    }
  }, [draftId, selectedInitiativeType]);

  useEffect(() => {
    if (!draftId && isCreateMode && getWorkflow) {
      setWorkflow?.(getWorkflow);
      setIsStepperVisible?.(Boolean(getWorkflow.customFormDefinition));
      setSelectedInitiativeType?.(requestType);
    }
  }, [
    getWorkflow,
    setIsStepperVisible,
    requestType,
    setSelectedInitiativeType,
    setWorkflow,
    setDefaultFormData,
    isCreateMode,
    draftId,
  ]);

  useEffect(() => {
    if (isEditMode && fetchedData) {
      setSelectedInitiativeType?.(
        getOptions()[fetchedData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL ? 0 : 1]
      );
      setIsStepperVisible?.(!!fetchedData?.customFormData);
    }
  }, [fetchedData, isEditMode, getOptions, setIsStepperVisible, setSelectedInitiativeType]);

  const renderSupportInfoAlert = () =>
    !hasBenchmarkResults && (
      <Box mb={3}>
        <Alert
          description={t('requests_view:involve_sastrify_alert:description')}
          severity='info'
          title={t('requests_view:involve_sastrify_alert:title')}
        />
      </Box>
    );

  const renderBenchmarkInfoAlert = () =>
    hasBenchmarkResults && (
      <Box mb={3}>
        <Alert
          description={t('requests_view:benchmarks_alert:description')}
          severity='info'
          title={t('requests_view:benchmarks_alert:title')}
        />
      </Box>
    );

  const renderInitiativeNameField = () => (
    <Box display='flex' flexDirection='column' width='100%' mb={3} data-testid='initiative-name'>
      <Typography variant='h3' mb={1.5}>
        {translations.initiativeName}*
      </Typography>
      <InputField
        id='initiativeName'
        value={initiativeName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          setInitiativeName?.(value);
          setInitiativeNameError?.(!value);
        }}
        onClearValue={() => setInitiativeName?.('')}
        label={translations.initiativeNamePlaceholder}
        helperText={initiativeNameError ? translations.initiativeNameErrorMessage : ''}
        error={initiativeNameError}
        size='small'
      />
    </Box>
  );

  const renderInitiativeTypeField = () => (
    <Box display='flex' mb={3} flexDirection='column' width='100%' data-testid='initiative-type'>
      <Typography variant='h3' mb={1.5}>
        {translations.title}
      </Typography>
      <Autocomplete
        id='initiative-type'
        disablePortal
        disableClearable
        label=''
        disabled={getOptions().length === 1 || !!draftId}
        fullWidth
        value={requestType}
        options={getOptions()}
        onChange={(_: unknown, value: unknown) => {
          setDefaultFormData?.(null);
          setCustomForm?.(null);
          setCustomFormError?.(false);
          return value && setRequestType(value as AutocompleteOption);
        }}
        size='small'
      />
    </Box>
  );

  const renderCreateForm = () => (
    <>
      {!isProcurementSupportEnabled && renderSupportInfoAlert()}
      {renderBenchmarkInfoAlert()}
      {renderInitiativeNameField()}
      {renderInitiativeTypeField()}
      {selectedInitiativeType?.id === InitiativeType.PURCHASE && (
        <PurchaseDefaultForm
          mode='create'
          defaultFormRef={defaultFormRef}
          initialData={defaultForm}
          initFiles={defaultForm?.files}
        />
      )}
      {selectedInitiativeType?.id === InitiativeType.RENEWAL && (
        <RenewalDefaultForm
          mode='create'
          defaultFormRef={defaultFormRef}
          initialData={defaultForm}
          initFiles={defaultForm?.files}
        />
      )}
    </>
  );

  const renderEditForm = () => (
    <>
      {fetchedData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL && (
        <PurchaseDefaultForm
          mode='edit'
          defaultFormRef={defaultFormRef}
          initialData={defaultForm}
          initFiles={defaultForm?.files}
          fetchedData={fetchedData}
        />
      )}
      {fetchedData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL && (
        <RenewalDefaultForm
          mode='edit'
          defaultFormRef={defaultFormRef}
          initialData={defaultForm}
          initFiles={defaultForm?.files}
          fetchedData={fetchedData}
        />
      )}
    </>
  );

  const renderForm = () => (
    <>
      {isCreateMode && renderCreateForm()}
      {isEditMode && renderEditForm()}
    </>
  );

  const isFormReady = (!isEditMode && workflow) || (isEditMode && fetchedData);

  return isFormReady ? (
    <>
      <Typography variant='h2' mb={3} data-testid='initiative-title'>
        {isEditMode ? translations.editTitle : translations.createTitle}
      </Typography>
      {renderForm()}
    </>
  ) : null;
};
