import { IconButton, IconButtonProps } from '@mui/material';
import { useState } from 'react';

import { Icon } from '../icon/icon.component';
import { Dropdown } from './dropdown.component';
import { IconDropdownProps } from './dropdown.component.props';

export const IconDropdown: React.FC<IconDropdownProps> = ({
  iconButtonProps,
  iconName,
  iconProps,
  ...restDropdownProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        {...iconButtonProps}
        color={(iconButtonProps?.color as IconButtonProps['color']) || 'text'}
        onClick={handleClick}>
        <Icon {...iconProps}>{iconName}</Icon>
      </IconButton>
      <Dropdown {...restDropdownProps} anchorEl={anchorEl} open={open} onClose={handleClose} />
    </>
  );
};

export default IconDropdown;
