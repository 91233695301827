import { Box, styled } from '@mui/material';
import { colors as asteroidsColors } from 'asteroids';
import { colors } from 'shared/theme';

export const DropzoneWrapper = styled(Box)(({ theme }) => ({
  '& .base-style': {
    '&:hover': {
      '& > svg > path': {
        fill: colors.primary,
      },

      backgroundColor: asteroidsColors.primary.highlight,
      border: `1px dotted ${asteroidsColors.primary.main}`,
    },
    alignItems: 'center',
    background: 'transparent',
    border: `1px dotted ${colors.silverSand}`,
    borderRadius: '0.4rem',
    cursor: 'pointer',
    display: 'flex',
    height: '3.5rem',
    justifyContent: 'center',
    padding: theme.spacing(1),
    transition: 'all 0.2s ease-in-out',
  },
  '& .category': {
    color: colors.rollingStone,
    fontSize: '1rem',
    fontWeight: 500,
  },
  '& .dropzone-message': {
    '& span:first-child': {
      color: asteroidsColors.primary.main,
      textDecoration: 'underline',
    },
    color: colors.rollingStone,
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  '& .base-style-v2': {
    '&:hover': {
      backgroundColor: asteroidsColors.primary.highlight,
      border: `1px dotted ${asteroidsColors.primary.main}`,
    },
    alignItems: 'center',
    background: 'transparent',
    border: `1px dotted ${asteroidsColors.tertiary.main}`,
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    display: 'flex',
    height: '5.9rem',
    justifyContent: 'center',
    padding: theme.spacing(2, 2),
    transition: 'all 0.2s ease-in-out',
  },
  width: '100%',
}));
