import { ProcurementInitiative } from './components/procurement-initiative.component';
import { ProcurementInitiativesProps } from './procurement-initiatives.component.props';

export const ProcurementInitiativesComponent: React.FC<ProcurementInitiativesProps> = ({
  childRequests = [],
  companyId,
  currency,
  documentsUploadFunction,
  expanded,
  initiatives,
  refetchInitiatives,
  requestWorkflowTypeId,
  setExpanded,
  showNotification,
  vendor,
}) => {
  return (
    <>
      {initiatives.map((initiative) => {
        const childVendor = childRequests
          .filter(({ requestId }) => String(requestId) === String(initiative.requestId))
          .map(({ vendorAvatarUrl, vendorName }) => ({ avatarUrl: vendorAvatarUrl || '', name: vendorName }))[0];

        return (
          <ProcurementInitiative
            key={initiative.id}
            expanded={expanded}
            setExpanded={setExpanded}
            vendor={childVendor || vendor}
            initiative={initiative}
            currency={currency}
            showNotification={showNotification}
            refetchInitiatives={refetchInitiatives}
            documentsUploadFunction={documentsUploadFunction}
            companyId={companyId}
            requestWorkflowTypeId={requestWorkflowTypeId}
          />
        );
      })}
    </>
  );
};
