import * as React from 'react';
import { colors } from 'shared/theme';

export const SastrifyLogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ height, width }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.9885 11.0014C8.31937 11.0066 7.65637 10.8735 7.04106 10.6105C6.44849 10.3569 5.91107 9.99011 5.45894 9.5307C5.20017 9.26545 4.97116 8.97272 4.77599 8.65771C4.62813 8.88913 4.49923 9.13212 4.39054 9.38431C4.12996 9.99702 3.99742 10.6565 4.00105 11.3223V16.3111L9.32225 10.9893C9.20943 10.9967 9.10065 11.0014 8.9885 11.0014Z'
        fill={colors.brightSun}
      />
      <path
        d='M13.989 11.3223C13.9926 10.6565 13.8601 9.99696 13.5995 9.38425C13.4915 9.13391 13.3642 8.89233 13.2188 8.66168L13.2134 8.65363C13.0186 8.33834 12.7896 8.04557 12.5304 7.78064L9.3259 10.9845L9.3212 10.9892L4 16.3111H9.00021C9.66924 16.3167 10.3322 16.1841 10.9476 15.9216C11.5382 15.6691 12.075 15.306 12.5291 14.8518C12.5848 14.7961 12.6392 14.739 12.6916 14.6813C13.0699 14.2647 13.3763 13.7882 13.5982 13.2711C13.8613 12.6553 13.9943 11.9919 13.989 11.3223Z'
        fill={colors.primary}
      />
      <path
        d='M13.989 1.00024L8.6665 6.32279C8.77999 6.3154 8.89482 6.31003 9.01033 6.31003C9.6794 6.30467 10.3424 6.43749 10.9578 6.70018C11.5502 6.95411 12.0875 7.32087 12.5399 7.78C12.7983 8.04555 13.0273 8.33825 13.2228 8.65299C13.3707 8.42183 13.5 8.17932 13.6096 7.92774C13.8704 7.31506 14.0031 6.65555 13.9998 5.98971V1.00024H13.989Z'
        fill={colors.turquoise}
      />
      <path
        d='M8.98891 1.00019C8.31984 0.994218 7.65678 1.12683 7.04148 1.38968C5.84942 1.89861 4.89989 2.84815 4.39095 4.04021C4.13385 4.65587 4.00146 5.31642 4.00146 5.98361C4.00146 6.6508 4.13385 7.31136 4.39095 7.92702C4.50047 8.18035 4.6298 8.42465 4.77775 8.65764C4.97292 8.97265 5.20193 9.26538 5.4607 9.53063L8.66523 6.32676L8.66926 6.32273L13.9918 1.00019H8.98891Z'
        fill={colors.gossamer}
      />
      <path
        d='M9.32523 10.9846C9.21376 10.992 9.10161 11.0014 8.98947 11.0014C8.32034 11.0066 7.65734 10.8735 7.04204 10.6105C6.44946 10.3569 5.91205 9.99011 5.45991 9.5307C5.20114 9.26546 4.97214 8.97272 4.77697 8.65771C4.62911 8.88913 4.5002 9.13212 4.39151 9.38431C4.13094 9.99702 3.9984 10.6565 4.00203 11.3223V11.9267C7.38384 12.5868 9.32255 10.9866 9.32523 10.9846Z'
        fill={colors.ripeLemon}
      />
      <path
        d='M8.67236 6.33227C8.78451 6.32488 8.89598 6.31615 9.00813 6.31615C9.67722 6.31072 10.3402 6.44355 10.9556 6.70631C11.5467 6.96036 12.0823 7.32765 12.5323 7.78748C12.793 8.05447 13.0236 8.34924 13.22 8.66651C13.3692 8.43263 13.4994 8.18719 13.6095 7.93253C13.8702 7.31986 14.003 6.66034 13.9996 5.99449V5.39011C10.6144 4.73 8.67572 6.33026 8.67236 6.33227Z'
        fill={colors.aquamarineBlue}
      />
    </svg>
  );
};

SastrifyLogoIcon.defaultProps = {
  height: 18,
  width: 18,
};
