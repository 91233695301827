import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Typography } from '@mui/material';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { ModalAndNotificationsContext } from 'context/modal-notification.context';
import { useMarkTaskAsDone } from 'hooks/useMarkTaskAsDone';
import { forwardRef, useContext, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { googleTagManagerEvents, SubscriptionStatus } from 'shared/common.definitions';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import { changeSubscriptionRenewalDate } from 'shared/logic/subscription-item.logic';
import { Subscription } from 'shared/models';
import { updateFooterState } from 'shared/store/modal';
import { invalidateRenewalReminder } from 'views/subscription-detail/components/renewal-status-alert/helpers';

import { ChangeRenewalDateFeatureProps } from './change-renewal-date.feature.props';
import { useChangeRenewalDateForm } from './change-renewal-date-form.hook';
import { RenewalDateField } from './change-renewal-date-renewal-date-field.component';
import { RenewalFrequencyField } from './change-renewal-date-renewal-frequency-field.component';

export type SubscriptionRenewalDateValues = {
  subscription: Subscription;
  billingFrequency?: number;
  renewalDate?: Date;
  autoRenewal: number;
  cancellationPeriod: number;
  customCancellationPeriod: string;
  customCancellationPeriodType: number;
  otherBillingFrequencyType?: 'years' | 'months';
  otherBillingFrequency?: number;
};

enum SubscriptionBillingFrequency {
  'monthly',
  'yearly',
  'custom',
  'other',
}

export const ChangeRenewalDateFeature = forwardRef((props: ChangeRenewalDateFeatureProps, ref) => {
  const {
    billingFrequency,
    closeModal,
    isOnDetailsSideBarSection,
    isStatusUpdate,
    onSuccess,
    renewalDate,
    subscription,
    updateSubscription,
    updateSubscriptionItem,
    user,
  } = props;

  const { onShowNotification: showNotification } = useContext(ModalAndNotificationsContext);
  const { markTodoAsDone } = useMarkTaskAsDone();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const isCustomBillingFrequency = subscription.billingFrequency === SubscriptionBillingFrequency.custom;
  const form = useChangeRenewalDateForm(subscription, billingFrequency, renewalDate);

  const { mutate: changeSubscriptionRenewalDateMutation } = useMutation(changeSubscriptionRenewalDate);

  const onConfirmChangeRenewalDate = (todoId?: string) => {
    const values = form.getValues();

    const submitValues = {
      autoRenewal: values.autoRenewal,
      billingFrequency: values.billingFrequency,
      cancellationPeriod: values.cancellationPeriod,
      customCancellationPeriod: values.customCancellationPeriod,
      customCancellationPeriodType: values.customCancellationPeriodType,
      otherBillingFrequency: Number(values.billingFrequency) === 3 ? values.otherBillingFrequency : undefined,
      otherBillingFrequencyType: Number(values.billingFrequency) === 3 ? values.otherBillingFrequencyType : undefined,
      renewalDate: Number(values.billingFrequency) > 0 && values.renewalDate ? new Date(values.renewalDate) : undefined,
      subscription: {
        ...subscription,
        state: isStatusUpdate ? SubscriptionStatus.active : subscription.state,
      },
    };

    dispatch(updateFooterState({ isFormSubmitting: true }));

    changeSubscriptionRenewalDateMutation(submitValues, {
      onError: (error) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSettled: () => {
        dispatch(updateFooterState({ isFormSubmitting: false }));
      },
      onSuccess: (updatedSubscription) => {
        updateSubscriptionItem?.(updatedSubscription, closeModal, 'Subscription Renewal Date Updated Successfully!');
        updateSubscription?.(updatedSubscription, 'Subscription Renewal Date Verified Successfully!');
        fireGTagManagerEvent(window, String(user?.email), googleTagManagerEvents.RenewalDateUpdated);
        onSuccess?.(updatedSubscription);

        if (todoId) {
          markTodoAsDone(updatedSubscription.id, todoId);
        }

        if (isOnDetailsSideBarSection) {
          queryClient.setQueryData(['subscription', subscription.id], updatedSubscription);
          queryClient.invalidateQueries('subscriptionTodos');
          queryClient.invalidateQueries('subscription-history');
          queryClient.invalidateQueries('auditLogs');
          invalidateRenewalReminder(queryClient, subscription.id);
          closeModal?.();
          showNotification?.('Subscription Renewal Date Verified Successfully!', NotificationAlertType.Success);
        } else {
          queryClient.invalidateQueries('auditLogs');
        }
      },
    });
  };

  useImperativeHandle(ref, () => ({
    onConfirmChangeRenewalDate,
  }));

  const billingFrequencyValue = form.watch('billingFrequency');

  return (
    <div className='sastrify-changerenewaldate modal-feature'>
      <div className='modal-feature-content'>
        <Typography variant='subtitle' color='text.primary'>
          {t('common:modals.change_renewal_date_feature.body_section.form.header_section_text')}
        </Typography>

        <RenewalFrequencyField form={form} isCustomBillingFrequency={isCustomBillingFrequency} />

        {Number(billingFrequencyValue) !== 0 && <RenewalDateField form={form} />}
      </div>
    </div>
  );
});
