import { StatusCommonProps, Statuses } from '../status.component.props';

export const SubscriptionStatuses = [
  Statuses.active,
  Statuses.inEvaluation,
  Statuses.inNegotiation,
  Statuses.renewal,
  Statuses.inactive,
  Statuses.discovered,
  Statuses.expired,
] as const;

export type StatusSubscriptionProps = StatusCommonProps & {
  status: (typeof SubscriptionStatuses)[number];
};
