import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ReplyableType } from 'shared/common.definitions';
import { saveReplyable } from 'shared/logic/replyable.logic';
import { queryClient } from 'shared/reactQuery';

export function useAddReply(commentId: string, templateId?: string) {
  const { onShowNotification } = useModalAndNotification();
  const { t } = useTranslation();

  return useMutation(
    (comment: string) =>
      saveReplyable(ReplyableType.SubscriptionComments, commentId, {
        comment,
        replyableTemplateId: templateId || undefined,
      }),
    {
      onSuccess: () => {
        onShowNotification(t('subscription_detail_view:reply_toast.create_success'), NotificationAlertType.Success);
        queryClient.invalidateQueries([ReplyableType.SubscriptionComments, commentId]);
        queryClient.invalidateQueries('subscription-history');
      },
    }
  );
}
