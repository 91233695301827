import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { FC } from 'react';

export const VendorKnowledgeSkeleton: FC = () => {
  return (
    <div className='vendor-knowledge-skeleton'>
      <Skeleton variant='rectangular' width={75} height={15} className='text' animation='wave' />
      <Skeleton variant='rectangular' width={160} height={15} className='text' animation='wave' />
      <Grid container spacing={2}>
        {[1, 2, 3].map((item) => (
          <Grid lg={12} item key={item}>
            <Skeleton variant='rectangular' width='100%' height={140} animation='wave' />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
