import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkRaw from 'remark-gfm';
import { SUBSCRIPTION_DOCUMENT_REGEX_PATTERN } from 'shared/common.definitions';
import { getMarkDownTextWithUrls } from 'shared/logic/subscription-item.logic';

import { MarkdownReaderComponentProps } from './markdown-reader.component.props';

export const MarkdownReaderComponent: React.FC<MarkdownReaderComponentProps> = (
  props: MarkdownReaderComponentProps
) => {
  const { className, documents, isDashboardComment, text } = props;

  const getDocumentsFromMarkdown = () => {
    const matches = text?.match(SUBSCRIPTION_DOCUMENT_REGEX_PATTERN);

    if (matches?.length && documents) {
      const { markdownWithResolvedUrls } = getMarkDownTextWithUrls(
        String(text),
        matches,
        documents,
        isDashboardComment
      );

      return markdownWithResolvedUrls;
    }
    return text || '';
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkRaw]}
      rehypePlugins={[rehypeRaw]}
      className={`markdownreader-text ${className || ''}`}
      data-testid='markdownreader'
      linkTarget='_blank'>
      {getDocumentsFromMarkdown()}
    </ReactMarkdown>
  );
};
