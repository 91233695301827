import { Box, Button, Typography } from '@mui/material';
import OptimizeImage from 'assets/images/connect/optimize.svg';
import DocumentsImage from 'assets/images/drawer-info-section/documents-upload.svg';
import InviteUsersImage from 'assets/images/users-and-authentication/team-building.svg';
import newWorkflowImg from 'assets/images/workflows/new_workflow.svg';
import { Icon, NavigateToUrlLink } from 'asteroids';
import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { DrawerInfoType, RENEWAL_INITIATIVE_EVENT_SOURCE, toolStoreBundleDrawerTypes } from 'shared/common.definitions';
import { getHash } from 'shared/helpers/common.helper';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { VendorType } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { DRAWER_FROM_URL } from '../drawer-workflow-request/drawer-workflow-request.component';
import { AddNewSolutionIcon, AddNewSubscriptionIcon } from '../icons';
import {
  DrawerInfoSectionComponentProps,
  InitiativeSection,
  MultiToolInitiativesInfoWrapper,
  ToolRequestSection,
} from '.';
import { InfoSectionStyledContainer } from './drawer-info-section.styles';

export const getIsRequestDrawer = (infoType: string) =>
  [DrawerInfoType.REQUEST_SUPPORT, DrawerInfoType.SASTRIFY_APP_HELP].includes(infoType as DrawerInfoType);

export const DrawerInfoSectionComponent: FC<DrawerInfoSectionComponentProps> = (props) => {
  const { infoType, showNotification, toggleDrawer, vendorName, ...drawerProps } = props;
  const appDrawerData = useAppSelector((state) => state.common?.appDrawer?.data);
  const isRequestDrawer = useMemo(() => getIsRequestDrawer(infoType), [infoType]);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const exploreData = queryClient.getQueryData<VendorType>('exploreData');

  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isToolStoreBundleEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);
  const isSastriCloudEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.SASTRICLOUD);

  const handleCloseDrawer = () => {
    const routeFrom = sessionStorage.getItem(DRAWER_FROM_URL);

    if (routeFrom && infoType === DrawerInfoType.REQUEST_DETAILS) {
      sessionStorage.removeItem(DRAWER_FROM_URL);
      return history.replace(routeFrom);
    }

    if (infoType === DrawerInfoType.COMPANY_DOCUMENTS) {
      if (appDrawerData?.spendImportItem) {
        history.replace(NAVIGATION_URLS.SPEND_IMPORT);
      } else {
        history.replace(NAVIGATION_URLS.SUBSCRIPTIONS);
      }
    }
    if (
      infoType === DrawerInfoType.WORKFLOW_REQUEST ||
      infoType === DrawerInfoType.REQUEST_DETAILS ||
      infoType === DrawerInfoType.WORKFLOW_REQUEST_EDIT
    ) {
      const includedHash = getHash(location.hash);
      history.replace(`${location.pathname}${location.search}${includedHash}`);
    }
    localStorage.removeItem(RENEWAL_INITIATIVE_EVENT_SOURCE);
    toggleDrawer();
  };

  const getBackButtonText = () => {
    switch (infoType) {
      case DrawerInfoType.SUBSCRIPTION_DOCUMENTS:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_to_text',
          {
            vendorName,
          }
        );
      case DrawerInfoType.REQUEST_HELP:
        if (exploreData) {
          return t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_text'
          );
        }

        if (vendorName) {
          return t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_to_text',
            {
              vendorName,
            }
          );
        }
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_text'
        );

      case DrawerInfoType.NEW_SUBSCRIPTION:
      case DrawerInfoType.COMPANY_DOCUMENTS:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_to_subscriptions_text'
        );

      case DrawerInfoType.USER_INVITE:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_to_users_text'
        );

      case DrawerInfoType.USER_INVITE_SUBSCRIPTION:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTIONS:
      case DrawerInfoType.PROCUREMENT_HELP:
      case DrawerInfoType.SASTRIFY_APP_HELP:
      case DrawerInfoType.REQUEST_SUPPORT:
      case DrawerInfoType.TOOL_REQUEST:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_text'
        );

      case DrawerInfoType.NEW_SOLUTION:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_to_new_solutions_text'
        );

      case DrawerInfoType.NEW_WORKFLOW:
        return t('workflows_view:task_automation.drawers.new_workflow.info_section.back_button_text');

      case DrawerInfoType.SASTRICLOUD_OPTIMIZE:
        return t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.back_text');

      default:
        return null;
    }
  };

  const getTitleText = () => {
    switch (infoType) {
      case DrawerInfoType.PROCUREMENT_HELP:
      case DrawerInfoType.REQUEST_HELP:
        return t('common:csm.request_csm_support_text');

      case DrawerInfoType.SUBSCRIPTION_DOCUMENTS:
      case DrawerInfoType.COMPANY_DOCUMENTS:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.left_drawer_title.upload_documents_title'
        );

      case DrawerInfoType.USER_INVITE:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTION:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTIONS:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.left_drawer_title.user_invite_title'
        );

      case DrawerInfoType.NEW_SUBSCRIPTION:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.left_drawer_title.add_new_subscription_title'
        );

      case DrawerInfoType.NEW_SOLUTION:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.left_drawer_title.add_new_solution_title'
        );

      case DrawerInfoType.NEW_WORKFLOW:
        return t('workflows_view:task_automation.drawers.new_workflow.info_section.title_text');

      case DrawerInfoType.SASTRIFY_APP_HELP:
        return t('common:request_support.sastrify_app_help_section.sastrify_app_help_title_text');

      case DrawerInfoType.TOOL_REQUEST:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.left_drawer_title.add_tool_request_title'
        );

      case DrawerInfoType.SASTRICLOUD_OPTIMIZE:
        return t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.title');

      default:
        return null;
    }
  };

  const getBodyText = () => {
    if (infoType === DrawerInfoType.COMPANY_DOCUMENTS) {
      return t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.company_body_text'
      );
    }
    if (infoType === DrawerInfoType.SUBSCRIPTION_DOCUMENTS) {
      return t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.upload_document_body_text'
      );
    }
    if (infoType === DrawerInfoType.PROCUREMENT_HELP || infoType === DrawerInfoType.REQUEST_HELP) {
      return t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.request_help_body_text'
      );
    }
    if (
      infoType === DrawerInfoType.USER_INVITE ||
      infoType === DrawerInfoType.USER_INVITE_SUBSCRIPTIONS ||
      infoType === DrawerInfoType.USER_INVITE_SUBSCRIPTION
    ) {
      return (
        <Box>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.user_invite_body_text'
          )}
          <NavigateToUrlLink
            icon={false}
            href='https://support.sastrify.com/support/solutions/articles/101000495053-how-to-invite-new-users-to-sastrify'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.user_invite_body_link'
            )}
          </NavigateToUrlLink>
        </Box>
      );
    }
    if (infoType === DrawerInfoType.NEW_SUBSCRIPTION) {
      return t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.add_new_subscription_body_text'
      );
    }
    if ([DrawerInfoType.NEW_SOLUTION, DrawerInfoType.TOOL_REQUEST].includes(infoType as DrawerInfoType)) {
      return t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.add_new_solution_body_text'
      );
    }
    if (infoType === DrawerInfoType.NEW_WORKFLOW) {
      return t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.add_new_workflow_body_text'
      );
    }
    if (infoType === DrawerInfoType.SASTRIFY_APP_HELP) {
      return (
        <div className='request-support-text'>
          <div>{t('common:request_support.sastrify_app_help_section.body_text')}</div>
          <Trans
            i18nKey='common:request_support.sastrify_app_help_section.sub_body_text'
            values={{
              requestType: t('common:request_support.sastrify_app_help_section.sastrify_app_help_title_text'),
            }}
            components={[<b />]}
          />
        </div>
      );
    }
    if (infoType === DrawerInfoType.SASTRICLOUD_OPTIMIZE) {
      return t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.body_text');
    }
  };

  const getHelpCenterLink = () => {
    switch (infoType) {
      case DrawerInfoType.COMPANY_DOCUMENTS:
        return 'https://support.sastrify.com/support/solutions/articles/101000395133-upload-your-data-into-sastrify';

      case DrawerInfoType.SUBSCRIPTION_DOCUMENTS:
        return 'https://support.sastrify.com/support/solutions/articles/101000395133-upload-your-data-into-sastrify';

      case DrawerInfoType.REQUEST_HELP:
      case DrawerInfoType.REQUEST_SUPPORT:
      case DrawerInfoType.SASTRIFY_APP_HELP:
        return 'https://support.sastrify.com';

      case DrawerInfoType.NEW_SUBSCRIPTION:
        return 'https://support.sastrify.com/support/solutions/folders/101000262134';

      case DrawerInfoType.NEW_SOLUTION:
      case DrawerInfoType.TOOL_REQUEST:
        return 'https://support.sastrify.com/support/solutions/articles/101000384363--new-solutions-vs-subscriptions-the-difference';

      case DrawerInfoType.PROCUREMENT_HELP:
        return 'https://support.sastrify.com/support/home';

      default:
        return '';
    }
  };

  const getBodyIcon = () => {
    switch (infoType) {
      case DrawerInfoType.SUBSCRIPTION_DOCUMENTS:
      case DrawerInfoType.COMPANY_DOCUMENTS:
        return (
          <img
            width='284px'
            src={DocumentsImage}
            alt={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_upload_drawer_image_alt.upload_documents_image_alt'
            )}
          />
        );

      case DrawerInfoType.REQUEST_HELP:
      case DrawerInfoType.REQUEST_SUPPORT:
      case DrawerInfoType.PROCUREMENT_HELP:
        return <div className='info-icon request-support' />;

      case DrawerInfoType.USER_INVITE:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTION:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTIONS:
        return <img width='298' src={InviteUsersImage} alt={t('users_view:invite_user_button_text')} />;

      case DrawerInfoType.NEW_SUBSCRIPTION:
        return <AddNewSubscriptionIcon />;

      case DrawerInfoType.NEW_SOLUTION:
      case DrawerInfoType.TOOL_REQUEST:
        return <AddNewSolutionIcon />;

      case DrawerInfoType.NEW_WORKFLOW:
        return <img className='new-workflow-icon' src={newWorkflowImg} alt='' />;

      case DrawerInfoType.SASTRIFY_APP_HELP:
        return <div className='info-icon sastrify-app-help' />;

      case DrawerInfoType.SASTRICLOUD_OPTIMIZE:
        return (
          <img
            width='200'
            src={OptimizeImage}
            alt={t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.info_panel.step3.alt')}
          />
        );

      default:
        return null;
    }
  };

  const getFooterText = () => {
    switch (infoType) {
      case DrawerInfoType.SASTRIFY_APP_HELP:
      case DrawerInfoType.REQUEST_SUPPORT:
        return t('common:request_support.footer_text');
      case DrawerInfoType.SUBSCRIPTION_DOCUMENTS:
      case DrawerInfoType.COMPANY_DOCUMENTS:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.help_text_section.upload_document_help_text'
        );

      case DrawerInfoType.REQUEST_HELP:
      case DrawerInfoType.PROCUREMENT_HELP:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.help_text_section.request_help_text'
        );

      case DrawerInfoType.USER_INVITE:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTION:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTIONS:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.help_text_section.user_invite_help_text'
        );

      case DrawerInfoType.NEW_SUBSCRIPTION:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.help_text_section.add_new_subscription_text'
        );

      case DrawerInfoType.NEW_SOLUTION:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.help_text_section.add_new_solution_text'
        );

      case DrawerInfoType.TOOL_REQUEST:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.help_text_section.add_request_tool_text'
        );

      default:
        return '';
    }
  };

  const getFooterLinkText = () => {
    switch (infoType) {
      case DrawerInfoType.SASTRIFY_APP_HELP:
        return t('common:request_support.footer_text');
      default:
        return t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.external_link_text'
        );
    }
  };

  const showDrawerFooter = [
    DrawerInfoType.COMPANY_DOCUMENTS,
    DrawerInfoType.SUBSCRIPTION_DOCUMENTS,
    DrawerInfoType.USER_INVITE,
    DrawerInfoType.USER_INVITE_SUBSCRIPTION,
    DrawerInfoType.USER_INVITE_SUBSCRIPTIONS,
    DrawerInfoType.NEW_SOLUTION,
    DrawerInfoType.NEW_SUBSCRIPTION,
    DrawerInfoType.TOOL_REQUEST,
    DrawerInfoType.REQUEST_HELP,
    DrawerInfoType.SASTRIFY_APP_HELP,
    DrawerInfoType.PROCUREMENT_HELP,
  ].includes(infoType as DrawerInfoType);

  const showFooterLinkText = [
    DrawerInfoType.COMPANY_DOCUMENTS,
    DrawerInfoType.SUBSCRIPTION_DOCUMENTS,
    DrawerInfoType.NEW_SOLUTION,
    DrawerInfoType.NEW_SUBSCRIPTION,
    DrawerInfoType.TOOL_REQUEST,
    DrawerInfoType.REQUEST_HELP,
    DrawerInfoType.SASTRIFY_APP_HELP,
    DrawerInfoType.PROCUREMENT_HELP,
    DrawerInfoType.GLOBAL_REQUEST_HELP,
  ].includes(infoType as DrawerInfoType);

  if (!isToolStoreBundleEnabled && infoType && toolStoreBundleDrawerTypes.includes(infoType as DrawerInfoType)) {
    return null;
  }

  if (!isSastriCloudEnabled && infoType === DrawerInfoType.SASTRICLOUD_OPTIMIZE) {
    return null;
  }

  if (infoType === DrawerInfoType.TOOL_REQUEST) {
    return <ToolRequestSection handleCloseDrawer={handleCloseDrawer} />;
  }
  if (infoType === DrawerInfoType.WORKFLOW_REQUEST || infoType === DrawerInfoType.WORKFLOW_REQUEST_EDIT) {
    return <InitiativeSection handleCloseDrawer={handleCloseDrawer} />;
  }
  if (infoType === DrawerInfoType.REQUEST_DETAILS) {
    return (
      <MultiToolInitiativesInfoWrapper handleCloseDrawer={handleCloseDrawer} showNotification={showNotification} />
    );
  }
  if (
    infoType === DrawerInfoType.NEW_REQUEST_WORKFLOW ||
    infoType === DrawerInfoType.NEW_REQUEST_WORKFLOW_EDIT ||
    infoType === DrawerInfoType.NEW_REQUEST_WORKFLOW_DETAILS
  ) {
    return null;
  }

  return (
    <InfoSectionStyledContainer $infoType={infoType} className='leftSection' {...drawerProps}>
      <Box display='flex' flex-direction='column' alignItems='center' className='header'>
        <Button
          variant='text'
          data-testid='back_button'
          className='back-button-text'
          startIcon={infoType ? <Icon color='primary'>arrow_back</Icon> : null}
          onClick={handleCloseDrawer}>
          {getBackButtonText()}
        </Button>
      </Box>
      <Box className='leftSectionContent' data-testid='left-section-desc'>
        <Typography variant='h2' className='upload-document-title'>
          {getTitleText()}
        </Typography>

        <Box className='body-text-and-icon'>
          <Box className='icon-wrapper'>{getBodyIcon()}</Box>

          <Typography variant='subtitle' className='body-text' data-testid={`${infoType}-infoBodyText`}>
            {getBodyText()}
          </Typography>
        </Box>
      </Box>
      {showDrawerFooter ? (
        <Box display='flex' flexDirection='column' alignItems='center' className='leftSectionFooter'>
          {!isRequestDrawer && <Typography variant='body2'>{getFooterText()}</Typography>}
          {showFooterLinkText && (
            <Typography variant='body2'>
              <NavigateToUrlLink
                href={getHelpCenterLink()}
                target='_blank'
                rel='noreferrer'
                data-testid='help-center-link'>
                {getFooterLinkText()}
              </NavigateToUrlLink>
            </Typography>
          )}
        </Box>
      ) : null}
    </InfoSectionStyledContainer>
  );
};
