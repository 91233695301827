export function extractMediaFromContent(content: string) {
  const matches = content.match(/\bhttps?:\/\/sastrify-vendor-insights\S+/gi);

  const mediaUrl = [...(matches || [])];

  let newContent = content;

  mediaUrl.forEach((url) => {
    newContent = newContent.replace(url, '');
  });

  const videosUrls = mediaUrl.filter((media) => !isImage(media));
  const imagesUrls = mediaUrl.filter((media) => isImage(media));

  return { content: newContent, imagesUrls, videosUrls };
}

function isImage(url: string): boolean {
  return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
}
