import axios, { AxiosInterceptorManager, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';

import { IApiService } from '../interfaces/api.interface';
import { limiter } from './rate-limiter.service';

export class ApiService implements IApiService {
  private axiosInstance = axios.create();

  public config?: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  constructor(config?: AxiosRequestConfig) {
    if (config) {
      this.config = config;
    }
  }

  public delete = (url: string, id?: string): Promise<AxiosResponse> => {
    const formattedURL = !id ? url : `${url}/${id}`;

    return this.rateLimiter(this.axiosInstance.delete(formattedURL, this.config));
  };

  public get = (url: string, id?: string, headers?: AxiosRequestHeaders): Promise<AxiosResponse> => {
    const formattedURL = !id ? url : `${url}/${id}`;
    const config: AxiosRequestConfig = {
      ...this.config,
      headers: {
        ...this.config?.headers,
        ...headers,
      },
    };

    return this.rateLimiter(this.axiosInstance.get(formattedURL, config));
  };

  public patch = (url: string, data?: unknown, id?: string): Promise<AxiosResponse> => {
    const formattedURL = !id ? url : `${url}/${id}`;

    return this.rateLimiter(this.axiosInstance.patch(formattedURL, data, this.config));
  };

  public post = (url: string, data?: unknown, id?: string): Promise<AxiosResponse> => {
    const formattedURL = !id ? url : `${url}/${id}`;

    return this.rateLimiter(this.axiosInstance.post(formattedURL, data, this.config));
  };

  public upload = (url: string, data?: unknown, id?: string): Promise<AxiosResponse> => {
    const formattedURL = !id ? url : `${url}/${id}`;
    let config: AxiosRequestConfig;
    if (this.config?.headers?.authorization) {
      config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          authorization: this.config?.headers?.authorization,
        },
      };
    } else {
      config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      };
    }

    return this.rateLimiter(this.axiosInstance.post(formattedURL, data, config));
  };

  public uploadFileToS3 = (url: string, file?: unknown, fileType?: string): Promise<AxiosResponse> => {
    const config: AxiosRequestConfig = {
      headers: fileType
        ? {
            'Content-Type': fileType,
          }
        : undefined,
    };

    return this.rateLimiter(this.axiosInstance.put(url, file, config));
  };

  public put = (url: string, data?: unknown): Promise<AxiosResponse> => {
    return this.rateLimiter(this.axiosInstance.put(url, data, this.config));
  };

  public setConfig = (config: AxiosRequestConfig): void => {
    this.config = config;
  };

  public setToken = (token?: string): void => {
    if (this.config) {
      this.config.headers = {
        ...this.config.headers,
        authorization: `Bearer ${token}`,
      };
    }
  };

  public interceptors = (): {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  } => {
    return this.axiosInstance.interceptors;
  };

  private rateLimiter(request: Promise<AxiosResponse<any, any>>) {
    return limiter.rateLimiter(request);
  }
}

export default new ApiService();
