import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';
import { SortOption } from '../../models/subscription.model';

export const trackDiscoveredToolsPageViewed = (data: { userId: string; companyId: string }) =>
  EventTracking.trackEvent(trackEventName.trackDiscoveredToolsPageViewed, data);

export const trackDiscoveredToolsUpdateAction = (data: {
  userId: string;
  companyId: string;
  subscriptionIds: string[];
}) => {
  EventTracking.trackEvent(trackEventName.trackDiscoveredToolUpdateAction, data);
};

export const trackDiscoveredBulkDeleteAction = (data: {
  userId: string;
  companyId: string;
  subscriptionIds: string[];
}) => EventTracking.trackEvent(trackEventName.trackDiscoveredToolsBulkDeleteAction, data);

export const trackDiscoveredSortingAction = (data: { userId: string; companyId: string; sortOption: SortOption }) =>
  EventTracking.trackEvent(trackEventName.trackDiscoveredToolsSortAction, data);
