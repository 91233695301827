import React, { useState } from 'react';
import TooltipWrapper from 'react-tooltip-lite';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';

interface TooltipProps {
  content: string;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  const [visible, setTooltipVisible] = useState(false);

  return (
    <TooltipWrapper content={content} isOpen={visible}>
      <span
        tabIndex={0}
        role='button'
        onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => setTooltipVisible(!visible))}
        onClick={() => setTooltipVisible(!visible)}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}>
        {children}
      </span>
    </TooltipWrapper>
  );
};
