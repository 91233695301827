import { FormControl, Radio } from '@mui/material';
import classnames from 'classnames';
import { FC } from 'react';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';

type CriteriaTriggerOptionProps = {
  selected: boolean;
  value: string;
  title: string;
  text: string;
  icon: React.ReactNode;
  onSelect: (newTrigger: string) => void;
};

export const CriteriaTriggerOptionComponent: FC<CriteriaTriggerOptionProps> = (props) => {
  const handleTriggerSelect = (evt: React.SyntheticEvent<EventTarget>) => {
    evt.stopPropagation();
    evt.preventDefault();
    const newTrigger = (evt.target as Element).closest('.card-option-target')?.getAttribute('data-trigger') || '';
    props.onSelect(newTrigger);
    return false;
  };

  return (
    <FormControl
      key={props.value}
      className={classnames(['card-option', 'new-workflow--step-option', { active: props.selected }])}>
      <div
        className={classnames('card-option-target')}
        data-trigger={props.value}
        tabIndex={0}
        role='button'
        onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => handleTriggerSelect(e))}
        onClick={handleTriggerSelect}>
        <div className={classnames('new-workflow--option-title')}>{props.title}</div>
        <Radio color='secondary' value={props.value} />
        <div className={classnames('new-workflow--option-text')}>{props.text}</div>
        <div className={classnames('new-workflow--option-icon')}>{props.icon}</div>
      </div>
    </FormControl>
  );
};
