import React from 'react';

export const NotAssigned: React.FC<React.SVGProps<SVGElement>> = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path
      fill='#5F2B01'
      d='m19 15.17-3.37-3.38c.64.22 1.23.48 1.77.76.97.51 1.58 1.52 1.6 2.62Zm1.19 4.02-1.41 1.41-2.61-2.6H3v-2.78c0-1.12.61-2.15 1.61-2.66 1.29-.66 2.87-1.22 4.67-1.45L.39 2.22 1.8.81l18.39 18.38ZM14.17 16l-3-3H11c-2.37 0-4.29.73-5.48 1.34-.32.16-.52.5-.52.88V16h9.17ZM11 4c1.1 0 2 .9 2 2 0 .86-.54 1.59-1.3 1.87l1.48 1.48a3.999 3.999 0 1 0-5.53-5.53L9.13 5.3C9.41 4.54 10.14 4 11 4Z'
    />
  </svg>
);
