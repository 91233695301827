import { Box, Stack, Typography } from '@mui/material';
import { type MRT_Row } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { RequestDraft } from 'shared/models/request-draft.model';
import { formatDate } from 'views/overview/calendar/subscription-indicator/helpers';

import { Avatar } from '../components/initiative-list/initiative-list.styles';

enum AccessorKey {
  updatedBy = 'updatedByFullName',
  updatedAt = 'updatedAt',
}

export const useGetDraftInitiativesTableContent = () => {
  const { t } = useTranslation();

  const InitiativeNameCell = ({ row: { original: request } }: { row: MRT_Row<RequestDraft> }): React.ReactNode => {
    return <Box component='span'>{request.name}</Box>;
  };

  const UpdatedByCell = ({ row: { original: request } }: { row: MRT_Row<RequestDraft> }): React.ReactNode => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Avatar alt={request.updatedByFullName} src={request.updatedByAvatarUrl} />
      <Typography data-testid={`requestId-${request.id}-requesterName`}>{request.updatedByFullName}</Typography>
    </Stack>
  );

  const UpdatedAtCell = ({ row: { original: request } }: { row: MRT_Row<RequestDraft> }): React.ReactNode => (
    <Box component='span' data-testid={`requestId-${request.id}-updatedAt`}>
      {formatDate(request.updatedAt as string)}
    </Box>
  );

  const columns = [
    {
      Cell: InitiativeNameCell,
      accessorFn: (request: RequestDraft) => request.name,
      header: t('requests_view:request_list_header.initiative_name'),
      size: 300,
    },
    {
      Cell: UpdatedByCell,
      accessorKey: AccessorKey.updatedBy,
      header: t('requests_view:draft_request_list_header.updated_by'),
      size: 240,
    },
    {
      Cell: UpdatedAtCell,
      accessorKey: AccessorKey.updatedAt,
      enableColumnFilter: false,
      header: t('requests_view:draft_request_list_header.updated_at'),
      size: 150,
    },
  ];

  return { columns };
};
