import { Collapse, List } from '@mui/material';
import React from 'react';

import { NavListProps } from './nav-list.props';

export const NavList: React.FC<NavListProps> = (props) => {
  const { children, expandable, expanded = true, ...listProps } = props;

  return (
    <>
      {expandable ? (
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <List {...listProps}>{children}</List>
        </Collapse>
      ) : (
        <List {...listProps}>{children}</List>
      )}
    </>
  );
};
