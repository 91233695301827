import { ContentElementBlock } from 'shared/models/vendor-knowledge-item.model';

export function isVideoInContent(contentBlocks: ContentElementBlock[]): boolean {
  const blocks = contentBlocks.filter((item) => item.type === 'blok');

  if (!blocks.length) {
    return false;
  }

  const firstVideo = blocks.some((block) => {
    const { attrs } = block;
    if (!attrs?.body) {
      return false;
    }

    return attrs.body.some((item) => item.component === 'Video');
  });

  return !!firstVideo;
}
