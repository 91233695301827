import { useMutation, useQuery } from 'react-query';
import {
  getRenewalReminder,
  markRenewalCompleted,
  muteNextReminder,
  RenewalAction,
} from 'shared/logic/renewal-reminders.logic';
import { ReminderRenewalAlert } from 'shared/models/subscription-renewal-reminder-alert.model';

export const RENEWAL_REMINDER_QUERY_KEY = 'renewal-reminder';

export function useSaveRenewalDetails(renewalAction: RenewalAction, subscriptionId: string) {
  return useMutation(async (id: string) => {
    return markRenewalCompleted(renewalAction, id, subscriptionId);
  });
}

export function useMuteRenewal() {
  return useMutation(async (id: string) => {
    return muteNextReminder(id);
  });
}

export function useRenewalReminder(subscriptionId: string) {
  return useQuery<ReminderRenewalAlert>(
    [RENEWAL_REMINDER_QUERY_KEY, subscriptionId],
    async () => getRenewalReminder(subscriptionId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}
