import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Typography } from '@mui/material';
import classnames from 'classnames';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { NumberFormatInput } from 'components/number-format-input';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { forwardRef, useImperativeHandle } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Currency, CurrencyOptions } from 'shared/common.definitions';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';
import { updateSubscription } from 'shared/logic/subscription-item.logic';
import { Company, Subscription } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';

import { useExpendAndBudgetForm } from './spend-and-budget-form.hook';

type SpendAndBudgetProps = {
  subscriptionId: string;
  showNotification: ((message: string, type: NotificationAlertType) => void) | undefined;
  closeModal: (() => void) | undefined;
};

export const SpendAndBudgetModal = forwardRef((props: SpendAndBudgetProps, ref) => {
  const { closeModal, showNotification, subscriptionId } = props;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const subscription = queryClient.getQueryData<Subscription>(['subscription', subscriptionId]);
  const company = queryClient.getQueryData<Company>('company');

  const currencySymbol = company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY;
  const budgetCents = Number(subscription?.budgetCents) / 100 || null;
  const form = useExpendAndBudgetForm(budgetCents);

  const { errors } = form.formState;

  const { mutate: updateYearlyBudget } = useMutation(updateSubscription);

  const onUpdateBudget = async () => {
    const budgetCents = Math.round(Number(form.watch('budgetCents')) * 100) || null;
    const clonedSubscription: Partial<Subscription> = {
      ...subscription,
      budgetCents,
    };
    dispatch(updateFooterState({ isFormSubmitting: true }));

    updateYearlyBudget(clonedSubscription as Subscription, {
      onError: (error) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSettled: () => {
        dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
      },
      onSuccess: (data) => {
        const customActionMessage = budgetCents
          ? t(
              'subscription_detail_view:subscription_detail_sidebar_section.yearly_budget_section.update_yearly_budget_message'
            )
          : t(
              'subscription_detail_view:subscription_detail_sidebar_section.yearly_budget_section.remove_yearly_budget_message'
            );

        closeModal?.();
        showNotification?.(customActionMessage, NotificationAlertType.Success);

        queryClient.setQueryData(['subscription', subscriptionId], data);
        queryClient.invalidateQueries('auditLogs');
        queryClient.invalidateQueries('subscription-history');
      },
    });
  };

  useImperativeHandle(ref, () => ({
    onUpdateBudget,
  }));

  const removeBudgetHandler = () => {
    if (Number(form.watch('budgetCents'))) {
      form.setValue('budgetCents', 0);
    }
  };

  return (
    <Box my={1} display='flex' flexDirection='column' className={classnames('spend-and-budget-modal')}>
      <Typography variant='label' color='text.secondary' sx={{ mb: 1 }}>
        {t(
          'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.label_text'
        )}
      </Typography>

      <Controller
        name='budgetCents'
        control={form.control}
        render={({ field: { name, onChange, value } }) => (
          <>
            <NumberFormatInput
              decimalScale={2}
              decimalSeparator='.'
              fixedDecimalScale
              thousandSeparator
              prefix={currencySymbol}
              value={value}
              onValueChange={(value) => {
                onChange(value.floatValue || null);
              }}
              placeholder={t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.placeholder_text'
              )}
            />

            {errors[name] && <div className={classnames('field-error', 'mt-1')}>{errors[name]?.message}</div>}
          </>
        )}
      />

      <div
        className={classnames('remove-budget-section', { disabled: !Number(form.watch('budgetCents')) })}
        data-testid='remove-budget'
        tabIndex={0}
        role='button'
        onKeyUp={(e) => onEnterOrSpaceKeyUp(e, removeBudgetHandler)}
        onClick={removeBudgetHandler}>
        {t(
          'subscription_detail_view:subscription_detail_header_section.yearly_budget_modal.modal_title_section.remove_budget_title_text'
        )}
        <i className='uil-trash-alt' />
      </div>
    </Box>
  );
});
