import { combineReducers } from 'redux';
import { getPersistConfig } from 'redux-deep-persist';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { discoveredPanelReducers } from './panel-discovered.reducer';
import { inUsePanelReducers } from './panel-in-use.reducer';
import { inactivePanelReducers } from './panel-inactive.reducer';

export enum StateStoreKeys {
  'inUsePanel' = 'inUsePanel',
  'discoveredPanel' = 'discoveredPanel',
  'inactivePanel' = 'inactivePanel',
}

const reducers = combineReducers({
  discoveredPanel: discoveredPanelReducers,
  inUsePanel: inUsePanelReducers,
  inactivePanel: inactivePanelReducers,
});

const persistConfig = getPersistConfig({
  key: 'sastrify:companyTools',
  rootReducer: reducers,
  storage,
  version: 1,
  whitelist: [
    'inUsePanel.table.columnOrder',
    'inUsePanel.table.columnPinning',
    'inUsePanel.table.columnVisibility',
    'inUsePanel.table.sorting',
    'discoveredPanel.table.columnOrder',
    'discoveredPanel.table.columnPinning',
    'discoveredPanel.table.columnVisibility',
    'discoveredPanel.table.sorting',
    'inactivePanel.table.columnOrder',
    'inactivePanel.table.columnPinning',
    'inactivePanel.table.columnVisibility',
    'inactivePanel.table.sorting',
    'inUsePanel.filters',
  ],
});

export const companyToolsReducers = persistReducer(persistConfig, reducers);
