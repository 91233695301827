import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { LoadingComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useFetchData } from 'hooks/index';
import { FC } from 'react';
import {
  getCommentTemplates,
  getSubscriptionDocuments,
  getSubscriptionHistory,
  getSubscriptionSpendAndInvoices,
  showdownConverter,
} from 'shared/logic/subscription-item.logic';
import { CommentTemplate, Document, SubscriptionHistory, SubscriptionSpendAndInvoices } from 'shared/models';

import { SubscriptionTimelineFeature } from '..';
import { CommentTemplateFeature } from '../comment-template';
import { SubscriptionHistoryTabFeatureProps } from './subscription-history-tab.feature.props';

export const SubscriptionHistoryTabFeature: FC<SubscriptionHistoryTabFeatureProps> = ({
  closeModal,
  showModal,
  showNotification,
  subscription,
  subscriptionHistoryCache,
}) => {
  const subscriptionId = subscription.id;

  const { data: subscriptionHistory, isLoading: isLoadingHistory } = useFetchData<SubscriptionHistory[]>(
    ['subscription-history', subscriptionId],
    () => getSubscriptionHistory(subscriptionId),
    {
      enabled: Boolean(subscriptionId),
      initialData: subscriptionHistoryCache,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: spendAndInvoices, isLoading: isSpendAndInvoicesLoading } = useFetchData<SubscriptionSpendAndInvoices[]>(
    'subscription-spend-and-invoices',
    () => getSubscriptionSpendAndInvoices(subscriptionId),
    {
      onError: (error: unknown) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      refetchInterval: 1.74e6,
      refetchOnWindowFocus: false,
    }
  );

  const { data: documents, isLoading: isLoadingDocuments } = useFetchData<Document[]>(
    'subscriptionDocuments',
    () => getSubscriptionDocuments(subscriptionId),
    {
      onError: (error: unknown) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      refetchInterval: 1.74e6,
      refetchOnWindowFocus: false,
    }
  );

  const { data: commentTemplates } = useFetchData<CommentTemplate[]>('comment-templates', getCommentTemplates, {
    refetchOnWindowFocus: false,
  });

  let commentTemplatesMarkdown: CommentTemplate[] | undefined = commentTemplates;

  if (commentTemplatesMarkdown) {
    commentTemplatesMarkdown = commentTemplatesMarkdown.map((commentTemplate) => ({
      ...commentTemplate,
      template: showdownConverter.makeHtml(commentTemplate.template),
    }));
  }

  const isSubscriptionTimelineLoading = isLoadingHistory || isLoadingDocuments || isSpendAndInvoicesLoading;

  return (
    <>
      <CommentTemplateFeature
        commentTemplates={commentTemplatesMarkdown}
        showNotification={showNotification}
        subscriptionId={subscriptionId}
      />
      {isSubscriptionTimelineLoading ? (
        <LoadingComponent />
      ) : (
        <SubscriptionTimelineFeature
          history={subscriptionHistory as SubscriptionHistory[]}
          subscription={subscription}
          showNotification={showNotification}
          showModal={showModal}
          closeModal={closeModal}
          documents={documents || []}
          spendAndInvoices={spendAndInvoices || []}
        />
      )}
    </>
  );
};
