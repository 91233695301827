/* eslint-disable react-hooks/exhaustive-deps */
import { AlertProps, Box, ToolbarProps, useTheme } from '@mui/material';
import {
  MaterialReactTable as MuiReactTable,
  MRT_ColumnDef,
  MRT_LinearProgressBar as MRTLinearProgressBar,
  MRT_RowData,
  MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
  MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MRTToggleFiltersButton,
  MRT_ToggleFullScreenButton as MRTToggleFullScreenButton,
  MRT_ToolbarAlertBanner as MRTToolbarAlertBanner,
  MRT_ToolbarInternalButtons as MRTToolbarInternalButtons,
  useMaterialReactTable,
} from 'material-react-table';
import { FC, useCallback, useMemo } from 'react';

import { TableSearch } from './components/table-search';
import { getCellProps, getMergedMuiProps } from './helpers';
import { useTablePagination } from './hooks/use-table-pagination';
import { MaterialReactTableComponentProps } from './material-react-table.props';
import { MaterialReactTableWrapper } from './material-react-table.styles';

export const MaterialReactTable: FC<MaterialReactTableComponentProps> = (props) => {
  const { data, search, sx, ...rest } = props;
  const theme = useTheme();

  const isToolbarBannerPositionedBottom = props.positionToolbarAlertBanner === 'bottom';
  const defaultToolbar: ToolbarProps = useMemo(() => ({ sx: { pt: `${theme.spacing(1)} !important` } }), []);
  const defaultAlertBanner: ToolbarProps = useMemo(() => ({ sx: { background: 'transparent !important' } }), []);

  const getMuiTableHeadCellProps = getCellProps.bind(this, props.muiTableHeadCellProps);
  const getMuiTableBodyCellProps = getCellProps.bind(this, props.muiTableBodyCellProps);
  const getTopToolbarProps = getMergedMuiProps.bind(this, props.muiTopToolbarProps, defaultToolbar);
  const getBottomToolbarProps = getMergedMuiProps.bind(this, props.muiBottomToolbarProps, defaultToolbar);
  const getToolbarAlertBannerProp = getMergedMuiProps.bind(this, props.muiToolbarAlertBannerProps, defaultAlertBanner);

  const { pagination, updatePagination } = useTablePagination(props);

  const table = useMaterialReactTable({
    ...rest,
    columns: props.columns as MRT_ColumnDef<MRT_RowData, unknown>[],
    data: data ?? [],
    globalFilterFn: 'contains',
    muiBottomToolbarProps: getBottomToolbarProps as ToolbarProps,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 25, 30, 50],
      ...props.muiPaginationProps,
    },
    muiTableBodyCellProps: getMuiTableBodyCellProps,
    muiTableHeadCellProps: getMuiTableHeadCellProps,
    muiToolbarAlertBannerProps: getToolbarAlertBannerProp as AlertProps,
    muiTopToolbarProps: getTopToolbarProps as ToolbarProps,
    onPaginationChange: props.onPaginationChange ?? updatePagination,
    positionToolbarDropZone: 'none',
    renderToolbarInternalActions: ({ table }) => (
      <>
        {props.enableFilters && props.enableColumnFilters && props.columnFilterDisplayMode !== 'popover' && (
          <MRTToggleFiltersButton table={table} />
        )}
        {(props.enableHiding || props.enableColumnOrdering || props.enableColumnPinning) && (
          <MRTShowHideColumnsButton table={table} />
        )}
        {props.enableDensityToggle && <MRTToggleDensePaddingButton table={table} />}
        {props.enableFullScreenToggle && <MRTToggleFullScreenButton table={table} />}
      </>
    ),
    renderTopToolbar: ({ table }) => {
      return (
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ display: 'flex', padding: '8px 0 16px' }}>
            {!isToolbarBannerPositionedBottom && (
              <Box
                data-testid='mrt-toolbar-banner'
                sx={{ '.MuiStack-root': { whiteSpace: 'nowrap' }, left: 0, position: 'absolute', top: 1 }}>
                <MRTToolbarAlertBanner table={table} />
              </Box>
            )}
            <Box sx={{ alignItems: 'center', display: 'flex', marginLeft: 'auto' }}>
              <Box>{props.renderTopToolbarCustomActions?.({ table })}</Box>
              <MRTToolbarInternalButtons table={table} />
            </Box>
          </Box>
          <MRTLinearProgressBar isTopToolbar table={table} />
        </Box>
      );
    },
    state: {
      pagination: props.state?.pagination ?? pagination,
      ...props.state,
    },
  });

  const onSearchChange = useCallback(
    (val: string) => {
      if (typeof search === 'object') search.onChange?.(val);
      table.setGlobalFilter(val);
    },
    [search, table]
  );

  return (
    <MaterialReactTableWrapper sx={sx} data-testid='material-react-table-wrapper'>
      {Boolean(search) && (
        <Box
          sx={{ left: 0, position: 'absolute', top: 0, zIndex: 2, ...(props.enableRowSelection ? { top: -44 } : {}) }}>
          <TableSearch {...(typeof search === 'object' ? search : {})} onChange={onSearchChange} />
        </Box>
      )}
      <MuiReactTable table={table} />
    </MaterialReactTableWrapper>
  );
};
