import { Box } from '@mui/material';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { colors } from 'shared/theme';
import UAParser from 'ua-parser-js';

export interface ShortcutInfoProps {
  actionText?: string;
  bottomSectionStyles?: { [style: string]: string };
  className: string;
  editorHasContent: boolean;
}

export const ShortcutInfo: React.FC<ShortcutInfoProps> = ({
  actionText,
  bottomSectionStyles,
  className,
  editorHasContent,
}) => {
  const userOS = new UAParser().getOS();
  const shortCutKeys = userOS?.name?.toLowerCase().includes('mac') ? ['Meta', 'Enter'] : ['Control', 'Enter'];

  return (
    <Box
      className={classNames(className, 'editor-shortcut-info')}
      color={editorHasContent ? colors.primary : colors.alto}
      sx={{ ...bottomSectionStyles }}>
      <Trans
        i18nKey='common:comment_editor.send_comment_text'
        values={{
          actionText: actionText || 'send comment',
          firstKey: shortCutKeys[0] === 'Meta' ? 'Cmd ⌘' : shortCutKeys[0],
          secondKey: shortCutKeys[1],
        }}
        components={[<span />]}
      />
    </Box>
  );
};
