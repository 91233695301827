import { Grid } from '@mui/material';
import classnames from 'classnames';
import { PERMISSION } from 'libs/enums/permissions';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getSubscriptionStatus } from 'shared/logic/subscription-item.logic';

import { useCheckUserPermission } from '../../hooks';
import { ChevronDownIcon } from '../icons';
import { StatusComponentProps } from './status.component.props';
import { classes, Root } from './status.styles';

export const StatusComponent: FC<StatusComponentProps> = ({
  handleClick,
  isDropdown,
  isFilterDrawer,
  isSubscriptionDetail,
  isSubscriptionInDiscovery,
  status,
}) => {
  const { t } = useTranslation();

  const canChangeSolutionStatus = useCheckUserPermission(PERMISSION.UPDATE_NEW_SOLUTION_STATUS);

  const changeStatus = isSubscriptionInDiscovery ? canChangeSolutionStatus : true;

  return (
    <Root>
      {isFilterDrawer ? (
        <Grid data-testid='filter-drawer' container className={classes.status} alignItems='center'>
          <Grid item lg={1}>
            <section
              data-testid='status-box'
              className={classnames('status-box', getSubscriptionStatus(status).className)}
            />
          </Grid>
          <Grid item>
            <p>{t(getSubscriptionStatus(status).status)}</p>
          </Grid>
        </Grid>
      ) : (
        <section className={classes.root}>
          <button
            type='button'
            data-testid='status'
            className={classnames(
              'subscription-status',
              getSubscriptionStatus(status).className,
              !!handleClick && 'is-clickable',
              classes.noBorder
            )}
            onClick={changeStatus ? handleClick : undefined}>
            {t(getSubscriptionStatus(status).status)}
            {isSubscriptionDetail && !isDropdown && changeStatus && (
              <span data-testid='drop-down-icon'>
                <ChevronDownIcon />
              </span>
            )}
          </button>
        </section>
      )}
    </Root>
  );
};
