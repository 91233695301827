import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Avatar, Icon } from 'asteroids';
import { AxiosError } from 'axios';
import { FilesListComponent } from 'components/drawer-workflow-request/components/files-list/files-list.component';
import { FileUploadComponent } from 'components/drawer-workflow-request-details/components/file-upload/file-upload.component';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { DEFAULT_SUPPORT_AGENT, DRAWER_VARIANT, REQUEST_STATUS } from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { deleteRequestDocument, downloadDocument, uploadRequestDocument } from 'shared/logic/requests.logic';
import { Company } from 'shared/models';

import { useAsteroidsDialog } from '../../context/asteroids-dialog.context';
import { TimelineComponent } from '../../views/requests/components/timeline/timeline.component';
import { StateType, TimelineItem } from '../../views/requests/components/timeline/timeline.component.props';
import {
  useAddRequestDrawer,
  useWorkflowActivitiesData,
  useWorkflowRequestDetailsData,
} from '../../views/requests/hooks';
import { ContactSastrifyFormComponent } from '../drawer-workflow-request-details/components/contact-sastrify-form/contact-sastrify-form.component';
import { DialogComponent } from '../drawer-workflow-request-details/components/dialog/dialog.component';
import { DialogComponentProps } from '../drawer-workflow-request-details/components/dialog/dialog.component.props';

type IProps = {
  handleCloseDrawer: () => void;
  showNotification: ((message: string, type: NotificationAlertType) => void) | undefined;
  vendorName: string;
  requestId: number;
  workflowTypeId: number;
  subscriptionId: number;
};

const ContactSastrifyComponent: FC<IProps> = ({
  handleCloseDrawer,
  requestId,
  showNotification,
  subscriptionId,
  vendorName,
  workflowTypeId,
}) => {
  const { t } = useTranslation();
  const { closeDialog: closeAsteroidsDialog, openDialog: openAsteroidsDialog } = useAsteroidsDialog();
  const { onAddRequestDrawer } = useAddRequestDrawer();

  const handleCloseContactSastrifyDialog = () => {
    closeAsteroidsDialog?.();
    onAddRequestDrawer(`#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`);
  };

  const openContactSastrifyDialog = () => {
    handleCloseDrawer();
    openAsteroidsDialog?.({
      bodyContent: (
        <ContactSastrifyFormComponent
          closeDialogAndOpenDrawer={handleCloseContactSastrifyDialog}
          showNotification={showNotification}
          requestId={requestId}
          closeDialog={closeAsteroidsDialog}
          vendorName={vendorName}
          workflowTypeId={workflowTypeId}
          subscriptionId={subscriptionId}
        />
      ),
      onClose: handleCloseContactSastrifyDialog,
      width: 1050,
    });
  };

  return (
    <Box my={4}>
      <Stack direction='column' spacing={1} mb={3}>
        <Typography variant='h2' data-testid='get-in-touch-title'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.contact_sastrify_section.title'
          )}
        </Typography>
        <Typography variant='body' data-testid='get-in-touch-subtitle'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.contact_sastrify_section.new_subtitle'
          )}
        </Typography>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Avatar alt={DEFAULT_SUPPORT_AGENT.team} src={DEFAULT_SUPPORT_AGENT.avatar} />
          <Stack direction='column'>
            <Typography data-testid='csm-contact' noWrap>
              {DEFAULT_SUPPORT_AGENT.team}
            </Typography>
            <Typography color='textSecondary' variant='small'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.contact_sastrify_section.csm_company'
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Button
        startIcon={<Icon fontSize='small'>{CONTACT_SUPPORT_ICON}</Icon>}
        variant='contained'
        onClick={openContactSastrifyDialog}
        data-testid='contact-sastrify-button'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.contact_sastrify_section.button_new_text'
        )}
      </Button>
    </Box>
  );
};

type RequestDetailsSectionProps = {
  handleCloseDrawer: () => void;
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
};

const formatDate = (date = '') => format(new Date(date), 'MMMM dd, yyyy');
const CONTACT_SUPPORT_ICON = 'contact_support';

export const RequestDetailsSection: FC<RequestDetailsSectionProps> = ({ handleCloseDrawer, showNotification }) => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const queryClient = useQueryClient();

  const requestId = hash.split(DRAWER_VARIANT.REQUEST_DETAILS).slice(-1)[0];

  const {
    data: detailsData,
    isLoading: isDetailsLoading,
    refetch: refetchDetails,
  } = useWorkflowRequestDetailsData(requestId);
  const { refetch: refetchActivities } = useWorkflowActivitiesData(requestId);
  const company = queryClient.getQueryData<Company>('company');

  const hasDocuments = detailsData?.documents && detailsData?.documents?.length > 0;

  const [dialog, setDialog] = useState<DialogComponentProps>({ open: false });
  const [isToolActivated, setIsToolActivated] = useState(false);
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);

  const { mutate: deleteDocument } = useMutation(deleteRequestDocument, {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'Delete request document error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSettled: () => {
      setDialog({ open: false });
    },
    onSuccess: () => {
      refetchDetails();
      refetchActivities();
      showNotification?.(
        t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.successfully_deleted_message'
        ),
        NotificationAlertType.Success
      );
    },
  });

  useEffect(() => {
    if (detailsData && detailsData?.requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED) {
      setIsToolActivated(true);
    }
  }, [detailsData]);

  const getTimelineData = (): TimelineItem[] => {
    const states: { [key: string]: StateType } = {
      approved: 'success',
      pending: 'pending',
      rejected: 'error',
    };

    return [
      ...(detailsData?.timeline?.map(({ stepId, stepName, stepStatus, updatedAt }, index) => ({
        id: index,
        state: states[stepStatus],
        ...(updatedAt && { subtitle: formatDate(updatedAt) }),
        active: Number(stepId) === detailsData.currentStepId,
        title: stepName,
      })) || []),
    ];
  };

  const openDocumentDeleteConfirmationDialog = (documentId: number) => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.confirm_button_label'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.cancel_button_label'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.message'
      ),
      onAction: () => {
        setDialog((oldState) => ({ ...oldState, disabledActionBtn: true }));
        deleteDocument(documentId);
      },
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.title'
      ),
    });
  };

  const handleOnDownload = async (fileName: string, fileUrl: string, fileId?: string) => {
    setIsDownloadingDocument(true);
    await downloadDocument(fileName, fileUrl, fileId);
    setIsDownloadingDocument(false);
  };

  const renderButton = () => (
    <Box px='3rem' pb='2rem'>
      <Button variant='text' color='primary' startIcon={<WestRoundedIcon />} onClick={handleCloseDrawer}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_text'
        )}
      </Button>
    </Box>
  );

  const renderTimelineInfo = () => (
    <Stack direction='column' spacing={1}>
      <Typography data-testid='timeline-heading' variant='h2' mb={0}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.request_details_timeline_title'
        )}
      </Typography>
      <Box px='0.5rem'>
        <TimelineComponent items={getTimelineData()} size='small' />
      </Box>
      <Divider />
      <Typography color='textSecondary' variant='small'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.request_details_timeline_new_caption'
        )}
      </Typography>
    </Stack>
  );

  const renderFileUpload = () => (
    <FileUploadComponent
      showNotification={showNotification}
      companyId={company?.id}
      referenceId={Number(requestId || 0) || 0}
      uploadFunction={uploadRequestDocument}
      refetchData={async () => {
        await refetchDetails();
        await refetchActivities();
      }}
    />
  );

  const renderDocumentList = () => (
    <Stack direction='column' spacing={1}>
      <Typography data-testid='timeline-heading' variant='h2' mb={0}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.attachments_new_label'
        )}
      </Typography>
      <FilesListComponent
        files={detailsData?.documents || []}
        onRemove={openDocumentDeleteConfirmationDialog}
        onDownload={handleOnDownload}
        disableDownloadButton={isDownloadingDocument}
        textTruncate
      />
    </Stack>
  );

  const renderDocumentSection = () => (
    <>
      {hasDocuments && renderDocumentList()}
      {!isToolActivated && renderFileUpload()}
    </>
  );

  return (
    <>
      {!isDetailsLoading && (
        <Box sx={{ maxHeight: '100vh', overflowY: 'auto', padding: '4rem 0' }}>
          {renderButton()}
          <Box px='2.5rem'>
            <Typography variant='h2' mb='2rem'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.left_drawer_title.request_details_new_title'
              )}
            </Typography>
            {renderTimelineInfo()}
            <ContactSastrifyComponent
              handleCloseDrawer={handleCloseDrawer}
              showNotification={showNotification}
              vendorName={detailsData?.vendorName || ''}
              requestId={Number(requestId)}
              workflowTypeId={Number(detailsData?.requestWorkflowTypeId || 0)}
              subscriptionId={Number(detailsData?.subscriptionId || 0)}
            />
            {renderDocumentSection()}
          </Box>
        </Box>
      )}
      {/* Asteroids dialog doesn't accept disableEnforceFocus prop and therefore has an issue when opened over a Drawer. Until component is updated use this local instead. */}
      <DialogComponent {...dialog} />
    </>
  );
};
