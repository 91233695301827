export const DISABLE_EMAILS_SENDING = 'disable_emails_sending';
export const WEEKLY_SUMMARY_EMAIL = 'weekly_summary_email';
export const DISCOVERED_SUBSCRIPTION_COMMENT = 'discovered_subscription_comment';
export const DISABLE_NEW_VENDOR_INSIGHTS_NOTIFICATIONS = 'disable_new_vendor_insights_notifications';
export const ENABLE_UNSEEN_INSIGHTS = 'enable_insights_seen';
export const MAX_NUMBER_OF_INVOICES_LOADED = 1000;
export const PAGE_NUMBER_OF_INVOICES = 50;
export const PAGE_NUMBER_OF_COMPANY_INVOICES = 10;
export const RENEWAL_REMINDER_PERIODIC_ALERT = 'renewal_reminder_periodic_alert';
export const ENABLE_RENEWAL_REMINDERS_V2_API = 'enable_tool_details_renewal_reminders_v2_api';
export const ENABLE_SLACK_INTERACTIVE_NOTIFICATIONS = 'enable_slack_interactive_notifications';
export const ENABLE_SLACK_INFORMATIVE_NOTIFICATIONS = 'slack_informative_initiative_request_notifications';
export const ENABLE_SLACK_RENEWAL_REMINDER_CHANNEL_PREFERENCES = 'slack_renewal_reminder_enable_channel_preferences';
