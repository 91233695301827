import { formatCurrency } from 'shared/helpers/common.helper';
import { capitalize } from 'shared/logic/subscription-item.logic';
import { Company } from 'shared/models';
import { SubscriptionSpendAndInvoices } from 'shared/models/subscription-spend-and-invoices.model';

export const getItemAmount = (item: SubscriptionSpendAndInvoices) => {
  return item.processing
    ? capitalize(item.displayAmountCents)
    : formatCurrency({
        currency: item.amountCurrency,
        isDecimal: true,
        style: 'currency',
        value: Number(item.amountCents),
      });
};

export const getItemConversion = (item: SubscriptionSpendAndInvoices, company?: Company) => {
  return item.processing
    ? capitalize(item.displayAmountCents)
    : formatCurrency({
        currency: item.companyCurrency ?? company?.currency,
        isDecimal: true,
        style: 'currency',
        value: Number(item.companyAmountCents),
      });
};
