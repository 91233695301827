import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TabItem } from '../page.props';

const getActiveTabIndexOrDefault = (tabs: TabItem[], locationHash: string) => {
  if (!locationHash) {
    return 0;
  }
  const index = tabs.findIndex((tab) => tab.hash === locationHash.slice(1));
  return index === -1 ? 0 : index;
};

export const useTabs = (tabs: TabItem[]) => {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(getActiveTabIndexOrDefault(tabs, location.hash));

  useEffect(() => {
    if (location.hash) {
      const activeTabIndex = getActiveTabIndexOrDefault(tabs, location.hash);
      if (activeTabIndex !== -1) {
        setActiveTab(activeTabIndex);
      }
    }
  }, [location.hash, tabs]);

  const handleTabChange = (tab: number) => {
    setActiveTab(tab);
    history.replace(`${location.pathname}#${tabs[tab].hash}`);
  };

  return { activeTab, setActiveTab: handleTabChange };
};
