import './card-header.style.scss';

import { FC } from 'react';

import { CardHeaderProps } from '.';

export const CardHeader: FC<CardHeaderProps> = ({ action, cardIcon, className }) => {
  return (
    <section className={`${className} card-header`}>
      <div>{cardIcon}</div>
      <div>{action}</div>
    </section>
  );
};
