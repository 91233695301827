import { Box, Typography } from '@mui/material';
import { ConnectionStatus } from 'asteroids';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCodatIcon } from 'shared/helpers/connect.helper';
import { CodatConnectionStatus } from 'shared/models/integration-item.model';

import { AccountingConnectionViewOnlyProps } from './accounting-connection.component.props';
import { AccountingConnectionViewOnlyContainer, ConnectionIconBox } from './accounting-connection.styles';

export const AccountingConnectionViewOnly: React.FC<AccountingConnectionViewOnlyProps> = ({
  connectionTitle,
  type,
}) => {
  const { t } = useTranslation();

  const connectedText = t('connect_view:tab_section.codat.accountingConnection.connectedText');

  return (
    <AccountingConnectionViewOnlyContainer>
      <Box display='flex' alignItems='center'>
        <ConnectionIconBox>
          <img aria-label='connection-icon' src={getCodatIcon(type)} />
        </ConnectionIconBox>
        <Typography ml={1} variant='h3'>
          {connectionTitle}
        </Typography>
      </Box>
      <Box>
        <ConnectionStatus status={CodatConnectionStatus.Connected as any} title={connectedText} />
      </Box>
    </AccountingConnectionViewOnlyContainer>
  );
};

export default AccountingConnectionViewOnly;
