import { Popper, PopperProps, styled, useTheme } from '@mui/material';

import { shadows } from '../../theme/shadow.styles';

const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: shadows.list,
  '& .MuiPaper-root.MuiAutocomplete-paper': {
    backgroundColor: theme.palette.background.light,
    boxShadow: 'none',
    '& .MuiAutocomplete-listbox': {
      backgroundColor: theme.palette.background.light,
    },
  },
}));

export const PopperComponent = ({ hasHelperText, ...rest }: PopperProps & { hasHelperText: boolean }) => {
  const theme = useTheme();
  const spacingWithoutPx = theme.spacing(3).replace('px', '');

  return (
    <StyledPopper
      {...rest}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, hasHelperText ? spacingWithoutPx : 0],
          },
        },
      ]}
    />
  );
};

export const StyledListItem = styled('li')(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  color: theme.palette.text.primary,
  '.MuiIcon-root, .MuiAvatar-root': {
    marginRight: theme.spacing(1),
  },
}));
