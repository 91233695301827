import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UserRoles } from 'shared/common.definitions';
import { isToolRequestsRoute } from 'shared/helpers/common.helper';
import { SastrifyStore, User } from 'shared/models';

export const useCustomTaskActions = (assigneeId: string) => {
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;
  const location = useLocation();

  const [showCustomTaskActions, setShowCustomTaskActions] = useState<boolean>(true);

  const isToolRequestView = isToolRequestsRoute(location.pathname);
  const isViewerRole = user.role === UserRoles.Viewer;

  useEffect(() => {
    if (isViewerRole && isToolRequestView && user.id !== assigneeId) {
      setShowCustomTaskActions(false);
    }
  }, [assigneeId, isToolRequestView, isViewerRole, user]);

  return showCustomTaskActions;
};
