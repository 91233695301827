import { FC } from 'react';
import { RootState } from 'shared/store';
import { useAppSelector } from 'shared/store/hooks';

import {
  ManageGoogleSSOConnection,
  ManageJumpCloudSSOConnection,
  ManageMicrosoftSSOConnection,
  ManageWorkatoSSOConnection,
} from './providers-drawers';

export const DrawerManageSSOIntegration: FC = () => {
  const { ssoProviderName } = useAppSelector(
    (state: RootState) => state.common.appDrawer.data as { ssoProviderName: string }
  );

  switch (ssoProviderName) {
    case 'Google':
      return <ManageGoogleSSOConnection />;
    case 'Microsoft':
      return <ManageMicrosoftSSOConnection />;
    case 'Okta':
      return <ManageWorkatoSSOConnection />;
    case 'JumpCloud':
      return <ManageJumpCloudSSOConnection />;
    default:
      return null;
  }
};
