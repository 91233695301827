/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Autocomplete, AutocompleteOption, Avatar, DatePicker, Icon } from 'asteroids';
import { AxiosError } from 'axios';
import { WorkflowRequestWrapper } from 'components/drawer-workflow-request/drawer-workflow-request.component.styles';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { TypeaheadComponent } from 'components/typeahead';
import { DialogContext } from 'context/dialog.context';
import { format, sub } from 'date-fns';
import { REQUEST_STATES, REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CSM_USER_EMAIL,
  DocumentCategory,
  DRAWER_VARIANT,
  RENEWAL_INITIATIVE_EVENT_SOURCE,
  UserRoles,
} from 'shared/common.definitions';
import { getHash } from 'shared/helpers/common.helper';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import {
  RenewalActionEventType,
  trackRenewalReminderAction,
} from 'shared/logic/event-tracking/renewal-reminder.events';
import { trackWorkflowRequestCreated } from 'shared/logic/event-tracking/workflow-requests.events';
import { RenewalAction } from 'shared/logic/renewal-reminders.logic';
import { createNewRequest, editRequest, getRequestDetails, getRequestWorkflows } from 'shared/logic/requests.logic';
import { getCompanyDocumentCategory, uploadSubscriptionDocument } from 'shared/logic/subscription-item.logic';
import { Company, CompanyDocument, RequestPayload, ToolOwnerFilter, User } from 'shared/models';
import { RequestResponse } from 'shared/models/request-model';
import { RequestWorkflowItem } from 'shared/models/request-workflow.model';
import { useAppSelector } from 'shared/store/hooks';
import { clearBenchmarkRequestVendorName } from 'shared/store/views/benchmarks/benchmark-request.slice';
import { useAddRequestDrawer } from 'views/requests/hooks';
import {
  getRenewalReminderData,
  invalidateRenewalReminder,
} from 'views/subscription-detail/components/renewal-status-alert/helpers';
import { useFetchSubscriptions } from 'views/subscriptions/hooks/use-display-data';
import * as yup from 'yup';

import { DropzoneComponent } from '../../dropzone/dropzone.component';
import { RenewalFormProps } from './renewal-form.props';

export const DRAWER_FROM_URL = 'routeFrom';

type ToolOwner = { avatar?: string; id?: string; name: string; value?: string };

type FormValues = {
  description: string;
  dueDate?: Date | string;
  toolName: AutocompleteOption | null;
  collaborators?: AutocompleteOption[];
};

const defaultFormValues: FormValues = {
  collaborators: [],
  description: '',
  dueDate: undefined,
  toolName: null,
};

const defaultToolOwner: ToolOwnerFilter = { avatar: '', id: '', name: '' };

const formValidationSchema = yup.object().shape({
  dueDate: yup.date().min(sub(new Date(), { days: 1 })),
  toolName: yup
    .object()
    .shape({
      id: yup.string(),
      label: yup.string(),
      prefix: yup.object(),
    })
    .required(),
});

export const RenewalForm: FC<RenewalFormProps> = ({ initialData, mode, showNotification, toggleDrawer }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { hash, pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const { openDialog } = useContext(DialogContext);
  const { onAddRequestDrawer } = useAddRequestDrawer();
  const theme = useTheme();
  const dispatch = useDispatch();

  const selectedBenchmarkVendorName = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.vendorName);

  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState(defaultToolOwner);
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [toolOwnerError, setToolOwnerError] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isDocumentsUploadInProgress, setIsDocumentsUploadInProgress] = useState(false);
  const [isRefetchingSubscriptions, setIsRefetchingSubscriptions] = useState(false);

  const subscriptionId = hash.includes(DRAWER_VARIANT.NEW_REQUEST)
    ? hash.split(DRAWER_VARIANT.NEW_REQUEST).slice(-1)[0]
    : '';

  const includedHash = getHash(hash);

  const dropzoneComponentRef = useRef<any>(null);

  const requestId = hash.includes(DRAWER_VARIANT.EDIT_REQUEST)
    ? hash.split(DRAWER_VARIANT.EDIT_REQUEST).slice(-1)[0]
    : '';
  const isEditMode = mode === 'edit';
  const isNewRequestMode = mode === 'create';

  const company = queryClient.getQueryData<Company>('company');
  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');
  const requests = queryClient.getQueryData<RequestWorkflowItem[]>('workflow-requests');
  const { data: subscriptionsData, isLoading } = useFetchSubscriptions();

  const subscriptions = useMemo(() => {
    if (!isLoading && subscriptionsData) {
      return subscriptionsData;
    }
    return [];
  }, [isLoading, subscriptionsData]);

  const renewalRequests = useMemo(
    () =>
      requests
        ?.filter(({ requestWorkflowTypeId }) => requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL)
        .filter(
          ({ requestStateId }) =>
            requestStateId === String(REQUEST_STATES.ACTIVE) || requestStateId === String(REQUEST_STATES.APPROVED)
        )
        .map(({ vendorName }) => vendorName),
    [requests]
  );

  const filteredSubscriptions = useMemo(
    () => subscriptions.filter(({ name }) => !renewalRequests?.includes(name || '')),
    [renewalRequests, subscriptions]
  );

  const mapSubscriptionsOptions = useCallback(
    ({ categoryName, name, vendorId, vendorLogoUrl }: any) => {
      if (!name) return;
      return {
        id: vendorId || name,
        label: name,
        prefix: (
          <Box
            className='tool-item-cell'
            sx={{
              alignItems: 'center',
              color: 'text.primary',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              textDecoration: 'none',
            }}>
            <Avatar
              variant='rounded'
              src={vendorLogoUrl}
              imgProps={{ sx: { background: theme.palette.background.light, objectFit: 'contain' } }}
            />
            <Box ml={1} data-testid='item-vendor-name'>
              <Stack direction='row' display='flex' alignItems='center'>
                <Typography variant='label'>{name}</Typography>
              </Stack>
              {categoryName && (
                <Typography variant='small' whiteSpace='nowrap'>
                  {categoryName}
                </Typography>
              )}
            </Box>
          </Box>
        ),
      };
    },
    [theme.palette.background.light]
  );

  const subscriptionsOptions: AutocompleteOption[] = useMemo(
    () =>
      (isEditMode
        ? subscriptions.map(mapSubscriptionsOptions)
        : filteredSubscriptions.map(mapSubscriptionsOptions)
      ).filter((a) => !!a) as AutocompleteOption[],
    [filteredSubscriptions, isEditMode, mapSubscriptionsOptions, subscriptions]
  );

  const userOptions: AutocompleteOption[] = useMemo(
    () =>
      usersAndToolOwnersList
        ?.filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && user.id)
        .map(({ avatar, email, id, name }) => ({
          id: id || '',
          label: name || email || '',
          prefix: (
            <Avatar
              src={avatar}
              imgProps={{ sx: { background: theme.palette.background.light, objectFit: 'contain' } }}
            />
          ),
        })) || [],
    [theme.palette.background.light, usersAndToolOwnersList]
  );

  const handleCloseDrawer = () => {
    replace(`${pathname}${search}${includedHash}`);
    localStorage.removeItem(RENEWAL_INITIATIVE_EVENT_SOURCE);
    toggleDrawer();
  };

  const onModifySuccess = async (data: RequestResponse) => {
    handleCloseDrawer();
    await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });

    invalidateRenewalReminder(queryClient, subscriptionId);
    queryClient.invalidateQueries(['subscription', subscriptionId]);

    openDialog({
      confirmBtnText: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.button_initiative_text'
      ),
      content: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_text'
      ),
      icon: (
        <Icon color='success' sx={{ mr: 1 }}>
          check_circle_outline
        </Icon>
      ),
      onConfirm: () => {
        onAddRequestDrawer(`${includedHash}#${DRAWER_VARIANT.REQUEST_DETAILS}${data.id}`);
      },
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_title'
      ),
    });

    const requestDetails = await queryClient.fetchQuery('workflow-request-details', () => getRequestDetails(data.id));

    trackWorkflowRequestCreated({
      companyId: company?.id,
      companyName: company?.name,
      numberOfContributors: requestDetails?.contributors?.length || 0,
      requestType: 'renewal',
      toolName: getValues().toolName?.label,
      totalStages: requestDetails?.timeline.length || 0,
    });

    const renewalReminderData = await getRenewalReminderData(queryClient, subscriptionId);
    const eventSource = localStorage.getItem(RENEWAL_INITIATIVE_EVENT_SOURCE) as RenewalActionEventType;

    if (renewalReminderData)
      trackRenewalReminderAction({
        action: RenewalAction.StartInitiative,
        renewalReminderId: renewalReminderData?.id,
        source: eventSource,
        subscriptionId,
      });
  };

  const onModifyError = (err: AxiosError) => {
    const message =
      getErrorCodeMessage(err) ||
      t('common:modals.add_new_subscription_feature_modal.error_message', {
        value: 'New initiative',
      });

    showNotification?.(message, NotificationAlertType.Error);
  };

  const { isLoading: isCreateLoading, mutate: mutateCreate } = useMutation(
    (payload: RequestPayload) => createNewRequest(payload),
    {
      onError: onModifyError,
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { isLoading: isEditLoading, mutate: mutateEdit } = useMutation(
    (payload: RequestPayload) => editRequest(payload),
    {
      onError: onModifyError,
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { mutateAsync: uploadDocumentAsync } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      setIsDocumentsUploadInProgress(false);
    },
  });

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    (async () => {
      if (subscriptionId && isNewRequestMode) {
        let subscription = subscriptions.find((subscription) => subscription.id === subscriptionId);

        if (!subscription) {
          setIsRefetchingSubscriptions(true);
          await queryClient.invalidateQueries('subscriptions');
          await queryClient.invalidateQueries('subscriptions-todos');
          setIsRefetchingSubscriptions(false);

          subscription = subscriptions.find((subscription) => subscription.id === subscriptionId);
        }

        const activeSubscriptionOption = subscription ? mapSubscriptionsOptions(subscription) : null;
        setValue('toolName', activeSubscriptionOption || null);
      }
    })();
  }, [isNewRequestMode, subscriptions, subscriptionId, mapSubscriptionsOptions, setValue, queryClient]);

  useEffect(() => {
    let activeSubscriptionOption: AutocompleteOption | undefined;

    if (isNewRequestMode && selectedBenchmarkVendorName) {
      activeSubscriptionOption = subscriptionsOptions.find((option) => option.label === selectedBenchmarkVendorName);
    } else {
      activeSubscriptionOption = subscriptionsOptions.find((option) => option.label === initialData?.vendorName);
    }

    if (activeSubscriptionOption) {
      setValue('toolName', activeSubscriptionOption || null);
    }
  }, [initialData?.vendorName, subscriptionsOptions, setValue, selectedBenchmarkVendorName, isNewRequestMode]);

  useEffect(() => {
    if (isEditMode && initialData) {
      const toolOwner =
        usersAndToolOwnersList
          ?.filter(({ name }) => name === initialData.assigneeFullName)
          .map(({ avatar, id, name }) => ({ avatar: avatar || '', id: id || '', name: name || '' }))[0] ||
        defaultToolOwner;

      setToolOwner(toolOwner);
      setToolOwnerName(toolOwner.name);

      const collaborators = userOptions.filter(({ id }) =>
        initialData.contributors?.map(({ id }) => id).includes(Number(id))
      );

      setValue('collaborators', collaborators);
      setValue('description', String(initialData.description));
      setValue('dueDate', new Date(initialData.dueDate || ''));
    }
  }, [isEditMode, initialData, usersAndToolOwnersList, setValue, userOptions]);

  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList
        .filter((user: User) => user.role !== UserRoles.Viewer)
        .filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && user.id)
        .map((user: User) => ({
          avatar: user?.avatar,
          name: user.name,
          value: user.id || user.name?.replaceAll(' ', '-'),
        }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
      setToolOwnerError(false);
      return;
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner(defaultToolOwner);
      setToolOwnerName('');
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
    setToolOwnerError(false);
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleDocumentsUpload = async (): Promise<CompanyDocument[]> => {
    setIsDocumentsUploadInProgress(true);

    const uploads: unknown[] = [];
    const files = dropzoneComponentRef.current.getFiles();
    const category = getCompanyDocumentCategory(DocumentCategory.other_documents);
    const companyId = String(company?.id);

    files.forEach((file: File) => {
      const payload = {
        category,
        companyId,
        file,
        isCompanyDocument: true,
      };

      uploads.push(uploadDocumentAsync(payload));
    });

    return (await Promise.all(uploads)) as CompanyDocument[];
  };

  const onModifyRequest = async () => {
    trigger();

    if (!isValid) {
      if (!toolOwnerName) {
        setToolOwnerError(true);
      }
      return;
    }
    if (!toolOwnerName) {
      setToolOwnerError(true);
      return;
    }

    const formValues = getValues();
    const value = formValues?.toolName;

    const subscription = subscriptions.find(({ name }: any) => value?.label === name);
    const documents = !isEditMode ? await handleDocumentsUpload() : [];

    const modifyRequestPayload: RequestPayload = {
      ...(isEditMode && { id: Number(requestId || 0) || undefined }),
      contributors: formValues?.collaborators?.map(({ id }) => Number(id)) || [],
      description: formValues.description,
      documents,
      dueDate: formValues.dueDate
        ? format(new Date(formValues.dueDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
      licenseCurrency: company?.currency || '',
      requestWorkflowTypeId: REQUEST_WORKFLOW_TYPE.RENEWAL,
      steps: [
        {
          stepOwnerId: Number(toolOwner.value) || Number(toolOwner.id),
        },
      ],
      tools: [
        ...(subscription?.vendorId
          ? [
              {
                vendorId: Number(subscription?.vendorId),
              },
            ]
          : [
              {
                toolName: value?.label || '',
              },
            ]),
      ],
      ...(isNewRequestMode && { subscriptionId: Number(subscription?.id) }),
    };

    return isEditMode ? mutateEdit(modifyRequestPayload) : mutateCreate(modifyRequestPayload);
  };

  const renderToolField = () => (
    <Box display='flex' flexDirection='column' data-testid='tool-name'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.input_label'
        )}
        *
      </Typography>
      <Controller
        name='toolName'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='toolName'
              label={
                isRefetchingSubscriptions
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.placeholder_loading_text'
                    )
                  : t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.placeholder_text'
                    )
              }
              value={value}
              options={subscriptionsOptions}
              onChange={(_, data: any) => {
                dispatch(clearBenchmarkRequestVendorName());
                onChange(data);
              }}
              error={!!errors.toolName}
              helperText={
                errors.toolName
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.error_message'
                    )
                  : ''
              }
              disableClearable
              ListboxProps={{
                sx: {
                  '& li': { color: 'transparent' },
                },
              }}
              disabled={isEditMode || Boolean(subscriptionId)}
            />
          );
        }}
      />
    </Box>
  );

  const renderAssigneeField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='assignee'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.input_label'
        )}
        *
      </Typography>
      <TypeaheadComponent
        data={toolOwners}
        onItemSelected={onToolOwnerSelected}
        onBlurToolOwnerFilter={onToolOwnerSelected}
        value={toolOwnerName}
        fields={['name', 'email']}
        isToolOwnerFilter
        onTextChanged={onChangeToolOwner}
        isSubscriptionInDiscovery
        renderCustomInput={({
          autoFocus,
          disabled,
          inputTextRef,
          onChange,
          onClick,
          onFocus,
          onKeyDown,
          onKeyUp,
          value,
        }) => (
          <TextField
            id='assignee'
            inputRef={inputTextRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onFocus={(ev) => {
              onFocus?.(ev);
              setFocused(true);
            }}
            onBlur={() => setFocused(false)}
            onClick={onClick}
            disabled={disabled}
            value={value || ''}
            label={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.placeholder_initiative_text'
            )}
            autoFocus={autoFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                  {!isFocused ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>}
                </InputAdornment>
              ),
            }}
            error={toolOwnerError}
            helperText={
              toolOwnerError
                ? t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.assignee_section.error_message'
                  )
                : ''
            }
            fullWidth
          />
        )}
      />
    </Box>
  );

  const renderCollaboratorsField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='collaborators' width='100%'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.input_label'
        )}
      </Typography>
      <Controller
        name='collaborators'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='collaborators'
              filterSelectedOptions
              value={value}
              options={userOptions}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.placeholder_text'
              )}
              helperText={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.helper_text'
              )}
              multiple
              onChange={(_, newCollaborators, reason) => {
                if (reason === 'createOption' && Array.isArray(newCollaborators)) {
                  const autocompleteOptionCollaborators = newCollaborators.slice(0, -1) as AutocompleteOption[];
                  const lastItem = newCollaborators[newCollaborators.length - 1] as string;
                  const lastAutocompleteOptionCollaborator: AutocompleteOption = {
                    id: `${autocompleteOptionCollaborators.length}-${lastItem}`,
                    label: lastItem,
                  };
                  const collaborators: AutocompleteOption[] = [
                    ...autocompleteOptionCollaborators,
                    lastAutocompleteOptionCollaborator,
                  ];

                  return onChange(collaborators);
                }
                onChange(newCollaborators);
              }}
            />
          );
        }}
      />
    </Box>
  );

  const renderDateField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='due-date' width='100%'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.input_label'
        )}
      </Typography>
      <Controller
        name='dueDate'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <DatePicker
              value={value as any}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              minDate={new Date() as any}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.placeholder_initiative_text'
              )}
              error={Boolean(errors.dueDate)}
              helperText={
                errors.dueDate
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.due_date_section.error_message'
                    )
                  : ''
              }
              inputFormat='dd/MM/yyyy'
            />
          );
        }}
      />
    </Box>
  );

  const renderDescField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='description'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.input_label'
        )}
      </Typography>
      <Controller
        name='description'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              id='description'
              data-testid='description-input'
              onChange={onChange}
              value={value}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.placeholder_initiative_text'
              )}
              multiline
              rows={4}
              error={!!errors.description}
              helperText={
                errors.description
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:description_section:error_message'
                    )
                  : ''
              }
            />
          );
        }}
      />
    </Box>
  );

  const renderDocumentsField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='documents'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.documents_section.input_label'
        )}
      </Typography>
      <DropzoneComponent dropzoneRef={dropzoneComponentRef} />
    </Box>
  );

  const renderButtons = () => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: '3rem' }}>
      <Box sx={{ mr: '1rem' }}>
        <Button
          variant='text'
          color='secondary'
          onClick={() => {
            if (isEditMode) {
              push(`${pathname}${search}${includedHash}#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`);
            } else {
              handleCloseDrawer();
            }
          }}
          data-testid='cancel-button'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.action_buttons.cancel_button_text'
          )}
        </Button>
      </Box>
      <Button
        variant='contained'
        role='search'
        onClick={onModifyRequest}
        data-testid={isEditMode ? 'update-request-button' : 'send-request-button'}
        disabled={isCreateLoading || isEditLoading || isDocumentsUploadInProgress}>
        {isCreateLoading || isEditLoading || isDocumentsUploadInProgress ? (
          <CircularProgress color='inherit' size={20} />
        ) : (
          t(
            `subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.action_buttons.${
              isEditMode ? 'update_initiative_button_text' : 'submit_initiative_button_text'
            }`
          )
        )}
      </Button>
    </Box>
  );

  return (
    <WorkflowRequestWrapper>
      <section className='request-fields'>
        {renderToolField()}
        {renderAssigneeField()}
        {renderDescField()}
        {renderCollaboratorsField()}
        {renderDateField()}
        {!isEditMode && renderDocumentsField()}
      </section>
      {(isEditMode || isNewRequestMode) && renderButtons()}
    </WorkflowRequestWrapper>
  );
};
