import React from 'react';
import { colors } from 'shared/theme/index';

export const DownloadIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox='0 0 18 18'
    fill='none'
    width='18'
    height='18'
    xmlns='http://www.w3.org/2000/svg'
    role='img'
    data-icon='download'>
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.46967 12.2197C5.76256 11.9268 6.23744 11.9268 6.53033 12.2197L9 14.6893L11.4697 12.2197C11.7626 11.9268 12.2374 11.9268 12.5303 12.2197C12.8232 12.5126 12.8232 12.9874 12.5303 13.2803L9.53033 16.2803C9.23744 16.5732 8.76256 16.5732 8.46967 16.2803L5.46967 13.2803C5.17678 12.9874 5.17678 12.5126 5.46967 12.2197Z'
        fill={props.fill || colors.bahamaBlue}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V15.75C9.75 16.1642 9.41421 16.5 9 16.5C8.58579 16.5 8.25 16.1642 8.25 15.75V9C8.25 8.58579 8.58579 8.25 9 8.25Z'
        fill={props.fill || colors.bahamaBlue}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.5007 1.49557C7.52106 1.45888 8.53646 1.65414 9.47043 2.06665C10.4044 2.47917 11.2326 3.09819 11.8928 3.87711C12.4256 4.50588 12.8378 5.22562 13.1103 5.99995L13.4994 5.99995C14.4558 5.99933 15.388 6.3034 16.1599 6.86806C16.9319 7.43283 17.5039 8.22892 17.7928 9.14079C18.0817 10.0527 18.0725 11.0329 17.7666 11.9392C17.4607 12.8455 16.8739 13.6307 16.0914 14.181C15.7526 14.4192 15.2848 14.3377 15.0465 13.9989C14.8082 13.66 14.8898 13.1922 15.2286 12.9539C15.7502 12.5871 16.1414 12.0636 16.3454 11.4594C16.5493 10.8552 16.5554 10.2018 16.3628 9.59384C16.1702 8.98593 15.7889 8.4552 15.2742 8.07869C14.7596 7.70217 14.1383 7.49945 13.5006 7.49995H12.555C12.2126 7.49995 11.9137 7.26803 11.8285 6.93636C11.6312 6.16715 11.2619 5.45274 10.7484 4.84691C10.235 4.24108 9.59083 3.75962 8.8644 3.43878C8.13797 3.11793 7.34823 2.96606 6.55461 2.9946C5.76099 3.02314 4.98419 3.23135 4.28269 3.60354C3.58118 3.97574 2.97326 4.50223 2.50467 5.14337C2.03608 5.78451 1.71905 6.52361 1.57744 7.30501C1.43582 8.0864 1.47332 8.88975 1.68711 9.65456C1.90089 10.4194 2.2854 11.1257 2.81167 11.7204C3.08617 12.0306 3.05724 12.5046 2.74704 12.7791C2.43684 13.0536 1.96285 13.0247 1.68835 12.7145C1.01171 11.9499 0.517352 11.0417 0.242484 10.0584C-0.0323838 9.07504 -0.0805958 8.04217 0.101478 7.03752C0.283551 6.03286 0.691166 5.0826 1.29363 4.25827C1.8961 3.43395 2.67773 2.75703 3.57966 2.2785C4.4816 1.79996 5.48034 1.53227 6.5007 1.49557Z'
        fill={props.fill || colors.bahamaBlue}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='18' height='18' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
