import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

export const FooterActionBar: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
      {children}
    </Box>
  );
};
