import { Box, Typography } from '@mui/material';
import { Switch } from 'asteroids';
import { useTranslation } from 'react-i18next';

type UsageAnalyticsProps = {
  checked: boolean;
  disabled: boolean;
  ssoProviderName: string;
  onToggle: (checked: boolean) => void;
};

export const UsageAnalytics = ({ checked, disabled, onToggle, ssoProviderName }: UsageAnalyticsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant='section' sx={{ mb: 1 }} data-testid='drawer-analytics-title'>
        {t('manage_sso_integrations_view:usage_analytics_title', { ssoProviderName })}
      </Typography>

      <Typography variant='body1' color='text.primary' sx={{ mb: 1 }} data-testid='drawer-analytics-body'>
        {checked
          ? t('manage_sso_integrations_view:toggle_usage_analytics_off', {
              ssoProviderName,
            })
          : t('manage_sso_integrations_view:toggle_usage_analytics_on', {
              ssoProviderName,
            })}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Switch
          key='switch'
          data-testid='manage-usage-analytics-switch'
          checked={checked}
          label={t('manage_sso_integrations_view:toggle_usage_analytics')}
          onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked) => onToggle(checked)}
          disabled={disabled}
          sx={{ ml: '11px' }}
          size='large'
        />
      </Box>
    </>
  );
};
