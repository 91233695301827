import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export function trackCommentThreadToggled(isExpanded: boolean) {
  const eventName = isExpanded ? trackEventName.CommentThreadExpanded : trackEventName.CommentThreadCollapsed;
  EventTracking.trackEvent(eventName);
}

export function trackReplyEditClicked() {
  EventTracking.trackEvent(trackEventName.ReplyEditClicked);
}

export function trackReplyDeleteClicked() {
  EventTracking.trackEvent(trackEventName.ReplyDeleteClicked);
}

export function trackReplySaveClicked() {
  EventTracking.trackEvent(trackEventName.ReplySaveClicked);
}

export function trackReplyEditCancelClicked() {
  EventTracking.trackEvent(trackEventName.ReplyEditCancelled);
}

export function trackReplyEditSaveClicked() {
  EventTracking.trackEvent(trackEventName.ReplyEditSaved);
}
