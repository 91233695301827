import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Icon } from 'asteroids';
import { AxiosError } from 'axios';
import { ExploreData } from 'components/explore-search-result-card/explore-search-result-card.component.props';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Currency, CurrencyOptions, SubscriptionStatus, UserRoles } from 'shared/common.definitions';
import { isToolStoreRoute } from 'shared/helpers/common.helper';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { trackToolStoreRequestCreated } from 'shared/logic/event-tracking/tool-store.events';
import { addSubscriptionComment, isASubscription } from 'shared/logic/subscription-item.logic';
import { checkToolStatus, NewSubscription, postNewSolution } from 'shared/logic/subscriptions.logic';
import { Company, Subscription, ToolOwnerFilter, User, VendorType } from 'shared/models';
import { AppUrl } from 'src/constants/appurl';
import * as yup from 'yup';

import { DialogContext } from '../../context/dialog.context';
import { useDebounce } from '../../hooks';
import { DatePickerComponent, NumberFormatInput, TypeaheadComponent, VendorSelectInputComponent } from '..';
import { SastrifyInput } from '../input';
import { DrawerRequestToolProps, RequestToolAlert, RequestToolAlertType } from '.';
import { ToolRequestWrapper } from './drawer-request-tool.styles';

type ToolOwner = { avatar?: string; id?: string; name: string; value?: string };

const toolRequestSchema = yup.object().shape({
  budgetCents: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  comment: yup.string().required(),
  decisionDate: yup.string(),
  name: yup.string().required(),
});

export const DrawerRequestTool: FC<DrawerRequestToolProps> = ({ showNotification, toggleDrawer }) => {
  const history = useHistory();

  const { openDialog } = useContext(DialogContext);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    register,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm<NewSubscription>({
    mode: 'onChange',
    resolver: yupResolver(toolRequestSchema),
    shouldUnregister: true,
  });

  const queryClient = useQueryClient();

  const company = queryClient.getQueryData<Company>('company');

  // eslint-disable-next-line no-restricted-globals
  const isToolStore = isToolStoreRoute(location.pathname);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [vendorName, setVendorName] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>();
  const [selectedVendor, setSelectedVendor] = useState<VendorType>();
  const [tags, setTags] = useState<string[]>([]);
  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState<ToolOwnerFilter>({ avatar: '', id: '', name: '' });
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [isRequestedOrRejected, setIsRequestedOrRejected] = useState<Partial<Subscription>>();
  const [showToolSelectionError, setShowToolSelectionError] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const isMounted = useRef(true);

  const { t } = useTranslation();

  useDebounce(setDebouncedValue, vendorName, 500);

  const comment = watch('comment');

  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');
  const initialSelectedVendor = queryClient.getQueryData<ExploreData>('initial-selected-vendor');

  const tools = queryClient.getQueryData<Subscription[]>('subscriptions-and-new-solutions');

  useEffect(() => {
    if (isMounted.current && initialSelectedVendor && initialSelectedVendor.name) {
      setSelectedVendor(initialSelectedVendor as VendorType);
      setVendorName(initialSelectedVendor.name);
      setValue('name', initialSelectedVendor.name, { shouldValidate: true });
      queryClient.setQueryData('initial-selected-vendor', undefined);
      isMounted.current = false;
    }
  }, [initialSelectedVendor, setValue, queryClient]);

  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList
        .filter((user: User) => user.role !== UserRoles.Viewer)
        .map((user: User) => ({
          avatar: user?.avatar,
          name: user.name,
          value: user.id || user.name?.replaceAll(' ', '-'),
        }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  const updateSubscriptionsCacheAndCloseModal = (data: Subscription, isInvalidateQueries?: boolean) => {
    const queryKey = ['subscriptions-in-discovery', true];
    queryClient.setQueryData(queryKey, (cacheData) => {
      if (cacheData) {
        const subscription = cacheData as Array<Subscription>;
        return [...subscription, data];
      }
    });
    if (isInvalidateQueries) {
      queryClient.invalidateQueries('subscriptions');
      queryClient.invalidateQueries('subscriptions-todos');
    }

    queryClient.invalidateQueries('subscriptions-and-new-solutions');
    reset();
    history.push(AppUrl.getToolUrl(data, false, true));
  };

  const { isLoading, mutate } = useMutation(postNewSolution, {
    onError: (err: AxiosError) => {
      const message =
        getErrorCodeMessage(err) ||
        t('common:modals.add_new_subscription_feature_modal.error_message', {
          value: 'New request',
        });

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: async (data) => {
      if (comment) {
        await addSubscriptionComment({
          comment,
          subscriptionId: data?.id,
        });
      }

      toggleDrawer();

      openDialog({
        confirmBtnText: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.success_message_section.button_text'
        ),
        content: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.success_message_section.content_text',
          {
            name: data?.name || data?.vendorName,
          }
        ),
        onConfirm: () => {
          updateSubscriptionsCacheAndCloseModal(data);
        },
        title: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.success_message_section.title'
        ),
      });
    },
  });

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner({ avatar: '', id: '', name: '' });
      setToolOwnerName('');
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleClearButtonClicked = () => {
    setToolOwner({ avatar: '', id: '', name: '' });
    setToolOwnerName('');
  };

  const onSubmit = async () => {
    trigger();
    if (!isValid) {
      return;
    }
    const vendorId = selectedVendor && selectedVendor.name === getValues().name ? selectedVendor.id : undefined;

    if (!vendorId) {
      setShowToolSelectionError(true);
    } else {
      const formValues = getValues();
      const subscriptionClone = { ...formValues };
      const budgetCents = Number(getValues('budgetCents')) * 100 || undefined;

      const toolRequestData = {
        budgetCents,
        budgetCurrency: DEFAULT_CURRENCY,
        ownerId: toolOwner.value,
        ownerName: toolOwner.name ? toolOwner.name : undefined,
        tags,
        vendorId,
      };

      const mutateData: NewSubscription = {
        ...subscriptionClone,
        ...toolRequestData,
        state: SubscriptionStatus.requested,
      };
      delete mutateData?.comment;

      mutate(mutateData);

      if (isToolStore) {
        trackToolStoreRequestCreated({
          ...toolRequestData,
          comment: subscriptionClone.comment,
          vendorName: selectedVendor?.name,
        });
      }
    }
  };

  const handleItemSelected = (item: VendorType) => {
    if (item) {
      setValue('name', item.name, { shouldValidate: true });
      setSelectedVendor(item);
      setShowToolSelectionError(false);
    } else {
      setSelectedVendor(undefined);
    }

    const isRequested = checkToolStatus(item, tools);

    setIsRequestedOrRejected(isRequested);
  };

  const handleOnViewRequest = useCallback(() => {
    const isSubscription = isASubscription(isRequestedOrRejected?.state as number);
    history.push(AppUrl.getToolUrl(isRequestedOrRejected as Partial<Subscription>, isSubscription, !isSubscription));
    toggleDrawer();
  }, [history, isRequestedOrRejected, toggleDrawer]);

  return (
    <ToolRequestWrapper>
      <Box mb='36px' display='flex' flexDirection='column' data-testid='tool-name'>
        <Typography variant='h2' paragraph>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.tool_name_section.input_label'
          )}
        </Typography>
        <Controller
          name='name'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <VendorSelectInputComponent
                isMuiInput
                handleItemSelected={handleItemSelected}
                setDebouncedValue={setDebouncedValue}
                vendorName={vendorName}
                debouncedValue={debouncedValue}
                onChange={onChange}
                setVendorName={setVendorName}
                placeholder={t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.tool_name_section.placeholder_text'
                )}
                value={value}
                error={!!errors.name || showToolSelectionError}
                helperText={
                  errors.name || showToolSelectionError
                    ? t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:add_tool_request_section:tool_name_section:error_message'
                      )
                    : ''
                }
              />
            );
          }}
        />
      </Box>

      <Box mb='36px' display='flex' flexDirection='column' data-testid='owner'>
        <Typography variant='h2' paragraph>
          {t(
            'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.label_text'
          )}
        </Typography>

        <TypeaheadComponent
          data={toolOwners}
          onItemSelected={onToolOwnerSelected}
          onBlurToolOwnerFilter={onToolOwnerSelected}
          value={toolOwnerName}
          fields={['name', 'email']}
          isToolOwnerFilter
          onTextChanged={onChangeToolOwner}
          placeholder={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.owner_section.placeholder_text'
          )}
          isSubscriptionInDiscovery
          rightIcon={
            <IconButton onClick={handleClearButtonClicked} onMouseDown={(e) => e.preventDefault()}>
              <Icon color='secondary'>clear</Icon>
            </IconButton>
          }
          renderCustomInput={({
            autoFocus,
            disabled,
            inputTextRef,
            onChange,
            onClick,
            onFocus,
            onKeyDown,
            onKeyUp,
            value,
          }) => (
            <SastrifyInput
              inputRef={inputTextRef}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onFocus={(ev) => {
                onFocus?.(ev);
                setFocused(true);
              }}
              onBlur={() => setFocused(false)}
              onClick={onClick}
              disabled={disabled}
              value={value || ''}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.owner_section.placeholder_text'
              )}
              autoFocus={autoFocus}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                    {!isFocused ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Box mb='36px' display='flex' flexDirection='column' data-testid='yearly-budget'>
        <Typography variant='h2' paragraph>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.budget_section.input_text'
          )}
        </Typography>
        <Controller
          name='budgetCents'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <NumberFormatInput
                label={t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.budget_section.placeholder_text'
                )}
                decimalScale={2}
                decimalSeparator='.'
                fixedDecimalScale
                thousandSeparator
                prefix={company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY}
                value={value}
                onValueChange={(value) => onChange(value.floatValue)}
                isMuiInput
              />
            );
          }}
        />
      </Box>
      <Box mb='36px' display='flex' flexDirection='column' className='sastrify-datepicker' data-testid='decision-date'>
        <Typography variant='h2' paragraph>
          {t(
            'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.decision_date_input_section.label_text'
          )}
        </Typography>

        <Controller
          name='decisionDate'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePickerComponent
                value={value}
                onChange={(newValue) => {
                  onChange(newValue);
                  setOpenDatePicker(false);
                }}
                openDatePicker={openDatePicker}
                setOpenDatePicker={setOpenDatePicker}
                minDate={new Date()}
                label={t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.decision_date_section.placeholder_text'
                )}
                isMuiInput
              />
            );
          }}
        />
      </Box>
      <Box display='flex' flexDirection='column' className='sastrify-tags' data-testid='tags'>
        <Typography variant='h2' paragraph>
          {t(
            'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.label_text'
          )}
        </Typography>

        <Autocomplete
          data-testid='tags'
          sx={{ width: '100%' }}
          multiple
          limitTags={2}
          onChange={(_, value) => {
            setTags(value);
          }}
          options={company?.companyTags || []}
          filterSelectedOptions
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => <Chip label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <SastrifyInput
              {...params}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.tags_section.placeholder_text'
              )}
            />
          )}
        />
        <Typography variant='small'>
          {t(
            'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.placeholder_text'
          )}
        </Typography>
      </Box>

      <Box mt='32px' display='flex' flexDirection='column' className='sastrify-tags' data-testid='comment'>
        <Typography variant='h2' paragraph>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.comments_section.input_text'
          )}
        </Typography>
        <SastrifyInput
          label={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.comments_section.placeholder_text'
          )}
          {...register('comment')}
          data-testid='comment-input'
          multiline
          rows={4}
          error={!!errors.comment}
          helperText={
            errors.comment
              ? t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.comments_section.error_message'
                )
              : ' '
          }
        />
      </Box>
      {Object.keys(isRequestedOrRejected || {}).length ? (
        <Box mt='36px'>
          <RequestToolAlert
            toolName={isRequestedOrRejected?.vendorName as string}
            onViewRequest={handleOnViewRequest}
            type={
              // eslint-disable-next-line no-nested-ternary
              isRequestedOrRejected?.state === SubscriptionStatus.requested
                ? RequestToolAlertType.requested
                : isRequestedOrRejected?.state === SubscriptionStatus.rejected
                ? RequestToolAlertType.rejected
                : RequestToolAlertType.used
            }
            isSubscription={isASubscription(isRequestedOrRejected?.state as number)}
          />
        </Box>
      ) : null}

      <Box className='action-button' mt='40px'>
        <Button
          variant='text'
          color='secondary'
          className='cancel-button'
          onClick={() => toggleDrawer()}
          data-testid='cancel-button'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.cancel_button_text'
          )}
        </Button>
        <Button
          color='primary'
          variant='contained'
          data-testid='add-new-tool-button'
          startIcon={isLoading ? <CircularProgress color='inherit' size={16} /> : null}
          onClick={onSubmit}
          disabled={isRequestedOrRejected?.state === SubscriptionStatus.requested}>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.submit_button_text'
          )}
        </Button>
      </Box>
    </ToolRequestWrapper>
  );
};
