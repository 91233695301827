import { createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { mrtTableSliceConfigFactory } from '../../component-reducers/material-react-table.slice';

const mrtTableSlice = createSlice(mrtTableSliceConfigFactory('inactivePanel'));

export const inactivePanelReducers = combineReducers({
  table: mrtTableSlice.reducer,
});

export const { actions: tableActions } = mrtTableSlice;
