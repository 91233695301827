import { Box, styled, Typography } from '@mui/material';
import React from 'react';

const FallbackContentContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(6),
  width: '100%',
}));

const Image = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

interface UsageAnalyticsFallbackContentProps {
  description: string;
  icon: string;
}

export const UsageAnalyticsFallbackContent: React.FC<UsageAnalyticsFallbackContentProps> = (props) => {
  const { description, icon } = props;

  return (
    <FallbackContentContainer>
      <Image src={icon} alt='empty-list' />
      <Typography variant='body1' color='text.secondary'>
        {description}
      </Typography>
    </FallbackContentContainer>
  );
};
