import { FC } from 'react';
import { colors } from 'shared/theme';

export const ClipboardIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.25 11H6.75C6.55109 11 6.36032 11.079 6.21967 11.2197C6.07902 11.3603 6 11.5511 6 11.75C6 11.9489 6.07902 12.1397 6.21967 12.2803C6.36032 12.421 6.55109 12.5 6.75 12.5H11.25C11.4489 12.5 11.6397 12.421 11.7803 12.2803C11.921 12.1397 12 11.9489 12 11.75C12 11.5511 11.921 11.3603 11.7803 11.2197C11.6397 11.079 11.4489 11 11.25 11ZM11.25 8H8.25C8.05109 8 7.86032 8.07902 7.71967 8.21967C7.57902 8.36032 7.5 8.55109 7.5 8.75C7.5 8.94891 7.57902 9.13968 7.71967 9.28033C7.86032 9.42098 8.05109 9.5 8.25 9.5H11.25C11.4489 9.5 11.6397 9.42098 11.7803 9.28033C11.921 9.13968 12 8.94891 12 8.75C12 8.55109 11.921 8.36032 11.7803 8.21967C11.6397 8.07902 11.4489 8 11.25 8ZM12.75 3.5H11.865C11.7103 3.06234 11.4239 2.68325 11.0453 2.4147C10.6667 2.14616 10.2142 2.0013 9.75 2H8.25C7.78579 2.0013 7.33335 2.14616 6.9547 2.4147C6.57605 2.68325 6.28974 3.06234 6.135 3.5H5.25C4.65326 3.5 4.08097 3.73705 3.65901 4.15901C3.23705 4.58097 3 5.15326 3 5.75V14.75C3 15.3467 3.23705 15.919 3.65901 16.341C4.08097 16.7629 4.65326 17 5.25 17H12.75C13.3467 17 13.919 16.7629 14.341 16.341C14.7629 15.919 15 15.3467 15 14.75V5.75C15 5.15326 14.7629 4.58097 14.341 4.15901C13.919 3.73705 13.3467 3.5 12.75 3.5ZM7.5 4.25C7.5 4.05109 7.57902 3.86032 7.71967 3.71967C7.86032 3.57902 8.05109 3.5 8.25 3.5H9.75C9.94891 3.5 10.1397 3.57902 10.2803 3.71967C10.421 3.86032 10.5 4.05109 10.5 4.25V5H7.5V4.25ZM13.5 14.75C13.5 14.9489 13.421 15.1397 13.2803 15.2803C13.1397 15.421 12.9489 15.5 12.75 15.5H5.25C5.05109 15.5 4.86032 15.421 4.71967 15.2803C4.57902 15.1397 4.5 14.9489 4.5 14.75V5.75C4.5 5.55109 4.57902 5.36032 4.71967 5.21967C4.86032 5.07902 5.05109 5 5.25 5H6V5.75C6 5.94891 6.07902 6.13968 6.21967 6.28033C6.36032 6.42098 6.55109 6.5 6.75 6.5H11.25C11.4489 6.5 11.6397 6.42098 11.7803 6.28033C11.921 6.13968 12 5.94891 12 5.75V5H12.75C12.9489 5 13.1397 5.07902 13.2803 5.21967C13.421 5.36032 13.5 5.55109 13.5 5.75V14.75Z'
        fill={fill}
      />
    </svg>
  );
};

ClipboardIcon.defaultProps = {
  fill: colors.primary,
  height: '19',
  width: '18',
};
