/* eslint-disable func-names */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Autocomplete, AutocompleteOption, Avatar, Icon, InputField } from 'asteroids';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CSM_USER_EMAIL } from 'shared/common.definitions';
import { User } from 'shared/models';
import * as yup from 'yup';

import { DialogComponent } from '../../drawer-workflow-request-details/components/dialog/dialog.component';
import { WORKFLOW_KEYWORDS } from '../drawer-request-workflow.component';
import { TemplateFormProps } from './template-form.props';

declare module 'yup' {
  interface StringSchema {
    unique(): StringSchema;
  }
}

yup.addMethod(yup.string, 'unique', function () {
  return this.test('unique', function (value) {
    const { stages } = (this as any).from[1].value;
    const occurrences = [...stages].filter((stage: any) => stage.name === value).length;

    return occurrences <= 1;
  });
});

const formValidationSchema = yup.object().shape({
  stages: yup.array().of(
    yup.object().shape({
      name: yup.string().unique().required(),
      owner: yup.string().required(),
    })
  ),
  workflowName: yup.string().required(),
});

export const TemplateForm: FC<TemplateFormProps> = ({
  availableStages,
  defaultValues,
  isActive,
  isDetails,
  isLoading,
  onFormSubmit,
  typeId,
  users,
}) => {
  const [stageNameInputValue, setStageNameInputValue] = useState<string>('');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    clearErrors,
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
  });
  const { append: appendStage, fields: stages, remove: removeStage } = useFieldArray({ control, name: 'stages' });

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.new_request_workflow';

  const text = {
    buttonActivate: t(`${pathToTranslation}.approval_template.form_section.button_activate_label`),
    buttonDeleteStage: t(`${pathToTranslation}.approval_template.form_section.button_delete_stage_label`),
    buttonDraft: t(`${pathToTranslation}.approval_template.form_section.button_draft_label`),
    buttonNewStage: t(`${pathToTranslation}.approval_template.form_section.button_add_stage_label`),
    buttonUpdate: t(`${pathToTranslation}.approval_template.form_section.button_update_label`),
    errorOccupiedStage: t(`${pathToTranslation}.approval_template.form_section.stage_occupied_error_text`),
    errorRequiredField: t(`${pathToTranslation}.approval_template.form_section.required_field_error_text`),
    helperAssignee: t(`${pathToTranslation}.approval_template.form_section.assignee_helper_text`),
    helperFirstAssignee: t(`${pathToTranslation}.approval_template.form_section.assignee_first_helper_text`),
    helperStage: t(`${pathToTranslation}.approval_template.form_section.stage_helper_text`),
    labelAssignee: t(`${pathToTranslation}.approval_template.form_section.assignee_label`),
    labelStage: t(`${pathToTranslation}.approval_template.form_section.stage_label`),
    labelWorkflow: t(`${pathToTranslation}.approval_template.form_section.workflow_name_label`),
    labelWorkflowInitiatives: t(`${pathToTranslation}.approval_template.form_section.workflow_name_label_initiatives`),
    titleWorkflow: t(`${pathToTranslation}.approval_template.form_section.workflow_name_title`),
  };

  const stageNames = useMemo(() => {
    return [...stages].map((stage, stageIndex) => `stages.${stageIndex}.name`);
  }, [stages]);

  const requestApprover = useMemo(
    (): AutocompleteOption => ({
      id: WORKFLOW_KEYWORDS.REQUEST_APPROVER,
      label: WORKFLOW_KEYWORDS.REQUEST_APPROVER,
      prefix: (
        <Avatar sx={{ backgroundColor: 'tertiary.light' }} size='normal'>
          <Icon color='warning' variant='Outlined' sx={{ color: 'warning.dark', ml: 1 }}>
            person_off
          </Icon>
        </Avatar>
      ),
    }),
    []
  );

  const getUsers = useMemo(() => {
    const userOptions = users
      .filter((user: User) => !user.email?.includes(CSM_USER_EMAIL))
      .map(({ avatar, avatarUrl, email, id, name }: User) => ({
        id,
        label: `${users.filter((user) => user.name === name).length > 1 ? `${name} - ${email}` : name}`,
        prefix: <Avatar src={avatarUrl || avatar} sx={{ mr: 2 }} />,
      }));

    return [requestApprover, ...(userOptions as AutocompleteOption[])];
  }, [requestApprover, users]);

  useEffect(() => {
    if (stageNameInputValue.length === 0) return;

    const timeout = setTimeout(() => {
      trigger(stageNames as any[]);
      setStageNameInputValue('');
    }, 500);

    return () => clearTimeout(timeout);
  }, [stageNames, trigger, stageNameInputValue]);

  const onSubmit = () => {
    setIsConfirmationDialogOpen(false);
    onFormSubmit({
      form: getValues(),
      type: 'active',
    });
  };

  const onDraft = () => {
    clearErrors();
    trigger([...(stageNames as any[]), WORKFLOW_KEYWORDS.WORKFLOW_NAME]);
    if (getValues().workflowName.length > 0 && !errors.workflowName && !errors.stages) {
      onFormSubmit({
        form: getValues(),
        type: 'draft',
      });
    }
  };

  const onUpdate = () => {
    trigger();
    if (isValid) {
      onFormSubmit({
        form: getValues(),
        type: 'active',
      });
    }
  };

  const addNewStage = () => appendStage({ name: '', owner: '' });

  const getStageNameHelperText = (error: FieldError | undefined) => {
    let helperText = text.helperStage;

    if (error && error.type === 'required') {
      helperText = text.errorRequiredField;
    }
    if (error && error.type === 'unique') {
      helperText = text.errorOccupiedStage;
    }

    return helperText;
  };

  const renderWorkflowName = () => (
    <Stack spacing={1} mb={3} data-testid={WORKFLOW_KEYWORDS.WORKFLOW_NAME}>
      <Typography variant='h3' mb={1}>
        {text.titleWorkflow}
      </Typography>
      <Controller
        control={control}
        name={WORKFLOW_KEYWORDS.WORKFLOW_NAME}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <InputField
            {...field}
            id={WORKFLOW_KEYWORDS.WORKFLOW_NAME}
            label={text.labelWorkflowInitiatives}
            onClearValue={() => {
              setValue(WORKFLOW_KEYWORDS.WORKFLOW_NAME as any, '');
              trigger(WORKFLOW_KEYWORDS.WORKFLOW_NAME);
            }}
            helperText={text.errorRequiredField}
            error={Boolean(error)}
            autoComplete='off'
            fullWidth
            readOnly={isDetails}
            disabled={isDetails}
          />
        )}
      />
    </Stack>
  );

  const renderStages = () => (
    <>
      {stages.map((field, index) => (
        <Box key={field.id}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' mb={index === 0 ? 2 : 1}>
            <Typography data-testid={`workflows-stage-${index}-title`} variant='h3' sx={{ mb: 2 }}>
              {t(`${pathToTranslation}.approval_template.form_section.stage_title`, { index: index + 1 })}
            </Typography>
            {index !== 0 && !isDetails && (
              <Button
                data-testid={`workflows-stage-${index}-delete-button`}
                color='secondary'
                variant='text'
                startIcon={<Icon>delete</Icon>}
                onClick={() => removeStage(index)}>
                {text.buttonDeleteStage}
              </Button>
            )}
          </Stack>
          <Stack mb={3} direction='row' spacing={2} width='100%'>
            <Controller
              control={control}
              name={`stages.${index}.name`}
              render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    id={`stages-${index}-name`}
                    {...field}
                    label={text.labelStage}
                    options={availableStages.map((stage) => ({ label: stage }))}
                    onChange={(_, data: any) => {
                      onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                      trigger(stageNames as any[]);
                    }}
                    onInputChange={(_, value: any) => {
                      onChange(value);
                      setStageNameInputValue(value);
                    }}
                    helperText={getStageNameHelperText(error)}
                    error={Boolean(error)}
                    disablePortal
                    creatable
                    fullWidth
                    readOnly={isDetails}
                    disabled={isDetails}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name={`stages.${index}.owner`}
              render={({ field: { onChange, ref, value, ...field }, fieldState: { error } }) => {
                return (
                  <Autocomplete
                    {...field}
                    id={`stages-${index}-assignee`}
                    value={value}
                    label={text.labelAssignee}
                    options={getUsers}
                    onChange={(_, data: any) => onChange(data?.label || '')}
                    helperText={error ? text.errorRequiredField : ''}
                    error={Boolean(error)}
                    disabled={(index === 0 && value === WORKFLOW_KEYWORDS.REQUEST_APPROVER) || isDetails}
                    disablePortal
                    fullWidth
                    readOnly={isDetails}
                  />
                );
              }}
            />
          </Stack>
        </Box>
      ))}
    </>
  );

  const renderAddButton = () => (
    <Box mb={3}>
      <Button
        variant='text'
        startIcon={<Icon>add</Icon>}
        onClick={addNewStage}
        disabled={isDetails}
        data-testid='workflows-new-stage-button'>
        {text.buttonNewStage}
      </Button>
    </Box>
  );

  const renderActionButtons = () => (
    <Stack direction='row' justifyContent='flex-end' spacing={2} mb={4}>
      {isActive ? (
        <Button variant='contained' onClick={onUpdate} disabled={isLoading} data-testid='workflows-update-button'>
          {text.buttonUpdate}
        </Button>
      ) : (
        <>
          <Button
            variant='contained'
            color='secondary'
            onClick={onDraft}
            disabled={isLoading}
            data-testid='workflows-save-draft-button'>
            {text.buttonDraft}
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              trigger();
              if (isValid) {
                setIsConfirmationDialogOpen(true);
              }
            }}
            disabled={isLoading}
            data-testid='workflows-activate-button'>
            {text.buttonActivate}
          </Button>
        </>
      )}
    </Stack>
  );

  return (
    <>
      <Box component='form'>
        {renderWorkflowName()}
        {renderStages()}
        {renderAddButton()}
        {!isDetails && renderActionButtons()}
      </Box>
      <DialogComponent
        open={isConfirmationDialogOpen}
        message={t(`${pathToTranslation}.approval_template.activate_confirmation_dialog.message_initiatives`, {
          type: typeId === REQUEST_WORKFLOW_TYPE.RENEWAL ? 'renewal' : 'approval',
        })}
        title={t(`${pathToTranslation}.approval_template.activate_confirmation_dialog.title`)}
        actionBtnLabel={t(`${pathToTranslation}.approval_template.activate_confirmation_dialog.confirm_button_text`)}
        cancelBtnLabel={t(`${pathToTranslation}.approval_template.activate_confirmation_dialog.cancel_button_text`)}
        onAction={onSubmit}
        onClose={() => setIsConfirmationDialogOpen(false)}
        actionBtnColor='primary'
      />
    </>
  );
};
