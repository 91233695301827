import { useContext } from 'react';

import { WorkflowInitiativeWizardContext } from '../contexts';

export const useWorkflowInitiativeWizardContext = () => {
  const context = useContext(WorkflowInitiativeWizardContext);
  if (!context) {
    throw new Error('useWorkflowInitiativeWizardContext must be used within a WorkflowInitiativeWizardContextProvider');
  }
  return context;
};
