/* eslint-disable camelcase */
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button, CircularProgress, Grid, Icon, IconButton, Typography } from '@mui/material';
import classnames from 'classnames';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DocumentCategory as DocumentCategoryType,
  ExtendedBlobType,
  googleTagManagerEvents,
} from 'shared/common.definitions';
import { decodeIdFromRoute } from 'shared/helpers/common.helper';
import { WrapperButton } from 'shared/helpers/styles.helper';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import {
  getCategoryTranslationKey,
  getCompanyDocumentCategory,
  getDocumentCategoryID,
  uploadSubscriptionDocument,
} from 'shared/logic/subscription-item.logic';
import { Company, SastrifyStore, User } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { NAVIGATION_URLS } from 'src/constants/navigation';
import { ImporterType } from 'views/tools-spend-importer/models/ImporterType';

import { DropzoneComponent } from '../drop-zone';
import { ApproveIcon, CancelIcon, CloseIcon, DocumentOutlineIcon, ErrorCircleIcon } from '../icons';
import { DrawerDocumentUploadComponentProps } from '.';

type DocumentAndCategory = { category: DocumentCategoryType; documents: ExtendedBlobType[]; order: number }[];
const {
  accounting_data,
  contract,
  data_processing_agreement,
  invoice,
  master_services_agreement,
  miscellaneous,
  offer,
  other_documents,
  subscription_list,
  terms_and_conditions,
} = DocumentCategoryType;

const initialDocumentStates = [
  { category: data_processing_agreement, documents: [], order: 1 },
  { category: terms_and_conditions, documents: [], order: 2 },
  { category: offer, documents: [], order: 3 },
  { category: contract, documents: [], order: 4 },
  { category: subscription_list, documents: [], order: 5 },
  { category: master_services_agreement, documents: [], order: 6 },
  { category: accounting_data, documents: [], order: 7 },
  { category: invoice, documents: [], order: 8 },
  { category: miscellaneous, documents: [], order: 9 },
  { category: other_documents, documents: [], order: 10 },
];
export const DrawerDocumentUploadComponent: FC<DrawerDocumentUploadComponentProps> = (props) => {
  const { t } = useTranslation();

  const {
    isCompanyDocument,
    isSubscriptionInDiscovery,
    isUploadingDocument,
    onSuccess,
    setIsUploadingDocument,
    showNotification,
    toggleDrawer,
  } = props;

  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const appDrawerData = useAppSelector((state) => state.common?.appDrawer?.data);

  const [documentStates, setDocumentStates] = useState<DocumentAndCategory>(initialDocumentStates);
  const [rejectedError, setRejectedError] = useState<{
    file: ExtendedBlobType;
    category: DocumentCategoryType;
  }>();

  const company = queryClient.getQueryData<Company>('company');
  const companyId = String(company?.id);
  const subscriptionId = decodeIdFromRoute(location.pathname);
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const isEmptyDocumentState = Object.entries(documentStates).every(([, value]) => !value.documents.length);
  const documentsWithAccountingExt = [DocumentCategoryType.accounting_data, DocumentCategoryType.subscription_list];

  const { mutateAsync } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
  });

  const handleRemoveDocument = (documentIndex: number, category: DocumentCategoryType) => {
    const updatedDocumentStatesAndCategory = documentStates.map((documentState) => {
      if (category === documentState.category) {
        const updatedDocuments = documentState.documents.filter((_, index) => index !== documentIndex);
        return { ...documentState, documents: updatedDocuments };
      }
      return documentState;
    });

    setDocumentStates(updatedDocumentStatesAndCategory);
  };

  const getFilteredDocumentStates = () => {
    const sortedDocumentStates = documentStates.sort((a, b) => a.order - b.order);

    const subscriptionDocumentCategories = [
      contract,
      data_processing_agreement,
      invoice,
      miscellaneous,
      offer,
      terms_and_conditions,
      master_services_agreement,
    ];
    const companyDocumentCategories = [subscription_list, accounting_data, invoice, other_documents];
    const isSubscriptionInDiscoveryCategories = [data_processing_agreement, miscellaneous, offer, terms_and_conditions];

    if (isSubscriptionInDiscovery) {
      return sortedDocumentStates.filter(({ category }) => isSubscriptionInDiscoveryCategories.includes(category));
    }

    if (isCompanyDocument) {
      return sortedDocumentStates.filter(({ category }) => companyDocumentCategories.includes(category));
    }

    return sortedDocumentStates.filter(({ category }) => subscriptionDocumentCategories.includes(category));
  };

  const getCategoryAction = (category: DocumentCategoryType): JSX.Element => {
    if (category === subscription_list) {
      return (
        <Button
          variant='text'
          onClick={() => {
            history.push(`${NAVIGATION_URLS.TOOLS_SPENDS_IMPORTER}?importerType=${ImporterType.TOOLS}`);
            toggleDrawer();
          }}
          size='small'
          data-testid='spreadsheet-tools-upload-button'
          endIcon={<Icon>arrow_forward</Icon>}>
          {t('connect_view:tab_section.tabs.discovery.sections.spreadsheet_upload.button')}
        </Button>
      );
    }
    if (category === accounting_data) {
      return (
        <Button
          variant='text'
          onClick={() => {
            history.push(`${NAVIGATION_URLS.TOOLS_SPENDS_IMPORTER}?importerType=${ImporterType.SPENDS}`);
            toggleDrawer();
          }}
          size='small'
          data-testid='spreadsheet-spends-upload-button'
          endIcon={<Icon>arrow_forward</Icon>}>
          {t('connect_view:tab_section.tabs.discovery.sections.spreadsheet_upload.button')}
        </Button>
      );
    }

    return (
      <DropzoneComponent
        isPaperClipIcon
        hasCustomMessage
        message={
          <span className='dropzone-message' data-testid='upload-document-message'>
            <Trans
              i18nKey='subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.drag_and_drop_text'
              values={{ clickHere: 'click here' }}
              components={[<span>click here</span>]}
            />
          </span>
        }
        name='file'
        onDrop={(value) => {
          onChange(value as unknown as ExtendedBlobType[], category);
        }}
        onDropRejected={(rejected) => onDropRejected(rejected, category)}
        multiple
        {...(documentsWithAccountingExt.includes(category) && { accept: ['.xlsx', '.csv'] })}
      />
    );
  };

  const getCategorySubText = (category: DocumentCategoryType) => {
    if (category === invoice && isCompanyDocument) {
      return (
        <span className='category-sub-text'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.company_invoices_subtext'
          )}
        </span>
      );
    }

    if (category === invoice) {
      return (
        <span className='category-sub-text'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.invoices_subtext'
          )}
        </span>
      );
    }

    if (category === subscription_list) {
      return (
        <span className='category-sub-text'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.subscription_list_subtext'
          )}
        </span>
      );
    }

    if (category === accounting_data) {
      return (
        <div>
          <span className='category-sub-text'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.accounting_data_subtext'
            )}
          </span>
        </div>
      );
    }

    return null;
  };

  const onChange = (value: ExtendedBlobType[], category: DocumentCategoryType) => {
    const updatedDocumentStatesAndCategory = documentStates.map((documentState) => {
      if (documentState.category === category) {
        return {
          ...documentState,
          documents: documentState.documents.concat(value),
        };
      }
      return documentState;
    });

    setDocumentStates(updatedDocumentStatesAndCategory);
  };

  const handleCloseDrawer = () => {
    toggleDrawer();
    setIsUploadingDocument?.(false);
    setDocumentStates(initialDocumentStates);

    if (isCompanyDocument) {
      if (appDrawerData?.spendImportItem) {
        history.replace(NAVIGATION_URLS.SPEND_IMPORT);
        return;
      }

      history.push(NAVIGATION_URLS.SUBSCRIPTIONS);
    }
  };

  const handleDocumentUpload = async () => {
    setIsUploadingDocument?.(true);

    const documentsData = documentStates
      .filter(({ documents }) => documents.length > 0)
      .map(({ category, documents }) =>
        documents.map((document) => ({
          category: isCompanyDocument ? getCompanyDocumentCategory(category) : getDocumentCategoryID(category),
          document,
        }))
      );

    const files = documentsData.flat(1);
    const filesPerChunk = Number.isNaN(Number(process.env.REACT_APP_UPLOAD_FILES_PER_CHUNK))
      ? 20
      : Number(process.env.REACT_APP_UPLOAD_FILES_PER_CHUNK);
    const chunksArray = new Array(Math.ceil(files.length / filesPerChunk)).fill(0);

    // eslint-disable-next-line no-restricted-syntax
    for await (const [chunkIndex] of Object.entries(chunksArray)) {
      const uploads: unknown[] = [];
      const filesChunk = files.slice(Number(chunkIndex) * filesPerChunk, (Number(chunkIndex) + 1) * filesPerChunk);

      filesChunk.forEach(({ category, document }) => {
        const values = {
          category,
          companyId,
          file: document,
          isCompanyDocument,
          subscriptionId: isCompanyDocument ? undefined : subscriptionId,
        };
        try {
          uploads.push(mutateAsync(values));
        } catch (error) {
          showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
        }
      });

      try {
        const results = await Promise.all(uploads);
        onSuccess?.(results);
      } catch (error) {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      }
    }

    if (isCompanyDocument) {
      fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.CompanyDocumentsUploaded);
    } else {
      fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.SubscriptionDocumentsUploaded);
    }

    const invoiceDocumentState = documentStates.find(
      ({ category, documents }) => category === invoice && documents.length > 0
    );

    if (invoiceDocumentState) {
      await queryClient.invalidateQueries('subscription-spend-and-invoices');
    }

    if (!isCompanyDocument) {
      await queryClient.invalidateQueries(['subscription', subscriptionId]);
    }

    await queryClient.invalidateQueries('subscriptionDocuments');
    await queryClient.invalidateQueries('subscriptions');
    await queryClient.invalidateQueries('auditLogs');

    const hasManyDocuments = documentsData.flat(1).length > 1;

    let message = '';

    if (isCompanyDocument) {
      message = hasManyDocuments
        ? t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.company_uploads_success_message'
          )
        : t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.company_upload_success_message'
          );
    } else {
      message = hasManyDocuments
        ? t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.documents_upload_success_message'
          )
        : t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.document_upload_success_message'
          );
    }

    setIsUploadingDocument?.(false);
    setDocumentStates(initialDocumentStates);

    handleCloseDrawer();
    showNotification?.(message, NotificationAlertType.Success);
  };

  const onDropRejected = (rejected: any[], category: DocumentCategoryType) => {
    const message = documentsWithAccountingExt.includes(category)
      ? t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.upload_file_type_error_message_accounting_ext'
        )
      : t('subscription_detail_view:tabs_component_section.subscription_document_tab.upload_file_type_error_message');
    showNotification?.(message, NotificationAlertType.Error);
    setRejectedError({ category, file: rejected[0].file as ExtendedBlobType });
  };

  return (
    <>
      {getFilteredDocumentStates().map(({ category, documents }) => (
        <Grid container spacing={2} data-testid='upload-section' alignItems='center' key={category} sx={{ pr: 4 }}>
          <Grid item xs={6} className='pr-4'>
            <Typography variant='body1' className='category'>
              {t(
                `subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.${
                  category === contract ? 'contract_text' : getCategoryTranslationKey(category)
                }`
              )}
            </Typography>
            {getCategorySubText(category)}
          </Grid>
          {documents.length ? (
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <DocumentOutlineIcon />
                </Grid>
                <Grid item xs={10} className='mb-3'>
                  <Box display='flex' justifyContent='space-between' mb={1}>
                    <Box width='95%' display='flex' flexWrap='wrap'>
                      {documents.map((file, index) => (
                        <Box display='flex' alignItems='center' key={file.name}>
                          <Typography variant='body1' className='filename mr-1'>
                            {file.name}
                          </Typography>
                          <WrapperButton
                            data-testid='remove-document-icon'
                            className={classnames('delete-icon-wrapper', {
                              'close-button-active': !isUploadingDocument,
                            })}
                            onClick={() => (isUploadingDocument ? null : handleRemoveDocument(index, category))}>
                            <CloseIcon />
                          </WrapperButton>
                        </Box>
                      ))}
                    </Box>
                    <ApproveIcon />
                  </Box>
                  <Box className='upload-complete-line' />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={6} data-testid='upload-document'>
              {rejectedError && rejectedError.category === category ? (
                <Grid className='is-flex dropzone-rejected-section is-align-items-start'>
                  <Grid className='mr-5 mt-1'>
                    <DocumentOutlineIcon />
                  </Grid>
                  <Grid>
                    <div className='is-flex'>
                      <div className='filename'>{rejectedError.file.name}</div>
                      <IconButton className='ml-2 p-1' onClick={() => setRejectedError(undefined)}>
                        <CancelIcon width={16} height={16} />
                      </IconButton>
                    </div>
                    <Box className='upload-rejected-line mb-3' />
                    <div className='is-flex is-align-items-start'>
                      <span>
                        <ErrorCircleIcon />
                      </span>
                      <span className='dropzone-error-message'>
                        {t(
                          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.rejected_error_text',
                          {
                            filename: rejectedError.file.name,
                          }
                        )}
                      </span>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                getCategoryAction(category)
              )}
            </Grid>
          )}
        </Grid>
      ))}
      <Box display='flex' justifyContent='flex-end' width='100%'>
        <Button
          variant='contained'
          color='primary'
          data-testid='upload-submit-button'
          onClick={handleDocumentUpload}
          startIcon={isUploadingDocument ? <CircularProgress color='inherit' size={16} /> : null}
          disabled={isEmptyDocumentState || isUploadingDocument}>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.save_button_text'
          )}
        </Button>
      </Box>
    </>
  );
};
