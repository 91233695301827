import { Box, RadioGroup } from '@mui/material';
import dataProcessing from 'assets/images/workflows/data_processing.svg';
import classnames from 'classnames';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CriteriaTriggerOptionComponent } from './CriteriaTriggerOptionComponent';
import { DrawerNewWorkflowStepperCriteriaStepComponentProps } from './drawer-new-workflow-stepper.component.props';

export const DrawerNewWorkflowStepperCriteriaStepComponent: FC<DrawerNewWorkflowStepperCriteriaStepComponentProps> = ({
  control,
  setSelectedTrigger,
}) => {
  const { t } = useTranslation();

  return (
    <Box className={classnames(['new-workflow--step-option-container'])} my={2} display='flex' flexDirection='column'>
      <div className={classnames('form-section-header')}>
        {t('workflows_view:new_workflow_drawer.criteria_step.title')}
      </div>
      <p>{t('workflows_view:new_workflow_drawer.criteria_step.description')}</p>

      <Controller
        name='triggerCriteria'
        control={control}
        render={(params) => (
          <RadioGroup
            {...params.field}
            className={classnames(['card-option-group'])}
            onChange={(evt) => setSelectedTrigger(evt.currentTarget.value)}>
            <CriteriaTriggerOptionComponent
              selected={params.field.value === 'new_subscription'}
              value='new_subscription'
              onSelect={setSelectedTrigger}
              title={t('workflows_view:new_workflow_drawer.criteria_step.option.new_subscription.title')}
              text={t('workflows_view:new_workflow_drawer.criteria_step.option.new_subscription.description')}
              icon={<img className='new-workflow-icon' src={dataProcessing} alt='' />}
            />

            <CriteriaTriggerOptionComponent
              selected={params.field.value === 'subscription_is_up_for_renewal'}
              value='subscription_is_up_for_renewal'
              onSelect={setSelectedTrigger}
              title={t('workflows_view:new_workflow_drawer.criteria_step.option.subscription_is_up_for_renewal.title')}
              text={t(
                'workflows_view:new_workflow_drawer.criteria_step.option.subscription_is_up_for_renewal.description'
              )}
              icon={
                <div className={classnames(['subscription-status', 'squared', 'upForRenewal'])}>
                  {t(
                    'workflows_view:new_workflow_drawer.criteria_step.option.subscription_is_up_for_renewal.icon_text'
                  )}
                </div>
              }
            />
          </RadioGroup>
        )}
      />
    </Box>
  );
};
