import { AuditLogActions, AuditLogFieldNames } from 'shared/common.definitions';
import { SubscriptionHistory } from 'shared/models/subscription-history.model';

const auditLogFilterArray = [
  AuditLogFieldNames.AUTO_RENEWAL,
  AuditLogFieldNames.CANCELLATION_PERIOD,
  AuditLogFieldNames.DOCUMENT,
  AuditLogFieldNames.BILLING_FREQUENCY,
  AuditLogFieldNames.LINKED_SOFTWARE,
];

const auditLogFiltersForInclude = [
  filterIsAComment,
  filterIsNotASpecialEntry,
  filterIsLinkedSoftware,
  filterNotRemovedSpecialEntriesExceptLinkedSoftware,
  filterIsRemovedDocument,
];

const auditLogFiltersToExclude = [filterIsRemovedCommentAuditEntry];

export function getFilteredHistory(history: SubscriptionHistory[], switchChecked: boolean): SubscriptionHistory[] {
  if (switchChecked) {
    return history.filter(filterIsAComment);
  }

  const data = history.filter((historyItem) => {
    return auditLogFiltersForInclude.some((filter) => filter(historyItem));
  });

  return auditLogFiltersToExclude.reduce((d, fn) => d.filter(fn), data);
}

function filterIsAComment(historyItem: SubscriptionHistory): boolean {
  return !!historyItem.comment || historyItem.numberOfReplies >= 0;
}

function filterIsNotASpecialEntry(historyItem: SubscriptionHistory): boolean {
  return !auditLogFilterArray.includes(historyItem.actionChanges?.fieldName as AuditLogFieldNames);
}

function filterIsLinkedSoftware(historyItem: SubscriptionHistory): boolean {
  return historyItem.actionChanges?.fieldName === AuditLogFieldNames.LINKED_SOFTWARE;
}

function filterNotRemovedSpecialEntriesExceptLinkedSoftware(historyItem: SubscriptionHistory): boolean {
  return (
    !filterIsNotASpecialEntry(historyItem) &&
    historyItem.actionChanges?.fieldName !== AuditLogFieldNames.LINKED_SOFTWARE &&
    historyItem.action !== AuditLogActions.REMOVED
  );
}

function filterIsRemovedDocument(historyItem: SubscriptionHistory): boolean {
  return (
    historyItem.actionChanges?.fieldName === AuditLogFieldNames.DOCUMENT &&
    historyItem.action === AuditLogActions.REMOVED &&
    String(historyItem.actionChanges?.changes?.from) !== 'null'
  );
}

function filterIsRemovedCommentAuditEntry(historyItem: SubscriptionHistory): boolean {
  return (
    historyItem.action !== AuditLogActions.REMOVED &&
    historyItem.actionChanges?.fieldName !== AuditLogFieldNames.COMMENT
  );
}
