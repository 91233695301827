import {
  Checkbox,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { GenericDataType, TablePropsV2 } from './table.component.props';

export const TableV2 = <T extends GenericDataType>({
  bulkSelection,
  data,
  headCells,
  onSelectedRowsChange,
  onSortParamsChanged,
  renderCells,
  selectedRows,
  sortBy,
  sortOrder,
  stickyCheckbox,
  ...rest
}: TablePropsV2<T>) => {
  const [bulkSelectionChecked, setBulkSelectionChecked] = useState(false);

  useEffect(() => {
    const numOfSelectedRows = selectedRows?.length || 0;
    const totalNumOfRows = data?.length || 0;
    setBulkSelectionChecked(totalNumOfRows > 0 && numOfSelectedRows === totalNumOfRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();

  const stickyCheckboxSX = stickyCheckbox
    ? {
        background: theme.palette.background.light,
        left: 0,
        position: 'sticky',
        zIndex: 2,
      }
    : undefined;

  const changeSortOrder = (changedSortBy: string): void => {
    const sortParams = {
      sortBy: sortBy || undefined,
      sortOrder: sortOrder || undefined,
    };

    if (sortBy === changedSortBy) {
      switch (sortOrder) {
        case undefined:
          sortParams.sortOrder = 'asc';
          break;
        case 'asc':
          sortParams.sortOrder = 'desc';
          break;
        default:
          sortParams.sortBy = undefined;
          sortParams.sortOrder = undefined;
      }
    } else {
      sortParams.sortOrder = 'asc';
    }

    onSortParamsChanged?.(sortParams.sortBy, sortParams.sortOrder);
  };

  const handleBulkRowSelection = (data: T[], checked: boolean) => {
    if (!checked) {
      const ids = data.map((item) => item.id);
      onSelectedRowsChange?.(selectedRows?.filter((item) => !ids.includes(item.id)) || []);
    } else if (checked && selectedRows?.length === 0) {
      onSelectedRowsChange?.(data);
    } else {
      const rows = selectedRows ? [...selectedRows] : [];
      data.forEach((item) => {
        if (!selectedRows?.find((selectedItem) => selectedItem.id === item.id)) {
          rows.push(item);
        }
      });
      onSelectedRowsChange?.(rows);
    }
  };

  const handleRowSelection = (selectedItem: T, checked: boolean) => {
    if (checked) {
      const rows = selectedRows ? [...selectedRows, selectedItem] : [selectedItem];
      onSelectedRowsChange?.(rows);
    } else {
      const rows = selectedRows?.filter((item) => selectedItem.id !== item.id);
      onSelectedRowsChange?.(rows || []);
    }
  };

  useEffect(() => {
    if (!selectedRows || selectedRows?.length === 0) {
      setBulkSelectionChecked(false);
    } else {
      const ids = data.map((item) => item.id);
      const currentPageSelectedRows = selectedRows.filter((item) => ids.includes(item.id));
      setBulkSelectionChecked(currentPageSelectedRows?.length === ids.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const isRowSelected = (id: string) => {
    return selectedRows?.find((item) => item.id === id) !== undefined || false;
  };

  const getLabel = (label?: string | JSX.Element) => {
    return typeof label === 'string' ? <Typography variant='label'>{label}</Typography> : label;
  };

  const getSortLabel = useCallback(
    (headCellId: string, label?: string | JSX.Element): React.ReactNode => {
      const labelJSX = getLabel(label);

      if (headCellId === sortBy) {
        return (
          <TableSortLabel
            data-testid={`sort-label-${headCellId}-${sortOrder}`}
            active={sortOrder !== undefined}
            direction={sortOrder}
            onClick={() => changeSortOrder(headCellId)}>
            {labelJSX}
          </TableSortLabel>
        );
      }

      return (
        <TableSortLabel
          data-testid={`sort-label-${headCellId}-${sortOrder}`}
          active={false}
          direction='asc'
          onClick={() => changeSortOrder(headCellId)}>
          {labelJSX}
        </TableSortLabel>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortOrder, sortBy]
  );

  return (
    <MuiTable {...rest}>
      <MuiTableHead>
        <TableRow>
          {bulkSelection && (
            <TableCell padding='checkbox' sx={{ ...stickyCheckboxSX }}>
              <Checkbox
                data-testid='table-bulk-select-checkbox'
                checked={bulkSelectionChecked}
                onChange={(_event, checked) => handleBulkRowSelection(data || [], checked)}
              />
            </TableCell>
          )}
          {headCells.map(({ id, label, minWidth, sortable = false, sticky = false, stickyProps, ...rest }) => {
            const stickyCellStyle = sticky ? { ...stickyProps, position: 'sticky' } : {};

            return (
              <TableCell key={id} style={{ minWidth, ...stickyCellStyle }} {...rest}>
                {sortable ? getSortLabel(id, label) : getLabel(label)}
              </TableCell>
            );
          })}
        </TableRow>
      </MuiTableHead>
      <MuiTableBody>
        {data?.map((item, index) => {
          const isSelected = isRowSelected(item.id);
          return (
            <TableRow key={item.id} data-testid={`table-row-${index}`} hover selected={isSelected}>
              {bulkSelection && (
                <TableCell padding='checkbox' sx={{ ...stickyCheckboxSX }} data-testid={`table-row-${index}-checkbox`}>
                  <Checkbox checked={isSelected} onChange={(_event, checked) => handleRowSelection(item, checked)} />
                </TableCell>
              )}
              {renderCells?.(item, index)}
            </TableRow>
          );
        })}
      </MuiTableBody>
    </MuiTable>
  );
};

export default TableV2;
