import { FC } from 'react';
import { colors } from 'shared/theme';

export const NotesIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 11H6a.75.75 0 100 1.5h6a.75.75 0 100-1.5zm0-3H7.5a.75.75 0 000 1.5H12A.75.75 0 1012 8zm3-4.5h-2.25v-.75a.75.75 0 10-1.5 0v.75h-1.5v-.75a.75.75 0 00-1.5 0v.75h-1.5v-.75a.75.75 0 00-1.5 0v.75H3a.75.75 0 00-.75.75v10.5A2.25 2.25 0 004.5 17h9a2.25 2.25 0 002.25-2.25V4.25A.75.75 0 0015 3.5zm-.75 11.25a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V5h1.5v.75a.75.75 0 001.5 0V5h1.5v.75a.75.75 0 001.5 0V5h1.5v.75a.75.75 0 101.5 0V5h1.5v9.75z'
      fill={fill}
    />
  </svg>
);

NotesIcon.defaultProps = {
  fill: colors.osloGray,
  height: '19',
  width: '18',
};
