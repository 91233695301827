type ElementStyle = CSSStyleDeclaration & {
  msOverflowStyle: string;
};

export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');

  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  (outer.style as ElementStyle).msOverflowStyle = 'scrollbar'; // needed for WinJS apps

  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  document.body.removeChild(outer);

  return scrollbarWidth ?? 0;
};
