import { Skeleton, Typography } from '@mui/material';

import { StatusDot } from '../status-dot/status-dot.component';
import { StatusDotStatus } from '../status-dot/status-dot.component.props';
import { StatusProps } from './status.component.props';
import { StatusContainer, StatusSubtitle, StatusTextContainer } from './status.styles';

export const Status: React.FC<StatusProps> = ({ loading, status, statusTitle, subtitle, sx }) => {
  return (
    <StatusContainer key='status-container' sx={sx}>
      {!loading ? (
        <StatusDot key='dot' status={status as StatusDotStatus} />
      ) : (
        <Skeleton variant='circular' width={9} height={9} animation='wave' data-testid='status-dot-skeleton' />
      )}
      <StatusTextContainer key='status'>
        {!loading ? (
          <Typography key='title' variant='label'>
            {statusTitle}
          </Typography>
        ) : (
          <Skeleton
            variant='text'
            width={100}
            animation='wave'
            sx={{ fontSize: '14px', lineHeight: '18px' }}
            data-testid='status-title-skeleton'
          />
        )}
        {subtitle && !loading && (
          <StatusSubtitle key='subtitle' variant='small'>
            {subtitle}
          </StatusSubtitle>
        )}
        {subtitle && loading && (
          <Skeleton
            variant='text'
            width={80}
            animation='wave'
            sx={{ fontSize: '11px', lineHeight: '15px' }}
            data-testid='status-subtitle-skeleton'
          />
        )}
      </StatusTextContainer>
    </StatusContainer>
  );
};
