import { Origin } from 'shared/models/vendor-knowledge-item.model';
import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export function trackVendorKnowledgeTabClicked(subscriptionId: string, hasUnseenInsights: boolean) {
  EventTracking.trackEvent(trackEventName.VendorKnowledgeTabClicked, {
    hasUnseenInsights,
    subscriptionId,
  });
}

export function trackVendorKnowledgeItemClicked(subscriptionId: string) {
  EventTracking.trackEvent(trackEventName.VendorKnowledgeItemClicked, {
    subscriptionId,
  });
}

type VendorKnowledgeItemOpenedTrackingParams = {
  subscriptionId?: string;
  vendorId?: string;
};

export function trackVendorKnowledgeVideoPlayed(params: VendorKnowledgeItemOpenedTrackingParams) {
  EventTracking.trackEvent(trackEventName.VendorKnowledgeVideoPlayed, {
    subscriptionId: params.subscriptionId,
    vendorId: params.vendorId,
  });
}

interface BasicVendorKnowledgeTrackingParams {
  subscriptionId?: string;
  vendorId?: string;
  userId?: string;
  companyId?: string;
}
interface VendorKnowledgeModalTrackingParams extends BasicVendorKnowledgeTrackingParams {
  title: string;
  origin: Origin;
  hasVideo: boolean;
  storyblokInsightId: string;
}

export function trackVendorKnowledgeModalClosed(trackingParams: VendorKnowledgeModalTrackingParams) {
  EventTracking.trackEvent(trackEventName.VendorKnowledgeModalClosed, trackingParams);
}

export function trackVendorKnowledgeModalOpened(trackingParams: VendorKnowledgeModalTrackingParams) {
  EventTracking.trackEvent(trackEventName.VendorKnowledgeModalOpened, trackingParams);
}

export function trackVendorInsightAlertClicked(trackingParams: BasicVendorKnowledgeTrackingParams) {
  EventTracking.trackEvent(trackEventName.VendorknowledgeAlertClicked, trackingParams);
}
