/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { RadioGroup } from 'asteroids';
import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { differenceInMilliseconds, millisecondsToHours } from 'date-fns';
import { useUser } from 'hooks/useUser';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult, useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { DRAWER_VARIANT, REQUEST_STATUS, TOOLS_TYPE_FILTER, UPDATE_REQUEST_TYPE } from 'shared/common.definitions';
import { getHash, isSubscriptionDetailsRoute } from 'shared/helpers/common.helper';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import {
  trackWorkflowRequestCommentAdded,
  trackWorkflowRequestCompleted,
  trackWorkflowRequestRejected,
} from 'shared/logic/event-tracking/workflow-requests.events';
import {
  createRequestComment,
  deactivateTool,
  deleteRequest,
  deleteRequestComment,
  getRequestWorkflows,
  reopenRequest,
  updateRequestComment,
  updateRequestStatus,
  uploadRequestInitiativeDocument,
} from 'shared/logic/requests.logic';
import { Company, ModifyRequestCommentPayload, RequestDetails, UpdateRequestStatusPayload } from 'shared/models';
import { AppUrl } from 'src/constants/appurl';
import { invalidateRenewalReminder } from 'views/subscription-detail/components/renewal-status-alert/helpers';

import { useAsteroidsDialog } from '../../context/asteroids-dialog.context';
import { usePersistentSearchFilters } from '../../hooks';
import {
  useAddRequestDrawer,
  useRequestInitiativesData,
  useWorkflowActivitiesData,
  useWorkflowRequestDetailsData,
} from '../../views/requests/hooks';
import { DrawerWorkflowRequestDetailsProps } from '.';
import { ActivateToolFormComponent } from './components/activate-tool-form/activate-tool-form.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogComponentProps } from './components/dialog/dialog.component.props';
import { NewDetailsLookInfoBox } from './components/new-details-look-info-box/new-details-look-info-box.component';
import { ProcurementInitiativesComponent } from './components/procurement-initiatives/procurement-initiatives.component';
import { RequestDetailsCard } from './components/request-details-card/request-details-card.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';

enum RADIO_ITEM {
  APPROVE = 'approve',
  REJECT = 'reject',
}

enum REQUEST_STEP_STATUS {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

type ToolStoreSearchFilters = {
  searchTerm: string;
  toolTypeFilter: TOOLS_TYPE_FILTER;
};

export const DrawerWorkflowRequestDetails: FC<DrawerWorkflowRequestDetailsProps> = ({
  showNotification,
  subscriptionId,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const { hash, pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const user = useUser();
  const { onAddRequestDrawer } = useAddRequestDrawer();
  const { closeDialog: closeAsteroidsDialog, openDialog: openAsteroidsDialog } = useAsteroidsDialog();

  const [accordionsExpanded, setAccordionsExpanded] = useState(new Map([['request-details-accordion', true]]));
  const [actionText, setActionText] = useState('');
  const [commentText, setCommentText] = useState('');
  const [errorActionText, setErrorActionText] = useState('');
  const [actionValue, setActionValue] = useState('');
  const [isRequestApproved, setIsRequestApproved] = useState(false);
  const [isToolActivated, setIsToolActivated] = useState(false);
  const [dialog, setDialog] = useState<DialogComponentProps>({
    open: false,
  });
  const [initiativeCollapsedByUser, setInitiativeCollapsedByUser] = useState(false);
  const initiativesRef = useRef<HTMLDivElement>(null);

  const requestId = hash.split(DRAWER_VARIANT.REQUEST_DETAILS).slice(-1)[0];
  const defaultErrorActionText = t(
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.error_text'
  );

  const queryClient = useQueryClient();

  const [searchFilters] = usePersistentSearchFilters<ToolStoreSearchFilters>(
    null,
    pathname,
    { searchTerm: '', toolTypeFilter: TOOLS_TYPE_FILTER.ALL_TOOLS },
    (searchFilters) =>
      new URLSearchParams({
        tab: searchFilters.toolTypeFilter.toLowerCase(),
        ...(searchFilters.searchTerm && { searchTerm: searchFilters.searchTerm }),
      }).toString(),
    (search) => {
      const searchParams = new URLSearchParams(search);
      return {
        searchTerm: searchParams.get('searchTerm') || '',
        toolTypeFilter: searchParams.get('tab')?.toUpperCase() as TOOLS_TYPE_FILTER,
      };
    }
  );

  const invalidateTools = () => {
    if (pathname === '/tool-store') {
      queryClient.invalidateQueries({ queryKey: ['tools', 1, searchFilters] });
    }
  };

  const invalidateTickets = () => {
    if (pathname === '/sastrify-support') {
      queryClient.invalidateQueries({ queryKey: ['tools', 1, {}] });
    }
  };

  const handleCloseDrawer = () => {
    const includedHash = getHash(hash);
    replace(`${pathname}${search}${includedHash}`);
    toggleDrawer();
  };

  const {
    data: detailsData,
    error: detailsError,
    isLoading: isDetailsLoading,
    refetch: refetchDetails,
  } = useWorkflowRequestDetailsData(requestId);
  const {
    data: activitiesData,
    isLoading: isActivitiesLoading,
    refetch: refetchActivities,
  } = useWorkflowActivitiesData(requestId);
  const { data: initiativesData, refetch: refetchInitiatives } = useRequestInitiativesData(requestId, true);

  const company = queryClient.getQueryData<Company>('company');

  const { isLoading: isApproveLoading, mutate: setApprove } = useMutation(
    (payload: UpdateRequestStatusPayload) => updateRequestStatus(UPDATE_REQUEST_TYPE.APPROVE, payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Approve error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async () => {
        handleActionCancel();
        const { data: updatedDetailsData }: QueryObserverResult<RequestDetails, unknown> = await refetchDetails();
        refetchActivities();
        await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });
        showNotification?.(
          t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_new_approve_text'
          ),
          NotificationAlertType.Success
        );

        if (
          updatedDetailsData?.permissions?.canActivateRequest &&
          updatedDetailsData?.currentStepStatus === REQUEST_STEP_STATUS.APPROVED
        ) {
          setIsRequestApproved(true);
          openActivateToolDialog();
          trackWorkflowRequestCompleted({
            companyId: company?.id,
            companyName: company?.name,
            requestType:
              detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL ? 'renewal' : 'newPurchase',
            timeToCompleteInHours:
              updatedDetailsData?.updatedAt && updatedDetailsData?.createdAt
                ? millisecondsToHours(
                    differenceInMilliseconds(
                      new Date(updatedDetailsData?.updatedAt),
                      new Date(updatedDetailsData?.createdAt)
                    )
                  )
                : undefined,
            toolName: updatedDetailsData?.vendorName || 'unknown',
          });
        }
      },
    }
  );
  const { isLoading: isRejectLoading, mutate: setReject } = useMutation(
    (payload: UpdateRequestStatusPayload) => updateRequestStatus(UPDATE_REQUEST_TYPE.REJECT, payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Reject error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async () => {
        handleActionCancel();
        const { data: updatedDetailsData }: QueryObserverResult<RequestDetails, unknown> = await refetchDetails();
        refetchActivities();
        await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });
        showNotification?.(
          t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_new_reject_text'
          ),
          NotificationAlertType.Success
        );

        if (updatedDetailsData?.currentStepStatus === REQUEST_STEP_STATUS.REJECTED) {
          trackWorkflowRequestRejected({
            companyId: company?.id,
            companyName: company?.name,
            requestType:
              detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL ? 'renewal' : 'newPurchase',
            toolName: updatedDetailsData?.vendorName || 'unknown',
          });
        }
      },
    }
  );
  const { mutate: setDelete } = useMutation(() => deleteRequest(requestId), {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'Delete error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: async () => {
      invalidateTools();
      invalidateTickets();

      invalidateRenewalReminder(queryClient, subscriptionId as string);
      queryClient.invalidateQueries(['subscription', subscriptionId]);

      await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });
      handleCloseDrawer();

      showNotification?.(
        t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_delete_new_text'
        ),
        NotificationAlertType.Success
      );
    },
  });
  const { isLoading: isCreateCommentLoading, mutate: setCreateComment } = useMutation(
    (payload: ModifyRequestCommentPayload) => createRequestComment(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Create request comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchActivities();
        setCommentText('');

        trackWorkflowRequestCommentAdded({
          companyId: company?.id,
          companyName: company?.name,
          requestId: String(detailsData?.requestId) || '',
          requestType: detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL ? 'renewal' : 'newPurchase',
          toolName: detailsData?.vendorName || 'unknown',
        });
      },
    }
  );
  const { mutate: setDeleteComment } = useMutation(
    (commentId: string | number) => deleteRequestComment(requestId, commentId),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Delete request comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchActivities();
        setCommentText('');
      },
    }
  );
  const { isLoading: isUpdateCommentLoading, mutate: setUpdateComment } = useMutation(
    (payload: ModifyRequestCommentPayload) => updateRequestComment(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Update request comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchActivities();
        setCommentText('');
      },
    }
  );
  const { isLoading: isReopenLoading, mutate: setReopenRequest } = useMutation(() => reopenRequest(requestId), {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'Reopen error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: async () => {
      await refetchDetails();
      refetchActivities();
      await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });

      showNotification?.(
        t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_reopen_new_text'
        ),
        NotificationAlertType.Success
      );
    },
  });
  const { isLoading: isDeactivatingTool, mutate: setDeactivateTool } = useMutation(
    () => deactivateTool(Number(requestId || 0)),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Deactivate tool error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async () => {
        showNotification?.(
          t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_deactivated_text'
          ),
          NotificationAlertType.Success
        );

        if (detailsData?.subscriptionName && detailsData?.subscriptionId) {
          const isSubscriptionDetails = isSubscriptionDetailsRoute(pathname);

          toggleDrawer();
          push(
            AppUrl.getToolDetailsUrl(
              { id: detailsData?.subscriptionId, name: detailsData?.subscriptionName },
              isSubscriptionDetails ? 'refetch=true' : ''
            )
          );
        }
      },
    }
  );

  useEffect(() => {
    if (detailsError && (detailsError as AxiosError).response?.status === 400) {
      toggleDrawer();
      push('/request-not-found');
    }
    if (detailsData && detailsData?.currentStepStatus === REQUEST_STEP_STATUS.APPROVED) {
      setIsRequestApproved(true);
    }
    if (detailsData && detailsData?.requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED) {
      setIsToolActivated(true);
    }
  }, [detailsData, detailsError, push, toggleDrawer]);

  useEffect(() => {
    if (initiativesData && initiativesData.length > 0 && !initiativeCollapsedByUser) {
      const searchParams = new URLSearchParams(search);

      if (searchParams.has('supportTicketId')) {
        const supportTicketId = searchParams.get('supportTicketId');

        if (supportTicketId && !accordionsExpanded.get(supportTicketId)) {
          const newExpanded = new Map(accordionsExpanded);

          newExpanded.set(supportTicketId, true);
          setAccordionsExpanded(newExpanded);
          window.requestAnimationFrame(() => {
            initiativesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          });
        }
      }
    }
  }, [accordionsExpanded, initiativesData, search, initiativeCollapsedByUser]);

  const isRenewalInitiative = useMemo(
    () => detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL,
    [detailsData?.requestWorkflowTypeId]
  );

  const isRequestRejected = (item: RequestDetails | undefined): boolean => {
    if (item?.requestStateId === REQUEST_STATUS.REJECTED) return true;
    return false;
  };

  const isRequestReopenAllowed = (item: RequestDetails | undefined): boolean => {
    if (item?.permissions?.canReopenRequest) return true;
    return false;
  };

  const handleActionCancel = () => {
    setActionText('');
    setErrorActionText('');
    setActionValue('');
  };

  const handleActionConfirm = () => {
    if (!actionText) {
      setErrorActionText(defaultErrorActionText);
    } else if (detailsData) {
      const payload = { comment: actionText, requestId: detailsData?.requestId, stepId: detailsData?.currentStepId };

      if (actionValue === RADIO_ITEM.APPROVE) setApprove(payload);
      if (actionValue === RADIO_ITEM.REJECT) setReject(payload);
    }
  };

  const handleActionTextChange = (text: string) => {
    setErrorActionText('');
    setActionText(text);
  };

  const handleActionValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionValue((event.target as HTMLInputElement).value);
  };

  const handleSendComment = () => {
    const payload = {
      comment: commentText,
      requestId: Number(requestId),
    };

    setCreateComment(payload);
  };

  const openActivateToolDialog = () => {
    toggleDrawer();
    openAsteroidsDialog?.({
      bodyContent: (
        <ActivateToolFormComponent
          closeDialog={closeAsteroidsDialog}
          showNotification={showNotification}
          requestId={requestId}
          initiativeDetails={detailsData}
        />
      ),
      onClose: closeAsteroidsDialog,
      width: 600,
    });
  };

  const viewActivatedTool = (): void => {
    if (!detailsData) return;
    toggleDrawer();
    push(
      AppUrl.getToolDetailsUrl({ id: detailsData?.subscriptionId, name: detailsData?.subscriptionName }, 'refetch=true')
    );
  };

  const openRequestDeleteConfirmationDialog = () => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.action_btn_label'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.cancel_btn_label'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.text'
      ),
      onAction: setDelete,
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.new_title'
      ),
    });
  };

  const openDeactivateToolConfirmationDialog = () => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.deactivate_tool_action_btn_label'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.cancel_btn_label'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.deactivate_tool_text'
      ),
      onAction: () => {
        setDeactivateTool();
        setDialog({ open: false });
      },
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.deactivate_tool_title'
      ),
    });
  };

  const toggleAccordions = (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const newExpanded = new Map(accordionsExpanded);

    if (newExpanded.get(id)) {
      newExpanded.delete(id);
    } else {
      newExpanded.set(id, isExpanded);
    }

    setAccordionsExpanded(newExpanded);
    setInitiativeCollapsedByUser(true);
  };

  const renderActionsTitle = () => (
    <Typography variant='h2' mb='1rem'>
      {t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.title'
      )}
    </Typography>
  );

  const renderApproveOrRejectActions = () => (
    <>
      {renderActionsTitle()}
      <Typography component='div' variant='h3' data-testid='actions-question' mb={1}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.action_approve_new_question_text'
        )}
      </Typography>
      <Box mb={3} ml={1}>
        <RadioGroup
          row
          name='row-radio-buttons-group'
          value={actionValue}
          onChange={handleActionValueChange}
          options={[
            {
              disabled: detailsData?.currentStepStatus !== 'pending',
              label: t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.action_approve_yes'
              ),
              value: RADIO_ITEM.APPROVE,
            },
            {
              disabled: detailsData?.currentStepStatus !== 'pending',
              label: t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.action_approve_no'
              ),
              value: RADIO_ITEM.REJECT,
            },
          ]}
        />
      </Box>
      {actionValue && (
        <>
          <Box mb={4}>
            <RichTextEditorComponent
              value={actionText}
              onChange={handleActionTextChange}
              placeholder={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.text_area_placeholder'
              )}
              dataTestId='actions-details-input'
              error={!!errorActionText}
              helperText={errorActionText}
              disabled={detailsData?.currentStepStatus !== 'pending'}
              autoFocus
            />
          </Box>
          {renderActionButtons()}
        </>
      )}
    </>
  );

  const renderActivateToolAction = () => (
    <>
      {!isToolActivated && detailsData?.permissions?.canActivateRequest && (
        <>
          {renderActionsTitle()}
          <Typography component='div' data-testid='activate-tool-subtitle' mb={2}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.activate_tool_new_text'
            )}
          </Typography>
          <Box mb={4}>
            <Button
              color='primary'
              size='small'
              variant='contained'
              onClick={openActivateToolDialog}
              data-testid='activate-tool-btn'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.activate_tool_btn'
              )}
            </Button>
          </Box>
        </>
      )}
      {isToolActivated && detailsData?.permissions?.canViewSubscription && (
        <>
          {renderActionsTitle()}
          <Typography component='div' mb={2}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.view_tool_text'
            )}
          </Typography>
          <Box mb={4}>
            <Button
              color='primary'
              size='small'
              variant='contained'
              onClick={viewActivatedTool}
              data-testid='view-tool-btn'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.view_tool_btn'
              )}
            </Button>
          </Box>
        </>
      )}
    </>
  );

  const handleReopenRequest = () => {
    setReopenRequest();
  };

  const renderReopenRequestAction = () => (
    <>
      {renderActionsTitle()}
      <Typography component='div' data-testid='activate-tool-subtitle' mb={2}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.reopen_request_new_text'
        )}
      </Typography>
      <Box mb={4}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={handleReopenRequest}
          disabled={isReopenLoading}
          startIcon={isReopenLoading ? <CircularProgress color='inherit' size={16} /> : null}
          data-testid='reopen-request-btn'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.reopen_request_new_btn'
          )}
        </Button>
      </Box>
    </>
  );

  const renderDeactivateToolAction = () => (
    <>
      {renderActionsTitle()}
      <Typography component='div' data-testid='activate-tool-subtitle' mb={2}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.deactivate_tool_text'
        )}
      </Typography>
      <Box mb={4}>
        <Button
          data-testid='deactivate-tool-btn'
          variant='contained'
          color='error'
          size='small'
          onClick={openDeactivateToolConfirmationDialog}
          disabled={isDeactivatingTool}
          startIcon={isDeactivatingTool ? <CircularProgress color='inherit' size={16} /> : null}>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.deactivate_tool_btn'
          )}
        </Button>
      </Box>
    </>
  );

  const renderActions = () => (
    <>
      {isRequestApproved && renderActivateToolAction()}
      {!isRequestApproved && detailsData?.permissions?.approve && renderApproveOrRejectActions()}
      {!isRenewalInitiative &&
        isRequestRejected(detailsData) &&
        isRequestReopenAllowed(detailsData) &&
        renderReopenRequestAction()}
      {isRenewalInitiative && isRequestRejected(detailsData) && renderDeactivateToolAction()}
      {isRequestApproved ||
        (!isRequestApproved && detailsData?.permissions?.approve) ||
        (!isRenewalInitiative && isRequestRejected(detailsData) && isRequestReopenAllowed(detailsData)) ||
        (isRenewalInitiative && isRequestRejected(detailsData) && <Divider variant='fullWidth' sx={{ my: '2rem' }} />)}
    </>
  );

  const renderActionButtons = () => (
    <Stack direction='row' spacing={2} alignItems='center' justifyContent='flex-end'>
      <Button
        variant='text'
        color='secondary'
        size='small'
        onClick={handleActionCancel}
        disabled={detailsData?.currentStepStatus !== 'pending' || !actionValue}
        data-testid='actions-cancel-button'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.cancel_button_label'
        )}
      </Button>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleActionConfirm}
        startIcon={isApproveLoading || isRejectLoading ? <CircularProgress color='inherit' size={16} /> : null}
        disabled={detailsData?.currentStepStatus !== 'pending' || !actionValue || isApproveLoading || isRejectLoading}
        data-testid='actions-confirm-button'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.confirm_button_label'
        )}
      </Button>
    </Stack>
  );

  const renderActivities = () => (
    <ActivitiesComponent
      activitiesTitle={t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.title'
      )}
      commentTestId='add-comment'
      commentAvatarSrc={user.avatar}
      commentAvatarAlt={user.name}
      commentText={commentText}
      setCommentText={(value) => setCommentText(value)}
      onCommentAction={handleSendComment}
      logItems={activitiesData || []}
      onLogItemDelete={(id) => id && setDeleteComment(id)}
      onLogItemSend={(val, id) => {
        setUpdateComment({
          comment: val || '',
          id: id || '',
          requestId: Number(requestId || 0),
        });
      }}
      alertInfo={!!initiativesData?.length}
      isSingleToolInitiative
      isSubmitButtonDisabled={isCreateCommentLoading || isUpdateCommentLoading}
    />
  );

  const handleEditRequest = () => {
    const includedHash = getHash(hash);
    onAddRequestDrawer(`${includedHash}#${DRAWER_VARIANT.EDIT_REQUEST}${requestId}`);
  };

  const renderDetailsCard = () => (
    <>
      <RequestDetailsCard
        expanded={Array.from(accordionsExpanded.keys())}
        setExpanded={toggleAccordions}
        detailsData={detailsData}
        isRequestApproved={isRequestApproved}
        isToolActivated={isToolActivated}
        onEditRequest={handleEditRequest}
        onDeleteRequest={openRequestDeleteConfirmationDialog}
      />
      <Divider variant='fullWidth' sx={{ my: '2rem' }} />
    </>
  );

  const renderProcurementInitiatives = () => {
    if (initiativesData && initiativesData?.length > 0) {
      return (
        <Box ref={initiativesRef}>
          <ProcurementInitiativesComponent
            expanded={Array.from(accordionsExpanded.keys())}
            setExpanded={toggleAccordions}
            vendor={{
              avatarUrl: detailsData?.vendorAvatarUrl || '',
              name: detailsData?.vendorName || '',
            }}
            initiatives={initiativesData}
            currency={detailsData?.licenseCurrency}
            showNotification={showNotification}
            companyId={company?.id || '0'}
            documentsUploadFunction={uploadRequestInitiativeDocument}
            refetchInitiatives={async () => {
              await refetchInitiatives();
            }}
            requestWorkflowTypeId={detailsData?.requestWorkflowTypeId || REQUEST_WORKFLOW_TYPE.APPROVAL}
          />
          <Divider variant='fullWidth' sx={{ my: '2rem' }} />
        </Box>
      );
    }
  };

  const renderNewDetailsLookInfoBox = () => <NewDetailsLookInfoBox />;

  return (
    <>
      {!isDetailsLoading && !isActivitiesLoading && (
        <Box width='100%' pr='4rem'>
          {renderNewDetailsLookInfoBox()}
          {renderDetailsCard()}
          {renderProcurementInitiatives()}
          {renderActions()}
          {activitiesData && renderActivities()}
        </Box>
      )}
      {/* Asteroids dialog doesn't accept disableEnforceFocus prop and therefore has an issue when opened over a Drawer. Until component is updated use this local instead. */}
      <DialogComponent {...dialog} />
    </>
  );
};
