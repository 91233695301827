import { RENEWAL_CHANNEL_PREFERENCE_DEFAULT_WITH_SLACK } from 'libs/constants';
import { RequiredNotificationsTemplateIds } from 'libs/constants/notification-preferences/template-ids.enum';
import { RenewalReminderChannel } from 'libs/dtos';
import { NOTIFICATION_PREFERENCES_CATEGORY } from 'libs/enums';

import { apiService, apiUrl } from '../services';

type UserNotificationPreferences = Record<
  NOTIFICATION_PREFERENCES_CATEGORY,
  {
    [RequiredNotificationsTemplateIds.RENEWAL_REMINDER]: {
      channels: RenewalReminderChannel;
      isDefault?: true;
    };
  }
>;

export enum RenewalAction {
  AutomaticallyRenew = 'set_to_auto_renew',
  StartInitiative = 'start_initiative',
  MoveToInactive = 'set_to_move_to_inactive',
}

export const markRenewalCompleted = (renewalAction: RenewalAction, id: string, subscriptionId: string) => {
  return apiService.post(apiUrl.completeRenewalReminder(id), { actionType: renewalAction, subscriptionId });
};

export const muteNextReminder = (id: string) => {
  return apiService.post(apiUrl.muteRenewalReminder(id));
};

export const getRenewalReminder = async (subscriptionId: string) => {
  const res = await apiService.get(apiUrl.getRenewalReminder(subscriptionId));
  return res.data;
};

export const getCompanyRenewalChannelPreference = async (): Promise<RenewalReminderChannel> => {
  const response = await apiService.get(apiUrl.companyRenewalNotificationChannelPreference());
  return response.data;
};

export const mutateCompanyRenewalChannelPreference = async (
  channels: RenewalReminderChannel
): Promise<RenewalReminderChannel> => {
  const response = await apiService.patch(apiUrl.companyRenewalNotificationChannelPreference(), channels);
  return response.data;
};

export const getUserRenewalChannelPreference = async (): Promise<{
  isDefault?: boolean;
  personalChannelPreference: RenewalReminderChannel;
}> => {
  const allUserPreferences: UserNotificationPreferences = await apiService
    .get(apiUrl.notificationPreferences())
    .then((response) => response.data);

  const personalPreference = allUserPreferences?.required?.['required:renewal_reminder'];
  return {
    isDefault: personalPreference?.isDefault,
    personalChannelPreference: personalPreference?.channels || RENEWAL_CHANNEL_PREFERENCE_DEFAULT_WITH_SLACK,
  };
};

export const mutateUserRenewalChannelPreference = async (
  channels: RenewalReminderChannel
): Promise<RenewalReminderChannel> => {
  const response = await apiService.patch(apiUrl.notificationPreferences(), {
    category: 'required',
    channels,
    templateId: RequiredNotificationsTemplateIds.RENEWAL_REMINDER,
  });
  return response.data.channels;
};
