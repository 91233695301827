import { alpha } from '@mui/system';
// import { alpha } from '@mui/material/styles';

export const mainColors = {
  text: '#363F45',
  primary: '#068466',
  secondary: '#E0E0E0',
  tertiary: '#ED6C02',
  error: '#D32F2F',
  warning: '#F7DD0E',
  info: '#6663FF',
  success: '#0ACD9F',
  inactive: '#A1A5A8',
};

export const singleColors = {
  energyYellow: '#FFD643',
};

export const colors = {
  background: {
    paper: '#FFFFFF',
    default: '#F2F5FA',
    light: '#FFFFFF',
  },
  text: {
    main: mainColors.text,
    dark: '#343A40',
    primary: mainColors.text,
    secondary: '#71787C',
    disabled: '#A1A5A8',
  },
  primary: {
    main: mainColors.primary,
    dark: '#065B47',
    light: '#7CBCAE',
    contrastText: '#FFFFFF',
    highlight: '#EBF5F3',
  },
  secondary: {
    main: mainColors.secondary,
    dark: '#BDBDBD',
    light: '#FAFAFA',
    contrastText: mainColors.text,
    highlight: '#F5F5F5',
  },
  tertiary: {
    main: mainColors.tertiary,
    dark: '#E65100',
    light: '#FFF5E5',
    contrastText: '#FFFFFF',
    highlight: '#FEF9F5',
  },
  error: {
    main: mainColors.error,
    dark: '#541313',
    light: '#FBEAEA',
    contrastText: '#FFFFFF',
    highlight: '#FDF7F7',
  },
  warning: {
    main: mainColors.warning,
    dark: '#5F2B01',
    light: '#FEFCE7',
    contrastText: mainColors.text,
    highlight: '#FFFEF5',
  },
  info: {
    main: mainColors.info,
    dark: '#1F1E4E',
    light: '#EDEDF9',
    contrastText: '#FFFFFF',
    highlight: '#FBFBFF',
  },
  success: {
    main: mainColors.success,
    dark: '#123214',
    light: '#E6FAF5',
    contrastText: mainColors.text,
    highlight: '#F5FDFB',
  },
  action: {
    active: '#71787C', // mainColors.secondary,
    disabled: 'rgba(0, 0, 0, 0.06)',
    hover: alpha(mainColors.text, 0.08),
    selected: alpha(mainColors.text, 0.16),
    focus: alpha(mainColors.text, 0.3),
  },
  divider: 'rgba(0, 0, 0, .12)',
  outlineBorder: '#BDBDBD', // secondary.dark
  backdropOverlay: 'rgba(0, 0, 0, .5)',
  tableRowHover: '#EBECEE',
  tableRowSelected: '#EBF5F3', // primary.highlight
};

export const backgrounds = {
  successToWarningGradient: `linear-gradient(242.15deg, ${alpha(mainColors.success, 0.23)} -5.36%, ${alpha(
    mainColors.warning,
    0.42
  )} 76.12%, ${singleColors.energyYellow} 122.94%), ${colors.background.light}`,
};
