export interface TimeLineChartItem {
  x: string;
  y: number;
}

export interface TimeLineChartDataSet {
  id: string;
  data: TimeLineChartItem[];
}

export enum TimeLineSpan {
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
  YEAR = 'years',
}

export interface TimeLineChartProps {
  data: TimeLineChartDataSet[];
  xAxisTimeUnit: number;
  xAxisTimeSpan: TimeLineSpan;
  xAxisFormat: string;
  lineColors?: string[];
}

export interface PointSymbol {
  borderColor: string;
  borderWidth: number;
  color: string;
  size: number;
}

export interface TimeLineChartConfig {
  maxY?: number;
  minY?: number;
  showChart: boolean;
  tickValues?: string;
  xFormat?: string;
  colors?: string[];
}
