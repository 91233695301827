import { KomboIntegratedTool } from 'libs/models/integration.model';
import { FC, lazy } from 'react';
import { Route, Switch } from 'react-router';
import { featureFlags, UserRoles } from 'shared/common.definitions';
import { RoutedProps } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { NAVIGATION_URLS } from 'src/constants/navigation';

const HRISIndexView = lazy(() => import('../views/hris/pages/index.view'));
const HRISDetailsView = lazy(() => import('views/hris/pages/details/hris-tool.view'));
const ErrorView = lazy(() => import('../views/error/error.view'));

export const IntegrationsRoutes: FC<RoutedProps> = (props) => {
  const userRole = useAppSelector((state) => state.authentication.user?.role);
  const isViewerRole = userRole === UserRoles.Viewer;

  // Feature flags
  const hrIntegrationsEnabled = useCompanyFeatureFlag(featureFlags.HR_INTEGRATIONS);

  return (
    <>
      {!isViewerRole && hrIntegrationsEnabled ? (
        <Switch>
          <Route exact path={NAVIGATION_URLS.HR_INFORMATION_SYSTEMS.INDEX}>
            <HRISIndexView {...props} />
          </Route>
          {/* Dynamic routes for each integration */}
          {Object.values(KomboIntegratedTool).map((key) => (
            <Route
              key={key}
              path={`${NAVIGATION_URLS.HR_INFORMATION_SYSTEMS.DETAILS}/${key}`}
              render={() => <HRISDetailsView integratedTool={key} />}
            />
          ))}
          <Route path='*'>
            <ErrorView code={404} />
          </Route>
        </Switch>
      ) : (
        <ErrorView code={404} />
      )}
    </>
  );
};
