import { trackEventName } from 'shared/common.definitions';
import { DetailsSaveSchema } from 'shared/models/subscription-details.model';
import { EventTracking } from 'shared/services';

export const trackSubscriptionDetailTabClicked = (subscriptionId: string) => {
  EventTracking.trackEvent(trackEventName.SubscriptionDetailTabClicked, { subscriptionId });
};

export const trackSubscriptionDetailSaved = (data: {
  userId: string;
  companyId: string;
  note: string;
  subscriptionId: string;
  subscriptionDetailFields: DetailsSaveSchema;
  subscriptionCreationDate: string;
}) => {
  EventTracking.trackEvent(trackEventName.SubscriptionDetailSaved, { data });
};
