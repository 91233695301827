import { Box, styled } from '@mui/material';

import { StylesProps } from './vendor-logo.component';

export const VendorLogoWrapper = styled(Box)<StylesProps>(({ theme }) => ({
  '& .vendor-logo': {
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },

  '& .MuiAvatar-img': {
    objectFit: 'contain',
  },
  '& .MuiAvatar-square': {
    borderRadius: theme.spacing(0),
    height: '100%',
    width: '100%',
  },

  '& :not(.MuiAvatar-colorDefault)': {
    background: theme.palette.background.light,
  },

  position: 'relative',
}));
