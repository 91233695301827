import { Box, styled } from '@mui/material';

export const DocumentAttributeEditWrapper = styled(Box)(({ theme }) => ({
  '& .mandatoryLabel': {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: 400,
    fontsize: '0.875rem',
    marginLeft: '0.3rem',
  },
  '& .nameInput': {
    '&::placeholder': {
      color: theme.palette.text.disabled,
    },
  },
  '& .numberInput': {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  '& .placeholderText': {
    color: theme.palette.text.disabled,
    fontFamily: 'inherit !important',
  },
}));
