import { useCallback } from 'react';
import { UserIdentityTrait } from 'shared/common.definitions';
import { sendIdentifyEvent } from 'shared/logic/track-events.logic';

export function useEventTracking() {
  const startTracking = useCallback((userId: string, userProfile: UserIdentityTrait) => {
    sendIdentifyEvent(userId, userProfile);

    // This is commented out because we are sending Login event via backend when we update the last login date.
    // sendLoggedInEvent();
  }, []);

  return { startTracking };
}
