import { styled } from '@mui/material/styles';

import pageBreakpoints from '../../helpers/page-breakpoints';

export const StyledPageHeaderContainer = styled('div')(({ theme }) => ({
  '& .page-header-content': {
    '&.page-header-content--attached': {
      paddingBottom: '0',
    },
    [theme.breakpoints.down('xl')]: {
      ...pageBreakpoints('sm'),
    },
    [theme.breakpoints.up('xl')]: {
      ...pageBreakpoints('xl'),
    },
    padding: theme.spacing(3, 4),
    margin: '0 auto',
  },
  background: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  width: '100%',
}));
