import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button } from '@mui/material';
import { DocumentType } from 'components/email-bridge/email-bridge.component.props';
import { EmailBridgeModalComponent } from 'components/email-bridge-modal';
import { EmailBridgeComponent, LoadingComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useFetchData } from 'hooks/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { googleTagManagerEvents } from 'shared/common.definitions';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import { getSubscriptionDocuments } from 'shared/logic/subscription-item.logic';
import { Company, Document, SastrifyStore, Subscription, User } from 'shared/models';

import { NoResultFeature, NoResultFeatureProps } from '../..';
import { RelevantDocumentsChecklist } from '..';
import { SubscriptionDocumentsTableFeature } from '../subscription-documents-table';
import { SubscriptionDocumentsTabFeatureProps } from './subscription-documents-tab.props';

export const SubscriptionDocumentsTabFeature: FC<SubscriptionDocumentsTabFeatureProps> = ({
  closeModal,
  documentsCount,
  isSubscriptionInDiscovery,
  openDocumentUploadDrawer,
  showModal,
  showNotification,
  subscriptionDocumentsCache,
  subscriptionId,
  subscriptionName,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: subscriptionDocumentsData, isLoading: isLoadingDocument } = useFetchData<Document[]>(
    'subscriptionDocuments',
    () => getSubscriptionDocuments(subscriptionId),
    {
      initialData: subscriptionDocumentsCache,
      onError: (error: unknown) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      refetchOnWindowFocus: false,
    }
  );
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const subscription: Subscription | undefined = queryClient.getQueryData(['subscription', subscriptionId]);
  const company: Company | undefined = queryClient.getQueryData('company');

  const documentRegex = /[0-9]/;

  const NoResultFeatureProps: NoResultFeatureProps = {
    actionClassName: 'is-primary is-shadowed',
    actionText: t(
      'subscription_detail_view:tabs_component_section.subscription_document_tab.no_document_section.action_text'
    ),
    className: 'is-white is-shadowed',
    messages: isSubscriptionInDiscovery
      ? [
          t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.no_document_section.new_solutions_message_text',
            { newSolutionName: subscriptionName }
          ),
        ]
      : [
          t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.no_document_section.message_text',
            { subscriptionName }
          ),
        ],
    onActionClick: openDocumentUploadDrawer,
    title: isSubscriptionInDiscovery
      ? t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.no_document_section.new_solution_title_text'
        )
      : t('subscription_detail_view:tabs_component_section.subscription_document_tab.no_document_section.title_text'),
  };
  if (documentRegex.test(documentsCount) && isLoadingDocument) return <LoadingComponent />;

  const handleLinkClick = () => {
    showModal?.({
      children: (
        <EmailBridgeModalComponent
          subscriptionName={subscription?.name || subscription?.vendorName}
          subscriptionInvoiceEmail={subscription?.subscriptionInvoiceEmail}
          subscriptionsEmail={subscription?.subscriptionEmail}
          vendorLogoUrl={subscription?.vendorLogoUrl}
          companyEmail={company?.uploadsEmail}
          closeModal={closeModal}
        />
      ),
      hasCustomFooter: true,
      hasCustomWidth: true,
      showHeader: false,
    });

    fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.ForwardYourDocumentsLinkClicked);
  };

  return (
    <>
      <EmailBridgeComponent type={DocumentType.documents} handleClick={handleLinkClick} />

      <Box display='flex' justifyContent='flex-end'>
        {documentRegex.test(documentsCount) && (
          <Button
            data-testid='upload-document-button'
            onClick={openDocumentUploadDrawer}
            variant='contained'
            color='primary'>
            {t('subscription_detail_view:tabs_component_section.subscription_document_tab.upload_document_button_text')}
          </Button>
        )}
      </Box>

      <RelevantDocumentsChecklist showNotification={showNotification} />
      {documentRegex.test(documentsCount) ? (
        <SubscriptionDocumentsTableFeature
          documents={subscriptionDocumentsData as Document[]}
          showModal={showModal}
          closeModal={closeModal}
          showNotification={showNotification}
        />
      ) : (
        <NoResultFeature {...NoResultFeatureProps} />
      )}
    </>
  );
};
