import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export const trackSlackInitiativeRedirect = (data: { requestId: string; initiativeName: string; tag: string }) => {
  EventTracking.trackEvent(trackEventName.SlackInitiativeRedirect, { ...data, source: 'slack' });
};

export const trackSlackSubscriptionCommentRedirect = (data: {
  subscriptionId?: string;
  commentId: string;
  replyId?: string;
  tag: string;
}) => {
  EventTracking.trackEvent(trackEventName.SlackSubscriptionCommentRedirect, { ...data, source: 'slack' });
};
