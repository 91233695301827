import { FC } from 'react';
import { colors } from 'shared/theme';

export const RepeatIcon: FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.38729 0.887294C8.57035 0.704235 8.86715 0.704235 9.05021 0.887294L10.9252 2.76229C11.1083 2.94535 11.1083 3.24215 10.9252 3.42521L9.05021 5.30021C8.86715 5.48326 8.57035 5.48326 8.38729 5.30021C8.20424 5.11715 8.20424 4.82035 8.38729 4.63729L9.93084 3.09375L8.38729 1.55021C8.20424 1.36715 8.20424 1.07035 8.38729 0.887294Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.03125 3.5625C3.65829 3.5625 3.3006 3.71066 3.03688 3.97438C2.77316 4.2381 2.625 4.59579 2.625 4.96875V5.90625C2.625 6.16513 2.41513 6.375 2.15625 6.375C1.89737 6.375 1.6875 6.16513 1.6875 5.90625V4.96875C1.6875 4.34715 1.93443 3.75101 2.37397 3.31147C2.81351 2.87193 3.40965 2.625 4.03125 2.625H10.5938C10.8526 2.625 11.0625 2.83487 11.0625 3.09375C11.0625 3.35263 10.8526 3.5625 10.5938 3.5625H4.03125Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.36271 7.44979C4.54576 7.63285 4.54576 7.92965 4.36271 8.11271L2.81916 9.65625L4.36271 11.1998C4.54576 11.3829 4.54576 11.6796 4.36271 11.8627C4.17965 12.0458 3.88285 12.0458 3.69979 11.8627L1.82479 9.98771C1.64174 9.80465 1.64174 9.50785 1.82479 9.32479L3.69979 7.44979C3.88285 7.26674 4.17965 7.26674 4.36271 7.44979Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5938 6.375C10.8526 6.375 11.0625 6.58487 11.0625 6.84375V7.78125C11.0625 8.40285 10.8156 8.99899 10.376 9.43853C9.93649 9.87807 9.34035 10.125 8.71875 10.125H2.15625C1.89737 10.125 1.6875 9.91513 1.6875 9.65625C1.6875 9.39737 1.89737 9.1875 2.15625 9.1875H8.71875C9.09171 9.1875 9.4494 9.03934 9.71312 8.77562C9.97684 8.5119 10.125 8.15421 10.125 7.78125V6.84375C10.125 6.58487 10.3349 6.375 10.5938 6.375Z'
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='12' height='12' fill={fill} transform='translate(0.75 0.75)' />
      </clipPath>
    </defs>
  </svg>
);

RepeatIcon.defaultProps = {
  fill: colors.white,
};
