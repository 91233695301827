import { useSelector } from 'react-redux';

import { SastrifyStore } from '../../shared/models';

export const useCheckUserPermission = (permission: string): boolean => {
  const user = useSelector((state: SastrifyStore) => state.authentication.user);
  const isPermitted = user?.permissions?.includes(permission);

  return !!isPermitted;
};
