import { Box, BoxProps as MUIBoxProps, styled } from '@mui/material';
import procurementHelpSvg from 'assets/images/request-support/procurement_help.svg';
import requestSupportSvg from 'assets/images/request-support/request_support.svg';
import sastrifyAppHelpSvg from 'assets/images/request-support/sastrify_app_help.svg';
import { withTransientProps } from 'asteroids';

import { getIsRequestDrawer } from './drawer-info-section.component';

type TransientProps = {
  $infoType: string;
};

export const InfoSectionStyledContainer = styled(
  Box,
  withTransientProps
)<MUIBoxProps & TransientProps>(({ $infoType, theme }) => {
  const isRequestDrawer = getIsRequestDrawer($infoType);

  return {
    '& .icon-wrapper': {
      marginTop: theme.spacing(3),
    },

    '& .procurement-help': {
      backgroundImage: `url(${procurementHelpSvg})`,
      backgroundSize: '168.85px 158.67px',
      height: '158.67px',
      width: '168.85px',
    },

    '& .request-support': {
      backgroundImage: `url(${requestSupportSvg})`,
      height: '234px',
      width: '310.78px',
    },

    '& .request-support-text': {
      '& div': {
        marginBottom: theme.spacing(4),
      },
    },

    '& .sastrify-app-help': {
      backgroundImage: `url(${sastrifyAppHelpSvg})`,
      backgroundSize: '59.13px 98.49px',
      height: '98.49px',
      width: '59.13px',
    },

    '.leftSectionContent': {
      '& .body-text': {
        color: theme.palette.text.dark,
        marginTop: theme.spacing(3),
      },

      '& .body-text-and-icon': {
        display: 'flex',
        flexDirection: isRequestDrawer ? 'column-reverse' : 'column',
      },

      '& .upload-document-title': {},

      marginTop: '2rem',
    },

    '.leftSectionFooter': {
      '& .help-center-link': {
        '&:hover': {
          textDecoration: 'underline !important',
        },

        color: theme.palette.primary.main,
        fontSize: '0.875rem',
        marginRight: '0.5rem',
        textDecoration: 'underline',
      },

      marginTop: theme.spacing(4),
    },

    padding: theme.spacing(8, 6),
  };
});
