import { Avatar as MuiAvatar } from '@mui/material';
import { SystemCssProperties, Theme } from '@mui/system';
import React from 'react';
import { getFirstAndLastInitialsFromText } from 'shared/helpers/common.helper';

import { AvatarProps } from './avatar.props';
import { StyledAvatarSubtitle, StyledAvatarTitle, StyledAvatarWrapper, StyledLabelsWrapper } from './avatar.styles';

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { children, 'data-testid': dataTestId, subtitle, title, ...avatarProps } = props;
  const size = props.size || 'normal';
  const setAvatarFromTitle = title && title.length > 1 && !props.src && !props.children;

  let sizedChildren = null;

  if (children) {
    if (Array.isArray(children)) {
      sizedChildren = children.map((child) =>
        child && typeof child === 'object' ? React.cloneElement(child as React.ReactElement, { size }) : child
      );
    } else if (typeof children === 'object') {
      sizedChildren = React.cloneElement(children as React.ReactElement, { size });
    } else {
      sizedChildren = children;
    }
  }

  return title ?? subtitle ? (
    <StyledAvatarWrapper data-testid={dataTestId ?? 'avatar-component'}>
      {setAvatarFromTitle ? (
        <MuiAvatar {...avatarProps}>{getFirstAndLastInitialsFromText(title)}</MuiAvatar>
      ) : (
        <MuiAvatar {...avatarProps}>{sizedChildren}</MuiAvatar>
      )}
      <StyledLabelsWrapper
        sx={{
          maxWidth: `calc(100% - ${(avatarProps.sx as SystemCssProperties<Theme>)?.width} - 8px)`, // 8px is the margin-left
        }}>
        {title && (
          <StyledAvatarTitle variant='subtitle2' title={title} component='h4' data-testid='avatar-title'>
            {title}
          </StyledAvatarTitle>
        )}
        {subtitle && (
          <StyledAvatarSubtitle title={subtitle} data-testid='avatar-subtitle'>
            {subtitle}
          </StyledAvatarSubtitle>
        )}
      </StyledLabelsWrapper>
    </StyledAvatarWrapper>
  ) : (
    <MuiAvatar {...avatarProps} data-testid={dataTestId ?? 'avatar-component'}>
      {sizedChildren}
    </MuiAvatar>
  );
};

export default Avatar;
