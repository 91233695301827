/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { format, isValid } from 'date-fns';
import i18next from 'i18next';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { ConversionRates } from 'shared/models/conversion-rates.model';
import * as SparkMD5 from 'spark-md5';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { Currency, DEFAULT_COUNTRY, LOGIN_METHOD_ERROR_MESSAGE, UserRoles } from '../common.definitions';
import { Company, User } from '../models';

const documentCategoryTranslation =
  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories';

export const decodeIdFromRoute = (route: string): string => {
  if (!route) {
    return '';
  }
  const routes = route.split('-');
  if (routes.length < 2) {
    return '';
  }
  const id = routes[routes.length - 1];
  return id;
};

export const getFirstAndLastInitialsFromText = (name?: string, ignoreUserLevel = false): string => {
  if (!name) {
    return '';
  }

  const removeUserLevel = (name: string): string => name.replace(/\s?\(.*?\)/, '');
  const formattedName = ignoreUserLevel ? removeUserLevel(name) : name;
  const words = formattedName.split(' ');

  if (words.length >= 2) {
    const { 0: wordA, [words.length - 1]: wordB } = words;

    return wordA.substring(0, 1).concat(wordB.substring(0, 1)).toLocaleUpperCase();
  }

  return formattedName.length > 0 ? formattedName.substring(0, 2).toLocaleUpperCase() : '';
};

export const getDocumentCategory = (category: number): string => {
  switch (category) {
    case 0:
      return i18next.t(`${documentCategoryTranslation}.miscellaneous`);
    case 1:
      return i18next.t(`${documentCategoryTranslation}.terms_and_conditions`);
    case 2:
      return i18next.t(`${documentCategoryTranslation}.invoice`);
    case 3:
      return i18next.t(`${documentCategoryTranslation}.contract`);
    case 4:
      return i18next.t(`${documentCategoryTranslation}.offer`);
    case 5:
      return i18next.t(`${documentCategoryTranslation}.data_processing_agreement`);
    case 6:
      return i18next.t(`${documentCategoryTranslation}.master_services_agreement`);
    default:
      return i18next.t(`${documentCategoryTranslation}.miscellaneous`);
  }
};

export const formatFullDate = (dateString?: string): string => {
  if (!dateString) {
    return '';
  }

  const date: Date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleDateString(DEFAULT_COUNTRY, { month: 'long' }).substring(0, 3);
  const year = date.getFullYear();

  return `${day} ${month} ${String(year).padStart(4, '0')}`;
};

export const shortenFileName = (filePath: string, maxText = 25): string => {
  const regex = /^([^\\]*)\.(\w+)$/;
  const matches = filePath.match(regex);
  const filename = matches?.[1];
  const extension = matches?.[2];

  if (filename && filename.length > maxText) {
    if (filename.length > maxText) {
      const newFilename = filename.slice(0, maxText);
      return `${newFilename}...${extension}`;
    }
    return `${filename}.${extension}`;
  }
  return shortenText(filePath, maxText);
};

export const shortenText = (text: string, maxText: number): string => {
  if (text.length > maxText) {
    return `${text.slice(0, maxText)}...`;
  }
  return text;
};

export const formatCurrency = ({
  country,
  currency,
  hideDecimal,
  isDecimal,
  style,
  value,
}: {
  country?: string;
  currency?: string;
  value?: number;
  isDecimal?: boolean;
  style?: string;
  hideDecimal?: boolean;
}): string | undefined => {
  if (currency) {
    return new Intl.NumberFormat(country || DEFAULT_COUNTRY, {
      currency: currency || DEFAULT_CURRENCY,
      maximumFractionDigits: hideDecimal ? 0 : 2,
      minimumFractionDigits: hideDecimal ? 0 : 2,
      style,
    }).format(isDecimal ? convertAmountFromCents(value) : value || 0);
  }
};

export const convertAmountFromCents = (amount: number | string | undefined): number => {
  return (Number(amount) || 0) / 100;
};

export const convertAmountToCents = (amount: number | undefined): number => {
  return Number(((amount || 0) * 100).toFixed(0));
};

export const formatDate = (dateString?: string): string => {
  if (!dateString) {
    return '';
  }

  const date: Date = new Date(dateString);

  return `${date.getDate()} ${date
    .toLocaleDateString(DEFAULT_COUNTRY, { month: 'long' })
    .substring(0, 3)} ${date.getFullYear()}`;
};

export const formatDateDDMMYYYY = (date: Date | string): string => {
  const d = typeof date === 'string' ? new Date(date) : date;
  const year = d.getFullYear();
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  return `${day}/${month}/${year}`;
};

export const formatDateDDMMMYYYY = (date: Date): string | null => {
  if (!isValid(date)) return null;
  return format(date, 'do LLL yyyy');
};

export const getFormattedDate = (date: Date, formatString = 'yyyy-MM-dd') => {
  return format(date, formatString);
};

export const computeChecksumMd5 = (file: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const chunkSize = 2097152;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    let cursor = 0;

    fileReader.onerror = (): void => {
      Error('MD5 computation failed - error reading the file');
    };

    const processChunk = (chunkStart: number): void => {
      const chunkEnd = Math.min(file.size, chunkStart + chunkSize);
      fileReader.readAsArrayBuffer(file.slice(chunkStart, chunkEnd));
    };
    fileReader.onload = (e: ProgressEvent<FileReader>): void => {
      spark.append(e.target?.result as ArrayBuffer);
      cursor += chunkSize;

      if (cursor < file.size) {
        processChunk(cursor);
      } else {
        resolve(btoa(spark.end(true)));
      }
    };

    processChunk(0);
  });
};

export const isDashboardRoute = (pathname: string) => pathname.split('/').includes(NAVIGATION_URLS.OVERVIEW.slice(1));
export const isCompanyToolsRoute = (pathname: string) =>
  pathname.split('/').includes(NAVIGATION_URLS.SUBSCRIPTIONS.slice(1));
export const isNewSolutionsRoute = (pathname: string) => pathname.split('/').includes('new-solutions');
export const isDiscoveredRoute = (pathname: string) => pathname.split('/').includes('discovered-tools');
export const isToolRequestsRoute = (pathname: string) => pathname.split('/').includes('tool-requests');
export const isWorkflowRequestsRoute = (pathname: string) => pathname.split('/').includes('requests');
export const isRequestWorkflowsRoute = (pathname: string) => pathname.split('/').includes('request-workflows');
export const isToolStoreRoute = (pathname: string) => pathname.split('/').includes('tool-store');
export const isSubscriptionDetailsRoute = (pathname: string) =>
  pathname.split('/').length > 2 && pathname.split('/').includes(NAVIGATION_URLS.SUBSCRIPTIONS.slice(1));
export const isToolDetailsRoute = (pathname: string) =>
  pathname.split('/').length > 2 && pathname.split('/').includes(NAVIGATION_URLS.TOOL_DETAILS.slice(1));
export const isSastrifySupportRoute = (pathname: string) => pathname.split('/').includes('sastrify-support');
export const isBenchmarkingRoute = (pathname: string) => pathname.split('/').includes('pricing-benchmarks');

export const formatCompanyTags = (company?: Company) =>
  company?.companyTags?.map((name, id) => ({
    id,
    name,
  }));

export const customStringSort = (a: string, b: string) => {
  if (a.toLowerCase() < b.toLowerCase()) return -1;
  if (a.toLowerCase() > b.toLowerCase()) return 1;
  return 0;
};

type GetConversionRate = {
  conversionRates: ConversionRates;
  fromCurrency: keyof Currency;
  toCurrency: keyof Currency;
};

export const getCurrencyConversionRate = ({ conversionRates, fromCurrency, toCurrency }: GetConversionRate) => {
  return conversionRates[fromCurrency].rates[toCurrency];
};

export const capitalizeString = (str: string | undefined) => {
  if (!str || typeof str !== 'string') return '';

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const removeUnderscore = (str: string | undefined) => {
  if (!str || typeof str !== 'string') return '';

  return str.replaceAll('_', ' ');
};

export const isUrlValid = (url: string): boolean => {
  const isUrlValid = url.match(/^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g);

  return !!isUrlValid;
};

export const formatCompanyDomains = (company?: Company) => {
  if (company?.allowedUrls && company.allowedUrls.length > 0) {
    return company.allowedUrls
      .slice(0, -1)
      .map((domain) => domain)
      .join(', ');
  }
  return null;
};

export const getLastCompanyDomain = (company?: Company) => {
  if (company?.allowedUrls && company.allowedUrls.length > 0) {
    return company.allowedUrls[company.allowedUrls.length - 1];
  }
};

export const formateUrlToReturnDomain = (url: string) => {
  let domain: URL | string = new URL(url);
  domain = domain.hostname.replace('www.', '');
  return domain;
};

export const isLoginMethodErrorMessage = (message: string) => {
  return message.toLowerCase() === LOGIN_METHOD_ERROR_MESSAGE.toLowerCase();
};

export const getUserRoleFromUser = (user: User | null | undefined): UserRoles => {
  if (!user) {
    return UserRoles.Viewer;
  }

  const role = Object.keys(UserRoles).find((key: string) => UserRoles[key as keyof typeof UserRoles] === user.role);

  return role ? (UserRoles[role as keyof typeof UserRoles] as UserRoles) : UserRoles.Viewer;
};

export const getByteSizeInMB = (byteSize: number): string => {
  return (Number(byteSize) / 1000000).toFixed(2);
};

export const downloadFile = (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};

export const getHash = (routerHash: string) => {
  const [, ...hashes] = routerHash.split('#');
  hashes.pop();
  return hashes.length ? hashes.map((hash: string) => `#${hash}`).join('') : '';
};

export const removeUndefinedProps = <T extends object>(newParams: T, currentParams: T): T => {
  const updatedParams: T = { ...currentParams };

  Object.keys(newParams).forEach((key) => {
    const newValue = newParams[key as keyof T];
    const hasProperty = Object.prototype.hasOwnProperty.call(newParams, key);
    if (hasProperty && newValue !== undefined) {
      updatedParams[key as keyof T] = newValue as T[keyof T];
    } else if (hasProperty && newValue === undefined) {
      delete updatedParams[key as keyof T];
    }
  });

  return updatedParams;
};
