import { Request } from './request.model';
import { Subscription } from './subscription.model';

export enum TicketStatuses {
  OPEN = 2,
  PENDING = 3,
  RESOLVED = 4,
  CLOSED = 5,
  ON_HOLD = 8,
  IN_PROGRESS = 9,
}

export enum TicketStages {
  CAPTURE_YOUR_BUSINESS_NEEDS = 0,
  WAITING_VENDOR_MEETING_ACCEPTANCE = 1,
  NEGOTIATION_IN_PROGRESS = 2,
  BENCHMARKING_IN_PROGRESS = 3,
  PROPOSAL_REVIEWS_BY_YOU = 4,
  YOUR_LEGAL_AND_SECURITY_REVIEW = 5,
  AWAITING_YOUR_SIGNATURE = 6,
  PROPOSAL_REJECTED = 7,
  PROPOSAL_ACCEPTED = 8,
  EXPLORING_ALTERNATIVES = 9,
  ALTERNATIVES_IDENTIFIED = 10,
  BENCHMARKING_COMPLETED = 11,
}

export type BasicTicketType = {
  id?: string;
  title: string;
  status: TicketStatuses;
  stage: TicketStages;
};

export type TicketType = BasicTicketType & {
  subscription?: Subscription | null;
  commentId?: string | null;
  requestId?: number;
  parentRequestId?: number;
  requestTicketId?: string;
  vendorAvatarUrl?: string;
  vendorName?: string;
};

export type RequestTicketType = BasicTicketType & { request: Request | null; requestTicketId: string | null };
