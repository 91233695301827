import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import errorState from 'assets/images/tool-usage-analytics/sso-tools-list-error.svg';
import { Icon } from 'asteroids';
import {
  FooterSection,
  HeaderSection,
  ToolsListSection,
  Wrapper,
} from 'components/drawer-usage-analytics/drawer-usage-analytics.styles';
import { UsageAnalyticsSsoToolsListSkeleton } from 'components/skeletons/usage-analytics-sso-tools-list.skeleton';
import { TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  disconnectSsoWorkatoIntegration,
  getSsoToolsList,
  updateWorkatoSsoProvider,
} from 'shared/logic/tool-usage-analytics.logic';
import { useAppSelector } from 'shared/store/hooks';

import { UsageAnalyticsFallbackContent } from '../components/usage-analytics-fallback-content.component';
import { SsoToolsList } from './sso-tools-list.component';

type IProps = {
  handleCloseDrawer: () => void;
};

export const DrawerUsageAnalyticsManageSso: FC<IProps> = ({ handleCloseDrawer }) => {
  const [disconnectState, setDisconnectState] = useState(false);

  const queryClient = useQueryClient();

  const selectedSsoProvider = useAppSelector((state) => state.views.connect.usageAnalyticsItem.selectedSsoProvider);

  const {
    data: toolsList,
    isError: toolsListError,
    isLoading: isLoadingToolsList,
  } = useQuery(
    ['sso-tools-list'],
    () =>
      getSsoToolsList({
        companyId: selectedSsoProvider?.companyId || '',
        ssoName: selectedSsoProvider?.ssoProviderName || '',
      }),
    {
      enabled: Boolean(selectedSsoProvider?.companyId && selectedSsoProvider?.ssoProviderName),
    }
  );

  const { mutate: disconnect } = useMutation('disconnect-workato', disconnectSsoWorkatoIntegration, {
    onSuccess: () => {
      updateWorkatoSsoProvider(
        queryClient,
        selectedSsoProvider?.ssoProviderName || '',
        TOOL_USAGE_INTEGRATION_STATE.AVAILABLE
      );
    },
  });

  const { t } = useTranslation();
  const theme = useTheme();

  const onDisconnect = () => {
    if (selectedSsoProvider?.companyId) {
      disconnect({ companyId: selectedSsoProvider?.companyId, ssoName: selectedSsoProvider?.ssoProviderName });
      handleCloseDrawer();
    }
  };

  const handleDisconnectClick = () => {
    setDisconnectState(true);
  };

  return (
    <Wrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
        <HeaderSection>
          {disconnectState ? (
            <Typography variant='h2' color={theme.palette.text.primary} sx={{ mb: 3 }}>
              {t('connect_view:tab_section.tabs.usage_analytics.manage_sso.confirm_disconnect.title')}
            </Typography>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }}>
              <Icon sx={{ color: theme.palette.primary.main }}>check_circle</Icon>
              <Typography variant='h2' color={theme.palette.primary.main} sx={{ pl: 1 }}>
                {t('connect_view:tab_section.tabs.usage_analytics.manage_sso.title', {
                  ssoProviderName: selectedSsoProvider?.ssoProviderName,
                })}
              </Typography>
            </Box>
          )}
          <Typography variant='body1' color='text.primary' sx={{ mb: 3 }}>
            {disconnectState
              ? t('connect_view:tab_section.tabs.usage_analytics.manage_sso.confirm_disconnect.description', {
                  ssoProviderName: selectedSsoProvider?.ssoProviderName,
                })
              : t('connect_view:tab_section.tabs.usage_analytics.manage_sso.description', {
                  ssoProviderName: selectedSsoProvider?.ssoProviderName,
                })}
          </Typography>
          <Typography variant='section' color='text.primary' sx={{ mb: 2 }}>
            {t('connect_view:tab_section.tabs.usage_analytics.manage_sso.tools_list_title', {
              ssoProviderName: selectedSsoProvider?.ssoProviderName,
            })}
          </Typography>
        </HeaderSection>
        <Divider orientation='horizontal' textAlign='center' />
        <ToolsListSection>
          {toolsListError && (
            <UsageAnalyticsFallbackContent
              icon={errorState}
              description={t('connect_view:tab_section.tabs.usage_analytics.manage_sso.error_state.description', {
                ssoProviderName: selectedSsoProvider?.ssoProviderName,
              })}
            />
          )}
          {isLoadingToolsList && <UsageAnalyticsSsoToolsListSkeleton />}
          {!toolsListError && !isLoadingToolsList && <SsoToolsList tools={toolsList || []} />}
        </ToolsListSection>
        <Divider orientation='horizontal' textAlign='center' />
        <FooterSection>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'end',
              pb: 3,
              pt: 3,
              width: '100%',
            }}>
            <Button onClick={handleCloseDrawer} variant='text' color='secondary' data-testid='modal-cancel-button'>
              {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.close_button_text')}
            </Button>
            <Button
              onClick={disconnectState ? onDisconnect : handleDisconnectClick}
              variant='contained'
              color='error'
              data-testid='modal-confirm-button'
              disableRipple
              disableElevation>
              {disconnectState
                ? t('connect_view:tab_section.tabs.usage_analytics.manage_sso.confirm_disconnect.button')
                : t('connect_view:tab_section.disconnect')}
            </Button>
          </Box>
        </FooterSection>
      </Box>
    </Wrapper>
  );
};
