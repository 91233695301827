import { AxiosResponse } from 'axios';

import { apiService, apiUrl } from '../services';

export const updateConnectionName = (
  companyId: string,
  codatCompanyId: string,
  sastrifyConnectionName: string
): Promise<void> => {
  return apiService
    .patch(`${apiUrl.updateCodatConnectionName(companyId, codatCompanyId)}`, { sastrifyConnectionName })
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

export const disconnectCodatConnectionV2 = async (companyId: string, codatCompanyId: string) => {
  const response = await apiService.delete(apiUrl.disconnectCodatPlatformV2(companyId, codatCompanyId));
  return response.data;
};
