import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MICROSOFT_INTEGRATION_SCOPE } from 'libs/constants';
import { SSOIntegrationStatus } from 'libs/enums';
import {
  getMicrosoftConnectionStatus,
  toggleMicrosoftUsageAnalytics,
  updateMicrosoftIntegration,
} from 'shared/logic/company.logic';
import { MicrosoftConnect, User } from 'shared/models';
import { apiService, apiUrl } from 'shared/services';
import { v4 as uuidv4 } from 'uuid';

type DisconnectPayload = {
  refetch: () => void;
  userData: User;
};

type ToggleUsageAnalyticsPayload = {
  refetch: () => void;
  companyId: string;
  enableUsageAnalytics: boolean;
};

type MicrosoftIntegrationDetails = {
  status: string;
  companyId: string;
  createdAt: string;
  refreshToken: string | null;
  updatedAt: string;
};

export const getConnectionStatus = async (companyId: number) => {
  return getMicrosoftConnectionStatus(companyId);
};

export const disconnectFromMicrosoft = createAsyncThunk(
  'microsoftConnect/disconnect',
  async (payload: DisconnectPayload, thunkAPI) => {
    const { refetch, userData } = payload;

    const companyId = userData.companyId as string;
    const data = await updateMicrosoftIntegration(companyId, SSOIntegrationStatus.DISCONNECTED);
    const connectionData = data as MicrosoftIntegrationDetails;

    if (connectionData.status === SSOIntegrationStatus.DISCONNECTED) {
      thunkAPI.dispatch(microsoftConnectSlice.actions.setConnectionState(false));

      refetch();
    }
  }
);

const getAuthorizeUrl = (stateToken: string): string => {
  const microsoftClientId = process.env.MICROSOFT_CLIENT_ID;
  const microsoftTenantId = process.env.MICROSOFT_TENANT_ID;
  const microsoftRedirectUI = process.env.MICROSOFT_REDIRECT_URI;
  const redirectUri = encodeURIComponent(microsoftRedirectUI || '');
  const scope = encodeURIComponent(MICROSOFT_INTEGRATION_SCOPE);
  const responseType = 'code';

  return `https://login.microsoftonline.com/${microsoftTenantId}/oauth2/v2.0/authorize?client_id=${microsoftClientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=query&scope=${scope}&state=${stateToken}&sso_reload=true&prompt=select_account`;
};

export const onConnect = async (companyId: string, enableUsageAnalytics: boolean): Promise<string> => {
  const stateToken = uuidv4();

  await apiService.post(apiUrl.initConnectMicrosoftIntegration(companyId), { enableUsageAnalytics, stateToken });

  return getAuthorizeUrl(stateToken);
};

export const toggleUsageAnalytics = createAsyncThunk(
  'microsoftConnect/toggleUsageAnalytics',
  async ({ companyId, enableUsageAnalytics, refetch }: ToggleUsageAnalyticsPayload, thunkAPI) => {
    thunkAPI.dispatch(microsoftConnectSlice.actions.setEnableUsageAnalytics(enableUsageAnalytics));
    await toggleMicrosoftUsageAnalytics(companyId, enableUsageAnalytics);
    refetch();
  }
);

const initialState: MicrosoftConnect = {
  connected: false,
  connecting: false,
  error: '',
  isError: false,
  usageAnalyticsEnabled: false,
};

const microsoftConnectSlice = createSlice({
  initialState,
  name: 'microsoftConnect',
  reducers: {
    disconnect(state) {
      if (!state.connected) {
        return;
      }

      state.error = null;
      state.isError = false;
      state.connecting = false;
      state.connected = false;
    },
    setConnectingState(state) {
      state.connecting = true;
    },
    setConnectionState(state, action: PayloadAction<boolean>) {
      if (state.connected !== action.payload) {
        state.connected = action.payload;
        state.connecting = false;
      }
    },
    setEnableUsageAnalytics(state, action: PayloadAction<boolean>) {
      state.usageAnalyticsEnabled = action.payload;
    },
    setError(state, action: PayloadAction<string | unknown>) {
      state.isError = true;
      state.error = action.payload;
      state.connecting = false;
    },
  },
});

export const { disconnect, setConnectingState, setConnectionState, setError } = microsoftConnectSlice.actions;
export default microsoftConnectSlice.reducer;
