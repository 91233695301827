import './search-bar.component.scss';

import SearchIcon from '@mui/icons-material/Search';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { PaginatedResponse } from 'libs/models';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { KeyboardKeys, TOOLS_TYPE_FILTER } from 'shared/common.definitions';
import { ToolType } from 'shared/models';
import { colors } from 'shared/theme';

import { useDebounce } from '../../hooks';
import { CancelIcon } from '../icons';
import { SearchBarProps } from './search-bar.component.props';

const PAGE_SIZE = 5;

const CustomInput = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '0px',
    },
  },
  '& .MuiTextField-root': {
    width: '100%',
  },
  '& label.Mui-focused': {
    backgroundColor: colors.white,
    color: colors.newPrimary,
    padding: '0 8px',
  },
}));

// TODO: Move button to component folder
const MuiButton = styled(Button)<ButtonProps>(() => ({
  '&:hover': {
    backgroundColor: colors.newPrimary,
  },
  backgroundColor: colors.newPrimary,
  color: '#fff',
  fontFamily: 'Inter',
}));

export const SearchBar: FC<SearchBarProps> = ({
  fetchFn,
  initialSearchTerm,
  label,
  onSearch,
  placeholder,
  renderSearchResult,
}) => {
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [debounceSearchValue, setDebounceSearchValue] = useState('');
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [searchResult, setSearchResult] = useState<PaginatedResponse<ToolType>>();
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [haveResultsFocus, setHaveResultsFocus] = useState(false);

  useDebounce(setDebounceSearchValue, searchTerm || '', 500);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onSearchResultClick = (value: string) => {
    onSearch(value);
    setFocused(false);
    setSearchResult(undefined);
    setSearchTerm(value);
  };

  const [currentPage] = useState(1);

  const { data, isFetching, refetch } = useQuery({
    enabled: !!debounceSearchValue && Number(searchTerm?.length) > 2,
    keepPreviousData: true,
    queryFn: () =>
      fetchFn(currentPage, {
        searchTerm: debounceSearchValue,
        size: PAGE_SIZE,
        toolTypeFilter: TOOLS_TYPE_FILTER.ALL_TOOLS,
      }),
    queryKey: [
      'top-vendors-search',
      currentPage,
      { searchTerm: debounceSearchValue, size: PAGE_SIZE, toolTypeFilter: TOOLS_TYPE_FILTER.ALL_TOOLS },
    ],
  });

  useEffect(() => {
    setSearchResult(data);
  }, [data]);

  useEffect(() => {
    if (debounceSearchValue.length > 2) {
      refetch();
    } else {
      setSearchResult(undefined);
    }
  }, [debounceSearchValue, refetch]);
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement | HTMLDivElement>) => {
    const extendedEvent = event as React.KeyboardEvent<HTMLInputElement> & { target: { value: string } };

    if (extendedEvent.key === KeyboardKeys.Enter && extendedEvent.target.value.length >= 3) {
      onSearchResultClick(searchTerm || '');
      setFocused(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const index = Number(activeIndex);
    const dataLength = Number(data?.items?.length);

    setHaveResultsFocus(searchTerm.length >= 3);

    switch (event.key) {
      case KeyboardKeys.ArrowUp:
        setActiveIndex(index >= 1 ? index - 1 : 0);
        break;
      case KeyboardKeys.ArrowDown:
        setActiveIndex(index < dataLength - 1 ? index + 1 : dataLength - 1);
        break;
      default:
        break;
    }
  };

  const renderCancelButtonAndSpinner = () => {
    if (!searchTerm) {
      return null;
    }

    return (
      <>
        {isFetching && <CircularProgress color='inherit' size='18px' sx={{ margin: '0 0.5rem' }} />}
        <IconButton
          data-testid='search-input-cancel-btn'
          className='search-input-container__cancel-btn'
          onClick={() => {
            setSearchTerm('');
            onSearch('');
          }}>
          <CancelIcon width='24' height='24' />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <FormControl
        onBlur={() => setHaveResultsFocus(false)}
        sx={{
          alignItems: 'center',
          background: 'white',
          border: focused ? `1px solid ${colors.newPrimary}` : '0px',
          borderRadius: '4px',
          boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'row',
          px: '4px',
        }}>
        <IconButton sx={{ p: '10px' }} aria-label='menu'>
          <SearchIcon sx={{ color: focused ? colors.newPrimary : 'inherit' }} />
        </IconButton>
        <CustomInput
          size='small'
          sx={{ flex: '1' }}
          label={label}
          data-testid='search-input-container'
          onChange={handleOnChange}
          placeholder={placeholder}
          onKeyDown={handleOnKeyDown}
          value={searchTerm}
          onFocus={() => {
            setHaveResultsFocus(searchTerm.length >= 3);
            setFocused(true);
          }}
          onBlur={() => setFocused(false)}
          onKeyUp={handleKeyPress}
          InputProps={{
            endAdornment: renderCancelButtonAndSpinner(),
            inputProps: {
              'data-testid': 'search-input',
            },
          }}
        />
        <MuiButton
          role='search'
          startIcon={<SearchIcon />}
          variant='contained'
          data-testid='search-button'
          disableElevation
          onClick={() => onSearchResultClick(searchTerm || '')}>
          {t('common:search_bar_component:btn_text')}
        </MuiButton>
      </FormControl>
      {Number(searchResult?.items?.length) > 0 &&
        !!debounceSearchValue &&
        haveResultsFocus &&
        renderSearchResult(searchResult as PaginatedResponse<ToolType>, activeIndex, onSearchResultClick)}
    </>
  );
};
