import { useQuery } from 'react-query';

import { fetchVendorKnowledgeItemsStoryblok } from '../../shared/logic/subscription-item.logic';
import { VendorKnowledgeStoryblokItemModel } from '../../shared/models/vendor-knowledge-item.model';

interface FetchInsightsArguments {
  companyId?: string | null;
  vendorId?: string | null;
}

export const useFetchVendorInsights = ({ companyId, vendorId }: FetchInsightsArguments) => {
  return useQuery<VendorKnowledgeStoryblokItemModel[]>(
    ['vendor-knowledge-items', vendorId],
    () => fetchVendorKnowledgeItemsStoryblok(String(vendorId), String(companyId)),
    {
      enabled: !!companyId && !!vendorId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};
