import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { FC } from 'react';

const UsageAnalyticsSsoToolsListItem: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      <Skeleton variant='rounded' width={40} height={40} />
      <Skeleton variant='text' width={160} sx={{ fontSize: '1rem', margin: 0 }} />
    </Box>
  );
};

export const UsageAnalyticsSsoToolsListSkeleton: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {[0, 1, 2, 3, 4, 5, 6].map((item) => (
        <UsageAnalyticsSsoToolsListItem key={item} />
      ))}
    </Box>
  );
};
