import { AxiosResponse } from 'axios';
import { CLOUD_OPTIMIZATION_TOOL, SSOIntegrationStatus } from 'libs/enums';
import { CodatIntegrationDetailsRes } from 'libs/models/codat-service.model';
import { googleTagManagerEvents, YesNo } from 'shared/common.definitions';
import {
  CodatConnectionPayloadV2,
  CodatLinkUrlResponse,
  SSOCompanyConnectionState,
} from 'shared/models/integration-item.model';
import { type AuthenticationSettingsDTOType } from 'views/users-and-authentication/dialogs/authentication-settings/authentication-settings.dialog';

import { SubscriptionAccess } from '../helpers/user-drawer.helper';
import { Company, PotentialSavings, User } from '../models';
import { apiService, apiUrl } from '../services';

export const fetchCompany = (companyId: number): Promise<Company | unknown> => {
  return apiService.get(`${apiUrl.Companies}/${companyId}`).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const updateCompany = (data: Company): Promise<Company | unknown> => {
  return apiService.patch(`${apiUrl.Companies}/${data?.id}`, data).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const fetchCompanyToolOwners = async (companyId: string): Promise<User[]> => {
  const { data } = await apiService.get(apiUrl.companyToolOwners(companyId));
  data.forEach((user: User) => {
    user.lastSignInAtFormatted = user.lastSignInAt ? new Date(user.lastSignInAt)?.toDateString?.() : '';
  });
  return data;
};

export const fetchCompanySubscriptionAccess = async (companyId: string): Promise<SubscriptionAccess[]> => {
  const { data } = await apiService.get(apiUrl.companySubscriptionAcess(companyId));
  return data;
};

export const getCompanyPotentialSavings = async (companyId: string): Promise<PotentialSavings[]> => {
  const response = await apiService.get(`${apiUrl.getCompanyPotentialSavings(companyId)}`);
  return response.data;
};

export const getCompanyGoogleConnectionState = async (
  companyId: string | number
): Promise<SSOCompanyConnectionState> => {
  const response = await apiService.get(`${apiUrl.getGoogleConnectionState(companyId)}`);
  return response.data;
};

export const getMicrosoftConnectionStatus = async (companyId: string | number): Promise<SSOCompanyConnectionState> => {
  const response = await apiService.get(`${apiUrl.getMicrosoftConnectionStatus(companyId)}`);
  return response.data;
};

export const updateMicrosoftIntegration = async (companyId: string, status: SSOIntegrationStatus): Promise<unknown> => {
  const response = await apiService.put(`${apiUrl.updateMicrosoftIntegration(companyId)}`, {
    status,
  });
  return response.data;
};

export const toggleMicrosoftUsageAnalytics = async (
  companyId: string,
  enableUsageAnalytics: boolean
): Promise<unknown> => {
  const response = await apiService.put(`${apiUrl.toggleMicrosoftUsageAnalytics(companyId)}`, {
    enableUsageAnalytics,
  });

  return response.data;
};

export const updateGoogleIntegration = async (companyId: string, status: SSOIntegrationStatus): Promise<unknown> => {
  const response = await apiService.put(`${apiUrl.updateGoogleIntegration(companyId)}`, {
    status,
  });
  return response.data;
};

export const toggleGoogleUsageAnalytics = async (
  companyId: string,
  enableUsageAnalytics: boolean
): Promise<unknown> => {
  const response = await apiService.put(`${apiUrl.toggleGoogleUsageAnalytics(companyId)}`, {
    enableUsageAnalytics,
  });

  return response.data;
};

export const connectCodatPlatformV2 = async (
  companyId: string,
  payload: CodatConnectionPayloadV2
): Promise<CodatLinkUrlResponse> => {
  const response = await apiService.post(`${apiUrl.connectCodatPlatformV2(companyId)}`, payload);
  return response.data;
};

export const getCompanyCodatIntegrationDetails = async (companyId: string): Promise<CodatIntegrationDetailsRes[]> => {
  const response = await apiService.get(`${apiUrl.getCodatIntegrationDetailsByCompanyIdV2(companyId)}`);
  return response.data;
};

const isInternalEmail = (email: string) => {
  const domain = email.split('@')[1];
  return ['sastrify.com', 'sastrix.com', 'sastrix.io'].includes(domain);
};

export const fireGTagManagerEvent = (
  window: Window,
  email: string,
  event: googleTagManagerEvents,
  data?: { name: string; value: string }
) => {
  const isSastrifyEmail = isInternalEmail(email);

  const dataLayer = {
    event,
    isSastrifyEmail,
    ...(data ? { data: { [data.name]: data.value } } : {}),
  };

  window.dataLayer.push(dataLayer);
};

type CreateAppRequestPayload = {
  body: string;
  companyId: string;
  templateId?: string;
};

export const createAppHelpRequest = async (payload: CreateAppRequestPayload) => {
  return apiService.post(`${apiUrl.requestAppHelp(payload.companyId)}`, payload);
};

export type CreateSastricloudOptimizeRequestPayload = {
  companyId: string;
  payload: {
    cloudToolName: CLOUD_OPTIMIZATION_TOOL;
    documents: {
      category: number;
      ids: string[];
    };
    form: {
      hasCommitment3PlusMonths: YesNo;
      hasCommitmentAndWouldStay: YesNo;
      purchasingFromVendorDirectly: YesNo;
    };
  };
};

export const createSastricloudOptimizeRequest = async ({
  companyId,
  payload,
}: CreateSastricloudOptimizeRequestPayload) => {
  return companyId && apiService.post(`${apiUrl.requestSastricloudOptimize(companyId)}`, payload);
};

export const getCommentForOptimizeRequest = ({
  companyName,
  documents,
  vendorName,
}: {
  companyName: string;
  vendorName: string;
  documents: { fileName?: string; id?: number | string }[];
}) => {
  const documentLinks = documents
    .map((document) => document && `[${document?.fileName}](${apiUrl.CompanyDocumentId(document?.id || '') || ''})`)
    .filter((a) => !!a)
    .join('<br />');

  return `Optimize request from ${companyName} for ${vendorName}<br/><br/>_A file was attached to this request please review it on: <br />${documentLinks}_`;
};

// ////////////////////////////////////////////////////////////////////////////
// Authentication settings
export const updateCompanyAuthenticationSettings = async (
  companyId: string,
  settings: AuthenticationSettingsDTOType
): Promise<Company> => {
  const { data } = await apiService.patch(apiUrl.companyAuthenticationSettings(companyId), settings);
  return data as Company;
};
