export enum REQUEST_STATES {
  ACTIVE = 1,
  APPROVED = 4,
  REJECTED = 5,
  SUBSCRIPTION_CREATED = 6,
}

export enum REQUEST_WORKFLOW_STATES {
  ACTIVE = 1,
  INACTIVE = 2,
  DRAFT = 3,
}

export enum REQUEST_SUPPORT_TYPES {
  NEGOTIATION_SUPPORT = 'Negotiation support',
  REQUEST_BENCHMARK = 'Request benchmark',
  RENEWAL_AND_NEGOTIATION_SUPPORT = 'Renewal and Negotiation Support',
  FIND_ALTERNATIVE = 'Find Alternative',
  OTHER = 'Other',
}

export enum REQUEST_WORKFLOW_TYPE {
  APPROVAL = 1,
  RENEWAL = 2,
}

export enum REQUEST_WORKFLOW_TYPE_NAME {
  APPROVAL = 'Purchase',
  RENEWAL = 'Renewal',
}
