import { FC } from 'react';
import { colors } from 'shared/theme';

export const CalenderWithBackgroundIcon: FC<React.SVGProps<SVGElement>> = ({ fill }) => {
  return (
    <svg width={40} height={96} viewBox='0 0 40 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 8a8 8 0 018-8h32v96H8a8 8 0 01-8-8V8z' fill={colors.edgeWater} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 41a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1V42a1 1 0 00-1-1H13zm-3 1a3 3 0 013-3h14a3 3 0 013 3v14a3 3 0 01-3 3H13a3 3 0 01-3-3V42z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 37a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM16 37a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM10 46a1 1 0 011-1h18a1 1 0 110 2H11a1 1 0 01-1-1z'
        fill={fill}
      />
    </svg>
  );
};

CalenderWithBackgroundIcon.defaultProps = {
  fill: colors.emerald,
};
