import { Paper, type PaperProps as MuiPaperProps, styled } from '@mui/material';

export const StyledPaperContainer = styled(Paper)<MuiPaperProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.light,
  '& .dialog-wrapper': {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto',
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  '& .dialog-title': {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,

    '& .dialog-default-title': {
      display: 'flex',
      alignContent: 'center',
    },
  },
  '& .dialog-content-scroll-wrapper': {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',

    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,

    '&.attach-content': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  '& .dialog-actions-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} 0`,

    '& .dialog-actions': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },

  '& .side-to-side': {
    position: 'relative',
    left: theme.spacing(-3),
    width: `calc(100% + ${theme.spacing(6)})`,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },

  '&.show-divider .dialog-wrapper': {
    '> .dialog-title': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '> .dialog-actions-wrapper': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
}));
