import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { UserRoles } from 'shared/common.definitions';
import { checkIsArchiveSubscriptionView } from 'shared/logic/subscription-item.logic';
import { SastrifyStore, User } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { decodeNameFromRoute } from 'src/constants/appurl';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { BreadcrumbsComponentProps } from './breadcrumbs.component.props';
import { getPagesPathWithoutBreadcrumbs } from './helpers';

const APP_NAME = 'Sastrify';

type RouteToTranslationMapping = {
  alerts: string;
  integrations: string;
  settings: string;
  subscriptions: string;
  users: string;
  potentialSavings: string;
};

const composeDocumentTitle = (routes: string[]): string => {
  const reversedRoutes = [...routes].reverse();
  const formatedRoutes = reversedRoutes.map((route) => {
    const decodedRoute = decodeNameFromRoute(route);

    return `${decodedRoute.charAt(0).toUpperCase()}${decodedRoute.slice(1)}`;
  });

  return `${formatedRoutes.join(' - ')} - ${APP_NAME}`;
};

export const BreadcrumbsComponent: React.FC<BreadcrumbsComponentProps> = () => {
  const location = useLocation();
  const user = useAppSelector((state: SastrifyStore) => state.authentication.user) as User;
  const pagesPathWithoutBreadcrumbs = getPagesPathWithoutBreadcrumbs();

  const currentPath = location.pathname || '';
  let routes = currentPath.split('/').filter((route: string) => route);

  routes = routes.map((route: string) => {
    if (route === 'requests') {
      return 'initiatives';
    }
    if (route === 'request-workflows') {
      return 'workflows';
    }
    return route;
  });

  const { t } = useTranslation();

  const ROUTE_TO_TRANSLATION_MAPPING: RouteToTranslationMapping = {
    alerts: t('breadcrumbs:alerts_link_text'),
    integrations: t('breadcrumbs:integrations_link_text'),
    potentialSavings: t('breadcrumbs:potential_savings_link_text'),
    settings: t('breadcrumbs:settings_link_text'),
    subscriptions: t('breadcrumbs:subscriptions_link_text'),
    users: t('breadcrumbs:users_link_text'),
  };

  const WORKFLOWS_ROUTE = '/workflows';

  const translatedRoutes = routes.map((route: string) => {
    if (ROUTE_TO_TRANSLATION_MAPPING[route as keyof RouteToTranslationMapping]) {
      return ROUTE_TO_TRANSLATION_MAPPING[route as keyof RouteToTranslationMapping];
    }
    return route;
  });

  const isDashboardRoute = translatedRoutes[0] === NAVIGATION_URLS.OVERVIEW.slice(1);

  document.title = composeDocumentTitle(translatedRoutes);

  const getBreadcrumbText = (route: string): string => {
    const url = `${location.pathname}${location.search}`;
    let text = !checkIsArchiveSubscriptionView(url) ? decodeNameFromRoute(route) : t('breadcrumbs:archive_link_text');

    if (url === WORKFLOWS_ROUTE) {
      text = t('breadcrumbs:task_automation_link_text');
    }

    return text;
  };

  let pagesWithoutBreadcrumbs = pagesPathWithoutBreadcrumbs.some((path: string) => currentPath.includes(path));
  const isConnectViewEnabled = currentPath.includes('/connect');
  const isViewerRole = user.role !== UserRoles.Admin && user.role !== UserRoles.Contributor;

  if (currentPath === NAVIGATION_URLS.SUBSCRIPTIONS) pagesWithoutBreadcrumbs = true;

  if (pagesWithoutBreadcrumbs || isConnectViewEnabled || isViewerRole) {
    return null;
  }

  return !isDashboardRoute ? (
    <section className='sastrify-breadcrumbs is-title-bar' data-testid='breadcrumbs'>
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <ul>
              <li className='breadcrumb-item'>
                <Link to={NAVIGATION_URLS.OVERVIEW}>
                  <span>{t('breadcrumbs:home_link_text')}</span>
                </Link>
              </li>

              {translatedRoutes.map((route: string, index: number) => {
                return (
                  <li
                    key={`breadcrumb_${route}`}
                    className={classnames('breadcrumb-item', {
                      'breadcrumb-current': translatedRoutes.length - 1 === index,
                    })}>
                    {routes.length - 1 === index ? (
                      getBreadcrumbText(route)
                    ) : (
                      <Link to={`/${route.toLowerCase()}`} className='breadcrumb-path'>
                        {decodeNameFromRoute(route)}
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};
