import { AxiosResponse } from 'axios';
import i18n from 'i18next';
import { WorkflowActionCategory, WorkflowsCategory, WorkflowsCategoryHashes } from 'shared/common.definitions';

import { Company, CreateNewWorkflow, EditWorkflow, EditWorkflowAction, Workflow, WorkflowAction } from '../models';
import { NewWorkflow } from '../models/workflow.model';
import { WorkflowTemplate } from '../models/workflow-templates.model';
import { queryClient } from '../reactQuery';
import { apiService, apiUrl } from '../services';

const { t } = i18n;

export const fetchWorkflows = (id: string): Promise<Workflow<WorkflowAction>[]> => {
  let modifiedData: Workflow<WorkflowAction>[];
  return apiService.get(apiUrl.Workflows(id)).then((response: AxiosResponse<Workflow<WorkflowAction[]>[]>) => {
    const { data } = response;

    modifiedData = data.map((item) => {
      return {
        ...item,
        actions: {
          ...item.actions[0],
        },
      };
    });

    return modifiedData;
  });
};

export const formattedWorkflowDueDate = (dueDate?: string) => {
  if (!dueDate) {
    return '';
  }

  const [days, interval] = parseDueDate(dueDate);

  return `${t(`common:periods.${interval}`, { count: days })}`;
};

export function parseDueDate(dueDate: string): [number, string] {
  const parse = new RegExp('^([1-9][0-9]{0,2})([dwmy])$').exec(dueDate);

  if (parse && parse[1] && parse[2]) {
    return [Number(parse[1]), parse[2]];
  }

  return [0, ''];
}

export const formValuesToCreateNewWorkflow = (newWorkflow: NewWorkflow): CreateNewWorkflow => {
  const company = queryClient.getQueryData('company') as Company;

  return {
    actions: [
      {
        actionType: WorkflowActionCategory.create_custom_task,
        payload: {
          assignToDefaultToolOwner: newWorkflow.assignToDefaultToolOwner,
          assignee: newWorkflow.assignee,
          description: newWorkflow.description,
          dueDate:
            newWorkflow.dueDate ||
            (newWorkflow.dueDateAmount || '').trim() + (newWorkflow.dueDatePeriod || '').trim() ||
            undefined,
          title: newWorkflow.name,
        },
      },
    ],
    companyId: String(company?.id) || undefined,
    name: undefined,
    published: true,
    triggerType: newWorkflow.triggerCriteria
      ? WorkflowsCategoryHashes[newWorkflow.triggerCriteria]
      : WorkflowsCategory.null,
  };
};

export const formValuesToWorkflowAction = (workflowAction: EditWorkflow): EditWorkflowAction => {
  return {
    actionType: WorkflowActionCategory.create_custom_task,
    id: workflowAction.id,
    payload: {
      assignToDefaultToolOwner: workflowAction.assignToDefaultToolOwner,
      assignee: !workflowAction.assignToDefaultToolOwner ? workflowAction.assignee : undefined,
      description: workflowAction.description,
      dueDate: `${workflowAction.dueDateAmount}${workflowAction.dueDatePeriod}`,
      title: workflowAction.title,
      triggerType: workflowAction.triggerCriteria
        ? Number(workflowAction.triggerCriteria as WorkflowsCategory)
        : Number(WorkflowsCategory.null),
    },
    workflowId: workflowAction.workflowId,
  };
};

export const createWorkflow = async (newWorkflow: CreateNewWorkflow): Promise<AxiosResponse<CreateNewWorkflow>> => {
  const response = await apiService.post(apiUrl.newWorkflow(), newWorkflow);
  return response;
};

export const deleteWorkflowAction = async (workflowId: string): Promise<AxiosResponse> => {
  const response = await apiService.delete(apiUrl.deleteWorkflow(workflowId));
  return response;
};

export const editWorkflowAction = async (workflowAction: EditWorkflowAction): Promise<AxiosResponse> => {
  const response = apiService
    .patch(apiUrl.updateWorkflowAction(workflowAction.workflowId, workflowAction.id), workflowAction)
    .then((response: AxiosResponse) => {
      return response.data;
    });
  return response;
};

export function fetchWorkflowTemplates(): Promise<WorkflowTemplate[]> {
  return apiService.get(apiUrl.workflowTemplates()).then((response: AxiosResponse) => {
    return response.data as WorkflowTemplate[];
  });
}
