export enum SOURCE_SYSTEM {
  ACCOUNTING_IMPORT = 'ACCOUNTING_IMPORT',
  USER_UPLOAD = 'USER_UPLOAD',
  NANONETS = 'NANONETS',
  GOOGLE_INTEGRATION = 'GOOGLE_INTEGRATION',
  MICROSOFT_INTEGRATION = 'MICROSOFT_INTEGRATION',
  JUMPCLOUD_INTEGRATION = 'JUMPCLOUD_INTEGRATION',
  CODAT = 'CODAT',
  GMI = 'GMI',
  ZAPIER = 'ZAPIER',
  PLEO = 'PLEO',
  WORKATO = 'WORKATO',
  SPREADSHEET_UPLOAD = 'SPREADSHEET_UPLOAD',
  KOMBO = 'KOMBO',
}
