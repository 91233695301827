import { useState } from 'react';

export const useWorkatoMessage = () => {
  const [iframeHeight, setIframeHeight] = useState('');
  const [canStartIntegration, setCanStartIntegration] = useState(false);
  const [hasIframeError, setHasIframeError] = useState(false);

  const receiveIframeMessage = (event: MessageEvent<any>) => {
    let eventData = event.data;

    if (typeof eventData === 'string') {
      eventData = JSON.parse(eventData);
    }

    switch (eventData.type) {
      case 'heightChange':
        setIframeHeight(`${eventData.payload.height}px`);
        break;
      case 'connectionStatusChange':
        if (eventData.payload.connected) {
          setCanStartIntegration(true);
        }
        break;
      case 'error':
        setHasIframeError(true);
        break;
      default:
        break;
    }
  };

  return { canStartIntegration, hasIframeError, iframeHeight, receiveIframeMessage };
};
