export interface StepProps {
  label: string;
  optionalLabel?: string;
  completed?: boolean;
  error?: boolean;
}

export enum StepperOrientation {
  'horizontal' = 'horizontal',
  'vertical' = 'vertical',
}

export interface StepperProps {
  steps: StepProps[];
  activeStep: number;
  orientation?: StepperOrientation;
  alternativeLabel?: boolean;
}
