import { combineReducers } from 'redux';

import { benchmarksReducers } from './benchmarks';
import { importedSuccessfullyReducers } from './company-invoices';
import { companyToolsReducers } from './company-tools';
import { connectReducers } from './connect';
import { documentsReducers } from './documents';
import { subscriptionsReducers } from './subscription';
import { usersReducers } from './users';
import { usersReducers as usersReducersOld } from './users-old';
import { workflowsReducers } from './workflows';

export const ViewsReducer = combineReducers({
  benchmarks: benchmarksReducers,
  companyInvoices: importedSuccessfullyReducers,
  companyTools: companyToolsReducers,
  connect: connectReducers,
  documents: documentsReducers,
  subscriptions: subscriptionsReducers,
  users: usersReducers,
  usersOld: usersReducersOld,
  workflows: workflowsReducers,
});
