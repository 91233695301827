import { Box, Button, Typography, useTheme } from '@mui/material';
import connectErrorSvg from 'assets/images/tool-usage-analytics/connect-error.svg';
import connectSuccessSvg from 'assets/images/tool-usage-analytics/connect-success.svg';
import { Icon } from 'asteroids';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FooterSection, SvgContainer } from '../drawer-usage-analytics.styles';

export enum ConnectionStatus {
  SUCCESS,
  ERROR,
}

export interface IntegrationStatusProps {
  action?: () => void;
  actionTitle?: string;
  description: string;
  title: string;
}

interface StartIntegrationStatusProps {
  status: ConnectionStatus;
  successProps: IntegrationStatusProps;
  errorProps: IntegrationStatusProps;
  closeHandler: () => void;
}

export const StartIntegrationStatus: FC<StartIntegrationStatusProps> = (props: StartIntegrationStatusProps) => {
  const { closeHandler, errorProps, status, successProps } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const componentProps = useMemo(() => {
    if (status === ConnectionStatus.SUCCESS) {
      return {
        actionHandler: () => successProps.action?.(),
        actionTitle: successProps.actionTitle,
        color: theme.palette.primary.main,
        description: successProps.description,
        icon: 'check_circle',
        image: connectSuccessSvg,
        title: successProps.title,
      };
    }
    return {
      actionHandler: () => errorProps.action?.(),
      actionTitle: errorProps.actionTitle,
      color: 'error.main',
      description: errorProps.description,
      icon: 'error',
      image: connectErrorSvg,
      title: errorProps.title,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1, marginLeft: 5, marginRight: 5 }}>
        <Box>
          <Box display='flex' alignItems='center' pb={8}>
            <Icon sx={{ color: componentProps.color }}>{componentProps.icon}</Icon>
            <Typography variant='h2' color={componentProps.color} sx={{ mb: '0', pl: 1 }}>
              {componentProps.title}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <SvgContainer src={componentProps.image} alt='status-image' />
            <Typography variant='body1' color='text.primary'>
              {componentProps.description}
            </Typography>
          </Box>

          {componentProps.actionHandler && (
            <Box display='flex' justifyContent='end' pt={8}>
              <Button variant='text' color='primary' onClick={() => componentProps.actionHandler()}>
                {componentProps.actionTitle}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <FooterSection>
        <Box
          sx={{
            display: 'flex',
            height: 52,
            justifyContent: 'end',
            paddingBottom: 1,
            paddingTop: 1,
            width: '100%',
          }}>
          <Button variant='text' color='secondary' onClick={() => closeHandler()}>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.close_button_text')}
          </Button>
        </Box>
      </FooterSection>
    </>
  );
};
