import { Box, TabProps } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: TabProps['value'];
  value: TabProps['value'];
}

export const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, index, value, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};
