import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { colors } from 'asteroids';

const PREFIX = 'ChatComponent';

export const classes = {
  chatContent: `${PREFIX}-chatContent`,
};

export const StyledBox = styled(Box)({
  [`&.${classes.chatContent}`]: {
    '& .mention': {
      background: alpha(colors.primary.main, 0.08),
      color: colors.primary.main,
    },
    '& a': {
      '&:hover': {
        color: colors.primary.dark,
      },
      color: colors.primary.main,
    },
    '& h1': {
      fontSize: '1.125rem',
    },
    '& h1, & h2, & h3': {
      fontWeight: 600,
      lineHeight: '1.25rem',
    },
    '& h2': {
      fontSize: '1rem',
    },
    '& h3': {
      fontSize: '0.875rem',
    },
    '& ol': {
      paddingLeft: '1.25rem',
    },
    '& ul': {
      listStyle: 'inside',
    },
    '& ul, & ol': {
      '& li.ql-indent-1': {
        paddingLeft: '1.8rem !important',
      },
      '& li.ql-indent-2': {
        paddingLeft: '2.5rem !important',
      },
      '& li.ql-indent-3': {
        paddingLeft: '3.5rem !important',
      },
      '& li.ql-indent-4': {
        paddingLeft: '4.5rem !important',
      },
      '& li.ql-indent-5': {
        paddingLeft: '5.5rem !important',
      },
      '& li.ql-indent-6': {
        paddingLeft: '6.5rem !important',
      },
      '& li.ql-indent-7': {
        paddingLeft: '7.5rem !important',
      },
      '& li.ql-indent-8': {
        paddingLeft: '8.5rem !important',
      },
      paddingTop: '0.5rem',
    },
    fontSize: '0.875rem',
    fontWeight: 300,
  },
});
