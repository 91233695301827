import { PaginatedResponse, TicketStages, TicketStatuses, TicketType } from 'libs/models';
import { AppUrl } from 'src/constants/appurl';

import { Subscription } from '../models';
import { apiService, apiUrl } from '../services';
import { isADiscoveryTool, isAToolRequest } from './subscription-item.logic';

export const fetchTickets = async (pageNumber: number): Promise<PaginatedResponse<TicketType>> => {
  const baseUrl = `${apiUrl.freshdesk}?page=${pageNumber - 1}`;
  const { data } = await apiService.get(baseUrl);

  return data;
};

export const buildToolDetailsPageUrl = (ticket: TicketType): string => {
  if (!ticket.subscription) {
    // TODO: is it correct?
    return '';
  }

  const isNewSolutionDetailPage = false;
  let url = AppUrl.getToolUrl(
    ticket.subscription as Subscription,
    isNewSolutionDetailPage,
    isAToolRequest(ticket.subscription?.state as number),
    isADiscoveryTool(ticket.subscription?.state as number)
  );

  if (ticket.commentId) {
    url = `${url}?commentId=${ticket.commentId}`;
  }

  url = `${url}#showHistory`;

  return url;
};

export const ticketStatusColor = {
  [TicketStatuses.CLOSED]: 'text.disabled',
  [TicketStatuses.IN_PROGRESS]: 'info.main',
  [TicketStatuses.ON_HOLD]: 'error.main',
  [TicketStatuses.OPEN]: 'warning.main',
  [TicketStatuses.PENDING]: 'warning.main',
  [TicketStatuses.RESOLVED]: 'success.main',
};

export const ticketStatuses = {
  [TicketStatuses.OPEN]: 'sastrify_support_view:ticket_statuses.open',
  [TicketStatuses.IN_PROGRESS]: 'sastrify_support_view:ticket_statuses.in_progress',
  [TicketStatuses.PENDING]: 'sastrify_support_view:ticket_statuses.pending',
  [TicketStatuses.ON_HOLD]: 'sastrify_support_view:ticket_statuses.on_hold',
  [TicketStatuses.RESOLVED]: 'sastrify_support_view:ticket_statuses.resolved',
  [TicketStatuses.CLOSED]: 'sastrify_support_view:ticket_statuses.closed',
};

export const ticketStages = {
  [TicketStages.CAPTURE_YOUR_BUSINESS_NEEDS]: 'sastrify_support_view:ticket_stages.capture_business_needs',
  [TicketStages.WAITING_VENDOR_MEETING_ACCEPTANCE]: 'sastrify_support_view:ticket_stages.vendor_meeting_requested',
  [TicketStages.NEGOTIATION_IN_PROGRESS]: 'sastrify_support_view:ticket_stages.negotiation_in_progress',
  [TicketStages.BENCHMARKING_IN_PROGRESS]: 'sastrify_support_view:ticket_stages.benchmarking_in_progress',
  [TicketStages.PROPOSAL_REVIEWS_BY_YOU]: 'sastrify_support_view:ticket_stages.your_proposal_review',
  [TicketStages.YOUR_LEGAL_AND_SECURITY_REVIEW]: 'sastrify_support_view:ticket_stages.your_legal_and_security_review',
  [TicketStages.AWAITING_YOUR_SIGNATURE]: 'sastrify_support_view:ticket_stages.awaiting_your_signature',
  [TicketStages.PROPOSAL_REJECTED]: 'sastrify_support_view:ticket_stages.proposal_rejected',
  [TicketStages.PROPOSAL_ACCEPTED]: 'sastrify_support_view:ticket_stages.proposal_accepted',
  [TicketStages.EXPLORING_ALTERNATIVES]: 'sastrify_support_view:ticket_stages.exploring_alternatives',
  [TicketStages.ALTERNATIVES_IDENTIFIED]: 'sastrify_support_view:ticket_stages.alternatives_identified',
  [TicketStages.BENCHMARKING_COMPLETED]: 'sastrify_support_view:ticket_stages.benchmarking_completed',
};

export const getTicketStatusColor = (status: TicketStatuses) => {
  if (!ticketStatusColor[status]) {
    return 'text.disabled';
  }
  return ticketStatusColor[status];
};

export const getTicketStageName = (stage: TicketStages) => {
  if (!ticketStages[stage]) {
    return '';
  }
  return ticketStages[stage];
};

export const getTicketStatusName = (status: TicketStatuses) => {
  if (!ticketStatuses[status]) {
    return '';
  }
  return ticketStatuses[status];
};

export const getTicketStageColor = (stage: TicketStages) => {
  const stages = [
    TicketStages.PROPOSAL_REVIEWS_BY_YOU,
    TicketStages.YOUR_LEGAL_AND_SECURITY_REVIEW,
    TicketStages.AWAITING_YOUR_SIGNATURE,
  ];
  if (stages.includes(stage)) {
    return 'tertiary.main';
  }

  return 'text.secondary';
};
