import { Box, styled } from '@mui/material';

export const ReviewFailedInvoiceContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  width: '100%',
  '.left-section': {
    display: 'flex',
    justifyContent: 'center',
    minWidth: theme.spacing(40),
    alignItems: 'center',
    flexBasis: '60%',
    padding: theme.spacing(2),
    background: theme.palette.grey[100],
  },
  '.right-section': {
    flexBasis: '40%',
    minWidth: theme.spacing(40),
    borderRadius: theme.spacing(0.5),
    justifyContent: 'space-between',
    '.invoice-date-picker': {
      '.MuiInputBase-root': {
        height: '38px',
      },
    },
    '.confirmation-section': {
      width: '100%',
      display: 'block',
      padding: theme.spacing(0, 4, 2),
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      textAlign: 'right',
      '.confirmation-options': {
        '.delete-button': {
          marginRight: theme.spacing(2),
          color: theme.palette.text.secondary,
        },
      },
    },
  },
}));

export const ReviewInvoiceFormContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: 'calc(100% - 70px)',
  label: {
    padding: theme.spacing(1, 0),
  },
  padding: theme.spacing(2, 4),
  '.amount-label': {
    padding: theme.spacing(1, 0),
  },
  '.currency-label': {
    padding: theme.spacing(0.3, 0),
  },
  '.currency-select-button': {
    height: '40px',
    textTransform: 'none',
  },
}));
