import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const InvoiceTableSkeleton = (): JSX.Element => {
  return (
    <Box px={6}>
      <Box mt={2}>
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <Box display='flex' gap={3} justifyContent='space-around' alignItems='center' key={item}>
            <Skeleton variant='rectangular' height={30} width={30} />
            <Skeleton variant='text' height={50} width={100} />
            <Skeleton variant='text' height={50} width={100} />
            <Skeleton variant='text' height={50} width={100} />
            <Skeleton variant='text' height={50} width={100} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
