export const colors = {
  actionRequired: '#F66F31',
  alabaster: '#FAFAFA',
  alabasterV2: '#FCFCFC',
  alto: '#d1d1d1',
  antiqueBrass: '#CE8172',
  apricotPeach: '#FCCFBA',
  aquamarineBlue: '#6DDFCE',
  athensGray: '#EBECF0',
  avatarHoverColor: 'rgba(246, 112, 19, 0.5)',
  bahamaBlue: '#0869A0',
  black: '#000000',
  blackSecondary: '#363636',
  bridesmaid: '#FEECE3',
  bridesmaidV2: '#FEF3ED',
  brightSun: '#FFCD3C',
  bunker: '#141A1D',
  buttercup: '#F7B60E',
  buttonHover: '#FCFCFC',
  cadetBlue: '#AEB7C2',
  chipBackgroundColor: 'rgba(212, 214, 216, 0.15)',
  cinderella: '#FDE2D6',
  cinnabar: '#EA4335',
  clearDay: '#F9FFFC',
  cloudBurst: '#24285B',
  concrete: '#F2F2F2',
  corduroy: '#6A6C6A',
  cornFlowerBlue: '#6195ED',
  crusta: '#F67031',
  dark: '#363636',
  darkGunmetal: '#1B242B',
  doveGray: '#6E6E6E',
  downy: '#6DCDA5',
  dustyGray: '#949494',
  edgeWater: '#cae4d9',
  emerald: '#49C18F',
  errorFeedback: '#D92D3E',
  eucalyptus: '#1E9664',
  frostedMint: '#E2FFF3',
  gallery: '#f0f0f0',
  gold: '#FFD200',
  gossamer: '#08A07C',
  grayChateau: '#A3ABB7',
  green: 'rgba(30, 150, 100, 1)',
  greenIcon: '#0BC2A7',
  grey: '#A2A4A1',
  greyBackgroundHover: 'rgba(218, 218, 218, 0.5)',
  greyIcon: 'rgba(255, 255, 255, 0.6)',
  greyLight: '#cfcfcf',
  greyText: '#5B616E',
  gunPowder: '#3F3D56',
  iceberg: '#D3F0E4',
  iron: '#D4D6D8',
  ironV2: '#D1D7DB',
  lemonChiffon: '#FFF8C9',
  lightGrey: '#F4F4F4',
  lightGreyBackground: 'rgba(212, 214, 216, 0.4)',
  lightGreyBackgroundDisabled: '#F5F5F5',
  lightNewPrimaryBackground: '#EBF5F3',
  lightPrimaryBackground: 'rgba(253, 226, 214, 0.5)',
  linen: '#FAEEE8',
  manatee: '#919192',
  mercury: '#E6E6E6',
  mineShaft: '#333333',
  mirage: '#1A2327',
  newPrimary: '#068466',
  nobel: '#b5b5b5',
  osloGray: '#939A9D',
  outerSpace: '#27343A',
  outerSpaceV2: '#212B30',
  peachOrange: '#FFD99A',
  pearlAqua: '#92DABC',
  persianRed: '#D32F2F',
  persimmon: '#FF6154',
  piper: '#CD5D29',
  pomegranate: '#F44336',
  primary: '#f67031',
  primaryLight: '#b48f62',
  primarySuperLightRgba: 'rgba(246, 112, 49, 0.2)',
  rajah: '#F9A076',
  raven: '#727c8f',
  red: '#D71818',
  ripeLemon: '#F7DD0E',
  riverBed: '#4B565B',
  rollingStone: '#6F787C',
  roseBud: '#FBB898',
  sanMarino: '#406CA0',
  seashell: '#f1f1f1',
  secondary: '#24B478',
  silver: '#cccccc',
  silverChalice: '#A8A8A8',
  silverSand: '#B7BBBD',
  soapstone: '#FFFBF9',
  sterile: '#CFFCF5',
  tagGrey: 'rgba(227, 227, 227, 0.5)',
  tanHide: '#F88853',
  tetapaGreen: '#1E3139',
  textPrimary: 'rgba(0, 0, 0, 0.87)',
  transparent: 'transparent',
  tuna: '#2e323a',
  turquoise: '#35D0BA',
  venetianRed: '#C9C9C9',
  vividTangerine: '#F4A28C',
  waxFlower: '#FFC0A6',
  white: '#fff',
  wildSand: '#F6F6F6',
};

export const statusColor = {
  active: {
    background: '#D3F0E4',
    color: '#24B478',
  },
  discovered: {
    background: 'rgba(214, 229, 50, 0.59)',
    color: 'rgba(80, 85, 22, 0.59)',
  },
  expired: {
    background: colors.rollingStone,
    color: colors.white,
  },
  inActive: {
    background: '#e1e0e0',
    color: '#4a4a4a',
  },
  inDiscoveryNegotiation: {
    // TODO: use the correct color
    background: '#FCE1DE',
    color: '#DB2424',
  },
  inEvaluation: {
    background: 'rgba(231, 202, 245, 0.59)',
    color: '#BD35FF',
  },
  inNegotiation: {
    background: 'rgba(174, 203, 250, 0.44)',
    color: '#2E7EFF',
  },
  inTrial: {
    background: 'rgba(141, 111, 225, 0.09)',
    color: '#8D6FE1',
  },
  rejected: {
    background: '#FCE1DE',
    color: '#DB2424',
  },
  requested: {
    background: 'rgba(255, 210, 189, 0.29)',
    color: '#F67031',
  },
  underConsideration: {
    background: 'rgba(244, 151, 66, 0.09)',
    color: '#F49742',
  },
  underReview: {
    // TODO: use the correct color
    background: '#FCE1DE',
    color: '#DB2424',
  },
  upForRenewal: {
    background: '#FCE1DE',
    color: '#DB2424',
  },
};

export const borders = {
  actionMenuBorder: '1px solid rgba(162, 164, 161, 0.5)',
};

export const boxShadows = {
  actionMenuBoxShadow: '4px 4px 8px rgba(196, 196, 196, 0.2)',
  boxShadowOne: '0px 6px 21px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.04)',
  boxShadowTwo: '0.215rem 0.215rem 0.539rem rgba(196, 196, 196, 0.2)',
  cardBoxShadow: '3.4466px 3.4466px 8.6165px rgba(196, 196, 196, 0.2);',
  cardBoxShadowTwo: '0px 4px 10px 1px rgba(31, 30, 78, 0.1)',
  condensedItemBoxShadow: 'rgb(125 145 179 / 20%) 0px 1.5px 2px 0px, rgb(23 43 77 / 20%) 1px 0px 1px',
  condensedItemDiscoveredBoxShadow: '3.42857px 3.42857px 8.57143px rgba(196, 196, 196, 0.2)',
  dropDownPopperBoxShadow:
    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  popperShadow: '3.4466px 3.4466px 8.6165px rgba(196, 196, 196, 0.2)',
  popperShadowTwo: '0px 1px 8px rgba(234, 67, 53, 0.33)',
  tableRowContainerBoxShadow: '2.68314px 2.68314px 6.70784px rgba(196, 196, 196, 0.2)',
};
