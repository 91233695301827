import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { DRAWER_VARIANT, DrawerInfoType } from 'shared/common.definitions';
import { getRequestDetails } from 'shared/logic/requests.logic';

import { DrawerWorkflowInitiative, DrawerWorkflowInitiativeWizard } from '..';

const NOT_FOUND_PAGE = '/request-not-found';
const QUERY_NAME = 'workflow-request-details';
const BAD_REQUEST_STATUS = 400;

interface DrawerWorkflowInitiativeWrapperProps {
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
  toggleDrawer: () => void;
  drawerType: DrawerInfoType;
}

export const DrawerWorkflowInitiativeWrapper: React.FC<DrawerWorkflowInitiativeWrapperProps> = ({
  drawerType,
  showNotification,
  toggleDrawer,
}) => {
  const { hash } = useLocation();
  const { push } = useHistory();

  const requestId = hash.includes(DRAWER_VARIANT.EDIT_REQUEST)
    ? hash.split(DRAWER_VARIANT.EDIT_REQUEST).slice(-1)[0]
    : '';

  const { data, isLoading } = useQuery([QUERY_NAME, requestId], () => getRequestDetails(requestId), {
    enabled: !!requestId,
    onError: (error) => {
      if (error && (error as AxiosError).response?.status === BAD_REQUEST_STATUS) {
        toggleDrawer();
        push(NOT_FOUND_PAGE);
      }
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {(!requestId || (!isLoading && !!data?.childRequests)) && (
        <DrawerWorkflowInitiativeWizard
          toggleDrawer={toggleDrawer}
          drawerType={drawerType}
          showNotification={showNotification}
        />
      )}
      {requestId && !isLoading && !data?.childRequests && (
        <DrawerWorkflowInitiative
          showNotification={showNotification}
          toggleDrawer={toggleDrawer}
          drawerType={drawerType}
        />
      )}
    </>
  );
};
