import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export const trackAddonRequest = (data: {
  companyId: string;
  customerName: string;
  userId: string;
  userName: string;
  page: string;
  feature: string;
}) => {
  EventTracking.trackEvent(trackEventName.UpsellingPrompt, data);
};
