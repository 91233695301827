import classnames from 'classnames';

interface CommentButtonProps {
  onClick(): void;
  disabled: boolean;
  submitting: boolean;
  isActive: boolean;
}

export const CommentButton: React.FC<CommentButtonProps> = ({ disabled, isActive, onClick, submitting }) => {
  return (
    <div className='comment-editorfooter pt-3 pb-3 comment-editorfooter pt-3 pb-3 is-flex is-justify-content-flex-end'>
      <button
        disabled={disabled}
        type='button'
        className={classnames('button button--icon', {
          'is-loading': submitting,
          'is-primary': isActive,
        })}
        data-testid='send-comment'
        onClick={onClick}>
        <i className='uil-message' />
      </button>
    </div>
  );
};
