import { Box, LinearProgress, styled, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionTaskProgressBarComponentProps } from '.';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
  },
  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.secondary.main,
  },
  borderRadius: 5,
  height: 10,
}));

export const SubscriptionTaskProgressBarComponent: FC<SubscriptionTaskProgressBarComponentProps> = (props) => {
  const { allTodos, completedTodos, isOpenTasks } = props;
  const { t } = useTranslation();

  const completedTodosProgressValue = (completedTodos * 100) / allTodos;

  const renderProgressBar = () => {
    const completed = completedTodos || 0;
    const total = allTodos || 0;

    if (isOpenTasks) {
      return (
        <Box data-testid='tasks-completed'>
          <Box my='0.5rem' display='flex' width='100%' justifyContent='space-between' alignItems='center'>
            <Typography variant='small' color='text.secondary'>{`${completed} / ${total}`}</Typography>
          </Box>
          <BorderLinearProgress variant='determinate' value={completedTodosProgressValue} />
        </Box>
      );
    }

    return (
      <Box data-testid='tasks-completed'>
        <BorderLinearProgress variant='determinate' value={completedTodosProgressValue} />
        <Box my='0.5rem' display='flex' width='100%' justifyContent='space-between' alignItems='center'>
          <Typography variant='small' color='text.secondary'>
            {t('subscription_detail_view:tabs_component_section.subscription_task_tab.progress_bar_text', {
              completed,
              total,
            })}
          </Typography>
        </Box>
      </Box>
    );
  };

  return <>{renderProgressBar()}</>;
};
