import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type FeatureFlags = {
  [key: string]: boolean;
};

type TCompanyFeatureFlagContext = {
  companyFeatureFlags: FeatureFlags;
  setCompanyFeatureFlags: Dispatch<SetStateAction<FeatureFlags>>;
};

type CompanyFeatureFlagProviderProps = {
  children: React.ReactNode;
};

export const CompanyFeatureFlagContext = createContext<TCompanyFeatureFlagContext>({
  companyFeatureFlags: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCompanyFeatureFlags: () => {},
});

export const CompanyFeatureFlagProvider: React.FC<CompanyFeatureFlagProviderProps> = ({ children }) => {
  const [companyFeatureFlags, setCompanyFeatureFlags] = useState<FeatureFlags>({});

  return (
    <CompanyFeatureFlagContext.Provider value={{ companyFeatureFlags, setCompanyFeatureFlags }}>
      {children}
    </CompanyFeatureFlagContext.Provider>
  );
};

export const useCompanyFeatureFlag = (featureFlagName: string): boolean => {
  const { companyFeatureFlags } = useContext(CompanyFeatureFlagContext);

  return companyFeatureFlags[featureFlagName] || false;
};
