import { Box, MenuItem, Select, Typography } from '@mui/material';
import { Chip, InputField } from 'asteroids';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RenewalDate } from './change-renewal-date-form.hook';

type RenewalFrequencyProps = {
  form: UseFormReturn<RenewalDate>;
  isCustomBillingFrequency: boolean;
};

export const RenewalFrequencyField = (props: RenewalFrequencyProps) => {
  const { form } = props;
  const { t } = useTranslation();
  const { errors } = form.formState;
  const billingFrequency = form.watch('billingFrequency');
  const otherBillingFrequencyType = form.watch('otherBillingFrequencyType');

  const frequencyOptions = [
    {
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.renewal_frequency_select_input_section.monthly_option_text'
      ),
      value: 0,
    },
    {
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.renewal_frequency_select_input_section.yearly_option_text'
      ),
      value: 1,
    },
    {
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.renewal_frequency_select_input_section.other_option_text'
      ),
      value: 3,
    },
  ];

  return (
    <Box data-testid='renewal-frequency'>
      <Typography variant='h3' mb={2} mt={3}>
        {t(
          'common:modals.change_renewal_date_feature.body_section.form.renewal_frequency_select_input_section.renewal_frequency_label_text'
        )}
      </Typography>
      <Controller
        name='billingFrequency'
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <>
            {frequencyOptions.map((item) => (
              <Chip
                data-testid={`${item.label.toLowerCase()}-frequency`}
                variant={value === item.value ? 'filled-dark' : 'outlined'}
                color='secondary'
                size='medium'
                clickable
                onClick={() => onChange(item.value)}
                sx={{ mr: 1 }}
                label={item.label}
                {...form.register('billingFrequency')}
              />
            ))}
          </>
        )}
      />
      {billingFrequency === 3 && (
        <Box data-testid='other-frequency-box'>
          <Typography variant='body' mt={2} color='text.primary'>
            {t(
              'common:modals.change_renewal_date_feature.body_section.form.renewal_frequency_select_input_section.subscription_renews_text'
            )}
            <br />
            {t(
              'common:modals.change_renewal_date_feature.body_section.form.renewal_frequency_select_input_section.subscription_renews_every_text'
            )}
          </Typography>
          <Box display='flex'>
            <InputField
              data-testid='other-frequency-input'
              size='small'
              type='number'
              {...form.register('otherBillingFrequency', {
                required: true,
              })}
              inputRef={form.register('otherBillingFrequency').ref}
              inputProps={{ min: 0 }}
              defaultValue={0}
              sx={{ mr: 1, mt: 2, p: 0, width: 56 }}
            />
            <Select
              data-testid='other-frequency-select'
              size='small'
              {...form.register('otherBillingFrequencyType')}
              value={otherBillingFrequencyType}
              sx={{ mt: 2, width: '177px' }}>
              <MenuItem value='months'>
                {t('common:modals.change_renewal_date_feature.body_section.form.renewal_interval.months_option_text')}
              </MenuItem>
              <MenuItem value='years'>
                {t('common:modals.change_renewal_date_feature.body_section.form.renewal_interval.years_option_text')}
              </MenuItem>
            </Select>
          </Box>
        </Box>
      )}
      {!!errors.otherBillingFrequency && (
        <Typography color='error.main' variant='caption'>
          {errors.otherBillingFrequency?.message}
        </Typography>
      )}
    </Box>
  );
};
