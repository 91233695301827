import { AxiosResponse } from 'axios';
import Bottleneck from 'bottleneck';

class RateLimiter {
  private limiter: Bottleneck;

  private REACT_APP_API_MAX_CONCURRENT: number;

  private REACT_APP_API_MIN_TIME: number;

  constructor() {
    this.REACT_APP_API_MAX_CONCURRENT = Number.isNaN(Number(process.env.REACT_APP_API_MAX_CONCURRENT))
      ? 2
      : Number(process.env.REACT_APP_API_MAX_CONCURRENT);
    this.REACT_APP_API_MIN_TIME = Number.isNaN(Number(process.env.REACT_APP_API_MIN_TIME))
      ? 100
      : Number(process.env.REACT_APP_API_MIN_TIME);
    this.limiter = new Bottleneck({
      maxConcurrent: this.REACT_APP_API_MAX_CONCURRENT,
      minTime: this.REACT_APP_API_MIN_TIME,
    });
  }

  public rateLimiter(request: Promise<AxiosResponse<any, any>>) {
    return this.limiter.schedule(() => request);
  }
}

export const limiter = new RateLimiter();
