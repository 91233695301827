import { Box, Divider, Stack, Typography } from '@mui/material';
import { Avatar } from 'asteroids';
import { FC, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkRaw from 'remark-gfm';

import { ChatComponentProps } from './chat.component.props';
import { classes, StyledBox } from './chat.component.styles';
import { ChatInputComponent } from './components/chat-input.component';
import { KebabMenuComponent, KebabMenuOptions } from './components/kebab-menu.component';

export const ChatComponent: FC<ChatComponentProps> = ({
  avatarAlt,
  avatarSrc,
  canModify,
  createdAt,
  description,
  isSubmitButtonDisabled = false,
  onDelete,
  onSend,
  testId,
  title,
}) => {
  const [desc, setDesc] = useState('');
  const [modify, setModify] = useState(true);

  useEffect(() => {
    if (description) {
      setDesc(description);
      setModify(false);
    }
  }, [description]);

  const renderInfo = () => (
    <>
      <Stack direction='column' width='100%' mb={1}>
        <Stack direction='row' alignItems='center'>
          <Avatar src={avatarSrc} alt={avatarAlt} size='small' sx={{ marginRight: '0.5rem' }} />
          <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
            <Stack direction='row' alignItems='center' width='100%' spacing={1}>
              <Typography variant='label' fontWeight={500} data-testid={testId}>
                {title}
              </Typography>
              <Typography variant='small' color='textSecondary'>
                {createdAt}
              </Typography>
            </Stack>
            {canModify && (
              <KebabMenuComponent
                onDelete={onDelete}
                onEdit={() => setModify(true)}
                availableOptions={[KebabMenuOptions.edit, KebabMenuOptions.delete]}
              />
            )}
          </Stack>
        </Stack>
        {desc && (
          <StyledBox mx={4} data-testid={`${testId}-description`} className={classes.chatContent}>
            <ReactMarkdown remarkPlugins={[remarkRaw]} rehypePlugins={[rehypeRaw]}>
              {desc}
            </ReactMarkdown>
          </StyledBox>
        )}
      </Stack>
      <Divider />
    </>
  );
  const renderEdit = () => (
    <ChatInputComponent
      avatarSrc={avatarSrc}
      avatarAlt={avatarAlt}
      value={desc}
      testId='edit-comment'
      onChange={(value) => setDesc(value)}
      onAction={() => {
        if (onSend) onSend(desc);
      }}
      onCancel={() => {
        if (description) {
          setDesc(description);
          setModify(false);
        }
      }}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
    />
  );

  return <Box mb={3}>{canModify && modify ? renderEdit() : renderInfo()}</Box>;
};
