/* eslint-disable @typescript-eslint/no-explicit-any */
import 'quill-mention';

import { Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import { CSM_USER_EMAIL } from 'shared/common.definitions';
import { mentionAllowedChars } from 'shared/helpers/form.helpers';
import { User } from 'shared/models';

import { RichTextEditorComponentProps, UserSuggestion } from './rich-text-editor.component.props';
import { classes, RichTextEditorRoot } from './rich-text-editor.component.styles';

export const RichTextEditorComponent: React.FC<RichTextEditorComponentProps> = ({
  autoFocus,
  dataTestId,
  disabled,
  error,
  helperText,
  onChange,
  placeholder,
  value,
}) => {
  const [isFocused, setFocus] = useState(false);

  const editorRef = useRef<ReactQuill | null>(null);

  const queryClient = useQueryClient();
  const users = queryClient.getQueryData<User[]>('users');

  useEffect(() => {
    if (autoFocus) {
      editorRef?.current?.focus();
    }
  }, [editorRef, autoFocus]);

  const getUsers = useMemo(() => {
    return users
      ?.filter((user: User) => !user.email?.includes(CSM_USER_EMAIL))
      .map((user) => (user.isDeleted ? false : { id: user.id, value: user.name }))
      .filter(Boolean) as [
      {
        id: string | undefined;
        value: string | undefined;
      }
    ];
  }, [users]);

  const getUserSuggestions = useCallback(
    (searchTerm: string) => {
      return getUsers?.filter((person) => person?.value?.toLowerCase().startsWith(searchTerm.toLowerCase()));
    },
    [getUsers]
  );

  const getEditorClassName = () => {
    let className = '';

    if (isFocused) {
      className = classes.editorFocused;
    }
    if (error) {
      className = classes.editorError;
    }
    if (disabled) {
      className = classes.editorDisabled;
    }

    return className;
  };

  const editorModules = useMemo(
    () => ({
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: mentionAllowedChars,
        defaultMenuOrientation: 'bottom',
        mentionContainerClass: 'ql-list-container',
        mentionDenotationChars: ['@'],
        onSelect: (item: any, insertItem: (item: string) => void) => {
          insertItem(item);
        },
        source(searchTerm: string, renderList: (matches: UserSuggestion[] | undefined) => void) {
          const matchedUsersSuggestions = getUserSuggestions(searchTerm);

          renderList(matchedUsersSuggestions);
        },
      },
      toolbar: {
        container: [
          [{ header: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ],
      },
    }),
    [getUserSuggestions]
  );

  return (
    <RichTextEditorRoot className={classes.editorContainer} data-testid={dataTestId}>
      <ReactQuill
        value={value}
        ref={editorRef}
        onChange={(text: string, _, source: string) => {
          if (source === 'user') {
            onChange?.(text);
          }
        }}
        className={getEditorClassName()}
        placeholder={placeholder || ''}
        modules={editorModules}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        readOnly={disabled}
      />
      {helperText && (
        <Typography
          variant='small'
          data-testid='input-helper-text'
          className={`${classes.helperText} ${error ? classes.helperTextError : ''}`}>
          {helperText}
        </Typography>
      )}
    </RichTextEditorRoot>
  );
};
