import classnames from 'classnames';
import { AvatarIcon } from 'components/icons';
import { FC } from 'react';
import { getFirstAndLastInitialsFromText } from 'shared/helpers/common.helper';
import { Avatar } from 'src/asteroids';

import { LeftIconProps } from './left-icon.props';

export const LeftIcon: FC<LeftIconProps> = ({ avatarUrl, className, isToolOwnerNameEdited, name }) => {
  const ownerAvatarUrl = !isToolOwnerNameEdited ? avatarUrl : undefined;
  const ownerName = !isToolOwnerNameEdited ? name : undefined;

  if (!ownerName) {
    return <AvatarIcon />;
  }

  return (
    <Avatar
      src={ownerAvatarUrl}
      classes={{
        colorDefault: classnames({ 'sastrify-avatar': !ownerAvatarUrl }),
        root: className,
      }}>
      {getFirstAndLastInitialsFromText(ownerName, true)}
    </Avatar>
  );
};
