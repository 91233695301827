import './subscription-timeline-item.feature.scss';

import { Box } from '@mui/material';
import { HelpLink } from 'asteroids';
import classnames from 'classnames';
import {
  AccountIcon,
  AddAccountIcon,
  CalendarIcon,
  CashIcon,
  ChatRemoveIcon,
  ClipboardDeletedIcon,
  ClipboardDoneIcon,
  ClipboardIcon,
  CollectionIcon,
  ConnectLinkIcon,
  MySubscriptionsIcon,
  NewSolutionsIcon,
  NotesIcon,
  ReloadIcon,
  ReloadRemoveIcon,
  RemoveAccountIcon,
  RepeatIcon,
  SastrifyLogoIcon,
  TagIcon,
  UploadFileIcon,
} from 'components/icons';
import { MarkdownReaderComponent, StatusComponent, UserNameAndAvatarComponent } from 'components/index';
import { Tooltip } from 'components/tooltip';
import { useCheckUserPermission } from 'hooks/index';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { PERMISSION } from 'libs/enums/permissions';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  AuditLogActions,
  AuditLogFieldNames,
  billingFrequencyMapping,
  BillingFrequencyMappingType,
  SubscriptionActions,
  TagsActions,
  TodoStates,
} from 'shared/common.definitions';
import { formatCurrency, formatFullDate } from 'shared/helpers/common.helper';
import { dateToHumanReadableString } from 'shared/helpers/dates.helper';
import { Company, Document, SubscriptionSpendAndInvoices } from 'shared/models';
import { colors } from 'shared/theme';

import { SubscriptionCommentFeature } from '..';
import { SubscriptionTimelineItemFeatureProps } from '.';

type ToolOwnerChange = {
  name: string;
};

type DocumentData = (Document & SubscriptionSpendAndInvoices)[];

export const SubscriptionTimelineItemFeature: FC<SubscriptionTimelineItemFeatureProps> = memo((props) => {
  const {
    actionDescription,
    actionMenuIndex,
    cancelEditComment,
    closeModal,
    dateFormatted,
    documents,
    editingCommentIndex,
    handleEditComment,
    hasComment,
    historyItem,
    index,
    isEditingComment,
    isNewSolutionDetailPage,
    isToolRequestsPage,
    setActionMenuIndex,
    showModal,
    showNotification,
    spendAndInvoices,
    subscription,
  } = props;
  const { t } = useTranslation();
  const pathToAuditLogTranslation = 'subscription_detail_view:tabs_component_section.subscription_auditlog';
  const queryClient = useQueryClient();

  const canViewBudget = useCheckUserPermission(PERMISSION.VIEW_BUDGET);

  const actionChangesFrom = historyItem?.actionChanges?.changes?.from;
  const actionChangesTo = historyItem?.actionChanges?.changes?.to;
  const isNewSolutionCreated =
    actionDescription === SubscriptionActions.SubscriptionCreated && (isToolRequestsPage || isNewSolutionDetailPage);

  const subscriptionDocumentsCache = queryClient.getQueryData<Document[]>('subscriptionDocuments');
  const company = queryClient.getQueryData<Company>('company');

  const renderChangedLog = (pathToTranslation: string, showArrow = true) => {
    let text = '';
    if (pathToTranslation) {
      text = showArrow ? `${t(pathToTranslation)}: →` : t(pathToTranslation);
    } else {
      text = showArrow ? '→' : '';
    }

    return (
      <Box
        display='flex'
        alignItems='center'
        marginRight='0.6875rem'
        fontSize='1rem'
        fontWeight='500'
        lineHeight='1.5rem'
        color={colors.corduroy}>
        <div className='no-wrap'>{text}</div>
      </Box>
    );
  };

  const renderTagsDescription = () => {
    const fieldNameKeyPath = `${pathToAuditLogTranslation}.tags_change_log`;
    const tagsActionChangesFrom = actionChangesFrom ? (actionChangesFrom as unknown as string[]) : [];
    const tagsActionChangesTo = actionChangesTo ? (actionChangesTo as unknown as string[]) : [];
    let actionKeyPath = '';

    if (tagsActionChangesTo.length > tagsActionChangesFrom.length) {
      actionKeyPath = `${fieldNameKeyPath}.tag_added_label`;
    } else if (tagsActionChangesTo.length < tagsActionChangesFrom.length) {
      actionKeyPath = `${fieldNameKeyPath}.tag_removed_label`;
    }

    let removedTag;
    let shouldSkip = false;
    tagsActionChangesFrom.forEach((tag) => {
      if (shouldSkip) return;
      if (!tagsActionChangesTo.includes(tag)) {
        removedTag = tag;
        shouldSkip = true;
      }
    });

    const isTagRemoved = actionKeyPath && t(actionKeyPath) === TagsActions.TagRemoved;
    const isTagAdded = actionKeyPath && t(actionKeyPath) === TagsActions.TagAdded;

    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(actionKeyPath)}
        <Box display='inline-flex' fontSize='1rem' fontWeight='400' color={colors.outerSpace}>
          <span
            className={classnames('tag-auditLog', {
              'tag-removed': isTagRemoved,
            })}>
            {isTagAdded ? tagsActionChangesTo.slice(-1)[0] : removedTag}
          </span>
        </Box>
      </div>
    );
  };

  const renderDocumentDescription = () => {
    const { action, actionChanges, entityId } = historyItem;
    let document = null;

    const documentData: DocumentData = [...(documents as DocumentData), ...(spendAndInvoices as DocumentData)];

    if (entityId && action && action === AuditLogActions.ADDED) {
      document = documentData.find((document) => {
        const documentId = document.documentId ?? document.id;
        return documentId === entityId;
      });
    }

    const isDocumentRemoved = action && action === AuditLogActions.REMOVED;
    const actionKeyPath = isDocumentRemoved
      ? `${pathToAuditLogTranslation}.document_log.action_changes.document_deleted`
      : `${pathToAuditLogTranslation}.document_log.action_changes.document_upload`;

    const documentName = document ? (
      <a className='link break-all' href={document?.documentUrl ?? document?.url} target='_blank' rel='noreferrer'>
        {actionChanges?.file?.name}
      </a>
    ) : (
      <>
        <span style={{ textDecoration: 'line-through' }}>{actionChanges?.file?.name}</span>
        <em className='ml-2 has-text-grey'>({t(`${pathToAuditLogTranslation}.document_log.deleted_text`)})</em>
      </>
    );

    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(actionKeyPath)}
        <Box
          display='inline-flex'
          fontSize='1rem'
          fontWeight='400'
          color={colors.primary}
          style={{
            textDecoration: isDocumentRemoved ? 'line-through' : 'none',
          }}>
          {isDocumentRemoved ? <span>{actionChanges?.changes?.from}</span> : documentName}
        </Box>
      </div>
    );
  };

  const renderBillingFrequencyDescription = () => {
    const fieldNameKeyPath = `${pathToAuditLogTranslation}.billing_frequency_log`;
    const actionKeyPath = billingFrequencyMapping[Number(actionChangesTo) as keyof BillingFrequencyMappingType];
    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(`${fieldNameKeyPath}.action_change_label`)}
        <Box display='inline-flex' fontSize='1rem' fontWeight='400' color={colors.outerSpace}>
          {t(`${fieldNameKeyPath}.${actionKeyPath}`)}
        </Box>
      </div>
    );
  };

  const renderNotesDescription = () => {
    const fieldNameKeyPath = `${pathToAuditLogTranslation}.note_log`;
    const actionKeyPath =
      actionChangesFrom == null && String(actionChangesTo)?.length
        ? `${fieldNameKeyPath}.note_added_label`
        : `${fieldNameKeyPath}.note_edited_label`;

    return <div className='is-flex is-align-items-center'>{renderChangedLog(actionKeyPath, false)}</div>;
  };

  const renderDecisionDateDescription = () => {
    const { action } = historyItem;
    const fieldNameKeyPath = `${pathToAuditLogTranslation}.decision_date_log`;
    const actionKeyPath =
      action && action === AuditLogActions.CHANGED
        ? `${fieldNameKeyPath}.action_change_label`
        : `${fieldNameKeyPath}.action_remove_label`;
    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(actionKeyPath)}
        <Box
          display='inline-flex'
          fontSize='1rem'
          fontWeight='400'
          color={colors.outerSpace}
          style={{
            textDecoration: action && action === AuditLogActions.REMOVED ? 'line-through' : 'none',
          }}>
          {formatFullDate(String(actionChangesTo ?? actionChangesFrom))}
        </Box>
      </div>
    );
  };

  const renderRenewalDateDescription = () => {
    const { action } = historyItem;
    const fieldNameKeyPath = `${pathToAuditLogTranslation}.renewal_date_log`;
    const actionKeyPath =
      action && action === AuditLogActions.CHANGED
        ? `${fieldNameKeyPath}.action_change_label`
        : `${fieldNameKeyPath}.action_remove_label`;
    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(actionKeyPath)}
        <Box
          display='inline-flex'
          fontSize='1rem'
          fontWeight='400'
          color={colors.outerSpace}
          style={{
            textDecoration: action && action === AuditLogActions.REMOVED ? 'line-through' : 'none',
          }}>
          {formatFullDate(String(actionChangesTo ?? actionChangesFrom))}
        </Box>
      </div>
    );
  };

  const renderCancellationPeriodDescription = () => {
    const changedToArray = String(actionChangesTo).split(' ');
    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(`${pathToAuditLogTranslation}.cancellation_period_log.action_change_label`)}
        <Box display='inline-flex' fontSize='1rem' fontWeight='400' color={colors.outerSpace}>
          {t(`${pathToAuditLogTranslation}.cancellation_period_log.action_change_attribute.${changedToArray[1]}`, {
            count: Number(changedToArray[0]),
          })}
        </Box>
      </div>
    );
  };

  const renderAutoRenewalDescription = () => (
    <div className='is-flex is-align-items-center'>
      {renderChangedLog(`${pathToAuditLogTranslation}.auto_renewal_log.action_change_label`)}
      <Box display='inline-flex' fontSize='1rem' fontWeight='400' color={colors.outerSpace}>
        {t(`${pathToAuditLogTranslation}.auto_renewal_log.action_changes.${actionChangesTo}`)}
      </Box>
    </div>
  );

  const renderStateDescription = () => (
    <div className='is-flex is-align-items-center'>
      {renderChangedLog(`${pathToAuditLogTranslation}.status_change_log.action_change_label`)}
      <StatusComponent status={Number(actionChangesFrom)} />
      <div className='mx-2'>{t(`${pathToAuditLogTranslation}.status_change_log.to_text`)}</div>
      <StatusComponent status={Number(actionChangesTo)} />
    </div>
  );

  const renderOwnerDescription = () => {
    const { action } = historyItem;
    let logKey = 'assign_owner_label';
    if (action === AuditLogActions.REMOVED) logKey = 'remove_owner_label';
    if (action === AuditLogActions.CHANGED && actionChangesFrom) logKey = 'change_owner_label';

    let ownerActionChangesFrom: number | string | undefined = actionChangesFrom as number;
    let ownerActionChangesTo: number | string | undefined = actionChangesTo;

    if (typeof actionChangesFrom === 'object' && actionChangesFrom != null) {
      ownerActionChangesFrom = (actionChangesFrom as unknown as ToolOwnerChange).name;
    }

    if (typeof actionChangesTo === 'object' && actionChangesTo !== null) {
      ownerActionChangesTo = (actionChangesTo as unknown as ToolOwnerChange).name;
    }

    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(`${pathToAuditLogTranslation}.owner_log.action_changes.${logKey}`)}
        <Box
          fontSize='1rem'
          style={{
            textDecoration: action && action === AuditLogActions.REMOVED ? 'line-through' : 'none',
          }}>
          {action === AuditLogActions.REMOVED ? ownerActionChangesFrom : ownerActionChangesTo}
        </Box>
      </div>
    );
  };

  const renderBudgetDescription = () => {
    const { action } = historyItem;
    let logKey = 'remove_budget_label';
    if (action === AuditLogActions.CHANGED) logKey = 'change_budget_label';

    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(`${pathToAuditLogTranslation}.budget_log.action_changes.${logKey}`)}
        <Box
          fontSize='1rem'
          style={{
            textDecoration: action && action === AuditLogActions.REMOVED ? 'line-through' : 'none',
          }}>
          {formatCurrency({
            currency: company?.currency ?? DEFAULT_CURRENCY,
            isDecimal: true,
            style: 'currency',
            value: action && action === AuditLogActions.CHANGED ? Number(actionChangesTo) : Number(actionChangesFrom),
          })}
        </Box>
      </div>
    );
  };

  const renderNameDescription = () => (
    <div className='is-flex is-align-items-center'>
      {renderChangedLog(`${pathToAuditLogTranslation}.rename_log.subscription_rename_label`)}
      <Box>{actionChangesTo}</Box>
    </div>
  );

  const renderSubscriptionCreationDescription = () => {
    const fieldNameKeyPath = `${pathToAuditLogTranslation}.subscription_log`;

    let actionKeyPath = `${fieldNameKeyPath}.subscription_created_label`;

    if (isNewSolutionCreated) {
      actionKeyPath = `${fieldNameKeyPath}.new_solution_created_label`;
    }

    return <div className='is-flex is-align-items-center'>{renderChangedLog(actionKeyPath, false)}</div>;
  };

  const renderLinkedSoftwareDescription = () => {
    const { action } = historyItem;
    let logKey = 'link_removed_label';
    if (action === AuditLogActions.ADDED) logKey = 'link_added_label';

    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(`${pathToAuditLogTranslation}.linked_software_log.action_changes.${logKey}`)}
        <Box
          fontSize='1rem'
          style={{
            textDecoration: action && action === AuditLogActions.REMOVED ? 'line-through' : 'none',
          }}>
          {action === AuditLogActions.REMOVED ? actionChangesFrom : actionChangesTo}
        </Box>
      </div>
    );
  };

  const renderSubscriptionTodoDescription = (isDone?: boolean) => {
    const { action } = historyItem;
    let logKey = 'created_label';
    if (action === AuditLogActions.REMOVED) logKey = 'deleted_label';
    if (isDone) logKey = 'done_label';

    const description = historyItem?.actionChanges?.title;
    return (
      <div className='is-flex is-align-items-center'>
        {renderChangedLog(`${pathToAuditLogTranslation}.subscription_todos_log.action_changes.${logKey}`)}
        <Box
          fontSize='1rem'
          style={{
            textDecoration: action && action === AuditLogActions.REMOVED ? 'line-through' : 'none',
          }}>
          {description}
        </Box>
      </div>
    );
  };

  const renderSubscriptionTodoIcon = (isDone?: boolean) => {
    const { action } = historyItem;
    if (isDone) return <ClipboardDoneIcon />;
    if (action === AuditLogActions.REMOVED) return <ClipboardDeletedIcon />;
    return <ClipboardIcon fill={colors.white} width='14' height='14' />;
  };

  const isSubscriptionTodo =
    AuditLogFieldNames.STATE &&
    historyItem.actionChanges?.changes?.to === TodoStates.DONE &&
    historyItem.entity === AuditLogFieldNames.SUBSCRIPTION_TODOS;

  const renderAuditLogDescription = () => {
    switch (historyItem.actionChanges?.fieldName) {
      case AuditLogFieldNames.AUTO_RENEWAL:
        return renderAutoRenewalDescription();
      case AuditLogFieldNames.BILLING_FREQUENCY:
        return renderBillingFrequencyDescription();
      case AuditLogFieldNames.BUDGET_CENTS:
        return renderBudgetDescription();
      case AuditLogFieldNames.CANCELLATION_PERIOD:
        return renderCancellationPeriodDescription();
      case AuditLogFieldNames.COMMENT:
        return null;
      case AuditLogFieldNames.DECISION_DATE:
        return renderDecisionDateDescription();
      case AuditLogFieldNames.DOCUMENT:
        return renderDocumentDescription();
      case AuditLogFieldNames.LINKED_SOFTWARE:
        return renderLinkedSoftwareDescription();
      case AuditLogFieldNames.NAME:
        return renderNameDescription();
      case AuditLogFieldNames.NOTE:
        return renderNotesDescription();
      case AuditLogFieldNames.OWNER:
        return renderOwnerDescription();
      case AuditLogFieldNames.RENEWAL_DATE:
        return renderRenewalDateDescription();
      case AuditLogFieldNames.STATE:
        if (!isSubscriptionTodo) return renderStateDescription();
        return renderSubscriptionTodoDescription(true);
      case AuditLogFieldNames.TAGS:
        return renderTagsDescription();
      case AuditLogFieldNames.SUBSCRIPTION:
        return renderSubscriptionCreationDescription();
      case AuditLogFieldNames.SUBSCRIPTION_TODOS:
        return renderSubscriptionTodoDescription();
      default:
        return <MarkdownReaderComponent text={actionDescription} />;
    }
  };

  const renderAuditLogIcon = () => {
    const { action } = historyItem;
    switch (historyItem.actionChanges?.fieldName) {
      case AuditLogFieldNames.AUTO_RENEWAL:
      case AuditLogFieldNames.CANCELLATION_PERIOD:
        return <ReloadIcon fill={colors.white} height='12' width='12' />;
      case AuditLogFieldNames.BUDGET_CENTS:
        return <CashIcon width={14} fill={colors.white} />;
      case AuditLogFieldNames.COMMENT:
        return <ChatRemoveIcon />;
      case AuditLogFieldNames.BILLING_FREQUENCY:
      case AuditLogFieldNames.DECISION_DATE:
        return <CalendarIcon fill='white' height='12' width='12' />;
      case AuditLogFieldNames.DOCUMENT:
        return <UploadFileIcon />;
      case AuditLogFieldNames.LINKED_SOFTWARE:
        return <ConnectLinkIcon />;
      case AuditLogFieldNames.NAME:
        return <CollectionIcon />;
      case AuditLogFieldNames.NOTE:
        return <NotesIcon fill={colors.white} height='14' width='15' />;
      case AuditLogFieldNames.OWNER:
        if (action === AuditLogActions.REMOVED) return <RemoveAccountIcon />;
        if (action === AuditLogActions.CHANGED && actionChangesFrom) return <AccountIcon />;
        return <AddAccountIcon />;
      case AuditLogFieldNames.RENEWAL_DATE:
        return action === AuditLogActions.CHANGED ? (
          <ReloadIcon fill='white' height='12' width='12' />
        ) : (
          <ReloadRemoveIcon height='12' width='12' />
        );
      case AuditLogFieldNames.SUBSCRIPTION:
        if (isNewSolutionCreated) return <NewSolutionsIcon width='15' height='15' fill={colors.white} />;
        return <MySubscriptionsIcon width='15' height='15' fill={colors.white} />;
      case AuditLogFieldNames.STATE:
        if (!isSubscriptionTodo) return <RepeatIcon />;
        return renderSubscriptionTodoIcon(true);
      case AuditLogFieldNames.TAGS:
        return <TagIcon />;
      case AuditLogFieldNames.SUBSCRIPTION_TODOS:
        return renderSubscriptionTodoIcon();
      default:
        return <RepeatIcon />;
    }
  };

  const renderAuthorWithFormattedDate = () => (
    <div className='subscription-comment__author'>
      {historyItem.authorName && (
        <div className='subscription-comment__author-name'>
          <UserNameAndAvatarComponent
            avatarUrl={historyItem.authorAvatarUrl ?? undefined}
            name={historyItem.authorName}
            hideAvatar={!hasComment}
            height='24px'
            width='24px'
          />
        </div>
      )}
      <div className='comment-info'>
        {dateFormatted && (
          <Tooltip content={formatFullDate(dateFormatted)}>
            <span className='date-separator'>&nbsp;-&nbsp;</span>
            <span className='comment-date'>{dateToHumanReadableString(dateFormatted)}</span>
          </Tooltip>
        )}
      </div>
    </div>
  );

  const getItemId = () => {
    const fieldName = historyItem.actionChanges?.fieldName;
    if (!fieldName && historyItem.comment) {
      return `history-${AuditLogFieldNames.COMMENT}-${historyItem.id}`;
    }
    return `history-${historyItem.actionChanges?.fieldName ?? 'unknown'}-${historyItem.id}`;
  };

  if (historyItem.actionChanges?.fieldName === AuditLogFieldNames.BUDGET_CENTS && !canViewBudget) {
    return null;
  }

  if (historyItem.entity === 'SubscriptionProcurementSupportTicket') {
    const { actionChanges, entityId } = historyItem;

    return (
      <Box display='flex' alignItems='flex-start'>
        <Box display='flex' alignItems='center' justifyContent='center' ml={0.25}>
          <SastrifyLogoIcon width='24' height='24' />
        </Box>
        <Box ml={2.5}>
          <Box display='flex' alignItems='center'>
            <Box mr={1} fontSize='1rem' fontWeight='600' color={colors.corduroy}>
              {`${actionChanges?.changes?.to}:`}
            </Box>
            <HelpLink href={`/procurement-support/support-details?id=${entityId}`} target='_blank' icon=''>
              {t(`${pathToAuditLogTranslation}.procurement_support.link_text`)}
            </HelpLink>
          </Box>
          {renderAuthorWithFormattedDate()}
        </Box>
      </Box>
    );
  }

  return (
    <div key={`${historyItem.id}`} id={getItemId()} className='timeline-item pb-0'>
      <div className={classnames('timeline-marker p-0', !historyItem.comment && 'is-icon')}>
        {historyItem.comment && historyItem.authorName ? (
          <div className='subscription-comment__author-name'>
            <UserNameAndAvatarComponent
              avatarUrl={historyItem.authorAvatarUrl ?? undefined}
              name={historyItem.authorName}
              hideAvatar={!hasComment}
              height='24px'
              width='24px'
              hideName
            />
          </div>
        ) : (
          renderAuditLogIcon()
        )}
      </div>
      <div className='timeline-content' data-testid='timeline-content'>
        {historyItem.comment ?? historyItem.numberOfReplies ? (
          <SubscriptionCommentFeature
            showNotification={showNotification}
            subscription={subscription}
            history={historyItem}
            documents={subscriptionDocumentsCache || []}
            index={index}
            actionMenuIndex={actionMenuIndex}
            setActionMenuIndex={setActionMenuIndex}
            showModal={showModal}
            closeModal={closeModal}
            isEditingComment={isEditingComment}
            handleEditComment={handleEditComment}
            cancelEditComment={cancelEditComment}
            shouldEditComment={Boolean(editingCommentIndex)}
          />
        ) : (
          <div className='comment-item comment-item--transparent'>
            <div className='comment-text subscription-timeline__item'>{renderAuditLogDescription()}</div>
            {renderAuthorWithFormattedDate()}
          </div>
        )}
      </div>
    </div>
  );
});
