import { useQuery, UseQueryResult } from 'react-query';
import { getRequestInitiativeTickets } from 'shared/logic/requests.logic';
import { Initiative } from 'shared/models';

export const useRequestInitiativesData = (
  requestId: string | number,
  isEnabled: boolean
): UseQueryResult<Initiative[], unknown> =>
  useQuery(['request-initiatives', requestId], () => getRequestInitiativeTickets(requestId), {
    enabled: isEnabled,
    refetchOnWindowFocus: false,
  });
