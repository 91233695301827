import { Button, Divider, Grid, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { Subscription } from 'shared/models';
import { useAddRequestDrawer } from 'views/requests/hooks/use-add-request-drawer';

import {
  RequestHistoryContainer,
  RequestHistoryContent,
  RequestHistoryHeader,
  RequestHistoryHeaderActions,
  RequestHistoryHeaderInfo,
} from './request-history.style';

const SUCCESS_ICON = 'check_circle';

interface RequestHistoryProps {
  subscription: Subscription;
}

export const RequestHistoryComponent: React.FC<RequestHistoryProps> = ({
  subscription: { requestApprovedAt, requestId },
}) => {
  const { t } = useTranslation();
  const { onAddRequestDrawer } = useAddRequestDrawer();

  return (
    <RequestHistoryContainer>
      <RequestHistoryHeader>
        <Grid container>
          <RequestHistoryHeaderInfo item xs={6}>
            <Typography variant='h3'>{t('subscription_detail_view:details_view.request_history.title')}</Typography>
            <Typography variant='small'>
              {`${t('subscription_detail_view:details_view.request_history.approved_on')}: ${
                requestApprovedAt ? format(new Date(requestApprovedAt), 'do MMM yyyy') : null
              }.`}
            </Typography>
            <Icon size='small'>{SUCCESS_ICON}</Icon>
          </RequestHistoryHeaderInfo>
          <RequestHistoryHeaderActions item xs={6}>
            <Button
              color='primary'
              size='small'
              variant='text'
              onClick={() => onAddRequestDrawer(`#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`)}>
              {t('subscription_detail_view:details_view.request_history.view_btn')}
            </Button>
          </RequestHistoryHeaderActions>
        </Grid>
      </RequestHistoryHeader>
      <Divider />
      <RequestHistoryContent>
        <Typography variant='body'>
          {t('subscription_detail_view:details_view.request_history.content_message')}
        </Typography>
      </RequestHistoryContent>
    </RequestHistoryContainer>
  );
};
