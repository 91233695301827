import { Box, Button, Tooltip } from '@mui/material';
import { Icon } from 'asteroids';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MethodTypes } from 'shared/common.definitions';
import { formatFullDate } from 'shared/helpers/common.helper';
import { DropdownMenuIcon } from 'shared/helpers/styles.helper';
import { Alert } from 'shared/models';

import { DropdownComponent } from '..';
import { SubscriptionAlertSummaryItemProps } from './subscription-alert-summary-item.component.props';

export const SubscriptionAletSummaryItemComponent: FC<SubscriptionAlertSummaryItemProps> = (props) => {
  const { alert, openCreateEditAlertModal, openDeleteAlertModal, setSelectedAlert } = props;
  const { t } = useTranslation();
  const dropDownComponentRef = useRef<{ handleToggle: () => void }>();

  const tooltipStatus = 'This reminder was created automatically';

  const alertActionsSection = (alert: Alert) => {
    return (
      <DropdownComponent
        ref={dropDownComponentRef}
        hasPadding
        menuMinWidth='11rem'
        menuTriggerElement={
          <DropdownMenuIcon
            disableRipple
            data-testid='dropdown-action-menu-button'
            onClick={() => {
              dropDownComponentRef.current?.handleToggle();
              setSelectedAlert(alert);
            }}>
            <Icon>more_vert</Icon>
          </DropdownMenuIcon>
        }>
        <Box display='flex' flexDirection='column'>
          <Button
            variant='text'
            color='secondary'
            data-testid='edit-button'
            onClick={() => openCreateEditAlertModal(MethodTypes.PATCH)}>
            {t('alerts_view:alert_item_component.action_buttons_section.edit_alert_button_text')}
          </Button>
          <Button
            variant='text'
            data-testid='delete-button'
            color='error'
            onClick={() => openDeleteAlertModal(MethodTypes.DELETE)}>
            {t('alerts_view:alert_item_component.action_buttons_section.delete_alert_button_text')}
          </Button>
        </Box>
      </DropdownComponent>
    );
  };

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      className='alert-wrapper'
      data-testid='alert-wrapper'
      key={`alert-wrapper-${alert.id}`}>
      <section className='alert-item'>
        <span className='date'>
          {formatFullDate(alert.dateFormatted?.toString()).split(' ').splice(0, 2).join(' ')}:
        </span>

        <span className='alert-text'>
          <span>{alert.title || 'Renewal reminder'}</span>
          {String(alert.category) === '0' && (
            <Tooltip title={tooltipStatus} arrow>
              <span className='is-pointer'>
                <Icon size='small'>help</Icon>
              </span>
            </Tooltip>
          )}
        </span>
      </section>
      {alertActionsSection(alert)}
    </Box>
  );
};
