import { User } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import { useCheckUserPermission, useModalAndNotification, useUser } from 'hooks/index';
import { SOURCE_NAME, TYPE } from 'libs/constants/tool-usage.constants';
import { PERMISSION } from 'libs/enums';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  BaseUsageAnalyticsTrackingParams,
  trackConnected,
  trackConnectedError,
  trackDisconnected,
} from 'shared/logic/event-tracking/usage-analytics.events';
import { RootState } from 'shared/store';
import { hide as hideDrawer } from 'shared/store/common/appDrawer.slice';
import {
  connectToGoogleAccounts,
  disconnectFromGoogle,
  toggleUsageAnalytics,
} from 'shared/store/common/googleConnect.slice';
import { useAppSelector } from 'shared/store/hooks';

import { ManageSSOCard, ManageSSOCardFooter } from '../components';

export const ManageGoogleSSOConnection: FC = () => {
  const hasManageIntegrationsPermission = useCheckUserPermission(PERMISSION.MANAGE_INTEGRATIONS);
  const { t } = useTranslation();
  const user = useUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const { onCloseAsteroidsModal: closeModal, onShowAsteroidsModal: showModal } = useModalAndNotification();
  const handleCloseDrawer = useCallback(() => dispatch(hideDrawer()), [dispatch]);
  const { refetch: refetchConnectionState } = useQuery('google-sso-connection-state');
  const { isConnected, ssoProviderName } = useAppSelector(
    (state: RootState) =>
      state.common.appDrawer.data as {
        isConnected: boolean;
        ssoProviderName: string;
      }
  );

  if (!user?.companyId) {
    return null;
  }

  const baseTrackingEventProps: BaseUsageAnalyticsTrackingParams = {
    analyticsProviderName: ssoProviderName,
    analyticsProviderType: TYPE.SSO,
    analyticsSourceName: SOURCE_NAME.GOOGLE_SSO,
    companyId: user.companyId,
    userId: user.id ?? '',
  };

  const showSuccessMessage = () => {
    const message = (
      <Typography variant='body'>{t('connect_view:tab_section.google_connect.connection_success_message')}</Typography>
    );

    showModal?.({
      confirmButtonColor: 'secondary',
      confirmButtonText: 'Close',
      confirmButtonVariant: 'text',
      message,
      onConfirmButtonClick: closeModal,
      title: t('connect_view:tab_section.google_connect.connection_success_title'),
      titleIcon: 'check_circle',
      width: 'small',
    });
  };

  const handleConnect = (enableUsageAnalytics: boolean) => {
    if (!hasManageIntegrationsPermission) {
      return;
    }

    dispatch(
      connectToGoogleAccounts({
        enableUsageAnalytics,
        showSuccessMessage: () => {
          showSuccessMessage();
        },
        trackConnected: () => {
          trackConnected(baseTrackingEventProps, location.pathname, enableUsageAnalytics);
        },
        trackConnectedError: (errorMessage: string) => {
          trackConnectedError(
            {
              ...baseTrackingEventProps,
              errorMessage,
            },
            location.pathname,
            enableUsageAnalytics
          );
        },
        userData: user as User,
      })
    );

    handleCloseDrawer();
  };

  const handleDisconnect = (isEnabledUsageAnalytics: boolean) => {
    if (!hasManageIntegrationsPermission) {
      return;
    }

    dispatch(disconnectFromGoogle({ refetch: refetchConnectionState, userData: user as User }));
    trackDisconnected(baseTrackingEventProps, location.pathname, isEnabledUsageAnalytics);
  };

  const handleToggleUsageAnalytics = (isEnabled: boolean) => {
    dispatch(
      toggleUsageAnalytics({
        companyId: user.companyId as string,
        enableUsageAnalytics: isEnabled,
        refetch: refetchConnectionState,
      })
    );
    if (isConnected) {
      trackConnected(baseTrackingEventProps, location.pathname, isEnabled);
    }
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', mt: 8, mx: 5 }}>
      <ManageSSOCard onToggleUsageAnalytics={handleToggleUsageAnalytics} onConnect={handleConnect} />
      <ManageSSOCardFooter onDisconnect={handleDisconnect} />
    </Box>
  );
};
