import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { MultiRequestToolAlert } from 'components/drawer-workflow-request/components/multi-requests-tool-alert/multi-request-tool-alert.component';
import { NumberInput } from 'components/drawer-workflow-request/components/number-input/number-input.component';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { VendorSelectAsyncComponent } from 'components/vendor-select-async/vendor-select-async.component';
import { MultiToolRequestToolDto } from 'libs/dtos';
import { DEFAULT_CURRENCY, REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Currency, CurrencyOptions, REQUEST_STATUS } from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { trackMultitoolInitiativeToolAdded } from 'shared/logic/event-tracking/workflow-requests.events';
import {
  addInitiativeTool,
  checkSubscriptionStatus,
  checkToolRequestStatus,
  editRequest,
  getRequestDetails,
} from 'shared/logic/requests.logic';
import { Company, RequestPayload, RequestResponse, RequestWorkflowItem, Subscription, VendorType } from 'shared/models';
import { AppUrl } from 'src/constants/appurl';

import { AddNewToolFormProps } from './add-new-tool-form.component.props';
import { defaultFormValues, formValidationSchema } from './add-new-tool-form.validation';

export const AddNewToolFormComponent: React.FC<AddNewToolFormProps> = ({
  closeDialogAndOpenDrawer,
  closeDialogAndOpenToolAccordion,
  parentRequestId,
  refetchData,
  showNotification,
  tool,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [existingRequestOrSubscription, setExistingRequestOrSubscription] = useState<
    RequestWorkflowItem | Partial<Subscription> | null
  >(null);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  const isEditMode = !!tool;

  const company = queryClient.getQueryData<Company>('company');
  const requests = queryClient.getQueryData<RequestWorkflowItem[]>('workflow-requests');
  const subscriptions = queryClient.getQueryData<Subscription[]>('subscriptions');

  const isAddToolButtonDisabled =
    Boolean(existingRequestOrSubscription) &&
    ((existingRequestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.ACTIVE ||
      (existingRequestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.APPROVED ||
      Boolean((existingRequestOrSubscription as Subscription).id) ||
      (existingRequestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED);

  const { isLoading: isUpdatingTool, mutateAsync: updateTool } = useMutation(
    (payload: RequestPayload) => editRequest(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Initiative tool update error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async () => {
        await refetchData?.();
        showNotification?.(
          t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.update_success_message'
          ),
          NotificationAlertType.Success
        );
        closeDialogAndOpenToolAccordion?.();
      },
    }
  );

  const { isLoading: isAddingTool, mutateAsync: addTool } = useMutation(
    (payload: MultiToolRequestToolDto) => addInitiativeTool(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Initiative tool add error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async (data: RequestResponse) => {
        await refetchData?.();

        const requestDetails = await queryClient.fetchQuery('workflow-request-details', () =>
          getRequestDetails(data.id)
        );
        const toolName =
          typeof getValues().tool === 'object' ? (getValues().tool as VendorType)?.name : (getValues().tool as string);
        trackMultitoolInitiativeToolAdded({
          companyId: company?.id,
          companyName: company?.name,
          initiativeId: String(requestDetails.requestId),
          initiativeName: requestDetails.name || '',
          initiativeType:
            requestDetails.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL ? 'renewal' : 'newPurchase',
          toolName,
        });

        showNotification?.(
          t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.add_success_message'
          ),
          NotificationAlertType.Success
        );
        closeDialogAndOpenDrawer?.();
      },
    }
  );

  useEffect(() => {
    if (tool) {
      if (tool.licenseCents) setValue('cost', String(tool.licenseCents / 100));
      if (tool.description) setValue('notes', tool.description);
      if (tool.vendorName) setValue('tool', tool.vendorName);
    }
  }, [tool, setValue]);

  const handleOnSubmit = () => {
    trigger();

    if (isValid) {
      const { cost, notes, tool: formTool } = getValues();

      if (isEditMode && tool) {
        const payload = {
          description: notes,
          id: Number(tool.requestId),
          licenseCents: Number(cost) * 100,
        };

        updateTool(payload);
      } else if (!isEditMode && parentRequestId && formTool) {
        const payload = {
          cost: Number(cost) * 100,
          description: notes,
          parentRequestId,
          tool: { ...(typeof formTool === 'string' ? { toolName: formTool } : { vendorId: Number(formTool.id) }) },
        };

        addTool(payload);
      }
    }
  };

  const handleOnToolChange = (value: VendorType | string | null) => {
    setValue('tool', value);
    trigger();

    if (value && typeof value === 'object') {
      const existingRequest = checkToolRequestStatus(value, requests);
      const existingSubscription = checkSubscriptionStatus(value, subscriptions);

      if (existingSubscription) {
        setExistingRequestOrSubscription(existingSubscription);
      } else if (existingRequest) {
        setExistingRequestOrSubscription(existingRequest);
      } else {
        setExistingRequestOrSubscription(null);
      }
    } else if (!value && existingRequestOrSubscription) {
      setExistingRequestOrSubscription(null);
    }
  };

  const handleToolAlertOnView = () => {
    let url = '';
    if ((existingRequestOrSubscription as Subscription).id) {
      url = `${AppUrl.getToolDetailsUrl(existingRequestOrSubscription as Partial<Subscription>)}`;
    } else if ((existingRequestOrSubscription as RequestWorkflowItem).parentRequestId) {
      url = `/requests#requestDetails${(existingRequestOrSubscription as RequestWorkflowItem).parentRequestId}`;
    } else if ((existingRequestOrSubscription as RequestWorkflowItem).requestId) {
      url = `/requests#requestDetails${(existingRequestOrSubscription as RequestWorkflowItem).requestId}`;
    }
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const renderToolField = () => {
    if (!isEditMode) {
      return (
        <Box display='flex' flexDirection='column' data-testid='tool-name'>
          <Typography variant='h3' mb={1} data-testid='tool-dialog-tool-name-heading'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.tool.label'
            )}
            *
          </Typography>
          <Controller
            name='tool'
            control={control}
            render={() => {
              return (
                <VendorSelectAsyncComponent
                  value={getValues().tool || undefined}
                  getValue={handleOnToolChange}
                  loadDefaultVendors
                  error={!!errors.tool}
                  helperText={
                    errors.tool
                      ? t(
                          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.tool.error_text'
                        )
                      : ''
                  }
                  placeholder={t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.tool.placeholder'
                  )}
                />
              );
            }}
          />
        </Box>
      );
    }
  };

  const renderToolAlert = () => {
    if (existingRequestOrSubscription) {
      return (
        <Box
          mt={2}
          key={
            (existingRequestOrSubscription as RequestWorkflowItem)?.requestId ||
            (existingRequestOrSubscription as Subscription)?.id
          }>
          <MultiRequestToolAlert requestOrSubscription={existingRequestOrSubscription} onView={handleToolAlertOnView} />
        </Box>
      );
    }
  };

  const renderCostField = () => (
    <Box display='flex' flexDirection='column'>
      <Typography variant='h3' mb={1} data-testid='tool-dialog-cost-heading'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.cost.label'
        )}
      </Typography>
      <Controller
        name='cost'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <NumberInput
              name='cost'
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.cost.placeholder'
              )}
              decimalScale={2}
              decimalSeparator='.'
              fixedDecimalScale
              thousandSeparator
              prefix={company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY}
              value={value || ''}
              onValueChange={(value) => onChange(value.floatValue)}
              onClear={() => onChange('')}
            />
          );
        }}
      />
    </Box>
  );

  const renderNotesField = () => (
    <Box display='flex' flexDirection='column'>
      <Typography variant='h3' mb={1} data-testid='tool-dialog-notes-heading'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.notes.label'
        )}
      </Typography>
      <Controller
        name='notes'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              id='notes'
              onChange={onChange}
              value={value}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.notes.placeholder'
              )}
              multiline
              rows={4}
              error={!!errors.notes}
              helperText={
                errors.notes
                  ? errors.notes.message
                  : t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.fields.notes.helper_text'
                    )
              }
            />
          );
        }}
      />
    </Box>
  );

  return (
    <Box m={1}>
      <Typography variant='h2' mb={2} data-testid={isEditMode ? 'edit-tool-dialog-title' : 'add-tool-dialog-title'}>
        {isEditMode
          ? t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.edit_title',
              { toolName: tool?.vendorName }
            )
          : t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.title'
            )}
      </Typography>
      <Stack direction='column' spacing={2}>
        {renderToolField()}
        {renderToolAlert()}
        {renderCostField()}
        {renderNotesField()}
      </Stack>
      <Stack direction='row-reverse' spacing={1} mt={4}>
        <Button
          data-testid='tool-dialog-confirm-button'
          color='primary'
          variant='contained'
          onClick={handleOnSubmit}
          disabled={isUpdatingTool || isAddingTool || isAddToolButtonDisabled}>
          {(isUpdatingTool || isAddingTool) && <CircularProgress color='inherit' size={20} />}
          {isEditMode &&
            !isUpdatingTool &&
            t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.actions.confirm_changes'
            )}
          {!isEditMode &&
            !isAddingTool &&
            t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.actions.add_tool'
            )}
        </Button>
        <Button color='secondary' variant='text' onClick={closeDialogAndOpenDrawer}>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.add_new_tool_dialog.actions.cancel'
          )}
        </Button>
      </Stack>
    </Box>
  );
};
