import { useEffectOnce } from 'hooks/useEffectOnce';

export const CREATE_TASK_PARAM = 'createTask';

export function useShouldOpenCustomTask(openModalCallback: () => void): void {
  return useEffectOnce(() => {
    const params = new URLSearchParams(window.location.search);
    const hasParamInLocation = params.has(CREATE_TASK_PARAM);

    if (hasParamInLocation && openModalCallback) {
      openModalCallback();

      // Remove the param from the url and append the rest of the query string, if any left.
      params.delete(CREATE_TASK_PARAM);
      const query = params.values.length ? `?${params.toString()}` : '';
      window.history.pushState({}, document.title, window.location.pathname + query);
    }
  });
}

export default useShouldOpenCustomTask;
