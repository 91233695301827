import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../../shared/theme';
import { DialogContext, DialogProps } from '../context/dialog.context';

interface IDialog {
  children: React.ReactNode;
}

export const DialogProvider: FC<IDialog> = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialog, setDialog] = useState<DialogProps>();
  const { t } = useTranslation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const openDialog = (dialog: DialogProps) => {
    setDialog(dialog);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setDialog(undefined);
  };

  return (
    <DialogContext.Provider
      value={{
        closeDialog,
        openDialog,
      }}>
      {children}
      {dialog && (
        <Dialog
          fullScreen={fullScreen}
          open={isDialogOpen}
          onClose={closeDialog}
          sx={{ '& .MuiDialog-paper': { p: '32px 24px', width: '444px' } }}
          data-testid='dialog'
          aria-labelledby='responsive-dialog-title'>
          <Box display='flex' alignItems='center'>
            {dialog.icon ? (
              dialog.icon
            ) : (
              <CheckCircleOutlineRoundedIcon
                sx={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontSize: '20px',
                  mr: '10px',
                }}
              />
            )}
            <DialogTitle
              sx={{ color: colors.darkGunmetal, fontFamily: 'inherit', fontWeight: '600', p: 0 }}
              id='responsive-dialog-title'>
              {dialog.title}
            </DialogTitle>
          </Box>

          <DialogContent sx={{ p: 0 }}>
            <DialogContentText
              sx={{ color: colors.black, fontFamily: 'inherit', p: 0, pb: '24px', pt: '32px' }}
              data-testid='dialog-body-text'>
              {dialog.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: 0 }}>
            <Button onClick={closeDialog} data-testid='dialog-close-btn' variant='text' color='secondary'>
              {t('common:dialog_component.close_button_text')}
            </Button>
            <Button
              data-testid='dialog-confirm-btn'
              variant='contained'
              color='primary'
              onClick={() => {
                dialog.onConfirm();
                closeDialog();
              }}>
              {dialog.confirmBtnText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};
