import { styled } from '@mui/material';
import { colors } from 'shared/theme';

export const AddNewSubscriptionWrapper = styled('section')(({ theme }) => ({
  '& .comment': {
    '&:focus-visible': {
      outlineColor: colors.primary,
    },
    borderColor: colors.alto,
    borderRadius: '0.25rem',
    fontFamily: 'inherit',
    fontSize: '1rem',
    lineHeight: '1rem',
    padding: '0.813rem 0.5rem',
    width: '100%',
  },
  '& .label': {
    '& .MuiTypography-body1': {
      color: '#27343A',
      fontSize: '16px',
      fontWeight: '500',
    },
    '& .mandatory': {
      color: colors.greyText,
      fontStyle: 'italic',
      fontWeight: '400',
      fontsize: '0.875rem',
      marginLeft: '0.3rem',
    },
  },
  '& .placeholderText': {
    color: `${colors.dustyGray} !important`,
    fontFamily: 'inherit !important',
  },
  '& .react-tags__search-input': {
    color: colors.greyText,
    height: '2.5rem',
  },
  '& .sub-label': {
    '& .MuiTypography-body1': {
      color: '#4B565B',
      fontSize: '0.875rem',
      fontWeight: '400',
    },
  },
  marginBottom: theme.spacing(5),
  width: '100%',
}));
