import { UnassignedToolOwnerIcon } from 'components/icons';
import { Subscription } from 'libs/models';
import { useTranslation } from 'react-i18next';
import { CSM_USER_EMAIL, UserRoles } from 'shared/common.definitions';
import { User } from 'shared/models';

type UseUsersListProps = {
  loggedInUser: User | null | undefined;
  users: User[];
  subscription?: Subscription;
  isRequester: boolean;
  name: string | null | undefined;
  avatarUrl?: string;
};

export const useUsersList = ({
  avatarUrl,
  isRequester,
  loggedInUser,
  name,
  subscription,
  users,
}: UseUsersListProps): User[] => {
  const { t } = useTranslation();

  const currentToolOwner: User = {
    avatarUrl,
    id: String(subscription?.ownerId),
    name: name ?? '',
  };

  const unAssignedToolOwner: User = {
    icon: <UnassignedToolOwnerIcon width='1.5rem' height='1.5rem' />,
    id: '-1',
    name: t('common:assign_tool_owner_feature.typeahead_component.unassign_tool_owner_text'),
  };

  const isAssignedToolOwner = !!name;

  if (users?.length > 0) {
    return [
      isAssignedToolOwner ? currentToolOwner : (loggedInUser as User),
      isAssignedToolOwner && unAssignedToolOwner,
      ...users
        .filter(
          (user: User) =>
            (isAssignedToolOwner ? user.name !== currentToolOwner?.name : user.id !== loggedInUser?.id) &&
            (!isRequester ? user.role !== UserRoles.Viewer : true)
        )
        .filter((user: User) => !user.email?.includes(CSM_USER_EMAIL))
        .sort((a: User, b: User) => String(a.name).localeCompare(String(b.name))),
    ].filter(Boolean) as User[];
  }

  if (users?.length === 1) return users;

  return [];
};
