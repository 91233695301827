import {
  Box,
  Checkbox,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { colors } from '../../theme/color.styles';
import { OrderType, RowType, TableProps } from './table.component.props';

const stickyCheckboxSX = {
  background: colors.background.light,
  left: 0,
  position: 'sticky',
  zIndex: 2,
};

export const Table: FC<TableProps> = ({
  bulkSelection,
  headCells,
  onBulkSelection,
  onSelectionChange,
  onSort,
  order,
  orderBy,
  rows,
  selectedRows: selectedRowsProp,
  stickyCheckbox,
  ...rest
}) => {
  const getExistingRowsFromSelectedOnes = useCallback(() => {
    if (Array.isArray(selectedRowsProp)) {
      return selectedRowsProp
        .map((row) => (typeof row === 'string' ? rows.find((item) => item.id === row) : row))
        .filter((item) => !!item) as RowType[];
    }
    return [];
  }, [rows, selectedRowsProp]);

  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(selectedRowsProp === true); // Whether all rows are selected or not
  const [selectedRows, setSelectedRows] = useState<RowType[]>(getExistingRowsFromSelectedOnes); // Initially selected row objects from rows property
  const [deselectedRows, setDeselectedRows] = useState<RowType[]>([]); // Rows that have been deselected after checking the bulk select all checkbox

  useEffect(
    () => setSelectedRows(getExistingRowsFromSelectedOnes()),
    [getExistingRowsFromSelectedOnes, rows, selectedRowsProp]
  );

  useEffect(() => (!bulkSelection ? setSelectedRows([]) : undefined), [bulkSelection]);

  const getSortIcon = (order: OrderType | undefined, orderBy: string | undefined, headCellId: string) => {
    if (orderBy === headCellId) {
      return (
        <Box component='span' sx={visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      );
    }
    return null;
  };

  const handleSelectAllClick = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setAllRowsSelected(checked);
      setSelectedRows([]);
      if (checked) {
        setDeselectedRows([]);
      }
      onBulkSelection?.(checked);
      onSelectionChange?.(checked || [], undefined, checked);
    },
    [onBulkSelection, onSelectionChange]
  );

  const handleRowSelect = useCallback(
    (row: RowType, checked: boolean) => {
      let newSelectedRows = selectedRows;

      if (allRowsSelected) {
        const deselectedRowIndex = deselectedRows.indexOf(row);

        if (checked && deselectedRowIndex !== -1) {
          setDeselectedRows(deselectedRows.filter((item) => item !== row));
        }

        if (!checked && deselectedRowIndex === -1) {
          deselectedRows.push(row);
          setDeselectedRows(deselectedRows.slice());
        }
      } else {
        const selectedRowIndex = selectedRows.indexOf(row);

        if (checked && selectedRowIndex === -1) {
          selectedRows.push(row);
          newSelectedRows = selectedRows.slice();
          setSelectedRows(newSelectedRows);
        }

        if (!checked && selectedRowIndex !== -1) {
          newSelectedRows = selectedRows.filter((item) => item !== row);
          setSelectedRows(newSelectedRows);
        }
      }

      row.onItemSelect?.(row.id, checked);
      onSelectionChange?.(allRowsSelected || newSelectedRows, row, checked);
    },
    [allRowsSelected, deselectedRows, onSelectionChange, selectedRows]
  );

  const isRowSelected = (row: RowType) =>
    allRowsSelected ? !deselectedRows.includes(row) : (bulkSelection && selectedRows?.includes(row)) || false;

  const renderRow = (row: RowType) => {
    const isSelected = isRowSelected(row);

    return (
      <TableRow
        key={row.id}
        hover={row.hover}
        data-testid={row.testId}
        selected={isSelected}
        onClick={(event) => row.onClick?.(event, row.id)}>
        {bulkSelection &&
          (row.noCheckbox ? (
            <TableCell />
          ) : (
            <TableCell padding='checkbox' sx={stickyCheckbox ? stickyCheckboxSX : {}}>
              <Checkbox
                checked={isSelected}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleRowSelect(row, evt.target.checked)}
              />
            </TableCell>
          ))}
        {row.rowCells.map(({ children, id, sticky = false, stickyProps, ...rest }) => {
          const stickyCellStyle = sticky ? { ...stickyProps, position: 'sticky' } : {};
          return (
            <TableCell key={id} {...rest} style={{ ...stickyCellStyle }}>
              {children}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const TableHead = () => {
    return (
      <MuiTableHead>
        <TableRow>
          {bulkSelection && (
            <TableCell padding='checkbox' sx={stickyCheckbox ? stickyCheckboxSX : {}}>
              <Checkbox
                indeterminate={!allRowsSelected && !!selectedRows.length}
                checked={allRowsSelected}
                onChange={handleSelectAllClick}
              />
            </TableCell>
          )}
          {headCells.map(({ id, label, minWidth, sortable = false, sticky = false, stickyProps, ...rest }) => {
            const stickyCellStyle = sticky ? { ...stickyProps, position: 'sticky' } : {};

            return (
              <TableCell key={id} style={{ minWidth, ...stickyCellStyle }} {...rest}>
                {sortable ? (
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'asc'}
                    onClick={() => onSort?.(id)}>
                    <Typography variant='label' onClick={() => onSort?.(id)}>
                      {label}
                    </Typography>
                    {getSortIcon(order, orderBy, id)}
                  </TableSortLabel>
                ) : (
                  <Typography variant='label' onClick={() => onSort?.(id)}>
                    {label}
                  </Typography>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </MuiTableHead>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const TableBody = () => {
    return <MuiTableBody>{rows.map(renderRow)}</MuiTableBody>;
  };

  return (
    <MuiTable {...rest}>
      <TableHead />
      <TableBody />
    </MuiTable>
  );
};

export default Table;
