import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { FC, lazy } from 'react';
import { Route } from 'react-router';
import { UserRoles } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { RoutedProps } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { NAVIGATION_URLS } from 'src/constants/navigation';
import { ErrorView } from 'views/error';

import { BenchmarkRoutes } from './benchmark.routes';

const SastriCloudView = lazy(() => import('../views/sastricloud/sastricloud.view'));
const SavingsView = lazy(() => import('../views/savings/savings.view'));

export const OptimizationRoutes: FC<RoutedProps> = (props) => {
  const userRole = useAppSelector((state) => state.authentication.user?.role);
  const isViewerRole = userRole === UserRoles.Viewer;

  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();
  const isSastriCloudFeatureEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.SASTRICLOUD);
  const isSastriCloudFeaturePurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.SASTRICLOUD);
  return (
    <>
      <BenchmarkRoutes
        {...props}
        exact
        path={[
          NAVIGATION_URLS.PRICING_BENCHMARKS,
          NAVIGATION_URLS.NEW_PRICING_BENCHMARK,
          NAVIGATION_URLS.NEW_PRICING_BENCHMARK_RESULTS,
        ]}
      />
      <Route exact path={NAVIGATION_URLS.SAVINGS}>
        {!isViewerRole ? <SavingsView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.SASTRICLOUD}>
        {!isViewerRole && (isSastriCloudFeatureEnabled || isSastriCloudFeaturePurchasable) ? (
          <SastriCloudView {...props} />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
    </>
  );
};
