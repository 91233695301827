import { Badge, BadgeProps } from '@mui/material';

import { StatusDotProps } from './status-dot.component.props';

export const StatusDot: React.FC<StatusDotProps> = ({ status = 'primary' }) => {
  let badgeColor: BadgeProps['color'];

  switch (status) {
    case 'inProgress':
      badgeColor = 'info';
      break;
    case 'inactive':
      badgeColor = 'text';
      break;
    default:
      badgeColor = status;
  }

  return <Badge sx={{ zIndex: 0 }} color={badgeColor} variant='dot' className='status-dot' />;
};

export default StatusDot;
