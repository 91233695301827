import { Box } from '@mui/material';
import { DetailsItem } from 'components/drawer-workflow-request-details/components/details-item/details-item.component';
import { formatText } from 'components/drawer-workflow-request-details/components/details-item/details-item.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RenewalOtherProps } from 'shared/models/request-workflow.model';

export const RenewalOtherDetails: FC<RenewalOtherProps> = ({ requestDetails }) => {
  const { t } = useTranslation();

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.renewal_involvement_details.renewal_other_labels';

  return (
    <Box mb={1}>
      <DetailsItem title={t(`${pathToTranslation}.other_details`)} content={formatText(requestDetails || '-')} />
    </Box>
  );
};
