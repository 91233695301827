import { sub } from 'date-fns';
import * as yup from 'yup';

import { alternativeToolsDefaultValue } from './renewal-multitool-form.constants';
import { FormValues } from './renewal-multitool-form.props';

export const defaultFormValues: FormValues = {
  alternativeTools: alternativeToolsDefaultValue,
  collaborators: [],
  cost: '',
  description: '',
  dueDate: undefined,
  numberOfLicenses: '',
  paymentFrequency: null,
  toolName: null,
};

export const formValidationSchema = yup.object().shape({
  dueDate: yup.date().min(sub(new Date(), { days: 1 })),
  toolName: yup
    .object()
    .shape({
      id: yup.string(),
      label: yup.string(),
      prefix: yup.object(),
    })
    .required(),
});
