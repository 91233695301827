import { Box } from '@mui/material';
import usageAnalyticsProcessing from 'assets/images/tool-usage-analytics/usage-analytics-processing.svg';
import { StatusDisplay } from 'components/design-system/status-display/status-display.component';
import { TimeLineChart } from 'components/time-line-chart';
import { TimeLineSpan } from 'components/time-line-chart/time-line-chart-props';
import { useTranslation } from 'react-i18next';
import { getLicenseUsageGraphData } from 'shared/logic/tool-usage-analytics.logic';

import { UsageAnalyticsCardGraphProps } from '../usage-analytics.props';

export const UsageAnalyticsCardGraph = (props: UsageAnalyticsCardGraphProps) => {
  const { activeUsers, isProcessing, totalUsers } = props;

  const { t } = useTranslation();

  const hasSufficientData = totalUsers.length >= 1;

  const data = getLicenseUsageGraphData(activeUsers, totalUsers);

  if (isProcessing || !hasSufficientData)
    return (
      <StatusDisplay
        icon={usageAnalyticsProcessing}
        title={t('subscription_detail_view:usage_analytics_section:graph:processing_title')}
        description={t('subscription_detail_view:usage_analytics_section:graph:processing_description')}
      />
    );

  return (
    <Box sx={{ paddingBottom: 3 }}>
      <TimeLineChart
        data={data}
        lineColors={['#7A7A7A', '#6663FF']}
        xAxisTimeUnit={1}
        xAxisTimeSpan={TimeLineSpan.WEEK}
        xAxisFormat='%Y-%m-%d'
      />
    </Box>
  );
};
