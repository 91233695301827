import { alpha, styled } from '@mui/material/styles';
import { colors } from 'asteroids';

const PREFIX = 'RichTextEditorComponent';

export const classes = {
  editorContainer: `${PREFIX}-editorContainer`,
  editorDisabled: `${PREFIX}-editorDisabled`,
  editorError: `${PREFIX}-editorError`,
  editorFocused: `${PREFIX}-editorFocused`,
  helperText: `${PREFIX}-helperText`,
  helperTextError: `${PREFIX}-helperTextError`,
};

export const RichTextEditorRoot = styled('div')({
  [`&.${classes.editorContainer}`]: {
    '& .ql-container': {
      '& p': {
        fontSize: '0.875rem',
      },
      background: colors.background.paper,
      borderRadius: '0.25rem !important',
    },
    '& .ql-editor': {
      '& .mention': {
        background: alpha(colors.primary.main, 0.08),
        color: colors.primary.main,
      },
      '& li.ql-indent-1': {
        paddingLeft: '1.8rem !important',
      },
      '& li.ql-indent-2': {
        paddingLeft: '2.5rem !important',
      },
      '& li.ql-indent-3': {
        paddingLeft: '3.5rem !important',
      },
      '& li.ql-indent-4': {
        paddingLeft: '4.5rem !important',
      },
      '& li.ql-indent-5': {
        paddingLeft: '5.5rem !important',
      },
      '& li.ql-indent-6': {
        paddingLeft: '6.5rem !important',
      },
      '& li.ql-indent-7': {
        paddingLeft: '7.5rem !important',
      },
      '& li.ql-indent-8': {
        paddingLeft: '8.5rem !important',
      },
      '& ol li': {
        paddingLeft: '0.8rem',
      },
      '& ol, ul': {
        fontSize: '0.875rem',
        paddingLeft: 0,
      },
      '& ul li': {
        paddingLeft: '0.8rem',
      },
      '&.ql-blank::before': {
        fontSize: '0.875rem',
      },
      maxHeight: '20rem',
      minHeight: '5.625rem',
      overflowY: 'auto',
      resize: 'vertical',
    },
    '& .ql-list-container': {
      '& .ql-mention-list-item.selected': {
        background: alpha(colors.primary.main, 0.08),
      },
      backgroundColor: colors.background.paper,
      border: `1px solid ${colors.divider}`,
      borderRadius: '4px',
      boxShadow: '0 0.125rem 0.75rem 0 rgb(30 30 30 / 8%)',
      height: '16.5625rem',
      overflowY: 'auto',
      width: 'max-content',
      zIndex: 9001,
    },
    '& .ql-snow.ql-toolbar': {
      borderTopLeftRadius: '0.25rem',
      borderTopRightRadius: '0.25rem',
    },
    '& .ql-tooltip': {
      marginLeft: '7.5rem',
    },
    position: 'relative',
  },
  [`& .${classes.editorDisabled}`]: {
    '& .ql-container': {
      border: `1px solid ${colors.divider} !important`,
    },
    '& .ql-editor': {
      overflowY: 'hidden',
    },
  },
  [`& .${classes.editorError}`]: {
    '& .ql-container': {
      border: `1px solid ${colors.error.main} !important`,
    },
  },
  [`& .${classes.editorFocused}`]: {
    '& .ql-container': {
      borderTopLeftRadius: '0 !important',
      borderTopRightRadius: '0 !important',
    },
  },
  [`& .${classes.helperText}`]: {
    color: colors.text.secondary,
    marginTop: '0.5rem !important',
    textIndent: '0.75rem',
  },
  [`& .${classes.helperTextError}`]: {
    color: colors.error.main,
  },
});
