import { Link, type LinkProps } from '@mui/material';

import { Icon } from '../icon/icon.component';

interface ActionableLinkProps extends LinkProps {
  tooltip?: string;
  href: string;
  icon?: boolean | string;
}

export const ActionableLink = (props: ActionableLinkProps) => {
  const { children, href = '', icon = false, rel, target, ...rest } = props;

  const hrefProp = href || '#';
  let relProp;
  let targetProp;

  if (href === '#') {
    targetProp = target;
    relProp = rel;
  } else {
    targetProp = '_blank';
    relProp = 'noreferrer';
  }

  return (
    <span>
      <Link target={targetProp} rel={relProp} {...rest} href={hrefProp}>
        {children}
        {(icon && (
          <>
            {' '}
            <Icon
              key='icon'
              sx={{
                color: 'inherit',
                display: 'inline',
                fontSize: 'inherit',
                position: 'relative',
                top: '2px',
              }}>
              {typeof icon === 'string' ? icon : 'open_in_new'}
            </Icon>
          </>
        )) ||
          null}
      </Link>
    </span>
  );
};
