import { Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface VendorInsightReadWatchButtonProps {
  onClick?: () => void;
}
export const VendorInsightReadWatchButton: FC<VendorInsightReadWatchButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Button variant='text' color='primary' onClick={handleClick} data-testid='vendor-knowledge-item-open-button'>
      {t('subscription_detail_view:tabs_component_section.insight_tab.learn_more')}
    </Button>
  );
};
