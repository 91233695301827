import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import usageAnalyticsLevelMain from 'assets/images/tool-usage-analytics/usage-analytics-level-main.svg';
import usageAnalyticsLevelProcessing from 'assets/images/tool-usage-analytics/usage-analytics-level-processing.svg';
import { Alert, Icon } from 'asteroids';
import { useUsageAnalyticsLicenseHoldersModal } from 'components/subscription-details/hook/use-usage-analytics-license-holders-modal';
import { SOURCE_NAME, TYPE } from 'libs/constants/index';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import {
  getLastLicenseUsageDataCount,
  getLicenseUsageLevel,
  getLicenseUsagePercentage,
  getUsersV2,
} from 'shared/logic/tool-usage-analytics.logic';
import { Company } from 'shared/models';
import { LicenseUsageLevel, ToolUsageActivityDataV2 } from 'shared/models/tool-usage-analytics.model';
import { useUser } from 'src/app/hooks/useUser';

import { UsageAnalyticsCardConnectedProps } from '../usage-analytics.props';
import { CardContainer, CardContent, CardHeader, ExternaLink, Line, TooltipList } from '../usage-analytics.styles';
import { UsageAnalyticsLicenseHoldersError } from '../usage-analytics-license-holders/usage-analytics-license-holders-error.component';
import { UsageAnalyticsCardGraph } from './usage-analytics-card-graph.component';

export const UsageAnalyticsCardConnected = (props: UsageAnalyticsCardConnectedProps) => {
  const {
    data,
    isDirectIntegration,
    isDirectUsageAnalyticsAvailable,
    onConnect,
    showNotification,
    subscriptionId,
    subscriptionVendorName,
    toolName,
    toolUsageProvider,
  } = props;

  const userFilter = (user: { type: TYPE; source: SOURCE_NAME }) =>
    user.type === toolUsageProvider.type && user.source === toolUsageProvider.toolUsageSource;

  const filteredData: ToolUsageActivityDataV2 = {
    ...data,
    activeUsers: toolUsageProvider.hideActiveUsers ? [] : data.activeUsers.filter(userFilter),
    totalUsers: data.totalUsers.filter(userFilter),
  };

  const { activeUsers, isProcessing, totalUsers } = filteredData;
  const { activeUsersCount, totalUsersCount } = getLastLicenseUsageDataCount(activeUsers, totalUsers);

  const queryClient = useQueryClient();
  const company = queryClient.getQueryData('company') as Company;
  const user = useUser();

  const [isLicenseHoldersDialogVisible, setIsLicenseHoldersDialogVisible] = useState(false);

  const {
    data: licenseHolders,
    isError: isLicenseHoldersError,
    refetch: refetchLicenseHolders,
  } = useQuery('all-users', () => getUsersV2(String(company?.id), subscriptionId, userFilter), {
    enabled: company?.id !== undefined && !toolUsageProvider.hideActiveUsers,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const [openLicenseHoldersModal] = useUsageAnalyticsLicenseHoldersModal({
    data: licenseHolders,
    showNotification,
    subscriptionId,
    trackClickData: {
      analyticsProviderName: toolName,
      analyticsProviderType: isDirectIntegration ? TYPE.DIRECT : TYPE.SSO,
      analyticsSourceName: toolUsageProvider.toolUsageSource,
      companyId: company?.id ?? '',
      isDirectAnalyticsAvailable: isDirectUsageAnalyticsAvailable || isDirectIntegration,
      subscriptionId,
      toolName: subscriptionVendorName,
      userId: user.id ?? '',
    },
  });

  const openModal = useCallback((): void => {
    openLicenseHoldersModal();
    setIsLicenseHoldersDialogVisible(true);
  }, [openLicenseHoldersModal]);

  const disableLicenseHolderModal = isProcessing || !licenseHolders || licenseHolders?.length === 0;

  const { t } = useTranslation();
  const theme = useTheme();

  const EMPTY_DASH = '-';

  const usageVolume = useMemo((): string => {
    const usageLevel = getLicenseUsageLevel(activeUsersCount, totalUsersCount);
    const usagePercentage = getLicenseUsagePercentage(activeUsersCount, totalUsersCount);
    switch (usageLevel) {
      case LicenseUsageLevel.LOW:
        return t('subscription_detail_view:usage_analytics_section:low_usage', { usagePercentage });
      case LicenseUsageLevel.MEDIUM:
        return t('subscription_detail_view:usage_analytics_section:medium_usage', { usagePercentage });
      case LicenseUsageLevel.HIGH:
        return t('subscription_detail_view:usage_analytics_section:high_usage', { usagePercentage });
      default:
        return EMPTY_DASH;
    }
  }, [activeUsersCount, totalUsersCount, t]);

  const disclaimer = useMemo(() => {
    if (!isDirectIntegration) {
      if (!toolUsageProvider.hideActiveUsers) {
        return t('subscription_detail_view:usage_analytics_section:disclaimer_auth_category', {
          toolName,
        });
      }
      return;
    }
    if (['Asana'].includes(toolName)) {
      return t('subscription_detail_view:usage_analytics_section:disclaimer_activity_category', { toolName });
    }
    return t('subscription_detail_view:usage_analytics_section:disclaimer_login_category', { toolName });
  }, [t, toolName, isDirectIntegration]);

  const warning = useMemo(() => {
    if (!isDirectIntegration) {
      if (toolUsageProvider.hideActiveUsers) {
        if (isDirectUsageAnalyticsAvailable) {
          return (
            <Alert
              severity='info'
              description={t('subscription_detail_view:usage_analytics_section:disclaimer_no_activity_long', {
                toolName,
              })}
              actionText={t('subscription_detail_view:usage_analytics_section:connect_button_text')}
              title={t('subscription_detail_view:usage_analytics_section:direct_connection_available_text')}
              onAction={onConnect}
              actionIcon={false}
            />
          );
        }
        return t('subscription_detail_view:usage_analytics_section:disclaimer_no_activity', {
          toolName,
        });
      }
    }
  }, [t, toolName, toolUsageProvider, isDirectIntegration, isDirectUsageAnalyticsAvailable]);

  return (
    <CardContainer data-testid='usage-analytics-details-card'>
      <CardHeader sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3'>{t('subscription_detail_view:usage_analytics_section:title')}</Typography>
        {!toolUsageProvider.hideActiveUsers && (
          <Button
            variant='text'
            color='primary'
            disabled={disableLicenseHolderModal}
            onClick={openModal}
            sx={{ textTransform: 'uppercase' }}>
            {t('subscription_detail_view:usage_analytics_section:view_user_details')}
          </Button>
        )}
      </CardHeader>
      <CardContent
        sx={{
          padding: 3,
          paddingBottom: 0,
        }}>
        {warning}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          {!toolUsageProvider.hideActiveUsers && (
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: 1 }}>
              <img
                src={isProcessing ? usageAnalyticsLevelProcessing : usageAnalyticsLevelMain}
                alt='usage-analytics-level'
              />
              <Box display='flex' flexDirection='column' gap={0}>
                <Box display='flex'>
                  <Typography variant='section' sx={{ display: 'inline-block', margin: 'auto 0' }}>
                    {isProcessing ? EMPTY_DASH : usageVolume}
                  </Typography>
                  <Tooltip
                    placement='right'
                    title={
                      <Box sx={{ fontWeight: 300, textAlign: 'left', whiteSpace: 'pre-line' }}>
                        <Typography variant='small'>
                          {t('subscription_detail_view:usage_analytics_section:usage_tooltip:title')}
                        </Typography>
                        <TooltipList>
                          <li>{t('subscription_detail_view:usage_analytics_section:usage_tooltip:low')}</li>
                          <li>{t('subscription_detail_view:usage_analytics_section:usage_tooltip:medium')}</li>
                          <li>{t('subscription_detail_view:usage_analytics_section:usage_tooltip:high')}</li>
                        </TooltipList>
                      </Box>
                    }>
                    <Box sx={{ height: theme.spacing(3), ml: 1 }}>
                      <Icon>help_outline</Icon>
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant='body'>
                  {isProcessing
                    ? `${EMPTY_DASH}/${EMPTY_DASH}`
                    : `${activeUsersCount !== undefined ? activeUsersCount : EMPTY_DASH}/${
                        totalUsersCount !== undefined ? totalUsersCount : EMPTY_DASH
                      }`}{' '}
                  {t('subscription_detail_view:usage_analytics_section:active_users')}
                </Typography>
              </Box>
            </Box>
          )}
          {toolUsageProvider.hideActiveUsers && (
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: 1 }} />
          )}
          {!isProcessing && (
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1, height: 'fit-content', mt: 2 }}>
              <Line sx={{ backgroundColor: theme.palette.text.secondary }} />
              <Typography variant='body' color={theme.palette.text.secondary}>
                {t('subscription_detail_view:usage_analytics_section:total_assigned_label')}
              </Typography>
              {activeUsersCount !== undefined && (
                <>
                  <Line sx={{ backgroundColor: theme.palette.info.main }} />
                  <Typography variant='body' color={theme.palette.text.secondary}>
                    {t('subscription_detail_view:usage_analytics_section:active_users_label')}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
        {disclaimer && (
          <Box sx={{ border: 0, display: 'flex', padding: `${theme.spacing(3)} 0` }}>
            <Typography variant='body' color={theme.palette.text.secondary}>
              {disclaimer}
              {toolUsageProvider.learnMoreUrl ? (
                <>
                  {' '}
                  {t('subscription_detail_view:usage_analytics_section:disclaimer_read_more')}
                  <a href={toolUsageProvider.learnMoreUrl as string} target='_blank' rel='noreferrer'>
                    <ExternaLink display='inline' variant='body'>
                      {t('subscription_detail_view:usage_analytics_section:learn_more_url')}
                    </ExternaLink>
                  </a>
                </>
              ) : (
                '.'
              )}
            </Typography>
          </Box>
        )}
      </CardContent>
      <UsageAnalyticsCardGraph
        isProcessing={isProcessing || false}
        activeUsers={filteredData.activeUsers}
        totalUsers={filteredData.totalUsers}
      />
      {isLicenseHoldersError && isLicenseHoldersDialogVisible && (
        <UsageAnalyticsLicenseHoldersError
          onClose={() => setIsLicenseHoldersDialogVisible(false)}
          onConfirm={() => refetchLicenseHolders()}
        />
      )}
    </CardContainer>
  );
};
