import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { AutocompleteOption } from 'asteroids';
import { CurrencySelectInputComponent } from 'components/currency-select-input/currency-select-input.component';
import { DatePickerComponent, NumberFormatInput } from 'components/index';
import { SubscriptionAutocomplete } from 'components/subscription-autocomplete';
import { SpendModalHeader } from 'features/subscription/subscription-spend-review-modal/spend-modal-header/spend-modal-header.component';
import { SPEND_CATEGORIES } from 'libs/enums';
import { getImportSourceName, SpendSourceName } from 'libs/util/invoiceHelper.util';
import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Currency, CurrencyOptions } from 'shared/common.definitions';
import { convertAmountFromCents, downloadFile, formatFullDate } from 'shared/helpers/common.helper';
import { invoiceSchema, transformSpendCategoryToSource } from 'shared/helpers/spend.helper';
import { Currency as CurrencyType, Invoice, Subscription } from 'shared/models';
import { useConfirmationModal } from 'src/app/hooks';
import { ConfirmationModalVersion } from 'src/app/hooks/useConfirmationModal';
import { getOffsetDateISOString } from 'views/overview/calendar/subscription-indicator/helpers';

import { ReviewInvoiceFormContainer } from '../../to-review/review-user-upload/failed-invoice-details/failed-invoice-details.component.styles';
import { EditedInvoice, EditSpendModalProps } from './edit-spend.modal.component.props';
import { SpendEditModalContainer } from './edit-spend.modal.styles';

type SubscriptionOption = AutocompleteOption & {
  subscription: Subscription;
};

export const EditSpendModal: FC<EditSpendModalProps> = ({
  closeModal,
  invoiceData,
  isUpdating,
  onDelete,
  onSave,
  subscription,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | undefined>(subscription);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const { openModal: openConfirmationModal } = useConfirmationModal(ConfirmationModalVersion.V2);

  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(invoiceSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    setValue('amount', invoiceData?.amountCents ? String(convertAmountFromCents(invoiceData.amountCents)) : '');
    setValue('currency', invoiceData?.amountCurrency || '');
    const invoiceDate = invoiceData?.date
      ? getOffsetDateISOString(new Date(invoiceData?.date))
      : invoiceData?.date || '';
    setValue('invoiceDate', invoiceDate);
    setValue('name', invoiceData?.name || '');
    setValue('tool', subscription?.name || '');
  }, [setValue, reset, invoiceData, subscription]);

  const selectedCurrency = watch('currency');

  const displayCurrency = CurrencyOptions[selectedCurrency as keyof Currency] || selectedCurrency;

  const onSubmit = async () => {
    if (!selectedSubscription) return;

    const formValues = getValues();
    const invoiceEdited = {
      ...formValues,
      amount: Number(Number(Number(formValues.amount) * 100 || formValues.amount).toFixed()),
    };
    onSave(invoiceEdited as EditedInvoice, invoiceData, selectedSubscription);
  };

  const onSelectSubscription = (item: SubscriptionOption) => {
    if (item) {
      setValue('tool', item.subscription.id);
      setSelectedSubscription(item.subscription);
    }
  };

  const handleDownloadFile = () => {
    if (invoiceData.documentUrl) {
      downloadFile(invoiceData?.documentUrl);
    }
  };

  const handleDelete = () => {
    if (!invoiceData) return;

    onDelete(invoiceData);
  };

  const openDeleteModal = () => {
    openConfirmationModal?.({
      cancelButtonLabel: t('connect_view:tab_section.disconnect_integration_modal.cancel_button'),
      confirmButtonLabel: t('spend_import_view:imported_successfully.delete.confirm_button_text'),
      customHeader: (
        <Typography width='100%' color='text.primary' mb={3} variant='h2'>
          {t('spend_import_view:imported_successfully.delete.title_one')}
        </Typography>
      ),

      customMessage: (
        <Typography color='text.primary' variant='subtitle'>
          {t('spend_import_view:imported_successfully.delete.description_one')}
        </Typography>
      ),
      onConfirm: handleDelete,
    });
  };

  const getSubtitle = (invoice: Invoice) => {
    const sourceName = invoice.isEstimated
      ? SpendSourceName.Estimated
      : getImportSourceName(invoice.importSubsystem || (invoice.importSource as any));
    const { uploadDate, uploadedByName } = invoice || {};
    const uploadedByText = uploadedByName ? `by ${uploadedByName}` : '';
    const uploadedDateText = uploadDate ? `• ${formatFullDate(uploadDate as string)}` : '';

    if (!sourceName) {
      if (
        (invoice?.category === SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT ||
          invoice?.category === SPEND_CATEGORIES.SUBSCRIPTION_DOCUMENT) &&
        invoice?.uploadedByName
      ) {
        return `${t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.uploaded_by_text',
          { user: invoice?.uploadedByName }
        )} ${uploadedDateText}`;
      }

      if (invoice?.category === SPEND_CATEGORIES.AUTOMATED_IMPORT) {
        const category = transformSpendCategoryToSource(Number(invoice?.category));
        return `${category} ${uploadedDateText}`;
      }
    }

    return <>{`${sourceName || ''} ${uploadedByText} ${uploadedDateText}`}</>;
  };

  return (
    <SpendEditModalContainer hasAttachment={Boolean(invoiceData.documentPreviewUrl)}>
      <Box className='spend-edit-modal' display='flex' flexDirection='column' data-testid='spend-edit-modal'>
        <SpendModalHeader
          title={t('spend_import_view:imported_successfully.edit.modal_title')}
          subtitle={getSubtitle(invoiceData)}
          enableDownload={Boolean(invoiceData?.documentUrl)}
          closeModal={closeModal}
          handleDownloadFile={handleDownloadFile}
        />
        <Box className='spend-edit-modal-body' display='flex' flexDirection='row'>
          {invoiceData.documentPreviewUrl && (
            <Box className='left-section'>
              <iframe
                src={`${invoiceData.documentPreviewUrl}`}
                title='attachement-preview'
                width='100%'
                height='100%'
              />
            </Box>
          )}
          <Box className='right-section' display='flex' flexDirection='column'>
            <ReviewInvoiceFormContainer mb={2} display='flex' flexDirection='column' className='form-container'>
              <Box display='flex' flexDirection='column' width='100%' data-testid='tool-name'>
                <Typography variant='label'>{t('common:modals.review_spend_modal.form.tool_label_text')}</Typography>
                <Controller
                  name='tool'
                  control={control}
                  render={() => {
                    return (
                      <SubscriptionAutocomplete
                        subscriptionValue={selectedSubscription}
                        onSelectSubscription={(
                          _e: SyntheticEvent<Element, Event>,
                          option: AutocompleteOption | string | null | (string | AutocompleteOption)[]
                        ) => onSelectSubscription(option as SubscriptionOption)}
                      />
                    );
                  }}
                />
              </Box>
              <Box display='flex' flexDirection='column' width='100%' mt={2} data-testid='invoice-name'>
                <Typography variant='label'>
                  {t('spend_import_view:imported_successfully.edit.invoice_name')}
                </Typography>
                <Controller
                  name='name'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        size='small'
                        value={value}
                        onChange={(value) => onChange(value)}
                        placeholder={t('common:modals.review_spend_modal.form.name_placeholder')}
                        error={!value}
                      />
                    );
                  }}
                />
              </Box>
              <Box display='flex' flexDirection='column' width='100%' mt={2} data-testid='invoice-date'>
                <Typography variant='label'>{t('common:modals.review_spend_modal.form.invoice_date_text')}</Typography>
                <Controller
                  name='invoiceDate'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerComponent
                      value={value || ''}
                      onChange={(newValue) => {
                        onChange(newValue);
                        setOpenDatePicker(false);
                      }}
                      placeholder={t('common:modals.review_spend_modal.form.invoice_date_placeholder')}
                      openDatePicker={openDatePicker}
                      setOpenDatePicker={setOpenDatePicker}
                      maxDate={new Date()}
                      valid={Boolean(value)}
                    />
                  )}
                />
              </Box>
              <Box display='flex' flexDirection='row' width='100%' mt={2}>
                <Box display='flex' flexDirection='column' width='48%' data-testid='amount'>
                  <Typography variant='label' className='amount-label'>
                    {t('common:modals.review_spend_modal.form.amount_label_text')}
                  </Typography>
                  <Controller
                    name='amount'
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NumberFormatInput
                          decimalScale={2}
                          decimalSeparator='.'
                          fixedDecimalScale
                          thousandSeparator
                          prefix={displayCurrency}
                          value={value}
                          isValid={value !== 'undefined' && !Number.isNaN(Number(value))}
                          onValueChange={(value) => onChange(value.floatValue)}
                          placeholder={t('common:modals.review_spend_modal.form.amount_placeholder')}
                        />
                      );
                    }}
                  />
                </Box>
                <Box display='flex' flexDirection='column' width='48%' ml={2} data-testid='currency'>
                  <Typography variant='label' className='currency-label'>
                    {t('common:modals.review_spend_modal.form.currency_label_text')}
                    <Tooltip
                      title={`${t('common:modals.review_spend_modal.form.currency_label_tooltip_text')}`}
                      placement='top'
                      arrow>
                      <IconButton>
                        <Icon sx={{ color: theme.palette.text.secondary }} fontSize='small'>
                          help
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Controller
                    name='currency'
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CurrencySelectInputComponent
                          initialValue={value}
                          getValue={(value: CurrencyType) => onChange(value.code)}
                          isValid={Boolean(value)}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            </ReviewInvoiceFormContainer>
            <Box display='flex' flexDirection='row' width='100%' className='confirmation-section'>
              <Box className='confirmation-options'>
                <Button onClick={openDeleteModal} data-testid='delete-button' color='error' sx={{ mr: 2 }}>
                  {t('common:modals.review_spend_modal.delete_button_text')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!isValid}
                  onClick={onSubmit}
                  startIcon={isUpdating ? <CircularProgress color='inherit' size={16} /> : null}
                  data-testid='save-button'>
                  {t('common:modals.review_spend_modal.save_button_text')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </SpendEditModalContainer>
  );
};
