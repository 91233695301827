import i18next from 'i18next';
import { SchemaField, SchemaFieldTypes } from 'shared/models/schema-field';
import * as yup from 'yup';

export function buildValidationSchema(schemata: SchemaField[] | null | undefined) {
  return yup.object().shape({
    ...schemata?.reduce((acc, schema) => {
      let validation = yup.string();
      if (schema.field_options?.required === 'true') {
        validation = validation.required();
      }

      if (schema.field_options?.maxLength && typeof schema.field_options?.maxLength === 'string') {
        validation = validation.max(parseInt(schema.field_options.maxLength, 10));
      }

      if (schema.field_type === SchemaFieldTypes.Email) {
        validation = validation.email(i18next.t('common:validation_errors.invalid_email'));
      }

      acc[String(schema.field_name)] = validation;
      return acc;
    }, {} as { [key: string]: yup.StringSchema }),
  });
}
