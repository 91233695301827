import { Button, Stack, Typography } from '@mui/material';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from 'shared/common.definitions';
import { Avatar } from 'views/requests/components/request-list/request-list.styles';

import { TransitionToolProps } from './transition-tool.component.props';

export const TransitionTool: React.FC<TransitionToolProps> = ({ isMainTool, onReject, onSubmit, onView, tool }) => {
  const { t } = useTranslation();

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section';

  const isTransitionMode = useMemo(
    () => Boolean(tool?.requestStateId === REQUEST_STATUS.APPROVED),
    [tool?.requestStateId]
  );
  const isViewMode = useMemo(
    () => Boolean(tool?.requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED),
    [tool?.requestStateId]
  );

  const renderRejectButton = useMemo(
    () => (
      <Button color='error' size='small' variant='text' onClick={() => onReject(tool)} data-testid='reject-tool-btn'>
        {t(`${pathToTranslation}.request_details_section.actions.reject_button_label`)}
      </Button>
    ),
    [t, tool, onReject]
  );

  const renderActivateButton = useMemo(
    () => (
      <Button color='primary' size='small' variant='contained' onClick={onSubmit} data-testid='activate-tool-btn'>
        {isMainTool && tool.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL
          ? t(`${pathToTranslation}.request_details_section.actions.renew_tool_btn`)
          : t(`${pathToTranslation}.request_details_section.actions.activate_tool_btn`)}
      </Button>
    ),
    [t, onSubmit, isMainTool, tool]
  );

  const renderViewButton = useMemo(
    () => (
      <Button color='primary' size='small' variant='text' onClick={() => onView(tool)} data-testid='view-tool-btn'>
        {t(`${pathToTranslation}.request_details_section.actions.view_tool_btn`)}
      </Button>
    ),
    [t, tool, onView]
  );

  return (
    <Stack direction='row' spacing={2} justifyContent='flex-start' alignItems='center' mb={2}>
      <Stack direction='row' spacing={1} alignItems='center' flexBasis={240}>
        <Avatar alt={tool.vendorName} src={tool.vendorAvatarUrl || 'gravatar'} size='normal' variant='rounded' />
        <Stack direction='column'>
          <Typography>{tool.vendorName}</Typography>
          <Typography color='textSecondary' variant='small'>
            {tool.vendorCategory}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction='row' spacing={2}>
        {isTransitionMode && (
          <>
            {renderRejectButton}
            {renderActivateButton}
          </>
        )}
        {isViewMode && renderViewButton}
      </Stack>
    </Stack>
  );
};
