import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateFooterState } from 'shared/store/modal';
import * as yup from 'yup';

export type SpendAndBudget = {
  budgetCents?: number | null;
};

export function useExpendAndBudgetForm(budgetCents: number | null): UseFormReturn<SpendAndBudget> {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const spendAndBudgetSchema = useMemo(
    () =>
      yup.object().shape({
        budgetCents: yup
          .number()
          .min(0, t('subscription_detail_view:subscription_detail_sidebar_section.yearly_budget_section.error_budget'))
          .nullable(),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultValues: SpendAndBudget = {
    budgetCents: budgetCents || null,
  };

  const form = useForm<SpendAndBudget>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(spendAndBudgetSchema),
    shouldUnregister: true,
  });

  const { isDirty, isValid } = form.formState;

  useEffect(() => {
    if (isDirty) {
      dispatch(updateFooterState({ isFormValid: isValid }));
    }
  }, [dispatch, isDirty, isValid]);

  return form;
}

export default useExpendAndBudgetForm;
