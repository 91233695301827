import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { ToolUsageSsoProviderDto } from 'libs/dtos';
import { ToolUsageActivityDataV2, WorkatoLicenseHolderData } from 'shared/models/tool-usage-analytics.model';

export interface UsageAnalyticsProps {
  subscriptionId: string;
  vendorId: string;
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
}

export interface UsageAnalyticsLicenseHoldersProps {
  data: WorkatoLicenseHolderData[];
}

export interface UsageAnalyticsCardConnectProps {
  onConnect: () => void;
}

export interface UsageAnalyticsCardConnectedProps {
  onConnect: () => void;
  toolName: string;
  toolUsageProvider: ToolUsageSsoProviderDto;
  data: ToolUsageActivityDataV2;
  isDirectIntegration: boolean;
  isDirectUsageAnalyticsAvailable: boolean;
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
  subscriptionId: string;
  subscriptionVendorName: string;
}

export type UsageAnalyticsCardGraphProps = ToolUsageActivityDataV2;

export const GOOGLE_WORKSPACE = 'Google Workspace';
export const MICROSOFT_AZURE = 'Microsoft';
export const OKTA = 'Okta';
