/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Components, createTheme, ThemeOptions } from '@mui/material/styles';
import { alpha, blend, lighten, Theme } from '@mui/system';

import { colors, mainColors } from './color.styles';
import { shadows } from './shadow.styles';
import { typographyVariants } from './typography.styles';

export const spacing = 8;
const minTabHeight = '42px';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1756,
    xxl: 1900,
  },
};

const cssBaselineComponentConfig: Components['MuiCssBaseline'] = {
  styleOverrides: {
    body: {
      color: colors.text.primary,
    },
  },
};

const typographyComponentConfig: Components['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      body: 'div',
      body1: 'span',
      extra: 'h1',
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      label: 'label',
      paragraph: 'p',
      section: 'div',
      small: 'div',
      subtitle: 'span',
      // button: 'div',
      // buttonSmall: 'div',
    },
  },
};

const cardComponentConfig: Components['MuiCard'] = {
  styleOverrides: {
    root: {
      boxShadow: shadows.card,
    },
  },
};

const getSizeDimensions: (value: number) => { height: string; width: string } = (value: number) => ({
  height: `${value}px`,
  width: `${value}px`,
});

const avatarSxForSize = {
  large: {
    ...getSizeDimensions(40),
    ...typographyVariants.label,
  },
  normal: {
    ...getSizeDimensions(32),
    ...typographyVariants.label,
  },
  undefined: {
    ...getSizeDimensions(32),
    ...typographyVariants.label,
  },
  medium: {
    ...getSizeDimensions(32),
    ...typographyVariants.label,
  },
  small: {
    ...getSizeDimensions(24),
    ...typographyVariants.small,
  },
  tiny: {
    ...getSizeDimensions(18),
    ...typographyVariants.tiny,
  },
};

const avatarComponentConfig: Components['MuiAvatar'] = {
  styleOverrides: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    root: ({ ownerState, theme }: { ownerState: any; theme: any }) => ({
      backgroundColor: ownerState.fitImage ? theme.palette.background.paper : theme.palette.secondary.main,
      color: theme.palette.action.active,
      // @ts-ignore
      ...avatarSxForSize[ownerState?.size as string],
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    img: ({ ownerState }: { ownerState: any; theme: any }) =>
      (ownerState.fitImage && { height: '100%', objectFit: 'contain' }) || {},
  },
};

const iconComponentConfig: Components['MuiIcon'] = {
  defaultProps: {
    baseClassName: 'material-symbols-outlined',
  },
  styleOverrides: {
    root: {
      '&.MuiIcon-colorTertiary': {
        color: colors.tertiary.main,
      },
      '&.MuiIcon-colorText': {
        color: colors.text.main,
      },
    },
  },
};

const menuComponentConfig: Components['MuiMenu'] = {
  styleOverrides: {
    root: {
      '& .MuiPaper-root': {
        background: colors.background.light,
        boxShadow: shadows.list,
        padding: `${spacing}px`,
      },
    },
  },
};

const menuItemComponentConfig: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      '&:active': {
        backgroundColor: alpha(colors.primary.main, 0.08),
      },
      '&:hover': {
        backgroundColor: alpha(colors.secondary.contrastText, 0.08),
      },
    },
  },
};

const list: Components['MuiList'] = {
  styleOverrides: {
    root: {
      '&.MuiList-root': {
        backgroundColor: colors.background.paper,
      },
    },
  },
};

const listItemButtonComponentConfig: Components['MuiListItemButton'] = {
  styleOverrides: {
    root: {
      '& .MuiListItemIcon-root': {
        color: 'inherit',
        minWidth: '40px',
      },
      '& .MuiListItemText-root': {
        marginBottom: 0,
        marginTop: 0,
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        color: mainColors.primary,
      },
      '&:hover': {
        backgroundColor: colors.primary.highlight,
      },
      paddingBottom: 10,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 10,
    },
  },
};

const tabsComponentConfig: Components['MuiTabs'] = {
  styleOverrides: {
    indicator: {
      backgroundColor: mainColors.primary,
    },
    root: {
      minHeight: minTabHeight,
    },
  },
};

const tabComponentConfig: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        color: mainColors.primary,
      },
      '&:hover': {
        backgroundColor: colors.secondary.highlight,
      },
      color: colors.text.secondary,
      fontSize: typographyVariants.section?.fontSize,
      fontWeight: typographyVariants.section?.fontWeight,
      minHeight: minTabHeight,
    },
  },
};

const linkComponentConfig: Components['MuiLink'] = {
  styleOverrides: {
    root: {
      '&:hover': {
        backgroundColor: blend(colors.background.default, colors.primary.main, 0.08),
        border: 0,
        borderRadius: `${spacing / 2}px`,
      },
      color: colors.primary.main,
      fontWeight: 500,
      padding: `${spacing / 2}px`,
    },
  },
};

const badgeComponentConfig: Components['MuiBadge'] = {
  styleOverrides: {
    root: {
      '& .MuiBadge-badge:not([class*="MuiBadge-color"])': {
        backgroundColor: colors.tertiary.main,
        color: colors.tertiary.contrastText,
      },
      '& .MuiBadge-colorError': {
        backgroundColor: colors.error.main,
        color: colors.error.contrastText,
      },
      '& .MuiBadge-colorInfo': {
        backgroundColor: colors.info.main,
        color: colors.info.contrastText,
      },
      '& .MuiBadge-colorPrimary': {
        backgroundColor: colors.primary.main,
        color: colors.primary.contrastText,
      },
      '& .MuiBadge-colorSecondary': {
        backgroundColor: colors.warning.main,
        color: colors.warning.contrastText,
      },
      '& .MuiBadge-colorSuccess': {
        backgroundColor: colors.success.main,
        color: colors.success.contrastText,
      },
      '& .MuiBadge-colorTertiary': {
        backgroundColor: colors.tertiary.main,
        color: colors.tertiary.contrastText,
      },
      '& .MuiBadge-colorText': {
        backgroundColor: colors.text.disabled,
        color: colors.text.disabled,
      },
      '& .MuiBadge-colorWarning': {
        backgroundColor: colors.warning.main,
        color: colors.warning.contrastText,
      },
    },
  },
};

const buttonComponentConfig: Components['MuiButton'] = {
  defaultProps: {
    size: 'medium',
    variant: 'text',
  },
  styleOverrides: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    root: ({ ownerState, theme }: { ownerState: any; theme: any }) => {
      const defaultColor = theme.palette.primary.main;
      const color = (ownerState.color === 'warning' ? 'tertiary' : ownerState.color) ?? 'primary';

      return {
        '&.MuiButton-contained': {
          ':hover': {
            backgroundColor: theme.palette[color]?.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: colors.action.disabled,
          },
          backgroundColor: theme.palette[color]?.main,
          color: color === 'success' ? theme.palette.background.light : theme.palette[color]?.contrastText,
        },
        '&.MuiButton-text': {
          '&:hover': {
            backgroundColor:
              color &&
              (color === 'secondary'
                ? alpha(theme.palette[color]?.contrastText, 0.08)
                : alpha(theme.palette[color]?.main || defaultColor, 0.08)),
          },
          color: theme.palette[color]?.main,
          '&.MuiButton-textSecondary': {
            color: colors.text.secondary,
            '&.Mui-disabled': {
              color: colors.text.disabled,
            },
          },
        },
        '&.Mui-disabled': {
          color: colors.text.disabled,
        },
      };
    },
  },
};

const switchComponentConfig: Components['MuiSwitch'] = {
  styleOverrides: {
    root: {
      '& .MuiSwitch-switchBase': {
        '& .MuiSwitch-thumb': {
          backgroundColor: colors.secondary.light,
        },
      },
      '& .MuiSwitch-switchBase + .MuiSwitch-track, & .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: colors.secondary.dark,
        opacity: 1,
      },
      '& .MuiSwitch-switchBase, & .MuiSwitch-switchBase.Mui-checked': {
        '&.Mui-disabled': {
          '& .MuiSwitch-thumb': {
            backgroundColor: colors.secondary.main,
            boxShadow: 'none',
          },
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        '& .MuiSwitch-thumb': {
          backgroundColor: mainColors.primary,
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: colors.primary.light,
        opacity: 1,
      },
      '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: colors.secondary.dark,
      },
    },
  },
};

const formControlLabelComponentConfig: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
        color: colors.text.primary,
      },
      '& .MuiFormControlLabel-root.Mui-disabled .MuiFormControlLabel-label': {
        color: colors.text.disabled,
      },
    },
  },
};

const tooltipComponentConfig: Components['MuiTooltip'] = {
  defaultProps: {
    placement: 'top',
  },
  styleOverrides: {
    arrow: {
      color: colors.text.primary,
    },
    tooltip: {
      backgroundColor: colors.text.primary,
      '& .MuiTypography-root': {
        color: colors.primary.contrastText,
      },
    },
  },
};

const formControlComponentConfig: Components['MuiFormControl'] = {
  styleOverrides: {
    root: {
      '& .MuiFormGroup-root[role="radiogroup"] + .MuiFormHelperText-root': {
        marginLeft: 0,
      },
      '& .MuiFormHelperText-root': {
        '&.Mui-error': {
          color: colors.error.main,
        },
        color: colors.text.secondary,
        fontSize: typographyVariants.small?.fontSize,
        lineHeight: typographyVariants.small?.lineHeight,
      },
      '& .MuiFormLabel-root + .MuiFormGroup-root[role="radiogroup"]': {
        paddingLeft: 8,
      },
      '& .MuiFormLabel-root, & .MuiFormLabel-root#radio-buttons-group-label': {
        color: colors.text.secondary,
      },
      '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused': {
        '&.Mui-error': {
          color: colors.error.main,
        },
        color: colors.text.secondary,
      },
      '& .MuiFormLabel-root.Mui-error, & .MuiFormLabel-root.Mui-error#radio-buttons-group-label': {
        color: colors.error.main,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: colors.primary.main,
      },
      '& .MuiInputBase-root': {
        backgroundColor: colors.background.light,
      },
      '& .MuiInputLabel-outlined': {
        color: colors.text.secondary,
      },
      '& .MuiInputLabel-outlined.Mui-disabled': {
        color: colors.text.disabled,
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.secondary.dark,
      },
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.action.disabled,
      },
      '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.error.main,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.primary.main,
      },
      '& .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.error.main,
      },
      '& .MuiRadio-root': {
        '& .MuiTouchRipple-root .MuiTouchRipple-child': {
          backgroundColor: colors.primary.light,
        },
        '&.Mui-disabled': {
          color: colors.secondary.dark,
        },
        '&:hover': {
          backgroundColor: alpha(colors.secondary.contrastText, 0.08),
        },
      },
    },
  },
};

const tableRowComponentConfig: Components['MuiTableRow'] = {
  styleOverrides: {
    root: {
      // Row dictates background color for cells, so we need to override it here
      '&.MuiTableRow-root': {
        backgroundColor: colors.background.light,
      },
      '&.Mui-selected': {
        backgroundColor: colors.tableRowSelected,
      },
      '&:hover': {
        backgroundColor: colors.tableRowHover,
      },
      '&.Mui-selected:hover': {
        backgroundColor: lighten(colors.primary.main, 0.66),
        '& .MuiTableCell-root': {
          backgroundColor: 'inherit',
        },
      },
    },
    head: {
      '&.MuiTableRow-root': {
        backgroundColor: colors.background.light,
      },
    },
  },
};

const tableCellComponentConfig: Components['MuiTableCell'] = {
  styleOverrides: {
    root: {
      '&.MuiTableCell-root[data-pinned="true"]:before': {
        backgroundColor: 'inherit',
        boxShadow: 'none',
      },
      '&.MuiTableCell-pinned': {
        '&.MuiTableCell-pinned--right--first': {
          borderLeft: `1px solid ${colors.outlineBorder}`,
        },
        '&.MuiTableCell-pinned--left--last': {
          borderRight: `1px solid ${colors.outlineBorder}`,
        },
      },
    },
  },
};

const tableComponentConfig: Components['MuiTable'] = {
  styleOverrides: {
    root: {
      '& .MuiTableCell-sizeMedium': {
        padding: `${spacing}px ${spacing * 2}px`,
      },
    },
  },
};

const paginationComponentConfig: Components['MuiPagination'] = {
  styleOverrides: {
    root: {
      '& .MuiPagination-ul .MuiPaginationItem-root.Mui-selected': {
        backgroundColor: colors.secondary.main,
        color: colors.text.primary,
      },
      '& .MuiPagination-ul .MuiPaginationItem-root.Mui-selected.Mui-disabled': {
        backgroundColor: 'transparent',
        color: colors.text.disabled,
      },
    },
  },
};

const alertComponentConfig: Components['MuiAlert'] = {
  styleOverrides: {
    action: {
      paddingTop: 0,
    },
    filledError: {
      backgroundColor: colors.error.light,
      color: colors.error.dark,
    },
    filledInfo: {
      backgroundColor: colors.info.light,
      color: colors.info.dark,
    },
    filledSuccess: {
      backgroundColor: colors.success.light,
      color: colors.success.dark,
    },
    filledWarning: {
      backgroundColor: colors.tertiary.light,
      color: colors.warning.dark,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: ({ theme }: { theme: any }) => ({
      '.MuiIcon-root': { fontSize: theme.typography.body?.lineHeight },
      marginRight: theme.spacing(1),
      padding: 0,
    }),
    message: {
      padding: 0,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    root: ({ theme }: { theme: any }) => ({
      alignItems: 'flex-start',
      padding: theme.spacing(1, 2, 1, 1),
    }),
  },
};

const alertTitleComponentConfig: Components['MuiAlertTitle'] = {
  styleOverrides: {
    root: {
      marginTop: 0,
    },
  },
};

const autocompleteComponentConfig: Components['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        color: colors.text.secondary,
      },
      // '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
      //   color: colors.primary.main,
      // },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.secondary.dark,
        margin: 0,
      },
    },
  },
};

const paperComponentConfig: Components['MuiPaper'] = {
  styleOverrides: {
    root: {
      '& .MuiCalendarPicker-root .MuiPickersDay-root': {
        '&:hover': {
          backgroundColor: alpha(colors.secondary.contrastText, 0.08),
        },
      },
      '& .MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected': {
        backgroundColor: colors.primary.main,
      },
      '& .MuiCalendarPicker-root, & .MuiCalendarPicker-root .MuiPickersDay-root': {
        backgroundColor: colors.background.light,
      },
      backgroundColor: colors.background.paper,
    },
  },
};

const iconButtonComponentConfig: Components['MuiIconButton'] = {
  defaultProps: {
    size: 'normal',
  },
  styleOverrides: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    root: ({ ownerState, theme }: { ownerState: any; theme: any }) => {
      const defaultColor = theme.palette.primary.main;
      const color = (ownerState.color === 'warning' ? 'tertiary' : ownerState.color) ?? 'primary';

      return {
        '&.MuiIconButton-sizeSmall': {
          padding: theme.spacing(0.5),
          fontSize: theme.typography.h2.fontSize,
        },
        '&.MuiIconButton-sizeNormal': {
          padding: theme.spacing(0.5),
        },
        '&:hover': {
          backgroundColor:
            color &&
            (color === 'secondary'
              ? alpha(theme.palette[color]?.contrastText, 0.08)
              : alpha(theme.palette[color]?.main || defaultColor, 0.08)),
        },
        color: theme.palette[color]?.main,
        '&.MuiIconButton-colorSecondary': {
          color: colors.text.secondary,
          '&.Mui-disabled': {
            color: colors.text.disabled,
          },
        },
        '&.Mui-disabled': {
          color: colors.text.disabled,
        },
        '& .MuiIcon-fontSizeMedium': {
          fontSize: 'inherit',
        },
      };
    },
  },
};

const chipComponentConfig: Components['MuiChip'] = {
  defaultProps: {
    color: 'secondary',
    size: 'small',
  },
};

const dialogComponentConfig: Components['MuiDialog'] = {
  defaultProps: {
    maxWidth: 'lg',
  },
};

const stepLabelComponentConfig: Components['MuiStepLabel'] = {
  styleOverrides: {
    root: {
      '& .MuiTypography-small, & .MuiTypography-section': {
        color: colors.text.primary,
      },
      '& .Mui-disabled, &.inactive': {
        '& .MuiStepIcon-root': {
          fill: colors.text.disabled,
        },
        '& .MuiTypography-section': {
          color: `${colors.text.disabled} !important`,
        },
      },
      '& .Mui-error': {
        '& .MuiTypography-section': {
          color: colors.error.main,
        },
      },
      ':not(.inactive)': {
        '& .error': {
          color: colors.error.main,
        },
      },
    },
  },
};

const drawerWidths = {
  max: '90%',
  medium: '792px',
  small: '480px',
  large: '1280px',
};

const drawerComponentConfig: Components['MuiDrawer'] = {
  styleOverrides: {
    root: ({ ownerState: { size, sx }, theme }) => {
      const { spacing } = theme as Theme;
      const previewElementStyles = {
        right: `calc(min(${drawerWidths.small}, ${drawerWidths.max}) + ${spacing(5)})`,
      };

      if (size === 'medium')
        previewElementStyles.right = `calc(min(${drawerWidths.medium}, ${drawerWidths.max}) + ${spacing(5)})`;
      if (size === 'large')
        previewElementStyles.right = `calc(min(${drawerWidths.large}, ${drawerWidths.max}) + ${spacing(5)})`;

      // Ignore size prop if sx.width is set
      const customWidth = (sx as Record<string, string>)?.width;
      if (customWidth) previewElementStyles.right = `calc(min(${customWidth}, ${drawerWidths.max}) + ${spacing(5)})`;

      return {
        '& .drawer-preview-element': { ...previewElementStyles },
      };
    },
    paper: ({ ownerState: { size, sx } }) => {
      const sizeStyles: Record<string, string> = {
        width: '480px',
        maxWidth: drawerWidths.max,
      };

      if (size === 'medium') sizeStyles.width = drawerWidths.medium;
      if (size === 'large') sizeStyles.width = drawerWidths.large;

      // Ignore size prop if sx.width is set
      const customWidth = (sx as Record<string, string>)?.width;
      if (customWidth) sizeStyles.width = customWidth;

      return sizeStyles;
    },
  },
};

export const config: ThemeOptions = {
  breakpoints,
  components: {
    MuiCssBaseline: cssBaselineComponentConfig,
    MuiAlert: alertComponentConfig,
    MuiAlertTitle: alertTitleComponentConfig,
    MuiAutocomplete: autocompleteComponentConfig,
    MuiAvatar: avatarComponentConfig,
    MuiBadge: badgeComponentConfig,
    MuiButton: buttonComponentConfig,
    MuiCard: cardComponentConfig,
    MuiChip: chipComponentConfig,
    MuiDialog: dialogComponentConfig,
    MuiFormControl: formControlComponentConfig,
    MuiFormControlLabel: formControlLabelComponentConfig,
    MuiIcon: iconComponentConfig,
    MuiIconButton: iconButtonComponentConfig,
    MuiList: list,
    MuiListItemButton: listItemButtonComponentConfig,
    MuiMenu: menuComponentConfig,
    MuiMenuItem: menuItemComponentConfig,
    MuiPagination: paginationComponentConfig,
    MuiPaper: paperComponentConfig,
    MuiSwitch: switchComponentConfig,
    MuiTab: tabComponentConfig,
    MuiTable: tableComponentConfig,
    MuiTableRow: tableRowComponentConfig,
    MuiTableCell: tableCellComponentConfig,
    MuiTabs: tabsComponentConfig,
    MuiTooltip: tooltipComponentConfig,
    MuiTypography: typographyComponentConfig,
    MuiStepLabel: stepLabelComponentConfig,
    MuiLink: linkComponentConfig,
    MuiDrawer: drawerComponentConfig,
  },
  palette: colors,
  spacing,
  typography: {
    fontFamily: '"Inter var", Helvetica, Arial, sans-serif',
    fontSize: 14,
    htmlFontSize: 16,
    ...typographyVariants,
  },
};

const theme = createTheme(config);

export default theme;
