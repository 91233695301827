/* eslint-disable react/no-unstable-nested-components */
import './subscription-documents-table.scss';

import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DocumentAttributeEditComponent } from 'components/document-attribute-edit';
import { ChevronDownIcon, DownloadIcon, EditIcon } from 'components/icons';
import { DeleteBinIcon } from 'components/icons/delete-bin.icon';
import { DropdownComponent } from 'components/index';
import { ModalComponentProps } from 'components/modal';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { CsvBuilder } from 'filefy';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  MUIDataTableState,
} from 'mui-datatables';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  DocumentCategory,
  DocumentType,
  googleTagManagerEvents,
  MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY,
  TableColumnNames,
  TableDateSortType,
} from 'shared/common.definitions';
import {
  customStringSort,
  decodeIdFromRoute,
  formatFullDate,
  getDocumentCategory,
  isNewSolutionsRoute,
  shortenFileName,
} from 'shared/helpers/common.helper';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import {
  deleteSubscriptionDocument,
  DeleteSubscriptionType,
  getCategoryTranslationKey,
} from 'shared/logic/subscription-item.logic';
import { Document, SastrifyStore, Subscription, User } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { boxShadows, colors } from 'shared/theme';
import { formatDateWithoutTimeZone } from 'views/overview/calendar/subscription-indicator/helpers';

import { SubscriptionDocumentsTableProps } from '.';

const PREFIX = 'SubscriptionDocumentsTableFeature';

const classes = {
  actionMenuTrigger: `${PREFIX}-actionMenuTrigger`,
  anchorText: `${PREFIX}-anchorText`,
  cellSpanText: `${PREFIX}-cellSpanText`,
  documentHeaderActionsMenuDropdown: `${PREFIX}-documentHeaderActionsMenuDropdown`,
  headerText: `${PREFIX}-headerText`,
  root: `${PREFIX}-root`,
  smallerText: `${PREFIX}-smallerText`,
};

const Root = styled('div')({
  [`& .${classes.actionMenuTrigger}`]: {
    alignItems: 'center',
    color: colors.rollingStone,
    cursor: 'pointer',
    display: 'flex',
    fontSize: '0.75rem',
    fontWeight: 500,
    margin: '0 -0.75rem 0 -1.5rem',
  },
  [`& .${classes.anchorText}`]: {
    color: colors.bahamaBlue,
    fontSize: '0.75rem',
    lineHeight: '0.695rem',
    marginBottom: '0.5rem',
    textDecoration: 'underline !important',
  },
  [`& .${classes.cellSpanText}`]: {
    color: colors.rollingStone,
    fontSize: '0.75rem',
    fontWeight: 400,
  },
  [`& .${classes.documentHeaderActionsMenuDropdown}`]: {
    '& > button': {
      '&:last-child': {
        color: colors.cinnabar,
      },
      color: colors.rollingStone,
      display: 'flex',
      fontSize: '0.875rem',
      justifyContent: 'flex-end',
      padding: '0.375rem 0.75rem',
    },
  },
  [`& .${classes.headerText}`]: {
    color: colors.rollingStone,
    fontSize: '0.75rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  [`&.${classes.root}`]: {
    '& .MuiButton-label > div': {
      '& svg': {
        height: '0.875rem',
      },
      alignItems: 'center',
    },
    '& .MuiTableCell-paddingCheckbox': {
      paddingLeft: '0.75rem',
    },
    '& > div': {
      background: 'transparent',
      boxShadow: 'none',
    },
    '& tbody': {
      '& > tr': {
        '& > td': {
          position: 'static',
          zIndex: '1',
        },
        '&:last-child > td': {
          borderBottom: 'none',
        },
      },
      background: colors.white,
      borderRadius: '0.195rem',
      boxShadow: boxShadows.tableRowContainerBoxShadow,
    },
    '& thead > tr': {
      '& > th': {
        background: 'transparent',
        borderBottom: 'none',
        zIndex: 1,
      },
    },
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  [`& .${classes.smallerText}`]: {
    color: colors.osloGray,
    fontSize: '0.625rem',
    lineHeight: '0.695rem',
  },
});

type FieldKeys = {
  id: number;
  name: string;
  category: string;
  date: string;
  uploadDate: string;
};

export const SubscriptionDocumentsTableFeature: FC<SubscriptionDocumentsTableProps> = ({
  closeModal,
  documents,
  showModal,
  showNotification,
}) => {
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation();
  const dispatch = useDispatch();
  const subscriptionId = decodeIdFromRoute(location.pathname);
  const [sortOrder, setSortOrder] = useState<TableDateSortType>({
    direction: 'desc',
    name: 'date',
  });
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const columnHeaderDropDownActionsRef = useRef<{ handleToggle: () => void }>();
  const documentCategoryEditRef = useRef<{ handleUpdateDocuments: () => void }>();
  const singleRowToDelete = useRef<number>();
  const documentTabTranslationPath = 'subscription_detail_view:tabs_component_section.subscription_document_tab';
  const subscription = queryClient.getQueryData<Subscription>(['subscription', subscriptionId]);
  const pathToEditDocumentTranslation = 'common:modals.edit_document_modal';
  const initialModalProps: ModalComponentProps = {
    cancelButtonText: t(`${pathToEditDocumentTranslation}.action_buttons_section.cancel_button_text`),
    children: undefined,
    onCancelButtonClick: () => closeModal?.(),
  };
  const isNewSolutionDetailPage = isNewSolutionsRoute(location.pathname);

  const { mutateAsync: deleteDocumentAsync } = useMutation(deleteSubscriptionDocument);

  const mutateDocuments = async (updateDocumentFn: unknown[], successMessage: string, errorMessage: string) => {
    try {
      await Promise.all(updateDocumentFn);

      queryClient.invalidateQueries('subscriptionDocuments');
      queryClient.invalidateQueries(['subscription', subscriptionId]);
      queryClient.invalidateQueries('auditLogs');

      setSelectedRows([]);
      singleRowToDelete.current = undefined;
      closeModal?.();
      showNotification?.(successMessage, NotificationAlertType.Success);
    } catch {
      showNotification?.(errorMessage, NotificationAlertType.Error);
    } finally {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    }
  };

  const handleDeleteDocuments = () => {
    dispatch(updateFooterState({ isFormSubmitting: true }));
    const deleteDocumentsFn: Array<unknown> = [];
    const successMessage = t(`${documentTabTranslationPath}.notification_messages.delete_document.success_message`);
    const errorMessage = t(`${documentTabTranslationPath}.notification_messages.delete_document.error_message`);

    const rowsToDelete = singleRowToDelete.current !== undefined ? [singleRowToDelete.current] : selectedRows;
    rowsToDelete.forEach((rowIndex) => {
      const data = documents[rowIndex];
      const apiParams: DeleteSubscriptionType = {
        documentId: data.id as string,
        subscriptionId,
      };
      deleteDocumentsFn.push(deleteDocumentAsync(apiParams));
    });

    mutateDocuments(deleteDocumentsFn, successMessage, errorMessage);
  };

  const openDeleteDocumentModal = (rowIndex?: number) => {
    singleRowToDelete.current = rowIndex;
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    const message = t('common:modals.delete_document_modal.delete_message_with_document_count', {
      count: rowIndex !== undefined ? 1 : selectedRows.length,
    });

    showModal?.({
      ...initialModalProps,
      confirmButtonColor: 'error',
      confirmButtonText: t('common:modals.delete_document_modal.action_buttons_section.confirm_button_text'),
      message,
      onConfirmButtonClick: handleDeleteDocuments,
      title: t('common:modals.delete_document_modal.header_section_text'),
    });
  };

  const openEditDocumentModal = (document?: Document) => {
    dispatch(updateFooterState({ isFormValid: false }));
    showModal?.({
      ...initialModalProps,
      children: (
        <DocumentAttributeEditComponent
          ref={documentCategoryEditRef}
          documents={documents}
          document={document as Document}
          documentType={DocumentType.DOCUMENT}
          mutateDocuments={mutateDocuments}
          selectedRows={selectedRows}
          subscriptionId={subscriptionId}
          isNewSolutionDetailPage={isNewSolutionDetailPage}
        />
      ),
      confirmButtonText: t(`${pathToEditDocumentTranslation}.action_buttons_section.confirm_button_text`),
      onConfirmButtonClick: () => {
        dispatch(updateFooterState({ isFormSubmitting: true }));
        documentCategoryEditRef.current?.handleUpdateDocuments();
      },
      title: t(
        `${pathToEditDocumentTranslation}.${
          document ? 'single_edit_header_section_title' : 'multiple_edit_header_section_title'
        }`
      ),
    });
  };

  const getRowDate = (dateData?: string | Date): string | undefined => {
    if (dateData) {
      return typeof dateData === 'object' ? dateData.toISOString() : dateData;
    }
    return dateData;
  };

  const handleDownloadDocumentDataCSV = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const selectedRowsData = selectedRows.map((rowIndex) => documents[rowIndex]);
    const csvFileName = `Sastrify(${
      subscription?.name || subscription?.vendorName
    })-document-data-export-${currentDate}.csv`;
    const modifiedTableColumns = [...tableColumns].slice(1) as MUIDataTableColumn[]; // remove first unwanted column
    modifiedTableColumns.splice(modifiedTableColumns.length - 2, 2); // remove last two unwanted columns
    modifiedTableColumns.unshift({ label: 'ID', name: 'id' });
    const modifiedSelectedRowsData = (
      JSON.parse(JSON.stringify(selectedRowsData)) as (Document & { category: string })[]
    ).map((rowData) => {
      const dateString = getRowDate(rowData.date);
      rowData.name = rowData.name || rowData.fileName || '--';
      (rowData.category as string) = getDocumentCategory(rowData.category);
      rowData.date = formatFullDate(dateString) || '--';
      rowData.uploadDate = formatFullDate(rowData.uploadDate) || '--';
      return rowData;
    });

    new CsvBuilder(csvFileName)
      .setColumns(modifiedTableColumns.map((col) => String(col.label)))
      .addRows(
        modifiedSelectedRowsData.map((rowData) =>
          modifiedTableColumns.map((col) => String(rowData[col.name as keyof FieldKeys]))
        )
      )
      .exportFile();
    showNotification?.(MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY, NotificationAlertType.Success);
  };

  const renderStyledCell = (childElement: React.ReactNode) => (
    <span className={classes.cellSpanText}>{childElement}</span>
  );
  const renderStyledColumn = (label: string) => <span className={classes.headerText}>{label}</span>;

  const renderColumnHeaderActions = () => (
    <DropdownComponent
      ref={columnHeaderDropDownActionsRef}
      menuMinWidth='7rem'
      hasPadding
      isRenderedOnTableColumn
      menuTriggerElement={
        <span
          key='actionsColumn'
          className={classes.actionMenuTrigger}
          tabIndex={0}
          role='button'
          onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => columnHeaderDropDownActionsRef.current?.handleToggle())}
          onClick={() => {
            columnHeaderDropDownActionsRef.current?.handleToggle();
          }}>
          {t(`${documentTabTranslationPath}.document_table.actions_menu_section.menu_trigger_text`)}{' '}
          <ChevronDownIcon fill={colors.rollingStone} className='ml-1' />
        </span>
      }>
      <Box className={classes.documentHeaderActionsMenuDropdown}>
        <Box component={Button} width='100%' onClick={handleDownloadDocumentDataCSV}>
          {t(`${documentTabTranslationPath}.document_table.actions_menu_section.download_menu_text`)}
        </Box>
        <Box component={Button} width='100%' onClick={() => openEditDocumentModal()}>
          {t(`${documentTabTranslationPath}.document_table.actions_menu_section.edit_menu_text`)}
        </Box>
        <Box component={Button} width='100%' onClick={() => openDeleteDocumentModal()}>
          {t(`${documentTabTranslationPath}.document_table.actions_menu_section.delete_menu_text`)}
        </Box>
      </Box>
    </DropdownComponent>
  );

  const tableColumns: MUIDataTableColumnDef[] = [
    {
      name: 'actions',
      options: {
        customHeadLabelRender: () => renderColumnHeaderActions(),
        display: selectedRows.length > 0,
        empty: true,
        sort: false,
      },
    },
    {
      label: t(`${documentTabTranslationPath}.document_table.header_section.name_label`),
      name: 'name',
      options: {
        customBodyRenderLite: (rowIndex: number) => {
          const rowData: Document = documents[rowIndex];

          return (
            <a
              data-testid='document-link'
              className={classes.anchorText}
              href={rowData.previewUrl}
              onClick={() => {
                fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.DocumentViewed, {
                  name: 'documentType',
                  value: getCategoryTranslationKey(rowData.category as DocumentCategory),
                });
              }}
              target='_blank'
              rel='noreferrer'>
              {shortenFileName(rowData.name || rowData.fileName || '--')}
            </a>
          );
        },
        customHeadLabelRender: () =>
          renderStyledColumn(t(`${documentTabTranslationPath}.document_table.header_section.name_label`)),
        sortCompare:
          (order: 'desc' | 'asc') =>
          (
            obj1: {
              data: string | null;
            },
            obj2: {
              data: string | null;
            }
          ) => {
            const modifiedObjectOne: { data: string | null; rowData?: unknown[] } = obj1;
            const modifiedObjectTwo: { data: string | null; rowData?: unknown[] } = obj2;
            const objectOneSortByName = (modifiedObjectOne.data || modifiedObjectOne.rowData?.[5]) as string;
            const objectTwoSortByName = (modifiedObjectTwo.data || modifiedObjectTwo.rowData?.[5]) as string;

            if (order === 'asc') {
              return customStringSort(objectOneSortByName, objectTwoSortByName);
            }
            return customStringSort(objectTwoSortByName, objectOneSortByName);
          },
        sortThirdClickReset: true,
      },
    },
    {
      label: t(`${documentTabTranslationPath}.document_table.header_section.category_label`),
      name: 'category',
      options: {
        customBodyRenderLite: (rowIndex: number) => {
          const rowData: Document = documents[rowIndex];
          return renderStyledCell(getDocumentCategory(rowData.category as number));
        },
        customHeadLabelRender: () =>
          renderStyledColumn(t(`${documentTabTranslationPath}.document_table.header_section.category_label`)),
        sort: false,
      },
    },
    {
      label: t(`${documentTabTranslationPath}.document_table.header_section.document_date_label`),
      name: 'date',
      options: {
        customBodyRenderLite: (rowIndex: number) => {
          const rowData: Document = documents[rowIndex];
          const dateString = rowData.date ? formatDateWithoutTimeZone(rowData.date) : '';
          return renderStyledCell(formatFullDate(dateString) || '--');
        },
        customHeadLabelRender: () =>
          renderStyledColumn(t(`${documentTabTranslationPath}.document_table.header_section.document_date_label`)),
        sortCompare:
          (order: 'desc' | 'asc') =>
          (
            obj1: {
              data: string | null;
            },
            obj2: {
              data: string | null;
            }
          ) => {
            const modifiedObjectOne: { data: string | null; rowData?: unknown[] } = obj1;
            const modifiedObjectTwo: { data: string | null; rowData?: unknown[] } = obj2;
            const objectOneDate = modifiedObjectOne.data;
            const objectTwoDate = modifiedObjectTwo.data;
            const objectOneUploadedOnDate = modifiedObjectOne.rowData?.[4] as string;
            const objectTwoUploadedOnDate = modifiedObjectTwo.rowData?.[4] as string;

            let objectOneSortBy = objectOneDate;
            let objectTwoSortBy = objectTwoDate;

            if (!objectOneSortBy && !objectTwoSortBy) {
              objectOneSortBy = objectOneUploadedOnDate;
              objectTwoSortBy = objectTwoUploadedOnDate;
            } else if (!objectOneSortBy && objectTwoSortBy) objectOneSortBy = objectOneUploadedOnDate;
            else if (objectOneSortBy && !objectTwoSortBy) objectTwoSortBy = objectTwoUploadedOnDate;
            else if (objectOneSortBy && objectTwoSortBy && objectOneSortBy === objectTwoSortBy) {
              objectOneSortBy = objectOneUploadedOnDate;
              objectTwoSortBy = objectTwoUploadedOnDate;
            }

            if (order === 'asc') {
              return new Date(objectOneSortBy as string).valueOf() - new Date(objectTwoSortBy as string).valueOf();
            }
            return new Date(objectTwoSortBy as string).valueOf() - new Date(objectOneSortBy as string).valueOf();
          },
        sortThirdClickReset: true,
      },
    },
    {
      label: t(`${documentTabTranslationPath}.document_table.header_section.uploaded_on_label`),
      name: 'uploadDate',
      options: {
        customBodyRenderLite: (rowIndex: number) => {
          const rowData: Document = documents[rowIndex];

          return (
            <div className='is-flex is-flex-direction-column'>
              <span className={classes.cellSpanText}>{formatFullDate(rowData.uploadDate) || '--'}</span>
              {rowData.uploadedByName && <span className={classes.smallerText}>{rowData.uploadedByName}</span>}
            </div>
          );
        },
        customHeadLabelRender: () =>
          renderStyledColumn(t(`${documentTabTranslationPath}.document_table.header_section.uploaded_on_label`)),
        sortThirdClickReset: true,
      },
    },
    {
      name: 'fileName',
      options: {
        display: false,
        sort: false,
      },
    },
    {
      name: 'editDocumentAction',
      options: {
        customBodyRenderLite: (rowIndex: number) => {
          const document: Document = documents[rowIndex];

          return (
            <div className='document-options'>
              <span
                tabIndex={0}
                role='button'
                onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => openEditDocumentModal(document))}
                onClick={() => openEditDocumentModal(document)}
                data-testid='edit-document'>
                <EditIcon fill={colors.osloGray} />
              </span>
              <span>
                <a href={document.url} download data-testid='download-document'>
                  <DownloadIcon />
                </a>
              </span>
              <span
                tabIndex={0}
                role='button'
                onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => openDeleteDocumentModal(rowIndex))}
                onClick={() => openDeleteDocumentModal(rowIndex)}
                data-testid='delete-document'>
                <DeleteBinIcon />
              </span>
            </div>
          );
        },
        customHeadLabelRender: () => <div />,
        sort: false,
      },
    },
  ];

  const tableRows: Document[] = [...documents];

  const handleRowSelection = (_: unknown[], allRowsSelected: Array<{ index: number; dataIndex: number }>) => {
    const selectedData = allRowsSelected.length > 0 ? allRowsSelected.map(({ dataIndex }) => dataIndex) : [];
    setSelectedRows(selectedData);
  };
  const renderColumnHeaderTooltip = (column: MUIDataTableColumn) => {
    switch (column.name) {
      case TableColumnNames.DATE:
        return t(`${documentTabTranslationPath}.document_table.sort_tooltips.date`);
      case TableColumnNames.NAME:
        return t(`${documentTabTranslationPath}.document_table.sort_tooltips.name`);
      case TableColumnNames.UPLOAD_DATE:
        return t(`${documentTabTranslationPath}.document_table.sort_tooltips.uploadDate`);
      default:
        return t(`${documentTabTranslationPath}.document_table.sort_tooltips.default`);
    }
  };

  const customTableOptions: MUIDataTableOptions = {
    download: false,
    filter: false,
    onRowSelectionChange: handleRowSelection,
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      if (action === 'sort') {
        const newSortOrder = tableState.sortOrder;
        setSortOrder(newSortOrder);
      }
    },
    pagination: false,
    print: false,
    responsive: 'standard',
    rowsSelected: [...selectedRows],
    search: false,
    selectToolbarPlacement: 'none',
    sortOrder,
    textLabels: {
      body: {
        columnHeaderTooltip: renderColumnHeaderTooltip,
      },
    },
    viewColumns: false,
  };

  return (
    <Root className={classes.root}>
      <MUIDataTable title='' data={tableRows} columns={tableColumns} options={customTableOptions} />
    </Root>
  );
};
