import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { LoadingComponent, SubscriptionAletSummaryItemComponent } from 'components/index';
import { ModalComponentProps } from 'components/modal';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useFetchData } from 'hooks/useFetchData';
import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { METHOD_TO_ACTION_MAPPINGS, MethodTypes } from 'shared/common.definitions';
import { normalizeDateToUTC } from 'shared/helpers/dates.helper';
import { getSubscriptionAlerts, mutateSubscriptionAlert } from 'shared/logic/subscription-item.logic';
import { Alert, Subscription } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { object, string } from 'yup';

import { SubscriptionAlertFormFeature } from '../subscription-alert-form/subscription-alert-form.feature';
import { SubscriptionAlertFormFeatureProps } from '../subscription-alert-form/subscription-alert-form.feature.props';
import { SubscriptionAlertSummaryFeatureProps } from '.';
import { SubscriptionAlertSummaryWrapper } from './subscription-alert-summary.styles';

export type FormValue = {
  date?: string | Date;
  title?: string;
};
export interface SubscriptionAlertData extends FormValue {
  id?: string;
  method?: MethodTypes;
  subscriptionId: string;
}

type DeleteAlertType = {
  alertId: string;
  mutateMethodType: MethodTypes;
  mutateFunction: (variables: SubscriptionAlertData) => void;
  subscriptionId: string;
};

export const alertSchema = object().shape({
  date: string().required(),
  title: string().required().min(2),
});

type FormattedAlerts = {
  [key: string]: Array<Alert>;
};

export const deleteAlert = ({ alertId, mutateFunction, mutateMethodType, subscriptionId }: DeleteAlertType): void => {
  const deleteData: SubscriptionAlertData = {
    id: alertId,
    method: mutateMethodType,
    subscriptionId,
  };

  mutateFunction(deleteData);
};

export const SubscriptionAlertSummaryFeature: React.FC<SubscriptionAlertSummaryFeatureProps> = (
  props: SubscriptionAlertSummaryFeatureProps
) => {
  const { closeModal, showModal, showNotification } = props;

  const [selectedAlert, setSelectedAlert] = useState<Alert>();
  const [formattedAlerts, setFormattedAlerts] = useState<FormattedAlerts>({});
  const mutateMethodRef = useRef<MethodTypes>();
  const {
    newSolutionId: newSolutionNameAndId,
    subscriptionId: subscriptionNameAndId,
    toolRequestId: toolRequestNameAndId,
  } = useParams<{
    subscriptionId?: string;
    newSolutionId?: string;
    toolRequestId?: string;
  }>();

  const subscriptionId = `${(subscriptionNameAndId || newSolutionNameAndId || toolRequestNameAndId)?.split('-').pop()}`;
  const isSubscriptionInDiscovery = Boolean(newSolutionNameAndId || toolRequestNameAndId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const subscription: Subscription | undefined = queryClient.getQueryData(['subscription', subscriptionId]);
  const mainText = isSubscriptionInDiscovery
    ? t('subscription_detail_view:subscription_detail_sidebar_section.alert_summary_section.deadline_text')
    : t('subscription_detail_view:subscription_detail_sidebar_section.alert_summary_section.alert_text');

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<FormValue>({
    defaultValues: {
      date: '',
      title: '',
    },
    mode: 'onChange',
    resolver: yupResolver(alertSchema),
  });

  useEffect(() => {
    if (isDirty) dispatch(updateFooterState({ isFormValid: isValid }));
  }, [dispatch, isDirty, isValid]);

  const { data: alerts, isLoading } = useFetchData<Alert[]>(
    'subscriptionAlerts',
    () => getSubscriptionAlerts(subscriptionId),
    {
      cacheTime: 0,
      onError: (error: unknown) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSuccess: (data) => {
        const formattedData: FormattedAlerts = {};
        const sortedAlerts = data?.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
        sortedAlerts.forEach((item) => {
          const year = new Date(item.date).getFullYear();
          formattedData[year] = [...(formattedData[year] || []), item];
        });

        setFormattedAlerts(formattedData);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate } = useMutation(mutateSubscriptionAlert, {
    onError: (_, variables) => {
      const message = `An error occurred. Alert not ${METHOD_TO_ACTION_MAPPINGS[String(variables.method)]}`;

      showNotification?.(message, NotificationAlertType.Error);
    },
    onMutate: () => {
      dispatch(updateFooterState({ isFormSubmitting: true }));
    },
    onSettled: () => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData('subscriptionAlerts', (cacheData) => {
        const previousAlertData = cacheData as Alert[];
        const returnedAlertData = data as Alert;
        if (variables.method === MethodTypes.POST) return previousAlertData.concat(returnedAlertData);
        if (variables.method === MethodTypes.PATCH) {
          const alertIndex = previousAlertData.findIndex((alert) => Number(alert.id) === Number(returnedAlertData.id));
          previousAlertData.splice(alertIndex, 1, returnedAlertData);
          return previousAlertData;
        }
        if (variables.method === MethodTypes.DELETE) {
          return previousAlertData.filter((alert) => Number(alert.id) !== Number(variables.id));
        }
      });

      const message = `${mainText} successfully ${METHOD_TO_ACTION_MAPPINGS[String(variables.method)]}`;

      reset();
      closeAlertModal();
      showNotification?.(message, NotificationAlertType.Success);
    },
  });

  const closeAlertModal = () => {
    mutateMethodRef.current = undefined;
    setSelectedAlert(undefined);
    closeModal?.();
  };

  const initialModalProps: ModalComponentProps = {
    cancelButtonText: 'Cancel',
    children: undefined,
    message: '',
    onCancelButtonClick: closeAlertModal,
  };

  const openModal = (mutateMethodType: MethodTypes, modalProps: ModalComponentProps) => {
    mutateMethodRef.current = mutateMethodType;

    showModal?.(modalProps);
  };

  const openCreateEditAlertModal = (mutateMethodType: MethodTypes) => {
    const isPostMethod = mutateMethodType === MethodTypes.POST;
    if (isPostMethod) {
      reset();
      dispatch(updateFooterState({ isFormValid: false }));
    }
    if (!isPostMethod && selectedAlert?.title) dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    if (!isPostMethod && !selectedAlert?.title) dispatch(updateFooterState({ isFormValid: false }));

    const formProps: SubscriptionAlertFormFeatureProps = {
      control,
      isSubscriptionInDiscovery,
      mutateMethodRef: mutateMethodRef as React.MutableRefObject<MethodTypes>,
      register,
      selectedAlert,
      setValue,
      subscriptionName: subscription?.name || subscription?.vendorName,
    };

    openModal(mutateMethodType, {
      ...initialModalProps,
      children: <SubscriptionAlertFormFeature {...formProps} />,
      confirmButtonText: `${
        isPostMethod
          ? t('alerts_view:alert_item_component.action_buttons_section.create_alert_button_text')
          : t('alerts_view:alert_item_component.action_buttons_section.edit_alert_button_text')
      } ${mainText.toLowerCase()}`,
      onConfirmButtonClick: handleSubmit(onSubmit),
      title: `${
        isPostMethod
          ? t('common:modals.edit_alert_modal.header_section.create_new_text')
          : t('alerts_view:alert_item_component.action_buttons_section.edit_alert_button_text')
      } ${mainText.toLowerCase()}`,
    });
  };

  const openDeleteAlertModal = (mutateMethodType: MethodTypes) => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    const deleteAlertProps = {
      alertId: String(selectedAlert?.id),
      mutateFunction: mutate,
      mutateMethodType,
      subscriptionId: String(subscriptionId),
    };
    const message = (
      <p className='content'>
        {t('common:modals.delete_alert_modal.body_section_text')}: <strong>{selectedAlert?.title}</strong>{' '}
        {mainText.toLowerCase()}
      </p>
    );

    openModal(mutateMethodType, {
      ...initialModalProps,
      confirmButtonText: t('common:modals.delete_alert_modal.action_buttons_section.confirm_delete_alert_button_text'),
      message,
      onConfirmButtonClick: () => deleteAlert(deleteAlertProps),
      title: `${t(
        'common:modals.delete_alert_modal.action_buttons_section.confirm_delete_alert_button_text'
      )} ${mainText.toLowerCase()}`,
    });
  };

  const onSubmit: SubmitHandler<FormValue> = async (alert) => {
    const formattedDate = normalizeDateToUTC(new Date(String(alert.date)));
    const isPostMethod = mutateMethodRef.current === MethodTypes.POST;
    const subscriptionAlertData: SubscriptionAlertData = {
      date: formattedDate,
      id: isPostMethod ? undefined : String(selectedAlert?.id),
      method: mutateMethodRef.current,
      subscriptionId: String(subscriptionId),
      title: alert.title,
    };

    mutate(subscriptionAlertData);
  };

  return (
    <SubscriptionAlertSummaryWrapper data-testid='alerts-section'>
      <Box display='flex' justifyContent='space-between' alignItems='center' data-testid='alerts'>
        <Typography variant='h3' color='initial' className='title-text'>
          {mainText.toUpperCase()}
        </Typography>
        <Tooltip title={t('common:modals.delete_alert_modal.tooltip_add_text')} arrow>
          <IconButton data-testid='add-new-alert' onClick={() => openCreateEditAlertModal(MethodTypes.POST)}>
            <Icon color='tertiary'>add</Icon>
          </IconButton>
        </Tooltip>
      </Box>
      {isLoading && <LoadingComponent />}
      {!isLoading &&
        (alerts && alerts.length > 0
          ? Object.entries(formattedAlerts).map(([year, alerts]) => (
              <Box className='alert-container' key={`alert-container-${year}`} data-testid='alert-container'>
                <Typography variant='subtitle1' color='initial' className='year-text' data-testid='alert-year'>
                  {year}
                </Typography>
                {alerts.map((alert: Alert) => (
                  <SubscriptionAletSummaryItemComponent
                    key={alert.id}
                    alert={alert}
                    setSelectedAlert={setSelectedAlert}
                    openCreateEditAlertModal={openCreateEditAlertModal}
                    openDeleteAlertModal={openDeleteAlertModal}
                  />
                ))}
              </Box>
            ))
          : null)}
    </SubscriptionAlertSummaryWrapper>
  );
};
