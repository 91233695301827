import { Grid, Typography } from '@mui/material';
import { CardWithTitle } from 'asteroids';
import { RelevantDocumentComponent } from 'components/index';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { DocumentCategory } from 'shared/common.definitions';
import { isNewSolutionsRoute } from 'shared/helpers/common.helper';
import {
  getCategoryTranslationKey,
  getDocumentCategoryID,
  getLastestDocumentByCategory,
} from 'shared/logic/subscription-item.logic';
import { Document } from 'shared/models';

import { RelevantDocumentsChecklistFeatureProps } from './relevant-documents-checklist.feature.props';

export const RelevantDocumentsChecklist: FC<RelevantDocumentsChecklistFeatureProps> = ({ showNotification }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation();

  const documents = queryClient.getQueryData<Array<Document>>('subscriptionDocuments');

  const relevantDocumentsChecklistForNewSolution: DocumentCategory[] = [
    DocumentCategory.offer,
    DocumentCategory.terms_and_conditions,
    DocumentCategory.data_processing_agreement,
  ];

  const relevantDocumentsChecklist: DocumentCategory[] = [
    ...relevantDocumentsChecklistForNewSolution,
    DocumentCategory.contract,
    DocumentCategory.master_services_agreement,
  ];

  const getRelevantDocumentsChecklist = (): DocumentCategory[] => {
    return isNewSolutionsRoute(location.pathname)
      ? relevantDocumentsChecklistForNewSolution
      : relevantDocumentsChecklist;
  };

  return (
    <CardWithTitle
      sx={{ my: 2 }}
      title={
        <Typography variant='h2' color='text.secondary' data-testid='documents-header'>
          {t('subscription_detail_view:tabs_component_section.subscription_document_tab.tab_title_text')}
        </Typography>
      }>
      <Grid container spacing={2}>
        {getRelevantDocumentsChecklist().map((documentCategory) => {
          const [categoryId, categoryTranslationKey] = [
            getDocumentCategoryID(documentCategory),
            getCategoryTranslationKey(documentCategory),
          ];
          return (
            <Grid item key={categoryId} md={6} sm={12}>
              <RelevantDocumentComponent
                categoryTranslationKey={categoryTranslationKey}
                category={categoryId}
                document={documents && getLastestDocumentByCategory(documents, categoryId)}
                showNotification={showNotification}
              />
            </Grid>
          );
        })}
      </Grid>
    </CardWithTitle>
  );
};
