/* eslint-disable security/detect-object-injection */
import { SOURCE_SYSTEM } from '../enums/source-system.enum';
import { NANONETS_IMPORT_SUB_SYSTEMS } from '../enums/subscription.enum';
import { CodatPlatformType } from '../models/codat-service.model';

export enum SpendSourceName {
  ManualUpload = 'Manual upload',
  EmailImport = 'Email Import',
  AccountingData = 'Accounting import',
  SpreadsheetUpload = 'Spreadsheet upload',
  Estimated = 'Estimation',
  Xero = 'Xero',
  Netsuite = 'Netsuite',
  Sage = 'Sage',
  SageIntacct = 'Sage Intacct',
  Dynamics365 = 'Microsoft Dynamics 365',
  QuickBooksOnline = 'Quickbooks',
  ZohoBooks = 'Zohobooks',
  FreshBooks = 'Freshbooks',
  ExactOnline = 'Exact Online',
  Pleo = 'Pleo',
  Gmi = 'Gmi',
}

export enum CodatPlatformName {
  Xero = 'xero',
  Netsuite = 'netsuite',
  Sage = 'sage',
  SageIntacct = 'sage intacct',
  Dynamics365 = 'microsoft dynamics 365',
  QuickBooksOnline = 'quickbooks',
  ZohoBooks = 'zohobooks',
  FreshBooks = 'freshbooks',
}

type PlatformKeyType = CodatPlatformType | NANONETS_IMPORT_SUB_SYSTEMS | SOURCE_SYSTEM | string;

export const getImportSourceName = (platformType?: PlatformKeyType | null): SpendSourceName | undefined => {
  const platformToSourceNameMap: { [key in PlatformKeyType]?: SpendSourceName } = {
    [NANONETS_IMPORT_SUB_SYSTEMS.EMAIL_IMPORT]: SpendSourceName.EmailImport,
    [NANONETS_IMPORT_SUB_SYSTEMS.MANUAL_UPLOAD]: SpendSourceName.ManualUpload,
    [SOURCE_SYSTEM.NANONETS]: SpendSourceName.ManualUpload,
    [SOURCE_SYSTEM.USER_UPLOAD]: SpendSourceName.ManualUpload,
    [SOURCE_SYSTEM.ACCOUNTING_IMPORT]: SpendSourceName.AccountingData,
    [SOURCE_SYSTEM.SPREADSHEET_UPLOAD]: SpendSourceName.SpreadsheetUpload,
    [SOURCE_SYSTEM.ZAPIER]: SpendSourceName.Pleo,
    [SOURCE_SYSTEM.GMI]: SpendSourceName.Gmi,
    [CodatPlatformType.NETSUITE]: SpendSourceName.Netsuite,
    [CodatPlatformType.XERO]: SpendSourceName.Xero,
    [CodatPlatformType.QUICKBOOKS_ONLINE]: SpendSourceName.QuickBooksOnline,
    [CodatPlatformType.QUICKBOOKS_ONLINE_SANDBOX]: SpendSourceName.QuickBooksOnline,
    [CodatPlatformType.SAGE]: SpendSourceName.Sage,
    [CodatPlatformType.SAGE_INTACCT]: SpendSourceName.SageIntacct,
    [CodatPlatformType.DYNAMICS_365]: SpendSourceName.Dynamics365,
    [CodatPlatformType.ZOHO_BOOKS]: SpendSourceName.ZohoBooks,
    [CodatPlatformType.FRESHBOOKS]: SpendSourceName.FreshBooks,
    [CodatPlatformType.EXACT_ONLINE_UK]: SpendSourceName.ExactOnline,
    [CodatPlatformType.EXACT_ONLINE_NL]: SpendSourceName.ExactOnline,
  };

  return platformType ? platformToSourceNameMap[platformType] : undefined;
};
