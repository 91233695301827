import { FC } from 'react';
import { colors } from 'shared/theme';

export const ClipboardDoneIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.91666 2.33333H9.22833C9.10798 1.99293 8.88529 1.69808 8.59079 1.48921C8.29628 1.28035 7.94438 1.16768 7.58333 1.16667H6.41667C6.05561 1.16768 5.70371 1.28035 5.40921 1.48921C5.11471 1.69808 4.89201 1.99293 4.77167 2.33333H4.08333C3.6192 2.33333 3.17408 2.51771 2.8459 2.8459C2.51771 3.17408 2.33333 3.6192 2.33333 4.08333V11.0833C2.33333 11.5475 2.51771 11.9926 2.8459 12.3208C3.17408 12.649 3.6192 12.8333 4.08333 12.8333H9.91666C10.3808 12.8333 10.8259 12.649 11.1541 12.3208C11.4823 11.9926 11.6667 11.5475 11.6667 11.0833V4.08333C11.6667 3.6192 11.4823 3.17408 11.1541 2.8459C10.8259 2.51771 10.3808 2.33333 9.91666 2.33333ZM5.83333 2.91667C5.83333 2.76196 5.89479 2.61358 6.00419 2.50419C6.11358 2.39479 6.26196 2.33333 6.41667 2.33333H7.58333C7.73804 2.33333 7.88641 2.39479 7.99581 2.50419C8.10521 2.61358 8.16666 2.76196 8.16666 2.91667V3.5H5.83333V2.91667ZM10.5 11.0833C10.5 11.238 10.4385 11.3864 10.3291 11.4958C10.2197 11.6052 10.0714 11.6667 9.91666 11.6667H4.08333C3.92862 11.6667 3.78025 11.6052 3.67085 11.4958C3.56146 11.3864 3.5 11.238 3.5 11.0833V4.08333C3.5 3.92862 3.56146 3.78025 3.67085 3.67085C3.78025 3.56146 3.92862 3.5 4.08333 3.5H4.66667V4.08333C4.66667 4.23804 4.72812 4.38642 4.83752 4.49581C4.94692 4.60521 5.09529 4.66667 5.25 4.66667H8.75C8.90471 4.66667 9.05308 4.60521 9.16248 4.49581C9.27187 4.38642 9.33333 4.23804 9.33333 4.08333V3.5H9.91666C10.0714 3.5 10.2197 3.56146 10.3291 3.67085C10.4385 3.78025 10.5 3.92862 10.5 4.08333V11.0833Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.2574 5.91219C9.35864 6.01733 9.35864 6.1878 9.2574 6.29294L6.40555 9.25448C6.3043 9.35962 6.14014 9.35962 6.0389 9.25448L4.7426 7.90832C4.64135 7.80318 4.64135 7.63271 4.7426 7.52757C4.84385 7.42243 5.008 7.42243 5.10925 7.52757L6.22222 8.68335L8.89075 5.91219C8.992 5.80705 9.15615 5.80705 9.2574 5.91219Z'
        fill={fill}
        stroke='white'
        strokeWidth='0.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

ClipboardDoneIcon.defaultProps = {
  fill: colors.white,
  height: '14',
  width: '14',
};
