import { Box, styled } from '@mui/material';

export const MaterialReactTableWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&::-webkit-scrollbar': {
    height: theme.spacing(1),
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.secondary.main,
  },
  '& .MuiTableCell-root': {
    '&, &.MuiTableCell-pinned': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  '& td span.MuiTypography-root': {
    display: 'inline-block',
  },
  '& .MuiTableContainer-root': {
    overflowX: 'auto',
    minHeight: 200,
    '&, & + .MuiBox-root': {
      boxShadow: 'none',
    },
  },
  '& .MuiPaper-root': {
    boxShadow: 'none',
    width: '100%',
  },
  '& .MuiSkeleton-root': {
    marginTop: 6,
    marginBottom: 7,
  },
}));
