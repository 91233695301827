import { Box } from '@mui/material';
import { FC } from 'react';

import { TabsPanelsProps } from './tabs-panels.props';

export const TabsPanels: FC<TabsPanelsProps> = ({ item: { content, label }, value }) => (
  <Box
    key={`page-tab-panel-${label}`}
    id={`page-tabpanel-${value}`}
    sx={{ minHeight: '100%' }}
    data-testid={`page-tabpanel-${value}`}
    aria-labelledby={`page-tab-${value}`}>
    {content}
  </Box>
);
