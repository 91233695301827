import { Box, Icon, IconButton, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SpendModalHeaderProps } from './spend-modal-header.component.props';
import { SpendModalHeaderContainer } from './spend-modal-header.styles';

export const SpendModalHeader: FC<SpendModalHeaderProps> = ({
  closeModal,
  enableDownload,
  handleDownloadFile,
  showActions = true,
  subtitle,
  title,
}) => {
  const { t } = useTranslation();
  const reviewSpendTranslation = 'common:modals.review_spend_modal';

  return (
    <SpendModalHeaderContainer display='flex' flexDirection='column' data-testid='spend-review-modal'>
      <Box className='spend-review-modal--wrapper'>
        <Box className='spend-review-modal-header'>
          <Box className='invoice-data' display='flex' flexDirection='column'>
            <Typography className='invoice-title' data-testid='title'>
              {title}
            </Typography>
            <Typography mt={1} className='invoice-subtitle' data-testid='subtitle'>
              {subtitle}
            </Typography>
          </Box>
        </Box>
        {showActions && (
          <Box className='spend-review-modal--icons'>
            <Tooltip
              title={t(`${reviewSpendTranslation}.download_tooltip_text`)}
              placement='top'
              arrow
              className='is-flex'>
              <IconButton className='download-icon' disabled={!enableDownload} onClick={handleDownloadFile}>
                <Icon>save_alt</Icon>
              </IconButton>
            </Tooltip>
            <IconButton onClick={closeModal} className='close-icon'>
              <Icon>close</Icon>
            </IconButton>
          </Box>
        )}
      </Box>
    </SpendModalHeaderContainer>
  );
};
