import { Dispatch } from 'redux';

import { ModalStore } from '../../../models/modal-store.model';
import { ModalAction } from './modal.action.payload';
import { ModalActionTypes } from './modal.action.types';

export const updateFooterState = (payload: ModalStore) => {
  return (dispatch: Dispatch<ModalAction>): void => {
    dispatch({
      payload,
      type: ModalActionTypes.UPDATE_FOOTER_STATE,
    });
  };
};

export const openSubscriptionRenewalActionModal = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ModalActionTypes.OPEN_SUBSCRIPTION_RENEWAL_ACTION_RENEWAL_MODAL,
    });
  };
};

export const closeSubscriptionRenewalActionModal = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ModalActionTypes.CLOSE_SUBSCRIPTION_RENEWAL_ACTION_RENEWAL_MODAL,
    });
  };
};

export const openSubscriptionCancelActionModal = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ModalActionTypes.OPEN_SUBSCRIPTION_RENEWAL_ACTION_CANCEL_MODAL,
    });
  };
};

export const closeSubscriptionCancelActionModal = () => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: ModalActionTypes.CLOSE_SUBSCRIPTION_RENEWAL_ACTION_CANCEL_MODAL,
    });
  };
};
