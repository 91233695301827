import { styled } from '@mui/material';

export const DatePickerWrapper = styled('div')(({ theme }) => ({
  '& .MuiTextField-root': {
    width: '100%',
  },

  '& input': {
    border: '1px solid #fff',
    color: theme.palette.text.primary,
    height: theme.spacing(3),
    paddingLeft: theme.spacing(1),
  },

  '&.is-danger .MuiInputAdornment-root svg': {
    fill: theme.palette.error,
  },

  '&.is-danger .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.error,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.error,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.error,
    },
  },
}));
