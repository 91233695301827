/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@mui/material';
import { Autocomplete, DatePicker, InputField, RadioGroup } from 'asteroids';
import { DropzoneComponent } from 'components/drawer-workflow-request/components/dropzone/dropzone.component';
import { NumberInput } from 'components/drawer-workflow-request/components/number-input/number-input.component';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FlexColumn } from '../../contact-sastrify-form.component.styles';
import {
  billingCycles,
  commitmentLengths,
  existingSolutionRadioGroupOptions,
  pathToTranslation,
} from '../../contact-sastrify-form.constants';
import { NegotiationSupportFormProps } from './negotiation-support-form.component.props';
import { formValidationSchema } from './negotiation-support-form.validation-schema';

export const NegotiationSupportFormComponent: React.FC<NegotiationSupportFormProps> = ({ formRef }) => {
  const { t } = useTranslation();
  const dropzoneRef = useRef<any>(null);
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      approvalLength: '',
      billingCycle: '',
      commercialTarget: '',
      commitmentLength: '',
      contractSignatory: '',
      decisionDate: '',
      existingSolution: 'yes',
      licenseType: '',
      numberOfLicenses: '',
      otherToolConsideration: '',
      vendorInformation: '',
    },
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  formRef.current = {
    getValues: () => ({
      ...getValues(),
      documents: dropzoneRef.current?.getFiles(),
    }),
    trigger,
  };

  return (
    <>
      <FlexColumn item xs={6} data-testid='decision-date'>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.decision_data.label`)}
        </Typography>
        <Controller
          name='decisionDate'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                value={value as any}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                minDate={new Date() as any}
                label={t(`${pathToTranslation}.fields.decision_data.placeholder`)}
                error={Boolean(errors.decisionDate)}
                helperText={errors.decisionDate ? t(`${pathToTranslation}.errors.required_field`) : ''}
                inputFormat='dd/MM/yyyy'
              />
            );
          }}
        />
      </FlexColumn>
      <Grid item xs={6} data-testid='replacing-sas-solution-radio-buttons'>
        <Typography component='p' variant='label' mb={2}>
          {t(`${pathToTranslation}.fields.existing_solution.label`)}
        </Typography>
        <Controller
          name='existingSolution'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <RadioGroup
                value={value}
                options={existingSolutionRadioGroupOptions}
                row
                onChange={(value) => onChange(value)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} data-testid='attach-documents'>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.attach_documents.label`)}
        </Typography>
        <DropzoneComponent dropzoneRef={dropzoneRef} />
        <Typography variant='small' mt={0.5}>
          {t(`${pathToTranslation}.fields.attach_documents.helper_text`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.commercial_target.label`)}
        </Typography>
        <Controller
          name='commercialTarget'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='commercial-target'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.commercial_target.placeholder`)}
                multiline
                rows={2}
                fullWidth
                error={!!errors.commercialTarget}
                helperText={errors.commercialTarget ? t(`${pathToTranslation}.errors.required_field`) : ''}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.other_tool_consideration.label`)}
        </Typography>
        <Controller
          name='otherToolConsideration'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='other-tool-consideration'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.other_tool_consideration.placeholder`)}
                multiline
                rows={2}
                fullWidth
              />
            );
          }}
        />
      </Grid>
      <FlexColumn item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.license_type.label`)}
        </Typography>
        <Controller
          name='licenseType'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <InputField
                id='license-type'
                value={value}
                onChange={(value: any) => onChange(value)}
                onClearValue={() => onChange('')}
                label={t(`${pathToTranslation}.fields.license_type.placeholder`)}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.number_of_licenses.label`)}
        </Typography>
        <Controller
          name='numberOfLicenses'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <NumberInput
                name='numberOfLicenses'
                thousandSeparator
                value={value || ''}
                onValueChange={(value) => onChange(value.floatValue)}
                onClear={() => onChange('')}
                label={t(`${pathToTranslation}.fields.number_of_licenses.placeholder`)}
                helperText={errors.numberOfLicenses ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.numberOfLicenses)}
              />
            );
          }}
        />
      </FlexColumn>
      <Grid item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.commitment_length.label`)}
        </Typography>
        <Controller
          name='commitmentLength'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='commitmentLength'
                label={t(`${pathToTranslation}.fields.commitment_length.placeholder`)}
                value={value}
                options={commitmentLengths}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                onInputChange={(_, value: any) => {
                  onChange(value);
                }}
                helperText={errors.commitmentLength ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.commitmentLength)}
                creatable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.billing_cycle.label`)}
        </Typography>
        <Controller
          name='billingCycle'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='billingCycle'
                label={t(`${pathToTranslation}.fields.billing_cycle.placeholder`)}
                value={value}
                options={billingCycles}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                onInputChange={(_, value: any) => {
                  onChange(value);
                }}
                helperText={errors.billingCycle ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.billingCycle)}
                creatable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.vendor_information.label`)}
        </Typography>
        <Controller
          name='vendorInformation'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='vendor-information'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.vendor_information.placeholder`)}
                multiline
                rows={2}
                fullWidth
                error={!!errors.vendorInformation}
                helperText={errors.vendorInformation ? t(`${pathToTranslation}.errors.required_field`) : ''}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.contract_signatory.label`)}
        </Typography>
        <Controller
          name='contractSignatory'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='contract-signatory'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.contract_signatory.placeholder`)}
                multiline
                rows={2}
                fullWidth
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.approval_length.label`)}
        </Typography>
        <Controller
          name='approvalLength'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='approval-length'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.approval_length.placeholder`)}
                multiline
                rows={2}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </>
  );
};
