import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppUrl } from 'src/constants/appurl';

export interface FeatureFlagsType {
  companyFF: { [key: string]: boolean };
}

export const initialState: FeatureFlagsType = {
  companyFF: {},
};

const FeatureFlags = createSlice({
  initialState,
  name: 'featureFlags',
  reducers: {
    setCompanyFeatureFlags(state, action: PayloadAction<FeatureFlagsType['companyFF']>) {
      const companyFF = action.payload;
      state.companyFF = companyFF;

      AppUrl.isLoading = false;
      AppUrl.companyFeatureFlags = companyFF;
    },
  },
});

export const { setCompanyFeatureFlags } = FeatureFlags.actions;
export default FeatureFlags.reducer;
