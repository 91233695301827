import { createSlice } from '@reduxjs/toolkit';
import { PricingPlanFeature } from 'libs/models';

const initialState: PricingPlanFeature[] = [];

const PricingPlansFeaturesSlice = createSlice({
  initialState,
  name: 'pricingPlansFeatures',
  reducers: {
    setPricingPlansFeatures: (_state, action) => action.payload,
  },
});

export const PricingPlansFeaturesReducer = PricingPlansFeaturesSlice.reducer;
export const { setPricingPlansFeatures } = PricingPlansFeaturesSlice.actions;
