import { Avatar, Badge, CircularProgress, Tooltip as MuiTooltip } from '@mui/material';
import classnames from 'classnames';
import { AlertMailIcon, WrappedEditPenIcon } from 'components/icons';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-tooltip-lite';
import { getFirstAndLastInitialsFromText } from 'shared/helpers/common.helper';

import { ToolOwnerProps } from './tool-owner.props';

export const ToolOwner: FC<ToolOwnerProps> = ({
  avatarUrl,
  classes,
  containerDataTestid,
  handleUnassignedToolOwnerClick,
  hasHoverStyle,
  isCondensed,
  isRequester,
  isStatusTooltipOpen,
  isSubscriptionDetailPage,
  isSubscriptionInDiscovery,
  isUpdatingToolOwner,
  isViewOnly,
  name,
  onEnterOrSpaceKeyUp,
  ownerNameTooltipOpen,
  setIsStatusTooltipOpen,
  setOwnerNameTooltipOpen,
  subscription,
}) => {
  const { t } = useTranslation();
  const tooltipText = t('common:assign_tool_owner_feature.tooltip.not_yet_invited_user_text');

  const isToolOwner = !subscription?.ownerId && !isCondensed ? 'not_invited_tool_owner_text' : 'tool_owner_text';
  const TranslationText = t(`common:assign_tool_owner_feature.${isToolOwner}`);

  const ownerText = `${name && !isCondensed && name} (${
    isSubscriptionInDiscovery ? t('common:assign_tool_owner_feature.requester_text') : TranslationText
  })`;

  return (
    <div
      data-testid={containerDataTestid}
      className={classnames('subscription-owner subscription-owner--add is-clickable ', {
        'is-7 is-12-mobile is-flex is-align-items-center': !isSubscriptionDetailPage,
        'mt-2': isSubscriptionDetailPage,
      })}
      tabIndex={0}
      role='button'
      onKeyUp={(e) => onEnterOrSpaceKeyUp(e, handleUnassignedToolOwnerClick)}
      onClick={
        isViewOnly
          ? undefined
          : (event) => handleUnassignedToolOwnerClick(event as unknown as MouseEvent<HTMLDivElement, MouseEvent>)
      }>
      <Tooltip
        content={name}
        isOpen={!isSubscriptionDetailPage && ownerNameTooltipOpen}
        className={classes.toolownerTooltip}>
        <div className={classes.toolOwnerWrapper}>
          <Badge
            overlap='circular'
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            invisible={Boolean(subscription?.ownerId ?? isRequester)}
            badgeContent={
              !subscription?.ownerId &&
              !isRequester && (
                <Tooltip content={tooltipText} isOpen={isStatusTooltipOpen}>
                  <span
                    tabIndex={0}
                    role='button'
                    onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => setIsStatusTooltipOpen(!isStatusTooltipOpen))}
                    onClick={() => setIsStatusTooltipOpen(!isStatusTooltipOpen)}
                    onMouseEnter={() => setIsStatusTooltipOpen(!isStatusTooltipOpen)}
                    onMouseLeave={() => setIsStatusTooltipOpen(!isStatusTooltipOpen)}>
                    <AlertMailIcon />
                  </span>
                </Tooltip>
              )
            }>
            <Avatar
              src={avatarUrl}
              className={classes.toolOwner}
              onMouseEnter={() => setOwnerNameTooltipOpen(true)}
              onMouseLeave={() => setOwnerNameTooltipOpen(false)}
              onClick={() => setOwnerNameTooltipOpen(false)}>
              {getFirstAndLastInitialsFromText(name as string, true)}
            </Avatar>
            {hasHoverStyle && (
              <MuiTooltip title={String(name)} arrow placement='top'>
                <div className='avatar-overlay' />
              </MuiTooltip>
            )}
          </Badge>
          {isSubscriptionDetailPage && (
            <>
              <p className='owner-name-text'>{ownerText}</p>
              {!isViewOnly && <WrappedEditPenIcon width={18} height={18} />}
            </>
          )}
        </div>
      </Tooltip>
      {isUpdatingToolOwner && (
        <CircularProgress classes={{ colorPrimary: classes.circularProgress }} size='1rem' className='ml-3' />
      )}
    </div>
  );
};
