/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@mui/material';
import { Autocomplete, InputField } from 'asteroids';
import { DropzoneComponent } from 'components/drawer-workflow-request/components/dropzone/dropzone.component';
import { NumberInput } from 'components/drawer-workflow-request/components/number-input/number-input.component';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FlexColumn } from '../../contact-sastrify-form.component.styles';
import {
  benchmarkChangeInPlans,
  benchmarkCommitmentLengths,
  benchmarkGoals,
  benchmarkGoalsMapping,
  benchmarkPaymentCycles,
  pathToTranslation,
} from '../../contact-sastrify-form.constants';
import { RequestRenewalBenchmarkFormProps } from './request-renewal-benchmark-form.component.props';
import { formValidationSchema } from './request-renewal-benchmark-form.validation-schema';

export const RequestRenewalBenchmarkFormComponent: React.FC<RequestRenewalBenchmarkFormProps> = ({ formRef }) => {
  const { t } = useTranslation();
  const latestDocumentsDropzoneRef = useRef<any>(null);
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      benchmarkDetails: '',
      benchmarkGoal: '',
      changeInPlans: '',
      commitmentLengthValue: '',
      newBenchmarkVolume: '',
      preferredCommitmentLength: 'Months',
      preferredPaymentCycle: '',
    },
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  const benchmarkGoal = watch('benchmarkGoal');
  const isNewRequirements = benchmarkGoal === benchmarkGoalsMapping.what_is_good_price_new_requirements;

  formRef.current = {
    getValues: () => ({
      benchmarkDetails: getValues('benchmarkDetails'),
      benchmarkGoal: getValues('benchmarkGoal'),
      documents: latestDocumentsDropzoneRef.current?.getFiles(),
      ...(isNewRequirements && {
        changeInPlans: getValues('changeInPlans'),
        commitmentLengthValue: getValues('commitmentLengthValue'),
        newBenchmarkVolume: getValues('newBenchmarkVolume'),
        preferredCommitmentLength: getValues('preferredCommitmentLength'),
        preferredPaymentCycle: getValues('preferredPaymentCycle'),
      }),
    }),
    trigger: () =>
      trigger([
        'benchmarkGoal',
        ...((isNewRequirements
          ? [
              'changeInPlans',
              'commitmentLengthValue',
              'newBenchmarkVolume',
              'preferredCommitmentLength',
              'preferredPaymentCycle',
            ]
          : []) as any),
      ]),
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography component='p' variant='h3' my={2}>
          {t(`${pathToTranslation}.form_sections.documents_section`)}
        </Typography>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.latest_documents.label`)}
        </Typography>
        <DropzoneComponent dropzoneRef={latestDocumentsDropzoneRef} />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='h3' my={2}>
          {t(`${pathToTranslation}.form_sections.questionnaire_section`)}
        </Typography>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.benchmark_goal.label`)}
        </Typography>
        <Controller
          name='benchmarkGoal'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='benchmarkGoal'
                label={t(`${pathToTranslation}.fields.benchmark_goal.placeholder`)}
                value={value}
                options={benchmarkGoals}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={errors.benchmarkGoal ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.benchmarkGoal)}
              />
            );
          }}
        />
      </Grid>
      {isNewRequirements && (
        <>
          <Grid item xs={12}>
            <Typography component='p' variant='label' mb={1.5}>
              {t(`${pathToTranslation}.fields.benchmark_change_in_plans.label`)}
            </Typography>
            <Controller
              name='changeInPlans'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    id='changeInPlans'
                    label={t(`${pathToTranslation}.fields.benchmark_change_in_plans.placeholder`)}
                    value={value}
                    options={benchmarkChangeInPlans}
                    onChange={(_, data: any) => {
                      onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                    }}
                    helperText={errors.changeInPlans ? t(`${pathToTranslation}.errors.required_field`) : ''}
                    error={Boolean(errors.changeInPlans)}
                  />
                );
              }}
            />
          </Grid>
          <FlexColumn item xs={12}>
            <Typography component='p' variant='label' mb={1.5}>
              {t(`${pathToTranslation}.fields.benchmark_new_volume.label`)}
            </Typography>
            <Controller
              name='newBenchmarkVolume'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <InputField
                    id='newBenchmarkVolume'
                    value={value}
                    onChange={(value: any) => onChange(value)}
                    onClearValue={() => onChange('')}
                    label={t(`${pathToTranslation}.fields.benchmark_new_volume.placeholder`)}
                    helperText={
                      errors.newBenchmarkVolume
                        ? t(`${pathToTranslation}.errors.required_field`)
                        : t(`${pathToTranslation}.fields.benchmark_new_volume.helper_text`)
                    }
                    error={Boolean(errors.newBenchmarkVolume)}
                    fullWidth
                  />
                );
              }}
            />
          </FlexColumn>
          <FlexColumn item xs={6}>
            <Typography component='p' variant='label' mb={1.5}>
              {t(`${pathToTranslation}.fields.benchmark_commitment_length.label`)}
            </Typography>
            <Controller
              name='preferredCommitmentLength'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    id='preferredCommitmentLength'
                    label={t(`${pathToTranslation}.fields.benchmark_commitment_length.placeholder`)}
                    value={value}
                    options={benchmarkCommitmentLengths}
                    onChange={(_, data: any) => {
                      onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                    }}
                    helperText={
                      errors.preferredCommitmentLength
                        ? t(`${pathToTranslation}.errors.required_field`)
                        : t(`${pathToTranslation}.fields.benchmark_commitment_length.helper_text`)
                    }
                    error={Boolean(errors.preferredCommitmentLength)}
                  />
                );
              }}
            />
          </FlexColumn>
          <FlexColumn item xs={6} mt={3.75}>
            <Controller
              name='commitmentLengthValue'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <NumberInput
                    name='commitmentLengthValue'
                    thousandSeparator
                    value={value || ''}
                    onValueChange={(value) => onChange(value.floatValue)}
                    onClear={() => onChange('')}
                    label={t(`${pathToTranslation}.fields.benchmark_commitment_length_number.placeholder`)}
                    error={Boolean(errors.commitmentLengthValue)}
                    helperText={errors.commitmentLengthValue ? t(`${pathToTranslation}.errors.required_field`) : ''}
                  />
                );
              }}
            />
          </FlexColumn>
          <Grid item xs={12}>
            <Typography component='p' variant='label' mb={1.5}>
              {t(`${pathToTranslation}.fields.benchmark_payment_cycle.label`)}
            </Typography>
            <Controller
              name='preferredPaymentCycle'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    id='preferredPaymentCycle'
                    label={t(`${pathToTranslation}.fields.benchmark_payment_cycle.placeholder`)}
                    value={value}
                    options={benchmarkPaymentCycles}
                    onChange={(_, data: any) => {
                      onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                    }}
                    helperText={
                      errors.preferredPaymentCycle
                        ? t(`${pathToTranslation}.errors.required_field`)
                        : t(`${pathToTranslation}.fields.benchmark_payment_cycle.helper_text`)
                    }
                    error={Boolean(errors.preferredPaymentCycle)}
                  />
                );
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.benchmark_details.label`)}
        </Typography>
        <Controller
          name='benchmarkDetails'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='benchmarkDetails'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.benchmark_details.placeholder`)}
                multiline
                rows={3}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </>
  );
};
