import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  title: string;
  paragraph: string;
  onButtonClick: () => void;
};

export const AuthError: React.FC<IProps> = (props) => {
  const { onButtonClick, paragraph, title } = props;

  const { t } = useTranslation();

  return (
    <div className='sastrify-auth-error'>
      <div className='sastrify-auth-error__container'>
        <h1 className='sastrify-auth-error__title'>{title}</h1>

        <p>{paragraph}</p>

        <div className='sastrify-auth-error__actions'>
          <Button variant='contained' color='primary' data-testid='back-btn' onClick={onButtonClick}>
            {t('auth_error_view:logout_text')}
          </Button>
        </div>
      </div>
    </div>
  );
};
