import classnames from 'classnames';
import { FC, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { PAGE_CONTAINER_WITH_FOOTER_CLASS } from '../../page.component';
import { PageFooterProps } from './page-footer.props';
import { StyledPageFooterContainer } from './page-footer.styles';

const PageFooterComponent: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <StyledPageFooterContainer>
      <div className={classnames('page-footer-content')} data-testid='page-footer'>
        {children}
      </div>
    </StyledPageFooterContainer>
  );
};

export const PageFooter: FC<PageFooterProps> = ({ children, renderViaPortal }) => {
  const pageFooterPlaceholder = document.getElementById('page-footer-placeholder');
  const pageContainer = document.getElementById('page-container');

  useEffect(() => {
    return () => {
      if (renderViaPortal) pageContainer?.classList.remove(PAGE_CONTAINER_WITH_FOOTER_CLASS);
    };
  }, [pageContainer?.classList, renderViaPortal]);

  if (renderViaPortal && pageFooterPlaceholder) {
    pageContainer?.classList.add(PAGE_CONTAINER_WITH_FOOTER_CLASS);

    return createPortal(<PageFooterComponent>{children}</PageFooterComponent>, pageFooterPlaceholder);
  }

  return <PageFooterComponent>{children}</PageFooterComponent>;
};
