import { ConfirmationModalComponent, ConfirmationModalComponentProps } from 'components/confirmation-modal';
import { useCallback } from 'react';

import { ConfirmationModalComponentV2 } from '../components/confirmation-modal-v2/confirmation-modal-v2.component';
import { useModalAndNotification } from '.';

export enum ConfirmationModalVersion {
  V1 = 'V1',
  V2 = 'V2',
}
export const useConfirmationModal = (version = ConfirmationModalVersion.V1) => {
  const { onCloseModal, onShowModal } = useModalAndNotification();

  const openModal = useCallback(
    ({
      cancelButtonLabel,
      confirmButtonLabel,
      customHeader,
      customMessage,
      description,
      level,
      onCancel,
      onClickAway,
      onClose,
      onConfirm,
      title,
    }: ConfirmationModalComponentProps) => {
      if (onShowModal) {
        switch (version) {
          case ConfirmationModalVersion.V2:
            onShowModal({
              children: (
                <ConfirmationModalComponentV2
                  title={title}
                  customHeader={customHeader}
                  customMessage={customMessage}
                  description={description}
                  cancelButtonLabel={cancelButtonLabel}
                  confirmButtonLabel={confirmButtonLabel}
                  onClose={() => {
                    if (onClose) {
                      onClose();
                    }
                    onCloseModal();
                  }}
                  onCancel={onCancel}
                  onConfirm={onConfirm}
                />
              ),
              hasCustomFooter: true,
              isConfirmationV2Modal: true,
              onClickAway,
              showHeader: false,
            });
            break;
          default:
            onShowModal({
              children: (
                <ConfirmationModalComponent
                  title={title}
                  description={description}
                  cancelButtonLabel={cancelButtonLabel}
                  confirmButtonLabel={confirmButtonLabel}
                  onClose={() => {
                    if (onClose) {
                      onClose();
                    }
                    onCloseModal();
                  }}
                  onCancel={onCancel}
                  onConfirm={onConfirm}
                  level={level}
                />
              ),
              hasCustomFooter: true,
              showHeader: false,
            });
        }
      }
    },
    [version, onCloseModal, onShowModal]
  );

  return { onCloseModal, openModal };
};
