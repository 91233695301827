import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { FC } from 'react';

import { colors } from '../../theme/color.styles';
import { ChipProps } from './chip.props';

export const Chip: FC<ChipProps> = (props) => {
  return (
    <MuiChip
      {...(props as MuiChipProps)}
      sx={{
        ...props.sx,
        '&.MuiChip-filled-dark': {
          backgroundColor: colors.secondary.contrastText,
          color: colors.primary.contrastText,
        },
        '&.MuiChip-outlinedSecondary': {
          borderColor: colors.secondary.dark,
          color: colors.text.primary,
        },
      }}
    />
  );
};

export default Chip;
