import { Box, styled } from '@mui/material';

export const ConfirmationModalBox = styled(Box)(({ theme }) => ({
  '& .MuiTypography-body1': {
    color: theme.palette.text.secondary,
  },
  '& .MuiTypography-h2': {
    marginBottom: '2rem',
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

export const ActionsBox = styled(Box)(({ theme }) => ({
  '& .MuiButton-outlined, .MuiButton-outlined:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  '& .MuiButton-text': {
    color: theme.palette.text.secondary,
    padding: '0.313rem 1rem',
    textTransform: 'uppercase',
  },
  display: 'flex',
  justifyContent: 'end',
  marginTop: '2rem',
  width: '100%',
}));
