import { NoResultFeature, NoResultFeatureProps } from 'features/index';
import { useTranslation } from 'react-i18next';

interface NoInitiativeFoundProps {
  isSubscriptionInitiatives?: boolean;
}

export const NoInitiativeFound: React.FC<NoInitiativeFoundProps> = ({ isSubscriptionInitiatives }) => {
  const { t } = useTranslation();

  const NoResultFeatureProps: NoResultFeatureProps = {
    actionClassName: 'is-primary is-shadowed',
    className: 'no-results-container',
    isPageView: true,
    messages: [
      isSubscriptionInitiatives
        ? t(
            'subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.list.no_records.description'
          )
        : t('requests_view:when_no_tool_requests.sub_messages_section.first_sub_message_text'),
    ],
    title: isSubscriptionInitiatives
      ? t(
          'subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.list.no_records.title'
        )
      : t('requests_view:when_no_tool_requests.not_found_message_text'),
  };

  return <NoResultFeature {...NoResultFeatureProps} />;
};
