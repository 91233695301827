/* eslint-disable react/no-array-index-key */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Autocomplete, AutocompleteOption, Avatar, DatePicker, Icon } from 'asteroids';
import { AxiosError } from 'axios';
import { WorkflowRequestWrapper } from 'components/drawer-workflow-request/drawer-workflow-request.component.styles';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { TypeaheadComponent } from 'components/typeahead';
import { VendorSelectAsyncComponent } from 'components/vendor-select-async/vendor-select-async.component';
import { DialogContext } from 'context/dialog.context';
import { format } from 'date-fns';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CSM_USER_EMAIL,
  Currency,
  CurrencyOptions,
  DocumentCategory,
  DRAWER_VARIANT,
  REQUEST_STATUS,
  UserRoles,
} from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { trackWorkflowRequestCreated } from 'shared/logic/event-tracking/workflow-requests.events';
import {
  checkSubscriptionStatus,
  checkToolRequestStatus,
  createNewRequest,
  editRequest,
  getRequestDetails,
  getRequestWorkflows,
  getVendorItem,
} from 'shared/logic/requests.logic';
import { getCompanyDocumentCategory, uploadSubscriptionDocument } from 'shared/logic/subscription-item.logic';
import {
  Company,
  CompanyDocument,
  RequestDetails,
  RequestPayload,
  RequestResponse,
  RequestWorkflowItem,
  Subscription,
  ToolOwnerFilter,
  User,
  VendorType,
} from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { clearBenchmarkRequestVendorId } from 'shared/store/views/benchmarks/benchmark-request.slice';
import { AppUrl } from 'src/constants/appurl';
import { useAddRequestDrawer } from 'views/requests/hooks';

import { DropzoneComponent } from '../../dropzone/dropzone.component';
import { MultiRequestToolAlert } from '../../multi-requests-tool-alert/multi-request-tool-alert.component';
import { NumberInput } from '../../number-input/number-input.component';
import {
  alternativeToolsDefaultValue,
  defaultToolOwner,
  NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW,
  paymentFrequencyItems,
} from './tool-request-multitool-form.constants';
import { ToolOwner, ToolRequestMultiToolFormProps } from './tool-request-multitool-form.props';
import { defaultFormValues, formValidationSchema } from './tool-request-multitool-form.validation';

export const DRAWER_FROM_URL = 'routeFrom';

export const ToolRequestMultiToolForm: FC<ToolRequestMultiToolFormProps> = ({
  initialData,
  initiativeName,
  mode,
  onEmptyInitiativeName,
  showNotification,
  toggleDrawer,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { hash, pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const { openDialog } = useContext(DialogContext);
  const { onAddRequestDrawer } = useAddRequestDrawer();
  const theme = useTheme();
  const dispatch = useDispatch();

  const selectedBenchmarkVendorId = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.vendorId);

  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState(defaultToolOwner);
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [toolOwnerError, setToolOwnerError] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [isDocumentsUploadInProgress, setIsDocumentsUploadInProgress] = useState(false);
  const [existingRequestsOrSubscriptions, setExistingRequestsOrSubscriptions] = useState<Map<string, any>>(new Map([]));

  const dropzoneComponentRef = useRef<any>(null);

  const requestId = hash.includes(DRAWER_VARIANT.EDIT_REQUEST)
    ? hash.split(DRAWER_VARIANT.EDIT_REQUEST).slice(-1)[0]
    : '';
  const toolId = hash.includes(DRAWER_VARIANT.NEW_REQUEST) ? hash.split(DRAWER_VARIANT.NEW_REQUEST).slice(-1)[0] : '';
  const isEditMode = mode === 'edit';
  const isNewRequestMode = mode === 'create';

  const company = queryClient.getQueryData<Company>('company');
  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');
  const requests = queryClient.getQueryData<RequestWorkflowItem[]>('workflow-requests');
  const subscriptions: Subscription[] = queryClient.getQueryData<any>('subscriptions');

  const toolQuery = queryClient.getQueriesData('tools');
  const toolQueryParams = toolQuery?.[toolQuery.length - 1]?.[0];

  const isCreateInitiativeDisabled = [...existingRequestsOrSubscriptions.values()].some((requestOrSubscription) => {
    return (
      (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.ACTIVE ||
      (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.APPROVED ||
      Boolean((requestOrSubscription as Subscription).id) ||
      (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED
    );
  });

  const userOptions: AutocompleteOption[] = useMemo(
    () =>
      usersAndToolOwnersList
        ?.filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && user.id)
        .map(({ avatar, email, id, name }) => ({
          id: id || '',
          label: name || email || '',
          prefix: (
            <Avatar
              src={avatar}
              imgProps={{ sx: { background: theme.palette.background.light, objectFit: 'contain' } }}
            />
          ),
        })) || [],
    [theme.palette.background.light, usersAndToolOwnersList]
  );

  const invalidateTools = () => {
    if (pathname === '/tool-store') {
      queryClient.invalidateQueries({ queryKey: toolQueryParams });
    }
  };

  const handleCloseDrawer = () => {
    replace(`${pathname}${search}`);
    toggleDrawer();
  };

  const onModifySuccess = async (data: RequestResponse) => {
    invalidateTools();
    handleCloseDrawer();
    queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });

    const requestDetails = await queryClient.fetchQuery('workflow-request-details', () => getRequestDetails(data.id));
    const toolName =
      typeof getValues().mainTool === 'object'
        ? (getValues().mainTool as VendorType)?.name
        : (getValues().mainTool as string);
    const alternativeTools = getValues().alternativeTools?.map((tool) =>
      typeof tool === 'object' ? (tool?.name as string) : tool
    );
    trackWorkflowRequestCreated({
      alternativeTools,
      companyId: company?.id,
      companyName: company?.name,
      hasGenericDocuments: Boolean(requestDetails?.documents?.length),
      isMultiTool: Boolean(getValues().alternativeTools?.length),
      name: requestDetails.name,
      numberOfContributors: requestDetails?.contributors?.length || 0,
      requestType: 'newPurchase',
      toolName,
      totalNumberOfTools: requestDetails?.tools?.length || 0,
      totalStages: requestDetails?.timeline.length || 0,
    });

    openDialog({
      confirmBtnText: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.button_initiative_text'
      ),
      content: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_text'
      ),
      icon: (
        <Icon color='success' sx={{ mr: 1 }}>
          check_circle_outline
        </Icon>
      ),
      onConfirm: () => {
        onAddRequestDrawer(`#${DRAWER_VARIANT.REQUEST_DETAILS}${data.id}`);
      },
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_title'
      ),
    });
  };

  const onModifyError = (err: AxiosError) => {
    const message =
      getErrorCodeMessage(err) ||
      t('common:modals.add_new_subscription_feature_modal.error_message', {
        value: 'New initiative',
      });

    showNotification?.(message, NotificationAlertType.Error);
  };

  const { isLoading: isCreateLoading, mutate: mutateCreate } = useMutation(
    (payload: RequestPayload) => createNewRequest(payload),
    {
      onError: onModifyError,
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { isLoading: isEditLoading, mutate: mutateEdit } = useMutation(
    (payload: RequestPayload) => editRequest(payload),
    {
      onError: onModifyError,
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { mutateAsync: uploadDocumentAsync } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      setIsDocumentsUploadInProgress(false);
    },
  });

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });
  const { append: addNewAlternativeToolFields, replace: setAlternativeToolFields } = useFieldArray({
    control,
    name: 'alternativeTools',
  });
  const alternativeTools = watch('alternativeTools');

  const isEveryAlternativeToolFieldPopulated = alternativeTools.every((tool: any) => {
    if (!tool) return false;
    if (typeof tool === 'string') return Boolean(tool.trim());
    return true;
  });
  const getPaymentFrequencyItem = (id: string) => paymentFrequencyItems.find((item) => item.id === id);

  useQuery(['vendor-item', toolId], () => getVendorItem(toolId), {
    enabled: Boolean(toolId),
    onSuccess: (vendor) => {
      if (vendor) {
        setValue('mainTool', vendor as VendorType);
      }
    },
    refetchOnWindowFocus: false,
  });

  useQuery(
    ['vendor-item-benchmark', selectedBenchmarkVendorId],
    () => {
      if (selectedBenchmarkVendorId) {
        return getVendorItem(selectedBenchmarkVendorId);
      }
    },
    {
      enabled: Boolean(isNewRequestMode && selectedBenchmarkVendorId),
      onSuccess: (vendor) => {
        if (vendor) {
          handleToolChange(vendor as VendorType, 'mainTool');
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const getSelectedToolsIds = () => {
    const selectedTools = [getValues().mainTool, ...getValues().alternativeTools];

    return selectedTools.filter((tool) => typeof tool === 'object' && tool?.id).map((tool) => (tool as VendorType)?.id);
  };

  useEffect(() => {
    if (isEditMode && initialData) {
      const setFormValues = (data: RequestDetails) => {
        const collaborators = userOptions.filter(({ id }) =>
          data.contributors?.map(({ id }) => id).includes(Number(id))
        );
        setValue('collaborators', collaborators);
        setValue('cost', String((data?.licenseCents || 0) / 100));
        setValue('description', String(data.description));
        setValue('dueDate', new Date(data.dueDate || ''));
        setValue('numberOfLicenses', String(data?.licenseNumber || 0));
        if (data.licensePaymentFrequency) {
          setValue('paymentFrequency', getPaymentFrequencyItem(data.licensePaymentFrequency || '') || null);
        }
        if (data.childRequests) {
          const additionalToolFields: string[] = [];
          data.childRequests.forEach((childRequest, index) => {
            if (index === 0) {
              setValue('mainTool', childRequest.vendorName);
            } else {
              additionalToolFields.push(childRequest.vendorName);
            }
          });
          setAlternativeToolFields(additionalToolFields);
        }
      };

      const toolOwner =
        usersAndToolOwnersList
          ?.filter(({ name }) => name === initialData.assigneeFullName)
          .map(({ avatar, id, name }) => ({ avatar: avatar || '', id: id || '', name: name || '' }))[0] ||
        defaultToolOwner;

      setToolOwner(toolOwner);
      setToolOwnerName(toolOwner.name);
      setFormValues(initialData);
    }
  }, [isEditMode, initialData, usersAndToolOwnersList, setValue, setAlternativeToolFields, userOptions]);

  useEffect(() => {
    queryClient.invalidateQueries<Subscription[]>(['subscriptions-and-new-solutions']);
  }, [queryClient]);

  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList
        .filter((user: User) => user.role !== UserRoles.Viewer)
        .filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && user.id)
        .map((user: User) => ({
          avatar: user?.avatar,
          name: user.name,
          value: user.id || user.name?.replaceAll(' ', '-'),
        }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
      setToolOwnerError(false);
      return;
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner(defaultToolOwner);
      setToolOwnerName('');
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
    setToolOwnerError(false);
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleDocumentsUpload = async (): Promise<CompanyDocument[]> => {
    setIsDocumentsUploadInProgress(true);

    const uploads: unknown[] = [];
    const files = dropzoneComponentRef.current.getFiles();
    const category = getCompanyDocumentCategory(DocumentCategory.other_documents);
    const companyId = String(company?.id);

    files.forEach((file: File) => {
      const payload = {
        category,
        companyId,
        file,
        isCompanyDocument: true,
      };

      uploads.push(uploadDocumentAsync(payload));
    });

    return (await Promise.all(uploads)) as CompanyDocument[];
  };

  const onModifyRequest = async () => {
    trigger();

    if (!initiativeName) onEmptyInitiativeName?.();
    if (!toolOwnerName) setToolOwnerError(true);
    if (!isEditMode && (!initiativeName || !toolOwnerName)) return;
    if (!isValid) return;

    const formValues = getValues();
    const documents = !isEditMode ? await handleDocumentsUpload() : [];

    const tools = [formValues.mainTool, ...formValues.alternativeTools]
      .filter((tool) => (typeof tool === 'string' ? Boolean(tool.trim()) : Boolean(tool?.id)))
      .map((tool) => (typeof tool === 'string' ? { toolName: tool } : { vendorId: Number(tool?.id) }));

    const modifyRequestPayload: RequestPayload = {
      multiToolParentRequest: true,
      ...(initiativeName && { name: initiativeName }),
      ...(isEditMode && { id: Number(requestId || 0) || undefined }),
      contributors: formValues?.collaborators?.map(({ id }) => Number(id)) || [],
      description: formValues.description,
      documents,
      dueDate: formValues.dueDate
        ? format(new Date(formValues.dueDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
      licenseCurrency: company?.currency || '',
      ...(formValues.cost && { licenseCents: Number(formValues.cost) * 100 }),
      ...(formValues.numberOfLicenses && { licenseNumber: Number(formValues.numberOfLicenses) }),
      ...(formValues.paymentFrequency && { licensePaymentFrequency: formValues.paymentFrequency.id }),
      requestWorkflowTypeId: REQUEST_WORKFLOW_TYPE.APPROVAL,
      steps: [
        {
          stepOwnerId: Number(toolOwner.value) || Number(toolOwner.id),
        },
      ],
      tools,
    };

    return isEditMode ? mutateEdit(modifyRequestPayload) : mutateCreate(modifyRequestPayload);
  };

  const handleToolChange = (value: string | VendorType | null, field: string) => {
    setValue(field as any, value);

    const newExistingRequestsOrSubscriptions = new Map(existingRequestsOrSubscriptions);
    if (value && typeof value !== 'string') {
      const existingRequest = checkToolRequestStatus(value, requests);
      const existingSubscription = checkSubscriptionStatus(value, subscriptions);

      if (existingSubscription) {
        newExistingRequestsOrSubscriptions.set(String(field), existingSubscription);
      } else if (existingRequest) {
        newExistingRequestsOrSubscriptions.set(String(field), existingRequest);
      } else {
        newExistingRequestsOrSubscriptions.delete(String(field));
      }
    } else {
      const selectedTools = [getValues().mainTool, ...getValues().alternativeTools];
      const existingRequestsOrSubscriptionsValues = Array.from(newExistingRequestsOrSubscriptions.values());
      const existingRequestsOrSubscriptionsKeys = Array.from(newExistingRequestsOrSubscriptions.keys());

      existingRequestsOrSubscriptionsValues.forEach((requestOrSubscription, index) => {
        if (
          !selectedTools.find(
            (tool) => (typeof tool === 'string' ? tool : tool?.name) === requestOrSubscription?.vendorName
          )
        ) {
          newExistingRequestsOrSubscriptions.delete(existingRequestsOrSubscriptionsKeys[index]);
        }
      });
    }
    setExistingRequestsOrSubscriptions(newExistingRequestsOrSubscriptions);
  };

  const handleToolAlertOnView = (requestOrSubscription: RequestWorkflowItem | Subscription) => {
    let url = '';
    if ((requestOrSubscription as Subscription).id) {
      url = `${AppUrl.getToolDetailsUrl(requestOrSubscription as Partial<Subscription>)}`;
    } else if ((requestOrSubscription as RequestWorkflowItem).parentRequestId) {
      url = `/requests#requestDetails${(requestOrSubscription as RequestWorkflowItem).parentRequestId}`;
    } else if ((requestOrSubscription as RequestWorkflowItem).requestId) {
      url = `/requests#requestDetails${(requestOrSubscription as RequestWorkflowItem).requestId}`;
    }
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const renderMainToolField = () => (
    <Box display='flex' flexDirection='column' mt={3} data-testid='tool-name'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.input_label'
        )}
        *
      </Typography>
      <Controller
        control={control}
        name='mainTool'
        render={({ field: { onChange } }) => {
          return (
            <VendorSelectAsyncComponent
              shouldHandleInputChange={!selectedBenchmarkVendorId}
              value={getValues('mainTool') as string}
              getValue={(value: string | VendorType | null) => {
                onChange(value);
                handleToolChange(value, 'mainTool');
                if (selectedBenchmarkVendorId) {
                  dispatch(clearBenchmarkRequestVendorId());
                }
              }}
              loadDefaultVendors={false}
              noOptionsText={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.no_options_text'
              )}
              error={!!errors.mainTool}
              placeholder={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.placeholder_text'
              )}
              helperText={
                errors.mainTool
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.error_message'
                    )
                  : t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.helper_text'
                    )
              }
              excludeVendors={getSelectedToolsIds()}
              disabled={isEditMode || Boolean(toolId)}
              size='small'
            />
          );
        }}
      />
    </Box>
  );

  const renderVendorsEvaluationFields = () => (
    <Box sx={{ mt: '1.5rem' }} data-testid='vendor-evaluation'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.vendor_evaluation_new_section.label'
        )}
      </Typography>
      {Array(Math.ceil(alternativeTools.length / NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW))
        .fill('')
        .map((_, rowIndex) => (
          <Stack direction='row' spacing={2} key={`alternativeToolsRow-${rowIndex}`} mb={2}>
            {alternativeTools
              .slice(
                rowIndex * NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW,
                rowIndex * NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW + NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW
              )
              .map((_, cellIndex) => {
                const toolIndex = rowIndex * NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW + cellIndex;
                const name =
                  typeof `alternativeTools.${toolIndex}` === 'string'
                    ? `alternativeTools.${toolIndex}`
                    : `alternativeTools.${toolIndex}.name`;

                return (
                  <Controller
                    key={`alternativeTool-${toolIndex}`}
                    control={control}
                    name={name as any}
                    render={() => {
                      return (
                        <Grid item xs={4}>
                          <VendorSelectAsyncComponent
                            value={getValues(`alternativeTools.${toolIndex}`) as string}
                            getValue={(value: string | VendorType | null) => handleToolChange(value, name)}
                            loadDefaultVendors={false}
                            noOptionsText={t(
                              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.no_options_text'
                            )}
                            placeholder={t(
                              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.alternative_placeholder_text'
                            )}
                            excludeVendors={getSelectedToolsIds()}
                            size='small'
                          />
                        </Grid>
                      );
                    }}
                  />
                );
              })}
          </Stack>
        ))}
      <Box>
        <Button
          variant='text'
          startIcon={<Icon>add</Icon>}
          disabled={!isEveryAlternativeToolFieldPopulated}
          onClick={() => addNewAlternativeToolFields(alternativeToolsDefaultValue)}>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.vendor_evaluation_new_section.add_more_tools'
          )}
        </Button>
      </Box>
    </Box>
  );

  const renderToolAlerts = () => {
    return Array.from(existingRequestsOrSubscriptions.values()).map((requestOrSubscription) => (
      <Box mt={2} key={requestOrSubscription?.requestId || requestOrSubscription?.id}>
        <MultiRequestToolAlert
          requestOrSubscription={requestOrSubscription}
          onView={() => handleToolAlertOnView(requestOrSubscription)}
        />
      </Box>
    ));
  };

  const renderDescField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='description'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.input_label'
        )}
      </Typography>
      <Controller
        name='description'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              id='description'
              data-testid='description-input'
              onChange={onChange}
              value={value}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.multitool_placeholder_text'
              )}
              multiline
              rows={3}
              error={!!errors.description}
              helperText={
                errors.description
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:description_section:error_message'
                    )
                  : ''
              }
              size='small'
            />
          );
        }}
      />
    </Box>
  );

  const renderLicenseFields = () => (
    <Box sx={{ mt: '1.5rem' }}>
      <Grid container>
        <Grid item xs={4} data-testid='value'>
          <Typography variant='h3'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.value_section.multitool_input_label'
            )}
          </Typography>
          <Controller
            name='cost'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Box component='div' display='flex' flexDirection='column' mr={2}>
                  <NumberInput
                    name='cost'
                    label={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.value_section.placeholder_text'
                    )}
                    decimalScale={2}
                    decimalSeparator='.'
                    fixedDecimalScale
                    thousandSeparator
                    prefix={company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY}
                    value={value || ''}
                    onValueChange={(value) => onChange(value.floatValue)}
                    onClear={() => onChange('')}
                    error={Boolean(errors.cost)}
                    helperText={
                      errors.cost
                        ? t(
                            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:value_section:error_message'
                          )
                        : ''
                    }
                    size='small'
                  />
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item xs={4} data-testid='number-of-licenses'>
          <Typography variant='h3'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.number_of_licenses_section.input_label'
            )}
          </Typography>
          <Controller
            name='numberOfLicenses'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Box component='div' display='flex' flexDirection='column' mr={2}>
                  <NumberInput
                    name='numberOfLicenses'
                    thousandSeparator
                    value={value || ''}
                    onValueChange={(value) => onChange(value.floatValue)}
                    onClear={() => onChange('')}
                    label={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.number_of_licenses_section.placeholder_text'
                    )}
                    error={Boolean(errors.numberOfLicenses)}
                    helperText={
                      errors.numberOfLicenses
                        ? t(
                            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:number_of_licenses_section:error_message'
                          )
                        : ''
                    }
                    size='small'
                  />
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item xs={4} data-testid='payment-frequency'>
          <Typography variant='h3'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.payment_frequency_section.input_label'
            )}
          </Typography>
          <Controller
            name='paymentFrequency'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Box component='div' display='flex' flexDirection='column'>
                  <Autocomplete
                    id='paymentFrequency'
                    data-testid='payment-frequency-select'
                    label={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.payment_frequency_section.placeholder_text'
                    )}
                    value={value}
                    size='small'
                    options={paymentFrequencyItems}
                    onChange={(_, value) => onChange(value)}
                  />
                </Box>
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const renderAssigneeField = () => (
    <Box display='flex' flexDirection='column' data-testid='assignee'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.input_label'
        )}
        *
      </Typography>
      <TypeaheadComponent
        data={toolOwners}
        onItemSelected={onToolOwnerSelected}
        onBlurToolOwnerFilter={onToolOwnerSelected}
        value={toolOwnerName}
        fields={['name', 'email']}
        isToolOwnerFilter
        onTextChanged={onChangeToolOwner}
        isSubscriptionInDiscovery
        renderCustomInput={({
          autoFocus,
          disabled,
          inputTextRef,
          onChange,
          onClick,
          onFocus,
          onKeyDown,
          onKeyUp,
          value,
        }) => (
          <TextField
            id='assignee'
            inputRef={inputTextRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onFocus={(ev) => {
              onFocus?.(ev);
              setFocused(true);
            }}
            onBlur={() => setFocused(false)}
            onClick={onClick}
            disabled={disabled}
            value={value || ''}
            label={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.multitool_placeholder_text'
            )}
            autoFocus={autoFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                  {!isFocused ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>}
                </InputAdornment>
              ),
            }}
            error={toolOwnerError}
            helperText={
              toolOwnerError
                ? t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.assignee_section.error_message'
                  )
                : ''
            }
            fullWidth
            size='small'
          />
        )}
      />
    </Box>
  );

  const renderCollaboratorsField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='collaborators' width='100%'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.input_label'
        )}
      </Typography>
      <Controller
        name='collaborators'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='collaborators'
              filterSelectedOptions
              value={value}
              options={userOptions}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.multitool_placeholder_text'
              )}
              helperText={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.multitool_helper_text'
              )}
              multiple
              onChange={(_, newCollaborators, reason) => {
                if (reason === 'createOption' && Array.isArray(newCollaborators)) {
                  const autocompleteOptionCollaborators = newCollaborators.slice(0, -1) as AutocompleteOption[];
                  const lastItem = newCollaborators[newCollaborators.length - 1] as string;
                  const lastAutocompleteOptionCollaborator: AutocompleteOption = {
                    id: `${autocompleteOptionCollaborators.length}-${lastItem}`,
                    label: lastItem,
                  };
                  const collaborators: AutocompleteOption[] = [
                    ...autocompleteOptionCollaborators,
                    lastAutocompleteOptionCollaborator,
                  ];

                  return onChange(collaborators);
                }
                onChange(newCollaborators);
              }}
              size='small'
            />
          );
        }}
      />
    </Box>
  );

  const renderDateField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='due-date'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.input_label'
        )}
      </Typography>
      <Controller
        name='dueDate'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <DatePicker
              value={value as any}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              minDate={new Date() as any}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.placeholder_initiative_text'
              )}
              error={Boolean(errors.dueDate)}
              helperText={
                errors.dueDate
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.due_date_section.error_message'
                    )
                  : ''
              }
              inputFormat='dd/MM/yyyy'
              InputProps={{ size: 'small' }}
            />
          );
        }}
      />
    </Box>
  );

  const renderDocumentsField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='documents'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.documents_section.input_label'
        )}
      </Typography>
      <DropzoneComponent
        dropzoneRef={dropzoneComponentRef}
        helperText={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.documents_section.helper_text'
        )}
      />
    </Box>
  );

  const renderButtons = () => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: '3rem' }}>
      <Box sx={{ mr: '1rem' }}>
        <Button
          variant='text'
          color='secondary'
          onClick={() => {
            if (isEditMode) {
              push(`${pathname}${search}#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`);
            } else {
              handleCloseDrawer();
            }
          }}
          data-testid='cancel-button'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.action_buttons.cancel_button_text'
          )}
        </Button>
      </Box>
      <Button
        variant='contained'
        role='search'
        onClick={onModifyRequest}
        data-testid={isEditMode ? 'update-request-button' : 'send-request-button'}
        disabled={isCreateLoading || isEditLoading || isDocumentsUploadInProgress || isCreateInitiativeDisabled}>
        {isCreateLoading || isEditLoading || isDocumentsUploadInProgress ? (
          <CircularProgress color='inherit' size={20} />
        ) : (
          t(
            `subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.action_buttons.${
              isEditMode ? 'update_initiative_button_text' : 'submit_initiative_button_text'
            }`
          )
        )}
      </Button>
    </Box>
  );

  return (
    <WorkflowRequestWrapper>
      <section className='request-fields'>
        {renderAssigneeField()}
        {renderDescField()}
        {!isEditMode && renderMainToolField()}
        {!isEditMode && renderVendorsEvaluationFields()}
        {!isEditMode && renderToolAlerts()}
        {renderLicenseFields()}
        {renderCollaboratorsField()}
        {renderDateField()}
        {!isEditMode && renderDocumentsField()}
      </section>
      {(isEditMode || isNewRequestMode) && renderButtons()}
    </WorkflowRequestWrapper>
  );
};
