import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/store/hooks';

import { UsageAnalyticsInformation } from '../components/usage-analytics-information.component';

const UsageAnalyticsInformationSso: FC = () => {
  const { t } = useTranslation();
  const selectedSsoProvider = useAppSelector((state) => state.views.connect.usageAnalyticsItem.selectedSsoProvider);

  const selectedProvider = {
    clickHereUrl: selectedSsoProvider?.clickHereUrl || '',
    name: selectedSsoProvider?.ssoProviderName || '',
    requiredPlanDescription: selectedSsoProvider?.requiredPlanDescription || '',
    requiredPlanTitle: selectedSsoProvider?.requiredPlanTitle || '',
  };

  return (
    <UsageAnalyticsInformation
      selectedProvider={selectedProvider}
      usageSectionTitle={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_1.title')}
      usageSectionText={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_1.sso', {
        ssoProviderName: selectedProvider.name,
      })}
      infoCollectedSectionTitle={t(
        'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.sso.title'
      )}
      infoCollectedSectionText={t(
        'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.sso.text'
      )}
    />
  );
};

export default React.memo(UsageAnalyticsInformationSso);
