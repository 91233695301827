/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { User } from 'shared/models';
import { ActionMap } from 'shared/store/action.map';

import { apiService, apiUrl } from '../../../services';
import { UserActionPayloads } from './user.action.payload';
import { UserActionTypes } from './user.action.types';

export const authenticate = (token: string, avatarUrl?: string): any => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionTypes.AuthenticationRequest,
    });

    apiService.setToken(token);

    apiService
      .post(apiUrl.Auth, { token })
      .then((response: AxiosResponse) => {
        const user: User = {
          ...response.data,
          avatarUrl,
          token,
        };

        dispatch({
          payload: { user },
          type: UserActionTypes.AuthenticationComplete,
        });
      })
      .catch((exception) => {
        dispatch({
          payload: {
            error: exception.response?.data?.error || 'An unknown error has occurred.',
            user: exception.response?.data?.data?.user,
          },
          type: UserActionTypes.AuthenticationError,
        });
      });
  };
};

export const logout = (): any => {
  apiService.setToken(undefined);

  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionTypes.Logout,
    });
  };
};

export const forcedLogout = (error: string): any => {
  apiService.setToken(undefined);

  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      payload: { error },
      type: UserActionTypes.ForcedLogout,
    });
  };
};
export type UserAction = ActionMap<UserActionPayloads>[keyof ActionMap<UserActionPayloads>];
