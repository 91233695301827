import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button, Grid } from '@mui/material';
import { NoSubscriptionTaskIcon } from 'components/icons';
import { SubscriptionTaskComponent, SubscriptionTaskProgressBarComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { SubscriptionTasksSkeleton } from 'components/skeletons';
import { ModalAndNotificationsContext } from 'context/modal-notification.context';
import { useFetchData } from 'hooks/index';
import { useMarkTaskAsDone } from 'hooks/useMarkTaskAsDone';
import { useUser } from 'hooks/useUser';
import { FC, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { googleTagManagerEvents } from 'shared/common.definitions';
import { decodeIdFromRoute } from 'shared/helpers/common.helper';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import {
  getAllCompletedSubscriptionTodos,
  getAllOpenSubscriptionTodos,
  getSubscriptionTodos,
} from 'shared/logic/subscription-item.logic';
import { Subscription, Todo } from 'shared/models';
import { colors } from 'shared/theme';

import { CustomTaskForm } from '..';
import { SubscriptionTaskFeatureProps } from '.';
import { useShouldOpenCustomTask } from './hooks/should-open-custom-task.hook';

export const SubscriptionTaskFeature: FC<SubscriptionTaskFeatureProps> = (props) => {
  const { closeModal, onUpdateRenewalDate, openInviteUserDrawer, showModal, updateSubscriptionTodosCache } = props;

  const location = useLocation();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { onShowNotification: showNotification } = useContext(ModalAndNotificationsContext);

  const customTaskRef = useRef<{ onSubmit: () => void }>();

  const subscriptionId = decodeIdFromRoute(location.pathname);

  const subscription = queryClient.getQueryData<Subscription>(['subscription', subscriptionId]);

  const { markTodoAsDone } = useMarkTaskAsDone();

  const { data, isLoading } = useFetchData<Todo[]>('subscriptionTodos', () => getSubscriptionTodos(subscriptionId), {
    cacheTime: 0,
    onError: (error: unknown) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });

  const openTodos = getAllOpenSubscriptionTodos(data) || [];
  const completedTodos = getAllCompletedSubscriptionTodos(data) || [];

  const allTodos = [...openTodos, ...completedTodos];

  const user = useUser();

  const openCreateCustomTaskModal = () => {
    showModal?.({
      cancelButtonText: t(
        'subscription_detail_view:tabs_component_section.subscription_task_tab.tasks_sections.custom_task_section.modal.cancel_button_text'
      ),
      children: (
        <CustomTaskForm
          ref={customTaskRef}
          showNotification={showNotification}
          closeModal={closeModal}
          subscription={subscription}
        />
      ),
      confirmButtonText: t(
        'subscription_detail_view:tabs_component_section.subscription_task_tab.tasks_sections.custom_task_section.modal.confirm_button_text'
      ),
      onCancelButtonClick: () => closeModal?.(),
      onConfirmButtonClick: () => customTaskRef.current?.onSubmit(),
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_task_tab.tasks_sections.custom_task_section.modal.create_title_text'
      ),
    });

    fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.CustomTaskModalOpened);
  };

  useShouldOpenCustomTask(openCreateCustomTaskModal);

  const renderSubscriptionTasks = () => {
    return (
      <>
        {allTodos?.length > 0 ? (
          <>
            <Box mb='2rem'>
              <Grid container spacing={1} justifyContent='space-between'>
                <Grid item lg={7}>
                  <Button variant='contained' color='primary' onClick={openCreateCustomTaskModal}>
                    {t(
                      'subscription_detail_view:tabs_component_section.subscription_task_tab.create_custom_task_button'
                    )}
                  </Button>
                </Grid>
                <Grid item lg={5}>
                  <SubscriptionTaskProgressBarComponent
                    completedTodos={completedTodos.length}
                    allTodos={allTodos.length}
                    subscription={subscription}
                  />
                </Grid>
              </Grid>
            </Box>
            {allTodos?.map((todo) => {
              return (
                <SubscriptionTaskComponent
                  key={todo.id}
                  onUpdateRenewalDate={onUpdateRenewalDate}
                  markTodoAsDone={markTodoAsDone}
                  todo={todo}
                  subscription={subscription as Subscription}
                  showModal={showModal}
                  closeModal={closeModal}
                  showNotification={showNotification}
                  updateSubscriptionTodosCache={updateSubscriptionTodosCache}
                  openInviteUserDrawer={openInviteUserDrawer}
                />
              );
            })}
          </>
        ) : (
          <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%'>
            <NoSubscriptionTaskIcon />
            <Box fontWeight='500' fontSize='1rem' lineHeight='1rem' color={colors.riverBed} mt='0.9rem' mb='2.813rem'>
              {t('subscription_detail_view:tabs_component_section.subscription_task_tab.no_task_message')}
            </Box>
          </Box>
        )}
      </>
    );
  };

  return <section>{isLoading ? <SubscriptionTasksSkeleton /> : renderSubscriptionTasks()}</section>;
};
