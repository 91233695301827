import { Box, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailBridgeComponentProps } from '.';
import { EmailBridgeComponentWrapper } from './email-bridge.styles';

export const EmailBridgeComponent: FC<EmailBridgeComponentProps> = (props) => {
  const { handleClick, type } = props;
  const { t } = useTranslation();

  return (
    <EmailBridgeComponentWrapper>
      <Typography variant='h2' color='text.secondary' paragraph>
        {t('subscription_detail_view:tabs_component_section.subscription_spend_tab.email_bridge_section.title_text', {
          documentType: type,
        })}
      </Typography>
      <Box className='upload-documents-section'>
        <Typography variant='subtitle' sx={{ maxWidth: '73%' }} data-testid='email-body-text'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_spend_tab.email_bridge_section.body_message_text',
            { documentType: type }
          )}
        </Typography>
        <Box data-testid='forward-link' onClick={handleClick} className='forward-documents-button'>
          <Icon color='primary'>turn_right</Icon>
          <Typography variant='subtitle' color='primary' className='forward-documents-text'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_spend_tab.email_bridge_section.forward_link_text',
              { documentType: type }
            )}
          </Typography>
        </Box>
      </Box>
    </EmailBridgeComponentWrapper>
  );
};
