import { yupResolver } from '@hookform/resolvers/yup';
import { ListItemText, Tooltip, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import classnames from 'classnames';
import { format } from 'date-fns';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Currency, CurrencyOptions, DocumentCategory, DocumentType } from 'shared/common.definitions';
import { convertAmountFromCents } from 'shared/helpers/common.helper';
import { getDocumentCategoryID, mutateSubscriptionDocument } from 'shared/logic/subscription-item.logic';
import {
  Currency as CurrencyType,
  Document,
  SubscriptionSpendAndInvoices,
  UploadSubscriptionDocumentModel,
} from 'shared/models';
import { updateFooterState } from 'shared/store/modal';
import { getOffsetDateISOString } from 'views/overview/calendar/subscription-indicator/helpers';
import * as yup from 'yup';

import { DatePickerComponent, NumberFormatInput } from '..';
import { CurrencySelectInputComponent } from '../currency-select-input';
import { DocumentAttributeEditComponentProps } from '.';
import { DocumentAttributeEditWrapper } from './document-attribute-edit.styles';

const documentUpdateSchema = yup.object().shape({
  category: yup.number().required().min(0),
});

type FormValue = {
  category: number;
  date?: string | Date;
  name?: string;
  amountCents?: number | string;
  currency?: string;
};

export const DocumentAttributeEditComponent = forwardRef((props: DocumentAttributeEditComponentProps, ref) => {
  const {
    document,
    documentType,
    documents,
    isDocumentsRedesignPage,
    isNewSolutionDetailPage,
    mutateDocuments,
    mutateInvoice,
    selectedRows,
    subscriptionId,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

  const { mutateAsync: updateDocumentAsync } = useMutation(mutateSubscriptionDocument);
  const defaultCurrency =
    documentType === DocumentType.INVOICE
      ? (document as SubscriptionSpendAndInvoices).amountCurrency
      : DEFAULT_CURRENCY;
  const {
    control,
    formState: { isDirty, isValid },
    getValues,
    register,
    setValue,
    watch,
  } = useForm<FormValue>({
    defaultValues: {
      category: document ? Number(document.category) : -1,
      currency: defaultCurrency,
      date: document?.date ? getOffsetDateISOString(new Date(document.date)) : undefined,
      name: document?.name ? document.name : undefined,
    },
    mode: 'onChange',
    resolver: yupResolver(documentUpdateSchema),
  });

  useEffect(() => {
    dispatch(updateFooterState({ isFormValid: isValid }));
  }, [dispatch, isDirty, isValid]);

  useEffect(() => {
    const defaultInvoiceAmount = document
      ? convertAmountFromCents(Number((document as SubscriptionSpendAndInvoices).amountCents))
      : '';
    setValue('amountCents', defaultInvoiceAmount);
  }, [setValue, document]);

  useImperativeHandle(ref, () => {
    if (documentType === DocumentType.INVOICE) {
      return { handleUpdateSpendAndInvoice };
    }
    return { handleUpdateDocuments };
  });

  const selectedCurrency = watch('currency');
  const documentCategory = watch('category');

  const categoryOptions = [
    {
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.data_processing_agreement'
      ),
      value: getDocumentCategoryID(DocumentCategory.data_processing_agreement),
    },
    {
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.terms_and_conditions'
      ),
      value: getDocumentCategoryID(DocumentCategory.terms_and_conditions),
    },
    {
      name: t('subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.offer'),
      value: getDocumentCategoryID(DocumentCategory.offer),
    },
    {
      name: t('subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.contract'),
      value: getDocumentCategoryID(DocumentCategory.contract),
    },
    {
      name: t('subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.invoice'),
      value: getDocumentCategoryID(DocumentCategory.invoice),
    },
    {
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.master_services_agreement'
      ),
      value: getDocumentCategoryID(DocumentCategory.master_services_agreement),
    },
    {
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.miscellaneous'
      ),
      value: getDocumentCategoryID(DocumentCategory.miscellaneous),
    },
  ];

  if (isNewSolutionDetailPage) {
    categoryOptions.splice(3, 2);
  } else if (isDocumentsRedesignPage) {
    categoryOptions.splice(4, 1);
  }

  const populateUpdateFunctionArray = (updateDocumentsFn: unknown[], rowIndex?: number) => {
    const data = document ? { ...document } : { ...documents?.[Number(rowIndex)] };
    data.category = Number(getValues('category'));
    data.name = getValues('name') || data.name;

    let formattedDate = getValues('date');

    if (formattedDate) {
      formattedDate = new Date(formattedDate as string);
      formattedDate = new Date(formattedDate?.getTime() - formattedDate?.getTimezoneOffset() * 60000)?.toISOString();
      formattedDate = formattedDate?.split('T')?.[0] || data.date;
    }

    data.date = formattedDate || data.date;

    const apiParams: UploadSubscriptionDocumentModel = {
      data: data as Document,
      id: data.id,
      subscriptionId,
      update: true,
    };
    updateDocumentsFn.push(updateDocumentAsync(apiParams));
  };

  const handleUpdateDocuments = () => {
    const updateDocumentsFn: Array<unknown> = [];
    const successMessage = t(
      'subscription_detail_view:tabs_component_section.subscription_document_tab.notification_messages.update_document.success_message'
    );
    const errorMessage = t(
      'subscription_detail_view:tabs_component_section.subscription_document_tab.notification_messages.update_document.error_message'
    );

    if (document) populateUpdateFunctionArray(updateDocumentsFn);
    else {
      selectedRows.forEach((rowIndex) => {
        populateUpdateFunctionArray(updateDocumentsFn, rowIndex);
      });
    }

    mutateDocuments?.(updateDocumentsFn, successMessage, errorMessage);
  };

  const handleUpdateSpendAndInvoice = () => {
    const data = { ...document } as SubscriptionSpendAndInvoices;

    const amountCents = Number(Number(getValues('amountCents')) * 100).toFixed();

    const updatedInvoiceAndSpendData = {
      amountCents: Number(amountCents),
      amountCurrency: getValues('currency') || data.amountCurrency,
      date: getValues('date') ? format(new Date(getValues('date') || ''), 'yyyy-MM-dd') : data.date,
      id: data.id,
      name: getValues('name') || data.name,
    };

    mutateInvoice?.(updatedInvoiceAndSpendData);
  };

  const displayCurrency = CurrencyOptions[selectedCurrency as keyof Currency] || selectedCurrency;
  const placeholderText = 'placeholderText';

  return (
    <DocumentAttributeEditWrapper my={1} display='flex' flexDirection='column'>
      {!document && (
        <Typography variant='body' paragraph>
          {t('common:modals.edit_document_modal.body_section.form.header_text', {
            count: selectedRows.length,
          })}
        </Typography>
      )}
      {documentType !== DocumentType.INVOICE && (
        <>
          <ListItemText className='label'>
            {t('common:modals.edit_document_modal.body_section.form.category_input_label')}
            <span className='mandatoryLabel'>
              ({t('common:modals.edit_document_modal.body_section.form.required_input_label')})
            </span>
          </ListItemText>
          <div className='select is-fullwidth is-expanded'>
            <select
              data-testid='category-select'
              className={classnames({
                [placeholderText]: Number(documentCategory) === -1,
              })}
              {...register('category')}>
              <option value={-1}>
                {t('common:modals.edit_document_modal.body_section.form.category_input_placeholder_text')}
              </option>
              {categoryOptions.map(({ name, value }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
      {documentType !== DocumentType.INVOICE && Number(documentCategory) === DocumentCategory.invoice && (
        <Typography variant='small' sx={{ mt: 1 }}>
          *{t('common:modals.edit_document_modal.body_section.form.invoice_helper_text')}
        </Typography>
      )}
      {document && (
        <>
          <div className='content-body field is-fullwidth mt-3' data-testid='name'>
            <ListItemText className='label'>
              {t('common:modals.edit_document_modal.body_section.form.name_input_label')}
            </ListItemText>
            <input
              data-testid='name-input'
              className={classnames('input is-fullwidth nameInput')}
              {...register('name')}
              type='text'
              placeholder={t('common:modals.edit_document_modal.body_section.form.name_input_placeholder')}
            />
          </div>
          <div className='field is-fullwidth' data-testid='date'>
            <ListItemText className='label'>
              {t('common:modals.edit_document_modal.body_section.form.date_input_label')}
            </ListItemText>
            <div className='sastrify-datepicker'>
              <Controller
                name='date'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePickerComponent
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setOpenDatePicker(false);
                    }}
                    placeholder={t('common:modals.edit_document_modal.body_section.form.date_input_placeholder')}
                    openDatePicker={openDatePicker}
                    setOpenDatePicker={setOpenDatePicker}
                    maxDate={new Date()}
                  />
                )}
              />
            </div>
          </div>
          {documentType === DocumentType.INVOICE && (
            <>
              <div className='field is-fullwidth' data-testid='currency'>
                <ListItemText className='label'>
                  {t('common:modals.edit_document_modal.body_section.form.invoice_currency_input_label')}
                  <Tooltip
                    title={t(
                      'common:modals.edit_document_modal.body_section.form.invoice_currency_input_label_tooltip_title'
                    )}
                    arrow
                    placement='top'
                    className='ml-2'>
                    <span>
                      <Icon size='small' color='action'>
                        help
                      </Icon>
                    </span>
                  </Tooltip>
                </ListItemText>
                <Typography variant='small' color='text.secondary'>
                  {t('common:modals.edit_document_modal.body_section.form.invoice_currency_input_sub_label')}
                </Typography>
                <Controller
                  name='currency'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CurrencySelectInputComponent
                        initialValue={value}
                        getValue={(value: CurrencyType) => onChange(value.code)}
                      />
                    );
                  }}
                />
              </div>
              <div className='field is-fullwidth' data-testid='invoice-ammount'>
                <ListItemText className='label'>
                  {t('common:modals.edit_document_modal.body_section.form.invoice_amount_input_label')}
                </ListItemText>
                <div className='numberInput'>
                  <Controller
                    name='amountCents'
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NumberFormatInput
                          decimalScale={2}
                          decimalSeparator='.'
                          fixedDecimalScale
                          thousandSeparator
                          prefix={displayCurrency}
                          value={value}
                          onValueChange={(value) => onChange(value.floatValue)}
                          placeholder={t(
                            'common:modals.edit_document_modal.body_section.form.amount_input_placeholder'
                          )}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </DocumentAttributeEditWrapper>
  );
});
