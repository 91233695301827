import { RenewalReminderChannel } from '../dtos';

export const RENEWAL_CHANNEL_PREFERENCE_DEFAULT: RenewalReminderChannel = {
  email: true,
  slack: false,
};

export const RENEWAL_CHANNEL_PREFERENCE_DEFAULT_WITH_SLACK: RenewalReminderChannel = {
  email: true,
  slack: true,
};
