import { Tab, TabProps, Tabs as MuiTabs } from '@mui/material';
import React from 'react';

import { TabsProps } from './tabs.component.props';

export const Tabs: React.FC<TabsProps> = ({ items, ...rest }) => (
  <MuiTabs {...rest}>
    {items.map((item: TabProps, index: number) => (
      <Tab key={`${item.label}${index}` as React.Key} data-testid={`tab-${index}`} {...item} />
    ))}
  </MuiTabs>
);

export default Tabs;
