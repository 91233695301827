import './subscription-summary.styles.scss';

import { Button } from '@mui/material';
import usageAnalyticsUpsellingPrompt from 'assets/images/tool-usage-analytics/upselling-prompt-card.svg';
import { Icon } from 'asteroids';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { PROCUREMENT_INITIATIVES } from 'components/tabs/tabs.component';
import { UpsellingPrompt } from 'components/upselling-prompt';
import { UpsellingPromptVariant } from 'components/upselling-prompt/upselling-prompt.props';
import { useUser } from 'hooks/useUser';
import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Prompt, useLocation } from 'react-router';
import { formatDate } from 'shared/helpers/common.helper';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { trackSubscriptionDetailSaved } from 'shared/logic/event-tracking/subscription-details.events';
import { Company, DetailsSaveSchema, Subscription } from 'shared/models';
import { ARTICLE_LINKS } from 'src/constants/article-links';

import { useConfirmationModal } from '../../hooks';
import { SchemaFormFieldsRendererComponent } from '../schema-form-fields-renderer';
import { DetailsNoteFieldComponent } from './components/details-note-field.component';
import { RequestHistoryComponent } from './components/request-history/request-history.component';
import { UsageAnalytics } from './components/usage-analytics/usage-analytics.component';
import { useNote } from './hook/use-note';
import { useSubscriptionDetails } from './hook/use-subscription-details';

interface SubscriptionDetailsComponentProps {
  subscriptionId: string;
  createdAt: string;
  note: string;
  vendorId?: string;
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
}

interface OverviewWrapperProps {
  children: React.ReactNode;
}

const OverviewWrapper: FC<OverviewWrapperProps> = ({ children }) => {
  return (
    <div data-test-id='SubscriptionsOverview' className='subscription-overview'>
      {children}
    </div>
  );
};

export const SubscriptionDetailsComponent: FC<SubscriptionDetailsComponentProps> = ({
  createdAt,
  note,
  showNotification,
  subscriptionId,
  vendorId,
}) => {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const [isEditing, setIsEditing] = useState(false);
  const { handleChangeNoteFormValue, hasNoteBeenChanged, noteValue, resetNoteValue, updateNote } = useNote(note);
  const user = useUser();
  const queryClient = useQueryClient();
  const company = queryClient.getQueryData('company') as Company;
  const subscription = queryClient.getQueryData(['subscription', subscriptionId]) as Subscription;

  const isInitiativeTabActive = hash.includes(PROCUREMENT_INITIATIVES);

  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isToolStoreBundleEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);
  const isUsageAnalyticsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.USAGE_ANALYTICS);
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();
  const isUsageAnalyticsPurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.USAGE_ANALYTICS);

  const hasVendorAndSubscriptionId = vendorId && subscriptionId;

  const { error, isLoadingSaveSubscriptionDetails, isLoadingSchema, saveSubscriptionDetails, schema } =
    useSubscriptionDetails(subscriptionId);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const isFormEmpty = useMemo(
    () =>
      Boolean(schema) &&
      schema?.filter((s) => {
        return !!s.field_value;
      }).length === 0 &&
      !note,
    [note, schema]
  );

  const isInitialLoad = useRef(true);

  useEffect(() => {
    if (Boolean(schema) && isFormEmpty && isInitialLoad.current) {
      setIsEditing(true);
    }

    if (schema) {
      isInitialLoad.current = false;
    }
  }, [isFormEmpty, schema]);

  const handleFormDirtyStateUpdate = (isDirty: boolean) => {
    setFormIsDirty(isDirty);
  };

  const handleSaveClicked = (details: DetailsSaveSchema) => {
    saveSubscriptionDetails(details);
    updateNote(noteValue, subscriptionId);
    trackSubscriptionDetailSaved({
      companyId: company.id as string,
      note: noteValue,
      subscriptionCreationDate: createdAt,
      subscriptionDetailFields: details,
      subscriptionId,
      userId: user.id as string,
    });
    setFormIsDirty(false);
    setIsEditing(false);
  };

  const { openModal: openConfirmationModal } = useConfirmationModal();

  const openConfirmation = useCallback(
    (onConfirmCallback: () => void) => {
      openConfirmationModal?.({
        cancelButtonLabel: t('subscription_detail_view:details_view.confirmation_dialog.cancel'),
        confirmButtonLabel: t('subscription_detail_view:details_view.confirmation_dialog.confirm'),
        description: t('subscription_detail_view:details_view.confirmation_dialog.message'),
        level: 'danger',
        onConfirm: onConfirmCallback,
        title: t('subscription_detail_view:details_view.confirmation_dialog.title'),
      });
    },
    [t, openConfirmationModal]
  );

  const handleCancelClicked = (callback: () => void) => {
    if (formNeedsUpdate) {
      openConfirmation(() => {
        callback();
        resetNoteValue();
        setIsEditing(false);
      });
    } else {
      callback();
      resetNoteValue();
      setIsEditing(false);
    }
  };

  const formNeedsUpdate = formIsDirty || hasNoteBeenChanged(); // Note or Details form has local changes
  const showCancelButton = isEditing && formNeedsUpdate;
  const hasRequestHistory = subscription.requestId;

  if (!subscriptionId || error) {
    return (
      <OverviewWrapper>{t('subscription_detail_view:details_view.error.subscription_detail_error')}</OverviewWrapper>
    );
  }

  if (isLoadingSchema) {
    return <OverviewWrapper>{t('subscription_detail_view:details_view.is_fetching')}</OverviewWrapper>;
  }

  if (!schema) {
    return (
      <OverviewWrapper>
        <h2 className='subscription-overview__title' data-testid='subscription-overview-title'>
          {t('subscription_detail_view:details_view.title')}
        </h2>
        <p className='subscription-overview__headline' data-testid='subscription-overview-headline'>
          {t('subscription_detail_view:details_view.headline_v2')}
        </p>
        {hasRequestHistory && <RequestHistoryComponent subscription={subscription} />}
      </OverviewWrapper>
    );
  }

  return (
    <OverviewWrapper>
      <div className='subscription-overview__header'>
        <p className='subscription-overview__headline' data-testid='subscription-overview-headline'>
          {t('subscription_detail_view:details_view.headline_v2')}
        </p>
      </div>
      {isUsageAnalyticsEnabled && hasVendorAndSubscriptionId && (
        <UsageAnalytics vendorId={vendorId} subscriptionId={subscriptionId} showNotification={showNotification} />
      )}
      {isUsageAnalyticsPurchasable && (
        <UpsellingPrompt
          variant={UpsellingPromptVariant.CARD}
          feature={PRICING_PLANS_FEATURES.USAGE_ANALYTICS}
          featureName={t('pricing_plans:upselling_prompt:featureNames.usageAnalytics')}
          image={usageAnalyticsUpsellingPrompt}
          description={t('pricing_plans:upselling_prompt.featureDescriptions.usageAnalyticsCard')}
          learnMoreLink={ARTICLE_LINKS.USAGE_ANALYTICS}
        />
      )}
      <div className='subscription-overview__box'>
        <div className='subscription-overview__title-wrapper'>
          <div>
            <h2 className='subscription-overview__title' data-testid='subscription-overview-title'>
              {t('subscription_detail_view:details_view.title')}
            </h2>
            {subscription.detailsItemsUpdatedAt && (
              <div
                className='subscription-overview__title-updated-at'
                data-testid='subscription-overview-last-updated-at'>
                <span className='subscription-overview__title-updated-at-text'>
                  {t('subscription_detail_view:details_view.updated_at', {
                    date: formatDate(subscription.detailsItemsUpdatedAt),
                  })}
                </span>
                <Icon size='small' color='primary' data-testid='check-icon'>
                  check_circle
                </Icon>
              </div>
            )}
          </div>

          {!isEditing && (
            <div
              className='subscription-overview__header__action-button'
              data-testid='subscription-overview-edit-button'>
              <Button variant='text' color='secondary' onClick={() => setIsEditing(!isEditing)}>
                {t('common_labels:edit')}
              </Button>
            </div>
          )}
        </div>
        {!isInitiativeTabActive && (
          <Prompt
            when={formNeedsUpdate}
            message={t('subscription_detail_view:details_view.confirmation_dialog.message')}
          />
        )}
        <SchemaFormFieldsRendererComponent
          schemata={schema}
          onCancelClicked={handleCancelClicked}
          onSaveClicked={handleSaveClicked}
          isEditing={isEditing}
          onFormDirtyStateUpdate={handleFormDirtyStateUpdate}
          showCancelButton={showCancelButton}
          isSaving={isLoadingSaveSubscriptionDetails}>
          <DetailsNoteFieldComponent
            editModeEnabled={isEditing}
            onChangeNote={handleChangeNoteFormValue}
            note={noteValue}
            subscriptionId={subscriptionId}
          />
        </SchemaFormFieldsRendererComponent>
      </div>
      {isToolStoreBundleEnabled && hasRequestHistory && <RequestHistoryComponent subscription={subscription} />}
    </OverviewWrapper>
  );
};
