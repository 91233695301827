import { AgentMarkdown } from 'agentmarkdown';
import { AxiosResponse } from 'axios';
import { SubscriptionRenewalDateValues } from 'features/subscription/change-renewal-date/change-renewal-date.feature';
import { SubscriptionAlertData } from 'features/subscription/subscription-alert-summary/subscription-alert-summary.feature';
import { DeleteSubscriptionSpendAndInvoiceParams } from 'features/subscription/subscription-spend-and-invoices-table/subscription-spend-and-invoices-table.feature.props';
import { SpendDetail } from 'libs/dtos';
import { SubscriptionHrisGroupDto } from 'libs/dtos/subscription-hris-group.dto';
import { HRISGroupType } from 'libs/enums';
import { DEFAULT_STATE_OPTIONS } from 'libs/enums/subscription.enum';
import { PaginatedResponse } from 'libs/models';
import * as Plotly from 'plotly.js-basic-dist-min';
import { Tag } from 'react-tag-autocomplete';
import {
  BaseNumberToStringMapping,
  billingFrequencyOptions,
  COMMENT_DOCUMENT_REGEX_PATTERN,
  COMMENT_MENTION_REGEX_PATTERN,
  COMMENT_TEMPLATE_SCOPES,
  CommentDocument,
  DocumentCategory,
  ExtendedBlobType,
  GroupedSpendAndInvoicesByMonth,
  MethodTypes,
  NewSolutionStates,
  Statuses,
  SUBSCRIPTION_USERNAME_REGEX_PATTERN,
  SubscriptionStatus,
  TodoCategories,
  TodoStates,
  UserMention,
} from 'shared/common.definitions';
import { DeleteSubscriptionSpendRes } from 'shared/models/subscription-spend-and-invoices.model';
import { VendorKnowledgeStoryblokItemModel } from 'shared/models/vendor-knowledge-item.model';
import Showdown from 'showdown';
import { AppUrl } from 'src/constants/appurl';
import { formatDateWithoutTimeZone } from 'views/overview/calendar/subscription-indicator/helpers';

import { computeChecksumMd5, formatCurrency, formatFullDate } from '../helpers/common.helper';
import { IApiService } from '../interfaces/api.interface';
import {
  AddSubscriptionCommentModel,
  Alert,
  CommentTemplate,
  CreateTodo,
  Document,
  Interval,
  PresignedUploadUrl,
  SelectedHRISGroups,
  Subscription,
  SubscriptionComment,
  SubscriptionDocument,
  SubscriptionHistory,
  SubscriptionSpendAndInvoices,
  Todo,
  UpdateSubscriptionTodoState,
  UploadSubscriptionDocumentModel,
  VendorKnowledgeItemModel,
} from '../models';
import { apiService, apiUrl } from '../services';

type SubscriptionStatusReturnValue = {
  className: string;
  status: string;
};

export type DeleteSubscriptionType = {
  subscriptionId: string;
  documentId: string;
  documentName?: string;
};

export const SUBSCRIPTION_ACTIONS_IDS = {
  AssignToolOwner: '2',
  DeleteSubscription: '10',
  EditSubscriptionName: '8',
  EditToolOwner: '4',
  RemoveToolOwner: '5',
  UpdateRenewalDate: '7',
  Upload: '9',
};

export const showdownConverter = new Showdown.Converter({
  emoji: true,
  openLinksInNewWindow: true,
  simplifiedAutoLink: true,
  smartIndentationFix: true,
  strikethrough: true,
  tables: true,
  tasklists: true,
});

const REGEX_EXTRACT_NUMBER = /\d+/;

const subscriptionHistoryMapper = [
  {
    descriptionField: 'billingFrequencyOptions',
    field: 'billingFrequency',
    readableName: 'Billing Frequency',
    type: 'number',
  },
  {
    descriptionField: 'stateOptions',
    field: 'state',
    readableName: 'State',
    type: 'number',
  },
  {
    field: 'renewalDate',
    readableName: 'Renewal Date',
    type: 'date',
  },
];

export const SubscriptionStatuses = [
  Statuses.active,
  Statuses.inEvaluation,
  Statuses.inNegotiation,
  Statuses.renewal,
  Statuses.inactive,
  Statuses.discovered,
  Statuses.expired,
] as const;

export const getAsteroidsSubscriptionStatus = (status?: number): Statuses => {
  switch (status) {
    case SubscriptionStatus.active:
      return Statuses.active;
    case SubscriptionStatus.in_negotiation:
    case SubscriptionStatus.in_discovery_negotiation:
    case SubscriptionStatus.under_consideration:
    case SubscriptionStatus.in_trial:
      return Statuses.inNegotiation;
    case SubscriptionStatus.inactive:
      return Statuses.inactive;
    case SubscriptionStatus.in_evaluation:
      return Statuses.inEvaluation;
    case SubscriptionStatus.up_for_renewal:
      return Statuses.renewal;
    case SubscriptionStatus.expired:
      return Statuses.expired;
    case SubscriptionStatus.discovered:
      return Statuses.discovered;
    default:
      return Statuses.inactive;
  }
};

export const getAsteroidsSubscriptionStatusForDot = (
  status: SubscriptionStatus
): (typeof statusMappings)[SubscriptionStatus] => {
  const statusMappings: Record<SubscriptionStatus, string> = {
    [SubscriptionStatus.active]: 'success',
    [SubscriptionStatus.discovered]: 'inProgress',
    [SubscriptionStatus.expired]: 'error',
    [SubscriptionStatus.in_evaluation]: 'tertiary',
    [SubscriptionStatus.in_negotiation]: 'info',
    [SubscriptionStatus.inactive]: 'inactive',
    [SubscriptionStatus.up_for_renewal]: 'error',
    [SubscriptionStatus.in_discovery_negotiation]: 'inactive',
    [SubscriptionStatus.under_consideration]: 'inactive',
    [SubscriptionStatus.in_trial]: 'inactive',
    [SubscriptionStatus.under_review]: 'inactive',
    [SubscriptionStatus.rejected]: 'inactive',
    [SubscriptionStatus.requested]: 'inactive',
  };

  return statusMappings[status];
};

export const getSubscriptionStatus = (status?: number): SubscriptionStatusReturnValue => {
  const pathToTranslation = 'subscription_detail_view:tabs_component_section.subscription_auditlog.status_change_log';
  switch (status) {
    case SubscriptionStatus.active:
      return {
        className: 'active',
        status: `${pathToTranslation}.active`,
      };
    case SubscriptionStatus.in_negotiation:
      return {
        className: 'in-negotiation',
        status: `${pathToTranslation}.in_negotiation`,
      };
    case SubscriptionStatus.inactive:
      return {
        className: 'inactive',
        status: `${pathToTranslation}.inactive`,
      };
    case SubscriptionStatus.in_evaluation:
      return {
        className: 'in-evaluation',
        status: `${pathToTranslation}.in_evaluation`,
      };
    case SubscriptionStatus.up_for_renewal:
      return {
        className: 'up-for-renewal',
        status: `${pathToTranslation}.up_for_renewal`,
      };
    case SubscriptionStatus.under_consideration:
      return {
        className: 'under-consideration',
        status: `${pathToTranslation}.under_consideration`,
      };
    case SubscriptionStatus.in_trial:
      return {
        className: 'in-trial',
        status: `${pathToTranslation}.in_trial`,
      };
    case SubscriptionStatus.in_discovery_negotiation:
      return {
        className: 'in-negotiation',
        status: `${pathToTranslation}.in_discovery_negotiation`,
      };
    case SubscriptionStatus.expired:
      return {
        className: 'expired',
        status: `${pathToTranslation}.expired`,
      };
    case SubscriptionStatus.requested:
      return {
        className: 'requested',
        status: `${pathToTranslation}.requested`,
      };
    case SubscriptionStatus.discovered:
      return {
        className: 'discovered',
        status: `${pathToTranslation}.discovered`,
      };
    case SubscriptionStatus.rejected:
      return {
        className: 'rejected',
        status: `${pathToTranslation}.rejected`,
      };
    default:
      return {
        className: 'active',
        status: `${pathToTranslation}.active`,
      };
  }
};

export const checkIsArchiveSubscriptionView = (queryParams: string): boolean =>
  queryParams.includes(`status=${SubscriptionStatus.inactive}`);

const todoCategoriesArray = [
  TodoCategories.ASSIGN_TOOL_OWNER,
  TodoCategories.CUSTOM_TODO,
  TodoCategories.ONBOARDING_QUESTIONNAIRE,
  TodoCategories.RENEWAL_DATE,
  TodoCategories.SAVINGS_SUGGESTION,
  TodoCategories.UPLOAD_INVOICE,
  TodoCategories.RENEWAL_QUESTIONNAIRE,
  TodoCategories.USER_CUSTOM_TODO,
  TodoCategories.AUTO_EXTENDED_RENEWAL_DATE,
];

export const getAllOpenSubscriptionTodos = (data?: Todo[]): Todo[] => {
  const todo =
    data?.filter(
      (todo) => (todo.state === TodoStates.OPEN || todo.state === null) && todoCategoriesArray.includes(todo.category)
    ) || [];
  return todo;
};

export const getAllCompletedSubscriptionTodos = (data?: Todo[]): Todo[] => {
  const todo =
    data?.filter((todo) => todo.state === TodoStates.DONE && todoCategoriesArray.includes(todo.category)) || [];

  return todo;
};

export const addSubscriptionComment = async ({
  comment,
  subscriptionId,
  templateId,
  todoId,
  todoState,
}: AddSubscriptionCommentModel): Promise<SubscriptionComment> => {
  const commentData: AddSubscriptionCommentModel = {
    comment,
    subscriptionId,
    templateId,
    todoId,
  };

  if (todoState) {
    commentData.todoState = todoState;
  }
  const response = await apiService.post(apiUrl.SubscriptionComments(subscriptionId), commentData);

  const addedComment: SubscriptionComment = response.data as SubscriptionComment;

  addedComment.dateFormatted = addedComment.date ? new Date(addedComment.date) : undefined;

  return addedComment;
};

export const updateSubscriptionComment = async (comment: SubscriptionComment): Promise<SubscriptionComment> => {
  const response = await apiService.patch(
    `${apiUrl.SubscriptionComments(String(comment.subscriptionId))}${comment.id}`,
    comment
  );
  return response.data;
};

export const deleteSubscriptionComment = async (comment: SubscriptionComment): Promise<boolean> => {
  const response = await apiService.delete(
    `${apiUrl.SubscriptionComments(String(comment.subscriptionId))}${comment.id}`
  );
  return response.data;
};

export const getSubscriptionName = (subscription: Partial<Subscription>): string | undefined => {
  return subscription?.name || subscription?.vendorName;
};

export const subscriptionAutoRenewalLogic = (
  subscription: Subscription
): {
  autoRenewal: (value?: boolean | null) => string;
  cancellationPeriodDate: (value?: Interval | null) => string;
  customCancellationPeriod: boolean | undefined;
  customCancellationPeriodType: boolean | undefined;
} => {
  const customCancellationPeriod =
    subscription?.cancellationPeriod !== null &&
    subscription?.cancellationPeriod?.days !== 30 &&
    subscription?.cancellationPeriod?.days !== 60 &&
    subscription?.cancellationPeriod?.days !== 1;

  const customCancellationPeriodType =
    subscription?.cancellationPeriod !== null && (subscription?.cancellationPeriod?.days as number) % 30 === 0;

  const autoRenewal = (value?: boolean | null): string => {
    if (value === null) {
      return 'unknown';
    }
    if (value) {
      return 'yes';
    }
    return 'no';
  };

  const cancellationPeriodDate = (value?: Interval | null): string => {
    if (value === null) {
      return 'unknown';
    }

    const cancellationPeriod = Number(value?.days) === 1 ? `${String(value?.days)} day` : `${String(value?.days)} days`;
    return customCancellationPeriodType && customCancellationPeriod
      ? `${Number(value?.days) / 30} months`
      : cancellationPeriod;
  };

  return {
    autoRenewal,
    cancellationPeriodDate,
    customCancellationPeriod,
    customCancellationPeriodType,
  };
};

export const changeSubscriptionRenewalDate = ({
  autoRenewal,
  billingFrequency,
  cancellationPeriod,
  customCancellationPeriod,
  customCancellationPeriodType,
  otherBillingFrequency,
  otherBillingFrequencyType,
  renewalDate,
  subscription,
}: SubscriptionRenewalDateValues): Promise<Subscription> => {
  const clonedSubscription = { ...subscription };
  const billingFrequencyDescription = billingFrequencyOptions[Number(billingFrequency)];
  const newCancellationPeriod = Number(cancellationPeriod) >= 1 ? Number(cancellationPeriod) : null;
  const changedAutoRenewal = Number(autoRenewal) === -1 ? null : Boolean(Number(autoRenewal));
  const changedCancellationPeriod = newCancellationPeriod ? { days: newCancellationPeriod } : null;

  const newCustomCancellationPeriod =
    Number(customCancellationPeriodType) === 0
      ? Number(customCancellationPeriod)
      : Number(customCancellationPeriod) * 30;

  const getRenewalInterval = () => {
    return Number(billingFrequency) === 3 && otherBillingFrequencyType
      ? { [otherBillingFrequencyType as string]: Number(otherBillingFrequency) }
      : null;
  };

  const changedCustomCancellationPeriod =
    newCustomCancellationPeriod > 0 && Number(cancellationPeriod) !== -1 ? { days: newCustomCancellationPeriod } : null;

  clonedSubscription.autoRenewal = changedAutoRenewal;
  clonedSubscription.cancellationPeriod = changedCancellationPeriod || changedCustomCancellationPeriod;

  clonedSubscription.billingFrequency = Number(billingFrequency);
  clonedSubscription.renewalInterval = getRenewalInterval();

  if (isInactive(clonedSubscription)) {
    clonedSubscription.state = DEFAULT_STATE_OPTIONS.ACTIVE;
  }

  if (billingFrequencyDescription !== 'monthly') {
    let dateFormatted =
      renewalDate && new Date(renewalDate?.getTime() - renewalDate?.getTimezoneOffset() * 60000)?.toISOString();
    dateFormatted = dateFormatted?.split('T')?.[0];

    clonedSubscription.renewalDate = dateFormatted || renewalDate?.toISOString();
  } else {
    clonedSubscription.renewalDate = undefined;
  }

  return updateSubscription(clonedSubscription);
};

export const followSubscription = (subscriptionId: string, userId?: string): Promise<AxiosResponse> => {
  return apiService.post(apiUrl.SubscriptionFollowers(subscriptionId), {
    subscriptionId,
    userId,
  });
};

export const formatSubscription = (subscription: Subscription, viewOnly = false): Subscription => {
  if (viewOnly) {
    subscription.viewOnly = viewOnly;
    return subscription;
  }
  subscription.lastYearCostFormatted = formatCurrency({
    currency: subscription?.lastYearCostCurrency,
    isDecimal: true,
    style: 'currency',
    value: subscription.lastYearCost,
  });

  subscription.currentYearCostFormatted = formatCurrency({
    currency: subscription?.currentYearCostCurrency,
    isDecimal: true,
    style: 'currency',
    value: subscription.currentYearCost,
  });

  subscription.pastYearsSum = (subscription.lastYearCost || 0) + (subscription.currentYearCost || 0);

  subscription.savingPotentialFormatted = formatCurrency({
    currency: subscription.savingPotentialCurrency,
    isDecimal: true,
    style: 'currency',
    value: subscription.savingPotential,
  });

  subscription.renewalDateFormatted = formatDateWithoutTimeZone(subscription.renewalDate);
  return subscription;
};

const formatSubscriptionAlertData = (alertData: Alert[] | Alert) => {
  const isAlertDataArray = Array.isArray(alertData);
  const alertDataArray = Array.isArray(alertData) ? alertData : [alertData];
  const formattedAlertData = alertDataArray.map((alert: Alert) => {
    alert.dateFormatted = alert.date ? new Date(alert.date) : undefined;
    alert.renewalDateFormatted = alert.renewalDate ? new Date(alert.renewalDate) : undefined;

    return alert;
  });

  return isAlertDataArray ? formattedAlertData : formattedAlertData[0];
};

export const getBillingFrequencyDescription = (billingFrequency?: number): string => {
  let billingFrequencyDescription = '';

  if (String(billingFrequency) === 'null' || String(billingFrequency) === '-1') {
    return billingFrequencyDescription;
  }

  if (Number(billingFrequency) >= 0 && billingFrequencyOptions) {
    billingFrequencyDescription = billingFrequencyOptions[Number(billingFrequency)] || '';
  }

  return billingFrequencyDescription;
};

export const getSubscription = async (subscriptionId: string): Promise<Subscription> => {
  return apiService.get(apiUrl.Subscriptions, subscriptionId).then((response: AxiosResponse) => {
    return formatSubscription(response.data as Subscription);
  });
};

export const deleteUserSubscription = async (subscriptionId: string): Promise<boolean> => {
  const response: AxiosResponse = await apiService.delete(apiUrl.deleteSubscription(subscriptionId));
  return response.data as boolean;
};

export const deleteMultipleUserSubscriptions = async (subscriptionIds: string[]): Promise<void> => {
  await apiService.patch(apiUrl.deleteMultipleSubscriptions(), { subscriptionIds });
};

export const deleteUserNewSolution = async (newSolutionId: string): Promise<boolean> => {
  const response: AxiosResponse = await apiService.delete(apiUrl.deleteNewSolutions(newSolutionId));
  return response.data as boolean;
};

type EditMultipleInterface = {
  subscriptionData?: Partial<Subscription>;
  selectedHrisGroups?: SelectedHRISGroups;
  subscriptionIds?: string[];
  allTools?: boolean;
};

export const editMultipleUserSubscriptions = async ({
  allTools,
  selectedHrisGroups,
  subscriptionData,
  subscriptionIds,
}: EditMultipleInterface): Promise<void> => {
  const hrisGroups = selectedHrisGroups ? transformHRISGroupsToDto(selectedHrisGroups) : undefined;
  const data = { ...subscriptionData, hrisGroups };
  await apiService.patch(apiUrl.editMultipleSubscriptions(), { allTools, data, subscriptionIds });
};

export const getSubscriptionAlerts = (subscriptionId: string): Promise<Alert[]> => {
  return apiService.get(apiUrl.SubscriptionAlerts(subscriptionId)).then((response: AxiosResponse) => {
    const alerts = formatSubscriptionAlertData(response.data) as Alert[];

    return sortSubscriptionAlertsByDate(alerts);
  });
};

export const getSubscriptionTodos = (subscriptionId: string): Promise<Todo[]> => {
  return apiService.get(apiUrl.SubscriptionTodos(subscriptionId)).then((response: AxiosResponse) => {
    const todos = response.data as Todo[];

    return todos;
  });
};

export const getCommentTemplates = async (): Promise<CommentTemplate[]> =>
  (await apiService.get(apiUrl.CommentTemplates())).data as CommentTemplate[];

export const mutateSubscriptionAlert = async (subscriptionAlertData: SubscriptionAlertData): Promise<Alert | void> => {
  const { id, method, subscriptionId } = subscriptionAlertData;

  if (method === MethodTypes.DELETE) {
    await apiService[method as keyof Pick<IApiService, 'delete'>](apiUrl.SubscriptionAlerts(subscriptionId), id);

    return;
  }

  const allowedData = { ...subscriptionAlertData };
  delete allowedData.method;

  const alertResponse: AxiosResponse<Alert> = await apiService[method as keyof Pick<IApiService, 'post' | 'patch'>](
    apiUrl.SubscriptionAlerts(subscriptionId),
    allowedData,
    id
  );

  return formatSubscriptionAlertData(alertResponse.data) as Alert;
};

const getSubscriptionSpendAndInvoicesCategory = (category: number, uploader?: string | null): string => {
  switch (category) {
    case 0:
      return 'Imported from GMI';
    case 1:
      return 'Estimated spendings';
    case 2:
      return uploader ? `Manual upload by ${uploader}` : '';
    case 3:
      return uploader ? `Manual upload by ${uploader}` : '';
    case 4:
      return 'Booking from accounting';
    case 5:
      return 'Spreadsheet upload';
    default:
      return '';
  }
};

export const getSubscriptionSpendAndInvoices = (subscriptionId: string): Promise<SubscriptionSpendAndInvoices[]> => {
  return apiService.get(apiUrl.SubscriptionSpendAndInvoice(subscriptionId)).then(({ data }: AxiosResponse) => {
    return data?.items?.map((item: SubscriptionSpendAndInvoices) => {
      const currency = formatCurrency({
        currency: item.amountCurrency,
        isDecimal: true,
        style: 'currency',
        value: Number(item.displayAmountCents),
      });
      const amountFormatted = item.displayAmountCents !== 'processing' ? currency : '';
      const companyAmountFormatted = item.displayAmountCents !== 'processing' ? item.companyAmountCents : '';
      return {
        ...item,
        amountFormatted,
        categoryType: getSubscriptionSpendAndInvoicesCategory(item?.category as number, item.uploadedByName as string),
        companyAmountFormatted,
        processing: item.displayAmountCents === 'processing',
      };
    });
  });
};

export const getPaginatedSubscriptionSpendAndInvoices = (
  subscriptionId: string,
  page: number,
  pageSize = 50
): Promise<PaginatedResponse<SubscriptionSpendAndInvoices>> => {
  return apiService
    .get(apiUrl.getPaginatedSubscriptionSpendAndInvoices(subscriptionId, page, pageSize))
    .then(({ data }: AxiosResponse) => {
      return {
        ...data,
        items: data?.items?.map((item: SubscriptionSpendAndInvoices) => {
          const currency = formatCurrency({
            currency: item.amountCurrency,
            isDecimal: true,
            style: 'currency',
            value: Number(item.displayAmountCents),
          });
          const amountFormatted = item.displayAmountCents !== 'processing' ? currency : '';
          const companyAmountFormatted = item.displayAmountCents !== 'processing' ? item.companyAmountCents : '';
          return {
            ...item,
            amountFormatted,
            categoryType: getSubscriptionSpendAndInvoicesCategory(
              item?.category as number,
              item.uploadedByName as string
            ),
            companyAmountFormatted,
            processing: item.displayAmountCents === 'processing',
          };
        }),
      };
    });
};

export const deleteSubscriptionSpendAndInvoice = async ({
  category,
  spendAndInvoiceId,
  subscriptionId,
}: DeleteSubscriptionSpendAndInvoiceParams): Promise<boolean> => {
  const response = await apiService.delete(
    apiUrl.SubscriptionSpendAndInvoice(subscriptionId, spendAndInvoiceId, category)
  );

  return response.data;
};

export const deleteSubscriptionSpendsAndInvoices = async ({
  spendIds,
  subscriptionId,
}: {
  subscriptionId: string;
  spendIds: SpendIdsParams[];
}): Promise<DeleteSubscriptionSpendRes> => {
  const response = await apiService.post(apiUrl.deleteSubscriptionSpendsAndInvoices(subscriptionId), spendIds);
  return response.data;
};

export const deleteSubscriptionSpendList = async (
  subscriptionSpendList: {
    subscriptionId: string;
    spendId: string;
    categoryId: string;
  }[]
): Promise<DeleteSubscriptionSpendRes> => {
  const response = await apiService.post(apiUrl.DeleteSubscriptionSpendList, subscriptionSpendList);
  return response.data;
};

export const getSubscriptionSpendDetail = async (): Promise<SpendDetail> => {
  const response = await apiService.get(apiUrl.getSubscriptionSpendDetail());
  return response.data;
};

export type SpendIdsParams = {
  spendId: string;
  categoryId: string;
};

type EditSpendAndInvoiceParams = {
  subscriptionId: string;
  vendorId?: string;
  invoiceId: string;
  spendAndInvoiceData: Partial<SubscriptionSpendAndInvoices>;
};

export const editSubscriptionSpendAndInvoice = async ({
  invoiceId,
  spendAndInvoiceData,
  subscriptionId,
  vendorId,
}: EditSpendAndInvoiceParams): Promise<SubscriptionSpendAndInvoices> => {
  if (vendorId) {
    const response = await apiService.patch(apiUrl.editSingleInvoice(subscriptionId, invoiceId), {
      ...spendAndInvoiceData,
      subscriptionId,
      vendorId,
    });
    return response.data;
  }

  const response = await apiService.patch(apiUrl.editSingleInvoice(subscriptionId, invoiceId), {
    ...spendAndInvoiceData,
    subscriptionId,
  });
  return response.data;
};

export const getSubscriptionDocuments = (subscriptionId: string): Promise<Document[]> => {
  return apiService.get(apiUrl.SubscriptionDocuments(subscriptionId)).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const getMarkDownTextWithUrls = (
  markdown: string,
  matches: string[],
  documents: Document[],
  isDashboardComment?: boolean
): {
  commentDocuments: CommentDocument[];
  markdownWithResolvedUrls: string;
} => {
  let clonedMarkdown = markdown;
  const commentDocuments: CommentDocument[] = [];
  const resolvedTextArray = matches.map((match) => {
    let tempMarkdown = '';
    const documentId = match.split(':')[1];
    const document = documents.find((document) => document.id === documentId);

    if (isDashboardComment) {
      tempMarkdown = clonedMarkdown.replace(match, `[document](${document?.url})`);
    } else {
      tempMarkdown = clonedMarkdown.replace(match, String(document?.url));
    }

    clonedMarkdown = tempMarkdown;
    commentDocuments.push({ id: documentId, url: String(document?.url) });
    return clonedMarkdown;
  });
  const markdownWithResolvedUrls = [...resolvedTextArray].sort((a, b) => b.length - a.length)[0];

  return { commentDocuments, markdownWithResolvedUrls };
};

export const sanitizeHTML = (text: string) => text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

export const getMarkDownTextWithMention = (markdown: string, isDashboardComment = false): string => {
  const markdownComment = markdown.match(SUBSCRIPTION_USERNAME_REGEX_PATTERN);

  if (!markdownComment) {
    return sanitizeHTML(markdown);
  }

  let clonedMarkdown = sanitizeHTML(markdown);

  const markdownCommentWithFormattedMention = markdownComment.map((item) => {
    let tempMarkdown = '';
    const match = item.match(COMMENT_MENTION_REGEX_PATTERN)?.[0];
    const userId = item?.split('userName:')[1]?.replace(')', '');
    const dashboardComment = `<span className='mentioned-name'>@${match?.slice(1)}</span>`;
    const span = '<span class="ql-mention-denotation-char">@</span>';
    const dataId = `data-id=${userId || ''} `;
    const modifiedDashboardComment = `<span class="mention" data-testid="mentioned-user" data-denotation-char="@" ${dataId} data-value="${match?.slice(
      1
    )}">&#xFEFF;${span}${match?.slice(1)}&#xFEFF;</span>`;

    tempMarkdown = clonedMarkdown.replace(item, isDashboardComment ? dashboardComment : modifiedDashboardComment);
    clonedMarkdown = tempMarkdown;

    return clonedMarkdown;
  });

  return markdownCommentWithFormattedMention[markdownCommentWithFormattedMention.length - 1];
};

export const getSubscriptionSpendCategoryMappings = (legendTranslations: string[]): BaseNumberToStringMapping => ({
  0: legendTranslations[2],
  1: legendTranslations[0],
  2: legendTranslations[3],
  3: legendTranslations[3],
  4: legendTranslations[1],
  5: legendTranslations[4],
});

export const getSortedGroupedSpendAndInvoicesByMonth = (
  legends: string[],
  subscriptionSpendAndInvoicesData: SubscriptionSpendAndInvoices[]
): GroupedSpendAndInvoicesByMonth => {
  const groupedSpendAndInvoicesByMonth: GroupedSpendAndInvoicesByMonth = {};
  const CATEGORY_TO_LEGEND_MAPPING: BaseNumberToStringMapping = getSubscriptionSpendCategoryMappings(legends);
  delete CATEGORY_TO_LEGEND_MAPPING[2];

  subscriptionSpendAndInvoicesData.forEach((item) => {
    const date = new Date(String(item.date));
    const key = date.toLocaleString('default', { month: 'short', year: 'numeric' }).toUpperCase();
    const amountInCents = Number(item.companyAmountCents) / 100;
    const legendKey = CATEGORY_TO_LEGEND_MAPPING[Number(item.category)] || null;
    if (!groupedSpendAndInvoicesByMonth[key] && legendKey) {
      groupedSpendAndInvoicesByMonth[key] = {
        [legendKey]: amountInCents,
        sortByKey: date.getTime(),
        sum: Number(amountInCents),
      };
    } else if (groupedSpendAndInvoicesByMonth[key] && legendKey) {
      const categoryTypeValue = groupedSpendAndInvoicesByMonth[key][legendKey];
      groupedSpendAndInvoicesByMonth[key] = {
        ...groupedSpendAndInvoicesByMonth[key],
        [legendKey]: categoryTypeValue ? categoryTypeValue + amountInCents : amountInCents,
        sum: groupedSpendAndInvoicesByMonth[key].sum + amountInCents,
      };
    }
  });

  const sortedGroupedSpendAndInvoicesByMonth = Object.fromEntries(
    Object.entries(groupedSpendAndInvoicesByMonth).sort((a, b) => a[1].sortByKey - b[1].sortByKey)
  );

  return sortedGroupedSpendAndInvoicesByMonth;
};

export const generateSpendAndInvoicesStackedData = (
  legends: string[],
  sortedGroupedSpendAndInvoicesByMonth: GroupedSpendAndInvoicesByMonth
): Plotly.Data[] => {
  const LEGEND_TO_COLOR_MAPPING = {
    [legends[0]]: '#C4C4C4',
    [legends[1]]: '#FCCD3C',
    [legends[2]]: '#339EDA',
    [legends[3]]: '#35D0BA',
    [legends[4]]: '#235EA6',
  };
  const spendArrayOfKeysWithinPast12Months: string[] = [];
  const currentDate = new Date();

  for (let i = 0; i > -12; i--) {
    const decrementedDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
    const monthWithYearKey = decrementedDate
      .toLocaleString('default', { month: 'short', year: 'numeric' })
      .toUpperCase();
    spendArrayOfKeysWithinPast12Months.unshift(monthWithYearKey);
  }

  return [...legends].reverse().map((legend) => {
    const yAxisValues = spendArrayOfKeysWithinPast12Months.map(
      (monthKey) => sortedGroupedSpendAndInvoicesByMonth[monthKey]?.[legend] || 0
    );

    return {
      marker: { color: LEGEND_TO_COLOR_MAPPING[legend] },
      name: legend,
      type: 'bar',
      x: spendArrayOfKeysWithinPast12Months,
      y: yAxisValues,
    };
  });
};

export const getCommaStrippedFile = (file: ExtendedBlobType): ExtendedBlobType => {
  const commaStrippedFileName = String(file.name).split(',').join('');
  const clonedFile: ExtendedBlobType = new Blob([file], { type: file.type });
  clonedFile.name = commaStrippedFileName;
  clonedFile.lastModified = file.lastModified;
  clonedFile.lastModifiedDate = file.lastModifiedDate;

  return clonedFile;
};

export const getSubscriptionHistory = (subscriptionId: string): Promise<SubscriptionHistory[]> => {
  return apiService.get(apiUrl.SubscriptionHistory(subscriptionId)).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const getPresignedUploadUrl = async (companyId: string): Promise<PresignedUploadUrl> => {
  return apiService.get(apiUrl.generatePresignUploadUrl(companyId)).then((response: AxiosResponse) => {
    return response.data;
  });
};

export const mapActionDescription = (actionDescription?: string | null, subscription?: Subscription): string => {
  let mappedDescription = actionDescription || '';

  if (
    mappedDescription &&
    subscription &&
    subscriptionHistoryMapper.some((mapper) => actionDescription?.includes(mapper.field))
  ) {
    subscriptionHistoryMapper.forEach((item) => {
      if (mappedDescription.includes(item.field)) {
        mappedDescription = mappedDescription.replace(item.field, item.readableName);

        if (item.type === 'number') {
          const value = mappedDescription.match(REGEX_EXTRACT_NUMBER);
          const description: string[] = (Object.entries(subscription).find(
            (property) => property[0] === item.descriptionField
          )?.[1] || []) as string[];

          mappedDescription = mappedDescription.replace(REGEX_EXTRACT_NUMBER, description[Number(value)]);
        } else if (item.type === 'date') {
          if (mappedDescription.includes('Changed')) {
            const unformattedDate = mappedDescription.replace(`Changed ${item.readableName} to`, '') || '';
            const formattedDate = formatFullDate(unformattedDate.trim());

            mappedDescription = mappedDescription.replace(unformattedDate, ` ${formattedDate}`);
          }
        }
      }
    });
  }

  return mappedDescription;
};

const isInactive = (subscription: Subscription): boolean => {
  return DEFAULT_STATE_OPTIONS[subscription.state || 0] === 'inactive';
};

type SaveSubscriptionNote = {
  subscriptionId: string;
  note: string | null;
  userId?: string;
};

export const updateOnlySubscriptionNote = async ({ note, subscriptionId }: SaveSubscriptionNote) => {
  return apiService.patch(apiUrl.SubscriptionNote(subscriptionId), { note });
};

export const saveSubscriptionNote = ({ note, subscriptionId, userId }: SaveSubscriptionNote): Promise<Subscription> => {
  const subscription = {
    id: subscriptionId,
    note,
    noteAuthorId: userId,
  };

  return updateSubscription(subscription);
};

const sortSubscriptionAlertsByDate = (alerts: Alert[]) => {
  return alerts.sort((previousAlert: Alert, currentAlert: Alert) => {
    if (currentAlert.date && previousAlert.date) {
      const previousAlertDate: number = parseInt(previousAlert.date.replace(/-/, ''), 10);
      const currentAlertDate: number = parseInt(currentAlert.date.replace(/-/, ''), 10);

      return currentAlertDate - previousAlertDate;
    }
    return 0;
  });
};

export const unfollowSubscription = (subscriptionId: string): Promise<AxiosResponse> => {
  return apiService.delete(apiUrl.SubscriptionFollowers(subscriptionId));
};

export const updateSubscription = async (subscription: Partial<Subscription>): Promise<Subscription> => {
  const response = await apiService.patch(apiUrl.Subscriptions, subscription, subscription.id);

  return formatSubscription(response.data as Subscription);
};

export const updateSubscriptionHRISGroups = async (
  subscriptionId: string,
  toolHRISGroups: SelectedHRISGroups
): Promise<SubscriptionHrisGroupDto[]> => {
  const hrisGroups = transformHRISGroupsToDto(toolHRISGroups);
  const response = await apiService.post(apiUrl.subscriptionHRISGroups(subscriptionId), hrisGroups);

  return response.data as SubscriptionHrisGroupDto[];
};

export const updateSubscriptionTags = (subscription: Subscription, tags: Tag[]): Promise<Subscription> => {
  subscription.tags = tags.map((tag: Tag) => tag.name);

  return updateSubscription(subscription);
};

export const updateSubscriptionToolOwner = (
  subscription: Subscription,
  ownerId?: string,
  ownerName?: string
): Promise<Subscription> => {
  const clonedSubscription = { ...subscription };
  clonedSubscription.ownerId = ownerId;
  clonedSubscription.ownerName = ownerName;
  if (ownerId && ownerName) delete clonedSubscription.ownerName;
  return updateSubscription(clonedSubscription);
};

export const mutateSubscriptionDocument = (params: UploadSubscriptionDocumentModel): Promise<AxiosResponse> => {
  const { data, id, isCompanyDocument, subscriptionId, update } = params;
  const url = isCompanyDocument ? apiUrl.CompanyDocuments() : apiUrl.SubscriptionDocuments(subscriptionId as string);

  if (update) {
    return apiService.patch(`${url}${id}`, { ...data, id });
  }
  return apiService.post(url, data);
};

export const deleteSubscriptionDocument = async (params: DeleteSubscriptionType): Promise<boolean> => {
  const { documentId, subscriptionId } = params;
  const response = await apiService.delete(apiUrl.SubscriptionDocument(subscriptionId, documentId));

  return response.data;
};

export const getSubscriptionDocument = async (
  subscriptionId: string,
  documentId: string
): Promise<SubscriptionDocument> => {
  const response = await apiService.get(apiUrl.SubscriptionDocument(subscriptionId, documentId));

  return response.data;
};

export const uploadFileToPresignedUrl = (url: string, file?: Blob): Promise<AxiosResponse> => {
  return apiService.uploadFileToS3(url, file, file?.type);
};

export const createSubscriptionTodo = ({ subscriptionId, ...todo }: CreateTodo): Promise<AxiosResponse> => {
  return apiService.post(apiUrl.SubscriptionTodos(String(subscriptionId)), {
    ...todo,
    subscriptionId,
  });
};

export const deleteSubscriptionTodo = ({
  subscriptionId,
  todoId,
}: {
  subscriptionId: string;
  todoId: string;
}): Promise<AxiosResponse> => {
  return apiService.delete(apiUrl.UpdateSubscriptionTodo(subscriptionId, todoId));
};

export const updateSubscriptionTodo = ({
  state,
  subscriptionId,
  todoId,
  ...todo
}: UpdateSubscriptionTodoState): Promise<AxiosResponse> => {
  return apiService.patch(apiUrl.UpdateSubscriptionTodo(subscriptionId, todoId), {
    ...todo,
    id: todoId,
    state,
    subscriptionId,
  });
};

export const capitalize = (value: string | number | undefined): string | null => {
  if (typeof value !== 'string') {
    return null;
  }

  const valueArray = value.split(' ');
  if (valueArray.length === 1) {
    return `${value[0].toUpperCase()}${value.slice(1)}`;
  }

  valueArray.forEach((value, index) => {
    valueArray[index] = valueArray[index].charAt(0).toUpperCase() + valueArray[index].slice(1).toLowerCase();
  });

  return valueArray.join(' ');
};

export const getLastestDocumentByCategory = (documents: Array<Document>, category: DocumentCategory): Document => {
  const document = documents
    .filter((doc) => doc.category === category)
    .sort((a, b) => {
      const currentDate = a.date || a.uploadDate;
      const previousDate = b.date || b.uploadDate;
      return new Date(previousDate as string).getTime() - new Date(currentDate as string).getTime();
    });

  return document[0];
};

export const getDocumentCategoryID = (category: DocumentCategory): number => {
  switch (category) {
    case DocumentCategory.miscellaneous:
      return 0;
    case DocumentCategory.terms_and_conditions:
      return 1;
    case DocumentCategory.invoice:
      return 2;
    case DocumentCategory.contract:
      return 3;
    case DocumentCategory.offer:
      return 4;
    case DocumentCategory.data_processing_agreement:
      return 5;
    case DocumentCategory.master_services_agreement:
      return 6;
    default:
      return 0;
  }
};

export const getCompanyDocumentCategory = (category: DocumentCategory): number => {
  switch (category) {
    case DocumentCategory.subscription_list:
      return 0;
    case DocumentCategory.accounting_data:
      return 1;
    case DocumentCategory.invoice:
      return 2;
    case DocumentCategory.other_documents:
      return 3;
    default:
      return 3;
  }
};

export const getCategoryTranslationKey = (category: DocumentCategory): string => {
  switch (category) {
    case DocumentCategory.miscellaneous:
      return 'miscellaneous';
    case DocumentCategory.terms_and_conditions:
      return 'terms_and_conditions';
    case DocumentCategory.invoice:
      return 'invoice';
    case DocumentCategory.offer:
      return 'offer';
    case DocumentCategory.data_processing_agreement:
      return 'data_processing_agreement';
    case DocumentCategory.accounting_data:
      return 'accounting_data';
    case DocumentCategory.subscription_list:
      return 'subscription_list';
    case DocumentCategory.other_documents:
      return 'other_documents';
    case DocumentCategory.contract:
      return 'contract';
    case DocumentCategory.master_services_agreement:
      return 'master_services_agreement';
    default:
      return '';
  }
};

type UploadSubscriptionDocumentParams = {
  companyId?: string;
  subscriptionId?: string;
  file: ExtendedBlobType;
  category?: DocumentCategory;
  isCompanyDocument?: boolean;
};

export const uploadSubscriptionDocument = async (
  params: UploadSubscriptionDocumentParams
): Promise<SubscriptionDocument> => {
  const { category, companyId, file, isCompanyDocument, subscriptionId } = params;

  const commaStrippedFile = getCommaStrippedFile(file);

  const checksum = await computeChecksumMd5(commaStrippedFile);

  const formValues = {
    category,
    checksum,
    companyId: isCompanyDocument ? companyId : undefined,
    contentType: commaStrippedFile.type,
    fileName: commaStrippedFile.name,
    subscriptionId: isCompanyDocument ? undefined : subscriptionId,
  };

  try {
    const presignedUploadUrl: PresignedUploadUrl = await getPresignedUploadUrl(String(companyId));

    await uploadFileToPresignedUrl(presignedUploadUrl?.url as string, file);

    const response = await mutateSubscriptionDocument({
      companyId,
      data: { ...formValues, fileKey: presignedUploadUrl.key },
      isCompanyDocument,
      subscriptionId,
    });

    return response.data;
  } catch {
    throw Error('Upload Error');
  }
};

const getAllDocumentMarkdown = (markdown: string, documents: CommentDocument[]): string => {
  const matches = markdown.match(COMMENT_DOCUMENT_REGEX_PATTERN);

  if (matches?.length) {
    let clonedMarkdown = markdown;
    matches.forEach((match) => {
      let tempMarkdown = '';

      documents.forEach(({ id, url }) => {
        if (`![](${url})` === match || `![image](${url})` === match) {
          tempMarkdown = clonedMarkdown.replace(match, `![image](subscriptionDocument:${id})`);
          clonedMarkdown = tempMarkdown;
        }
      });
    });
    return clonedMarkdown;
  }
  return markdown;
};

const getMentionsMarkdown = (markdown: string, userMentions: UserMention[]): string => {
  let clonedMarkdown = markdown;

  if (userMentions.length) {
    userMentions.forEach(({ id, value }) => {
      let tempMarkdown = '';
      tempMarkdown = clonedMarkdown.replaceAll(value, `[${value}](userName:${id})`);
      clonedMarkdown = tempMarkdown;
    });
  }
  return clonedMarkdown;
};

const formatMarkdownComment = (markdown: string, documents: CommentDocument[], userMentions: UserMention[]): string => {
  const documentsMarkdown = getAllDocumentMarkdown(markdown, documents);
  const formattedMarkdown = getMentionsMarkdown(documentsMarkdown, userMentions);

  return formattedMarkdown;
};

export const htmlToMarkdown = async (html: string): Promise<string> => {
  const { markdown } = await AgentMarkdown.render({ html });
  return markdown;
};

export const getMarkdownFromComment = async (
  comment: string,
  documents: CommentDocument[],
  htmlMentions: NodeListOf<Element>
): Promise<string> => {
  const userMentions: UserMention[] = [];

  if (htmlMentions.length) {
    htmlMentions.forEach((data) => {
      const dataId = data.getAttribute('data-id');
      const dataValue = data.getAttribute('data-value');
      if (dataId && dataValue && !userMentions.map((userMention) => userMention.id).includes(dataId)) {
        userMentions.push({ id: dataId, value: `@${dataValue}` });
      }
    });
  }

  const markdown = await htmlToMarkdown(comment);

  return formatMarkdownComment(markdown, documents, userMentions);
};

export const sanitizeMarkdown = async (text: string, documents: CommentDocument[] = []) => {
  const comment = String(text);
  const generatedCommentDomFromString = new DOMParser().parseFromString(comment, 'text/html');
  const htmlMentions = generatedCommentDomFromString.querySelectorAll('[data-denotation-char="@"]');
  return getMarkdownFromComment(comment, documents || [], htmlMentions);
};

export const getFormattedHTMLWithNoWhiteSpaces = (comment: string): string =>
  comment
    ?.replaceAll(/(<.*?>)|\s+/g, (_: string, $1: string) => $1 || '')
    .replaceAll('<p></p>', '')
    .replaceAll('<p><br></p>', '')
    .replaceAll('<h1><br></h1>', '')
    .replaceAll('<h2><br></h2>', '')
    .replaceAll('<h3><br></h3>', '');

export const CUSTOM_COMMENT_TEMPLATE_ID = '-1';

export const getFormattedCommentTemplates = (
  commentTemplates?: CommentTemplate[],
  subscription?: Subscription,
  isOrderByCommentTemplates = false,
  scope?: COMMENT_TEMPLATE_SCOPES
): CommentTemplate[] | undefined => {
  if (!commentTemplates) {
    return undefined;
  }

  let commentTemplateFiltered = commentTemplates;

  if (scope) {
    commentTemplateFiltered = commentTemplateFiltered.filter((commentTemplate) =>
      scope !== undefined ? commentTemplate.scope === scope : true
    );
  }

  if (subscription) {
    const filteredTemplates = commentTemplateFiltered.filter((commentTemplate) =>
      commentTemplate.subscriptionStates?.includes(subscription?.state as number)
    );

    if (filteredTemplates.length) {
      commentTemplateFiltered = filteredTemplates;
    } else {
      commentTemplateFiltered = commentTemplateFiltered.filter((commentTemplate) =>
        commentTemplate.subscriptionStates?.includes(SubscriptionStatus.active)
      );
    }
  }

  if (!subscription && scope === COMMENT_TEMPLATE_SCOPES.PROCUREMENT_HELP) {
    commentTemplateFiltered = commentTemplateFiltered.filter((commentTemplate) =>
      commentTemplate.subscriptionStates?.includes(SubscriptionStatus.active)
    );
  }

  commentTemplateFiltered = commentTemplateFiltered.sort((a, b) => a.displayOrder - b.displayOrder);

  return [
    ...(isOrderByCommentTemplates
      ? []
      : [
          {
            buttonLabel: 'Comment',
            displayOrder: 0,
            id: CUSTOM_COMMENT_TEMPLATE_ID,
            name: 'Comment',
            scope: COMMENT_TEMPLATE_SCOPES.PROCUREMENT_HELP,
            template: '',
          },
        ]),
    ...commentTemplateFiltered,
  ];
};

export const isASubscription = (state: number): boolean => {
  return [
    SubscriptionStatus.active,
    SubscriptionStatus.discovered,
    SubscriptionStatus.in_negotiation,
    SubscriptionStatus.under_review,
    SubscriptionStatus.inactive,
    SubscriptionStatus.in_evaluation,
    SubscriptionStatus.up_for_renewal,
    SubscriptionStatus.expired,
  ].includes(state);
};

export const isAToolRequest = (state: number): boolean => {
  return NewSolutionStates.includes(state);
};

export const isADiscoveryTool = (state: number): boolean => {
  return [SubscriptionStatus.discovered].includes(state);
};

export const fetchVendorKnowledgeItems = async (
  vendorId: string,
  companyId: string
): Promise<VendorKnowledgeItemModel[]> => {
  const response = await apiService.get(apiUrl.getVendorKnowledgeItems(vendorId, companyId));
  return response.data;
};

export const fetchVendorKnowledgeItemsStoryblok = async (
  vendorId: string,
  companyId: string
): Promise<VendorKnowledgeStoryblokItemModel[]> => {
  const response = await apiService.get(apiUrl.getStoryblokVendorKnowledgeItems(vendorId, companyId));
  return response.data;
};

export const fetchVendorKnowledgeItemByStoryId = async (
  storyId: string,
  vendorId: string,
  companyId: string
): Promise<VendorKnowledgeStoryblokItemModel> => {
  const response = await apiService.get(apiUrl.getStoryblokVendorKnowledgeItemById(storyId, vendorId, companyId));
  return response.data;
};

export const markStoryAsSeen = async (
  storyId: string,
  vendorId?: string
): Promise<VendorKnowledgeStoryblokItemModel> => {
  const response = await apiService.post(apiUrl.getEventsEndpoint(), {
    entityId: storyId,
    entityType: 1,
    vendorId,
  });
  return response.data;
};

export const modifyCommentToIncludeSubscriptionDetailLink = ({
  comment,
  isDocument,
  subscription,
}: {
  comment: string;
  isDocument: boolean;
  subscription: Subscription;
}) => {
  if (isDocument) {
    const origin = process.env.WEB_APP_URL?.replace(/\/$/, '');
    const subscriptionLink = `${origin}${AppUrl.getToolUrl(subscription)}#showDocuments`;
    return `${comment}<br/><br/>_A file was attached to this request please review it on: [${subscriptionLink}](${subscriptionLink})_
    `;
  }
  return comment;
};

export const getDetectedSpend = (subscription?: Subscription) => {
  let spend = subscription?.lastYearCost || 0;
  spend += subscription?.currentYearCost || 0;
  return spend;
};

export const getTotalDetectedSpend = (lastYearCost: number, currentYearCost: number): number => {
  return lastYearCost + currentYearCost;
};

export const convertMarkDownToHtml = (markdown: string) => {
  return showdownConverter.makeHtml(markdown);
};

export const convertHtmlToMarkdown = (htmlStr: string) => {
  return showdownConverter.makeMarkdown(htmlStr);
};

export const getOtherBillingFrequency = (years?: number, months?: number): number => {
  let otherBillingFrequency = 0;

  if (years && months) {
    otherBillingFrequency = years * 12 + months;
  } else if (months) {
    otherBillingFrequency = months;
  } else if (years) {
    otherBillingFrequency = years;
  }

  return otherBillingFrequency;
};

export const transformHRISGroupsToDto = (groups: SelectedHRISGroups): SubscriptionHrisGroupDto[] => {
  const result: SubscriptionHrisGroupDto[] = [];

  if (groups.departmentId) {
    result.push({
      hrisGroupId: groups.departmentId,
      type: HRISGroupType.DEPARTMENT,
    } as SubscriptionHrisGroupDto);
  }

  if (groups.teamId) {
    result.push({
      hrisGroupId: groups.teamId,
      type: HRISGroupType.TEAM,
    } as SubscriptionHrisGroupDto);
  }

  if (groups.costCenterId) {
    result.push({
      hrisGroupId: groups.costCenterId,
      type: HRISGroupType.COST_CENTER,
    } as SubscriptionHrisGroupDto);
  }

  return result;
};
