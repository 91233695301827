export enum StatusDotColors {
  primary = 'primary',
  tertiary = 'tertiary',
  error = 'error',
  info = 'info',
  success = 'success',
  inProgress = 'inProgress',
  inactive = 'inactive',
  warning = 'warning',
}

export const StatusDotStatuses = [
  'primary',
  'tertiary',
  'error',
  'info',
  'success',
  'inProgress',
  'inactive',
  'warning',
] as const;

export type StatusDotStatus = (typeof StatusDotStatuses)[number];

export interface StatusDotProps {
  status: StatusDotStatus;
}
