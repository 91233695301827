import { Box, Card, Link, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from 'asteroids';

export const CardLink = styled(Link)(({ theme }) => ({
  '&:hover': { color: `${theme.palette.primary.main} !important`, textDecoration: 'underline !important' },
  alignItems: 'center',
  cursor: 'pointer',
  display: 'inline-flex',
}));

export const ExternaLink = styled(Typography)(({ theme }) => ({
  '&:hover': { color: `${theme.palette.primary.main}`, textDecoration: 'underline' },
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}));

export const CopyIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  fontSize: 20,
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
}));

export const DownloadIcon = styled(Icon)({
  cursor: 'pointer',
});

export const CardContainer = styled(Card)(() => ({
  boxShadow: 'none',
  marginBottom: '1rem',
}));

export const CardHeader = styled(Box)(({ theme }) => ({
  '& .MuiTypography-h3': {
    color: theme.palette.text.primary,
    marginBottom: 1,
  },
  backgroundColor: theme.palette.background.light,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  height: 46,
  padding: `13px ${theme.spacing(2)}`,
}));

export const CardContent = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.light,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  paddingBottom: 0,
}));

export const CardContentRow = styled(Box)(({ theme }) => ({
  '> .MuiTypography-body1': {
    color: theme.palette.text.secondary,
    marginBottom: '1rem',
    marginRight: '1rem',
    width: '8.625rem',
  },
  display: 'flex',
  width: '100%',
}));
export const Line = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  height: '3px',
  width: theme.spacing(3),
}));

export const TooltipList = styled(List)(({ theme }) => ({
  '& li::before': { content: '"•"', paddingRight: theme.spacing(0.5) },
  margin: 'auto 0',
  padding: 0,
  paddingLeft: theme.spacing(0.5),
}));
