/* eslint-disable no-console */
import { Box, Divider, MenuItem, MenuProps, Typography } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';

import { Icon } from '../icon/icon.component';
import { DropdownItemProps, DropdownProps } from './dropdown.component.props';
import { StyledBox, StyledDropdown } from './dropdown.styles';

export const Dropdown: React.FC<DropdownProps> = ({
  align = 'right',
  className,
  dense = false,
  footer,
  height,
  items = [],
  noPadding = false,
  onClose,
  open = false,
  position,
  width,
  ...props
}) => {
  const getBoxItemClasses = useMemo(
    () =>
      ['item-box', noPadding && 'item-box--no-padding', dense && 'item-box--condensed']
        .filter((item) => !!item)
        .join(' '),
    [noPadding, dense]
  );

  const getAnchorPosition: Partial<MenuProps> = useMemo(
    () => ({
      anchorOrigin: { horizontal: align, vertical: 'bottom' },
      transformOrigin: { horizontal: align, vertical: 'top' },
      ...(position === 'left' && {
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
        transformOrigin: { horizontal: 'right', vertical: 'top' },
      }),
      ...(position === 'right' && {
        anchorOrigin: { horizontal: 'right', vertical: 'top' },
        transformOrigin: { horizontal: 'left', vertical: 'top' },
      }),
    }),
    [align, position]
  );

  const renderItems = () =>
    items
      .filter((item): item is DropdownItemProps => !!item)
      .map((item, index) => {
        const {
          color = undefined,
          dataTestId,
          disabled = false,
          iconColor = undefined,
          iconName,
          iconPosition = 'left',
          id,
          key,
          onClick,
          subtitle,
          title,
          withDivider,
        } = item;
        return (
          <StyledBox key={key || id || `${title} - ${index}`}>
            <MenuItem
              disableRipple
              onClick={() => {
                if (onClick) onClick(item);
                if (onClose) onClose();
              }}
              sx={{ width }}
              disabled={disabled}
              data-testid={`menu-item-${dataTestId}`}>
              <Box
                className={getBoxItemClasses}
                display='flex'
                alignItems='center'
                justifyContent={iconPosition === 'right' ? 'space-between' : 'flex-start'}
                sx={{ color }}>
                {iconName && iconPosition === 'left' && (
                  <Icon color={iconColor} key='left-icon' sx={{ mr: 1 }}>
                    {iconName}
                  </Icon>
                )}
                <Box>
                  <Typography key='title' variant={dense ? 'body' : 'subtitle'}>
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography key='subtitle' variant={dense ? 'small' : 'body'} color='textSecondary'>
                      {subtitle}
                    </Typography>
                  )}
                </Box>
                {iconName && iconPosition === 'right' && (
                  <Icon color={iconColor} key='right-icon' sx={{ ml: 1 }}>
                    {iconName}
                  </Icon>
                )}
              </Box>
            </MenuItem>
            {withDivider && <Divider />}
          </StyledBox>
        );
      });

  return (
    <StyledDropdown
      {...props}
      onClose={onClose}
      open={open}
      {...getAnchorPosition}
      className={classNames(
        'dropdown--align',
        {
          [`dropdown--position-${position}`]: Boolean(position),
        },
        className
      )}>
      <Box height={height}>
        {renderItems()}
        {footer && footer}
      </Box>
    </StyledDropdown>
  );
};

export default Dropdown;
