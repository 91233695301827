import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { useMemo } from 'react';

import { useAppSelector } from '../../store/hooks';
import { PRICING_PLANS_FEATURE_STATUS } from '../feature-switch/feature-statuses';

export type UsePlanEnabledFeatures = {
  enabledFeatures: PRICING_PLANS_FEATURES[];
  isFeatureEnabled: (feature: PRICING_PLANS_FEATURES) => boolean;
};

export const usePlanEnabledFeatures = (): UsePlanEnabledFeatures => {
  const featureStatuses = useAppSelector((state) => state.pricingPlansFeatures);

  const enabledFeatures: PRICING_PLANS_FEATURES[] = useMemo(() => {
    return featureStatuses
      ?.filter((feature) => feature.status === PRICING_PLANS_FEATURE_STATUS.ENABLED)
      .map((feature) => feature.name);
  }, [featureStatuses]);

  const isFeatureEnabled = (feature: PRICING_PLANS_FEATURES): boolean => {
    return enabledFeatures?.includes(feature);
  };

  return { enabledFeatures, isFeatureEnabled };
};
