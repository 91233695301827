import { DrawerInfoHashes, DrawerInfoType, featureFlags } from 'shared/common.definitions';
import { getSubscriptionName } from 'shared/logic/subscription-item.logic';
import { Subscription } from 'shared/models';

import { NAVIGATION_URLS } from './navigation';

export const decodeNameFromRoute = (route: string): string => {
  const words: string[] = route.split('-');
  let id = null;

  if (words.length && !Number.isNaN(Number(words[words.length - 1]))) {
    id = words.pop();
  }

  if (words.length > 0) {
    return words
      .map((word) => {
        return word
          .replace(/__AND__/, '&')
          .replace(/__QM__/, '?')
          .replace(/__AT__/, '@')
          .replace(/_/g, '-');
      })
      .join(' ');
  }

  return id || '';
};

export const encodeNameAndId = (name: string, id: string | number | null | undefined): string => {
  return encodeURIComponent(
    `${name
      .trim()
      .replace(/-+/g, '_')
      .replace(/\?/, '__QM__')
      .replace(/&/, '__AND__')
      .replace(/@/, '__AT__')
      .replace(/%/, '__PERCENT__')
      .replace(/\//g, '_')
      .replace(/\s+/g, '_')}-${id === 0 ? '0' : id || ''}`
  );
};

export class AppUrl {
  static isLoading = true;

  static companyFeatureFlags: { [key: string]: boolean } = {};

  public static getToolDetailsUrl(
    tool: Partial<{ id: string | number | undefined | null; name: string | undefined | null }>,
    params = '',
    hash = ''
  ): string {
    return `${
      this.companyFeatureFlags[featureFlags.ENABLE_TOOL_DETAILS]
        ? NAVIGATION_URLS.TOOL_DETAILS
        : NAVIGATION_URLS.SUBSCRIPTIONS
    }/${encodeNameAndId(tool.name || '', `${tool.id}`)}${hash ? `#${hash}` : ''}${params ? `?${params}` : ''}`;
  }

  // DEPRECATED: Will be gone when we switch off old company tool detail view
  public static getSubscriptionDetailsUrl(tool: Partial<{ id: string | number; name: string }>): string {
    return `${NAVIGATION_URLS.SUBSCRIPTIONS}/${encodeNameAndId(tool.name || '', `${tool.id}`)}`;
  }

  // DEPRECATED: Will be gone when we switch off old company tool detail view
  public static getNewToolDetailsUrl(tool: Partial<{ id: string | number; name: string }>): string {
    return `${NAVIGATION_URLS.TOOL_DETAILS}/${encodeNameAndId(tool.name || '', `${tool.id}`)}`;
  }

  // FIXME: Refactor the `isSomething` flags to a single `sourceView` enum
  public static getToolUrl(
    subscription: Partial<Subscription>,
    isNewSolutionDetailPage = false,
    isToolRequestDetailPage = false,
    isDiscoveredToolsPage = false,
    params = '',
    hash = ''
  ): string {
    let initialPath = this.companyFeatureFlags[featureFlags.ENABLE_TOOL_DETAILS]
      ? NAVIGATION_URLS.TOOL_DETAILS
      : NAVIGATION_URLS.SUBSCRIPTIONS;
    if (isNewSolutionDetailPage) initialPath = '/new-solutions';
    if (isToolRequestDetailPage) initialPath = '/tool-requests';
    if (isDiscoveredToolsPage) initialPath = '/discovered-tools';

    return `${initialPath}/${encodeNameAndId(`${getSubscriptionName(subscription)}`, `${subscription?.id}`)}${
      hash ? `#${hash}` : ''
    }${params ? `?${params}` : ''}`;
  }

  public static addNewSubscriptionLink = `${NAVIGATION_URLS.SUBSCRIPTIONS}${
    DrawerInfoHashes[DrawerInfoType.NEW_SUBSCRIPTION]
  }`;

  public static uploadDocumentLink = `${NAVIGATION_URLS.SUBSCRIPTIONS}#uploadDocuments`;

  public static addNewRequestWorkflowsLink = '/request-workflows';

  public static addNewUserLink = '/users#addNew';

  public static editUserLink = '/users#edit';
}
