import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { featureFlags, UserRoles } from 'shared/common.definitions';
import { RoutedProps } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { useCompanyFeatureFlag } from '../../company-feature-flag.provider';
import { LoadingComponent } from '../components';
import { IntegrationsRoutes } from './integrations.routes';
import { OptimizationRoutes } from './optimization.routes';
import { ProcurementSupportRoutes } from './procurement-support.routes';
import { SettingsRoutes } from './settings.routes';
import { ToolStoreWorkflowRoutes } from './tool-store-workflows.routes';
import { UserAndCompandyDataRoutes } from './user-and-company-data.routes';

const OverviewView = lazy(() => import('../views/overview/overview.view'));
const ErrorView = lazy(() => import('../views/error/error.view'));
const SubscriptionDetailView = lazy(() => import('../views/subscription-detail/subscription-detail.view'));
const ToolDetailsView = lazy(() => import('../views/tool-details/tool-details.view'));
const CompanyToolsView = lazy(() => import('../views/company-tools/company-tools.view'));
const SpendImportView = lazy(() => import('../views/spend-import/spend-import.view'));
const ToolMatchingView = lazy(() => import('../views/tool-matching/tool-matching.view'));

export const SastrifyRoute: React.FC<RoutedProps> = (props) => {
  const userRole = useAppSelector((state) => state.authentication.user?.role);
  const isViewerRole = userRole === UserRoles.Viewer;
  const isAdminRole = userRole === UserRoles.Admin;

  const redirectToUrl = isViewerRole ? '/tool-store' : NAVIGATION_URLS.OVERVIEW;

  // Feature ≈flags
  const disableSpendReview = useCompanyFeatureFlag(featureFlags.DISABLE_SPEND_REVIEW);
  const isNewToolDetailsViewEnabled = useCompanyFeatureFlag(featureFlags.ENABLE_TOOL_DETAILS);
  const isToolDetailsRedirectEnabled = useCompanyFeatureFlag(featureFlags.ENABLE_TOOL_DETAILS_REDIRECT);
  const isToolMatchingEnabled = useCompanyFeatureFlag(featureFlags.TOOL_MATCHING);

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Switch>
        <Redirect exact from='/' to={{ ...window.location, pathname: redirectToUrl }} />
        <Redirect exact from='/dashboard' to={{ ...window.location, pathname: NAVIGATION_URLS.OVERVIEW }} />
        <Redirect exact from='/subscriptions' to={{ ...window.location, pathname: NAVIGATION_URLS.SUBSCRIPTIONS }} />
        <Redirect
          exact
          from='/subscriptions/:subscriptionId'
          to={{
            ...window.location,
            pathname: `${
              isNewToolDetailsViewEnabled ? NAVIGATION_URLS.TOOL_DETAILS : NAVIGATION_URLS.SUBSCRIPTIONS
            }/:subscriptionId`,
          }}
        />

        {isToolDetailsRedirectEnabled && (
          <Redirect
            exact
            from={`${NAVIGATION_URLS.SUBSCRIPTIONS}/:subscriptionId`}
            to={{
              ...window.location,
              hash: window.location.hash === '#showHistory' ? '#activities' : window.location.hash,
              pathname: `${NAVIGATION_URLS.TOOL_DETAILS}/:subscriptionId`,
            }}
          />
        )}

        <Redirect exact from='/settings' to={NAVIGATION_URLS.USERS_MANAGEMENT} />

        <Route exact path={NAVIGATION_URLS.OVERVIEW}>
          {!isViewerRole ? <OverviewView {...props} /> : <ErrorView code={404} />}
        </Route>
        <UserAndCompandyDataRoutes
          {...props}
          exact
          path={[
            NAVIGATION_URLS.COMPANY_DATA,
            `${NAVIGATION_URLS.COMPANY_DATA_DEPARTMENTS_TEAMS_DETAIL}/:groupId`,
            `${NAVIGATION_URLS.COMPANY_DATA_COST_CENTERS_DETAIL}/:groupId`,
            NAVIGATION_URLS.USER_AND_COMPANY_DATA,
          ]}
        />
        <Route exact path={`${NAVIGATION_URLS.SUBSCRIPTIONS}/:subscriptionId`}>
          {!isViewerRole ? <SubscriptionDetailView {...props} /> : <ErrorView code={404} />}
        </Route>
        <Route exact path={`${NAVIGATION_URLS.TOOL_DETAILS}/:subscriptionId`}>
          {!isViewerRole && isNewToolDetailsViewEnabled ? <ToolDetailsView {...props} /> : <ErrorView code={404} />}
        </Route>
        <Route exact path={NAVIGATION_URLS.SUBSCRIPTIONS}>
          {!isViewerRole ? <CompanyToolsView {...props} /> : <ErrorView code={404} />}
        </Route>
        <Route exact path={NAVIGATION_URLS.TOOL_MATCHING}>
          {isToolMatchingEnabled && isAdminRole ? <ToolMatchingView {...props} /> : <ErrorView code={404} />}
        </Route>
        <Route exact path={NAVIGATION_URLS.SPEND_IMPORT}>
          {!isViewerRole && !disableSpendReview ? <SpendImportView {...props} /> : <ErrorView code={404} />}
        </Route>
        <ProcurementSupportRoutes
          {...props}
          exact
          path={[
            NAVIGATION_URLS.PROCUREMENT_SUPPORT,
            NAVIGATION_URLS.PROCUREMENT_SUPPORT_DETAILS,
            NAVIGATION_URLS.SASTRIFY_SUPPORT,
          ]}
        />
        <SettingsRoutes
          {...props}
          exact
          path={[
            NAVIGATION_URLS.USERS_MANAGEMENT,
            NAVIGATION_URLS.DISCOVERY,
            NAVIGATION_URLS.CONNECT_EMAIL,
            NAVIGATION_URLS.CONNECT_GETMYINVOICES,
            NAVIGATION_URLS.TOOLS_SPENDS_IMPORTER,
            NAVIGATION_URLS.USAGE_ANALYTICS,
            NAVIGATION_URLS.NOTIFICATIONS,
          ]}
        />
        <ToolStoreWorkflowRoutes
          {...props}
          exact
          path={[
            NAVIGATION_URLS.TOOL_STORE,
            NAVIGATION_URLS.NEW_WORKFLOW,
            NAVIGATION_URLS.EDIT_WORKFLOW,
            NAVIGATION_URLS.WORKFLOW_DETAILS,
            NAVIGATION_URLS.WORKFLOWS,
            NAVIGATION_URLS.REQUESTS,
            NAVIGATION_URLS.REQUESTS_WORKFLOWS,
          ]}
        />
        <OptimizationRoutes
          {...props}
          exact
          path={[
            NAVIGATION_URLS.PRICING_BENCHMARKS,
            NAVIGATION_URLS.NEW_PRICING_BENCHMARK,
            NAVIGATION_URLS.NEW_PRICING_BENCHMARK_RESULTS,
            NAVIGATION_URLS.SAVINGS,
            NAVIGATION_URLS.SASTRICLOUD,
          ]}
        />
        <IntegrationsRoutes
          {...props}
          path={[NAVIGATION_URLS.HR_INFORMATION_SYSTEMS.INDEX, NAVIGATION_URLS.HR_INFORMATION_SYSTEMS.DETAILS]}
        />
        <Route path='*'>
          <ErrorView code={404} />
        </Route>
      </Switch>
    </Suspense>
  );
};
