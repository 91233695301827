import { RefObject } from '../../../../dropzone.component.props';

export interface FileItemComponentProps {
  file: File;
  onRemove: () => void;
  fileItemsRef: RefObject;
  maxFileSizeInMB: number;
  canEditFiles: boolean;
}

export enum FileItemCategory {
  invoice = 'Invoice',
  contract = 'Contract',
  miscellaneous = 'Miscellaneous',
  offer = 'Offer',
  dataProcessingAgreement = 'Data Processing Agreement',
  termsAndConditions = 'Terms & Conditions',
}
