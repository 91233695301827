import { t } from 'i18next';
import { Subscription, User, UserSubscription } from 'shared/models';
import * as yup from 'yup';

import { UserRoles } from '../common.definitions';

export enum ErrorType {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  VALIDATE = 'validate',
}

export const getErrorMessage = (type: ErrorType, name: string, value: string) => {
  switch (type) {
    case ErrorType.REQUIRED:
      return `${name} is required`;
    case ErrorType.PATTERN:
      if (value.length > 1) {
        return `Invalid ${name}`;
      }
      return undefined;
    case ErrorType.VALIDATE:
      return `${name} already exists`;
    default:
      return undefined;
  }
};

export type InviteUserError = {
  response: {
    data: {
      error?: string;
    };
    config: {
      data: {
        email?: string;
      };
    };
    status: number;
  };
};

export type UsersType = {
  name?: string;
  email?: string;
  role?: User['role'];
  owner_of?: string[];
  access_to?: string[];
  departmentId?: number | null;
};

export type UpdateUserType = {
  userId: string;
  userData: UsersType;
};

export type UserData = UsersType & {
  id: string;
};

export type inviteUserType = UsersType & {
  comment: string;
};

export type formUserType = inviteUserType & {
  owner_of?: Subscription[];
  access_to?: Subscription[];
};

export const ADMIN_USER_ROLE = 'Admin';

export const getRolesList = (canAssignAdminRole: boolean) => {
  return canAssignAdminRole
    ? [
        {
          name: ADMIN_USER_ROLE,
        },
        {
          name: 'Contributor',
        },
        {
          name: 'Viewer',
        },
      ]
    : [
        {
          name: 'Contributor',
        },
        {
          name: 'Viewer',
        },
      ];
};

export const inviteUserSchema = yup.object().shape({
  comment: yup.string(),
  email: yup.string().email().required(),
  name: yup.string().required().min(3),
});

export type InviteUserFormValues = {
  email: string;
  name: string;
  comment?: string;
  ownerOfSubscriptionIds?: string[];
};

export type CreateUserData = InviteUserFormValues & {
  role?: UserRoles;
};

export type SubscriptionAccess = {
  subscriptionIds: string[];
  userId: string;
};

export type FullSubscriptionAccess = {
  subscriptions: UserSubscription[];
  userId: string;
};

export const getUserAccessList = (subscriptionsData: Subscription[], subscriptionAccess: SubscriptionAccess[]) => {
  return subscriptionAccess?.map((access: SubscriptionAccess) => {
    return {
      subscriptions: access.subscriptionIds.map((subscriptionId: string) => {
        const fullSubscriptionData = subscriptionsData.find(({ id }) => id === subscriptionId);

        return {
          id: subscriptionId,
          vendorLogoUrl: fullSubscriptionData?.vendorLogoUrl as string,
          vendorName: fullSubscriptionData?.vendorName as string,
        };
      }),
      userId: access.userId,
    };
  });
};

const pathToTranslation =
  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section';

export function roleMenuOption(role: string) {
  switch (role.toLowerCase()) {
    case UserRoles.Admin:
      return t(`${pathToTranslation}.right_section.user_invite_section.form_section.admin_role_description`);
    case UserRoles.Contributor:
      return t(`${pathToTranslation}.right_section.user_invite_section.form_section.contributor_role_description`);
    case UserRoles.Viewer:
      return t(`${pathToTranslation}.right_section.user_invite_section.form_section.viewer_role_description`);
    default:
      return '';
  }
}
