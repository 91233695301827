import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { forwardRef, useRef } from 'react';

import { Icon } from '../icon/icon.component';
import { InputFieldProps } from './input-field.component.props';

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const {
    controlSx,
    disabled,
    endAdornment,
    error,
    helperText,
    id,
    label,
    onClearValue,
    size,
    startAdornment,
    value,
    ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOnClearValue = () => {
    if (onClearValue) {
      onClearValue();
      inputRef.current?.focus();
    }
  };

  const getStartAdornment = () => {
    if (startAdornment) {
      return <InputAdornment position='start'>{startAdornment}</InputAdornment>;
    }
    return null;
  };

  const getEndAdornment = () => {
    if (endAdornment) {
      return endAdornment;
    }

    if (value && !disabled) {
      return (
        <IconButton aria-label='clear input value' onClick={handleOnClearValue} edge='end'>
          <Icon>clear</Icon>
        </IconButton>
      );
    }
  };

  return (
    <FormControl variant='outlined' disabled={disabled} size={size} sx={controlSx}>
      <InputLabel key='label' htmlFor='outlined-adornment' error={error}>
        {label}
      </InputLabel>
      <OutlinedInput
        key='input'
        id={id}
        startAdornment={getStartAdornment()}
        endAdornment={<InputAdornment position='end'>{getEndAdornment()}</InputAdornment>}
        label={label}
        value={value}
        disabled={disabled}
        size={size}
        error={error}
        inputRef={inputRef}
        ref={ref}
        {...rest}
      />
      {helperText && (
        <FormHelperText key='helper' error={error} id={id ? `${id}-helper-text` : undefined}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default InputField;
