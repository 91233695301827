import './subscription-status.scss';

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import { ModalComponentProps, StatusComponent, SubscriptionStatusItemComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { WysiwygEditorComponent } from 'components/wysiwyg-editor';
import { useCheckUserPermission, useDeleteSubscription, useModalAndNotification } from 'hooks/index';
import { PERMISSION } from 'libs/enums/permissions';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  CommentDocument,
  subscriptionInDiscoveryStatus,
  SubscriptionStatus,
  subscriptionStatus,
} from 'shared/common.definitions';
import {
  addSubscriptionComment,
  getFormattedHTMLWithNoWhiteSpaces,
  getMarkdownFromComment,
  getSubscriptionStatus,
  htmlToMarkdown,
  updateSubscription,
} from 'shared/logic/subscription-item.logic';
import { AddSubscriptionCommentModel, SastrifyStore, Subscription, User } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { AppUrl } from 'src/constants/appurl';
import { invalidateRenewalReminder } from 'views/subscription-detail/components/renewal-status-alert/helpers';
import * as yup from 'yup';

import { AddNewSubscriptionFeature, ChangeRenewalDateFeature } from '..';
import { SubscriptionStatusFeatureProps } from './subscription-status.feature.props';
import { classes, Root } from './subscription-status.styles';

const commentSchema = yup.object().shape({
  comment: yup.string().required(),
});

export type SubmitStatusChange = {
  status?: number;
  comment?: string;
  subscription?: Partial<Subscription>;
};

export const SubscriptionStatusFeature: FC<SubscriptionStatusFeatureProps> = (props) => {
  const {
    closeModal,
    customPlaceholder,
    handleStatusChange,
    isFilterDrawer,
    isSubscriptionDetail,
    isSubscriptionInDiscovery,
    showModal,
    showNotification,
    subscription,
    value,
  } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;
  const { t } = useTranslation();
  const history = useHistory();

  const canCreateSubscription = useCheckUserPermission(PERMISSION.CREATE_SUBSCRIPTION);
  const canCreateNewSolution = useCheckUserPermission(PERMISSION.CREATE_NEW_SOLUTION);
  const canDeleteSubscription = useCheckUserPermission(PERMISSION.DELETE_SUBSCRIPTION);
  const canDeleteNewSolution = useCheckUserPermission(PERMISSION.DELETE_NEW_SOLUTION);
  const { onCloseAsteroidsModal, onShowAsteroidsModal } = useModalAndNotification();

  const isNewSolution = isSubscriptionDetail && isSubscriptionInDiscovery;
  const isDiscoveredSubscription = subscription?.state === SubscriptionStatus.discovered;
  const isInactiveSubscription = subscription?.state === SubscriptionStatus.inactive;

  const [open, setOpen] = useState(false);
  const [isUploadingDocument, setIsUploadingDocument] = useState<boolean>(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const isMounted = useRef(true);
  const isModalVisible = useRef(false);
  const updateSubscriptionRef = useRef<{ onSubmit: () => Promise<void> }>(null);

  const [status, setStatus] = useState<number>();

  const { onDeleteSubscription } = useDeleteSubscription({
    isDetailsView: isSubscriptionDetail,
    isSubscriptionInDiscovery: isSubscriptionInDiscovery as boolean,
    subscription,
  });

  const isSubscriptionStatus = (checkedStatus: SubscriptionStatus) => {
    return subscriptionStatus.some((status) => status.value === checkedStatus);
  };

  const isNewSolutionStatus = (checkedStatus: SubscriptionStatus) => {
    return subscriptionInDiscoveryStatus.some((status) => status.value === checkedStatus);
  };

  const getData = () => {
    const filteredSubscriptionInDiscoveryStatus = subscriptionInDiscoveryStatus.filter(
      (status) => status.value !== SubscriptionStatus.requested
    );
    const combinedStatuses = [
      ...filteredSubscriptionInDiscoveryStatus,
      {
        name: 'Active',
        value: SubscriptionStatus.active,
      },
      {
        name: 'Inactive',
        value: SubscriptionStatus.inactive,
      },
    ];

    if (isSubscriptionInDiscovery && !isFilterDrawer) {
      if (isSubscriptionDetail) return combinedStatuses;
      return filteredSubscriptionInDiscoveryStatus;
    }

    if (isFilterDrawer && !isSubscriptionInDiscovery) {
      return subscriptionStatus;
    }

    if (isFilterDrawer && isSubscriptionInDiscovery) {
      return subscriptionInDiscoveryStatus;
    }

    if (isDiscoveredSubscription || isInactiveSubscription) {
      return [
        ...subscriptionStatus.filter((status) => !['Discovered', 'Expired', 'Inactive'].includes(status.name)),
        ...subscriptionInDiscoveryStatus,
        {
          name: 'Inactive',
          value: SubscriptionStatus.inactive,
        },
      ];
    }
    return subscriptionStatus.filter((status) => !['Discovered', 'Expired'].includes(status.name));
  };

  const { control, getValues, watch } = useForm({
    resolver: yupResolver(commentSchema),
    shouldUnregister: true,
  });
  const comment = watch('comment');

  useEffect(() => {
    if (isMounted.current) {
      queryClient.fetchQuery({
        cacheTime: Infinity,
        queryFn: () => undefined,
        queryKey: 'uploaded-documents',
      });
      isMounted.current = false;
    }
  }, [queryClient]);

  useEffect(() => {
    if (isModalVisible.current) {
      const formattedHTMLWithNoWhiteSpaces = comment ? getFormattedHTMLWithNoWhiteSpaces(comment) : null;
      dispatch(updateFooterState({ isFormValid: Boolean(formattedHTMLWithNoWhiteSpaces) && !isUploadingDocument }));
    }
  }, [comment, dispatch, isUploadingDocument]);

  const { mutate: mutateUpdateSubscription } = useMutation(updateSubscription, {
    onError: () => {
      showNotification?.(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
    },
  });

  const { mutate: mutateAddComment } = useMutation(addSubscriptionComment, {
    onError: () => {
      dispatch(updateFooterState({ isFormSubmitting: false }));
      showNotification?.(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
    },
    onSettled: () => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('subscription-history');
      showNotification?.(`Status Changed to ${t(getSubscriptionStatus(status).status)}`, NotificationAlertType.Success);
      queryClient.resetQueries('uploaded-documents');
      isModalVisible.current = false;
      closeModal?.();

      if (isSubscriptionInDiscovery && status === SubscriptionStatus.active) {
        history.replace(AppUrl.getToolDetailsUrl(subscription as Subscription));
      }
    },
  });

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: MouseEvent | TouchEvent) => {
    if (anchorRef.current?.contains(event?.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const initialModalProps: ModalComponentProps = {
    cancelButtonText: t(
      'subscription_detail_view:subscription_detail_header_section.subscription_status_modal.action_button_section.cancel_button_text'
    ),
    confirmButtonText: t(
      'subscription_detail_view:subscription_detail_header_section.subscription_status_modal.action_button_section.change_status_button_text'
    ),
    isScrollable: true,
    onCancelButtonClick: () => {
      isModalVisible.current = false;
      closeModal?.();
    },
    title: t('subscription_detail_view:subscription_detail_header_section.subscription_status_modal.modal_title_text'),
  };

  const onSubmit = async ({ comment, status, subscription }: SubmitStatusChange, hasHtmlComment = true) => {
    dispatch(updateFooterState({ isFormSubmitting: true }));
    setStatus(status);

    const uploadedDocuments = queryClient.getQueryData<CommentDocument[]>('uploaded-documents');

    let markDownComment;

    if (comment || getValues('comment')) {
      // converting HTML to Markdown for comments without mentions before sending to BE
      markDownComment = await htmlToMarkdown(comment || getValues('comment'));
    }

    if (comment && hasHtmlComment && markDownComment) {
      const generatedCommentDomFromString = new DOMParser().parseFromString(markDownComment, 'text/html');
      const htmlMentions = generatedCommentDomFromString.querySelectorAll('[data-denotation-char="@"]');

      markDownComment = await getMarkdownFromComment(markDownComment, uploadedDocuments || [], htmlMentions);
    }

    const newComment = `### Subscription was changed from ${t(
      getSubscriptionStatus(subscription?.state).status
    )} to ${t(getSubscriptionStatus(status).status)}\n${markDownComment || ''}`;

    const clonedSubscription = {
      ...subscription,
      state: status,
    };
    const commentData: AddSubscriptionCommentModel = {
      comment: newComment,
      subscriptionId: String(subscription?.id),
    };

    mutateUpdateSubscription(clonedSubscription as Subscription, {
      onError: (error) => {
        dispatch(updateFooterState({ isFormSubmitting: false }));
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['subscription', subscription?.id]);
        queryClient.invalidateQueries('subscriptionTodos');
        mutateAddComment(commentData);
        invalidateRenewalReminder(queryClient, subscription?.id as string);
      },
    });
  };

  const changeRenewalDateFeatureRef = useRef<{ onConfirmChangeRenewalDate: () => void }>();

  const openRenewalDateAndKeyFactsModal = () => {
    onShowAsteroidsModal({
      cancelButtonText: t('common:modals.change_renewal_date_feature.action_buttons_section.cancel_button_text'),
      children: (
        <ChangeRenewalDateFeature
          ref={changeRenewalDateFeatureRef}
          billingFrequency={subscription?.billingFrequency}
          renewalDate={subscription?.renewalDate ? new Date(subscription?.renewalDate) : undefined}
          closeModal={onCloseAsteroidsModal}
          subscription={subscription as Subscription}
          isOnDetailsSideBarSection
          isStatusUpdate
          user={user}
        />
      ),
      confirmButtonText: t('common:modals.change_renewal_date_feature.action_buttons_section.confirm_button_text'),
      onCancelButtonClick: onCloseAsteroidsModal,
      onConfirmButtonClick: () => changeRenewalDateFeatureRef.current?.onConfirmChangeRenewalDate(),
      title: t('common:modals.change_renewal_date_feature.header_section_text'),
      width: 640,
    });
  };

  const onStatusClick = (status: number) => {
    if (subscription?.state === SubscriptionStatus.expired && status === SubscriptionStatus.active) {
      openRenewalDateAndKeyFactsModal();
      handleClose();
      return;
    }

    const handleOnSubmit = () => onSubmit({ status, subscription });

    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    dispatch(updateFooterState({ isFormValid: false })); // Initially disabled because comment is required

    const isTransitioningToNewSolution =
      isDiscoveredSubscription && subscriptionInDiscoveryStatus.map((item) => item.value).includes(status);
    const modalMainBodyTextParams = {
      status: t(getSubscriptionStatus(status).status),
      subscription: subscription?.name || subscription?.vendorName,
    };
    const modalMainBodyText = isTransitioningToNewSolution
      ? t(
          'subscription_detail_view:subscription_detail_header_section.transition_to_new_solution_modal.modal_body_section.main_body_text',
          modalMainBodyTextParams
        )
      : t(
          'subscription_detail_view:subscription_detail_header_section.subscription_status_modal.modal_body_section.main_body_text',
          modalMainBodyTextParams
        );

    handleClose();

    isModalVisible.current = true;

    showModal?.({
      ...initialModalProps,
      children: (
        <Root>
          <div className='mb-4'>
            <ReactMarkdown>{modalMainBodyText}</ReactMarkdown>
          </div>
          <div className='mb-4'>
            {t(
              'subscription_detail_view:subscription_detail_header_section.subscription_status_modal.modal_body_section.sub_body_text'
            )}
          </div>
          <Controller
            name='comment'
            control={control}
            render={({ field: { onChange, value } }) => (
              <WysiwygEditorComponent
                actionText='change status'
                showNotification={showNotification}
                value={value}
                subscriptionId={subscription ? subscription.id : ''}
                bottomSectionStyles={{ bottom: '-3.5rem', left: '0' }}
                placeholder={t(
                  'subscription_detail_view:subscription_detail_header_section.subscription_status_modal.modal_body_section.textarea_placeholder_text'
                )}
                onChange={onChange}
                setIsUploadingDocument={setIsUploadingDocument}
                handleSaveCommentShortCutPress={handleOnSubmit}
              />
            )}
          />
        </Root>
      ),
      onCancelButtonClick: () => {
        queryClient.resetQueries('uploaded-documents');
        isModalVisible.current = false;
        closeModal?.();
      },
      onConfirmButtonClick: handleOnSubmit,
      triggerCancelButtonClick: true,
      ...(isTransitioningToNewSolution && {
        title: t(
          'subscription_detail_view:subscription_detail_header_section.transition_to_new_solution_modal.modal_title_text'
        ),
      }),
    });
  };

  const onTransitionToSubscription = (status: number) => {
    handleClose();
    showModal?.({
      ...initialModalProps,
      children: (
        <AddNewSubscriptionFeature
          isTransitionToSubscription
          subscriptionId={subscription?.id}
          onSubmitStatusChange={({ comment, subscription }, hasHtmlComment) =>
            onSubmit({ comment, status, subscription }, hasHtmlComment)
          }
          ref={updateSubscriptionRef}
        />
      ),
      onConfirmButtonClick: () => updateSubscriptionRef.current?.onSubmit(),
      subTitle: t(
        'subscription_detail_view:subscription_detail_header_section.transition_to_subscription_modal.modal_sub_title_text',
        {
          vendorName: subscription?.name || subscription?.vendorName,
        }
      ),
      title: t(
        'subscription_detail_view:subscription_detail_header_section.transition_to_subscription_modal.modal_title_text'
      ),
    });
  };

  const handleClickMenuItem = (value: string) => {
    handleClose();
    handleStatusChange?.(value);
  };

  const dropdownDefaultValue =
    customPlaceholder ||
    t(
      'subscriptions_view:subscriptions_control_feature_section.filter_subscription_drawer_section.body_section.select_button_none_text'
    );

  const statusChangeTitle = isSubscriptionInDiscovery
    ? t('common:subscription_status_feature.title_section.new_solution_title_text')
    : t('common:subscription_status_feature.title_section.subscription_title_text');

  return (
    <Root>
      {!isFilterDrawer ? (
        <section ref={anchorRef}>
          <StatusComponent
            status={Number(subscription?.state)}
            handleClick={
              isSubscriptionDetail ? (handleToggle as unknown as React.MouseEventHandler<HTMLButtonElement>) : undefined
            }
            isSubscriptionDetail={isSubscriptionDetail}
            isSubscriptionInDiscovery={isSubscriptionInDiscovery}
          />
          <Popper
            className={`subscription-status-section ${classes.popper}`}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement='bottom-end'>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu' data-testid='status-dropdown-menu'>
                      <Typography variant='h6' color='initial' className='status-group-title'>
                        {statusChangeTitle}
                      </Typography>
                      {getData().map((status) => {
                        if (status.value === Number(subscription?.state)) {
                          return null;
                        }

                        if (status.value === SubscriptionStatus.active && isNewSolution) {
                          if (canCreateSubscription) {
                            return (
                              <Box key={status.value}>
                                <Typography variant='h6' color='initial' className='status-group-title'>
                                  {t(
                                    'common:subscription_status_feature.title_section.transition_to_subscription_title_text'
                                  )}
                                </Typography>
                                <SubscriptionStatusItemComponent
                                  onClick={() => onTransitionToSubscription(status.value)}
                                  value={status.value}
                                  isSubscriptionDetail={isSubscriptionDetail}
                                />
                              </Box>
                            );
                          }
                          return null;
                        }

                        if (status.value === SubscriptionStatus.rejected && isNewSolution) {
                          return (
                            <Box key={status.value}>
                              <Typography variant='h6' color='initial' className='status-group-title'>
                                {t('common:subscription_status_feature.title_section.reject_request_title_text')}
                              </Typography>
                              <SubscriptionStatusItemComponent
                                onClick={() => onStatusClick(status.value)}
                                value={status.value}
                                isSubscriptionDetail={isSubscriptionDetail}
                              />
                            </Box>
                          );
                        }

                        if (status.value === SubscriptionStatus.inactive && isSubscriptionDetail) {
                          return (
                            <Box key={status.value}>
                              <Typography variant='h6' color='initial' className='status-group-title'>
                                {t('common:subscription_status_feature.title_section.move_to_archive_title_text')}
                              </Typography>
                              <SubscriptionStatusItemComponent
                                onClick={() => onStatusClick(status.value)}
                                value={status.value}
                                isSubscriptionDetail={isSubscriptionDetail}
                              />
                            </Box>
                          );
                        }

                        if (
                          (isDiscoveredSubscription || isInactiveSubscription) &&
                          status.value === SubscriptionStatus.under_consideration
                        ) {
                          if (canCreateNewSolution) {
                            return (
                              <Box key={status.value}>
                                <Typography variant='h6' color='initial' className='status-group-title'>
                                  {t(
                                    'common:subscription_status_feature.title_section.transition_to_new_solution_title_text'
                                  )}
                                </Typography>
                                <SubscriptionStatusItemComponent
                                  onClick={() => onStatusClick(status.value)}
                                  value={status.value}
                                  isSubscriptionDetail={isSubscriptionDetail}
                                />
                              </Box>
                            );
                          }
                          return null;
                        }
                        if (isSubscriptionStatus(status.value) && (isNewSolution || isInactiveSubscription)) {
                          if (!canCreateSubscription) {
                            return null;
                          }
                        }
                        if (isNewSolutionStatus(status.value) && !(isNewSolution || isInactiveSubscription)) {
                          if (!canCreateNewSolution) {
                            return null;
                          }
                        }
                        return (
                          <SubscriptionStatusItemComponent
                            onClick={() => onStatusClick(status.value)}
                            value={status.value}
                            isSubscriptionDetail={isSubscriptionDetail}
                            key={status.value}
                          />
                        );
                      })}

                      {((isNewSolution && canDeleteNewSolution) || canDeleteSubscription) && (
                        <Box className='delete-tool-wrapper'>
                          <Divider />
                          <MenuItem onClick={() => onDeleteSubscription()}>
                            <div className='delete-tool-section' data-testid='delete-tool'>
                              <i className='uil uil-trash-alt' />
                              <p>
                                {isNewSolution
                                  ? t('common:subscription_status_feature.delete_new_solution_text')
                                  : t('common:subscription_status_feature.delete_subscription_text')}
                              </p>
                            </div>
                          </MenuItem>
                        </Box>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </section>
      ) : (
        <section ref={anchorRef} className={classes.statusDropdown}>
          <Button
            className={classnames('has-text-grey-light', classes.statusButton)}
            endIcon={<i className='fas fa-chevron-down' />}
            onClick={handleToggle}
            data-testid='status-dropdown-button'
            fullWidth>
            {value ? (
              <StatusComponent
                isFilterDrawer
                status={Number(value)}
                isDropdown
                isSubscriptionDetail={isSubscriptionDetail}
              />
            ) : (
              dropdownDefaultValue
            )}
          </Button>
          <Popper
            className={classes.popper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            placement='bottom'
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper data-testid='status-dropdown-paper'>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id='split-button-menu'>
                      <MenuItem value='' onClick={() => handleClickMenuItem('')}>
                        <p className='menuItemText'>{dropdownDefaultValue}</p>
                      </MenuItem>
                      {getData().map((status) => {
                        if (value) {
                          if (status.value !== Number(value)) {
                            return (
                              <MenuItem
                                key={status.value}
                                value={status.value}
                                onClick={() => handleClickMenuItem(String(status.value))}>
                                <StatusComponent
                                  status={Number(status.value)}
                                  isDropdown
                                  isSubscriptionDetail={isSubscriptionDetail}
                                  isFilterDrawer
                                />
                              </MenuItem>
                            );
                          }
                        } else {
                          return (
                            <MenuItem
                              onClick={() => handleClickMenuItem(String(status.value))}
                              key={status.value}
                              value={status.value}>
                              <StatusComponent
                                status={Number(status.value)}
                                isDropdown
                                isSubscriptionDetail={isSubscriptionDetail}
                                isFilterDrawer
                              />
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </section>
      )}
    </Root>
  );
};
