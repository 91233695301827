import { Box } from '@mui/material';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { SubscriptionSpendReview } from 'features/subscription/subscription-spend-review-modal';
import { SPEND_CATEGORIES } from 'libs/enums';
import { Subscription } from 'libs/models';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { dateToSqlDateString } from 'shared/helpers/dates.helper';
import { markFailedInvoiceAsArchived } from 'shared/logic/subscription-invoices.logic';
import {
  deleteSubscriptionSpendsAndInvoices,
  editSubscriptionSpendAndInvoice,
  SpendIdsParams,
} from 'shared/logic/subscription-item.logic';
import { Company, Invoice } from 'shared/models';
import { FailedInvoiceData, SubscriptionSpendAndInvoices } from 'shared/models/subscription-spend-and-invoices.model';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { useModalAndNotification } from 'src/app/hooks';
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from 'src/constants/common';
import { EditedInvoice, EditSpendModal } from 'views/spend-import/components/imported-successfully/edit-spend-modal';
import { CustomModalContainer } from 'views/spend-import/components/imported-successfully/edit-spend-modal/edit-spend.modal.styles';

import { DEFAULT_TABLE_PAGE_SIZE } from '../spend-table/spend-table.component';

type FailedInvoices = {
  [x: string]: FailedInvoiceData;
};

export type SpendActionsProps = {
  subscription: Subscription;
  selectedRows: SubscriptionSpendAndInvoices[];
  setSelectedRows: (rows: SubscriptionSpendAndInvoices[]) => void;
  failedInvoices?: FailedInvoices;
  refetchSpendData: () => void;
};

export function useSpendActions({
  failedInvoices,
  refetchSpendData,
  selectedRows,
  setSelectedRows,
  subscription,
}: SpendActionsProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { onCloseAsteroidsModal, onCloseModal, onShowAsteroidsModal, onShowModal, onShowNotification } =
    useModalAndNotification();
  const translationPath = 'tool_details_view:spend_tab.spend_and_invoices_table';

  const { mutateAsync } = useMutation(deleteSubscriptionSpendsAndInvoices);
  const { isLoading: isUpdatingSpendAndInvoice, mutate: mutateSpendAndInvoice } = useMutation(
    editSubscriptionSpendAndInvoice,
    {
      onError: (error) => {
        onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSettled: () => {
        dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
        onCloseModal?.();
      },
      onSuccess: () => {
        const message = t(`${translationPath}.notification_messages.update_invoices.success_message`);
        onShowNotification?.(message, NotificationAlertType.Success);
        refreshQueries();
      },
    }
  );

  const refreshQueries = () => {
    queryClient.invalidateQueries('subscription-spend-and-invoices');
    queryClient.invalidateQueries('failed-invoices-batch');
    queryClient.invalidateQueries(['subscription-spend', 0, DEFAULT_TABLE_PAGE_SIZE]);
    queryClient.invalidateQueries(['subscription', subscription?.id]);
    queryClient.invalidateQueries('auditLogs');
    refetchSpendData();
  };

  const onSuccessfulDelete = () => {
    const message = t(`${translationPath}.notification_messages.delete_invoices.success_message`);
    onCloseModal?.();
    onShowNotification?.(message, NotificationAlertType.Success);
    refreshQueries();
  };

  const handleDeleteSingleSpendData = async (item: SubscriptionSpendAndInvoices) => {
    dispatch(updateFooterState({ isFormSubmitting: true }));
    try {
      const isProcessedDocument = Number(item.category) === SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT;
      const apiParams = {
        spendIds: [
          {
            categoryId: String(item.category),
            spendId: isProcessedDocument && item.documentId ? item.documentId : item.id,
          },
        ],
        subscriptionId: subscription?.id,
      };

      if (item.processing) {
        if (failedInvoices?.[item.documentId as string]) {
          const invoiceDocumentData = failedInvoices[item.documentId as string];
          if (invoiceDocumentData.sourceId)
            markFailedInvoiceAsArchived(invoiceDocumentData.sourceSystem, invoiceDocumentData.sourceId);
        }
      }

      const { failedDeletedIds, successfullyDeletedIds } = await mutateAsync(apiParams);
      if (successfullyDeletedIds.length && !failedDeletedIds.length) {
        onSuccessfulDelete();
      } else if (failedDeletedIds.length) {
        const message = t(`${translationPath}.notification_messages.delete_invoices.error_message`);
        onCloseModal?.();
        onShowNotification?.(message, NotificationAlertType.Error);
      }
    } catch {
      const message = t(`${translationPath}.notification_messages.delete_invoices.error_message`);
      onShowNotification?.(message, NotificationAlertType.Error);
    } finally {
      dispatch(updateFooterState({ isFormSubmitting: false }));
    }
  };

  const handleDeleteSpendData = async () => {
    try {
      dispatch(updateFooterState({ isFormSubmitting: true }));
      const apiParams: SpendIdsParams[] = [];

      selectedRows.forEach((invoiceItem) => {
        if (invoiceItem) {
          const isProcessedDocument = Number(invoiceItem.category) === SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT;
          apiParams.push({
            categoryId: String(invoiceItem.category),
            spendId: isProcessedDocument && invoiceItem.documentId ? invoiceItem.documentId : invoiceItem.id,
          });

          if (invoiceItem.processing) {
            // NOTE: this is a quick fix, we need a new EP to do this in bulk or update delete-bulk ep to do this
            if (failedInvoices?.[invoiceItem.documentId as string]) {
              const invoiceDocumentData = failedInvoices[invoiceItem.documentId as string];
              if (invoiceDocumentData.sourceId)
                markFailedInvoiceAsArchived(invoiceDocumentData.sourceSystem, invoiceDocumentData.sourceId);
            }
          }
        }
      });

      const { failedDeletedIds, successfullyDeletedIds } = await mutateAsync({
        spendIds: apiParams,
        subscriptionId: subscription?.id,
      });
      if (successfullyDeletedIds.length && !failedDeletedIds.length) {
        setSelectedRows([]);
        onSuccessfulDelete();
      } else if (failedDeletedIds.length) {
        const message = t(`${translationPath}.notification_messages.delete_invoices.error_message`);
        onCloseModal?.();
        onShowNotification?.(message, NotificationAlertType.Error);
      }
    } catch {
      const message = t(`${translationPath}.notification_messages.delete_invoices.error_message`);
      onShowNotification?.(message, NotificationAlertType.Error);
    } finally {
      dispatch(updateFooterState({ isFormSubmitting: false }));
    }
  };

  const openDeleteSpendAndInvoiceModal = (
    item?: SubscriptionSpendAndInvoices,
    subscription?: Subscription,
    company?: Company
  ) => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    const deletingSingleSpend = selectedRows.length === 0 && item?.id !== undefined;
    onShowAsteroidsModal({
      cancelButtonText: t(
        `${translationPath}.delete_spend_and_invoices_modal.action_buttons_section.cancel_delete_invoice_button_text`
      ),
      confirmButtonColor: 'error',
      confirmButtonText: t(
        `${translationPath}.delete_spend_and_invoices_modal.action_buttons_section.confirm_delete_invoice_button_text`
      ),
      confirmButtonVariant: 'text',
      message: (
        <Box mt={1}>
          {t(`${translationPath}.delete_spend_and_invoices_modal.body_section_text`, {
            count: deletingSingleSpend ? 1 : selectedRows.length,
          })}
        </Box>
      ),
      onCancelButtonClick: () => {
        onCloseAsteroidsModal();
        if (subscription && company && item) {
          handleOpenReviewSpendModal(subscription, company, item);
        }
      },
      onConfirmButtonClick: () => (deletingSingleSpend ? handleDeleteSingleSpendData(item) : handleDeleteSpendData()),
      title: t(`${translationPath}.delete_spend_and_invoices_modal.header_section_text`),
      triggerCancelButtonClick: true,
    });
  };

  const handleOpenReviewSpendModal = (
    subscription: Subscription,
    company: Company,
    invoiceData: SubscriptionSpendAndInvoices
  ) => {
    dispatch(updateFooterState({ isFormValid: false }));

    onShowModal?.({
      children: (
        <SubscriptionSpendReview
          closeModal={onCloseModal}
          subscription={subscription}
          onDelete={openDeleteSpendAndInvoiceModal}
          company={company}
          showNotification={onShowNotification}
          invoiceData={invoiceData}
        />
      ),
      hasCustomFooter: true,
      hasCustomWidth: true,
      onConfirmButtonClick: () => {
        dispatch(updateFooterState({ isFormSubmitting: true }));
      },
      overlayClass: 'review-spend-modal',
      showHeader: false,
    });
  };

  const onEditInvoiceAndSpend = (editedInvoice: EditedInvoice, invoiceData: Invoice, subscription: Subscription) => {
    dispatch(updateFooterState({ isFormSubmitting: true }));

    if (!invoiceData.subscriptionId) {
      return;
    }

    try {
      mutateSpendAndInvoice({
        invoiceId: invoiceData.id,
        spendAndInvoiceData: {
          amountCents: editedInvoice.amount,
          amountCurrency: editedInvoice.currency,
          date: dateToSqlDateString(editedInvoice.invoiceDate),
          id: invoiceData.id,
          name: editedInvoice.name,
        } as SubscriptionSpendAndInvoices,
        subscriptionId: subscription.id,
        vendorId: subscription?.vendorId || '',
      });
    } catch {
      const message = t(`${translationPath}.notification_messages.update_invoices.error_message`);
      onShowNotification?.(message, NotificationAlertType.Error);
    }
  };

  const handleEditSpendData = (invoiceData: SubscriptionSpendAndInvoices) => {
    dispatch(updateFooterState({ isFormValid: false }));

    onShowAsteroidsModal?.({
      PaperComponent: CustomModalContainer,
      children: (
        <EditSpendModal
          invoiceData={{ ...invoiceData, subscriptionId: subscription?.id }}
          subscription={subscription}
          closeModal={onCloseAsteroidsModal}
          onDelete={handleDeleteSingleSpendData}
          onSave={onEditInvoiceAndSpend}
          isUpdating={isUpdatingSpendAndInvoice}
        />
      ),
      width: invoiceData.documentPreviewUrl ? 'large' : 640,
    });
  };

  const handleDownloadSpendData = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  return {
    handleDownloadSpendData,
    handleEditSpendData,
    handleOpenReviewSpendModal,
    openDeleteSpendAndInvoiceModal,
  };
}
