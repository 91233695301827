import { getVendorItem } from 'shared/logic/requests.logic';
import { getSubscription } from 'shared/logic/subscription-item.logic';
import { Subscription } from 'shared/models';

import { useFetchData } from './useFetchData';

export const useFetchSubscription = (subscriptionId: string) =>
  useFetchData<Subscription>(['subscription', subscriptionId], () => getSubscription(subscriptionId), {
    enabled: !Number.isNaN(parseInt(subscriptionId, 10)),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export function useFetchVendor(vendorId: string) {
  return useFetchData(['vendor-item', vendorId], () => getVendorItem(vendorId), {
    enabled: !Number.isNaN(parseInt(vendorId, 10)),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
