import React from 'react';

export const SuccessModalIcon: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 24.5C2.5 12.3497 12.3497 2.5 24.5 2.5C36.6503 2.5 46.5 12.3497 46.5 24.5C46.5 36.6503 36.6503 46.5 24.5 46.5C12.3497 46.5 2.5 36.6503 2.5 24.5Z'
      fill='#0BC2A8'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M35.1214 16.5859C35.9024 17.367 35.9024 18.6333 35.1214 19.4144L22.7072 32.4144C21.9261 33.1954 20.6598 33.1954 19.8788 32.4144L13.8788 26.4144C13.0977 25.6333 13.0977 24.367 13.8788 23.5859C14.6598 22.8049 15.9261 22.8049 16.7072 23.5859L21.293 28.1717L32.293 16.5859C33.074 15.8049 34.3403 15.8049 35.1214 16.5859Z'
      fill='white'
    />
  </svg>
);
