import { Card, type CardProps as MuiCardProps, styled } from '@mui/material';

export const StyledCardContainer = styled(Card)<MuiCardProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.light,
  '& .MuiCardActions-root': {
    padding: theme.spacing(1, 2, 1.5, 2),
  },
  '& .MuiTabsCard-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiCardContent-root': {
    overflow: 'auto',
    maxHeight: 'inherit',
  },
  '& .MuiTabsCard-tab-actions': {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));
