export enum ConnectedSystemEntityMatchStatus {
  UNMATCHED = 1,
  MATCHED = 2,
  IGNORED = 3,
}

export enum SastrifyToolEntityType {
  SUBSCRIPTION = 'subscription',
  VENDOR = 'vendor',
  NEW_SOLUTION = 'new_solution',
}
