import { styled } from '@mui/material/styles';

import pageBreakpoints from '../../helpers/page-breakpoints';

export const StyledPageSubHeader = styled('div')(({ theme }) => ({
  '& .page-sub-header-content': {
    '&.page-sub-header-content--attached': {
      paddingBottom: '0',
    },
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(4),
      ...pageBreakpoints('sm'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 5, 5, 5),
      ...pageBreakpoints('xl'),
    },
  },
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  margin: '0 auto',
  width: '100%',
}));
