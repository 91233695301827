import { FC } from 'react';
import { colors } from 'shared/theme';

export const ChatRemoveIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.0833 1.16669H2.91666C2.45254 1.16669 2.00742 1.35106 1.67923 1.67925C1.35104 2.00744 1.16666 2.45256 1.16666 2.91669V8.75002C1.16666 9.21415 1.35104 9.65927 1.67923 9.98746C2.00742 10.3156 2.45254 10.5 2.91666 10.5H9.6775L11.8358 12.6642C11.8903 12.7183 11.955 12.761 12.0261 12.7901C12.0971 12.8191 12.1732 12.8338 12.25 12.8334C12.3265 12.8353 12.4024 12.8193 12.4717 12.7867C12.5782 12.7429 12.6694 12.6686 12.7337 12.5731C12.7981 12.4776 12.8328 12.3652 12.8333 12.25V2.91669C12.8333 2.45256 12.649 2.00744 12.3208 1.67925C11.9926 1.35106 11.5475 1.16669 11.0833 1.16669ZM11.6667 10.8442L10.3308 9.50252C10.2763 9.44846 10.2117 9.40568 10.1406 9.37665C10.0695 9.34762 9.99343 9.33291 9.91667 9.33335H2.91666C2.76195 9.33335 2.61358 9.2719 2.50419 9.1625C2.39479 9.0531 2.33333 8.90473 2.33333 8.75002V2.91669C2.33333 2.76198 2.39479 2.6136 2.50419 2.50421C2.61358 2.39481 2.76195 2.33335 2.91666 2.33335H11.0833C11.238 2.33335 11.3864 2.39481 11.4958 2.50421C11.6052 2.6136 11.6667 2.76198 11.6667 2.91669V10.8442Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.76191 4.25417C8.5341 4.02636 8.16476 4.02636 7.93695 4.25417L7.09138 5.09974L6.24581 4.25417C6.01801 4.02636 5.64866 4.02636 5.42085 4.25417C5.19305 4.48197 5.19305 4.85132 5.42086 5.07913L6.26642 5.9247L5.42085 6.77027C5.19305 6.99807 5.19305 7.36742 5.42085 7.59522C5.64866 7.82303 6.01801 7.82303 6.24581 7.59522L7.09138 6.74965L7.93695 7.59522C8.16476 7.82303 8.53411 7.82303 8.76191 7.59522C8.98972 7.36742 8.98972 6.99807 8.76191 6.77027L7.91634 5.9247L8.76191 5.07912C8.98972 4.85132 8.98972 4.48197 8.76191 4.25417Z'
      fill={fill}
    />
  </svg>
);

ChatRemoveIcon.defaultProps = {
  fill: colors.white,
  height: 14,
  width: 14,
};
