/* eslint-disable security/detect-possible-timing-attacks */
import { Box } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import classnames from 'classnames';
import { useFetchVendorInsights } from 'hooks/useFetchVendorInsights';
import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { DrawerInfoType, featureFlags } from 'shared/common.definitions';
import { isNewSolutionsRoute, isToolRequestsRoute } from 'shared/helpers/common.helper';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { trackSlackSubscriptionCommentRedirect } from 'shared/logic/event-tracking/slack-redirect.events';
import { trackSubscriptionDetailTabClicked } from 'shared/logic/event-tracking/subscription-details.events';
import { trackSubscriptionSpendTabClicked } from 'shared/logic/event-tracking/subscription-spend.events';
import { trackVendorKnowledgeTabClicked } from 'shared/logic/event-tracking/vendor-knowledge.events';
import { getAllOpenSubscriptionTodos } from 'shared/logic/subscription-item.logic';
import { Subscription, TabItem } from 'shared/models';
import { colors } from 'shared/theme';
import { AppUrl } from 'src/constants/appurl';

import {
  SubscriptionDocumentsTabFeature,
  SubscriptionHistoryTabFeature,
  SubscriptionInitiatives,
  SubscriptionSpendAndInvoicesFeature,
  SubscriptionTaskFeature,
  VendorKnowledgeFeature,
} from '../../features/subscription';
import { getUnseenInsights } from '../../features/subscription/vendor-knowledge/helpers/get-has-seen-new-insights.helper';
import { BarGraphIcon, ClipboardIcon, ConnectLinkIcon, DocumentsIcon, ListIcon } from '../icons';
import { SubscriptionDetailsComponent } from '../subscription-details';
import { TabsComponentProps } from './tabs.component.props';
import { StyledBadge, TabsWithBottomBorder } from './tabs.component.styles';

export const DOCUMENTS_HASH = '#showDocuments';
export const SPENDS_HASH = '#showSpends';
export const LINKED_SOFTWARES_HASH = '#showLinkedSoftwares';
export const HISTORY_HASH = '#showHistory';
export const TASK_HASH = '#task';
export const INSIGHT_HASH = '#insight';
export const DETAIL_HASH = '#detail';
export const PROCUREMENT_INITIATIVES = '#procurementInitiatives';

const HashStates = {
  detail: 0,
  documents: 6,
  history: 2,
  insights: 4,
  linkedSoftwares: 4,
  procurementInitiatives: 1,
  spend: 5,
  tasks: 3,
};

const TabKeys = {
  detail: 'detail',
  documents: 'documents',
  history: 'history',
  insights: 'insights',
  linkedSoftwares: 'linkedSoftwares',
  procurementInitiatives: 'procurementInitiatives',
  spend: 'spend',
  tasks: 'tasks',
};

export const TabsComponent: React.FC<TabsComponentProps> = (props: TabsComponentProps) => {
  const {
    closeModal,
    documentsCount,
    isMyTask,
    onUpdateRenewalDate,
    openDocumentUploadDrawer,
    openInviteUserDrawer,
    showModal,
    showNotification,
    spendsCount,
    subscription,
    subscriptionDocumentsCache,
    subscriptionHistoryCache,
    subscriptionSpendAndInvoiceCache,
    updateSubscriptionTodosCache,
  } = props;

  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  const { hash, pathname, search } = location;
  const isToolRequest = isToolRequestsRoute(pathname);
  const isSubscriptionInDiscovery = isNewSolutionsRoute(pathname) || isToolRequest;
  const isSubscriptionDetailsEnabled = useFlag(featureFlags.DETAILS_ENABLED);

  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();
  const isToolStoreBundleEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);
  const isVendorInsightsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);
  const isVendorInsightsPurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);
  useEffect(() => {
    if (hash.includes('source=slack')) {
      const searchParams = search;
      const tag = hash;
      const [commentId, replyId] = searchParams
        .replace('?', '')
        .split('&')
        .map((param: string) => param.replace('commentId=', '').replace('replyId=', ''));

      trackSlackSubscriptionCommentRedirect({
        commentId,
        replyId,
        subscriptionId: subscription?.id,
        tag: tag.replace('#showHistory', ''),
      });
    }
  }, []);
  const getCurrentTab = () => {
    if (hash === '' && isSubscriptionDetailsEnabled && !isSubscriptionInDiscovery) {
      return HashStates.detail;
    }

    return HashStates.history;
  };

  const isMounted = useRef(true);
  const [currentTabIndex, setCurrentTabIndex] = useState(getCurrentTab());
  const subscriptionId = String(subscription?.id);
  const urlPath = AppUrl.getToolUrl(subscription as Subscription, isSubscriptionInDiscovery, isToolRequest);

  const {
    detail,
    documents,
    history: historyKey,
    insights,
    linkedSoftwares,
    procurementInitiatives,
    spend,
    tasks,
  } = TabKeys;

  const vendorId = subscription?.vendorId;
  const companyId = subscription?.companyId;

  const { data: vendorKnowledgeItems, isLoading } = useFetchVendorInsights({ companyId, vendorId });

  useEffect(() => {
    if (hash && isMounted.current) {
      if (hash === DOCUMENTS_HASH) {
        setCurrentTabIndex(HashStates.documents);
      } else if (hash === SPENDS_HASH) {
        setCurrentTabIndex(HashStates.spend);
      } else if (hash === LINKED_SOFTWARES_HASH) {
        setCurrentTabIndex(HashStates.linkedSoftwares);
      } else if (hash.includes(TASK_HASH)) {
        setCurrentTabIndex(HashStates.tasks);
      } else if ((isVendorInsightsEnabled || isVendorInsightsPurchasable) && hash.includes(INSIGHT_HASH)) {
        setCurrentTabIndex(HashStates.insights);
      } else if (!isVendorInsightsEnabled && !isVendorInsightsPurchasable && hash.includes(INSIGHT_HASH)) {
        setCurrentTabIndex(HashStates.detail);
      } else if (hash.includes(DETAIL_HASH)) {
        setCurrentTabIndex(HashStates.detail);
      } else if (hash.includes(HISTORY_HASH)) {
        setCurrentTabIndex(HashStates.history);
      } else if (isToolStoreBundleEnabled && hash.includes(PROCUREMENT_INITIATIVES)) {
        setCurrentTabIndex(HashStates.procurementInitiatives);
      } else if (!isToolStoreBundleEnabled && hash.includes(PROCUREMENT_INITIATIVES)) {
        setCurrentTabIndex(HashStates.detail);
      }
    }
    isMounted.current = false;

    return () => {
      isMounted.current = true;
    };
  }, [hash, history, isVendorInsightsEnabled, isVendorInsightsPurchasable, isToolStoreBundleEnabled, urlPath]);

  const onTabClick = (tab: TabItem): void => {
    if (tab.onTabClicked) {
      tab.onTabClicked();
    }
  };

  const getTabHashByKey = (tabKey: string | undefined): string => {
    switch (tabKey) {
      case historyKey:
        return HISTORY_HASH;
      case tasks:
        return TASK_HASH;
      case documents:
        return DOCUMENTS_HASH;
      case spend:
        return SPENDS_HASH;
      case linkedSoftwares:
        return LINKED_SOFTWARES_HASH;
      case insights:
        return INSIGHT_HASH;
      case detail:
        return DETAIL_HASH;
      case procurementInitiatives:
        return PROCUREMENT_INITIATIVES;
      default:
        return '';
    }
  };

  const openTasksLength = getAllOpenSubscriptionTodos(subscription?.todos)?.length;

  const shouldShowInsightsTab =
    (isVendorInsightsEnabled || isVendorInsightsPurchasable) && vendorKnowledgeItems?.length;
  const shouldShowInsightsCount = getUnseenInsights(vendorKnowledgeItems);
  const shouldShowInsights = (isVendorInsightsEnabled || isVendorInsightsPurchasable) && shouldShowInsightsCount > 0;

  const tabs = subscription
    ? [
        {
          component: (
            <SubscriptionDetailsComponent
              subscriptionId={subscriptionId}
              note={subscription.note || ''}
              createdAt={subscription.createdAt || ''}
              vendorId={vendorId}
              showNotification={showNotification}
            />
          ),
          icon: <ListIcon fill={currentTabIndex === HashStates.detail ? colors.primary : colors.osloGray} />,
          isActive: currentTabIndex === HashStates.detail,
          onTabClicked: () => {
            trackSubscriptionDetailTabClicked(subscription.id);
          },
          title: {
            key: detail,
            textValue: t('subscription_detail_view:tabs_component_section.subscription_details_tab.tab_title_text'),
            value: `${t('subscription_detail_view:tabs_component_section.subscription_details_tab.tab_title_text')}`,
          },
        },
        {
          component: <SubscriptionInitiatives subscriptionId={subscriptionId} />,
          icon: (
            <ConnectLinkIcon fill={currentTabIndex === HashStates.linkedSoftwares ? colors.primary : colors.osloGray} />
          ),
          isActive: currentTabIndex === HashStates.procurementInitiatives,
          title: {
            key: procurementInitiatives,
            textValue: t(
              'subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.tab_title_text'
            ),
            value: `${t(
              'subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.tab_title_text'
            )}`,
          },
        },
        {
          component: (
            <SubscriptionHistoryTabFeature
              subscription={subscription as Subscription}
              showModal={showModal}
              closeModal={closeModal}
              showNotification={showNotification}
              subscriptionHistoryCache={subscriptionHistoryCache}
            />
          ),
          icon: (
            <span
              className={classnames('sastrify-tabs__icon uil-estate', {
                'is-tab-active': currentTabIndex === HashStates.history,
              })}
            />
          ),
          isActive: currentTabIndex === HashStates.history,
          title: {
            key: historyKey,
            textValue: t('subscription_detail_view:tabs_component_section.subscription_history_tab.tab_title_text'),
            value: `${t('subscription_detail_view:tabs_component_section.subscription_history_tab.tab_title_text')}`,
          },
        },
        {
          component: (
            <SubscriptionTaskFeature
              updateSubscriptionTodosCache={updateSubscriptionTodosCache}
              onUpdateRenewalDate={onUpdateRenewalDate}
              showModal={showModal}
              closeModal={closeModal}
              openInviteUserDrawer={openInviteUserDrawer}
            />
          ),
          icon: <ClipboardIcon fill={currentTabIndex === HashStates.tasks ? colors.primary : colors.osloGray} />,
          isActive: currentTabIndex === HashStates.tasks,
          title: {
            badgeValue: openTasksLength,
            key: tasks,
            textValue: t('subscription_detail_view:tabs_component_section.subscription_task_tab.tab_title_text'),
            value: (
              <>
                {t('subscription_detail_view:tabs_component_section.subscription_task_tab.tab_title_text')}{' '}
                {openTasksLength ? <span className='count-badge'>{openTasksLength}</span> : null}
              </>
            ),
          },
        },
        {
          component: (
            <VendorKnowledgeFeature isLoading={isLoading} data={vendorKnowledgeItems} subscription={subscription} />
          ),
          icon: (
            <span
              className={classnames('sastrify-tabs__icon uil-lightbulb-alt', {
                'is-tab-active': currentTabIndex === HashStates.insights,
              })}
            />
          ),
          isActive: currentTabIndex === HashStates.insights,
          onTabClicked: () => {
            trackVendorKnowledgeTabClicked(subscription.id, shouldShowInsights);
          },
          title: {
            badgeValue: shouldShowInsightsCount,
            key: insights,
            textValue: t('subscription_detail_view:tabs_component_section.insight_tab.tab_title_text'),
            value: (
              <>
                <span>{t('subscription_detail_view:tabs_component_section.insight_tab.tab_title_text')}</span>
                {shouldShowInsights && <span className='count-badge'>{shouldShowInsightsCount}</span>}
              </>
            ),
          },
        },
        {
          component: (
            <SubscriptionSpendAndInvoicesFeature
              showNotification={showNotification}
              subscriptionId={subscriptionId}
              subscriptionSpendAndInvoiceCache={subscriptionSpendAndInvoiceCache}
              showModal={showModal}
              closeModal={closeModal}
              updateSubscriptionTodosCache={updateSubscriptionTodosCache}
              subscription={subscription}
            />
          ),
          icon: <BarGraphIcon fill={currentTabIndex === HashStates.spend ? colors.primary : colors.osloGray} />,
          isActive: currentTabIndex === HashStates.spend,
          onTabClicked: () => {
            trackSubscriptionSpendTabClicked(subscription.id);
          },
          title: {
            key: spend,
            textValue: t('subscription_detail_view:tabs_component_section.subscription_spend_tab.tab_title_text'),
            value: `${t(
              'subscription_detail_view:tabs_component_section.subscription_spend_tab.tab_title_text'
            )} ${spendsCount}`,
          },
        },
        {
          component: (
            <SubscriptionDocumentsTabFeature
              subscriptionId={subscriptionId}
              documentsCount={String(documentsCount)}
              subscriptionName={subscription?.name || subscription?.vendorName}
              openDocumentUploadDrawer={() => openDocumentUploadDrawer?.(DrawerInfoType.SUBSCRIPTION_DOCUMENTS)}
              showModal={showModal}
              showNotification={showNotification}
              closeModal={closeModal}
              subscriptionDocumentsCache={subscriptionDocumentsCache}
              isSubscriptionInDiscovery={isSubscriptionInDiscovery}
              subscription={subscription}
            />
          ),
          icon: <DocumentsIcon fill={currentTabIndex === HashStates.documents ? colors.primary : colors.osloGray} />,
          isActive: currentTabIndex === HashStates.documents,
          title: {
            key: documents,
            textValue: t('subscription_detail_view:tabs_component_section.subscription_document_tab.tab_title_text'),
            value: `${t('subscription_detail_view:tabs_component_section.subscription_document_tab.tab_title_text')}${
              documentsCount ? `(${documentsCount})` : ''
            }`,
          },
        },
      ]
    : [];

  const renderTabs = () => {
    return (
      <TabsWithBottomBorder
        items={tabs
          .filter((item: TabItem) => {
            if (item.title.key === spend && isSubscriptionInDiscovery) return false;
            if (item.title.key === insights && !shouldShowInsightsTab) return false;
            if (item.title.key === procurementInitiatives && !isToolStoreBundleEnabled) return false;
            if (item.title.key === detail && (!isSubscriptionDetailsEnabled || isSubscriptionInDiscovery)) return false;
            return true;
          })
          .map((item: TabItem) => ({
            'data-testid': item.title.key,
            label: item.title.badgeValue ? (
              <StyledBadge
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                key={item.title.key}
                badgeContent={item.title.badgeValue}
                data-testid='badge-wrapper'
                color='tertiary'>
                <Box>{item.title.textValue}</Box>
              </StyledBadge>
            ) : (
              <Box key={item.title.key}>{item.title.textValue}</Box>
            ),
            onClick: () => {
              const { title } = item;

              onTabClick(item);
              history.push({
                ...location,
                hash: Object.values(TabKeys).includes(title.key) ? getTabHashByKey(title.key) : '',
              });
            },
            value: HashStates[item.title.key as keyof typeof HashStates],
          }))}
        value={currentTabIndex}
        sx={{ mb: 3 }}
      />
    );
  };

  const renderTabContent = () => {
    return (
      <div
        className={classnames('tab-content', {
          'is-overflow-content': isMyTask,
        })}>
        {tabs?.[currentTabIndex] && tabs?.[currentTabIndex].component}
      </div>
    );
  };

  return (
    <div className='sastrify-tabs' data-testid='sastrify-tabs'>
      {renderTabs()}
      {renderTabContent()}
    </div>
  );
};
