import { Badge, Box, LinearProgress, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { type MRT_Row } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from 'shared/common.definitions';
import { Contributor, RequestWorkflowItem, Tool } from 'shared/models/request-workflow.model';
import { formatDate } from 'views/overview/calendar/subscription-indicator/helpers';

import { InitiativeState } from '../components/initiative-list/initiative-list.component.props';
import { Avatar, AvatarGroup } from '../components/initiative-list/initiative-list.styles';

enum AccessorKey {
  requester = 'requesterFullName',
  assignee = 'assigneeFullName',
  dueDate = 'dueDate',
}

const AVATAR_GROUP_MAX_DISPLAY = 3;

export const useGetInitiativesTableContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getRequestState = (item: RequestWorkflowItem): InitiativeState => {
    switch (item.requestStateId) {
      case REQUEST_STATUS.REJECTED:
        return { color: 'error', name: t('requests_view:status_name.rejected') };
      case REQUEST_STATUS.APPROVED:
        return { color: 'success', name: t('requests_view:status_name.approved') };
      case REQUEST_STATUS.SUBSCRIPTION_CREATED:
        return { color: 'success', name: t('requests_view:status_name.active_tool') };
      default:
        return { color: 'info', name: item.currentStepName };
    }
  };

  const getType = (requestWorkflowTypeId?: REQUEST_WORKFLOW_TYPE) => {
    let type = '';
    if (requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL) {
      type = t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.tool_request_label');
    }
    if (requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL) {
      type = t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.renewal_label');
    }

    return type;
  };

  const InitiativeNameCell = ({
    row: { original: request },
  }: {
    row: MRT_Row<RequestWorkflowItem>;
  }): React.ReactNode => {
    return <Box component='span'>{request.name}</Box>;
  };

  const InitiativeTypeCell = ({
    row: { original: request },
  }: {
    row: MRT_Row<RequestWorkflowItem>;
  }): React.ReactNode => {
    const type = getType(request.requestWorkflowTypeId);

    return (
      <Box component='span' data-testid={`requestId-${request.requestId}-type`}>
        {type}
      </Box>
    );
  };

  const ToolsCell = ({ row: { original: request } }: { row: MRT_Row<RequestWorkflowItem> }): React.ReactNode => {
    if (request.tools && request.tools.length > 0) {
      const isMultiTool = request.tools.length > 1;

      return isMultiTool ? (
        <AvatarGroup variant='rounded'>
          {request.tools.map((tool: Tool, index: number) => {
            if (index < AVATAR_GROUP_MAX_DISPLAY) {
              return (
                <Tooltip title={tool.vendorName}>
                  <Box>
                    <Avatar variant='rounded' alt={tool.vendorName} src={tool.vendorAvatarUrl || 'gravatar'} />
                  </Box>
                </Tooltip>
              );
            }
            return null;
          })}
          {request.tools.length > AVATAR_GROUP_MAX_DISPLAY && (
            <Tooltip
              title={request.tools.slice(AVATAR_GROUP_MAX_DISPLAY).map((tool: Tool) => (
                <Box>{tool.vendorName}</Box>
              ))}>
              <Box>
                <Avatar variant='rounded'>+{request.tools.length - AVATAR_GROUP_MAX_DISPLAY}</Avatar>
              </Box>
            </Tooltip>
          )}
        </AvatarGroup>
      ) : (
        <Stack direction='row' spacing={1} alignItems='center'>
          <Avatar
            alt={request.tools[0].vendorName}
            src={request.tools[0].vendorAvatarUrl || 'gravatar'}
            variant='rounded'
          />
          <Stack direction='column'>
            <Typography>{request.tools[0].vendorName}</Typography>
            <Typography color='textSecondary' variant='small'>
              {request.tools[0].vendorCategory}
            </Typography>
          </Stack>
        </Stack>
      );
    }

    return null;
  };

  const RequesterCell = ({ row: { original: request } }: { row: MRT_Row<RequestWorkflowItem> }): React.ReactNode => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Avatar alt={request.requesterFullName} src={request.requesterAvatarUrl} />
      <Typography data-testid={`requestId-${request.requestId}-requesterName`}>{request.requesterFullName}</Typography>
    </Stack>
  );

  const AssigneeCell = ({ row: { original: request } }: { row: MRT_Row<RequestWorkflowItem> }): React.ReactNode => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Avatar alt={request.assigneeFullName} src={request.assigneeAvatarUrl} />
      <Typography data-testid={`requestId-${request.requestId}-assigneeName`}>{request.assigneeFullName}</Typography>
    </Stack>
  );

  const CollaboratorsCell = ({
    row: { original: request },
  }: {
    row: MRT_Row<RequestWorkflowItem>;
  }): React.ReactNode => {
    if (request.contributors && request.contributors.length > 0) {
      const isMultiContributors = request.contributors.length > 1;

      return isMultiContributors ? (
        <AvatarGroup>
          {request.contributors.map((contributor: Contributor, index: number) => {
            if (index < AVATAR_GROUP_MAX_DISPLAY) {
              return (
                <Tooltip title={contributor.name || contributor.email}>
                  <Box ml={theme.spacing(-1)}>
                    <Avatar alt={contributor.name || contributor.email} src={contributor.avatar || undefined} />
                  </Box>
                </Tooltip>
              );
            }
            return null;
          })}
          {request.contributors.length > AVATAR_GROUP_MAX_DISPLAY && (
            <Tooltip
              title={request.contributors.slice(AVATAR_GROUP_MAX_DISPLAY).map((contributor: Contributor) => (
                <Box>{contributor.name || contributor.email}</Box>
              ))}>
              <Box>
                <Avatar>+{request.contributors.length - AVATAR_GROUP_MAX_DISPLAY}</Avatar>
              </Box>
            </Tooltip>
          )}
        </AvatarGroup>
      ) : (
        <Stack direction='row' spacing={1} alignItems='center'>
          <Avatar
            alt={request.contributors[0].name || request.contributors[0].email}
            src={request.contributors[0].avatar || undefined}
          />
          <Typography>{request.contributors[0].name || request.contributors[0].email}</Typography>
        </Stack>
      );
    }

    return null;
  };

  const StatusCell = ({ row: { original: request } }: { row: MRT_Row<RequestWorkflowItem> }): React.ReactNode => {
    const { color, name } = getRequestState(request);

    return (
      <Stack direction='row' spacing={2} alignItems='center'>
        <Badge data-testid={`requestId-${request.requestId}-status-dot`} color={color} variant='dot' />
        <Typography data-testid={`requestId-${request.requestId}-status`}>{name}</Typography>
      </Stack>
    );
  };

  const ProgressCell = ({ row: { original: request } }: { row: MRT_Row<RequestWorkflowItem> }): React.ReactNode => {
    const barValue = (request.currentStep * 100) / request.totalNumberOfSteps;

    return (
      <Stack direction='row' spacing={2} alignItems='center'>
        <LinearProgress variant='determinate' value={barValue} color='success' sx={{ width: 120 }} />
        <Typography>
          {request.currentStep} / {request.totalNumberOfSteps}
        </Typography>
      </Stack>
    );
  };

  const DueDateCell = ({ row: { original: request } }: { row: MRT_Row<RequestWorkflowItem> }): React.ReactNode => (
    <Box component='span' data-testid={`requestId-${request.requestId}-dueDate`}>
      {formatDate(request.dueDate as string)}
    </Box>
  );

  const columns = [
    {
      Cell: InitiativeNameCell,
      accessorFn: (request: RequestWorkflowItem) => request.name,
      header: t('requests_view:request_list_header.initiative_name'),
      size: 300,
    },
    {
      Cell: InitiativeTypeCell,
      accessorFn: (request: RequestWorkflowItem) => getType(request?.requestWorkflowTypeId),
      header: t('requests_view:request_list_header.initiative_type'),
      size: 175,
    },
    {
      Cell: ToolsCell,
      accessorFn: (request: RequestWorkflowItem) => request.tools?.map((tool) => tool.vendorName).join(', ') || '',
      header: t('requests_view:request_list_header.multi_tool_name'),
      size: 300,
    },
    {
      Cell: RequesterCell,
      accessorKey: AccessorKey.requester,
      header: t('requests_view:request_list_header.requester'),
      size: 240,
    },
    {
      Cell: AssigneeCell,
      accessorKey: AccessorKey.assignee,
      header: t('requests_view:request_list_header.assignee'),
      size: 240,
    },
    {
      Cell: CollaboratorsCell,
      accessorFn: (request: RequestWorkflowItem) =>
        request.contributors?.map((contributor) => contributor.name).join(', ') || '',
      header: t('requests_view:request_list_header.collaborators'),
      size: 300,
    },
    {
      Cell: StatusCell,
      accessorFn: (request: RequestWorkflowItem) => getRequestState(request).name,
      header: t('requests_view:request_list_header.status'),
      size: 200,
    },
    {
      Cell: ProgressCell,
      accessorFn: (request: RequestWorkflowItem) => `${request.currentStep} / ${request.totalNumberOfSteps}`,
      enableColumnFilter: false,
      enableSorting: false,
      header: t('requests_view:request_list_header.progress'),
      size: 200,
    },
    {
      Cell: DueDateCell,
      accessorKey: AccessorKey.dueDate,
      enableColumnFilter: false,
      header: t('requests_view:request_list_header.due_date'),
      size: 150,
    },
  ];

  return { columns };
};
