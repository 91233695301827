import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { FC } from 'react';

export const SubscriptionTasksSkeleton: FC = () => {
  return (
    <section>
      <Skeleton variant='text' width={200} />
      <Skeleton variant='text' width={320} />
      <Skeleton variant='text' width={320} />
      <Box mt='1.5rem'>
        {[1, 2, 3].map((item) => (
          <Skeleton key={item} variant='rectangular' style={{ margin: '1rem 0' }} height='152px' width='100%' />
        ))}
      </Box>
    </section>
  );
};
