import { TextField } from '@mui/material';
import { FC } from 'react';
import NumberFormat from 'react-number-format';

import { SastrifyInput } from '../input';
import { NumberFormatInputProps } from './number-format-input.component.props';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  name: string;
}

export const NumberFormatCustom: FC<NumberFormatCustomProps> = (props) => {
  const { inputRef, ...other } = props;

  return <NumberFormat {...other} getInputRef={inputRef} />;
};

export const NumberFormatInput: FC<NumberFormatInputProps> = (props) => {
  const { className, isMuiInput, isValid = true, label, name, ...other } = props;
  return (
    <>
      {isMuiInput ? (
        <SastrifyInput
          label={label}
          data-testid='number-format'
          name={name}
          color='primary'
          id={`formatted-numberformat-input-${name}`}
          InputProps={{
            inputComponent: NumberFormatCustom as never,
            inputProps: { ...other },
          }}
        />
      ) : (
        // TODO: remove this when we move to standard input
        <TextField
          className={className}
          variant='outlined'
          size='small'
          data-testid='number-format'
          name={name}
          error={!isValid}
          color='primary'
          id={`formatted-numberformat-input-${name}`}
          InputProps={{
            inputComponent: NumberFormatCustom as never,
            inputProps: { ...other },
          }}
        />
      )}
    </>
  );
};
