import { ListItem as MuiListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Avatar } from 'asteroids';
import { ToolType } from 'shared/models';

interface ListItemProps {
  item?: ToolType;
  onClick?: (tool: ToolType) => void;
}

const ListItemStyled = styled(MuiListItem)(({ theme }) => ({
  '> div': {
    padding: theme.spacing(0.5),
  },
  padding: theme.spacing(0),
}));

export const ListItem: React.FC<ListItemProps> = ({ item, onClick }) => {
  return (
    <ListItemStyled disablePadding key={item?.id}>
      <ListItemButton onMouseDown={() => onClick && item && onClick?.(item)}>
        <ListItemAvatar>
          <Avatar src={item?.vendorLogoUrl ?? item?.name} alt={item?.name} sx={{ ml: 0.5 }} />
        </ListItemAvatar>
        <ListItemText primary={item?.name} secondary={item?.categoryName} />
      </ListItemButton>
    </ListItemStyled>
  );
};
