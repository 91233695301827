import { Badge, Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import classNames from 'classnames';

import { Icon } from '../../icon/icon.component';
import { NavItemProps } from './nav-item.props';

export const NavItem: React.FC<NavItemProps> = (props) => {
  const {
    badge,
    badgeColor = 'tertiary',
    badgeGetter,
    customBadge,
    customBadgeColor,
    expandable,
    expanded,
    iconName,
    isHeader,
    item,
    selected,
    text,
    ...listItemButtonProps
  } = props;

  const badgeContent = (
    badge === true && badgeGetter ? badgeGetter(item) : (typeof badge === 'function' && badge()) || badge
  ) as React.ReactNode;

  const getTextNode = () => {
    return (
      <Box display='flex' alignItems='center'>
        {text}
        {customBadge && <Box ml={1}>{customBadge}</Box>}
        {(badgeContent || badgeContent === 0) && (
          <Badge
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            data-testid='navitem-badge'
            badgeContent={badgeContent}
            color={typeof badgeColor === 'function' ? badgeColor() : badgeColor}
            sx={{
              '& .MuiBadge-badge': {
                background: customBadgeColor,
                backgroundColor: customBadgeColor && 'unset !important',
                transform: 'scale(1) translate(100%, calc(-50% - 1px))',
              },
              ml: 1,
            }}
          />
        )}
      </Box>
    );
  };

  const listItemSx = {
    color: selected ? 'primary' : 'text',
    ...(listItemButtonProps.sx || {}),
    paddingLeft: 0,
    paddingRight: 0,
  };

  return (
    <ListItemButton
      selected={selected}
      {...listItemButtonProps}
      sx={listItemSx}
      className={classNames(listItemButtonProps.className, { 'Mui-expanded': expanded })}>
      <ListItemIcon key='icon' sx={{ ml: 2 }}>
        {(iconName && <Icon>{iconName}</Icon>) || null}
      </ListItemIcon>
      <ListItemText key='text' primaryTypographyProps={{ variant: isHeader ? 'h3' : 'body' }} primary={getTextNode()} />
      {expandable && (
        <Icon key='collapse-icon' variant='Outlined' sx={{ color: 'text.primary', mr: 1 }}>
          {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        </Icon>
      )}
    </ListItemButton>
  );
};
