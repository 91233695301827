import { VendorInsightComponent } from 'components/vendor-insight-modal-content';
import { useFetchSubscription, useFetchVendor } from 'hooks/useFetchSubscription';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { useUser } from 'hooks/useUser';
import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { useTranslation } from 'react-i18next';
import { featureFlags, googleTagManagerEvents } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import {
  trackVendorKnowledgeModalClosed,
  trackVendorKnowledgeModalOpened,
} from 'shared/logic/event-tracking/vendor-knowledge.events';
import { Origin, VendorKnowledgeStoryblokItemModel } from 'shared/models/vendor-knowledge-item.model';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';

import { markInsightAsSeen } from '../../vendor-knowledge-item/helpers/mark-insight-as-seen';
import { isVideoInContent } from '../../vendor-knowledge-item/helpers/storyblok.utils';

type VendorInsightsModalParams = {
  subscriptionId?: string;
  companyId: string;
  vendorId?: string;
  onModalClose?: () => void;
};

export function useVendorInsightsModal(origin: Origin, params: VendorInsightsModalParams) {
  const { companyId, onModalClose, subscriptionId, vendorId } = params;
  const { t } = useTranslation();
  const { onCloseModal, onShowModal } = useModalAndNotification();
  const loggedInUser = useUser();
  const enableInsightCategory = useCompanyFeatureFlag(featureFlags.ENABLE_VENDOR_INSIGHTS_CATEGORY);
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isVendorInsightsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);

  const { data: subscription, isLoading: isLoadingSubscription } = useFetchSubscription(subscriptionId || '');
  const { data: vendor, isLoading: isLoadingVendor } = useFetchVendor(vendorId || '');

  const closeVendorModal = (item: VendorKnowledgeStoryblokItemModel) => {
    onCloseModal();
    onModalClose?.();
    if (isVendorInsightsEnabled) {
      trackVendorKnowledgeModalClosed({
        companyId,
        hasVideo: isVideoInContent(item.content.content.content),
        origin,
        storyblokInsightId: item.id,
        subscriptionId,
        title: item.content.title,
        userId: loggedInUser?.id,
        vendorId,
      });
      fireGTagManagerEvent(window, String(loggedInUser?.email), googleTagManagerEvents.VendorInsightsModalClosed);
    }
  };

  const openVendorModal = (item: VendorKnowledgeStoryblokItemModel) => {
    if (!item.insight_seen && vendorId) {
      markInsightAsSeen(item.id, vendorId);
    }

    onShowModal({
      children: (
        <VendorInsightComponent
          content={item.content}
          publishedAt={item.published_at}
          isLoading={isLoadingSubscription || isLoadingVendor}
          subscription={subscription}
          vendor={vendor}
          closeButtonLabel={t('common_labels:close')}
          onClose={() => closeVendorModal(item)}
          category={enableInsightCategory ? item.category : null}
        />
      ),
      hasCustomFooter: true,
      onCancelButtonClick: () => closeVendorModal(item),
      overlayClass: 'vendor-insight-modal-overlay',
      showHeader: false,
      triggerCancelButtonClick: true,
    });
    if (isVendorInsightsEnabled) {
      trackVendorKnowledgeModalOpened({
        companyId,
        hasVideo: isVideoInContent(item.content.content.content),
        origin,
        storyblokInsightId: item.id,
        subscriptionId,
        title: item.content.title,
        userId: loggedInUser?.id,
        vendorId,
      });
    }
  };

  return [openVendorModal];
}
