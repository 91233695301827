import { format } from 'date-fns';

const isDateValid = (date: string) => {
  const dateObject = new Date(date);

  return dateObject instanceof Date && !Number.isNaN(dateObject.getTime());
};

export const getAmountWithCurrency = (amount: number, currency: string) =>
  new Intl.NumberFormat(currency, {
    currency,
    style: 'currency',
  }).format(amount / 100);

export const formatText = (text = '') => text.charAt(0).toUpperCase() + text.slice(1);
export const formatDetailsDate = (date: string): string | null =>
  isDateValid(date) ? format(new Date(date), 'dd/MM/yyyy') : null;
