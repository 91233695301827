import { Box, styled, Theme } from '@mui/material';

export const useFormBuilderStyles = (theme: Theme) => {
  const fontColor = { color: '#363F45' };
  const resetPadding = { padding: 0 };
  const resetMargin = { margin: 0 };
  const resetPaddingAndMargin = { ...resetPadding, ...resetMargin };
  const fontStyle = {
    ...fontColor,
    fontFamily: '"Inter var",Helvetica,Arial,sans-serif',
  };
  const typographyBody = {
    ...fontStyle,
    ...resetPaddingAndMargin,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  };

  const typographyP = { ...typographyBody, display: 'block' };

  const typographySmall = {
    fontFamily: '"Inter var",Helvetica,Arial,sans-serif',
    ...resetPaddingAndMargin,
    fontSize: '0.6875rem',
    fontWeight: 400,
    lineHeight: '0.9375rem',
  };

  const typographyH1 = {
    ...fontStyle,
    ...resetPaddingAndMargin,
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '1.8125rem',
  };

  const typographyH2 = {
    ...fontStyle,
    ...resetPaddingAndMargin,
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.5rem',
  };

  const typographyH3 = {
    ...fontStyle,
    ...resetPaddingAndMargin,
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.1875rem',
  };

  const formControl = {
    '&:focus': {
      boxShadow: 'none',
      outline: `1px solid ${theme.palette.divider}`,
    },
    backgroundColor: '#F8F9FB !important',
    border: 'none',
    borderRadius: '.25rem',
    ...typographyBody,
    padding: '.5rem .75rem',
  };

  return {
    fontColor,
    fontStyle,
    formControl,
    resetMargin,
    resetPadding,
    typographyBody,
    typographyH1,
    typographyH2,
    typographyH3,
    typographyP,
    typographySmall,
  };
};

export const StyledFormBuilder = styled(Box)<{ isViewOnly: boolean; dropzoneImage: any }>(
  ({ dropzoneImage, isViewOnly, theme }) => {
    const {
      formControl,
      resetMargin,
      resetPadding,
      typographyBody,
      typographyH1,
      typographyH2,
      typographyH3,
      typographyP,
      typographySmall,
    } = useFormBuilderStyles(theme);

    return {
      '&': {
        ...(isViewOnly
          ? {
              '&:after': {
                backgroundColor: theme.palette.background.paper,
                bottom: 0,
                content: '""',
                left: 0,
                opacity: 0.5,
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 10,
              },
              pointerEvents: 'none',
              position: 'relative',
            }
          : {}),
        '.formbuilder': {
          // RIGHT AREA - FORM DISPLAY
          '.formarea': {
            '.formio-builder-form': {
              background: theme.palette.background.paper,

              // COMMON PROPS FOR EVERY COMPONENT
              '.formio-component': {},

              // CHECKBOX
              '.formio-component-checkbox': {
                '.checkbox.form-check': {
                  ...resetMargin,
                  ...typographyBody,
                  left: '1.5rem',
                  span: { position: 'relative', top: '2px' },
                },
              },

              // HTML CONTENT
              '.formio-component-content': {
                '.formio-component-htmlelement': {
                  border: 'none',
                  h2: { ...typographyH1, padding: '.5rem 0' },
                  h3: { ...typographyH2, padding: '.35rem 0' },
                  h4: { ...typographyH3, padding: '.25rem 0' },
                  li: { ...typographyBody },
                  p: { ...typographyP, padding: '.15rem 0' },
                  ul: { listStyle: 'inherit', marginLeft: '1.125rem' },
                  ol: { paddingInlineStart: '.5rem' },
                },
                border: 'none',
              },

              // DATE-TIME
              '.formio-component-datetime': {
                '.form-text': { ...typographySmall, paddingTop: '4px' },
                input: { ...formControl },
                label: { ...typographyBody, paddingBottom: '.5rem' },
              },

              // EMAIL
              '.formio-component-email': {
                input: { ...formControl },
                label: { ...typographyBody, paddingBottom: '.5rem' },
              },

              // RADIO GROUP
              '.formio-component-radio': {
                '.form-text': { ...typographySmall, paddingTop: '4px' },
                '.radio.form-check': {
                  ...resetMargin,
                  ...typographyP,
                  label: { ...resetPadding },
                  left: '1.5rem',
                  span: { position: 'relative', top: '1px' },
                },
                label: { ...typographyP, paddingBottom: '.5rem' },
              },

              // CHECKBOX GROUP
              '.formio-component-selectboxes': {
                '.checkbox.form-check': {
                  ...resetMargin,
                  ...typographyP,
                  label: { ...resetPadding },
                  left: '1.5rem',
                  span: { position: 'relative', top: '1px' },
                },
                '.form-text': { ...typographySmall, paddingTop: '4px' },
                label: { ...typographyP, paddingBottom: '.5rem' },
              },

              // TEXTAREA
              '.formio-component-textarea': {
                '.form-text': { ...typographySmall, paddingTop: '4px' },
                label: { ...typographyBody, paddingBottom: '.5rem' },
                textarea: { ...formControl },
              },

              // TEXTFIELD
              '.formio-component-textfield': {
                '.form-text': { ...typographySmall, paddingTop: '4px' },
                input: { ...formControl },
                label: { ...typographyBody, paddingBottom: '.5rem' },
              },

              // URL
              '.formio-component-url': {
                '.form-text': { ...typographySmall, paddingTop: '4px' },
                input: { ...formControl },
                label: { ...typographyBody, paddingBottom: '.5rem' },
              },

              // DRAG & DROP ALERT
              '.alert-info': {
                backgroundColor: formControl.backgroundColor,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.spacing(0.5),
                ...typographyBody,
                padding: theme.spacing(2),
                position: 'relative',
                top: theme.spacing(34.5),
                margin: theme.spacing(6),

                '&::before': {
                  width: theme.spacing(30),
                  height: theme.spacing(30),
                  content: '""',
                  position: 'absolute',
                  bottom: theme.spacing(13),
                  left: `calc(50% - ${theme.spacing(15)})`,
                  backgroundImage: `url(${dropzoneImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                },
              },

              border: `1px solid ${theme.palette.divider}`,

              borderRadius: theme.spacing(0.5),

              minHeight: theme.spacing(57),

              padding: `${theme.spacing(2)} !important`,
            },
          },

          // LEFT AREA - FORM ELEMENTS
          '.formcomponents': {
            '.builder-sidebar_scroll': {
              '.form-builder-panel': {
                '#group-container-custom.card-body': {
                  display: 'flex',
                  flexDirection: 'column',
                  padding: `${theme.spacing(2)} !important`,

                  'span.formcomponent': {
                    '&.btn': {
                      fontFamily: '"Inter var",Helvetica,Arial,sans-serif',
                      fontSize: '0.875rem',
                      fontWeight: 400,
                      lineHeight: '1.25rem',

                      '& > i': {
                        fontSize: '1rem',
                      },
                    },

                    '&.btn-primary': {
                      backgroundColor: '#F8F9FB !important',
                      border: 'none',
                      color: '#363F45',
                    },
                    '&:not(:last-child)': { margin: '0 0 .5rem' },

                    border: 'none',

                    padding: theme.spacing(1.25, 1),
                  },
                },
                '#heading-custom.card-header': {
                  background: 'none',
                  border: 'none',
                  button: {
                    cursor: 'initial',
                    fontFamily: '"Inter var",Helvetica,Arial,sans-serif',
                    fontSize: '1.25rem',
                    fontWeight: 600,
                    lineHeight: '1.5rem',
                    margin: 0,
                    padding: 0,
                    pointerEvents: 'none',
                  },

                  padding: '1rem 1rem 0 !important',
                },
                border: `1px solid ${theme.palette.divider}`,

                borderRadius: theme.spacing(0.5),

                minHeight: theme.spacing(57),
              },

              top: 0,
            },

            '.form-control.builder-sidebar_search': {
              display: 'none',
            },
          },
        },
      },
    };
  }
);
