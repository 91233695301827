import { Drawer, DrawerProps as MUIDrawerProps, styled } from '@mui/material';
import { withTransientProps } from 'asteroids';
import { colors } from 'shared/theme';

type TransientProps = {
  $isFullWidth: boolean;
  $isMidSize: boolean;
  $isInitiativeCustomWidth: boolean;
};

export const DrawerStyledContainer = styled(
  Drawer,
  withTransientProps
)<MUIDrawerProps & TransientProps>(({ $isFullWidth, $isInitiativeCustomWidth, $isMidSize, theme }) => {
  const getWidth = ($breakpoint: '1024px' | '1280px' | '1536px') => {
    switch ($breakpoint) {
      case '1024px':
        return {
          maxWidth: $isInitiativeCustomWidth ? theme.spacing(110) : '96%',
          minWidth: $isInitiativeCustomWidth ? theme.spacing(110) : theme.spacing(90),
        };
      case '1280px':
        return {
          maxWidth: $isInitiativeCustomWidth ? theme.spacing(110) : '1143px',
          minWidth: theme.spacing(110),
          ...($isFullWidth && { minWidth: '100%' }),
          ...($isMidSize && { minWidth: 'unset' }),
          ...($isInitiativeCustomWidth && { minWidth: theme.spacing(110) }),
        };
      case '1536px':
        return {
          maxWidth: $isInitiativeCustomWidth ? theme.spacing(128) : '1280px',
          minWidth: $isInitiativeCustomWidth ? theme.spacing(128) : theme.spacing(130),
        };
      default:
        return {
          minWidth: 'unset',
          maxWidth: 'unset',
        };
    }
  };

  return {
    '& .MuiDrawer-paper': {
      '& .MuiGrid-container': {
        margin: 0,
      },

      '&.rootEdit': {
        overflow: 'hidden',
        width: '80%',
      },

      '&.rootUpdated': {
        overflow: 'hidden',
        width: '100%',
      },

      '@media screen and (min-width: 1024px)': {
        maxWidth: getWidth('1024px').maxWidth,
        minWidth: getWidth('1024px').minWidth,
      },

      '@media screen and (min-width: 1280px)': {
        maxWidth: getWidth('1280px').maxWidth,
        minWidth: getWidth('1280px').minWidth,
      },

      '@media screen and (min-width: 1536px)': {
        maxWidth: getWidth('1536px').maxWidth,
        minWidth: getWidth('1536px').minWidth,
      },

      minWidth: $isFullWidth ? '100%' : 'unset',
      overflow: 'hidden',
      width: $isMidSize ? '50%' : '98%',
    },

    '& .MuiPaper-root > .MuiGrid-root > .MuiGrid-item': {
      padding: 0,
    },

    '& .leftSection': {
      padding: theme.spacing(5),
    },

    '& .leftSectionContent': {
      marginTop: theme.spacing(4),
    },

    '& .leftSectionFooter': {
      '& .help-center-link': {
        color: colors.primary,
        fontSize: '0.875rem',
        marginRight: theme.spacing(1),
        textDecoration: 'underline',
      },

      marginTop: theme.spacing(4),
    },

    '& .leftSectionWrapper': {
      background: theme.palette.background.default,
      minHeight: '100vh',
      padding: 0,
    },

    '& .rightSectionWrapper': {
      height: '100vh',
      overflowY: 'auto',
      padding: 0,
    },
  };
});
