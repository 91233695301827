import * as yup from 'yup';

export const formValidationSchema = yup.object().shape({
  autoRenewal: yup.string().required(),
  cancelationPeriod: yup.string().required(),
  cancelationPeriodValue: yup.number().required(),
  changeInPlans: yup.string().required(),
  commitmentLengthValue: yup.number().required(),
  negotiationInvolvement: yup.string().required(),
  newVolume: yup.string().required(),
  preferredCommitmentLength: yup.string().required(),
  preferredPaymentCycle: yup.string().required(),
  renewalDate: yup.string().required(),
  renewalFrequency: yup.string().required(),
  renewalFrequencyValue: yup.number().required(),
  renewalGoal: yup.string().required(),
});
