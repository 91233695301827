import { Button, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { UsageAnalyticsInformationSection } from 'components/drawer-usage-analytics/components/usage-analytics-information-section.component';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type ConnectSectionProps = {
  guideUrl: string;
  ssoProviderName: string;
};

export const ConnectSection: FC<ConnectSectionProps> = ({ guideUrl, ssoProviderName }) => {
  const { t } = useTranslation();

  return (
    <UsageAnalyticsInformationSection
      title={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_3.title')}
      customContent={
        <>
          <Typography variant='body1' color='text.primary' sx={{ display: 'block', marginBottom: 1 }}>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_3.paragraph1')}
          </Typography>
          <Link to={{ pathname: guideUrl }} target='_blank' rel='noreferrer'>
            <Button startIcon={<Icon>account_balance</Icon>} variant='text'>
              {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_3.button', {
                name: ssoProviderName,
              }).toUpperCase()}
            </Button>
          </Link>
        </>
      }
    />
  );
};
