import { Box, Typography } from '@mui/material';
import { DatePicker, RadioGroup } from 'asteroids';
import { isValid } from 'date-fns';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatDateWithoutTimeZone } from 'views/overview/calendar/subscription-indicator/helpers';

import { CancellationPeriodField } from './change-renewal-date-cancellation-period-field.component';
import { RenewalDate } from './change-renewal-date-form.hook';

type RenewalFrequencyProps = {
  form: UseFormReturn<RenewalDate>;
};

export const RenewalDateField = (props: RenewalFrequencyProps) => {
  const { form } = props;
  const { errors } = form.formState;
  const { t } = useTranslation();

  const autoRenewal = form.watch('autoRenewal');
  const renewalDate = form.watch('renewalDate');
  const renewalDateString = isValid(new Date(renewalDate || ''))
    ? formatDateWithoutTimeZone(renewalDate, 'do LLL yyyy')
    : null;

  const radioOptions = [
    {
      'data-testid': 'unknown',
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.auto_renewal_select_input_section.unknown_option_text'
      ),
      value: -1,
    },
    {
      'data-testid': 'renews-automatically',
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.auto_renewal_select_input_section.yes_option_text'
      ),
      value: 1,
    },
    {
      'data-testid': 'expires',
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.auto_renewal_select_input_section.no_option_text',
        { date: renewalDate ? renewalDateString : 'dd mmm yyyy' }
      ),
      value: 0,
    },
  ].map((option) => (option.value === autoRenewal ? { ...option, checked: true } : option));

  return (
    <Box className='sastrify-changerenewaldate' data-testid='next-renewal-date'>
      <Typography variant='h3' mt={3} mb={2}>
        {t('common:modals.change_renewal_date_feature.body_section.form.next_renewal_date_input_label_text')}
      </Typography>
      <Controller
        name='renewalDate'
        control={form.control}
        render={({ field: { name, onChange, value } }) => (
          <DatePicker
            className='renewal-date-input'
            value={value as any}
            minDate={new Date() as any}
            error={!!errors[name]}
            onChange={(newValue) => onChange(newValue)}
            helperText={errors[name]?.message}
            inputFormat='dd/MM/yyyy'
          />
        )}
      />
      <Typography variant='h3' mt={3} data-testid='renewal-type'>
        {t('common:modals.change_renewal_date_feature.body_section.form.renewal_type_input_label')}
      </Typography>
      <Controller
        name='autoRenewal'
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup options={radioOptions} value={value} onChange={onChange} data-testid='renewal-radio' />
        )}
      />

      {Number(autoRenewal) === 1 && <CancellationPeriodField form={form} />}
    </Box>
  );
};
