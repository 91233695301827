import { Box, CardActionArea, CardActions, CardContent, CardMedia, Skeleton, Typography } from '@mui/material';
import classnames from 'classnames';
import React, { useCallback } from 'react';

import { CardWithTitleProps } from './card-with-title.props';
import { StyledCardContainer } from './card-with-title.styles';

export const CardWithTitle: React.FC<CardWithTitleProps> = (props) => {
  const {
    actionsDivider,
    cardActions,
    cardActionsAlignment = 'right',
    cardMedia,
    children,
    contentSx = {},
    header,
    onClick,
    title,
    titleActions,
    titleActionsLoading,
    titleDivider,
    ...rest
  } = props;

  const hasCardAction = typeof onClick === 'function';

  const onClickHandler = useCallback(
    (evt: React.SyntheticEvent) => {
      const target = evt?.target;
      if (target && (target as HTMLElement).tagName !== 'BUTTON' && typeof onClick === 'function') {
        onClick(evt);
      }
    },
    [onClick]
  );

  const renderCardContents = () => (
    <>
      {cardMedia && <CardMedia {...cardMedia} />}

      {!header ? (
        (title || titleActions) && (
          <Box
            className={classnames('MuiCardWithTitle-header', {
              'MuiCardWithTitle-header--with-divider': titleDivider !== false,
            })}>
            {title &&
              (typeof title === 'string' || typeof title === 'number' ? (
                <Typography variant='h3'>{title}</Typography>
              ) : (
                <Box>{title}</Box>
              ))}
            {!hasCardAction && titleActions && (
              <Box className='MuiCardWithTitle-title-actions' data-testid='cardwithtitle-title-actions'>
                {titleActionsLoading ? (
                  <Skeleton variant='rounded' height={32} width={100} animation='wave' />
                ) : (
                  titleActions
                )}
              </Box>
            )}
          </Box>
        )
      ) : (
        <Box
          className={classnames('MuiCardWithTitle-header__custom', {
            'MuiCardWithTitle-header--with-divider': titleDivider === true,
          })}>
          {header}
        </Box>
      )}

      <CardContent sx={{ mt: title ? 0 : 1, ...contentSx }}>{children}</CardContent>

      {cardActions && (
        <CardActions
          sx={{
            borderTopColor: (theme) => (actionsDivider ? theme.palette.divider : 'transparent'),
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
          }}
          key='card-actions'
          data-testid='cardwithtitle-card-actions'>
          {cardActions}
        </CardActions>
      )}
    </>
  );

  // Card action control
  if (hasCardAction) {
    rest.variant = undefined; // Variant when having card action is always 'undefined' because it acts like a big button
  }
  rest.className = classnames(rest.className, {
    'MuiCardWithTitle-with-action': hasCardAction,
  });

  return (
    <StyledCardContainer $cardActionsAlignment={cardActionsAlignment} {...rest}>
      {hasCardAction ? (
        <CardActionArea onClick={onClickHandler}>{renderCardContents()}</CardActionArea>
      ) : (
        renderCardContents()
      )}
    </StyledCardContainer>
  );
};

export default CardWithTitle;
