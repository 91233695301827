import { TableCellProps } from '@mui/material/TableCell';
import { MRT_Cell, MRT_Column, MRT_Row, MRT_RowData, MRT_TableInstance } from 'material-react-table';

const getIsLastLeftPinnedColumn = (table: MRT_TableInstance<MRT_RowData>, column: MRT_Column<MRT_RowData>) => {
  return column.getIsPinned() === 'left' && table.getLeftLeafHeaders().length - 1 === column.getPinnedIndex();
};
const getIsFirstRightPinnedColumn = (column: MRT_Column<MRT_RowData>) => {
  return column.getIsPinned() === 'right' && column.getPinnedIndex() === 0;
};

// Appends classes to cell depending on special column configurations
export const getCellProps: (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  originalProps: any,
  props: {
    cell?: MRT_Cell<MRT_RowData>;
    column: MRT_Column<MRT_RowData>;
    row?: MRT_Row<MRT_RowData>;
    table: MRT_TableInstance<MRT_RowData>;
  }
) => TableCellProps = (originalProps, args) => {
  // Function args are { cell, column, row, table } for body cells and { column, table } for head cells
  const { cell, column, row, table } = args;
  const { columnDef } = column;
  const cellMuiProps = cell && row ? columnDef.muiTableBodyCellProps : columnDef.muiTableHeadCellProps;
  const cellProps = cellMuiProps instanceof Function ? cellMuiProps(args as never) : cellMuiProps;

  const globalProps =
    (originalProps instanceof Function ? (originalProps as (props: unknown) => TableCellProps)(args) : originalProps) ||
    {};

  const isPinned = column.getIsPinned();

  if (isPinned) {
    const isLastLeftpinned = getIsLastLeftPinnedColumn(table, column);
    const isFirstRightpinned = getIsFirstRightPinnedColumn(column);

    let classNames = `MuiTableCell-pinned MuiTableCell-pinned--${isPinned} `;

    if (isLastLeftpinned) {
      classNames += `MuiTableCell-pinned--${isPinned}--last `;
    }
    if (isFirstRightpinned) {
      classNames += `MuiTableCell-pinned--${isPinned}--first `;
    }

    if (classNames) {
      if (cellProps) {
        const classNamesList = (`${cellProps?.className || ''} ${classNames}`?.split(' ') || []).filter((x) => !!x);
        cellProps.className = [...new Set(classNamesList)].join(' ');
      } else {
        globalProps.className = `${globalProps?.className || ''} ${classNames}`.trim();
      }
    }
  }

  return Object.keys(globalProps).length ? globalProps : originalProps;
};

export const getMergedMuiProps = (
  prop: unknown,
  defaultProps: unknown,
  args: { table: MRT_TableInstance<MRT_RowData> }
) => {
  const originalProps = prop instanceof Function ? prop(args) : prop;
  const sxProps = {};

  if (!originalProps) return defaultProps;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (originalProps?.sx && defaultProps?.sx) sxProps.sx = { ...defaultProps.sx, ...originalProps.sx };

  return { ...(originalProps || {}), ...sxProps };
};
