import { Box, styled } from '@mui/material';
import { boxShadows, colors } from 'shared/theme';

export const SubscriptionSelectInputWrapper = styled(Box)(({ theme }) => ({
  '& .button': {
    '& .selected-items': {
      marginRight: '.5rem',
    },
    background: colors.white,
    border: `0.027rem solid ${colors.iron}`,
    borderRadius: '0.25rem',
    cursor: 'pointer',
    height: '2.5rem',
    marginBottom: '0.25rem',
    padding: '0.25rem 0.5rem',
    width: '100%',
  },
  '& .inputBase': {
    margin: '0.75rem 0',
    padding: 10,
    width: '100%',
  },
  '& .option': {
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    alignItems: 'flex-start',
    minHeight: 'auto',
    padding: '0.5rem',
  },
  '& .paper': {
    boxShadow: 'none',
    color: colors.rollingStone,
    fontSize: '0.813rem',
    margin: 0,
  },
  '& .popper': {
    '& .MuiAutocomplete-listbox': {
      overflowY: 'hidden !important',
    },
    backgroundColor: colors.white,
    borderRadius: '0.25rem',
    boxShadow: boxShadows.dropDownPopperBoxShadow,
    color: colors.rollingStone,
    fontFamily: 'inherit',
    fontSize: '0.813rem',
    padding: '0 0.938rem',
    position: 'absolute',
    width: '100%',
    zIndex: 10000,
  },
  '& .popperDisablePortal': {
    position: 'relative !important',
    transform: 'none !important',
  },
  '& .root': {
    '& .chip': {
      background: colors.greyBackgroundHover,
      borderRadius: '100%',
      fontSize: '.8rem',
      padding: '.4rem',
    },
    width: '100%',
  },
}));
