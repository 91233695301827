import './subscription-insight-alert.scss';

import { Alert, AlertTitle } from '@mui/material';
import { useFetchVendorInsights } from 'hooks/useFetchVendorInsights';
import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { getSubscriptionVendorInsightTabUrl } from 'shared/helpers/vendor-insight-helpers';

import { SubscriptionInsightAlertProps } from './subscription-insight-alert.props';

export const SubscriptionInsightAlert: FC<SubscriptionInsightAlertProps> = ({ onClick, subscription }) => {
  const { companyId, vendorId } = subscription;
  const { t } = useTranslation();
  const location = useLocation();

  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();

  const isVendorInsightsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);

  const { data: subscriptionInsightData, isLoading: subscriptionInsightIsLoading } = useFetchVendorInsights({
    companyId,
    vendorId,
  });

  const shouldShowInsightAlert = isVendorInsightsEnabled && !subscriptionInsightIsLoading && !!subscriptionInsightData;

  const linkToInsights = getSubscriptionVendorInsightTabUrl(subscription);

  const isSamePath = `${location.pathname}${location.hash}` === linkToInsights;

  if (!shouldShowInsightAlert || !linkToInsights || isSamePath) return null;

  /* TODO: replace the whole component with Sastrify alert component once design system is ready */

  return (
    <Alert
      className='subscription-insight-alert'
      severity='info'
      action={
        <Link
          className='subscription-insight-alert-link'
          to={linkToInsights}
          onClick={() => onClick(subscription)}
          data-testid='subscription-insight-alert-link'>
          {t('vendor_insight:vendor_insight_alert.button_label')}
        </Link>
      }>
      <AlertTitle className='subscription-insight-alert-title' data-testid='subscription-insight-alert-title'>
        {t('vendor_insight:vendor_insight_alert.title')}
      </AlertTitle>
      <div className='subscription-insight-alert-body' data-testid='subscription-insight-alert-body'>
        {t('vendor_insight:vendor_insight_alert.body')}
      </div>
    </Alert>
  );
};
