import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { ToolMatchingRecord, ToolMatchingRecords, ToolMatchingStatusLabels } from '../types';

export type ToolMatchingRecordState =
  | ToolMatchingStatusLabels.Matched
  | ToolMatchingStatusLabels.Unmatched
  | ToolMatchingStatusLabels.Ignored;

export const ToolMatchingContext = createContext<{
  filteredToolMatchingRecords: ToolMatchingRecords;
  allToolMatchingRecords: ToolMatchingRecords;
  updateFilteredToolMatchingRecords: (
    filteredToolMatchingRecords: ToolMatchingRecord[],
    type: ToolMatchingStatusLabels
  ) => void;
  updateToolMatchingRecord: (
    toolMatchingRecordState: ToolMatchingRecordState,
    toolMatchingRecordIndex: number,
    newToolInSastrify: ToolMatchingRecord['toolInSastrify']
  ) => void;
  getToolMatchingRecord: (
    toolMatchingRecordState: ToolMatchingRecordState,
    toolMatchingRecordIndex: number
  ) => ToolMatchingRecord;
}>({
  allToolMatchingRecords: { ignored: [], matched: [], unmatched: [] },
  filteredToolMatchingRecords: { ignored: [], matched: [], unmatched: [] },
  getToolMatchingRecord: () => ({} as ToolMatchingRecord),
  updateFilteredToolMatchingRecords: () => {},
  updateToolMatchingRecord: () => {},
});

export const ToolMatchingProvider = ({
  children,
  data = { ignored: [], matched: [], unmatched: [] },
}: {
  children: React.ReactNode;
  data: ToolMatchingRecords;
}) => {
  const [filteredToolMatchingRecords, setFilteredToolMatchingRecords] = useState<ToolMatchingRecords>({
    ignored: [],
    matched: [],
    unmatched: [],
  });
  const [allToolMatchingRecords, setAllToolMatchingRecords] = useState<ToolMatchingRecords>({
    ignored: [],
    matched: [],
    unmatched: [],
  });

  useEffect(() => {
    if (data.unmatched && data.matched && data.ignored) {
      setFilteredToolMatchingRecords(data);
      setAllToolMatchingRecords(data);
    }
  }, [data]);

  const updateToolMatchingRecord = useCallback(
    (
      toolMatchingRecordState: ToolMatchingRecordState,
      toolMatchingRecordIndex: number,
      newToolInSastrify: ToolMatchingRecord['toolInSastrify']
    ) => {
      setFilteredToolMatchingRecords((prevToolMatchingRecords) => {
        const newToolMatchingRecords = { ...prevToolMatchingRecords };

        // set current value as previous value
        newToolMatchingRecords[toolMatchingRecordState][toolMatchingRecordIndex].toolInSastrifyPreviousValue =
          newToolMatchingRecords[toolMatchingRecordState][toolMatchingRecordIndex].toolInSastrify;

        // set original/server value if not set
        if (!newToolMatchingRecords[toolMatchingRecordState][toolMatchingRecordIndex].toolInSastrifyOriginalValue) {
          newToolMatchingRecords[toolMatchingRecordState][toolMatchingRecordIndex].toolInSastrifyOriginalValue =
            newToolMatchingRecords[toolMatchingRecordState][toolMatchingRecordIndex].toolInSastrify;
        }

        // set current value
        newToolMatchingRecords[toolMatchingRecordState][toolMatchingRecordIndex].toolInSastrify = newToolInSastrify;

        return newToolMatchingRecords;
      });
    },
    []
  );

  const getToolMatchingRecord = useCallback(
    (toolMatchingRecordState: ToolMatchingRecordState, toolMatchingRecordIndex: number) =>
      filteredToolMatchingRecords[toolMatchingRecordState][toolMatchingRecordIndex],
    [filteredToolMatchingRecords]
  );

  const updateFilteredToolMatchingRecords = useCallback(
    (filteredToolMatchingRecords: ToolMatchingRecord[], type: ToolMatchingStatusLabels) => {
      setFilteredToolMatchingRecords((prevToolMatchingRecords) => {
        const newToolMatchingRecords = { ...prevToolMatchingRecords };

        newToolMatchingRecords[type] = filteredToolMatchingRecords;

        return newToolMatchingRecords;
      });
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      allToolMatchingRecords,
      filteredToolMatchingRecords,
      getToolMatchingRecord,
      updateFilteredToolMatchingRecords,
      updateToolMatchingRecord,
    }),
    [
      filteredToolMatchingRecords,
      updateToolMatchingRecord,
      getToolMatchingRecord,
      updateFilteredToolMatchingRecords,
      allToolMatchingRecords,
    ]
  );

  return <ToolMatchingContext.Provider value={contextValue}>{children}</ToolMatchingContext.Provider>;
};
