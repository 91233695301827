import { SxProps } from '@mui/material';
import { PRICING_PLANS_FEATURES } from 'libs/constants';

export const REQUESTED_ADDONS_KEY = 'requestedAddons';

export enum UpsellingPromptVariant {
  VIEW,
  CARD,
}

export interface UpsellingPromptProps {
  feature: PRICING_PLANS_FEATURES;
  featureName: string;
  description?: string;
  learnMoreLink: string;
  image: string;
  variant?: UpsellingPromptVariant;
  submitting?: boolean;
  sx?: SxProps;
}

export type UpsellingPromptCardProps = {
  featureName: string;
  description?: string;
  learnMoreLink: string;
  image: string;
  isAddonRequested?: boolean;
  submitting?: boolean;
  sx?: SxProps;
  handleAddonRequest: () => void;
};

export type UpsellingPromptViewProps = UpsellingPromptCardProps & { imageHeight?: string };
