import { Box, type BoxProps as MuiBoxProps, Stack, type StackProps as MuiStackProps, styled } from '@mui/material';

export const StatusesContainer = styled(Box)<MuiBoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}));

export const TotalContainer = styled(Box)<MuiBoxProps>(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ChartContainer = styled(Stack)<MuiStackProps>(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.background.light,
  '& > .MuiBox-root:first-of-type': {
    width: theme.spacing(12),
    padding: theme.spacing(0, 1),
  },
  '& > .MuiBox-root:last-child': {
    width: theme.spacing(19),
  },
}));
