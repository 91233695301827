import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteMultipleUserSubscriptions,
  deleteUserNewSolution,
  deleteUserSubscription,
} from 'shared/logic/subscription-item.logic';
import { Company, SastrifyStore, Subscription, User } from 'shared/models';
import { OnSelectSubscriptionParams } from 'shared/models/subscription.model';
import { useAppDispatch } from 'shared/store/hooks';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { trackDiscoveredBulkDeleteAction } from '../../shared/logic/event-tracking/discovered-subscriptions.events';
import { ModalAndNotificationsContext } from '../context/modal-notification.context';

type useDeleteSubscriptionProps = {
  isSubscriptionInDiscovery: boolean;
  isDetailsView?: boolean;
  subscription?: Subscription;
  subscriptions?: Subscription[];
  onSelect?: (params: OnSelectSubscriptionParams) => void;
};

export const useDeleteSubscription = ({
  isDetailsView,
  isSubscriptionInDiscovery,
  onSelect,
  subscription,
  subscriptions,
}: useDeleteSubscriptionProps) => {
  const { onCloseModal, onShowModal, onShowNotification } = useContext(ModalAndNotificationsContext);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { t } = useTranslation();
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;
  const company = useQueryClient().getQueryData('company') as Company;

  const { mutate: deleteSubscription } = useMutation(
    isSubscriptionInDiscovery ? deleteUserNewSolution : deleteUserSubscription
  );

  const { mutate: deleteMultipleSubscription } = useMutation(deleteMultipleUserSubscriptions);

  const onConfirmDeleteMultipleSubscriptions = useCallback(
    (subscriptionIds: string[]) => {
      dispatch(updateFooterState({ isFormSubmitting: true }));

      deleteMultipleSubscription(subscriptionIds, {
        onError: (error) => {
          onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
        },
        onSettled: () => {
          dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
        },
        onSuccess: () => {
          onCloseModal();
          onShowNotification?.(
            t(
              'common:delete_subscription_or_new_solution_section.confrimation_messages.subscriptions_deleted_successfully_text'
            ),
            NotificationAlertType.Success
          );

          onSelect?.({ unSelectAll: true });

          queryClient.invalidateQueries('subscriptions');
          queryClient.invalidateQueries('subscriptions-todos');
          queryClient.invalidateQueries('potential-savings');
        },
      });
    },
    [deleteMultipleSubscription, dispatch, onCloseModal, onShowNotification, onSelect, queryClient, t]
  );

  const onConfirmDeleteSubscription = useCallback(
    (subscriptionId: string) => {
      dispatch(updateFooterState({ isFormSubmitting: true }));

      deleteSubscription(subscriptionId, {
        onError: (error) => {
          onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
        },
        onSettled: () => {
          dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
        },
        onSuccess: () => {
          onCloseModal();

          onShowNotification?.(
            isSubscriptionInDiscovery
              ? t(
                  'common:delete_subscription_or_new_solution_section.confrimation_messages.new_solution_deleted_successfully_text'
                )
              : t(
                  'common:delete_subscription_or_new_solution_section.confrimation_messages.subscription_deleted_successfully_text'
                ),
            NotificationAlertType.Success
          );

          queryClient.invalidateQueries(
            isSubscriptionInDiscovery ? ['subscriptions-in-discovery', true] : 'subscriptions'
          );

          queryClient.invalidateQueries('potential-savings');

          if (isDetailsView && !isSubscriptionInDiscovery) {
            history.push(NAVIGATION_URLS.SUBSCRIPTIONS);
          }
        },
      });
    },
    [
      deleteSubscription,
      dispatch,
      isSubscriptionInDiscovery,
      queryClient,
      onShowNotification,
      t,
      history,
      isDetailsView,
      onCloseModal,
    ]
  );

  const onDeleteMultipleSubscriptions = useCallback(() => {
    if (!subscriptions) return;

    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });

    const subscriptionIds = subscriptions.map((item) => item.id);
    const title = t(
      'common:delete_subscription_or_new_solution_section.delete_modal_section.subscription_title_text_multiple'
    );

    if (subscriptions && onShowModal) {
      onShowModal({
        cancelButtonText: t(
          'common:delete_subscription_or_new_solution_section.delete_modal_section.cancel_button_text'
        ),
        children: null,
        confirmButtonText: t(
          'common:delete_subscription_or_new_solution_section.delete_modal_section.confirm_button_text'
        ),
        message: t('common:delete_subscription_or_new_solution_section.delete_modal_section.body_text_multiple', {
          count: subscriptions.length,
        }),
        onCancelButtonClick: onCloseModal,
        onConfirmButtonClick: () => onConfirmDeleteMultipleSubscriptions(subscriptionIds),
        title,
      });
    }
    trackDiscoveredBulkDeleteAction({ companyId: company.id as string, subscriptionIds, userId: user.id as string });
  }, [company, dispatch, onCloseModal, onConfirmDeleteMultipleSubscriptions, onShowModal, subscriptions, t, user]);

  const onDeleteSubscription = useCallback(
    (companyTool?: Subscription) => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });

      const title = isSubscriptionInDiscovery
        ? t('common:delete_subscription_or_new_solution_section.delete_modal_section.new_solution_title_text')
        : t('common:delete_subscription_or_new_solution_section.delete_modal_section.subscription_title_text');

      if ((companyTool || subscription) && onShowModal) {
        onShowModal({
          cancelButtonText: t(
            'common:delete_subscription_or_new_solution_section.delete_modal_section.cancel_button_text'
          ),
          children: null,
          confirmButtonText: t(
            'common:delete_subscription_or_new_solution_section.delete_modal_section.confirm_button_text'
          ),
          message: t('common:delete_subscription_or_new_solution_section.delete_modal_section.body_text', {
            name: (companyTool || subscription)?.name || (companyTool || subscription)?.vendorName,
          }),
          onCancelButtonClick: onCloseModal,
          onConfirmButtonClick: () => onConfirmDeleteSubscription((companyTool || subscription)?.id || ''),
          title,
        });
      }
    },
    [dispatch, isSubscriptionInDiscovery, onConfirmDeleteSubscription, onShowModal, subscription, onCloseModal, t]
  );

  return {
    onDeleteMultipleSubscriptions,
    onDeleteSubscription,
  };
};
