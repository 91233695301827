/* eslint-disable @typescript-eslint/no-explicit-any */

import { Form } from '@formio/react';
import { Alert } from 'asteroids';
import { useWorkflowInitiativeWizardContext } from 'components/drawer-workflow-request/hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DRAWER_VARIANT } from 'shared/common.definitions';

import { StyledForm } from './craft-initiative-custom-form.styled';

export const CraftInitiativeCustomForm = () => {
  const { customForm, customFormError, fetchedData, setCustomForm, setCustomFormError, workflow } =
    useWorkflowInitiativeWizardContext();
  const { t } = useTranslation();
  const { hash } = useLocation();

  const isEditMode = !!hash.includes(DRAWER_VARIANT.EDIT_REQUEST);

  const getJsonSchema: any = useCallback(() => {
    let jsonSchema;

    if (!isEditMode && workflow) {
      jsonSchema = workflow.customFormDefinition;
    }

    if (isEditMode && fetchedData) {
      jsonSchema = fetchedData?.customFormData?.definition;
    }

    return jsonSchema;
  }, [isEditMode, fetchedData, workflow]);

  // Triggered when fetched api data used for pre-populating custom form
  useEffect(() => {
    if (isEditMode && !customForm && fetchedData?.customFormData) {
      setCustomForm?.({ data: fetchedData?.customFormData.data, isValid: true });
    }
  }, [isEditMode, customForm, fetchedData, setCustomForm]);

  return (
    <StyledForm>
      {(!isEditMode || customForm) && (
        <Form
          form={getJsonSchema()}
          submission={customForm}
          onChange={(data: any) => {
            if (data.isValid) setCustomFormError?.(false);
            if (!customForm || data.changed) setCustomForm?.(data);
          }}
        />
      )}
      {customForm && customFormError && (
        <Alert
          description={t('requests_view:modify_initiative_drawer.wizard.form_error.description')}
          severity='error'
        />
      )}
    </StyledForm>
  );
};
