import { Box, styled } from '@mui/material';
import { useFormBuilderStyles } from 'components/form-builder/form-builder.styles';

export const StyledForm = styled(Box)(({ theme }) => {
  const {
    formControl,
    resetMargin,
    resetPadding,
    typographyBody,
    typographyH1,
    typographyH2,
    typographyH3,
    typographyP,
    typographySmall,
  } = useFormBuilderStyles(theme);

  return {
    '&': {
      '.formio-form': {
        // COMMON PROPS FOR EVERY COMPONENT
        '.formio-component': {
          paddingBottom: '1rem',
        },

        // CHECKBOX
        '.formio-component-checkbox': {
          '.checkbox.form-check': {
            ...resetMargin,
            ...typographyBody,
            left: '1.5rem',
            span: { position: 'relative', top: '2px' },
          },
        },

        // HTML CONTENT
        '.formio-component-content': {
          '.formio-component-htmlelement': {
            border: 'none',
            h2: { ...typographyH1, padding: '.5rem 0' },
            h3: { ...typographyH2, padding: '.35rem 0' },
            h4: { ...typographyH3, padding: '.25rem 0' },
            li: { ...typographyBody },
            ol: { paddingInlineStart: '.5rem' },
            p: { ...typographyP, padding: '.15rem 0' },
            ul: { listStyle: 'inherit', marginLeft: '1.125rem' },
          },
          border: 'none',
        },

        // DATE-TIME
        '.formio-component-datetime': {
          '.form-text': { ...typographySmall, paddingTop: '4px' },
          input: { ...formControl },
          label: { ...typographyBody, paddingBottom: '.5rem' },
        },

        // EMAIL
        '.formio-component-email': {
          input: { ...formControl },
          label: { ...typographyBody, paddingBottom: '.5rem' },
        },

        // RADIO GROUP
        '.formio-component-radio': {
          '.form-text': { ...typographySmall, paddingTop: '4px' },
          '.radio.form-check': {
            ...resetMargin,
            ...typographyP,
            label: { ...resetPadding },
            left: '1.5rem',
            span: { position: 'relative', top: '1px' },
          },
          label: { ...typographyP, paddingBottom: '.5rem' },
        },

        // CHECKBOX GROUP
        '.formio-component-selectboxes': {
          '.checkbox.form-check': {
            ...resetMargin,
            ...typographyP,
            label: { ...resetPadding },
            left: '1.5rem',
            span: { position: 'relative', top: '1px' },
          },
          '.form-text': { ...typographySmall, paddingTop: '4px' },
          label: { ...typographyP, paddingBottom: '.5rem' },
        },

        // TEXTAREA
        '.formio-component-textarea': {
          '.form-text': { ...typographySmall, paddingTop: '4px' },
          label: { ...typographyBody, paddingBottom: '.5rem' },
          textarea: { ...formControl },
        },

        // TEXTFIELD
        '.formio-component-textfield': {
          '.form-text': { ...typographySmall, paddingTop: '4px' },
          input: { ...formControl },
          label: { ...typographyBody, paddingBottom: '.5rem' },
        },

        // URL
        '.formio-component-url': {
          '.form-text': { ...typographySmall, paddingTop: '4px' },
          input: { ...formControl },
          label: { ...typographyBody, paddingBottom: '.5rem' },
        },

        // ERRORS
        '.formio-errors': {
          'form-text.error': {
            color: 'red !important',
          },
        },
      },
    },
  };
});
