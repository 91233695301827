import { ClickAwayListener } from '@mui/base';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '../../avatars';
import { Icon } from '../../icon/icon.component';
import { SastrifyLogo } from '../../static-assets/sastrify-logo.component';
import { MenuUserInformation } from '../menu-user-information/menu-user-information.molecule';
import { LogoAndUserProps } from './logo-and-user.props';

export const LogoAndUser = (props: LogoAndUserProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (open) return;
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const onClose = () => {
    if (!open) return;
    setOpen(false);
    setAnchorEl(null);
  };

  const onLogout = () => {
    onClose();
    if (typeof props.onLogout === 'function') props.onLogout();
  };

  const renderMenu = () => (
    <Menu
      key='user-menu'
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      sx={{ ml: 3, mr: 2, mt: -4 }}>
      <ClickAwayListener onClickAway={onClose}>
        <Box>
          <MenuItem onClick={onLogout} data-testid='logout'>
            <Icon sx={{ mr: 1 }}>logout</Icon>{' '}
            <Typography variant='subtitle'>{t('asteroids:navigation.logout_menu')}</Typography>
          </MenuItem>

          <MenuUserInformation email={props.email} companyName={props.companyName} />
        </Box>
      </ClickAwayListener>
    </Menu>
  );

  return (
    <Box
      p={1}
      pb={2}
      sx={{ alignItems: 'center', display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
      <Box key='logo-container' sx={{ display: 'flex', flex: 1, flexGrow: 1, pl: 1 }}>
        <SastrifyLogo />
      </Box>
      {props.middlePlaceholder}
      {(props.src || props.initials) && (
        <Box key='user-container'>
          <IconButton
            data-testid='user-logo-button'
            onClick={handleOpen}
            size='small'
            sx={{ ml: 2, mr: 0.5 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}>
            <Avatar size='normal' variant='circular' src={props.src}>
              {!props.src ? props.initials || '' : null}
            </Avatar>
          </IconButton>
        </Box>
      )}

      {renderMenu()}
    </Box>
  );
};
