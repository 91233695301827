import { WorkflowTemplateItemDto } from 'libs/dtos';

import { CreateWorkflowPayload, EditWorkflowPayload, WorkflowListItem } from '../models';
import { apiService, apiUrl } from '../services';

export const getWorkflowTemplates = async (): Promise<WorkflowTemplateItemDto[]> => {
  const response = await apiService.get(`${apiUrl.getRequestWorkflowTemplates()}`);

  return response.data;
};

export const getWorkflowsList = async (): Promise<WorkflowListItem[]> => {
  const response = await apiService.get(`${apiUrl.getRequestWorkflowsList()}`);

  return response.data;
};

export const createRequestWorkflow = async (payload: CreateWorkflowPayload) => {
  const response = await apiService.post(`${apiUrl.createRequestWorkflow()}`, payload);

  return response.data;
};

export const editRequestWorkflow = async (payload: EditWorkflowPayload) => {
  const response = await apiService.patch(`${apiUrl.createRequestWorkflow()}`, payload);

  return response.data;
};

export const deleteRequestWorkflow = async (workflowId: string | number) => {
  const response = await apiService.delete(`${apiUrl.deleteRequestWorkflow(workflowId)}`);

  return response.data;
};

export const saveWorkflowAsDraft = async (payload: CreateWorkflowPayload) => {
  const response = await apiService.post(`${apiUrl.saveWorkflowAsDraft()}`, payload);

  return response.data;
};
