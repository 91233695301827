import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SSOIntegrationStatus } from 'libs/enums';
import { SSOConnect } from 'shared/models/sso-connect.model';
import { apiService, apiUrl } from 'shared/services';

type ConnectPayload = {
  data: { apiKey: string; companyId: string };
  onSuccess?: () => void;
  onError?: (errorMessage: string) => void;
};

type DisconnectPayload = {
  companyId: string;
  onSuccess?: () => void;
  onError?: (errorMessage: string) => void;
};

export const connectToJumpCloud = createAsyncThunk(
  'jumpCloudConnect/connectToJumpCloud',
  async (payload: ConnectPayload, { dispatch }) => {
    const { data, onError, onSuccess } = payload;

    dispatch(jumpCloudConnectSlice.actions.setConnectingState());

    apiService
      .post(apiUrl.jumpCloudConnect(), data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(jumpCloudConnectSlice.actions.setConnectionState(true));
          onSuccess?.();
        }
      })
      .catch(({ message, response }: AxiosError<{ error?: string }>) => {
        dispatch(jumpCloudConnectSlice.actions.setError(response?.data?.error || message));
        onError?.(response?.data?.error || message);
      });
  }
);

export const disconnectFromJumpCloud = createAsyncThunk(
  'jumpCloudConnect/disconnectFromJumpCloud',
  async ({ companyId, onError, onSuccess }: DisconnectPayload, { dispatch }) => {
    apiService
      .put(apiUrl.updateJumpCloudStatus(companyId), { status: SSOIntegrationStatus.DISCONNECTED })
      .then((response) => {
        if (response.status === 200) {
          dispatch(jumpCloudConnectSlice.actions.disconnect());
          onSuccess?.();
        }
      })
      .catch(({ message, response }: AxiosError<{ error?: string }>) => {
        dispatch(jumpCloudConnectSlice.actions.setError(response?.data?.error || message));
        onError?.(response?.data?.error || message);
      });
  }
);

const initialState: SSOConnect = {
  connected: false,
  connecting: false,
  error: '',
  expired: false,
  isError: false,
  usageAnalyticsEnabled: false,
};

const jumpCloudConnectSlice = createSlice({
  initialState,
  name: 'jumpCloudConnect',
  reducers: {
    disconnect(state) {
      state.error = '';
      state.isError = false;
      state.connecting = false;
      state.connected = false;
      state.expired = false;
    },
    resetState(state) {
      state.connected = false;
      state.connecting = false;
      state.isError = false;
      state.error = '';
      state.expired = false;
      state.usageAnalyticsEnabled = false;
    },
    setConnectingState(state) {
      state.connecting = true;
    },
    setConnectionState(state, action: PayloadAction<boolean>) {
      if (state.connected !== action.payload) {
        state.connected = action.payload;
        state.connecting = false;
      }
    },
    setEnableUsageAnalytics(state, action: PayloadAction<boolean>) {
      state.usageAnalyticsEnabled = action.payload;
    },
    setError(state, action: PayloadAction<string | unknown>) {
      state.isError = true;
      state.error = action.payload;
      state.connecting = false;
      state.connected = false;
    },
    setExpired(state) {
      state.expired = true;
    },
  },
});

export const { disconnect, resetState, setConnectingState, setConnectionState, setError, setExpired } =
  jumpCloudConnectSlice.actions;
export default jumpCloudConnectSlice.reducer;
