import { Button, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CardContainer, CardContent, CardHeader } from '../usage-analytics.styles';

type UsageAnalyticsErrorCardProps = {
  onRefresh: () => void;
};

export const UsageAnalyticsCardError: React.FC<UsageAnalyticsErrorCardProps> = memo(({ onRefresh }) => {
  const { t } = useTranslation();

  return (
    <CardContainer data-testid='usage-analytics-details-card'>
      <CardHeader sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3'>{t('subscription_detail_view:usage_analytics_section:title')}</Typography>
        <Button variant='text' color='error' onClick={onRefresh}>
          {t('subscription_detail_view:usage_analytics_section:refresh_button')}
        </Button>
      </CardHeader>
      <CardContent>
        <Typography mb={2} variant='body' color='error.main'>
          {t('subscription_detail_view:usage_analytics_section:error_message')}
        </Typography>
      </CardContent>
    </CardContainer>
  );
});
