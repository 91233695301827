import { Box, Button, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import NoRequestsImage from 'assets/images/requests/no-requests-image.svg';
import { Icon } from 'asteroids';
import { Page } from 'components-2.0/page';
import { PageTitle } from 'components-2.0/page/components';
import { REQUEST_STATES } from 'libs/enums/request.enum';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { RequestWorkflowItem, RoutedProps } from 'shared/models';
import { RequestDraft } from 'shared/models/request-draft.model';

import { TabPanel } from '../connect2/molecules/tab-panel.molecule';
import { useWorkflowsListData } from '../request-workflows/hooks/use-workflows-list-data';
import { DraftInitiativeListComponent } from './components/draft-initiative-list';
import { InitiativeListComponent } from './components/initiative-list';
import { useAddRequestDrawer, useGetInitiativesTableContent, useWorkflowRequestsData } from './hooks';
import { useGetDraftInitiativesTableContent } from './hooks/use-get-draft-initiatives-table-content';
import { useDraftRequestsData } from './hooks/use-request-draft-data';

export enum QUERY {
  SUBSCRIPTIONS_IN_DISCOVERY = 'subscriptions-in-discovery',
}

enum TAB {
  ACTIVE_REQUESTS = 'activeRequests',
  REQUESTS_HISTORY = 'requestsHistory',
  DRAFT_REQUESTS = 'draftRequests',
}

enum TabIndex {
  ACTIVE_REQUESTS = 0,
  DRAFT_REQUESTS = 1,
  REQUESTS_HISTORY = 2,
}

export const draftParamPrefix = 'draft';

export const RequestsView: FC<RoutedProps> = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { hash } = useLocation();
  const { data: workflowsListData, isLoading: isWorkflowsListLoading } = useWorkflowsListData();
  const { data, isLoading } = useWorkflowRequestsData();
  const { data: draftRequestsData, isLoading: isDraftRequestsLoading } = useDraftRequestsData();
  const { onAddRequestDrawer } = useAddRequestDrawer();

  const activeRequests = useMemo(
    () =>
      data?.filter(
        ({ requestStateId }) =>
          requestStateId === String(REQUEST_STATES.ACTIVE) || requestStateId === String(REQUEST_STATES.APPROVED)
      ) || [],
    [data]
  );
  const historyRequests = useMemo(
    () =>
      data?.filter(
        ({ requestStateId }) =>
          requestStateId === String(REQUEST_STATES.REJECTED) ||
          requestStateId === String(REQUEST_STATES.SUBSCRIPTION_CREATED)
      ) || [],
    [data]
  );
  const draftRequests = useMemo(() => draftRequestsData || [], [draftRequestsData]);

  const { columns: activeInitiativesColumns } = useGetInitiativesTableContent();
  const { columns: historyInitiativesColumns } = useGetInitiativesTableContent();
  const { columns: draftInitiativesColumns } = useGetDraftInitiativesTableContent();

  useEffect(() => {
    if (!isLoading) {
      if ((!activeRequests.length && !historyRequests.length) || (activeRequests.length && !historyRequests.length)) {
        return push(`/requests${hash}`);
      }

      if (!activeRequests.length && historyRequests.length) {
        return push(`/requests?tab=${TAB.REQUESTS_HISTORY}${hash}`);
      }
    }
  }, [push, hash, isLoading, activeRequests, historyRequests, isDraftRequestsLoading, draftRequests]);

  const handleItemClick = (item: RequestWorkflowItem) => {
    onAddRequestDrawer(`#${DRAWER_VARIANT.REQUEST_DETAILS}${item.requestId}`);
  };

  const handleDraftItemClick = (item: RequestDraft) => {
    onAddRequestDrawer(`#${DRAWER_VARIANT.NEW_REQUEST}-${draftParamPrefix}-${item.id}`);
  };

  const renderActiveRequestsPanel = () => {
    return (
      <InitiativeListComponent
        columns={activeInitiativesColumns}
        rows={activeRequests}
        onItemClick={handleItemClick}
        withSearch
        isSubscriptionInitiatives
      />
    );
  };
  const renderRequestsHistoryPanel = () => {
    return (
      <InitiativeListComponent
        columns={historyInitiativesColumns}
        rows={historyRequests}
        onItemClick={handleItemClick}
        withSearch
        isSubscriptionInitiatives
      />
    );
  };
  const renderDraftRequestsPanel = () => {
    return (
      <DraftInitiativeListComponent
        columns={draftInitiativesColumns}
        rows={draftRequests}
        onItemClick={handleDraftItemClick}
        withSearch
        isSubscriptionInitiatives
      />
    );
  };

  const tabsDefinition = [
    {
      key: TAB.ACTIVE_REQUESTS,
      panel: renderActiveRequestsPanel(),
      title: 'requests_view:tab_section.tabs.active_initiatives.title',
    },
    {
      key: TAB.DRAFT_REQUESTS,
      panel: renderDraftRequestsPanel(),
      title: 'requests_view:tab_section.tabs.draft_initiatives.title',
    },
    {
      key: TAB.REQUESTS_HISTORY,
      panel: renderRequestsHistoryPanel(),
      title: 'requests_view:tab_section.tabs.initiatives_history.title',
    },
  ];

  const filteredTabsDefinition = tabsDefinition.filter(
    (tab) =>
      (tab.key === TAB.ACTIVE_REQUESTS && activeRequests.length) ||
      (tab.key === TAB.REQUESTS_HISTORY && historyRequests.length) ||
      (tab.key === TAB.DRAFT_REQUESTS && draftRequests.length)
  );

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number): void => {
    if (newTabIndex === TabIndex.DRAFT_REQUESTS && !draftRequests.length) {
      push(`/requests?tab=${TAB.REQUESTS_HISTORY}`);
      return;
    }

    switch (newTabIndex) {
      case TabIndex.ACTIVE_REQUESTS:
        push(`/requests?tab=${TAB.ACTIVE_REQUESTS}`);
        break;
      case TabIndex.DRAFT_REQUESTS:
        push(`/requests?tab=${TAB.DRAFT_REQUESTS}`);
        break;
      case TabIndex.REQUESTS_HISTORY:
        push(`/requests?tab=${TAB.REQUESTS_HISTORY}`);
        break;
      default:
        push(`/requests?tab=${TAB.ACTIVE_REQUESTS}`);
        break;
    }
  };

  const getCurrentTabIndex = () => {
    const queryString = new URLSearchParams(window.location.search);
    const tab = queryString.get('tab');

    if (filteredTabsDefinition.length < 2) {
      return TabIndex.ACTIVE_REQUESTS;
    }

    if (tab === String(TAB.REQUESTS_HISTORY) && !draftRequests.length) {
      return 1;
    }

    if (tab === String(TAB.DRAFT_REQUESTS) && draftRequests.length) {
      return TabIndex.DRAFT_REQUESTS;
    }

    return tab === String(TAB.REQUESTS_HISTORY) ? TabIndex.REQUESTS_HISTORY : TabIndex.ACTIVE_REQUESTS;
  };

  const renderRequestButton = (): JSX.Element => {
    const isActiveWorkflowSet = Boolean(workflowsListData?.find(({ state }) => state.toLowerCase() === 'active'));

    return (
      <Tooltip
        title={!isActiveWorkflowSet ? t('requests_view:disabled_new_request_button_tooltip_text') : ''}
        placement='bottom'>
        <Box key='request-button'>
          <Button
            variant='contained'
            startIcon={<Icon>add_task</Icon>}
            onClick={() => onAddRequestDrawer(`#${DRAWER_VARIANT.NEW_REQUEST}`)}
            data-testid='new-request'
            disabled={!isActiveWorkflowSet}>
            {t('requests_view:new_initiative_button_text')}
          </Button>
        </Box>
      </Tooltip>
    );
  };

  const renderEmptyListContent = (): JSX.Element => (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: '3rem auto 0',
        width: '100%',
      }}>
      <Box component='img' src={NoRequestsImage} sx={{ mb: '2rem' }} />
      <Typography variant='h3' sx={{ mb: '0.5rem' }} data-testid='no-request-heading'>
        {t('requests_view:no_requests.title_initiatives')}
      </Typography>
      <Typography variant='body1' align='center' sx={{ mb: '2rem' }} data-testid='no-request-description'>
        {t('requests_view:no_requests.subtitle_initiatives')}
      </Typography>
      {!isWorkflowsListLoading && renderRequestButton()}
    </Box>
  );

  return (
    <Page
      attachHeaderToContent={!isLoading && data && !!data.length}
      header={
        <>
          <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
            <Box maxWidth='75%'>
              <PageTitle
                title={t('requests_view:header_section.page_title_initiatives_text')}
                subtitle={t('requests_view:header_section.page_subtitle_initiatives_text')}
              />
            </Box>
            {!isLoading && data && !!data.length && !isWorkflowsListLoading && renderRequestButton()}
          </Stack>
          {!isLoading && data && !!data.length && (
            <Tabs value={getCurrentTabIndex()} onChange={handleTabChange} sx={{ mt: 4 }}>
              {filteredTabsDefinition.map(({ key, title }) => (
                <Tab key={key} label={<>{t(title)}</>} iconPosition='start' data-testid={`tab-item-${key}`} />
              ))}
            </Tabs>
          )}
        </>
      }>
      {!isLoading && data && !data.length && renderEmptyListContent()}
      {!isLoading &&
        data &&
        !!data.length &&
        filteredTabsDefinition.map(({ key, panel }, index) => (
          <TabPanel key={key} value={getCurrentTabIndex()} index={index}>
            {panel}
          </TabPanel>
        ))}
    </Page>
  );
};

export default RequestsView;
