import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodatIntegrationDetailsRes, CodatPlatformType } from 'libs/models';

type SelectedIntegration = {
  companyName: string;
  companyId: string;
  platformType: CodatPlatformType;
  platformName: string;
};

export interface MultipleIntegrationsItemInterface {
  selectedItem?: SelectedIntegration;
  codatConnections: CodatIntegrationDetailsRes[];
}

export const initialState: MultipleIntegrationsItemInterface = {
  codatConnections: [],
  selectedItem: undefined,
};

const multipleIntegrationsItemSlice = createSlice({
  initialState,
  name: 'multipleIntegrationsItem',
  reducers: {
    clearSelectedIntegration(state) {
      state.selectedItem = undefined;
    },
    setCodatConnectionDetails(state, action: PayloadAction<CodatIntegrationDetailsRes[]>) {
      state.codatConnections = action.payload;
    },
    setSelectedIntegration(state, action: PayloadAction<SelectedIntegration>) {
      state.selectedItem = action.payload;
    },
  },
});

export const { clearSelectedIntegration, setCodatConnectionDetails, setSelectedIntegration } =
  multipleIntegrationsItemSlice.actions;
export default multipleIntegrationsItemSlice.reducer;
