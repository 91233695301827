import { ExportableField } from './models';
import { statusColor } from './theme/colors';

export const teamMemberPermissionsLink =
  'https://support.sastrify.com/support/solutions/articles/101000416323-team-member-permissions';

export enum KeyboardKeys {
  Enter = 'Enter',
  Esc = 'Escape',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}

export enum UserRoles {
  Admin = 'admin',
  Contributor = 'contributor',
  Viewer = 'viewer',
}

export enum ToolOwnershipStep {
  Add = 'Add',
  Change = 'Change',
  Invite = 'Invite',
  Unassign = 'Unassign',
}

export enum MethodTypes {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export type BaseStringToStringMapping = {
  [name: string]: string;
};

export type BaseNumberToStringMapping = {
  [name: number]: string;
};

export type ApiActionsMapping = BaseStringToStringMapping;

export enum SubscriptionStatus {
  'active',
  'in_negotiation',
  'under_review',
  'inactive',
  'in_evaluation',
  'up_for_renewal',
  'under_consideration',
  'in_trial',
  'in_discovery_negotiation',
  'expired',
  'requested',
  'discovered',
  'rejected',
}

export enum Statuses {
  pending = 'pending',
  inProgress = 'inProgress',
  alert = 'alert',
  success = 'success',
  error = 'error',
  inactive = 'inactive',
  active = 'active',
  inEvaluation = 'inEvaluation',
  inNegotiation = 'inNegotiation',
  renewal = 'renewal',
  discovered = 'discovered',
  connected = 'connected',
  connectionExpired = 'connectionExpired',
  expired = 'expired',
}

export enum WorkflowsCategory {
  'null',
  'new_solution',
  'new_subscription',
  'new_solution_to_subscription',
  'subscription_is_up_for_renewal',
}

type WorkflowsCategoryInfoHashes = {
  [key: string]: number;
};

export const WorkflowsCategoryHashes: WorkflowsCategoryInfoHashes = {
  new_solution: WorkflowsCategory.new_solution,
  new_solution_to_subscription: WorkflowsCategory.new_solution_to_subscription,
  new_subscription: WorkflowsCategory.new_subscription,
  subscription_is_up_for_renewal: WorkflowsCategory.subscription_is_up_for_renewal,
};

export enum WorkflowActionCategory {
  'null',
  'create_custom_task',
}

type WorkflowActionCategoryInfoHashes = {
  [key: string]: number;
};

export const WorkflowActionCategoryHashes: WorkflowActionCategoryInfoHashes = {
  create_custom_task: WorkflowActionCategory.create_custom_task,
  null: WorkflowActionCategory.null,
};

export const SubscriptionStates = [
  SubscriptionStatus.active,
  SubscriptionStatus.in_negotiation,
  SubscriptionStatus.under_review,
  SubscriptionStatus.in_evaluation,
  SubscriptionStatus.up_for_renewal,
  SubscriptionStatus.discovered,
];

export const NewSolutionStates = [
  SubscriptionStatus.under_consideration,
  SubscriptionStatus.in_trial,
  SubscriptionStatus.in_discovery_negotiation,
  SubscriptionStatus.requested,
  SubscriptionStatus.rejected,
];

export enum DocumentCategory {
  'miscellaneous',
  'terms_and_conditions',
  'invoice',
  'contract',
  'offer',
  'data_processing_agreement',
  'accounting_data',
  'subscription_list',
  'other_documents',
  'master_services_agreement',
}

export const METHOD_TO_ACTION_MAPPINGS: ApiActionsMapping = {
  delete: 'deleted',
  patch: 'updated',
  post: 'created',
};

export const DEFAULT_COUNTRY = 'en-US';
export const EXPORTABLE_SUBSCRIPTION_FIELDS: ExportableField[] = [
  {
    displayName: 'Id',
    name: 'id',
  },
  {
    displayName: 'Name',
    name: 'vendorName',
  },
  {
    displayName: 'Category',
    name: 'categoryName',
  },
  {
    displayName: 'Tags',
    name: 'tags',
  },
  {
    displayName: 'Renewal Frequency',
    name: 'billingFrequency',
  },
  {
    displayName: 'Renewal Date',
    name: 'renewalDate',
  },
  {
    displayName: 'Sum Cost (this year)',
    name: 'currentYearCostFormatted',
  },
  {
    displayName: 'Sum Cost (last year)',
    name: 'lastYearCostFormatted',
  },
  {
    displayName: 'Budget',
    name: 'formattedBudget',
  },
  {
    displayName: 'Tool Owner',
    name: 'ownerName',
  },
];

export interface UserIdentityTrait {
  companyId: string;
  isSastrifyEmailAddress: boolean;
  name: string;
  email: string;
  companyName: string;
}

export enum trackEventName {
  Login = 'Login',
  TaskCreated = 'Task Created',
  TaskEdited = 'Task Edited',
  TaskCompleted = 'Task Completed',
  TaskDeleted = 'Task Deleted',
  VendorknowledgeAlertClicked = 'Vendor knowledge alert clicked',
  VendorKnowledgeTabClicked = 'Vendor Knowledge Tab Clicked',
  VendorKnowledgeItemClicked = 'Vendor Knowledge Item Clicked',
  VendorKnowledgeModalClosed = 'Vendor Knowledge Modal Closed',
  VendorKnowledgeModalOpened = 'Vendor Knowledge Modal Opened',
  VendorKnowledgeVideoPlayed = 'Vendor Knowledge Video Played',
  CommentThreadExpanded = 'Comment thread expanded',
  CommentThreadCollapsed = 'Comment thread collapsed',
  ReplyEditClicked = 'Reply edit clicked',
  ReplyEditCancelled = 'Reply edit cancel clicked',
  ReplyEditSaved = 'Reply edit save clicked',
  ReplyDeleteClicked = 'Reply delete clicked',
  ReplySaveClicked = 'Reply save clicked',
  trackDiscoverPopularToolsClicked = 'Discover Popular Tools Widget Clicked',
  ToolSummaryVerificationModalOpened = 'Tool Summary Verification Modal Opened',
  ToolSummaryVerificationModalSubmitError = 'Tool Summary Verification Modal Submit Error',
  ToolSummaryVerificationModalDataSaved = 'Tool Summary Verification Modal Data Saved',
  ToolSummaryStartRenewalInitiativeTriggered = 'Tool Summary Start Renewal Initiative Triggered',
  ToolSummaryOpenRenewalInitiativeTriggered = 'Tool Summary Open Renewal Initiative Triggered',
  ToolSummaryUpdateRenewalDateTriggered = 'Tool Summary Update Renewal Date Triggered',
  ToolSummaryMoveToInactiveTriggered = 'Tool Summary Move To Inactive Triggered',
  ToolSummaryMoveToInactiveWhenDueTriggered = 'Tool Summary Move To Inactive When Due Triggered',
  ToolSummaryAutoRenewWhenDueTriggered = 'Tool Summary Auto Renew When Due Triggered',
  WorkflowSastriverseLinkClicked = 'Workflow sastriverse link clicked',
  WorkflowVideoPlayed = 'Workflows instructionalvideo played',
  WorkflowTemplateUsed = 'Workflow Template has been used',
  trackFixFailedInvoiceDuration = 'Fix failed invoice duration',
  trackSubscriptionSpendTabClicked = 'Subscription Spend Tab Clicked',
  SubscriptionDocumentUploaded = 'Subscription document has been uploaded',
  SubscriptionDetailTabClicked = 'Subscription details tab has been clicked',
  SubscriptionDetailSaved = 'Subscription details has been saved',
  ToolStoreToolRequestCreated = 'Tool Store tool request created',
  ToolStoreSearchTriggered = 'Tool Store search triggered',
  ToolStoreRequestToolButtonClicked = 'Tool Store Request Tool button clicked',
  WorkflowRequestCreated = 'Workflow request created',
  WorkflowRequestCompleted = 'Workflow request completed successfully',
  WorkflowRequestCommentAdded = 'Workflow request comment posted',
  WorkflowRequestRejected = 'Workflow request rejected',
  WorkflowActiveEdited = 'Active Workflow edited',
  WorkflowActivated = 'Workflow activated',
  WorkflowActivatedWithDefaultStagesChanged = 'Workflow activated with default stages changed',
  WorkflowRequestSastrifyInvolvementCreated = 'Sastrify involvement created',
  BenchmarkRequest = 'Benchmark request',
  NotificationsInboxPopupOpen = 'Notifications: Inbox popup opened',
  NotificationsClick = 'Notifications: Notification clicked',
  NotificationsMarkRead = 'Notifications: Mark read',
  NotificationsMarkUnread = 'Notifications: Mark unread',
  NotificationsArchive = 'Notifications: Archive',
  NotificationsMarkAllRead = 'Notifications: Mark all read',
  MultitoolInitiativeToolAdded = 'Multitool initiative tool added',
  MultitoolInitiativeToolRemoved = 'Multitool initiative tool removed',
  InitiativesStepSelected = 'Initiatives: Step Selected',
  GlobalProcurementHelpRequestCreated = 'Global procurement help request created',
  OverviewPageInitiativeAction = 'Overview page initiative action',
  RenewalReminderAction = 'Renewal Reminder action',
  SlackInitiativeRedirect = 'Slack Initiative Redirect',
  SlackSubscriptionCommentRedirect = 'Slack Subscription Comment Redirect',
  WorkflowWithCustomFormActivated = 'Workflow with custom form activated',
  ToolUsageAnalyticsViewUsersListClicked = 'Tool Usage Analytics: View users list',
  ToolUsageAnalyticsDownloadUsersListClicked = 'Tool Usage Analytics: Download users list',
  ToolUsageAnalyticsConnectViewed = 'Tool Usage Analytics: Connect Viewed',
  ToolUsageAnalyticsReconnectViewed = 'Tool Usage Analytics: Reconnect Viewed',
  ToolUsageAnalyticsConnect = 'Tool Usage Analytics: Connect',
  ToolUsageAnalyticsDisconnect = 'Tool Usage Analytics: Disconnect',
  UpsellingPrompt = 'Upselling Prompt',
  ToolStackPageViewed = 'Tool stack: Page viewed',
  ToolStackToolEditAction = 'Tool stack: in use tab - update tool',
  ToolStackToolMoveAction = 'Tool stack: in use tab - move tool to discovered',
  ToolStackBulkDeleteAction = 'Tool stack: Bulk delete',
  ToolStackBulkMoveAction = 'Tool stack: Bulk move',
  ToolStackSortAction = 'Tool stack: sorting was changed',
  trackDiscoveredToolsPageViewed = 'Tool stack: Discovered Tools Page Viewed',
  trackDiscoveredToolUpdateAction = 'Tool stack: Discovered Tool Bulk Update was used',
  trackDiscoveredToolsBulkDeleteAction = 'Tool stack: Discovered Tools Bulk Delete action performed',
  trackDiscoveredToolsSortAction = 'Tool stack: Discovered tools sorting was changed',
  ToolStackInUseActionNewSubscription = 'Tool stack: in use tab - new subscription',
  ToolStackInUseActionExport = 'Tool stack: in use tab - export',
  ToolStackInUseActionUpload = 'Tool stack: in use tab - upload',
  ToolStackInUseEditInfo = 'Tool stack: in use tab - edit info',
  ToolStackInUseUpdateRenewal = 'Tool stack: in use tab - update renewal',
  ToolStackInUseUploadDocuments = 'Tool stack: in use tab - upload documents',
  ToolStackInUseBulkActionEditDetails = 'Tool stack: in use tab - bulk edit details',
  ToolStackInUseBulkActionMoveToDiscovered = 'Tool stack: in use tab - bulk move to discovered',
  ToolStackInUseBulkActionDelete = 'Tool stack: in use tab - bulk delete',
  ToolStackDiscoveredBulkActionMoveToInUse = 'Tool stack: discovered tab - bulk move to in use',
  ToolStackDiscoveredBulkActionDelete = 'Tool stack: discovered tab - bulk delete',
  ToolStackDiscoveredManageIntegrations = 'Tool stack: discovered tab - manage integrations',
  ToolStackInUseTabShow = 'Tool stack: in use tab - show',
  ToolStackDiscoveredTabShow = 'Tool stack: discovered tab - show',
  ToolStackInactiveTabShow = 'Tool stack: inactive tab - show',
  ToolStackInUseGlobalSearch = 'Tool stack: in use tab - global search',
  ToolStackDiscoveredGlobalSearch = 'Tool stack: discovered tab - global search',
  ToolStackInactiveGlobalSearch = 'Tool stack: inactive tab - global search',
  ToolStackTableReorderColumns = 'Tool stack: table - reorder columns',
  ToolStackTableToggleColumns = 'Tool stack: table - toggle columns',
  ToolStackTableSort = 'Tool stack: table - sort',
  ToolStackTableFullScreen = 'Tool stack: table - full screen',
  ToolStackTableFilterColumns = 'Tool stack: table - filter columns',
  HelpCenterItemClicked = 'Help center item clicked',
  HelpCenterRequestSubmitted = 'Help center request submitted',
  ProcurementSupportInvolveSastrifyButtonClicked = 'Procurement Support: Involve Sastrify button clicked',
  ProcurementSupportTicketSubmitted = 'Procurement Support: Ticket submitted',
  ProcurementSupportFileUploaded = 'Procurement Support: File uploaded',
  ProcurementSupportTicketCommentAdded = 'Procurement Support: New comment added',
  ProcurementSupportListSearch = 'Procurement Support: List search',
  ProcurementSupportDetailsViewTabChanged = 'Procurement Support: Details view tab changed',
  ProcurementSupportTicketDuplicationAttempted = 'Procurement Support: Attempt to create an already existing ticket',
  ProcurementSupportPageViewed = 'Procurement Support: Page viewed',
  ProcurementSupportDetailsPageViewed = 'Procurement Support: Details page viewed',
}

export const featureFlags = {
  CHATBOT: 'chatbot_integration',
  COMPANY_TOOLS_PAGE_V2: 'new_company_tools_enabled',
  DETAILS_ENABLED: 'subscription_details_enabled',
  DISABLE_CONTRACT_MULTIPLE_YEARS_VIEW: 'disable_contract_multiple_years_view',
  DISABLE_SPEND_REVIEW: 'disable_spend_review',
  ENABLE_ASTEROIDS_NAVIGATION: 'enable_asteroids_navigation',
  ENABLE_CONTRACT_PARSING: 'contract_parsing_enabled',
  ENABLE_INSIGHTS_MODAL: 'enable_insight_modal',
  ENABLE_INSIGHT_SEEN: 'enable_insights_seen',
  ENABLE_LINK_TO_OLD_TOOL_DETAILS: 'enable_link_to_old_tool_details',
  ENABLE_RENEWAL_CANCELLATION_ALERTS: 'enable_renewal_cancellation_alerts',
  ENABLE_SAVING_INSIGHT: 'enable_savings_insight',
  ENABLE_SUPPORT_INSIGHT_ALERT: 'enable_support_insight_alert',
  ENABLE_TERMINATING_STATUS: 'enable_terminating_status',
  ENABLE_TOOL_DETAILS: 'enable_tool_details',
  ENABLE_TOOL_DETAILS_REDIRECT: 'enable_tool_details_redirect',
  ENABLE_USERS_VIEW_V2: 'enable_users_view_v2',
  ENABLE_VENDOR_INSIGHTS_CATEGORY: 'enable_vendor_insight_category',
  ENFORCE_SSO_LOGIN: 'enforce_sso_login_fe',
  HR_INTEGRATIONS: 'hr_integrations',
  IN_APP_BENCHMARKING: 'in_app_benchmarking',
  IN_APP_NOTIFICATIONS_PREFERENCES_IN_APP_OPTION: 'in_app_notifications_preferences_in_app_option',
  MANUAL_GROUP_ASSIGNMENT_PAT_1953: 'manual_group_assignment_pat_1953',
  MOVE_NOTE_TO_SUBSCRIPTION_OVERVIEW: 'move_note_to_subscription_overview',
  NEW_INDICATOR_VENDOR_INSIGHTS: 'new_indicator_vendor_insights',
  PROCUREMENT_SUPPORT: 'procurement_support',
  PROCUREMENT_SUPPORT_EXCLUDED: 'procurement_support_excluded',
  SLACK_AUTHENTICATION: 'slack_authentication',
  SLACK_NOTIFICATIONS_PREFERENCES_SLACK_OPTION: 'slack_notifications_preferences_slack_option',
  SLACK_RENEWAL_REMINDER_ENABLE_CHANNEL_PREFERENCES: 'slack_renewal_reminder_enable_channel_preferences',
  TOOL_MATCHING: 'tool_matching',
  USER_AND_COMPANY_DATA: 'user_and_company_data',
};

export enum googleTagManagerEvents {
  CompanyDocumentsUploaded = 'company_documents_uploaded',
  CustomTaskModalOpened = 'custom_task_modal_opened',
  DocumentViewed = 'document_viewed',
  ForwardYourDocumentsLinkClicked = 'forward_your_documents_link_clicked',
  ForwardYourInvoicesLinkClicked = 'forward_your_invoices_link_clicked',
  InviteSent = 'invite_sent',
  NewSolutionCreated = 'new_solution_created',
  OnboardingQuestionnaireLinkClicked = 'onboarding_questionnaire_link_clicked',
  RenewalDateRemoved = 'renewal_date_removed',
  RenewalDateUpdated = 'renewal_date_updated',
  RenewalQuestionnaireLinkClicked = 'renewal_questionnaire_link_clicked',
  RequestDrawerOpened = 'request_drawer_opened',
  SubscriptionCreated = 'subscription_created',
  SubscriptionDocumentDrawerOpened = 'subscription_document_drawer_opened',
  SubscriptionDocumentsUploaded = 'subscription_documents_uploaded',
  ToolOwnerAssigned = 'tool_owner_assigned',
  ToolOwnerNameAssigned = 'tool_owner_name_assigned',
  WorkflowCreateButtonClicked = 'workflow_create_button_clicked',
  SubmitSastricloudOptimizeRequest = 'submit_sastricloud_optimize_request',
  SubmitSupportOrProcurementRequest = 'submit_support_or_procurement_request',
  SubmitNewToolRequest = 'submit_new_tool_request',
  VendorInsightsModalClosed = 'vendor_insights_modal_closed',
}

export const SUBSCRIPTION_DOCUMENT_REGEX_PATTERN = /subscriptionDocument:[0-9]*/gim;

export const SUBSCRIPTION_USERNAME_REGEX_PATTERN = /\[@(.*?[aA-zZ ].*?)\]\(.*?userName:[0-9].*?\)/gm;

export const COMMENT_MENTION_REGEX_PATTERN =
  /@[0-9a-zA-ZŠŒŽšœžŸ¥µÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝĄĘŁŻŹÓŃßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿąęłżźóń -]+/gm;

export const COMMENT_DOCUMENT_REGEX_PATTERN = /!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)/g;

export type GroupedSpendAndInvoicesByMonth = {
  [key: string]: {
    sum: number;
    sortByKey: number;
    [key: string]: number;
  };
};

export enum TodoCategories {
  RENEWAL_DATE = 1,
  UPLOAD_INVOICE = 7,
  SAVINGS_SUGGESTION = 8,
  CUSTOM_TODO = 9,
  ASSIGN_TOOL_OWNER = 11,
  ONBOARDING_QUESTIONNAIRE = 10,
  RENEWAL_QUESTIONNAIRE = 12,
  USER_CUSTOM_TODO = 13,
  AUTO_EXTENDED_RENEWAL_DATE = 14,
}

export enum TodoStates {
  OPEN = 0,
  IN_PROGRESS = 1,
  DONE = 2,
}

export enum OpenTodoFilter {
  ALL = 'all',
  RENEWAL_DATE = 'renewalDate',
  INVOICE_UPLOAD = 'invoiceUpload',
  SAVINGS_SUGGESTION = 'savingsSuggestion',
}

export type OpenTodoFilterArray = (
  | OpenTodoFilter.ALL
  | OpenTodoFilter.RENEWAL_DATE
  | OpenTodoFilter.INVOICE_UPLOAD
  | OpenTodoFilter.SAVINGS_SUGGESTION
)[];

export const OpenTodoCategoryToFilter: Omit<Record<number, OpenTodoFilterArray[number]>, OpenTodoFilter.ALL> = {
  [TodoCategories.RENEWAL_DATE]: OpenTodoFilter.RENEWAL_DATE,
  [TodoCategories.UPLOAD_INVOICE]: OpenTodoFilter.INVOICE_UPLOAD,
  [TodoCategories.SAVINGS_SUGGESTION]: OpenTodoFilter.SAVINGS_SUGGESTION,
} as const;

export enum ToolOwnerFilter {
  NOT_ASSIGNED = 'notAssigned',
  WITHOUT_INVITATION = 'withoutInvitation',
}

export enum RenewalFrequencyFilter {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  CUSTOM = 'custom',
  OTHER = 'other',
}

export type MissingDocument = [
  'contract',
  'dataProcessingAgreement',
  'invoice',
  'masterServicesAgreement',
  'offer',
  'termsAndConditions'
];

export enum CommentsFilter {
  NO_COMMENTS = 'noComments',
  ALL_COMMENTS = 'withComments',
}

export enum ToolAccessLevelFilter {
  ONLY_TOOLS_WITH_ACCESS = 'onlyToolsWithAccess',
}

export const billingFrequencyOptions = ['MONTHLY', 'YEARLY', 'CUSTOM', 'OTHER'];

export type BillingFrequencyMappingType = {
  [key: number]: RenewalFrequencyFilter;
};

export const billingFrequencyMapping: BillingFrequencyMappingType = {
  0: RenewalFrequencyFilter.MONTHLY,
  1: RenewalFrequencyFilter.YEARLY,
  2: RenewalFrequencyFilter.CUSTOM,
  3: RenewalFrequencyFilter.OTHER,
};

export enum AuditLogFieldNames {
  AUTO_RENEWAL = 'autoRenewal',
  BILLING_FREQUENCY = 'billingFrequency',
  BUDGET_CENTS = 'budgetCents',
  CANCELLATION_PERIOD = 'cancellationPeriod',
  DECISION_DATE = 'decisionDate',
  DOCUMENT = 'document',
  OWNER = 'owner',
  NAME = 'name',
  NOTE = 'note',
  TAGS = 'tags',
  RENEWAL_DATE = 'renewalDate',
  SUBSCRIPTION = 'subscription',
  STATE = 'state',
  COMMENT = 'comment',
  LINKED_SOFTWARE = 'subscriptionLinks',
  SUBSCRIPTION_TODOS = 'SubscriptionTodos',
}

export enum AuditLogActions {
  ADDED = 'Added',
  CHANGED = 'Changed',
  REMOVED = 'Removed',
}

export enum ActionOptions {
  UPDATE = 'update',
  REMOVE = 'remove',
}

export enum SubscriptionActions {
  SubscriptionCreated = 'Subscription created.',
}

export enum TableColumnNames {
  AMOUNT = 'amountFormatted',
  DATE = 'date',
  NAME = 'name',
  UPLOAD_DATE = 'uploadDate',
  COMPANY_AMOUNT = 'companyAmountCents',
  SPEND_SOURCE = 'spendSource',
  TYPE = 'type',
}

export enum TagsActions {
  TagAdded = 'Tag Added',
  TagRemoved = 'Tag Removed',
}

export enum DocumentType {
  DOCUMENT = 'document',
  INVOICE = 'invoice',
}

export const DEFAULT_SUPPORT_AGENT = {
  avatar: 'https://avatars.slack-edge.com/2021-02-10/1759163403056_d238d8ffb470350fc4e9_72.png',
  name: 'Sastrify Team',
  team: 'Procurement/IT buying team',
};

export const subscriptionStatus = [
  {
    color: statusColor.active.color,
    name: 'Active',
    value: SubscriptionStatus.active,
  },
  {
    color: statusColor.upForRenewal.color,
    name: 'Up for Renewal',
    value: SubscriptionStatus.up_for_renewal,
  },
  {
    color: statusColor.inEvaluation.color,
    name: 'In Evaluation',
    value: SubscriptionStatus.in_evaluation,
  },
  {
    color: statusColor.inNegotiation.color,
    name: 'In Negotiation',
    value: SubscriptionStatus.in_negotiation,
  },
  {
    color: statusColor.inActive.color,
    name: 'Inactive',
    value: SubscriptionStatus.inactive,
  },
  {
    color: statusColor.expired.color,
    name: 'Expired',
    value: SubscriptionStatus.expired,
  },
  {
    color: statusColor.discovered.color,
    name: 'Discovered',
    value: SubscriptionStatus.discovered,
  },
];

export const subscriptionStatusNew = [
  {
    color: statusColor.active.color,
    name: 'Active',
    value: SubscriptionStatus.active,
  },
  {
    color: statusColor.inEvaluation.color,
    name: 'In Evaluation',
    value: SubscriptionStatus.in_evaluation,
  },
  {
    color: statusColor.inNegotiation.color,
    name: 'In Negotiation',
    value: SubscriptionStatus.in_negotiation,
  },
  {
    color: statusColor.upForRenewal.color,
    name: 'Up for Renewal',
    value: SubscriptionStatus.up_for_renewal,
  },
  {
    color: statusColor.expired.color,
    name: 'Expired',
    value: SubscriptionStatus.expired,
  },
];

export const subscriptionInDiscoveryStatus = [
  {
    color: statusColor.underConsideration.color,
    name: 'Under Consideration',
    value: SubscriptionStatus.under_consideration,
  },
  {
    color: statusColor.inTrial.color,
    name: 'In Trial',
    value: SubscriptionStatus.in_trial,
  },
  {
    color: statusColor.inDiscoveryNegotiation.color,
    name: 'New Negotiation',
    value: SubscriptionStatus.in_discovery_negotiation,
  },
  {
    color: statusColor.requested.color,
    name: 'Requested',
    value: SubscriptionStatus.requested,
  },
  {
    color: statusColor.rejected.color,
    name: 'Rejected',
    value: SubscriptionStatus.rejected,
  },
];

export const subscriptionStatusToValidNameMapper: Record<string, { label: string; key: SubscriptionStatus }> = {
  active: {
    key: SubscriptionStatus.active,
    label: 'Active',
  },
  discovered: { key: SubscriptionStatus.discovered, label: 'Discovered' },
  expired: { key: SubscriptionStatus.expired, label: 'Expired' },
  inEvaluation: { key: SubscriptionStatus.in_evaluation, label: 'In Evaluation' },
  inNegotiation: {
    key: SubscriptionStatus.in_negotiation,
    label: 'In Negotiation',
  },
  upForRenewal: { key: SubscriptionStatus.up_for_renewal, label: 'Up for Renewal' },
};

export enum VENDOR_RESELLING_STATUS {
  NO_RESELLER = 0,
  WE_KNOW_A_RESELLER = 1,
  WE_ARE_A_RESELLER = 2,
}

export enum DrawerInfoType {
  SUBSCRIPTION_DOCUMENTS = 'subscriptionDocuments',
  COMPANY_DOCUMENTS = 'companyDocuments',
  REQUEST_HELP = 'requestHelp',
  USER_INVITE = 'userInvite',
  GLOBAL_REQUEST_HELP = 'globalRequest',
  USER_INVITE_SUBSCRIPTIONS = 'userInviteSubscriptions',
  USER_INVITE_SUBSCRIPTION = 'userInviteSubscription',
  EDIT_USER = 'editUser',
  NEW_SUBSCRIPTION = 'newSubscription',
  NEW_SOLUTION = 'newSolution',
  NEW_WORKFLOW = 'newWorkflow',
  PROCUREMENT_HELP = 'procurementHelp',
  SASTRIFY_APP_HELP = 'sastrifyAppHelp',
  REQUEST_SUPPORT = 'requestSupport',
  TOOL_REQUEST = 'toolRequest',
  WORKFLOW_REQUEST = 'workflowRequest',
  WORKFLOW_REQUEST_EDIT = 'workflowRequestEdit',
  USAGE_ANALYTICS_DIRECT = 'usageAnalyticsDirect',
  USAGE_ANALYTICS_SSO = 'usageAnalyticsSso',
  USAGE_ANALYTICS_MANAGE_CONNECTION = 'usageAnalyticsManageConnection',
  REQUEST_DETAILS = 'requestDetails',
  NEW_REQUEST_WORKFLOW = 'newRequestWorkflow',
  NEW_REQUEST_WORKFLOW_EDIT = 'newRequestWorkflowEdit',
  NEW_REQUEST_WORKFLOW_DETAILS = 'newRequestWorkflowDetails',
  MANAGE_CODAT_INTEGRATIONS = 'manageCodatIntegrations',
  SASTRICLOUD_OPTIMIZE = 'sastricloudOptimize',
  MANAGE_SSO_INTEGRATIONS = 'manageSSOIntegrations',
}

export const toolStoreBundleDrawerTypes = [
  DrawerInfoType.TOOL_REQUEST,
  DrawerInfoType.WORKFLOW_REQUEST,
  DrawerInfoType.WORKFLOW_REQUEST_EDIT,
  DrawerInfoType.REQUEST_DETAILS,
  DrawerInfoType.NEW_REQUEST_WORKFLOW,
  DrawerInfoType.NEW_REQUEST_WORKFLOW_EDIT,
  DrawerInfoType.NEW_REQUEST_WORKFLOW_DETAILS,
  DrawerInfoType.NEW_WORKFLOW,
];

type DrawerInfoHashesType = {
  [key: string]: string;
};

export const DrawerInfoHashes: DrawerInfoHashesType = {
  null: '',
  [DrawerInfoType.SUBSCRIPTION_DOCUMENTS]: '',
  [DrawerInfoType.COMPANY_DOCUMENTS]: '',
  [DrawerInfoType.REQUEST_HELP]: '',
  [DrawerInfoType.USER_INVITE]: '',
  [DrawerInfoType.USER_INVITE_SUBSCRIPTIONS]: '',
  [DrawerInfoType.USER_INVITE_SUBSCRIPTION]: '',
  [DrawerInfoType.NEW_SUBSCRIPTION]: '#addNewSubscription',
  [DrawerInfoType.NEW_SOLUTION]: '#addNewSolution',
  [DrawerInfoType.NEW_WORKFLOW]: '#addNewWorkflow',
  [DrawerInfoType.SASTRIFY_APP_HELP]: '#sastrifyAppHelp',
  [DrawerInfoType.TOOL_REQUEST]: '#newToolRequest',
  [DrawerInfoType.WORKFLOW_REQUEST]: '#newRequest',
  [DrawerInfoType.WORKFLOW_REQUEST_EDIT]: '#editRequest',
  [DrawerInfoType.REQUEST_DETAILS]: '#requestDetails',
  [DrawerInfoType.NEW_REQUEST_WORKFLOW]: '#newRequestWorkflow',
  [DrawerInfoType.NEW_REQUEST_WORKFLOW_EDIT]: '#newRequestWorkflowEdit',
  [DrawerInfoType.NEW_REQUEST_WORKFLOW_DETAILS]: '#newRequestWorkflowDetails',
};

export type TableDateSortType = { direction: 'asc' | 'desc'; name: string };

export type ExtendedBlobType = Blob & { name?: string; lastModified?: number; lastModifiedDate?: Date };

export type UserMention = {
  id: string;
  value: string;
};

export type CommentDocument = {
  id: string;
  url: string;
};

export const priorityCurrencies = ['EUR', 'USD', 'GBP', 'CHF'];

export type Currency = {
  AED: 'AED';
  AUD: 'A$';
  SGD: 'S$';
  CHF: 'CHF';
  EUR: '€';
  USD: '$';
  GBP: '£';
  SEK: 'kr';
  ARS: 'ARS';
  MXN: 'MXN';
};

export const CurrencyOptions: Currency = {
  AED: 'AED',
  ARS: 'ARS',
  AUD: 'A$',
  CHF: 'CHF',
  EUR: '€',
  GBP: '£',
  MXN: 'MXN',
  SEK: 'kr',
  SGD: 'S$',
  USD: '$',
};

export enum ModalType {
  GlobalRequestHelp = 'GlobalRequestHelp',
  NewSubscription = 'NewSubscription',
  RequestHelp = 'RequestHelp',
  AppHelp = 'AppHelp',
}

export const INVALID_DATE_TEXT = 'Invalid Date';
export const INACTIVE_STATE = 'INACTIVE';
export const MY_SUBSCRIPTIONS_STATE = 'mySubscriptions';
export const MY_SUBSCRIPTIONS_TEXT = 'My_Subscriptions';
export const SUBSCRIPTIONS_TEXT = 'Subscriptions';
export const MY_TOOLS_TEXT = 'My_Tools';
export const MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY = 'CSV file generated successfully.';
export const INTEGRATIONS_IMAGES_PATH = 'https://s3.eu-central-1.amazonaws.com/static.sastrix.io/images/integrations/';
export const uploadInvoiceUrlPath = '?document_upload=open&category=invoice';
export const TERMS_AND_CONDITION_LINK = 'https://www.sastrify.com/terms-conditions';
export const PRIVACY_POLICY_LINK = 'https://www.sastrify.com/privacy-policy';

const date = new Date();
const currentDayOfMonth = date.getDate();
const currentMonth = date.getMonth(); // Be careful! January is 0, not 1
const currentYear = date.getFullYear();

export const currentDate = new Date(currentYear, currentMonth, currentDayOfMonth);

export const RESPONSE_CODE = {
  BadRequest: { code: 400, message: 'Bad Request' },
  Conflict: { code: 409, message: 'Conflict' },
  Forbidden: { code: 403, message: 'Forbidden' },
  InternalServerError: { code: 500, message: 'Internal Server Error' },
  NotFound: { code: 404, message: 'Not Found' },
  OK: { code: 200, message: 'OK' },
  Unauthorized: { code: 401, message: 'Unauthorized' },
};

export enum ReplyableType {
  SubscriptionComments = 'subscription_comment',
}

export const FileTypesAllowList = ['.png', '.csv', '.pdf', '.jpeg', '.jpg', '.xls', '.xlsx', '.doc', '.docx'];

export const FileMimeTypesAllowList = [
  'image/png',
  'text/csv',
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
];

enum DEVELOPMENT {
  FIND_ALTERNATIVE = '10',
}

enum STAGING {
  FIND_ALTERNATIVE = '6',
}

enum PRODUCTION {
  FIND_ALTERNATIVE = '6',
}

export enum CARD_FOOTER_TYPE {
  CONNECT = 'connect',
  SETUP_GUIDE = 'setup_guide',
  COMING_SOON = 'coming_soon',
  ERROR = 'error',
}
export type FOOTER_TYPE = 'connect' | 'setup_guide' | 'coming_soon' | 'error';

export const TemplateIds: TemplateIdsType = {
  DEVELOPMENT,
  PRODUCTION,
  STAGING,
};

export type TemplateIdsType = {
  DEVELOPMENT: typeof DEVELOPMENT;
  STAGING: typeof STAGING;
  PRODUCTION: typeof PRODUCTION;
};

export const LOGIN_METHOD_ERROR_MESSAGE = 'Login method does not match that of the company';

export enum COMMENT_TEMPLATE_SCOPES {
  PROCUREMENT_HELP = 'PROCUREMENT_HELP',
  APP_HELP = 'APP_HELP',
}

export enum TOOLS_TYPE_FILTER {
  COMPANY_TOOLS = 'COMPANY_TOOLS',
  ALL_TOOLS = 'ALL_TOOLS',
  SASTRIFY_MARKETPLACE_TOOLS = 'SASTRIFY_MARKETPLACE_TOOLS',
}

export enum UPDATE_REQUEST_TYPE {
  APPROVE = 'approve',
  REJECT = 'reject',
}

export enum DRAWER_VARIANT {
  NEW_REQUEST = 'newRequest',
  EDIT_REQUEST = 'editRequest',
  REQUEST_DETAILS = 'requestDetails',
  NEW_REQUEST_WORKFLOW = 'newRequestWorkflow',
  NEW_REQUEST_WORKFLOW_EDIT = 'newRequestWorkflowEdit',
  NEW_REQUEST_WORKFLOW_DETAILS = 'newRequestWorkflowDetails',
}

export enum REQUEST_STATUS {
  ACTIVE = '1',
  APPROVED = '4',
  REJECTED = '5',
  SUBSCRIPTION_CREATED = '6',
}

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export const E2E_USERS = [
  'devinbox+e2e@sastrify.com',
  'devinbox+e2e2_contributor@sastrify.com',
  'devinbox+e2e_viewer@sastrify.com',
];

export const RENEWAL_INITIATIVE_EVENT_SOURCE = 'renewal_initiative_event_source';

export const CSM_USER_EMAIL = 'operations+';
