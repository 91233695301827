import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DropzoneAreaProps } from './dropzone.component.props';

export const DropzoneWrapper = styled(Box)(() => ({
  position: 'relative',
}));

export const DropzoneArea = styled(Box)<DropzoneAreaProps>(({ hover, theme }) => ({
  alignItems: 'center',
  border: `1px dashed ${theme.palette.secondary.dark}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  zIndex: 0,
  ...(hover === 'true' && { backgroundColor: theme.palette.primary.highlight }),
  ...(hover === 'false' && { backgroundColor: theme.palette.background.paper }),
  ...(hover === 'true' && { borderColor: theme.palette.primary.main }),
}));

export const LinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline',
  textDecoration: 'underline',
}));

export const FileInput = styled('input')(() => ({
  cursor: 'pointer',
  height: '100%',
  left: 0,
  opacity: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1,
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  marginTop: theme.spacing(1),
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  padding: `0 ${theme.spacing(2)}`,
  color: theme.palette.text.secondary,
}));
