import { Box, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { useTranslation } from 'react-i18next';

type DrawerHeaderProps = {
  isConnected: boolean;
  ssoProviderName: string;
};

export const DrawerHeader = ({ isConnected, ssoProviderName }: DrawerHeaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3 }} data-testid='drawer-title'>
        {isConnected ? (
          <>
            <Icon color='primary' sx={{ mr: 1 }}>
              check_circle
            </Icon>
            <Typography variant='h2' color='primary'>
              {t('manage_sso_integrations_view:connected_header', {
                ssoProviderName,
              })}
            </Typography>
          </>
        ) : (
          <Typography variant='h2' color='text.primary' sx={{ marginBottom: 1 }}>
            {t('manage_sso_integrations_view:connect_header', {
              ssoProviderName,
            })}
          </Typography>
        )}
      </Box>

      <Typography variant='body1' color='text.primary' sx={{ mb: 3 }} data-testid='drawer-body'>
        {isConnected
          ? t('manage_sso_integrations_view:connected_paragraph', {
              ssoProviderName,
            })
          : t('manage_sso_integrations_view:connect_paragraph', {
              ssoProviderName,
            })}
      </Typography>
    </>
  );
};
