import { useFlag } from '@unleash/proxy-client-react';
import { useTranslation } from 'react-i18next';
import { featureFlags } from 'shared/common.definitions';

import { MarkdownReaderComponent } from '../../markdown-reader';
import { WysiwygEditorComponent } from '../../wysiwyg-editor';

interface DetailsNoteFieldProps {
  editModeEnabled: boolean;
  subscriptionId: string;
  onChangeNote(value: string): void;
  note: string;
}

export const DetailsNoteFieldComponent: React.FC<DetailsNoteFieldProps> = ({
  editModeEnabled,
  note,
  onChangeNote,
  subscriptionId,
}) => {
  const { t } = useTranslation();

  const shouldMoveNoteToOverview = useFlag(featureFlags.MOVE_NOTE_TO_SUBSCRIPTION_OVERVIEW);
  const noNote = `_${t('subscription_detail_view:subscription_detail_sidebar_section.notes_section.empty')}_`;

  if (!shouldMoveNoteToOverview) {
    return null;
  }

  if (!editModeEnabled) {
    return (
      <div className='note-editor-container' data-testid='subscription-overview-note'>
        <div className='note-editor-container__label note-editor-container__label--disabled'>
          {t('subscription_detail_view:details_view.fields.note.label')}
        </div>
        <div className='note-editor-container__preview-box'>
          <MarkdownReaderComponent className={!note ? 'no-value' : ''} text={note || noNote} />
        </div>
      </div>
    );
  }

  return (
    <div className='note-editor-container' data-testid='subscription-overview-note'>
      <div className='note-editor-container__label'>{t('subscription_detail_view:details_view.fields.note.label')}</div>
      <WysiwygEditorComponent
        value={note}
        subscriptionId={subscriptionId}
        placeholder={t('subscription_detail_view:details_view.fields.note.placeholder')}
        onChange={onChangeNote}
        setIsUploadingDocument={() => null}
        bottomSectionStyles={{ bottom: '-2.5rem' }}
        hideImageUploadButton
      />
    </div>
  );
};
