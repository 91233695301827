import { AutocompleteOption } from 'asteroids';
import { createContext, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestDetails, RequestPayload, WorkflowListItem } from 'shared/models';
import { RequestDraft } from 'shared/models/request-draft.model';

interface IStepper {
  isVisible: boolean;
  activeStep: number;
  steps: { completed: boolean; label: string }[];
}

interface IWorkflowInitiativeWizardContext {
  stepper: IStepper;
  setActiveStep?: (step: number) => void;
  completePreviousStep?: () => void;
  goOneStepBackAndMarkStepAsUncompleted?: () => void;
  setIsStepperVisible?: (isVisible: boolean) => void;
  selectedInitiativeType?: AutocompleteOption | null;
  setSelectedInitiativeType?: (initiativeType: AutocompleteOption | null) => void;
  workflow?: WorkflowListItem | null;
  setWorkflow?: (workflow: WorkflowListItem) => void;
  initiativeName?: string;
  setInitiativeName?: (name: string) => void;
  initiativeNameError?: boolean;
  setInitiativeNameError?: (value: boolean) => void;
  defaultForm?: any | null;
  setDefaultFormData?: (formValues: RequestPayload | null) => void;
  fetchedData?: RequestDetails | null;
  setApiData?: (data: RequestDetails | null) => void;
  customForm?: any | null;
  setCustomForm?: (formValues: any) => void;
  customFormError?: boolean;
  setCustomFormError?: (isValid: boolean) => void;
  isToolBusy?: boolean;
  setIsToolBusy?: (busy: boolean) => void;
  setDraftData?: (draft: RequestDraft) => void;
}

interface IWorkflowInitiativeWizardContextProvider {
  children: React.ReactNode;
}

export const WorkflowInitiativeWizardContext = createContext<IWorkflowInitiativeWizardContext>({
  defaultForm: null,
  stepper: { activeStep: 0, isVisible: true, steps: [] },
});

export const WorkflowInitiativeWizardContextProvider = ({ children }: IWorkflowInitiativeWizardContextProvider) => {
  const { t } = useTranslation();

  const [workflow, setWorkflow] = useState<WorkflowListItem | null>(null);
  const [stepper, setStepper] = useState<IStepper>({
    activeStep: 0,
    isVisible: false,
    steps: [
      {
        completed: false,
        label: t('requests_view:modify_initiative_drawer.wizard.stepper.step_one'),
      },
      {
        completed: false,
        label: t('requests_view:modify_initiative_drawer.wizard.stepper.step_two'),
      },
    ],
  });
  const [initiativeName, setInitiativeName] = useState<string>('');
  const [initiativeNameError, setInitiativeNameError] = useState<boolean>(false);
  const [selectedInitiativeType, setSelectedInitiativeType] = useState<AutocompleteOption | null>(null);
  const [defaultForm, setDefaultForm] = useState<RequestPayload | null>(null);
  const [fetchedData, setFetchedData] = useState<RequestDetails | null>(null);
  const [customForm, setCustomForm] = useState<any>(null);
  const [customFormError, setCustomFormError] = useState<boolean>(false);
  const [isToolBusy, setIsToolBusy] = useState<boolean>(false);

  const setActiveStep = useCallback((step: number) => {
    setStepper((prevState) => ({
      ...prevState,
      activeStep: step,
    }));
  }, []);

  const completePreviousStep = useCallback(() => {
    setStepper((prevState) => ({
      ...prevState,
      steps: prevState.steps.map((step, index) => {
        if (index === prevState.activeStep - 1) {
          return {
            ...step,
            completed: true,
          };
        }
        return step;
      }),
    }));
  }, []);

  const goOneStepBackAndMarkStepAsUncompleted = useCallback(() => {
    setStepper((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
      steps: prevState.steps.map((step, index) => {
        if (index === prevState.activeStep - 1) {
          return {
            ...step,
            completed: false,
          };
        }
        return step;
      }),
    }));
  }, []);

  const setIsStepperVisible = useCallback((isVisible: boolean) => {
    setStepper((prevState) => ({
      ...prevState,
      isVisible,
    }));
  }, []);

  const setDefaultFormData = useCallback((values: RequestPayload | null) => {
    setDefaultForm((prevState) => {
      if (values) return { ...prevState, ...values };
      return null;
    });
  }, []);

  const setApiData = useCallback((data: RequestDetails | null) => {
    setFetchedData((prevState) => {
      if (data) return { ...prevState, ...data };
      return null;
    });
  }, []);

  const setDraftData = useCallback(
    (draft: RequestDraft) => {
      setCustomForm?.(draft.requestData?.customForm);
      setDefaultFormData?.(draft.requestData?.defaultForm);
      setInitiativeName?.(draft.requestData?.initiativeName);
      setSelectedInitiativeType?.((prevState) => ({
        ...prevState,
        ...draft.requestData?.selectedInitiativeType,
      }));
      setIsStepperVisible?.(!!draft.requestData?.workflow?.customFormDefinition);
      setWorkflow?.(draft.requestData?.workflow);
    },
    [setDefaultFormData, setIsStepperVisible]
  );

  const providerValue = useMemo(
    () => ({
      completePreviousStep,
      customForm,
      customFormError,
      defaultForm,
      fetchedData,
      goOneStepBackAndMarkStepAsUncompleted,
      initiativeName,
      initiativeNameError,
      isToolBusy,
      selectedInitiativeType,
      setActiveStep,
      setApiData,
      setCustomForm,
      setCustomFormError,
      setDefaultFormData,
      setDraftData,
      setInitiativeName,
      setInitiativeNameError,
      setIsStepperVisible,
      setIsToolBusy,
      setSelectedInitiativeType,
      setWorkflow,
      stepper,
      workflow,
    }),
    [
      completePreviousStep,
      goOneStepBackAndMarkStepAsUncompleted,
      setActiveStep,
      stepper,
      setIsStepperVisible,
      selectedInitiativeType,
      setSelectedInitiativeType,
      workflow,
      setWorkflow,
      initiativeName,
      setInitiativeName,
      initiativeNameError,
      setInitiativeNameError,
      defaultForm,
      setDefaultFormData,
      fetchedData,
      setApiData,
      customForm,
      setCustomForm,
      customFormError,
      setCustomFormError,
      isToolBusy,
      setIsToolBusy,
      setDraftData,
    ]
  );

  return (
    <WorkflowInitiativeWizardContext.Provider value={providerValue}>
      {children}
    </WorkflowInitiativeWizardContext.Provider>
  );
};
