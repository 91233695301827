import { Box, type BoxProps as MuiBoxProps, styled } from '@mui/material';

export const StyledNavigationContainer = styled(Box)<MuiBoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowX: 'hidden',
  overflowY: 'auto',
  width: theme.spacing(32),
  minWidth: theme.spacing(32),
  maxWidth: theme.spacing(32),
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingRight: 0,
  paddingBottom: theme.spacing(1),
  paddingLeft: 0,
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.light,
}));
