import { Box, styled } from '@mui/material';

export const EmailBridgeComponentWrapper = styled(Box)(({ theme }) => ({
  '& .upload-documents-section': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  '& .forward-documents-button': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    maxWidth: theme.spacing(23),
  },
  '& .forward-documents-text': {
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
  },

  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));
