import { EventType, IInboxMessagePreview } from '@trycourier/react-provider';
import { trackEventName } from 'shared/common.definitions';
import { EventTracking } from 'shared/services';

type Data = {
  notificationTitle?: string;
  notificationLink?: string;
};

type CourierEvent = { messageId?: string; message?: IInboxMessagePreview; event: EventType };

export function trackNotificationsInboxPopupOpen() {
  EventTracking.trackEvent<Data>(trackEventName.NotificationsInboxPopupOpen);
}

export function trackNotificationsClick(data: Data) {
  EventTracking.trackEvent<Data>(trackEventName.NotificationsClick, data);
}

export function trackNotificationsReadActionClick(data: Data) {
  EventTracking.trackEvent<Data>(trackEventName.NotificationsMarkRead, data);
}

export const notificationsEventsTrackMap = {
  archive: (e: CourierEvent) =>
    EventTracking.trackEvent<Data>(trackEventName.NotificationsArchive, {
      notificationTitle: e.message?.title,
    }),
  'mark-all-read': (e: CourierEvent) =>
    EventTracking.trackEvent<Data>(trackEventName.NotificationsMarkAllRead, {
      notificationTitle: e.message?.title,
    }),
  unread: (e: CourierEvent) =>
    EventTracking.trackEvent<Data>(trackEventName.NotificationsMarkUnread, {
      notificationTitle: e.message?.title,
    }),
};
