import { styled } from '@mui/system';
import { boxShadows, colors } from 'shared/theme';

const PREFIX = 'SubscriptionStatusFeature';

export const classes = {
  comment: `${PREFIX}-comment`,
  popper: `${PREFIX}-popper`,
  statusButton: `${PREFIX}-statusButton`,
  statusDropdown: `${PREFIX}-statusDropdown`,
};

export const Root = styled('div')(() => ({
  [`& .${classes.comment}`]: {
    '&:focus': {
      border: `1px solid ${colors.primary}`,
      outline: 'none',
    },
    border: `1px solid ${colors.greyText}`,
    borderRadius: '5px',
    marginTop: '0.5rem',
    padding: '.5rem',
    width: '100%',
  },
  [`& .${classes.popper}`]: {
    '& .MuiListItem-gutters': {
      paddingLeft: '1.313rem',
      paddingRight: '1.313rem',
    },
    '& .MuiMenuItem-root': {
      fontSize: '1.1rem',
      margin: '.4rem 0',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: boxShadows.popperShadow,
    },
    '& .menuItemText': {
      color: colors.greyText,
      fontSize: '0.875rem',
      fontWeight: 'normal',
    },
    '& .status-group-title': {
      color: colors.osloGray,
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '0.094rem',
      padding: '1.313rem 1.313rem 0.563rem 1.313rem',
    },
    background: colors.white,
    marginTop: '0.25rem',
    minWidth: (props: { isFilterDrawer?: boolean }) => (props.isFilterDrawer ? '90%' : '18.75rem'),
    zIndex: 5,
  },
  [`& .${classes.statusButton}`]: {
    '& .MuiButton-label': {
      color: colors.dustyGray,
    },
    '& i': {
      color: colors.greyText,
      fontSize: '.85rem !important',
      marginRight: '.5rem',
    },
    background: colors.white,
    border: '0.5px solid rgba(162, 164, 161, 0.7)',
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.statusDropdown}`]: {
    position: 'relative',
  },
}));
