import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { Alert, CardWithTitle, colors, LinearGradientChip } from 'asteroids';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UpsellingPromptCardProps } from '../../upselling-prompt.props';

export const UpsellingPromptCard = ({
  description,
  featureName,
  handleAddonRequest,
  image,
  isAddonRequested,
  learnMoreLink,
  submitting,
  sx,
}: UpsellingPromptCardProps) => {
  const { t } = useTranslation();

  return (
    <CardWithTitle
      titleDivider
      header={
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            padding: 1,
          }}>
          <Typography variant='h3'>{featureName}</Typography>
          <LinearGradientChip withIcon title={t('pricing_plans:premium_feature')} size='small' />
        </Box>
      }
      sx={{ marginBottom: 2, ...sx }}
      data-testid='upselling-prompt-card'>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <img src={image} alt='upselling-prompt' data-testid='prompt-card-image' />
        <Box>
          <Typography variant='section' sx={{ marginBottom: 1 }} data-testid='prompt-card-title'>
            {t('pricing_plans:upselling_prompt.title')}
          </Typography>
          <Typography
            variant='body'
            sx={{ color: colors.text.secondary, marginBottom: 2 }}
            data-testid='prompt-card-description'>
            {description}
          </Typography>
          <Box>
            <LoadingButton
              variant='contained'
              color='primary'
              disabled={isAddonRequested || submitting}
              loading={submitting}
              loadingPosition='start'
              startIcon={<SaveIcon sx={{ opacity: 0, ...(!submitting && { display: 'none' }) }} />} // This is a workaround for loading icon to be displayed properly
              onClick={handleAddonRequest}
              data-testid='prompt-card-primary-btn'
              sx={{ marginRight: 1 }}>
              {t('pricing_plans:upselling_prompt.button_text')}
            </LoadingButton>
            <Link to={{ pathname: learnMoreLink }} target='_blank' rel='noreferrer' data-testid='prompt-card-link'>
              <Button variant='text' color='secondary'>
                {t('pricing_plans:upselling_prompt.second_button_text')} {featureName}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      {isAddonRequested && (
        <Alert
          severity='info'
          description={t('pricing_plans:upselling_prompt.requestedAlert')}
          sx={{ marginTop: 2, width: '100%' }}
          data-testid='prompt-card-alert'
        />
      )}
    </CardWithTitle>
  );
};
