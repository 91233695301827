import { Box } from '@mui/material';
import { CourierProvider, Inbox } from '@trycourier/react-inbox';
import { colors } from 'asteroids';
import { useClickOutside } from 'hooks/useClickOutside';
import { useUser } from 'hooks/useUser';
import { useEffect, useRef, useState } from 'react';
import {
  notificationsEventsTrackMap,
  trackNotificationsClick,
  trackNotificationsInboxPopupOpen,
  trackNotificationsReadActionClick,
} from 'shared/logic/event-tracking/notifications-inbox.events';

import { useRelativeNotificationsLinks } from './hooks/use-relative-notifications-links';
import { styles } from './notifications-inbox.styles';

export const NotificationsInbox = () => {
  const container = useRef<HTMLDivElement>();
  const user = useUser();
  const [isOpen, setIsOpen] = useState(false);

  useRelativeNotificationsLinks({
    container,
    onNotificationClick: (notificationTitle, notificationLink) => {
      trackNotificationsClick({
        notificationLink,
        notificationTitle,
      });
      setIsOpen(false);
    },
    onNotificationReadActionClick: (notificationTitle) => trackNotificationsReadActionClick({ notificationTitle }),
  });

  useEffect(() => {
    const containerElement = container.current;

    // Open popup event tracking
    const openClosePopupHandler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (target.classList.contains('inbox-bell') || target.closest('.inbox-bell')) {
        setIsOpen(true);
        trackNotificationsInboxPopupOpen();
      }

      if ((target.classList.contains('close') || target.closest('.close')) && target.closest('[class^="Header"]'))
        setIsOpen(false);
    };

    containerElement?.addEventListener('click', openClosePopupHandler);

    return () => {
      containerElement?.removeEventListener('click', openClosePopupHandler);
    };
  }, []);

  useClickOutside(() => isOpen && setIsOpen(false), container.current);

  return (
    <CourierProvider authorization={user.inAppInboxToken ?? undefined}>
      <Box sx={styles.root} ref={container}>
        <Inbox
          isOpen={isOpen}
          onEvent={(e) => notificationsEventsTrackMap[e.event as keyof typeof notificationsEventsTrackMap]?.(e)}
          openLinksInNewTab={false}
          trigger='hover'
          placement='right'
          theme={{
            header: {
              backgroundColor: colors.background.paper,
            },
            menu: {
              height: '380px',
            },
            messageList: {
              container: {
                height: '380px',
                maxHeight: '380px',
                overflow: 'auto',
              },
            },
            root: {
              borderRadius: '8px',
            },
            unreadIndicator: {
              backgroundColor: colors.tertiary.main,
              height: '8px',
              right: '-2px',
              width: '8px',
            },
          }}
        />
      </Box>
    </CourierProvider>
  );
};
