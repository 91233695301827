import { AccordionDetails, Box, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Alert, Icon, StatusDot } from 'asteroids';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from 'shared/common.definitions';
import { RequestDetails } from 'shared/models';
import { borders } from 'shared/theme';
import { formatDate } from 'views/overview/calendar/subscription-indicator/helpers';
import { Avatar } from 'views/requests/components/request-list/request-list.styles';

import { Accordion, AccordionSummary } from '../accordion/accordion.styles';
import { DetailsItem } from '../details-item/details-item.component';
import { formatText, getAmountWithCurrency } from '../details-item/details-item.utils';
import { AvatarGroupStyled } from './request-details-card.component.styles';
import { RequestDetailsCardProps } from './request-details-card.component.types';

interface RequestState {
  name: string;
  color: 'info' | 'success' | 'error';
}

export const RequestDetailsCard: React.FC<RequestDetailsCardProps> = ({
  detailsData,
  expanded,
  isRequestApproved,
  isToolActivated,
  onDeleteRequest,
  onEditRequest,
  setExpanded,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isNewPurchaseInitiative = detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL;

  const getInitiativeTitle = () => {
    switch (detailsData?.requestWorkflowTypeId) {
      case REQUEST_WORKFLOW_TYPE.RENEWAL:
        return `${t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.renewal_label')} ${t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.initiative_details'
        )}`;
      case REQUEST_WORKFLOW_TYPE.APPROVAL:
        return `${t(
          'requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.tool_request_label'
        )} ${t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.initiative_details'
        )}`;
      default:
        return '';
    }
  };

  const getRequestState = (item: RequestDetails): RequestState => {
    switch (item.requestStateId) {
      case REQUEST_STATUS.REJECTED:
        return { color: 'error', name: t('requests_view:status_name.rejected') };
      case REQUEST_STATUS.APPROVED:
        return { color: 'success', name: t('requests_view:status_name.approved') };
      case REQUEST_STATUS.SUBSCRIPTION_CREATED:
        return { color: 'success', name: t('requests_view:status_name.active_tool') };
      default:
        return { color: 'info', name: item.currentStepName };
    }
  };

  const renderMainButtons = () => {
    const disabled = detailsData?.currentStep !== 1 || detailsData?.currentStepStatus !== 'pending';

    return (
      <Stack direction='row' spacing={2} alignItems='center'>
        {detailsData?.permissions?.edit && (
          <IconButton disabled={disabled} onClick={onEditRequest} data-testid='edit-button'>
            <Icon>edit</Icon>
          </IconButton>
        )}

        {detailsData?.permissions?.delete && (
          <IconButton onClick={onDeleteRequest} data-testid='delete-button'>
            <Icon>delete</Icon>
          </IconButton>
        )}
      </Stack>
    );
  };

  const renderTitle = () => (
    <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
      <Typography variant='h2' data-testid='initiative-title'>
        {getInitiativeTitle()}
      </Typography>
      {(detailsData?.permissions?.edit || detailsData?.permissions?.delete) && renderMainButtons()}
    </Stack>
  );

  const renderActivationAlert = () => (
    <>
      {isNewPurchaseInitiative &&
        isRequestApproved &&
        !isToolActivated &&
        !detailsData?.permissions?.canActivateRequest && (
          <Alert
            sx={{ mb: 1 }}
            actionIcon=''
            description={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.approval_alert.message'
            )}
            isIconVisible
            severity='info'
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.approval_alert.title'
            )}
          />
        )}
    </>
  );

  const renderVendorInfo = () => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Avatar alt={detailsData?.vendorName} src={detailsData?.vendorAvatarUrl} size='normal' variant='rounded' />
      <Stack direction='column'>
        <Typography data-testid='vendor-name'>{detailsData?.vendorName}</Typography>
        <Typography color='textSecondary' variant='small'>
          {detailsData?.vendorCategory}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderStatusInfo = () => (
    <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
      <StatusDot status={detailsData ? getRequestState(detailsData).color : 'info'} />
      <Typography data-testid='status' fontWeight={300}>
        {detailsData ? getRequestState(detailsData).name : ''}
      </Typography>
    </Stack>
  );

  const renderCardSummary = () => (
    <Stack component='div' direction='row' spacing={2} width='100%' alignItems='center' px={1} mr={4}>
      <Stack flex={1}>{renderVendorInfo()}</Stack>
      <Stack>{renderStatusInfo()}</Stack>
    </Stack>
  );

  const renderRequesterInfo = (secondaryText = true) => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Avatar alt={detailsData?.requesterFullName} src={detailsData?.requesterAvatarUrl} size='normal' />
      <Stack direction='column'>
        <Typography data-testid='requester' noWrap>
          {detailsData?.requesterFullName || detailsData?.requesterEmail}
        </Typography>
        {secondaryText && (
          <Typography color='textSecondary' variant='small'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.requester_text'
            )}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  const renderAssigneeInfo = () => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Avatar alt={detailsData?.assigneeFullName} src={detailsData?.assigneeAvatarUrl} size='normal' />
      <Stack direction='column'>
        <Typography data-testid='assignee' noWrap>
          {detailsData?.assigneeFullName || detailsData?.assigneeEmail}
        </Typography>
        <Typography color='textSecondary' variant='small'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.assignee_text'
          )}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderNewPurchaseInitiativeInfo = () => (
    <>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2} py={1}>
        <Stack flex={1} borderRight={borders.actionMenuBorder} pr={5}>
          {renderRequesterInfo()}
        </Stack>
        <Stack flex={1}>{renderAssigneeInfo()}</Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            testId='cost'
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.cost_label'
            )}
            content={
              detailsData?.licenseCents && detailsData?.licenseCurrency
                ? getAmountWithCurrency(detailsData?.licenseCents, detailsData?.licenseCurrency)
                : '-'
            }
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            testId='number-of-licenses'
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.number_licenses_label'
            )}
            content={detailsData?.licenseNumber || '-'}
          />
        </Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            testId='payment-frequency'
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.payment_freq_label'
            )}
            content={formatText(detailsData?.licensePaymentFrequency) || '-'}
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            testId='due-date'
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.due_date_label'
            )}
            content={formatDate(detailsData?.dueDate) || '-'}
          />
        </Stack>
      </Stack>
      <Stack>
        <DetailsItem
          testId='description'
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.desc_label'
          )}
          content={detailsData?.description || '-'}
        />
      </Stack>
    </>
  );

  const renderContributorsInfo = () => {
    if (detailsData?.contributors && detailsData?.contributors?.length > 0) {
      return (
        <AvatarGroupStyled max={4} sx={{ ml: theme.spacing(1) }}>
          {detailsData?.contributors?.map(({ avatar, email, id, name }, index) => (
            <Tooltip title={name || email} data-testid={`collaborator-${index}`}>
              <Box ml={theme.spacing(-1)}>
                <Avatar key={id} alt={name} src={avatar || ''} size='normal' />
              </Box>
            </Tooltip>
          ))}
        </AvatarGroupStyled>
      );
    }
    return '-';
  };

  const renderRenewalInitiativeInfo = () => (
    <>
      <Stack component='div' direction='row' spacing={5} alignItems='flex-start' mb={2} py={1}>
        <Stack flex={1} borderRight={borders.actionMenuBorder} pr={5} data-testid='owner'>
          <Typography fontWeight={600} mb='0.5rem'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.owner'
            )}
          </Typography>
          {renderRequesterInfo(false)}
        </Stack>
        <Stack flex={1} direction='column' alignItems='flex-start' borderRight={borders.actionMenuBorder}>
          <Typography fontWeight={600} mb='0.5rem' data-testid='collaborators'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.contributors'
            )}
          </Typography>
          {renderContributorsInfo()}
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            testId='due-date'
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.due_date_label'
            )}
            content={formatDate(detailsData?.dueDate) || '-'}
          />
        </Stack>
      </Stack>
      <Stack>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.desc_label'
          )}
          content={detailsData?.description || '-'}
        />
      </Stack>
    </>
  );

  const renderDetailsInfo = () => {
    switch (detailsData?.requestWorkflowTypeId) {
      case REQUEST_WORKFLOW_TYPE.RENEWAL:
        return renderRenewalInitiativeInfo();
      case REQUEST_WORKFLOW_TYPE.APPROVAL:
        return renderNewPurchaseInitiativeInfo();
      default:
        return null;
    }
  };

  return (
    <>
      {renderTitle()}
      {renderActivationAlert()}
      <Accordion
        onChange={setExpanded('request-details-accordion')}
        expanded={expanded.includes('request-details-accordion')}
        disableGutters>
        <AccordionSummary expandIcon={<Icon variant='Outlined'>expand_more</Icon>}>
          {renderCardSummary()}
        </AccordionSummary>
        <AccordionDetails>
          <Box mr={10} px={1}>
            {renderDetailsInfo()}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
