interface IsSendButtonEnabledPayload {
  commentText: string;
  isAppHelp: boolean;
  isExploreRequestSupport: boolean;
  isSendingAnotherRequest: boolean;
  providedSubscriptionId: string | null;
  selectedSubscription: object | null;
  templateId: string | undefined;
  vendorId: string | undefined;
}

export const getIsSendButtonEnabled = (params: IsSendButtonEnabledPayload) =>
  !params.isSendingAnotherRequest && // another request should not already being sent
  !!params.commentText && // comment should not be empty
  !!params.templateId && // template id should not be empty
  ((!params.isAppHelp &&
    (!!params.selectedSubscription ||
      !!params.providedSubscriptionId ||
      (params.isExploreRequestSupport && !!params.vendorId))) ||
    params.isAppHelp); // if the user did not choose app help, a subscription must be provided or if the user is in the expore page, the vendor id should be present
