import { Box, styled } from '@mui/material';

export const SpendModalHeaderContainer = styled(Box)(({ theme }) => ({
  '.spend-review-modal--wrapper': {
    display: 'flex',
    height: theme.spacing(8.5),
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '.spend-review-modal-header': {
      '.invoice-title': {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.spacing(2),
      },
      '.invoice-subtitle': {
        fontSize: theme.spacing(1.6),
      },
    },
    '.spend-review-modal--icons': {
      display: 'flex',
      '.close-icon': {
        margin: theme.spacing(0.5),
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
      '.download-icon': {
        margin: theme.spacing(0.5),
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
  },
}));
