import { FC } from 'react';
import { colors } from 'shared/theme';

export const BarGraphIcon: FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.5 7.25h-3a.75.75 0 00-.75.75v9a.75.75 0 00.75.75h3a.75.75 0 00.75-.75V8a.75.75 0 00-.75-.75zm-.75 9h-1.5v-7.5h1.5v7.5zm6.75-15h-3a.75.75 0 00-.75.75v15a.75.75 0 00.75.75h3a.75.75 0 00.75-.75V2a.75.75 0 00-.75-.75zm-.75 15h-1.5V2.75h1.5v13.5zM4.5 11.75h-3a.75.75 0 00-.75.75V17a.75.75 0 00.75.75h3a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75zm-.75 4.5h-1.5v-3h1.5v3z'
      fill={fill}
    />
  </svg>
);

BarGraphIcon.defaultProps = {
  fill: colors.osloGray,
};
