import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { cloudOptimizationData } from 'assets/data/cloud-optimization.data';
import { RadioGroup } from 'asteroids';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { CLOUD_OPTIMIZATION_TOOL } from 'libs/enums';
import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { DocumentCategory, ExtendedBlobType, googleTagManagerEvents, YesNo } from 'shared/common.definitions';
import {
  createSastricloudOptimizeRequest,
  type CreateSastricloudOptimizeRequestPayload,
  fireGTagManagerEvent,
} from 'shared/logic/company.logic';
import { getCompanyDocumentCategory, uploadSubscriptionDocument } from 'shared/logic/subscription-item.logic';
import { Company } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';

import { DropzoneComponent } from '../drop-zone';
import { DrawerSastricloudAccessRequestProps } from './drawer-sastricloud-access-request.props';
import { useSastricloudAccessRequestForm } from './drawer-sastricloud-access-request-form.hook';

const yesNoOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const DrawerSastricloudAccessRequest: FC<DrawerSastricloudAccessRequestProps> = (props) => {
  const { showNotification, toggleDrawer } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const drawerState = useAppSelector((state) => state.common.appDrawer);
  const appDrawerData = drawerState.data as (typeof cloudOptimizationData)[0];
  const user = useAppSelector((state) => state.authentication.user);
  const company = queryClient.getQueryData<Company>('company');
  const companyId = String(company?.id || '');

  const [isSendingAnotherRequest, setIsSendingAnotherRequest] = useState(false);
  const [documents, setDocuments] = useState<ExtendedBlobType[]>([]);
  const { control, formState, getValues } = useSastricloudAccessRequestForm();
  const { isValid } = formState;

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section';
  const pathToDocumentTabTranslation = 'subscription_detail_view:tabs_component_section.subscription_document_tab';

  const { mutateAsync: documentUploadRequest } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
  });

  const { mutateAsync: mutateCreateSastricloudOptimizeRequest } = useMutation(createSastricloudOptimizeRequest);

  const onDocumentUpload = (files: ExtendedBlobType[]) => {
    setDocuments(files);
  };

  const handleDocumentUpload = async () => {
    const uploads: unknown[] = [];

    documents.forEach((document) => {
      const values = {
        category: getCompanyDocumentCategory(DocumentCategory.invoice),
        companyId,
        file: document,
        isCompanyDocument: true,
        subscriptionId: undefined,
      };

      try {
        uploads.push(documentUploadRequest(values));
      } catch (error) {
        handleCloseDrawer();
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      }
    });

    try {
      const responses = await Promise.all(uploads);
      return responses;
    } catch (error) {
      handleCloseDrawer();
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    }
  };

  const handleSaveRequest = async () => {
    try {
      const documents = ((await handleDocumentUpload()) || []) as Partial<{
        fileName?: string;
        id?: number | string;
      }>[];

      const formValues = getValues();

      const optimizeRequestData: CreateSastricloudOptimizeRequestPayload = {
        companyId: companyId || '',
        payload: {
          cloudToolName: appDrawerData.key as CLOUD_OPTIMIZATION_TOOL,
          documents: {
            category: getCompanyDocumentCategory(DocumentCategory.invoice),
            ids: documents?.map((doc) => String(doc.id)),
          },
          form: {
            hasCommitment3PlusMonths: formValues.hasCommitment3PlusMonths as YesNo,
            hasCommitmentAndWouldStay: formValues.hasCommitmentAndWouldStay as YesNo,
            purchasingFromVendorDirectly: formValues.purchasingFromVendorDirectly as YesNo,
          },
        },
      };

      await mutateCreateSastricloudOptimizeRequest(optimizeRequestData, {
        onError: (error) => {
          showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
        },
        onSuccess: () => {
          handleCloseDrawer();
        },
      });
    } catch (error) {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    } finally {
      showNotification?.(
        <Trans
          i18nKey='connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.success'
          components={[<span />]}
        />,
        NotificationAlertType.Success
      );

      fireGTagManagerEvent(window, String(user?.email), googleTagManagerEvents.SubmitSastricloudOptimizeRequest);
    }
  };

  const handleCloseDrawer = () => {
    toggleDrawer();
    setIsSendingAnotherRequest(false);
    setDocuments([]);
  };

  const renderFormOptions = () => {
    return (
      <>
        <Typography variant='label' component='div' sx={{ mb: 1 }}>
          {t(
            'connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.questions.purchasingFromVendorDirectly'
          )}{' '}
          *
        </Typography>
        <Controller
          name='purchasingFromVendorDirectly'
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <RadioGroup
              key='question-1'
              row
              options={yesNoOptions}
              sx={{ mb: 2 }}
              name={name}
              value={value}
              onChange={onChange}
              data-testid='purchasingFromVendorDirectly'
            />
          )}
        />

        <Typography variant='label' component='div' sx={{ mb: 1 }}>
          {t(
            'connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.questions.hasCommitment3PlusMonths'
          )}{' '}
          *
        </Typography>
        <Controller
          name='hasCommitment3PlusMonths'
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <RadioGroup
              key='question-2'
              row
              options={yesNoOptions}
              sx={{ mb: 2 }}
              name={name}
              value={value}
              onChange={onChange}
              data-testid='hasCommitment3PlusMonths'
            />
          )}
        />

        <Typography variant='label' component='div' sx={{ mb: 1 }}>
          {t(
            'connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.questions.hasCommitmentAndWouldStay'
          )}{' '}
          *
        </Typography>
        <Controller
          name='hasCommitmentAndWouldStay'
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <RadioGroup
              key='question-3'
              row
              options={yesNoOptions}
              sx={{ mb: 2 }}
              name={name}
              value={value}
              onChange={onChange}
              data-testid='hasCommitmentAndWouldStay'
            />
          )}
        />
      </>
    );
  };

  const renderActions = () => (
    <>
      <Button
        key='close'
        sx={{ ml: 1 }}
        variant='text'
        color='secondary'
        disabled={isSendingAnotherRequest}
        onClick={handleCloseDrawer}>
        {t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.cancel')}
      </Button>

      <Button
        key='submit'
        sx={{ ml: 1 }}
        variant='contained'
        color='primary'
        disabled={isSendingAnotherRequest || !documents.length || !isValid}
        onClick={() => {
          setIsSendingAnotherRequest(true);
          handleSaveRequest();
        }}
        startIcon={isSendingAnotherRequest ? <CircularProgress color='inherit' size={16} /> : null}>
        {t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.submit')}
      </Button>
    </>
  );

  const renderForm = () => (
    <>
      <Typography variant='h2' sx={{ display: 'inline-block', mb: 3 }}>
        {t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.title_right', {
          name: t(
            `connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.vendors.${appDrawerData.key}`
          ),
        })}
      </Typography>

      <Typography variant='subtitle' sx={{ display: 'inline-block', mb: 3 }}>
        {t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.description', {
          name: t(
            `connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.vendors.${appDrawerData.key}`
          ),
        })}
      </Typography>

      <Box key='file-input' data-testid='upload-document' sx={{ mb: 3 }}>
        <DropzoneComponent
          isPaperClipIcon
          hasCustomMessage
          message={
            <span className='dropzone-message' data-testid='upload-document-message'>
              <Trans
                i18nKey={`${pathToTranslation}.right_section.document_category_section.drag_and_drop_text`}
                values={{
                  clickHere: t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.click_here'),
                }}
                components={[<span />]}
              />
            </span>
          }
          name='file'
          onDrop={(value) => {
            onDocumentUpload(value as ExtendedBlobType[]);
          }}
          onDropRejected={() => {
            const message = t(`${pathToDocumentTabTranslation}.upload_file_type_error_message`);
            showNotification?.(message, NotificationAlertType.Error);
          }}
          multiple
        />
      </Box>

      {!!documents.length && (
        <Box key='current-file' sx={{ mb: 3 }}>
          {documents?.map((file) => (
            <Box display='flex' key={file.name}>
              <Typography sx={{ mr: 1, whiteSpace: 'nowrap' }}>
                {t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.file_name')}
              </Typography>
              <Typography className='filename'>{file.name}</Typography>
            </Box>
          ))}
        </Box>
      )}

      {renderFormOptions()}

      <Divider />

      <Typography variant='subtitle' sx={{ mb: 1.5, mt: 2 }} component='div'>
        {t('connect_view:tab_section.tabs.cloud_optimization.sections.cloud.drawer.bottom_text')}
      </Typography>
    </>
  );

  return (
    <Box
      key='request-section'
      className='requestSection'
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
      <Box key='form'>{renderForm()}</Box>

      <Box key='actions' display='flex' justifyContent='flex-end' width='100%' mt={5}>
        {renderActions()}
      </Box>
    </Box>
  );
};
