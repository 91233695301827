import { LanOutlined, LayersOutlined } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import { Page } from 'components-2.0/page';
import { PageTitle } from 'components-2.0/page/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RoutedProps } from 'shared/models';

import { TabPanel } from '../connect2/molecules/tab-panel.molecule';
import { useWorkflowTemplatesData } from './hooks/use-workflow-templates-data';
import { useWorkflowsListData } from './hooks/use-workflows-list-data';
import { TemplatesPanel } from './panels/templates.panel';
import { WorkflowsPanel } from './panels/workflows.panel';

enum TAB {
  TEMPLATES = 'templates',
  WORKFLOWS = 'workflows',
}

enum TAB_INDEX {
  TEMPLATES = 0,
  WORKFLOWS = 1,
}

export enum REQUEST_WORKFLOW_QUERY {
  TEMPLATES = 'request-workflow-templates',
  WORKFLOWS_LIST = 'request-workflows-list',
}

const tabsDefinition = [
  {
    Icon: LayersOutlined,
    Panel: TemplatesPanel,
    key: TAB.TEMPLATES,
    title: 'request_workflows_view:tab_section.tabs.templates.title',
  },
  {
    Icon: LanOutlined,
    Panel: WorkflowsPanel,
    key: TAB.WORKFLOWS,
    title: 'request_workflows_view:tab_section.tabs.workflows.title',
  },
];

export const RequestWorkflowsView: FC<RoutedProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { isLoading: isTemplatesLoading } = useWorkflowTemplatesData();
  const { data: listData, isLoading: isListLoading } = useWorkflowsListData();

  const filteredTabsDefinition = tabsDefinition.filter(
    (tab) => tab.key !== TAB.WORKFLOWS || (tab.key === TAB.WORKFLOWS && listData?.length)
  );

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number): void => {
    history.push(`/request-workflows?tab=${newTabIndex === TAB_INDEX.TEMPLATES ? TAB.TEMPLATES : TAB.WORKFLOWS}`);
  };

  const getCurrentTabIndex = () => {
    const queryString = new URLSearchParams(window.location.search);
    const tab = queryString.get('tab');

    return tab === String(TAB.WORKFLOWS) ? TAB_INDEX.WORKFLOWS : TAB_INDEX.TEMPLATES;
  };

  return (
    <Page
      attachHeaderToContent
      header={
        <>
          <PageTitle
            title={t('request_workflows_view:header_section.page_title_text')}
            subtitle={t('request_workflows_view:header_section.page_subtitle_text')}
          />
          <Tabs value={getCurrentTabIndex()} onChange={handleTabChange} sx={{ mt: 4 }}>
            {!isTemplatesLoading &&
              !isListLoading &&
              filteredTabsDefinition.map(({ Icon, key, title }) => (
                <Tab
                  key={key}
                  label={<>{t(title)}</>}
                  icon={<Icon />}
                  iconPosition='start'
                  data-testid={`tab-item-${key}`}
                />
              ))}
          </Tabs>
        </>
      }>
      {!isTemplatesLoading &&
        !isListLoading &&
        filteredTabsDefinition.map(({ Panel, key }, index) => (
          <TabPanel key={key} value={getCurrentTabIndex()} index={index}>
            <Panel {...props} />
          </TabPanel>
        ))}
    </Page>
  );
};

export default RequestWorkflowsView;
