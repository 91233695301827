import { styled } from '@mui/material';
import { Avatar as AsteroidsAvatar } from 'asteroids';

export const Avatar = styled(AsteroidsAvatar)(() => ({
  '& img': {
    objectFit: 'contain',
  },
  '&:not(.MuiAvatar-colorDefault)': {
    background: 'transparent',
  },
}));
