export enum DEFAULT_STATE_OPTIONS {
  ACTIVE = 0,
  IN_NEGOTIATION = 1,
  UNDER_REVIEW = 2,
  INACTIVE = 3,
  IN_EVALUATION = 4,
  UP_FOR_RENEWAL = 5,
  UNDER_CONSIDERATION = 6, // solution
  IN_TRIAL = 7, // solution
  IN_DISCOVERY_NEGOTIATION = 8, // solution
  EXPIRED = 9,
  REQUESTED = 10, // solution
  DISCOVERED = 11,
  REJECTED = 12, // solution
}

export const SUBSCRIPTION_STATES: DEFAULT_STATE_OPTIONS[] = [
  DEFAULT_STATE_OPTIONS.ACTIVE,
  DEFAULT_STATE_OPTIONS.IN_NEGOTIATION,
  DEFAULT_STATE_OPTIONS.UNDER_REVIEW,
  DEFAULT_STATE_OPTIONS.INACTIVE,
  DEFAULT_STATE_OPTIONS.IN_EVALUATION,
  DEFAULT_STATE_OPTIONS.UP_FOR_RENEWAL,
  DEFAULT_STATE_OPTIONS.EXPIRED,
  DEFAULT_STATE_OPTIONS.DISCOVERED,
];

export const NEW_SOLUTION_STATES: DEFAULT_STATE_OPTIONS[] = [
  DEFAULT_STATE_OPTIONS.UNDER_CONSIDERATION,
  DEFAULT_STATE_OPTIONS.IN_TRIAL,
  DEFAULT_STATE_OPTIONS.IN_DISCOVERY_NEGOTIATION,
  DEFAULT_STATE_OPTIONS.REQUESTED,
  DEFAULT_STATE_OPTIONS.REJECTED,
];

export const SUBSCRIPTION_STATE_WITHOUT_INACTIVE_AND_DISCOVERED = [
  DEFAULT_STATE_OPTIONS.ACTIVE,
  DEFAULT_STATE_OPTIONS.IN_NEGOTIATION,
  DEFAULT_STATE_OPTIONS.UNDER_REVIEW,
  DEFAULT_STATE_OPTIONS.IN_EVALUATION,
  DEFAULT_STATE_OPTIONS.UP_FOR_RENEWAL,
  DEFAULT_STATE_OPTIONS.EXPIRED,
];

export enum SUBSCRIPTION_TYPE {
  SUBSCRIPTION,
  NEW_SOLUTION,
}

export enum ENTITY_TYPE {
  SUBSCRIPTION = 'SUBSCRIPTION',
  NEW_SOLUTION = 'NEW_SOLUTION',
  VENDOR = 'VENDOR',
}

export enum BILLING_FREQUENCIES {
  MONTHLY = 0,
  YEARLY = 1,
  CUSTOM = 2,
  OTHER = 3,
}

export enum NANONETS_IMPORT_SUB_SYSTEMS {
  MANUAL_UPLOAD = 'manual-upload',
  EMAIL_IMPORT = 'email-import',
}

export const NANONETS_IMPORT_SUBSYSTEM_OPTIONS = [
  NANONETS_IMPORT_SUB_SYSTEMS.EMAIL_IMPORT,
  NANONETS_IMPORT_SUB_SYSTEMS.MANUAL_UPLOAD,
];

export enum TOOL_SUMMARY_STATE_ENUMS {
  'active' = 0,
  'inNegotiation' = 1,
  'inEvaluation' = 4,
  'upForRenewal' = 5,
  'expired' = 9,
  'discovered' = 11,
}

export enum RENEWAL_FREQUENCY {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  OTHER = 'other',
}

export enum RENEWAL_PERIOD {
  MONTHS = 'months',
  YEARS = 'years',
}

export enum RESELLER_SUPPLIER {
  DIRECT = 'direct',
  RESELLER = 'reseller',
}
