import { format } from 'date-fns';
import { MAX_NUMBER_OF_INVOICES_LOADED, PAGE_NUMBER_OF_INVOICES } from 'libs/constants/features.constants';
import { AuthenticationOptionsType, HRISGroupType, SOURCE_SYSTEM, SPEND_CATEGORIES } from 'libs/enums';
import { CodatPlatformType } from 'libs/models';

import { ReplyableType, UPDATE_REQUEST_TYPE } from '../common.definitions';

class ApiUrl {
  private serverUrl = process.env.API_SERVER_URL;

  private freshdeskServiceUrl = process.env.API_FRESHDESK_SERVICE_URL;

  private googleIntegrationServiceUrl = process.env.API_GOOGLE_INTEGRATION_SERVICE_URL;

  private invoicesErrorHandlerServiceUrl = process.env.API_INVOICES_ERROR_HANDLER_SERVICE_URL;

  private codatIntegrationServiceUrl = process.env.API_CODAT_INTEGRATION_SERVICE_URL;

  private toolUsageServiceUrl = process.env.API_TOOL_USAGE_SERVICE_URL;

  private benchmarkingServiceUrl = process.env.API_BENCHMARKING_SERVICE_URL;

  private microsoftIntegrationServiceUrl = process.env.API_MICROSOFT_INTEGRATION_SERVICE_URL;

  private notificationServiceUrl = process.env.API_NOTIFICATION_SERVICE_URL;

  private jumpCloudIntegrationServiceUrl = `${process.env.SSO_INTEGRATION_SERVICE_URL}/jumpcloud`;

  public get Auth(): string {
    return `${this.serverUrl}auth`;
  }

  public get Alerts(): string {
    return `${this.serverUrl}alerts`;
  }

  public get UpcomingAlerts(): string {
    return `${this.serverUrl}alerts/upcoming`;
  }

  public get AcceptInvitation(): string {
    return `${this.serverUrl}accept-invitation`;
  }

  public get AccountConfirm(): string {
    return `${this.serverUrl}account-confirm`;
  }

  public get AccountUnlock(): string {
    return `${this.serverUrl}account-unlock`;
  }

  public CompanyDocuments(): string {
    return `${this.serverUrl}company/documents`;
  }

  public CompanyDocumentId(id: string | number): string {
    return (id && `${this.serverUrl}company/documents/${id || ''}`) || '';
  }

  public CompanyGmi(companyId: string): string {
    return `${this.serverUrl}companies/${companyId}/gmi`;
  }

  public get Companies(): string {
    return `${this.serverUrl}companies`;
  }

  public get PasswordReset(): string {
    return `${this.serverUrl}password-reset`;
  }

  public get Permission(): string {
    return `${this.serverUrl}role-permissions`;
  }

  public get RequestAccountConfirmation(): string {
    return `${this.serverUrl}request-account-confirmation`;
  }

  public get RequestPasswordReset(): string {
    return `${this.serverUrl}request-password-reset`;
  }

  public get RequestAccountUnlock(): string {
    return `${this.serverUrl}request-account-unlock`;
  }

  public get Subscriptions(): string {
    return `${this.serverUrl}subscriptions`;
  }

  public get SubscriptionsDetails(): string {
    return `${this.serverUrl}subscriptions/details`;
  }

  public getUnmatchedSuppliersPerSource(
    importSource: SOURCE_SYSTEM | CodatPlatformType | string,
    connectionId?: string
  ): string {
    if (!connectionId) return `${this.serverUrl}unmatched-suppliers-invoices?importSource=${importSource}`;

    return `${this.serverUrl}unmatched-suppliers-invoices?importSource=${importSource}&connectionId=${connectionId}`;
  }

  public bulkDeleteUnmatchedSuppliers(): string {
    return `${this.serverUrl}unmatched-suppliers/bulk-delete`;
  }

  public get FixAccountingImportSuppliers(): string {
    return `${this.serverUrl}fix-accounting-import-invoices`;
  }

  public get MapExternalSuppliers(): string {
    return `${this.serverUrl}map-external-suppliers`;
  }

  public completeRenewalReminder(id: string): string {
    return `${this.serverUrl}renewal-reminders/${id}/complete`;
  }

  public muteRenewalReminder(id: string): string {
    return `${this.serverUrl}renewal-reminders/${id}/mute`;
  }

  public getRenewalReminder(subscriptionId: string): string {
    return `${this.serverUrl}renewal-reminders/subscriptions/${subscriptionId}`;
  }

  public get SubscriptionsViewOnly(): string {
    return `${this.Subscriptions}/view-only`;
  }

  public get NewSolutions(): string {
    return `${this.serverUrl}new-solutions`;
  }

  public get SubscriptionsInDiscovery(): string {
    return `${this.Subscriptions}?status=discovery`;
  }

  public get SubscriptionsViewOnlyInDiscovery(): string {
    return `${this.SubscriptionsViewOnly}?status=discovery`;
  }

  public get SubscriptionsAndNewSolutions(): string {
    return `${this.Subscriptions}/all`;
  }

  public get DashboardTodos(): string {
    return `${this.serverUrl}subscriptions/dashboard`;
  }

  public ToolSummary(): string {
    return `${this.serverUrl}subscriptions/tools/tool-summary`;
  }

  public SubscriptionAlerts(id: string): string {
    return `${this.serverUrl}subscriptions/${id}/alerts`;
  }

  public ReInviteUser(userId: string): string {
    return `${this.serverUrl}users/${userId}/reinvite`;
  }

  public SubscriptionComments(id: string): string {
    return `${this.serverUrl}subscriptions/${id}/comments/`;
  }

  public SubscriptionDocuments(id: string): string {
    return `${this.serverUrl}subscriptions/${id}/documents/`;
  }

  public SubscriptionDocument(subscriptionId: string, documentId: string): string {
    return `${this.serverUrl}subscriptions/${subscriptionId}/documents/${documentId}`;
  }

  public SubscriptionFollowers(id: string) {
    return `${this.serverUrl}subscriptions/${id}/followers`;
  }

  public SubscriptionHistory(id: string) {
    return `${this.serverUrl}subscriptions/${id}/history`;
  }

  public SubscriptionTodos(id: string) {
    return `${this.serverUrl}subscriptions/${id}/todos`;
  }

  public SubscriptionsTodos() {
    return `${this.serverUrl}subscriptions/todos-and-statistics`;
  }

  public SubscriptionNote(id: string) {
    return `${this.serverUrl}subscriptions/${id}/note`;
  }

  public get ImportSubscriptions(): string {
    return `${this.serverUrl}subscriptions/import`;
  }

  public get ImportSubscriptionsSpends(): string {
    return `${this.serverUrl}subscriptions/spends/import`;
  }

  public get Vendors(): string {
    return `${this.serverUrl}vendors`;
  }

  public get Tools(): string {
    return `${this.serverUrl}tools`;
  }

  public get Users(): string {
    return `${this.serverUrl}users`;
  }

  public Workflows(companyId: string): string {
    return `${this.Companies}/${companyId}/workflows`;
  }

  public newWorkflow(): string {
    return `${this.serverUrl}workflows`;
  }

  public deleteWorkflow(workflowId: string): string {
    return `${this.serverUrl}workflows/${workflowId}`;
  }

  public updateWorkflowAction(workflowId: string, actionId: string): string {
    return `${this.serverUrl}workflows/${workflowId}/actions/${actionId}`;
  }

  public BuildUrl(request: string): string {
    return `${this.serverUrl}${request}`;
  }

  public generatePresignUploadUrl(id: string): string {
    return `${this.serverUrl}companies/${id}/request-upload?q=${Math.random() * 1000000}`; // Fix for https://github.com/axios/axios/issues/3211
  }

  public SubscriptionSpendAndInvoice(id: string, spendId?: string, category?: number): string {
    const initialUrl = `${this.serverUrl}subscriptions/${id}/spends?limit=${MAX_NUMBER_OF_INVOICES_LOADED}&page=0`;
    return spendId && typeof category !== 'undefined' && category in SPEND_CATEGORIES
      ? `${initialUrl}/${spendId}/${category}`
      : initialUrl;
  }

  public getFailedInvoiceData(subscriptionId: string, documentId: string): string {
    return `${this.invoicesErrorHandlerServiceUrl}invoices-error-handler/api/subscription/${subscriptionId}/document/${documentId}`;
  }

  public changeFailedInvoiceStatus(sourceSystem: string, sourceId: string): string {
    return `${this.invoicesErrorHandlerServiceUrl}invoices-error-handler/api/${sourceSystem}/${sourceId}`;
  }

  public getBulkFailedInvoiceData(subscriptionId: string): string {
    return `${this.invoicesErrorHandlerServiceUrl}invoices-error-handler/api/subscriptions/${subscriptionId}/failed-invoices-batch-get`;
  }

  public fixFailedSubscriptionInvoice(subscriptionId: string, documentId: string): string {
    return `${this.serverUrl}subscriptions/${subscriptionId}/document/${documentId}/invoice`;
  }

  public getSpendDuplicatesSubscriptionList() {
    return `${this.serverUrl}spend-duplicates/subscriptions`;
  }

  public fixSpendDuplicates() {
    return `${this.serverUrl}spend-duplicates/fix`;
  }

  public getSpendDuplicatesBySubscriptionId(subscriptionId: string) {
    return `${this.serverUrl}spend-duplicates/subscriptions/${subscriptionId}`;
  }

  public fixFailedCompanyInvoice(documentId: string): string {
    return `${this.serverUrl}invoices/document/${documentId}`;
  }

  public getPaginatedSubscriptionSpendAndInvoices(id: string, page: number, pageSize: number): string {
    const initialUrl = `${this.serverUrl}subscriptions/${id}/spends?limit=${
      pageSize || PAGE_NUMBER_OF_INVOICES
    }&page=${page}`;

    return initialUrl;
  }

  public deleteSubscriptionSpendsAndInvoices(id: string): string {
    return `${this.serverUrl}subscriptions/${id}/spends/bulk-delete`;
  }

  public get DeleteSubscriptionSpendList(): string {
    return `${this.serverUrl}subscriptions/spends/bulk-delete`;
  }

  public get HighestSpendSubscriptions(): string {
    return `${this.serverUrl}spends/subscriptions`;
  }

  public getSubscriptionSpendDetail(): string {
    return `${this.serverUrl}spends/detail`;
  }

  public getAuditLogs(): string {
    return `${this.serverUrl}audit-logs`;
  }

  public UpdateSubscriptionTodo(subscriptionId: string, todoId: string): string {
    return `${this.serverUrl}subscriptions/${subscriptionId}/todos/${todoId}`;
  }

  public CommentTemplates(): string {
    return `${this.serverUrl}comment-templates`;
  }

  public companyToolOwners(id: string): string {
    return `${this.Companies}/${id}/tool-owners`;
  }

  public companySubscriptionAcess(id: string): string {
    return `${this.Companies}/${id}/subscription-access`;
  }

  public getCompanyPotentialSavings(companyId: string): string {
    return `${this.Companies}/${companyId}/todos?category=saving_potential`;
  }

  public realizedSavings(companyId: string): string {
    return `${this.Companies}/${companyId}/savings`;
  }

  public deleteSubscription(subscriptionId: string): string {
    return `${this.serverUrl}subscriptions/${subscriptionId}`;
  }

  public deleteMultipleSubscriptions(): string {
    return `${this.serverUrl}subscriptions/bulk-delete`;
  }

  public deleteNewSolutions(newSolutionId: string): string {
    return `${this.serverUrl}new-solutions/${newSolutionId}`;
  }

  public editMultipleSubscriptions(): string {
    return `${this.serverUrl}subscriptions/bulk-edit`;
  }

  public getLoggedInUserCommentsMentions() {
    return `${this.serverUrl}user/mentions`;
  }

  public getAllInvoices(query: string) {
    return `${this.serverUrl}invoices?${query}`;
  }

  public get GetSpendSourceOptions(): string {
    return `${this.serverUrl}invoices/spend-sources`;
  }

  public getUnmatchedSuppliersTotals() {
    return `${this.serverUrl}unmatched-company-suppliers-count`;
  }

  public get GetSpendDuplicatesCount(): string {
    return `${this.serverUrl}spend-duplicates/count`;
  }

  public getFailedInvoicesTotals(companyId: string) {
    return `${this.invoicesErrorHandlerServiceUrl}invoices-error-handler/api/companies/${companyId}/user-upload/count`;
  }

  public getFailedInvoicesList(companyId: string, status: string) {
    return `${this.invoicesErrorHandlerServiceUrl}invoices-error-handler/api/companies/${companyId}/user-upload-invoices/status/${status}`;
  }

  public editSingleInvoice(subscriptionId: string, invoiceId: string) {
    return `${this.serverUrl}subscriptions/${subscriptionId}/invoices/${invoiceId}`;
  }

  public getAccessToken(userId: string, companyId: string, code?: string): string {
    const query = code ? `?code=${code}` : '';
    return `${this.serverUrl}google-integration/get-token/company/${companyId}/user/${userId}${query}`;
  }

  public revokeAccessToken(userId: string, companyId: string): string {
    return `${this.serverUrl}google-integration/revoke-token/company/${companyId}/user/${userId}`;
  }

  public sendCompanyTokenToGoogleService(code: string, companyId: string, enableUsageAnalytics: boolean): string {
    return `${this.googleIntegrationServiceUrl}google-integration-service/api/callback?code=${code}&companyId=${companyId}&enableUsageAnalytics=${enableUsageAnalytics}`;
  }

  public updateGoogleIntegration(companyId: string): string {
    return `${this.googleIntegrationServiceUrl}google-integration-service/api/integration-details/${companyId}`;
  }

  public getGoogleConnectionState(companyId: string | number): string {
    return `${this.googleIntegrationServiceUrl}google-integration-service/api/integration-details/${companyId}/state`;
  }

  public toggleGoogleUsageAnalytics(companyId: string): string {
    return `${this.googleIntegrationServiceUrl}google-integration-service/api/integration-details/${companyId}/usage-analytics-flag`;
  }

  public connectCodatPlatform(platformType: CodatPlatformType) {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/platforms/${platformType}/connect`;
  }

  public connectCodatPlatformV2(companyId: string) {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/v2/integration/company/${companyId}`;
  }

  public disconnectCodatPlatform(platformType: CodatPlatformType, companyId: string) {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/company/${companyId}/platforms/${platformType}/disconnect`;
  }

  public disconnectCodatPlatformV2(companyId: string, codatCompanyId: string) {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/v2/integration/company/${companyId}/codat-company/${codatCompanyId}`;
  }

  public getCodatIntegrationDetailsByCompanyId(companyId: string): string {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/integration-details/${companyId}`;
  }

  public updateCodatConnectionName(companyId: string, codatCompanyId: string) {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/v2/integration/company/${companyId}/codat-company/${codatCompanyId}`;
  }

  public getCodatIntegrationDetailsByCompanyIdV2(companyId: string): string {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/v2/integration/company/${companyId}`;
  }

  public getCodatRefreshSuppliersSpend(companyId: string): string {
    return `${this.codatIntegrationServiceUrl}codat-integration-service/api/v2/company/${companyId}/refresh-suppliers-spend`;
  }

  public getVendorKnowledgeItems(vendorId: string, companyId: string): string {
    return `${this.serverUrl}vendors/${vendorId}/vendor-knowledge-items?companyId=${companyId}`;
  }

  public getStoryblokVendorKnowledgeItems(vendorId: string, companyId: string): string {
    return `${this.serverUrl}vendors/${vendorId}/vendor-knowledge-stories?companyId=${companyId}`;
  }

  public getStoryblokVendorKnowledgeItemById(storyId: string, vendorId: string, companyId: string): string {
    return `${this.serverUrl}vendors/${vendorId}/vendor-knowledge-stories/${storyId}?companyId=${companyId}`;
  }

  public get MapVendorList(): string {
    return `${this.serverUrl}vendors/bulk-map`;
  }

  public getEventsEndpoint(): string {
    return `${this.serverUrl}events`;
  }

  public getReplyableOfTypeAndId(type: ReplyableType, replyableId: string): string {
    return `${this.serverUrl}replies/${type}/${replyableId}`;
  }

  public getReplyOfReplyableOfTypeAndId(type: ReplyableType, replyableId: string, replyId: string): string {
    return `${this.serverUrl}replies/${type}/${replyableId}/${replyId}`;
  }

  public requestAppHelp(companyId: string): string {
    return `${this.serverUrl}companies/${companyId}/general-support`;
  }

  public requestSastricloudOptimize(companyId: string): string {
    return `${this.serverUrl}companies/${companyId}/cloud-optimization`;
  }

  public workflowTemplates() {
    return `${this.serverUrl}workflow-templates`;
  }

  public subscriptionDetails(subscriptionId: string) {
    return `${this.serverUrl}subscriptions/${subscriptionId}/details`;
  }

  public subscriptionSummary(subscriptionId: string) {
    return `${this.serverUrl}subscriptions/${subscriptionId}/summary`;
  }

  public contractDetails(subscriptionId: string) {
    return `${this.serverUrl}subscriptions/${subscriptionId}/contracts`;
  }

  public resellerInformation(subscriptionId: string) {
    return `${this.serverUrl}subscriptions/${subscriptionId}/reseller-information`;
  }

  public availableCurrencies() {
    return `${this.serverUrl}currencies`;
  }

  public createNewRequest() {
    return `${this.serverUrl}requests`;
  }

  public modifyDraft() {
    return `${this.serverUrl}requests/draft`;
  }

  public editRequest() {
    return `${this.serverUrl}requests`;
  }

  public createRequestComment() {
    return `${this.serverUrl}requests/comments`;
  }

  public deleteRequestComment(requestId: string | number, commentId: string | number) {
    return `${this.serverUrl}requests/comments/${requestId}/${commentId}`;
  }

  public updateRequestComment() {
    return `${this.serverUrl}requests/comments`;
  }

  public get freshdesk() {
    return `${this.freshdeskServiceUrl}freshdesk-service/api/tickets`;
  }

  public getRequestWorkflows() {
    return `${this.serverUrl}requests`;
  }

  public getInitiativeSubscriptions() {
    return `${this.serverUrl}requests/subscriptions/all`;
  }

  public getRequestDetails(requestId: string | number) {
    return `${this.serverUrl}requests/${requestId}`;
  }

  public getRequestActivities(requestId: string | number) {
    return `${this.serverUrl}requests/activity/${requestId}`;
  }

  public getVendor(toolId: string | number) {
    return `${this.serverUrl}vendors/${toolId}`;
  }

  public updateRequestStatus(type: UPDATE_REQUEST_TYPE) {
    return `${this.serverUrl}requests/step/${type}`;
  }

  public updateMultiToolsRequestStatus() {
    return `${this.serverUrl}requests/multi/step/approve`;
  }

  public getWorkatoDirectLinkJWT(companyId: string) {
    return `${this.toolUsageServiceUrl}/workato-api/company/${companyId}/direct-link-jwt`;
  }

  public createDirectWorkatoIntegration(companyId: string, subscriptionId: string) {
    return `${this.toolUsageServiceUrl}/workato-api/recipe/company/${companyId}/subscription/${subscriptionId}`;
  }

  public startDirectWorkatoIntegration(companyId: string, subscriptionId: string) {
    return `${this.toolUsageServiceUrl}/workato-api/recipe/company/${companyId}/subscription/${subscriptionId}/start`;
  }

  public disconnectDirectWorkatoIntegration(companyId: string, subscriptionId: string) {
    return `${this.toolUsageServiceUrl}/workato-api/recipe/company/${companyId}/subscription/${subscriptionId}`;
  }

  public createSsoWorkatoIntegration(companyId: string, ssoName: string) {
    return `${this.toolUsageServiceUrl}/workato-api/recipe/company/${companyId}/sso/${ssoName}`;
  }

  public startSsoWorkatoIntegration(companyId: string, ssoName: string) {
    return `${this.toolUsageServiceUrl}/workato-api/recipe/company/${companyId}/sso/${ssoName}/start`;
  }

  public disconnectSsoWorkatoIntegration(companyId: string, ssoName: string) {
    return `${this.toolUsageServiceUrl}/workato-api/recipe/company/${companyId}/sso/${ssoName}`;
  }

  public getSsoWorkatoIntegration(companyId: string, ssoName: string) {
    return `${this.toolUsageServiceUrl}/workato-api/recipe/company/${companyId}/sso/${ssoName}`;
  }

  public getSsoToolsList(companyId: string, ssoName: string) {
    return `${this.toolUsageServiceUrl}/v2/sso-tools/company/${companyId}/sso/${ssoName}`;
  }

  public deleteRequest(requestId: string | number) {
    return `${this.serverUrl}requests/${requestId}`;
  }

  public getToolUsageVendors() {
    return `${this.serverUrl}tool-usage-vendors`;
  }

  public getToolUsageVendorById(id: string) {
    return `${this.serverUrl}tool-usage-vendors/${id}`;
  }

  public getToolUsageSsoProviders() {
    return `${this.serverUrl}tool-usage-sso-providers`;
  }

  public getToolUsageSsoProviderById(id: string) {
    return `${this.serverUrl}tool-usage-sso-providers/${id}`;
  }

  public getLicenseUsageV2(companyId: string, subscriptionId: string) {
    return `${this.toolUsageServiceUrl}/v2/users-activities/company/${companyId}/subscription/${subscriptionId}`;
  }

  public getUsersV2(companyId: string, subscriptionId: string) {
    return `${this.toolUsageServiceUrl}/v2/users/company/${companyId}/subscription/${subscriptionId}?days=90`;
  }

  public getRequestWorkflowTemplates() {
    return `${this.serverUrl}request/workflow/templates`;
  }

  public getRequestWorkflowsList() {
    return `${this.serverUrl}request/workflows`;
  }

  public createRequestWorkflow() {
    return `${this.serverUrl}request/workflows`;
  }

  public deleteRequestWorkflow(workflowId: string | number) {
    return `${this.serverUrl}request/workflows/${workflowId}`;
  }

  public activateTool() {
    return `${this.serverUrl}requests/activate`;
  }

  public updateRequestDocuments() {
    return `${this.serverUrl}requests/documents`;
  }

  public deleteRequestDocument(documentId: number) {
    return `${this.serverUrl}requests/documents/${documentId}`;
  }

  public reopenRequest(requestId: string | number) {
    return `${this.serverUrl}requests/reopen/${requestId}`;
  }

  public createSastrifySupportTicket() {
    return `${this.serverUrl}requests/support/ticket`;
  }

  public getRequestInitiativeTickets(requestId: string | number) {
    return `${this.freshdeskServiceUrl}freshdesk-service/api/requests/tickets/${requestId}`;
  }

  public getRenewalReminderGeneralSettings() {
    return `${this.serverUrl}companies/reminders`;
  }

  public getToolsRenewalSummary(startDate?: Date, endDate?: Date) {
    const searchParams = new URLSearchParams({
      endDate: endDate ? format(endDate, 'yyyy-MM-dd') : '',
      startDate: startDate ? format(startDate, 'yyyy-MM-dd') : '',
    });

    return `${this.serverUrl}subscriptions/tools/renewal-summary${
      startDate || endDate ? `?${searchParams.toString()}` : ''
    }`;
  }

  public updateRequestInitiativeDocuments() {
    return `${this.serverUrl}requests/support/ticket/documents`;
  }

  public createRequestInitiativeComment() {
    return `${this.serverUrl}requests/ticket/comment`;
  }

  public deleteRequestInvolvementComment(ticketId: number, commentId: string) {
    return `${this.serverUrl}requests/ticket/comment/${ticketId}/${commentId}`;
  }

  public requestBenchmark(productId: string) {
    return `${this.benchmarkingServiceUrl}/benchmarks/products/${productId}`;
  }

  public getBenchmarkVendors() {
    return `${this.benchmarkingServiceUrl}/vendors`;
  }

  public getBenchmarkProductsWithTiers(vendorId: string) {
    return `${this.benchmarkingServiceUrl}/vendors/${vendorId}/products`;
  }

  public getBenchmarkPaymentCycles() {
    return `${this.benchmarkingServiceUrl}/payment-cycles`;
  }

  public getBenchmarkCurrencies() {
    return `${this.benchmarkingServiceUrl}/currencies`;
  }

  public deactivateTool(requestId: number) {
    return `${this.serverUrl}requests/deactivate/${requestId}`;
  }

  public initConnectMicrosoftIntegration(companyId: string) {
    return `${this.microsoftIntegrationServiceUrl}microsoft-integration-service/api/integration-details/${companyId}/connection-init`;
  }

  public getMicrosoftConnectionStatus(companyId: string | number): string {
    return `${this.microsoftIntegrationServiceUrl}microsoft-integration-service/api/integration-details/${companyId}/state`;
  }

  public updateMicrosoftIntegration(companyId: string): string {
    return `${this.microsoftIntegrationServiceUrl}microsoft-integration-service/api/integration-details/${companyId}`;
  }

  public toggleMicrosoftUsageAnalytics(companyId: string): string {
    return `${this.microsoftIntegrationServiceUrl}microsoft-integration-service/api/integration-details/${companyId}/usage-analytics-flag`;
  }

  public addInitiativeTool() {
    return `${this.serverUrl}requests/multi/tool`;
  }

  public getRenewalStatusGeneralSettings() {
    return `${this.serverUrl}companies/placeholderurl`;
  }

  public companyRenewalNotificationChannelPreference() {
    return `${this.notificationServiceUrl}/api/company-renewal-channel-preference`;
  }

  public notificationPreferences() {
    return `${this.notificationServiceUrl}/api/preferences`;
  }

  public getSlackAuthUrl() {
    return `${this.serverUrl}slack-auth`;
  }

  public disconnectSlackAuth() {
    return `${this.serverUrl}slack-disconnect`;
  }

  public getDraftRequests() {
    return `${this.serverUrl}requests/draft`;
  }

  public saveWorkflowAsDraft() {
    return `${this.serverUrl}request/workflows/draft`;
  }

  public requestAddon(companyId: string) {
    return `${this.serverUrl}companies/${companyId}/upselling-request`;
  }

  public getDocumentLink(documentId: string) {
    return `${this.serverUrl}requests/documents/link/${documentId}`;
  }

  public jumpCloudConnect() {
    return `${this.jumpCloudIntegrationServiceUrl}/save-key`;
  }

  public getJumpCloudState(companyId: string) {
    return `${this.jumpCloudIntegrationServiceUrl}/integration-details/${companyId}/state`;
  }

  public updateJumpCloudStatus(companyId: string) {
    return `${this.jumpCloudIntegrationServiceUrl}/integration-details/${companyId}`;
  }

  public createProcurementSupportTicket() {
    return `${this.serverUrl}procurement/support/ticket`;
  }

  public getProcurementSupportTickets() {
    return `${this.freshdeskServiceUrl}freshdesk-service/api/procurement/support/tickets`;
  }

  public getProcurementSupportTicket(ticketId: number) {
    return `${this.freshdeskServiceUrl}freshdesk-service/api/procurement/support/tickets/${String(ticketId)}`;
  }

  public getProcurementSupportTicketComments(ticketId: number) {
    return `${this.serverUrl}procurement/support/ticket/${ticketId}/comment`;
  }

  public createProcurementSupportTicketComment() {
    return `${this.serverUrl}procurement/support/ticket/comment`;
  }

  public updateProcurementSupportTicketComment() {
    return `${this.serverUrl}procurement/support/ticket/comment`;
  }

  public deleteProcurementSupportTicketComment(ticketId: number, commentId: number) {
    return `${this.serverUrl}procurement/support/ticket/${ticketId}/comment/${commentId}`;
  }

  public updateProcurementSupportDocuments(ticketId: number) {
    return `${this.serverUrl}procurement/support/ticket/${String(ticketId)}/document`;
  }

  public updateProcurementSupportTicketFollowers() {
    return `${this.serverUrl}procurement/support/ticket/update-followers`;
  }

  public companyAuthenticationSettings(companyId: string) {
    return `${this.serverUrl}companies/${companyId}/authentication-settings`;
  }

  public markProcurementSupportCommentsAsRead(ticketId: number) {
    return `${this.serverUrl}procurement/support/ticket/${ticketId}/comment/read`;
  }

  public procurementSupportDuplicateCheck() {
    return `${this.freshdeskServiceUrl}freshdesk-service/api/procurement/support/duplicate-check`;
  }

  public getSSORequests() {
    return `${this.serverUrl}sso-requests`;
  }

  public requestEnforceSSO(authOption: AuthenticationOptionsType) {
    return `${this.serverUrl}sso-requests/${authOption}/request`;
  }

  public integrations() {
    return `${this.serverUrl}integrations`;
  }

  public integration(integrationId: string) {
    return `${this.serverUrl}integrations/${integrationId}`;
  }

  public integrationsKomboCreateLink() {
    return `${this.serverUrl}integrations/kombo/hris/connection/create-link`;
  }

  public integrationsKomboActivate() {
    return `${this.serverUrl}integrations/kombo/hris/activate`;
  }

  public integrationsKomboDisconnect() {
    return `${this.serverUrl}integrations/kombo/hris/disconnect`;
  }

  public toolMatchings() {
    return `${this.serverUrl}connected-system/entity-matches`;
  }

  public hrisGroup(types: HRISGroupType[]) {
    const query = types.map((type) => `type=${type}`).join('&');
    return `${this.serverUrl}hris/groups?${query}`;
  }

  public hrisGroupById(id: string) {
    return `${this.serverUrl}hris/groups/${id}`;
  }

  public hrisGroupTools(id: string, limit?: number) {
    const limitParam = limit ? `?limit=${limit}` : '';
    return `${this.serverUrl}hris/groups/${id}/tools${limitParam}`;
  }

  public hrisEmployee(sourceSastrifyIntegrationId?: string) {
    const queryParam = sourceSastrifyIntegrationId ? `?sourceSastrifyIntegrationId=${sourceSastrifyIntegrationId}` : '';
    return `${this.serverUrl}hris/employees${queryParam}`;
  }

  public hrisGroupEmployees(id: string) {
    return `${this.serverUrl}hris/employees?groupId=${id}`;
  }

  public hrisGroupManager(groupId: string) {
    return `${this.serverUrl}hris/groups/${groupId}/manager`;
  }

  public subscriptionHRISGroups(subscriptionId: string) {
    return `${this.serverUrl}subscriptions/${subscriptionId}/hris/groups`;
  }
}

export default new ApiUrl();
