import { Box, Divider, Typography } from '@mui/material';
import { SsoToolsList } from 'components/drawer-usage-analytics/drawer-usage-analytics-manage-sso/sso-tools-list.component';
import { useTranslation } from 'react-i18next';
import { SsoTool } from 'shared/models/tool-usage-analytics.model';

type ToolsListProps = {
  toolsList: SsoTool[];
  ssoProviderName: string;
  loading?: boolean;
};

export const ToolsList = ({ loading, ssoProviderName, toolsList }: ToolsListProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant='section' color='text.primary' sx={{ mb: 2 }}>
        {t('connect_view:tab_section.tabs.usage_analytics.manage_sso.tools_list_title', {
          ssoProviderName,
        })}
      </Typography>
      <Divider orientation='horizontal' textAlign='center' />
      <SsoToolsList tools={toolsList || []} loading={loading} />
    </Box>
  );
};
