import { useQuery } from 'react-query';
import { ReplyableType } from 'shared/common.definitions';
import { fetchReplyable } from 'shared/logic/replyable.logic';

export function useFetchReplies(commentId: string, onSuccess?: () => void, enabled = true) {
  return useQuery(
    [ReplyableType.SubscriptionComments, commentId],
    () => {
      return fetchReplyable(ReplyableType.SubscriptionComments, commentId);
    },
    {
      enabled,
      onSuccess,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}
