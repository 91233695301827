import { Badge, styled, Typography } from '@mui/material';
import { FC } from 'react';

import { StateType, TimelineComponentProps } from './timeline.component.props';

const Timeline = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 0',
  width: 'fit-content',
}));

const TimelineItem = styled('div')(() => ({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'row',
}));

const VerticalLine = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  height: '1.75rem',
  marginTop: '0.75rem',
  width: '2px',
}));

const ItemStep = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginRight: '0.75rem',
  marginTop: '0.75rem',
  width: '0.5rem',
}));

const ItemText = styled('div')(({ theme }) => ({
  '& .MuiTypography-h3': {
    margin: '0',
  },
  '& .MuiTypography-h3.small, & .MuiTypography-subtitle1.small': {
    fontSize: '0.875rem',
  },
  '& .MuiTypography-subtitle1, & .MuiTypography-small': {
    color: theme.palette.text.secondary,
  },
  display: 'flex',
  flexDirection: 'column',
}));

const Dot = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    borderRadius: '50%',
    height: '0.75rem',
    width: '0.75rem',
  },
  '&.actionRequired .MuiBadge-badge': {
    backgroundColor: theme.palette.tertiary.main,
  },
  '&.current .MuiBadge-badge': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '&.error .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
  },
  '&.main .MuiBadge-badge': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&.pending .MuiBadge-badge': {
    backgroundColor: theme.palette.info.main,
  },
  '&.success .MuiBadge-badge': {
    backgroundColor: theme.palette.success.main,
  },
}));

export const TimelineComponent: FC<TimelineComponentProps> = ({ items, size = 'default' }) => {
  const totalNumberOfItems = items.length;
  const isSmallSize = size === 'small';

  return (
    <Timeline>
      {items.map(({ active, id, state = 'main', subtitle, title }, index) => {
        const isNotLastElement = index + 1 < totalNumberOfItems;
        const getDotStateClassName = (state: StateType | undefined) => {
          let className = '';
          switch (state) {
            case 'actionRequired':
              className = 'actionRequired';
              break;
            case 'current':
              className = 'current';
              break;
            case 'pending':
              className = 'pending';
              break;
            case 'error':
              className = 'error';
              break;
            case 'success':
              className = 'success';
              break;
            default:
              className = 'main';
          }

          return className;
        };

        return (
          <TimelineItem key={id}>
            <ItemStep>
              <Dot data-testid={`timeline-dot-${id}`} variant='dot' className={`${getDotStateClassName(state)}`} />
              {isNotLastElement && <VerticalLine />}
            </ItemStep>
            <ItemText>
              <Typography variant={active ? 'h3' : 'subtitle1'} className={`${isSmallSize ? 'small' : ''}`}>
                {title}
              </Typography>
              {subtitle && <Typography variant='small'>{subtitle}</Typography>}
            </ItemText>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
