import subscriptionSortByData from 'assets/data/subscription-sort.data.json';
import { AxiosResponse } from 'axios';
import { differenceInDays } from 'date-fns';
import { BillingFrequencyOptions, DEFAULT_CURRENCY } from 'libs/enums';
import { DEFAULT_STATE_OPTIONS } from 'libs/enums/subscription.enum';
import { QueryFunctionContext, QueryKey } from 'react-query';
import { Alert, BaseItem, PotentialSavings, Subscription, TodoAndStatistics } from 'shared/models';
import { DateRange } from 'shared/models/daterange.model';

import { MatchedVendors } from '../../app/views/tools-spend-importer/models/ReviewVendors';
import {
  billingFrequencyOptions,
  CommentsFilter,
  INVALID_DATE_TEXT,
  MissingDocument,
  OpenTodoCategoryToFilter,
  OpenTodoFilter,
  OpenTodoFilterArray,
  RenewalFrequencyFilter,
  SubscriptionStates,
  SubscriptionStatus,
  TodoCategories,
  TodoStates,
  ToolAccessLevelFilter,
  ToolOwnerFilter,
} from '../common.definitions';
import { formatCurrency } from '../helpers/common.helper';
import { getImportSourceName, mapImportedVendors, mapImportedVendorsSpends } from '../helpers/subscriptions.helper';
import { SortOption, ToolSummaryData } from '../models/subscription.model';
import { SubscriptionHighestSpendModel } from '../models/subscription-highest-spend.model';
import { VendorType } from '../models/vendor.model';
import { apiService, apiUrl } from '../services';
import { formatSubscription, getDetectedSpend } from './subscription-item.logic';

export type NewSubscription = {
  name?: string;
  lastYearCost?: number;
  currentYearCost?: number;
  billingFrequency?: number;
  renewalDate?: string;
  note?: string;
  vendorId?: string;
  tags?: Array<string>;
  budgetCents?: number;
  ownerId?: string;
  budgetCurrency?: string;
  ownerName?: string;
  state?: number;
  decisionDate?: string;
  comment?: string;
  otherBillingFrequencyType?: 'years' | 'months';
  otherBillingFrequency?: number;
  renewalInterval?: {
    years?: number;
    months?: number;
  } | null;
};

type FilterType = {
  [key: string]: unknown;
};

export const hasPendingSastrifyTasks = (subscription: Subscription | undefined) =>
  subscription?.todoStatistics &&
  (subscription?.todoStatistics?.totalSastrifyCoveredBadgeTodos || 0) > 0 &&
  (subscription?.todoStatistics?.completedSastrifyCoveredBadgeTodos || 0) <
    (subscription?.todoStatistics?.totalSastrifyCoveredBadgeTodos || 0);

export const getSubscriptionSastrifyCoveredProgress = (subscription: Subscription | undefined) =>
  subscription &&
  ((subscription?.todoStatistics?.completedSastrifyCoveredBadgeTodos || 0) /
    (subscription?.todoStatistics?.totalSastrifyCoveredBadgeTodos || 0) || 0) * 100;

export const fetchSubscriptions = async (params: QueryFunctionContext<QueryKey>): Promise<Subscription[]> => {
  const url = !params.queryKey?.[1] ? apiUrl.Subscriptions : apiUrl.SubscriptionsInDiscovery;
  const { data }: AxiosResponse = await apiService.get(url);
  return data.map((subscription: Subscription) => {
    return formatSubscription(subscription);
  });
};

export const fetchViewOnlySubscriptions = async (
  params: QueryFunctionContext<QueryKey>
): Promise<Subscription[] | undefined> => {
  const url = !params.queryKey?.[1] ? apiUrl.SubscriptionsViewOnly : apiUrl.SubscriptionsViewOnlyInDiscovery;
  const { data }: AxiosResponse = await apiService.get(url);
  return data.map((subscription: Subscription) => {
    return formatSubscription(subscription, true);
  });
};

export const fetchAllSubscriptionsAndNewSolutions = async (): Promise<Subscription[]> => {
  const { data }: AxiosResponse = await apiService.get(apiUrl.SubscriptionsAndNewSolutions);
  return data.map((subscription: Subscription) => {
    return formatSubscription(subscription);
  });
};

export const fetchAllAlerts = async (): Promise<Alert[]> => {
  const { data } = await apiService.get(apiUrl.Alerts);
  return data;
};

export const fetchUpcomingAlerts = async (): Promise<Alert[]> => {
  const { data } = await apiService.get(apiUrl.UpcomingAlerts);
  return data;
};

export const postSubscription = async (subscription: NewSubscription): Promise<Subscription> => {
  const response = await apiService.post(apiUrl.Subscriptions, {
    ...subscription,
    billingFrequency: Number(subscription.billingFrequency),
  });

  return formatSubscription(response.data as Subscription);
};

export const bulkUpdateSubscriptionsStateAndOwner = async ({
  comment,
  ownerId,
  state,
  subscriptionIds,
}: {
  subscriptionIds: string[];
  state?: DEFAULT_STATE_OPTIONS;
  ownerId?: string;
  comment?: string;
}): Promise<boolean> => {
  try {
    await apiService.patch(apiUrl.SubscriptionsDetails, {
      comment,
      ownerId,
      state,
      subscriptionIds,
    });

    return true;
  } catch (error) {
    return false;
  }
};

export const postNewSolution = async (newSolution: NewSubscription): Promise<Subscription> => {
  const response = await apiService.post(apiUrl.NewSolutions, newSolution);

  return formatSubscription(response.data as Subscription);
};

const filterSubscriptionByOpenTodos = (subscription: Subscription): boolean => {
  if (subscription.todos && subscription.todos.length > 0) {
    return subscription.todos.some(
      (todo) =>
        (todo.state === TodoStates.OPEN && todo.category === TodoCategories.RENEWAL_DATE) ||
        (todo.state === TodoStates.OPEN && todo.category === TodoCategories.UPLOAD_INVOICE) ||
        (todo.state === TodoStates.OPEN && todo.category === TodoCategories.CUSTOM_TODO) ||
        (todo.state === TodoStates.OPEN && todo.category === TodoCategories.ASSIGN_TOOL_OWNER) ||
        (todo.state === TodoStates.OPEN && todo.category === TodoCategories.ONBOARDING_QUESTIONNAIRE) ||
        (todo.state === TodoStates.OPEN && todo.category === TodoCategories.SAVINGS_SUGGESTION)
    );
  }
  return false;
};

type FilterOptions = {
  searchString?: string;
  activeSubscriptions?: Subscription[];
};

export const filterSubscriptions = (filterOptions: FilterOptions): Array<Subscription> | undefined => {
  const { activeSubscriptions, searchString } = filterOptions;

  if (!searchString) {
    return activeSubscriptions;
  }

  const formattedSearchString = searchString.toLowerCase();

  return activeSubscriptions?.filter(
    (item) =>
      item.categoryName?.toString().toLowerCase().includes(formattedSearchString) ||
      item.note?.toString().toLowerCase().includes(formattedSearchString) ||
      item.ownerName?.toString().toLowerCase().includes(formattedSearchString) ||
      item.tags?.join(' ').toString().toLowerCase().includes(formattedSearchString) ||
      item.name?.toString().toLowerCase().includes(formattedSearchString) ||
      item.vendorName?.toString().toLowerCase().includes(formattedSearchString)
  );
};

export const getHighestSubscriptionCost = (subscription: Subscription[]): number => {
  const highestSubscriptionCost = subscription
    ?.flatMap((value) => [value.lastYearCost, value.currentYearCost])
    .reduce((previousValue, currentValue): number => {
      if (Number(previousValue) < Number(currentValue)) {
        previousValue = currentValue;
      }
      return Number(previousValue);
    }, 0);
  return highestSubscriptionCost as number;
};

const filterByDateRange = (
  filteredData: Subscription[],
  dateRange: DateRange,
  dataProperty: 'renewalDate' | 'decisionDate'
) => {
  const filteredResult = filteredData?.filter((item) => {
    if (item[dataProperty]) {
      const date = new Date(`${item[dataProperty]}T00:00:00`);
      if (String(dateRange.from) === INVALID_DATE_TEXT) {
        return date <= dateRange.to;
      }
      if (String(dateRange.to) === INVALID_DATE_TEXT) {
        return date >= dateRange.from;
      }
      return date >= dateRange.from && date <= dateRange.to;
    }
    return null;
  });

  return filteredResult;
};

const modifiedDate = (value: string | Date) => {
  if (!value) {
    return;
  }
  const filterValueDecisionDate = new Date(value);

  const decisionDate = filterValueDecisionDate.getDate();
  const decisionDateMonth = filterValueDecisionDate.getMonth();
  const decisionDateYear = filterValueDecisionDate.getFullYear();

  const modifiedFilterValueDecisionDate = new Date(
    `${decisionDateYear}-0${decisionDateMonth + 1}-${decisionDate}`
  ).toISOString();

  return modifiedFilterValueDecisionDate;
};

export const filterSubscriptionByFilter = (
  filters: Array<string[]>,
  data: Subscription[]
): Subscription[] | undefined => {
  let filteredData = data;

  const filterValues = filters.reduce((accumulator: FilterType, currentValue) => {
    const key = currentValue[0];
    const value = currentValue[1];
    accumulator[key] = value;

    return accumulator;
  }, {});

  if (filterValues.openTask) {
    let openTask = filterValues.openTask as OpenTodoFilterArray;

    if (typeof openTask === 'string') {
      openTask = (openTask as string).split(',') as OpenTodoFilterArray;
    }

    if (openTask.includes(OpenTodoFilter.ALL)) {
      filteredData = filteredData.filter(filterSubscriptionByOpenTodos);
    } else {
      filteredData = filteredData.filter((item) => {
        return openTask.some((task) => {
          return item.todos?.some((todo) => {
            return task === OpenTodoCategoryToFilter[todo.category] && todo.state === TodoStates.OPEN;
          });
        });
      });
    }
  }

  if (filterValues.toolOwner) {
    const { toolOwner } = filterValues;
    if (!Number(toolOwner)) {
      if (toolOwner === ToolOwnerFilter.NOT_ASSIGNED) {
        filteredData = filteredData.filter((item) => !item.ownerName);
      } else if (toolOwner === ToolOwnerFilter.WITHOUT_INVITATION) {
        filteredData = filteredData.filter((item) => !item.ownerId && item.ownerName);
      } else {
        const toolOwnerName = String(toolOwner).replaceAll('-', ' ');
        filteredData = filteredData.filter((item) => !item.ownerId && item.ownerName === toolOwnerName);
      }
    } else {
      filteredData = filteredData.filter((item) => item.ownerId === filterValues.toolOwner);
    }
  }

  if (filterValues.requester) {
    const { requester } = filterValues;
    if (!Number(requester)) {
      if (requester === ToolOwnerFilter.NOT_ASSIGNED) {
        filteredData = filteredData.filter((item) => !item.creatorName);
      } else if (requester === ToolOwnerFilter.WITHOUT_INVITATION) {
        filteredData = filteredData.filter((item) => !item.creatorId && item.creatorName);
      } else {
        const requesterName = String(requester).replaceAll('-', ' ');
        filteredData = filteredData.filter((item) => !item.creatorId && item.creatorName === requesterName);
      }
    } else {
      filteredData = filteredData.filter((item) => item.creatorId === filterValues.requester);
    }
  }

  if (filterValues.status) {
    const { status } = filterValues;
    if (Number(status) === 0) {
      filteredData = filteredData.filter((item) => item.state === Number(status) || !item.state);
    } else {
      filteredData = filteredData.filter((item) => item.state === Number(status));
    }
  }

  if (filterValues.renewalFrequency) {
    const { renewalFrequency } = filterValues;
    if (renewalFrequency === RenewalFrequencyFilter.MONTHLY) {
      filteredData = filteredData.filter((item) => item.billingFrequency === BillingFrequencyOptions.MONTHLY);
    } else if (renewalFrequency === RenewalFrequencyFilter.YEARLY) {
      filteredData = filteredData.filter((item) => item.billingFrequency === BillingFrequencyOptions.YEARLY);
    } else if (renewalFrequency === RenewalFrequencyFilter.CUSTOM) {
      filteredData = filteredData.filter((item) => item.billingFrequency === BillingFrequencyOptions.CUSTOM);
    } else if (renewalFrequency === RenewalFrequencyFilter.OTHER) {
      filteredData = filteredData.filter((item) => item.billingFrequency === BillingFrequencyOptions.OTHER);
    }
  }

  if (filterValues.renewalDate) {
    const [from, to] = filterValues.renewalDate as Date[];
    let dateRange = {
      from: new Date(from),
      to: new Date(to),
    };

    if (typeof filterValues.renewalDate === 'string') {
      dateRange = {
        from: new Date(filterValues.renewalDate.split(',')[0]),
        to: new Date(filterValues.renewalDate.split(',')[1]),
      };
    }

    filteredData = filterByDateRange(filteredData, dateRange, 'renewalDate');
  }

  type DateRangeStrings = {
    from?: string;
    to?: string;
  };

  if (filterValues.decisionDate) {
    let dateRange: DateRangeStrings = {};

    if (typeof filterValues.decisionDate === 'string') {
      dateRange = {
        from: modifiedDate(filterValues.decisionDate.split(',')[0]),
        to: modifiedDate(filterValues.decisionDate.split(',')[1]),
      };
    }
    if (typeof filterValues.decisionDate === 'object') {
      const [from, to] = filterValues.decisionDate as Date[];
      dateRange = {
        from: modifiedDate(from),
        to: modifiedDate(to),
      };
    }

    filteredData = filteredData?.filter((item) => {
      if (item.decisionDate) {
        const date = new Date(`${item.decisionDate}`).toISOString();

        if (String(dateRange.from) === INVALID_DATE_TEXT) {
          return date <= String(dateRange?.to);
        }
        if (String(dateRange.to) === INVALID_DATE_TEXT) {
          return date >= String(dateRange?.from);
        }
        return date >= String(dateRange?.from) && date <= String(dateRange?.to);
      }
      return null;
    });
  }

  if (filterValues.missingDocument) {
    const missingDocuments = Array.isArray(filterValues.missingDocument)
      ? filterValues.missingDocument
      : (filterValues.missingDocument as string).split(',');

    filteredData = filteredData.filter((item) => {
      return missingDocuments.some((doc: MissingDocument[number]) => {
        return item.documentsCategoryCount && item.documentsCategoryCount[doc] === 0;
      });
    });
  }

  if (filterValues.withComments) {
    const { withComments } = filterValues;

    if (withComments === CommentsFilter.NO_COMMENTS) {
      filteredData = filteredData.filter((item) => item.commentsCount === 0);
    } else if (withComments === CommentsFilter.ALL_COMMENTS) {
      filteredData = filteredData.filter((item) => item.commentsCount && item.commentsCount > 0);
    }
  }

  if (filterValues.tags) {
    const tags = filterValues.tags as string;
    const tagsArray = tags.split(',');
    const filteredDataForTags: Subscription[] = [];

    filteredData.forEach((data) => {
      if (data.tags) {
        tagsArray.forEach((tag) => {
          if (data?.tags?.includes(tag)) {
            filteredDataForTags.push(data);
          }
        });
      }
    });

    filteredData = filteredDataForTags;
  }

  if (filterValues.spend) {
    let spend: number[] = filterValues.spend as number[];
    if (typeof filterValues.spend === 'string') {
      const spendFrom = Number(filterValues.spend.split(',')[0]);
      const spendTo = Number(filterValues.spend.split(',')[1]);
      spend = [spendFrom, spendTo];
    }

    filteredData = filteredData.filter((item) => {
      const { currentYearCost } = item;
      const [spendFrom, spendTo] = spend;

      const convertedSpendFrom = spendFrom * 100;
      const convertedSpendTo = spendTo * 100;

      const currentYearCostFilterCondition =
        Number(currentYearCost) >= convertedSpendFrom && Number(currentYearCost) <= convertedSpendTo;

      return currentYearCostFilterCondition;
    });
  }

  if (filterValues.subscriptionAccessLevel) {
    if (filterValues.subscriptionAccessLevel === ToolAccessLevelFilter.ONLY_TOOLS_WITH_ACCESS) {
      filteredData = filteredData.filter((item) => !item.viewOnly);
    }
  }

  if (typeof filterValues.imported === 'string') {
    if (filterValues.imported === '*') {
      filteredData = filteredData.filter((item) => !!item.importSource || !!item.importSubsystem);
    } else {
      const source = filterValues.imported;
      filteredData = filteredData.filter((item) => item.importSource === source);
    }
  }

  return filteredData;
};

export const getSummaryDetails = (
  data?: Subscription[],
  displayData?: Subscription[],
  hideDecimal = false
): {
  currentYearTotal: string | undefined;
  currentYearTotalFiltered: string | undefined;
  lastYearTotal: string | undefined;
  lastYearTotalFiltered: string | undefined;
} => {
  const hasFilter = data?.length !== displayData?.length;

  let currency = data?.find((subscription) => subscription?.lastYearCostCurrency)?.lastYearCostCurrency;

  if (!currency) {
    currency =
      data?.find((subscription) => subscription?.currentYearCostCurrency)?.currentYearCostCurrency ?? DEFAULT_CURRENCY;
  }

  const currentYearTotal = data?.reduce<number>(
    (sum: number, subscription: Subscription) => sum + (subscription?.currentYearCost ?? 0),
    0
  );
  const currentYearTotalFiltered = displayData?.reduce<number>(
    (sum: number, subscription: Subscription) => sum + (subscription?.currentYearCost ?? 0),
    0
  );
  const lastYearTotal = data?.reduce<number>(
    (sum: number, subscription: Subscription) => sum + (subscription?.lastYearCost ?? 0),
    0
  );
  const lastYearTotalFiltered = displayData?.reduce<number>(
    (sum: number, subscription: Subscription) => sum + (subscription?.lastYearCost ?? 0),
    0
  );

  const getFormattedValue = (amount?: number) =>
    formatCurrency({
      currency,
      hideDecimal,
      isDecimal: true,
      style: 'currency',
      value: amount ?? 0,
    });

  const values = {
    currentYearTotal: getFormattedValue(currentYearTotal),
    currentYearTotalFiltered: hasFilter ? getFormattedValue(currentYearTotalFiltered) : undefined,
    lastYearTotal: getFormattedValue(lastYearTotal),
    lastYearTotalFiltered: hasFilter ? getFormattedValue(lastYearTotalFiltered) : undefined,
  };

  return values;
};

const getSubscriptionFieldNameValue = (fieldName: string, subscription: Subscription) => {
  if (fieldName === 'renewalDate') {
    return billingFrequencyOptions[Number(subscription.billingFrequency)] === 'monthly'
      ? null
      : Object.entries(subscription).find((item) => item[0] === fieldName)?.[1];
  }

  if (fieldName === 'name') {
    const sortByName = subscription.name ? 'name' : 'vendorName';
    return Object.entries(subscription).find((item) => item[0] === sortByName)?.[1];
  }

  if (fieldName === 'detectedSpend') {
    return getDetectedSpend(subscription);
  }

  if (fieldName === 'importSource') {
    return getImportSourceName(subscription);
  }

  return Object.entries(subscription).find((item) => item[0] === fieldName)?.[1];
};

export const sortSubscriptions = (
  data: Subscription[] = [],
  { sortById, sortOption }: { sortById?: string; sortOption?: SortOption }
): Array<Subscription> | undefined => {
  if ((!sortById || sortById === '0') && !sortOption) return data;

  let sortBy: SortOption | Record<string, string | undefined> | undefined;

  if (sortById) {
    const sortData: BaseItem | undefined = subscriptionSortByData.find((item: BaseItem) => item.id === sortById);

    if (sortData) {
      sortBy = { field: sortData.field, order: sortData.order, type: sortData.type };
    }
  } else {
    sortBy = sortOption;
  }

  const fieldName = sortBy?.field ?? '';

  if (fieldName) {
    const sorted = data?.sort((previousSubscription, currentSubscription) => {
      const currentValue = getSubscriptionFieldNameValue(fieldName, currentSubscription);
      const previousValue = getSubscriptionFieldNameValue(fieldName, previousSubscription);

      const type = sortBy?.type ?? typeof currentValue;

      if (!currentValue && currentValue !== 0) {
        return -1;
      }

      if (!previousValue && previousValue !== 0) {
        return 1;
      }

      if (type === 'number') {
        if (sortBy?.order === 'desc') {
          return Number(currentValue) - Number(previousValue);
        }
        return Number(previousValue) - Number(currentValue);
      }
      if (type === 'date') {
        if (sortBy?.order === 'desc') {
          return new Date(String(currentValue)).valueOf() > new Date(String(previousValue)).valueOf() ? 1 : -1;
        }
        return new Date(String(previousValue)).valueOf() > new Date(String(currentValue)).valueOf() ? 1 : -1;
      }
      const orderKey = type === 'string' ? -1 : 0;

      if (sortBy?.order === 'desc') {
        return currentValue.toString().toLowerCase().trim() > previousValue.toString().toLowerCase().trim()
          ? 1
          : orderKey;
      }
      return previousValue.toString().toLowerCase().trim() > currentValue.toString().toLowerCase().trim()
        ? 1
        : orderKey;
    });

    return sorted;
  }
};

export function fetchHighestSpendSubscriptions(): Promise<SubscriptionHighestSpendModel[]> {
  return apiService.get(apiUrl.HighestSpendSubscriptions).then((response) => {
    if (response.status === 204) {
      return [];
    }

    return response.data;
  });
}

export const fetchVendors = async (searchQuery?: string, isExploreQuery = false): Promise<VendorType[]> => {
  const baseUrl = `${apiUrl.Vendors}/search`;
  const queryUrl = !searchQuery
    ? baseUrl
    : `${baseUrl}?${isExploreQuery ? 'explore' : 'name'}=${encodeURIComponent(searchQuery)}`;
  return (await apiService.get(queryUrl)).data;
};

export const calculateTotalPotentialSavings = (data: PotentialSavings[], currency = 'EUR'): string => {
  const estimatedTotalAmount = data.reduce((accumulator, item) => {
    if (item.savingPotentialCents) {
      return accumulator + item?.savingPotentialCents;
    }
    return accumulator;
  }, 0);

  const formattedAmount = formatCurrency({ currency, isDecimal: true, style: 'currency', value: estimatedTotalAmount });

  if (formattedAmount) {
    return formattedAmount;
  }

  return '';
};

export const formatPotentialSavings = (data: PotentialSavings[], currency = 'EUR'): PotentialSavings[] => {
  const potentialSavings = data.map((item: PotentialSavings) => {
    const { savingPotentialCents } = item;

    const formattedAmount = formatCurrency({
      currency,
      isDecimal: true,
      style: 'currency',
      value: savingPotentialCents ?? 0,
    });

    return { ...item, savingsPotential: formattedAmount };
  });

  return sortPotentialSavings(potentialSavings);
};

const sortPotentialSavings = (data: PotentialSavings[]): PotentialSavings[] => {
  const newPotentialSavings = data
    .filter((item) => item.state === 0)
    .sort(
      (a: PotentialSavings, b: PotentialSavings) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
    );

  const inProgressPotentialSavings = data
    .filter((item) => item.state === 1)
    .sort(
      (a: PotentialSavings, b: PotentialSavings) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
    );

  return [...newPotentialSavings, ...inProgressPotentialSavings];
};

export const getUnassignedSubscriptions = (subscriptions?: Subscription[]): Subscription[] => {
  if (subscriptions?.length) {
    return subscriptions.filter((subscription) => subscription.ownerName === null);
  }
  return [];
};

export const getAlertsDueInTwoWeeks = (alerts?: Alert[]): Alert[] => {
  if (!alerts) {
    return [];
  }

  const alertsWithDueDates = alerts
    .sort((a: Alert, b: Alert) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    .map((alert) => {
      const alertDate = new Date(alert.date);
      alertDate.setHours(0, 0, 0, 0);

      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);

      const days = differenceInDays(alertDate, todayDate);
      return { ...alert, dueDate: days };
    });

  return alertsWithDueDates.filter((alert) => {
    return alert.dueDate > 0 && alert.dueDate <= 14;
  });
};

export const getAlertFormattedDueDate = (date?: number): string => {
  if (!date) {
    return '';
  }

  if (date === 1) return 'Due in 1 day';
  if (date === 7) return 'Due in 1 week';
  if (date === 14) return 'Due in 2 weeks';
  return `Due in ${date} days`;
};

export const checkToolStatus = (tool?: VendorType, tools?: Subscription[]): Partial<Subscription> | undefined => {
  if (!tool || !tools || (Array.isArray(tools) && tools.length === 0)) {
    return undefined;
  }

  return tools.find(
    (subscription: Subscription) =>
      subscription.vendorId === tool.id &&
      [...SubscriptionStates, SubscriptionStatus.rejected, SubscriptionStatus.requested].includes(
        subscription.state as SubscriptionStatus
      )
  );
};

export const fetchToolSummary = (): Promise<ToolSummaryData> => {
  return apiService.get(apiUrl.ToolSummary()).then((response: AxiosResponse<ToolSummaryData>) => {
    return response.data;
  });
};

export const getSubscriptionsTodos = async () => {
  const response = await apiService.get(apiUrl.SubscriptionsTodos());
  return response.data as TodoAndStatistics[];
};

export const importSubscriptions = async (reviewVendorsResult: MatchedVendors[]) => {
  const importedVendorsList = mapImportedVendors(reviewVendorsResult);
  await apiService.post(apiUrl.ImportSubscriptions, importedVendorsList);
};

export const importSubscriptionsSpends = async (reviewVendorsResult: MatchedVendors[]) => {
  const importedVendorsSpendsList = mapImportedVendorsSpends(reviewVendorsResult);
  await apiService.post(apiUrl.ImportSubscriptionsSpends, importedVendorsSpendsList);
};
