import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton, TextField, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { DatePickerComponent, TypeaheadComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { currentDate, TodoCategories } from 'shared/common.definitions';
import { normalizeDateToUTC } from 'shared/helpers/dates.helper';
import {
  createSubscriptionTodo,
  getSubscriptionName,
  updateSubscriptionTodo,
} from 'shared/logic/subscription-item.logic';
import { sendTaskCreatedEvent, sendTaskEditedEvent } from 'shared/logic/track-events.logic';
import { SastrifyStore, Subscription, ToolOwnerFilter, UpdateSubscriptionTodoState, User } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import * as yup from 'yup';

import { CustomTaskFormProps } from '.';
import { CustomTaskFormWrapper } from './custom-task-form.styles';

const schema = yup.object().shape({
  description: yup.string(),
  title: yup.string().required(),
});

type ToolOwner = { avatar?: string; id?: string; name: string; value?: string };

export const CustomTaskForm = forwardRef((props: CustomTaskFormProps, ref) => {
  const { closeModal, initialData, showNotification, subscription } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const subscriptionId = subscription?.id;

  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState<ToolOwnerFilter>({ avatar: '', id: '', name: '' });
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dueDateError, setDueDateError] = useState(false);

  const usersAndToolOwnersList = queryClient.getQueryData<User[]>('tool-owners');

  const user = useSelector((state: SastrifyStore) => state.authentication.user);

  const trackCustomTaskEvents = (subscription: Subscription) => {
    const traits = {
      subscriptionName: getSubscriptionName(subscription) as string,
    };

    if (!initialData) {
      sendTaskCreatedEvent(traits);
    } else {
      sendTaskEditedEvent(traits);
    }
  };

  const { mutate } = useMutation(initialData ? updateSubscriptionTodo : createSubscriptionTodo, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    onSettled: () => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    },
    onSuccess: () => {
      showNotification?.(
        !initialData
          ? t('common:modals.add_custom_task_feature_modal.success_message')
          : t('common:modals.add_custom_task_feature_modal.update_success_message'),
        NotificationAlertType.Success
      );
      queryClient.invalidateQueries('subscriptionTodos');
      queryClient.invalidateQueries('subscriptions-todos');
      queryClient.invalidateQueries(['subscription', subscriptionId]);

      trackCustomTaskEvents(subscription as Subscription);

      closeModal?.();
    },
  });

  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList.map((user: User) => ({
        avatar: user?.avatar,
        name: user.name,
        value: user.id || user.name?.replaceAll(' ', '-'),
      }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  const {
    control,
    formState: { isValid },
    getValues,
    register,
  } = useForm({
    defaultValues: {
      description: initialData?.message,
      dueDate: initialData?.dueDate ? new Date(initialData?.dueDate) : undefined,
      title: initialData?.title,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialData?.assignedToName) {
      setToolOwnerName(initialData.assignedToName);
      setToolOwner({
        avatar: initialData.assignedToAvatarUrl,
        id: initialData.assignedToId,
        name: initialData.assignedToName,
      });
    } else if (!initialData && subscription) {
      const ownerName = subscription?.ownerName ? subscription?.ownerName : user?.name;
      const ownerId = subscription?.ownerId ? subscription?.ownerId : user?.id;
      const ownerAvatarUrl = subscription?.ownerAvatarUrl ? subscription?.ownerAvatarUrl : user?.avatarUrl;

      setToolOwnerName(ownerName as string);
      setToolOwner({
        avatar: ownerAvatarUrl,
        id: String(ownerId),
        name: String(ownerName),
      });
    }
  }, [initialData, subscription, user]);

  useEffect(() => {
    if (!isValid) dispatch(updateFooterState({ isFormValid: isValid }));
    return () => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    };
  }, [dispatch, isValid]);

  const onSubmit = () => {
    dispatch(updateFooterState({ isFormSubmitting: true }));
    const formValue = getValues();
    const payload = {
      assignedToId: toolOwner.id,
      category: initialData?.category || TodoCategories.USER_CUSTOM_TODO,
      dueDate: normalizeDateToUTC(formValue.dueDate) || null,
      message: formValue.description,
      subscriptionId,
      title: formValue.title,
      todoId: initialData?.id,
    };

    if (!toolOwner.id) {
      delete payload.assignedToId;
    }

    if (!initialData?.id) {
      delete payload.todoId;
    }

    mutate(payload as UpdateSubscriptionTodoState);
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner({ avatar: item?.avatar, id: item.value, name: item.name });
    }
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleClearButtonClicked = () => {
    setToolOwner({ avatar: '', id: '', name: '' });
    setToolOwnerName('');
  };

  return (
    <CustomTaskFormWrapper>
      <section className='input-section'>
        <Typography variant='h3' className='title-spacing'>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.title_label_text')}
        </Typography>
        <Typography variant='body' paragraph>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.title_sub_label_text')}
        </Typography>
        <TextField
          id='title'
          variant='outlined'
          size='small'
          label={t('common:modals.add_custom_task_feature_modal.body_section.form.title_placeholder_text')}
          {...register('title')}
        />
      </section>

      <section className='input-section'>
        <Typography variant='h3' className='title-spacing'>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.description_label_text')}
        </Typography>
        <Typography variant='body' paragraph>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.description_sub_label_text')}
        </Typography>
        <TextField
          id='description'
          variant='outlined'
          size='small'
          label={t('common:modals.add_custom_task_feature_modal.body_section.form.description_placeholder_text')}
          multiline
          rows={3}
          {...register('description')}
        />
      </section>

      <section className='input-section'>
        <Typography variant='h3' className='title-spacing'>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.assignee_label_text')}
        </Typography>
        <Typography variant='body' paragraph>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.assignee_sub_label_text')}
        </Typography>
        <TypeaheadComponent
          data={toolOwners}
          onItemSelected={onToolOwnerSelected}
          onBlurToolOwnerFilter={onToolOwnerSelected}
          value={toolOwnerName}
          fields={['name', 'email']}
          isToolOwnerFilter
          onTextChanged={onChangeToolOwner}
          isSubscriptionInDiscovery
          rightIcon={
            <IconButton onClick={handleClearButtonClicked} onMouseDown={(e) => e.preventDefault()}>
              <Icon color='error'>clear</Icon>
            </IconButton>
          }
        />
      </section>

      <section className='input-section'>
        <Typography variant='h3' className='title-spacing'>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.due_date_label_text')}
        </Typography>
        <Typography variant='body' paragraph>
          {t('common:modals.add_custom_task_feature_modal.body_section.form.due_date_sub_label_text')}
        </Typography>
        <Controller
          name='dueDate'
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePickerComponent
              value={value}
              onChange={(newValue) => {
                setOpenDatePicker(false);
                const isDateInThePast = (newValue as Date)?.getTime() < currentDate.getTime();
                if (isDateInThePast) {
                  setDueDateError(true);
                } else {
                  setDueDateError(false);
                }
                onChange(newValue);
              }}
              openDatePicker={openDatePicker}
              setOpenDatePicker={setOpenDatePicker}
              minDate={new Date()}
              placeholder={t('common:modals.add_custom_task_feature_modal.body_section.form.due_date_placeholder_text')}
              helperText={
                dueDateError
                  ? t('common:modals.add_custom_task_feature_modal.body_section.form.due_date_input_helper_text')
                  : undefined
              }
            />
          )}
        />
      </section>
    </CustomTaskFormWrapper>
  );
});
