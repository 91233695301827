import { FC } from 'react';
import { colors } from 'shared/theme';

export const ClipboardDeletedIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.91668 2.33334H9.22834C9.10799 1.99293 8.8853 1.69809 8.5908 1.48922C8.29629 1.28035 7.94439 1.16769 7.58334 1.16667H6.41668C6.05563 1.16769 5.70373 1.28035 5.40922 1.48922C5.11472 1.69809 4.89203 1.99293 4.77168 2.33334H4.08334C3.61921 2.33334 3.1741 2.51771 2.84591 2.8459C2.51772 3.17409 2.33334 3.61921 2.33334 4.08334V11.0833C2.33334 11.5475 2.51772 11.9926 2.84591 12.3208C3.1741 12.649 3.61921 12.8333 4.08334 12.8333H9.91668C10.3808 12.8333 10.8259 12.649 11.1541 12.3208C11.4823 11.9926 11.6667 11.5475 11.6667 11.0833V4.08334C11.6667 3.61921 11.4823 3.17409 11.1541 2.8459C10.8259 2.51771 10.3808 2.33334 9.91668 2.33334ZM5.83334 2.91667C5.83334 2.76196 5.8948 2.61359 6.0042 2.50419C6.11359 2.3948 6.26197 2.33334 6.41668 2.33334H7.58334C7.73805 2.33334 7.88643 2.3948 7.99582 2.50419C8.10522 2.61359 8.16668 2.76196 8.16668 2.91667V3.50001H5.83334V2.91667ZM10.5 11.0833C10.5 11.238 10.4386 11.3864 10.3292 11.4958C10.2198 11.6052 10.0714 11.6667 9.91668 11.6667H4.08334C3.92863 11.6667 3.78026 11.6052 3.67086 11.4958C3.56147 11.3864 3.50001 11.238 3.50001 11.0833V4.08334C3.50001 3.92863 3.56147 3.78026 3.67086 3.67086C3.78026 3.56146 3.92863 3.50001 4.08334 3.50001H4.66668V4.08334C4.66668 4.23805 4.72813 4.38642 4.83753 4.49582C4.94693 4.60521 5.0953 4.66667 5.25001 4.66667H8.75001C8.90472 4.66667 9.05309 4.60521 9.16249 4.49582C9.27188 4.38642 9.33334 4.23805 9.33334 4.08334V3.50001H9.91668C10.0714 3.50001 10.2198 3.56146 10.3292 3.67086C10.4386 3.78026 10.5 3.92863 10.5 4.08334V11.0833Z'
        fill={fill}
      />
      <rect
        x='8.39999'
        y='5.83333'
        width='1.31993'
        height='5.27973'
        rx='0.659966'
        transform='rotate(45 8.39999 5.83333)'
        fill={fill}
      />
      <rect
        x='4.66672'
        y='6.76666'
        width='1.31993'
        height='5.27973'
        rx='0.659966'
        transform='rotate(-45 4.66672 6.76666)'
        fill={fill}
      />
    </svg>
  );
};

ClipboardDeletedIcon.defaultProps = {
  fill: colors.white,
  height: '14',
  width: '14',
};
