import { format, formatDistance, parseISO } from 'date-fns';

export function dateToHumanReadableString(date?: string): string {
  if (!date) {
    return '';
  }

  return formatDistance(new Date(String(date)), new Date(), { addSuffix: true });
}

export function normalizeDateToUTC(date?: Date | null): string {
  if (!date) {
    throw new Error('Input date cannot be null');
  }

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    ) -
      date.getTimezoneOffset() * 60000
  ).toISOString();
}

export function dateToSqlDateString(date: string) {
  return format(parseISO(new Date(date).toISOString()), 'yyyy-MM-dd');
}
