import './vendor-insight-content.style.scss';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatFullDate } from 'shared/helpers/common.helper';
import { trackVendorKnowledgeVideoPlayed } from 'shared/logic/event-tracking/vendor-knowledge.events';

import { StoryBlokRenderer } from './storyblok.renderer.component';
import { VendorInsightContentProps } from './vendor-insight-content.props';

export const VendorInsightContent: FC<VendorInsightContentProps> = ({
  onItemClicked,
  publishedAt,
  subscriptionId,
  vendorId,
  vendorKnowledgeItem,
}) => {
  const { content, link_text: linkTitle, link_url: link, title } = vendorKnowledgeItem;

  const { t } = useTranslation();

  const handlePlayVideo = () => {
    trackVendorKnowledgeVideoPlayed({ subscriptionId, vendorId });
  };

  const publishText = `${t('vendor_insight:vendor_insight_modal.publish_text')} ${formatFullDate(publishedAt)}`;

  return (
    <div className='vendor-insight-content' data-testid='vendor-knowledge-item'>
      <div className='vendor-knowledge-item-wrapper'>
        <div className='vendor-knowledge-item-content-container open' data-testid='vendor-knowledge-item-content'>
          {publishedAt && (
            <div className='vendor-knowledge-item-publish' data-testid='vendor-knowledge-item-publish'>
              {publishText}
            </div>
          )}
          <h3 data-testid='vendor-knowledge-item-title'>{title}</h3>
          <StoryBlokRenderer content={content} onVideoPlay={handlePlayVideo} />
        </div>

        {link && linkTitle ? (
          <div className='vendor-knowledge-item-footer'>
            <a href={link} data-testid='link-title' target='_blank' rel='noreferrer' onClick={onItemClicked}>
              {linkTitle}
            </a>
            <span className='uil-arrow-right' />
          </div>
        ) : null}
      </div>
    </div>
  );
};
