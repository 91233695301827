import { sub } from 'date-fns';
import * as yup from 'yup';

import { alternativeToolsDefaultValue } from './purchase-default-form.constants';
import { FormValues } from './purchase-default-form.props';

export const defaultFormValues: FormValues = {
  alternativeTools: alternativeToolsDefaultValue,
  collaborators: [],
  cost: '',
  description: '',
  dueDate: undefined,
  mainTool: null,
  numberOfLicenses: '',
  paymentFrequency: null,
};

export const formValidationSchema = (isCreate = true) => {
  return yup.object().shape({
    dueDate: yup.date().min(sub(new Date(), { days: 1 })),
    ...(isCreate && { mainTool: yup.mixed().required() }),
  });
};
