import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export const trackManualInvoiceFixingDuration = (data: {
  companyId: string;
  subscriptionId: string;
  invoiceId: string;
  durationInSeconds: number;
}) => EventTracking.trackEvent(trackEventName.trackFixFailedInvoiceDuration, data);
