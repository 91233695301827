import 'quill-mention';

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import ImageUploader from 'quill-image-uploader';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import ReactQuill, { Quill } from 'react-quill';
import { CommentDocument, CSM_USER_EMAIL, UserRoles } from 'shared/common.definitions';
import { computeChecksumMd5 } from 'shared/helpers/common.helper';
import { mentionAllowedChars } from 'shared/helpers/form.helpers';
import {
  getCommaStrippedFile,
  getPresignedUploadUrl,
  mutateSubscriptionDocument,
  uploadFileToPresignedUrl,
} from 'shared/logic/subscription-item.logic';
import { Company, PresignedUploadUrl, User } from 'shared/models';
import { colors } from 'shared/theme';

import { WysiwygEditorComponentProps } from '.';
import { ShortcutInfo } from './components/shortcut-info.component';
import { useEditorFocus } from './hooks/use-editor-focus';
import { EditorModuleProps } from './wysiwyg-editor.component.props';

const PREFIX = 'WysiwygEditorComponent';

const classes = {
  editor: `${PREFIX}-editor`,
  editorContainer: `${PREFIX}-editorContainer`,
  editorFocused: `${PREFIX}-editorFocused`,
  sendCommentShortcut: `${PREFIX}-sendCommentShortcut`,
};

const StyledBox = styled(Box)({
  [`& .${classes.editor}`]: {
    '& .ql-toolbar': {
      opacity: '0',
    },
  },
  [`&.${classes.editorContainer}`]: {
    '& .ql-container': {
      '& p': {
        fontSize: '0.875rem',
      },
      background: colors.white,
      borderBottomLeftRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    },
    '& .ql-editor': {
      '& .mention': {
        background: colors.lightPrimaryBackground,
        color: colors.primary,
      },
      '& img': {
        display: 'block',
        marginTop: '1rem',
      },
      '& li.ql-indent-1': {
        paddingLeft: '1.8rem !important',
      },
      '& li.ql-indent-2': {
        paddingLeft: '2.5rem !important',
      },
      '& li.ql-indent-3': {
        paddingLeft: '3.5rem !important',
      },
      '& li.ql-indent-4': {
        paddingLeft: '4.5rem !important',
      },
      '& li.ql-indent-5': {
        paddingLeft: '5.5rem !important',
      },
      '& li.ql-indent-6': {
        paddingLeft: '6.5rem !important',
      },
      '& li.ql-indent-7': {
        paddingLeft: '7.5rem !important',
      },
      '& li.ql-indent-8': {
        paddingLeft: '8.5rem !important',
      },
      '& ol li': {
        paddingLeft: '0.8rem',
      },
      '& ol, ul': {
        fontSize: '0.875rem',
        paddingLeft: 0,
      },
      '& ul li': {
        paddingLeft: '0.8rem',
      },
      maxHeight: '20rem',
      minHeight: '5.625rem',
      overflowY: 'scroll',
      resize: 'vertical',
    },
    '& .ql-list-container': {
      '& .ql-mention-list-item.selected': {
        background: colors.lightPrimaryBackground,
      },
      backgroundColor: colors.white,
      border: `1px solid ${colors.gallery}`,
      borderRadius: '4px',
      boxShadow: '0 0.125rem 0.75rem 0 rgb(30 30 30 / 8%)',
      height: '16.5625rem',
      overflow: 'scroll',
      width: 'max-content',
      zIndex: 9001,
    },

    '& .ql-snow.ql-toolbar': {
      borderTopLeftRadius: '0.5rem',
      borderTopRightRadius: '0.5rem',
    },

    '& .ql-tooltip': {
      marginLeft: '7.5rem',
    },

    position: 'relative',
  },
  [`& .${classes.editorFocused}`]: {
    '&:focus .ql-toolbar': {
      opacity: '1',
    },
  },
  [`& .${classes.sendCommentShortcut}`]: {
    '& span': {
      fontWeight: 'bold',
    },
    fontSize: '0.9rem',
    position: 'absolute',
    zIndex: 1,
  },
});

type UserSuggestion = {
  id?: string;
  value?: string;
};

Quill.register('modules/imageUploader', ImageUploader);

export const WysiwygEditorComponent: React.FC<WysiwygEditorComponentProps> = (props) => {
  const {
    actionText,
    autofocus,
    bottomSectionStyles,
    className,
    dataTestId,
    handleSaveCommentShortCutPress,
    hideImageUploadButton,
    onChange,
    placeholder,
    setDocuments,
    setIsUploadingDocument,
    showNotification,
    subscriptionId,
    sx,
    value,
    withShortcutInfo = true,
  } = props;

  const imageUploadRef = useRef(false);
  const editorRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (autofocus) {
      editorRef?.current?.focus();
    }
  }, [editorRef, autofocus]);

  const editorHasComment = () => {
    if (!editorRef?.current?.value) {
      return false;
    }

    const editorValue = new DOMParser().parseFromString(String(editorRef.current?.value), 'text/html');
    return Boolean(editorValue.body.textContent);
  };

  const queryClient = useQueryClient();
  const company = queryClient.getQueryData('company') as Company;
  const users = queryClient.getQueryData<User[]>('users');
  const [isFocused, setFocus, setBlur] = useEditorFocus();

  const linkAndImageToolBar = useMemo(() => ['link', 'image'], []);

  const loadSuggestions = useCallback(
    (searchTerm: string) => {
      const suggestions = users
        ?.filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && user.role !== UserRoles.Viewer)
        .map((user) => ({ id: user.id, value: user.name })) as [
        {
          id: string | undefined;
          value: string | undefined;
        }
      ];
      return suggestions?.filter((person) => person?.value?.toLowerCase().startsWith(searchTerm.toLowerCase()));
    },
    [users]
  );

  const editorModules: EditorModuleProps = useMemo(
    () => ({
      clipboard: {
        matchVisual: false,
      },
      imageUploader: {
        upload: async (file: File) => {
          if (hideImageUploadButton) return;

          const commaStrippedFile = getCommaStrippedFile(file);

          setIsUploadingDocument(true);

          const checksum = await computeChecksumMd5(commaStrippedFile);
          const presignedUploadUrl: PresignedUploadUrl = await getPresignedUploadUrl(String(company?.id));
          await uploadFileToPresignedUrl(presignedUploadUrl.url, commaStrippedFile);

          return new Promise((resolve, reject) => {
            mutateSubscriptionDocument({
              data: { checksum, fileKey: presignedUploadUrl.key, fileName: commaStrippedFile.name, subscriptionId },
              subscriptionId,
            })
              .then((result) => {
                const {
                  data: { id, url },
                } = result;

                if (setDocuments) {
                  setDocuments((prevState) => [...prevState, { id, url }]);
                } else {
                  queryClient.setQueryData<CommentDocument[]>('uploaded-documents', (cacheData) => {
                    if (cacheData) {
                      return [...cacheData, { id, url }];
                    }

                    return [{ id, url }];
                  });
                }

                resolve(url);
                setIsUploadingDocument(false);
              })
              .catch((error) => {
                setIsUploadingDocument(false);
                reject(error);
                showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
              });
          });
        },
      },
      keyboard: {
        bindings: {
          custom: {
            handler: () => {
              if (!editorHasComment()) return;
              handleSaveCommentShortCutPress?.(editorRef?.current?.value);
            },
            key: 'Enter',
            shortKey: true,
          },
        },
      },
      mention: {
        allowedChars: mentionAllowedChars,
        defaultMenuOrientation: 'bottom',
        mentionContainerClass: 'ql-list-container',
        mentionDenotationChars: ['@'],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSelect: (item: any, insertItem: (item: string) => void) => {
          insertItem(item);
        },
        source(searchTerm: string, renderList: (matches: UserSuggestion[] | undefined) => void) {
          const matchedUsersSuggestions = loadSuggestions(searchTerm);
          renderList(matchedUsersSuggestions);
        },
      },
      toolbar: {
        container: [
          [{ header: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          linkAndImageToolBar,
        ],
      },
    }),
    [
      company?.id,
      handleSaveCommentShortCutPress,
      hideImageUploadButton,
      linkAndImageToolBar,
      loadSuggestions,
      queryClient,
      setDocuments,
      setIsUploadingDocument,
      showNotification,
      subscriptionId,
    ]
  );

  const clonedEditorModules: EditorModuleProps = useMemo(() => ({ ...editorModules }), [editorModules]);

  if (hideImageUploadButton) {
    delete linkAndImageToolBar[1];
    delete clonedEditorModules.imageUploader;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modules = useMemo(() => clonedEditorModules, []);

  return (
    <StyledBox
      className={classNames(classes.editorContainer, className)}
      sx={sx}
      data-testid={dataTestId ?? 'comment-editor'}>
      <ReactQuill
        value={value}
        ref={editorRef}
        onChange={(text: string, _, source: string) => {
          if (source === 'user' || (source === 'api' && imageUploadRef.current)) {
            onChange?.(text);
            imageUploadRef.current = false;
          }
        }}
        className={isFocused ? classes.editorFocused : ''}
        placeholder={placeholder}
        modules={modules}
        onBlur={setBlur}
        onFocus={setFocus}
        style={{ zIndex: 'unset' }}
      />
      {handleSaveCommentShortCutPress && withShortcutInfo && (
        <ShortcutInfo
          bottomSectionStyles={bottomSectionStyles}
          editorHasContent={editorHasComment()}
          className={classes.sendCommentShortcut}
          actionText={actionText}
        />
      )}
    </StyledBox>
  );
};
