import { Box, styled } from '@mui/material';
import { shadows } from 'asteroids';

export const RootContainer: typeof Box = styled(Box)(() => ({
  display: 'flex',
  height: '500px',
  width: '857px',
}));

export const AccountingConnectionContainer: typeof Box = styled(Box)(({ theme }) => ({
  '&.error-container': {
    height: '80px',
  },
  backgroundColor: theme.palette.background.light,
  borderRadius: '4px',
  boxShadow: shadows.card,
  display: 'flex',
  flexDirection: 'column',
  height: '52px',
  maxWidth: '100%',
  minWidth: '596px',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: theme.spacing(1, 1, 0, 1),

  width: '100%',
}));

export const AccountingConnectionViewOnlyContainer: typeof Box = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.light,
  borderRadius: '4px',
  boxShadow: shadows.card,
  display: 'flex',
  height: '52px',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5, 3, 0.5, 1),
  width: '100%',
}));

export const ConnectionContainer: typeof Box = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'hidden',
  overflowY: 'auto',
  width: '100%',
}));

export const ConnectionValidationContainer: typeof Box = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: theme.spacing(7),
  overflowX: 'hidden',
  overflowY: 'auto',
  p: {
    color: theme.palette.error.main,
  },

  width: '100%',
}));

export const ConnectionIconBox: typeof Box = styled(Box)(({ theme }) => ({
  height: theme.spacing(4),
  img: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  marginLeft: theme.spacing(2),
  maxWidth: theme.spacing(4),
  minWidth: theme.spacing(4),

  width: theme.spacing(4),
}));

export const StaticControls: typeof Box = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginRight: theme.spacing(1),
  maxWidth: '60%',
  minWidth: '290px',
  overflowX: 'hidden',
  overflowY: 'auto',
  width: '60%',
}));

export const EditableControls: typeof Box = styled(Box)(({ theme }) => ({
  '.MuiInputBase-input': {
    height: '1.2175em',
  },
  display: 'flex',
  flexDirection: 'row',
  marginRight: theme.spacing(1),
  maxWidth: '60%',
  minWidth: '290px',
  overflowX: 'hidden',
  overflowY: 'auto',

  width: '60%',
}));

export const StatusBox: typeof Box = styled(Box)(({ theme }) => ({
  maxWidth: '10%',
  minWidth: '120px',
  padding: theme.spacing(1),
  width: '10%',
}));

export const ConnectBtn: typeof Box = styled(Box)(({ theme }) => ({
  maxWidth: '20%',
  minWidth: '120px',
  paddingRight: theme.spacing(2),
  textAlign: 'right',
  width: '20%',
}));

export const IconBox: typeof Box = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  maxWidth: theme.spacing(3),
  minWidth: theme.spacing(3),
  width: theme.spacing(3),
}));

export const EditableTextBox: typeof Box = styled(Box)(({ theme }) => ({
  fontWeight: 'bold',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  maxWidth: '75%',
  minWidth: '140px',
  width: '75%',
  '& .MuiFormControl-root': {
    width: '100%',
  },
}));

export const TextBox: typeof Box = styled(Box)(({ theme }) => ({
  fontWeight: 'bold',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  maxWidth: '75%',
  minWidth: theme.spacing(5),
  overflow: 'hidden',
  padding: theme.spacing(1),
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
