import { styled } from '@mui/system';
import { colors, statusColor } from 'shared/theme';

const PREFIX = 'StatusComponent';

export const classes = {
  noBorder: `${PREFIX}-noBorder`,
  root: `${PREFIX}-root`,
  status: `${PREFIX}-status`,
};

export const Root = styled('div')({
  [`& .${classes.noBorder}`]: {
    border: 'none',
  },
  [`& .${classes.root}`]: {
    '& .active': {
      '& svg': {
        fill: statusColor.active.color,
        marginLeft: '.5rem',
      },
      background: statusColor.active.background,
      color: statusColor.active.color,
    },
    '& .discovered': {
      '& svg': {
        fill: statusColor.discovered.color,
        marginLeft: '.5rem',
      },
      background: statusColor.discovered.background,
      color: statusColor.discovered.color,
    },
    '& .expired': {
      '& svg': {
        fill: statusColor.expired.color,
        marginLeft: '.5rem',
      },
      background: statusColor.expired.background,
      color: statusColor.expired.color,
    },
    '& .in-evaluation': {
      '& svg': {
        fill: statusColor.inEvaluation.color,
        marginLeft: '.5rem',
      },
      background: statusColor.inEvaluation.background,
      color: statusColor.inEvaluation.color,
    },
    '& .in-negotiation': {
      '& svg': {
        fill: statusColor.inNegotiation.color,
        marginLeft: '.5rem',
      },
      background: statusColor.inNegotiation.background,
      color: statusColor.inNegotiation.color,
    },
    '& .in-trial': {
      '& svg': {
        fill: statusColor.inTrial.color,
        marginLeft: '.5rem',
      },
      background: statusColor.inTrial.background,
      color: statusColor.inTrial.color,
    },
    '& .inactive': {
      '& svg': {
        fill: statusColor.inActive.color,
        marginLeft: '.5rem',
      },
      background: statusColor.inActive.background,
      color: statusColor.inActive.color,
    },
    '& .rejected': {
      '& svg': {
        fill: statusColor.rejected.color,
        marginLeft: '.5rem',
      },
      background: statusColor.rejected.background,
      color: statusColor.rejected.color,
    },
    '& .requested': {
      '& svg': {
        fill: statusColor.requested.color,
        marginLeft: '.5rem',
      },
      background: statusColor.requested.background,
      color: statusColor.requested.color,
    },
    '& .subscription-status': {
      alignItems: 'center',
      borderRadius: '0.9rem',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '0.75rem',
      justifyContent: 'center',
      letterSpacing: '0.025rem',
      lineHeight: '1rem',
      margin: 'auto',
      padding: '0.25rem 1rem',
      textAlign: 'center',
      width: 'max-content',
    },
    '& .under-consideration': {
      '& svg': {
        fill: statusColor.underConsideration.color,
        marginLeft: '.5rem',
      },
      background: statusColor.underConsideration.background,
      color: statusColor.underConsideration.color,
    },
    '& .up-for-renewal': {
      '& svg': {
        fill: statusColor.upForRenewal.color,
        marginLeft: '.5rem',
      },
      background: statusColor.upForRenewal.background,
      color: statusColor.upForRenewal.color,
    },
  },
  [`& .${classes.status}`]: {
    '& .active': {
      background: statusColor.active.color,
      opacity: '0.5',
    },
    '& .discovered': {
      background: statusColor.discovered.color,
      opacity: '0.5',
    },
    '& .expired': {
      background: statusColor.expired.background,
      opacity: '0.5',
    },
    '& .in-evaluation': {
      background: statusColor.inEvaluation.color,
      opacity: '0.5',
    },
    '& .in-negotiation': {
      background: statusColor.inNegotiation.color,
      opacity: '0.5',
    },
    '& .in-trial': {
      background: statusColor.inTrial.color,
      opacity: '0.5',
    },
    '& .inactive': {
      background: statusColor.inActive.color,
    },
    '& .requested': {
      background: statusColor.requested.color,
      opacity: '0.5',
    },
    '& .status-box': {
      borderRadius: '0.125rem',
      height: '0.75rem',
      width: '0.75rem',
    },
    '& .under-consideration': {
      background: statusColor.underConsideration.color,
      opacity: '0.5',
    },
    '& .up-for-renewal': {
      background: statusColor.upForRenewal.color,
      opacity: '0.5',
    },
    '& p': {
      color: colors.greyText,
      fontSize: '0.875rem',
      fontWeight: 'normal',
    },
    '.rejected': {
      background: statusColor.rejected.color,
      opacity: '0.5',
    },
  },
});
