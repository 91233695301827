import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import FlagProvider from '@unleash/proxy-client-react';
import theme from 'asteroids/theme';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { SastrifyApp } from './app';
import { AsteroidsDialogProvider } from './app/providers/asteroids-dialog.provider';
import { DialogProvider } from './app/providers/dialog.provider';
import Auth0ProviderWithHistory from './auth0-with-history.provider';
import { CompanyFeatureFlagProvider } from './company-feature-flag.provider';
import i18n from './localization/i18n';
import { E2E_USERS } from './shared/common.definitions';
import { queryClient } from './shared/reactQuery';
import { persistor, SastrifyStore } from './shared/store';

Sentry.init({
  beforeSend(event) {
    if (
      event?.user?.email &&
      E2E_USERS.includes(event?.user?.email) &&
      event.message?.includes('Request failed with status code 400')
    ) {
      return null;
    }
    return event;
  },
  dsn: process.env.SENTRY_WEB_TOKEN,
  environment: process.env.SENTRY_ENVIRONMENT,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Request failed with status code 404',
  ],
  integrations: [
    new Sentry.Integrations.GlobalHandlers(),
    new Sentry.Integrations.FunctionToString(),
    new Sentry.Integrations.Dedupe(),
    new Sentry.BrowserTracing(),
  ],
  // we will get all of the events for now
  tracesSampleRate: 1.0,
});

const config = {
  appName: process.env.REACT_APP_UNLEASH_APP_NAME as string,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY as string,
  disableMetrics: true,
  environment: process.env.REACT_APP_UNLEASH_ENVIRONMENT as string,
  refreshInterval: 1000,
  storage: { get: () => null, save: () => null },
  url: process.env.REACT_APP_UNLEASH_PROXY_URL as string,
};

const container = document.getElementById('root');

ReactDOM.render(
  <SentryReact.ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={SastrifyStore}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <BrowserRouter>
              <Auth0ProviderWithHistory>
                <FlagProvider config={config}>
                  <CompanyFeatureFlagProvider>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <I18nextProvider i18n={i18n}>
                        <DialogProvider>
                          <AsteroidsDialogProvider>
                            <SastrifyApp />
                          </AsteroidsDialogProvider>
                        </DialogProvider>
                      </I18nextProvider>
                    </ThemeProvider>
                  </CompanyFeatureFlagProvider>
                </FlagProvider>
              </Auth0ProviderWithHistory>
            </BrowserRouter>
          </React.StrictMode>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </SentryReact.ErrorBoundary>,
  container
);
