import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { DRAWER_VARIANT, DrawerInfoType } from 'shared/common.definitions';
import { getRequestDetails } from 'shared/logic/requests.logic';

import { DrawerMultiToolInitiativeDetails, DrawerWorkflowRequestDetails } from '..';

const NOT_FOUND_PAGE = '/request-not-found';
const QUERY_NAME = 'workflow-request-details';
const BAD_REQUEST_STATUS = 400;

interface DrawerInitiativeDetailsWrapperProps {
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
  toggleDrawer: () => void;
  closeDrawer: () => void;
  drawerType: DrawerInfoType;
  subscriptionId?: string;
}

export const DrawerInitiativeDetailsWrapper: React.FC<DrawerInitiativeDetailsWrapperProps> = ({
  closeDrawer,
  drawerType,
  showNotification,
  subscriptionId,
  toggleDrawer,
}) => {
  const { hash } = useLocation();
  const { push } = useHistory();

  const getRequestId: string = useMemo(() => {
    if (hash.includes(DRAWER_VARIANT.REQUEST_DETAILS))
      return hash.split(DRAWER_VARIANT.REQUEST_DETAILS).slice(-1)[0] || '';
    return '';
  }, [hash]);

  const { data, isLoading } = useQuery([QUERY_NAME, getRequestId], () => getRequestDetails(getRequestId), {
    onError: (error) => {
      if (error && (error as AxiosError).response?.status === BAD_REQUEST_STATUS) {
        toggleDrawer();
        push(NOT_FOUND_PAGE);
      }
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {!isLoading && !!data?.childRequests && (
        <DrawerMultiToolInitiativeDetails
          showNotification={showNotification}
          toggleDrawer={toggleDrawer}
          drawerType={drawerType}
          closeDrawer={closeDrawer}
        />
      )}
      {!isLoading && !data?.childRequests && (
        <DrawerWorkflowRequestDetails
          showNotification={showNotification}
          toggleDrawer={toggleDrawer}
          drawerType={drawerType}
          subscriptionId={subscriptionId}
        />
      )}
    </>
  );
};
