import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { YesNo } from 'shared/common.definitions';
import * as yup from 'yup';

export type SastricloudAccessRequestForm = {
  purchasingFromVendorDirectly?: YesNo;
  hasCommitment3PlusMonths?: YesNo;
  hasCommitmentAndWouldStay?: YesNo;
};

const sastricloudAccessRequestFormSchema = yup.object().shape({
  hasCommitment3PlusMonths: yup.string().oneOf(Object.values(YesNo)).required(),
  hasCommitmentAndWouldStay: yup.string().oneOf(Object.values(YesNo)).required(),
  purchasingFromVendorDirectly: yup.string().oneOf(Object.values(YesNo)).required(),
});

const defaultSastricloudAccessRequestData: SastricloudAccessRequestForm = {
  hasCommitment3PlusMonths: undefined,
  hasCommitmentAndWouldStay: undefined,
  purchasingFromVendorDirectly: undefined,
} as SastricloudAccessRequestForm;

export const useSastricloudAccessRequestForm = (): UseFormReturn<SastricloudAccessRequestForm> =>
  useForm<SastricloudAccessRequestForm>({
    defaultValues: defaultSastricloudAccessRequestData,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(sastricloudAccessRequestFormSchema),
    shouldUnregister: true,
  });

export default useSastricloudAccessRequestForm;
