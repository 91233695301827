import { Box, type BoxProps, styled } from '@mui/material';

export const NoResultsWrapper = styled(Box)<BoxProps>(({ theme }) => [
  {
    '& .custom--button__class': {
      '& svg': {
        height: 20,
        width: 20,
      },
      fontSize: theme.typography.body.fontSize,
    },
    '& .noresult-content': {
      '& a': {
        textDecoration: 'underline !important',
      },
      alignSelf: 'center',
      width: '100%',
      textAlign: 'center',
    },
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.main,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(6),
    maxWidth: theme.spacing(80),
    width: '80%',
  },
]);
