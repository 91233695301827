import { Box } from '@mui/material';
import { TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { DrawerInfoType } from 'shared/common.definitions';
import {
  createSsoWorkatoIntegration,
  getToolUsageSsoProviders,
  getWorkatoConnectionId,
  getWorkatoIframeUrl,
  startSsoWorkatoIntegration,
  updateWorkatoSsoProvider,
} from 'shared/logic/tool-usage-analytics.logic';
import { Company } from 'shared/models';
import { WorkatoConnect } from 'shared/models/okta-connect.model';
import { RootState } from 'shared/store';
import { hide as hideDrawer, show as showDrawer } from 'shared/store/common/appDrawer.slice';
import {
  setConnectionDetails,
  setIsLoadingIframe,
  setStartIntegrationError,
  setStartIntegrationSuccess,
  setWorkatoRecipeError,
} from 'shared/store/common/workatoSSOConnect.slice';
import { useAppSelector } from 'shared/store/hooks';

import { ErrorAlert, Iframe } from '../../drawer-usage-analytics/drawer-usage-analytics.styles';
import { useWorkatoMessage } from '../../drawer-usage-analytics/use-workato-message.component';
import { UsageAnalyticsIframeSkeleton } from '../../skeletons/usage-analytics-iframe.skeleton';
import { ConnectSection } from '../components/connect-section.component';

export const WorkatoSSOConnectContainer: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const handleCloseDrawer = useCallback(() => dispatch(hideDrawer()), [dispatch]);
  const workatoRef = useRef<HTMLIFrameElement>(null);
  const { canStartIntegration, hasIframeError, iframeHeight, receiveIframeMessage } = useWorkatoMessage();
  const company = queryClient.getQueryData<Company>('company');
  const { ssoProviderName } = useAppSelector(
    (state: RootState) =>
      state.common.appDrawer.data as {
        ssoProviderName: string;
      }
  );
  const {
    connected,
    connectionDetails,
    isLoadingIframe,
    usageAnalyticsEnabled: usageAnalyticToggleEnabled,
    workatoRecipeError,
  } = useAppSelector<WorkatoConnect>((state) => state.common.workatoSSOConnect);

  const connectionId = getWorkatoConnectionId(connectionDetails?.connection?.connectionIds || []);

  const { data: ssoProvidersData } = useQuery('workato-sso-providers', getToolUsageSsoProviders, {
    refetchOnWindowFocus: false,
  });
  const oktaProvider = ssoProvidersData?.find((provider) => provider.ssoProviderName === ssoProviderName);

  useEffect(() => {
    if (workatoRef?.current) {
      workatoRef.current.style.height = iframeHeight;
    }
  }, [iframeHeight]);

  useEffect(() => {
    window.addEventListener('message', receiveIframeMessage);
  }, [receiveIframeMessage]);

  useEffect(() => {
    dispatch(setWorkatoRecipeError(hasIframeError));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasIframeError]);

  const { isLoading: isCreateSSoIntegrationLoading, mutate: createSsoIntegration } = useMutation(
    createSsoWorkatoIntegration,
    {
      onError: () => {
        dispatch(setWorkatoRecipeError(true));
      },
      onSettled: () => {
        dispatch(setIsLoadingIframe(false));
      },
      onSuccess: (data) => {
        dispatch(setConnectionDetails(data));
      },
    }
  );

  const toggleUsageAnalytics = () => {
    if (connected && company?.id && company?.name) {
      createSsoIntegration({
        companyId: company.id,
        companyName: company.name,
        ssoName: ssoProviderName,
        usageAnalyticsEnabled: usageAnalyticToggleEnabled,
      });
    }
  };

  useEffect(() => {
    if (company?.id && company?.name) {
      const abortSignal = new AbortController();
      createSsoIntegration({
        companyId: company.id,
        companyName: company.name,
        signal: abortSignal.signal,
        ssoName: ssoProviderName,
        usageAnalyticsEnabled: usageAnalyticToggleEnabled,
      });
    }
  }, [createSsoIntegration, queryClient, company, company?.id, company?.name]);

  const { mutate: startSsoIntegration } = useMutation(startSsoWorkatoIntegration, {
    onError: () => {
      dispatch(setStartIntegrationError(true));
    },
    onSuccess: () => {
      dispatch(setStartIntegrationSuccess(true));
      updateWorkatoSsoProvider(queryClient, ssoProviderName, TOOL_USAGE_INTEGRATION_STATE.CONNECTED);
      toggleUsageAnalytics();
    },
  });

  useEffect(() => {
    if (canStartIntegration && connectionDetails?.connection?.isRunning === false && company?.id) {
      dispatch(setIsLoadingIframe(true));
      startSsoIntegration({
        companyId: company?.id,
        ssoName: ssoProviderName,
      });

      handleCloseDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionDetails?.connection?.isRunning, canStartIntegration, company?.id]);

  return (
    <Box>
      <ConnectSection guideUrl={oktaProvider?.clickHereUrl as string} ssoProviderName={ssoProviderName} />
      {(isLoadingIframe || isCreateSSoIntegrationLoading || !connectionId || !connectionDetails?.token) && (
        <UsageAnalyticsIframeSkeleton />
      )}
      {workatoRecipeError && (
        <ErrorAlert
          description={t(
            'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.error_alert:description'
          )}
          actionText={t('common:contact_sastrify')}
          severity='error'
          actionIcon={false}
          onAction={() => dispatch(showDrawer(DrawerInfoType.SASTRIFY_APP_HELP))}
          isIconVisible={false}
          title={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.error_alert:title')}
          sx={{ mb: 3, mt: 1 }}
        />
      )}
      {connectionDetails?.token && !isCreateSSoIntegrationLoading && (
        <Iframe
          ref={workatoRef}
          style={{
            visibility:
              !workatoRecipeError &&
              !isLoadingIframe &&
              !isCreateSSoIntegrationLoading &&
              connectionId &&
              connectionDetails.token
                ? 'visible'
                : 'hidden',
          }}
          title={ssoProviderName}
          key={connectionId}
          id='okta-workatoId'
          src={getWorkatoIframeUrl(connectionId, connectionDetails.token)}
        />
      )}
    </Box>
  );
};
