interface IQueryStringKeyVal {
  [key: string]: string | boolean;
}

export default function useQueryString(string = window.location.search): [IQueryStringKeyVal] {
  const query = new URLSearchParams(string);
  const params: IQueryStringKeyVal = {};

  query.forEach((val, key) => {
    Object.assign(params, { [key]: val || true });
  });

  return [params];
}
