import { Button, styled } from '@mui/material';
import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDocumentCategory } from 'shared/helpers/common.helper';

import { DatePickerComponent } from '..';
import { UploadDocumentAttributesComponentProps } from './upload-document-attributes.component.props';

export const DocumentActionButton = styled(Button)(({ theme }) => ({
  color: 'unset !important',
  fontSize: theme.spacing(2),
  margin: 0,
  minWidth: 'auto',
  padding: 0,
}));

export const UploadDocumentAttributesComponent: FC<UploadDocumentAttributesComponentProps> = (props) => {
  const { control, fieldName, file, isInvoiceDocument, register, showAttributes } = props;

  const { t } = useTranslation();

  const [isEditable, setIsEditable] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

  const initialCategory = isInvoiceDocument ? 2 : 0;
  const pathToTranslation = 'common:modals.upload_document_modal.document_modal.document_attributes';

  const [category, setCategory] = useState(initialCategory);

  const handleIsEditable = () => setIsEditable(!isEditable);

  return (
    <fieldset name={fieldName}>
      <section data-testid='document-form'>
        <div className='field is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center my-2'>
          <div>
            <p className='label'>{file?.name}</p>
            {!showAttributes && <p className='grey-text'>{getDocumentCategory(category as number).toLowerCase()}</p>}
          </div>
          {!showAttributes && (
            <>
              {isEditable ? (
                <DocumentActionButton onClick={handleIsEditable}>
                  <i data-testid='cancel' className='fa fa-times-circle is-clickable' />
                </DocumentActionButton>
              ) : (
                <DocumentActionButton onClick={handleIsEditable}>
                  <i data-testid='edit' className='fa fa-edit is-clickable' />
                </DocumentActionButton>
              )}
            </>
          )}
        </div>
        {isEditable ? (
          <section data-testid='document-attributes'>
            <div className='content-body field is-fullwidth'>
              <div className='field-label has-text-left'>
                <label className='label'>{t(`${pathToTranslation}.name_input_label`)}</label>
              </div>
              <input
                className='input is-fullwidth'
                {...register(`${fieldName}.name`)}
                type='text'
                autoFocus
                data-testid='edit-name'
              />
            </div>
            {!isInvoiceDocument && (
              <div className='content-body field is-fullwidth'>
                <div className='field-label has-text-left'>
                  <label className='label' htmlFor='category'>
                    {t(`${pathToTranslation}.category_input_section.input_label`)}
                  </label>
                </div>
                <div className='select is-fullwidth is-expanded'>
                  <select
                    id='category'
                    className='select-billingfrequency is-expanded'
                    defaultValue={initialCategory}
                    {...register(`${fieldName}.category`)}
                    onChange={(e) => setCategory(Number(e.target.value))}>
                    <option value={0}>
                      {t(`${pathToTranslation}.category_input_section.miscellaneous_option_text`)}
                    </option>
                    <option value={1}>
                      {t(`${pathToTranslation}.category_input_section.terms_and_conditions_option_text`)}
                    </option>
                    <option value={2}>{t(`${pathToTranslation}.category_input_section.invoice_option_text`)}</option>
                    <option value={3}>{t(`${pathToTranslation}.category_input_section.contract_option_text`)}</option>
                    <option value={4}>{t(`${pathToTranslation}.category_input_section.offer_option_text`)}</option>
                    <option value={5}>
                      {t(`${pathToTranslation}.category_input_section.data_processing_option_text`)}
                    </option>
                  </select>
                </div>
              </div>
            )}
            <div className='field is-fullwidth sastrify-changerenewaldate'>
              <div className='field-label has-text-left'>
                <label className='label'>{t(`${pathToTranslation}.data_input_label`)}</label>
              </div>
              <div className='sastrify-datepicker'>
                <Controller
                  name={`${fieldName}.date`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePickerComponent
                      value={value}
                      onChange={(newValue) => {
                        onChange(newValue);
                        setOpenDatePicker(false);
                      }}
                      placeholder={t(`${pathToTranslation}.data_input_placeholder_text`)}
                      openDatePicker={openDatePicker}
                      setOpenDatePicker={setOpenDatePicker}
                      maxDate={new Date()}
                    />
                  )}
                />
              </div>
            </div>
          </section>
        ) : null}
      </section>
    </fieldset>
  );
};
