import { FC } from 'react';
import { colors } from 'shared/theme';

export const CalendarIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.04163 3.54167C2.65042 3.54167 2.33329 3.8588 2.33329 4.25V14.1667C2.33329 14.5579 2.65042 14.875 3.04163 14.875H12.9583C13.3495 14.875 13.6666 14.5579 13.6666 14.1667V4.25C13.6666 3.8588 13.3495 3.54167 12.9583 3.54167H3.04163ZM0.916626 4.25C0.916626 3.07639 1.86802 2.125 3.04163 2.125H12.9583C14.1319 2.125 15.0833 3.07639 15.0833 4.25V14.1667C15.0833 15.3403 14.1319 16.2917 12.9583 16.2917H3.04163C1.86802 16.2917 0.916626 15.3403 0.916626 14.1667V4.25Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8333 0.708332C11.2245 0.708332 11.5416 1.02546 11.5416 1.41667V4.25C11.5416 4.6412 11.2245 4.95833 10.8333 4.95833C10.4421 4.95833 10.125 4.6412 10.125 4.25V1.41667C10.125 1.02546 10.4421 0.708332 10.8333 0.708332Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.16663 0.708332C5.55783 0.708332 5.87496 1.02546 5.87496 1.41667V4.25C5.87496 4.6412 5.55783 4.95833 5.16663 4.95833C4.77542 4.95833 4.45829 4.6412 4.45829 4.25V1.41667C4.45829 1.02546 4.77542 0.708332 5.16663 0.708332Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.916626 7.08333C0.916626 6.69213 1.23376 6.375 1.62496 6.375H14.375C14.7662 6.375 15.0833 6.69213 15.0833 7.08333C15.0833 7.47453 14.7662 7.79167 14.375 7.79167H1.62496C1.23376 7.79167 0.916626 7.47453 0.916626 7.08333Z'
      fill={fill}
    />
  </svg>
);

CalendarIcon.defaultProps = {
  fill: colors.emerald,
  height: '17',
  width: '16',
};
