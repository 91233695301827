import { StepLabel as MuiStepLabel, Typography } from '@mui/material';

import { StepLabelProps } from './step-label.props';

export const StepLabel: React.FC<StepLabelProps> = (props: StepLabelProps) => {
  const { error, inactive, label, optionalLabel } = props;
  return (
    <MuiStepLabel
      error={error}
      optional={
        optionalLabel && (
          <Typography variant='small' className={error ? 'error' : undefined}>
            {optionalLabel}
          </Typography>
        )
      }
      className={inactive ? 'inactive' : undefined}>
      <Typography variant='section'>{label}</Typography>
    </MuiStepLabel>
  );
};

export default StepLabel;
