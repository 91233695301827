import { RefObject, useEffect, useRef } from 'react';

export function useScrollToTop(): RefObject<HTMLDivElement> {
  const appContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    appContainerRef?.current?.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return appContainerRef;
}
