import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, Icon } from 'asteroids';

import { Accordion, AccordionSummary } from '../../accordion/accordion.styles';

export const ActionIcon = styled(Icon)(() => ({
  color: 'rgba(0, 0, 0, 0.54)',
  cursor: 'pointer',
}));

export const AccordionStyled = styled(Accordion)(() => ({
  marginBottom: '0 !important',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  cursor: 'default !important',
  borderLeft: `5px solid ${colors.secondary.dark} !important`,
}));
