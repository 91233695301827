import { createContext } from 'react';

export interface DialogProps {
  title: string;
  content: string;
  onConfirm: () => void;
  confirmBtnText: string;
  icon?: React.ReactNode;
}

interface DialogContextType {
  openDialog(modalProps: DialogProps): void;
  closeDialog(): void;
}

export const DialogContext = createContext<DialogContextType>({
  closeDialog: () => {
    throw new Error('Using DialogContext without a provider');
  },
  openDialog: () => {
    throw new Error('Using DialogContext without a provider');
  },
} as DialogContextType);
