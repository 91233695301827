export enum RequiredNotificationsTemplateIds {
  RENEWAL_REMINDER = 'required:renewal_reminder',
}

export enum GeneralNotificationsTemplateIds {
  NEW_VENDOR_INSIGHTS = '2DE20DDBPTM8SBJ79Z0C5XZFF2GE',
  WEEKLY_SUMMARY_EMAIL = '2KNJ9NJGANMBXGPTHDGSS7EG12CB',
  DISCOVERED_SUBSCRIPTIONS = '8BJD4R5EM9M7QQMR8P536F4QGCM3',
  NEWLY_CREATED_SUBSCRIPTIONS = 'YEKGK44JD3MVV2HM2GPVD0YQAVX5',
}

export enum FeedCommentTemplateIds {
  NEW_COMMENT = 'CVR7XWC1FGMZMTQRR9Z07NEN5XDQ',
  NEW_REPLY_ON_THREAD = 'G9FFD6YSB9MF47PXGQKS68NVY0BB',
  USER_MENTIONED = 'RAQFB3DK6VMAV4P7608C4Q5V86A7',
}

export enum TasksTemplateIds {
  TASK_ASSIGNED = '9Z4YCA08B0MDECJX7V1AQ9Z8EY7M',
  TASK_COMPLETION = 'FFXXWEXY9W4HWEM0AWGJ97C79KVK',
  TASK_SUMMARY = 'N41HVSJXEN4PJ3N0D3K7APXYQBAR',
}
