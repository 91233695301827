import { useCallback, useEffect, useState } from 'react';

import { MaterialReactTableComponentProps } from '../material-react-table.props';

export const useTablePagination = (props: MaterialReactTableComponentProps) => {
  const localStorageKey = `${window.location.href}-table-page-size`;
  const pageSize = Number(localStorage.getItem(localStorageKey));
  const defaultPageSize = 50;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize || defaultPageSize,
    ...props.state?.pagination,
  });

  useEffect(() => {
    if (props.state?.pagination) setPagination(props.state.pagination);
  }, [props.state?.pagination]);

  useEffect(() => {
    if (!pageSize) localStorage.setItem(localStorageKey, defaultPageSize.toString());
  }, [localStorageKey, pageSize]);

  const getUpdaterValue = useCallback(
    (updater: unknown, stateValue: unknown) => (updater instanceof Function ? updater(stateValue) : updater),
    []
  );

  const updatePagination = useCallback(
    (updater) => {
      const newPaginationValue = getUpdaterValue(updater, pagination);

      setPagination(newPaginationValue);
      localStorage.setItem(localStorageKey, newPaginationValue.pageSize.toString());
    },
    [getUpdaterValue, localStorageKey, pagination]
  );

  return {
    pagination,
    updatePagination,
  };
};
