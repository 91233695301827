import { Box, styled } from '@mui/material';

export const CustomTaskFormWrapper = styled(Box)(({ theme }) => ({
  '& .input-section': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .title-spacing': {
    margin: theme.spacing(2, 0, 1, 0),
  },
}));
