import React, { FC } from 'react';
import { colors } from 'shared/theme';

export const DocumentOutlineIcon: FC<React.SVGProps<SVGElement>> = ({ fill }) => {
  return (
    <svg width={28} height={30} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.5 10.41a1.963 1.963 0 00-.09-.405V9.87a1.604 1.604 0 00-.285-.42l-9-9a1.606 1.606 0 00-.42-.285.48.48 0 00-.135 0A1.32 1.32 0 0017.075 0H11a4.5 4.5 0 00-4.5 4.5V6H5a4.5 4.5 0 00-4.5 4.5v15A4.5 4.5 0 005 30h12a4.5 4.5 0 004.5-4.5V24H23a4.5 4.5 0 004.5-4.5v-9-.09zm-9-5.295L22.385 9H20a1.5 1.5 0 01-1.5-1.5V5.115zm0 20.385A1.5 1.5 0 0117 27H5a1.5 1.5 0 01-1.5-1.5v-15A1.5 1.5 0 015 9h1.5v10.5A4.5 4.5 0 0011 24h7.5v1.5zm6-6A1.5 1.5 0 0123 21H11a1.5 1.5 0 01-1.5-1.5v-15A1.5 1.5 0 0111 3h4.5v4.5A4.5 4.5 0 0020 12h4.5v7.5z'
        fill={fill}
      />
    </svg>
  );
};

DocumentOutlineIcon.defaultProps = {
  fill: colors.iron,
};
