import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { UsageAnalyticsCardConnectProps } from '../usage-analytics.props';
import { CardContainer, CardContent, CardHeader, CardLink } from '../usage-analytics.styles';

export const UsageAnalyticsCardConnect = (props: UsageAnalyticsCardConnectProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { onConnect } = props;
  return (
    <CardContainer data-testid='usage-analytics-details-card'>
      <CardHeader sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='h3'>{t('subscription_detail_view:usage_analytics_section:title')}</Typography>
        <Button variant='text' color='primary' onClick={onConnect} sx={{ textTransform: 'uppercase' }}>
          {t('subscription_detail_view:usage_analytics_section:connect_button_text')}
        </Button>
      </CardHeader>

      <CardContent>
        <Typography variant='body1' color='text.primary' sx={{ pb: 2 }}>
          {t('subscription_detail_view:usage_analytics_section:connect_your_subscription_text')}{' '}
          <CardLink
            variant='body1'
            color='primary'
            onClick={() => {
              history.push('/usage-analytics');
            }}>
            <span> {` ${t('subscription_detail_view:usage_analytics_section:connect_center_text')}`}</span>{' '}
            <OpenInNewIcon sx={{ fontSize: '12px' }} />
          </CardLink>
        </Typography>
      </CardContent>
    </CardContainer>
  );
};
