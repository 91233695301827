import { useQuery, UseQueryResult } from 'react-query';
import { getRequestActivities, getRequestDetails } from 'shared/logic/requests.logic';
import { RequestDetails } from 'shared/models';

export const useWorkflowRequestDetailsData = (
  requestId: string | number,
  enabled = true
): UseQueryResult<RequestDetails, unknown> =>
  useQuery(['workflow-request-details', requestId], () => getRequestDetails(requestId), {
    enabled,
    refetchOnWindowFocus: false,
  });

export const useWorkflowActivitiesData = (requestId: string | number) =>
  useQuery(['workflow-request-activities', requestId], () => getRequestActivities(requestId));
