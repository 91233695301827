import { ActionButtonProps } from 'asteroids';
import { createContext, useContext } from 'react';

export interface DialogProps {
  open: boolean;
  onClose?: () => void;
  width?: number | number;
  title?: string;
  titleIcon?: string;
  customTitle?: React.ReactNode;
  bodyContent?: React.ReactNode;
  actions?: ActionButtonProps[];
}

interface AsteroidsDialogContextProps {
  openDialog?: (dialogProps: Omit<DialogProps, 'open'>) => void;
  closeDialog?: () => void;
}

export const AsteroidsDialogContext = createContext<AsteroidsDialogContextProps>({});

export const useAsteroidsDialog = () => {
  const context = useContext(AsteroidsDialogContext);

  if (!context) {
    throw new Error('useAsteroidsDialog must be used within an AsteroidsDialogProvider');
  }

  return context;
};
