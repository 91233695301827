import './vendor-knowledge.style.scss';

import { Box, Typography } from '@mui/material';
import upsellingPrompt from 'assets/images/upselling-prompt-vendor-insight.svg';
import { LinearGradientChip } from 'asteroids';
import { VendorKnowledgeSkeleton } from 'components/skeletons';
import { UpsellingPrompt } from 'components/upselling-prompt';
import { useVendorInsightsModal } from 'components/vendor-insight-modal-content/hooks/use-vendor-insights-modal';
import { VendorKnowledgeItem } from 'components/vendor-knowledge-item';
import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { featureFlags } from 'shared/common.definitions';
import { EnabledFeature, FeatureSwitch, PurchasableFeature } from 'shared/helpers/feature-switch';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';

import { VendorKnowledgeFeatureProps } from './vendor-knowledge.feature.props';

export const VendorKnowledgeFeature: FC<VendorKnowledgeFeatureProps> = ({ data, isLoading, subscription }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [openVendorModal] = useVendorInsightsModal('insights', {
    companyId: subscription.companyId || '0',
    subscriptionId: subscription.id,
    vendorId: subscription.vendorId || '0',
  });
  const enableInsightCategory = useCompanyFeatureFlag(featureFlags.ENABLE_VENDOR_INSIGHTS_CATEGORY);
  const enableInsightSeen = useCompanyFeatureFlag(featureFlags.ENABLE_INSIGHT_SEEN);
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();

  useEffect(() => {
    if (!data?.length && !isLoading) {
      history.replace(history.location.pathname);
    }
  }, [data, history, isLoading]);

  if (isLoading) {
    return <VendorKnowledgeSkeleton />;
  }

  return (
    <section className='vendor-knowledge'>
      <Box sx={{ marginBottom: 4 }} data-testid='vendor-knowledge-header'>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: 2, mb: 1 }}>
          <Typography variant='h1' sx={{ color: 'text.primary' }}>
            {t('subscription_detail_view:tabs_component_section.insight_tab.header_section.title_text')}
          </Typography>
          {isFeaturePurchasable(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS) && (
            <LinearGradientChip withIcon title={t('pricing_plans:premium_feature')} size='small' />
          )}
        </Box>
        <Typography variant='subtitle1' sx={{ color: 'text.primary' }}>
          {t('subscription_detail_view:tabs_component_section.insight_tab.header_section.description_text')}
        </Typography>
      </Box>
      <FeatureSwitch featureName={PRICING_PLANS_FEATURES.VENDOR_INSIGHTS}>
        <EnabledFeature>
          <section className='vendor-knowledge-container' data-testid='vendor-knowledge-container'>
            {data?.map((item) => (
              <VendorKnowledgeItem
                key={item.id}
                isNew={enableInsightSeen && !item.insight_seen}
                vendorKnowledgeItem={item.content}
                onItemClicked={() => openVendorModal(item)}
                subscriptionId={subscription.id}
                category={enableInsightCategory ? item.category : null}
              />
            ))}
          </section>
        </EnabledFeature>
        <PurchasableFeature>
          <UpsellingPrompt
            feature={PRICING_PLANS_FEATURES.VENDOR_INSIGHTS}
            featureName={t('pricing_plans:upselling_prompt.featureNames.vendor_insight')}
            image={upsellingPrompt}
            learnMoreLink='https://support.sastrify.com/support/solutions/articles/101000503832-get-pricing-benchmarks-through-sastrify-beta-version-'
          />
        </PurchasableFeature>
      </FeatureSwitch>
    </section>
  );
};
