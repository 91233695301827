import { AuthenticationStore, ModalStore, SastrifyStore } from '../models';

export const DefaultAuthenticationStore: AuthenticationStore = {
  isAuthenticated: false,
  isLoading: false,
  user: null,
};

export const DefaultModalStore: ModalStore = {
  isFormSubmitting: false,
  isFormValid: true,
  isSubscriptionRenewalActionCancelModalOpen: false,
  isSubscriptionRenewalActionRenewalModalOpen: false,
};

export const DefaultStore: SastrifyStore = {
  authentication: DefaultAuthenticationStore,
  modal: DefaultModalStore,
};
