import { useEffect } from 'react';
import { getScrollbarWidth } from 'shared/helpers/scrollbar-width';

const scrollbarExistsOnAppContainer = (): boolean => {
  const elementWithScrollbar = document.getElementsByClassName('app-container')[0];

  if (!elementWithScrollbar) return false;
  return elementWithScrollbar.scrollHeight > elementWithScrollbar.clientHeight;
};

const setScrollbarWidth = () => {
  if (scrollbarExistsOnAppContainer()) {
    document.documentElement.style.setProperty('--scrollbar-width', `${getScrollbarWidth()}px`);
  } else {
    document.documentElement.style.setProperty('--scrollbar-width', '0px');
  }
};

export const useScrollbarWidth = () => {
  useEffect(() => {
    setScrollbarWidth();

    window.addEventListener('resize', setScrollbarWidth);

    // Setup MutationObserver to detect changes in the DOM
    const observer = new MutationObserver(setScrollbarWidth);

    observer.observe(document.getElementById('root') as HTMLElement, {
      childList: true,
      subtree: true,
    });

    return () => {
      window.removeEventListener('resize', setScrollbarWidth);
      observer.disconnect();
    };
  }, []);
};
