import { Button, styled } from '@mui/material';
import { colors } from 'shared/theme';

export const WrapperButton = styled(Button)(() => ({
  alignSelf: 'baseline',
  margin: 0,
  minWidth: 'auto',
  padding: 0,
}));

export const DropdownMenuIcon = styled(Button)(() => ({
  '& .MuiIcon-root, & i': {
    color: colors.grey,
  },
  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  color: 'unset',
  minWidth: 'auto',
}));
