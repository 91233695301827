import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GoogleDirectory, GoogleDirectoryUser, User } from 'shared/models';
import {
  GoogleClient,
  ListDirectoryPeopleResponse,
  TokenResponse,
  UserIDCompanyID,
  UsersPayload,
} from 'shared/models/google-directory.model';
import { apiService, apiUrl } from 'shared/services';
import { AppDispatch } from 'shared/store';
import i18n from 'src/localization/i18n';

import { formatGoogleDirectoryUsers, getInvitedUsersEmail } from '../../logic/users.logic';
import { queryClient } from '../../reactQuery';

const CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/people/v1/rest'];
const SCOPES = 'https://www.googleapis.com/auth/directory.readonly';

const pathToTranslation = 'app:google_workspace';
const { t } = i18n;

const initialState: GoogleDirectory = {
  accessToken: null,
  connected: false,
  currentUser: null,
  directoryUsers: [],
  error: '',
  hasFetchedUsers: false,
  isError: false,
  isFetchingUsers: false,
  isGoogleDirectoryError: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorFn = (dispatch: AppDispatch, error: any) => {
  const message: string = error?.result?.error?.message || error.details || error.message;
  dispatch(googleDirectorySlice.actions.setConnectionState(false));
  dispatch(googleDirectorySlice.actions.setError(message));
};

let tokenClient: GoogleClient | null = null;

export const getTokenResponse = async (userId: string, companyId: string, code?: string) => {
  try {
    return await apiService.get(apiUrl.getAccessToken(userId, companyId, code));
  } catch (err) {
    return null;
  }
};

const revokeAccessToken = async (userId: string, companyId: string) => {
  return apiService.put(apiUrl.revokeAccessToken(userId, companyId));
};

async function handleTokenResponse(userId: string, companyId: string, dispatch: AppDispatch, code?: string) {
  try {
    const response = await getTokenResponse(userId, companyId, code);

    if (response?.data?.message === 'Credentials not found') {
      throw new Error(t(`${pathToTranslation}.token_response_error`));
    }

    const token = response?.data.access_token;
    setToken(dispatch, token);
  } catch (err) {
    throw new Error(t(`${pathToTranslation}.token_response_error`));
  }
}

const setToken = (dispatch: AppDispatch, token: string) => {
  if (window.gapi?.client) {
    window.gapi.client.setToken({ access_token: token });

    dispatch(googleDirectorySlice.actions.setAccessToken(token));
    dispatch(googleDirectorySlice.actions.setConnectionState(true));
  }
};

export const initializeGoogleIdentityServices = async (dispatch: AppDispatch, companyId: string, userId: string) => {
  if (!window.google) {
    errorFn(dispatch, { message: t(`${pathToTranslation}.google_connection_error`) });

    return false;
  }

  const response = await getTokenResponse(userId, companyId);

  if (response?.data?.access_token) {
    const token = response.data.access_token;
    setToken(dispatch, token);
  }

  tokenClient = window.google.accounts.oauth2.initCodeClient({
    callback: async (tokenResponse: TokenResponse) => {
      const { code } = tokenResponse;

      await handleTokenResponse(userId as string, companyId as string, dispatch, code);
    },
    client_id: CLIENT_ID,
    redirect_uri: 'http://app.sastrify.com/users',
    scope: SCOPES,
    ux_mode: 'popup',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initializeGoogleApi = (dispatch: AppDispatch) => {
  if (!window.gapi) {
    errorFn(dispatch, { message: t(`${pathToTranslation}.google_connection_error`) });

    return false;
  }

  const initClient = async () => {
    await window.gapi.client.init({
      discoveryDocs: DISCOVERY_DOCS,
    });
  };

  window.gapi.load('client', initClient);
};

export const connect = createAsyncThunk('googleDirectory/connect', async () => {
  if (tokenClient) {
    tokenClient.requestCode();
  }
});

export const revokeAccess = createAsyncThunk(
  'googleDirectory/revokeAccess',
  async ({ companyId, userId }: UserIDCompanyID, thunkAPI) => {
    await revokeAccessToken(userId, companyId);

    thunkAPI.dispatch(googleDirectorySlice.actions.disconnect());
  }
);

const googleDirectorySlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getUsersList.pending, (state) => {
      state.isFetchingUsers = true;
    });

    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.isFetchingUsers = false;
      if (action.payload) {
        state.hasFetchedUsers = true;
        state.directoryUsers = action.payload;
      }
    });

    builder.addCase(getUsersList.rejected, (state) => {
      state.isFetchingUsers = false;
      state.directoryUsers = [];
    });
  },
  initialState,
  name: 'googleDirectory',
  reducers: {
    disconnect(state) {
      state.error = null;
      state.isError = false;
      state.connected = false;
      state.currentUser = null;
      state.isFetchingUsers = false;
      state.hasFetchedUsers = false;
      state.directoryUsers = [];
      state.accessToken = null;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      if (state.accessToken !== action.payload) {
        state.accessToken = action.payload;
      }
    },
    setConnectionState(state, action: PayloadAction<boolean>) {
      if (state.connected !== action.payload) {
        state.connected = action.payload;
      }
    },
    setError(state, action: PayloadAction<string | unknown>) {
      state.isError = true;
      state.error = action.payload;
    },
    setGoogleDirectoryError(state, action: PayloadAction<boolean>) {
      state.isGoogleDirectoryError = action.payload;
    },
    setHasFetchedUsers(state, action: PayloadAction<boolean>) {
      state.hasFetchedUsers = action.payload;
    },
  },
});

export default googleDirectorySlice.reducer;

const usersNotLoadedError = t(`${pathToTranslation}.users_not_loaded_error`);
const googleDirectoryProcessingError = t(`${pathToTranslation}.google_directory_processing_error`);

const getListDirectoryPeople = async (nextPageToken: string) => {
  try {
    const response = await window.gapi.client.people.people.listDirectoryPeople({
      pageToken: nextPageToken,
      readMask: 'emailAddresses,names,photos',
      sources: ['DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE'],
    });

    return response;
  } catch (error) {
    return {
      error,
    };
  }
};

export const getUsersList = createAsyncThunk('googleDirectory/users', async (payload: UsersPayload, thunkAPI) => {
  try {
    let nextPageToken = '';
    let allUsers = [] as GoogleDirectoryUser[];

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = (await getListDirectoryPeople(nextPageToken)) as ListDirectoryPeopleResponse;

      if (!response.result) {
        thunkAPI.dispatch(googleDirectorySlice.actions.setGoogleDirectoryError(true));

        const reason = response?.error?.result?.error;

        throw new Error(reason?.message);
      }

      nextPageToken = response?.result?.nextPageToken || '';

      allUsers = [...allUsers, ...response.result.people];
    } while (nextPageToken);

    const appUsers: User[] = queryClient.getQueryData('users') || [];

    if (!appUsers?.length) {
      thunkAPI.dispatch(googleDirectorySlice.actions.setGoogleDirectoryError(true));

      if (payload.showNotification) {
        payload.showNotification(usersNotLoadedError);
      }

      throw new Error(usersNotLoadedError);
    }

    const invitedUsersEmail = getInvitedUsersEmail(appUsers);
    const directoryUsers = formatGoogleDirectoryUsers(allUsers as GoogleDirectoryUser[]);

    directoryUsers.forEach((user) => {
      if (invitedUsersEmail.indexOf(user.email) !== -1) {
        user.isInvited = true;
      }
    });

    return directoryUsers;
  } catch (error) {
    if (typeof error === 'object' && error !== null) {
      if (payload.showNotification) {
        payload.showNotification(error?.toString() || googleDirectoryProcessingError);
      }
    }
  }
});
