export enum SchemaFieldTypes {
  Text = 'text',
  Select = 'select',
  Email = 'email',
}

/**
 * For now we only support equality rule
 */
type FieldVisibilityRule = {
  [key: string]: string;
  // | {
  //     $ne?: string;
  //   };
};

export interface SchemaField {
  field_name: string;
  field_value: string;
  field_type: SchemaFieldTypes;
  field_options?: { [key: string]: string | string[] };
  field_visible?: FieldVisibilityRule[];
}
