import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert as AsteroidsAlert, Icon } from 'asteroids';
import { borders } from 'shared/theme';

import { ContactSastrifyFormFooterProps } from './contact-sastrify-form.component.props';

export const ContactSastrifyFormHeader = styled(Box)(() => ({
  alignItems: 'center',
  borderBottom: borders.actionMenuBorder,
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 -1.5rem',
  padding: '.5rem 2rem 1rem',
}));

export const HeaderCloseIcon = styled(Icon)(() => ({
  cursor: 'pointer',
}));

export const ContactSastrifyFormFooter = styled(Box)<ContactSastrifyFormFooterProps>(({ shadow }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '0 -1.5rem -1rem',
  padding: '1.5rem 2.5rem',
  ...(shadow === 'true' && { boxShadow: '0 -1px 40px 2px rgba(31,30,78,0.1)' }),
  borderTop: borders.actionMenuBorder,
}));

export const ContactSastrifyFormScrollableContent = styled(Box)(() => ({
  maxHeight: 'calc(100vh - 14rem)',
  overflowY: 'auto',
  padding: '0 .5rem 2rem',
}));

export const FlexColumn = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  margin: '.1875rem 0 0 .875rem',
}));

export const Alert = styled(AsteroidsAlert)(() => ({
  '& .MuiAlert-action': {
    minWidth: '4rem',
  },
}));
