import { colors, theme } from 'asteroids';

export const styles = {
  root: {
    '& [class*="MessageList"] > [class*="PositionRelative"]': {
      borderBottom: `1px solid ${colors.secondary.main}`,
      '& [class*="ClickableContainer"]': {
        display: 'flex',

        '&:hover': {
          backgroundColor: 'rgba(6, 132, 102, 0.14)',
        },
      },
    },
    '& [class*="HeadingContainer"]': {
      '& .message-count': {
        backgroundColor: colors.tertiary.main,
      },
      pointerEvents: 'none',
      svg: {
        display: 'none',
      },
    },
    '& [class*="Message__Contents"]': {
      maxWidth: '300px',
    },
    '& [class*="MessageContainer"]': {
      border: 'none',
      '& [class*="TextElement"]': {
        WebkitBoxOrient: 'vertical',
        BoxOrient: 'vertical',
        WebkitLineClamp: '3',
        LineClamp: '3',
        display: ' -webkit-box',
        overflow: 'hidden',
        maxHeight: '64px',

        'a:hover': {
          color: colors.primary.main,
        },
      },
      '& [class*="TextElement"], & strong, & b': {
        fontWeight: 400,
      },
      '& [class*="Title"]': {
        fontSize: '13px',
        marginBottom: '5px',
      },
      '& [class*="Title"], & [class*="TextElement"]': {
        color: colors.text.main,
        fontFamily: theme.typography.fontFamily,
      },
      '& [class*="UnreadIndicator"]': {
        visibility: 'visible',
      },
      '&.read': {
        '& [class*="Title"], & [class*="TextElement"], & strong, & b': { color: '#666' },
        '[class*="UnreadIndicator"]': {
          visibility: 'hidden',
        },
      },
    },
    '& [class*="ScrollTop"]': {
      '&::before': {
        alignItems: 'center',
        backgroundColor: colors.primary.main,
        color: colors.primary.contrastText,
        display: 'flex',
        fontWeight: 500,
        height: '22px',
        padding: '0 12px',
      },
      '&:hover::before': {
        backgroundColor: colors.primary.dark,
      },
      backgroundColor: colors.secondary.dark,
    },
    '[class*="PreferenceList_"]': {
      '& [class*="SubscriptionDivider"]': {
        display: 'none',
      },
    },
  },
};
