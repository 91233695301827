import { DropdownComponent } from 'components/index';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';
import { DropdownMenuIcon } from 'shared/helpers/styles.helper';

import { useShowCommentDropdownActions } from '../hooks/use-show-comment-dropdown-actions';

interface DropdownActionsProps {
  isEditFormToggled: boolean;
  onEditClicked(): void;
  onDeleteClicked(): void;
  authorId: string;
}

export const DropdownActions: React.FC<DropdownActionsProps> = ({
  authorId,
  isEditFormToggled,
  onDeleteClicked,
  onEditClicked,
}) => {
  const dropDownComponentRef = useRef<{ handleToggle: () => void }>();
  const { t } = useTranslation();

  const showCommentDropdownActions = useShowCommentDropdownActions(authorId);

  if (!showCommentDropdownActions) {
    return null;
  }

  return (
    <DropdownComponent
      ref={dropDownComponentRef}
      hasPadding
      isActionMenuDropdown
      menuMinWidth='6.25rem'
      menuTriggerElement={
        <DropdownMenuIcon
          disableRipple
          data-testid='dropdown-action-menu-button'
          className='action-menu-dots'
          onClick={() => dropDownComponentRef.current?.handleToggle()}>
          <i className='uil-ellipsis-v' />
        </DropdownMenuIcon>
      }>
      {!isEditFormToggled && (
        <span
          className='action-menu-item'
          tabIndex={0}
          role='button'
          onKeyUp={(e) => onEnterOrSpaceKeyUp(e, onEditClicked)}
          onClick={onEditClicked}>
          {t('subscription_detail_view:subscription_comment_section.subscription_action_menu_section.edit_button_text')}
        </span>
      )}
      <span
        className='action-menu-item'
        tabIndex={0}
        role='button'
        onKeyUp={(e) => onEnterOrSpaceKeyUp(e, onDeleteClicked)}
        onClick={onDeleteClicked}>
        {t('subscription_detail_view:subscription_comment_section.subscription_action_menu_section.delete_button_text')}
      </span>
    </DropdownComponent>
  );
};
