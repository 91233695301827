class CelloReferral {
  init(celloToken: string): void {
    window.cello = window.cello || { cmd: [] };

    if (window.cello && celloToken) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.cello.cmd.push((cello: any) => {
        cello.boot({
          customLauncherSelector: '#celloId',
          hideDefaultLauncher: true,
          productId: process.env.CELLO_PRODUCT_ID,
          showOnBoot: true,
          token: celloToken,
        });
      });
    } else {
      throw new Error('Cello token is not defined');
    }
  }

  openReferralModal(): void {
    if (!window.cello) {
      throw new Error('Cello is not defined');
    } else {
      window.cello.cmd.push((cello: { open: () => void }) => {
        cello.open();
      });
    }
  }
}

export default new CelloReferral();
