/* eslint-disable sort-keys-fix/sort-keys-fix */
export const NAVIGATION_URLS = {
  // 1. Overview
  OVERVIEW: '/overview', // renamed from /dashboard

  // 2. Tool Stack
  SUBSCRIPTIONS: '/tool-stack', // renamed from /subscriptions
  SPEND_IMPORT: '/spend-import',
  TOOL_DETAILS: '/tool-details',
  TOOL_MATCHING: '/tool-matching',

  // 3. Company Data
  COMPANY_DATA: '/company-data',
  COMPANY_DATA_DEPARTMENTS_TEAMS_TAB: '/company-data#departments-teams',
  COMPANY_DATA_DEPARTMENTS_TEAMS_DETAIL: '/company-data/departments-teams',
  COMPANY_DATA_COST_CENTERS_TAB: '/company-data#cost-centers',
  COMPANY_DATA_COST_CENTERS_DETAIL: '/company-data/cost-centers',
  USER_AND_COMPANY_DATA: '/user-and-company-data',

  // 4. Initiatives
  REQUESTS: '/requests', // to be renamed into /initiatives

  // 5. Support
  SASTRIFY_SUPPORT: '/sastrify-support',
  PROCUREMENT_SUPPORT: '/procurement-support',
  PROCUREMENT_SUPPORT_DETAILS: '/procurement-support/support-details',

  // 6. Tool Store
  TOOL_STORE: '/tool-store',

  // 7. Optimization
  PRICING_BENCHMARKS: '/pricing-benchmarks',
  NEW_PRICING_BENCHMARK: '/pricing-benchmarks/new-price-benchmark',
  NEW_PRICING_BENCHMARK_RESULTS: '/pricing-benchmarks/new-price-benchmark-results',
  SAVINGS: '/savings',
  SASTRICLOUD: '/sastricloud',

  // 8. Settings
  AUTHENTICATION: '/settings',
  USERS_MANAGEMENT: '/users',
  WORKFLOWS: '/workflows', // to be renamed into /task-automation
  REQUESTS_WORKFLOWS: '/request-workflows', // to be renamed into /workflows
  NEW_WORKFLOW: '/request-workflows/new-workflow',
  EDIT_WORKFLOW: '/request-workflows/edit-workflow',
  WORKFLOW_DETAILS: '/request-workflows/workflow-details',
  NOTIFICATIONS: '/notification-settings',
  USAGE_ANALYTICS: '/usage-analytics',
  DISCOVERY: '/discovery',
  TOOLS_SPENDS_IMPORTER: '/discovery/tools-spend-importer',
  CONNECT_EMAIL: '/connect/email',
  CONNECT_GETMYINVOICES: '/connect/getmyinvoices',
  HR_INFORMATION_SYSTEMS: { INDEX: '/hris', DETAILS: '/hris/details' },
};
