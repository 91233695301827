import { useTranslation } from 'react-i18next';
import { NAVIGATION_URLS } from 'src/constants/navigation';

export const useBreadcrumbName = (finalLink?: string): Record<string, string> => {
  const { t } = useTranslation();

  return {
    '/cloud-optimization': 'Cloud Optimization',
    '/connect': 'Connect',
    [`${NAVIGATION_URLS.DISCOVERY}`]: 'Discovery',
    [`${NAVIGATION_URLS.PRICING_BENCHMARKS}`]: t('benchmarks:title'),
    [`${NAVIGATION_URLS.NEW_PRICING_BENCHMARK}`]: t('benchmarks:new_price_benchmark_title'),
    [`${NAVIGATION_URLS.NEW_PRICING_BENCHMARK_RESULTS}`]: t('benchmarks:new_price_benchmark_results_title'),
    [`${NAVIGATION_URLS.REQUESTS_WORKFLOWS}`]: t('request_workflows_view:breadcrumb_text'),
    [`${NAVIGATION_URLS.REQUESTS}`]: t('requests_view:breadcrumb_text'),
    [`${NAVIGATION_URLS.SASTRICLOUD}`]: t('connect_view:tab_section.tabs.cloud_optimization.title'),
    [`${NAVIGATION_URLS.SASTRIFY_SUPPORT}`]: 'Sastrify Support',
    [`${NAVIGATION_URLS.SAVINGS}`]: t('realized_savings_view:breadcrumb'),
    [`${NAVIGATION_URLS.SPEND_IMPORT}`]: 'Spend',
    [`${NAVIGATION_URLS.SUBSCRIPTIONS}`]: t('company_tools_view:title_tools'),
    [`${NAVIGATION_URLS.NOTIFICATIONS}`]: t('notification_settings_page:breadcrumb'),
    [`${NAVIGATION_URLS.TOOLS_SPENDS_IMPORTER}`]: t('tools_spend_importer_view:breadcrumb'),
    [`${NAVIGATION_URLS.TOOL_STORE}`]: 'Tool Store',
    [`${NAVIGATION_URLS.USAGE_ANALYTICS}`]: 'Usage Analytics',
    [`${NAVIGATION_URLS.USERS_MANAGEMENT}`]: t('breadcrumbs:users_and_authentication_link_text'),
    [`${NAVIGATION_URLS.PROCUREMENT_SUPPORT}`]: t('procurement_support_view:title'),
    [`${NAVIGATION_URLS.PROCUREMENT_SUPPORT_DETAILS}`]: finalLink || '',
    [`${NAVIGATION_URLS.EDIT_WORKFLOW}`]: 'Edit Workflow',
    [`${NAVIGATION_URLS.NEW_WORKFLOW}`]: 'New Workflow',
    [`${NAVIGATION_URLS.WORKFLOW_DETAILS}`]: 'Workflow Details',
    [`${NAVIGATION_URLS.TOOL_MATCHING}`]: t('tool_matching_view:breadcrumb_name'),
  };
};
