import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CommentEditActionsProps {
  onCancelClicked: () => void;
  onSaveClicked: () => void;
  isCancelDisabled: boolean;
  isSubmitDisabled: boolean;
  isSubmitLoading: boolean;
}

export const CommentEditActions: React.FC<CommentEditActionsProps> = ({
  isCancelDisabled,
  isSubmitDisabled,
  isSubmitLoading,
  onCancelClicked,
  onSaveClicked,
}) => {
  const { t } = useTranslation();

  return (
    <div className='comment-footer__actions' data-testid='comment-action-buttons'>
      <Button variant='text' color='secondary' disabled={isCancelDisabled} onClick={onCancelClicked}>
        {t('subscription_detail_view:subscription_comment_section.comment_actions_button.cancel_button_text')}
      </Button>
      <Button
        variant='contained'
        color='primary'
        sx={{ ml: 1 }}
        data-testid='save-comment'
        onClick={onSaveClicked}
        disabled={isSubmitDisabled || isSubmitLoading}
        startIcon={isSubmitLoading ? <CircularProgress color='inherit' size={16} /> : null}>
        {t('subscription_detail_view:subscription_comment_section.comment_actions_button.save_button_text')}
      </Button>
    </div>
  );
};
