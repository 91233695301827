import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'asteroids';

export const InfoBoxWrapper = styled(Box)(() => ({
  '& a': {
    color: colors.text.primary,
    textDecoration: 'underline !important',
    '&:hover': {
      color: colors.primary.main,
    },
  },
}));
