import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Button, CircularProgress, Switch, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { followSubscription, unfollowSubscription } from 'shared/logic/subscription-item.logic';
import { SastrifyStore, Subscription } from 'shared/models';

import { HistoryTimelineHeaderComponentProps } from '.';
import { useUnfollowWhenQueryStringIsPresent } from './hooks/use-unfollow';

export const HistoryTimelineHeaderComponent: React.FC<HistoryTimelineHeaderComponentProps> = (props) => {
  const {
    handleSwitchChange,
    isNewSolutionDetailPage,
    isToolRequestsPage,
    isUpdatingFollow,
    setIsUpdatingFollow,
    showNotification,
    subscription,
    switchChecked,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const loggedInUser = useSelector((state: SastrifyStore) => state.authentication.user);

  const updateCacheAndShowMessage = (message: string) => {
    const clonedSubscription: Subscription = { ...subscription, isFollowing: !subscription.isFollowing };
    queryClient.setQueryData(['subscription', subscription.id], clonedSubscription);
    showNotification?.(message, NotificationAlertType.Success);
  };

  const mutateSubscriptionFollow = () =>
    subscription?.isFollowing
      ? unfollowSubscription(subscription?.id)
      : followSubscription(subscription?.id, loggedInUser?.id);

  const { mutate } = useMutation(mutateSubscriptionFollow);

  const onHandleFollowButtonClick = () => {
    setIsUpdatingFollow(true);
    mutate(undefined, {
      onError: (error) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSettled: () => {
        setIsUpdatingFollow(false);
      },
      onSuccess: () => {
        const followMessage =
          isToolRequestsPage || isNewSolutionDetailPage
            ? t(
                'subscription_detail_view:tabs_component_section.subscription_history_tab.history_activity_section.new_solution_follower_notification.started_following_text'
              )
            : t(
                'subscription_detail_view:tabs_component_section.subscription_history_tab.history_activity_section.subscription_follower_notification.started_following_text'
              );
        const unFollowMessage =
          isToolRequestsPage || isNewSolutionDetailPage
            ? t(
                'subscription_detail_view:tabs_component_section.subscription_history_tab.history_activity_section.new_solution_follower_notification.stopped_following_text'
              )
            : t(
                'subscription_detail_view:tabs_component_section.subscription_history_tab.history_activity_section.subscription_follower_notification.stopped_following_text'
              );

        updateCacheAndShowMessage(subscription.isFollowing ? unFollowMessage : followMessage);
      },
    });
  };

  useUnfollowWhenQueryStringIsPresent(() => {
    if (subscription?.isFollowing) {
      onHandleFollowButtonClick();
    }
  });

  return (
    <div data-testid='history-timeline-header'>
      <div className='is-flex is-justify-content-space-between is-align-items-center mb-5'>
        <div>
          <Typography variant='label'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_history_tab.history_activity_section.comments_only_text'
            )}
            :
          </Typography>
          <Switch
            checked={switchChecked}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
        {subscription?.isFollowing ? (
          <Button
            variant='contained'
            color='primary'
            onClick={onHandleFollowButtonClick}
            disabled={isUpdatingFollow}
            startIcon={!isUpdatingFollow ? <Icon>check</Icon> : <CircularProgress color='inherit' size={16} />}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_history_tab.history_activity_section.following_button_text'
            )}
          </Button>
        ) : (
          <Button
            variant='text'
            color='secondary'
            startIcon={isUpdatingFollow ? <CircularProgress color='inherit' size={16} /> : null}
            onClick={onHandleFollowButtonClick}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_history_tab.history_activity_section.follow_updates_button_text'
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
