import { Skeleton } from '@mui/material';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { RequestWorkflowItem } from 'shared/models/request-workflow.model';
import { InitiativeListComponent } from 'views/requests/components/initiative-list';
import { useAddRequestDrawer, useGetInitiativesTableContent, useWorkflowRequestsData } from 'views/requests/hooks';

import { SubscriptionInitiativesBanner } from './components/subscription-initiative-banner/subscription-initiative-banner.component';
import { SubscriptionInitiativeFeatureProps } from './subscription-initiatives.feature.props';
import { InitiativesListWrapper } from './subscription-initiatives.styles';

export const SubscriptionInitiatives: React.FC<SubscriptionInitiativeFeatureProps> = ({ subscriptionId }) => {
  const { t } = useTranslation();
  const { hash } = useLocation();

  const { onAddRequestDrawer } = useAddRequestDrawer();

  const { data: requestsData, isLoading: isRequestsDataLoading } = useWorkflowRequestsData();

  const initiativesData = useMemo(() => {
    return requestsData?.filter(
      (request) =>
        request.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL && request.subscriptionId === subscriptionId
    );
  }, [requestsData, subscriptionId]);

  const columns = useGetInitiativesTableContent().columns.filter(
    (column) => column.header !== t('requests_view:request_list_header.tool_name')
  );

  const handleItemClick = (item: RequestWorkflowItem) => {
    onAddRequestDrawer(`${hash}#${DRAWER_VARIANT.REQUEST_DETAILS}${item.requestId}`);
  };

  const renderTableSkeleton = useMemo(
    () => (
      <>
        <Skeleton variant='rounded' height={40} sx={{ mb: 0.5 }} />
        {[...Array(2)].map(() => (
          <Skeleton key={Math.floor(Math.random() * 100)} variant='rounded' height={50} sx={{ mb: 0.5 }} />
        ))}
      </>
    ),
    []
  );

  return (
    <>
      <SubscriptionInitiativesBanner initiativesData={initiativesData} subscriptionId={subscriptionId} />
      {isRequestsDataLoading ? (
        renderTableSkeleton
      ) : (
        <InitiativesListWrapper>
          <InitiativeListComponent
            columns={columns}
            rows={initiativesData || []}
            onItemClick={handleItemClick}
            enableColumnFilters={false}
            enableSorting={false}
            isSubscriptionInitiatives
          />
        </InitiativesListWrapper>
      )}
    </>
  );
};
