import { Box, Skeleton } from '@mui/material';
import { MaterialReactTable } from 'asteroids';
import { PageFooter } from 'components-2.0/page/components';
import { PaginatedResponse } from 'libs/models';
import { type MRT_ColumnDef, MRT_RowData, MRT_RowSelectionState, MRT_TableState } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getPaginatedSubscriptionSpendAndInvoices } from 'shared/logic/subscription-item.logic';
import { Company, Subscription, SubscriptionSpendAndInvoices } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { selectAllUploads } from 'shared/store/views/subscription/subscription-invoice-upload.slice';

import { useBulkSpendActions } from '../hooks/use-spend-bulk-actions';
import { useSpendTable } from '../hooks/use-spend-table';
import { BulkActionsFooterComponent } from './bulk-actions-footer/bulk-actions-footer.component';
import { UploadedSpendProcessingAlert } from './uploaded-spend-processing-alert/uploaded-spend-processing-alert.component';

type SpendChartProps = {
  subscription: Subscription;
  legends: string[];
};

export const DEFAULT_TABLE_PAGE_SIZE = 50;
export const CACHE_TIME_IN_MILLISECONDS = 1800 * 1000;
export const DEFAULT_COLUMN_SORTING = [{ desc: false, id: 'invoiceDate' }] as MRT_TableState<MRT_RowData>['sorting'];

export const SpendTable: React.FC<SpendChartProps> = ({ legends, subscription }) => {
  const queryClient = useQueryClient();
  const company = queryClient.getQueryData<Company>('company');
  const uploadedInvoices = useAppSelector((state) => selectAllUploads(state.views.subscriptions.invoiceUploads));

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedRows, setSelectedRows] = useState<SubscriptionSpendAndInvoices[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
  });

  const fetchSpendAndInvoices = (
    page: number,
    pageSize: number
  ): Promise<PaginatedResponse<SubscriptionSpendAndInvoices>> => {
    return getPaginatedSubscriptionSpendAndInvoices(subscription?.id, page, pageSize);
  };

  const {
    data: spendData,
    isFetching: isFetchingSpendData,
    isLoading,
    refetch: refetchSpendData,
  } = useQuery({
    cacheTime: CACHE_TIME_IN_MILLISECONDS,
    enabled: Boolean(subscription?.id),
    queryFn: () => fetchSpendAndInvoices(pagination.pageIndex, pagination.pageSize),
    queryKey: ['subscription-spend', pagination.pageIndex, pagination.pageSize],
    refetchInterval: CACHE_TIME_IN_MILLISECONDS,
    refetchOnWindowFocus: false,
  });

  const hideConversionColumn = !spendData?.items.some(
    (spend: SubscriptionSpendAndInvoices) => spend.companyCurrency !== spend.amountCurrency
  );

  useEffect(() => {
    const rows = Object.keys(rowSelection).map((rowId) =>
      spendData?.items?.find((invoice) => Number(invoice.id) === Number(rowId))
    );

    setSelectedRows(rows as SubscriptionSpendAndInvoices[]);
  }, [rowSelection, spendData?.items]);

  const updateSelectedRows = () => {
    setRowSelection({});
  };

  const { columns, openDeleteSpendAndInvoiceModal } = useSpendTable({
    company,
    hideConversionColumn,
    legends,
    refetchSpendData,
    selectedRows,
    setSelectedRows: updateSelectedRows,
    spendData,
    subscription,
    uploadedInvoices,
  });

  const { handleDownloadSpendDataCSV } = useBulkSpendActions({
    company,
    hideConversionColumn,
    legends,
    selectedRows,
    subscription,
  });

  const bulkActionsFooter = useMemo(() => {
    const selectedRowsCount = Object.keys(rowSelection || {}).length;

    return (
      <BulkActionsFooterComponent
        handleBulkDelete={() => openDeleteSpendAndInvoiceModal()}
        exportCsv={() => handleDownloadSpendDataCSV()}
        selectedRowsCount={selectedRowsCount}
      />
    );
  }, [handleDownloadSpendDataCSV, openDeleteSpendAndInvoiceModal, rowSelection]);

  return (
    <>
      <UploadedSpendProcessingAlert uploadedInvoices={uploadedInvoices} />
      {!isFetchingSpendData ? (
        <Box key='spend-material-react-table' data-testid='table' mb={3}>
          <MaterialReactTable
            layoutMode='grid'
            data={spendData?.items ?? []}
            columns={columns as MRT_ColumnDef<object>[]}
            manualPagination
            rowCount={spendData?.total ?? 0}
            enableDensityToggle={false}
            enableGlobalFilter={false}
            onRowSelectionChange={(selection: unknown) => setRowSelection(selection as MRT_RowSelectionState)}
            enableFullScreenToggle
            enableSorting={false}
            enableColumnActions={false}
            selectAllMode='all'
            onPaginationChange={setPagination}
            state={{
              isLoading: isFetchingSpendData ?? isLoading,
              pagination,
              rowSelection,
              sorting: DEFAULT_COLUMN_SORTING,
            }}
            initialState={{
              columnPinning: {
                right: ['actions'],
              },
              isLoading: isFetchingSpendData || isLoading,
              pagination,
              rowSelection,
              sorting: DEFAULT_COLUMN_SORTING,
            }}
            enableMultiRowSelection
            enableRowSelection
            getRowId={(originalRow: unknown) => (originalRow as Subscription)?.id || ''}
            muiTableHeadCellProps={{
              sx: {
                '& .Mui-TableHeadCell-Content-Labels': {
                  overflow: 'visible',
                },
              },
            }}
          />
        </Box>
      ) : null}
      {isFetchingSpendData && (
        <Box>
          <Skeleton sx={{ marginBottom: 1 }} variant='rounded' height={32} width='100%' animation='wave' />
          <Skeleton sx={{ marginBottom: 1 }} variant='rounded' height={32} width='100%' animation='wave' />
          <Skeleton sx={{ marginBottom: 1 }} variant='rounded' height={32} width='100%' animation='wave' />
          <Skeleton sx={{ marginBottom: 1 }} variant='rounded' height={32} width='100%' animation='wave' />
          <Skeleton sx={{ marginBottom: 1 }} variant='rounded' height={32} width='100%' animation='wave' />
          <Skeleton sx={{ marginBottom: 1 }} variant='rounded' height={32} width='100%' animation='wave' />
        </Box>
      )}
      {Object.keys(rowSelection || {}).length > 0 && <PageFooter renderViaPortal>{bulkActionsFooter}</PageFooter>}
    </>
  );
};
