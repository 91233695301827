export enum INVOICE_DOCUMENT_TYPE {
  SUBSCRIPTION_DOCUMENT = 'subscriptionDocument',
  COMPANY_DOCUMENT = 'companyDocument',
}

export enum INVOICE_ORIGIN {
  INVOICE_ERROR_QUEUE = 'invoice_error_queue',
  INVOICE_DLQ = 'invoice_dlq',
  USER_EDITED = 'user_edited',
}
