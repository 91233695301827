import { SOURCE_NAME, TYPE } from 'libs/constants/tool-usage.constants';
import { TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { DrawerInfoType } from 'shared/common.definitions';
import {
  BaseUsageAnalyticsTrackingParams,
  trackConnected,
  trackConnectedError,
} from 'shared/logic/event-tracking/usage-analytics.events';
import {
  createDirectWorkatoIntegration,
  getWorkatoConnectionId,
  startDirectWorkatoIntegration,
  updateWorkatoVendor,
} from 'shared/logic/tool-usage-analytics.logic';
import { CreateWorkatoIntegrationResponse } from 'shared/models/tool-usage-analytics.model';
import { show as showDrawer } from 'shared/store/common/appDrawer.slice';
import { useAppSelector } from 'shared/store/hooks';
import { useUser } from 'src/app/hooks/useUser';
import { AppUrl } from 'src/constants/appurl';

import { ConnectionStatus, StartIntegrationStatus } from '../components/start-integration-status.component';
import { UsageAnalyticsContent } from '../components/usage-analytics-content.component';
import { useWorkatoMessage } from '../use-workato-message.component';
import UsageAnalyticsInformationDirectComponent from './usage-analytics-information-direct.component';

type IProps = {
  handleCloseDrawer: () => void;
};

export const DrawerUsageAnalyticsDirect: FC<IProps> = ({ handleCloseDrawer }) => {
  const [connectionDetails, setConnectionDetails] = useState<CreateWorkatoIntegrationResponse>();
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [workatoRecipeError, setWorkatoRecipeError] = useState(false);
  const [startIntegrationSuccess, setStartIntegrationSuccess] = useState(false);
  const [startIntegrationError, setStartIntegrationError] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { canStartIntegration, hasIframeError, iframeHeight, receiveIframeMessage } = useWorkatoMessage();

  const selectedVendor = useAppSelector((state) => state.views.connect.usageAnalyticsItem.selectedVendor);

  const queryClient = useQueryClient();

  const user = useUser();
  const location = useLocation();
  const baseTrackingEventProps: BaseUsageAnalyticsTrackingParams = {
    analyticsProviderName: selectedVendor?.vendorName ?? '',
    analyticsProviderType: TYPE.DIRECT,
    analyticsSourceName: SOURCE_NAME.WORKATO,
    companyId: selectedVendor?.companyId ?? '',
    userId: user.id ?? '',
  };

  const { mutate: createDirectIntegration } = useMutation(createDirectWorkatoIntegration, {
    onError: (error) => {
      setWorkatoRecipeError(true);
      trackConnectedError(
        {
          ...baseTrackingEventProps,
          errorMessage: `Error creating direct integration, error: ${error}`,
        },
        location.pathname
      );
    },
    onSettled: () => setIsLoadingIframe(false),
    onSuccess: (data) => setConnectionDetails(data),
  });

  const { mutate: startDirectIntegration } = useMutation(startDirectWorkatoIntegration, {
    onError: (error) => {
      setStartIntegrationError(true);
      trackConnectedError(
        {
          ...baseTrackingEventProps,
          errorMessage: `Error starting direct integration, error: ${error}`,
        },
        location.pathname
      );
    },
    onSuccess: () => {
      setStartIntegrationSuccess(true);
      updateWorkatoVendor(queryClient, selectedVendor?.subscriptionId || '', TOOL_USAGE_INTEGRATION_STATE.CONNECTED);
      trackConnected(baseTrackingEventProps, location.pathname);
    },
  });

  useEffect(() => {
    if (canStartIntegration && selectedVendor && connectionDetails?.connection?.isRunning === false) {
      setIsLoadingIframe(true);
      startDirectIntegration({
        companyId: selectedVendor.companyId,
        subscriptionId: selectedVendor.subscriptionId,
      });
    }
  }, [connectionDetails?.connection?.isRunning, selectedVendor, startDirectIntegration, canStartIntegration]);

  useEffect(() => {
    setWorkatoRecipeError(hasIframeError);
  }, [hasIframeError]);

  useEffect(() => {
    const abortSignal = new AbortController();
    if (selectedVendor) {
      createDirectIntegration({
        companyId: selectedVendor.companyId,
        companyName: selectedVendor.companyName,
        signal: abortSignal.signal,
        subscriptionId: selectedVendor.subscriptionId,
        vendorId: selectedVendor.vendorId,
      });
    }
  }, [createDirectIntegration, queryClient, selectedVendor]);

  useEffect(() => {
    window.addEventListener('message', receiveIframeMessage);
  }, [receiveIframeMessage]);

  return (
    <UsageAnalyticsContent
      informationSection={<UsageAnalyticsInformationDirectComponent />}
      startIntegrationStatus={
        <StartIntegrationStatus
          status={startIntegrationSuccess ? ConnectionStatus.SUCCESS : ConnectionStatus.ERROR}
          closeHandler={handleCloseDrawer}
          successProps={{
            action: () => {
              handleCloseDrawer();
              if (selectedVendor)
                push(
                  AppUrl.getToolDetailsUrl({ id: selectedVendor?.subscriptionId, name: selectedVendor?.vendorName })
                );
            },
            actionTitle: t('connect_view:tab_section.tabs.usage_analytics.connection_success.direct.button_text'),
            description: t('connect_view:tab_section.tabs.usage_analytics.connection_success.direct.description'),
            title: t('connect_view:tab_section.tabs.usage_analytics.connection_success.title', {
              name: selectedVendor?.vendorName,
            }),
          }}
          errorProps={{
            action: () => dispatch(showDrawer(DrawerInfoType.SASTRIFY_APP_HELP)),
            actionTitle: t('common:contact_sastrify'),
            description: t(
              'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.connection_error:description'
            ),
            title: t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.connection_error:title'),
          }}
        />
      }
      connectionId={getWorkatoConnectionId(connectionDetails?.connection?.connectionIds || [])}
      token={connectionDetails?.token || ''}
      isLoadingIframe={isLoadingIframe}
      workatoRecipeError={workatoRecipeError}
      startIntegrationSuccess={startIntegrationSuccess}
      startIntegrationError={startIntegrationError}
      iframeHeight={iframeHeight}
      providerName={selectedVendor?.vendorName || ''}
      handleCloseDrawer={handleCloseDrawer}
      title={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.title_direct', {
        vendorName: selectedVendor?.vendorName,
      })}
    />
  );
};
