import { Analytics, AnalyticsBrowser, Options } from '@segment/analytics-next';
import { UserIdentityTrait } from 'shared/common.definitions';

class EventTracking {
  private analytics: Analytics | undefined;

  private isInternalUser = false;

  constructor() {
    this.analytics = undefined;

    this.loadAnalytics()
      .then((analytics) => {
        this.analytics = analytics;
      })
      .catch(() => {
        // send debug message
      });
  }

  private async loadAnalytics(): Promise<Analytics> {
    const [response] = await AnalyticsBrowser.load({ writeKey: process.env.SEGMENT_KEY || '' });
    return response;
  }

  get analyticsInstance() {
    return this.analytics;
  }

  trackEvent<T extends object>(eventName: string, eventProperties?: T, options?: Options): void {
    if (this.isInternalUser) {
      // eslint-disable-next-line no-console
      console.log(
        `Event: '${eventName}' was not sent because the app is configured not to track usage of internal users.`,
        eventProperties
      );
      return;
    }

    if (!this.analyticsInstance) {
      // eslint-disable-next-line no-console
      console.log(
        `Event: '${eventName}' was not sent because the analyticsInstance is not configured.`,
        eventProperties
      );
      return;
    }

    this.analyticsInstance.track(eventName, eventProperties, options).then(
      () => {
        // event sent successfully.
      },
      (reason) => {
        // failed to track the event
        // eslint-disable-next-line no-console
        console.log(`Event: '${eventName}' was not sent because of an error.`, eventProperties, reason);
      }
    );
  }

  identify(userId: string, userProfile: UserIdentityTrait): void {
    const { companyId, companyName, email, isSastrifyEmailAddress, name } = userProfile;
    this.isInternalUser = isSastrifyEmailAddress;

    if (this.analyticsInstance) {
      this.analyticsInstance
        .identify(userId, {
          company: {
            id: companyId,
          },
          companyName,
          email,
          name,
        })
        .then(
          () => {
            // event sent successfully.
          },
          () => {
            // failed to track the event
          }
        );
      this.analyticsInstance.group(companyId, { company: companyId });
    }
  }
}

export default new EventTracking();
