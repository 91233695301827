import { IconButton } from '@mui/material';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../icon/icon.component';
import { InputField } from '../input-field/input-field.component';
import { PasswordInputFieldProps } from './password-input-field.props';

export const PasswordInputField = forwardRef<HTMLInputElement, PasswordInputFieldProps>((props, ref) => {
  const { label, noLabel, ...rest } = props;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputField
      {...rest}
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      label={noLabel ? undefined : label || t('asteroids:passwordInputField.password')}
      endAdornment={
        <IconButton
          onClick={togglePasswordVisibility}
          aria-label={t('asteroids:passwordInputField.passwordToggle')}
          edge='end'>
          <Icon variant='Filled'>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
        </IconButton>
      }
    />
  );
});

export default PasswordInputField;
