import React from 'react';
import { colors } from 'shared/theme';

export const SubscriptionBadgeIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ height, width }) => (
  <svg
    width={width || undefined}
    height={height || undefined}
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.9043 9.18514L17.6332 7.32571L17.7701 5.08003C17.7838 4.85407 17.6594 4.64233 17.4554 4.54436L15.4291 3.57165L14.4564 1.54536C14.3571 1.33852 14.1409 1.21368 13.9123 1.23123L11.675 1.40168L9.82125 0.100326C9.62932 -0.0344185 9.37357 -0.0344185 9.18164 0.100326L7.32791 1.40164L5.09063 1.2312C4.86218 1.21364 4.64579 1.33848 4.54653 1.54533L3.57378 3.57161L1.5475 4.54432C1.34065 4.64363 1.21589 4.85964 1.23333 5.08842L1.40377 7.32571L0.102495 9.17947C-0.0322119 9.3714 -0.0322119 9.62719 0.102495 9.81912L1.40381 11.6728L1.23337 13.9101C1.21592 14.1389 1.34069 14.3549 1.54753 14.4542L3.57382 15.4269L4.54653 17.4532C4.64583 17.6601 4.86166 17.7849 5.09063 17.7674L7.32791 17.5969L9.18164 18.8982C9.2776 18.9656 9.38952 18.9993 9.50144 18.9993C9.61337 18.9993 9.72529 18.9656 9.82125 18.8982L11.675 17.5969L13.9123 17.7674C14.1406 17.7848 14.3571 17.66 14.4564 17.4532L15.4291 15.4269L17.4554 14.4542C17.6622 14.355 17.787 14.1389 17.7695 13.9101L17.5991 11.6728L18.9004 9.81912C19.0337 9.62923 19.0352 9.37663 18.9043 9.18514Z'
      fill={colors.secondary}
    />
    <path
      d='M9.5 3.37695C6.12375 3.37695 3.37695 6.12375 3.37695 9.5C3.37695 12.8762 6.12375 15.623 9.5 15.623C12.8762 15.623 15.623 12.8762 15.623 9.5C15.623 6.12375 12.8762 3.37695 9.5 3.37695Z'
      fill={colors.frostedMint}
    />
    <g clipPath='url(#clip1)'>
      <path
        d='M9.4933 10.4494C9.18025 10.4518 8.87007 10.3901 8.5822 10.2682C8.30497 10.1507 8.05354 9.98065 7.84201 9.7677C7.72095 9.64475 7.61381 9.50905 7.5225 9.36304C7.45332 9.4703 7.39302 9.58294 7.34217 9.69984C7.22026 9.98386 7.15825 10.2896 7.15995 10.5982V12.9107L9.64944 10.4438C9.59666 10.4472 9.54577 10.4494 9.4933 10.4494Z'
        fill={colors.brightSun}
      />
      <path
        d='M11.8327 10.5982C11.8344 10.2896 11.7724 9.98389 11.6505 9.69988C11.6 9.58384 11.5404 9.47186 11.4724 9.36494L11.4699 9.36121C11.3787 9.21506 11.2716 9.07935 11.1503 8.95654L9.65112 10.4417L9.64892 10.4438L7.15942 12.9107H9.49875C9.81175 12.9133 10.1219 12.8518 10.4098 12.7302C10.6861 12.6131 10.9373 12.4448 11.1497 12.2343C11.1758 12.2085 11.2012 12.182 11.2257 12.1552C11.4027 11.9622 11.5461 11.7413 11.6499 11.5016C11.773 11.2161 11.8352 10.9086 11.8327 10.5982Z'
        fill={colors.crusta}
      />
      <path
        d='M11.8327 5.81348L9.34253 8.28067C9.39562 8.27725 9.44935 8.27476 9.50339 8.27476C9.81641 8.27228 10.1266 8.33385 10.4145 8.45561C10.6916 8.57332 10.943 8.74332 11.1547 8.95615C11.2756 9.07924 11.3827 9.21492 11.4742 9.36081C11.5434 9.25366 11.6039 9.14125 11.6551 9.02463C11.7771 8.74063 11.8392 8.43492 11.8377 8.12628V5.81348H11.8327Z'
        fill={colors.turquoise}
      />
      <path
        d='M9.49327 5.81357C9.18025 5.8108 8.87004 5.87227 8.58217 5.99411C8.02447 6.23002 7.58023 6.67016 7.34213 7.22273C7.22185 7.50811 7.15991 7.8143 7.15991 8.12357C7.15991 8.43284 7.22185 8.73903 7.34213 9.02441C7.39337 9.14184 7.45388 9.25508 7.5231 9.36308C7.6144 9.5091 7.72154 9.64479 7.84261 9.76775L9.34184 8.28263L9.34372 8.28077L11.8338 5.81357H9.49327Z'
        fill={colors.gossamer}
      />
      <path
        d='M9.65112 10.4416C9.59897 10.445 9.5465 10.4494 9.49403 10.4494C9.18098 10.4518 8.8708 10.3901 8.58293 10.2682C8.3057 10.1507 8.05427 9.98065 7.84275 9.7677C7.72168 9.64475 7.61454 9.50905 7.52323 9.36304C7.45406 9.4703 7.39375 9.58294 7.3429 9.69984C7.22099 9.98385 7.15898 10.2896 7.16068 10.5982V10.8783C8.74285 11.1843 9.64986 10.4426 9.65112 10.4416Z'
        fill={colors.ripeLemon}
      />
      <path
        d='M9.34546 8.28514C9.39793 8.28172 9.45008 8.27767 9.50254 8.27767C9.81557 8.27515 10.1258 8.33672 10.4136 8.45852C10.6902 8.57628 10.9408 8.74654 11.1513 8.95968C11.2733 9.08344 11.3811 9.22008 11.473 9.36715C11.5428 9.25874 11.6038 9.14496 11.6553 9.02692C11.7773 8.74292 11.8394 8.43721 11.8378 8.12857V7.84842C10.254 7.54243 9.34703 8.28421 9.34546 8.28514Z'
        fill={colors.aquamarineBlue}
      />
    </g>
  </svg>
);

SubscriptionBadgeIcon.defaultProps = {
  height: 19,
  width: 19,
};
