import { Box, styled } from '@mui/material';
import { shadows } from 'asteroids';

export const SubscriptionAlertSummaryWrapper = styled(Box)(({ theme }) => ({
  '& .alert-container': {
    '& .alert-item': {
      '& .alert-text': {
        alignItems: 'center',
        display: 'flex',
      },
      '& .date': {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
      },
      display: 'flex',
      fontSize: '0.875rem',
      fontStyle: 'italic',
      lineHeight: '1.063rem',
    },

    '& .year-text': {
      borderRadius: theme.spacing(0.5),
      color: theme.palette.tertiary.main,
      display: 'flex',
      fontSize: '.875rem',
      fontWeight: '700',
      height: '1.125rem',
      lineHeight: '1.074rem',
    },
    marginTop: theme.spacing(2),
  },

  '& .title-text': {
    color: theme.palette.tertiary.main,
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.074rem',
  },
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  boxShadow: shadows.card,
  marginBottom: theme.spacing(1.5),
  padding: theme.spacing(1.5),
}));
