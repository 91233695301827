import { ListSubheader, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';

const LISTBOX_PADDING = 8;
const SMALL_SCREEN_ITEM_SIZE = 36;
const DESKTOP_SCREEN_ITEM_SIZE = 48;
const DEFAULT_VISIBLE_ITEMS_COUNT = 5;

interface ListboxComponentProps {
  children?: React.ReactNode;
  visibleItemsCount?: number;
}

export const ListboxComponent = React.forwardRef<HTMLDivElement, ListboxComponentProps>((props, ref) => {
  const { children, visibleItemsCount = DEFAULT_VISIBLE_ITEMS_COUNT, ...other } = props;

  const itemData = React.Children.toArray(children);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smallScreen ? SMALL_SCREEN_ITEM_SIZE : DESKTOP_SCREEN_ITEM_SIZE;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return DESKTOP_SCREEN_ITEM_SIZE;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > visibleItemsCount) {
      return visibleItemsCount * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const renderItem = (index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <section>{React.cloneElement(itemData[index] as React.DetailedReactHTMLElement<any, HTMLElement>)}</section>;
  };

  const getVirtuosoStyle = (): Partial<React.CSSProperties> => ({
    height: getHeight() + 2 * LISTBOX_PADDING,
    overflowY: 'scroll',
  });

  return (
    <div ref={ref} {...other}>
      <Virtuoso
        style={getVirtuosoStyle()}
        className='virtual-list'
        data={itemData}
        itemContent={(index) => renderItem(index)}
      />
    </div>
  );
});
