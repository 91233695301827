import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, InputLabel } from '@mui/material';
import { PasswordInputField } from 'asteroids';
import { ConnectSection } from 'components/drawer-manage-sso-integration/components/connect-section.component';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { SsoIntegrationToolName } from 'libs/constants';
import { PERMISSION } from 'libs/enums';
import { FC, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  SSOForDiscoveryTrackingParams,
  trackConnectedForDiscovery,
  trackConnectedForDiscoveryError,
} from 'shared/logic/event-tracking/usage-analytics.events';
import { hide as hideDrawer } from 'shared/store/common/appDrawer.slice';
import { connectToJumpCloud } from 'shared/store/common/jumpCloudConnect.slice';
import { useCheckUserPermission, useModalAndNotification, useUser } from 'src/app/hooks';
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from 'src/constants/common';
import * as yup from 'yup';

type FormData = { apiKey: string };

export const JumpCloudConnectSection: FC = () => {
  const { t } = useTranslation();
  const hasManageIntegrationsPermission = useCheckUserPermission(PERMISSION.MANAGE_INTEGRATIONS);
  const user = useUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleCloseDrawer = useCallback(() => dispatch(hideDrawer()), [dispatch]);

  const { onShowNotification } = useModalAndNotification();

  const baseTrackingEventProps: SSOForDiscoveryTrackingParams = {
    companyId: user.companyId ?? '',
    sourceName: SsoIntegrationToolName.JUMP_CLOUD,
    userId: user.id ?? '',
  };

  const jumpCloudValidationSchema = yup.object().shape({
    apiKey: yup.string().required(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(jumpCloudValidationSchema),
  });

  const onSubmit = ({ apiKey }: FormData) => {
    if (!hasManageIntegrationsPermission || !user.companyId) {
      return;
    }

    if (Object.keys(errors).length === 0) {
      dispatch(
        connectToJumpCloud({
          data: { apiKey, companyId: user.companyId },
          onError: (errorMessage) => {
            trackConnectedForDiscoveryError({ ...baseTrackingEventProps, errorMessage }, location.pathname);
            onShowNotification(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
          },
          onSuccess: () => {
            trackConnectedForDiscovery(baseTrackingEventProps, location.pathname);
            onShowNotification(
              t('manage_sso_integrations_view:successfully_connected', {
                ssoProviderName: SsoIntegrationToolName.JUMP_CLOUD,
              }),
              NotificationAlertType.Success
            );
          },
        })
      );

      handleCloseDrawer();
    }
  };

  return (
    <>
      <ConnectSection
        ssoProviderName='JumpCloud'
        guideUrl='https://docs.jumpcloud.com/api/2.0/index.html#section/API-Key/Access-Your-API-Key'
      />
      <Box component='form' onSubmit={handleSubmit(onSubmit)} data-testid='jump-cloud-connect-form'>
        <Box>
          <InputLabel
            variant='standard'
            htmlFor='jump-cloud-api-key'
            sx={{
              cursor: 'pointer',
              fontWeight: 600,
              marginBottom: 1.5,
            }}
            data-testid='api-key-input-label'>
            {t('manage_sso_integrations_view:jump_cloud.api_key')}
          </InputLabel>
          <Box sx={{ '& .MuiFormControl-root': { width: '100%' } }}>
            <Controller
              name='apiKey'
              control={control}
              defaultValue=''
              render={({ field }) => {
                return (
                  <PasswordInputField
                    type='password'
                    noLabel
                    id='jump-cloud-api-key'
                    sx={{ '& .MuiInputAdornment-root': { marginRight: 1 } }}
                    error={Boolean(errors.apiKey)}
                    data-testid='api-key-input-wrapper'
                    inputProps={{ 'data-testid': 'api-key-input' }}
                    {...field}
                  />
                );
              }}
            />
          </Box>
        </Box>
        <Button
          type='submit'
          color='primary'
          variant='contained'
          sx={{ marginTop: 3 }}
          data-testid='jump-cloud-connect-btn'>
          {t('manage_sso_integrations_view:jump_cloud.connect_button')}
        </Button>
      </Box>
    </>
  );
};
