import { styled } from '@mui/material';
import { Dialog } from 'asteroids';
import { useMemo, useState } from 'react';

import { AsteroidsDialogContext, DialogProps } from '../context/asteroids-dialog.context';

interface AsteroidsDialogProviderProps {
  children: React.ReactNode;
}

const StyledDialog = styled(Dialog)(() => ({
  '& .dialog-content-scroll-wrapper': {
    overflow: 'hidden !important',
  },
}));

export const AsteroidsDialogProvider: React.FC<AsteroidsDialogProviderProps> = ({ children, ...rest }) => {
  const [dialog, setDialog] = useState<DialogProps>({
    open: false,
  });

  const contextValue = useMemo(
    () => ({
      closeDialog: () => setDialog({ open: false }),
      openDialog: (dialogProps: Omit<DialogProps, 'open'>) => setDialog({ ...dialogProps, open: true }),
    }),
    []
  );

  return (
    <AsteroidsDialogContext.Provider value={contextValue} {...rest}>
      {children}
      <StyledDialog {...dialog} />
    </AsteroidsDialogContext.Provider>
  );
};
