import { AxiosResponse } from 'axios';
import { SubscriptionContract, SubscriptionResellerInformation } from 'libs/models/document-data.model';

import { SubscriptionDetailsModel } from '../models';
import { DetailsSaveSchema, SubscriptionSummaryModel } from '../models/subscription-details.model';
import { apiService, apiUrl } from '../services';

export function fetchSubscriptionDetails(subscriptionId: string): Promise<SubscriptionDetailsModel[]> {
  return apiService.get(apiUrl.subscriptionDetails(subscriptionId)).then((response: AxiosResponse) => {
    return response.data as SubscriptionDetailsModel[];
  });
}

export const saveSubscriptionDetails = (
  subscriptionId: string,
  details: DetailsSaveSchema
): Promise<SubscriptionDetailsModel> => {
  return apiService
    .patch(apiUrl.subscriptionDetails(subscriptionId), details)
    .then((response: AxiosResponse<SubscriptionDetailsModel>) => {
      return response.data;
    });
};

export async function fetchSubscriptionSummary(subscriptionId: string): Promise<SubscriptionSummaryModel> {
  const response = await apiService.get(apiUrl.subscriptionSummary(subscriptionId));
  return response.data as SubscriptionSummaryModel;
}

export function updateContractDetails(contractDetails: SubscriptionContract): Promise<SubscriptionContract> {
  return apiService
    .post(apiUrl.contractDetails(contractDetails.subscriptionId), contractDetails)
    .then((response: AxiosResponse) => {
      return response.data as SubscriptionContract;
    });
}

export function updateResellerInformation({
  data,
  subscriptionId,
}: {
  data: SubscriptionResellerInformation;
  subscriptionId: string;
}): Promise<void> {
  return apiService.patch(apiUrl.resellerInformation(subscriptionId), data).then((response: AxiosResponse) => {
    return response.data;
  });
}
