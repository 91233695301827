import './vendor-insight-modal-content.component.scss';

import { Box, Button, Chip, CircularProgress, Typography } from '@mui/material';
import error404 from 'assets/images/errors/error-404.svg';
import { Avatar } from 'asteroids';
import classNames from 'classnames';
import { StatusDisplay } from 'components/design-system/status-display/status-display.component';
import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionStatus } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { getSubscriptionStatus } from 'shared/logic/subscription-item.logic';
import { formatDateWithoutTimeZone } from 'views/overview/calendar/subscription-indicator/helpers';

import { VendorInsightContent } from './vendor-insight-content/vendor-insight-content.component';
import { VendorInsightComponentProps } from './vendor-insight-modal-content.props';

export const VendorInsightComponent: FC<VendorInsightComponentProps> = (props) => {
  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isVendorInsightsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);

  const { t } = useTranslation();
  const {
    category,
    closeButtonLabel = t('common:modals.confirmation_modal.close_button_text'),
    content,
    isLoading,
    level = 'info',
    onClose,
    publishedAt,
    subscription,
    vendor,
  } = props;

  // TODO: replace components and styles once design system is ready

  const handleOnCloseClick = () => {
    onClose?.();
  };

  const renewText = subscription?.renewalDate
    ? `${t('vendor_insight:vendor_insight_modal.renew_text')} ${formatDateWithoutTimeZone(
        subscription.renewalDate,
        'dd MMM yyyy'
      )}`
    : undefined;

  if (!isVendorInsightsEnabled) {
    /**
     * The ErrorView component was not used because the Back button
     * in that view redirects to dashboard, but this modal is opened
     * from the dashboard view.
     * The URL is like this:
     * http://<environment>/dashboard?insightId=262797993&vendorId=36
     * */
    const code = 404;
    return (
      <StatusDisplay
        title={t(`routes:error_${code}:title`)}
        description={t(`routes:error_${code}:description`)}
        icon={error404}
        primaryButton={{
          children: t('routes:back_button_label'),
          dataTestId: 'back-button',
          onClick: handleOnCloseClick,
        }}
      />
    );
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 5 }}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <Box className={`vendor-insight-wrapper ${level}`} display='flex' flexDirection='column'>
      <Box>
        <LogoWithStatus
          logoUrl={subscription?.vendorLogoUrl || vendor?.vendorLogoUrl || undefined}
          title={subscription?.vendorName || vendor?.name || undefined}
          subtitle={renewText}
          subscriptionStatus={subscription?.state}
          categoryImageUrl={category?.image}
          categoryLabel={category?.label}
        />

        <VendorInsightContent
          vendorKnowledgeItem={content}
          subscriptionId={subscription?.id}
          vendorId={vendor?.id}
          publishedAt={publishedAt}
        />
      </Box>
      <Box className='action-buttons-wrapper' display='flex' justifyContent='flex-end'>
        <Button
          onClick={handleOnCloseClick}
          className='action-button-close'
          variant='text'
          data-testid='modal-close-button'>
          {closeButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};

interface LogoWithStatusProps {
  logoUrl?: string;
  title?: string;
  subscriptionStatus?: SubscriptionStatus;
  subtitle?: string;
  categoryLabel?: string;
  categoryImageUrl?: string;
}
const LogoWithStatus: FC<LogoWithStatusProps> = ({
  categoryImageUrl,
  categoryLabel,
  logoUrl,
  subscriptionStatus,
  subtitle,
  title,
}) => {
  const { t } = useTranslation();
  const { className, status } = getSubscriptionStatus(subscriptionStatus);
  const subscriptionStatusText = subscriptionStatus ? t(status) : null;
  return (
    <>
      <Box className='vendor-insight-logo-wrapper' display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <Avatar alt={title} src={logoUrl} variant='rounded' size='large' fitImage data-testid='vendor-insight-logo' />
          <Box display='flex' flexDirection='column' sx={{ ml: 1 }}>
            <Box display='flex' alignItems='center'>
              <Typography className='vendor-insight-name' variant='h2' data-testid='vendor-insight-name'>
                {title}
              </Typography>
              {subscriptionStatusText && (
                <>
                  <span className={classNames('dot', className)} />
                  <Typography
                    className='vendor-insight-subscription-status'
                    variant='subtitle2'
                    data-testid='vendor-insight-subscription-status'>
                    {subscriptionStatusText.toLowerCase()}
                  </Typography>
                </>
              )}
            </Box>
            {subtitle && (
              <Typography className='vendor-insight-renewal' variant='subtitle2' data-testid='vendor-insight-renewal'>
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        {categoryImageUrl && (
          <img
            src={categoryImageUrl}
            alt={categoryLabel}
            className='vendor-insight-category-image'
            data-testid='vendor-insight-category-image'
          />
        )}
      </Box>
      {categoryLabel && (
        <Chip
          label={categoryLabel}
          className='vendor-insight-category-chip'
          data-testid='vendor-insight-category-chip'
        />
      )}
    </>
  );
};
