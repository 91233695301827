import { AxiosResponse } from 'axios';
import { ReplyableType } from 'shared/common.definitions';

import { ReplyableResponseModel, ReplyableSaveModel } from '../models/replyable.model';
import { apiService, apiUrl } from '../services';

interface UpdateReplyPayload {
  replyableType: ReplyableType;
  replyableId: string;
  replyId: string;
  replyable: ReplyableSaveModel;
}

export const fetchReplyable = (
  replyableType: ReplyableType,
  replyableId: string
): Promise<ReplyableResponseModel[]> => {
  return apiService
    .get(apiUrl.getReplyableOfTypeAndId(replyableType, replyableId))
    .then((response: AxiosResponse<ReplyableResponseModel[]>) => {
      return response.data;
    });
};

export const saveReplyable = (
  replyableType: ReplyableType,
  replyableId: string,
  replyable: ReplyableSaveModel
): Promise<ReplyableResponseModel> => {
  return apiService
    .post(apiUrl.getReplyableOfTypeAndId(replyableType, replyableId), replyable)
    .then((response: AxiosResponse<ReplyableResponseModel>) => {
      return response.data;
    });
};

export const updateReplyable = ({
  replyId,
  replyable,
  replyableId,
  replyableType,
}: UpdateReplyPayload): Promise<ReplyableResponseModel> => {
  return apiService
    .patch(apiUrl.getReplyOfReplyableOfTypeAndId(replyableType, replyableId, replyId), replyable)
    .then((response: AxiosResponse<ReplyableResponseModel>) => {
      return response.data;
    });
};

export const deleteReplyable = (
  replyableType: ReplyableType,
  replyableId: string,
  replyId: string
): Promise<ReplyableResponseModel> => {
  return apiService
    .delete(apiUrl.getReplyOfReplyableOfTypeAndId(replyableType, replyableId, replyId))
    .then((response: AxiosResponse<ReplyableResponseModel>) => {
      return response.data;
    });
};
