import { TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { DrawerInfoType } from 'shared/common.definitions';
import {
  createSsoWorkatoIntegration,
  getWorkatoConnectionId,
  startSsoWorkatoIntegration,
  updateWorkatoSsoProvider,
} from 'shared/logic/tool-usage-analytics.logic';
import { CreateWorkatoIntegrationResponse } from 'shared/models/tool-usage-analytics.model';
import { show as showDrawer } from 'shared/store/common/appDrawer.slice';
import { useAppSelector } from 'shared/store/hooks';

import { ConnectionStatus, StartIntegrationStatus } from '../components/start-integration-status.component';
import { UsageAnalyticsContent } from '../components/usage-analytics-content.component';
import { useWorkatoMessage } from '../use-workato-message.component';
import UsageAnalyticsInformationSsoComponent from './usage-analytics-information-sso.component';

type IProps = {
  handleCloseDrawer: () => void;
};

export const DrawerUsageAnalyticsSso: FC<IProps> = ({ handleCloseDrawer }) => {
  const [connectionDetails, setConnectionDetails] = useState<CreateWorkatoIntegrationResponse>();
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [workatoRecipeError, setWorkatoRecipeError] = useState(false);
  const [startIntegrationSuccess, setStartIntegrationSuccess] = useState(false);
  const [startIntegrationError, setStartIntegrationError] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { canStartIntegration, hasIframeError, iframeHeight, receiveIframeMessage } = useWorkatoMessage();

  const selectedSsoProvider = useAppSelector((state) => state.views.connect.usageAnalyticsItem.selectedSsoProvider);

  const queryClient = useQueryClient();

  const { mutate: createSsoIntegration } = useMutation(createSsoWorkatoIntegration, {
    onError: () => {
      setWorkatoRecipeError(true);
    },
    onSettled: () => setIsLoadingIframe(false),
    onSuccess: (data) => setConnectionDetails(data),
  });

  const { mutate: startSsoIntegration } = useMutation(startSsoWorkatoIntegration, {
    onError: () => {
      setStartIntegrationError(true);
    },
    onSuccess: () => {
      setStartIntegrationSuccess(true);
      updateWorkatoSsoProvider(
        queryClient,
        selectedSsoProvider?.ssoProviderName || '',
        TOOL_USAGE_INTEGRATION_STATE.CONNECTED
      );
    },
  });

  useEffect(() => {
    if (canStartIntegration && selectedSsoProvider && connectionDetails?.connection?.isRunning === false) {
      setIsLoadingIframe(true);
      startSsoIntegration({
        companyId: selectedSsoProvider.companyId,
        ssoName: selectedSsoProvider.ssoProviderName,
      });
    }
  }, [connectionDetails?.connection?.isRunning, selectedSsoProvider, startSsoIntegration, canStartIntegration]);

  useEffect(() => {
    setWorkatoRecipeError(hasIframeError);
  }, [hasIframeError]);

  useEffect(() => {
    const abortSignal = new AbortController();
    createSsoIntegration({
      companyId: selectedSsoProvider?.companyId || '',
      companyName: selectedSsoProvider?.companyName || '',
      signal: abortSignal.signal,
      ssoName: selectedSsoProvider?.ssoProviderName || '',
    });
  }, [createSsoIntegration, queryClient, selectedSsoProvider]);

  useEffect(() => {
    window.addEventListener('message', receiveIframeMessage);
  }, [receiveIframeMessage]);

  return (
    <UsageAnalyticsContent
      informationSection={<UsageAnalyticsInformationSsoComponent />}
      startIntegrationStatus={
        <StartIntegrationStatus
          status={startIntegrationSuccess ? ConnectionStatus.SUCCESS : ConnectionStatus.ERROR}
          closeHandler={handleCloseDrawer}
          successProps={{
            description: t('connect_view:tab_section.tabs.usage_analytics.connection_success.sso.description', {
              ssoProviderName: selectedSsoProvider?.ssoProviderName,
            }),
            title: t('connect_view:tab_section.tabs.usage_analytics.connection_success.title', {
              name: selectedSsoProvider?.ssoProviderName,
            }),
          }}
          errorProps={{
            action: () => dispatch(showDrawer(DrawerInfoType.SASTRIFY_APP_HELP)),
            actionTitle: t('common:contact_sastrify'),
            description: t(
              'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.connection_error:description'
            ),
            title: t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.connection_error:title'),
          }}
        />
      }
      connectionId={getWorkatoConnectionId(connectionDetails?.connection?.connectionIds || [])}
      token={connectionDetails?.token || ''}
      isLoadingIframe={isLoadingIframe}
      workatoRecipeError={workatoRecipeError}
      startIntegrationSuccess={startIntegrationSuccess}
      startIntegrationError={startIntegrationError}
      iframeHeight={iframeHeight}
      providerName={selectedSsoProvider?.ssoProviderName || ''}
      handleCloseDrawer={handleCloseDrawer}
      title={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.title_sso', {
        ssoProviderName: selectedSsoProvider?.ssoProviderName,
      })}
    />
  );
};
