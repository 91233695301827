import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { useState } from 'react';
import { useMutation } from 'react-query';
import {
  convertHtmlToMarkdown,
  convertMarkDownToHtml,
  updateOnlySubscriptionNote,
} from 'shared/logic/subscription-item.logic';
import { queryClient } from 'shared/reactQuery';

export const useNote = (
  initialNote: string
): {
  noteValue: string;
  isLoading: boolean;
  updateNote: (note: string, subscriptionId: string) => void;
  handleChangeNoteFormValue: (value: string) => void;
  resetNoteValue: () => void;
  hasNoteBeenChanged: () => boolean;
} => {
  const [noteValue, setNoteValue] = useState<string>(initialNote);
  const { onShowNotification } = useModalAndNotification();

  useEffectOnce(() => {
    resetNoteValue();
  });

  const resetNoteValue = () => {
    setNoteValue(convertMarkDownToHtml(initialNote));
  };

  const handleChangeNoteFormValue = (value: string): void => {
    setNoteValue(value);
  };

  const hasNoteBeenChanged = () =>
    convertHtmlToMarkdown(convertMarkDownToHtml(initialNote)) !== convertHtmlToMarkdown(noteValue);
  const { isLoading, mutate: updateSubscriptionNote } = useMutation(updateOnlySubscriptionNote, {
    onError: (error) => {
      onShowNotification(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries('subscription-history');
      await queryClient.invalidateQueries('subscription');
    },
  });

  const updateNote = (note: string, subscriptionId: string) => {
    const noteToUpdate = convertHtmlToMarkdown(note);

    updateSubscriptionNote({
      note: noteToUpdate,
      subscriptionId,
    });
  };

  return {
    handleChangeNoteFormValue,
    hasNoteBeenChanged,
    isLoading,
    noteValue,
    resetNoteValue,
    updateNote,
  };
};
