import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToolUsageSsoProviderDto } from 'libs/dtos';
import { ToolUsageVendor } from 'shared/models/tool-usage-analytics.model';

interface SelectedVendor extends Omit<ToolUsageVendor, 'state'> {
  companyId: string;
  companyName: string;
}

interface SelectedSsoProvider extends Omit<ToolUsageSsoProviderDto, 'state'> {
  companyId: string;
  companyName: string;
}

export interface UsageAnalyticsItem {
  selectedSsoProvider?: SelectedSsoProvider;
  selectedVendor?: SelectedVendor;
}

export const initialState: UsageAnalyticsItem = {
  selectedSsoProvider: undefined,
  selectedVendor: undefined,
};

const usageAnalyticsItemSlice = createSlice({
  initialState,
  name: 'usageAnalyticsItem',
  reducers: {
    clearSelectedSsoProvider(state) {
      state.selectedSsoProvider = undefined;
    },
    clearSelectedVendor(state) {
      state.selectedVendor = undefined;
    },
    setSelectedSsoProvider(state, action: PayloadAction<SelectedSsoProvider>) {
      state.selectedSsoProvider = action.payload;
    },
    setSelectedVendor(state, action: PayloadAction<SelectedVendor>) {
      state.selectedVendor = action.payload;
    },
  },
});

export const { clearSelectedSsoProvider, clearSelectedVendor, setSelectedSsoProvider, setSelectedVendor } =
  usageAnalyticsItemSlice.actions;

export default usageAnalyticsItemSlice.reducer;
