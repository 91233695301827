import { MarkdownReaderComponent } from 'components/markdown-reader/markdown-reader.component';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';
import { Document, SubscriptionHistory } from 'shared/models';

import { AuthorDateInfo } from './comment-item-elements.component';
import { DropdownActions } from './dropdown-actions.component';

export interface CommentItemProps {
  comment: SubscriptionHistory;
  onCommentEdit(): void;
  onCommentDelete(): void;
  isEditEnabled: boolean;
  parsedCommentText: string;
  documents: Document[];
  onToggleThreadCollapse(): void;
  isThreadExpanded: boolean;
}

export const CommentItem: React.FC<CommentItemProps> = ({
  comment,
  documents,
  isEditEnabled,
  isThreadExpanded,
  onCommentDelete,
  onCommentEdit,
  onToggleThreadCollapse,
  parsedCommentText,
}) => {
  const { authorAvatarUrl, authorId, authorName, comment: responseComment, createdAt, date, updatedAt } = comment;
  const { t } = useTranslation();

  const isCommentDeleted = responseComment === null;

  return (
    <div className='subscription-comment'>
      <div className='subscription-comment__author' data-testid='comment-header'>
        <AuthorDateInfo
          createdAt={date || createdAt}
          updatedAt={updatedAt}
          authorName={authorName}
          authorAvatarUrl={authorAvatarUrl}
          isCommentDeleted={isCommentDeleted}
        />

        {isThreadExpanded && (
          <div
            className='subscription-comment__collapse'
            tabIndex={0}
            role='button'
            onKeyUp={(e) => onEnterOrSpaceKeyUp(e, onToggleThreadCollapse)}
            onClick={onToggleThreadCollapse}>
            {t('subscription_detail_view:subscription_comment_section.collapse_thread')}
            <i className='uil-compress-alt' />
          </div>
        )}
      </div>
      <div className='subscription-comment__content' data-testid='subscription-comment-content'>
        <div className=''>
          <div className='subscription-comment__text' data-testid='comment-text'>
            {!isCommentDeleted ? (
              <MarkdownReaderComponent text={parsedCommentText} documents={documents} />
            ) : (
              <em>{t('subscription_detail_view:subscription_comment_section.comment_deleted')}</em>
            )}
          </div>
          {!isCommentDeleted && (
            <div className='subscription-comment__replies-actions'>
              <button
                className='subscription-comment__reply-button'
                type='button'
                onClick={onToggleThreadCollapse}
                data-testid='comment-reply'>
                {t('subscription_detail_view:subscription_comment_section.reply_button_text')}{' '}
                <i className='uil-comments-alt' />
              </button>
            </div>
          )}
        </div>
        {!isCommentDeleted && (
          <div className='subscription-comment__action-buttons'>
            <DropdownActions
              isEditFormToggled={isEditEnabled}
              onDeleteClicked={onCommentDelete}
              onEditClicked={onCommentEdit}
              authorId={authorId}
            />
          </div>
        )}
      </div>
    </div>
  );
};
