import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { ModalComponentProps } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { ReplyableType, RESPONSE_CODE } from 'shared/common.definitions';
import { deleteReplyable } from 'shared/logic/replyable.logic';
import { queryClient } from 'shared/reactQuery';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';

export function useDeleteReply(commentId: string) {
  const dispatch = useDispatch();
  const { onCloseModal, onShowModal, onShowNotification } = useModalAndNotification();
  const { t } = useTranslation();

  const { mutateAsync: deleteReply } = useMutation(
    (replyId: string) => deleteReplyable(ReplyableType.SubscriptionComments, commentId, replyId),
    {
      onError: (error: any) => {
        dispatch(updateFooterState({ isFormSubmitting: false }));

        if (error?.response?.status === RESPONSE_CODE.Forbidden.code) {
          onShowNotification?.(
            t('subscription_detail_view:reply_toast.delete_forbidden_error'),
            NotificationAlertType.Error
          );
        } else {
          onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
        }
      },
      onMutate: () => {
        dispatch(updateFooterState({ isFormSubmitting: true }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([ReplyableType.SubscriptionComments, commentId]);
        queryClient.invalidateQueries('subscription-history');
        onShowNotification(t('subscription_detail_view:reply_toast.delete_success'), NotificationAlertType.Success);
        onCloseModal();
      },
    }
  );

  const initialModalProps: ModalComponentProps = {
    cancelButtonText: t(
      'subscription_detail_view:subscription_comment_section.subscription_comment_modal.action_buttons_section.cancel_button_text'
    ),
    children: undefined,
    message: '',
    onCancelButtonClick: onCloseModal,
  };

  return (replyId: string) => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    onShowModal({
      ...initialModalProps,
      confirmButtonText: t(
        'subscription_detail_view:subscription_comment_section.subscription_comment_modal.action_buttons_section.confirm_button_text'
      ),
      message: t('subscription_detail_view:subscription_comment_section.subscription_comment_modal.modal_body_text'),
      onConfirmButtonClick: () => deleteReply(replyId),
      title: t('subscription_detail_view:subscription_comment_section.subscription_comment_modal.modal_title_text'),
    });
  };
}
