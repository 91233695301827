import { TimeLineChartDataSet } from 'components/time-line-chart/time-line-chart-props';
import { SOURCE_NAME, TYPE } from 'libs/constants/index';
import { ToolUsageSsoProviderDto } from 'libs/dtos';
import { TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums/vendor-tool-usage-integration.enum';
import { QueryClient } from 'react-query';

import { Company } from '../models';
import {
  CreateDirectWorkatoIntegrationParams,
  CreateSsoWorkatoIntegrationParams,
  CreateWorkatoIntegrationResponse,
  DirectWorkatoIntegrationParams,
  GetWorkatoIntegrationParams,
  LicenseUsageLevel,
  SsoTool,
  SsoWorkatoData,
  SsoWorkatoIntegrationParams,
  ToolUsageActivityDataV2,
  ToolUsageActivityItem,
  ToolUsageVendor,
  WorkatoIntegrationPayload,
  WorkatoLicenseHolderData,
} from '../models/tool-usage-analytics.model';
import { apiService, apiUrl } from '../services';

export const createDirectWorkatoIntegration = async ({
  companyId,
  companyName,
  signal,
  subscriptionId,
  vendorId,
}: CreateDirectWorkatoIntegrationParams): Promise<CreateWorkatoIntegrationResponse> => {
  let connection: WorkatoIntegrationPayload | object = {};
  let token = '';

  apiService.setConfig({
    ...apiService.config,
    signal,
  });

  const { data } = await apiService.put(apiUrl.createDirectWorkatoIntegration(companyId, subscriptionId), {
    companyId,
    companyName,
    subscriptionId,
    vendorId,
  });
  connection = data;
  const { data: jwt } = await apiService.post(apiUrl.getWorkatoDirectLinkJWT(companyId));
  token = jwt;

  return {
    connection: connection as WorkatoIntegrationPayload,
    token,
  };
};

export const startDirectWorkatoIntegration = async ({
  companyId,
  subscriptionId,
}: DirectWorkatoIntegrationParams): Promise<WorkatoIntegrationPayload> => {
  const { data } = await apiService.put(apiUrl.startDirectWorkatoIntegration(companyId, subscriptionId));
  return data;
};

export const disconnectDirectWorkatoIntegration = async ({
  companyId,
  subscriptionId,
}: DirectWorkatoIntegrationParams): Promise<WorkatoIntegrationPayload> => {
  const { data } = await apiService.delete(apiUrl.disconnectDirectWorkatoIntegration(companyId, subscriptionId));
  return data;
};

export const getSsoWorkatoIntegration = async ({
  companyId,
  ssoName,
}: GetWorkatoIntegrationParams): Promise<SsoWorkatoData | undefined> => {
  const response = await apiService.get(apiUrl.getSsoWorkatoIntegration(companyId, ssoName));

  return response.data || undefined;
};

export const createSsoWorkatoIntegration = async ({
  companyId,
  companyName,
  signal,
  ssoName,
  usageAnalyticsEnabled,
}: CreateSsoWorkatoIntegrationParams): Promise<CreateWorkatoIntegrationResponse> => {
  let connection: WorkatoIntegrationPayload | object = {};
  let token = '';

  apiService.setConfig({
    ...apiService.config,
    signal,
  });

  const { data } = await apiService.put(apiUrl.createSsoWorkatoIntegration(companyId, ssoName), {
    companyId,
    companyName,
    ssoName,
    usageAnalyticsEnabled,
  });
  connection = data;
  const { data: jwt } = await apiService.post(apiUrl.getWorkatoDirectLinkJWT(companyId));
  token = jwt;

  return {
    connection: connection as WorkatoIntegrationPayload,
    token,
  };
};

export const startSsoWorkatoIntegration = async ({
  companyId,
  ssoName,
}: SsoWorkatoIntegrationParams): Promise<WorkatoIntegrationPayload> => {
  const { data } = await apiService.put(apiUrl.startSsoWorkatoIntegration(companyId, ssoName));
  return data;
};

export const disconnectSsoWorkatoIntegration = async ({
  companyId,
  ssoName,
}: SsoWorkatoIntegrationParams): Promise<WorkatoIntegrationPayload> => {
  const { data } = await apiService.delete(apiUrl.disconnectSsoWorkatoIntegration(companyId, ssoName));
  return data;
};

export const getSsoToolsList = async ({ companyId, ssoName }: SsoWorkatoIntegrationParams): Promise<SsoTool[]> => {
  const { data } = await apiService.get(apiUrl.getSsoToolsList(companyId, ssoName));
  return data;
};

export const getWorkatoIframeUrl = (id: string, token: string) => {
  return `https://www.workato.com/direct_link/embedded/connections/${id}?workato_dl_token=${token}`;
};

export const getToolUsageVendors = async (): Promise<ToolUsageVendor[]> => {
  const { data } = await apiService.get(apiUrl.getToolUsageVendors());
  return data;
};

export const getToolUsageVendorById = async (vendorId: string): Promise<ToolUsageVendor> => {
  const { data } = await apiService.get(apiUrl.getToolUsageVendorById(vendorId));
  return { ...data, toolUsageSource: SOURCE_NAME.WORKATO, type: TYPE.DIRECT };
};

export const getToolUsageSsoProviders = async (): Promise<ToolUsageSsoProviderDto[]> => {
  const { data } = await apiService.get(apiUrl.getToolUsageSsoProviders());
  return data.map((ssoProvider: ToolUsageSsoProviderDto) => {
    return { ...ssoProvider, type: TYPE.SSO };
  });
};

export const getToolUsageSsoProviderById = async (ssoProviderId: string): Promise<ToolUsageSsoProviderDto> => {
  const { data } = await apiService.get(apiUrl.getToolUsageSsoProviderById(ssoProviderId));
  return data;
};

export const getLicenseUsageLevel = (activeLicenses: number, totalLicenses: number) => {
  const licenseUsagePercentage = getLicenseUsagePercentage(activeLicenses, totalLicenses);

  if (licenseUsagePercentage === null) {
    return null;
  }

  switch (true) {
    case licenseUsagePercentage < 33:
      return LicenseUsageLevel.LOW;
    case licenseUsagePercentage < 66:
      return LicenseUsageLevel.MEDIUM;
    case licenseUsagePercentage <= 100:
      return LicenseUsageLevel.HIGH;
    default:
      return null;
  }
};

export const getLicenseUsagePercentage = (activeLicenses: number, totalLicenses: number): number | null => {
  if (activeLicenses > totalLicenses) {
    return null;
  }
  if (totalLicenses === 0) {
    return null;
  }
  return Math.round((100.0 * activeLicenses) / totalLicenses);
};

export const getLicenseUsageV2 = async (
  companyId: string,
  subscriptionId: string
): Promise<ToolUsageActivityDataV2> => {
  const { data } = await apiService.get(apiUrl.getLicenseUsageV2(companyId, subscriptionId));

  let isProcessing = false;
  if (data.active_users?.length === 0 && data.total_users?.length === 0) {
    isProcessing = true;
  }

  return {
    activeUsers: data.active_users,
    isProcessing,
    totalUsers: data.total_users,
  };
};

export const getUsersV2 = async (
  companyId: string,
  subscriptionId: string,
  filter: (input: { type: TYPE; source: SOURCE_NAME }) => boolean
): Promise<WorkatoLicenseHolderData[]> => {
  const { data } = await apiService.get(apiUrl.getUsersV2(companyId, subscriptionId));
  return (data as []).filter(filter);
};

export const updateWorkatoVendor = (
  queryClient: QueryClient,
  subscriptionId: string,
  state: TOOL_USAGE_INTEGRATION_STATE
): void => {
  queryClient.invalidateQueries('vendor-usage');
  queryClient.invalidateQueries('license-usage');
  queryClient.invalidateQueries('license-usage-v2');

  queryClient.setQueryData('tool-usage-vendors', () => {
    const vendors: ToolUsageVendor[] | undefined = queryClient.getQueryData('tool-usage-vendors');
    const vendorIndex = vendors?.findIndex((vendor) => vendor.subscriptionId === subscriptionId);

    if (vendors && vendorIndex) {
      vendors[vendorIndex].state = state;
    }

    return vendors;
  });

  if (state === TOOL_USAGE_INTEGRATION_STATE.AVAILABLE) {
    const companyId = queryClient.getQueryData<Company>('company')?.id;
    queryClient.setQueryData(['analytics-state', companyId, subscriptionId], undefined);
  } else {
    queryClient.invalidateQueries('analytics-state');
  }
};

export const updateWorkatoSsoProvider = (
  queryClient: QueryClient,
  ssoProviderName: string,
  state: TOOL_USAGE_INTEGRATION_STATE
): void => {
  queryClient.invalidateQueries('license-usage');
  queryClient.invalidateQueries('license-usage-v2');

  queryClient.setQueryData('workato-sso-providers', () => {
    const ssoProviders = queryClient.getQueryData('workato-sso-providers') as ToolUsageSsoProviderDto[];
    const ssoProviderIndex = ssoProviders?.findIndex((ssoProvider) => ssoProvider.ssoProviderName === ssoProviderName);
    ssoProviders[ssoProviderIndex].state = state;

    return ssoProviders;
  });

  if (state === TOOL_USAGE_INTEGRATION_STATE.AVAILABLE) {
    const companyId = queryClient.getQueryData<Company>('company')?.id;
    queryClient.setQueryData(['analytics-state', companyId, ssoProviderName], undefined);
  } else {
    queryClient.invalidateQueries('analytics-state');
  }
};

const getFirstSundayBeforeDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  date.setDate(date.getDate() - (date.getDay() % 7));

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getLicenseUsageGraphData = (
  activeUsers: ToolUsageActivityItem[],
  totalUsers: ToolUsageActivityItem[]
): TimeLineChartDataSet[] => {
  const graphData = [];

  const totalUsersData = totalUsers.map((item) => {
    return {
      x: getFirstSundayBeforeDate(item.date),
      y: item.count,
    };
  });

  if (totalUsersData.length > 0) {
    graphData.push({ data: totalUsersData, id: 'total_users' });
  }

  const activeUsersData = activeUsers.map((item) => {
    return {
      x: getFirstSundayBeforeDate(item.date),
      y: item.count,
    };
  });

  if (activeUsersData.length > 0) {
    graphData.push({ data: activeUsersData, id: 'active_users' });
  }

  return graphData;
};

export const getLastLicenseUsageDataCount = (
  activeUsers: ToolUsageActivityItem[],
  totalUsers: ToolUsageActivityItem[]
) => {
  const lastActiveUser = activeUsers[0];
  const lastTotalUser = totalUsers[0];
  return {
    activeUsersCount: lastActiveUser?.count,
    totalUsersCount: lastTotalUser?.count,
  };
};

export const getWorkatoConnectionId = (ids: number[]): string => {
  if (ids.length > 0) {
    return String(ids[0]);
  }

  return '';
};
