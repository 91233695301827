import './no-result.feature.scss';

import { Box, Button, Typography } from '@mui/material';
import React from 'react';

import { NoResultFeatureProps } from './no-result.feature.props';
import { NoResultsWrapper } from './no-result.styles';

export const NoResultFeature: React.FC<NoResultFeatureProps> = (props: NoResultFeatureProps) => {
  const {
    actionClassName,
    actionText,
    customActions = false,
    customMessage,
    isPageView = false,
    messages,
    onActionClick,
    showContent = true,
    startIcon,
    title,
  } = props;

  const handleOnActionButtonClicked = (): void => {
    if (onActionClick) {
      onActionClick();
    }
  };

  const renderButton = () => {
    if (isPageView) {
      return (
        <Button data-testid='add-new-link' color='primary' variant='text' onClick={handleOnActionButtonClicked}>
          {actionText}
        </Button>
      );
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Button
          variant='contained'
          color='primary'
          data-testid='add-new-link'
          startIcon={startIcon}
          className={`button custom--button__class ${actionClassName}`}
          onClick={handleOnActionButtonClicked}>
          {actionText}
        </Button>
      </Box>
    );
  };

  return (
    <NoResultsWrapper data-testid='no-results'>
      <div className='noresult-icon' />
      {title && (
        <Typography
          variant='h2'
          sx={{ mb: customMessage || messages ? 3 : 0, mt: 2, textAlign: 'center' }}
          data-testid='noresult-title'>
          {title}
        </Typography>
      )}
      {showContent && (
        <Box className='noresult-content'>
          {!customMessage
            ? messages?.map((message) => (
                <Typography variant='subtitle' className='noresult-message' key={`${message}`}>
                  {message}
                </Typography>
              ))
            : customMessage}

          {actionText && renderButton()}

          {customActions || null}
        </Box>
      )}
    </NoResultsWrapper>
  );
};
