export enum SPEND_CATEGORIES {
  AUTOMATED_IMPORT = 0,
  ESTIMATED_INVOICE = 1,
  SUBSCRIPTION_DOCUMENT = 2,
  PROCESSED_SUBSCRIPTION_DOCUMENT = 3,
  ACCOUNTING_INFORMATION = 4,
  SPREADSHEET_UPLOAD = 5,
}

export enum SPEND_CATEGORY_TYPE_LABELS {
  AUTOMATED_IMPORT = 'Automated Import',
  ESTIMATED_INVOICE = 'Estimated Invoice',
  SUBSCRIPTION_DOCUMENT = 'Subscription Document',
  PROCESSED_SUBSCRIPTION_DOCUMENT = 'Processed Subscription Document',
  ACCOUNTING_INFORMATION = 'Accounting Information',
  SPREADSHEET_UPLOAD = 'Spreadsheet Upload',
}

export enum SPEND_CATEGORY_LABELS {
  SPREADSHEET_UPLOAD = 'SPREADSHEET_UPLOAD',
  AUTOMATED_IMPORT = 'AUTOMATED_IMPORT',
  ESTIMATED_INVOICE = 'ESTIMATED_INVOICE',
  SUBSCRIPTION_DOCUMENT = 'SUBSCRIPTION_DOCUMENT',
  PROCESSED_SUBSCRIPTION_DOCUMENT = 'PROCESSED_SUBSCRIPTION_DOCUMENT',
  ACCOUNTING_INFORMATION = 'ACCOUNTING_INFORMATION',
}

export const SPEND_CATEGORY_MAPPING = {
  [SPEND_CATEGORY_LABELS.SPREADSHEET_UPLOAD]: SPEND_CATEGORIES.SPREADSHEET_UPLOAD,
  [SPEND_CATEGORY_LABELS.AUTOMATED_IMPORT]: SPEND_CATEGORIES.AUTOMATED_IMPORT,
  [SPEND_CATEGORY_LABELS.ESTIMATED_INVOICE]: SPEND_CATEGORIES.ESTIMATED_INVOICE,
  [SPEND_CATEGORY_LABELS.SUBSCRIPTION_DOCUMENT]: SPEND_CATEGORIES.SUBSCRIPTION_DOCUMENT,
  [SPEND_CATEGORY_LABELS.PROCESSED_SUBSCRIPTION_DOCUMENT]: SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT,
  [SPEND_CATEGORY_LABELS.ACCOUNTING_INFORMATION]: SPEND_CATEGORIES.ACCOUNTING_INFORMATION,
};
