import { AlertColor, Box, CircularProgress } from '@mui/material';
import { Alert } from 'asteroids';
import { useEffect, useRef } from 'react';
import { SubscriptionInvoiceUpload } from 'shared/store/views/subscription/subscription-invoice-upload.slice';

import { useSpendProcessingAlert } from '../../hooks/use-spend-processing-alert';
import { AlertLoader } from '../spend-table.component.styles';

type UploadedSpendProcessingAlertProps = {
  uploadedInvoices: SubscriptionInvoiceUpload[];
};

export const UploadedSpendProcessingAlert: React.FC<UploadedSpendProcessingAlertProps> = ({ uploadedInvoices }) => {
  const alertRef = useRef<HTMLDivElement>(null);
  const scrolledIntoView = useRef(false);
  const { alertText } = useSpendProcessingAlert({ uploadedInvoices });

  useEffect(() => {
    if (alertText && !scrolledIntoView?.current) {
      alertRef?.current?.scrollIntoView({ behavior: 'smooth' });
      if (scrolledIntoView?.current) {
        scrolledIntoView.current = true;
      }
    }
  }, [alertText]);

  useEffect(() => {
    if (uploadedInvoices.length && scrolledIntoView?.current) {
      scrolledIntoView.current = false;
    }
  }, [uploadedInvoices.length]);

  return (
    <>
      <div ref={alertRef} />
      {!!alertText && !!uploadedInvoices.length && (
        <Box mb={3}>
          <Alert
            data-testid='uploaded-spend-processing-alert'
            description={alertText?.description || ''}
            title={alertText?.title}
            severity={(alertText?.severity || 'info') as AlertColor}
            {...(alertText?.showLoader && {
              action: (
                <AlertLoader>
                  <CircularProgress size={32} color='info' />
                </AlertLoader>
              ),
            })}
          />
        </Box>
      )}
    </>
  );
};
