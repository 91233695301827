import { Box, styled } from '@mui/material';

export const StyledTag = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  maxWidth: '100%',
  padding: theme.spacing(0, 0.5),
  borderRadius: theme.spacing(31.25),
  backgroundColor: theme.palette.secondary.main,
  '& .MuiTypography-tiny': {
    overflowX: 'hidden',
    maxWidth: '20ch',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
