import { Box, styled } from '@mui/material';
import { theme } from 'asteroids';

const PREFIX = 'DrawerUserInviteComponent';

export const classes = {
  alreadyInvitedTag: `${PREFIX}-alreadyInvitedTag`,
  autoCompleteOption: `${PREFIX}-autoCompleteOption`,
  avatar: `${PREFIX}-avatar`,
  googleUserInfo: `${PREFIX}-googleUserInfo`,
};

export const StyledBox = styled(Box)({
  [`& .${classes.alreadyInvitedTag}`]: {
    color: `${theme.palette.tertiary.main} !important`,
    fontStyle: 'italic',
    textTransform: 'uppercase',
  },
  [`& .${classes.autoCompleteOption}`]: {
    gap: '0.2rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.highlight,
      cursor: 'pointer',
    },
  },
  [`& .${classes.avatar}`]: {
    '&.MuiAvatar-circular': {
      border: `1px solid ${theme.palette.primary.main}`,
      marginRight: theme.spacing(1),
      maxHeight: theme.spacing(3),
      maxWidth: theme.spacing(3),
    },
  },
  [`& .${classes.googleUserInfo}`]: {
    '& .MuiTypography-root': {
      '&:nth-child(2)': {
        fontStyle: 'italic',
        fontWeight: 'bold',
      },
      color: theme.palette.text.secondary,
      fontSize: '0.875rem',
      marginRight: '0.6875rem',
    },
  },
});
