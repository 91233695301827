import { Box, styled } from '@mui/material';

type SpendEditModalContainerProps = {
  hasAttachment?: boolean;
};

export const CustomModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1.4),
}));

export const SpendEditModalContainer = styled(Box)<SpendEditModalContainerProps>(({ hasAttachment, theme }) => ({
  position: 'relative',
  '.spend-review-modal--wrapper': {
    border: !hasAttachment && 'none',
    '.download-icon': {
      visibility: 'hidden',
    },
  },
  '.spend-edit-modal-body': {
    height: '600px',
    '.left-section': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexBasis: '60%',
      padding: theme.spacing(2),
      background: theme.palette.grey[100],
    },
    '.right-section': {
      flexBasis: hasAttachment ? '40%' : '100%',
      borderRadius: theme.spacing(0.5),
      justifyContent: 'space-between',
      '.confirmation-section': {
        width: '100%',
        display: 'block',
        padding: theme.spacing(0, 4, 2),
        borderTop: !hasAttachment ? 'none' : `1px solid ${theme.palette.grey[300]}`,
        textAlign: 'right',
        '.confirmation-options': {
          marginTop: theme.spacing(2),
        },
      },
    },
  },
}));
