import { Pagination as MuiPagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PaginationPropsV2 } from './pagination.component.props';
import { PaginationContainerV2 } from './pagination.styles';

export const PaginationV2: React.FC<PaginationPropsV2> = ({ count, label, onPageChanged, page = 1, ...rest }) => {
  const { t } = useTranslation();

  const labelText =
    typeof label === 'string'
      ? label
      : `${label.from} - ${label.to} ${t('pagination:of_label')} ${label.totalItems} ${label.itemsLabel}`;

  return (
    <PaginationContainerV2>
      <Typography variant='label' data-testid='pagination-label'>
        {labelText}
      </Typography>
      <MuiPagination
        {...rest}
        page={page}
        color='primary'
        shape='rounded'
        variant='text'
        count={count}
        onChange={(_event, page) => onPageChanged(page)}
      />
    </PaginationContainerV2>
  );
};

export default PaginationV2;
