import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export enum SubscriptionInvoiceUploadStatus {
  processing = 'processing',
  completed = 'completed',
  failed = 'failed',
  delayed = 'delayed',
  uploaded = 'uploaded',
}

export interface SubscriptionInvoiceUpload {
  id: string;
  status: 'processing' | 'delayed' | 'failed' | 'completed' | 'uploaded';
}

export const initialState: SubscriptionInvoiceUpload[] = [];

const SubscriptionInvoiceUploadAdapter = createEntityAdapter<SubscriptionInvoiceUpload>();

const subscriptionInvoiceUpload = createSlice({
  initialState: SubscriptionInvoiceUploadAdapter.getInitialState(),
  name: 'subscriptionInvoiceUploadList',
  reducers: {
    setUploadedFiles: SubscriptionInvoiceUploadAdapter.setMany,
  },
});

export const { setUploadedFiles } = subscriptionInvoiceUpload.actions;
export const { selectAll: selectAllUploads } = SubscriptionInvoiceUploadAdapter.getSelectors();
export default subscriptionInvoiceUpload.reducer;
