import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { useMutation } from 'react-query';
import { CommentDocument } from 'shared/common.definitions';
import { getMarkdownFromComment, updateSubscriptionComment } from 'shared/logic/subscription-item.logic';
import { Subscription, SubscriptionComment, SubscriptionHistory } from 'shared/models';
import { queryClient } from 'shared/reactQuery';

export function useUpdateComment(
  documents: CommentDocument[],
  subscription: Subscription,
  history: SubscriptionHistory,
  cancelEditComment: () => void
) {
  const { onShowNotification } = useModalAndNotification();

  const { isLoading, mutate: updateComment } = useMutation(updateSubscriptionComment, {
    onError: (error) => {
      onShowNotification(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['subscription', subscription.id]);
      queryClient.invalidateQueries('subscription-history');

      cancelEditComment();
      onShowNotification('Comment Updated Successfully', NotificationAlertType.Success);
    },
  });

  async function onSaveComment(comment: string) {
    const generatedCommentDomFromString = new DOMParser().parseFromString(comment, 'text/html');
    const htmlMentions = generatedCommentDomFromString.querySelectorAll('[data-denotation-char="@"]');
    const commentToUpdate = await getMarkdownFromComment(comment, documents, htmlMentions);

    const updatedCommentHistory: SubscriptionComment = {
      comment: String(commentToUpdate),
      id: history.id,
      subscriptionId: history.subscriptionId,
    };

    if (documents.length) queryClient.invalidateQueries('subscriptionDocuments');

    updateComment(updatedCommentHistory);
  }

  return { isLoading, onSaveComment };
}
