import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { CurrencySelectInputComponent } from 'components/currency-select-input/currency-select-input.component';
import { DatePickerComponent, NumberFormatInput, VendorSelectInputComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { format } from 'date-fns';
import { useDebounce, useFetchData } from 'hooks/index';
import { SOURCE_SYSTEM } from 'libs/enums/source-system.enum';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Currency, CurrencyOptions } from 'shared/common.definitions';
import {
  convertAmountFromCents,
  convertAmountToCents,
  downloadFile,
  formatFullDate,
} from 'shared/helpers/common.helper';
import {
  getPreviewUrlExpireSeconds,
  invoiceSchema,
  NO_FAILED_INVOICE_FOUND_MESSAGE,
  supportedFileTypes,
} from 'shared/helpers/spend.helper';
import { trackManualInvoiceFixingDuration } from 'shared/logic/event-tracking/subscription-spend-review.events';
import { fetchFailedInvoiceData, fixFailedInvoice } from 'shared/logic/subscription-invoices.logic';
import { getSubscriptionDocument } from 'shared/logic/subscription-item.logic';
import { Currency as CurrencyType, SubscriptionDocument, VendorType } from 'shared/models';
import { FailedInvoiceData, FixInvoicePayload } from 'shared/models/subscription-spend-and-invoices.model';
import { ReviewInvoiceFormContainer } from 'views/spend-import/components/to-review/review-user-upload/failed-invoice-details/failed-invoice-details.component.styles';
import { DEFAULT_TABLE_PAGE_SIZE } from 'views/tool-details/panels/spend-panel/spend-table/spend-table.component';

import { SpendModalHeader } from './spend-modal-header/spend-modal-header.component';
import { SubscriptionSpendReviewProps } from './subscription-spend-review.modal.component.props';
import { SpendReviewModalContainer } from './subscription-spend-review.modal.styles';

export const SubscriptionSpendReview: FC<SubscriptionSpendReviewProps> = ({
  closeModal,
  company,
  enableToolUpdate = false,
  invoiceData,
  onDelete,
  showNotification,
  subscription,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [vendorName, setVendorName] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>();
  const [failedInvoiceData, setFailedInvoiceData] = useState<FailedInvoiceData | undefined>(undefined);
  const [isErrorUploading, setIsErrorUploading] = useState<boolean>(false);
  const [failedInvoiceExists, setFailedInvoiceExists] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<VendorType>();
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const openedAtRef = useRef<number>();

  const { data: spendData, status: spendDataStatus } = useFetchData<FailedInvoiceData | undefined>(
    'failed-invoice-data',
    () => fetchFailedInvoiceData(String(subscription?.id), String(invoiceData?.documentId)),
    {
      enabled: invoiceData?.documentId !== undefined && subscription?.id !== undefined,
      onError: (error: any) => {
        setFailedInvoiceData(undefined);

        const validationError = error?.response?.data?.error;

        if (validationError.includes(NO_FAILED_INVOICE_FOUND_MESSAGE)) {
          setFailedInvoiceExists(false);
        } else {
          setFailedInvoiceExists(true);
          setIsErrorUploading(true);
        }
      },
      onSuccess: (data: FailedInvoiceData | undefined) => {
        setFailedInvoiceExists(true);
        setIsErrorUploading(false);
        setFailedInvoiceData(data);
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const invoiceDocumentData = queryClient.getQueryData<SubscriptionDocument>([
    'subscription-document',
    invoiceData?.documentId,
  ]);

  const { isFetching: isLoadingAttachment } = useFetchData<SubscriptionDocument | undefined>(
    ['subscription-document', invoiceData?.documentId],
    () => getSubscriptionDocument(String(subscription?.id), String(invoiceData?.documentId)),
    {
      cacheTime: getPreviewUrlExpireSeconds(invoiceData?.documentPreviewUrl as string) * 1000,
      enabled: !!invoiceData?.documentId && !!subscription?.id && !invoiceDocumentData,
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync: fixInvoiceMutation } = useMutation(
    (invoiceDataPayload: FixInvoicePayload) =>
      fixFailedInvoice(subscription.id, invoiceData.documentId as string, invoiceDataPayload, failedInvoiceExists),
    {
      onError: (error: any) => {
        const validationError = error?.response?.data?.error;

        showNotification?.(
          validationError || error?.message || t('common:modals.review_spend_modal.fix_invoice_error'),
          NotificationAlertType.Error
        );
      },
      onSuccess: (success: boolean) => {
        if (success) {
          closeModal?.();
          queryClient.invalidateQueries('subscription-spend-and-invoices');
          queryClient.invalidateQueries('failed-invoices-batch');
          queryClient.invalidateQueries(['subscription-spend', 0, DEFAULT_TABLE_PAGE_SIZE]);
        } else {
          showNotification?.(t('common:modals.review_spend_modal.fix_invoice_error'), NotificationAlertType.Error);
        }
      },
    }
  );

  useDebounce(setDebouncedValue, vendorName, 500);

  const handleItemSelected = (item: VendorType) => {
    if (item) {
      setValue('tool', item.name, { shouldValidate: true });
      setSelectedVendor(item);
    } else {
      setSelectedVendor(undefined);
    }
  };

  const {
    control,
    formState: { isValid },
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(invoiceSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (!spendData) return;

    if (spendDataStatus === 'error') {
      reset();
      setValue('name', '');
    } else {
      setValue('amount', spendData?.amountCents ? String(convertAmountFromCents(spendData.amountCents)) : '');
      setValue('currency', spendData?.amountCurrency || '');
      setValue('invoiceDate', spendData?.date || '');
      setValue('name', spendData?.documentName || '');

      if (subscription?.name) {
        setValue('tool', subscription.name);
      }
    }
  }, [reset, setValue, spendData, spendDataStatus, subscription]);

  useEffect(() => {
    openedAtRef.current = Date.now();
  }, []);

  const selectedCurrency = watch('currency');

  const displayCurrency = CurrencyOptions[selectedCurrency as keyof Currency] || selectedCurrency;

  const isContentTypeSupported = (contentType: string | undefined) => {
    if (!contentType) return false;

    return supportedFileTypes.includes(contentType);
  };

  const onSubmit = async () => {
    const formValues = getValues();
    const invoiceClone = { ...formValues };

    if (!invoiceClone || !subscription || !company) {
      showNotification?.(
        t('common:modals.review_spend_modal.fix_invoice_validation_error'),
        NotificationAlertType.Error
      );
      return;
    }

    fixInvoiceMutation({
      amountCents: convertAmountToCents(invoiceClone.amount),
      amountCurrency: invoiceClone.currency,
      companyId: String(company.id),
      connectionId: null,
      date: format(new Date(invoiceClone.invoiceDate), 'yyyy-MM-dd'),
      importSource: failedInvoiceData ? (failedInvoiceData?.sourceSystem as SOURCE_SYSTEM) : null,
      importSubsystem: subscription.importSubsystem as string,
      name: invoiceClone.name,
      sourceId: failedInvoiceData ? (failedInvoiceData?.sourceId as string) : null,
      subscriptionId: Number(subscription.id),
      vendorId: selectedVendor?.id || subscription.vendorId,
    });

    const openedAt = openedAtRef?.current as number;
    const manualFixDuration = Math.round((Date.now() - openedAt) / 1000);
    trackManualInvoiceFixingDuration({
      companyId: company.id as string,
      durationInSeconds: manualFixDuration,
      invoiceId: invoiceData.id,
      subscriptionId: subscription.id,
    });
  };

  const handleDownloadFile = () => {
    if (invoiceData.documentUrl) {
      downloadFile(invoiceData?.documentUrl);
    }
  };

  const handleDelete = () => {
    onDelete(invoiceData, subscription, company);
  };

  const subtitle = (
    <>
      {t('common:modals.review_spend_modal.uploaded_by_text', { user: invoiceData.uploadedByName })} •{' '}
      {formatFullDate(invoiceData.uploadDate as string)}
    </>
  );

  return (
    <SpendReviewModalContainer>
      <Box className='spend-review-modal' display='flex' flexDirection='column' data-testid='spend-review-modal'>
        <SpendModalHeader
          title={invoiceData.name}
          subtitle={subtitle}
          enableDownload={Boolean(invoiceData?.documentUrl)}
          closeModal={closeModal}
          handleDownloadFile={handleDownloadFile}
        />
        <Box className='spend-review-modal-body' display='flex' flexDirection='row'>
          <Box className='left-section'>
            {isLoadingAttachment ? (
              <CircularProgress />
            ) : (
              <>
                {!!invoiceDocumentData && isContentTypeSupported(invoiceDocumentData.contentType) && (
                  <iframe
                    src={`${invoiceDocumentData.previewUrl}#toolbar=0&navpanes=0`}
                    title='attachement-preview'
                    width='100%'
                    height='100%'
                  />
                )}
              </>
            )}
          </Box>
          <Box className='right-section' display='flex' flexDirection='column'>
            {isErrorUploading && failedInvoiceExists ? (
              <Box className='error-message' display='flex' flexDirection='column'>
                <Typography variant='label' className='error-message-title'>
                  {t('common:modals.review_spend_modal.error_message_title')}
                </Typography>
                <Typography className='error-message-subtitle'>
                  {t('common:modals.review_spend_modal.error_message_subtitle')}
                </Typography>
              </Box>
            ) : (
              <>
                <Paper className='info-block'>
                  <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-start'>
                    <Box mr={2}>
                      <Icon color='error' fontSize='small'>
                        info
                      </Icon>
                    </Box>
                    <Box>
                      <Typography variant='label' className='label'>
                        {t('common:modals.review_spend_modal.update_invoice_data_caption')}
                      </Typography>
                      <Typography>{t('common:modals.review_spend_modal.update_invoice_data_text')}</Typography>
                    </Box>
                  </Box>
                </Paper>
                <ReviewInvoiceFormContainer my={1} display='flex' flexDirection='column' className='form-container'>
                  <Box display='flex' flexDirection='column' width='100%' data-testid='invoice-number'>
                    <Typography variant='label'>
                      {t('common:modals.review_spend_modal.form.name_label_text')}
                    </Typography>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <TextField
                            size='small'
                            value={value}
                            onChange={(value) => onChange(value)}
                            placeholder={t('common:modals.review_spend_modal.form.name_placeholder')}
                            error={!value}
                          />
                        );
                      }}
                    />
                  </Box>
                  {enableToolUpdate && (
                    <Box display='flex' flexDirection='column' width='100%' mt={2} data-testid='tool-name'>
                      <Typography variant='label'>
                        {t('common:modals.review_spend_modal.form.tool_label_text')}
                      </Typography>
                      <Controller
                        name='tool'
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <VendorSelectInputComponent
                              handleItemSelected={handleItemSelected}
                              setDebouncedValue={setDebouncedValue}
                              vendorName={vendorName}
                              debouncedValue={debouncedValue}
                              onChange={onChange}
                              setVendorName={setVendorName}
                              placeholder={t('common:modals.review_spend_modal.form.tool_placeholder')}
                              value={value}
                            />
                          );
                        }}
                      />
                    </Box>
                  )}
                  <Box display='flex' flexDirection='column' width='100%' mt={2} data-testid='invoice-date'>
                    <Typography variant='label'>
                      {t('common:modals.review_spend_modal.form.invoice_date_text')}
                    </Typography>
                    <Controller
                      name='invoiceDate'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePickerComponent
                          value={value || ''}
                          onChange={(newValue) => {
                            onChange(newValue);
                            setOpenDatePicker(false);
                          }}
                          placeholder={t('common:modals.review_spend_modal.form.invoice_date_placeholder')}
                          openDatePicker={openDatePicker}
                          setOpenDatePicker={setOpenDatePicker}
                          maxDate={new Date()}
                          valid={Boolean(value)}
                        />
                      )}
                    />
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%' mt={2}>
                    <Box display='flex' flexDirection='column' width='48%' data-testid='amount'>
                      <Typography variant='label' className='amount-label'>
                        {t('common:modals.review_spend_modal.form.amount_label_text')}
                      </Typography>
                      <Controller
                        name='amount'
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <NumberFormatInput
                              decimalScale={2}
                              decimalSeparator='.'
                              fixedDecimalScale
                              thousandSeparator
                              prefix={displayCurrency}
                              value={value}
                              isValid={value !== 'undefined' && !isNaN(Number(value))}
                              onValueChange={(value) => onChange(value.floatValue)}
                              placeholder={t('common:modals.review_spend_modal.form.amount_placeholder')}
                            />
                          );
                        }}
                      />
                    </Box>
                    <Box display='flex' flexDirection='column' width='48%' ml={2} data-testid='currency'>
                      <Typography variant='label' className='currency-label'>
                        {t('common:modals.review_spend_modal.form.currency_label_text')}{' '}
                        <Tooltip
                          title={`${t('common:modals.review_spend_modal.form.currency_label_tooltip_text')}`}
                          placement='top'
                          arrow>
                          <IconButton>
                            <Icon sx={{ color: theme.palette.text.secondary }} fontSize='small'>
                              help
                            </Icon>
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Controller
                        name='currency'
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CurrencySelectInputComponent
                              initialValue={value}
                              getValue={(value: CurrencyType) => onChange(value.code)}
                              isValid={Boolean(value)}
                            />
                          );
                        }}
                      />
                    </Box>
                  </Box>
                </ReviewInvoiceFormContainer>
              </>
            )}
            <Box display='flex' flexDirection='row' width='100%' className='confirmation-section'>
              <Box className='confirmation-options'>
                <Button onClick={handleDelete} className='mt-4 delete-button' data-testid='delete-button'>
                  {t('common:modals.review_spend_modal.delete_button_text')}
                </Button>
                <Button
                  variant='contained'
                  disabled={!isValid}
                  className='mt-4 save-button'
                  onClick={onSubmit}
                  data-testid='save-button'>
                  {t('common:modals.review_spend_modal.save_button_text')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </SpendReviewModalContainer>
  );
};
