/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import classnames from 'classnames';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { KeyboardKeys } from 'shared/common.definitions';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';
import { connect } from 'shared/store/common/googleDirectory.slice';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { AvatarIcon, GoogleWorkspaceIcon } from '../icons';
import { NotAssigned } from '../icons/not-assigned.icon';
import { WithoutInvitation } from '../icons/without-invitation.icon';
import Input from '../input';
import { UserNameAndAvatarComponent } from '../username-and-avatar/username-and-avatar.component';
import { TypeaheadComponentProps } from './typeahead.component.props';

const ADD_NEW_REQUEST_INDEX = -2;
const NO_SELECTION_REQUEST_INDEX = -1;
const ASSIGN_TOOL_OWNER_INDEX = -3;

type MatchItem = {
  index: number;
  match?: any;
  isAssigned?: boolean;
  isInvite?: boolean;
};

export const TypeaheadComponent = forwardRef((props: TypeaheadComponentProps, ref) => {
  const {
    addNewIcon,
    addNewText,
    allowAddNew,
    assignToolOwnerIcon,
    assignToolOwnerText,
    clickAwayToClose,
    currentToolOwnerId,
    customListItem,
    data,
    defaultData,
    defaultInputValue,
    disabled,
    fields,
    getInputValue,
    isCondensed,
    isCreatorFilter = false,
    isFetching,
    isSubscriptionInDiscovery,
    isToolOwner,
    isToolOwnerFilter,
    isToolOwnerNameEdited,
    isUserSection,
    isVendorSelect,
    isWorkflowRequest,
    label,
    leftIcon,
    onBlurToolOwnerFilter,
    onHideVisibility,
    onItemSelected,
    onTextChanged,
    placeholder,
    renderCustomInput,
    rightIcon,
    value,
  } = props;

  const shouldCloseWhenClickingAway = clickAwayToClose || typeof clickAwayToClose === 'undefined';
  const [activeIndex, setActiveIndex] = useState<number>(NO_SELECTION_REQUEST_INDEX);
  const [matches, setMatches] = useState<Array<any>>([]);
  const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<string>(defaultInputValue || '');
  const [currentInputValue, setCurrentInputValue] = useState<string>('');
  const [height, setHeight] = useState<number>();
  const [top, setTop] = useState<number>();
  const isMounted = useRef(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputTextRef = useRef() as React.RefObject<{ select?: () => void; clientHeight?: number }>;
  const DropdownMenuRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const pathToTranslation = 'common:type_ahead_component';
  const dispatch = useAppDispatch();

  const { connected: isConnectedToGoogle } = useAppSelector((state) => state.common.googleDirectory);

  const clearSelection = useCallback((): void => {
    setActiveIndex(NO_SELECTION_REQUEST_INDEX);
    if (!defaultData && !isToolOwnerFilter) setMatches([]);
    setDisplayDropdown(false);
    onHideVisibility?.();
  }, [defaultData, isToolOwnerFilter, onHideVisibility]);

  const collapse = useCallback(() => {
    clearSelection();
    onHideVisibility?.();
    getInputValue?.('');
    setDisplayDropdown(false);
  }, [clearSelection, getInputValue, onHideVisibility]);

  const handleOnfocus = useCallback(() => {
    setDisplayDropdown(true);

    if (isToolOwnerFilter) {
      setMatches(data);
    }
  }, [data, isToolOwnerFilter]);

  const handleOnBlur = useCallback(() => {
    clearSelection();
    onHideVisibility?.();
    getInputValue?.('');

    if (isToolOwnerFilter) {
      onBlurToolOwnerFilter?.();
    }
  }, [clearSelection, getInputValue, isToolOwnerFilter, onBlurToolOwnerFilter, onHideVisibility]);

  const keydownHookEventFn = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Tab') {
        return;
      }

      if (!displayDropdown) {
        handleOnfocus();
      } else if (displayDropdown && evt.key === 'Escape') {
        evt.preventDefault();
        evt.stopPropagation();
        collapse();
        return false;
      }
    },
    [collapse, displayDropdown, handleOnfocus]
  );

  // Click away to close
  useEffect(() => {
    const clickOutsideHandler: EventListenerOrEventListenerObject = (evt) => {
      if (!displayDropdown || !wrapperRef.current) {
        return;
      }

      const target = evt.target as Element;
      const targetContainer = target?.closest('.sastrify-typeahead');

      if (targetContainer && targetContainer === wrapperRef.current) {
        return; // Can't be closed because user clicked inside the Typeahead component
      }

      evt.preventDefault();
      evt.stopPropagation();
      handleOnBlur();
      return false;
    };

    const doCollapse = (evt: KeyboardEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
      collapse();
      return false;
    };

    const controlEscKeyHandler = (evt: KeyboardEvent) => {
      if (displayDropdown && evt.key === 'Escape') {
        return doCollapse(evt);
      }
      return true;
    };

    if (shouldCloseWhenClickingAway) {
      document.addEventListener('mouseup', clickOutsideHandler);
      document.addEventListener('touchend', clickOutsideHandler);
      document.addEventListener('keydown', controlEscKeyHandler, true);
    }

    return () => {
      document.removeEventListener('mouseup', clickOutsideHandler);
      document.removeEventListener('touchend', clickOutsideHandler);
      document.removeEventListener('keydown', controlEscKeyHandler, true);
    };
  }, [collapse, displayDropdown, handleOnBlur, shouldCloseWhenClickingAway]);

  // Typeahead handling
  useEffect(() => {
    if (isMounted.current) {
      if (inputTextRef.current && isToolOwner) {
        setMatches(data);
        inputTextRef.current.select?.();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [data, defaultInputValue, isToolOwner]);

  useEffect(() => {
    if (defaultData && defaultData.length > 0 && !value) setMatches(defaultData);
  }, [defaultData, value]);

  useEffect(() => {
    const inputHeight = Number(inputTextRef?.current?.clientHeight);
    setHeight(Number(DropdownMenuRef?.current?.clientHeight) + inputHeight);
    setTop(inputHeight);
  }, [currentInputValue, data, displayDropdown]);

  useEffect(() => {
    if (!isToolOwner && !isToolOwnerFilter && value) {
      setMatches(data);
    }
  }, [data, isToolOwner, isToolOwnerFilter, value]);

  useEffect(() => {
    if (data && isToolOwnerFilter && !value) {
      setMatches(data);
    }
  }, [data, isToolOwnerFilter, value]);

  useImperativeHandle(ref, () => ({
    collapse,
    handleClearDefaultOwnerNameValue: () => {
      if (defaultValue) setDefaultValue('');
      setMatches(data);
    },
  }));

  const connectToGoogleDirectory = () => {
    if (!isConnectedToGoogle) {
      clearSelection();
      dispatch(connect());
    }
  };

  const itemSelected = (item: any): void => {
    if (item) {
      onItemSelected(item);
      clearSelection();
    }
  };

  const filterByText = (text: string) => {
    setCurrentInputValue(text);
    getInputValue?.(text);

    const searchString = text.toLowerCase();
    if (!defaultData) {
      const filtered = data.filter((item) =>
        fields?.some((field) => item[field]?.toLowerCase().includes(searchString))
      );
      setMatches(filtered);
    }
  };

  const textChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (defaultValue) setDefaultValue('');
    onTextChanged(event);
    filterByText(event.target.value);
  };

  const getMatchItem = ({ index, isAssigned, isInvite, match }: MatchItem) => {
    const displayMatchItem = (currentToolOwnerId !== match?.id && !isToolOwnerNameEdited) || isToolOwnerNameEdited;

    const onItemClick = (evt: any) => {
      stopEventPropagation(evt);

      if (!isInvite) {
        itemSelected(isAssigned ? { name: currentInputValue } : match);
      } else {
        onItemSelected(currentInputValue);
      }
    };

    return (
      <div
        tabIndex={0}
        role='button'
        onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => onItemClick(e))}
        onClick={onItemClick}
        key={`query_match_${index}`}>
        <a
          className={classnames('typeahead__anchor-item dropdown-item', {
            'is-active': index === activeIndex,
            'is-hidden': isToolOwner && !displayMatchItem,
          })}>
          <div className='dropdown-item-content'>
            {isAssigned && assignToolOwnerIcon && assignToolOwnerText && (
              <div className='content-field is-add dropdown-icon' key={`dropdown_content_${index}`}>
                <span className='icon'>
                  <i className={assignToolOwnerIcon} />
                </span>
                {assignToolOwnerText}
              </div>
            )}
            {Object.keys(match || {}).length > 0 && !customListItem ? (
              <div className={classnames('content-field')} key={`dropdown_content_${match?.id}`}>
                <Grid container spacing={1} alignItems='center'>
                  <Grid item lg={10}>
                    <UserNameAndAvatarComponent
                      icon={match?.icon}
                      avatarUrl={match?.avatar || match?.avatarUrl}
                      name={String(match?.name)}
                      email={match?.givenName ? String(match?.email) : undefined}
                      width='1.5rem'
                      height='1.5rem'
                    />
                  </Grid>
                  <Grid item lg={2}>
                    {!(match?.id || Number(match?.value)) && (
                      <i className='fas fa-exclamation-triangle ml-2 is-primary' />
                    )}
                  </Grid>
                </Grid>
              </div>
            ) : (
              customListItem?.(match)
            )}
            {isInvite && (
              <div className='is-fixed content-field is-add dropdown-icon' key={`dropdown_content_${index}`}>
                <span className='icon'>
                  <i className={`${addNewIcon}`} />
                </span>
                {addNewText}
              </div>
            )}
          </div>
        </a>
      </div>
    );
  };

  const stopEventPropagation = (event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement, MouseEvent>) => {
    if (isCondensed) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    switch (event.key) {
      case KeyboardKeys.Enter:
        if (defaultData) {
          event.preventDefault();
          event.currentTarget.blur();
        }
        itemSelected(matches[activeIndex]);
        break;
      case KeyboardKeys.Esc:
        clearSelection();
        break;
      case KeyboardKeys.ArrowUp:
        setActiveIndex(activeIndex >= 1 ? activeIndex - 1 : 0);
        break;
      case KeyboardKeys.ArrowDown:
        setActiveIndex(activeIndex < matches.length - 1 ? activeIndex + 1 : matches.length - 1);
        break;
      default:
        break;
    }
  };

  const handleItemClick = (event?: React.MouseEvent<HTMLAnchorElement>) => {
    if (event) stopEventPropagation(event);
    onItemSelected(currentInputValue);
  };

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    const input =
      event.currentTarget.tagName === 'INPUT'
        ? event.currentTarget
        : event.currentTarget.getElementsByTagName('input')[0];

    if (!displayDropdown && input && !input.disabled) {
      if (input.value) {
        filterByText(input.value);
      } else {
        handleOnfocus();
      }
      setDisplayDropdown(true);
    }

    return false;
  };

  const toolOwnerExtras = [
    { icon: <WithoutInvitation />, name: 'Without invitation', value: 'withoutInvitation' },
    { icon: <NotAssigned />, name: 'Not assigned', value: 'notAssigned' },
  ];

  const showNoMatchesMessage = !!(
    defaultData &&
    !isToolOwnerFilter &&
    ((!isVendorSelect && value && matches.length === 0) ||
      (isVendorSelect && value.length >= 3 && matches.length === 0))
  );

  const hasToolOwnerExtrasMatch = toolOwnerExtras.filter(
    (item) => value && item.name.toLocaleLowerCase().includes(value.toLowerCase())
  );
  const hasNoToolOwnersMatch =
    data && isToolOwnerFilter && !isCreatorFilter && !matches.length && !hasToolOwnerExtrasMatch.length;
  const showRightIcon = displayDropdown && (defaultValue || value);

  const addNewDropdownItem = getMatchItem({
    index: ADD_NEW_REQUEST_INDEX,
  });

  return (
    <div data-testid='typeahead-container' className='sastrify-typeahead field' ref={wrapperRef}>
      {label && <label className='label'>{label}</label>}
      <div className='control'>
        <div className={classnames('dropdown', { 'is-active': false })}>
          <div
            className={classnames('control dropdown-trigger', { 'is-disabled': disabled, 'is-loading': isFetching })}>
            {renderCustomInput ? (
              renderCustomInput({
                autoFocus: isToolOwner,
                disabled,
                inputTextRef,
                leftIcon,
                onChange: textChanged,
                onClick: handleInputClick,
                onFocus: handleOnfocus,
                onKeyDown: keydownHookEventFn,
                onKeyUp: handleKeyPress,
                placeholder,
                rightIcon: showRightIcon ? rightIcon : null,
                value: defaultValue || value,
              })
            ) : (
              <Input
                type='text'
                inputRef={inputTextRef}
                onChange={textChanged}
                onKeyDown={keydownHookEventFn}
                onKeyUp={handleKeyPress}
                onFocus={handleOnfocus}
                onClick={handleInputClick}
                disabled={disabled}
                value={defaultValue || value}
                placeholder={placeholder}
                leftIcon={leftIcon}
                rightIcon={showRightIcon ? rightIcon : null}
                autoFocus={isToolOwner}
              />
            )}
          </div>
          {displayDropdown && (
            <div
              className='typeahead__dropdown--section sastrify-dropdown-menu'
              ref={DropdownMenuRef}
              style={{ top: `${top}px` }}>
              {(defaultData || isToolOwner || isToolOwnerFilter || value.length > 0) && (
                <div className='dropdown-content'>
                  {isToolOwnerFilter && !isSubscriptionInDiscovery && (
                    <div className='tool-owners-extras-dropdown px-2.5'>
                      {toolOwnerExtras.map((item) => {
                        if (item.value === 'withoutInvitation' && isCreatorFilter) {
                          return null;
                        }
                        return (
                          <a
                            key={item.value}
                            className='tool-owner-extras dropdown-item'
                            tabIndex={0}
                            role='button'
                            onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => itemSelected(item))}
                            onClick={() => itemSelected(item)}>
                            {item.icon}
                            <p>{item.name}</p>
                          </a>
                        );
                      })}
                    </div>
                  )}
                  {!showNoMatchesMessage ? (
                    <>
                      {defaultData && !value && <p className='quick-add'>{t(`${pathToTranslation}.quick_add_text`)}</p>}
                      {allowAddNew ? addNewDropdownItem : ''}
                      {matches.length
                        ? matches.map((match, index) => getMatchItem({ index, match }))
                        : getMatchItem({ index: ASSIGN_TOOL_OWNER_INDEX, isAssigned: true })}
                    </>
                  ) : (
                    <p
                      className={['is-normal has-text-grey ml-2', isWorkflowRequest ? 'is-workflow-request' : ''].join(
                        ' '
                      )}>
                      <Trans
                        i18nKey={
                          isWorkflowRequest
                            ? 'requests_view:type_ahead_component.no_match_message'
                            : `${pathToTranslation}.no_match_message`
                        }
                        values={{ value }}
                        components={[<strong>{value}</strong>]}
                      />
                    </p>
                  )}
                  {hasNoToolOwnersMatch && (
                    <p className='is-normal has-text-grey ml-2'>{t(`${pathToTranslation}.no_match_text`)}</p>
                  )}
                </div>
              )}
            </div>
          )}
          {displayDropdown && addNewIcon && addNewText ? (
            <div
              className={classnames('invite-user-section', {
                'hide-invite-user-section': isUserSection,
              })}
              style={{ top: `${height}px` }}>
              <a
                className={classnames('typeahead__anchor-item dropdown-item')}
                tabIndex={0}
                role='button'
                onKeyUp={(e) => onEnterOrSpaceKeyUp(e, handleItemClick)}
                onClick={(event) => handleItemClick(event)}>
                <div className='dropdown-item-content' data-testid='invite-user'>
                  <div className='is-fixed content-field is-add dropdown-icon'>
                    <span className='icon'>
                      <AvatarIcon />
                    </span>
                    <span>{t(`${pathToTranslation}.invite_users_text`)}</span>
                  </div>
                </div>
              </a>
              <a
                className={classnames('typeahead__anchor-item dropdown-item')}
                tabIndex={0}
                role='button'
                onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => connectToGoogleDirectory())}
                onClick={(event) => {
                  stopEventPropagation(event);
                  connectToGoogleDirectory();
                }}>
                <div className='dropdown-item-content'>
                  <div
                    data-testid='connect-google'
                    className={classnames('is-fixed content-field is-add dropdown-icon', {
                      'google-connect-text': !isConnectedToGoogle,
                    })}>
                    <span className='icon'>
                      <GoogleWorkspaceIcon />
                    </span>
                    <span className='connect-google-text'>
                      {t(
                        `${pathToTranslation}.${
                          isConnectedToGoogle ? 'connected_to_google_directory' : 'connect_google_directory'
                        }`
                      )}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});
