import { Avatar, Box } from '@mui/material';
import { FC } from 'react';

import { SubscriptionCoveredBadgeComponent } from '..';
import { VendorLogoComponentProps } from './vendor-logo.component.props';
import { VendorLogoWrapper } from './vendor-logo.styles';

export type StylesProps = {
  width?: string;
  height?: string;
};

export const VendorLogoComponent: FC<VendorLogoComponentProps> = ({
  height = '2.875rem',
  isSubscriptionInDiscovery,
  noBadge,
  subscription,
  subscriptionName,
  vendorLogoUrl,
  width = '2.875rem',
}) => {
  return (
    <VendorLogoWrapper width={width} height={height}>
      <div className='vendor-logo'>
        <Avatar alt={subscriptionName} variant='rounded' src={vendorLogoUrl}>
          {subscriptionName}
        </Avatar>
      </div>
      {subscription && !noBadge && (
        <Box position='absolute' left='-6px' top='-7px'>
          <SubscriptionCoveredBadgeComponent
            subscription={subscription}
            isSubscriptionInDiscovery={isSubscriptionInDiscovery}
            size={19}
          />
        </Box>
      )}
    </VendorLogoWrapper>
  );
};
