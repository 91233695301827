import { Box, Button, Divider, Link as MUILink, Typography } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DrawerInfoType, PRIVACY_POLICY_LINK, TERMS_AND_CONDITION_LINK } from 'shared/common.definitions';
import { getWorkatoIframeUrl } from 'shared/logic/tool-usage-analytics.logic';
import { show as showDrawer } from 'shared/store/common/appDrawer.slice';

import { UsageAnalyticsIframeSkeleton } from '../../skeletons/usage-analytics-iframe.skeleton';
import {
  ContentSection,
  ErrorAlert,
  FooterSection,
  HeaderSection,
  Iframe,
  Wrapper,
} from '../drawer-usage-analytics.styles';

interface UsageAnalyticsContentProps {
  title: string;
  handleCloseDrawer: () => void;
  connectionId: string;
  token: string;
  isLoadingIframe: boolean;
  workatoRecipeError: boolean;
  startIntegrationSuccess: boolean;
  startIntegrationError: boolean;
  iframeHeight: string;
  providerName: string;
  informationSection: JSX.Element;
  startIntegrationStatus: JSX.Element;
}

export const UsageAnalyticsContent: FC<UsageAnalyticsContentProps> = (props: UsageAnalyticsContentProps) => {
  const {
    connectionId,
    handleCloseDrawer,
    iframeHeight,
    informationSection,
    isLoadingIframe,
    providerName,
    startIntegrationError,
    startIntegrationStatus,
    startIntegrationSuccess,
    title,
    token,
    workatoRecipeError,
  } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const workatoRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (workatoRef?.current) {
      workatoRef.current.style.height = iframeHeight;
    }
  }, [iframeHeight]);

  return (
    <Wrapper>
      <Box height='100%' display='flex' flexDirection='column'>
        {startIntegrationSuccess || startIntegrationError ? (
          startIntegrationStatus
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
            <HeaderSection>
              <Typography variant='h2' color='text.primary' sx={{ marginBottom: 3 }}>
                {title}
              </Typography>
            </HeaderSection>
            <ContentSection>
              {informationSection}
              {isLoadingIframe && <UsageAnalyticsIframeSkeleton />}
              {workatoRecipeError && (
                <ErrorAlert
                  description={t(
                    'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.error_alert:description'
                  )}
                  actionText={t('common:contact_sastrify')}
                  severity='error'
                  actionIcon={false}
                  onAction={() => dispatch(showDrawer(DrawerInfoType.SASTRIFY_APP_HELP))}
                  isIconVisible={false}
                  title={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.error_alert:title')}
                  sx={{ mb: 3, mt: 1 }}
                />
              )}
              {!isLoadingIframe && !workatoRecipeError && connectionId && token && (
                <Box>
                  <Iframe
                    ref={workatoRef}
                    style={{ visibility: !workatoRecipeError && !isLoadingIframe ? 'visible' : 'hidden' }}
                    title={providerName}
                    key={connectionId}
                    id='workatoId'
                    src={getWorkatoIframeUrl(connectionId, token)}
                  />
                </Box>
              )}
            </ContentSection>
            <Divider orientation='horizontal' textAlign='center' />
            <FooterSection>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pb: 2,
                  pt: 2,
                  width: '100%',
                }}>
                <Typography variant='body1' color='text.primary' mt='1.5rem' sx={{ marginTop: 0 }}>
                  {`${t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.connection_agreement')} `}
                  <MUILink href={TERMS_AND_CONDITION_LINK} target='_blank'>
                    {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.terms_and_condition')}
                  </MUILink>
                  {` ${t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.and')} `}
                  <MUILink href={PRIVACY_POLICY_LINK} target='_blank'>
                    {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.privacy_policy')}
                  </MUILink>
                </Typography>
                <Button variant='text' color='secondary' onClick={handleCloseDrawer}>
                  {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.close_button_text')}
                </Button>
              </Box>
            </FooterSection>
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};
