import { Grid } from '@mui/material';
import { theme } from 'asteroids';
import { DrawerUsageAnalyticsDirect, DrawerUsageAnalyticsSso } from 'components/drawer-usage-analytics';
import { DrawerUsageAnalyticsManageSso } from 'components/drawer-usage-analytics/drawer-usage-analytics-manage-sso/drawer-usage-analytics-manage-sso.component';
import { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DrawerInfoType, featureFlags } from 'shared/common.definitions';
import { decodeIdFromRoute, getHash } from 'shared/helpers/common.helper';
import { getCommentTemplates, getSubscriptionName, showdownConverter } from 'shared/logic/subscription-item.logic';
import { CommentTemplate, Subscription } from 'shared/models';
import { clear as clearDrawer } from 'shared/store/common/appDrawer.slice';
import { useAppSelector } from 'shared/store/hooks';
import { clearSelectedSsoProvider, clearSelectedVendor } from 'shared/store/views/connect/usage-analytics-item.slice';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { useFetchData } from '../../hooks';
import { DrawerInfoSectionComponent, DrawerUploadSectionComponent } from '..';
import { DrawerManageSSOIntegration } from '../drawer-manage-sso-integration/drawer-manage-sso-integration.component';
import { DrawerComponentProps } from './drawer.component.props';
import { DrawerStyledContainer } from './drawer.styles';
import { DrawerManageIntegration } from './drawer-manage-integration/drawer-manage-integration.component';

export const DrawerComponent: FC<DrawerComponentProps> = (props: DrawerComponentProps) => {
  const {
    closeModal,
    infoType: infoTypeProp,
    inviteUserInitialValue,
    isCompanyDocument,
    isDrawerOpen,
    isSubscriptionInDiscovery,
    onSuccess,
    openSuccessConfirmationModal,
    showModal,
    showNotification,
    toggleDrawer,
    userData,
  } = props;

  const appDrawerType = useAppSelector((state) => state.common?.appDrawer?.type);
  const appDrawerData = useAppSelector((state) => state.common?.appDrawer?.data);
  const infoType = infoTypeProp || appDrawerType;

  const isUsageAnalyticsDrawer = [
    DrawerInfoType.USAGE_ANALYTICS_MANAGE_CONNECTION,
    DrawerInfoType.USAGE_ANALYTICS_DIRECT,
    DrawerInfoType.USAGE_ANALYTICS_SSO,
  ].includes(infoType);

  const isMidSizeDrawer = [
    DrawerInfoType.MANAGE_CODAT_INTEGRATIONS,
    DrawerInfoType.USAGE_ANALYTICS_DIRECT,
    DrawerInfoType.USAGE_ANALYTICS_SSO,
    DrawerInfoType.USAGE_ANALYTICS_MANAGE_CONNECTION,
    DrawerInfoType.MANAGE_SSO_INTEGRATIONS,
  ].includes(infoType);

  const isFullWidth = [
    DrawerInfoType.REQUEST_DETAILS,
    DrawerInfoType.USER_INVITE,
    DrawerInfoType.USER_INVITE_SUBSCRIPTIONS,
    DrawerInfoType.USER_INVITE_SUBSCRIPTION,
  ].includes(infoType);

  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();

  const subscriptionId = decodeIdFromRoute(location.pathname);

  const subscription = queryClient.getQueryData(['subscription', subscriptionId]) as Subscription;
  const dispatch = useDispatch();

  const { data: commentTemplates } = useFetchData<CommentTemplate[]>('comment-templates', getCommentTemplates, {
    refetchOnWindowFocus: false,
  });

  const commentTemplatesMarkdown: CommentTemplate[] | undefined = commentTemplates?.map((commentTemplate) => ({
    ...commentTemplate,
    template: showdownConverter.makeHtml(commentTemplate.template),
  }));

  const handleCloseDrawer = () => {
    if (infoType === DrawerInfoType.COMPANY_DOCUMENTS) {
      if (appDrawerData?.spendImportItem) {
        history.replace(NAVIGATION_URLS.SPEND_IMPORT);
      } else {
        history.replace(NAVIGATION_URLS.SUBSCRIPTIONS);
      }
    }

    if (
      infoType === DrawerInfoType.NEW_REQUEST_WORKFLOW ||
      infoType === DrawerInfoType.WORKFLOW_REQUEST ||
      infoType === DrawerInfoType.REQUEST_DETAILS ||
      infoType === DrawerInfoType.WORKFLOW_REQUEST_EDIT
    ) {
      const includedHash = getHash(location.hash);
      history.replace(`${location.pathname}${location.search}${includedHash}`);
    }

    if (isUsageAnalyticsDrawer) {
      dispatch(clearSelectedVendor());
      dispatch(clearSelectedSsoProvider());
    }

    toggleDrawer();

    // Clear the drawer type and data after the drawer is closed
    setTimeout(() => {
      dispatch(clearDrawer());
    }, theme.transitions.duration.leavingScreen);
  };

  // drawer component style calculations based on user roles feature flag
  const isRequestWorkflow = infoType === DrawerInfoType.NEW_REQUEST_WORKFLOW;
  const isProcurementSupportEnabled = !useCompanyFeatureFlag(featureFlags.PROCUREMENT_SUPPORT_EXCLUDED);
  const modifyInitiativeInfoTypes = [DrawerInfoType.WORKFLOW_REQUEST, DrawerInfoType.WORKFLOW_REQUEST_EDIT];
  const isInviteUserMode = [
    DrawerInfoType.USER_INVITE,
    DrawerInfoType.USER_INVITE_SUBSCRIPTIONS,
    DrawerInfoType.USER_INVITE_SUBSCRIPTION,
  ].includes(infoType);

  const rootClasses = isInviteUserMode ? 'rootUpdated' : '';
  const rightSectionGridSize =
    isRequestWorkflow || (isProcurementSupportEnabled && modifyInitiativeInfoTypes.includes(infoType)) ? 12 : 8;
  const withInfoSection =
    !isRequestWorkflow && !(isProcurementSupportEnabled && modifyInitiativeInfoTypes.includes(infoType));

  const getMidSizeDrawerContent = () => {
    switch (infoType) {
      case DrawerInfoType.MANAGE_CODAT_INTEGRATIONS:
        return <DrawerManageIntegration handleCloseDrawer={handleCloseDrawer} />;
      case DrawerInfoType.USAGE_ANALYTICS_DIRECT:
        return <DrawerUsageAnalyticsDirect handleCloseDrawer={handleCloseDrawer} />;
      case DrawerInfoType.USAGE_ANALYTICS_SSO:
        return <DrawerUsageAnalyticsSso handleCloseDrawer={handleCloseDrawer} />;
      case DrawerInfoType.USAGE_ANALYTICS_MANAGE_CONNECTION:
        return <DrawerUsageAnalyticsManageSso handleCloseDrawer={handleCloseDrawer} />;
      case DrawerInfoType.MANAGE_SSO_INTEGRATIONS:
        return <DrawerManageSSOIntegration />;
      default:
        return null;
    }
  };

  const [hideAfterNoDrawerType, setHideAfterNoDrawerType] = useState(false);

  useEffect(() => {
    if (isDrawerOpen && appDrawerType) {
      setHideAfterNoDrawerType(true);
    }

    if (isDrawerOpen && !appDrawerType) {
      setHideAfterNoDrawerType(false);
    }
  }, [isDrawerOpen, appDrawerType]);

  return (
    <DrawerStyledContainer
      anchor='right'
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
      classes={{ paper: rootClasses }}
      $isFullWidth={isFullWidth}
      $isMidSize={isMidSizeDrawer}
      $isInitiativeCustomWidth={isProcurementSupportEnabled && modifyInitiativeInfoTypes.includes(infoType)}
      sx={{
        ...(!appDrawerType && hideAfterNoDrawerType ? { display: 'none' } : {}),
      }}
      closeAfterTransition>
      {isMidSizeDrawer ? (
        getMidSizeDrawerContent()
      ) : (
        <Grid data-testid='document-drawer' container spacing={1}>
          {withInfoSection && (
            <Grid item xs={4} className='leftSectionWrapper'>
              <DrawerInfoSectionComponent
                infoType={infoType}
                toggleDrawer={toggleDrawer}
                vendorName={getSubscriptionName(subscription)}
                showNotification={showNotification}
              />
            </Grid>
          )}
          <Grid item xs={rightSectionGridSize} className='rightSectionWrapper' data-testid='drawer-right-section'>
            <DrawerUploadSectionComponent
              drawerType={infoType}
              userData={userData}
              toggleDrawer={toggleDrawer}
              isCompanyDocument={isCompanyDocument}
              isSubscriptionInDiscovery={isSubscriptionInDiscovery}
              showNotification={showNotification}
              isUploadingDocument={isUploadingDocument}
              setIsUploadingDocument={setIsUploadingDocument}
              commentTemplates={commentTemplatesMarkdown}
              showModal={showModal}
              closeModal={closeModal}
              inviteUserInitialValue={inviteUserInitialValue}
              openSuccessConfirmationModal={openSuccessConfirmationModal}
              closeDrawer={handleCloseDrawer}
              onSuccess={onSuccess}
            />
          </Grid>
        </Grid>
      )}
    </DrawerStyledContainer>
  );
};
