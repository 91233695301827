import { Box, Divider, Icon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface CustomItemComponentProps {
  onCreateCustomItemClick: () => void;
  searchTerm: string;
}

const CustomSubscription = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1.5),
}));

export const CustomItem: React.FC<CustomItemComponentProps> = ({ onCreateCustomItemClick, searchTerm }) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <CustomSubscription onMouseDown={onCreateCustomItemClick}>
        <Icon>add</Icon>&nbsp;
        <Box>{t('tools_dropdown_list_component:search_bar:custom_subscription_text', { searchTerm })}</Box>
      </CustomSubscription>
    </>
  );
};
