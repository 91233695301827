import { Button, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionsBox, ConfirmationModalBox } from './confirmation-modal-v2.helper';

export type ConfirmationModalComponentV2Props = {
  title?: string;
  description?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  customHeader?: React.ReactNode;
  customMessage?: React.ReactNode;
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export const ConfirmationModalComponentV2: FC<ConfirmationModalComponentV2Props> = (props) => {
  const { t } = useTranslation();
  const {
    cancelButtonLabel = t('common:modals.confirmation_modal.cancel_button_text'),
    confirmButtonLabel = t('common:modals.confirmation_modal.confirm_button_text'),
    customHeader,
    customMessage,
    description,
    onCancel,
    onClose,
    onConfirm,
    title,
  } = props;

  const handleOnCancelClick = () => {
    onClose?.();
    onCancel?.();
  };

  const handleOnConfirmClick = () => {
    onClose?.();
    onConfirm?.();
  };

  return (
    <ConfirmationModalBox>
      {customHeader || (
        <Typography variant='h2' color='text.primary'>
          {title}
        </Typography>
      )}
      {customMessage || (
        <Typography variant='subtitle1' color='text.secondary'>
          {description}
        </Typography>
      )}
      <ActionsBox>
        <Button
          onClick={handleOnCancelClick}
          sx={{ mr: 0.5 }}
          variant='text'
          color='secondary'
          data-testid='modal-cancel-button'>
          {cancelButtonLabel}
        </Button>
        <Button
          onClick={handleOnConfirmClick}
          variant='contained'
          color='error'
          data-testid='modal-confirm-button'
          disableRipple
          disableElevation>
          {confirmButtonLabel}
        </Button>
      </ActionsBox>
    </ConfirmationModalBox>
  );
};
