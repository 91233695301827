import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined';
import IconButton from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { boxShadows, globalTheme } from 'shared/theme';

export enum KebabMenuOptions {
  edit = 'edit',
  delete = 'delete',
  details = 'details',
}

interface KebabMenuProps {
  onEdit?: () => void;
  onDelete?: () => void;
  onDetails?: () => void;
  availableOptions: string[];
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    {...props}
  />
))(() => ({
  '& .MuiButtonBase-root.MuiMenuItem-root:hover': {
    backgroundColor: alpha(globalTheme.palette.primary.main, 0.08),
  },
  '& .MuiPaper-root': {
    boxShadow: boxShadows.boxShadowOne,
    minWidth: 100,
  },
  '& ul': {
    padding: 0,
  },
}));

export const KebabMenuComponent = ({ availableOptions, onDelete, onDetails, onEdit }: KebabMenuProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} size='small' data-testid='menu-open'>
        <MoreVertOutlined />
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose} disableAutoFocus>
        {availableOptions.includes(KebabMenuOptions.edit) && (
          <MenuItem
            onClick={() => {
              if (onEdit) onEdit();
              handleClose();
            }}
            disableRipple
            data-testid='menu-item-edit'>
            {t(`${pathToTranslation}.request_details_section.kebab_menu_options.edit`)}
          </MenuItem>
        )}
        {availableOptions.includes(KebabMenuOptions.delete) && (
          <MenuItem
            onClick={() => {
              if (onDelete) onDelete();
              handleClose();
            }}
            disableRipple
            data-testid='menu-item-delete'>
            {t(`${pathToTranslation}.request_details_section.kebab_menu_options.delete`)}
          </MenuItem>
        )}
        {availableOptions.includes(KebabMenuOptions.details) && (
          <MenuItem
            onClick={() => {
              if (onDetails) onDetails();
              handleClose();
            }}
            disableRipple
            data-testid='menu-item-details'>
            {t(`${pathToTranslation}.request_details_section.kebab_menu_options.details`)}
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};
