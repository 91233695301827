import { Box, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable, StatusDot } from 'asteroids';
import { CopyIcon } from 'components/subscription-details/components/usage-analytics/usage-analytics.styles';
import { MRT_Cell, MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'shared/helpers/common.helper';
import { WorkatoLicenseHolderData } from 'shared/models/tool-usage-analytics.model';

import { UsageAnalyticsLicenseHoldersProps } from '../usage-analytics.props';

export const UsageAnalyticsLicenseHolders: FC<UsageAnalyticsLicenseHoldersProps> = (
  props: UsageAnalyticsLicenseHoldersProps
) => {
  const { t } = useTranslation();

  const { data } = props;

  const [copyTooltip, setCopyTooltip] = useState<string>(t('common:clipboard.copy_to_clipboard'));
  const copyToClipboard = (content: string) => {
    setCopyTooltip(t('common:clipboard.copied'));
    navigator.clipboard.writeText(content);
  };

  const NameCell = useMemo(
    () =>
      ({ row: { original: licenseHolder } }: { row: MRT_Row<WorkatoLicenseHolderData> }): React.ReactNode =>
        <Typography sx={{ overflowWrap: 'break-word' }}>{licenseHolder.appUserName}</Typography>,
    [t]
  );

  const EmailCell = useMemo(
    () =>
      ({ row: { original: licenseHolder } }: { row: MRT_Row<WorkatoLicenseHolderData> }): React.ReactNode =>
        <Typography sx={{ mr: 1, overflowWrap: 'anywhere' }}>{licenseHolder.appUserEmail}</Typography>,
    [t]
  );

  const LastActiveCell = useMemo(
    () =>
      ({ row: { original: licenseHolder } }: { row: MRT_Row<WorkatoLicenseHolderData> }): React.ReactNode =>
        <Typography sx={{ mr: 1, overflowWrap: 'anywhere' }}>{formatDate(`${licenseHolder.activityDate}`)}</Typography>,
    [t]
  );

  const ActiveCell = useMemo(
    () =>
      ({
        cell: { getValue },
        row: { original: licenseHolder },
      }: {
        row: MRT_Row<WorkatoLicenseHolderData>;
        cell: MRT_Cell<WorkatoLicenseHolderData>;
      }): React.ReactNode =>
        (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '10px' }}>
            <StatusDot status={licenseHolder.isActive ? 'success' : 'error'} />
            <Typography>{getValue()}</Typography>
          </Box>
        ),
    [t]
  );

  const CopyCell = useMemo(
    () =>
      ({ row: { original: licenseHolder } }: { row: MRT_Row<WorkatoLicenseHolderData> }): React.ReactNode =>
        (
          <Tooltip placement='bottom' title={copyTooltip}>
            <CopyIcon onClick={() => copyToClipboard(licenseHolder.appUserEmail)}>content_copy</CopyIcon>
          </Tooltip>
        ),
    [t]
  );

  const columns = useMemo(
    () => [
      {
        Cell: NameCell,
        accessorFn: (licenseHolder: WorkatoLicenseHolderData) => licenseHolder?.appUserName || '',
        enableColumnActions: false,
        header: t('subscription_detail_view:usage_analytics_section:license_holders_modal:name'),
        size: 200,
      },
      {
        Cell: ActiveCell,
        accessorFn: (licenseHolder: WorkatoLicenseHolderData) =>
          licenseHolder.isActive
            ? t('subscription_detail_view:usage_analytics_section:license_holders_modal:active')
            : t('subscription_detail_view:usage_analytics_section:license_holders_modal:inactive'),
        enableColumnActions: false,
        header: t('subscription_detail_view:usage_analytics_section:license_holders_modal:last_90_days'),
        size: 120,
      },
      {
        Cell: EmailCell,
        accessorFn: (licenseHolder: WorkatoLicenseHolderData) => licenseHolder?.appUserEmail || '-',
        enableColumnActions: false,
        header: t('subscription_detail_view:usage_analytics_section:license_holders_modal:contact'),
        size: 200,
      },
      {
        Cell: LastActiveCell,
        accessorFn: (licenseHolder: WorkatoLicenseHolderData) => licenseHolder?.activityDate || '',
        enableColumnActions: false,
        enableColumnSorting: true,
        header: t('subscription_detail_view:usage_analytics_section:license_holders_modal:last_active'),
        size: 200,
        sortingFn: (
          { original: a }: MRT_Row<WorkatoLicenseHolderData>,
          { original: b }: MRT_Row<WorkatoLicenseHolderData>
        ) => {
          const aDate = a.activityDate || '';
          const bDate = b.activityDate || '';
          if (!aDate && bDate) return 1;
          if (aDate && !bDate) return -1;
          if (aDate === bDate) return 0;
          return aDate > bDate ? 1 : -1;
        },
      },
      {
        Cell: CopyCell,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableColumnFilter: false,
        enableColumnOrdering: false,
        enableFiltering: false,
        enableHiding: false,
        enablePinning: false,
        enableResizing: false,
        enableSorting: false,
        id: 'copyCell',
        size: 56,
      },
    ],
    [NameCell, EmailCell, ActiveCell, CopyCell, data, t]
  );

  return (
    <MaterialReactTable
      data={data || []}
      columns={columns as MRT_ColumnDef<object>[]}
      initialState={{
        columnPinning: {
          right: ['copyCell'],
        },
      }}
      enableStickyHeader
      layoutMode='grid'
      enableColumnActions={false}
      enablePagination={false}
      enableMultiSort={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnPinning
      muiTablePaperProps={{ sx: { boxShadow: 'none', height: '100%' } }}
      muiTableContainerProps={{ sx: { height: '600px' } }}
    />
  );
};
