import { Box, styled } from '@mui/material';

export const ToolMenu = styled(Box)<{ isportal: string }>(({ isportal, theme }) => ({
  position: 'absolute',
  zIndex: '6',
  overflowY: 'auto',
  maxHeight: theme.spacing(35),
  width: theme.spacing(52),
  marginTop: isportal === 'true' ? theme.spacing(1) : theme.spacing(6),
  borderRadius: theme.spacing(0.5),
  background: theme.palette.background.paper,
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  h2: {
    margin: theme.spacing(1),
    color: 'rgba(27, 36, 43, 0.6)',
    fontWeight: '500',
    fontSize: '14px',
  },
  '.tool-list-item': {
    padding: theme.spacing(0),
    '> div': {
      padding: theme.spacing(0.5),
      '.tool-list-item-avatar': {
        marginLeft: theme.spacing(0.5),
      },
    },
  },
}));

export const CustomSubscription = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}));
