import { Stack, Typography } from '@mui/material';

import { DetailsItemProps } from './details-item.component.props';

export const DetailsItem = ({ content, icon, testId, title }: DetailsItemProps) => (
  <Stack direction='row' spacing={1} alignItems='flex-start'>
    {typeof icon !== 'undefined' && icon}
    <Stack direction='column' sx={{ flex: 1 }} data-testid={testId}>
      <Typography fontWeight={600} mb='0.5rem'>
        {title}
      </Typography>
      {typeof content === 'string' || typeof content === 'number' ? (
        <Typography fontWeight={300}>{content}</Typography>
      ) : null}
      {typeof content === 'object' ? content : null}
    </Stack>
  </Stack>
);
