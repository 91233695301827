import { Accordion, AccordionProps, styled } from '@mui/material';

interface StyledAccordionProps extends AccordionProps {
  isCompleted?: boolean;
}

export const SubscriptionTaskAccordion = styled(Accordion)<StyledAccordionProps>(({ isCompleted, theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.spacing(0.5),
  boxShadow: 'none',
  marginBottom: theme.spacing(2),
  position: 'relative',

  ...(isCompleted && {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.light}`,
  }),
}));
