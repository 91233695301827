import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionInvoiceUpload } from 'shared/store/views/subscription/subscription-invoice-upload.slice';

export type SpendProcessingAlertProps = {
  uploadedInvoices: SubscriptionInvoiceUpload[];
};

export function useSpendProcessingAlert({ uploadedInvoices }: SpendProcessingAlertProps) {
  const { t } = useTranslation();
  const translationPath = 'tool_details_view:spend_tab.spend_and_invoices_table.invoice_upload_alert_text';

  const alertText = useMemo(() => {
    const { completed, delayed, failed, processing } = uploadedInvoices.reduce(
      (acc, curr) => {
        if (acc[curr.status]) {
          acc[curr.status] += 1;
        } else {
          acc[curr.status] = 1;
        }
        return acc;
      },
      {
        completed: 0,
        delayed: 0,
        failed: 0,
        processing: 0,
        uploaded: 0,
      }
    );

    if (delayed && !processing) {
      return {
        description: t(`${translationPath}.taking_too_long_stage.description`),
        showLoader: true,
        title: t(`${translationPath}.taking_too_long_stage.title`),
      };
    }

    if (failed && failed + completed === uploadedInvoices.length) {
      return {
        description: t(`${translationPath}.failed_stage.description`),
        severity: 'error',
        title: t(`${translationPath}.failed_stage.title`, { total: failed }),
      };
    }

    if (processing) {
      return {
        showLoader: true,
        title: t(`${translationPath}.processing_stage.title`),
      };
    }

    if (failed + completed === uploadedInvoices.length) return undefined;
  }, [t, uploadedInvoices]);

  return {
    alertText,
  };
}
