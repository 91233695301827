import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { DropzoneComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { UploadDocumentAttributesComponent } from 'components/upload-document-attributes';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { ExtendedBlobType } from 'shared/common.definitions';
import { computeChecksumMd5, isNewSolutionsRoute } from 'shared/helpers/common.helper';
import {
  getCommaStrippedFile,
  getPresignedUploadUrl,
  mutateSubscriptionDocument,
  uploadFileToPresignedUrl,
} from 'shared/logic/subscription-item.logic';
import { PresignedUploadUrl, Subscription, SubscriptionDocument } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { AppUrl } from 'src/constants/appurl';
import * as yup from 'yup';

import { UploadSubscriptionDocumentsFeatureProps } from './upload-subscription-documents.feature.props';

const uploadSubscriptionDocumentSchema = yup.object().shape({
  category: yup.number(),
  date: yup.date(),
  file: yup.mixed(),
  name: yup.string(),
});

const INVOICE_DOCUMENT = 'invoice';
const FIELD_NAME = 'documents';

type UploadDocument = {
  data: SubscriptionDocument;
  presignedUploadUrl?: PresignedUploadUrl;
};

export type UploadDocumentRef = {
  onSubmit: () => Promise<void>;
  handleClose: () => void;
};

export const UploadSubscriptionDocumentsFeature = forwardRef((props: UploadSubscriptionDocumentsFeatureProps, ref) => {
  const {
    closeModal,
    companyId,
    documentType,
    isAddNewSubscription,
    isCompanyDocument,
    isSpendDocument,
    onUploadInvoiceTodo,
    setIsFileSelected,
    showNotification,
    subscriptionId,
  } = props;

  const { t } = useTranslation();
  const modalPath = 'common:modals.upload_document_modal';
  const pathToDocumentTabTranslation = 'subscription_detail_view:tabs_component_section.subscription_document_tab';
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const { control, getValues, register, reset, setValue, watch } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(uploadSubscriptionDocumentSchema),
  });

  const queryClient = useQueryClient();

  const fileState = watch('file');
  const isFileState: boolean = fileState === undefined || Array.from(fileState).length === 0;

  const isInvoiceDocument = documentType === INVOICE_DOCUMENT || isSpendDocument;

  const subscription = queryClient.getQueryData(['subscription', subscriptionId]);

  const isSubscriptionInDiscovery = isNewSolutionsRoute(location.pathname);

  const subscriptionDetailUrl = AppUrl.getToolUrl(subscription as Subscription, isSubscriptionInDiscovery);

  useEffect(() => {
    if (!isFileState) {
      setValue('name', fileState[0]?.name);
    }
  }, [isFileState, setValue, fileState]);

  useEffect(() => {
    if (!isAddNewSubscription) {
      dispatch(updateFooterState({ isFormValid: !isFileState }));
    }
  }, [dispatch, isAddNewSubscription, isFileState]);

  const uploadDocumentToS3Bucket = ({ data, presignedUploadUrl }: UploadDocument): Promise<AxiosResponse> => {
    return uploadFileToPresignedUrl(presignedUploadUrl?.url as string, data?.file);
  };

  const { mutateAsync } = useMutation(uploadDocumentToS3Bucket, {
    onError: () => {
      const message = 'There was an error on the upload';

      showNotification?.(message, NotificationAlertType.Error);
    },
  });

  const documentUpload = async (
    file: ExtendedBlobType,
    attributes?: SubscriptionDocument,
    id?: string,
    subscriptionTodoId?: string
  ) => {
    const commaStrippedFile = getCommaStrippedFile(file);

    const checksum = await computeChecksumMd5(commaStrippedFile);
    const newSubscriptionId = id || subscriptionId;

    const documentCategory = documentType === INVOICE_DOCUMENT ? 2 : attributes?.category;

    const getId = isCompanyDocument ? { companyId } : { subscriptionId: newSubscriptionId };

    const formValues = {
      category: documentCategory,
      checksum,
      date: attributes?.date,
      fileName: commaStrippedFile.name,
      name: attributes?.name || undefined,
      subscriptionTodoId,
      ...getId,
    };

    try {
      const presignedUploadUrl: PresignedUploadUrl = await getPresignedUploadUrl(String(companyId));

      await mutateAsync(
        {
          data: {
            file: commaStrippedFile as Blob,
          },
          presignedUploadUrl,
        },
        {
          onSuccess: () => {
            if (documentType === INVOICE_DOCUMENT) {
              onUploadInvoiceTodo?.();
            }
          },
        }
      );

      await mutateSubscriptionDocument({
        companyId,
        data: { ...formValues, fileKey: presignedUploadUrl.key },
        isCompanyDocument,
        subscriptionId: newSubscriptionId,
      });
    } catch {
      const message = 'There was an error on the upload';

      showNotification?.(message, NotificationAlertType.Error);
      dispatch(updateFooterState({ isFormSubmitting: false }));
    }
  };

  const onSubmit = async (id?: string, todoId?: string) => {
    if (!isAddNewSubscription) dispatch(updateFooterState({ isFormSubmitting: true }));
    const data = getValues();
    try {
      const [amountMessageText, documentMessageText] =
        Array.from(fileState).length > 1 ? ['amounts', 'Documents'] : ['amount', 'Document'];
      const uploads: unknown[] = [];

      let message: string;

      if (isCompanyDocument) {
        message = `Upload successful. The ${documentMessageText.toLowerCase()} will be visible on the subscriptions, or you’ll hear back from us soon.`;
      } else if (isSpendDocument) {
        message = `Upload successful. The ${documentMessageText.toLowerCase()} ${amountMessageText.toLowerCase()} and spend graph will be visible after processing.`;
      } else {
        message = `${documentMessageText} saved`;
      }

      Array.from(data.file).forEach((file: unknown, index: number) => {
        uploads.push(documentUpload(file as Blob, data.documents?.[index], id, todoId));
      });
      await Promise.all(uploads);

      queryClient.invalidateQueries('subscription-spend-and-invoices');
      queryClient.invalidateQueries('subscriptionDocuments');
      queryClient.invalidateQueries(['subscription', subscriptionId]);
      queryClient.invalidateQueries('subscriptions');
      queryClient.invalidateQueries('subscriptions-todos');
      queryClient.invalidateQueries('auditLogs');

      reset();
      closeModal?.();
      showNotification?.(message, NotificationAlertType.Success);
      if (isInvoiceDocument) {
        history.push(`${subscriptionDetailUrl}#showSpends`);
      }
    } catch (e: unknown) {
      const message = 'There was an error on the upload';

      showNotification?.(message, NotificationAlertType.Error);
    } finally {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    }
  };

  const handleClose = () => closeModal?.();

  useImperativeHandle(ref, () => ({
    handleClose,
    onSubmit,
  }));

  const onSelectFile = () => {
    if (isAddNewSubscription) setIsFileSelected?.(true);
  };

  const uploadDocumentBodyText = isNewSolutionsRoute(location.pathname)
    ? t(`${modalPath}.document_modal.new_solution_body_text`)
    : t(`${modalPath}.document_modal.subscription_body_text`);

  return (
    <div data-testid='upload-document'>
      {!isAddNewSubscription && (
        <div className='content-title mb-3'>
          {isInvoiceDocument ? t(`${modalPath}.invoice_modal.body_text`) : uploadDocumentBodyText}
        </div>
      )}
      <div className='field file has-name is-boxed is-flex is-flex-direction-column'>
        <Controller
          rules={{ required: true }}
          name='file'
          control={control}
          render={({ field: { onChange } }) => (
            <DropzoneComponent
              name='file'
              onDrop={(value) => {
                onChange(value);
                onSelectFile();
              }}
              onDropRejected={() => {
                const message = t(`${pathToDocumentTabTranslation}.upload_file_type_error_message`);
                showNotification?.(message, NotificationAlertType.Error);
              }}
              message={
                <span className='dropzone-message' data-testid='upload-document-message'>
                  <Trans
                    i18nKey='subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.drag_and_drop_text'
                    values={{
                      clickHere: t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.click_here_text'
                      ),
                    }}
                    components={[<span />]}
                  />
                </span>
              }
              multiple
              isPaperClipIcon
              hasCustomMessage
            />
          )}
        />
        <div className='full-width'>
          {!isAddNewSubscription && isFileState && 'No files selected'}
          {!isFileState &&
            Array.from(fileState)?.map((file: unknown, index: number): unknown => {
              const fieldName = `${FIELD_NAME}[${index}]`;
              return (
                <UploadDocumentAttributesComponent
                  fieldName={fieldName}
                  file={file as Blob}
                  control={control}
                  register={register}
                  key={fieldName}
                  showAttributes={isCompanyDocument}
                  isInvoiceDocument={isInvoiceDocument}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
});
