import { Button, ClickAwayListener, Grow, MenuList, Paper, Popper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { borders, boxShadows, colors } from 'shared/theme';

import { DropdownComponentProps, StylesPropType } from './drop-down.component.props';

const PREFIX = 'DropdownComponent';

const classes = {
  activeButton: `${PREFIX}-activeButton`,
  activeLabel: `${PREFIX}-activeLabel`,
  label: `${PREFIX}-label`,
  popper: `${PREFIX}-popper`,
  root: `${PREFIX}-root`,
  statusButton: `${PREFIX}-statusButton`,
};

const Root = styled('section')(({ theme }) => ({
  [`& .${classes.activeButton}`]: {
    '& .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
    '&:hover': {
      borderColor: `${colors.primary} !important`,
    },
    borderColor: `${colors.primary} !important`,
  },

  [`& .${classes.activeLabel}`]: {
    color: ({ activeLabelColor }: StylesPropType) => activeLabelColor ?? colors.greyText,
  },

  [`& .${classes.label}`]: {
    background: colors.white,
    color: theme.palette.text.secondary,
    padding: '0 .25rem',
    position: 'absolute',
    transform: 'translate3d(.75rem, .19rem, 0)',
    zIndex: 1,
  },

  [`& .${classes.popper}`]: {
    '& .MuiMenuItem-root': {
      color: colors.greyText,
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
      margin: ({ hasMargin }: StylesPropType) => (hasMargin ? '0' : '.4rem 0'),
    },
    '& .MuiPaper-elevation1': {
      border: ({ isAuthenticationUserRoleSection }: StylesPropType) =>
        isAuthenticationUserRoleSection ? `.025rem solid ${colors.iron}` : '',
      boxShadow: ({ isRenderedOnTableColumn }: StylesPropType) =>
        isRenderedOnTableColumn ? boxShadows.dropDownPopperBoxShadow : boxShadows.popperShadow,
    },
    '& .menuItemText': {
      color: colors.greyText,
      fontSize: '0.875rem',
      fontWeight: 'normal',
    },
    '& ul': {
      '& > div.subscription-action-menu': {
        '& > span': {
          '&:hover': {
            background: 'hsl(0, 0%, 96%)',
          },
          display: 'block',
          padding: '0.625rem 0',
        },
        fontSize: '0.875rem',
        textAlign: 'center',
        width: '100%',
      },
      border: ({ isActionMenuDropdown }: StylesPropType) => (isActionMenuDropdown ? borders.actionMenuBorder : 0),
      borderRadius: ({ isActionMenuDropdown }: StylesPropType) => (isActionMenuDropdown ? '0.3125rem' : 0),
      padding: ({ hasPadding }: StylesPropType) => (hasPadding ? 0 : '0.5rem 0'),
    },
    background: colors.white,
    borderRadius: ({ borderRadius }: StylesPropType) => borderRadius ?? '0.25rem',
    marginTop: '.25rem',
    minWidth: ({ menuMinWidth }: StylesPropType) => menuMinWidth ?? '15.625rem',
    zIndex: 5,
  },

  [`& .${classes.root}`]: {
    '& .action-menu-dots': {
      display: 'flex',
      fontSize: '1.25rem',
    },
    '& .action-menu-item': {
      '&:first-child': {
        borderTopLeftRadius: '0.3125rem',
        borderTopRightRadius: '0.3125rem',
      },
      '&:hover': {
        background: 'hsl(0, 0%, 96%)',
      },
      '&:last-child': {
        borderBottom: 0,
        borderBottomLeftRadius: '0.3125rem',
        borderBottomRightRadius: '0.3125rem',
      },
      borderBottom: borders.actionMenuBorder,
      color: colors.greyText,
      cursor: 'pointer',
      display: 'block',
      fontSize: '0.8rem',
      padding: '0.5rem 1rem',
      textAlign: 'left',
    },
    '& .notificationSection': {
      alignItems: 'center',
      borderRight: '1px solid rgba(219, 219, 219, 0.25)',
      color: colors.grey,
      cursor: 'pointer',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      transition: '0.2s background ease-in-out',
    },
    '& .threeDotsSection': {
      '&:hover': {
        background: ({ isRenderedOnTableColumn }: StylesPropType) =>
          isRenderedOnTableColumn ? 'none' : colors.greyBackgroundHover,
      },
      alignItems: 'center',
      background: 'transparent',
      borderRadius: '0.25rem',
      color: colors.grey,
      cursor: 'pointer',
      display: 'flex',
      fontSize: '0.875rem',
      height: '2rem',
      justifyContent: 'center',
      transition: '0.2s background ease-in-out',
      width: '2rem',
    },
  },

  [`& .${classes.statusButton}`]: {
    '& .MuiButton-label': {
      color: ({ disabled, isAuthenticationUserRoleSection }: StylesPropType) => {
        if (disabled) return theme.palette.text.disabled;
        if (isAuthenticationUserRoleSection) return theme.palette.text.primary;
        return 'rgba(0, 0, 0, 0.87)';
      },
      fontWeight: ({ isAuthenticationUserRoleSection }: StylesPropType) =>
        isAuthenticationUserRoleSection ? 400 : 500,
    },
    '& .dropdown-placeholder': {
      color: colors.dustyGray,
    },
    '& i': {
      color: colors.greyText,
      fontSize: '.85rem !important',
      marginRight: '.5rem',
    },
    '&:hover': {
      backgroundColor: ({ isAuthenticationUserRoleSection }: StylesPropType) =>
        isAuthenticationUserRoleSection ? colors.white : 'rgba(0, 0, 0, 0.04)',
      borderColor: ({ isAuthenticationUserRoleSection }: StylesPropType) =>
        isAuthenticationUserRoleSection ? theme.palette.text.primary : 'rgba(0, 0, 0, 0.23)',
    },
    background: colors.white,
    borderColor: ({ disabled }: StylesPropType) =>
      disabled ? `${colors.lightGreyBackgroundDisabled} !important` : 'rgba(0, 0, 0, 0.23)',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: ({ isAuthenticationUserRoleSection }: StylesPropType) =>
      isAuthenticationUserRoleSection ? '.625rem' : '',
    minWidth: '15.625rem',
  },
}));

export const DropdownComponent = forwardRef((props: DropdownComponentProps, ref) => {
  const {
    buttonIcon,
    children,
    className,
    dataTestId,
    disabled,
    isAuthenticationUserRoleSection,
    isNotificationSection,
    label,
    menuTriggerElement,
    placeholder = '',
    placement = 'bottom-end',
    popperTestId,
    value,
  } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle: React.MouseEventHandler<HTMLButtonElement> | undefined = (event) => {
    if (event) event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: MouseEvent | TouchEvent) => {
    event?.stopPropagation();

    if (anchorRef.current?.contains(event?.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleToggle,
  }));

  const menuListProps = {
    'data-testid': dataTestId,
  };

  const popperProps = {
    'data-testid': popperTestId,
  };

  return (
    <Root ref={anchorRef} className={className ?? classes.root}>
      {menuTriggerElement ? (
        <span className={isNotificationSection ? 'notificationSection' : 'threeDotsSection'}>{menuTriggerElement}</span>
      ) : (
        <>
          {label && (
            <Typography variant='small' className={classnames(classes.label, open && classes.activeLabel)}>
              {label}
            </Typography>
          )}
          <Button
            disabled={disabled}
            variant='outlined'
            className={classnames(
              classes.statusButton,
              isAuthenticationUserRoleSection && open && classes.activeButton
            )}
            endIcon={buttonIcon || <i className='fas fa-chevron-down' />}
            onClick={handleToggle}
            fullWidth
            {...menuListProps}>
            {value}
            {!value && placeholder && <p className='dropdown-placeholder'>{placeholder}</p>}
          </Button>
        </>
      )}
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={placement}
        {...popperProps}
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onClick={() => handleClose()} id='split-button-menu' data-testid='split-button-menu'>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Root>
  );
});
