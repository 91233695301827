import { CheckBox, CheckBoxOutlineBlank, Clear } from '@mui/icons-material';
import { Box, Checkbox, IconButton } from '@mui/material';
import classnames from 'classnames';
import { FC, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { WorkflowsCategoryHashes } from 'shared/common.definitions';
import { parseDueDate } from 'shared/logic/workflows.logic';
import { ToolOwnerFilter, User } from 'shared/models';
import { WorkflowTemplate } from 'shared/models/workflow-templates.model';

import { TypeaheadComponent } from '..';
import Input from '../input';
import { DrawerNewWorkflowStepperTaskStepComponentProps } from './drawer-new-workflow-stepper.component.props';
import { useFetchWorkflowsTemplates } from './hooks/use-fetch-workflows-templates';

type ToolOwner = { avatar?: string; id?: string; name: string; value?: string };

export const DrawerNewWorkflowStepperTaskStepComponent: FC<DrawerNewWorkflowStepperTaskStepComponentProps> = ({
  clearErrors,
  control,
  errors,
  register,
  setValue,
  trigger,
  triggerCriteria,
  values,
  watch,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [toolOwner, setToolOwner] = useState<ToolOwnerFilter>({ avatar: '', id: '', name: '' });
  const [toolOwnerName, setToolOwnerName] = useState('');
  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');

  const unCheckedIcon = <CheckBoxOutlineBlank fontSize='small' />;
  const checkedIcon = <CheckBox fontSize='small' />;

  const typeaheadRef = useRef<{ collapse: () => void }>();

  const [workflowTemplateValue, setWorkflowTemplateValue] = useState('');

  const toolOwners = useMemo(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList.map((user: User) => ({
        avatar: user?.avatar,
        name: user.name,
        value: user.id || user.name?.replaceAll(' ', '-'),
      }));
      return filteredUsers as ToolOwnerFilter[];
    }
    return [];
  }, [usersAndToolOwnersList]);

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
      setValue('assignee', item.value, { shouldDirty: true, shouldValidate: true });
      trigger(['assignee']);
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner({ avatar: '', name: '', value: '' });
      setToolOwnerName('');
      setValue('assignee', '', { shouldDirty: true, shouldValidate: true });
      trigger(['assignee']);
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleClearButtonClicked = () => {
    setToolOwner({ avatar: '', id: '', name: '' });
    setToolOwnerName('');
    setValue('assignee', '', { shouldDirty: true, shouldValidate: true });
    setValue('assignToDefaultToolOwner', true);
    trigger(['assignee', 'assignToDefaultToolOwner']);
    typeaheadRef?.current?.collapse();
  };

  const onPeriodChange = (evt: React.FormEvent<HTMLSelectElement>) => {
    // We have to manually setValue and trigger in order to sync with the validation for dueDateAmount
    const newPeriod = evt.currentTarget.value;

    setValue('dueDatePeriod', newPeriod);
    trigger(['dueDatePeriod']);

    if (!newPeriod) {
      clearErrors('dueDateAmount');
    }
  };

  const { data: templates, error: workflowTemplatesFetchError } = useFetchWorkflowsTemplates();

  const templatesFiltered = useMemo(() => {
    if (templates && triggerCriteria) {
      return templates.filter((template: WorkflowTemplate) =>
        template.triggerTypes.includes(WorkflowsCategoryHashes[triggerCriteria])
      );
    }
    return [];
  }, [templates, triggerCriteria]);

  const onTemplateSelect = (templateId: string) => {
    const selectedTemplate = templatesFiltered.find((template: WorkflowTemplate) => template.id === templateId);
    const taskIsNotEmpty = values.name.length > 0 || values.description.length > 0;
    let shouldFillFieldsFromTemplate = true;

    if (!selectedTemplate) return;

    if (taskIsNotEmpty) {
      // eslint-disable-next-line no-alert
      shouldFillFieldsFromTemplate = window.confirm(
        t('workflows_view:new_workflow_drawer.task_step.confirm_template_change')
      );
    }

    if (shouldFillFieldsFromTemplate) {
      const [days, interval] = parseDueDate(selectedTemplate.payload.dueDate);

      // shouldValidate: triggers form state validation on name and description fields upon selecting a template (Important to enable form submit)
      setValue('name', selectedTemplate.payload.title);
      setValue('description', selectedTemplate.payload.description);
      setValue('dueDateAmount', days.toString() || '');
      setValue('dueDatePeriod', interval || '');
      setValue('assignToDefaultToolOwner', selectedTemplate.payload.assignToDefaultToolOwner);
      setValue('templateTitle', selectedTemplate.payload.title);
      trigger([
        'name',
        'description',
        'dueDateAmount',
        'dueDatePeriod',
        'assignToDefaultToolOwner',
        'templateTitle',
        'assignee',
      ]);
      setWorkflowTemplateValue(selectedTemplate.id);
    }
  };

  return (
    <Box className={classnames(['new-workflow--step-task-container'])} my={2} display='flex' flexDirection='column'>
      <div className={classnames('new-workflow--current-criteria')}>
        <span className={classnames('uil-server-alt')} />
        {t('workflows_view:new_workflow_drawer.task_step.selected_criteria')}&nbsp;{' '}
        <b>{t(`workflows_view:new_workflow_drawer.criteria_step.option.${triggerCriteria}.title`)}</b>.
      </div>
      <Box my={1}>
        <div className={classnames('label')}>{t('workflows_view:new_workflow_drawer.task_step.title')}</div>
        <p>{t('workflows_view:new_workflow_drawer.task_step.description')}</p>
      </Box>
      {!workflowTemplatesFetchError && templatesFiltered.length ? (
        <Box mb={1} data-testid='workflow-templates'>
          <label className='label'>{t('workflows_view:new_workflow_drawer.task_step.template')}</label>
          <p>{t('workflows_view:new_workflow_drawer.task_step.template_description')}</p>
          <div className='select is-fullwidth is-expanded' data-testid='select-workflow-template'>
            <select onChange={(e) => onTemplateSelect(e.target.value)} value={workflowTemplateValue}>
              <option disabled> </option>
              {templatesFiltered.map((template: WorkflowTemplate) => (
                <option key={template.id} value={template.id}>
                  {template.payload.title}
                </option>
              ))}
            </select>
          </div>
        </Box>
      ) : null}

      <Box mb={1}>
        <label className='label'>{t('workflows_view:new_workflow_drawer.task_step.field_name')}</label>
        <div data-testid='task-title'>
          <Controller
            name='name'
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Input
                  type='text'
                  value={value || ''}
                  placeholder={t('workflows_view:new_workflow_drawer.task_step.field_name_placeholder')}
                  onChange={onChange}
                  error={!!errors[name]}
                />
                {errors[name] && <div className={classnames('field-error')}>{errors[name]?.message}</div>}
              </>
            )}
          />
        </div>
      </Box>
      <Box mb={1}>
        <label className='label'>
          {t('workflows_view:new_workflow_drawer.task_step.field_description')}
          <div className={classnames('help-text')}>
            {t('workflows_view:new_workflow_drawer.task_step.field_description_description')}
          </div>
        </label>
        <div data-testid='task-description'>
          <Controller
            name='description'
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Input
                  type='text'
                  value={value || ''}
                  multiline
                  rows={3}
                  maxRows={8}
                  placeholder={t('workflows_view:new_workflow_drawer.task_step.field_description_placeholder')}
                  onChange={onChange}
                  error={!!errors[name]}
                />
                {errors[name] && <div className={classnames('field-error')}>{errors[name]?.message}</div>}
              </>
            )}
          />
        </div>
      </Box>
      <Box mb={1}>
        <label className='label'>
          {t('workflows_view:new_workflow_drawer.task_step.field_owner')}
          <div className={classnames('help-text')}>
            {t('workflows_view:new_workflow_drawer.task_step.field_owner_description')}
          </div>
        </label>
        <Controller
          name='assignee'
          control={control}
          render={(params) => (
            <TypeaheadComponent
              {...params.field}
              ref={typeaheadRef}
              disabled={watch('assignToDefaultToolOwner')}
              data={toolOwners}
              onItemSelected={onToolOwnerSelected}
              onBlurToolOwnerFilter={onToolOwnerSelected}
              value={toolOwnerName}
              fields={['name', 'email']}
              isToolOwnerFilter
              onTextChanged={onChangeToolOwner}
              placeholder={t('workflows_view:new_workflow_drawer.task_step.field_owner_placeholder')}
              isSubscriptionInDiscovery
              rightIcon={
                <IconButton onClick={handleClearButtonClicked} onMouseDown={(e) => e.preventDefault()}>
                  <Clear color='secondary' />
                </IconButton>
              }
            />
          )}
        />

        <div className={classnames('inline-checkbox')}>
          <Controller
            name='assignToDefaultToolOwner'
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Checkbox
                name={name}
                color='primary'
                icon={unCheckedIcon}
                checkedIcon={checkedIcon}
                checked={!!value}
                onChange={(evt) => {
                  handleClearButtonClicked();
                  onChange(evt.target.checked);
                }}
              />
            )}
          />
          <div className={classnames('help-text')}>
            {t('workflows_view:new_workflow_drawer.task_step.assign_to_default_owner')}
          </div>
        </div>
      </Box>

      <label className='label'>
        {t('workflows_view:new_workflow_drawer.task_step.field_due_date')}
        <div className={classnames('help-text')}>
          {t('workflows_view:new_workflow_drawer.task_step.field_due_date_description')}
        </div>
      </label>
      <Box display='flex' className='field is-fullwidth'>
        <Box mr={1} width='50%'>
          <div className='select is-fullwidth is-expanded'>
            <select {...register('dueDatePeriod')} onChange={onPeriodChange}>
              <option value=''>{t('workflows_view:new_workflow_drawer.task_step.empty')}</option>
              <option value='d'>{t('workflows_view:new_workflow_drawer.task_step.days')}</option>
              <option value='w'>{t('workflows_view:new_workflow_drawer.task_step.weeks')}</option>
              <option value='m'>{t('workflows_view:new_workflow_drawer.task_step.months')}</option>
              <option value='y'>{t('workflows_view:new_workflow_drawer.task_step.years')}</option>
            </select>
          </div>
          {errors.dueDatePeriod && <div className={classnames('field-error')}>{errors.dueDatePeriod?.message}</div>}
        </Box>
        <Box width='50%'>
          <Controller
            name='dueDateAmount'
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <div data-testid='due-date-period'>
                  <Input
                    type='number'
                    inputProps={{ min: 1 }}
                    value={value || ''}
                    placeholder={t(
                      'workflows_view:task_automation.drawers.new_workflow.upload_section.task_step.field_duedateamount_placeholder'
                    )}
                    onChange={onChange}
                    error={!!errors[name]}
                    disabled={!watch('dueDatePeriod')}
                  />
                </div>
                {errors[name] && <div className={classnames('field-error')}>{errors[name]?.message}</div>}
              </>
            )}
          />
        </Box>
      </Box>
      <div className='is-italic'>{t('workflows_view:new_workflow_drawer.task_step.field_due_date_help')}</div>
    </Box>
  );
};
