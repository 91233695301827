import { AutocompleteOption } from 'asteroids';
import { ToolOwnerFilter } from 'shared/models';

export const NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW = 3;

export const paymentFrequencyItems: AutocompleteOption[] = [
  {
    id: 'monthly',
    label: 'Monthly',
  },
  {
    id: 'quarterly',
    label: 'Quarterly',
  },
  {
    id: 'yearly',
    label: 'Yearly',
  },
];

export const alternativeToolsDefaultValue = [' ', ' ', ' '];

export const defaultToolOwner: ToolOwnerFilter = { avatar: '', id: '', name: '' };
