/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccordionDetails, Box, Button, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Alert, Icon, StatusDot } from 'asteroids';
import { FilesListComponent } from 'components/drawer-workflow-request/components/files-list/files-list.component';
import { requestTypesMapping } from 'components/drawer-workflow-request-details/components/contact-sastrify-form/contact-sastrify-form.constants';
import { ProcurementInitiativesComponent } from 'components/drawer-workflow-request-details/components/procurement-initiatives/procurement-initiatives.component';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { TicketStatuses } from 'libs/models';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SUPPORT_AGENT, REQUEST_STATUS } from 'shared/common.definitions';
import { downloadDocument } from 'shared/logic/requests.logic';
import { RequestDetails } from 'shared/models';
import { Avatar } from 'views/requests/components/request-list/request-list.styles';

import { DetailsItem } from '../../details-item/details-item.component';
import { getAmountWithCurrency } from '../../details-item/details-item.utils';
import { IconUploadComponent } from '../../icon-upload/icon-upload.component';
import { InitiativeToolProps } from './initiative-tool.component.props';
import { AccordionStyled, AccordionSummaryStyled, ActionIcon } from './initiative-tool.component.styles';

const EXPAND_MORE_ICON = 'expand_more';
const REJECTED_BACKGROUND_COLOR = '#F0F0F0';
const ACTIVE_INVOLVEMENT_STATUS = 2;
const pendingInvolvementStatuses = [
  TicketStatuses.IN_PROGRESS,
  TicketStatuses.ON_HOLD,
  TicketStatuses.OPEN,
  TicketStatuses.PENDING,
];

export const InitiativeToolComponent: React.FC<InitiativeToolProps> = ({
  canDelete = true,
  canEdit = true,
  companyId,
  deleteDocument,
  deleteTool,
  disableDelete = false,
  disableDeleteTooltipText = '',
  disableEdit = false,
  disableEditTooltipText = '',
  editTool,
  expanded,
  expandedInvolvements,
  involvements,
  openCreateInvolvementDialog,
  referenceId,
  refetchData,
  refetchInvolvements,
  setExpanded,
  setExpandedInvolvements,
  showNotification,
  tool,
  uploadDocuments,
  uploadInvolvementDocuments,
}) => {
  const { t } = useTranslation();
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);

  const isPending = useMemo(() => tool.requestStateId === REQUEST_STATUS.ACTIVE, [tool.requestStateId]);
  const isRenewalInitiative = REQUEST_WORKFLOW_TYPE.RENEWAL === tool.requestWorkflowTypeId;
  const getInvolvementTypes = useCallback(() => {
    return isRenewalInitiative
      ? [
          requestTypesMapping.request_benchmark,
          requestTypesMapping.renewal_and_negotiation_support,
          requestTypesMapping.other,
        ]
      : [requestTypesMapping.request_benchmark, requestTypesMapping.negotiation_support];
  }, [isRenewalInitiative]);

  const getToolInvolvements = useMemo(() => {
    return involvements.filter((involvement) => Number(involvement.requestId) === Number(tool.requestId));
  }, [involvements, tool.requestId]);
  const allInvolvementTypesExistAndActive = useMemo(() => {
    return getInvolvementTypes().every((involvementType) => {
      return getToolInvolvements.some((involvement) => {
        return involvement.name === involvementType && pendingInvolvementStatuses.includes(involvement.status);
      });
    });
  }, [getToolInvolvements, getInvolvementTypes]);

  const activeToolInvolvements = useMemo(() => {
    return getToolInvolvements
      .filter((involvement) => involvement.status === ACTIVE_INVOLVEMENT_STATUS)
      .map((involvement) => involvement.name);
  }, [getToolInvolvements]);

  const getStatus = useCallback(() => {
    switch (tool.requestStateId) {
      case REQUEST_STATUS.REJECTED:
        return { color: 'error', name: t('requests_view:status_name.rejected') };
      case REQUEST_STATUS.APPROVED:
        return { color: 'success', name: t('requests_view:status_name.approved') };
      case REQUEST_STATUS.SUBSCRIPTION_CREATED:
        return { color: 'success', name: t('requests_view:status_name.active_tool') };
      default:
        return { color: 'info', name: tool.currentStepName };
    }
  }, [t, tool]);

  const handleOnDownload = async (fileName: string, fileUrl: string, fileId?: string) => {
    setIsDownloadingDocument(true);
    await downloadDocument(fileName, fileUrl, fileId);
    setIsDownloadingDocument(false);
  };

  const renderStatus = () => (
    <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
      <StatusDot status={getStatus().color as any} />
      <Typography data-testid='status' fontWeight={300}>
        {getStatus().name}
      </Typography>
    </Stack>
  );

  const renderAccordionSummary = (tool: RequestDetails) => (
    <Stack component='div' direction='row' spacing={2} width='100%' alignItems='center' px={1}>
      <Stack flex={1} direction='row' spacing={1} alignItems='center'>
        <Avatar alt={tool.vendorName} src={tool.vendorAvatarUrl || 'gravatar'} size='normal' variant='rounded' />
        <Stack direction='column'>
          <Typography>{tool.vendorName}</Typography>
          <Typography color='textSecondary' variant='small'>
            {tool.vendorCategory}
          </Typography>
        </Stack>
      </Stack>
      {isPending ? (
        <Stack direction='row' spacing={0.5} alignItems='center' justifyContent='flex-start'>
          <Box pr={2}>
            <Button
              size='small'
              variant='text'
              data-testid='involve-sastrify-button'
              startIcon={<Avatar alt={DEFAULT_SUPPORT_AGENT.team} src={DEFAULT_SUPPORT_AGENT.avatar} />}
              onClick={() => openCreateInvolvementDialog(tool, activeToolInvolvements)}
              disabled={allInvolvementTypesExistAndActive}>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.contact_sastrify_section.button_new_text'
              )}
            </Button>
          </Box>
          <Tooltip
            sx={{ visibility: canDelete ? 'visible' : 'hidden' }}
            title={
              disableDelete
                ? disableDeleteTooltipText
                : t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.delete'
                  )
            }>
            <Box>
              <IconButton
                id={`tool-${tool.requestId}-delete`}
                onClick={() => deleteTool(Number(tool.requestId), tool.vendorName)}
                disabled={disableDelete}>
                <Icon>delete</Icon>
              </IconButton>
            </Box>
          </Tooltip>
          {canEdit && (
            <Tooltip
              title={
                disableEdit
                  ? disableEditTooltipText
                  : t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.edit'
                    )
              }>
              <Box>
                <IconButton onClick={() => editTool(tool)} disabled={disableEdit} id={`tool-${tool.requestId}-edit`}>
                  <Icon>edit</Icon>
                </IconButton>
              </Box>
            </Tooltip>
          )}
          <IconUploadComponent
            showNotification={showNotification}
            companyId={companyId}
            referenceId={referenceId}
            uploadFunction={uploadDocuments}
            refetchData={refetchData}
            tool={tool}
            tooltip={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.upload'
            )}
            expandedTools={expanded}
            setExpanded={setExpanded}
          />
        </Stack>
      ) : (
        renderStatus()
      )}
    </Stack>
  );

  return (
    <Box>
      <AccordionStyled
        expanded={expanded.includes(String(tool.requestId))}
        disableGutters
        sx={{ backgroundColor: isPending ? 'inherit' : REJECTED_BACKGROUND_COLOR }}>
        <AccordionSummaryStyled
          expandIcon={
            <ActionIcon
              onClick={(event) => setExpanded(String(tool.requestId))(event, true)}
              variant='Outlined'
              id={`tool-${tool.requestId}-expand`}>
              {EXPAND_MORE_ICON}
            </ActionIcon>
          }
          data-testid={`tool-${tool.requestId}-summary`}>
          {renderAccordionSummary(tool)}
        </AccordionSummaryStyled>
        <AccordionDetails>
          <Grid container pt={1}>
            <Grid item xs={7} pr={2}>
              <Stack direction='column' spacing={2}>
                <DetailsItem
                  testId={`tool-${tool.requestId}-description`}
                  title={t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.fields.description'
                  )}
                  content={tool?.description || '-'}
                />
                <DetailsItem
                  testId={`tool-${tool.requestId}-cost`}
                  title={t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.fields.cost'
                  )}
                  content={
                    tool?.licenseCents && tool?.licenseCurrency
                      ? getAmountWithCurrency(tool?.licenseCents, tool?.licenseCurrency)
                      : '-'
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <DetailsItem
                testId={`tool-${tool.requestId}-documents`}
                title={t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.fields.documents'
                )}
                content={
                  <>
                    {tool.documents && tool.documents.length > 0 ? (
                      <FilesListComponent
                        files={tool.documents || []}
                        onDownload={handleOnDownload}
                        onRemove={deleteDocument}
                        textTruncate
                        disableDownloadButton={isDownloadingDocument}
                      />
                    ) : (
                      '-'
                    )}
                  </>
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </AccordionStyled>
      {getToolInvolvements.length > 0 && (
        <ProcurementInitiativesComponent
          expanded={expandedInvolvements}
          setExpanded={setExpandedInvolvements}
          vendor={{
            avatarUrl: DEFAULT_SUPPORT_AGENT.avatar,
            name: DEFAULT_SUPPORT_AGENT.team,
          }}
          initiatives={getToolInvolvements}
          currency={tool.licenseCurrency}
          showNotification={showNotification}
          companyId={String(companyId || 0)}
          documentsUploadFunction={uploadInvolvementDocuments}
          refetchInitiatives={refetchInvolvements}
          requestWorkflowTypeId={tool?.requestWorkflowTypeId || REQUEST_WORKFLOW_TYPE.APPROVAL}
          childRequests={tool?.childRequests}
        />
      )}
      {allInvolvementTypesExistAndActive && (
        <Box mt={2}>
          <Alert
            description={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.involvement_alert.message'
            )}
            isIconVisible
            severity='info'
          />
        </Box>
      )}
    </Box>
  );
};
