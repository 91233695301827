import {
  Box,
  type BoxProps as MuiBoxProps,
  styled,
  Typography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';

export const StatusContainer = styled(Box)<MuiBoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',

  '& .status-dot .MuiBadge-badge': {
    position: 'static',
    transform: 'none',
  },
}));

export const StatusTextContainer = styled(Box)<MuiBoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(1),
}));

export const StatusSubtitle = styled(Typography)<MuiTypographyProps>(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
