import { Typography } from '@mui/material';

import { UserInformationProps } from './user-information.props';

export const UserInformation = (props: UserInformationProps) =>
  !props.email && !props.companyName ? null : (
    <Typography
      data-testid='user-information'
      key='user-information'
      variant='small'
      color='text.secondary'
      sx={{
        overflow: 'hidden',
        pl: 6,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}>
      {props.email || null}
      {props.email && props.companyName && <br />}
      {props.companyName || null}
    </Typography>
  );
