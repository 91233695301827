/* eslint-disable no-console */
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { ModalAndNotificationsContext } from 'context/modal-notification.context';
import { t } from 'i18next';
import { SastrifyToolEntityType } from 'libs/enums';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { modifyToolMatchings } from 'shared/logic/tool-matching.logic';
import { ModifyToolMatchingPayload, SastrifyTool } from 'shared/models/tool-matching.model';
import { queryClient } from 'shared/reactQuery';
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from 'src/constants/common';

import { ActionType, ToolInSastrify, ToolMatchingQuery, ToolMatchingRecord, ToolMatchingStatusValues } from '../types';

export interface ModifyToolMatchings {
  records: ToolMatchingRecord[];
  actionType: ActionType;
}

export const useUpdateToolMatching = (setIsLoading: Dispatch<SetStateAction<boolean>>) => {
  const { onShowNotification } = useContext(ModalAndNotificationsContext);
  const { isLoading, mutate } = useMutation(modifyToolMatchings);

  useEffect(() => {
    setIsLoading?.(isLoading);
  }, [isLoading, setIsLoading]);

  const getSuccessMessage = ({ actionType, records }: ModifyToolMatchings) => {
    switch (actionType) {
      case ActionType.CONFIRM_BULK_UNMATCHED:
        return records.length === 1
          ? t('tool_matching_view:notifications.confirm_bulk_unmatched.success_singular', {
              sastrifyTool: records[0].toolInSastrify?.name,
              sourceTool: records[0].toolFromSource.name,
            })
          : t('tool_matching_view:notifications.confirm_bulk_unmatched.success_plural', {
              count: records.length,
            });
      case ActionType.IGNORE_BULK_UNMATCHED:
        return records.length === 1
          ? t('tool_matching_view:notifications.ignore_bulk_unmatched.success_singular', {
              sastrifyTool: records[0].toolInSastrify?.name,
              sourceTool: records[0].toolFromSource.name,
            })
          : t('tool_matching_view:notifications.ignore_bulk_unmatched.success_plural', {
              count: records.length,
            });
      case ActionType.IGNORE_SINGLE_MATCHED:
        return t('tool_matching_view:notifications.ignore_single_matched.success', {
          sourceTool: records[0].toolFromSource.name,
        });
      case ActionType.IGNORE_BULK_MATCHED:
        return t('tool_matching_view:notifications.ignore_bulk_matched.success', {
          count: records.length,
        });
      case ActionType.RESTORE_SINGLE:
        return t('tool_matching_view:notifications.restore_single.success', {
          sourceTool: records[0].toolFromSource.name,
        });
      case ActionType.RESTORE_BULK:
        return t('tool_matching_view:notifications.restore_bulk.success', {
          count: records.length,
        });
      case ActionType.REASSIGN:
        return t('tool_matching_view:notifications.tool_reassignment.success', {
          newTool: records[0].toolInSastrify?.name ?? '',
          oldTool: records[0].toolInSastrifyOriginalValue?.name ?? '',
        });
      default:
        return '';
    }
  };

  const getStatus = (actionType: ActionType): ToolMatchingStatusValues | null => {
    switch (actionType) {
      case ActionType.RESTORE_BULK:
      case ActionType.RESTORE_SINGLE:
        return ToolMatchingStatusValues.Unmatched;
      case ActionType.CONFIRM_BULK_UNMATCHED:
      case ActionType.REASSIGN:
        return ToolMatchingStatusValues.Matched;
      case ActionType.IGNORE_BULK_UNMATCHED:
      case ActionType.IGNORE_BULK_MATCHED:
      case ActionType.IGNORE_SINGLE_MATCHED:
        return ToolMatchingStatusValues.Ignored;
      default:
        return null;
    }
  };

  const getToolInSastrify = (toolInSastrify?: ToolInSastrify | null): SastrifyTool | null => {
    return toolInSastrify
      ? {
          ...(toolInSastrify.id && { id: toolInSastrify.id }),
          entityType: toolInSastrify.entityType ?? SastrifyToolEntityType.SUBSCRIPTION,
          name: toolInSastrify.name,
        }
      : null;
  };

  const getPayload = ({ actionType, records }: ModifyToolMatchings): ModifyToolMatchingPayload[] =>
    records.map(({ id, toolInSastrify: tool }) => {
      const newStatus = getStatus(actionType);

      return {
        id,
        ...(newStatus && { status: newStatus }),
        toolInSastrify: getToolInSastrify(tool),
      };
    });

  const updateToolMatching = async (data: ModifyToolMatchings) => {
    const payload = getPayload(data);

    mutate(payload, {
      onError: (error) => {
        onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSuccess: async () => {
        onShowNotification(getSuccessMessage(data), NotificationAlertType.Success);
        queryClient.invalidateQueries(ToolMatchingQuery.TOOL_MATCHING_RECORDS);
        setIsLoading?.(false);
      },
    });
  };

  return { updateToolMatching };
};
