import { Grid, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/theme';

import { StatusComponent } from '..';
import { ArrowRightIcon } from '../icons';
import { SubscriptionStatusItemComponentProps } from './subscription-status-item.component.props';

const PREFIX = 'SubscriptionStatusItemComponent';

const classes = {
  menuItem: `${PREFIX}-menuItem`,
  status: `${PREFIX}-status`,
};

const StyledMenuItem = styled(MenuItem)({
  [`&.${classes.menuItem}`]: {
    '& .subscription-status': {
      marginLeft: '0.5rem',
    },
    '& p': {
      color: colors.greyText,
      fontSize: '0.75rem',
      fontWeight: '400',
    },
  },
  [`& .${classes.status}`]: {
    '& > section': {
      float: 'right',
    },
  },
});

export const SubscriptionStatusItemComponent: FC<SubscriptionStatusItemComponentProps> = (props) => {
  const { isSubscriptionDetail, onClick, value } = props;

  const { t } = useTranslation();

  return (
    <StyledMenuItem onClick={onClick} className={classes.menuItem}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item lg={6}>
          <p>{t('common:subscription_status_feature.change_status_text')}</p>
        </Grid>
        <Grid item lg={6} className={classes.status}>
          <section className='is-flex is-align-items-center is-justify-content-space-between'>
            <ArrowRightIcon />
            <StatusComponent status={Number(value)} isDropdown isSubscriptionDetail={isSubscriptionDetail} />
          </section>
        </Grid>
      </Grid>
    </StyledMenuItem>
  );
};
