import { NavigationItemType } from 'asteroids';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { ModalAndNotificationsContext } from 'context/modal-notification.context';
import { DEFAULT_STATE_OPTIONS } from 'libs/enums';
import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { INACTIVE_STATE } from 'shared/common.definitions';
import { getCompanyPotentialSavings } from 'shared/logic/company.logic';
import { fetchSubscriptions, getSubscriptionsTodos } from 'shared/logic/subscriptions.logic';
import { Company, PotentialSavings, SastrifyStore, Subscription, TodoAndStatistics } from 'shared/models';
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from 'src/constants/common';
import { useDuplicatesSpendCount } from 'views/spend-import/hooks/use-duplicates-spend-count';
import { useFailedInvoiceTotals } from 'views/spend-import/hooks/use-failed-invoice-totals';
import { useToolMatchingData } from 'views/tool-matching/hooks';

export const useGetDynamicNavigationBadges = () => {
  const queryClient = useQueryClient();
  const company = queryClient.getQueryData('company') as Company;
  const user = useSelector((state: SastrifyStore) => state.authentication.user);
  const { onShowNotification } = useContext(ModalAndNotificationsContext);

  const { pendingCount } = useFailedInvoiceTotals();
  const { duplicatesCount } = useDuplicatesSpendCount();
  const { data: toolMatchingRecords } = useToolMatchingData();

  const onError = useCallback(
    (error) => {
      onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    [onShowNotification]
  );

  const { data: subscriptionsTodos } = useQuery<TodoAndStatistics[]>('subscriptions-todos', getSubscriptionsTodos, {
    enabled: Boolean(company?.id),
    keepPreviousData: true,
    onError,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const transformFetchSubscription = (data?: Subscription[]) => {
    return data?.map((subscription) => {
      const todos = subscriptionsTodos?.find((item) => item.subscriptionId === subscription.id);
      if (todos) {
        return { ...subscription, todoStatistics: todos?.todoStatistics, todos: todos?.todos };
      }
      return subscription;
    });
  };

  const { data: subscriptions } = useQuery<Subscription[] | undefined>('subscriptions', fetchSubscriptions, {
    enabled: Boolean(company?.id),
    keepPreviousData: true,
    onError,
    refetchOnWindowFocus: false,
    retry: 2,
    select: transformFetchSubscription,
  });

  const { data: potentialSavingsData } = useQuery<PotentialSavings[]>(
    'potentialSavings',
    () => getCompanyPotentialSavings(String(company?.id)),
    {
      enabled: Boolean(company?.id),
      keepPreviousData: true,
      onError,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const spendImportCount = pendingCount + duplicatesCount;
  const numberOfTodos = useMemo(
    () =>
      !subscriptions?.length
        ? 0
        : subscriptions
            .filter(
              (subscription) =>
                DEFAULT_STATE_OPTIONS[Number(subscription.state)] !== INACTIVE_STATE &&
                subscription.todos?.length &&
                subscription.ownerId === user?.id
            )
            .flatMap((userSubscriptions) => userSubscriptions.todos)
            .filter((todo) => todo?.state === 0).length,
    [subscriptions, user?.id]
  );

  const potentialSavingsCount = useMemo(
    () => potentialSavingsData?.filter((item: PotentialSavings) => item.state === 0).length,
    [potentialSavingsData]
  );

  const badgeGetter = useCallback<(item: NavigationItemType | undefined) => ReactNode>(
    (item: NavigationItemType | undefined): ReactNode => {
      if (!item) return null;

      if (item.key === 'tools') return numberOfTodos || null;
      if (item.key === 'spend_import') return spendImportCount || null;
      if (item.key === 'savings') return potentialSavingsCount || null;
      if (item.key === 'tool_matching') return toolMatchingRecords?.unmatched?.length ?? null;

      return null;
    },
    [numberOfTodos, potentialSavingsCount, spendImportCount, toolMatchingRecords]
  );

  return { badgeGetter };
};
