import { createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { mrtTableSliceConfigFactory } from '../../component-reducers/material-react-table.slice';
import { toolFilterSliceConfigFactory } from '../../component-reducers/tool-filter.slice';

const mrtTableSlice = createSlice(mrtTableSliceConfigFactory('inUsePanel'));
const toolFilterSlice = createSlice(toolFilterSliceConfigFactory('inUsePanel'));

export const inUsePanelReducers = combineReducers({
  filters: toolFilterSlice.reducer,
  table: mrtTableSlice.reducer,
});

export const { actions: tableActions } = mrtTableSlice;
export const { actions: toolFilterActions } = toolFilterSlice;
