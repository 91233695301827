import { ClickAwayListener } from '@mui/base';
import { Button, FormHelperText, IconButton } from '@mui/material';
import { ConnectionStatus, Icon, InputField } from 'asteroids';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCodatIcon } from 'shared/helpers/connect.helper';
import { CodatConnectionStatus } from 'shared/models/integration-item.model';

import { AccountingConnectionProps } from './accounting-connection.component.props';
import {
  AccountingConnectionContainer,
  ConnectBtn,
  ConnectionContainer,
  ConnectionIconBox,
  ConnectionValidationContainer,
  EditableControls,
  EditableTextBox,
  IconBox,
  StaticControls,
  StatusBox,
  TextBox,
} from './accounting-connection.styles';

export const AccountingConnection: React.FC<AccountingConnectionProps> = ({
  connectionId,
  connectionTitle,
  connectionsList,
  editConnectionName,
  handleCancel,
  handleConnect,
  handleDelete,
  handleDisconnect,
  handleSave,
  isConnected,
  onEditMode,
  type,
}) => {
  const { t } = useTranslation();

  const [isEditable, setIsEditable] = useState(false);
  const [title, setTitle] = useState(connectionTitle);
  const [errorText, setErrorText] = useState('');
  const titleRef = useRef<HTMLInputElement>(null);
  const connectedText = t('connect_view:tab_section.codat.accountingConnection.connectedText');
  const emptyTextValidation = t('connect_view:tab_section.codat.accountingConnection.emptyTextValidation');
  const notUniqueTextValidation = t('connect_view:tab_section.codat.accountingConnection.notUniqueTextValidation');

  const onClose = () => {
    setIsEditable(false);
    setErrorText('');
    handleCancel?.();
  };

  const onEdit = () => {
    setIsEditable(true);
    setErrorText('');
  };

  useEffect(() => {
    if (editConnectionName) onEdit();
  }, [editConnectionName]);

  const getIsUniqueName = (connectionId: string | number, name: string): boolean => {
    let isUniqueName = true;
    if (!connectionsList) return isUniqueName;

    connectionsList.forEach((connection) => {
      if (connection.codatConnectionId === connectionId) return;

      if (connection.sastrifyConnectionName === name) {
        isUniqueName = false;
      }
    });

    return isUniqueName;
  };

  const onSave = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const connectionName = titleRef?.current?.value;

    if (!connectionName) {
      setErrorText(emptyTextValidation);
      return;
    }

    const isUniqueName = getIsUniqueName(connectionId, connectionName);

    if (!isUniqueName) {
      setErrorText(notUniqueTextValidation);
      return;
    }

    setTitle(connectionName);
    setIsEditable(false);
    handleSave(connectionId, connectionName);
  };

  useEffect(() => {
    onEditMode(isEditable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  const onDelete = () => {
    handleDelete(connectionId);
  };

  const onConnect = () => {
    handleConnect(connectionId);
  };

  const onDisconnect = () => {
    handleDisconnect(connectionId);
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <AccountingConnectionContainer className={errorText.length > 0 ? 'error-container' : ''}>
        <ConnectionContainer data-testid='new-connection'>
          <ConnectionIconBox>
            <img aria-label='connection-icon' src={getCodatIcon(type)} />
          </ConnectionIconBox>
          {!isEditable ? (
            <StaticControls>
              <TextBox data-testid='connection-name'>{title}</TextBox>
              <IconBox>
                <IconButton onClick={onEdit}>
                  <Icon color='action' fontSize='small' data-testid='connection-edit'>
                    edit
                  </Icon>
                </IconButton>
              </IconBox>
              {!isConnected && (
                <IconBox>
                  <IconButton onClick={onDelete}>
                    <Icon color='action' fontSize='small' data-testid='connection-delete'>
                      delete
                    </Icon>
                  </IconButton>
                </IconBox>
              )}
            </StaticControls>
          ) : (
            <EditableControls
              component='form'
              onSubmit={(e) => {
                e.preventDefault();
                onSave();
              }}>
              <EditableTextBox>
                <InputField
                  data-testid='connection-name-input'
                  size='small'
                  defaultValue={title}
                  inputRef={titleRef}
                  error={errorText.length > 0}
                  aria-describedby='title-helper-text'
                  autoFocus
                />
              </EditableTextBox>
              <IconBox>
                <IconButton onClick={onClose}>
                  <Icon color='action' fontSize='small' data-testid='connection-name-cancel'>
                    close
                  </Icon>
                </IconButton>
              </IconBox>
              <IconBox>
                <IconButton onClick={onSave}>
                  <Icon color='action' fontSize='small' data-testid='connection-name-save'>
                    check
                  </Icon>
                </IconButton>
              </IconBox>
            </EditableControls>
          )}
          <StatusBox>
            {isConnected && <ConnectionStatus status={CodatConnectionStatus.Connected as any} title={connectedText} />}
          </StatusBox>
          <ConnectBtn data-testid='connect-btn'>
            {!isConnected ? (
              <Button variant='text' size='normal' disabled={isEditable} onClick={onConnect}>
                {t('connect_view:tab_section.codat.accountingConnection.connectText')}
              </Button>
            ) : (
              <Button variant='text' size='normal' disabled={isEditable} onClick={onDisconnect}>
                {t('connect_view:tab_section.codat.accountingConnection.disconnectText')}
              </Button>
            )}
          </ConnectBtn>
        </ConnectionContainer>
        <ConnectionValidationContainer>
          <FormHelperText id='title-helper-text'>{errorText}</FormHelperText>
        </ConnectionValidationContainer>
      </AccountingConnectionContainer>
    </ClickAwayListener>
  );
};

export default AccountingConnection;
