const DEFAULT_FILES_TO_IGNORE = ['.DS_Store', 'Thumbs.db'];

export const EXTENSION_TO_MIME_TYPE_MAP = {
  avi: 'video/avi',
  gif: 'image/gif',
  ico: 'image/x-icon',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  mkv: 'video/x-matroska',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  pdf: 'application/pdf',
  png: 'image/png',
  zip: 'application/zip',
};

function shouldIgnoreFile(file) {
  return DEFAULT_FILES_TO_IGNORE.indexOf(file.name) >= 0;
}

function copyString(aString) {
  return ` ${aString}`.slice(1);
}

function traverseDirectory(entry) {
  const reader = entry.createReader();

  return new Promise((resolveDirectory) => {
    const iterationAttempts = [];
    // eslint-disable-next-line
    const errorHandler = () => {};

    function readEntries() {
      reader.readEntries((batchEntries) => {
        if (!batchEntries.length) {
          resolveDirectory(Promise.all(iterationAttempts));
        } else {
          iterationAttempts.push(
            Promise.all(
              batchEntries.map((batchEntry) => {
                if (batchEntry.isDirectory) {
                  return traverseDirectory(batchEntry);
                }
                return Promise.resolve(batchEntry);
              })
            )
          );
          readEntries();
        }
      }, errorHandler);
    }
    readEntries();
  });
}

function packageFile(file, entry) {
  let fileTypeOverride = '';
  const hasExtension = file.name && file.name.lastIndexOf('.') !== -1;

  if (hasExtension && !file.type) {
    const fileExtension = (file.name || '').split('.').pop();

    fileTypeOverride = EXTENSION_TO_MIME_TYPE_MAP[fileExtension];
  }
  return {
    fileObject: file,
    fullPath: entry ? copyString(entry.fullPath) : file.name,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
    name: file.name,
    size: file.size,
    type: file.type ? file.type : fileTypeOverride,
    webkitRelativePath: file.webkitRelativePath,
  };
}

function getFile(entry) {
  return new Promise((resolve) => {
    entry.file((file) => {
      resolve(packageFile(file, entry));
    });
  });
}

function handleFilePromises(promises, fileList) {
  return Promise.all(promises).then((files) => {
    files.forEach((file) => {
      if (!shouldIgnoreFile(file)) {
        fileList.push(file);
      }
    });

    return fileList;
  });
}
// eslint-disable-next-line
export function getDataTransferFiles(dataTransfer) {
  const dataTransferFiles = [];
  const folderPromises = [];
  const filePromises = [];

  [].slice.call(dataTransfer.items).forEach((listItem) => {
    if (typeof listItem.webkitGetAsEntry === 'function') {
      const entry = listItem.webkitGetAsEntry();

      if (entry) {
        if (entry.isDirectory) {
          folderPromises.push(traverseDirectory(entry));
        } else {
          filePromises.push(getFile(entry));
        }
      }
    } else {
      dataTransferFiles.push(listItem);
    }
  });

  if (folderPromises.length) {
    const flatten = (array) =>
      array.reduce((previous, current) => previous.concat(Array.isArray(current) ? flatten(current) : current), []);

    return Promise.all(folderPromises).then((fileEntries) => {
      const flattenedEntries = flatten(fileEntries);

      flattenedEntries.forEach((fileEntry) => {
        filePromises.push(getFile(fileEntry));
      });

      return handleFilePromises(filePromises, dataTransferFiles);
    });
  }
  if (filePromises.length) {
    return handleFilePromises(filePromises, dataTransferFiles);
  }
  return Promise.resolve(dataTransferFiles);
}

// eslint-disable-next-line
export function getDroppedOrSelectedFiles(event) {
  const { dataTransfer } = event;

  if (dataTransfer?.items) {
    return getDataTransferFiles(dataTransfer).then((fileList) => {
      return Promise.resolve(fileList);
    });
  }

  const files = [];
  const dragDropFileList = dataTransfer?.files;
  const inputFieldFileList = event.target?.files;
  const fileList = dragDropFileList || inputFieldFileList || [];

  for (let index = 0; index < fileList.length; index++) {
    files.push(packageFile(fileList[index]));
  }
  return Promise.resolve(files);
}
