import { Step, Stepper as MUIStepper } from '@mui/material';

import { StepLabel } from './step-label/step-label.component';
import { StepperOrientation, StepperProps } from './stepper.props';

export const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
  const { activeStep, alternativeLabel, orientation, steps } = props;

  return (
    <MUIStepper
      alternativeLabel={alternativeLabel}
      activeStep={activeStep}
      orientation={orientation || StepperOrientation.horizontal}>
      {steps.map((step, index) => {
        const activeError = index <= activeStep && step.error;
        const inactive = !step.error && index !== activeStep && !step.completed;
        return (
          <Step key={step.label} completed={!!step.completed}>
            <StepLabel label={step.label} optionalLabel={step.optionalLabel} error={activeError} inactive={inactive} />
          </Step>
        );
      })}
    </MUIStepper>
  );
};

export default Stepper;
