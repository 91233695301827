import React, { FC } from 'react';
import { colors } from 'shared/theme';

export const ApproveIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => {
  return (
    <svg width={width} height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.71 1.21a.999.999 0 00-1.42 0L5.84 8.67 2.71 5.53A1.022 1.022 0 101.29 7l3.84 3.84a1 1 0 001.42 0l8.16-8.16a.999.999 0 000-1.47z'
        fill={fill}
      />
    </svg>
  );
};

ApproveIcon.defaultProps = {
  fill: colors.downy,
  height: 12,
  width: 16,
};
