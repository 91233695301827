import { useQuery } from 'react-query';
import { fetchWorkflowTemplates } from 'shared/logic/workflows.logic';

export function useFetchWorkflowsTemplates() {
  return useQuery(
    'workflow-templates',
    () => {
      return fetchWorkflowTemplates();
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}
