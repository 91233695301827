import { styled } from '@mui/material/styles';
import { colors } from 'asteroids';

import { Accordion, AccordionSummary } from '../../accordion/accordion.styles';

export const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  borderLeft: `5px solid ${colors.secondary.main} !important`,
}));

export const AccordionStyled = styled(Accordion)(() => ({
  margin: '0 0 0 16px !important',
}));
