/* eslint-disable jsx-a11y/aria-role */
import { Box, CircularProgress, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import classnames from 'classnames';
import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { FileTypesAllowList } from 'shared/common.definitions';

import { DropzoneComponentProps } from './drop-zone.component.props';
import { DropzoneWrapper } from './drop-zone.styles';

export const DropzoneComponent: FC<DropzoneComponentProps> = ({
  accept,
  hasCustomMessage,
  isPaperClipIcon,
  isUploading,
  message,
  multiple,
  name,
  onDrop,
  onDropAccepted,
  onDropRejected,
  version = 'v1',
  ...rest
}) => {
  const { getInputProps, getRootProps, isDragAccept, isFocused } = useDropzone({
    accept: accept || FileTypesAllowList,
    multiple,
    ...rest,
    onDrop,
    onDropAccepted,
    onDropRejected,
  });

  const { t } = useTranslation();

  const getContent = () => {
    if (version === 'v2') {
      return (
        <>
          {isUploading && <CircularProgress sx={{ color: '#E24F09', position: 'absolute', right: '32px' }} size={32} />}
          <input role='input' {...getInputProps({ name })} data-testid='drop-zone' />
          <Box display='flex'>
            <span className='file-label'>
              {hasCustomMessage ? (
                message
              ) : (
                <Box textAlign='center'>
                  <Typography color='text.primary' variant='body'>
                    <Trans i18nKey='common:dropzone_component.placeholder_text_v2' components={[<br />]} />
                  </Typography>
                </Box>
              )}
            </span>
          </Box>
        </>
      );
    }

    return (
      <>
        {isUploading ? (
          <CircularProgress size={30} />
        ) : (
          <>
            <input role='input' {...getInputProps({ name })} data-testid='drop-zone' />
            <span className='is-flex'>
              <span className='file-icon'>
                {isPaperClipIcon ? (
                  <Icon color='action' size='small'>
                    attach_file
                  </Icon>
                ) : (
                  <Icon color='action' size='small'>
                    upload
                  </Icon>
                )}
              </span>
              <span className='file-label'>
                {hasCustomMessage ? message : t('common:dropzone_component.placeholder_text')}
              </span>
            </span>
          </>
        )}
      </>
    );
  };

  return (
    <DropzoneWrapper>
      <div
        {...getRootProps({
          className: classnames(version === 'v1' ? 'base-style' : 'base-style-v2', {
            'accept-style': isDragAccept,
            'focus-style': isFocused,
          }),
        })}>
        {getContent()}
      </div>
    </DropzoneWrapper>
  );
};
