import * as Sentry from '@sentry/browser';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { setCompanyFeatureFlags as setReduxCompanyFeatureFlags } from 'shared/store/common/featureFlags.slice';
import { useAppDispatch } from 'shared/store/hooks';
import { CompanyFeatureFlagContext } from 'src/company-feature-flag.provider';
import { UnleashClient } from 'unleash-proxy-client';

export function useFeatureFlagByCompanyId(featureName: string, companyId: string | undefined): { isLoading: boolean } {
  const [isLoading, setIsLoading] = useState(true);
  const { setCompanyFeatureFlags } = useContext(CompanyFeatureFlagContext);
  const retryCounter = useRef(0);
  const dispatch = useAppDispatch();

  const fetchFeatureFlags = useCallback(
    (unleash: UnleashClient) => {
      if (retryCounter.current >= 2) {
        setIsLoading(false);
        return;
      }

      retryCounter.current += 1;
      setIsLoading(true);

      const featureFlags = unleash
        .getAllToggles()
        .reduce((featureFlags, featureFlag) => ({ ...featureFlags, [featureFlag.name]: featureFlag.enabled }), {});

      setCompanyFeatureFlags(featureFlags);
      dispatch(setReduxCompanyFeatureFlags(featureFlags));

      Sentry.setContext('Feature flags', featureFlags);
      setIsLoading(false);
    },
    [dispatch, setCompanyFeatureFlags]
  );

  useEffect(() => {
    if (!companyId) return;

    const unleash = new UnleashClient({
      appName: process.env.REACT_APP_UNLEASH_APP_NAME as string,
      clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY as string,
      context: { userId: String(companyId) },
      url: process.env.REACT_APP_UNLEASH_PROXY_URL as string,
    });

    unleash.start();
    unleash.on('ready', () => fetchFeatureFlags(unleash));

    unleash.on('error', () => fetchFeatureFlags(unleash));
  }, [companyId, fetchFeatureFlags]);

  return { isLoading };
}
