import { AxiosResponse } from 'axios';
import { UpsellingRequest } from 'libs/models';
import { useMutation } from 'react-query';
import { apiService, apiUrl } from 'shared/services';

export const useUpsellingPrompt = (companyId: string) => {
  return useMutation('upselling-prompt', (payload: UpsellingRequest) =>
    apiService.post(apiUrl.requestAddon(companyId), payload).then((response: AxiosResponse) => response.data)
  );
};
