import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DrawerInfoType } from '../../common.definitions';

export interface AppDrawer {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any | null | undefined;
  type: DrawerInfoType | null;
  showDrawer: boolean;
}

export const initialState: AppDrawer = {
  data: null,
  showDrawer: false,
  type: null,
};

const appDrawerSlice = createSlice({
  initialState,
  name: 'appDrawer',
  reducers: {
    clear(state) {
      state.data = null;
      state.type = null;
    },
    hide(state) {
      state.showDrawer = false;
    },
    show(
      state,
      action: PayloadAction<DrawerInfoType | null | { type: DrawerInfoType; data?: object | null | undefined }>
    ) {
      const payload = action.payload || null;

      if (!payload) {
        return;
      }

      if (typeof payload === 'string') {
        state.type = payload;
        state.data = null;
      } else {
        const { data, type } = payload;

        state.type = type;
        state.data = data;
      }

      state.showDrawer = true;
    },
  },
});

export const { clear, hide, show } = appDrawerSlice.actions;
export default appDrawerSlice.reducer;
