import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { VirtuosoHandle } from 'react-virtuoso';
import { AuditLogFieldNames } from 'shared/common.definitions';
import { SubscriptionHistory } from 'shared/models/subscription-history.model';

export function useScrollToComment(filteredHistory: SubscriptionHistory[], virtuoso: React.RefObject<VirtuosoHandle>) {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const commentId = search.get('commentId');
  const replyId = search.get('replyId');
  let commentIndex = -1;

  const hasScrolledIntoContent = useRef<boolean>(false);

  if (commentId) {
    const commentItem = filteredHistory.find((item) => item.comment && item.id === commentId);
    commentIndex = commentItem ? filteredHistory.indexOf(commentItem) : -1;
  }

  useEffect(() => {
    if (hasScrolledIntoContent.current) return;

    if (virtuoso.current && commentIndex > -1) {
      // Scroll to comment
      window.setTimeout(() => {
        if (!virtuoso.current) return;

        // We need the setTimeout to make sure the virtuoso is fully rendered. Otherwise on certain comment counts, the scrollToIndex will not work.
        // FIXME(layout): The offset is required to bypass the current application header layout.
        virtuoso.current.scrollToIndex({
          align: 'start',
          behavior: 'smooth',
          index: commentIndex,
          offset: -100,
        });
      }, 0);

      // Make comment blink
      if (!replyId) {
        setTimeout(() => {
          const itemContainer: HTMLElement | null = document.querySelector(
            `#history-${AuditLogFieldNames.COMMENT}-${commentId}`
          );

          if (itemContainer) {
            const target: HTMLElement | null = itemContainer.querySelector('.subscription-comment__content');
            if (target) {
              target.classList.add('blink');
              setTimeout(() => target.classList.remove('blink'), 1000);
            }
          }
        }, 250);
      }
    }
  }, [commentIndex, commentId, replyId, virtuoso]);

  return { commentId, commentIndex, replyId };
}
