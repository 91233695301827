import { User } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { useCheckUserPermission, useUser } from 'hooks/index';
import { SOURCE_NAME, TYPE } from 'libs/constants/tool-usage.constants';
import { PERMISSION } from 'libs/enums';
import { FC, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  BaseUsageAnalyticsTrackingParams,
  trackConnected,
  trackDisconnected,
} from 'shared/logic/event-tracking/usage-analytics.events';
import { RootState } from 'shared/store';
import { hide as hideDrawer } from 'shared/store/common/appDrawer.slice';
import {
  disconnectFromMicrosoft,
  onConnect,
  setConnectingState,
  toggleUsageAnalytics,
} from 'shared/store/common/microsoftConnect.slice';
import { useAppSelector } from 'shared/store/hooks';

import { ManageSSOCard, ManageSSOCardFooter } from '../components';

export const ManageMicrosoftSSOConnection: FC = () => {
  const hasManageIntegrationsPermission = useCheckUserPermission(PERMISSION.MANAGE_INTEGRATIONS);
  const user = useUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleCloseDrawer = useCallback(() => dispatch(hideDrawer()), [dispatch]);
  const { refetch: refetchConnectionState } = useQuery('ms-connection-state');
  const { isConnected, ssoProviderName } = useAppSelector(
    (state: RootState) =>
      state.common.appDrawer.data as {
        isConnected: boolean;
        ssoProviderName: string;
      }
  );

  const baseTrackingEventProps: BaseUsageAnalyticsTrackingParams = {
    analyticsProviderName: ssoProviderName,
    analyticsProviderType: TYPE.SSO,
    analyticsSourceName: SOURCE_NAME.MICROSOFT_SSO,
    companyId: user.companyId ?? '',
    userId: user.id ?? '',
  };

  const handleConnect = async (enableUsageAnalytics: boolean): Promise<void> => {
    if (!hasManageIntegrationsPermission || !user?.companyId) {
      return;
    }

    dispatch(setConnectingState());
    const authorizeUrl = await onConnect(user.companyId, enableUsageAnalytics);
    window.location.href = authorizeUrl;
    trackConnected(baseTrackingEventProps, location.pathname, enableUsageAnalytics);
    handleCloseDrawer();
  };

  const handleDisconnect = (isEnabledUsageAnalytics: boolean) => {
    if (!hasManageIntegrationsPermission) {
      return;
    }

    dispatch(disconnectFromMicrosoft({ refetch: refetchConnectionState, userData: user as User }));
    trackDisconnected(baseTrackingEventProps, location.pathname, isEnabledUsageAnalytics);
  };

  const handleToggleUsageAnalytics = (isEnabled: boolean) => {
    if (!user?.companyId) {
      return;
    }

    dispatch(
      toggleUsageAnalytics({
        companyId: user.companyId,
        enableUsageAnalytics: isEnabled,
        refetch: refetchConnectionState,
      })
    );

    if (isConnected) {
      trackConnected(baseTrackingEventProps, location.pathname, isEnabled);
    }
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', mt: 8, mx: 5 }}>
      <ManageSSOCard onToggleUsageAnalytics={handleToggleUsageAnalytics} onConnect={handleConnect} />
      <ManageSSOCardFooter onDisconnect={handleDisconnect} />
    </Box>
  );
};
