import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { FC, ReactElement } from 'react';

import { useAppSelector } from '../../store/hooks';
import { PRICING_PLANS_FEATURE_STATUS } from '.';

type FeatureStatusComponentProps = {
  children: React.ReactNode;
};

export const EnabledFeature: FC<FeatureStatusComponentProps> = ({ children }) => <>{children}</>;

export const DisabledFeature: FC<FeatureStatusComponentProps> = ({ children }) => <>{children}</>;

export const PurchasableFeature: FC<FeatureStatusComponentProps> = ({ children }) => <>{children}</>;

type FeatureSwitchProps = {
  featureName: PRICING_PLANS_FEATURES;
  children: ReactElement<FeatureStatusComponentProps> | ReactElement<FeatureStatusComponentProps>[];
};

const getChildrenForAType = (
  children: ReactElement<FeatureStatusComponentProps> | ReactElement<FeatureStatusComponentProps>[],
  type: FC<FeatureStatusComponentProps>
) => {
  if (Array.isArray(children)) {
    return children.find((child) => child.type === type) || null;
  }

  return children.type === type ? children : null;
};

export const FeatureSwitch: FC<FeatureSwitchProps> = ({ children, featureName }) => {
  const featuresStatuses = useAppSelector((state) => state.pricingPlansFeatures);
  const featureStatus = featuresStatuses.find((featureStatus) => featureStatus.name === featureName)?.status;

  switch (featureStatus) {
    case PRICING_PLANS_FEATURE_STATUS.ENABLED:
      return getChildrenForAType(children, EnabledFeature);
    case PRICING_PLANS_FEATURE_STATUS.DISABLED:
      return getChildrenForAType(children, DisabledFeature);
    case PRICING_PLANS_FEATURE_STATUS.PURCHASABLE:
      return getChildrenForAType(children, PurchasableFeature);
    default:
      return null;
  }
};
