import { CSSObject } from '@emotion/styled';
import { CardProps, styled } from '@mui/material';
import Card from '@mui/material/Card';

import { shadows } from '../../theme/shadow.styles';
import { withTransientProps } from '../../utils/with-transient-props';
import { CardWithTitleProps } from './card-with-title.props';

type TransientProps = {
  $cardActionsAlignment?: CardWithTitleProps['cardActionsAlignment'];
};

export const StyledCardContainer = styled(
  Card,
  withTransientProps
)<CardProps & TransientProps>(({ $cardActionsAlignment, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.light,
  boxShadow: shadows.card,
  '&.MuiCardWithTitle-with-action': {
    boxShadow: shadows.card,
  },
  '&.MuiCardWithTitle-with-action:hover': {
    boxShadow: shadows.cardHover,
  },
  '& .MuiCardActions-root': {
    justifyContent:
      $cardActionsAlignment === 'center' ? 'center' : ($cardActionsAlignment === 'left' && 'flex-start') || 'flex-end',
    padding: theme.spacing(1, 2, 1.5, 2),
  },
  '& .MuiCardWithTitle-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'content-box',
    minHeight: (theme?.components?.MuiTab?.styleOverrides?.root as CSSObject)?.minHeight,
    padding: theme.spacing(0, 2),
    borderBottom: '1px solid transparent',
  },
  '& .MuiCardWithTitle-header__custom': {
    borderBottom: '1px solid transparent',
  },
  '& .MuiCardWithTitle-header--with-divider': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiCardContent-root': {
    overflow: 'auto',
    height: '100%',
    maxHeight: 'inherit',
    padding: theme.spacing(2),
  },
  '& .MuiCardWithTitle-title-actions': {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
}));
