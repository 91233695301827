import { Box, CircularProgress, Link, Typography, useTheme } from '@mui/material';
import { IconDropdown } from 'asteroids';
import { addSeconds } from 'date-fns';
import { SPEND_CATEGORIES } from 'libs/enums';
import { CodatIntegrationDetailsRes, CodatPlatformType, PaginatedResponse, Subscription } from 'libs/models';
import { MRT_Row } from 'material-react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { formatFullDate, shortenFileName, shortenText } from 'shared/helpers/common.helper';
import { getPlatformName } from 'shared/helpers/connect.helper';
import { getCompanyCodatIntegrationDetails } from 'shared/logic/company.logic';
import { fetchBatchFailedInvoiceData } from 'shared/logic/subscription-invoices.logic';
import { capitalize, getSubscriptionSpendCategoryMappings } from 'shared/logic/subscription-item.logic';
import { Company } from 'shared/models';
import { FailedInvoiceData, SubscriptionSpendAndInvoices } from 'shared/models/subscription-spend-and-invoices.model';
import {
  setUploadedFiles,
  SubscriptionInvoiceUpload,
  SubscriptionInvoiceUploadStatus,
} from 'shared/store/views/subscription/subscription-invoice-upload.slice';
import { useFetchData, useUser } from 'src/app/hooks';
import { formatDateWithoutTimeZone } from 'views/overview/calendar/subscription-indicator/helpers';

import { getItemAmount, getItemConversion } from '../spend-table/helpers';
import { ReviewSpendFeature } from '../spend-table/review-spend/review-spend.feature';
import { useSpendActions } from './use-spend-actions';

const POLLING_INTERVAL = 15000;
const NEW_UPDATE_LOADING_TIME = 1500;

export type SpendTableProps = {
  spendData: PaginatedResponse<SubscriptionSpendAndInvoices> | undefined;
  subscription: Subscription;
  hideConversionColumn: boolean;
  company: Company | undefined;
  selectedRows: SubscriptionSpendAndInvoices[];
  setSelectedRows: (rows: SubscriptionSpendAndInvoices[]) => void;
  legends: string[];
  refetchSpendData: () => void;
  uploadedInvoices: SubscriptionInvoiceUpload[];
};

export function useSpendTable({
  company,
  hideConversionColumn,
  legends,
  refetchSpendData,
  selectedRows,
  setSelectedRows,
  spendData,
  subscription,
  uploadedInvoices,
}: SpendTableProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useUser();

  const { data: codatConnectionDetails } = useFetchData<CodatIntegrationDetailsRes[]>(
    'codat-integration-details',
    () => getCompanyCodatIntegrationDetails(String(user?.companyId)),
    {
      enabled: Boolean(user?.companyId),
      refetchOnWindowFocus: false,
    }
  );

  const spendAndInvoicesTranslationPath = 'tool_details_view:spend_tab';
  const emptyCellValue = '--';
  const initialValue: { [key in string]: FailedInvoiceData } = {};

  const [itemsWithLoader, setItemsWithLoader] = useState<{ [key in string]: boolean }>({});

  const retryCounter = useRef(0);
  const intervalTimer = useRef<NodeJS.Timeout>();
  const uploadedInvoicesRef = useRef<SubscriptionInvoiceUpload[]>();

  const processingInvoiceIds = useMemo(() => {
    const filterFn = (item: SubscriptionSpendAndInvoices) => !!item.documentId && item.processing;
    return spendData?.items.filter(filterFn).map((item) => item.documentId as string) || [];
  }, [spendData?.items]);

  const transformFailedInvoice = (data: FailedInvoiceData[]) => {
    return data.reduce((acc, curr) => {
      acc[curr.documentId as string] = curr;
      return acc;
    }, initialValue);
  };

  const { data: failedInvoices, refetch: refetchFailedInvoices } = useQuery({
    enabled: !!processingInvoiceIds.length,
    queryFn: () => fetchBatchFailedInvoiceData(subscription?.id, processingInvoiceIds),
    queryKey: 'failed-invoices-batch',
    refetchOnWindowFocus: false,
    select: transformFailedInvoice,
  });

  const { handleDownloadSpendData, handleEditSpendData, handleOpenReviewSpendModal, openDeleteSpendAndInvoiceModal } =
    useSpendActions({
      failedInvoices,
      refetchSpendData,
      selectedRows,
      setSelectedRows,
      subscription,
    });

  const handleItemsWithLoader = useCallback((id: string) => {
    setItemsWithLoader((prevState) => (prevState[id] === undefined ? { ...prevState, [id]: true } : prevState));

    setTimeout(() => {
      setItemsWithLoader((prevState) => ({ ...prevState, [id]: false }));
    }, NEW_UPDATE_LOADING_TIME);
  }, []);

  useEffect(() => {
    uploadedInvoicesRef.current = uploadedInvoices;
  }, [uploadedInvoices]);

  useEffect(() => {
    if (retryCounter.current === 5) {
      retryCounter.current = 0;
      return;
    }

    const someInvoiceStillProcessing = processingInvoiceIds.some((id) => !failedInvoices?.[id]);
    if (someInvoiceStillProcessing) {
      intervalTimer.current = setInterval(() => {
        refetchSpendData();
        refetchFailedInvoices();
        retryCounter.current += 1;
      }, POLLING_INTERVAL);
    }
    return () => {
      if (intervalTimer.current) clearInterval(intervalTimer.current);
    };
  }, [failedInvoices, processingInvoiceIds, refetchSpendData, refetchFailedInvoices]);

  useEffect(() => {
    if (!uploadedInvoicesRef.current || !spendData?.items) return;
    const updatedInvoiceUploads = uploadedInvoicesRef.current.map((item) => {
      const { id } = item;

      const currentSpend = spendData?.items.find((item) => item.documentId === id);

      if (failedInvoices?.[id] && currentSpend?.processing) {
        return { ...item, status: SubscriptionInvoiceUploadStatus.failed };
      }

      if (currentSpend && !currentSpend.processing) {
        handleItemsWithLoader(currentSpend.documentId as string);
        return { ...item, status: SubscriptionInvoiceUploadStatus.completed };
      }

      if (
        currentSpend?.uploadDate &&
        addSeconds(new Date(currentSpend.uploadDate), 25) < new Date() &&
        processingInvoiceIds.includes(id)
      ) {
        return { ...item, status: SubscriptionInvoiceUploadStatus.delayed };
      }

      return {
        ...item,
        status:
          item.status === SubscriptionInvoiceUploadStatus.uploaded
            ? SubscriptionInvoiceUploadStatus.processing
            : item.status,
      };
    });
    dispatch(setUploadedFiles(updatedInvoiceUploads));
  }, [failedInvoices, processingInvoiceIds, spendData?.items, handleItemsWithLoader, dispatch]);

  const getConnectionNameById = (connectionId: string) => {
    const connection = codatConnectionDetails?.find((item) => item?.codatConnectionId === connectionId);
    return connection?.sastrifyConnectionName;
  };

  const getNameSubtitle = (item: SubscriptionSpendAndInvoices): string => {
    const date = item.uploadDate ? formatFullDate(String(item.uploadDate)) : '';
    const category = getSubscriptionSpendCategoryMappings(legends)[Number(item.category)];
    const subtitle = `${category}    •   ${date}`;

    if (item.category === SPEND_CATEGORIES.AUTOMATED_IMPORT) {
      const platformName = getPlatformName((item?.importSubsystem as CodatPlatformType) ?? undefined);

      const connectionName = item.connectionId ? getConnectionNameById(item.connectionId) : null;
      const platform = platformName
        ? `${capitalize(platformName)} ${item.connectionId ? '(disconnected)' : ''}`
        : category;

      return `${connectionName || platform}    •   ${date}`;
    }

    if (
      item.category === SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT ||
      item.category === SPEND_CATEGORIES.SUBSCRIPTION_DOCUMENT
    ) {
      return `${
        item.uploadedByName
          ? t(
              'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.uploaded_by_text',
              { user: item.uploadedByName }
            )
          : category
      }    •   ${date}`;
    }

    return subtitle;
  };

  const isShowingReviewSpendFeature = (item: SubscriptionSpendAndInvoices) => {
    if (failedInvoices?.[item.documentId as string] && item.processing) {
      return true;
    }

    if (item.uploadDate && item.processing) {
      const uploadDate = new Date(item.uploadDate);
      return addSeconds(uploadDate, 25) < new Date();
    }

    return false;
  };

  const renderItemWithLoader = (item: SubscriptionSpendAndInvoices, component: React.ReactNode) => {
    return itemsWithLoader[item.documentId as string] ? <CircularProgress size={24} /> : component;
  };

  const NameCell = useMemo(
    () =>
      ({ row: { original: item } }: { row: MRT_Row<SubscriptionSpendAndInvoices> }): React.ReactNode =>
        (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='label' sx={{ marginBottom: theme.spacing(0.5) }}>
                {item.documentPreviewUrl ? (
                  <Link
                    href={item.documentPreviewUrl}
                    target='_blank'
                    rel='noreferrer'
                    data-testid='file-name'
                    sx={{ textDecoration: 'underline !important' }}>
                    {shortenFileName(item.name || '') || emptyCellValue}
                  </Link>
                ) : (
                  shortenText(item.name || '', 25) || emptyCellValue
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant='small' sx={{ color: theme.palette.text.secondary }}>
                {getNameSubtitle(item)}
              </Typography>
            </Box>
          </Box>
        ),
    []
  );

  const InvoiceDateCell = useMemo(
    () =>
      ({ row: { original: item } }: { row: MRT_Row<SubscriptionSpendAndInvoices> }): React.ReactNode =>
        isShowingReviewSpendFeature(item)
          ? emptyCellValue
          : renderItemWithLoader(
              item,
              <Typography variant='body'>
                {item.date ? formatDateWithoutTimeZone(String(item.date)) : emptyCellValue}
              </Typography>
            ),
    []
  );

  const AmountCell = useMemo(
    () =>
      ({ row: { original: item } }: { row: MRT_Row<SubscriptionSpendAndInvoices> }): React.ReactNode => {
        return !isShowingReviewSpendFeature(item) || !subscription || !company ? (
          renderItemWithLoader(item, <Typography variant='body'>{getItemAmount(item)}</Typography>)
        ) : (
          <ReviewSpendFeature
            handleOpenReviewSpendModal={() => handleOpenReviewSpendModal(subscription, company, item)}
          />
        );
      },
    []
  );

  const ConversionCell = useMemo(
    () =>
      ({ row: { original: item } }: { row: MRT_Row<SubscriptionSpendAndInvoices> }): React.ReactNode => {
        return isShowingReviewSpendFeature(item)
          ? emptyCellValue
          : renderItemWithLoader(
              item,
              <Typography variant='body'>{getItemConversion(item, company) ?? emptyCellValue}</Typography>
            );
      },
    []
  );

  const ActionsCell = useMemo(
    () =>
      ({ row: { original: item } }: { row: MRT_Row<SubscriptionSpendAndInvoices> }): React.ReactNode => {
        const isProcessing = item.displayAmountCents === 'processing';

        return (
          <IconDropdown
            iconButtonProps={{ color: 'text' }}
            iconName='more_vert_outlined'
            items={[
              {
                dataTestId: 'edit',
                disabled: isProcessing,
                iconName: 'edit_outlined',
                onClick: () => handleEditSpendData(item),
                title: 'Edit Info',
              },
              {
                dataTestId: 'download',
                disabled: !item.documentUrl,
                iconName: 'file_download_outlined',
                onClick: () => item.documentUrl && handleDownloadSpendData(item.documentUrl),
                title: 'Download',
                withDivider: true,
              },
              {
                dataTestId: 'delete',
                iconName: 'delete',
                onClick: () => openDeleteSpendAndInvoiceModal(item),
                title: 'Delete',
              },
            ]}
          />
        );
      },
    []
  );

  const columns = useMemo(
    () => [
      {
        Cell: NameCell,
        accessorKey: 'name',
        header: t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_table.table_columns.name`),
        size: 180,
      },
      {
        Cell: InvoiceDateCell,
        accessorKey: 'invoiceDate',
        header: t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_table.table_columns.date`),
        size: 80,
      },
      {
        Cell: AmountCell,
        accessorKey: 'amount',
        header: t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_table.table_columns.amount`),
        size: 80,
      },
      {
        Cell: ConversionCell,
        accessorKey: 'conversion',
        header: t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_table.table_columns.conversion`),
        show: !hideConversionColumn,
        size: 80,
      },
      {
        Cell: ActionsCell,
        id: 'actions',
        size: 5,
      },
    ],
    [NameCell, t, InvoiceDateCell, AmountCell, ConversionCell, hideConversionColumn, ActionsCell]
  );

  return {
    columns,
    openDeleteSpendAndInvoiceModal,
  };
}
