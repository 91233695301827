import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { Avatar } from 'asteroids';
import { ListboxComponent } from 'components/listbox';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { FC, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Company, SastrifyStore, User } from 'shared/models';
import { connect, getUsersList, revokeAccess } from 'shared/store/common/googleDirectory.slice';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { classes } from './drawer-user-invite.styles';
import { GoogleConnectionComponentProps, SelectedGoogleWorkspaceUser } from './google-connection.component.props';

export const GoogleConnectionComponent: FC<GoogleConnectionComponentProps> = ({
  onSelectGoogleWorkSpaceUser,
  showNotification,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedGoogleWorkspaceUsers, setSelectedGoogleWorkspaceUsers] = useState<SelectedGoogleWorkspaceUser[]>([]);

  const {
    connected: isConnectedToGoogle,
    directoryUsers,
    error: googleError,
    hasFetchedUsers,
    isFetchingUsers,
    isGoogleDirectoryError,
  } = useAppSelector((state) => state.common.googleDirectory);
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const queryClient = useQueryClient();

  const company: Company | undefined = queryClient.getQueryData('company');

  const connectToGoogleDirectory = () => {
    dispatch(connect());
  };

  const disconnectToGoogleDirectory = () => {
    if (company?.id && user?.id) {
      dispatch(revokeAccess({ companyId: company.id, userId: user.id }));
    }
  };

  const handleGoogleDirectoryUsersFetch = () => {
    if (!hasFetchedUsers && !isGoogleDirectoryError) {
      dispatch(getUsersList({ showNotification: showErrorNotification }));
    }
  };

  const showErrorNotification = (message: React.ReactNode) => {
    showNotification?.(message, NotificationAlertType.Warning);
  };

  if (isConnectedToGoogle && !googleError) {
    return (
      <>
        <Box display='flex' flexDirection='row' mt={1} alignItems='center'>
          <Typography variant='body1'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.invite_colleagues_text'
            )}
          </Typography>

          <Button
            className='ml-2 connect-to-google-button'
            disableRipple
            color='inherit'
            startIcon={<span className='gbutton-icon' />}
            onClick={disconnectToGoogleDirectory}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.disconnect_from_google'
            )}
          </Button>
        </Box>

        <Autocomplete
          ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
          className='mt-1'
          options={directoryUsers}
          renderOption={(props, option) => {
            const boxProps = props as unknown as HTMLAttributes<HTMLDivElement>;
            const { avatarUrl, email, isInvited, name } = option;

            return (
              <Box
                sx={{ px: 2, py: 1 }}
                {...boxProps}
                width='100%'
                display='flex'
                flexDirection='row'
                alignItems='center'
                onClick={
                  !isInvited
                    ? () => {
                        setSelectedGoogleWorkspaceUsers([...selectedGoogleWorkspaceUsers, { email, name }]);
                        onSelectGoogleWorkSpaceUser(
                          {
                            email,
                            name,
                          },
                          selectedGoogleWorkspaceUsers
                        );
                      }
                    : undefined
                }
                className={classes.autoCompleteOption}>
                <Avatar alt='google-workspace-user' className={classes.avatar} variant='circular' src={avatarUrl}>
                  {name[0]}
                </Avatar>
                <Box display='flex' flexDirection='row' className={classes.googleUserInfo}>
                  <Typography>{name}</Typography>
                  <Typography>({email})</Typography>
                  {isInvited ? (
                    <Typography className={classes.alreadyInvitedTag}>
                      {t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.already_invited'
                      )}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            );
          }}
          loading={isFetchingUsers}
          loadingText={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.loading'
          )}
          disabled={isFetchingUsers}
          onFocus={handleGoogleDirectoryUsersFetch}
          getOptionDisabled={({ isInvited }) => !!isInvited}
          getOptionLabel={(option) => String(option?.name)}
          renderInput={(params) => (
            <TextField
              ref={params.InputProps.ref}
              {...params}
              variant='outlined'
              size='small'
              InputProps={{
                ...params.InputProps,
                endAdornment: undefined,
              }}
              placeholder={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.user_search_input_placeholder'
              )}
            />
          )}
        />
      </>
    );
  }

  if (googleError) {
    return (
      <Typography variant='body1'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.google_workspace_error',
          {
            errorMessage: googleError,
          }
        )}
      </Typography>
    );
  }

  return (
    <Box display='flex' flexDirection='row' mt={1} alignItems='center'>
      <Typography variant='body1'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.connect_teammates_text'
        )}
      </Typography>

      <Button
        className='connect-to-google-button'
        disableRipple
        color='inherit'
        startIcon={<span className='gbutton-icon' />}
        onClick={connectToGoogleDirectory}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.google_workspace_text'
        )}
      </Button>
    </Box>
  );
};
