import { Box, Button, CircularProgress, ClickAwayListener, TextField, Typography, useTheme } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Icon } from 'asteroids';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { priorityCurrencies } from 'shared/common.definitions';
import { getAvailableCurrencies } from 'shared/logic/currencies.logic';
import { Currency } from 'shared/models';

import { CurrencySelectInputComponentProps } from './currency-select-input.component.props';
import { CurrencySelectInputWrapper, LabeledTextField } from './currency-select-input.styles';
import { ListboxComponent } from './listbox.component';

const stringifyOption = (currency: Currency): string => `${currency.code} ${currency.name}`;

export const CurrencySelectInputComponent: FC<CurrencySelectInputComponentProps> = ({
  dataTestId,
  disabled = false,
  getValue,
  hasLabel = false,
  initialValue,
  isValid = true,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<Array<Currency>>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const currencies = await getAvailableCurrencies();
      const priorityCurrenciesArray: Currency[] = [];
      const otherCurrenciesArray: Currency[] = [];

      currencies.forEach((currency) => {
        if (priorityCurrencies.includes(currency.code)) {
          priorityCurrenciesArray.push(currency);
        } else {
          otherCurrenciesArray.push(currency);
        }
      });

      const sortedCurrencies = [...priorityCurrenciesArray, ...otherCurrenciesArray];

      setCurrencies(sortedCurrencies);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    const initialValueObject = currencies.find((currency) => currency.code === initialValue);

    if (initialValue && initialValueObject) {
      setSelectedCurrency(initialValueObject);
    } else {
      setSelectedCurrency(null);
    }
  }, [initialValue, currencies]);

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(undefined);
    setOpen(false);
  };

  const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }

    if (!open) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    } else {
      setOpen(false);
      setAnchorEl(undefined);
    }
  };

  const handleOptionClick = (value: Currency | null) => {
    if (value) {
      setSelectedCurrency(value);
      getValue?.(value);

      setAnchorEl(undefined);
      setOpen(false);
    }
  };

  const renderInput = () => (
    <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
      {hasLabel ? (
        <LabeledTextField
          size='small'
          value={selectedCurrency ? selectedCurrency.code : ''}
          label={t('common:currency_select_input_component.label_text')}
          placeholder={t('common:currency_select_input_component.placeholder_text')}
        />
      ) : (
        <Typography variant='label' color='text.primary'>
          {selectedCurrency ? selectedCurrency.code : t('common:currency_select_input_component.placeholder_text')}
        </Typography>
      )}
      {isLoading ? (
        <CircularProgress color='inherit' size={20} />
      ) : (
        !disabled && <Icon color='action'>{open ? 'expand_less' : 'expand_more'}</Icon>
      )}
    </Box>
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <CurrencySelectInputWrapper>
        <div className='root'>
          <Button
            variant='outlined'
            color={isValid ? 'secondary' : 'error'}
            className='currency-select-button'
            aria-describedby='currency-select-input'
            onClick={handleInputClick}
            data-testid='currency-select-input'>
            {renderInput()}
          </Button>
        </div>
        {open && (
          <div className='popper'>
            <Autocomplete
              open
              clearIcon={null}
              filterOptions={createFilterOptions({
                ignoreAccents: true,
                ignoreCase: true,
                matchFrom: 'any',
                stringify: (option: Currency) => stringifyOption(option),
                trim: true,
              })}
              ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
              classes={{
                option: 'option',
                paper: 'paper',
                popperDisablePortal: 'popperDisablePortal',
              }}
              loading={isLoading}
              value={selectedCurrency}
              onChange={(event, newValue) => {
                handleOptionClick(newValue);
              }}
              disableListWrap
              disablePortal
              renderTags={() => null}
              noOptionsText={t('common:currency_select_input_component.no_options_text')}
              options={currencies}
              getOptionLabel={(option) => String(option.code)}
              renderInput={(params) => (
                <TextField
                  ref={params.InputProps.ref}
                  {...params}
                  variant='outlined'
                  size='small'
                  InputProps={{
                    ...params.InputProps,
                    className: `${dataTestId}-filter`,
                    endAdornment: undefined,
                    startAdornment: (
                      <Icon color='action' sx={{ mx: 1 }}>
                        search
                      </Icon>
                    ),
                    sx: {
                      margin: theme.spacing(1, 1, 0, 1),
                      paddingRight: `${theme.spacing(1)} !important`,
                      width: `calc(100% - ${theme.spacing(2)})`,
                    },
                  }}
                  autoFocus
                  placeholder={t('common:currency_select_input_component.search_input_placeholder_text')}
                  data-testid='search-input'
                />
              )}
            />
          </div>
        )}
      </CurrencySelectInputWrapper>
    </ClickAwayListener>
  );
};
