import './subscription-comment.style.scss';

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { ModalComponentProps, UserNameAndAvatarComponent, WysiwygEditorComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import type { EditorValue } from 'components/wysiwyg-editor/wysiwyg-editor.component.props';
import useSessionStorage from 'hooks/useSessionStorage';
import { useUser } from 'hooks/useUser';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { CommentDocument, SUBSCRIPTION_DOCUMENT_REGEX_PATTERN } from 'shared/common.definitions';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';
import { trackReplySaveClicked } from 'shared/logic/event-tracking/comment-replies.events';
import {
  deleteSubscriptionComment,
  getFormattedHTMLWithNoWhiteSpaces,
  getMarkDownTextWithMention,
  getMarkDownTextWithUrls,
  sanitizeMarkdown,
  showdownConverter,
} from 'shared/logic/subscription-item.logic';
import { SubscriptionComment } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';

import { CommentButton } from '../comment-template/comment-button.component';
import { CommentEditActions } from './components/comment-edit-actions.component';
import { CommentItem } from './components/comment-item.component';
import { CommentThread } from './components/comment-thread.component';
import { useAddReply } from './hooks/use-add-reply';
import { useThreadState } from './hooks/use-thread-state';
import { useUpdateComment } from './hooks/use-update-comment';
import { SubscriptionCommentFeatureProps } from './subscription-comment.feature.props';

type ReplyEditorProps = {
  // documents: CommentDocument[];
  text: string;
  onTextChange?: (t: string) => void;
  onReplySave: (editorState: EditorValue) => void;
  setDocuments?: React.Dispatch<React.SetStateAction<CommentDocument[]>>;
  setIsUploadingDocument: (value: boolean) => void;
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
  subscriptionId: string;
  hideAddImageButton: boolean;
};

const ReplyEditor: FC<ReplyEditorProps> = (props) => {
  const {
    // documents,
    hideAddImageButton,
    onReplySave,
    onTextChange,
    setDocuments,
    setIsUploadingDocument,
    showNotification,
    subscriptionId,
    text,
  } = props;

  const { t } = useTranslation();

  // FIXME(comments): Place here the transformations required in order to make appear in the editor the correct formatting for text
  const value = text;

  return (
    <WysiwygEditorComponent
      actionText='save comment'
      showNotification={showNotification}
      value={value}
      subscriptionId={subscriptionId}
      placeholder={t('subscription_detail_view:subscription_comment_section.markdown_editor_placeholder_text')}
      onChange={onTextChange}
      setIsUploadingDocument={setIsUploadingDocument}
      setDocuments={setDocuments}
      handleSaveCommentShortCutPress={onReplySave}
      bottomSectionStyles={{ bottom: '-2.5rem' }}
      hideImageUploadButton={hideAddImageButton}
    />
  );
};

export const SubscriptionCommentFeature: FC<SubscriptionCommentFeatureProps> = (props) => {
  const {
    cancelEditComment,
    closeModal,
    documents: documentsProp,
    handleEditComment,
    history,
    isEditingComment,
    setActionMenuIndex,
    shouldEditComment,
    showModal,
    showNotification,
    subscription,
  } = props;

  const { comment, id, templateId } = history;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const user = useUser();

  const [documents, setDocuments] = useState<CommentDocument[]>([]);
  const [isUploadingDocument, setIsUploadingDocument] = useState<boolean>(false);

  const getDocumentsFromMarkdown = () => {
    const markdownCommentWithFormattedMentions = getMarkDownTextWithMention(String(comment));
    const documentUrlMatches = markdownCommentWithFormattedMentions.match(SUBSCRIPTION_DOCUMENT_REGEX_PATTERN) || [];
    let markdownComment = markdownCommentWithFormattedMentions;

    if (documentUrlMatches.length > 0) {
      const { commentDocuments, markdownWithResolvedUrls } = getMarkDownTextWithUrls(
        markdownCommentWithFormattedMentions,
        documentUrlMatches,
        documentsProp
      );
      markdownComment = markdownWithResolvedUrls;

      setDocuments(commentDocuments);
    }

    return showdownConverter.makeHtml(String(markdownComment));
  };

  const [commentText, setCommentText] = useState<string>(getDocumentsFromMarkdown);

  const initialModalProps: ModalComponentProps = {
    cancelButtonText: t(
      'subscription_detail_view:subscription_comment_section.subscription_comment_modal.action_buttons_section.cancel_button_text'
    ),
    children: undefined,
    message: '',
    onCancelButtonClick: () => closeModal?.(),
  };

  const { isLoading, onSaveComment } = useUpdateComment(documents, subscription, history, cancelEditComment);

  const { mutate: deleteComment } = useMutation(deleteSubscriptionComment, {
    onError: (error) => {
      dispatch(updateFooterState({ isFormSubmitting: false }));
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    onMutate: () => {
      dispatch(updateFooterState({ isFormSubmitting: true }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries('subscription-history');

      dispatch(updateFooterState({ isFormSubmitting: false }));
      closeModal?.();
      showNotification?.('Comment deleted successfully', NotificationAlertType.Success);
    },
  });

  const openDeleteCommentModal = (comment: SubscriptionComment) => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    setActionMenuIndex(undefined);

    const message = (
      <p>{t('subscription_detail_view:subscription_comment_section.subscription_comment_modal.modal_body_text')}</p>
    );

    showModal?.({
      ...initialModalProps,
      confirmButtonText: t(
        'subscription_detail_view:subscription_comment_section.subscription_comment_modal.action_buttons_section.confirm_button_text'
      ),
      message,
      onConfirmButtonClick: () => deleteComment(comment),
      title: t('subscription_detail_view:subscription_comment_section.subscription_comment_modal.modal_title_text'),
    });
  };

  const onCommentTextChange = (value: string): void => {
    setCommentText(value);
  };

  const getMarkdownReaderComment = useMemo(() => {
    return getMarkDownTextWithMention(String(comment));
  }, [comment]);

  const editorCommentText = getFormattedHTMLWithNoWhiteSpaces(commentText);

  const handleSaveCommentOnEditor = (comment: EditorValue) => {
    onSaveComment(String(comment));
  };

  const { editorContainerRef, handleScrollThreadIntoViewport, handleToggleThreadCollapsed, isThreadExpanded } =
    useThreadState(id);

  const { mutateAsync: saveReply } = useAddReply(history.id, templateId);

  const [replyState, setReplyState] = useSessionStorage<string>(`createReplyState:${history.id}`, '');

  const onReplyTextChange = useCallback(
    (replyText: string): void => {
      setReplyState(replyText);
    },
    [setReplyState]
  );

  const handleSaveReply = useCallback(
    async (value: EditorValue) => {
      const commentMD = await sanitizeMarkdown(String(value), documents);

      saveReply(commentMD);
      setReplyState('');
      handleScrollThreadIntoViewport();
      trackReplySaveClicked();
    },
    [documents, saveReply, setReplyState, handleScrollThreadIntoViewport]
  );

  const isCommentDeleted = history.comment === null;

  const threadEditor = useCallback(
    (
      text: string,
      onTextChange: (t: string) => void,
      onReplySave: (editorState: EditorValue) => void,
      hideImageUpload = false
    ) => {
      return (
        <ReplyEditor
          text={text}
          showNotification={showNotification}
          subscriptionId={subscription.id}
          setIsUploadingDocument={setIsUploadingDocument}
          setDocuments={setDocuments}
          onTextChange={onTextChange}
          onReplySave={onReplySave}
          hideAddImageButton={hideImageUpload}
        />
      );
    },
    [showNotification, subscription.id]
  );

  const renderAddNewReplyEditor = useMemo(() => {
    return threadEditor(replyState, onReplyTextChange, handleSaveReply, true);
  }, [replyState, handleSaveReply, onReplyTextChange, threadEditor]);

  return (
    <div data-testid='comment-section'>
      {!isEditingComment ? (
        <CommentItem
          comment={history}
          onCommentEdit={() => handleEditComment(Number(id))}
          onCommentDelete={() => openDeleteCommentModal(history as SubscriptionComment)}
          isEditEnabled={shouldEditComment}
          parsedCommentText={getMarkdownReaderComment}
          documents={documentsProp}
          onToggleThreadCollapse={handleToggleThreadCollapsed}
          isThreadExpanded={isThreadExpanded}
        />
      ) : (
        <WysiwygEditorComponent
          actionText='save comment'
          showNotification={showNotification}
          value={commentText}
          subscriptionId={subscription.id}
          placeholder={t('subscription_detail_view:subscription_comment_section.markdown_editor_placeholder_text')}
          onChange={onCommentTextChange}
          setIsUploadingDocument={setIsUploadingDocument}
          setDocuments={setDocuments}
          handleSaveCommentShortCutPress={handleSaveCommentOnEditor}
          bottomSectionStyles={{ bottom: '-2.5rem' }}
        />
      )}

      <div className='comment-footer'>
        {isEditingComment ? (
          <CommentEditActions
            onCancelClicked={() => {
              cancelEditComment();
              const wysiwygCommentText = getDocumentsFromMarkdown();
              setCommentText(wysiwygCommentText);
            }}
            isCancelDisabled={isUploadingDocument || isLoading}
            isSubmitLoading={isLoading}
            onSaveClicked={() => onSaveComment(commentText)}
            isSubmitDisabled={!editorCommentText || isLoading || isUploadingDocument}
          />
        ) : null}
      </div>

      {!isThreadExpanded && history.numberOfReplies > 0 && (
        <div
          tabIndex={0}
          role='button'
          onKeyUp={(e) => onEnterOrSpaceKeyUp(e, handleToggleThreadCollapsed)}
          onClick={handleToggleThreadCollapsed}
          className='subscription-comment__see-thread'>
          <i className='uil-corner-down-right' />
          {t('subscription_detail_view:subscription_comment_section.view_thread_replies', {
            count: parseInt(String(history.numberOfReplies), 10),
          })}
        </div>
      )}

      {isThreadExpanded && (
        <div className='subscription-comment__thread'>
          <CommentThread
            commentId={history.id}
            documents={documentsProp}
            onListFetched={handleScrollThreadIntoViewport}
            editorComponent={threadEditor}
            isUploadingDocument={isUploadingDocument}
          />
          <div className='subscription-comment__thread-editor' ref={editorContainerRef}>
            {!isCommentDeleted && (
              <>
                <div className='subscription-comment__thread-editor__avatar'>
                  <UserNameAndAvatarComponent
                    avatarUrl={user.avatar || undefined}
                    name=''
                    height='24px'
                    width='24px'
                    hideName
                  />
                </div>
                {renderAddNewReplyEditor}
                <CommentButton
                  disabled={!replyState || isUploadingDocument}
                  onClick={() => handleSaveReply(replyState)}
                  submitting={false}
                  isActive={!!editorCommentText}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
