import { Box, MenuItem, Select, Typography } from '@mui/material';
import { Chip, InputField } from 'asteroids';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RenewalDate } from './change-renewal-date-form.hook';

type RenewalFrequencyProps = {
  form: UseFormReturn<RenewalDate>;
};

export const CancellationPeriodField = (props: RenewalFrequencyProps) => {
  const { form } = props;
  const { t } = useTranslation();

  const { errors } = form.formState;
  const cancellationPeriod = form.watch('cancellationPeriod');

  const cancellationPeriodOptions = [
    {
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.cancellation_period_select_input_section.unknown_option_text'
      ),
      value: -1,
    },
    {
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.cancellation_period_select_input_section.thirty_days_option_text'
      ),
      value: 30,
    },
    {
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.cancellation_period_select_input_section.sixty_days_option_text'
      ),
      value: 60,
    },
    {
      label: t(
        'common:modals.change_renewal_date_feature.body_section.form.cancellation_period_select_input_section.other_option_text'
      ),
      value: -2,
    },
  ];

  return (
    <Box mb={2} data-testid='cancellation-period'>
      <Typography variant='h3' mt={3} mb={2} data-testid='title'>
        {t('common:modals.change_renewal_date_feature.body_section.form.cancellation_period_label')}
      </Typography>
      <Typography variant='body' mb={2} data-testid='subtitle'>
        {t('common:modals.change_renewal_date_feature.body_section.form.cancellation_period_subtitle')}
      </Typography>
      <Controller
        name='cancellationPeriod'
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <>
            {cancellationPeriodOptions.map((item) => (
              <Chip
                data-testid={`${item.label.toLowerCase().replace(' ', '-')}`}
                label={item.label}
                size='medium'
                variant={value === item.value ? 'filled-dark' : 'outlined'}
                color='secondary'
                onClick={() => onChange(item.value)}
                sx={{ mr: 1 }}
              />
            ))}
          </>
        )}
      />
      {Number(cancellationPeriod) !== -2 && errors.customCancellationPeriod && (
        <Typography color='error.main' variant='caption'>
          {errors.customCancellationPeriod?.message}
        </Typography>
      )}
      {Number(cancellationPeriod) === -2 && (
        <Box>
          <Box display='flex'>
            <InputField
              size='small'
              type='number'
              {...form.register('customCancellationPeriod')}
              inputRef={form.register('customCancellationPeriod').ref}
              inputProps={{ max: 31, min: 0 }}
              defaultValue={0}
              sx={{ mr: 1, mt: 2, p: 0, width: 56 }}
            />
            <Controller
              name='customCancellationPeriodType'
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <Select size='small' onChange={onChange} value={value} sx={{ mt: 2, width: 177 }}>
                  <MenuItem value={0}>
                    {t(
                      'common:modals.change_renewal_date_feature.body_section.form.custom_cancellation_period_type_select_input_section.days_option_text'
                    )}
                  </MenuItem>
                  <MenuItem value={1}>
                    {t(
                      'common:modals.change_renewal_date_feature.body_section.form.custom_cancellation_period_type_select_input_section.months_option_text'
                    )}
                  </MenuItem>
                </Select>
              )}
            />
          </Box>
          {!!errors.customCancellationPeriod && (
            <Typography color='error.main' variant='caption'>
              {errors.customCancellationPeriod?.message}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
