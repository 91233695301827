import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { getRequestDetails } from 'shared/logic/requests.logic';

import { MultiToolInitiativesDetailsSection, RequestDetailsSection } from '.';

const NOT_FOUND_PAGE = '/request-not-found';
const QUERY_NAME = 'workflow-request-details';
const BAD_REQUEST_STATUS = 400;

interface MultiToolInitiativesInfoWrapperProps {
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
  handleCloseDrawer: () => void;
}

export const MultiToolInitiativesInfoWrapper: React.FC<MultiToolInitiativesInfoWrapperProps> = ({
  handleCloseDrawer,
  showNotification,
}) => {
  const { hash } = useLocation();
  const { push } = useHistory();

  const getRequestId: string = useMemo(() => {
    if (hash.includes(DRAWER_VARIANT.REQUEST_DETAILS))
      return hash.split(DRAWER_VARIANT.REQUEST_DETAILS).slice(-1)[0] || '';
    return '';
  }, [hash]);

  const { data, isLoading } = useQuery([QUERY_NAME, getRequestId], () => getRequestDetails(getRequestId), {
    onError: (error) => {
      if (error && (error as AxiosError).response?.status === BAD_REQUEST_STATUS) {
        handleCloseDrawer();
        push(NOT_FOUND_PAGE);
      }
    },
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {!isLoading && !!data?.childRequests && (
        <MultiToolInitiativesDetailsSection handleCloseDrawer={handleCloseDrawer} showNotification={showNotification} />
      )}
      {!isLoading && !data?.childRequests && (
        <RequestDetailsSection handleCloseDrawer={handleCloseDrawer} showNotification={showNotification} />
      )}
    </>
  );
};
