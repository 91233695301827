import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SSOIntegrationStatus } from 'libs/enums';
import { RESPONSE_CODE } from 'shared/common.definitions';
import {
  getCompanyGoogleConnectionState,
  toggleGoogleUsageAnalytics,
  updateGoogleIntegration,
} from 'shared/logic/company.logic';
import { GoogleConnect, User } from 'shared/models';
import { TokenResponse } from 'shared/models/google-directory.model';
import { apiService, apiUrl } from 'shared/services';
import i18n from 'src/localization/i18n';

const pathToTranslation = 'app:google_connect';
const { t } = i18n;

const CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;

const ADMIN_SCOPES =
  'https://www.googleapis.com/auth/admin.reports.audit.readonly https://www.googleapis.com/auth/admin.reports.usage.readonly';

type ConnectPayload = {
  trackConnected: () => void;
  trackConnectedError: (errorMessage: string) => void;
  showSuccessMessage: () => void;
  userData: User;
  enableUsageAnalytics: boolean;
};

type DisconnectPayload = {
  refetch: () => void;
  userData: User;
};

type GoogleIntegrationDetails = {
  status: string;
  companyId: string;
  createdAt: string;
  refreshToken: string | null;
  updatedAt: string;
};

export const getConnectionStatus = async (companyId: number) => {
  return getCompanyGoogleConnectionState(companyId);
};

export const disconnectFromGoogle = createAsyncThunk(
  'googleConnect/disconnect',
  async (payload: DisconnectPayload, thunkAPI) => {
    const { refetch, userData } = payload;

    const companyId = userData.companyId as string;
    const data = await updateGoogleIntegration(companyId, SSOIntegrationStatus.DISCONNECTED);
    const connectionData = data as GoogleIntegrationDetails;

    if (connectionData.status === SSOIntegrationStatus.DISCONNECTED) {
      thunkAPI.dispatch(googleConnectSlice.actions.setConnectionState(false));
      thunkAPI.dispatch(googleConnectSlice.actions.setEnableUsageAnalytics(false));

      refetch();
    }
  }
);

export const sendCompanyTokenToGoogleIntegrationService = async (
  code: string,
  companyId: string,
  enableUsageAnalytics: boolean
) => {
  return apiService.get(apiUrl.sendCompanyTokenToGoogleService(code, companyId, enableUsageAnalytics));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function handleAuthorizationCode(
  code: string,
  user: User,
  enableUsageAnalytics: boolean,
  showSuccessMessage: () => void,
  trackConnected: () => void,
  trackConnectedError: (errorMessage: string) => void,
  thunkAPI: any
) {
  try {
    const companyId = user.companyId as string;

    thunkAPI.dispatch(googleConnectSlice.actions.setConnectingState());

    const tokenVerificationResponse = await sendCompanyTokenToGoogleIntegrationService(
      code,
      companyId,
      enableUsageAnalytics
    );

    if (tokenVerificationResponse.status === RESPONSE_CODE.Forbidden.code) {
      thunkAPI.dispatch(googleConnectSlice.actions.setError(tokenVerificationResponse.data.message));
      trackConnectedError(tokenVerificationResponse.data.message);
    } else {
      thunkAPI.dispatch(googleConnectSlice.actions.setConnectionState(true));
      thunkAPI.dispatch(googleConnectSlice.actions.setEnableUsageAnalytics(enableUsageAnalytics));
      showSuccessMessage();
      trackConnected();
    }
  } catch (err) {
    thunkAPI.dispatch(googleConnectSlice.actions.setError('Connection error'));
    trackConnectedError(`Connection error: ${err instanceof Error ? err.message : err}`);
    throw new Error(t(`${pathToTranslation}.token_verification_error`));
  }
}

export const connectToGoogleAccounts = createAsyncThunk(
  'googleConnect/connectToGoogleAccounts',
  async (payload: ConnectPayload, thunkAPI) => {
    try {
      const { enableUsageAnalytics, showSuccessMessage, trackConnected, trackConnectedError, userData } = payload;

      const client = window.google.accounts.oauth2.initCodeClient({
        callback: async (tokenResponse: TokenResponse) => {
          const { code } = tokenResponse;

          await handleAuthorizationCode(
            code,
            userData,
            enableUsageAnalytics,
            showSuccessMessage,
            trackConnected,
            trackConnectedError,
            thunkAPI
          );
        },
        client_id: CLIENT_ID,
        redirect_uri: 'http://app.sastrify.com/connect',
        scope: ADMIN_SCOPES,
        ux_mode: 'popup',
      });

      client.requestCode();
    } catch (error) {
      throw new Error(t(`${pathToTranslation}.google_identity_error`));
    }
  }
);

export const toggleUsageAnalytics = createAsyncThunk(
  'googleConnect/toggleUsageAnalytics',
  async (
    {
      companyId,
      enableUsageAnalytics,
      refetch,
    }: { refetch: () => void; companyId: string; enableUsageAnalytics: boolean },
    thunkAPI
  ) => {
    thunkAPI.dispatch(googleConnectSlice.actions.setTogglingUsageAnalytics(true));
    thunkAPI.dispatch(googleConnectSlice.actions.setEnableUsageAnalytics(enableUsageAnalytics));
    await toggleGoogleUsageAnalytics(companyId, enableUsageAnalytics);
    thunkAPI.dispatch(googleConnectSlice.actions.setTogglingUsageAnalytics(false));

    refetch();
  }
);

const initialState: GoogleConnect = {
  connected: false,
  connecting: false,
  error: '',
  isError: false,
  togglingUsageAnalytics: false,
  usageAnalyticsEnabled: false,
};

const googleConnectSlice = createSlice({
  initialState,
  name: 'googleConnect',
  reducers: {
    disconnect(state) {
      if (!state.connected) {
        return;
      }

      state.error = null;
      state.isError = false;
      state.connecting = false;
      state.connected = false;
      state.togglingUsageAnalytics = false;
    },
    setConnectingState(state) {
      state.connecting = true;
    },
    setConnectionState(state, action: PayloadAction<boolean>) {
      if (state.connected !== action.payload) {
        state.connected = action.payload;
        state.connecting = false;
      }
    },
    setEnableUsageAnalytics(state, action: PayloadAction<boolean>) {
      state.usageAnalyticsEnabled = action.payload;
    },
    setError(state, action: PayloadAction<string | unknown>) {
      state.connecting = false;
      state.isError = true;
      state.error = action.payload;
    },
    setTogglingUsageAnalytics(state, action: PayloadAction<boolean>) {
      state.togglingUsageAnalytics = action.payload;
    },
  },
});

export const {
  disconnect,
  setConnectingState,
  setConnectionState,
  setEnableUsageAnalytics,
  setError,
  setTogglingUsageAnalytics,
} = googleConnectSlice.actions;
export default googleConnectSlice.reducer;
