/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button, Stack, Typography } from '@mui/material';
import rocketImage from 'assets/images/requests/rocket.svg';
import sastrifyImage from 'assets/images/requests/sastrify.svg';
import { Icon, Stepper } from 'asteroids';
import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { DialogContext } from 'context/dialog.context';
import { format } from 'date-fns';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { DocumentCategory, DRAWER_VARIANT, featureFlags } from 'shared/common.definitions';
import { getHash } from 'shared/helpers/common.helper';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import {
  trackInitiativesStepSelected,
  trackWorkflowRequestCreated,
} from 'shared/logic/event-tracking/workflow-requests.events';
import {
  createDraft,
  createNewRequest,
  editRequest,
  getDraftRequests,
  getRequestDetails,
  getRequestWorkflows,
  updateDraft,
} from 'shared/logic/requests.logic';
import { getCompanyDocumentCategory, uploadSubscriptionDocument } from 'shared/logic/subscription-item.logic';
import { fetchVendors } from 'shared/logic/subscriptions.logic';
import { Company, CompanyDocument, RequestPayload, RequestResponse, Subscription, VendorType } from 'shared/models';
import { CustomFormData } from 'shared/models/request-workflow.model';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { useAddRequestDrawer } from 'views/requests/hooks';
import { draftParamPrefix } from 'views/requests/requests.view';
import { invalidateRenewalReminder } from 'views/subscription-detail/components/renewal-status-alert/helpers';

import { CraftInitiativeCustomForm, CraftInitiativeDefaultForm } from './components';
import { InitiativeType } from './components/craft-initiative-default-form/craft-initiative-default-form.component';
import { WorkflowInitiativeWizardContextProvider } from './contexts';
import { DrawerWorkflowInitiativeWizardProps } from './drawer-workflow-initiative-wizard.props';
import { ClickableCard, Content, Footer, Header, Wrapper } from './drawer-workflow-initiative-wizard.styles';
import { useWorkflowInitiativeWizardContext } from './hooks';

const BACK_ICON = 'arrow_back';

enum RenderedScreen {
  WIZARD = 0,
  MANAGER = 1,
}

const Steps = {
  ShareMoreDetails: 1,
  StartInitiative: 0,
};

const DrawerWorkflowInitiativeWizardComponent: React.FC<DrawerWorkflowInitiativeWizardProps> = ({
  showNotification,
  toggleDrawer,
}) => {
  const {
    completePreviousStep,
    customForm,
    defaultForm,
    fetchedData,
    goOneStepBackAndMarkStepAsUncompleted,
    initiativeName,
    isToolBusy,
    selectedInitiativeType,
    setActiveStep,
    setApiData,
    setCustomFormError,
    setDefaultFormData,
    setDraftData,
    setInitiativeNameError,
    stepper,
    workflow,
  } = useWorkflowInitiativeWizardContext();
  const isProcurementSupportEnabled = !useCompanyFeatureFlag(featureFlags.PROCUREMENT_SUPPORT_EXCLUDED);
  const { t } = useTranslation();
  const { hash, pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const { onAddRequestDrawer } = useAddRequestDrawer();
  const { openDialog } = useContext(DialogContext);
  const includedHash = getHash(hash);

  const queryClient = useQueryClient();
  const company = queryClient.getQueryData<Company>('company');

  const isEditMode = !!hash.includes(DRAWER_VARIANT.EDIT_REQUEST);
  const isDraftInitiative = hash.includes('newRequest-draft');

  const requestId = hash.includes(DRAWER_VARIANT.EDIT_REQUEST)
    ? hash.split(DRAWER_VARIANT.EDIT_REQUEST).slice(-1)[0]
    : '';

  const [renderedScreen, setRenderedScreen] = useState<RenderedScreen | null>(null);

  useQuery(['workflow-request-details', requestId], () => getRequestDetails(requestId), {
    enabled: isEditMode,
    onSuccess: (data) => {
      setApiData?.(data);
    },
    refetchOnWindowFocus: false,
  });

  const isRenewalInitiative = selectedInitiativeType?.id === InitiativeType.RENEWAL;
  let subscriptions: Subscription[];
  if (isRenewalInitiative) subscriptions = queryClient.getQueryData<any>('subscriptions');

  const [subscriptionId = '', draftId = '']: (string | undefined)[] = (
    hash.includes(DRAWER_VARIANT.NEW_REQUEST) ? hash.split(DRAWER_VARIANT.NEW_REQUEST).slice(-1)[0] : ''
  ).split(`-${draftParamPrefix}-`);

  useQuery('workflow-request-drafts', () => getDraftRequests(), {
    enabled: !!draftId,
    onSuccess: (drafts) => {
      const draft = drafts.find(({ id }) => id === draftId);

      if (draft) setDraftData?.(draft);
    },
    refetchOnWindowFocus: false,
  });

  const defaultFormRef = useRef<HTMLFormElement>(null);

  const isStartInitiativeStep = stepper.activeStep === Steps.StartInitiative;
  const isShareMoreDetailsStep = stepper.activeStep === Steps.ShareMoreDetails;

  const [isDocumentsUploadInProgress, setIsDocumentsUploadInProgress] = useState(false);

  const toolQuery = queryClient.getQueriesData('tools');
  const toolQueryParams = toolQuery?.[toolQuery.length - 1]?.[0];

  const invalidateTools = () => {
    if (pathname === '/tool-store') {
      queryClient.invalidateQueries({ queryKey: toolQueryParams });
    }
  };

  const handleCloseDrawer = () => {
    replace(`${pathname}${search}${isRenewalInitiative ? includedHash : ''}`);
    toggleDrawer();
  };

  const { mutateAsync: uploadDocumentAsync } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      setIsDocumentsUploadInProgress(false);
    },
  });

  const onModifyError = (err: AxiosError, mode: 'initiative' | 'draft' = 'initiative') => {
    const message =
      getErrorCodeMessage(err) ||
      t('common:modals.add_new_subscription_feature_modal.error_message', {
        value: mode === 'draft' ? 'Draft' : 'New initiative',
      });

    showNotification?.(message, NotificationAlertType.Error);
  };

  const onModifySuccess = async (data: RequestResponse) => {
    invalidateTools();
    handleCloseDrawer();
    await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });
    if (draftId) {
      await queryClient.fetchQuery({ queryFn: getDraftRequests, queryKey: 'workflow-request-drafts' });
    }

    if (isRenewalInitiative && subscriptionId) {
      invalidateRenewalReminder(queryClient, subscriptionId);
      queryClient.invalidateQueries(['subscription', subscriptionId]);
    }

    const requestDetails = await queryClient.fetchQuery('workflow-request-details', () => getRequestDetails(data.id));

    const alternativeTools: string[] =
      requestDetails?.childRequests?.map(({ vendorName }) => vendorName).slice(1) || [];

    trackWorkflowRequestCreated({
      alternativeTools,
      companyId: company?.id,
      companyName: company?.name,
      hasGenericDocuments: Boolean(requestDetails?.documents?.length),
      isMultiTool: Boolean(alternativeTools?.length),
      name: requestDetails.name,
      numberOfContributors: requestDetails?.contributors?.length || 0,
      requestType: isRenewalInitiative ? 'renewal' : 'newPurchase',
      toolName: requestDetails?.vendorName,
      totalNumberOfTools: requestDetails?.childRequests?.length || 0,
      totalStages: requestDetails?.timeline.length || 0,
    });

    openDialog({
      confirmBtnText: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.button_initiative_text'
      ),
      content: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_text'
      ),
      icon: (
        <Icon color='success' sx={{ mr: 1 }}>
          check_circle_outline
        </Icon>
      ),
      onConfirm: () => {
        onAddRequestDrawer(`${isRenewalInitiative ? includedHash : ''}#${DRAWER_VARIANT.REQUEST_DETAILS}${data.id}`);
      },
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_title'
      ),
    });
  };

  const onModifyDraftSuccess = async () => {
    await queryClient.fetchQuery({ queryFn: getDraftRequests, queryKey: 'workflow-request-drafts' });

    showNotification?.(
      t('requests_view:modify_initiative_drawer.wizard.dialog.draft.title'),
      NotificationAlertType.Success
    );
    replace('/requests?tab=draftRequests');
    toggleDrawer();
  };

  const { isLoading: isCreateLoading, mutate: mutateCreate } = useMutation(
    (payload: RequestPayload) => createNewRequest(payload),
    {
      onError: (err: AxiosError) => onModifyError(err),
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { isLoading: isEditLoading, mutate: mutateEdit } = useMutation(
    (payload: RequestPayload) => editRequest(payload),
    {
      onError: (err: AxiosError) => onModifyError(err),
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { isLoading: isCreateDraftLoading, mutate: mutateCreateDraft } = useMutation(
    (payload: { id?: number; name?: string; requestData: any }) => createDraft(payload),
    {
      onError: (err: AxiosError) => onModifyError(err, 'draft'),
      onSuccess: onModifyDraftSuccess,
    }
  );

  const { isLoading: isUpdateDraftLoading, mutate: mutateUpdateDraft } = useMutation(
    (payload: { id?: number; name?: string; requestData: any }) => updateDraft(payload),
    {
      onError: (err: AxiosError) => onModifyError(err, 'draft'),
      onSuccess: onModifyDraftSuccess,
    }
  );

  useEffect(() => {
    if (isProcurementSupportEnabled && !isEditMode && !isDraftInitiative) setRenderedScreen(RenderedScreen.MANAGER);
    else setRenderedScreen(RenderedScreen.WIZARD);
  }, [isProcurementSupportEnabled, isEditMode, isDraftInitiative]);

  const handleDocumentsUpload = async (): Promise<CompanyDocument[]> => {
    setIsDocumentsUploadInProgress(true);

    const uploads: unknown[] = [];
    const files = isStartInitiativeStep ? defaultFormRef?.current?.getDefaultForm().files : defaultForm.files;
    const category = getCompanyDocumentCategory(DocumentCategory.other_documents);
    const companyId = String(company?.id);

    files.forEach((file: File) => {
      const payload = {
        category,
        companyId,
        file,
        isCompanyDocument: true,
      };

      uploads.push(uploadDocumentAsync(payload));
    });

    return (await Promise.all(uploads)) as CompanyDocument[];
  };

  const handleOnGoBack = () => {
    if (isShareMoreDetailsStep) {
      goOneStepBackAndMarkStepAsUncompleted?.();
      setCustomFormError?.(false);
    }
  };

  const isValidStartStep = (): boolean => {
    defaultFormRef?.current?.trigger();
    const isValid = defaultFormRef?.current?.isValid;
    const toolOwnerName = defaultFormRef?.current?.toolOwnerName;
    const setToolOwnerError = defaultFormRef?.current?.setToolOwnerError;

    if (!initiativeName) setInitiativeNameError?.(true);
    if (!toolOwnerName) setToolOwnerError(true);
    if (!isEditMode && (!initiativeName || !toolOwnerName)) return false;
    if (!isValid) return false;

    return true;
  };

  const getCustomFormData = (): CustomFormData => {
    let definition: any;
    const data = customForm?.data;

    if (!isEditMode && workflow) {
      definition = workflow.customFormDefinition;
    }

    if (isEditMode && fetchedData) {
      definition = fetchedData?.customFormData?.definition;
    }

    return { data, definition };
  };

  const getTools = async (tools: (string | VendorType)[]): Promise<(string | VendorType)[]> => {
    const newTools = await Promise.all(
      tools.map(async (tool) => {
        if (typeof tool === 'string' && Boolean(tool.trim())) {
          const vendors = await fetchVendors(tool);
          const vendor = vendors.find((vendor) => vendor.name === tool);

          return vendor || tool;
        }
        return tool;
      })
    );

    return newTools;
  };

  const getCreateInitiativePayload = async () => {
    const formValues = isStartInitiativeStep ? defaultFormRef?.current?.getValues() : defaultForm?.form;
    const toolOwner = isStartInitiativeStep
      ? defaultFormRef?.current?.getDefaultForm().toolOwner
      : defaultForm?.toolOwner;

    const mainTool = isRenewalInitiative ? formValues.toolName : formValues.mainTool;
    const documents = !isEditMode ? await handleDocumentsUpload() : [];

    const fetchedTools = await getTools([mainTool, ...formValues.alternativeTools]);
    const tools = fetchedTools
      .filter((tool) => (typeof tool === 'string' ? Boolean(tool.trim()) : Boolean(tool?.id)))
      .map((tool) => {
        if (typeof tool !== 'string') {
          return !Number.isNaN(Number(tool?.id)) ? { vendorId: Number(tool?.id) } : { toolName: tool?.id };
        }

        return { toolName: tool };
      });
    let subscriptionId = '';

    if (isRenewalInitiative)
      subscriptionId = subscriptions?.find(({ name }: any) => mainTool?.label === name)?.id || '';

    return {
      multiToolParentRequest: true,
      ...(initiativeName && { name: initiativeName }),
      ...(isEditMode && { id: Number(requestId || 0) || undefined }),
      contributors: formValues?.collaborators?.map(({ id }: any) => Number(id)) || [],
      description: formValues.description,
      documents,
      dueDate: formValues.dueDate
        ? format(new Date(formValues.dueDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
      licenseCurrency: company?.currency || '',
      ...(formValues.cost && { licenseCents: Number(formValues.cost) * 100 }),
      ...(formValues.numberOfLicenses && { licenseNumber: Number(formValues.numberOfLicenses) }),
      ...(formValues.paymentFrequency && { licensePaymentFrequency: formValues.paymentFrequency.id }),
      requestWorkflowTypeId: isRenewalInitiative ? REQUEST_WORKFLOW_TYPE.RENEWAL : REQUEST_WORKFLOW_TYPE.APPROVAL,
      steps: [
        {
          stepOwnerId: Number(toolOwner.value) || Number(toolOwner.id),
        },
      ],
      tools,
      ...(!isEditMode && isRenewalInitiative && { subscriptionId: Number(subscriptionId) }),
      ...(isShareMoreDetailsStep && { customFormData: getCustomFormData() }),
      ...(draftId && { request_draft_id: Number(draftId) }),
    };
  };

  const validateCustomForm = (): boolean => {
    const formDefinition = isEditMode ? fetchedData?.customFormData?.definition : workflow?.customFormDefinition;
    const customFormData = customForm?.data;

    let validated = true;

    if (formDefinition && customFormData) {
      const errors = Object.keys(customFormData).filter((key): any => {
        const field = (formDefinition as any).components.find((component: any) => component.key === key);

        return field?.validate?.required && !customFormData?.[key];
      });

      validated = !errors.length;
    }

    return validated;
  };

  const handleContinueAndSubmit = async () => {
    const isCustomFormValid = validateCustomForm();

    if (isStartInitiativeStep && !isValidStartStep()) return;
    if (isShareMoreDetailsStep && !isCustomFormValid) return setCustomFormError?.(true);

    if (isStartInitiativeStep && stepper.isVisible) {
      const formValues = defaultFormRef?.current?.getDefaultForm();

      setDefaultFormData?.(formValues || null);
      setActiveStep?.(Steps.ShareMoreDetails);
      completePreviousStep?.();
    } else {
      const payload = await getCreateInitiativePayload();

      return isEditMode ? mutateEdit(payload) : mutateCreate(payload);
    }
  };

  const handleModifyDraft = () => {
    if (!initiativeName) setInitiativeNameError?.(true);
    if (!initiativeName) return false;

    const defForm = isStartInitiativeStep ? defaultFormRef?.current?.getDefaultForm() : defaultForm;

    defForm.files = [];

    if (isRenewalInitiative) {
      defForm.form.toolName = defForm.form.toolName
        ? { id: defForm.form.toolName.id, label: defForm.form.toolName.label }
        : null;
    }

    const requestData = {
      customForm: customForm
        ? { data: customForm.data, ...(typeof customForm.isValid === 'boolean' && { isValid: customForm.isValid }) }
        : null,
      defaultForm: defForm,
      initiativeName,
      selectedInitiativeType,
      workflow,
    };

    const payload = {
      ...(!!draftId && { id: Number(draftId) }),
      name: initiativeName,
      requestData,
    };

    return draftId ? mutateUpdateDraft(payload) : mutateCreateDraft(payload);
  };

  const onCardClick = (card: 'wizard' | 'manager') => {
    const getLocation = (pathname: string) => {
      let location: 'Dashboard' | 'Initiatives' | 'Tool store' | 'Tool details' | 'Tool initiative tab' = 'Initiatives';

      if (pathname.includes('overview')) location = 'Dashboard';
      if (pathname.includes('tool-stack')) location = 'Tool initiative tab';
      if (pathname.includes('tool-details')) location = 'Tool details';
      if (pathname.includes('tool-store')) location = 'Tool store';

      return location;
    };

    const eventData = {
      companyId: company?.id,
      companyName: company?.name,
      location: getLocation(pathname),
    };

    if (card === 'manager') {
      trackInitiativesStepSelected({ ...eventData, action: 'Request support' });
      toggleDrawer();
      push('/procurement-support?state=involve_sastrify', { from: `${pathname}${search}${hash}` });
    }

    if (card === 'wizard') {
      trackInitiativesStepSelected({ ...eventData, action: 'Start an initiative' });
      setRenderedScreen(RenderedScreen.WIZARD);
    }
  };

  const isFormReady = (!isEditMode && workflow) || (isEditMode && fetchedData);

  const renderHeaderComponent = () => {
    return isProcurementSupportEnabled ? (
      <Header isProcurementSupportEnabled={isProcurementSupportEnabled}>
        <Typography variant='h2' mb={2}>
          {t('requests_view:modify_initiative_drawer.wizard.header_section.title')}
        </Typography>
        <Typography variant='subtitle1' mb={stepper.isVisible ? 2 : 0}>
          {t('requests_view:modify_initiative_drawer.wizard.header_section.description')}
        </Typography>
        {stepper.isVisible && isFormReady && (
          <Box mx={-1}>
            <Stepper activeStep={stepper.activeStep} steps={stepper.steps} />
          </Box>
        )}
      </Header>
    ) : (
      stepper.isVisible && isFormReady && (
        <Header isProcurementSupportEnabled={isProcurementSupportEnabled}>
          <Stepper activeStep={stepper.activeStep} steps={stepper.steps} />
        </Header>
      )
    );
  };

  const renderFooterComponent = () =>
    isProcurementSupportEnabled ? (
      <Footer isProcurementSupportEnabled={isProcurementSupportEnabled}>
        <Typography variant='body' color='textSecondary' component='p' py={2}>
          {t('requests_view:modify_initiative_drawer.wizard.footer_section.helper_text')}
        </Typography>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box>
            {isStartInitiativeStep && !isEditMode && !isDraftInitiative && (
              <Button variant='text' color='secondary' onClick={() => setRenderedScreen(RenderedScreen.MANAGER)}>
                {t('requests_view:modify_initiative_drawer.wizard.footer_section.back_button_text')}
              </Button>
            )}
            {isShareMoreDetailsStep && (
              <Button startIcon={<Icon>{BACK_ICON}</Icon>} variant='text' color='secondary' onClick={handleOnGoBack}>
                {t('requests_view:modify_initiative_drawer.wizard.footer_section.go_back_button_text')}
              </Button>
            )}
          </Box>
          <Stack direction='row' spacing={2}>
            {isEditMode ? (
              <Button
                data-testid='cancel-button'
                variant='text'
                color='secondary'
                disabled={isCreateDraftLoading || isUpdateDraftLoading || isToolBusy}
                onClick={() => push(`${pathname}${search}#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`)}>
                {t('requests_view:modify_initiative_drawer.wizard.footer_section.cancel')}
              </Button>
            ) : (
              <Button
                data-testid='save-draft-button'
                variant='text'
                color='secondary'
                disabled={isCreateDraftLoading || isUpdateDraftLoading || isToolBusy}
                onClick={handleModifyDraft}>
                {t('requests_view:modify_initiative_drawer.wizard.footer_section.save_as_draft_button_text')}
              </Button>
            )}
            <Button
              data-testid={isStartInitiativeStep && stepper.isVisible ? 'continue-button' : 'submit-button'}
              variant='contained'
              onClick={handleContinueAndSubmit}
              disabled={isDocumentsUploadInProgress || isCreateLoading || isEditLoading || isToolBusy}>
              {isStartInitiativeStep && stepper.isVisible
                ? t('requests_view:modify_initiative_drawer.wizard.footer_section.continue_button_text')
                : t('requests_view:modify_initiative_drawer.wizard.footer_section.submit_button_text')}
            </Button>
          </Stack>
        </Box>
      </Footer>
    ) : (
      <Footer>
        <Box>
          {isShareMoreDetailsStep && (
            <Button startIcon={<Icon>{BACK_ICON}</Icon>} variant='text' color='secondary' onClick={handleOnGoBack}>
              {t('requests_view:modify_initiative_drawer.wizard.footer_section.go_back_button_text')}
            </Button>
          )}
        </Box>
        <Stack direction='row' spacing={2}>
          {isEditMode ? (
            <Button
              data-testid='cancel-button'
              variant='text'
              color='secondary'
              disabled={isCreateDraftLoading || isUpdateDraftLoading || isToolBusy}
              onClick={() => push(`${pathname}${search}#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`)}>
              {t('requests_view:modify_initiative_drawer.wizard.footer_section.cancel')}
            </Button>
          ) : (
            <Button
              data-testid='save-draft-button'
              variant='text'
              color='secondary'
              disabled={isCreateDraftLoading || isUpdateDraftLoading || isToolBusy}
              onClick={handleModifyDraft}>
              {t('requests_view:modify_initiative_drawer.wizard.footer_section.save_as_draft_button_text')}
            </Button>
          )}
          <Button
            data-testid={isStartInitiativeStep && stepper.isVisible ? 'continue-button' : 'submit-button'}
            variant='contained'
            onClick={handleContinueAndSubmit}
            disabled={isDocumentsUploadInProgress || isCreateLoading || isEditLoading || isToolBusy}>
            {isStartInitiativeStep && stepper.isVisible
              ? t('requests_view:modify_initiative_drawer.wizard.footer_section.continue_button_text')
              : t('requests_view:modify_initiative_drawer.wizard.footer_section.submit_button_text')}
          </Button>
        </Stack>
      </Footer>
    );

  const renderWizard = () => (
    <>
      {renderHeaderComponent()}
      <Content isStepperVisible={stepper.isVisible} isProcurementSupportEnabled={isProcurementSupportEnabled}>
        {isStartInitiativeStep && <CraftInitiativeDefaultForm defaultFormRef={defaultFormRef} />}
        {isShareMoreDetailsStep && <CraftInitiativeCustomForm />}
      </Content>
      {renderFooterComponent()}
    </>
  );

  const renderInternalProcessManager = () => (
    <>
      <Header isProcurementSupportEnabled={isProcurementSupportEnabled}>
        <Typography variant='h2' mb={2}>
          {t('requests_view:modify_initiative_drawer.manager.header_title')}
        </Typography>
        <Typography variant='subtitle1' mb={stepper.isVisible ? 2 : 0}>
          {t('requests_view:modify_initiative_drawer.manager.header_description')}
        </Typography>
      </Header>
      <Content isProcurementSupportEnabled={isProcurementSupportEnabled}>
        <Typography variant='h3' mt={2} mb={3}>
          {t('requests_view:modify_initiative_drawer.manager.content_title')}
        </Typography>
        <ClickableCard my={4} onClick={() => onCardClick('wizard')}>
          <img alt='rocket' src={rocketImage} />
          <Box p={1}>
            <Typography variant='section' my={1}>
              {t('requests_view:modify_initiative_drawer.manager.start_initiative_title')}
            </Typography>
            <Typography variant='body' my={1}>
              {t('requests_view:modify_initiative_drawer.manager.start_initiative_description')}
            </Typography>
          </Box>
        </ClickableCard>
        <ClickableCard my={4} onClick={() => onCardClick('manager')}>
          <img alt='sastrify' src={sastrifyImage} />
          <Box p={1}>
            <Typography variant='section' my={1}>
              {t('requests_view:modify_initiative_drawer.manager.request_support_title')}
            </Typography>
            <Typography variant='body' my={1}>
              {t('requests_view:modify_initiative_drawer.manager.request_support_description')}
            </Typography>
          </Box>
        </ClickableCard>
      </Content>
    </>
  );

  return (
    <Wrapper>
      {renderedScreen === RenderedScreen.MANAGER && renderInternalProcessManager()}
      {renderedScreen === RenderedScreen.WIZARD && renderWizard()}
    </Wrapper>
  );
};

export const DrawerWorkflowInitiativeWizard: React.FC<DrawerWorkflowInitiativeWizardProps> = (props) => (
  <WorkflowInitiativeWizardContextProvider>
    <DrawerWorkflowInitiativeWizardComponent {...props} />
  </WorkflowInitiativeWizardContextProvider>
);
