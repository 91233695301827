import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type SearchFilters = {
  [key: string]: string | boolean | number;
};

/**
 * Hook to persist search parameters, sort and filters in url query params and optionally in a (local) storage
 * @param storageKey - key to store the search parameters, sort and filters in (local) storage, null if you don't want to store it
 * @param pathName - pathname of the page that you want to persist the search parameters, sort and filters in (local) storage
 * @param initialState - initial state of the search parameters, sort and filters
 * @param serialize - function to serialize the search parameters, sort and filters
 * @param deserialize - function to deserialize the search parameters, sort and filters
 * @returns - tuple of search parameters, sort and filters, and a function to set the search parameters, sort and filters
 */

export const usePersistentSearchFilters = <T extends SearchFilters>(
  storageKey: string | null,
  pathName: string,
  initialState: T,
  serialize: (state: T) => string,
  deserialize: (state: string) => T
): [T, (state: T) => void] => {
  const history = useHistory();
  const { hash, search } = useLocation();
  const persistedSearch = storageKey ? JSON.parse(`${localStorage.getItem(storageKey)}`) : null;
  const searchParams = search || persistedSearch;
  const shouldPersistPageFilters = !!storageKey;
  const existingSearchParams = Object.fromEntries(new URLSearchParams(searchParams || '')) as unknown as SearchFilters;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_searchFilters, _setSearchFilters] = useState<T>(
    Object.keys(existingSearchParams).length !== 0 ? deserialize(searchParams) : initialState
  );

  const setSearchFilters = useCallback(
    (searchFilters: T) => {
      _setSearchFilters(searchFilters);
      const serializedSearch = serialize(searchFilters);

      if (shouldPersistPageFilters) {
        localStorage.setItem(storageKey, JSON.stringify(serializedSearch));
      }

      history.replace({ hash: hash || '', pathname: pathName, search: serializedSearch }); // FIXME: this is causing double renders and then the other useEffect is causing another render
    },
    [hash, history, pathName, serialize, shouldPersistPageFilters, storageKey]
  );

  useEffect(() => {
    if (shouldPersistPageFilters && persistedSearch && pathName) {
      history.replace({ hash: hash || '', pathname: pathName, search: search || persistedSearch }); // FIXME: This is triggering several whole page renders
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [_searchFilters, setSearchFilters];
};
