import { Box, Stack } from '@mui/material';
import { FilesListComponent } from 'components/drawer-workflow-request/components/files-list/files-list.component';
import { benchmarkGoalsMapping } from 'components/drawer-workflow-request-details/components/contact-sastrify-form/contact-sastrify-form.constants';
import { DetailsItem } from 'components/drawer-workflow-request-details/components/details-item/details-item.component';
import { formatText } from 'components/drawer-workflow-request-details/components/details-item/details-item.utils';
import { FileUploadComponent } from 'components/drawer-workflow-request-details/components/file-upload/file-upload.component';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { handleFileDownload } from 'shared/logic/requests.logic';
import { InitiativeUploadDocumentsProps, RenewalRequestBenchmarkProps } from 'shared/models/request-workflow.model';

export const RenewalRequestBenchmarkDetails: FC<RenewalRequestBenchmarkProps & InitiativeUploadDocumentsProps> = ({
  benchmarkDetails,
  benchmarkGoal,
  changeInPlans,
  commitmentLengthValue,
  companyId,
  documents,
  id,
  newBenchmarkVolume,
  preferredCommitmentLength,
  preferredPaymentCycle,
  refetchData,
  showNotification,
  uploadFunction,
}) => {
  const { t } = useTranslation();

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.renewal_involvement_details.renewal_request_benchmark_labels';

  const withExtraFields = benchmarkGoal === benchmarkGoalsMapping.what_is_good_price_new_requirements;

  return (
    <>
      <Stack component='div' direction='row' spacing={5} alignItems='flex-start' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.document_upload_label`)}
            content={
              <>
                {documents && documents.length > 0 ? (
                  <FilesListComponent files={documents || []} onDownload={handleFileDownload} />
                ) : (
                  '-'
                )}
                <FileUploadComponent
                  showNotification={showNotification}
                  companyId={companyId}
                  referenceId={Number(id)}
                  uploadFunction={uploadFunction}
                  refetchData={refetchData}
                />
              </>
            }
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem title={t(`${pathToTranslation}.request_goal`)} content={formatText(benchmarkGoal || '-')} />
        </Stack>
      </Stack>
      {withExtraFields && (
        <>
          <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
            <Stack flex={1} pr={5}>
              <DetailsItem
                title={t(`${pathToTranslation}.change_in_plans`)}
                content={formatText(changeInPlans || '-')}
              />
            </Stack>
            <Stack flex={1}>
              <DetailsItem
                title={t(`${pathToTranslation}.benchmark_volume`)}
                content={formatText(newBenchmarkVolume || '-')}
              />
            </Stack>
          </Stack>

          <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
            <Stack flex={1} pr={5}>
              <DetailsItem
                title={t(`${pathToTranslation}.preferred_commitment_length`)}
                content={
                  commitmentLengthValue && preferredCommitmentLength
                    ? `${commitmentLengthValue} ${preferredCommitmentLength}`
                    : '-'
                }
              />
            </Stack>
            <Stack flex={1}>
              <DetailsItem
                title={t(`${pathToTranslation}.preferred_payment_cycle`)}
                content={formatText(preferredPaymentCycle || '-')}
              />
            </Stack>
          </Stack>
        </>
      )}
      <Box mb={1}>
        <DetailsItem title={t(`${pathToTranslation}.other_details`)} content={formatText(benchmarkDetails || '-')} />
      </Box>
    </>
  );
};
