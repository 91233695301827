import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tabs } from 'asteroids';

export const StyledBadge = styled(Badge)(() => ({
  '.MuiBadge-badge': { right: '-6px', top: '-2px' },
}));

export const TabsWithBottomBorder = styled(Tabs)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 4, 0, 4),
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
}));
