import React, { FC } from 'react';
import { colors } from 'shared/theme';

export const TagIcon: FC<React.SVGProps<SVGElement>> = ({ fill }) => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.46876 2.62501C3.30188 2.62501 3.13875 2.67449 3 2.76721C2.86124 2.85992 2.7531 2.9917 2.68924 3.14587C2.62537 3.30005 2.60867 3.4697 2.64122 3.63337C2.67378 3.79704 2.75414 3.94738 2.87214 4.06538C2.99014 4.18338 3.14048 4.26374 3.30415 4.2963C3.46782 4.32885 3.63747 4.31214 3.79165 4.24828C3.94582 4.18442 4.0776 4.07628 4.17031 3.93752C4.26302 3.79877 4.31251 3.63564 4.31251 3.46876C4.31251 3.24498 4.22361 3.03037 4.06538 2.87214C3.90715 2.7139 3.69254 2.62501 3.46876 2.62501V2.62501ZM11.13 5.27438L6.39938 0.538134C6.34682 0.486001 6.28449 0.444756 6.21596 0.416763C6.14742 0.38877 6.07404 0.374581 6.00001 0.375009H0.937509C0.788325 0.375009 0.645251 0.434273 0.539762 0.539762C0.434273 0.645251 0.375009 0.788325 0.375009 0.937509V6.00001C0.374581 6.07404 0.38877 6.14742 0.416763 6.21596C0.444756 6.28449 0.486001 6.34682 0.538134 6.39938L5.27438 11.13C5.59079 11.446 6.0197 11.6235 6.46688 11.6235C6.91407 11.6235 7.34298 11.446 7.65938 11.13L11.13 7.68751C11.446 7.3711 11.6235 6.9422 11.6235 6.49501C11.6235 6.04782 11.446 5.61892 11.13 5.30251V5.27438ZM10.3369 6.86063L6.86063 10.3313C6.75524 10.436 6.61268 10.4948 6.46407 10.4948C6.31547 10.4948 6.1729 10.436 6.06751 10.3313L1.50001 5.76938V1.50001H5.76938L10.3369 6.06751C10.389 6.12007 10.4303 6.1824 10.4583 6.25094C10.4862 6.31947 10.5004 6.39286 10.5 6.46688C10.4994 6.61445 10.4408 6.75586 10.3369 6.86063V6.86063Z'
        fill={fill}
      />
    </svg>
  );
};

TagIcon.defaultProps = {
  fill: colors.white,
};
