import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Icon } from 'asteroids';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { DocumentCategory, FileMimeTypesAllowList } from 'shared/common.definitions';
import { getCompanyDocumentCategory } from 'shared/logic/subscription-item.logic';
import { RequestDocument, RequestInitiativeDocument } from 'shared/models';

import { FileInput } from './icon-upload.component.styles';
import { IconUploadComponentProps } from './icon-upload.component.types';

export const IconUploadComponent: React.FC<IconUploadComponentProps> = ({
  companyId,
  expandedTools,
  referenceId,
  refetchData,
  setExpanded,
  showNotification,
  tool,
  tooltip,
  uploadFunction,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isUploading, setIsUploading] = useState(false);

  const pathToSubscriptionDocumentTab = 'subscription_detail_view:tabs_component_section.subscription_document_tab';
  const documentUploadCategory = getCompanyDocumentCategory(DocumentCategory.other_documents);

  const { mutateAsync: uploadDocument } = useMutation(uploadFunction, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      setIsUploading(false);
    },
  });

  const handleOnButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFilesUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { files } = event.currentTarget;
    if (files) {
      const numberOfFiles = files.length;
      const filesArray: (File | null)[] = [];

      for (let i = 0; i < numberOfFiles; i++) {
        if (!FileMimeTypesAllowList.includes(files.item(i)?.type as string)) {
          showNotification?.(
            t(`${pathToSubscriptionDocumentTab}.upload_file_type_error_message`),
            NotificationAlertType.Error
          );
        } else {
          const blob = files.item(i);

          if (blob) {
            const file = new File([blob], blob.name.replace(/(<([^>]+)>)/gi, ''), { type: blob.type });
            filesArray.push(file);
          }
        }
      }

      if (filesArray.length > 0) {
        const uploads: Promise<RequestDocument | RequestInitiativeDocument>[] = [];

        filesArray.forEach((file: File | null) => {
          if (file && companyId) {
            uploads.push(
              uploadDocument({
                category: documentUploadCategory,
                companyId,
                file,
                referenceId,
              })
            );
          }
        });

        setIsUploading(true);
        await Promise.all(uploads);
        await refetchData();

        const isToolExpanded = expandedTools?.includes(String(tool.requestId));

        if (expandedTools && !isToolExpanded) {
          setExpanded?.(String(tool.requestId))(event, true);
        }

        setIsUploading(false);
      }
    }
  };

  return (
    <Box>
      <Tooltip title={tooltip || ''}>
        <IconButton
          id={`tool-${tool.requestId}-upload`}
          data-testid='upload-new-files-button'
          onClick={handleOnButtonClick}>
          {isUploading ? <CircularProgress color='inherit' size={24} /> : <Icon>attach_file</Icon>}
        </IconButton>
      </Tooltip>

      <FileInput
        data-testid='upload-new-files-input'
        ref={fileInputRef}
        type='file'
        value=''
        multiple
        onChange={handleFilesUpload}
      />
    </Box>
  );
};
