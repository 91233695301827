import { AxiosResponse } from 'axios';
import { ERROR_STATUS } from 'libs/constants/failed-invoice.status';
import { INVOICE_DOCUMENT_TYPE } from 'libs/enums/invoice-import.enum';
import { SOURCE_SYSTEM } from 'libs/enums/source-system.enum';

import { FailedInvoiceData, FixInvoicePayload } from '../models/subscription-spend-and-invoices.model';
import { apiService, apiUrl } from '../services';

export function fetchFailedInvoiceData(subscriptionId: string, documentId: string): Promise<FailedInvoiceData> {
  return apiService.get(apiUrl.getFailedInvoiceData(subscriptionId, documentId)).then((response: AxiosResponse) => {
    return response.data as FailedInvoiceData;
  });
}

export const fetchBatchFailedInvoiceData = async (
  subscriptionId: string,
  documentIds: string[]
): Promise<FailedInvoiceData[]> => {
  const response = await apiService.post(apiUrl.getBulkFailedInvoiceData(subscriptionId), { documentIds });
  return response.data;
};

export async function fixFailedInvoice(
  subscriptionId: string,
  documentId: string,
  invoiceDataPayload: FixInvoicePayload,
  resolveInvoice: boolean,
  documentType: INVOICE_DOCUMENT_TYPE = INVOICE_DOCUMENT_TYPE.SUBSCRIPTION_DOCUMENT
): Promise<boolean> {
  let response;

  if (documentType === INVOICE_DOCUMENT_TYPE.COMPANY_DOCUMENT) {
    response = await apiService.post(
      `${apiUrl.fixFailedCompanyInvoice(documentId)}?resolveInvoice=${resolveInvoice}`,
      invoiceDataPayload
    );
  } else if (documentType === INVOICE_DOCUMENT_TYPE.SUBSCRIPTION_DOCUMENT) {
    response = await apiService.post(
      `${apiUrl.fixFailedSubscriptionInvoice(subscriptionId, documentId)}?resolveInvoice=${resolveInvoice}`,
      invoiceDataPayload
    );
  }

  return response?.data;
}

export async function markFailedInvoiceAsArchived(sourceSystem: SOURCE_SYSTEM, sourceId: string): Promise<boolean> {
  const response = await apiService.patch(apiUrl.changeFailedInvoiceStatus(sourceSystem, sourceId), {
    status: ERROR_STATUS.ARCHIVED,
  });

  return response.status === 200;
}
