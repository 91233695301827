import { FormControlLabel, FormGroup, Switch as MuiSwitch } from '@mui/material';
import React from 'react';

import { SwitchProps } from './switch.component.props';

export const Switch: React.FC<SwitchProps> = ({ disabled, label, size = 'small', ...rest }) => {
  const switchSize = size === 'large' ? 'medium' : size;

  return label ? (
    <FormGroup>
      <FormControlLabel
        control={<MuiSwitch key='mui-switch' size={switchSize} {...rest} />}
        disabled={disabled}
        label={label}
      />
    </FormGroup>
  ) : (
    <MuiSwitch size={switchSize} disabled={disabled} {...rest} />
  );
};

export default Switch;
