/* eslint-disable no-restricted-syntax */
import { Box } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { FC, useMemo } from 'react';
import { colors } from 'shared/theme';

import { PointSymbol, TimeLineChartConfig, TimeLineChartProps } from './time-line-chart-props';

export const TimeLineChart: FC<TimeLineChartProps> = (props: TimeLineChartProps) => {
  const { data, lineColors, xAxisFormat, xAxisTimeSpan, xAxisTimeUnit } = props;

  const lineChartConfig: TimeLineChartConfig = useMemo(() => {
    let minY = Infinity;
    let maxY = -Infinity;
    const tickValues = `every ${xAxisTimeUnit} ${xAxisTimeSpan}`;

    for (const item of data) {
      if (item?.data && item.data.length === 0) {
        return {
          showChart: false,
        };
      }
      for (const datum of item.data) {
        const { y } = datum;
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
      }
    }

    // fine tuning Y axis
    const difference = maxY - minY;

    if (difference === 0) {
      minY = 0;
      maxY += maxY;
    }
    if (difference <= 10) {
      minY -= 10;
      maxY += 10;
    }

    minY = minY - difference < 0 ? 0 : minY - difference;
    maxY += difference;

    return {
      colors: lineColors,
      maxY,
      minY,
      showChart: true,
      tickValues,
      xFormat: xAxisFormat,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderPointSymbol = (props: PointSymbol): JSX.Element => {
    const { borderColor, borderWidth, color, size } = props;
    const outerCircleRadius = size / 2;
    const innerCircleRadius = outerCircleRadius * 0.55;

    return (
      <g transform={`translate(-${outerCircleRadius}, -${outerCircleRadius})`}>
        <circle
          cx={outerCircleRadius}
          cy={outerCircleRadius}
          r={outerCircleRadius}
          fill={colors.white}
          strokeWidth={borderWidth}
          stroke={color}
        />
        <circle
          cx={outerCircleRadius}
          cy={outerCircleRadius}
          r={innerCircleRadius}
          fill={borderColor}
          strokeWidth={borderWidth}
          stroke={color}
        />
      </g>
    );
  };

  return lineChartConfig?.showChart ? (
    <Box height={480}>
      <ResponsiveLine
        animate
        axisBottom={{
          format: '%b %d',
          tickPadding: 16,
          tickSize: 0,
          tickValues: lineChartConfig.tickValues,
        }}
        xFormat={`time:${lineChartConfig.xFormat}`}
        xScale={{
          format: lineChartConfig.xFormat,
          precision: 'day',
          type: 'time',
          useUTC: false,
        }}
        margin={{
          bottom: 32,
          left: 56,
          right: 56,
          top: 24,
        }}
        colors={lineChartConfig.colors}
        theme={{
          background: colors.white,
        }}
        curve='monotoneX'
        data={data}
        enablePointLabel
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['brighter', 1.5]],
        }}
        pointSymbol={renderPointSymbol}
        enablePoints
        pointSize={20}
        pointLabelYOffset={-16}
        yScale={{
          max: lineChartConfig.maxY,
          min: lineChartConfig.minY,
          type: 'linear',
        }}
        axisLeft={{
          tickPadding: 16,
          tickSize: 0,
        }}
      />
    </Box>
  ) : null;
};
