import { Box, Button, Typography } from '@mui/material';
import error403 from 'assets/images/errors/error-403.svg';
import error404 from 'assets/images/errors/error-404.svg';
import error500 from 'assets/images/errors/error-500.svg';
import { NavigateToUrlLink } from 'asteroids';
import { Page } from 'components-2.0/page';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DrawerInfoHashes, DrawerInfoType } from 'shared/common.definitions';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { ErrorViewProps } from './error.view.props';

export const ErrorView: React.FC<ErrorViewProps> = ({ code = 'unexpected', handleTryAgainClick }: ErrorViewProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const showTryAgainbutton = !!handleTryAgainClick;
  let errorImage;
  let withLinkToSupport = false;

  switch (code) {
    case 403:
      errorImage = error403;
      break;
    case 404:
      errorImage = error404;
      break;
    case 500:
    default:
      errorImage = error500;
      withLinkToSupport = true;
  }

  const renderDescriptionSecondLine = () => {
    return (
      <Typography sx={{ color: 'text.primary' }} variant='body1' data-testid='error-description-second-line'>
        {t(`routes:error_${code}:description_second_line`)}
        <NavigateToUrlLink
          href={`${NAVIGATION_URLS.SASTRIFY_SUPPORT}${DrawerInfoHashes[DrawerInfoType.SASTRIFY_APP_HELP]}`}
          icon={false}
          data-testid='error-support-link'>
          {t(`routes:error_${code}:support_link_label`)}.
        </NavigateToUrlLink>
      </Typography>
    );
  };

  return (
    <Page>
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: 4, width: '100%' }}>
        <img
          src={errorImage}
          alt={t(`routes:error_${code}:image_alternative_text`)}
          data-testid='image-alternative-text'
        />
        <Typography
          sx={{ color: 'text.primary', marginBottom: 2, marginTop: 2 }}
          variant='h3'
          data-testid='error-title'>
          {t(`routes:error_${code}:title`)}
        </Typography>
        <Typography sx={{ color: 'text.primary' }} variant='body1' data-testid='error-description'>
          {showTryAgainbutton
            ? t(`routes:error_${code}:description_retriable_error_first_line`)
            : t(`routes:error_${code}:description`)}
        </Typography>
        {withLinkToSupport && renderDescriptionSecondLine()}
        {showTryAgainbutton && (
          <Button
            onClick={handleTryAgainClick}
            variant='contained'
            color='primary'
            sx={{ marginBottom: 0.5, marginTop: 4 }}
            data-testid='try-again-button'>
            {t('routes:try_again_button_label')}
          </Button>
        )}
        <Button
          onClick={() => history.push('/')}
          sx={{ marginTop: showTryAgainbutton ? 0 : 4 }}
          variant='text'
          color='secondary'
          data-testid='back-button'>
          {t('routes:back_button_label')}
        </Button>
      </Box>
    </Page>
  );
};

export default ErrorView;
