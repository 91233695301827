import { SOURCE_NAME, SsoIntegrationToolName, TYPE } from 'libs/constants/tool-usage.constants';
import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export interface BaseUsageAnalyticsTrackingParams {
  companyId: string;
  userId: string;
  analyticsProviderName: string | null;
  analyticsProviderType: TYPE;
  analyticsSourceName: SOURCE_NAME | null;
}

export interface SSOForDiscoveryTrackingParams {
  sourceName: SsoIntegrationToolName;
  companyId: string;
  userId: string;
}

export interface SSOForDiscoveryErrorTrackingParams extends SSOForDiscoveryTrackingParams {
  errorMessage: string;
}

export interface SubscriptionUsageAnalyticsTrackingParams extends BaseUsageAnalyticsTrackingParams {
  subscriptionId: string;
  toolName: string;
  isDirectAnalyticsAvailable: boolean;
}

export enum UsageAnalyticsEventAction {
  CONNECT = 'CONNECT',
  RECONNECT = 'RECONNECT',
  DISCONNECT = 'DISCONNECT',
  VIEW = 'VIEW',
}

export interface ConnectionsTrackingParams extends BaseUsageAnalyticsTrackingParams {
  action: UsageAnalyticsEventAction;
  actionOrigin: string;
  error: boolean;
  usageAnalyticsEnabled?: boolean;
}

export interface DiscoveryConnectionsTrackingParams extends SSOForDiscoveryTrackingParams {
  action: UsageAnalyticsEventAction;
  actionOrigin: string;
  error: boolean;
  usageAnalyticsEnabled?: boolean;
}

export interface ConnectionsErrorTrackingParams extends BaseUsageAnalyticsTrackingParams {
  errorMessage: string;
}

export const trackViewUsersListClicked = (data: SubscriptionUsageAnalyticsTrackingParams) => {
  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsViewUsersListClicked, data);
};

export const trackDownloadUsersListClicked = (data: SubscriptionUsageAnalyticsTrackingParams) => {
  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsDownloadUsersListClicked, data);
};

export const trackConnectViewed = (
  data: BaseUsageAnalyticsTrackingParams,
  actionOrigin: string,
  usageAnalyticsEnabled?: boolean
) => {
  const trackData: ConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.VIEW,
    actionOrigin,
    error: false,
    usageAnalyticsEnabled,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnectViewed, trackData);
};

export const trackReconnectViewed = (
  data: BaseUsageAnalyticsTrackingParams,
  actionOrigin: string,
  usageAnalyticsEnabled?: boolean
) => {
  const trackData: ConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.RECONNECT,
    actionOrigin,
    error: false,
    usageAnalyticsEnabled,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsReconnectViewed, trackData);
};

export const trackConnected = (
  data: BaseUsageAnalyticsTrackingParams,
  actionOrigin: string,
  usageAnalyticsEnabled?: boolean
) => {
  const trackData: ConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.CONNECT,
    actionOrigin,
    error: false,
    usageAnalyticsEnabled,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnect, trackData);
};

export const trackConnectedError = (
  data: ConnectionsErrorTrackingParams,
  actionOrigin: string,
  usageAnalyticsEnabled?: boolean
) => {
  const trackData: ConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.CONNECT,
    actionOrigin,
    error: true,
    usageAnalyticsEnabled,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnect, trackData);
};

export const trackDisconnected = (
  data: BaseUsageAnalyticsTrackingParams,
  actionOrigin: string,
  usageAnalyticsEnabled?: boolean
) => {
  const trackData: ConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.DISCONNECT,
    actionOrigin,
    error: false,
    usageAnalyticsEnabled,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsDisconnect, trackData);
};

export const trackDisconnectedError = (
  data: ConnectionsErrorTrackingParams,
  actionOrigin: string,
  usageAnalyticsEnabled?: boolean
) => {
  const trackData: ConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.DISCONNECT,
    actionOrigin,
    error: true,
    usageAnalyticsEnabled,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsDisconnect, trackData);
};

export const trackDiscoveryConnectViewed = (data: SSOForDiscoveryTrackingParams, actionOrigin: string) => {
  const trackData: DiscoveryConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.VIEW,
    actionOrigin,
    error: false,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnectViewed, trackData);
};

export const trackDiscoveryReconnectViewed = (data: SSOForDiscoveryTrackingParams, actionOrigin: string) => {
  const trackData: DiscoveryConnectionsTrackingParams = {
    ...data,
    action: UsageAnalyticsEventAction.RECONNECT,
    actionOrigin,
    error: false,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsReconnectViewed, trackData);
};

export const trackConnectedForDiscovery = (data: SSOForDiscoveryTrackingParams, actionOrigin: string) => {
  const trackData = {
    ...data,
    action: UsageAnalyticsEventAction.CONNECT,
    actionOrigin,
    error: false,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnect, trackData);
};

export const trackConnectedForDiscoveryError = (data: SSOForDiscoveryErrorTrackingParams, actionOrigin: string) => {
  const trackData = {
    ...data,
    action: UsageAnalyticsEventAction.CONNECT,
    actionOrigin,
    error: true,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnect, trackData);
};

export const trackDisconnectedForDiscovery = (data: SSOForDiscoveryTrackingParams, actionOrigin: string) => {
  const trackData = {
    ...data,
    action: UsageAnalyticsEventAction.DISCONNECT,
    actionOrigin,
    error: false,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnect, trackData);
};

export const trackDisconnectedForDiscoveryError = (data: SSOForDiscoveryErrorTrackingParams, actionOrigin: string) => {
  const trackData = {
    ...data,
    action: UsageAnalyticsEventAction.DISCONNECT,
    actionOrigin,
    error: true,
  };

  EventTracking.trackEvent(trackEventName.ToolUsageAnalyticsConnect, trackData);
};
