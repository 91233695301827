import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from 'shared/theme';

type StylesProps = {
  hasAvatar?: boolean;
  isSubscriptionDetailPage?: boolean;
};

const PREFIX = 'AssignToolOwnerFeature';

export const classes = {
  avatar: `${PREFIX}-avatar`,
  circularProgress: `${PREFIX}-circularProgress`,
  toolOwner: `${PREFIX}-toolOwner`,
  toolOwnerCard: `${PREFIX}-toolOwnerCard`,
  toolOwnerWrapper: `${PREFIX}-toolOwnerWrapper`,
  toolownerTooltip: `${PREFIX}-toolownerTooltip`,
};

export const Root = styled(Box)(({ hasAvatar, isSubscriptionDetailPage }: StylesProps) => ({
  [`& .${classes.avatar}`]: {
    '&.MuiAvatar-root': {
      fontSize: '0.6rem',
      height: 24,
      width: 24,
    },
  },
  [`& .${classes.circularProgress}`]: {
    '& svg': {
      height: 15,
      width: 15,
    },
    '&.MuiCircularProgress-colorPrimary': {
      color: colors.grey,
    },
  },
  [`& .${classes.toolOwner}`]: {
    backgroundColor: colors.white,
    border: hasAvatar ? '' : `1px solid ${colors.primary}`,
    color: colors.primary,
    fontSize: '0.813rem',
    height: isSubscriptionDetailPage ? '30px' : '',
    lineHeight: '1.25rem',
    margin: 'auto',
    width: isSubscriptionDetailPage ? '30px' : '',
  },
  [`& .${classes.toolOwnerCard}`]: {
    marginTop: '1rem',
    position: 'absolute',
    right: '0%',
    width: isSubscriptionDetailPage ? '400px' : '300px',
    zIndex: 11,
  },
  [`& .${classes.toolOwnerWrapper}`]: {
    '& .avatar-overlay': {
      backgroundColor: colors.avatarHoverColor,
      borderRadius: '50%',
      bottom: 0,
      height: '2.5rem',
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transition: '.2s ease',
      width: '2.5rem',
    },

    '& .owner-name-text': {
      color: colors.osloGray,
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.25rem',
      margin: '0 0.5rem',
      textAlign: 'left',
    },

    '&:hover .avatar-overlay': {
      opacity: 1,
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginTop: '0rem',
    position: 'relative',
  },
  [`& .${classes.toolownerTooltip}`]: {
    '& .container-without-tool-owner': {
      alignItems: 'center',
      display: 'flex',
      marginTop: '0.5rem',
    },
    width: '100%',
  },
}));
