import { Box, Button } from '@mui/material';
import { Icon } from 'asteroids';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { BackButtonProps } from './back-button.props';

const ButtonComponent: FC<BackButtonProps> = ({ label, onClick }) => {
  return (
    <Button startIcon={<Icon>arrow_back</Icon>} color='secondary' sx={{ textTransform: 'none' }} onClick={onClick}>
      {label}
    </Button>
  );
};

export const BackButton: FC<BackButtonProps> = ({ label, link, onClick }) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      {link ? (
        <Link to={link} style={{ padding: 0 }}>
          <ButtonComponent label={label} onClick={onClick} />
        </Link>
      ) : (
        <ButtonComponent label={label} onClick={onClick} />
      )}
    </Box>
  );
};
