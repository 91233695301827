import { useEffect } from 'react';

export const useClickOutside = (onClickOutside: () => void, element?: HTMLElement) => {
  useEffect(() => {
    if (!element) return;

    const handleClickOutside = (event: Event) => {
      if (!element.contains(event.target as Node)) onClickOutside();
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [element, onClickOutside]);
};
