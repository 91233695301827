import type { NavigationItemType } from 'asteroids';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerInfoType, UserRoles } from 'shared/common.definitions';
import { trackHelpCentreItemClicked } from 'shared/logic/event-tracking/help-center.events';
import { SastrifyStore } from 'shared/models';
import { CelloReferral } from 'shared/services';
import { show as showDrawer } from 'shared/store/common/appDrawer.slice';

export const useNavigationSettingsItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: SastrifyStore) => state.authentication.user);
  const isViewerUser = useMemo(() => user?.role === UserRoles.Viewer, [user?.role]);

  const navSettingsList: NavigationItemType[] = useMemo(
    () =>
      [
        !isViewerUser && {
          isLeave: true,
          key: 'app_support',
          name: t('navigation:navItems.app_support'),
          onClick: () => {
            dispatch(showDrawer(DrawerInfoType.SASTRIFY_APP_HELP));
            trackHelpCentreItemClicked();
          },
          url: `/overview#${DrawerInfoType.SASTRIFY_APP_HELP}`,
        },
        {
          getNavItemProps: () => ({ 'data-cello-click': false, id: 'celloId' }),
          isLeave: true,
          key: 'referral',
          name: t('navigation:navItems.referral'),
          onClick: () => {
            CelloReferral.openReferralModal();
            return false;
          },
        },
      ].filter((item) => !!item) as NavigationItemType[],
    [isViewerUser, t, dispatch]
  );

  return navSettingsList;
};
