import classnames from 'classnames';
import * as React from 'react';

import { PageSubHeaderProps } from './page-sub-header.props';
import { StyledPageSubHeader } from './page-sub-header.styles';

export const PageSubHeader: React.FC<PageSubHeaderProps> = ({ attachToContent, children }) => {
  return (
    <StyledPageSubHeader>
      <div
        className={classnames('page-sub-header-content', {
          'page-sub-header-content--attached': attachToContent,
        })}
        data-testid='page-header'>
        {children}
      </div>
    </StyledPageSubHeader>
  );
};
