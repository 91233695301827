import MuiIcon, { IconProps } from '@mui/material/Icon';

interface IconPropsType extends IconProps {
  variant?: 'Filled' | 'Outlined' | 'Rounded' | 'Sharp';
  size?: IconProps['fontSize'];
}

export const Icon: React.FC<IconPropsType> = (props: IconPropsType) => {
  const { variant = 'Outlined', ...rest } = props;
  const iconProps = { ...(rest || {}) };

  iconProps.baseClassName = variant === 'Filled' ? 'material-icons' : `material-symbols-${variant.toLowerCase()}`;

  if (iconProps.size === 'tiny') {
    iconProps.size = 'small';
  }

  if (iconProps.size && !iconProps.fontSize) {
    iconProps.fontSize = iconProps.size;
  }

  return <MuiIcon {...iconProps} />;
};

Icon.defaultProps = {
  variant: 'Outlined',
};

export default Icon;
