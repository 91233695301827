import { Box, Skeleton } from '@mui/material';
import classnames from 'classnames';
import { Breadcrumbs } from 'components/design-system/breadcrumb/breadcrumb.component';
import * as React from 'react';

import { BackButton } from '..';
import { PageHeaderProps } from './page-header.props';
import { StyledPageHeaderContainer } from './page-header.styles';

export const PageHeader: React.FC<PageHeaderProps> = ({
  attachToContent,
  backButton,
  children,
  finalLink,
  headerActions,
  hideBreadcrumbs,
  isLoadingData,
  sectionRight,
}) => {
  return (
    <StyledPageHeaderContainer>
      <div
        className={classnames('page-header-content', {
          'page-header-content--attached': attachToContent,
        })}
        data-testid='page-header'>
        {(!hideBreadcrumbs &&
          (isLoadingData ? (
            <Skeleton variant='rounded' height={20} width={200} sx={{ mb: 2 }} />
          ) : (
            <Breadcrumbs finalLink={finalLink} />
          ))) ||
          null}
        {sectionRight ? (
          <>
            {/* Use back button for simple use cases, for more complicated ones use headerActions */}
            {headerActions && <Box sx={{ mb: 2 }}>{headerActions}</Box>}
            {backButton && <BackButton {...backButton} />}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>{children}</Box>
              <Box sx={{ mb: 1, ml: 3 }}>{sectionRight}</Box>
            </Box>
          </>
        ) : (
          <>
            {/* Use back button for simple use cases, for more complicated ones use headerActions */}
            {headerActions && <Box sx={{ mb: 2 }}>{headerActions}</Box>}
            {backButton && <BackButton {...backButton} />}
            {children}
          </>
        )}
      </div>
    </StyledPageHeaderContainer>
  );
};
