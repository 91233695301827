import { AxiosError } from 'axios';
import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

export const useFetchData = <T>(
  queryKey: QueryKey,
  queryFn: QueryFunction<T, QueryKey>,
  options?: UseQueryOptions<T, AxiosError, T, QueryKey>
): UseQueryResult<T, AxiosError> => {
  return useQuery<T, AxiosError>(queryKey, queryFn, options);
};
