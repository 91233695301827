import { FC } from 'react';
import { colors } from 'shared/theme';

export const CloseIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, width }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.471 3.528c.26.26.26.683 0 .943l-8 8a.667.667 0 01-.942-.943l8-8c.26-.26.682-.26.942 0z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.529 3.528c.26-.26.682-.26.942 0l8 8a.667.667 0 01-.942.943l-8-8a.667.667 0 010-.943z'
        fill={fill}
      />
    </svg>
  );
};

CloseIcon.defaultProps = {
  fill: colors.osloGray,
  height: 16,
  width: 16,
};
