import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button, CircularProgress, Link, TableCell, Tooltip, Typography, useTheme } from '@mui/material';
import { Icon, IconDropdown } from 'asteroids';
import { BulkSelectionFloatingMenuComponent } from 'components/bulk-selection-floating-menu';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { InvoiceTableSkeleton } from 'components/skeletons/invoice-table.skeleton';
import { addSeconds } from 'date-fns';
import { CsvBuilder } from 'filefy';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { SPEND_CATEGORIES } from 'libs/enums';
import { CodatPlatformType, PaginatedResponse } from 'libs/models';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY, TableColumnNames } from 'shared/common.definitions';
import { formatCurrency, formatFullDate, shortenFileName, shortenText } from 'shared/helpers/common.helper';
import { getPlatformName } from 'shared/helpers/connect.helper';
import { dateToSqlDateString } from 'shared/helpers/dates.helper';
import { fetchBatchFailedInvoiceData, markFailedInvoiceAsArchived } from 'shared/logic/subscription-invoices.logic';
import {
  capitalize,
  deleteSubscriptionSpendsAndInvoices,
  editSubscriptionSpendAndInvoice,
  getPaginatedSubscriptionSpendAndInvoices,
  getSubscriptionSpendCategoryMappings,
  SpendIdsParams,
} from 'shared/logic/subscription-item.logic';
import { Company, Invoice, Subscription } from 'shared/models';
import { FailedInvoiceData, SubscriptionSpendAndInvoices } from 'shared/models/subscription-spend-and-invoices.model';
import { useAppSelector } from 'shared/store/hooks';
import { updateFooterState } from 'shared/store/modal/actions/modal.action';
import { ModalActionTypes } from 'shared/store/modal/actions/modal.action.types';
import {
  selectAllUploads,
  setUploadedFiles,
  SubscriptionInvoiceUpload,
  SubscriptionInvoiceUploadStatus,
} from 'shared/store/views/subscription/subscription-invoice-upload.slice';
import { formatDateWithoutTimeZone } from 'views/overview/calendar/subscription-indicator/helpers';
import { EditedInvoice, EditSpendModal } from 'views/spend-import/components/imported-successfully/edit-spend-modal';
import { CustomModalContainer } from 'views/spend-import/components/imported-successfully/edit-spend-modal/edit-spend.modal.styles';

import { SubscriptionSpendReview } from '../subscription-spend-review-modal/subscription-spend-review.modal.component';
import { SubscriptionSpendAndInvoicesTableProps } from '.';
import { ReviewSpendFeature } from './review-spend.feature';
import { FieldKeys, SubscriptionSpendAndInvoicesCSV } from './subscription-spend-and-invoices-table.feature.props';
import { StyledTableWithPagination } from './subscription-spend-and-invoices-table.styles';

const INITIAL_PAGE = 1;
const POLLING_INTERVAL = 5000;
const NEW_UPDATE_LOADING_TIME = 1500;
const CACHE_TIME_IN_MILLISECONDS = 1800 * 1000;

export const SubscriptionSpendAndInvoicesTableFeature: FC<SubscriptionSpendAndInvoicesTableProps> = ({
  closeModal,
  codatConnectionDetails,
  showModal,
  showNotification,
  spendCategoryTypes,
  subscriptionId,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { onCloseAsteroidsModal, onShowAsteroidsModal } = useModalAndNotification();
  const uploadedInvoices = useAppSelector((state) => selectAllUploads(state.views.subscriptions.invoiceUploads));

  const subscription = queryClient.getQueryData<Subscription>(['subscription', subscriptionId]);
  const company = queryClient.getQueryData<Company>('company');

  const [selectedRows, setSelectedRows] = useState<SubscriptionSpendAndInvoices[]>([]);

  const uploadedInvoicesRef = useRef<SubscriptionInvoiceUpload[]>();

  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);

  const retryCounter = useRef(0);
  const intervalTimer = useRef<NodeJS.Timeout>();

  const searchFilters = null;

  const {
    data: spendData,
    isError,
    isFetching,
    isLoading,
    refetch: refetchSpendData,
  } = useQuery({
    cacheTime: CACHE_TIME_IN_MILLISECONDS,
    queryFn: () => fetchSpendAndInvoices(currentPage),
    queryKey: ['subscription-spend', currentPage, searchFilters],
    refetchInterval: CACHE_TIME_IN_MILLISECONDS,
    refetchOnWindowFocus: false,
  });

  const processingInvoiceIds = useMemo(() => {
    const filterFn = (item: SubscriptionSpendAndInvoices) => !!item.documentId && item.processing;
    return spendData?.items.filter(filterFn).map((item) => item.documentId as string) || [];
  }, [spendData?.items]);

  const initialValue: { [key in string]: FailedInvoiceData } = {};
  const transformFailedInvoice = (data: FailedInvoiceData[]) => {
    return data.reduce((acc, curr) => {
      acc[curr.documentId as string] = curr;
      return acc;
    }, initialValue);
  };
  const { data: failedInvoices, refetch: refetchFailedInvoices } = useQuery({
    enabled: !!processingInvoiceIds.length,
    queryFn: () => fetchBatchFailedInvoiceData(subscriptionId, processingInvoiceIds),
    queryKey: 'failed-invoices-batch',
    refetchOnWindowFocus: false,
    select: transformFailedInvoice,
  });

  const [itemsWithLoader, setItemsWithLoader] = useState<{ [key in string]: boolean }>({});

  const hideConversionColumn = !spendData?.items.some((spend) => spend.companyCurrency !== spend.amountCurrency);

  const handleitemsWithLoader = useCallback((id: string) => {
    setItemsWithLoader((prevState) => (prevState[id] === undefined ? { ...prevState, [id]: true } : prevState));

    setTimeout(() => {
      setItemsWithLoader((prevState) => ({ ...prevState, [id]: false }));
    }, NEW_UPDATE_LOADING_TIME);
  }, []);

  useEffect(() => {
    uploadedInvoicesRef.current = uploadedInvoices;
  }, [uploadedInvoices]);

  useEffect(() => {
    if (retryCounter.current === 5) {
      retryCounter.current = 0;
      return;
    }

    const someInvoiceStillProcessing = processingInvoiceIds.some((id) => !failedInvoices?.[id]);
    if (someInvoiceStillProcessing) {
      intervalTimer.current = setInterval(() => {
        refetchSpendData();
        refetchFailedInvoices();
        retryCounter.current += 1;
      }, POLLING_INTERVAL);
    }
    return () => {
      if (intervalTimer.current) clearInterval(intervalTimer.current);
    };
  }, [failedInvoices, processingInvoiceIds, refetchSpendData, refetchFailedInvoices]);

  useEffect(() => {
    if (!uploadedInvoicesRef.current || !spendData?.items) return;
    const updatedInvoiceUploads = uploadedInvoicesRef.current.map((item) => {
      const { id } = item;

      const currentSpend = spendData?.items.find((item) => item.documentId === id);

      if (failedInvoices?.[id] && currentSpend?.processing) {
        return { ...item, status: SubscriptionInvoiceUploadStatus.failed };
      }

      if (currentSpend && !currentSpend.processing) {
        handleitemsWithLoader(currentSpend.documentId as string);
        return { ...item, status: SubscriptionInvoiceUploadStatus.completed };
      }

      if (
        currentSpend?.uploadDate &&
        addSeconds(new Date(currentSpend.uploadDate), 25) < new Date() &&
        processingInvoiceIds.includes(id)
      ) {
        return { ...item, status: SubscriptionInvoiceUploadStatus.delayed };
      }

      return {
        ...item,
        status:
          item.status === SubscriptionInvoiceUploadStatus.uploaded
            ? SubscriptionInvoiceUploadStatus.processing
            : item.status,
      };
    });
    dispatch(setUploadedFiles(updatedInvoiceUploads));
  }, [failedInvoices, processingInvoiceIds, spendData?.items, handleitemsWithLoader, dispatch]);

  const emptyCellValue = '--';

  const getConnectionNameById = (connectionId: string) => {
    const connection = codatConnectionDetails?.find((item) => item?.codatConnectionId === connectionId);
    return connection?.sastrifyConnectionName;
  };

  const { mutateAsync } = useMutation(deleteSubscriptionSpendsAndInvoices);
  const { isLoading: isUpdatingSpendAndInvoice, mutate: mutateSpendAndInvoice } = useMutation(
    editSubscriptionSpendAndInvoice,
    {
      onError: (error) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSettled: () => {
        dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
        closeModal?.();
      },
      onSuccess: () => {
        const message = 'Invoice updated successfully';
        showNotification?.(message, NotificationAlertType.Success);
        queryClient.invalidateQueries('subscription-spend-and-invoices');
        queryClient.invalidateQueries(['subscription', subscriptionId]);
        queryClient.invalidateQueries('auditLogs');
      },
    }
  );

  const fetchSpendAndInvoices = (page: number): Promise<PaginatedResponse<SubscriptionSpendAndInvoices>> => {
    return getPaginatedSubscriptionSpendAndInvoices(subscriptionId, page - 1);
  };
  const handleSelectedRowsUpdate = (rows: SubscriptionSpendAndInvoices[]) => {
    if (rows !== selectedRows && rows?.length !== selectedRows?.length) setSelectedRows(rows);
  };

  const handleDownloadSpendDataCSV = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const csvFileName = `Sastrify(${
      subscription?.name || subscription?.vendorName
    })-spend-data-export-${currentDate}.csv`;

    const modifiedTableColumns = [...headerColumns].map((item) => ({ id: item.id, name: item.name }));
    modifiedTableColumns.pop();
    modifiedTableColumns.unshift({ id: 'id', name: 'Id' });

    const modifiedSelectedRowsData = (
      JSON.parse(JSON.stringify(selectedRows)) as SubscriptionSpendAndInvoicesCSV[]
    ).map((item) => {
      item.name = item.name ? item.name : '--';
      item.type = getSubscriptionSpendCategoryMappings(spendCategoryTypes)[Number(item.category)];
      item.date = formatFullDate(item.date as string);
      item.amountFormatted = getItemAmount(item) || '';
      item.companyAmountCents = getItemConversion(item) || '';

      return item;
    });

    new CsvBuilder(csvFileName)
      .setColumns(modifiedTableColumns.map((col) => String(col.name)))
      .addRows(
        modifiedSelectedRowsData.map((item) =>
          modifiedTableColumns.map((col) => String(item[col.id as keyof FieldKeys]))
        )
      )
      .exportFile();
    showNotification?.(MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY, NotificationAlertType.Success);
  };

  const onEditInvoiceAndSpend = (editedInvoice: EditedInvoice, invoiceData: Invoice, subscription: Subscription) => {
    dispatch(updateFooterState({ isFormSubmitting: true }));

    if (!invoiceData.subscriptionId) {
      return;
    }

    try {
      mutateSpendAndInvoice({
        invoiceId: invoiceData.id,
        spendAndInvoiceData: {
          amountCents: editedInvoice.amount,
          amountCurrency: editedInvoice.currency,
          date: dateToSqlDateString(editedInvoice.invoiceDate),
          id: invoiceData.id,
          name: editedInvoice.name,
        } as SubscriptionSpendAndInvoices,
        subscriptionId: subscription.id,
        vendorId: subscription?.vendorId || '',
      });
    } catch {
      const message = t('spend_import_view:imported_successfully.edit.error_message');
      showNotification?.(message, NotificationAlertType.Error);
    }
  };

  const handleEditSpendData = (invoiceData: SubscriptionSpendAndInvoices) => {
    dispatch(updateFooterState({ isFormValid: false }));

    onShowAsteroidsModal?.({
      PaperComponent: CustomModalContainer,
      children: (
        <EditSpendModal
          invoiceData={{ ...invoiceData, subscriptionId }}
          subscription={subscription}
          closeModal={closeModal}
          onDelete={handleDeleteSingleSpendData}
          onSave={onEditInvoiceAndSpend}
          isUpdating={isUpdatingSpendAndInvoice}
        />
      ),
      width: invoiceData.documentPreviewUrl ? 'large' : 640,
    });
  };

  const handleDownloadSpendData = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  const onSuccessfulDelete = () => {
    const message = t(
      'subscription_detail_view:tabs_component_section.subscription_spend_tab.notification_messages.delete_invoices.success_message'
    );

    closeModal?.();
    showNotification?.(message, NotificationAlertType.Success);

    queryClient.invalidateQueries('subscription-spend-and-invoices');
    queryClient.invalidateQueries(['subscription', subscriptionId]);
    queryClient.invalidateQueries('auditLogs');
  };

  const handleDeleteSingleSpendData = async (item: SubscriptionSpendAndInvoices) => {
    dispatch(updateFooterState({ isFormSubmitting: true }));
    try {
      const isProcessedDocument = Number(item.category) === SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT;
      const apiParams = {
        spendIds: [
          {
            categoryId: String(item.category),
            spendId: isProcessedDocument && item.documentId ? item.documentId : item.id,
          },
        ],
        subscriptionId,
      };

      if (item.processing) {
        if (failedInvoices?.[item.documentId as string]) {
          const invoiceDocumentData = failedInvoices[item.documentId as string];
          if (invoiceDocumentData.sourceId)
            markFailedInvoiceAsArchived(invoiceDocumentData.sourceSystem, invoiceDocumentData.sourceId);
        }
      }

      const { failedDeletedIds, successfullyDeletedIds } = await mutateAsync(apiParams);
      if (successfullyDeletedIds.length && !failedDeletedIds.length) {
        onSuccessfulDelete();
      } else if (failedDeletedIds.length) {
        const message = t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.notification_messages.delete_invoices.error_message'
        );
        closeModal?.();
        showNotification?.(message, NotificationAlertType.Error);
      }
    } catch {
      const message = t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.notification_messages.delete_invoices.error_message'
      );
      showNotification?.(message, NotificationAlertType.Error);
    } finally {
      dispatch(updateFooterState({ isFormSubmitting: false }));
    }
  };

  const handleDeleteSpendData = async () => {
    try {
      dispatch(updateFooterState({ isFormSubmitting: true }));
      const apiParams: SpendIdsParams[] = [];

      selectedRows.forEach((invoiceItem) => {
        if (invoiceItem) {
          const isProcessedDocument = Number(invoiceItem.category) === SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT;
          apiParams.push({
            categoryId: String(invoiceItem.category),
            spendId: isProcessedDocument && invoiceItem.documentId ? invoiceItem.documentId : invoiceItem.id,
          });

          if (invoiceItem.processing) {
            // NOTE: this is a quick fix, we need a new EP to do this in bulk or update delete-bulk ep to do this
            if (failedInvoices?.[invoiceItem.documentId as string]) {
              const invoiceDocumentData = failedInvoices[invoiceItem.documentId as string];
              if (invoiceDocumentData.sourceId)
                markFailedInvoiceAsArchived(invoiceDocumentData.sourceSystem, invoiceDocumentData.sourceId);
            }
          }
        }
      });

      const { failedDeletedIds, successfullyDeletedIds } = await mutateAsync({ spendIds: apiParams, subscriptionId });
      if (successfullyDeletedIds.length && !failedDeletedIds.length) {
        setSelectedRows([]);
        onSuccessfulDelete();
      } else if (failedDeletedIds.length) {
        const message = t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.notification_messages.delete_invoices.error_message'
        );
        closeModal?.();
        showNotification?.(message, NotificationAlertType.Error);
      }
    } catch {
      const message = t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.notification_messages.delete_invoices.error_message'
      );
      showNotification?.(message, NotificationAlertType.Error);
    } finally {
      dispatch(updateFooterState({ isFormSubmitting: false }));
    }
  };

  const openDeleteSpendAndInvoiceModal = (
    item?: SubscriptionSpendAndInvoices,
    subscription?: Subscription,
    company?: Company
  ) => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    const deletingSingleSpend = selectedRows.length === 0 && item?.id !== undefined;
    onShowAsteroidsModal({
      cancelButtonText: t(
        'common:modals.delete_spend_and_invoices_modal.action_buttons_section.cancel_delete_invoice_button_text'
      ),
      confirmButtonColor: 'error',
      confirmButtonText: t(
        'common:modals.delete_spend_and_invoices_modal.action_buttons_section.confirm_delete_invoice_button_text'
      ),
      confirmButtonVariant: 'text',

      message: (
        <Box mt={1}>
          {t('common:modals.delete_spend_and_invoices_modal.body_section_text', {
            count: deletingSingleSpend ? 1 : selectedRows.length,
          })}
        </Box>
      ),
      onCancelButtonClick: () => {
        onCloseAsteroidsModal();
        if (subscription && company && item) {
          handleOpenReviewSpendModal(subscription, company, item);
        }
      },
      onConfirmButtonClick: () => (deletingSingleSpend ? handleDeleteSingleSpendData(item) : handleDeleteSpendData()),
      title: t('common:modals.delete_spend_and_invoices_modal.header_section_text'),
      triggerCancelButtonClick: true,
    });
  };

  const isShowingReviewSpendFeature = (item: SubscriptionSpendAndInvoices) => {
    if (failedInvoices?.[item.documentId as string] && item.processing) {
      return true;
    }

    if (item.uploadDate && item.processing) {
      const uploadDate = new Date(item.uploadDate);
      return addSeconds(uploadDate, 25) < new Date();
    }

    return false;
  };

  const getItemAmount = (item: SubscriptionSpendAndInvoices) => {
    return item.processing
      ? capitalize(item.displayAmountCents)
      : formatCurrency({
          currency: item.amountCurrency,
          isDecimal: true,
          style: 'currency',
          value: Number(item.amountCents),
        });
  };

  const getItemConversion = (item: SubscriptionSpendAndInvoices) => {
    return item.processing
      ? capitalize(item.displayAmountCents)
      : formatCurrency({
          currency: item.companyCurrency || company?.currency,
          isDecimal: true,
          style: 'currency',
          value: Number(item.companyAmountCents),
        });
  };

  const getNameSubtitle = (item: SubscriptionSpendAndInvoices): string => {
    const date = item.uploadDate ? formatFullDate(String(item.uploadDate)) : '';
    const category = getSubscriptionSpendCategoryMappings(spendCategoryTypes)[Number(item.category)];
    const subtitle = `${category}    •   ${date}`;

    if (item.category === SPEND_CATEGORIES.AUTOMATED_IMPORT) {
      const platformName = getPlatformName((item?.importSubsystem as CodatPlatformType) ?? undefined);

      const connectionName = item.connectionId ? getConnectionNameById(item.connectionId) : null;
      const platform = platformName
        ? `${capitalize(platformName)} ${item.connectionId ? '(disconnected)' : ''}`
        : category;

      return `${connectionName || platform}    •   ${date}`;
    }

    if (
      item.category === SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT ||
      item.category === SPEND_CATEGORIES.SUBSCRIPTION_DOCUMENT
    ) {
      return `${
        item.uploadedByName
          ? t(
              'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.uploaded_by_text',
              { user: item.uploadedByName }
            )
          : category
      }    •   ${date}`;
    }

    return subtitle;
  };

  const renderItemWithLoader = (item: SubscriptionSpendAndInvoices, component: React.ReactNode) => {
    return itemsWithLoader[item.documentId as string] ? <CircularProgress size={24} /> : component;
  };

  const handleOpenReviewSpendModal = (
    subscription: Subscription,
    company: Company,
    invoiceData: SubscriptionSpendAndInvoices
  ) => {
    dispatch(updateFooterState({ isFormValid: false }));

    showModal?.({
      children: (
        <SubscriptionSpendReview
          closeModal={closeModal}
          subscription={subscription}
          onDelete={openDeleteSpendAndInvoiceModal}
          company={company}
          showNotification={showNotification}
          invoiceData={invoiceData}
        />
      ),
      hasCustomFooter: true,
      hasCustomWidth: true,
      onConfirmButtonClick: () => {
        dispatch(updateFooterState({ isFormSubmitting: true }));
      },
      overlayClass: 'review-spend-modal',
      showHeader: false,
    });
  };

  const renderCells = (item: SubscriptionSpendAndInvoices, index: number): JSX.Element => {
    const isProcessing = item.displayAmountCents === 'processing';
    return (
      <>
        <TableCell className='tool-item-cell' data-testid={`table-row-cell-${index}-1`}>
          <Typography variant='label' sx={{ marginBottom: theme.spacing(0.5) }}>
            {item.documentPreviewUrl ? (
              <Link
                href={item.documentPreviewUrl}
                target='_blank'
                rel='noreferrer'
                data-testid='file-name'
                sx={{ textDecoration: 'underline !important' }}>
                {shortenFileName(item.name || '') || emptyCellValue}
              </Link>
            ) : (
              shortenText(item.name || '', 25) || emptyCellValue
            )}
          </Typography>
          <Typography variant='small' sx={{ color: theme.palette.text.secondary }}>
            {getNameSubtitle(item)}
          </Typography>
        </TableCell>
        <TableCell data-testid={`table-row-cell-${index}-2`}>
          {isShowingReviewSpendFeature(item)
            ? emptyCellValue
            : renderItemWithLoader(
                item,
                <Typography variant='body'>
                  {item.date ? formatDateWithoutTimeZone(String(item.date)) : emptyCellValue}
                </Typography>
              )}
        </TableCell>
        <TableCell data-testid={`table-row-cell-${index}-3`}>
          {!isShowingReviewSpendFeature(item) || !subscription || !company ? (
            renderItemWithLoader(item, <Typography variant='body'>{getItemAmount(item)}</Typography>)
          ) : (
            <ReviewSpendFeature
              handleOpenReviewSpendModal={() => handleOpenReviewSpendModal(subscription, company, item)}
            />
          )}
        </TableCell>
        {!hideConversionColumn && (
          <TableCell data-testid={`table-row-cell-${index}-4`}>
            {isShowingReviewSpendFeature(item)
              ? emptyCellValue
              : renderItemWithLoader(
                  item,
                  <Typography variant='body'>{getItemConversion(item) || emptyCellValue}</Typography>
                )}
          </TableCell>
        )}
        <TableCell className='action-item-cell' data-testid={`table-row-cell-${index}-5`}>
          <IconDropdown
            iconName='more_vert_outlined'
            items={[
              {
                dataTestId: 'edit',
                disabled: isProcessing,
                iconName: 'edit_outlined',
                onClick: () => handleEditSpendData(item),
                title: 'Edit Info',
              },
              {
                dataTestId: 'download',
                disabled: !item.documentUrl,
                iconName: 'file_download_outlined',
                onClick: () => item.documentUrl && handleDownloadSpendData(item.documentUrl),
                title: 'Download',
                withDivider: true,
              },
              {
                dataTestId: 'delete',
                iconName: 'delete',
                onClick: () => openDeleteSpendAndInvoiceModal(item),
                title: 'Delete',
              },
            ]}
          />
        </TableCell>
      </>
    );
  };

  const allSelectedInvoicesHaveDocumentUrl = (): boolean => {
    return selectedRows?.filter((item) => item.documentUrl !== undefined)?.length === selectedRows.length;
  };

  const renderBulkActionsMenu = () => (
    <BulkSelectionFloatingMenuComponent
      selectedText={t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.bulk_selection_floating_menu.selection_text',
        {
          count: selectedRows.length,
        }
      )}
      actionComponents={[
        {
          component: (
            <Button
              variant='text'
              color='secondary'
              data-testid='bulk-download'
              className='downloadButton'
              onClick={() => handleDownloadSpendDataCSV()}
              startIcon={<Icon>file_download</Icon>}
              disabled={!allSelectedInvoicesHaveDocumentUrl()}>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.bulk_selection_floating_menu.export_btn_text'
              )}
            </Button>
          ),
          id: 1,
        },
        {
          component: (
            <Button
              sx={{ whiteSpace: 'nowrap' }}
              variant='text'
              color='secondary'
              data-testid='bulk-delete'
              className='deleteButton'
              onClick={() => openDeleteSpendAndInvoiceModal()}
              startIcon={<Icon>delete_outline</Icon>}>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.bulk_selection_floating_menu.delete_btn_text'
              )}
            </Button>
          ),
          id: 2,
        },
      ]}
    />
  );

  const headerColumns = [
    {
      className: 'tool-item-cell',
      id: TableColumnNames.NAME,
      label: t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.name_label'
      ),
      minWidth: 240,
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.name_label'
      ),
    },
    {
      id: TableColumnNames.DATE,
      label: t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.date_label'
      ),
      minWidth: 120,
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.date_label'
      ),
    },
    {
      id: TableColumnNames.AMOUNT,
      label: t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.amount_label'
      ),
      minWidth: 120,
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.amount_label'
      ),
    },
    {
      'data-testid': 'spends-company-amount',
      hide: hideConversionColumn,
      id: TableColumnNames.COMPANY_AMOUNT,
      label: (
        <Tooltip
          placement='top'
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.company_amount_description'
          )}>
          <Typography variant='label'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.company_amount_label'
            )}
          </Typography>
        </Tooltip>
      ),
      minWidth: 120,
      name: t(
        'subscription_detail_view:tabs_component_section.subscription_spend_tab.spend_and_invoices_table.header_section.company_amount_label'
      ),
    },
    {
      className: 'action-item-cell',
      id: 'edit',
      label: '',
    },
  ].filter((item) => !item.hide);

  const getHeaderColumns = () => {
    return headerColumns;
  };

  return (
    <Box data-testid='invoices-table'>
      {selectedRows.length > 0 && renderBulkActionsMenu()}
      <StyledTableWithPagination
        table={{
          bulkSelection: true,
          data: spendData,
          headCells: getHeaderColumns(),
          onSelectedRowsChange: handleSelectedRowsUpdate,
          renderCells,
          selectedRows,
          stickyCheckbox: true,
        }}
        isLoading={(isLoading || isFetching) && retryCounter.current === 0}
        isError={isError}
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        pagination={{ itemsLabel: 'invoices' }}
        skeletonComponent={<InvoiceTableSkeleton />}
        errorStateComponent={<>{null}</>}
      />
    </Box>
  );
};
