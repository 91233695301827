import { PERMISSION } from 'libs/enums';
import { FC, lazy } from 'react';
import { Route } from 'react-router';
import { featureFlags } from 'shared/common.definitions';
import { RoutedProps } from 'shared/models';
import { useCheckUserPermission } from 'src/app/hooks';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { NAVIGATION_URLS } from 'src/constants/navigation';
import { ErrorView } from 'views/error';

const CompanyDataView = lazy(() => import('../views/company-data/company-data.view'));
const DepartmentTeamView = lazy(
  () => import('../views/company-data-details/pages/department-team/department-team.view')
);
const CostCenterView = lazy(() => import('../views/company-data-details/pages/cost-center/cost-center.view'));
const UserAndCompanyDataView = lazy(() => import('../views/company-data/users-and-company-data.view'));

export const UserAndCompandyDataRoutes: FC<RoutedProps> = (props) => {
  const canViewUserAndCompanyData = useCheckUserPermission(PERMISSION.VIEW_HRIS_DATA);

  // Feature flags
  const hrIntegrationsEnabled = useCompanyFeatureFlag(featureFlags.HR_INTEGRATIONS);
  const isUserAndCompanyDataEnabled = useCompanyFeatureFlag(featureFlags.USER_AND_COMPANY_DATA);

  return (
    <>
      <Route exact path={NAVIGATION_URLS.COMPANY_DATA}>
        {canViewUserAndCompanyData && hrIntegrationsEnabled ? <CompanyDataView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={`${NAVIGATION_URLS.COMPANY_DATA_DEPARTMENTS_TEAMS_DETAIL}/:groupId`}>
        {canViewUserAndCompanyData && hrIntegrationsEnabled ? (
          <DepartmentTeamView {...props} />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
      <Route exact path={`${NAVIGATION_URLS.COMPANY_DATA_COST_CENTERS_DETAIL}/:groupId`}>
        {canViewUserAndCompanyData && hrIntegrationsEnabled ? <CostCenterView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.USER_AND_COMPANY_DATA}>
        {isUserAndCompanyDataEnabled && canViewUserAndCompanyData ? (
          <UserAndCompanyDataView {...props} />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
    </>
  );
};
