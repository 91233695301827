import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import React from 'react';
import { getFirstAndLastInitialsFromText } from 'shared/helpers/common.helper';
import { colors } from 'shared/theme';

import { UserNameAndAvatarComponentProps } from './username-and-avatar.component.props';

type StylesPropType = {
  width?: string;
  height?: string;
  fontSize?: string;
  border?: boolean;
};

const PREFIX = 'UserNameAndAvatarComponent';

const classes = {
  medium: `${PREFIX}-medium`,
  root: `${PREFIX}-root`,
};

const Root = styled('div')<StylesPropType>((props) => {
  const { theme } = props;

  return {
    [`& .${classes.medium}`]: {
      border: (props as StylesPropType)?.border ? `1px solid ${colors.primary}` : 'unset',
      fontSize: '0.6rem',
      img: {
        maxHeight: '100%',
      },
      marginRight: theme.spacing(1),
      maxHeight: (props as StylesPropType)?.height ?? '2rem',
      maxWidth: (props as StylesPropType)?.width ?? '2rem',
    },

    [`&.${classes.root}`]: {
      '& .custom-icon': {
        marginRight: theme.spacing(1),
      },
      '& .user-name': {
        color: colors.rollingStone,
        fontSize: (props as StylesPropType)?.fontSize ?? 'inherit',
      },
      alignItems: 'center',
      display: 'flex',
    },
  };
});

export const UserNameAndAvatarComponent: React.FC<UserNameAndAvatarComponentProps> = (
  props: UserNameAndAvatarComponentProps
) => {
  const {
    avatarUrl,
    border,
    classNames,
    email,
    fontSize,
    height,
    hideAvatar,
    hideName = false,
    icon,
    name,
    width,
  } = props;

  return (
    <Root
      className={classnames('sastrify-usernameandavatar', classes.root)}
      border={border}
      fontSize={fontSize}
      height={height}
      width={width}
      data-testid='user-name-and-avatar'>
      {!hideAvatar && !icon && (
        <span
          className={classnames('user-avatar', {
            [classNames as string]: classNames,
          })}>
          <Avatar
            src={avatarUrl}
            alt='user avatar'
            className={classes.medium}
            classes={{
              colorDefault: classnames({ 'sastrify-avatar': !avatarUrl }),
            }}>
            {getFirstAndLastInitialsFromText(name, true)}
          </Avatar>
        </span>
      )}
      {!hideAvatar && !avatarUrl && icon ? <span className='custom-icon'>{icon}</span> : null}
      {hideName || (
        <span data-testid='tool-owner-name' className='user-name'>
          {name} {email ? `(${email})` : ''}
        </span>
      )}
    </Root>
  );
};
