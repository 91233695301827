import { AxiosError } from 'axios';
import i18n from 'src/localization/i18n';

const { exists, t } = i18n;

export const getErrorCodeMessage = (err: AxiosError): string => {
  const errorCode = Object(err)?.response?.data?.data?.errorCode;

  if (errorCode && exists(`error_codes:${errorCode}`)) {
    return t(`error_codes:${errorCode}`);
  }
  if (Object(err)?.response?.data?.error) {
    return `${Object(err).response.data.error}`;
  }
  if (Object(err).message) {
    return `${err.message}`;
  }
  return '';
};
