import Clear from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { TypeaheadComponent, TypeaheadComponentProps } from 'components/typeahead';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { SastrifyStore, User } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';

import { useUsersList } from '../../hooks/use-users-list';
import { LeftIcon } from '../left-icon';
import { TypeaheadProps } from './typeahead.props';

export const Typeahead: FC<TypeaheadProps> = ({
  avatarUrl,
  componentRef,
  handleOnHideVisibility,
  handleToolOwnerSelection,
  isRequester,
  isSubscriptionInDiscovery,
  isToolOwnerNameEdited,
  leftIconClassName,
  name,
  onClearButtonClick,
  onInputChange,
  subscription,
  toolOwnerName,
}) => {
  const { t } = useTranslation();
  const [currentInputValue, setCurrentInputValue] = useState<string>('');
  const users = useQueryClient().getQueryData('tool-owners') as User[];
  const loggedInUser = useAppSelector((state: SastrifyStore) => state.authentication.user);
  const currentToolOwnerId = String(subscription?.ownerId);

  const { directoryUsers } = useAppSelector((state) => state.common.googleDirectory);

  const usersList = useUsersList({
    avatarUrl,
    isRequester,
    loggedInUser,
    name,
    subscription,
    users,
  });

  const notInvitedDirectoryUsers = () => {
    return directoryUsers.filter((user) => !user.isInvited);
  };

  const handleRightIconClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    onClearButtonClick();
    return false;
  };

  const typeaheadProps: TypeaheadComponentProps = {
    addNewIcon: 'fas fa-plus',
    addNewText: `${t(
      'common:assign_tool_owner_feature.typeahead_component.typeahead_add_new_text'
    )} ${currentInputValue}`,
    assignToolOwnerIcon: 'fas fa-user-plus',
    assignToolOwnerText: t('common:assign_tool_owner_feature.typeahead_component.typeahead_assign_tool_owner_text', {
      name: currentInputValue,
    }),
    currentToolOwnerId,
    data: [...usersList, ...(notInvitedDirectoryUsers() as User[])],
    defaultInputValue: name ?? '',
    fields: ['name', 'email'],
    getInputValue: (value) => setCurrentInputValue(value),
    isToolOwner: true,
    isToolOwnerNameEdited,
    isUserSection: isSubscriptionInDiscovery,
    leftIcon: (
      <LeftIcon
        avatarUrl={avatarUrl}
        className={leftIconClassName}
        isToolOwnerNameEdited={isToolOwnerNameEdited}
        name={name}
      />
    ),
    onHideVisibility: handleOnHideVisibility,
    onItemSelected: handleToolOwnerSelection,
    onTextChanged: onInputChange,
    placeholder: t('common:assign_tool_owner_feature.typeahead_component.typeahead_input_text_placeholder'),
    rightIcon: (
      <IconButton onClick={(event) => handleRightIconClick(event)} size='large'>
        <Clear color='secondary' />
      </IconButton>
    ),
    value: toolOwnerName,
  };

  return <TypeaheadComponent ref={componentRef} {...typeaheadProps} />;
};
