import { createAsyncThunk } from '@reduxjs/toolkit';

import { disconnectCodatConnectionV2, updateConnectionName } from '../../logic/codat-v2.logic';
import { connectCodatPlatformV2 } from '../../logic/company.logic';
import {
  CodatConnectionPayloadV2WithRefetch,
  CodatDisconnectV2Payload,
  CodatUpdateConnectionNamePayload,
} from '../../models/integration-item.model';

export const updateCodatConnectionName = createAsyncThunk(
  'codatConnect/updateConnectionName',
  async (payload: CodatUpdateConnectionNamePayload) => {
    const { codatCompanyId, companyId, name, refetch } = payload;

    await updateConnectionName(companyId, codatCompanyId, name);

    await refetch();
  }
);

export const createCodatConnection = createAsyncThunk(
  'codatConnect/initialConnect',
  async (payload: CodatConnectionPayloadV2WithRefetch) => {
    const { companyId, companyName, platformType, refetch, sastrifyConnectionName } = payload;

    const response = await connectCodatPlatformV2(companyId, {
      companyId,
      companyName,
      platformType,
      sastrifyConnectionName,
    });

    await refetch();

    return response;
  }
);

export const disconnectFromCodatV2 = createAsyncThunk(
  'codatConnect/disconnectV2',
  async (payload: CodatDisconnectV2Payload) => {
    const { codatCompanyId, companyId, refetch } = payload;
    await disconnectCodatConnectionV2(companyId, codatCompanyId);

    await refetch();
  }
);
