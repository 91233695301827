import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton as MUILoadingButton, LoadingButtonProps } from '@mui/lab';
import { FC, PropsWithChildren } from 'react';

export const LoadingButton: FC<PropsWithChildren<LoadingButtonProps>> = (props) => {
  return (
    <MUILoadingButton
      variant='contained'
      color='primary'
      loadingPosition='start'
      startIcon={
        props.loadingPosition === 'start' || !props.loadingPosition ? (
          props.startIcon || <SaveIcon sx={{ opacity: 0, ...(!props.loading && { display: 'none' }) }} />
        ) : (
          <>{null}</>
        )
      } // This is a workaround for loading icon to be displayed properly
      endIcon={
        props.loadingPosition === 'end' ? (
          props.endIcon || <SaveIcon sx={{ opacity: 0, ...(!props.loading && { display: 'none' }) }} />
        ) : (
          <>{null}</>
        )
      } // This is a workaround for loading icon to be displayed properly
      {...props}
      sx={{
        ...props.sx,
        ...(props.loadingPosition === 'start' || !props.loadingPosition
          ? { '.MuiButton-endIcon': { display: 'none' } }
          : {}),
        ...(props.loadingPosition === 'end' ? { '.MuiButton-startIcon': { display: 'none' } } : {}),
      }}>
      {props?.children}
    </MUILoadingButton>
  );
};

export default LoadingButton;
