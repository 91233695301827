/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { KnowledgeBlokContentInterface } from 'shared/models/vendor-knowledge-item.model';
import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_LINK,
  MARK_STRIKE,
  MARK_STYLED,
  MARK_UNDERLINE,
  NODE_BR,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_HR,
  NODE_IMAGE,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  render,
} from 'storyblok-rich-text-react-renderer';

import { Video } from '../../video';
import TableRenderer from './components/TableRenderer.component';
import { extractMediaFromContent } from './helpers/extract-videos';

interface StoryBlokRendererProps extends Pick<KnowledgeBlokContentInterface, 'content'> {
  onVideoPlay?: () => void;
  renderFirstBlockOnly?: boolean;
}
export const StoryBlokRenderer: FC<StoryBlokRendererProps> = ({
  content,
  onVideoPlay,
  renderFirstBlockOnly = false,
}) => {
  const resolveToText = (children?: ReactNode) =>
    children ? (
      <Typography variant='body' color='text.secondary'>
        {children}
      </Typography>
    ) : null;
  const resolveToTextWithSpace = (children?: ReactNode) => (children ? <>&nbsp;{children}</> : null);

  const renderExcerpt = (c: unknown) => {
    const excerpts = render(c, {
      markResolvers: {
        [MARK_BOLD]: resolveToText,
        [MARK_CODE]: resolveToText,
        [MARK_ITALIC]: resolveToText,
        [MARK_LINK]: resolveToText,
        [MARK_STRIKE]: resolveToText,
        [MARK_STYLED]: resolveToText,
        [MARK_UNDERLINE]: resolveToText,
      },
      nodeResolvers: {
        [NODE_BR]: resolveToText,
        [NODE_CODEBLOCK]: resolveToText,
        [NODE_HEADING]: resolveToText,
        [NODE_HR]: resolveToText,
        [NODE_IMAGE]: resolveToText,
        [NODE_LI]: resolveToTextWithSpace,
        [NODE_OL]: resolveToTextWithSpace,
        [NODE_PARAGRAPH]: resolveToText,
        [NODE_QUOTE]: resolveToText,
        [NODE_UL]: resolveToText,
      },
    });

    return excerpts && excerpts.length > 0 ? excerpts[0] : null;
  };

  if (renderFirstBlockOnly) return renderExcerpt(content);

  const handleVideoPlayed = () => {
    if (onVideoPlay) onVideoPlay();
  };

  const imageBlokResolver = (props: Record<string, any>) => (
    <img
      className='vendor-knowledge-item__media'
      data-testid='vendor-knowledge-item-image'
      alt=''
      src={props.asset.filename}
      key={`image--${new Date().getTime()}`}
    />
  );

  const videoBlokResolver = (props: Record<string, any>) => (
    <Video
      className='vendor-knowledge-item__media'
      src={props.asset.filename}
      onPlay={handleVideoPlayed}
      key={`video--${new Date().getTime()}`}
    />
  );

  const textCustomResolver = (text: string) => {
    const { content, imagesUrls, videosUrls } = extractMediaFromContent(text);
    const key = `text--${new Date().getTime()}`;

    return (
      <>
        {content}
        {imagesUrls.map((url, ikey) => (
          <img className='vendor-knowledge-item__media' alt='' src={url} key={`${key}--image-${ikey + 1}`} />
        ))}

        {videosUrls.map((url, vkey) => (
          <Video
            className='vendor-knowledge-item__media'
            src={url}
            onPlay={handleVideoPlayed}
            key={`${key}--image-${vkey + 1}`}
          />
        ))}
      </>
    );
  };

  const linkMarkResolver = (children: ReactNode, props: { target?: string; href?: string; linktype?: string }) => {
    const { href, linktype, target } = props;
    const { content, videosUrls } = extractMediaFromContent(href || '');
    const key = `table--${new Date().getTime()}`;

    if (href && !content && videosUrls.length > 0) {
      // this rule will skip creating a link for inline videos
      return <div key={key}>{children}</div>;
    }

    if (linktype === 'email') {
      // Email links: add `mailto:` scheme and map to <a>
      return (
        <a href={`mailto:${href}`} key={key}>
          {children}
        </a>
      );
    }

    return (
      <a href={href} target={target} key={key}>
        {children}
      </a>
    );
  };

  const tableBlokResolver = (props: Record<string, any>) => {
    if (!props?.table) {
      return null;
    }

    return <TableRenderer table={props.table} key={`table--${new Date().getTime()}`} />;
  };

  return render(content, {
    blokResolvers: {
      Image: imageBlokResolver,
      Table: tableBlokResolver,
      Video: videoBlokResolver,
    },
    markResolvers: {
      [MARK_LINK]: linkMarkResolver,
    },
    /** @ts-expect-error A bug in render package that expects string as return, when a Element is a valid return type */
    textResolver: textCustomResolver,
  });
};
