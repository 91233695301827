import { useTranslation } from 'react-i18next';

import { StatusDotColors, StatusDotProps } from '../../status-dot/status-dot.component.props';
import { Status } from '../status.component';
import { Statuses } from '../status.component.props';
import { StatusConnectionProps } from './connection-status.component.props';

export const ConnectionStatus: React.FC<StatusConnectionProps> = ({ status, subtitle, title }) => {
  const { t } = useTranslation();

  let statusColor: StatusDotProps['status'];
  let statusTitle!: string;

  switch (status) {
    case Statuses.connected:
      statusColor = StatusDotColors.success;
      statusTitle = t('asteroids:status.connected');
      break;
    case Statuses.disconnected:
      statusColor = StatusDotColors.error;
      statusTitle = t('asteroids:status.disconnected');
      break;
    case Statuses.connectionExpired:
      statusColor = StatusDotColors.error;
      statusTitle = t('asteroids:status.connectionExpired');
      break;
    default:
      statusColor = StatusDotColors.error;
      statusTitle = t('asteroids:status.connectionExpired');
  }

  if (title) statusTitle = title;

  return <Status status={statusColor} subtitle={subtitle} statusTitle={statusTitle} />;
};

export default ConnectionStatus;
