import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useCheckUserPermission, useFetchData } from 'hooks/index';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { PERMISSION } from 'libs/enums/permissions';
import { DEFAULT_STATE_OPTIONS } from 'libs/enums/subscription.enum';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  INACTIVE_STATE,
  MY_SUBSCRIPTIONS_STATE,
  SUBSCRIPTIONS_TEXT,
  SubscriptionStatus,
} from 'shared/common.definitions';
import {
  isCompanyToolsRoute,
  isDiscoveredRoute,
  isNewSolutionsRoute,
  isToolRequestsRoute,
} from 'shared/helpers/common.helper';
import {
  getDefaultDiscoveredToolsSortingOption,
  saveDiscoveredToolSorting,
} from 'shared/helpers/discovered-tools.helper';
import { trackDiscoveredSortingAction } from 'shared/logic/event-tracking/discovered-subscriptions.events';
import { checkIsArchiveSubscriptionView } from 'shared/logic/subscription-item.logic';
import {
  fetchSubscriptions,
  fetchViewOnlySubscriptions,
  filterSubscriptionByFilter,
  filterSubscriptions,
  sortSubscriptions,
} from 'shared/logic/subscriptions.logic';
import { Company, QueryParamsFilter, SastrifyStore, Subscription, TodoAndStatistics, User } from 'shared/models';
import { SortOption } from 'shared/models/subscription.model';
import { NAVIGATION_URLS } from 'src/constants/navigation';

interface UseDisplayDataProps {
  filterValues: QueryParamsFilter;
  filters: Array<string[]>;
  searchString: string;
  subscriptionsSet?: string;
}

export function useFetchViewOnlySubscriptions(isInDiscovery: boolean) {
  const { onShowNotification } = useModalAndNotification();
  const canViewAllSubscriptions = useCheckUserPermission(PERMISSION.ACCESS_ALL_SUBSCRIPTIONS);
  const queryKeyViewOnlySubscriptions = isInDiscovery
    ? ['subscriptions-view-only-in-discovery', true]
    : 'view-only-subscriptions';

  return useFetchData<Subscription[] | undefined>(queryKeyViewOnlySubscriptions, fetchViewOnlySubscriptions, {
    enabled: !canViewAllSubscriptions,
    onError: (error) => {
      onShowNotification(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    refetchOnWindowFocus: false,
    retry: 1,
  });
}

export function useFetchSubscriptions(isInDiscovery = false) {
  const { onShowNotification } = useModalAndNotification();
  const queryKey = isInDiscovery ? ['subscriptions-in-discovery', true] : 'subscriptions';

  return useFetchData<Subscription[]>(queryKey, fetchSubscriptions, {
    onError: (error) => {
      onShowNotification(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    refetchOnWindowFocus: false,
    retry: 1,
  });
}

export const useDisplayData = ({ filterValues, filters, searchString, subscriptionsSet }: UseDisplayDataProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const [loadingDisplayData, setLoadingDisplayData] = useState<boolean>(true);
  const [displayData, setDisplayData] = useState<Subscription[]>();
  const [sortOptionForDiscoveredTools, setSortOptionForDiscoveredTools] = useState<SortOption>(
    getDefaultDiscoveredToolsSortingOption()
  );

  let isCompanyTools = isCompanyToolsRoute(location.pathname);
  let isSubscriptionInDiscovery = isNewSolutionsRoute(location.pathname) || isToolRequestsRoute(location.pathname);
  let isDiscoveredSubscriptionRoute = isDiscoveredRoute(location.pathname);

  if (subscriptionsSet) {
    if (subscriptionsSet === 'discovery') {
      isCompanyTools = false;
      isSubscriptionInDiscovery = false;
      isDiscoveredSubscriptionRoute = true;
    }
  }

  const subscriptionsTodos = queryClient.getQueryData<TodoAndStatistics[]>('subscriptions-todos');

  const hasDisplayData = displayData && displayData.length > 0;
  const sortBy = new URLSearchParams(location.search).get('sort') ?? '0';
  const isArchiveView = checkIsArchiveSubscriptionView(`${location.pathname}${location.search}`);
  const canViewAllSubscriptions = useCheckUserPermission(PERMISSION.ACCESS_ALL_SUBSCRIPTIONS);
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;
  const company = useQueryClient().getQueryData('company') as Company;

  const { data: subscriptionsData, isLoading: isLoadingSubscriptions } =
    useFetchSubscriptions(isSubscriptionInDiscovery);

  const { data: viewOnlySubscriptions, isLoading: isLoadingViewOnlySubscriptions } =
    useFetchViewOnlySubscriptions(isSubscriptionInDiscovery);

  const isLoading = isLoadingSubscriptions || isLoadingViewOnlySubscriptions;

  const subscriptions = useMemo(() => {
    return subscriptionsData?.map((subscription) => {
      const data = subscriptionsTodos?.find((item) => item.subscriptionId === subscription.id);
      if (data) {
        return { ...subscription, todoStatistics: data?.todoStatistics, todos: data?.todos };
      }
      return subscription;
    });
  }, [subscriptionsData, subscriptionsTodos]);

  useEffect(() => {
    if (isLoading) return;

    let displayData;
    let combinedActiveSubscriptions: Array<Subscription> = [];

    if (subscriptions) {
      combinedActiveSubscriptions.push(...subscriptions);
    }

    if (viewOnlySubscriptions) {
      combinedActiveSubscriptions.push(...viewOnlySubscriptions);
    }

    const toolOwnerId = new URLSearchParams(location.search).get('toolOwner');

    if (isCompanyTools && canViewAllSubscriptions && !toolOwnerId) {
      combinedActiveSubscriptions = combinedActiveSubscriptions?.filter(
        (item) => Number(item.state) !== SubscriptionStatus.discovered
      );
    }

    if (isDiscoveredSubscriptionRoute) {
      combinedActiveSubscriptions = combinedActiveSubscriptions?.filter(
        (item) => Number(item.state) === SubscriptionStatus.discovered
      );
    } else if (!filterValues.status) {
      combinedActiveSubscriptions = combinedActiveSubscriptions?.filter(
        (item) => DEFAULT_STATE_OPTIONS[Number(item.state)] !== INACTIVE_STATE
      );
    }

    if (searchString && !isSubscriptionInDiscovery) {
      displayData = filterSubscriptions({ activeSubscriptions: combinedActiveSubscriptions, searchString });
    } else {
      displayData = filterSubscriptions({ activeSubscriptions: combinedActiveSubscriptions, searchString });
    }

    if (location.state) {
      if (location.state === MY_SUBSCRIPTIONS_STATE) {
        const toolOwnerFilter = ['toolOwner', String(filterValues.toolOwner)];
        displayData = filterSubscriptionByFilter([toolOwnerFilter], displayData as Subscription[]);

        const mySubscriptionsQueryParams = `${NAVIGATION_URLS.SUBSCRIPTIONS}?sort=0&toolOwner=${toolOwnerId}`;
        history.replace(mySubscriptionsQueryParams);
      }

      if (location.state === SUBSCRIPTIONS_TEXT.toLowerCase()) {
        displayData = filterSubscriptionByFilter([], displayData as Subscription[]);
      }
    } else if (!location.state) {
      if (filters.length) displayData = filterSubscriptionByFilter(filters, displayData as Subscription[]);
      else displayData = filterSubscriptionByFilter([], displayData as Subscription[]);
    } else if (isArchiveView) {
      const statusFilter = ['status', String(SubscriptionStatus.inactive)];
      displayData = filterSubscriptionByFilter([statusFilter], displayData as Subscription[]);
    }

    const sortedDisplayData = isDiscoveredSubscriptionRoute
      ? sortSubscriptions(displayData, { sortOption: sortOptionForDiscoveredTools })
      : sortSubscriptions(displayData, { sortById: sortBy });

    setDisplayData(sortedDisplayData);
    setLoadingDisplayData(false);
  }, [
    subscriptions,
    viewOnlySubscriptions,
    filterValues.status,
    filterValues.toolOwner,
    filters,
    history,
    isLoading,
    location.pathname,
    location.search,
    location.state,
    searchString,
    sortBy,
    isArchiveView,
    isSubscriptionInDiscovery,
    isDiscoveredSubscriptionRoute,
    isCompanyTools,
    canViewAllSubscriptions,
    sortOptionForDiscoveredTools,
  ]);

  const activeSubscriptions = useMemo(() => {
    const combinedActiveSubscriptions: Subscription[] = [];
    if (!isLoading) {
      if (subscriptions) {
        combinedActiveSubscriptions.push(
          ...subscriptions.filter((item) => DEFAULT_STATE_OPTIONS[Number(item.state)] !== INACTIVE_STATE)
        );
      }
      if (viewOnlySubscriptions) {
        combinedActiveSubscriptions.push(
          ...viewOnlySubscriptions.filter((item) => DEFAULT_STATE_OPTIONS[Number(item.state)] !== INACTIVE_STATE)
        );
      }
    }

    if (isCompanyTools && canViewAllSubscriptions) {
      return combinedActiveSubscriptions.filter((item) => Number(item.state) !== SubscriptionStatus.discovered);
    }
    return combinedActiveSubscriptions;
  }, [subscriptions, viewOnlySubscriptions, isCompanyTools, canViewAllSubscriptions, isLoading]);

  const onSortChange = (sortOption: SortOption) => {
    setSortOptionForDiscoveredTools(sortOption);
    saveDiscoveredToolSorting(sortOption);
    trackDiscoveredSortingAction({ companyId: company.id as string, sortOption, userId: user.id as string });
  };

  return {
    activeSubscriptions,
    displayData,
    hasDisplayData,
    isLoading,
    loadingDisplayData,
    onSortChange,
    setDisplayData,
  };
};
