/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, LinkProps, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { StyledBreadcrumbs } from './breadcrumbs.styles';
import { useBreadcrumbName } from './hooks/use-breadcrumb-name';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

interface BreadcrumbsProps {
  finalLink?: string;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ finalLink }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x: any) => x);
  const breadcrumbNameMap = useBreadcrumbName(finalLink);

  return (
    <StyledBreadcrumbs
      aria-label='breadcrumb'
      data-testid='breadcrumb'
      separator={<Icon size='small'>navigate_next</Icon>}>
      <Typography variant='body1' color='text.secondary'>
        <LinkRouter underline='hover' color='text.secondary' to='/' sx={{ paddingLeft: 0 }}>
          {t('breadcrumbs:home_link_text')}
        </LinkRouter>
      </Typography>

      {pathnames.map((_: any, index: number) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography key={to} variant='label' color='text.primary' data-testid='breadcrumb-current'>
            {breadcrumbNameMap[to] || finalLink}
          </Typography>
        ) : (
          <Typography variant='body1' key={to}>
            <LinkRouter underline='hover' color='inherit' to={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          </Typography>
        );
      })}
    </StyledBreadcrumbs>
  );
};
