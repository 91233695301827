import { Box } from '@mui/material';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { SsoIntegrationToolName } from 'libs/constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  trackDisconnectedForDiscovery,
  trackDisconnectedForDiscoveryError,
} from 'shared/logic/event-tracking/usage-analytics.events';
import { RootState } from 'shared/store';
import { disconnectFromJumpCloud } from 'shared/store/common/jumpCloudConnect.slice';
import { useAppSelector } from 'shared/store/hooks';
import { useModalAndNotification, useUser } from 'src/app/hooks';
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from 'src/constants/common';

import { DrawerHeader, ManageSSOCardFooter } from '../../components';
import { JumpCloudConnectSection } from './jump-cloud-connect-section';

export const ManageJumpCloudSSOConnection: FC = () => {
  const { isConnected } = useAppSelector(
    (state: RootState) =>
      state.common.appDrawer.data as {
        isConnected: boolean;
      }
  );
  const dispatch = useDispatch();
  const user = useUser();
  const location = useLocation();

  const { t } = useTranslation();
  const { onShowNotification } = useModalAndNotification();

  const baseTrackingEventData = {
    companyId: user.companyId ?? '',
    sourceName: SsoIntegrationToolName.JUMP_CLOUD,
    userId: user.id ?? '',
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', mx: 5, pt: 8 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
        <DrawerHeader isConnected={isConnected} ssoProviderName='JumpCloud' />
        {!isConnected && <JumpCloudConnectSection />}
      </Box>
      <ManageSSOCardFooter
        onDisconnect={() => {
          dispatch(
            disconnectFromJumpCloud({
              companyId: user.companyId as string,
              onError: (errorMessage) => {
                trackDisconnectedForDiscoveryError({ ...baseTrackingEventData, errorMessage }, location.pathname);
                onShowNotification(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
              },
              onSuccess: () => {
                trackDisconnectedForDiscovery(baseTrackingEventData, location.pathname);
                onShowNotification(
                  t('manage_sso_integrations_view:successfully_disconnected', {
                    ssoProviderName: SsoIntegrationToolName.JUMP_CLOUD,
                  }),
                  NotificationAlertType.Success
                );
              },
            })
          );
        }}
      />
    </Box>
  );
};
