import { PERMISSION } from 'libs/enums/permissions';
import { useQueryClient } from 'react-query';
import { getRolePermissions } from 'shared/logic/permissions.logic';
import { RolesPermissionsList } from 'shared/models';

import { useFetchData } from './useFetchData';

export const useRolesAndPermissionList = () => {
  const queryClient = useQueryClient();
  const rolesAndPermissionsQueryKey = 'roles-and-permissions';
  const rolesAndPermissions = queryClient.getQueryData<RolesPermissionsList[]>(rolesAndPermissionsQueryKey);

  useFetchData<RolesPermissionsList[] | undefined>(rolesAndPermissionsQueryKey, getRolePermissions, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const canAccessAllSubscriptions = (role?: string) => {
    const currentRole = rolesAndPermissions?.find((item) => item.role === role?.toLowerCase());
    return currentRole?.permissions.includes(PERMISSION.ACCESS_ALL_SUBSCRIPTIONS);
  };

  return {
    canAccessAllSubscriptions,
    rolesAndPermissions,
  };
};
