import { MarkdownReaderComponent } from 'components/markdown-reader/markdown-reader.component';
import { EditorValue } from 'components/wysiwyg-editor/wysiwyg-editor.component.props';
import useSessionStorage from 'hooks/useSessionStorage';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import {
  trackReplyDeleteClicked,
  trackReplyEditCancelClicked,
  trackReplyEditClicked,
  trackReplyEditSaveClicked,
} from 'shared/logic/event-tracking/comment-replies.events';
import {
  getFormattedHTMLWithNoWhiteSpaces,
  getMarkdownFromComment,
  getMarkDownTextWithMention,
  showdownConverter,
} from 'shared/logic/subscription-item.logic';
import { Document } from 'shared/models';

import { useDeleteReply } from '../hooks/use-delete-reply';
import { useEditReply } from '../hooks/use-edit-reply';
import { useFetchReplies } from '../hooks/use-fetch-replies.hook';
import { CommentEditActions } from './comment-edit-actions.component';
import { AuthorDateInfo } from './comment-item-elements.component';
import { DropdownActions } from './dropdown-actions.component';

interface CommentThreadProps {
  commentId: string;
  documents: Document[];
  onListFetched(): void;
  editorComponent(
    text: string,
    onTextChange: (t: string) => void,
    onReplySave: (editorState: EditorValue) => void,
    hideImageUploadButton: boolean
  ): JSX.Element;
  isUploadingDocument: boolean;
}

export const CommentThread = forwardRef<HTMLDivElement, CommentThreadProps>(
  ({ commentId, documents, editorComponent, isUploadingDocument, onListFetched }, ref) => {
    const queryString = new URLSearchParams(window.location.search);
    const queryStringCommentId = queryString.get('commentId');
    const queryStringReplyId = queryString.get('replyId');

    const hasScrolledToBottom = useRef(false);

    const {
      data: replies,
      isFetched: areRepliesLoaded,
      isLoading,
    } = useFetchReplies(commentId, () => {
      if (!hasScrolledToBottom.current) {
        onListFetched();
        hasScrolledToBottom.current = true;
      }
    });

    useEffect(() => {
      const isTheQueriedComment = queryStringCommentId === commentId;
      if (areRepliesLoaded && !hasScrolledToBottom.current && isTheQueriedComment && queryStringReplyId) {
        onListFetched();
        hasScrolledToBottom.current = true;
      }
    }, [areRepliesLoaded, queryStringCommentId, queryStringReplyId, onListFetched, commentId]);

    const { isLoading: isEditingReply, mutateAsync: saveReply } = useEditReply(commentId, {
      onSuccess: () => {
        setEditedThreadId('0');
        trackReplyEditSaveClicked();
        setReplyState('');
      },
    });

    const deleteReplyable = useDeleteReply(commentId);

    const [editedThreadId, setEditedThreadId] = useSessionStorage<string | undefined>(
      `editedThreadId:${commentId}`,
      '0'
    );
    const [replyState, setReplyState] = useSessionStorage<string>(`threadReplyState:${commentId}`, '');

    const handleReplyEditClick = (id = '0') => {
      setEditedThreadId(id);
      const comment = replies?.find((r) => r.id === id)?.comment || '';
      const markdownComment = getMarkDownTextWithMention(String(comment));

      setReplyState(showdownConverter.makeHtml(String(markdownComment)));
      trackReplyEditClicked();
    };

    const handleUpdateReply = async (id: string, text: string) => {
      const generatedCommentDomFromString = new DOMParser().parseFromString(text, 'text/html');
      const htmlMentions = generatedCommentDomFromString.querySelectorAll('[data-denotation-char="@"]');
      const commentToUpdate = await getMarkdownFromComment(text, [], htmlMentions);

      saveReply({ replyId: id, text: commentToUpdate });
    };

    const onReplyTextChange = useCallback(
      (replyText: string): void => {
        setReplyState(replyText);
      },
      [setReplyState]
    );

    const replyHasContent = getFormattedHTMLWithNoWhiteSpaces(replyState).length > 0;

    const handleReplyDelete = (id = '0') => {
      deleteReplyable(id);
      trackReplyDeleteClicked();
    };

    const handleCancelClick = () => {
      setEditedThreadId('0');
      setReplyState('');
      trackReplyEditCancelClicked();
    };

    if (isLoading) {
      return null;
    }

    return (
      <div className='subscription-comment__thread-items' data-testid='thread-replies'>
        {replies?.map((reply, i, row) => (
          <div
            key={reply.id}
            id={`comment-${commentId}-${reply.id}`}
            className='subscription-comment__thread-item'
            data-testid='thread-reply'>
            {editedThreadId === reply.id ? (
              <>
                {editorComponent(
                  replyState,
                  onReplyTextChange,
                  (editorValue) => {
                    handleUpdateReply(reply.id, String(editorValue));
                  },
                  true
                )}
                <CommentEditActions
                  onCancelClicked={() => {
                    handleCancelClick();
                  }}
                  onSaveClicked={() => handleUpdateReply(reply.id, replyState)}
                  isCancelDisabled={isUploadingDocument || isEditingReply}
                  isSubmitDisabled={!replyHasContent || isEditingReply || isUploadingDocument}
                  isSubmitLoading={isEditingReply}
                />
              </>
            ) : (
              <>
                <div className='subscription-comment__author' data-testid='reply-header'>
                  <AuthorDateInfo
                    createdAt={reply.createdAt}
                    updatedAt={reply.updatedAt}
                    authorAvatarUrl={reply.authorAvatarUrl}
                    authorName={reply.authorName}
                    showAvatar
                  />
                </div>
                <div className='subscription-comment__content' data-testid='subscription-comment-content'>
                  <div className=''>
                    <div className='subscription-comment__text' data-testid='comment-text'>
                      <MarkdownReaderComponent text={getMarkDownTextWithMention(reply.comment)} documents={documents} />
                    </div>
                  </div>
                  <div className='subscription-comment__action-buttons' ref={i + 1 === row.length && ref ? ref : null}>
                    <DropdownActions
                      isEditFormToggled={false}
                      onDeleteClicked={() => handleReplyDelete(reply.id)}
                      onEditClicked={() => handleReplyEditClick(reply.id)}
                      authorId={reply.authorId}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    );
  }
);
