import { QueryClient } from 'react-query';
import { Subscription } from 'shared/models';
import { ReminderRenewalAlert } from 'shared/models/subscription-renewal-reminder-alert.model';

import { FooterProps } from '../components/footer/footer.props';
import { HeaderProps, HeaderStatuses } from '../components/header/header.props';
import { StatusBarProps } from '../components/status-bar/status-bar.props';
import { RENEWAL_REMINDER_QUERY_KEY } from '../hooks/use-save-renewal-details';

type GetRenewalStatusAlertVariant = {
  reminderData?: ReminderRenewalAlert;
  subscription?: Subscription;
};

export const getRenewalStatusAlertVariant = ({ reminderData, subscription }: GetRenewalStatusAlertVariant) => {
  let headerVariant: HeaderProps['variant'] = 'no-status';
  let statusBarVariant: StatusBarProps['variant'] = 'renewal';
  let footerVariant: FooterProps['variant'] = 'personalize-reminder';

  // Header variant
  if (reminderData?.status) headerVariant = reminderData.status as HeaderStatuses;

  // Status bar variant
  if (subscription?.autoRenewal || reminderData?.actionType === 'set_to_auto_renew') {
    statusBarVariant = 'auto-renewal';
    if (reminderData?.initiativeId) statusBarVariant = 'auto-renewal-initiative';
  } else if (reminderData?.initiativeId) statusBarVariant = 'renewal-initiative';

  // Footer variant
  if (!subscription?.renewalDate) {
    footerVariant = 'no-renewal-date';
  } else {
    if (reminderData?.initiativeId) {
      footerVariant = 'initiative';
    } else {
      footerVariant = 'no-initiative';

      if (reminderData?.status === 'upcoming') footerVariant = 'upcoming-renewal';
      if (reminderData?.actionType === 'set_to_auto_renew') footerVariant = 'submission';
      if (reminderData?.status === 'overdue') footerVariant = 'overdue-no-initiative';
    }

    if (reminderData?.status === 'cancelled' || reminderData?.status === 'completed') footerVariant = 'inactive';
  }

  return {
    footerVariant,
    headerVariant,
    statusBarVariant,
  };
};

export const getStatusBarProgress = (alertDaysInterval: number, numberOfDaysUntilRenewal: number) => {
  if (numberOfDaysUntilRenewal <= 0) return 100;
  if (numberOfDaysUntilRenewal > alertDaysInterval) return Math.max(50 - (numberOfDaysUntilRenewal / 365) * 50, 2);

  return 100 - (numberOfDaysUntilRenewal / alertDaysInterval) * 100;
};

export const invalidateRenewalReminder = (queryClient: QueryClient, subscriptionId: string) => {
  queryClient.invalidateQueries({ queryKey: [RENEWAL_REMINDER_QUERY_KEY, subscriptionId] });
};

export const getRenewalReminderData = (
  queryClient: QueryClient,
  subscriptionId: string
): Promise<ReminderRenewalAlert> | undefined => {
  return queryClient.getQueryData([RENEWAL_REMINDER_QUERY_KEY, subscriptionId]);
};
