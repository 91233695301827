import { Box, Button, IconButton } from '@mui/material';
import { Icon } from 'asteroids';
import { FC } from 'react';

import { SuccessConfirmationComponentProps } from '.';
import { ConfirmationAlertWrapper } from './success-confirmation.styles';

export const SuccessConfirmationComponent: FC<SuccessConfirmationComponentProps> = (props) => {
  const {
    buttonLabel,
    closeModal,
    description,
    hasSecondaryButton,
    onButtonClick,
    onSecondaryButtonClick,
    secondaryButtonLabel,
    title,
  } = props;

  const handleButtonClick = () => {
    onButtonClick?.();
    closeModal?.();
  };

  const handleSecondaryButtonClick = () => {
    onSecondaryButtonClick?.();
    closeModal?.();
  };

  return (
    <ConfirmationAlertWrapper>
      <Box className='closeButton'>
        <IconButton onClick={closeModal} data-testid='close-button'>
          <Icon color='action'>close</Icon>
        </IconButton>
      </Box>
      <Icon id='circle-check-icon' size='large' color='primary' data-testid='circle-check-icon'>
        check_circle
      </Icon>
      <p className='alert-main__message'>{title}</p>
      <p data-testid='alert-description-message' className='alert-description__message'>
        {description}
      </p>
      <Button onClick={handleButtonClick} variant='contained' color='primary' disableRipple data-testid='action-button'>
        {buttonLabel}
      </Button>
      {hasSecondaryButton && (
        <Button
          variant='text'
          color='secondary'
          onClick={handleSecondaryButtonClick}
          disableRipple
          data-testid='action-secondary-button'
          sx={{ mt: 2 }}>
          {secondaryButtonLabel}
        </Button>
      )}
    </ConfirmationAlertWrapper>
  );
};
