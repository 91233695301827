import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileMimeTypesAllowList, FileTypesAllowList } from 'shared/common.definitions';

import { FilesListComponent } from '../files-list/files-list.component';
import { DropzoneProps } from './dropzone.component.props';
import {
  DropzoneArea,
  DropzoneWrapper,
  ErrorText,
  FileInput,
  LinkText,
  MainText,
  SecondaryText,
} from './dropzone.component.styles';

export const DropzoneComponent: React.FC<DropzoneProps> = ({
  dropzoneRef,
  error,
  helperText,
  initFiles,
  onDropCallback,
  onRemoveCallback,
}) => {
  const [unsupportedFiles, setUnsupportedFiles] = useState<string[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const { t } = useTranslation();
  const hasUnsupportedFiles = unsupportedFiles.length > 0;

  dropzoneRef.current = {
    getFiles: () => files,
  };

  useEffect(() => {
    if (initFiles) setFiles(initFiles);
  }, [initFiles]);

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section';

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (hasUnsupportedFiles) {
      setUnsupportedFiles([]);
    }
  };

  const onDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { files } = event.currentTarget;
    if (files) {
      const numberOfFiles = files.length;
      let filesArray: (File | null)[] = [];
      let unsupportedFilesArray = [];

      for (let i = 0; i < numberOfFiles; i++) {
        if (!FileMimeTypesAllowList.includes(files.item(i)?.type as string)) {
          unsupportedFilesArray.push(files.item(i)?.name || '');
        } else {
          filesArray.push(files.item(i));
        }
      }

      if (unsupportedFilesArray.length > 0) {
        unsupportedFilesArray = unsupportedFilesArray.filter((fileName) => Boolean(fileName));
        setUnsupportedFiles(unsupportedFilesArray);
      } else {
        setUnsupportedFiles([]);
      }

      filesArray = filesArray.filter((file) => file !== null);
      setFiles((oldState) => {
        return [...(oldState || []), ...filesArray] as File[];
      });
      onDropCallback?.(filesArray as File[]);
    }
  };

  const onRemoveFile = (index: number) => {
    setFiles((oldState) => {
      const newState = [...(oldState || [])];
      newState.splice(index, 1);
      onRemoveCallback?.(newState);

      return newState;
    });
  };

  return (
    <>
      <DropzoneWrapper>
        <DropzoneArea error={`${hasUnsupportedFiles || error}`} hover={`${isHovered}`}>
          <MainText variant='body'>
            {t(`${pathToTranslation}.right_section.workflow_request_section.documents_section.dropzone.main_text1`)}
            <LinkText variant='body'>
              {t(`${pathToTranslation}.right_section.workflow_request_section.documents_section.dropzone.main_text2`)}
            </LinkText>
            {t(`${pathToTranslation}.right_section.workflow_request_section.documents_section.dropzone.main_text3`)}
          </MainText>
          {!hasUnsupportedFiles && (
            <SecondaryText variant='body'>
              {t(`${pathToTranslation}.right_section.workflow_request_section.documents_section.dropzone.sub_text`, {
                fileTypes: [...FileTypesAllowList.slice(0, -1), `or ${FileTypesAllowList.slice(-1)}`].join(', '),
              })}
            </SecondaryText>
          )}
          {hasUnsupportedFiles && (
            <ErrorText variant='body'>
              {`${t(
                `${pathToTranslation}.right_section.workflow_request_section.documents_section.dropzone.unsupported_file_type`
              )}${unsupportedFiles.join(', ')}`}
            </ErrorText>
          )}
        </DropzoneArea>
        <FileInput
          type='file'
          value=''
          multiple
          onDragOver={onDragOver}
          onChange={onDrop}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
        {helperText && (
          <Typography variant='small' mt={0.5} px={2} color='text.secondary'>
            {helperText}
          </Typography>
        )}
      </DropzoneWrapper>
      {files && <FilesListComponent files={files} onRemove={onRemoveFile} />}
    </>
  );
};
