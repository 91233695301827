import { sub } from 'date-fns';
import * as yup from 'yup';

import { alternativeToolsDefaultValue } from './renewal-default-form.constants';
import { FormValues } from './renewal-default-form.props';

export const defaultFormValues: FormValues = {
  alternativeTools: alternativeToolsDefaultValue,
  collaborators: [],
  cost: '',
  description: '',
  dueDate: undefined,
  numberOfLicenses: '',
  paymentFrequency: null,
  toolName: null,
};

export const formValidationSchema = (isCreate = true) =>
  yup.object().shape({
    dueDate: yup.date().min(sub(new Date(), { days: 1 })),
    ...(isCreate && {
      toolName: yup
        .object()
        .shape({
          id: yup.string(),
          label: yup.string(),
          prefix: yup.object(),
        })
        .required(),
    }),
  });
