import './comment-template.styles.scss';

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { WysiwygEditorComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import type { EditorValue } from 'components/wysiwyg-editor/wysiwyg-editor.component.props';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { CommentDocument } from 'shared/common.definitions';
import { isNewSolutionsRoute, isToolRequestsRoute } from 'shared/helpers/common.helper';
import {
  addSubscriptionComment,
  CUSTOM_COMMENT_TEMPLATE_ID,
  getFormattedCommentTemplates,
  getFormattedHTMLWithNoWhiteSpaces,
  getMarkdownFromComment,
} from 'shared/logic/subscription-item.logic';
import { AddSubscriptionCommentModel, Subscription } from 'shared/models';

import { CommentTemplateFeatureProps } from '.';
import { CommentButton } from './comment-button.component';

type CommentTemplateState = {
  [key: string]: { isEdited: boolean; template: string };
};

export const CommentTemplateFeature: FC<CommentTemplateFeatureProps> = (props) => {
  const { commentTemplates, showNotification, subscriptionId } = props;

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [state, setState] = useState<CommentTemplateState>({});
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const subscription = queryClient.getQueryData<Subscription>(['subscription', subscriptionId]);
  const modifiedCommentTemplates = getFormattedCommentTemplates(commentTemplates, subscription);

  const currentTabKey = modifiedCommentTemplates?.[currentTabIndex]?.name;
  const commentText = state[String(currentTabKey)]?.template;
  const templateId = modifiedCommentTemplates?.[currentTabIndex]?.id;

  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [documents, setDocuments] = useState<CommentDocument[]>([]);

  const { isLoading: isSubmitting, mutate: mutateSubscriptionComment } = useMutation(addSubscriptionComment);

  const getInitialState = useCallback(() => {
    const state: CommentTemplateState = {};
    state.Comment = { isEdited: false, template: '' };
    return state;
  }, []);

  useEffect(() => {
    setCurrentTabIndex(0);
  }, [subscription?.state]);

  const onCommentTextChange = (value: string): void => {
    setState((prevState) => ({
      ...prevState,
      [`${modifiedCommentTemplates?.[currentTabIndex]?.name}`]: { isEdited: true, template: value },
    }));
  };

  const handleAddComment = async (comment: string) => {
    let markDownComment = comment;
    const toolType = isToolRequestsRoute(pathname) || isNewSolutionsRoute(pathname) ? 'Tool request' : 'Subscription';

    const generatedCommentDomFromString = new DOMParser().parseFromString(comment, 'text/html');
    const htmlMentions = generatedCommentDomFromString.querySelectorAll('[data-denotation-char="@"]');

    markDownComment = await getMarkdownFromComment(comment, documents, htmlMentions);

    const data: AddSubscriptionCommentModel = {
      comment: markDownComment,
      subscriptionId,
      templateId: templateId !== CUSTOM_COMMENT_TEMPLATE_ID ? templateId : undefined,
    };

    mutateSubscriptionComment(data, {
      onError: (error) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSuccess: () => {
        showNotification?.(`${toolType} comment added successfully!`, NotificationAlertType.Success);

        setState(getInitialState);

        if (documents.length) queryClient.invalidateQueries('subscriptionDocuments');
        queryClient.invalidateQueries('subscription-history');
        queryClient.invalidateQueries('auditLogs');
        queryClient.invalidateQueries(['subscription', subscriptionId]);
      },
    });
  };

  const editorCommentText = getFormattedHTMLWithNoWhiteSpaces(commentText);

  const handleEditorCommentSave = (comment: EditorValue) => handleAddComment(String(comment));

  return (
    <div className='comment-editor pb-3' data-testid='comment-editor'>
      <WysiwygEditorComponent
        dataTestId='comment-templates-editor'
        showNotification={showNotification}
        value={commentText}
        subscriptionId={subscriptionId}
        placeholder={t('subscription_detail_view:subscription_comment_section.markdown_editor_placeholder_text')}
        onChange={onCommentTextChange}
        setIsUploadingDocument={setIsUploadingDocument}
        setDocuments={setDocuments}
        handleSaveCommentShortCutPress={handleEditorCommentSave}
        bottomSectionStyles={{ bottom: '-2.5rem', left: '0' }}
      />
      <CommentButton
        disabled={!editorCommentText || isUploadingDocument || isSubmitting}
        onClick={() => handleAddComment(commentText)}
        submitting={isSubmitting}
        isActive={!!(editorCommentText && !isSubmitting)}
      />
    </div>
  );
};
