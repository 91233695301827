import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Icon } from 'asteroids';
import { FC } from 'react';

import { DialogComponentProps } from './dialog.component.props';

const SUCCESS_ICON = 'check_circle_outline';

export const DialogComponent: FC<DialogComponentProps> = ({
  actionBtnColor = 'error',
  actionBtnLabel,
  cancelBtnLabel,
  disabledActionBtn = false,
  message,
  noActionBtn = false,
  onAction,
  onClose,
  open,
  successIcon = false,
  title,
}) => (
  <Dialog open={open} onClose={onClose} disableEnforceFocus>
    <DialogTitle sx={{ alignItems: 'center', display: 'flex', padding: '1.5rem 1rem 1rem' }} data-testid='dialog-title'>
      {successIcon && (
        <Icon color='success' sx={{ marginRight: '.5rem' }}>
          {SUCCESS_ICON}
        </Icon>
      )}
      {title}
    </DialogTitle>
    <DialogContent sx={{ padding: '0 1rem' }}>
      <DialogContentText>
        <Typography data-testid='dialog-message' color='success' fontWeight={300}>
          {message}
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions sx={{ padding: '1rem' }}>
      <Button
        variant='text'
        color='secondary'
        onClick={onClose}
        size='small'
        data-testid={
          cancelBtnLabel ? `dialog-button-${cancelBtnLabel.replace(/\s+/g, '-').toLowerCase()}` : 'dialog-button-cancel'
        }>
        {cancelBtnLabel}
      </Button>
      {!noActionBtn && (
        <Button
          variant='contained'
          color={actionBtnColor}
          onClick={onAction}
          disabled={disabledActionBtn}
          size='small'
          data-testid={
            actionBtnLabel
              ? `dialog-button-${actionBtnLabel.replace(/[\s,]+/g, '-').toLowerCase()}`
              : 'dialog-action-button'
          }>
          {actionBtnLabel}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);
