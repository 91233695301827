import './relevant-document.component.scss';

import { CircularProgress, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import classnames from 'classnames';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { FileTypesAllowList } from 'shared/common.definitions';
import { computeChecksumMd5, decodeIdFromRoute, shortenFileName } from 'shared/helpers/common.helper';
import { trackSubscriptionDocumentUpload } from 'shared/logic/event-tracking/documents.events';
import {
  getCommaStrippedFile,
  getPresignedUploadUrl,
  mutateSubscriptionDocument,
  uploadFileToPresignedUrl,
} from 'shared/logic/subscription-item.logic';
import { Company, PresignedUploadUrl } from 'shared/models';

import { RelevantDocumentComponentProps } from './relevant-document.component.props';

type UploadDocument = {
  file: File;
  presignedUploadUrl: PresignedUploadUrl;
};

export const RelevantDocumentComponent: FC<RelevantDocumentComponentProps> = (props) => {
  const { category, categoryTranslationKey, document, showNotification } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation();

  const [selectedDocuments, setSelectedDocuments] = useState<File[]>();
  const [isUploadingDocument, setIsUploadingDocument] = useState<boolean>(false);

  const subscriptionId = decodeIdFromRoute(location.pathname);
  const company = queryClient.getQueryData<Company>('company');

  const { mutateAsync } = useMutation(({ file, presignedUploadUrl }: UploadDocument) =>
    uploadFileToPresignedUrl(presignedUploadUrl.url, file)
  );

  const onUploadDocument = async (file: File) => {
    const commaStrippedFile = getCommaStrippedFile(file);

    const checksum = await computeChecksumMd5(commaStrippedFile);

    const formValues = {
      category,
      checksum,
      fileName: commaStrippedFile.name,
      subscriptionId,
    };

    try {
      const presignedUploadUrl: PresignedUploadUrl = await getPresignedUploadUrl(String(company?.id));

      await mutateAsync({
        file: commaStrippedFile as File,
        presignedUploadUrl,
      });

      await mutateSubscriptionDocument({
        data: { ...formValues, fileKey: presignedUploadUrl.key },
        subscriptionId,
      });

      trackSubscriptionDocumentUpload(category);
    } catch {
      const message = t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.upload_error_message'
      );
      showNotification?.(message, NotificationAlertType.Error);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept: FileTypesAllowList,
    multiple: true,
    onDrop: async (files) => {
      setIsUploadingDocument(true);
      setSelectedDocuments(files);
      const uploads: unknown[] = [];
      files.forEach((file) => {
        uploads.push(onUploadDocument(file));
      });
      await Promise.all(uploads);
      await queryClient.invalidateQueries('subscriptionDocuments');
      await queryClient.invalidateQueries(['subscription', subscriptionId]);
      await queryClient.invalidateQueries('subscriptions');
      await queryClient.invalidateQueries('subscriptions-todos');
      await queryClient.invalidateQueries('auditLogs');
      setIsUploadingDocument(false);
      setSelectedDocuments(undefined);
    },
    onDropRejected() {
      const message = t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.upload_file_type_error_message'
      );
      showNotification?.(message, NotificationAlertType.Error);
    },
  });

  if (!selectedDocuments?.length && !document) {
    return (
      <div
        {...getRootProps({
          className: 'document-upload-container not-uploaded',
        })}
        data-testid={`${categoryTranslationKey}_box`}>
        <input {...getInputProps({ name: '' })} />
        <Icon color='primary'>upload</Icon>
        <div className={classnames('document-name-category-container')}>
          <div className={classnames('document-category')}>
            {t(
              `subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.${categoryTranslationKey}`
            )}
          </div>
          <Typography variant='small' color='primary' className='document-name'>
            {t('subscription_detail_view:tabs_component_section.subscription_document_tab.upload_document_text')}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames('document-upload-container')} data-testid={`${categoryTranslationKey}_box`}>
      <Icon color='primary' size='large'>
        file_copy
      </Icon>

      <div className={classnames('document-name-category-container')}>
        <div className={classnames('document-category')}>
          {t(
            `subscription_detail_view:tabs_component_section.subscription_document_tab.document_categories.${categoryTranslationKey}`
          )}
        </div>
        <Typography variant='small' color='primary' className='document-name'>
          {!selectedDocuments ? (
            <a href={document?.url} target='_blank' rel='noreferrer'>
              {shortenFileName(document?.fileName as string, 12)}
            </a>
          ) : (
            <div>
              <a href='#'>
                {shortenFileName(selectedDocuments[0].name, 12)}{' '}
                {selectedDocuments.length > 1 &&
                  t('subscription_detail_view:tabs_component_section.subscription_document_tab.more_text', {
                    count: selectedDocuments.length - 1,
                  })}
              </a>
            </div>
          )}
        </Typography>
      </div>

      {isUploadingDocument ? (
        <CircularProgress size={22} />
      ) : (
        <Icon id='check-icon' color='primary'>
          check_circle
        </Icon>
      )}
    </div>
  );
};
