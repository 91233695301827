import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Icon } from 'asteroids';
import { FC, useState } from 'react';
import { fetchVendors } from 'shared/logic/subscriptions.logic';
import { VendorType } from 'shared/models';

import { useDebounce, useFetchData } from '../../hooks';
import { TypeaheadComponent } from '..';
import { VendorSelectInputComponentProps } from './vendor-select-input.component.props';

export const VendorSelectInputComponent: FC<VendorSelectInputComponentProps> = (props) => {
  const {
    debouncedValue,
    disabled,
    error,
    handleItemSelected,
    helperText,
    isMuiInput,
    isWorkflowRequest,
    onChange,
    placeholder,
    setDebouncedValue,
    setVendorName,
    value,
    vendorName,
  } = props;
  const [vendors, setVendors] = useState<VendorType[]>([]);
  const [defaultVendors, setDefaultVendors] = useState<VendorType[]>([]);
  const [isFocused, setFocused] = useState(false);

  const customListItem = (vendor: VendorType) => (
    <div className='vendor__custom-list'>
      {vendor?.vendorLogoUrl ? (
        <img src={vendor?.vendorLogoUrl} alt={vendor?.name} />
      ) : (
        <div data-testid='vendor__image--placeholder' className='vendor__image--placeholder'>
          {vendor?.name?.slice(0, 1)}
        </div>
      )}
      <div>
        <span>{vendor?.name}</span>
        <span>{vendor?.categoryName}</span>
      </div>
    </div>
  );

  useDebounce(setDebouncedValue, vendorName, 500);
  const { isFetching } = useFetchData<VendorType[]>(['vendors', debouncedValue], () => fetchVendors(debouncedValue), {
    enabled: Boolean(!debouncedValue || (debouncedValue && debouncedValue?.length >= 3)),
    onSuccess: (data: VendorType[]) => {
      if (defaultVendors.length === 0) setDefaultVendors(data);
      setVendors(data);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <TypeaheadComponent
      data={vendors}
      defaultData={defaultVendors}
      isFetching={isFetching}
      onItemSelected={handleItemSelected}
      value={value || ''}
      disabled={disabled}
      customListItem={customListItem}
      isWorkflowRequest={isWorkflowRequest}
      onTextChanged={(e) => {
        onChange(e);
        setVendorName(e.target.value);
      }}
      renderCustomInput={
        isMuiInput
          ? ({ autoFocus, disabled, inputTextRef, onChange, onClick, onFocus, onKeyDown, onKeyUp, value }) => (
              <TextField
                id='vendor-select'
                inputRef={inputTextRef}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onFocus={(ev) => {
                  onFocus?.(ev);
                  setFocused(true);
                }}
                onBlur={() => setFocused(false)}
                onClick={onClick}
                disabled={disabled}
                value={value || ''}
                label={placeholder}
                autoFocus={autoFocus}
                error={error}
                helperText={helperText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                      {!isFocused ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>}
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )
          : undefined
      }
      placeholder={placeholder}
    />
  );
};
