import { useContext } from 'react';

import { ModalAndNotificationsContext } from '../context/modal-notification.context';

export const useModalAndNotification = () => {
  const context = useContext(ModalAndNotificationsContext);

  if (!context) {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  }

  return context;
};
