import { FC } from 'react';
import { colors } from 'shared/theme';

export const WrappedEditPenIcon: FC<React.SVGProps<SVGElement>> = ({ fill, height, onClick, width }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox='0 0 17 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.5 9a.833.833 0 00-.833.833v5a.833.833 0 01-.834.834H3.167a.833.833 0 01-.834-.834V3.167a.833.833 0 01.834-.834h5a.833.833 0 100-1.666h-5a2.5 2.5 0 00-2.5 2.5v11.666a2.5 2.5 0 002.5 2.5h11.666a2.5 2.5 0 002.5-2.5v-5A.834.834 0 0016.5 9zM4 9.633v3.534a.833.833 0 00.833.833h3.534a.833.833 0 00.591-.242l5.767-5.775 2.367-2.316a.833.833 0 000-1.184L13.558.908a.833.833 0 00-1.183 0l-2.35 2.359-5.783 5.775A.833.833 0 004 9.633zm8.967-6.958l2.358 2.358-1.183 1.184-2.359-2.359 1.184-1.183zm-7.3 7.3l4.941-4.942 2.359 2.359-4.942 4.941H5.667V9.975z'
      fill={fill}
    />
  </svg>
);

WrappedEditPenIcon.defaultProps = {
  fill: colors.silverSand,
  height: 18,
  width: 17,
};
