import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useModalAndNotification } from 'hooks/index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { fetchSubscriptionDetails, saveSubscriptionDetails } from 'shared/logic/subscription-details.logic';
import { DetailsSaveSchema } from 'shared/models/subscription-details.model';
import { queryClient } from 'shared/reactQuery';

function useSaveSubscriptionDetails(subscriptionId: string) {
  const { onShowNotification } = useModalAndNotification();
  const { t } = useTranslation();

  return useMutation((details: DetailsSaveSchema) => saveSubscriptionDetails(subscriptionId, details), {
    onError: (error) => {
      onShowNotification(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
    onSuccess: async () => {
      onShowNotification(
        t('subscription_detail_view:details_view.toast.change_success'),
        NotificationAlertType.Success
      );
      await queryClient.invalidateQueries(['subscription-details', subscriptionId]);
      await queryClient.invalidateQueries('subscription-history');
    },
  });
}

function useFetchSubscriptionDetailsFields(subscriptionId: string) {
  return useQuery(
    ['subscription-details', subscriptionId],
    () => {
      return fetchSubscriptionDetails(subscriptionId);
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
}

export function useSubscriptionDetails(subscriptionId: string) {
  const { data: schema, error, isLoading: isLoadingSchema } = useFetchSubscriptionDetailsFields(subscriptionId);

  const { isLoading: isLoadingSaveSubscriptionDetails, mutate: saveSubscriptionDetails } =
    useSaveSubscriptionDetails(subscriptionId);

  return {
    error,
    isLoadingSaveSubscriptionDetails,
    isLoadingSchema,
    saveSubscriptionDetails,
    schema,
  };
}
