/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable no-empty-character-class */
export const URL_VALIDATOR_REGEX =
  /^((https?:)(\/\/\/?)([\w]*(?::[\w]*)?@)?([\d\w\.-]+)(?::(\d+))?)?([\/\\\w\.()-]*)?(?:([?][^#]*)?(#.*)?)*/i;

export const EMAIL_VALIDATOR_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USERNAME_VALIDATOR_REGEX =
  /^[a-zA-ZŠŒŽšœžŸ¥µÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝĄĘŁŻŹÓŃßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿąęłżźóń -]{2,50}$/;

export const MATCH_DUPLICATED_URL_SLASHES_REGEX = /(?<!:)\/+/gm;

export const tidySlashes = (url: string): string => url.replace(MATCH_DUPLICATED_URL_SLASHES_REGEX, '/');

export const escape = (str: string): string => str.replace(/[-[\]{}()*+?.,\\^$|]/g, '\\$&');
