import { DatePickerComponent } from 'components/index';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MethodTypes } from 'shared/common.definitions';

import { SubscriptionAlertFormFeatureProps } from './subscription-alert-form.feature.props';

export const SubscriptionAlertFormFeature: React.FC<SubscriptionAlertFormFeatureProps> = (
  props: SubscriptionAlertFormFeatureProps
) => {
  const {
    control,
    isSubscriptionInDiscovery,
    mutateMethod,
    mutateMethodRef,
    register,
    selectedAlert,
    setValue,
    subscriptionName,
  } = props;
  const { t } = useTranslation();

  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

  const pathToTranslation = 'common:modals.edit_alert_modal.body_section.form';
  const customMessage = useRef<string>(
    !isSubscriptionInDiscovery
      ? t(`${pathToTranslation}.header_section_text.main_header_create_alert_text`)
      : t(`${pathToTranslation}.header_section_text.main_header_create_deadline_text`)
  );

  const isValue = useRef<boolean>(false);

  const mutateMethodType = mutateMethodRef?.current || mutateMethod;

  useEffect(() => {
    if (mutateMethodType === MethodTypes.PATCH && !isValue.current) {
      setValue('title', selectedAlert?.title as string, { shouldValidate: true });
      setValue('date', new Date(selectedAlert?.date || ''), { shouldValidate: true });
      customMessage.current = !isSubscriptionInDiscovery
        ? t(`${pathToTranslation}.header_section_text.main_header_edit_alert_text`)
        : t(`${pathToTranslation}.header_section_text.main_header_edit_deadline_text`);

      isValue.current = true;
    }
  }, [isSubscriptionInDiscovery, mutateMethodType, selectedAlert?.date, selectedAlert?.title, setValue, t]);

  return (
    <>
      <p className='content'>
        {customMessage.current} <strong>{subscriptionName}</strong>
      </p>
      <p className='content'>
        {!isSubscriptionInDiscovery
          ? t(`${pathToTranslation}.header_section_text.sub_header_alert_text`)
          : t(`${pathToTranslation}.header_section_text.sub_header_deadline_text`)}
      </p>
      <div className='field is-expanded mt-3'>
        <div className='field-label has-text-left'>
          <label className='label'>{t('common:modals.edit_alert_modal.body_section.form.name_text_input_label')}</label>
        </div>
        <div className='control is-fullhd'>
          <input
            type='text'
            data-testid='alert-name-input'
            {...register('title')}
            className='input'
            placeholder={
              !isSubscriptionInDiscovery
                ? t(`${pathToTranslation}.alert_name_text_input_placeholder`)
                : t(`${pathToTranslation}.deadline_name_text_input_placeholder`)
            }
          />
        </div>
      </div>
      <div className='field is-fullwidth sastrify-changerenewaldate'>
        <div className='field-label has-text-left'>
          <label className='label'>
            {!isSubscriptionInDiscovery
              ? t(`${pathToTranslation}.alert_date_text_input_label`)
              : t(`${pathToTranslation}.deadline_date_text_input_label`)}
          </label>
        </div>
        <div className='sastrify-datepicker'>
          <Controller
            name='date'
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePickerComponent
                value={value}
                onChange={(newValue) => {
                  onChange(newValue);
                  setOpenDatePicker(false);
                }}
                openDatePicker={openDatePicker}
                setOpenDatePicker={setOpenDatePicker}
                minDate={new Date()}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};
