import { useTranslation } from 'react-i18next';
import { REQUEST_STATUS } from 'shared/common.definitions';
import { RequestWorkflowItem, Subscription } from 'shared/models';

import { MultiRequestToolAlertProps } from './multi-request-tool-alert.props';
import { StyledAlert } from './multi-requests-tool-alert.styles';

export const MultiRequestToolAlert: React.FC<MultiRequestToolAlertProps> = ({ onView, requestOrSubscription }) => {
  const { t } = useTranslation();

  let isRequested = false;
  let isRejected = false;
  let isActive = false;
  if ((requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.REJECTED) {
    isRejected = true;
  } else if (
    (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.ACTIVE ||
    (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.APPROVED
  ) {
    isRequested = true;
  } else if (
    (requestOrSubscription as Subscription).id ||
    (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED
  ) {
    isActive = true;
  }

  const toolName = requestOrSubscription.vendorName || requestOrSubscription.name;

  const getContent = () => {
    if (isRequested) {
      return {
        description: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.active_initiative_exists',
          {
            toolName,
          }
        ),
        viewButtonText: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.alert.view_button_text'
        ),
      };
    }
    if (isRejected) {
      return {
        description: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.rejected_initiative_exists',
          {
            toolName,
          }
        ),
        viewButtonText: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.alert.view_rejected_button_text'
        ),
      };
    }
    if (isActive) {
      return {
        description: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.subscription_exists',
          {
            toolName,
          }
        ),
        viewButtonText: t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.alert.view_button_text'
        ),
      };
    }
  };

  return (
    <StyledAlert
      data-testid='multi-request-warning-alert'
      actionIcon=''
      actionText={getContent()?.viewButtonText}
      description=''
      isIconVisible
      onAction={onView}
      severity='warning'
      title={String(getContent()?.description)}
    />
  );
};
