import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { FC, lazy } from 'react';
import { Route } from 'react-router';
import { UserRoles } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { RoutedProps } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { NAVIGATION_URLS } from 'src/constants/navigation';

const NotificationSettingsView = lazy(() => import('../views/notification-settings/notification-settings.view'));
const ToolsSpendImporterView = lazy(() => import('../views/tools-spend-importer/tools-spend-import.view'));
const UsageAnalyticsView = lazy(() => import('../views/usage-analytics/usage-analytics.view'));
const EmailIntegrationView = lazy(() => import('../views/connect2/email/email-integration.view'));
const GmiIntegrationView = lazy(() => import('../views/connect2/gmi/gmi-integration.view'));
const UsersAndAuthentication = lazy(() => import('../views/users-and-authentication/users-and-authentication.view'));
const ConnectRootView = lazy(() => import('../views/connect2/connect.root.view'));
const ErrorView = lazy(() => import('../views/error/error.view'));

export const SettingsRoutes: FC<RoutedProps> = (props) => {
  const userRole = useAppSelector((state) => state.authentication.user?.role);
  const isAdminUser = userRole === UserRoles.Admin;
  const isViewerRole = userRole === UserRoles.Viewer;

  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();
  const isUsageAnalyticsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.USAGE_ANALYTICS);
  const isUsageAnalyticsPurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.USAGE_ANALYTICS);
  return (
    <>
      <Route exact path={NAVIGATION_URLS.USERS_MANAGEMENT}>
        {isAdminUser ? <UsersAndAuthentication {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.DISCOVERY}>
        {!isViewerRole ? <ConnectRootView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.CONNECT_EMAIL}>
        {!isViewerRole ? <EmailIntegrationView /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.CONNECT_GETMYINVOICES}>
        {!isViewerRole ? <GmiIntegrationView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.TOOLS_SPENDS_IMPORTER}>
        {!isViewerRole ? <ToolsSpendImporterView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.USAGE_ANALYTICS}>
        {!isViewerRole && (isUsageAnalyticsEnabled || isUsageAnalyticsPurchasable) ? (
          <UsageAnalyticsView {...props} />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
      <Route exact path={NAVIGATION_URLS.NOTIFICATIONS}>
        <NotificationSettingsView {...props} />
      </Route>
    </>
  );
};
