import { useState } from 'react';

export function useEditorFocus(): [boolean, () => void, () => void] {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return [isFocused, handleFocus, handleBlur];
}
