/* eslint-disable prettier/prettier */
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import classnames from 'classnames';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  COMMENT_TEMPLATE_SCOPES,
  DocumentCategory,
  DrawerInfoType,
  ExtendedBlobType,
  featureFlags,
  googleTagManagerEvents,
  ModalType,
  SubscriptionStatus,
} from 'shared/common.definitions';
import { WrapperButton } from 'shared/helpers/styles.helper';
import { createAppHelpRequest, fireGTagManagerEvent } from 'shared/logic/company.logic';
import { trackHelpCenterRequestSubmitted } from 'shared/logic/event-tracking/help-center.events';
import { trackVendorInsightAlertClicked } from 'shared/logic/event-tracking/vendor-knowledge.events';
import { trackGlobalProcurementHelpRequestCreated } from 'shared/logic/event-tracking/workflow-requests.events';
import {
  addSubscriptionComment,
  getCompanyDocumentCategory,
  getFormattedCommentTemplates,
  getMarkdownFromComment,
  modifyCommentToIncludeSubscriptionDetailLink,
  uploadSubscriptionDocument,
} from 'shared/logic/subscription-item.logic';
import { NewSubscription, postNewSolution } from 'shared/logic/subscriptions.logic';
import {
  AddSubscriptionCommentModel,
  CommentTemplate,
  Company,
  SastrifyStore,
  Subscription,
  VendorType,
} from 'shared/models';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { AppUrl } from 'src/constants/appurl';

import { DialogContext } from '../../context/dialog.context';
import { WysiwygEditorComponent } from '..';
import { DropzoneComponent } from '../drop-zone';
import { SubscriptionInsightAlert } from '../subscription-insight-alert';
import { SubscriptionSelectInputComponent } from '../subscription-select-input';
import type { EditorValue } from '../wysiwyg-editor/wysiwyg-editor.component.props';
import { DrawerRequestHelpComponentProps } from '.';
import { DrawerRequestHelpWrapper } from './drawer-request-help.styles';
import { getIsSendButtonEnabled } from './utils/is-send-button-enabled';

type CommentTemplateState = {
  [key: string]: { template: string };
};

export const DrawerRequestHelpComponent: FC<DrawerRequestHelpComponentProps> = (props) => {
  const {
    commentTemplates,
    drawerInfoType,
    isGlobalRequestHelpDrawer = false,
    openSuccessConfirmationModal,
    showNotification,
    subscriptionId: providedSubscriptionId,
    toggleDrawer,
  } = props;

  const queryClient = useQueryClient();
  const isMounted = useRef<boolean>(true);
  const { t } = useTranslation();
  const history = useHistory();
  const { openDialog } = useContext(DialogContext);
  const isInsightAlertEnabled = useCompanyFeatureFlag(featureFlags.ENABLE_SUPPORT_INSIGHT_ALERT);

  const subscriptionIdRef = useRef<string | undefined>();
  const templateIdRef = useRef<string | undefined>();

  const { control, getValues, watch } = useForm({
    mode: 'onChange',
    shouldUnregister: true,
  });

  const user = useSelector((state: SastrifyStore) => state.authentication.user);
  const company = queryClient.getQueryData<Company>('company');
  const exploreData = queryClient.getQueryData<VendorType>('exploreData');
  const companyId = String(company?.id);

  const isExploreRequestSupport = Boolean(exploreData);

  const [isSendingAnotherRequest, setIsSendingAnotherRequest] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(-1);
  const [state, setState] = useState<CommentTemplateState>({});
  const [documents, setDocuments] = useState<ExtendedBlobType[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();

  const getSubscription = useCallback(() => {
    let subscription;
    if (providedSubscriptionId) {
      subscription = queryClient.getQueryData<Subscription>(['subscription', providedSubscriptionId]);
    }
    if (isGlobalRequestHelpDrawer && providedSubscriptionId) return selectedSubscription || subscription;
    if (isGlobalRequestHelpDrawer) return selectedSubscription;
    return isExploreRequestSupport ? { ...exploreData, state: SubscriptionStatus.requested } : subscription;
  }, [
    exploreData,
    isExploreRequestSupport,
    isGlobalRequestHelpDrawer,
    queryClient,
    selectedSubscription,
    providedSubscriptionId,
  ]);

  const isAppHelp = drawerInfoType === DrawerInfoType.SASTRIFY_APP_HELP;

  const clonedSubscription = getSubscription() as Subscription;
  const modifiedCommentTemplates = getFormattedCommentTemplates(
    commentTemplates,
    clonedSubscription as Subscription,
    true,
    isAppHelp ? COMMENT_TEMPLATE_SCOPES.APP_HELP : COMMENT_TEMPLATE_SCOPES.PROCUREMENT_HELP
  );

  const currentTabKey = modifiedCommentTemplates?.[currentTabIndex]?.name;
  const selectedCommentTemplates = modifiedCommentTemplates?.[currentTabIndex];
  const commentText = state[String(currentTabKey)]?.template;
  templateIdRef.current = selectedCommentTemplates?.id;

  const setInitialState = useCallback(() => {
    const state: CommentTemplateState = {};
    commentTemplates?.forEach((commentTemplate) => {
      state[`${commentTemplate.name}` as keyof CommentTemplateState] = {
        template: commentTemplate.template,
      };
    });
    state.Comment = { template: '' };
    return state;
  }, [commentTemplates]);

  useEffect(() => {
    if (commentTemplates && isMounted.current) {
      setState(setInitialState);
      isMounted.current = false;
    }
  }, [commentTemplates, setInitialState]);

  const { mutateAsync } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    },
  });

  const { mutateAsync: mutateSubscriptionComment } = useMutation(addSubscriptionComment);

  const { mutateAsync: mutateCreateAppHelpRequest } = useMutation(createAppHelpRequest);

  const { mutateAsync: mutateCreateNewSolution } = useMutation(postNewSolution, {
    onError: () => {
      const message = t('common:error_messages.add_new_solution');

      showNotification?.(message, NotificationAlertType.Error);
    },
  });

  const onTemplateTabClick = (tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };

  const onCommentTextChange = (value: string): void => {
    setState((prevState) => ({
      ...prevState,
      [`${modifiedCommentTemplates?.[currentTabIndex]?.name}`]: { template: value },
    }));
  };

  const onDocumentUpload = (files: ExtendedBlobType[]) => {
    setDocuments(files);
  };

  const handleDocumentUpload = async (id?: string, isAppHelp?: boolean) => {
    const uploads: unknown[] = [];

    documents.forEach((document) => {
      const values = {
        category: isAppHelp
          ? getCompanyDocumentCategory(DocumentCategory.other_documents)
          : DocumentCategory.miscellaneous,
        companyId,
        file: document,
        isCompanyDocument: isAppHelp,
        subscriptionId: isAppHelp ? undefined : id || providedSubscriptionId,
      };

      try {
        uploads.push(mutateAsync(values));
      } catch (error) {
        handleCloseDrawer();

        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      }
    });

    try {
      await Promise.all(uploads);
    } catch (error) {
      handleCloseDrawer();

      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    }
  };

  const createNewSolution = async (newSolution: NewSubscription, comment: string) => {
    await mutateCreateNewSolution(newSolution, {
      onSettled: (data) => {
        handleCloseDrawer();

        openDialog({
          confirmBtnText: t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.success_message_section.button_text'
          ),
          content: t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.success_message_section.new_tool_content_text',
            {
              name: data?.name || data?.vendorName,
            }
          ),
          onConfirm: () => {
            if (data) {
              history.push(AppUrl.getToolUrl(data, false, true));
            }
          },
          title: t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.success_message_section.title'
          ),
        });
      },

      onSuccess: async (data) => {
        const commentData: AddSubscriptionCommentModel = {
          comment,
          subscriptionId: data.id,
          templateId: String(selectedCommentTemplates?.id),
        };
        await handleDocumentUpload(data.id);

        mutateSubscriptionComment(commentData);
        queryClient.invalidateQueries(['subscription', String(data?.id)]);
      },
    });
  };

  const inputValue = watch('subscription');

  const id = isGlobalRequestHelpDrawer ? inputValue : providedSubscriptionId;

  subscriptionIdRef.current = id;

  const handleSaveRequest = async (comment: string) => {
    try {
      const generatedCommentDomFromString = new DOMParser().parseFromString(comment, 'text/html');
      const htmlMentions = generatedCommentDomFromString.querySelectorAll('[data-denotation-char="@"]');

      const markDownComment = await getMarkdownFromComment(comment, [], htmlMentions);

      const modifiedComment = modifyCommentToIncludeSubscriptionDetailLink({
        comment: markDownComment,
        isDocument: Boolean(documents.length),
        subscription: getSubscription() as Subscription,
      });

      const data: AddSubscriptionCommentModel = {
        comment: modifiedComment,
        subscriptionId: id || providedSubscriptionId || subscriptionIdRef.current,
        templateId: String(selectedCommentTemplates?.id || templateIdRef.current),
      };

      await handleDocumentUpload(id, isAppHelp);

      if (isExploreRequestSupport) {
        const newSolutionData: NewSubscription = {
          name: exploreData?.name,
          ownerId: user?.id,
          ownerName: user?.name,
          state: SubscriptionStatus.requested,
          vendorId: exploreData?.id,
        };
        await createNewSolution(newSolutionData, markDownComment);
        return;
      }

      if (isAppHelp) {
        await mutateCreateAppHelpRequest(
          {
            body: modifiedComment,
            companyId: String(companyId),
            templateId: String(selectedCommentTemplates?.id),
          },
          {
            onError: (error) => {
              showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
            },
            onSuccess: () => {
              handleCloseDrawer();
              openSuccessConfirmationModal?.(
                getSubscription() as Subscription,
                ModalType.AppHelp,
                selectedCommentTemplates?.id
              );
            },
          }
        );
        return;
      }

      await mutateSubscriptionComment(data, {
        onError: (error) => {
          showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
        },
        onSuccess: () => {
          setState(setInitialState);

          if (documents.length) queryClient.invalidateQueries('subscriptionDocuments');
          queryClient.invalidateQueries('subscription-history');
          queryClient.invalidateQueries('auditLogs');
          queryClient.invalidateQueries(['subscription', id]);

          handleCloseDrawer();

          let modalType = ModalType.RequestHelp;
          if (isGlobalRequestHelpDrawer) {
            modalType = ModalType.GlobalRequestHelp;
          }
          openSuccessConfirmationModal?.(getSubscription() as Subscription, modalType, selectedCommentTemplates?.id);
        },
      });
    } catch (error) {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    } finally {
      if (!isGlobalRequestHelpDrawer) setIsSendingAnotherRequest(false);
      if (isGlobalRequestHelpDrawer)
        trackGlobalProcurementHelpRequestCreated({
          companyId,
          subscriptionId: id || providedSubscriptionId || subscriptionIdRef.current,
          templateId: String(selectedCommentTemplates?.id || templateIdRef.current),
        });

      fireGTagManagerEvent(window, String(user?.email), googleTagManagerEvents.SubmitSupportOrProcurementRequest);
    }
  };

  const handleCloseDrawer = () => {
    toggleDrawer();
    setIsSendingAnotherRequest(false);
    setDocuments([]);
  };

  const handleRemoveDocument = (documentIndex: number) => {
    const updatedDocuments = documents.filter((_, index) => index !== documentIndex);
    setDocuments(updatedDocuments);
  };

  const { location } = history;

  const isExploreOrToolStoreView =
    (location.pathname.includes('/explore') || location.pathname.includes('/tool-store')) &&
    !isGlobalRequestHelpDrawer &&
    !isAppHelp;

  const showBottomSection = true;
  const selectTypeOfRequestText = isExploreOrToolStoreView
    ? t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.request_section.explore_request_title_text',
        {
          name: exploreData?.name,
        }
      )
    : t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.request_section.request_title_text'
      );

  const handleSubscriptionChangeEvent = (value: Subscription[]) => {
    setSelectedSubscription(value[0]);
    setCurrentTabIndex(-1);
    setDocuments([]);
  };

  const handleEditorCommentSave = (comment: EditorValue) => handleSaveRequest(String(comment));

  const isSendButtonEnabled = getIsSendButtonEnabled({
    commentText,
    isAppHelp,
    isExploreRequestSupport,
    isSendingAnotherRequest,
    providedSubscriptionId,
    selectedSubscription: getValues('subscription'),
    templateId: selectedCommentTemplates?.id,
    vendorId: exploreData?.id,
  });

  const shouldRenderInsightAlert =
    (!!selectedSubscription || (clonedSubscription?.companyId && clonedSubscription?.vendorId)) &&
    isInsightAlertEnabled;

  const handleInsightAlertClick = (sub: Subscription) => {
    trackVendorInsightAlertClicked({ companyId: sub.companyId, subscriptionId: sub.id, userId: user?.id });
    handleCloseDrawer();
  };

  return (
    <DrawerRequestHelpWrapper>
      <Box className='requestSection'>
        {isGlobalRequestHelpDrawer &&
          drawerInfoType !== DrawerInfoType.SASTRIFY_APP_HELP &&
          !isExploreOrToolStoreView && (
            <Box className='selectSubscriptionSection'>
              <Typography variant='h2' sx={{ mt: 2 }} data-testid='section-title'>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.subscription_section.subscription_title_text'
                )}
                <span className='mandatory'>
                  {t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.subscription_section.required_input_text'
                  )}
                </span>
              </Typography>
              <Controller
                name='subscription'
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <SubscriptionSelectInputComponent
                      isGlobalRequestHelpDrawer
                      hideCheckBox
                      getValues={(value) => {
                        handleSubscriptionChangeEvent(value);
                        const newValue = value.map((item) => item.id);
                        onChange(newValue[0]);
                      }}
                      dataTestId='subscription-select-input'
                      multiple={false}
                      selectedTools={[providedSubscriptionId]}
                      placeholder={t('common:subscription_select_input_component.placeholder_text')}
                      clearValueOnUnmount
                    />
                  );
                }}
                rules={{ required: true }}
              />
            </Box>
          )}
        {shouldRenderInsightAlert && (
          <SubscriptionInsightAlert
            subscription={selectedSubscription || clonedSubscription}
            onClick={handleInsightAlertClick}
          />
        )}

        {showBottomSection && (
          <>
            <Typography variant='h2'>
              {selectTypeOfRequestText}{' '}
              <span className='mandatory'>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.request_section.required_input_text'
                )}
              </span>
            </Typography>
            <Box className='chips-container'>
              {modifiedCommentTemplates?.map((itemTemplate: CommentTemplate, itemIndex: number) => {
                const { buttonLabel, id } = itemTemplate;

                return (
                  <Chip
                    label={buttonLabel}
                    data-testid='comment-template'
                    key={id}
                    onClick={() => onTemplateTabClick(itemIndex)}
                    className={classnames({
                      'is-active': modifiedCommentTemplates?.[currentTabIndex]?.buttonLabel === buttonLabel,
                    })}
                  />
                );
              })}
            </Box>

            <Box>
              <Typography variant='h2' sx={{ mb: 2, mt: 4 }}>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.comment_section.comment_title_text'
                )}
              </Typography>
              <Typography variant='body' color='text.secondary' sx={{ my: 1.5 }}>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.comment_section.comment_subtext'
                )}
              </Typography>
              <Box>
                <WysiwygEditorComponent
                  actionText='send request'
                  hideImageUploadButton
                  value={commentText}
                  onChange={onCommentTextChange}
                  subscriptionId={id}
                  setIsUploadingDocument={() => null}
                  bottomSectionStyles={{ bottom: '-1.5rem', right: '0' }}
                  handleSaveCommentShortCutPress={handleEditorCommentSave}
                  placeholder={t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.comment_section.placeholder_text'
                  )}
                />
              </Box>
            </Box>
            <Box>
              <Typography variant='h2' sx={{ mb: 2, mt: 4 }}>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.document_section.document_title_text'
                )}
              </Typography>
              <Typography variant='body' color='text.secondary' sx={{ my: 1.5 }}>
                {!isAppHelp
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.document_section.document_subtext'
                    )
                  : t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.document_section.sastrify_app_help_document_subtext'
                    )}
              </Typography>
              {documents.length > 0 ? (
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Icon size='large' color='action'>
                      file_copy
                    </Icon>
                  </Grid>
                  <Grid item xs={8}>
                    <Box display='flex' justifyContent='space-between' mb={1}>
                      <Box width='95%' display='flex' flexWrap='wrap'>
                        {documents.map((file, index) => (
                          <Box display='flex' alignItems='center' key={file.name}>
                            <Typography variant='body1' className='filename mr-1'>
                              {file.name}
                            </Typography>
                            <WrapperButton
                              data-testid='remove-document-icon'
                              className={classnames('delete-icon-wrapper', {
                                'close-button-active': !isSendingAnotherRequest,
                              })}
                              onClick={() => (isSendingAnotherRequest ? null : handleRemoveDocument(index))}>
                              <Icon size='small' color='action'>
                                close
                              </Icon>
                            </WrapperButton>
                          </Box>
                        ))}
                      </Box>
                      <Icon size='small' color='success'>
                        check
                      </Icon>
                    </Box>
                    <Box className='upload-complete-line' />
                  </Grid>
                </Grid>
              ) : (
                <Box data-testid='upload-document'>
                  <DropzoneComponent
                    isPaperClipIcon
                    hasCustomMessage
                    message={
                      <span className='dropzone-message' data-testid='upload-document-message'>
                        <Trans
                          i18nKey='subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.drag_and_drop_text'
                          values={{ clickHere: 'click here' }}
                          components={[<span/>]}
                        />
                      </span>
                    }
                    name='file'
                    onDrop={(value) => {
                      onDocumentUpload(value as ExtendedBlobType[]);
                    }}
                    onDropRejected={() => {
                      const message = t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.upload_file_type_error_message'
                      );
                      showNotification?.(message, NotificationAlertType.Error);
                    }}
                    multiple
                  />
                </Box>
              )}
            </Box>
            <Box display='flex' justifyContent='flex-end' mt={5}>
              <Button
                variant='text'
                color='secondary'
                sx={{ mr: 1 }}
                disabled={isSendingAnotherRequest}
                onClick={handleCloseDrawer}>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.action_buttons_section.cancel_button_text'
                )}
              </Button>
              <Button
                variant='contained'
                color='primary'
                data-testid='save-request-button'
                onClick={() => {
                  setIsSendingAnotherRequest(true);
                  handleSaveRequest(commentText);
                  trackHelpCenterRequestSubmitted({
                    companyId,
                    companyName: company?.name,
                    requestType: selectedCommentTemplates?.buttonLabel,
                  });
                }}
                disabled={!isSendButtonEnabled}>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_help_section.action_buttons_section.send_button_text'
                )}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </DrawerRequestHelpWrapper>
  );
};
