import { Subscription } from 'shared/models';

import { TopVendors, VendorType } from '../models/vendor.model';
import { apiService, apiUrl } from '../services';

const getMatchingTools = (vendor: VendorType, subscriptions: Subscription[]) => {
  return subscriptions?.filter((subscription) => subscription.vendorId === vendor.id)[0];
};
export const getExistingTools = (subscriptions: Subscription[], topVendors?: TopVendors) => {
  const tools = topVendors?.data.map((vendor) => {
    const result = getMatchingTools(vendor, subscriptions);

    if (result) {
      return result;
    }
    return vendor;
  });

  return tools;
};

export const fetchTopVendors = async ({ pageParam = 9 }: { pageParam?: number }): Promise<TopVendors> => {
  const baseUrl = `${apiUrl.Vendors}/?page=0&size=${pageParam}`;

  const { data } = await apiService.get(baseUrl);
  return data;
};
