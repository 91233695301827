import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DatePicker as MUIDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Icon } from 'asteroids';
import classnames from 'classnames';
import { FC, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { LocaleMap, MaskMap } from 'shared/localMask';
import { getLocaleCode } from 'shared/logic/users.logic';

import { SastrifyInput } from '../input';
import { DatePickerComponentProps } from '.';
import { DatePickerWrapper } from './date-picker.styles';

export const DatePickerComponent: FC<DatePickerComponentProps> = ({
  clickAwayToClose,
  helperText,
  inputRef = null,
  isMuiInput,
  label,
  maxDate,
  minDate,
  onChange,
  onOpen,
  openDatePicker,
  placeholder,
  setOpenDatePicker,
  size,
  valid = true,
  value = null,
}) => {
  const localeCode = getLocaleCode(Intl.NumberFormat().resolvedOptions().locale) as keyof typeof MaskMap;
  const defaultLocale = LocaleMap.enGB;
  const [isOpen, setOpen] = useState(openDatePicker);
  const containerRef = useRef<HTMLDivElement>(null);
  const shouldCloseWhenClickingAway = clickAwayToClose || typeof clickAwayToClose === 'undefined';

  const closeDatePicker = useCallback(() => {
    setOpen(false);
    if (setOpenDatePicker) {
      setOpenDatePicker(false);
    }
  }, [setOpen, setOpenDatePicker]);

  const toggleDatePicker = useCallback(() => {
    setOpen(!isOpen);
    if (setOpenDatePicker) {
      setOpenDatePicker(!isOpen);
    }
  }, [isOpen, setOpen, setOpenDatePicker]);

  const handleYearAndMonthChange = useCallback(() => {
    if (setOpenDatePicker) {
      setOpenDatePicker(true);
    }
  }, [setOpenDatePicker]);

  const handleClickOutside: EventListenerOrEventListenerObject = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (evt: any) => {
      if (!isOpen || !containerRef.current) {
        return;
      }

      const target = evt.target as Element;
      const targetContainer = target?.closest('.sastrify-datepicker');

      if (targetContainer && targetContainer === containerRef.current) {
        return; // Can't be closed because user clicked inside the DatePicker component
      }

      evt.preventDefault();
      evt.stopPropagation();
      closeDatePicker();
      return false;
    },
    [closeDatePicker, containerRef, isOpen]
  );

  useEffect(() => {
    if (shouldCloseWhenClickingAway) {
      document.addEventListener('mouseup', handleClickOutside);
      document.addEventListener('touchend', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [handleClickOutside, shouldCloseWhenClickingAway]);

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const userEvent = event as React.MouseEvent<HTMLDivElement, MouseEvent> & {
      target: { nodeName: string };
    };

    if (['svg', 'path'].includes(userEvent.target.nodeName)) return;
    setOpenDatePicker(true);
  };

  const getInputProps = () => {
    return {
      endAdornment: (
        <InputAdornment position='end'>
          <Icon
            color='action'
            onClick={toggleDatePicker}
            style={{ cursor: 'pointer' }}
            data-testid='CalendarTodayRoundedIcon'>
            calendar_today
          </Icon>
        </InputAdornment>
      ),
    };
  };

  const getInnerInputProps = (params: JSX.IntrinsicAttributes & TextFieldProps) => {
    return {
      ...params.inputProps,
      className: classnames(params?.inputProps?.className || '', { 'is-danger': !valid }),
      placeholder: placeholder || 'dd/mm/yyyy',
    };
  };

  const renderInput = (params: TextFieldProps) => {
    if (isMuiInput) {
      return (
        <SastrifyInput
          {...params}
          data-testid='datepicker-input'
          color='primary'
          InputProps={getInputProps()}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={getInnerInputProps(params)}
          onClick={handleOnClick}
          helperText={helperText}
          label={label}
          size={size || 'medium'}
        />
      );
    }
    return (
      <TextField
        {...params}
        size='small'
        data-testid='datepicker-input'
        InputProps={getInputProps()}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={getInnerInputProps(params)}
        onClick={handleOnClick}
        helperText={helperText}
      />
    );
  };

  return (
    <DatePickerWrapper
      ref={containerRef}
      className={classnames('sastrify-datepicker', { 'is-danger': valid === false })}
      data-testid='datepicker'>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={LocaleMap[localeCode] || defaultLocale}>
        <MUIDatePicker
          value={value}
          open={isOpen}
          openTo='month'
          inputRef={inputRef as Ref<HTMLInputElement> | undefined}
          views={['year', 'month', 'day']}
          onYearChange={handleYearAndMonthChange}
          onMonthChange={handleYearAndMonthChange}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          onOpen={onOpen}
          onClose={() => closeDatePicker()}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          renderInput={renderInput}
          PopperProps={{
            disablePortal: shouldCloseWhenClickingAway,
          }}
        />
      </LocalizationProvider>
    </DatePickerWrapper>
  );
};
