import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { FC, lazy } from 'react';
import { Route } from 'react-router';
import { UserRoles } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { RoutedProps } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { NAVIGATION_URLS } from 'src/constants/navigation';
import { ErrorView } from 'views/error';

const InitiativeWorkflowsView = lazy(() => import('../views/request-workflows/initiative-workflows.view'));
const NewInitiativeWorkflowView = lazy(() => import('../views/new-initiative-workflow/new-initiative-workflow.view'));
const RequestsView = lazy(() => import('../views/requests/requests.view'));
const ToolStoreView = lazy(() => import('../views/tool-store/tool-store.view'));
const WorkflowsView = lazy(() => import('../views/workflows/workflows.view'));

export const ToolStoreWorkflowRoutes: FC<RoutedProps> = (props) => {
  const userRole = useAppSelector((state) => state.authentication.user?.role);
  const isAdminUser = userRole === UserRoles.Admin;

  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();
  const isToolStoreBundleEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);
  const isToolStoreBundlePurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);

  return (
    <>
      <Route exact path={NAVIGATION_URLS.TOOL_STORE}>
        {isToolStoreBundleEnabled || isToolStoreBundlePurchasable ? (
          <ToolStoreView {...props} />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
      <Route exact path={NAVIGATION_URLS.NEW_WORKFLOW}>
        {isAdminUser && isToolStoreBundleEnabled ? <NewInitiativeWorkflowView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.EDIT_WORKFLOW}>
        {isAdminUser && isToolStoreBundleEnabled ? <NewInitiativeWorkflowView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.WORKFLOW_DETAILS}>
        {isAdminUser && isToolStoreBundleEnabled ? <NewInitiativeWorkflowView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.WORKFLOWS}>
        {isAdminUser && isToolStoreBundleEnabled ? <WorkflowsView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.REQUESTS}>
        {isToolStoreBundleEnabled ? <RequestsView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.REQUESTS_WORKFLOWS}>
        {isAdminUser && isToolStoreBundleEnabled ? <InitiativeWorkflowsView {...props} /> : <ErrorView code={404} />}
      </Route>
    </>
  );
};
