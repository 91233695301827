import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DropzoneAreaProps } from './dropzone.component.props';

export const DropzoneWrapper = styled(Box)(() => ({
  position: 'relative',
}));

export const DropzoneArea = styled(Box)<DropzoneAreaProps>(({ error, hover, theme }) => ({
  alignItems: 'center',
  border: error === 'true' ? `1px solid ${theme.palette.error.main}` : `1px dashed ${theme.palette.secondary.dark}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  zIndex: 0,
  ...(hover === 'true' && error === 'false' && { backgroundColor: theme.palette.primary.highlight }),
  ...(hover === 'true' && error === 'true' && { backgroundColor: theme.palette.error.highlight }),
  ...(hover === 'false' && error === 'false' && { backgroundColor: theme.palette.background.paper }),
  ...(hover === 'true' && error === 'false' && { borderColor: theme.palette.primary.main }),
  ...(hover === 'true' && error === 'true' && { borderColor: theme.palette.error.main }),
}));

export const MainText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const LinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline',
  textDecoration: 'underline',
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  textAlign: 'center',
}));

export const FileInput = styled('input')(() => ({
  cursor: 'pointer',
  height: '100%',
  left: 0,
  opacity: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 1,
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.6875rem',
  margin: '3px 14px 0',
}));
