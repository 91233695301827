/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccordionDetails, Box, Chip, Divider, Stack, Typography } from '@mui/material';
import { Icon, StatusDot } from 'asteroids';
import { AxiosError } from 'axios';
import { FilesListComponent } from 'components/drawer-workflow-request/components/files-list/files-list.component';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useUser } from 'hooks/useUser';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import {
  createRequestInitiativeComment,
  deleteRequestInvolvementComment,
  downloadDocument,
  updateRequestInvolvementComment,
} from 'shared/logic/requests.logic';
import { ticketStages, ticketStatuses } from 'shared/logic/tickets.logic';
import {
  Initiative,
  InitiativeNegotiationSupportProps,
  InitiativeRequestBenchmarkProps,
  InitiativeUploadDocumentsProps,
} from 'shared/models';
import {
  CreateRequestInitiativeCommentPayload,
  InitiativeFindAlternativeProps,
  RenewalRequestBenchmarkProps,
  UpdateRequestInvolvementCommentPayload,
} from 'shared/models/request-workflow.model';
import { Avatar } from 'views/requests/components/request-list/request-list.styles';

import { ActivitiesComponent } from '../../activities/activities.component';
import { requestTypesMapping } from '../../contact-sastrify-form/contact-sastrify-form.constants';
import { DetailsItem } from '../../details-item/details-item.component';
import { formatDetailsDate, formatText, getAmountWithCurrency } from '../../details-item/details-item.utils';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { DetailsWrapper, InitiativeDetailsDivider, Outcome } from '../procurement-initiatives.styles';
import {
  RenewalFindAlternativeDetails,
  RenewalNegotiationSupportDetails,
  RenewalOtherDetails,
  RenewalRequestBenchmarkDetails,
} from './components';
import { ProcurementInitiativeProps } from './procurement-initiative.component.props';
import { AccordionStyled, AccordionSummaryStyled } from './procurement-initiative.styles';

const RequestBenchmarkDetails: FC<InitiativeRequestBenchmarkProps & InitiativeUploadDocumentsProps> = ({
  billingCycle,
  commitmentLength,
  companyId,
  documents,
  expectedOutcome,
  id,
  licenseType,
  numberOfLicenses,
  otherInformation,
  refetchData,
  showNotification,
  uploadFunction,
}) => {
  const { t } = useTranslation();
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);

  const handleOnDownload = async (fileName: string, fileUrl: string, fileId?: string) => {
    setIsDownloadingDocument(true);
    await downloadDocument(fileName, fileUrl, fileId);
    setIsDownloadingDocument(false);
  };

  return (
    <>
      <Box mb={2} data-testid='background-and-outcome'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.expected_outcome'
          )}
          content={formatText(expectedOutcome || '-')}
        />
      </Box>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5} data-testid='pricing-plan'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.pricing_plan'
            )}
            content={formatText(licenseType || '-')}
          />
        </Stack>
        <Stack flex={1} data-testid='volume-licenses'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.number_of_licenses'
            )}
            content={numberOfLicenses || '-'}
          />
        </Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5} data-testid='commitment-length'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.commitment_length'
            )}
            content={formatText(commitmentLength || '-')}
          />
        </Stack>
        <Stack flex={1} data-testid='billing-cycle'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.billing_cycle'
            )}
            content={formatText(billingCycle || '-')}
          />
        </Stack>
      </Stack>
      <Box mb={2} data-testid='vendor-proposal'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.vendor_proposal'
          )}
          content={
            <>
              {documents && documents.length > 0 ? (
                <FilesListComponent
                  files={documents || []}
                  onDownload={handleOnDownload}
                  disableDownloadButton={isDownloadingDocument}
                />
              ) : (
                '-'
              )}
              <FileUploadComponent
                showNotification={showNotification}
                companyId={companyId}
                referenceId={Number(id)}
                uploadFunction={uploadFunction}
                refetchData={refetchData}
              />
            </>
          }
        />
      </Box>
      <Box mb={2} data-testid='other-information'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.other_information'
          )}
          content={formatText(otherInformation || '-')}
        />
      </Box>
    </>
  );
};

const NegotiationSupportDetails: FC<InitiativeNegotiationSupportProps & InitiativeUploadDocumentsProps> = ({
  approvalLength,
  billingCycle,
  commercialTarget,
  commitmentLength,
  companyId,
  contractSignatory,
  decisionDate,
  documents,
  existingSolution,
  id,
  licenseType,
  numberOfLicenses,
  otherToolConsideration,
  refetchData,
  showNotification,
  uploadFunction,
  vendorInformation,
}) => {
  const { t } = useTranslation();
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);

  const handleOnDownload = async (fileName: string, fileUrl: string, fileId?: string) => {
    setIsDownloadingDocument(true);
    await downloadDocument(fileName, fileUrl, fileId);
    setIsDownloadingDocument(false);
  };

  return (
    <>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5} data-testid='decision-date'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.decision_date'
            )}
            content={formatDetailsDate(decisionDate) || '-'}
          />
        </Stack>
        <Stack flex={1} data-testid='replacing-saas-solution'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.existing_solution'
            )}
            content={formatText(existingSolution || '-')}
          />
        </Stack>
      </Stack>
      <Box mb={2} data-testid='commercial-target'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.commercial_target'
          )}
          content={formatText(commercialTarget || '-')}
        />
      </Box>
      <Box mb={2} data-testid='other-tool-consideration'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.other_tool_consideration'
          )}
          content={formatText(otherToolConsideration || '-')}
        />
      </Box>
      <Box mb={2} data-testid='attachments'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.attachments'
          )}
          content={
            <>
              {documents && documents.length > 0 ? (
                <FilesListComponent
                  files={documents || []}
                  onDownload={handleOnDownload}
                  disableDownloadButton={isDownloadingDocument}
                />
              ) : (
                '-'
              )}
              <FileUploadComponent
                showNotification={showNotification}
                companyId={companyId}
                referenceId={Number(id)}
                uploadFunction={uploadFunction}
                refetchData={refetchData}
              />
            </>
          }
        />
      </Box>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5} data-testid='license-type'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.license_type'
            )}
            content={formatText(licenseType || '-')}
          />
        </Stack>
        <Stack flex={1} data-testid='volume-licenses'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.number_of_licenses'
            )}
            content={numberOfLicenses || '-'}
          />
        </Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5} data-testid='commitment-length'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.commitment_length'
            )}
            content={formatText(commitmentLength || '-')}
          />
        </Stack>
        <Stack flex={1} data-testid='billing-cycle'>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.billing_cycle'
            )}
            content={formatText(billingCycle || '-')}
          />
        </Stack>
      </Stack>
      <Box mb={2} data-testid='vendor-information'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.vendor_information'
          )}
          content={formatText(vendorInformation || '-')}
        />
      </Box>
      <Box mb={2} data-testid='contract-signatory'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.contract_signatory'
          )}
          content={formatText(contractSignatory || '-')}
        />
      </Box>
      <Box mb={2} data-testid='approval-length'>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields.approval_length'
          )}
          content={formatText(approvalLength || '-')}
        />
      </Box>
    </>
  );
};

const FindAlternativeDetails: FC<InitiativeFindAlternativeProps & InitiativeUploadDocumentsProps> = ({
  alreadyHaveAlternative,
  budget,
  commitmentLengthValue,
  companyId,
  contractSignatory,
  currency,
  details,
  documents,
  findAlternativeGoal,
  id,
  involvementUrgency,
  mustHaveFeatures,
  needDemo,
  preferredCommitmentLength,
  preferredPaymentCycle,
  refetchData,
  showNotification,
  uploadFunction,
  vendors,
  volume,
}) => {
  const { t } = useTranslation();
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);

  const handleOnDownload = async (fileName: string, fileUrl: string, fileId?: string) => {
    setIsDownloadingDocument(true);
    await downloadDocument(fileName, fileUrl, fileId);
    setIsDownloadingDocument(false);
  };

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.fields';

  const vendorList = () => (
    <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap>
      {vendors.map((vendor) => (
        <Chip label={vendor} key={vendor} />
      ))}
    </Stack>
  );

  return (
    <>
      <Stack component='div' direction='row' spacing={5} alignItems='flex-start' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.relevant_documents`)}
            content={
              <>
                {documents && documents.length > 0 ? (
                  <FilesListComponent
                    files={documents || []}
                    onDownload={handleOnDownload}
                    disableDownloadButton={isDownloadingDocument}
                  />
                ) : (
                  '-'
                )}
                <FileUploadComponent
                  showNotification={showNotification}
                  companyId={companyId}
                  referenceId={Number(id)}
                  uploadFunction={uploadFunction}
                  refetchData={refetchData}
                />
              </>
            }
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            title={t(`${pathToTranslation}.find_alternative_goal`)}
            content={formatText(findAlternativeGoal || '-')}
          />
        </Stack>
      </Stack>

      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.alternatives`)}
            content={formatText(alreadyHaveAlternative || '-')}
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem title={t(`${pathToTranslation}.demo`)} content={formatText(needDemo || '-')} />
        </Stack>
      </Stack>

      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.vendor_consideration`)}
            content={vendors.length ? vendorList() : '-'}
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem title={t(`${pathToTranslation}.features_needed`)} content={mustHaveFeatures || '-'} />
        </Stack>
      </Stack>

      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.budget`)}
            content={(budget && getAmountWithCurrency(Number(budget) * 100, currency)) || '-'}
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem title={t(`${pathToTranslation}.volume`)} content={formatText(volume || '-')} />
        </Stack>
      </Stack>

      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.preferred_commitment_length`)}
            content={
              commitmentLengthValue && preferredCommitmentLength
                ? `${commitmentLengthValue} ${preferredCommitmentLength}`
                : '-'
            }
          />{' '}
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            title={t(`${pathToTranslation}.preferred_payment_cycle`)}
            content={formatText(preferredPaymentCycle || '-')}
          />
        </Stack>
      </Stack>

      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.contract_signatory`)}
            content={formatText(contractSignatory || '-')}
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            title={t(`${pathToTranslation}.request_urgency`)}
            content={formatText(involvementUrgency || '-')}
          />
        </Stack>
      </Stack>

      <Box mb={1}>
        <DetailsItem title={t(`${pathToTranslation}.other_details`)} content={formatText(details || '-')} />
      </Box>
    </>
  );
};

export const ProcurementInitiative: FC<ProcurementInitiativeProps> = ({
  companyId,
  documentsUploadFunction,
  expanded,
  initiative,
  refetchInitiatives,
  requestWorkflowTypeId,
  setExpanded,
  showNotification,
  vendor,
}) => {
  const { t } = useTranslation();
  const user = useUser();

  const [commentText, setCommentText] = useState('');

  const { isLoading: isCreateInitiativeCommentLoading, mutate: setCreateInitiativeComment } = useMutation(
    (payload: CreateRequestInitiativeCommentPayload) => createRequestInitiativeComment(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Create involvement comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchInitiatives();
        setCommentText('');
      },
    }
  );

  const { isLoading: isUpdateInvolvementCommentLoading, mutate: setUpdateInvolvementComment } = useMutation(
    (payload: UpdateRequestInvolvementCommentPayload) => updateRequestInvolvementComment(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Update involvement comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchInitiatives();
        setCommentText('');
      },
    }
  );

  const { mutate: setDeleteInvolvementComment } = useMutation(
    (commentId: string) => deleteRequestInvolvementComment(Number(initiative.id), commentId),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Delete involvement comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchInitiatives();
        setCommentText('');
      },
    }
  );

  const getTicketInfo = (initiative: Initiative) => {
    const status = t(ticketStatuses[initiative.status as keyof typeof ticketStatuses]);
    const stage = t(ticketStages[initiative.stage as keyof typeof ticketStages]);

    let statusColor;
    switch (initiative.status) {
      case 2:
      case 3:
        statusColor = 'warning';
        break;
      case 4:
        statusColor = 'success';
        break;
      case 5:
        statusColor = 'inactive';
        break;
      case 8:
        statusColor = 'error';
        break;
      default:
        statusColor = 'warning';
    }

    return { stage, status, statusColor };
  };

  const renderAccordionSummary = (initiative: Initiative) => (
    <Stack component='div' direction='row' spacing={2} width='100%' alignItems='center' px={1} mr={4}>
      <Stack flex={1} direction='row' spacing={1} alignItems='center'>
        <Avatar alt={vendor.name} src={vendor.avatarUrl} size='normal' variant='rounded' />
        <Stack direction='column'>
          <Typography>{initiative.name}</Typography>
          <Typography color='textSecondary' variant='small'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.involvement'
            )}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction='column'>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='flex-start'>
          <StatusDot status={getTicketInfo(initiative).statusColor as any} />
          <Typography fontWeight={300}>{getTicketInfo(initiative).status}</Typography>
        </Stack>
        {getTicketInfo(initiative).stage && (
          <Typography color='textSecondary' variant='small' pl={2}>
            {getTicketInfo(initiative).stage}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  const renderNewPurchaseDetails = (initiative: Initiative, uploadDocumentsProps: InitiativeUploadDocumentsProps) => {
    // eslint-disable-next-line security/detect-non-literal-regexp
    if (initiative.name.match(new RegExp(`^${requestTypesMapping.request_benchmark}$`, 'i'))) {
      return <RequestBenchmarkDetails {...(initiative as InitiativeRequestBenchmarkProps)} {...uploadDocumentsProps} />;
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    if (initiative.name.match(new RegExp(`^${requestTypesMapping.negotiation_support}$`, 'i'))) {
      return (
        <NegotiationSupportDetails {...(initiative as InitiativeNegotiationSupportProps)} {...uploadDocumentsProps} />
      );
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    if (initiative.name.match(new RegExp(`^${requestTypesMapping.find_alternative}$`, 'i'))) {
      return <FindAlternativeDetails {...(initiative as InitiativeFindAlternativeProps)} {...uploadDocumentsProps} />;
    }
  };

  const renderRenewalDetails = (initiative: Initiative, uploadDocumentsProps: InitiativeUploadDocumentsProps) => {
    // eslint-disable-next-line security/detect-non-literal-regexp
    if (initiative.name.match(new RegExp(`^${requestTypesMapping.request_benchmark}$`, 'i'))) {
      return (
        <RenewalRequestBenchmarkDetails {...(initiative as RenewalRequestBenchmarkProps)} {...uploadDocumentsProps} />
      );
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    if (initiative.name.match(new RegExp(`^${requestTypesMapping.renewal_and_negotiation_support}$`, 'i'))) {
      return <RenewalNegotiationSupportDetails {...(initiative as any)} {...uploadDocumentsProps} />;
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    if (initiative.name.match(new RegExp(`^${requestTypesMapping.find_alternative}$`, 'i'))) {
      return <RenewalFindAlternativeDetails {...(initiative as any)} {...uploadDocumentsProps} />;
    }
    // eslint-disable-next-line security/detect-non-literal-regexp
    if (initiative.name.match(new RegExp(`^${requestTypesMapping.other}$`, 'i'))) {
      return <RenewalOtherDetails {...(initiative as any)} />;
    }
  };

  const renderOutcome = (initiative: Initiative) => {
    if (initiative.outcome) {
      return (
        <>
          <InitiativeDetailsDivider variant='fullWidth' />
          <Outcome>
            <Typography variant='h2' mb={1}>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.procurement_initiatives.outcome'
              )}
            </Typography>
            <Typography variant='body'>{initiative.outcome}</Typography>
          </Outcome>
        </>
      );
    }
  };

  const renderActivities = () => (
    <>
      <Divider />
      <Box my={3}>
        <ActivitiesComponent
          commentTestId={`add-comment-${initiative.id}`}
          commentAvatarSrc={user.avatar}
          commentAvatarAlt={user.name}
          commentText={commentText}
          setCommentText={(value) => setCommentText(value)}
          onCommentAction={() =>
            setCreateInitiativeComment({
              comment: commentText,
              ticketId: Number(initiative.id),
            })
          }
          onLogItemSend={(val, id) =>
            setUpdateInvolvementComment({
              comment: val || '',
              id: String(id) || '',
              ticketId: Number(initiative.id),
            })
          }
          onLogItemDelete={(id) => id && setDeleteInvolvementComment(String(id))}
          logItems={initiative.comments.map(
            ({
              avatarUrl,
              canEdit: canModify,
              comment: description,
              commentedById: performedBy,
              createdAt: created,
              id,
              ticketId,
              title: name,
            }) => ({
              avatarUrl,
              canModify,
              created,
              description,
              id,
              name,
              performedBy,
              prefixTestId: `activities-item-${ticketId}`,
            })
          )}
          isSubmitButtonDisabled={isCreateInitiativeCommentLoading || isUpdateInvolvementCommentLoading}
        />
      </Box>
    </>
  );

  return (
    <AccordionStyled onChange={setExpanded(initiative.id)} expanded={expanded.includes(initiative.id)} disableGutters>
      <AccordionSummaryStyled
        expandIcon={<Icon variant='Outlined'>expand_more</Icon>}
        data-testid={`involvement-${initiative.id}-summary`}>
        {renderAccordionSummary(initiative)}
      </AccordionSummaryStyled>
      <AccordionDetails>
        <DetailsWrapper data-testid={`involvement-${initiative.id}-details`}>
          {requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL &&
            renderNewPurchaseDetails(initiative, {
              companyId,
              refetchData: refetchInitiatives,
              showNotification,
              uploadFunction: documentsUploadFunction,
            })}
          {requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL &&
            renderRenewalDetails(initiative, {
              companyId,
              refetchData: refetchInitiatives,
              showNotification,
              uploadFunction: documentsUploadFunction,
            })}
          {renderOutcome(initiative)}
        </DetailsWrapper>
        {renderActivities()}
      </AccordionDetails>
    </AccordionStyled>
  );
};
