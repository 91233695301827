import { useEffect } from 'react';

export const useDebounce = <T = string>(fnHandler: (value: T) => void, value: T, delay: number): void => {
  useEffect(() => {
    const handler = setTimeout(() => {
      fnHandler(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, fnHandler, value]);
};
