/* eslint-disable sonarjs/no-misleading-array-reverse */
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { format } from 'date-fns';
import { useModalAndNotification, useUser } from 'hooks/index';
import { useFetchData } from 'hooks/useFetchData';
import { SOURCE_SYSTEM } from 'libs/enums';
import { Subscription, UnmatchedCompanySupplierCount } from 'libs/models';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { featureFlags, UserRoles } from 'shared/common.definitions';
import { getFailedInvoicesList, getUnmatchedSuppliersTotals } from 'shared/logic/invoices.logic';
import { Company } from 'shared/models';
import { SpendCategory } from 'shared/models/subscription-spend-and-invoices.model';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';

export const useFailedInvoiceTotals = () => {
  const queryClient = useQueryClient();
  const company = queryClient.getQueryData<Company>('company');
  const { onShowNotification } = useModalAndNotification();
  const [totalsData, setTotalsData] = useState<SpendCategory[] | null>(null);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const disableSpendReview = useCompanyFeatureFlag(featureFlags.DISABLE_SPEND_REVIEW);
  const isToolMatchingEnabled = useCompanyFeatureFlag(featureFlags.TOOL_MATCHING);

  const user = useUser();
  const toolsAccessible = queryClient.getQueryData<Subscription[]>('subscriptions');
  const isAdminUser = user.role === UserRoles.Admin;

  const subscriptionIds = user.role === UserRoles.Contributor ? toolsAccessible?.map((item) => item.id) : undefined;

  const { data: unmatchedSuppliersTotals, isLoading: isLoadingUnmatched } = useFetchData(
    'unmatched-suppliers-totals',
    () => getUnmatchedSuppliersTotals(),
    {
      enabled: Boolean(company?.id) && !disableSpendReview && isAdminUser,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const { data: failedInvoicesList, isLoading: isLoadingFailed } = useFetchData(
    'failed-invoices-list',
    () => getFailedInvoicesList(company?.id as string, subscriptionIds),
    {
      enabled: Boolean(company?.id) && !disableSpendReview && !!toolsAccessible,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const failedInvoicesTotals = useMemo(() => {
    if (isLoadingUnmatched || isLoadingFailed || !failedInvoicesList) return;

    const sortedByImportedDate = failedInvoicesList.sort((a, b) => {
      const createdAtB = b?.createdAtMilliseconds ?? 0;
      const createdAtA = a?.createdAtMilliseconds ?? 0;
      return createdAtB - createdAtA;
    });

    return {
      count: failedInvoicesList.length,
      lastImportedMilliseconds: sortedByImportedDate[0]?.createdAtMilliseconds,
    };
  }, [failedInvoicesList, isLoadingFailed, isLoadingUnmatched]);

  useEffect(() => {
    if (isLoadingUnmatched || isLoadingFailed || (!unmatchedSuppliersTotals && !failedInvoicesTotals)) {
      return;
    }

    try {
      let importCategories: SpendCategory[] = [];

      if (unmatchedSuppliersTotals && unmatchedSuppliersTotals?.length > 0) {
        const suppliersTotals = unmatchedSuppliersTotals?.map((unmatchedSupplier: UnmatchedCompanySupplierCount) => {
          if (unmatchedSupplier.importSource === SOURCE_SYSTEM.ACCOUNTING_IMPORT) {
            return {
              connectionName: null,
              importSource: unmatchedSupplier.importSource,
              importSubsystem: null,
              invoiceCount: Number(unmatchedSupplier.total),
              lastImportedDate: unmatchedSupplier.lastImportDate
                ? format(new Date(unmatchedSupplier.lastImportDate), 'd LLL yyyy')
                : '',
            };
          }

          return {
            connectionId: unmatchedSupplier.connectionId,
            connectionName: unmatchedSupplier.connectionName,
            importSource: unmatchedSupplier.importSource,
            importSubsystem: unmatchedSupplier.importSubsystem,
            invoiceCount: Number(unmatchedSupplier.total),
            lastImportedDate: unmatchedSupplier.lastImportDate
              ? format(new Date(unmatchedSupplier.lastImportDate), 'd LLL yyyy')
              : '',
          };
        });

        importCategories = [...suppliersTotals];
      }

      if (failedInvoicesTotals && failedInvoicesTotals.count > 0) {
        const userUploadCategory = {
          connectionName: null,
          importSource: SOURCE_SYSTEM.USER_UPLOAD,
          importSubsystem: null,
          invoiceCount: Number(failedInvoicesTotals?.count),
          lastImportedDate: failedInvoicesTotals?.lastImportedMilliseconds
            ? format(new Date(failedInvoicesTotals?.lastImportedMilliseconds), 'd LLL yyyy')
            : '',
        };

        importCategories = [...importCategories, userUploadCategory];
      }

      importCategories = isToolMatchingEnabled
        ? importCategories?.filter(({ importSource }) => importSource !== SOURCE_SYSTEM.CODAT)
        : importCategories;

      setTotalsData(importCategories);

      const count = importCategories?.map((item) => item?.invoiceCount)?.reduce((a, b) => a + b, 0) ?? 0;
      setPendingCount(count);
    } catch (error) {
      onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
    }
  }, [
    failedInvoicesTotals,
    unmatchedSuppliersTotals,
    queryClient,
    onShowNotification,
    isLoadingUnmatched,
    isLoadingFailed,
    isToolMatchingEnabled,
  ]);

  return {
    failedInvoicesTotals,
    pendingCount,
    totalsData,
    unmatchedSuppliersTotals,
  };
};
