import { Box, styled } from '@mui/material';
import { colors } from 'shared/theme';

export const ConfirmationAlertWrapper = styled(Box)(({ theme }) => ({
  '& .alert-description__message': {
    fontSize: '0.875rem',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  '& .alert-main__message': {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    margin: theme.spacing(3, 0, 2),
  },
  '& .closeButton': {
    position: 'absolute',
    right: '-0.5rem',
    top: '-0.5rem',
  },
  alignItems: 'center',
  background: 'white',
  borderRadius: theme.spacing(0.5),
  color: colors.riverBed,
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  padding: theme.spacing(4),
  position: 'relative',
  width: '25rem',
}));
