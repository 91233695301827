export enum SOURCE_NAME {
  WORKATO = 'WORKATO',
  GOOGLE_SSO = 'GOOGLE_SSO',
  MICROSOFT_SSO = 'MICROSOFT_SSO',
  JUMPCLOUD_SSO = 'JUMPCLOUD_SSO',
  GENERIC_INTEGRATION_SERVICE = 'GENERIC_INTEGRATION_SERVICE',
  OKTA_SSO = 'OKTA_SSO',
}

export enum SsoIntegrationToolName {
  GOOGLE = 'Google Workspace',
  MICROSOFT = 'Microsoft',
  OKTA = 'Okta',
  JUMP_CLOUD = 'JumpCloud',
}

export enum TYPE {
  DIRECT = 'DIRECT',
  SSO = 'SSO',
}

export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}
