import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { CommonReducer } from './common';
import { DefaultStore } from './default.store';
import { DrawerReducer } from './drawer';
import { ModalReducer } from './modal';
import { PricingPlansFeaturesReducer } from './pricing-plans-features';
import { UserReducer } from './user/reducer';
import { ViewsReducer } from './views';

const env = process.env.NODE_ENV;

const reducers = {
  authentication: UserReducer,
  common: CommonReducer,
  drawer: DrawerReducer,
  modal: ModalReducer,
  pricingPlansFeatures: PricingPlansFeaturesReducer,
  views: ViewsReducer,
};

export const SastrifyStore = configureStore({
  devTools: env !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  preloadedState: DefaultStore,
  reducer: reducers,
});

export type Store = typeof SastrifyStore;
export type RootState = ReturnType<typeof SastrifyStore.getState>;
export type AppDispatch = typeof SastrifyStore.dispatch;
export const persistor = persistStore(SastrifyStore);
