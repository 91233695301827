/* eslint-disable prettier/prettier */
import './drawer-user-invite.scss';

import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, Button, CircularProgress, Grid, IconButton,MenuItem, Tooltip, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { PERMISSION } from 'libs/enums/permissions';
import { EMAIL_VALIDATOR_REGEX, USERNAME_VALIDATOR_REGEX } from 'libs/tools/regex-lib.tools';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { googleTagManagerEvents, teamMemberPermissionsLink, UserRoles } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import {
  ADMIN_USER_ROLE,
  CreateUserData,
  ErrorType,
  formUserType,
  getErrorMessage,
  getRolesList,
  InviteUserError,
  inviteUserType,
  roleMenuOption,
  UsersType,
} from 'shared/helpers/user-drawer.helper';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import { createUser } from 'shared/logic/users.logic';
import { SastrifyStore, Subscription, User } from 'shared/models';

import { useCheckUserPermission, useRolesAndPermissionList } from '../../hooks';
import { DropdownComponent } from '../drop-down/drop-down.component';
import Input from '../input';
import { SubscriptionSelectInputComponent } from '../subscription-select-input/subscription-select-input.component';
import { SelectedSubscription } from '../subscription-select-input/subscription-select-input.component.props';
import { DrawerUserInviteComponentProps } from '.';
import { StyledBox } from './drawer-user-invite.styles';
import { GoogleConnectionComponent } from './google-connection.component';
import { SelectedGoogleWorkspaceUser } from './google-connection.component.props';

const FIELD_NAME = 'users';

export const DrawerUserInviteComponent: FC<DrawerUserInviteComponentProps> = ({
  inviteUserInitialValue,
  isSubscription,
  showNotification,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;
  const canAssignAdminRole = useCheckUserPermission(PERMISSION.ASSIGN_ADMIN_ROLE);
  const { canAccessAllSubscriptions } = useRolesAndPermissionList();
  const roles = getRolesList(canAssignAdminRole);

  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isUserRolesFeatureEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.USER_ROLES);

  const defaultUserRole = useMemo(
    () => (isUserRolesFeatureEnabled ? undefined : ADMIN_USER_ROLE),
    [isUserRolesFeatureEnabled]
  );

  const usersData = queryClient.getQueryData<User[]>('tool-owners');

  const [inviteUserInputs, setInviteUserInputs] = useState([{ id: 0, name: `${FIELD_NAME}[0]` }]);
  const [formattedTools, setFormattedTools] = useState<Subscription[]>([]);
  const [userMetadata, setUserMetadata] = useState([{ id: 0, userAccessList: [] as string[] }]);
  const [userOwnerList, setUserOwnerList] = useState<string[] | undefined>([]);

  const data = queryClient.getQueryData<Subscription[]>('subscriptions-and-new-solutions');

  const isMounted = useRef<boolean>(true);
  const hasSetFormattedTools = useRef<boolean>(false);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    if (isMounted.current && inviteUserInitialValue) {
      const nameInput = `${inviteUserInputs[0].name}.name`;
      const toolsInput = `${inviteUserInputs[0].name}.owner_of`;
      setValue(nameInput, inviteUserInitialValue?.name || '');
      setValue(toolsInput, inviteUserInitialValue?.tool ? [inviteUserInitialValue.tool] : []);
      setUserOwnerList(inviteUserInitialValue?.tool?.id ? [inviteUserInitialValue.tool.id] : []);
      isMounted.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteUserInitialValue, inviteUserInputs]);

  useEffect(() => {
    if (!hasSetFormattedTools.current && data) {
      setFormattedTools(
        data?.map((tool) => {
          if (tool.ownerId || tool.ownerName) {
            return {
              ...tool,
              isAssigned: true,
            };
          }
          return {
            ...tool,
            isAssigned: false,
          };
        })
      );

      hasSetFormattedTools.current = true;
    }
  }, [data]);

  const { isLoading: isInvitingUser, mutateAsync: createUserMutation } = useMutation(createUser, {
    onError: (error: InviteUserError) => {
      if (error && error.response.status === 400) {
        const email = JSON.parse(String(error.response.config?.data || ''));
        const message = error.response.data?.error;
        const errorMessage = `${message} (${email?.email})`;
        showNotification?.(errorMessage, NotificationAlertType.Warning);
      }
    },
  });

  const handleAddInput = (addNewRow?: boolean, user?: { name: string; email: string }) => {
    const { id } = inviteUserInputs[inviteUserInputs.length - 1];
    const newId = id + 1;
    const name = `${FIELD_NAME}[${newId}]`;

    const input = {
      id: newId,
      name,
    };

    setInviteUserInputs([...inviteUserInputs, input]);
    setUserMetadata([...userMetadata, { id: newId, userAccessList: [] }]);

    if (addNewRow) {
      const newRow = `${FIELD_NAME}[${newId}]`;
      const nameInput = `${newRow}.name`;
      const emailInput = `${newRow}.email`;
      setValue(nameInput, user?.name, { shouldValidate: true });
      setValue(emailInput, user?.email, { shouldValidate: true });
    }
  };

  const handleRemoveInput = (id: number) => {
    const clonedInviteUserInputs = inviteUserInputs.filter((item) => item.id !== id);
    setInviteUserInputs(clonedInviteUserInputs);

    const clonedMetadata = userMetadata.filter((item) => item.id !== id);
    setUserMetadata(clonedMetadata);

    trigger();
  };

  const inviteUser = async (user: inviteUserType) => {
    await createUserMutation(user as Partial<CreateUserData>);
  };

  const getSelectedAccessToList = (id: number) => {
    const inputMetadata = userMetadata.find((user) => user.id === id);

    return inputMetadata?.userAccessList || [];
  };

  const getUpdatedMetadata = (id: number, userAccessList: string[]) => {
    return userMetadata.map((user) => {
      if (user.id === id) {
        return {
          ...user,
          userAccessList,
        };
      }
      return user;
    });
  };

  const handleUpdateRole = (value: string, id: number) => {
    const userRole = getValues(`users[${id}].role`);
    const currentInput = inviteUserInputs.find((input) => input.id === id);

    if (canAccessAllSubscriptions(userRole)) {
      setUserMetadata(getUpdatedMetadata(id, []));
      setValue(`${currentInput?.name}.access_to`, []);
    }

    if (value.toLowerCase() === UserRoles.Viewer) {
      setValue(`${currentInput?.name}.access_to`, []);
      setValue(`${currentInput?.name}.owner_of`, []);
    }

    setValue(`${currentInput?.name}.role`, value, { shouldValidate: true });
  };

  const handleUpdateOwnerOf = (value: SelectedSubscription[], id: number) => {
    const currentInput = inviteUserInputs.find((input) => input.id === id);
    setValue(`${currentInput?.name}.owner_of`, value);

    const userRole = getValues(`users[${id}].role`);

    if (!canAccessAllSubscriptions(userRole)) {
      const currentOwnerOf = value?.map((item) => item.id);
      setUserMetadata(getUpdatedMetadata(id, currentOwnerOf));
      setValue(`${currentInput?.name}.access_to`, value);
    }
  };

  const handleUpdateAccessTo = (value: SelectedSubscription[], id: number) => {
    const currentInput = inviteUserInputs.find((input) => input.id === id);
    setValue(`${currentInput?.name}.access_to`, value);
  };

  const onSelectGoogleWorkSpaceUser = (
    user: SelectedGoogleWorkspaceUser,
    selectedGoogleWorkspaceUsers: SelectedGoogleWorkspaceUser[]
  ) => {
    if (selectedGoogleWorkspaceUsers.length) {
      handleAddInput(true, user);
    } else {
      const nameInput = `${inviteUserInputs[0].name}.name`;
      const emailInput = `${inviteUserInputs[0].name}.email`;
      setValue(nameInput, user.name, { shouldValidate: true });
      setValue(emailInput, user.email, { shouldValidate: true });
    }

    trigger();
  };

  const handleSubmit = async () => {
    if (!isValid) {
      return;
    }

    try {
      const users: unknown[] = [];

      const inputValues = getValues();

      const clonedInputValues = {
        comment: inputValues.comment,
        users: inputValues.users.filter((user: UsersType) => user !== undefined),
      };

      clonedInputValues.users?.forEach((user: formUserType) => {
        const accessToSubscriptionIds = user.access_to?.map((subscription: Subscription) => subscription.id);
        const ownerOfSubscriptionIds = user.owner_of?.map((subscription: Subscription) => subscription.id);

        const payload = {
          accessToSubscriptionIds,
          comment: clonedInputValues.comment,
          email: user.email,
          name: user.name,
          ownerOfSubscriptionIds,
          role: user.role?.toLowerCase() as User['role'],
        };
        users.push(inviteUser(payload as inviteUserType));
      });

      await Promise.all(users);

      // Data cache update
      queryClient.invalidateQueries('tool-owners');
      queryClient.invalidateQueries('subscription-access');
      if (inviteUserInitialValue) {
        queryClient.invalidateQueries('subscriptions');
        queryClient.invalidateQueries('subscriptions-todos');
      }
      if (inviteUserInitialValue && isSubscription) {
        queryClient.invalidateQueries(['subscription', inviteUserInitialValue?.tool?.id]);
      }
      queryClient.refetchQueries(['users']);

      const successMessagePath = t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.success_message_text'
      );
      const message = `${inviteUserInputs.length} ${successMessagePath}`;

      showNotification?.(message, NotificationAlertType.Success);

      fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.InviteSent);

      toggleDrawer();
    } catch (error) {
      if ((error as { response: { status: number } }).response.status !== 400) {
        showNotification?.(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
      }
    }
  };

  const validateEmail = async (value: string) => {
    const inputValues = getValues();
    const isUserExists = usersData?.find((user) => user.email === value);

    if (isUserExists) {
      return false;
    }

    if (inputValues.users.length < 2) {
      return true;
    }

    const emailsArray = inputValues.users.map((user: formUserType) => user.email);

    let hasRepeatingEmails = false;

    hasRepeatingEmails = emailsArray.some((email: string, index: number) => {
      return emailsArray.indexOf(email) !== index;
    });

    return !hasRepeatingEmails;
  };

  return (
    <StyledBox
      sx={{
        '& .MuiGrid-root > .MuiGrid-item': {
          p: 2,
        },
      }}>
      <Box>
        <Typography variant='h2' data-testid='google-section'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.connect_google_title_text'
          )}
        </Typography>
        <GoogleConnectionComponent
          onSelectGoogleWorkSpaceUser={onSelectGoogleWorkSpaceUser}
          showNotification={showNotification}
        />
      </Box>
      <Box mt={3}>
        <Typography variant='h2' data-testid='invite-user-section'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.invite_user_title_text'
          )}
        </Typography>
      </Box>
      <Box sx={{ mt: 1.25 }}>
        <form>
          {inviteUserInputs.map((input) => {
            const { id, name } = input;
            const userErrors = errors.users as unknown as (typeof errors['user'])[];
            const fieldError = userErrors?.[id] as { [key: string]: typeof errors['user'] | typeof errors['email'] } | undefined;
            const isNameValid = Boolean(fieldError?.name);
            const isEmailValid = Boolean(fieldError?.email);

            const nameInputErrorType = fieldError?.name?.type as ErrorType;
            const emailInputErrorType = fieldError?.email?.type as ErrorType;

            const userRole = getValues(`users[${id}].role`);
            const isAccessFieldEnabled = !canAccessAllSubscriptions(userRole);

            const isViewUserRole = userRole?.toLowerCase() === UserRoles.Viewer;

            return (
              <Grid container spacing={3} key={name} alignItems='flex-start' data-testid='input-container'>
                <Grid item lg={2}>
                  <Grid sx={{ mb: 1.25 }}>
                    <Typography variant='subtitle'>
                      {t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.name_label'
                      )}{' '}
                      *
                    </Typography>
                  </Grid>
                  <Grid>
                    <Controller
                      name={`${name}.name`}
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => {
                        return (
                          <Input
                            dataTestId='invite-user-name-input'
                            error={isNameValid}
                            type='text'
                            value={value || ''}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder={t(
                              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.name_input_placeholder_text'
                            )}
                            helperText={getErrorMessage(nameInputErrorType, 'Name', value)}
                          />
                        );
                      }}
                      rules={{
                        max: 200,
                        min: 2,
                        pattern: USERNAME_VALIDATOR_REGEX,
                        required: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={2}>
                  <Grid sx={{ mb: 1.25 }}>
                    <Typography variant='subtitle'>
                      {t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.email_label'
                      )}{' '}
                      *
                    </Typography>
                  </Grid>
                  <Grid>
                    <Controller
                      name={`${name}.email`}
                      control={control}
                      render={({ field: { onBlur, onChange, value } }) => {
                        return (
                          <Input
                            error={isEmailValid}
                            dataTestId='invite-user-email-input'
                            type='email'
                            value={value || ''}
                            onChange={(e) => {
                              onChange(e);
                              trigger();
                            }}
                            onBlur={onBlur}
                            placeholder={t(
                              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.email_input_placeholder_text'
                            )}
                            helperText={getErrorMessage(emailInputErrorType, 'Email address', value)}
                          />
                        );
                      }}
                      rules={{ pattern: EMAIL_VALIDATOR_REGEX, required: true, validate: validateEmail }}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={3}>
                  <Grid display='flex' alignItems='center' sx={{ mb: 1.25 }}>
                    <Typography variant='subtitle'>
                      {t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.assign_role'
                      )}{' '}
                      *
                    </Typography>
                    <Box sx={{ ml: 1 }}>
                      <Tooltip
                        title={`${t(
                          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.assign_role_tooltip'
                        )}`}
                        placement='top'
                        arrow
                        className='is-flex'>
                        <a href={teamMemberPermissionsLink} target='_blank' rel='noreferrer'>
                          <Icon size='small' color='action'>
                            help
                          </Icon>
                        </a>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid>
                    <Controller
                      name={`${name}.role`}
                      defaultValue={defaultUserRole}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DropdownComponent
                            disabled={!isUserRolesFeatureEnabled}
                            value={value}
                            dataTestId='invite-user-role-dropdown'
                            className='role-dropdown'
                            placement='bottom-start'
                            placeholder='Select role'>
                            {roles.map((item) => {
                              return (
                                <MenuItem
                                  className='role-item'
                                  value={item.name}
                                  key={`role_${item.name}`}
                                  onClick={() => {
                                    onChange(item.name);
                                    handleUpdateRole(item.name, id);
                                  }}>
                                  <Box display='flex' flexDirection='column' className='role-content'>
                                    <Box mr='.5rem'>{item.name}</Box>
                                    <Box>{roleMenuOption(item.name)}</Box>
                                  </Box>
                                </MenuItem>
                              );
                            })}
                          </DropdownComponent>
                        );
                      }}
                      rules={{
                        required: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={3} sx={{ minWidth: '300px' }}>
                  <Grid display='flex' alignItems='center' sx={{ mb: 1.25 }}>
                    <Typography variant='subtitle'>
                      {t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.assign_owner'
                      )}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Controller
                      name={`${name}.owner_of`}
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <SubscriptionSelectInputComponent
                            defaultData={formattedTools || []}
                            selectedTools={userOwnerList}
                            disabled={isViewUserRole}
                            getValues={(value) => {
                              const newValue = value.map((item) => item.id);
                              onChange(newValue);
                              const newRow = `${FIELD_NAME}[${id}]`;
                              setValue(`${newRow}.owner_of`, newValue, { shouldValidate: true });
                            }}
                            handleUpdate={(value: SelectedSubscription[]) => handleUpdateOwnerOf(value, id)}
                            dataTestId='invite-user-owner-dropdown'
                            disableItems
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={3} sx={{ minWidth: '300px' }}>
                  <Grid sx={{ mb: 1.25 }}>
                    <Typography variant='subtitle'>
                      {t(
                        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.assign_access_to'
                      )}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Controller
                      name={`${name}.access_to`}
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <SubscriptionSelectInputComponent
                            defaultData={formattedTools || []}
                            selectedTools={getSelectedAccessToList(id)}
                            disableSelectedTools
                            accessToAllTools={!isAccessFieldEnabled}
                            disabled={!isAccessFieldEnabled || isViewUserRole}
                            getValues={(value) => {
                              const newValue = value.map((item) => item.id);
                              onChange(newValue);
                              const newRow = `${FIELD_NAME}[${id}]`;
                              setValue(`${newRow}.access_to`, newValue, { shouldValidate: true });
                            }}
                            handleUpdate={(value: SelectedSubscription[]) => handleUpdateAccessTo(value, id)}
                            dataTestId='invite-user-access-dropdown'
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                {inviteUserInputs.length > 1 && id !== 0 && (
                  <Grid item lg={1}>
                    <IconButton
                      aria-label='Remove input row'
                      data-testid='remove-row'
                      onClick={() => handleRemoveInput(id)}>
                      <Icon color='action'>delete</Icon>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            );
          })}
          <Button
            variant='text'
            color='primary'
            size='large'
            sx={{ mt: 4 }}
            startIcon={<Icon>add_circle</Icon>}
            aria-label='add-more-row'
            data-testid='add-more-row'
            onClick={() => handleAddInput()}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.add_more_input_text'
            )}
          </Button>
        </form>

        <Box sx={{ mt: 7.5 }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant='h2'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.custom_note_header_text'
              )}
            </Typography>
          </Box>

          <Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant='h3'>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.custom_note_input_label_text'
                )}
              </Typography>
            </Box>
            <Controller
              name='comment'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    dataTestId='invite-user-custom-note-textarea'
                    type='text'
                    value={value || ''}
                    onChange={onChange}
                    multiline
                    placeholder={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.custom_note_input_placeholder_text'
                    )}
                    rows={3}
                  />
                );
              }}
            />
          </Box>
        </Box>

        <Box display='flex' justifyContent='flex-end' mt='2rem'>
          <Box mr='1rem'>
            <Button variant='text' color='secondary' onClick={() => toggleDrawer()}>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.cancel_button_text'
              )}
            </Button>
          </Box>
          <Button
            variant='contained'
            color='primary'
            startIcon={isInvitingUser ? <CircularProgress color='inherit' size={16} /> : null}
            onClick={handleSubmit}
            data-testid='invite-users-button'
            disabled={!isValid || isInvitingUser}>
            {t(
              `subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.user_invite_section.form_section.${
                inviteUserInputs.length <= 1 ? 'send_invitation_button_text' : 'multiple_send_invitation_button_text'
              }`,
              {
                count: inviteUserInputs.length,
              }
            )}
          </Button>
        </Box>
      </Box>
    </StyledBox>
  );
};
