import { Box, BoxProps as MUIBoxProps, styled } from '@mui/material';
import { withTransientProps } from 'asteroids';

type TransientProps = {
  $padding: number;
};

export const SectorComponentContainer = styled(
  Box,
  withTransientProps
)<MUIBoxProps & TransientProps>(({ $padding, theme }) => {
  return {
    '& > svg': {
      margin: 'auto',
      transform: 'rotate(-90deg)',

      circle: {
        stroke: theme.palette.secondary.main,
        transition: 'stroke-dashoffset .750s cubic-bezier(.7,0,.3,1)',
        strokeWidth: '6px',
      },

      '.sector-bg': {
        strokeDashoffset: 0,
      },
      '.sector-fg': {
        stroke: theme.palette.success.main,
        strokeLinecap: 'round',
      },
    },

    '& .sector-content': {
      position: 'absolute',
      flex: '1 0 auto',
      boxSizing: 'border-box',
      padding: `${$padding}px`,
    },

    display: 'flex',
    position: 'relative',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
