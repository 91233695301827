import { Box, Button, Icon, Typography } from '@mui/material';
import { FooterActionBar } from 'components/footer-action-bar';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type BulkActionsFooterComponentProps = {
  exportCsv: () => void;
  handleBulkDelete: () => void;
  selectedRowsCount: number;
};

export const BulkActionsFooterComponent: FC<BulkActionsFooterComponentProps> = ({
  exportCsv,
  handleBulkDelete,
  selectedRowsCount,
}) => {
  const { t } = useTranslation();
  const translationPath = 'tool_details_view:spend_tab.spend_and_invoices_table';

  return (
    <FooterActionBar>
      <Typography variant='label' color='text.secondary' data-testid='number-invoices-selected'>
        {selectedRowsCount === 1
          ? t(`${translationPath}.table_actions.delete.selected_invoices_one`, {
              count: selectedRowsCount,
            })
          : t(`${translationPath}.table_actions.delete.selected_invoices_other`, {
              count: selectedRowsCount,
            })}
      </Typography>
      <Box display='flex' flexDirection='row'>
        <Box>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => exportCsv()}
            data-testid='export-csv'
            startIcon={<Icon>file_download</Icon>}>
            {t(`${translationPath}.table_actions.export.export_invoices`)}
          </Button>
        </Box>
        <Box ml={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleBulkDelete()}
            data-testid='bulk-delete'
            startIcon={<Icon>delete</Icon>}>
            {selectedRowsCount === 1
              ? t(`${translationPath}.table_actions.delete.delete_invoices_one`, {
                  count: selectedRowsCount,
                })
              : t(`${translationPath}.table_actions.delete.delete_invoices_other`, {
                  count: selectedRowsCount,
                })}
          </Button>
        </Box>
      </Box>
    </FooterActionBar>
  );
};
