import { NAVIGATION_URLS } from 'src/constants/navigation';

/*
 * FIXME: We decided not to show the breadcrumbs in some pages, because a new breadcrumbs was introduce
 * This is a temporary solution, until we have migrated every page to the new Page component
 */
export const getPagesPathWithoutBreadcrumbs = () =>
  [
    NAVIGATION_URLS.SASTRIFY_SUPPORT,
    NAVIGATION_URLS.REQUESTS,
    NAVIGATION_URLS.REQUESTS_WORKFLOWS,
    NAVIGATION_URLS.TOOL_STORE,
    NAVIGATION_URLS.DISCOVERY,
    NAVIGATION_URLS.USAGE_ANALYTICS,
    NAVIGATION_URLS.SASTRICLOUD,
    NAVIGATION_URLS.PRICING_BENCHMARKS,
    NAVIGATION_URLS.NEW_PRICING_BENCHMARK,
    NAVIGATION_URLS.NEW_PRICING_BENCHMARK_RESULTS,
    NAVIGATION_URLS.NOTIFICATIONS,
    NAVIGATION_URLS.TOOL_DETAILS,
    NAVIGATION_URLS.TOOLS_SPENDS_IMPORTER,
    NAVIGATION_URLS.SPEND_IMPORT,
    NAVIGATION_URLS.SAVINGS,
    NAVIGATION_URLS.USERS_MANAGEMENT,
    NAVIGATION_URLS.NEW_WORKFLOW,
    NAVIGATION_URLS.EDIT_WORKFLOW,
    NAVIGATION_URLS.WORKFLOW_DETAILS,
    NAVIGATION_URLS.PROCUREMENT_SUPPORT,
    NAVIGATION_URLS.PROCUREMENT_SUPPORT_DETAILS,
    NAVIGATION_URLS.HR_INFORMATION_SYSTEMS.INDEX,
    NAVIGATION_URLS.COMPANY_DATA,
    NAVIGATION_URLS.TOOL_MATCHING,
    NAVIGATION_URLS.USER_AND_COMPANY_DATA,
  ].filter((path) => !!path);
