import { AvatarGroup as MuiAvatarGroup, styled } from '@mui/material';
import { Avatar as AsteroidsAvatar } from 'asteroids';

export const Avatar = styled(AsteroidsAvatar)(() => ({
  '& img': {
    objectFit: 'contain',
  },
  '&:not(.MuiAvatar-colorDefault)': {
    background: 'white',
  },
}));

export const AvatarGroup = styled(MuiAvatarGroup)(({ theme }) => ({
  '& .MuiAvatar-root, & .MuiAvatarGroup-avatar': {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  '& .MuiAvatarGroup-avatar': {
    marginLeft: theme.spacing(-0.5),
  },
}));
