import emptyStateSvg from 'assets/images/empty-state.svg';
import { ArticleSmall } from 'components-2.0/article-small';
import { FC } from 'react';

import { StatusDisplayComponentProps } from './status-display.component.props';

export const StatusDisplay: FC<StatusDisplayComponentProps> = (props) => {
  const { buttonLabel, description, icon, iconHeight, onButtonClick, primaryButton, secondaryButton, title } = props;

  return (
    <ArticleSmall
      dataTestId='no-results'
      image={{
        alt: title,
        height: iconHeight,
        src: icon || emptyStateSvg,
      }}
      title={title}
      description={description}
      primaryButton={{
        ...primaryButton,
        children: buttonLabel || primaryButton?.children,
        onClick: onButtonClick || primaryButton?.onClick,
      }}
      secondaryButton={secondaryButton}
    />
  );
};
