import classnames from 'classnames';
import { FC, SyntheticEvent } from 'react';

import { PageFooter } from './components/footer/page-footer.component';
import { PageHeader } from './components/header/page-header.component';
import { PageSubHeader } from './components/sub-header/page-sub-header.component';
import { PageTabs } from './components/tabs';
import { TabsPanels } from './components/tabs-panels';
import { useTabs } from './hooks/useTabs';
import { PageProps } from './page.props';
import { StyledPageContainer } from './page.styles';

export const PAGE_CONTAINER_WITH_FOOTER_CLASS = 'page-container--with-footer';

export const Page: FC<PageProps> = ({
  attachHeaderToContent,
  backButton,
  breadcrumbFinalLink,
  children,
  className,
  footer,
  header,
  headerActions,
  headerSectionRight,
  hideBreadcrumbs = false,
  isLoadingData,
  style,
  subHeader,
  tabs,
}: PageProps) => {
  const { activeTab, setActiveTab } = useTabs(tabs?.items || []);

  const handleOnChange = (e: SyntheticEvent<Element, Event>, newValue: number) => {
    setActiveTab(newValue);
    tabs?.onChange?.(newValue);
  };

  return (
    <StyledPageContainer
      className={classnames(className, { [PAGE_CONTAINER_WITH_FOOTER_CLASS]: !!footer })}
      style={style}
      id='page-container'>
      {header && (
        <PageHeader
          key='page-header'
          attachToContent={!subHeader && attachHeaderToContent}
          backButton={backButton}
          sectionRight={headerSectionRight}
          finalLink={breadcrumbFinalLink}
          headerActions={headerActions}
          hideBreadcrumbs={hideBreadcrumbs}
          isLoadingData={isLoadingData}>
          {header}
          {tabs && (
            <PageTabs
              items={tabs.items.map((tab) => ({ label: tab.label }))}
              value={activeTab}
              onChange={handleOnChange}
            />
          )}
        </PageHeader>
      )}
      {subHeader && (
        <PageSubHeader key='page-subheader' attachToContent={attachHeaderToContent}>
          {subHeader}
        </PageSubHeader>
      )}
      <section key='page-container' className='page-container' data-testid='page-container'>
        {tabs && <TabsPanels item={tabs.items[activeTab]} value={activeTab} />}
        {children}
      </section>
      {footer && <PageFooter key='page-footer'>{footer}</PageFooter>}

      {/* Used to render page footer via React Portal */}
      <div id='page-footer-placeholder' />
    </StyledPageContainer>
  );
};
