import { Box } from '@mui/material';
import { Alert } from 'asteroids';
import { ArticleSmall } from 'components-2.0/article-small';
import { useTranslation } from 'react-i18next';

import { UpsellingPromptViewProps } from '../../upselling-prompt.props';

export const UpsellingPromptView = ({
  description,
  featureName,
  handleAddonRequest,
  image,
  imageHeight,
  isAddonRequested,
  learnMoreLink,
  submitting,
  sx,
}: UpsellingPromptViewProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ margin: 'auto', maxWidth: '650px' }} data-testid='upselling-prompt-view'>
      <ArticleSmall
        image={{
          alt: featureName,
          height: imageHeight,
          src: image,
        }}
        title={t('pricing_plans:upselling_prompt.title')}
        description={t('pricing_plans:upselling_prompt.description') || description}
        primaryButton={{
          children: t('pricing_plans:upselling_prompt.button_text'),
          dataTestId: 'prompt-view-primary-btn',
          disabled: isAddonRequested || submitting,
          loading: submitting,
          onClick: handleAddonRequest,
        }}
        secondaryButton={{
          children: `${t('pricing_plans:upselling_prompt.second_button_text')} ${featureName}`,
          link: {
            dataTestId: 'prompt-view-link',
            rel: 'noreferrer',
            target: '_blank',
            to: { pathname: learnMoreLink },
          },
          sx: { marginTop: 1 },
        }}
        sx={sx}
      />
      {isAddonRequested && (
        <Alert
          severity='info'
          description={t('pricing_plans:upselling_prompt.requestedAlert')}
          sx={{ marginBottom: 3, marginTop: 1, width: '100%' }}
          data-testid='prompt-view-alert'
        />
      )}
    </Box>
  );
};
