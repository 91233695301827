import { FileItemCategory } from './file-item.component.props';

export const formatTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp);

  return `${date.getDate()} ${new Intl.DateTimeFormat('en-US', {
    month: 'short',
  }).format(date)} ${date.getFullYear()}`;
};

export const fileItemCategoryOptions = [
  { label: FileItemCategory.miscellaneous, value: 0 },
  { label: FileItemCategory.termsAndConditions, value: 1 },
  { label: FileItemCategory.invoice, value: 2 },
  { label: FileItemCategory.contract, value: 3 },
  { label: FileItemCategory.offer, value: 4 },
  { label: FileItemCategory.dataProcessingAgreement, value: 5 },
];

export const truncateString = (
  stringToTruncate: string,
  charCountBefore = stringToTruncate.length,
  charCountAfter = 0,
  dotCount = 3
) => {
  if (stringToTruncate.length <= charCountBefore + charCountAfter) {
    return stringToTruncate;
  }

  const firstPortion = stringToTruncate.slice(0, charCountBefore);
  const endPortion = stringToTruncate.slice(-charCountAfter);
  const dots = '.'.repeat(dotCount);

  return `${firstPortion}${dots}${endPortion}`;
};

export const getFileSizeInMB = (fileSizeInBytes: number): string => `${(fileSizeInBytes / 1024 ** 2).toFixed(2)}MB`;
