import { yupResolver } from '@hookform/resolvers/yup';
import { Clear } from '@mui/icons-material';
import { Box, IconButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import {
  DatePickerComponent,
  NumberFormatInput,
  TagEditorComponent,
  TypeaheadComponent,
  VendorSelectInputComponent,
} from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useDebounce } from 'hooks/useDebounce';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Tag } from 'react-tag-autocomplete';
import { Currency, CurrencyOptions, googleTagManagerEvents } from 'shared/common.definitions';
import { formatCompanyTags, isNewSolutionsRoute } from 'shared/helpers/common.helper';
import { normalizeDateToUTC } from 'shared/helpers/dates.helper';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import { NewSubscription, postNewSolution, postSubscription } from 'shared/logic/subscriptions.logic';
import { Company, FilterTag, SastrifyStore, Subscription, ToolOwnerFilter, User, VendorType } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { colors } from 'shared/theme';
import { AppUrl } from 'src/constants/appurl';
import * as yup from 'yup';

import { SubscriptionStatusFeature, UploadSubscriptionDocumentsFeature } from '..';
import { AddNewSubscriptionFormFeatureProps } from './add-new-subscription-form.props';

const PREFIX = 'AddNewSubscriptionFeature';

const classes = {
  placeholderText: `${PREFIX}-placeholderText`,
  root: `${PREFIX}-root`,
};

const Root = styled('section')({
  [`& .${classes.placeholderText}`]: {
    color: `${colors.dustyGray} !important`,
    fontFamily: 'inherit !important',
  },
  [`&.${classes.root}`]: {
    '& .comment': {
      '&:focus-visible': {
        outlineColor: colors.primary,
      },
      borderColor: colors.alto,
      borderRadius: '0.25rem',
      fontFamily: 'inherit',
      fontSize: '1rem',
      lineHeight: '1rem',
      padding: '0.813rem 0.5rem',
      width: '100%',
    },
    '& .label': {
      '& .MuiTypography-body1': {
        fontSize: '0.875rem',
        fontWeight: '500',
      },
      '& .mandatory': {
        color: colors.greyText,
        fontStyle: 'italic',
        fontWeight: '400',
        fontsize: '0.875rem',
        marginLeft: '0.3rem',
      },
    },
    '& .react-tags__search-input': {
      color: colors.greyText,
      height: '2.5rem',
    },
    overflow: 'auto',
  },
});

const addNewSubscriptionSchema = yup.object().shape({
  billingFrequency: yup.number(),
  currentYearCost: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  lastYearCost: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  name: yup.string().required(),
  otherBillingFrequency: yup.number().min(0),
  otherBillingFrequencyType: yup.string(),
  renewalDate: yup.string().when('billingFrequency', {
    is: (billingFrequency: number) => Number(billingFrequency) > 0,
    then: yup.string().required(),
  }),
});

type ToolOwner = { avatar?: string; id?: string; name: string; value?: string };

const newSolutionSchema = yup.object().shape({
  budgetCents: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  decisionDate: yup.string(),
  name: yup.string().required(),
  state: yup.number().required(),
});

const transitionToSubscriptionSchema = yup.object().shape({
  billingFrequency: yup.number(),
  budgetCents: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  comment: yup.string().required(),
  otherBillingFrequency: yup.number().min(0),
  otherBillingFrequencyType: yup.string(),
  renewalDate: yup.string().when('billingFrequency', {
    is: (billingFrequency: number) => Number(billingFrequency) > 0,
    then: yup.string().required(),
  }),
});

export const AddNewSubscriptionFeature = forwardRef((props: AddNewSubscriptionFormFeatureProps, ref) => {
  const {
    closeModal,
    initialSelectedVendor,
    isAddNewSolution,
    isTransitionToSubscription,
    onSubmitStatusChange,
    showNotification,
    subscriptionId,
  } = props;

  const location = useLocation();
  const isSubscriptionInDiscovery =
    isNewSolutionsRoute(location.pathname) || Boolean(initialSelectedVendor) || isAddNewSolution;

  const getSchema = () => {
    if (isTransitionToSubscription) return transitionToSubscriptionSchema;
    if (isSubscriptionInDiscovery) return newSolutionSchema;
    return addNewSubscriptionSchema;
  };

  const {
    control,
    formState: { isValid },
    getValues,
    register,
    reset,
    setValue,
    watch,
  } = useForm<NewSubscription>({
    mode: 'onChange',
    resolver: yupResolver(getSchema()),
    shouldUnregister: true,
  });

  const queryClient = useQueryClient();

  const company = queryClient.getQueryData<Company>('company');
  const subscription = queryClient.getQueryData<Subscription>(['subscription', subscriptionId]);

  const history = useHistory();

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [vendorName, setVendorName] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>();
  const [selectedVendor, setSelectedVendor] = useState<VendorType>();
  const [tags, setTags] = useState<FilterTag[]>([]);
  const [tagSuggestions, setTagSuggestions] = useState<FilterTag[]>([]);
  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState<ToolOwnerFilter>({ avatar: '', id: '', name: '' });
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

  const { t } = useTranslation();
  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const isMounted = useRef(true);
  const uploadDocumentRef = useRef<{ onSubmit: (id?: string) => Promise<void> }>();

  const dispatch = useDispatch();
  useDebounce(setDebouncedValue, vendorName, 500);

  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');

  useEffect(() => {
    if (isMounted.current && initialSelectedVendor) {
      setSelectedVendor(initialSelectedVendor);
      setVendorName(initialSelectedVendor.name);
      setValue('name', initialSelectedVendor.name, { shouldValidate: true });
    }
    isMounted.current = false;
  }, [initialSelectedVendor, setValue]);

  useEffect(() => {
    if (!isValid) dispatch(updateFooterState({ isFormValid: isValid }));
    return () => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    };
  }, [dispatch, isValid]);

  useEffect(() => {
    if (company?.companyTags) {
      const formattedTags = formatCompanyTags(company) as FilterTag[];
      setTagSuggestions(formattedTags);
    }
  }, [company, setTagSuggestions]);

  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList.map((user: User) => ({
        avatar: user?.avatar,
        name: user.name,
        value: user.id || user.name?.replaceAll(' ', '-'),
      }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  useEffect(() => {
    if (subscription) {
      const formattedTags = subscription?.tags?.map((tag, index) => ({ id: index, name: tag }));
      setTags(formattedTags as FilterTag[]);
      setValue('budgetCents', Number(subscription?.budgetCents) / 100);
    }
  }, [setValue, subscription]);

  const updateSubscriptionsCacheAndCloseModal = (data: Subscription, isInvalidateQueries?: boolean) => {
    const queryKey = isSubscriptionInDiscovery ? ['subscriptions-in-discovery', true] : 'subscriptions';
    queryClient.setQueryData(queryKey, (cacheData) => {
      if (cacheData) {
        const subscription = cacheData as Array<Subscription>;
        return [...subscription, data];
      }
    });
    if (isInvalidateQueries) {
      queryClient.invalidateQueries('subscriptions');
      queryClient.invalidateQueries('subscriptions-todos');
    }

    queryClient.invalidateQueries('subscriptions-and-new-solutions');
    closeModal?.();
    const message = `${isSubscriptionInDiscovery ? 'Tool request' : 'Subscription'} successfully created`;
    showNotification?.(message, NotificationAlertType.Success);
    reset();
    history.push(AppUrl.getToolUrl(data, isSubscriptionInDiscovery));
  };

  const handleUploadDocument = async (id: string) => {
    if (isFileSelected) {
      await uploadDocumentRef?.current?.onSubmit(id);
    }
  };

  const { mutate } = useMutation(isSubscriptionInDiscovery ? postNewSolution : postSubscription, {
    onError: () => {
      const message = t('common:modals.add_new_subscription_feature_modal.error_message', {
        value: isSubscriptionInDiscovery ? 'Tool request' : 'Subscription',
      });

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSettled: () => {
      if (isSubscriptionInDiscovery) dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    },
    onSuccess: async (data) => {
      if (!isSubscriptionInDiscovery) {
        await handleUploadDocument(String(data.id));
        updateSubscriptionsCacheAndCloseModal(data, true);

        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.SubscriptionCreated);
      } else {
        updateSubscriptionsCacheAndCloseModal(data);

        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.NewSolutionCreated);
      }
    },
  });

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner({ avatar: '', id: '', name: '' });
      setToolOwnerName('');
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleClearButtonClicked = () => {
    setToolOwner({ avatar: '', id: '', name: '' });
    setToolOwnerName('');
  };

  const onSubmit = async () => {
    dispatch(updateFooterState({ isFormSubmitting: true }));
    const selectedTags = tags?.length ? tags.map((item) => item.name) : [];
    const formValues = getValues();
    const subscriptionClone = { ...formValues };
    const vendorId = selectedVendor && selectedVendor.name === getValues().name ? selectedVendor.id : undefined;

    const { otherBillingFrequency, otherBillingFrequencyType } = subscriptionClone;

    const renewalInterval = otherBillingFrequencyType
      ? { [otherBillingFrequencyType as string]: Number(otherBillingFrequency) }
      : null;

    const { billingFrequency } = subscriptionClone;
    const budgetCents = Number(getValues('budgetCents')) * 100 || undefined;
    if (billingFrequency === 0 && subscriptionClone.renewalDate) delete subscriptionClone.renewalDate;
    else if (String(billingFrequency) === '-1') delete subscriptionClone.billingFrequency;
    else if (billingFrequency && billingFrequency > 0 && subscriptionClone.renewalDate)
      subscriptionClone.renewalDate = normalizeDateToUTC(new Date(String(subscriptionClone.renewalDate)));

    if (renewalInterval) {
      delete subscriptionClone.otherBillingFrequencyType;
      delete subscriptionClone.otherBillingFrequency;
    }

    let newSubscription: NewSubscription = {
      ...subscriptionClone,
      ownerId: toolOwner.value,
      ownerName: toolOwner.name ? toolOwner.name : undefined,
      tags: selectedTags,
      vendorId,
    };

    if (!isTransitionToSubscription) {
      if (isSubscriptionInDiscovery) {
        newSubscription = {
          ...newSubscription,
          budgetCents,
          budgetCurrency: DEFAULT_CURRENCY,
          state: Number(getValues('state')),
        };
      } else {
        const currentYearCost = Math.round(Number(subscriptionClone?.currentYearCost) * 100) || undefined;
        const lastYearCost = Math.round(Number(subscriptionClone?.lastYearCost) * 100) || undefined;
        newSubscription = {
          ...newSubscription,
          currentYearCost,
          lastYearCost,
          renewalInterval,
        };
      }
      mutate(newSubscription);

      // update dataLayer on google tag manager
    } else {
      delete subscriptionClone.comment;
      const subscriptionData = {
        ...subscription,
        ...subscriptionClone,
        billingFrequency: Number(billingFrequency) === -1 ? undefined : Number(billingFrequency),
        budgetCents,
        budgetCurrency: DEFAULT_CURRENCY,
        renewalInterval,
        tags: selectedTags,
      };

      await handleUploadDocument(String(subscriptionId));
      onSubmitStatusChange?.(
        {
          comment: getValues('comment'),
          subscription: subscriptionData,
        },
        false
      );
    }
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  const billingFrequency = watch('billingFrequency');

  const handleItemSelected = (item: VendorType) => {
    if (item) {
      setValue('name', item.name, { shouldValidate: true });
      setSelectedVendor(item);
    } else {
      setSelectedVendor(undefined);
    }
  };

  const onRemoveTag = (tagIndex: number) => {
    let updatedTags: FilterTag[];
    if (tags.length === 1) {
      updatedTags = [];
    } else {
      updatedTags = tags.filter((_tag, index) => index !== tagIndex);
    }
    const removedTag = tags[tagIndex] as FilterTag;
    const updatedSuggestions: FilterTag[] = [...(tagSuggestions as FilterTag[]), removedTag];
    setTags(updatedTags);
    setTagSuggestions(updatedSuggestions);
  };

  const onAddTag = (tag: Tag) => {
    const newTag: FilterTag = { id: Number(tag.id), name: tag.name };

    const updatedTags = [...tags, newTag];
    const updatedSuggestions = tagSuggestions?.filter((tag) => tag.id !== newTag.id);

    setTags(updatedTags);
    setTagSuggestions(updatedSuggestions);
  };

  return (
    <Root className={classes.root}>
      {!isSubscriptionInDiscovery || isTransitionToSubscription ? (
        <>
          {!isTransitionToSubscription ? (
            <>
              <Box my={1} display='flex' flexDirection='column'>
                <ListItemText className='label'>
                  {t('common:modals.add_new_subscription_feature_modal.body_section.form.name_text_input_label')}
                  <span className='mandatory'>
                    {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
                  </span>
                </ListItemText>
                <Controller
                  name='name'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <VendorSelectInputComponent
                        handleItemSelected={handleItemSelected}
                        setDebouncedValue={setDebouncedValue}
                        vendorName={vendorName}
                        debouncedValue={debouncedValue}
                        onChange={onChange}
                        setVendorName={setVendorName}
                        placeholder={t(
                          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.subscription_name_placeholder'
                        )}
                        value={value}
                      />
                    );
                  }}
                />
              </Box>
              <Box
                my={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                data-testid='subscription-cost'>
                <Box display='flex' flexDirection='column' width='48%'>
                  <ListItemText className='label'>
                    {t(
                      'common:modals.add_new_subscription_feature_modal.body_section.form.cost_last_year_number_input_label'
                    )}
                  </ListItemText>
                  <Controller
                    name='lastYearCost'
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NumberFormatInput
                          decimalScale={2}
                          decimalSeparator='.'
                          fixedDecimalScale
                          step='0.1'
                          thousandSeparator
                          prefix={
                            company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY
                          }
                          value={value}
                          onValueChange={(value) => onChange(value.floatValue)}
                          placeholder={t(
                            'common:modals.add_new_subscription_feature_modal.body_section.form.cost_last_year_number_input_placeholder'
                          )}
                        />
                      );
                    }}
                  />
                </Box>
                <Box display='flex' flexDirection='column' width='48%'>
                  <ListItemText className='label'>
                    {t(
                      'common:modals.add_new_subscription_feature_modal.body_section.form.cost_this_year_number_input_label'
                    )}
                  </ListItemText>
                  <Controller
                    name='currentYearCost'
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NumberFormatInput
                          decimalScale={2}
                          decimalSeparator='.'
                          fixedDecimalScale
                          thousandSeparator
                          prefix={
                            company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY
                          }
                          value={value}
                          onValueChange={(value) => onChange(value.floatValue)}
                          placeholder={t(
                            'common:modals.add_new_subscription_feature_modal.body_section.form.cost_this_year_number_input_placeholder'
                          )}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Box display='flex' flexDirection='column'>
              <ListItemText className='label'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.comment_text_input_label')}
                <span className='mandatory'>
                  {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
                </span>
              </ListItemText>
              <textarea
                data-testid='comment-editor'
                rows={4}
                className='comment'
                placeholder={t(
                  'common:modals.add_new_subscription_feature_modal.body_section.form.comment_text_input_placeholder'
                )}
                {...register('comment')}
              />
            </Box>
          )}

          <Box my={1} display='flex' flexDirection='column'>
            <ListItemText className='label'>
              {t(
                'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.input_label'
              )}
            </ListItemText>
            <div className='select is-fullwidth is-expanded'>
              <select
                className={classnames('select-billingfrequency is-expanded', {
                  [classes.placeholderText]: billingFrequency && billingFrequency < 0,
                })}
                {...register('billingFrequency')}>
                <option value={-1}>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.default_option_text'
                  )}
                </option>
                <option value={0}>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.monthly_option_text'
                  )}
                </option>
                <option value={1}>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.yearly_option_text'
                  )}
                </option>
                <option value={3}>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.other_option_text'
                  )}
                </option>
              </select>
            </div>
          </Box>
          {billingFrequency && billingFrequency > 2 && (
            <Box my={2} display='flex'>
              <Box mr={1} width='50%'>
                <div className='select is-fullwidth is-expanded'>
                  <select
                    {...register('otherBillingFrequencyType', {
                      required: true,
                    })}>
                    <option value='months'>
                      {t(
                        'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_interval.months_option_text'
                      )}
                    </option>
                    <option value='years'>
                      {t(
                        'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_interval.years_option_text'
                      )}
                    </option>
                  </select>
                </div>
              </Box>
              <Box width='50%'>
                <input
                  {...register('otherBillingFrequency', {
                    required: true,
                  })}
                  className='input is-fullwidth'
                  type='number'
                  min={0}
                />
              </Box>
            </Box>
          )}
          {billingFrequency && billingFrequency > 0 && (
            <Box my={1} display='flex' flexDirection='column' className='sastrify-datepicker'>
              <ListItemText className='label'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.renewal_date_input_label')}
                <span className='mandatory'>
                  {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
                </span>
              </ListItemText>
              <Controller
                name='renewalDate'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePickerComponent
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setOpenDatePicker(false);
                    }}
                    openDatePicker={openDatePicker}
                    setOpenDatePicker={setOpenDatePicker}
                    minDate={new Date()}
                  />
                )}
              />
            </Box>
          )}

          {isTransitionToSubscription && (
            <Box my={1} display='flex' flexDirection='column'>
              <ListItemText className='label'>
                {t(
                  'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.label_text'
                )}
              </ListItemText>
              <Controller
                name='budgetCents'
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <NumberFormatInput
                      decimalScale={2}
                      decimalSeparator='.'
                      fixedDecimalScale
                      thousandSeparator
                      prefix={
                        company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY
                      }
                      value={value}
                      onValueChange={(value) => onChange(value.floatValue)}
                      placeholder={t(
                        'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.placeholder_text'
                      )}
                    />
                  );
                }}
              />
            </Box>
          )}
          <Box my={1} display='flex' flexDirection='column' className='sastrify-tags'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.label_text'
              )}
            </ListItemText>
            <TagEditorComponent
              isCustom
              tags={tags}
              placeholderText={t(
                'common:modals.add_new_subscription_feature_modal.body_section.form.tags_input_placeholder_text'
              )}
              suggestions={tagSuggestions}
              isFilterTag
              allowNew={false}
              onAddition={(tag) => onAddTag(tag)}
              onDelete={(tagIndex) => onRemoveTag(tagIndex)}
            />
          </Box>
          {!isTransitionToSubscription && (
            <Box my={1} display='flex' flexDirection='column'>
              <ListItemText className='label'>
                {t(
                  'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.label_text'
                )}
              </ListItemText>
              <TypeaheadComponent
                data={toolOwners}
                onItemSelected={onToolOwnerSelected}
                onBlurToolOwnerFilter={onToolOwnerSelected}
                value={toolOwnerName}
                fields={['name', 'email']}
                isToolOwnerFilter
                onTextChanged={onChangeToolOwner}
                placeholder={t(
                  'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.placeholder_text'
                )}
                isSubscriptionInDiscovery
                rightIcon={
                  <IconButton onClick={handleClearButtonClicked} onMouseDown={(e) => e.preventDefault()} size='large'>
                    <Clear color='secondary' />
                  </IconButton>
                }
              />
            </Box>
          )}

          <Box my={1} display='flex' flexDirection='column'>
            <ListItemText className='label'>
              {t('common:modals.add_new_subscription_feature_modal.body_section.form.upload_document_label')}
            </ListItemText>
            <UploadSubscriptionDocumentsFeature
              isAddNewSubscription
              ref={uploadDocumentRef}
              companyId={String(company?.id)}
              setIsFileSelected={setIsFileSelected}
            />
          </Box>
        </>
      ) : (
        <section className={classes.root}>
          <Box my={1} display='flex' flexDirection='column'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tool_name_input_section.label_text'
              )}
              <span className='mandatory'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
              </span>
            </ListItemText>
            <Controller
              name='name'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <VendorSelectInputComponent
                    handleItemSelected={handleItemSelected}
                    setDebouncedValue={setDebouncedValue}
                    vendorName={vendorName}
                    debouncedValue={debouncedValue}
                    onChange={onChange}
                    setVendorName={setVendorName}
                    placeholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tool_name_input_section.placeholder_text'
                    )}
                    value={value}
                  />
                );
              }}
            />
          </Box>
          <Box my={1} display='flex' flexDirection='column'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.status_input_section.label_text'
              )}
              <span className='mandatory'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
              </span>
            </ListItemText>
            <Controller
              name='state'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SubscriptionStatusFeature
                    isFilterDrawer
                    handleStatusChange={(value) => onChange(value)}
                    value={value ? String(value) : ''}
                    customPlaceholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.status_input_section.placeholder_text'
                    )}
                    isSubscriptionInDiscovery
                  />
                );
              }}
            />
          </Box>
          <Box my={1} display='flex' flexDirection='column'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.label_text'
              )}
            </ListItemText>
            <TypeaheadComponent
              data={toolOwners}
              onItemSelected={onToolOwnerSelected}
              onBlurToolOwnerFilter={onToolOwnerSelected}
              value={toolOwnerName}
              fields={['name', 'email']}
              isToolOwnerFilter
              onTextChanged={onChangeToolOwner}
              placeholder={t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.placeholder_text'
              )}
              isSubscriptionInDiscovery
              rightIcon={
                <IconButton onClick={handleClearButtonClicked} onMouseDown={(e) => e.preventDefault()} size='large'>
                  <Clear color='secondary' />
                </IconButton>
              }
            />
          </Box>
          <Box my={1} display='flex' flexDirection='column'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.label_text'
              )}
            </ListItemText>
            <Controller
              name='budgetCents'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <NumberFormatInput
                    decimalScale={2}
                    decimalSeparator='.'
                    fixedDecimalScale
                    thousandSeparator
                    prefix={company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY}
                    value={value}
                    onValueChange={(value) => onChange(value.floatValue)}
                    placeholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.placeholder_text'
                    )}
                  />
                );
              }}
            />
          </Box>
          <Box my={1} display='flex' flexDirection='column' className='sastrify-datepicker'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.decision_date_input_section.label_text'
              )}
            </ListItemText>
            <Controller
              name='decisionDate'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePickerComponent
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setOpenDatePicker(false);
                    }}
                    openDatePicker={openDatePicker}
                    setOpenDatePicker={setOpenDatePicker}
                    minDate={new Date()}
                    placeholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.decision_date_input_section.placeholder_text'
                    )}
                  />
                );
              }}
            />
          </Box>
          <Box my={1} display='flex' flexDirection='column' className='sastrify-tags'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.label_text'
              )}
            </ListItemText>
            <TagEditorComponent
              isCustom
              tags={tags}
              placeholderText={t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.placeholder_text'
              )}
              suggestions={tagSuggestions}
              isFilterTag
              allowNew={false}
              onAddition={(tag) => onAddTag(tag)}
              onDelete={(tagIndex) => onRemoveTag(tagIndex)}
            />
          </Box>
        </section>
      )}
    </Root>
  );
});
