import { DEFAULT_STATE_OPTIONS } from 'libs/enums/subscription.enum';

import { Subscription } from '../models';
import { SortOption } from '../models/subscription.model';

export function updateLatestDiscovered(latestDiscovered: number): void {
  localStorage.setItem('latestDiscovered', String(latestDiscovered));
}

export function getSavedDiscoveredDate(): number | null {
  return localStorage.getItem('latestDiscovered') ? Number(localStorage.getItem('latestDiscovered')) : null;
}

export const setDataLoadingAlertClosedDate = (date: number) => {
  localStorage.setItem('dataLoadingAlertClosedDate', String(date));
};

export const getDataLoadingAlertClosedDate = () => {
  return localStorage.getItem('dataLoadingAlertClosedDate')
    ? Number(localStorage.getItem('dataLoadingAlertClosedDate'))
    : null;
};

export function isDiscoveredBadgeShown(latestDiscoveredDate: number | null): boolean {
  const savedLatestDiscoveredDate = getSavedDiscoveredDate();

  if (latestDiscoveredDate === null) {
    return false;
  }

  return savedLatestDiscoveredDate === null || savedLatestDiscoveredDate < latestDiscoveredDate;
}

export function getDiscoveredTools(tools: Subscription[]): Subscription[] {
  return tools.filter((tool) => tool.state === DEFAULT_STATE_OPTIONS.DISCOVERED);
}

export function getLatestDiscoveredDateFromTools(tools: Subscription[]): number | null {
  const discoveredTools = getDiscoveredTools(tools);

  discoveredTools.sort((toolA, toolB) => {
    const dateA = toolA.createdAt as string;
    const dateB = toolB.createdAt as string;

    return new Date(dateB).getTime() - new Date(dateA).getTime();
  });

  const latestDiscoveredDate = discoveredTools?.[0]?.createdAt as string;

  if (!latestDiscoveredDate) {
    return null;
  }

  return new Date(latestDiscoveredDate).getTime();
}

export const saveDiscoveredToolSorting = (data: SortOption) => {
  localStorage.setItem('discoveredToolsSorting', JSON.stringify(data));
};

export const getSavedDiscoveredToolSorting = (): SortOption | void => {
  const data = localStorage.getItem('discoveredToolsSorting');
  if (data) {
    const parsed = JSON.parse(data);
    return {
      field: parsed.field,
      order: parsed.order,
      type: parsed.type,
    };
  }
};

export const getDefaultDiscoveredToolsSortingOption = () => {
  const savedDefaultSortingOption = getSavedDiscoveredToolSorting();
  return (
    savedDefaultSortingOption || {
      field: 'createdAt',
      order: 'desc',
      type: 'date',
    }
  );
};
