import { FC } from 'react';

export const GoogleWorkspaceIcon: FC<React.SVGProps<SVGElement>> = ({ height, width }) => (
  <svg width={width} height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx={12} cy={12} r={11.9} fill='#fff' stroke='#E4E3E3' strokeWidth={0.2} />
    <g clipPath='url(#a)'>
      <path
        d='M19.992 12.15c0-.656-.055-1.134-.173-1.63h-7.66v2.958h4.497c-.091.736-.58 1.843-1.668 2.587l-.016.1 2.422 1.833.168.016c1.541-1.39 2.43-3.437 2.43-5.864'
        fill='#4285F4'
      />
      <path
        d='M12.16 19.945c2.202 0 4.051-.709 5.402-1.931l-2.574-1.949c-.69.47-1.614.797-2.829.797a4.901 4.901 0 0 1-4.641-3.313l-.096.008-2.518 1.905-.033.09c1.342 2.604 4.097 4.393 7.288 4.393Z'
        fill='#34A853'
      />
      <path
        d='M7.518 13.55a4.807 4.807 0 0 1-.272-1.578c0-.549.1-1.08.263-1.576l-.004-.106-2.55-1.935-.084.039a7.832 7.832 0 0 0-.87 3.579c0 1.284.317 2.497.87 3.578l2.647-2.002'
        fill='#FBBC05'
      />
      <path
        d='M12.16 7.083c1.531 0 2.565.646 3.154 1.187l2.302-2.197C16.202 4.788 14.362 4 12.16 4 8.97 4 6.213 5.79 4.871 8.394l2.638 2.002a4.922 4.922 0 0 1 4.65-3.313'
        fill='#EB4335'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' transform='translate(4 4)' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
);

GoogleWorkspaceIcon.defaultProps = {
  height: 24,
  width: 24,
};
