import { InputField } from 'asteroids';
import { FC, forwardRef } from 'react';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';

interface NumberInputProps extends Omit<NumberFormatPropsBase, 'size'> {
  size?: 'small' | 'medium';
  label?: string;
  error?: boolean;
  helperText?: string;
  onClear?: () => void;
}

const NumberFormatCustom = forwardRef<NumberFormat>((props, ref) => <NumberFormat {...props} ref={ref} />);

export const NumberInput: FC<NumberInputProps> = (props) => {
  const {
    decimalScale,
    decimalSeparator,
    fixedDecimalScale,
    name,
    onClear,
    onValueChange,
    prefix,
    thousandSeparator,
    value,
    ...rest
  } = props;

  return (
    <InputField
      name={name}
      value={value}
      onClearValue={onClear}
      id={`formatted-numberformat-input-${name}`}
      inputComponent={NumberFormatCustom as never}
      inputProps={{
        allowNegative: false,
        decimalScale,
        decimalSeparator,
        fixedDecimalScale,
        onValueChange,
        prefix,
        thousandSeparator,
        value,
      }}
      {...rest}
    />
  );
};
