import { HistoryTimelineHeaderComponent } from 'components/history-timeline-header';
import React, { useContext, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { featureFlags } from 'shared/common.definitions';
import { isNewSolutionsRoute, isToolRequestsRoute } from 'shared/helpers/common.helper';
import { mapActionDescription } from 'shared/logic/subscription-item.logic';
import { SubscriptionHistory } from 'shared/models';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { AppContainerContext } from 'views/app-container';

import { SubscriptionTimelineItemFeature, SubscriptionTimelineItemFeatureProps } from '../subscription-timeline-item';
import { SubscriptionTimelineFeatureProps } from '.';
import { useScrollToComment } from './hooks/use-scroll-to-comment';
import { getFilteredHistory } from './utils/filtered-history.util';

enum HistoryFilter {
  COMMENTS = 'comments',
  ALL = 'all',
}

const HISTORY_FILTER_SESSION_KEY = 'historyFilter';

export const SubscriptionTimelineFeature: React.FC<SubscriptionTimelineFeatureProps> = (
  props: SubscriptionTimelineFeatureProps
) => {
  const { closeModal, documents, history, showModal, showNotification, spendAndInvoices, subscription } = props;

  const isProcurementSupportEnabled = !useCompanyFeatureFlag(featureFlags.PROCUREMENT_SUPPORT_EXCLUDED);

  const [actionMenuIndex, setActionMenuIndex] = useState<number>();
  const [editingCommentIndex, setEditingCommentIndex] = useState<number>();
  const [isUpdatingFollow, setIsUpdatingFollow] = useState<boolean>(false);
  const [switchChecked, setSwitchChecked] = useState<boolean>(() => {
    return sessionStorage.getItem(HISTORY_FILTER_SESSION_KEY) === HistoryFilter.COMMENTS;
  });

  const location = useLocation();
  const isNewSolutionDetailPage = isNewSolutionsRoute(location.pathname);
  const isToolRequestsPage = isToolRequestsRoute(location.pathname);

  let filteredHistory = React.useMemo(() => getFilteredHistory(history, switchChecked), [history, switchChecked]);

  if (!isProcurementSupportEnabled) {
    filteredHistory = filteredHistory.filter((item) => item.entity !== 'SubscriptionProcurementSupportTicket');
  }

  const handleEditComment = (id: number) => {
    setEditingCommentIndex(id);
  };

  const cancelEditComment = () => {
    setEditingCommentIndex(undefined);
  };

  const handleSwitchChange = () => {
    setSwitchChecked(!switchChecked);
    sessionStorage.setItem(HISTORY_FILTER_SESSION_KEY, !switchChecked ? HistoryFilter.COMMENTS : HistoryFilter.ALL);
  };

  const itemContent = (index: number, historyItem: SubscriptionHistory) => {
    const actionDescription = mapActionDescription(historyItem.actionDescription, subscription);
    const { comment, createdAt, date, id } = historyItem;
    const hasComment = !!comment;
    const itemDate = date || createdAt;
    const dateFormatted = itemDate ? new Date(itemDate).toISOString() : undefined;
    const isEditingComment = Number(id) === editingCommentIndex;

    const itemProps: SubscriptionTimelineItemFeatureProps = {
      actionDescription,
      actionMenuIndex,
      cancelEditComment,
      closeModal,
      dateFormatted,
      documents,
      handleEditComment,
      hasComment,
      historyItem,
      index,
      isEditingComment,
      isNewSolutionDetailPage,
      isToolRequestsPage,
      setActionMenuIndex,
      showModal,
      showNotification,
      spendAndInvoices,
      subscription,
    };

    return <SubscriptionTimelineItemFeature {...itemProps} />;
  };

  const containerRef = useContext(AppContainerContext);
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  useScrollToComment(filteredHistory, virtuosoRef);

  return (
    <div className='sastrify-subscriptiontimeline'>
      <HistoryTimelineHeaderComponent
        handleSwitchChange={handleSwitchChange}
        isNewSolutionDetailPage={isNewSolutionDetailPage}
        isToolRequestsPage={isToolRequestsPage}
        isUpdatingFollow={isUpdatingFollow}
        setIsUpdatingFollow={setIsUpdatingFollow}
        showNotification={showNotification}
        subscription={subscription}
        switchChecked={switchChecked}
      />
      <div className='timeline'>
        <Virtuoso
          ref={virtuosoRef}
          // we can safely ignore the warning, because we know that this data has been initialized.
          // TODO: make sure that context and ref is initialized typesafely
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          customScrollParent={containerRef ? containerRef.current! : undefined}
          data-testid='history-virtual-list'
          className='timeline-virtual-list'
          data={filteredHistory}
          itemContent={itemContent}
        />
      </div>
    </div>
  );
};
