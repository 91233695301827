import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';

import { RadioGroupProps } from './radio-group.component.props';

export const RadioGroup: React.FC<RadioGroupProps> = ({ error, helperText, label, options, sx, ...rest }) => {
  return (
    <FormControl error={error} sx={sx}>
      {label && <FormLabel id='radio-buttons-group-label'>{label}</FormLabel>}
      <MuiRadioGroup key='controls' aria-labelledby='radio-buttons-group-label' {...rest}>
        {options.map(({ size = 'small', ...rest }, index) => (
          <FormControlLabel
            key={`label-${index + 1}`}
            control={<Radio key={`control-${index + 1}`} size={size} />}
            {...rest}
          />
        ))}
      </MuiRadioGroup>
      {helperText && <FormHelperText key='helptext'>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default RadioGroup;
