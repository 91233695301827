import { Box } from '@mui/material';
import processingState from 'assets/images/tool-usage-analytics/sso-tools-list-processing.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SsoTool } from 'shared/models/tool-usage-analytics.model';

import { UsageAnalyticsFallbackContent } from '../components/usage-analytics-fallback-content.component';
import { SsoToolsListItem } from './sso-tools-list-item.component';

interface SsoToolsListProps {
  tools: SsoTool[];
  loading?: boolean;
}

export const SsoToolsList: FC<SsoToolsListProps> = ({ loading, tools }: SsoToolsListProps) => {
  const { t } = useTranslation();
  const isListEmpty = tools.length === 0;
  const items = isListEmpty ? new Array(3).fill(0) : tools;

  if (isListEmpty && !loading) {
    return (
      <UsageAnalyticsFallbackContent
        icon={processingState}
        description={t('connect_view:tab_section.tabs.usage_analytics.manage_sso.processing_state.description')}
      />
    );
  }

  return (
    <Box>
      {items.map((tool) => (
        <SsoToolsListItem
          key={tool.vendorId}
          id={tool.vendorId}
          name={tool.vendorName}
          logoUrl={tool.vendorLogoUrl}
          loading={loading}
        />
      ))}
    </Box>
  );
};
