import { Subscription } from 'shared/models';
import { AppUrl } from 'src/constants/appurl';

import { isAToolRequest } from '../logic/subscription-item.logic';

export const getSubscriptionVendorInsightTabUrl = (subscription: Subscription): string | null => {
  if (!subscription.id || (!subscription.name && !subscription.vendorName) || typeof subscription.state === 'undefined')
    return null;

  const baseToolUrl = AppUrl.getToolUrl(subscription, false, isAToolRequest(subscription.state));

  return `${baseToolUrl}#insight`;
};
