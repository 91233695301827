import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, styled } from '@mui/material';
import { borders } from 'shared/theme';

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  '&': {
    marginBottom: theme.spacing(2),
  },
  '&:before': {
    display: 'none',
  },
  borderRadius: '4px',
  boxShadow: '0 0 4px rgba(31, 30, 78, 0.2)',
}));

export const AccordionSummary = styled(MuiAccordionSummary)(() => ({
  borderBottom: borders.actionMenuBorder,
}));
