import * as yup from 'yup';

export const formValidationSchema = yup.object().shape({
  billingCycle: yup.string().required(),
  commercialTarget: yup.string().required(),
  commitmentLength: yup.string().required(),
  decisionDate: yup.string().required(),
  numberOfLicenses: yup.number().required(),
  vendorInformation: yup.string().required(),
});
