import { Box, Stack } from '@mui/material';
import { FilesListComponent } from 'components/drawer-workflow-request/components/files-list/files-list.component';
import { DetailsItem } from 'components/drawer-workflow-request-details/components/details-item/details-item.component';
import {
  formatText,
  getAmountWithCurrency,
} from 'components/drawer-workflow-request-details/components/details-item/details-item.utils';
import { FileUploadComponent } from 'components/drawer-workflow-request-details/components/file-upload/file-upload.component';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { handleFileDownload } from 'shared/logic/requests.logic';
import { InitiativeUploadDocumentsProps, RenewalNegotiationSupportProps } from 'shared/models/request-workflow.model';

export const RenewalNegotiationSupportDetails: FC<RenewalNegotiationSupportProps & InitiativeUploadDocumentsProps> = ({
  budget,
  changeInPlans,
  commitmentLengthValue,
  companyId,
  contactEmail,
  contactName,
  currency,
  documents,
  id,
  involvementUrgency,
  negotiationInvolvement,
  newVolume,
  preferredCommitmentLength,
  preferredPaymentCycle,
  refetchData,
  renewalDetails,
  renewalGoal,
  showNotification,
  uploadFunction,
}) => {
  const { t } = useTranslation();

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.renewal_involvement_details.renewal_negotiation_support_labels';

  return (
    <>
      <Stack component='div' direction='row' spacing={5} alignItems='flex-start' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.document_upload_label`)}
            content={
              <>
                {documents && documents.length > 0 ? (
                  <FilesListComponent files={documents || []} onDownload={handleFileDownload} />
                ) : (
                  '-'
                )}
                <FileUploadComponent
                  showNotification={showNotification}
                  companyId={companyId}
                  referenceId={Number(id)}
                  uploadFunction={uploadFunction}
                  refetchData={refetchData}
                />
              </>
            }
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem title={t(`${pathToTranslation}.request_goal`)} content={formatText(renewalGoal || '-')} />
        </Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem title={t(`${pathToTranslation}.change_in_plans`)} content={formatText(changeInPlans || '-')} />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            title={t(`${pathToTranslation}.budget`)}
            content={(budget && getAmountWithCurrency(Number(budget) * 100, currency)) || '-'}
          />
        </Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem title={t(`${pathToTranslation}.new_volume`)} content={formatText(newVolume || '-')} />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            title={t(`${pathToTranslation}.preferred_commitment_length`)}
            content={
              commitmentLengthValue && preferredCommitmentLength
                ? `${commitmentLengthValue} ${preferredCommitmentLength}`
                : '-'
            }
          />
        </Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem
            title={t(`${pathToTranslation}.preferred_payment_cycle`)}
            content={formatText(preferredPaymentCycle || '-')}
          />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            title={t(`${pathToTranslation}.negotiation_involvement`)}
            content={formatText(negotiationInvolvement || '-')}
          />
        </Stack>
      </Stack>
      <Stack component='div' direction='row' spacing={5} alignItems='center' mb={2}>
        <Stack flex={1} pr={5}>
          <DetailsItem title={t(`${pathToTranslation}.vendor_contact_name`)} content={formatText(contactName || '-')} />
        </Stack>
        <Stack flex={1}>
          <DetailsItem
            title={t(`${pathToTranslation}.vendor_contact_email`)}
            content={formatText(contactEmail || '-')}
          />
        </Stack>
      </Stack>
      <Box mb={2}>
        <DetailsItem
          title={t(`${pathToTranslation}.request_urgency`)}
          content={formatText(involvementUrgency || '-')}
        />
      </Box>
      <Box mb={1}>
        <DetailsItem title={t(`${pathToTranslation}.other_details`)} content={formatText(renewalDetails || '-')} />
      </Box>
    </>
  );
};
