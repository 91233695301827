import { styled } from '@mui/material';

export const DateRangePickerWrapper = styled('section')(({ theme }) => ({
  '& .to-label': {
    alignItems: 'flex-end',
    color: theme.palette.text.secondary,
    display: 'flex',
    flex: '32px 0 0',
    fontSize: 14,
    justifyContent: 'center',
  },
}));
