import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Icon, InputField } from 'asteroids';
import { InviteUserInitialValue } from 'components/drawer/drawer.component.props';
import { SuccessIcon } from 'components/icons';
import { DialogComponent, TagEditorComponent, VendorLogoComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useCheckUserPermission } from 'hooks/index';
import { PERMISSION } from 'libs/enums/permissions';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  DrawerInfoType,
  googleTagManagerEvents,
  KeyboardKeys,
  ToolOwnershipStep,
  UserRoles,
} from 'shared/common.definitions';
import { decodeIdFromRoute, isNewSolutionsRoute, isToolRequestsRoute } from 'shared/helpers/common.helper';
import { CreateUserData, InviteUserError, InviteUserFormValues } from 'shared/helpers/user-drawer.helper';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import {
  getSubscriptionName,
  updateSubscription as updateSubscriptionApi,
  updateSubscriptionToolOwner,
} from 'shared/logic/subscription-item.logic';
import { createUser } from 'shared/logic/users.logic';
import { SastrifyStore, Subscription, ToolOwner, User } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';
import { AppUrl } from 'src/constants/appurl';

import { AssignToolOwnerFeature } from '../..';
import { SubscriptionStatusFeature } from '../subscription-status';
import { SubscriptionDetailHeaderProps } from './subscription-detail-header.feature.props';

export const SubscriptionDetailHeader = forwardRef((props: SubscriptionDetailHeaderProps, ref) => {
  const {
    closeModal,
    isEditingSubscriptionName,
    isEditingToolOwner,
    openInviteUserDrawer,
    setIsEditingSubscriptionName,
    setIsEditingToolOwner,
    setState,
    showModal,
    showNotification,
    state,
    updateSubscription,
  } = props;

  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const subscriptionId = decodeIdFromRoute(location.pathname);
  const subscription = queryClient.getQueryData(['subscription', subscriptionId]) as Subscription;
  const dispatch = useDispatch();
  const isToolRequests = isToolRequestsRoute(location.pathname);
  const isSubscriptionInDiscovery = isNewSolutionsRoute(location.pathname) || isToolRequests;
  const subscriptionsCacheQueryKey = isSubscriptionInDiscovery ? ['subscriptions-in-discovery', true] : 'subscriptions';

  const { isLoading: isUpdatingSubscription, mutate: updateCurrentSubscription } = useMutation(updateSubscriptionApi);
  const canUpdateRequester = useCheckUserPermission(PERMISSION.UPDATE_REQUESTER);

  const [toolOwnerName, setToolOwnerName] = useState<string>('');
  const [isUpdatingToolOwner, setIsUpdatingToolOwner] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const [requesterName, setRequesterName] = useState<string>('');
  const [isUpdatingRequester, setIsUpdatingRequester] = useState<boolean>(false);
  const [isEditingRequester, setIsEditingRequester] = useState<boolean>(false);

  const subscriptionName = (subscription?.name || subscription?.vendorName) as string;

  const [editedSubscriptionName, setEditedSubscriptionName] = useState(subscriptionName);
  const inviteUserFeatureRef = useRef<{ getFormValues: () => InviteUserFormValues; resetForm: () => void }>();

  const { mutate: createUserMutation } = useMutation(createUser, {
    onError: (error: InviteUserError) => {
      if (error && error.response.status === 400) {
        const email = JSON.parse(String(error.response.config?.data || ''));
        const message = error.response.data?.error;
        const errorMessage = `${message} (${email?.email})`;
        showNotification?.(errorMessage, NotificationAlertType.Warning);
      } else {
        showNotification?.(DEFAULT_UNKNOWN_ERROR_MESSAGE, NotificationAlertType.Error);
      }
    },
    onSettled: () => setIsUpdatingToolOwner(false),
    onSuccess: (result) => {
      const ownerName = result.name;
      const vendorName = subscription.name || subscription.vendorName;

      queryClient.invalidateQueries('tool-owners');
      queryClient.invalidateQueries('subscription-history');
      queryClient.invalidateQueries('auditLogs');
      queryClient.invalidateQueries('subscriptionTodos');
      queryClient.invalidateQueries(['subscription', subscription?.id]);

      const message = t('common:assign_tool_owner_feature.dialog_component.message', {
        ownerName,
        vendorName,
      });

      setDialogMessage(message);
      setOpenDialog(true);
    },
  });

  useEffect(() => {
    if (subscription?.ownerName) setToolOwnerName(subscription?.ownerName);
    if (subscription?.creatorName) setRequesterName(subscription?.creatorName);
  }, [subscription]);

  const onConfirmToolOwnerChange = async (updatedToolOwner?: ToolOwner): Promise<void> => {
    const { toolOwnerProps } = state;
    const vendor = subscription?.name || subscription?.vendorName;
    const isInviteToolOwnerStep = updatedToolOwner?.step === ToolOwnershipStep.Invite;
    const isUnassignToolOwnerStep = updatedToolOwner?.step === ToolOwnershipStep.Unassign;

    if (isInviteToolOwnerStep || isUnassignToolOwnerStep) dispatch(updateFooterState({ isFormSubmitting: true }));

    if (subscription) {
      let { id: userId } = toolOwnerProps.owner;
      const { email, name } = toolOwnerProps.owner;
      const userFormValues = inviteUserFeatureRef.current?.getFormValues() as InviteUserFormValues;
      const ownerName = isInviteToolOwnerStep ? userFormValues.name : name;
      const ownerEmail = isInviteToolOwnerStep ? userFormValues.email : email;
      const ownerTools = userFormValues?.ownerOfSubscriptionIds || [];

      try {
        if (isInviteToolOwnerStep) {
          userId = (await createUser({ email: ownerEmail, name: ownerName, ownerOfSubscriptionIds: ownerTools })).id;
        }

        const updatedSubscription = await updateSubscriptionToolOwner(subscription, userId, toolOwnerProps.owner.name);

        queryClient.setQueryData(['subscription', subscriptionId], updatedSubscription);

        if (toolOwnerProps.owner.id) {
          fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.ToolOwnerAssigned, {
            name: 'toolOwnerName',
            value: toolOwnerName,
          });
        } else {
          fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.ToolOwnerNameAssigned, {
            name: 'toolOwnerName',
            value: ownerName || String(subscription?.ownerName),
          });
        }

        let message = '';

        if (ownerName && userFormValues?.ownerOfSubscriptionIds && userFormValues?.ownerOfSubscriptionIds?.length > 1) {
          message = t('common:assign_tool_owner_feature.assign_tool_owners_success_message', {
            ownerName,
            toolsCount: userFormValues?.ownerOfSubscriptionIds?.length - 1,
            vendorName: vendor,
          });
        } else if (ownerName) {
          message = `${ownerName} successfully assigned as tool owner of ${vendor}.`;
          message = t('common:assign_tool_owner_feature.assign_tool_owner_success_message', {
            ownerName,
            vendorName: vendor,
          });
        } else {
          message = t('common:assign_tool_owner_feature.unassign_tool_owner_success_message', {
            ownerName: subscription?.ownerName,
            vendorName: vendor,
          });
        }

        queryClient.invalidateQueries('tool-owners');
        queryClient.invalidateQueries('subscription-history');
        queryClient.invalidateQueries('auditLogs');
        queryClient.invalidateQueries('subscriptionTodos');
        queryClient.invalidateQueries(subscriptionsCacheQueryKey);

        toolOwnerProps.step = ToolOwnershipStep.Add;
        setState({ ...state, toolOwnerProps });

        inviteUserFeatureRef.current?.resetForm();
        showNotification?.(message, NotificationAlertType.Success);
        closeModal?.();
      } catch (error) {
        if (Object(error)?.response?.data?.error) {
          const responseMessage = Object(error).response.data.error;
          showNotification?.(responseMessage, NotificationAlertType.Warning);
        } else {
          const message = `${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`;
          showNotification?.(message, NotificationAlertType.Error);
        }
      } finally {
        setIsUpdatingToolOwner(false);
        dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    onConfirmToolOwnerChange,
  }));

  const handleOnHideVisibility = () => {
    setIsEditingToolOwner(false);
  };

  const handleRequestOnHideVisibility = () => {
    setIsEditingRequester(false);
  };

  const setAndUpdateToolOwner = (toolOwner: User) => {
    const clonedToolOwnerProps = state.toolOwnerProps;
    clonedToolOwnerProps.subscriptionId = subscription.id;
    clonedToolOwnerProps.vendor = subscription?.vendorName || '';

    if (toolOwner?.id !== '-1') {
      clonedToolOwnerProps.step = ToolOwnershipStep.Add;
      clonedToolOwnerProps.owner = { ...toolOwner };
    } else {
      clonedToolOwnerProps.step = ToolOwnershipStep.Unassign;
      clonedToolOwnerProps.owner = {};
    }

    setIsUpdatingToolOwner(true);

    setState({ ...state, toolOwnerProps: clonedToolOwnerProps });

    onConfirmToolOwnerChange();
  };

  const handleToolOwnerSelection = (selectedOwner: User | string) => {
    const user = selectedOwner as User;
    const isGoogleDirectoryUser = user?.familyName && user?.givenName;

    if (typeof selectedOwner !== 'string' && !isGoogleDirectoryUser) {
      setAndUpdateToolOwner(selectedOwner);
    } else if (isGoogleDirectoryUser) {
      setIsUpdatingToolOwner(true);
      const inviteUserData: CreateUserData = {
        email: String(user.email),
        name: String(user.name),
        ownerOfSubscriptionIds: [subscription.id],
        role: UserRoles.Contributor,
      };
      createUserMutation(inviteUserData);
    } else {
      onInviteUserRequested(selectedOwner as string);
    }
  };

  const onChangeRequester = async (selectedRequester: User) => {
    try {
      const clonedSubscription: Subscription = {
        ...subscription,
        creatorAvatarUrl: selectedRequester.avatarUrl,
        creatorEmail: selectedRequester.email,
        creatorId: selectedRequester.id,
        creatorName: selectedRequester.name,
      };

      const vendor = subscription.name || subscription.vendorName;

      const updatedSubscription = await updateSubscriptionApi(clonedSubscription);

      let message = t('common:assign_tool_owner_feature.assign_as_requester_of', {
        name: selectedRequester.name,
        vendorName: vendor,
      });

      if (
        selectedRequester.name === t('common:assign_tool_owner_feature.typeahead_component.unassign_tool_owner_text')
      ) {
        message = t('common:assign_tool_owner_feature.unassign_requester_success_message', {
          name: subscription.creatorName,
          vendorName: vendor,
        });
      }

      showNotification?.(message, NotificationAlertType.Success);

      queryClient.setQueryData(['subscription', subscriptionId], updatedSubscription);
    } catch (error) {
      const message = `${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`;
      showNotification?.(message, NotificationAlertType.Error);
    } finally {
      setIsUpdatingRequester(false);
      queryClient.invalidateQueries('tool-owners');
      queryClient.invalidateQueries('subscription-history');
      queryClient.invalidateQueries('auditLogs');
      queryClient.invalidateQueries('subscriptionTodos');
      queryClient.invalidateQueries(subscriptionsCacheQueryKey);
    }
  };

  const handleRequesterSelection = (selectedRequester: User) => {
    if (selectedRequester) {
      setIsUpdatingRequester(true);
      onChangeRequester(selectedRequester);
    }
  };

  const onInviteUserRequested = useCallback(
    (selectedOwner?: string): void => {
      const name = String(selectedOwner);

      const inviteUserData: InviteUserInitialValue = {
        name,
        tool: subscription,
      };

      openInviteUserDrawer(DrawerInfoType.USER_INVITE_SUBSCRIPTION, inviteUserData);
    },
    [openInviteUserDrawer, subscription]
  );

  const onSubscriptionNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEditedSubscriptionName(evt.target.value);
  };

  const handleOnBlurEditSubscriptionName = () => {
    if (subscription.name && !isUpdatingSubscriptionName) {
      setEditedSubscriptionName(subscription.name);
    }

    setIsEditingSubscriptionName(false);
  };

  const onSubmitNewSubscriptionName = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== KeyboardKeys.Enter) return;

    const editedName = editedSubscriptionName.trim();

    if (subscription.name === editedName) {
      setIsEditingSubscriptionName(false);
      setEditedSubscriptionName(subscription.name);
      return;
    }

    if (subscription?.name && !editedName) {
      setIsEditingSubscriptionName(false);
      setEditedSubscriptionName(subscription.name);
      return;
    }

    const updatedSubscription = {
      ...subscription,
      name: editedName,
    };

    updateCurrentSubscription(updatedSubscription, {
      onError: (error) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSettled: () => {
        setIsEditingSubscriptionName(false);
      },
      onSuccess: (data) => {
        setEditedSubscriptionName(editedName);

        showNotification?.(
          `${isSubscriptionInDiscovery ? 'Tool request' : 'Subscription'} name updated successfully`,
          NotificationAlertType.Success
        );

        queryClient.setQueryData(['subscription', subscriptionId], data);
        queryClient.invalidateQueries('auditLogs');
        queryClient.invalidateQueries('subscription-history');

        const updatedUrl = AppUrl.getToolUrl(data, isSubscriptionInDiscovery);
        history.replace(updatedUrl);
      },
    });
  };

  const getInputRightIcon = () => {
    if (isUpdatingSubscriptionName) {
      return <CircularProgress size='1rem' />;
    }
    if (editedSubscriptionName) {
      return (
        <IconButton onClick={() => setEditedSubscriptionName('')} onMouseDown={(e) => e.preventDefault()}>
          <Icon color='action'>clear</Icon>
        </IconButton>
      );
    }

    return null;
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isUpdatingSubscriptionName = editedSubscriptionName && isUpdatingSubscription;

  return (
    <section>
      <div className='subscription-header is-flex is-justify-content-space-between'>
        <div className='subscription-title'>
          <div className='subscription-image'>
            <VendorLogoComponent
              height='3.75rem'
              width='3.75rem'
              vendorLogoUrl={subscription?.vendorLogoUrl}
              subscriptionName={getSubscriptionName(subscription as Subscription)?.slice(0, 1)}
            />
          </div>
          <div className='subscription-vendor'>
            <div className='vendor-container'>
              <section className='is-flex is-align-items-baseline'>
                {isEditingSubscriptionName ? (
                  <InputField
                    autoFocus
                    className='vendor-name__input'
                    type='text'
                    value={editedSubscriptionName}
                    disabled={isUpdatingSubscription}
                    onChange={onSubscriptionNameChange}
                    onKeyUp={onSubmitNewSubscriptionName}
                    onBlur={handleOnBlurEditSubscriptionName}
                    fullWidth={false}
                    endAdornment={getInputRightIcon()}
                  />
                ) : (
                  <div data-testid='subscription-name' className='is-flex is-align-items-center vendor-name mr-5'>
                    <p className='mr-2'>{subscription?.name || subscription?.vendorName}</p>
                    <Icon
                      onClick={() => setIsEditingSubscriptionName(true)}
                      className='cursor-pointer'
                      data-testid='subscription-name-edit'
                      color='action'>
                      edit
                    </Icon>{' '}
                  </div>
                )}
                <SubscriptionStatusFeature
                  isSubscriptionDetail
                  showNotification={showNotification}
                  closeModal={closeModal}
                  showModal={showModal}
                  subscription={subscription}
                  isSubscriptionInDiscovery={isSubscriptionInDiscovery}
                />
              </section>
              <div className='vendor-category' data-testid='vendor-category'>
                {subscription?.categoryName}
              </div>

              <div className='subscription-tags' data-testid='subscription-tags'>
                <TagEditorComponent allowNew updateSubscription={updateSubscription} subscription={subscription} />
              </div>
              <div className='owner-wrapper'>
                <Box mr='1.5rem' display='flex'>
                  <AssignToolOwnerFeature
                    subscription={subscription}
                    handleOnHideVisibility={handleOnHideVisibility}
                    handleToolOwnerSelection={handleToolOwnerSelection}
                    toolOwnerName={toolOwnerName}
                    setToolOwnerName={setToolOwnerName}
                    isUpdatingToolOwner={isUpdatingToolOwner}
                    isEditingToolOwner={isEditingToolOwner}
                    setIsEditingToolOwner={setIsEditingToolOwner}
                    isSubscriptionDetailPage
                  />
                </Box>

                {isToolRequests && (
                  <AssignToolOwnerFeature
                    subscription={subscription}
                    handleOnHideVisibility={handleRequestOnHideVisibility}
                    handleToolOwnerSelection={handleRequesterSelection}
                    toolOwnerName={requesterName}
                    setToolOwnerName={setRequesterName}
                    isUpdatingToolOwner={isUpdatingRequester}
                    isEditingToolOwner={isEditingRequester}
                    setIsEditingToolOwner={setIsEditingRequester}
                    isSubscriptionDetailPage
                    isSubscriptionInDiscovery={isSubscriptionInDiscovery}
                    isViewOnly={!canUpdateRequester}
                    isRequester
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogComponent
        open={openDialog}
        handleClose={handleCloseDialog}
        title={t('common:assign_tool_owner_feature.dialog_component.title_text')}
        message={dialogMessage}
        icon={<SuccessIcon />}
      />
    </section>
  );
});
