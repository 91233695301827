import { FC } from 'react';
import { colors } from 'shared/theme';

export const DeleteBinIcon: FC<React.SVGProps<SVGElement>> = ({ fill, onClick }) => (
  <svg onClick={onClick} width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.666748 5.00001C0.666748 4.53977 1.03984 4.16668 1.50008 4.16668H16.5001C16.9603 4.16668 17.3334 4.53977 17.3334 5.00001C17.3334 5.46025 16.9603 5.83334 16.5001 5.83334H1.50008C1.03984 5.83334 0.666748 5.46025 0.666748 5.00001Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.33342 2.50001C7.1124 2.50001 6.90044 2.58781 6.74416 2.74409C6.58788 2.90037 6.50008 3.11233 6.50008 3.33334V4.16668H11.5001V3.33334C11.5001 3.11233 11.4123 2.90037 11.256 2.74409C11.0997 2.58781 10.8878 2.50001 10.6667 2.50001H7.33342ZM13.1667 4.16668V3.33334C13.1667 2.6703 12.9034 2.03442 12.4345 1.56558C11.9657 1.09674 11.3298 0.833344 10.6667 0.833344H7.33342C6.67037 0.833344 6.03449 1.09674 5.56565 1.56558C5.09681 2.03442 4.83342 2.6703 4.83342 3.33334V4.16668H3.16675C2.70651 4.16668 2.33341 4.53977 2.33341 5.00001V16.6667C2.33341 17.3297 2.59681 17.9656 3.06565 18.4344C3.53449 18.9033 4.17037 19.1667 4.83342 19.1667H13.1667C13.8298 19.1667 14.4657 18.9033 14.9345 18.4344C15.4034 17.9656 15.6667 17.3297 15.6667 16.6667V5.00001C15.6667 4.53977 15.2937 4.16668 14.8334 4.16668H13.1667ZM4.00008 5.83334V16.6667C4.00008 16.8877 4.08788 17.0996 4.24416 17.2559C4.40044 17.4122 4.6124 17.5 4.83342 17.5H13.1667C13.3878 17.5 13.5997 17.4122 13.756 17.2559C13.9123 17.0996 14.0001 16.8877 14.0001 16.6667V5.83334H4.00008Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.33342 8.33334C7.79365 8.33334 8.16675 8.70644 8.16675 9.16668V14.1667C8.16675 14.6269 7.79365 15 7.33342 15C6.87318 15 6.50008 14.6269 6.50008 14.1667V9.16668C6.50008 8.70644 6.87318 8.33334 7.33342 8.33334Z'
      fill={fill}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.6667 8.33334C11.127 8.33334 11.5001 8.70644 11.5001 9.16668V14.1667C11.5001 14.6269 11.127 15 10.6667 15C10.2065 15 9.83342 14.6269 9.83342 14.1667V9.16668C9.83342 8.70644 10.2065 8.33334 10.6667 8.33334Z'
      fill={fill}
    />
  </svg>
);

DeleteBinIcon.defaultProps = {
  fill: colors.primary,
};
