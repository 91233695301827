import { FC } from 'react';
import { colors } from 'shared/theme';

export const ListIcon: FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.75 5.75H6c-.45 0-.75-.3-.75-.75s.3-.75.75-.75h9.75c.45 0 .75.3.75.75s-.3.75-.75.75zM15.75 10.25H6c-.45 0-.75-.3-.75-.75s.3-.75.75-.75h9.75c.45 0 .75.3.75.75s-.3.75-.75.75zM15.75 14.75H6c-.45 0-.75-.3-.75-.75s.3-.75.75-.75h9.75c.45 0 .75.3.75.75s-.3.75-.75.75zM2.25 5.75H2.1c-.075 0-.075 0-.15-.075-.075 0-.075-.075-.15-.075s-.075-.075-.075-.075c-.075-.075-.15-.15-.15-.225C1.5 5.225 1.5 5.075 1.5 5c0-.075 0-.225.075-.3.075-.075.075-.15.15-.225.225-.225.525-.3.825-.15.075.075.15.075.225.15.15.15.225.3.225.525a.68.68 0 01-.225.525.68.68 0 01-.525.225zM2.25 10.25a.68.68 0 01-.525-.225A.68.68 0 011.5 9.5v-.15c0-.075 0-.075.075-.15 0-.075.075-.075.075-.15s.075-.075.075-.075c.075-.075.15-.15.225-.15.3-.15.6-.075.825.15l.075.075c0 .075.075.075.075.15s0 .075.075.15v.15a.68.68 0 01-.225.525.68.68 0 01-.525.225zM2.25 14.75a.68.68 0 01-.525-.225A.68.68 0 011.5 14c0-.075 0-.225.075-.3.075-.075.075-.15.15-.225.3-.3.75-.3 1.05 0 .15.15.225.3.225.525a.68.68 0 01-.225.525.68.68 0 01-.525.225z'
      fill={fill}
    />
  </svg>
);

ListIcon.defaultProps = {
  fill: colors.osloGray,
};
