import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { FC, lazy } from 'react';
import { Route } from 'react-router';
import { featureFlags, UserRoles } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { RoutedProps } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { NAVIGATION_URLS } from 'src/constants/navigation';
import { ErrorView } from 'views/error';

const BenchmarkView = lazy(() => import('../views/benchmarks/benchmarks.view'));
const NewBenchmarkView = lazy(() => import('../views/benchmarks/new-price-benchmark.view'));
const NewPriceBenchmarkResult = lazy(() => import('../views/benchmarks/new-price-benchmark-result.view'));

export const BenchmarkRoutes: FC<RoutedProps> = (props) => {
  const userRole = useAppSelector((state) => state.authentication.user?.role);
  const isViewerRole = userRole === UserRoles.Viewer;

  // Feature flags
  const isInAppBenchmarkingEnabled = useCompanyFeatureFlag(featureFlags.IN_APP_BENCHMARKING);

  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();
  const isVendorInsightsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);
  const isVendorInsightPurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);

  return (
    <>
      <Route exact path={NAVIGATION_URLS.PRICING_BENCHMARKS}>
        {!isViewerRole && (isVendorInsightsEnabled || isVendorInsightPurchasable) && isInAppBenchmarkingEnabled ? (
          <BenchmarkView {...props} />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
      <Route exact path={NAVIGATION_URLS.NEW_PRICING_BENCHMARK}>
        {!isViewerRole && isVendorInsightsEnabled && isInAppBenchmarkingEnabled ? (
          <NewBenchmarkView />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
      <Route exact path={NAVIGATION_URLS.NEW_PRICING_BENCHMARK_RESULTS}>
        {!isViewerRole && isVendorInsightsEnabled && isInAppBenchmarkingEnabled ? (
          <NewPriceBenchmarkResult {...props} />
        ) : (
          <ErrorView code={404} />
        )}
      </Route>
    </>
  );
};
