import { FC } from 'react';
import { Tabs } from 'src/asteroids/components/tabs/tabs.component';

import { PageTabsProps } from './tabs.props';

export const PageTabs: FC<PageTabsProps> = ({ items, onChange, value }) => {
  return (
    <Tabs
      data-testid='page-tabs'
      items={items}
      value={value}
      onChange={onChange}
      aria-label='page tabs'
      sx={{ marginBottom: -3, marginTop: 3 }}
    />
  );
};
