import './request-tool-alert.scss';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export enum RequestToolAlertType {
  used = 'used',
  rejected = 'rejected',
  requested = 'requested',
}

export type RequestToolAlertProps = {
  type: RequestToolAlertType;
  toolName: string;
  onViewRequest: () => void;
  isSubscription?: boolean;
};

export const RequestToolAlert: FC<RequestToolAlertProps> = ({ isSubscription, onViewRequest, toolName, type }) => {
  const { t } = useTranslation();

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_tool_request_section.alert_section';

  const getContent = () => {
    if (type === RequestToolAlertType.rejected) {
      return {
        body: t(`${pathToTranslation}.rejected_body_text`),
        title: t(`${pathToTranslation}.rejected_title`, {
          toolName,
        }),
      };
    }
    if (type === RequestToolAlertType.requested) {
      return {
        body: t(`${pathToTranslation}.requested_body_text`),
        title: t(`${pathToTranslation}.requested_title`, {
          toolName,
        }),
      };
    }

    return {
      body: t(`${pathToTranslation}.already_in_use_tool_body_text`, { toolName }),
      title: t(`${pathToTranslation}.already_in_use_tool_title`, {
        toolName,
      }),
    };
  };
  return (
    <Alert
      className='request-tool-alert'
      severity='warning'
      action={
        <Button data-testid='view-request-btn' color='inherit' size='small' onClick={onViewRequest}>
          <Box>
            {isSubscription ? t(`${pathToTranslation}.view_text`) : t(`${pathToTranslation}.view_request_text`)}
          </Box>
        </Button>
      }
      sx={{ mb: 2, mx: '20px', my: '14px' }}>
      <AlertTitle>{getContent().title}</AlertTitle>
      {getContent().body}
    </Alert>
  );
};
