import { de, deAT, enAU, enCA, enGB, enIN, enNZ, enUS, eu, fr, frCA, frCH, uk } from 'date-fns/locale';

export const LocaleMap = {
  de,
  deAT,
  enAU,
  enCA,
  enGB,
  enIN,
  enNZ,
  enUS,
  eu,
  fr,
  frCA,
  frCH,
  uk,
};

export const MaskMap = {
  de: '__.__.____',
  deAT: '__/__/____',
  enAU: '__/__/____',
  enCA: '__/__/____',
  enGB: '__/__/____',
  enIN: '__/__/____',
  enNZ: '__/__/____',
  enUS: '__/__/____',
  eu: '__/__/____',
  fr: '__/__/____',
  frCA: '__/__/____',
  frCH: '__/__/____',
  uk: '__/__/____',
};
