import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { Icon } from 'asteroids';
import { ContactSastrifyFormComponent } from 'components/drawer-workflow-request-details/components/contact-sastrify-form/contact-sastrify-form.component';
import { useAsteroidsDialog } from 'context/asteroids-dialog.context';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { RequestDetails } from 'shared/models';
import { useAddRequestDrawer } from 'views/requests/hooks';

import { DialogComponent } from '../dialog/dialog.component';
import { DialogComponentProps } from '../dialog/dialog.component.props';
import { AddNewToolFormComponent } from './add-new-tool-form/add-new-tool-form.component';
import { InitiativeToolComponent } from './initiative-tool/initiative-tool.component';
import { InitiativeToolsProps } from './initiative-tools.component.props';

const ADD_ICON = 'add';

export const InitiativeToolsComponent: React.FC<InitiativeToolsProps> = ({
  closeDrawer,
  companyId,
  deleteDocument,
  deleteTool,
  disabledAddTool = false,
  disabledAddToolTooltipText = '',
  disabledDeleteTools = [],
  disabledDeleteToolsTooltipText = '',
  expanded,
  expandedInvolvements,
  involvements,
  mainToolId,
  parentRequestId,
  refetchData,
  refetchInvolvements,
  setExpanded,
  setExpandedInvolvements,
  showNotification,
  tools,
  uploadDocuments,
  uploadInvolvementDocuments,
}) => {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState<DialogComponentProps>({ open: false });
  const { closeDialog: closeAsteroidsDialog, openDialog: openAsteroidsDialog } = useAsteroidsDialog();
  const { onAddRequestDrawer } = useAddRequestDrawer();

  const handleCloseAsteroidsDialog = (tool?: RequestDetails) => {
    closeAsteroidsDialog?.();
    if (tool?.requestId) {
      onAddRequestDrawer(`?toolId=${tool.requestId}#${DRAWER_VARIANT.REQUEST_DETAILS}${parentRequestId}`);
    } else {
      onAddRequestDrawer(`#${DRAWER_VARIANT.REQUEST_DETAILS}${parentRequestId}`);
    }
  };

  const openDocumentDeleteConfirmationDialog = (documentId: number, fileName: string | undefined) => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.confirm'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.cancel'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.document_delete_confirmation_dialog.message'
      ),
      onAction: () => {
        deleteDocument(documentId);
        setDialog({ open: false });
      },
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.document_delete_confirmation_dialog.title',
        { fileName }
      ),
    });
  };

  const openToolDeleteConfirmationDialog = (toolId: number, toolName: string) => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.delete'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.cancel'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.tool_delete_confirmation_dialog.message'
      ),
      onAction: () => {
        deleteTool(toolId);
        setDialog({ open: false });
      },
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.tool_delete_confirmation_dialog.title',
        { toolName }
      ),
    });
  };

  const openAddNewToolDialog = () => {
    closeDrawer();
    openAsteroidsDialog?.({
      bodyContent: (
        <AddNewToolFormComponent
          closeDialogAndOpenDrawer={handleCloseAsteroidsDialog}
          showNotification={showNotification}
          refetchData={refetchData}
          parentRequestId={parentRequestId}
        />
      ),
      onClose: handleCloseAsteroidsDialog,
      width: 600,
    });
  };

  const openEditToolDialog = (tool: RequestDetails) => {
    closeDrawer();
    openAsteroidsDialog?.({
      bodyContent: (
        <AddNewToolFormComponent
          closeDialogAndOpenDrawer={handleCloseAsteroidsDialog}
          closeDialogAndOpenToolAccordion={() => handleCloseAsteroidsDialog(tool)}
          tool={tool}
          showNotification={showNotification}
          refetchData={refetchData}
        />
      ),
      onClose: handleCloseAsteroidsDialog,
      width: 600,
    });
  };

  const openCreateInvolvementDialog = (tool: RequestDetails, excludedTypes: string[]) => {
    closeDrawer();
    openAsteroidsDialog?.({
      bodyContent: (
        <ContactSastrifyFormComponent
          closeDialogAndOpenDrawer={handleCloseAsteroidsDialog}
          showNotification={showNotification}
          requestId={Number(tool.requestId)}
          closeDialog={closeAsteroidsDialog}
          vendorName={tool.vendorName}
          workflowTypeId={Number(tool.requestWorkflowTypeId)}
          subscriptionId={Number(tool.subscriptionId)}
          parentRequestId={parentRequestId}
          excludedTypes={excludedTypes}
        />
      ),
      onClose: handleCloseAsteroidsDialog,
      width: 1050,
    });
  };

  return (
    <>
      <Stack direction='column' spacing={2}>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography variant='h2' data-testid='initiative-tools-title'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.title'
            )}
          </Typography>
          <Tooltip title={disabledAddTool ? disabledAddToolTooltipText : ''} placement='bottom-start'>
            <Box>
              <Button
                data-testid='add-tool-button'
                color={disabledAddTool ? 'primary' : 'secondary'}
                size='normal'
                variant='text'
                startIcon={<Icon>{ADD_ICON}</Icon>}
                onClick={openAddNewToolDialog}
                disabled={disabledAddTool}>
                {t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.add_tool'
                )}
              </Button>
            </Box>
          </Tooltip>
        </Box>
        {tools.map((tool) => (
          <InitiativeToolComponent
            key={tool.requestId}
            expanded={expanded}
            setExpanded={setExpanded}
            tool={tool}
            deleteDocument={openDocumentDeleteConfirmationDialog}
            deleteTool={openToolDeleteConfirmationDialog}
            editTool={openEditToolDialog}
            referenceId={Number(tool.requestId)}
            showNotification={showNotification}
            refetchData={refetchData}
            uploadDocuments={uploadDocuments}
            companyId={companyId}
            canDelete={String(tool.requestId) !== mainToolId}
            disableDelete={disabledDeleteTools.includes(String(tool.requestId))}
            disableDeleteTooltipText={disabledDeleteToolsTooltipText}
            openCreateInvolvementDialog={openCreateInvolvementDialog}
            expandedInvolvements={expandedInvolvements}
            setExpandedInvolvements={setExpandedInvolvements}
            involvements={involvements}
            uploadInvolvementDocuments={uploadInvolvementDocuments}
            refetchInvolvements={refetchInvolvements}
          />
        ))}
      </Stack>
      {/* Asteroids dialog doesn't accept disableEnforceFocus prop and therefore has an issue when opened over a Drawer. Until component is updated use this local instead. */}
      <DialogComponent {...dialog} />
    </>
  );
};
