import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { FilesListComponent } from 'components/drawer-workflow-request/components/files-list/files-list.component';
import { FileUploadComponent } from 'components/drawer-workflow-request-details/components/file-upload/file-upload.component';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { format } from 'date-fns';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { DRAWER_VARIANT, REQUEST_STATUS } from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { deleteRequestDocument, downloadDocument, uploadRequestDocument } from 'shared/logic/requests.logic';
import { Company } from 'shared/models';

import { TimelineComponent } from '../../views/requests/components/timeline/timeline.component';
import { StateType, TimelineItem } from '../../views/requests/components/timeline/timeline.component.props';
import { useWorkflowActivitiesData, useWorkflowRequestDetailsData } from '../../views/requests/hooks';
import { DialogComponent } from '../drawer-workflow-request-details/components/dialog/dialog.component';
import { DialogComponentProps } from '../drawer-workflow-request-details/components/dialog/dialog.component.props';

type MultiToolInitiativesDetailsSectionProps = {
  handleCloseDrawer: () => void;
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
};

const formatDate = (date = '') => format(new Date(date), 'MMMM dd, yyyy');

export const MultiToolInitiativesDetailsSection: FC<MultiToolInitiativesDetailsSectionProps> = ({
  handleCloseDrawer,
  showNotification,
}) => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const queryClient = useQueryClient();

  const requestId = hash.split(DRAWER_VARIANT.REQUEST_DETAILS).slice(-1)[0];

  const {
    data: detailsData,
    isLoading: isDetailsLoading,
    refetch: refetchDetails,
  } = useWorkflowRequestDetailsData(requestId);
  const { refetch: refetchActivities } = useWorkflowActivitiesData(requestId);
  const company = queryClient.getQueryData<Company>('company');

  const hasDocuments = detailsData?.documents && detailsData?.documents?.length > 0;

  const [dialog, setDialog] = useState<DialogComponentProps>({ open: false });
  const [isToolActivated, setIsToolActivated] = useState(false);
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);

  const { mutate: deleteDocument } = useMutation(deleteRequestDocument, {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'Delete request document error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSettled: () => {
      setDialog({ open: false });
    },
    onSuccess: () => {
      refetchDetails();
      refetchActivities();
      showNotification?.(
        t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.successfully_deleted_message'
        ),
        NotificationAlertType.Success
      );
    },
  });

  useEffect(() => {
    if (detailsData && detailsData?.requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED) {
      setIsToolActivated(true);
    }
  }, [detailsData]);

  const getMainTitle = () => {
    if (detailsData?.name) {
      return (
        <Typography variant='h2' mb={2} data-testid='initiative-name'>
          {detailsData?.name}
        </Typography>
      );
    }

    return (
      <Typography variant='h2' mb={2}>
        {detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL
          ? `${t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.multi_tool_renewal_text'
            )} - ${detailsData?.vendorName}`
          : `${t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.multi_tool_purchase_text'
            )} - ${detailsData?.vendorName}`}
      </Typography>
    );
  };

  const getTimelineData = (): TimelineItem[] => {
    const states: { [key: string]: StateType } = {
      approved: 'success',
      pending: 'pending',
      rejected: 'error',
    };

    return [
      ...(detailsData?.timeline?.map(({ stepId, stepName, stepStatus, updatedAt }, index) => ({
        id: index,
        state: states[stepStatus],
        ...(updatedAt && { subtitle: formatDate(updatedAt) }),
        active: Number(stepId) === detailsData.currentStepId,
        title: stepName,
      })) || []),
    ];
  };

  const openDocumentDeleteConfirmationDialog = (documentId: number) => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.confirm_button_label'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.cancel_button_label'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.message'
      ),
      onAction: () => {
        setDialog((oldState) => ({ ...oldState, disabledActionBtn: true }));
        deleteDocument(documentId);
      },
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.title'
      ),
    });
  };

  const handleOnDownload = async (fileName: string, fileUrl: string, fileId?: string) => {
    setIsDownloadingDocument(true);
    await downloadDocument(fileName, fileUrl, fileId);
    setIsDownloadingDocument(false);
  };

  const renderButton = () => (
    <Box px='3rem' pb='2rem'>
      <Button
        variant='text'
        color='primary'
        startIcon={<WestRoundedIcon />}
        onClick={handleCloseDrawer}
        data-testid='back-button'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_text'
        )}
      </Button>
    </Box>
  );

  const renderTimelineInfo = () => (
    <Stack direction='column' spacing={1}>
      <Typography data-testid='timeline-heading' variant='h2' mb={0}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.request_details_timeline_title'
        )}
      </Typography>
      <Box px='0.5rem'>
        <TimelineComponent items={getTimelineData()} size='small' />
      </Box>
      <Divider />
      <Typography color='textSecondary' variant='small'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.multi_tool_initiative_details_timeline_caption'
        )}
      </Typography>
    </Stack>
  );

  const renderFileUpload = () => (
    <FileUploadComponent
      showNotification={showNotification}
      companyId={company?.id}
      referenceId={Number(requestId || 0)}
      uploadFunction={uploadRequestDocument}
      refetchData={async () => {
        await refetchDetails();
        await refetchActivities();
      }}
    />
  );

  const renderAttachmentsSection = () => (
    <Box mt={5}>
      <Box>
        <Typography data-testid='attachments-heading' variant='h2' mb={2}>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.multi_tool_attachments_label'
          )}
        </Typography>
        <Typography component='div' variant='small' mb={2} color='text.secondary' data-testid='attachments-subheading'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.multi_tool_helper_text'
          )}
        </Typography>
        {hasDocuments && (
          <FilesListComponent
            files={detailsData?.documents || []}
            onRemove={openDocumentDeleteConfirmationDialog}
            onDownload={handleOnDownload}
            textTruncate
            disableDownloadButton={isDownloadingDocument}
          />
        )}
      </Box>
      {!isToolActivated && renderFileUpload()}
    </Box>
  );

  return (
    <>
      {!isDetailsLoading && (
        <Box sx={{ maxHeight: '100vh', overflowY: 'auto', padding: '4rem 0' }}>
          {renderButton()}
          <Box px='2.5rem' data-testid='initiative-drawer-left-section'>
            {getMainTitle()}
            <Typography variant='body' mb={4}>
              {detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL
                ? t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.multi_tool_renewal_initiative_label'
                  )
                : t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.multi_tool_purchase_initiative_label'
                  )}
            </Typography>
            {renderTimelineInfo()}
            {renderAttachmentsSection()}
          </Box>
        </Box>
      )}
      {/*
        Asteroids dialog doesn't accept disableEnforceFocus prop and therefore has an issue when opened over a Drawer.
        Until component is updated use this local instead.
      */}
      <DialogComponent {...dialog} />
    </>
  );
};
