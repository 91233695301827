import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface WorkflowsList {
  isUpdating: boolean;
}

export const initialState: WorkflowsList = {
  isUpdating: false,
};

const workflowsListSlice = createSlice({
  initialState,
  name: 'workflowsList',
  reducers: {
    setIsUpdating(state, action: PayloadAction<boolean>) {
      state.isUpdating = action.payload;
    },
  },
});

export const { setIsUpdating } = workflowsListSlice.actions;
export default workflowsListSlice.reducer;
