import { Box, styled } from '@mui/material';
import { Alert } from 'asteroids';

export const Iframe = styled('iframe')({
  border: '0',
  height: 'fit-content',
  width: '100%',
});

export const Wrapper = styled(Box)(({ theme }) => ({
  '& .MuiLink-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiTypography-label': {
    display: 'block',
  },
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  padding: theme.spacing(8, 0, 0, 0),
}));

export const Section = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(5),
}));

export const HeaderSection = styled(Section)({
  display: 'flex',
  flex: '0 1 auto',
  flexDirection: 'column',
});

export const ContentSection = styled(Section)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: theme.spacing(1),
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.secondary.main,
  },
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
  marginRight: 0,
  overflow: 'auto',
  paddingRight: theme.spacing(5),
}));

export const FooterSection = styled(Section)({
  display: 'flex',
  flex: '0 1 52px',
  height: '100%',
});

export const SvgContainer = styled('img')({
  height: '74px',
  marginRight: '24px',
  width: '120px',
});

export const ErrorAlert = styled(Alert)({
  '& .MuiAlert-action': {
    textAlign: 'end',
  },
});

export const ToolsListSection = styled(Section)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: theme.spacing(1),
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.secondary.main,
  },
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%',
  margin: 0,
  overflow: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(5)} 0 ${theme.spacing(5)}`,
}));
