import { trackEventName, UserIdentityTrait } from 'shared/common.definitions';

import { EventTracking } from '../services';

type TaskTraits = {
  subscriptionName: string;
};

export function sendIdentifyEvent(userId: string, userProfile: UserIdentityTrait) {
  EventTracking.identify(userId, userProfile);
}

export function sendLoggedInEvent(): void {
  EventTracking.trackEvent(trackEventName.Login);
}

export function sendTaskCreatedEvent(traits: TaskTraits): void {
  EventTracking.trackEvent(trackEventName.TaskCreated, traits);
}

export function sendTaskEditedEvent(traits: TaskTraits): void {
  EventTracking.trackEvent(trackEventName.TaskEdited, traits);
}

export function sendTaskCompletedEvent(traits: TaskTraits): void {
  EventTracking.trackEvent(trackEventName.TaskCompleted, traits);
}

export function sendTaskDeletedEvent(traits: TaskTraits): void {
  EventTracking.trackEvent(trackEventName.TaskDeleted, traits);
}
