import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Avatar, Icon } from 'asteroids';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InviteTeamIcon } from '../icons';
import { EmailBridgeModalComponentProps } from '.';

type EmailState = {
  companyEmail: string;
  subscriptionInvoicesEmail: string;
  subscriptionsEmail: string;
};

export const EmailBridgeModalComponent: FC<EmailBridgeModalComponentProps> = (props) => {
  const { t } = useTranslation();
  const { closeModal, companyEmail, subscriptionInvoiceEmail, subscriptionName, subscriptionsEmail, vendorLogoUrl } =
    props;

  const [tooltipTitle, setTooltipTitle] = useState<EmailState>({
    companyEmail: t('common:modals.email_bridge_modal.left_section.copy_email_address_text'),
    subscriptionInvoicesEmail: t('common:modals.email_bridge_modal.left_section.copy_email_address_text'),
    subscriptionsEmail: t('common:modals.email_bridge_modal.left_section.copy_email_address_text'),
  });

  const handleCopyEmail = (type: string, email?: string) => {
    setTooltipTitle((prevState) => ({
      ...prevState,
      [type]: t('common:modals.email_bridge_modal.left_section.email_address_copied'),
    }));
    navigator.clipboard.writeText(String(email));
  };

  return (
    <Box className='email-bridge-modal' display='flex' flexDirection='row' data-testid='email-bridge-modal'>
      <IconButton onClick={closeModal} className='close-icon'>
        <Icon>close</Icon>
      </IconButton>

      <Box className='left-section'>
        <Box>
          <Typography className='title'>{t('common:modals.email_bridge_modal.left_section.title_text')}</Typography>
          <Typography className='title-description'>
            {t('common:modals.email_bridge_modal.left_section.title_description')}
          </Typography>
        </Box>

        <Box mt={4}>
          <Box display='flex' flexDirection='row' alignItems='center' className='subscription-and-logo'>
            <Avatar
              alt={t('common:modals.email_bridge_modal.left_section.vendor_logo_alt_text')}
              src={vendorLogoUrl}
              variant='rounded'
              size='large'
              fitImage>
              {subscriptionName?.[0]}
            </Avatar>
            <Typography className='subscription-name'>{subscriptionName}</Typography>
          </Box>
        </Box>

        <Box mt={3}>
          <Box>
            <Box>
              <Typography data-testid='spend-title' className='sub-title' mb={1}>
                {' '}
                {t('common:modals.email_bridge_modal.left_section.spend_text')} {props.subscriptionName}
              </Typography>
              <Typography className='documents-library-description'>
                <Trans
                  i18nKey='common:modals.email_bridge_modal.left_section.spend_description_line1'
                  values={{
                    emphasized: t('common:modals.email_bridge_modal.left_section.spend_description_emphasized'),
                  }}
                  components={[<b />]}
                />
              </Typography>
              <Typography className='documents-library-description' component='p'>
                {t('common:modals.email_bridge_modal.left_section.spend_description_line2')}
              </Typography>
              <Box mt={1} display='flex' alignItems='center' className='invoice-email-wrapper'>
                <Tooltip title={tooltipTitle.subscriptionInvoicesEmail} arrow placement='top' data-testid='tooltip'>
                  <IconButton onClick={() => handleCopyEmail('subscriptionInvoicesEmail', subscriptionInvoiceEmail)}>
                    <Icon color='tertiary'>content_copy</Icon>
                  </IconButton>
                </Tooltip>
                <Typography className='invoice-email'>{subscriptionInvoiceEmail}</Typography>
              </Box>
            </Box>

            <Box mt={3}>
              <Typography data-testid='documentLibrary-title' className='sub-title' mb={1}>
                {t('common:modals.email_bridge_modal.left_section.document_library_text')}
              </Typography>
              <Typography className='documents-library-description'>
                {t('common:modals.email_bridge_modal.left_section.document_library_description')}
              </Typography>
              <Box mt={1} display='flex' alignItems='center' className='invoice-email-wrapper'>
                <Tooltip title={tooltipTitle.subscriptionsEmail} arrow placement='top' data-testid='tooltip'>
                  <IconButton onClick={() => handleCopyEmail('subscriptionsEmail', subscriptionsEmail)}>
                    <Icon color='tertiary'>content_copy</Icon>
                  </IconButton>
                </Tooltip>
                <Typography className='invoice-email'>{subscriptionsEmail}</Typography>
              </Box>
            </Box>

            <Box mt={3}>
              <Typography data-testid='subscriptions-title' className='subscriptions-title' mb={1}>
                {t('common:modals.email_bridge_modal.left_section.subscription_title_text')}
              </Typography>
              <Typography className='forward-all-documents-description'>
                {t('common:modals.email_bridge_modal.left_section.forward_all_documents_text')}
              </Typography>
              <Box mt={1} display='flex' alignItems='center' className='invoice-email-wrapper'>
                <Tooltip title={tooltipTitle.companyEmail} arrow placement='top' data-testid='tooltip'>
                  <IconButton onClick={() => handleCopyEmail('companyEmail', companyEmail)}>
                    <Icon color='tertiary'>content_copy</Icon>
                  </IconButton>
                </Tooltip>
                <Typography className='invoice-email'>{companyEmail}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='right-section' display='flex' flexDirection='column'>
        <Box mt={1}>
          <InviteTeamIcon />
        </Box>

        <Box>
          <Typography className='how-email-works'>
            {t('common:modals.email_bridge_modal.right_section.how_email_works_text')}
          </Typography>

          <Box className='body-text'>
            <Typography>
              {t('common:modals.email_bridge_modal.right_section.email_integration_description_one')}
            </Typography>
            <Typography>
              {t('common:modals.email_bridge_modal.right_section.email_integration_description_two')}
            </Typography>
            <Typography>{t('common:modals.email_bridge_modal.right_section.how_text')}</Typography>

            <Box display='flex' flexDirection='column' className='email-links'>
              <a href='https://support.google.com/mail/answer/10957?hl=en' target='_blank' rel='noreferrer'>
                {t('common:modals.email_bridge_modal.right_section.gmail_accounts_text')}
              </a>
              <a
                href='https://support.microsoft.com/en-us/office/use-rules-to-automatically-forward-messages-45aa9664-4911-4f96-9663-ece42816d746'
                target='_blank'
                rel='noreferrer'>
                {t('common:modals.email_bridge_modal.right_section.outlook_accounts_text')}
              </a>
            </Box>
          </Box>
          <Box mt={4}>
            <Typography className='note-text'>
              {t('common:modals.email_bridge_modal.right_section.note_text')}
            </Typography>
          </Box>
        </Box>

        <button type='submit' className='button is-primary mt-4 got-it-button' onClick={closeModal}>
          {t('common:modals.email_bridge_modal.right_section.got_it_button_text')}
        </button>
      </Box>
    </Box>
  );
};
