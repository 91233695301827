import { Box, styled } from '@mui/material';
import { colors } from 'shared/theme';

export const DrawerRequestHelpWrapper = styled(Box)(({ theme }) => ({
  '& .boxSection': {
    marginTop: theme.spacing(4),
  },
  '& .mandatory': {
    color: colors.greyText,
    fontStyle: 'italic',
    fontWeight: '400',
    fontsize: '0.875rem',
    marginLeft: '0.3rem',
  },
  '& .requestSection': {
    '& .chips-container': {
      '& .MuiChip-root': {
        backgroundColor: colors.chipBackgroundColor,
        border: `1px solid ${colors.chipBackgroundColor}`,
        color: colors.rollingStone,
        cursor: 'pointer',
        fontSize: '0.75rem',
      },
      '& .is-active': {
        backgroundColor: colors.lightPrimaryBackground,
        color: colors.primary,
      },
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.8rem',
      marginTop: theme.spacing(2),
    },
  },
  '& .selectSubscriptionSection': {
    marginBottom: theme.spacing(4),
  },
  width: '100%',
}));
