import { AvatarGroup, Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AvatarGroupStyled = styled(AvatarGroup)(({ theme }) => ({
  '& .MuiAvatar-root': {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

export const IntakeFormDetailsWrapper = styled(Stack)(() => ({
  alignItems: 'flex-start',
}));

export const SeeMoreButtonWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(2),
}));
