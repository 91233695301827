import { CheckBox as CheckboxIcon, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  createTheme,
  FormControlLabel,
  ThemeProvider,
} from '@mui/material';
import { colors, mainColors } from 'asteroids';
import { FC } from 'react';

import { CheckboxProps } from './checkbox.props';

const unCheckedIcon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckboxIcon fontSize='small' />;

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.main,
    },
  },
});

const CheckboxComponent: FC<CheckboxProps & MUICheckboxProps> = ({ checked, classes, label, onChange, ...rest }) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        sx={{ '.MuiCheckbox-root': { color: mainColors.text, svg: { fontSize: '1.5rem' } } }}
        label={label}
        data-testid='checkbox-component'
        control={
          <MUICheckbox
            {...rest}
            color='primary'
            icon={unCheckedIcon}
            checkedIcon={checkedIcon}
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
      />
    </ThemeProvider>
  );
};

export const Checkbox = CheckboxComponent;
