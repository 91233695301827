export enum CURRENCY {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTC = 'BTC',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  CNH = 'CNH',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GGP = 'GGP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  IMP = 'IMP',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLE = 'SLE',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  STD = 'STD',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export const DEFAULT_CURRENCY = CURRENCY.EUR;

export const DEFAULT_CONVERSION_RATE = {
  AED: {
    rates: { AED: 1, ARS: 43.48, AUD: 0.38, CHF: 0.25, EUR: 0.24, GBP: 0.2, MXN: 5.3, SEK: 2.77, SGD: 0.37, USD: 0.27 },
  },
  ARS: {
    rates: {
      AED: 0.023,
      ARS: 1,
      AUD: 0.0097,
      CHF: 0.0062,
      EUR: 0.0063,
      GBP: 0.0055,
      MXN: 0.12,
      SEK: 0.068,
      SGD: 0.0088,
      USD: 0.0063,
    },
  },
  AUD: {
    rates: {
      AED: 2.62,
      ARS: 103.44,
      AUD: 1,
      CHF: 0.65,
      EUR: 0.63,
      GBP: 0.53,
      MXN: 12.6,
      SEK: 7.05,
      SGD: 0.96,
      USD: 0.71,
    },
  },
  CHF: {
    rates: {
      AED: 4.03,
      ARS: 161.21,
      AUD: 1.54,
      CHF: 1,
      EUR: 0.93,
      GBP: 0.7874,
      MXN: 19.66,
      SEK: 10.67,
      SGD: 1.48,
      USD: 1.08,
    },
  },
  EUR: {
    rates: {
      AED: 4.15,
      ARS: 159.74,
      AUD: 1.59,
      CHF: 1.07527,
      EUR: 1,
      GBP: 0.8547,
      MXN: 19.47,
      SEK: 10.4,
      SGD: 1.52,
      USD: 1.23,
    },
  },
  GBP: {
    rates: {
      AED: 4.96,
      ARS: 182.83,
      AUD: 1.89,
      CHF: 1.27,
      EUR: 1.17,
      GBP: 1,
      MXN: 22.29,
      SEK: 12.42,
      SGD: 1.82,
      USD: 1.36,
    },
  },
  MXN: {
    rates: {
      AED: 0.19,
      ARS: 8.2,
      AUD: 0.079,
      CHF: 0.051,
      EUR: 0.051,
      GBP: 0.045,
      MXN: 1,
      SEK: 0.56,
      SGD: 0.072,
      USD: 0.051,
    },
  },
  SEK: {
    rates: {
      AED: 0.36,
      ARS: 14.74,
      AUD: 0.14,
      CHF: 0.094,
      EUR: 0.096,
      GBP: 0.081,
      MXN: 1.8,
      SEK: 1,
      SGD: 0.14,
      USD: 0.098,
    },
  },
  SGD: {
    rates: {
      AED: 2.73,
      ARS: 113.83,
      AUD: 1.04,
      CHF: 0.68,
      EUR: 0.66,
      GBP: 0.55,
      MXN: 13.88,
      SEK: 7.37,
      SGD: 1,
      USD: 0.74,
    },
  },
  USD: {
    rates: {
      AED: 3.67,
      ARS: 159.68,
      AUD: 1.4,
      CHF: 0.92593,
      EUR: 0.81162,
      GBP: 0.73529,
      MXN: 19.47,
      SEK: 10.16,
      SGD: 1.35,
      USD: 1,
    },
  },
};
