import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export const trackHelpCentreItemClicked = (data: { companyId?: string; companyName?: string } = {}) =>
  EventTracking.trackEvent(trackEventName.HelpCenterItemClicked, { ...data });

export const trackHelpCenterRequestSubmitted = (data: {
  companyId?: string;
  companyName?: string;
  requestType?: string;
}) => EventTracking.trackEvent(trackEventName.HelpCenterRequestSubmitted, { ...data });
