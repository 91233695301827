import { Box, Skeleton, Typography } from '@mui/material';
import { LinearGradientChip } from 'asteroids';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitleProps } from './page-title.props';

export const PageTitle: React.FC<PageTitleProps> = ({
  isLoadingData,
  premiumFeature,
  subtitle,
  title,
  titleAdditionalElement,
}) => {
  const { t } = useTranslation();

  if (isLoadingData) {
    return (
      <Box sx={{ overflow: 'hidden' }}>
        <Skeleton variant='rounded' width='40%' height={29} />
        {subtitle && <Skeleton variant='rounded' width='30%' height={20} sx={{ marginBottom: 0.5, marginTop: 1.5 }} />}
      </Box>
    );
  }

  return (
    <Box>
      {titleAdditionalElement || premiumFeature ? (
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: 2, mb: 1 }}>
          <Typography variant='h1' sx={{ color: 'text.primary' }} data-testid='page-title'>
            {title}
          </Typography>
          {titleAdditionalElement}
          {premiumFeature && <LinearGradientChip withIcon title={t('pricing_plans:premium_feature')} />}
        </Box>
      ) : (
        <Typography variant='h1' sx={{ color: 'text.primary' }} data-testid='page-title'>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant='subtitle1' sx={{ color: 'text.primary', marginTop: 1 }} data-testid='page-subtitle'>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
