import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum RequestType {
  TOOL_REQUEST = 'tool-request',
  RENEWAL = 'renewal',
}

export type BenchmarkRequestItem = {
  benchmarkRequestType: RequestType | undefined;
  // used for single tool initiatives, FF multitool_requests disabled
  vendorName: string | undefined;
  // used for multi tool initiatives, FF multitool_requests enabled
  vendorId: string | undefined;
  hasResults: boolean;
};

export const initialState: BenchmarkRequestItem = {
  benchmarkRequestType: undefined,
  hasResults: false,
  vendorId: undefined,
  vendorName: undefined,
};

const benchmarkRequestSlice = createSlice({
  initialState,
  name: 'benchmarkRequest',
  reducers: {
    clearBenchmarkRequestHasResults(state) {
      state.hasResults = false;
    },
    clearBenchmarkRequestType(state) {
      state.benchmarkRequestType = undefined;
    },
    clearBenchmarkRequestVendorId(state) {
      state.vendorId = undefined;
    },
    clearBenchmarkRequestVendorName(state) {
      state.vendorName = undefined;
    },
    setBenchmarkRequestHasResults(state, action: PayloadAction<boolean>) {
      state.hasResults = action.payload;
    },
    setBenchmarkRequestType(state, action: PayloadAction<RequestType>) {
      state.benchmarkRequestType = action.payload;
    },
    setBenchmarkRequestVendorId(state, action: PayloadAction<string>) {
      state.vendorId = action.payload;
    },
    setBenchmarkRequestVendorName(state, action: PayloadAction<string>) {
      state.vendorName = action.payload;
    },
  },
});

export const {
  clearBenchmarkRequestHasResults,
  clearBenchmarkRequestType,
  clearBenchmarkRequestVendorId,
  clearBenchmarkRequestVendorName,
  setBenchmarkRequestHasResults,
  setBenchmarkRequestType,
  setBenchmarkRequestVendorId,
  setBenchmarkRequestVendorName,
} = benchmarkRequestSlice.actions;

export default benchmarkRequestSlice.reducer;
