import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';

export const trackWorkflowRequestCreated = (data: {
  companyId?: string;
  companyName?: string;
  toolName?: string;
  totalStages: number;
  requestType: 'newPurchase' | 'renewal';
  numberOfContributors?: number;
  isMultiTool?: boolean;
  alternativeTools?: string[];
  name?: string;
  totalNumberOfTools?: number;
  hasGenericDocuments?: boolean;
}) => EventTracking.trackEvent(trackEventName.WorkflowRequestCreated, { ...data });

export const trackWorkflowRequestCompleted = (data: {
  companyId?: string;
  companyName?: string;
  toolName: string;
  timeToCompleteInHours?: number;
  requestType: 'newPurchase' | 'renewal';
}) => EventTracking.trackEvent(trackEventName.WorkflowRequestCompleted, { ...data });

export const trackWorkflowRequestCommentAdded = (data: {
  companyId?: string;
  companyName?: string;
  toolName: string;
  requestId: string;
  requestType: 'newPurchase' | 'renewal';
}) => EventTracking.trackEvent(trackEventName.WorkflowRequestCommentAdded, { ...data });

export const trackWorkflowRequestRejected = (data: {
  companyId?: string;
  companyName?: string;
  toolName: string;
  requestType: 'newPurchase' | 'renewal';
}) => EventTracking.trackEvent(trackEventName.WorkflowRequestRejected, { ...data });

export const trackSastrifyInvolvementCreated = (data: {
  companyId?: string;
  companyName?: string;
  toolName: string;
  requestType: 'newPurchase' | 'renewal';
}) => EventTracking.trackEvent(trackEventName.WorkflowRequestSastrifyInvolvementCreated, { ...data });

export const trackWorkflowActivatedWithDefaultStagesChanged = (data: {
  companyId?: string;
  companyName?: string;
  workflowType: 'Renewal' | 'Approval';
  defaultStagesNumber: number;
  newStagesNumber: number;
}) => EventTracking.trackEvent(trackEventName.WorkflowActivatedWithDefaultStagesChanged, { ...data });

export const trackWorkflowActivated = (data: { companyId?: string; workflowName: string }) =>
  EventTracking.trackEvent(trackEventName.WorkflowActivated, { ...data });

export const trackActiveWorkflowEdited = (data: { companyId?: string; workflowName: string }) =>
  EventTracking.trackEvent(trackEventName.WorkflowActiveEdited, { ...data });

export const trackMultitoolInitiativeToolAdded = (data: {
  companyId?: string;
  companyName?: string;
  initiativeId: string;
  initiativeName: string;
  initiativeType: 'newPurchase' | 'renewal';
  toolName: string;
}) => EventTracking.trackEvent(trackEventName.MultitoolInitiativeToolAdded, { ...data });

export const trackMultitoolInitiativeToolRemoved = (data: {
  companyId?: string;
  companyName?: string;
  initiativeId?: string;
  initiativeName?: string;
  initiativeType: 'newPurchase' | 'renewal';
  toolName: string;
}) => EventTracking.trackEvent(trackEventName.MultitoolInitiativeToolRemoved, { ...data });

export const trackGlobalProcurementHelpRequestCreated = (data: {
  subscriptionId: string;
  templateId: string;
  companyId: string;
}) => EventTracking.trackEvent(trackEventName.GlobalProcurementHelpRequestCreated, { ...data });

export const trackOverviewPageInitiativeAction = (data: {
  location: 'Upcoming renewals list' | 'Upcoming renewals calendar' | 'Shopping banner';
  action:
    | 'View renewal initiative'
    | 'Start renewal initiative'
    | 'View tool details'
    | 'New initiative'
    | 'Browse tool store';
}) => EventTracking.trackEvent(trackEventName.OverviewPageInitiativeAction, { ...data });

export const trackWorkflowWithCustomFormActivated = (data: {
  companyId?: string;
  companyName?: string;
  workflowType?: string;
}) => EventTracking.trackEvent(trackEventName.WorkflowWithCustomFormActivated, { ...data });

export const trackInitiativesStepSelected = (data: {
  companyId?: string;
  companyName?: string;
  location: 'Dashboard' | 'Initiatives' | 'Tool store' | 'Tool initiative tab' | 'Tool details';
  action: 'Start an initiative' | 'Request support';
}) => EventTracking.trackEvent(trackEventName.InitiativesStepSelected, { ...data });
