import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { useMemo } from 'react';

import { useAppSelector } from '../../store/hooks';
import { PRICING_PLANS_FEATURE_STATUS } from '../feature-switch/feature-statuses';

export type UsePlanPurchasableFeatures = {
  purchasableFeatures: PRICING_PLANS_FEATURES[];
  isFeaturePurchasable: (feature: PRICING_PLANS_FEATURES) => boolean;
};

export const usePlanPurchasableFeatures = (): UsePlanPurchasableFeatures => {
  const featureStatuses = useAppSelector((state) => state.pricingPlansFeatures);

  const purchasableFeatures: PRICING_PLANS_FEATURES[] = useMemo(() => {
    return featureStatuses
      ?.filter((feature) => feature.status === PRICING_PLANS_FEATURE_STATUS.PURCHASABLE)
      .map((feature) => feature.name);
  }, [featureStatuses]);

  const isFeaturePurchasable = (feature: PRICING_PLANS_FEATURES): boolean => {
    return purchasableFeatures?.includes(feature);
  };

  return { isFeaturePurchasable, purchasableFeatures };
};
