import { trackEventName } from 'shared/common.definitions';
import { EventTracking } from 'shared/services';

interface TrackToolStoreRequestCreatedParams {
  vendorName?: string;
  vendorId?: string;
  budgetCents?: number;
  budgetCurrency?: string;
  ownerId?: string;
  ownerName?: string;
  decisionDate?: string;
  comment?: string;
  tags?: Array<string>;
}

interface TrackToolStoreSearchTriggeredParams {
  searchTerm: string;
}

export const trackToolStoreRequestCreated = (data: TrackToolStoreRequestCreatedParams) => {
  EventTracking.trackEvent(trackEventName.ToolStoreToolRequestCreated, { ...data });
};

export const trackToolStoreSearchTriggered = (data: TrackToolStoreSearchTriggeredParams) => {
  EventTracking.trackEvent(trackEventName.ToolStoreSearchTriggered, { ...data });
};

export const trackToolStoreRequestToolButtonClicked = () => {
  EventTracking.trackEvent(trackEventName.ToolStoreRequestToolButtonClicked);
};
