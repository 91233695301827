import { Box, styled } from '@mui/material';

export const SpendReviewModalContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  '.spend-review-modal-body': {
    height: '600px',
    '.left-section': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexBasis: '60%',
      padding: theme.spacing(2),
      background: theme.palette.grey[100],
    },
    '.right-section': {
      flexBasis: '40%',
      borderRadius: theme.spacing(0.5),
      justifyContent: 'space-between',
      '.error-message': {
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
        '.error-message-title': {
          marginBottom: theme.spacing(0.5),
        },
        '.error-message-subtitle': {
          marginBottom: theme.spacing(0.5),
        },
      },
      '.info-block': {
        padding: theme.spacing(2, 4),
        background: theme.palette.error.light,
        borderRadius: theme.spacing(1.5),
        margin: theme.spacing(3, 4),
        boxShadow: 'none',
      },
      '.confirmation-section': {
        width: '100%',
        display: 'block',
        padding: theme.spacing(0, 4, 2),
        borderTop: `1px solid ${theme.palette.outlineBorder}`,
        textAlign: 'right',
        '.confirmation-options': {
          '.delete-button': {
            marginRight: theme.spacing(2),
            color: theme.palette.error.main,
          },
        },
      },
    },
  },
}));
