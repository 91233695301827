import { styled } from '@mui/material';
import { TableWithPaginationV2 } from 'components/table-with-pagination/table-with-pagination.component';

export const StyledTableWithPagination = styled(TableWithPaginationV2)(({ theme }) => ({
  '& > table': {
    '& > tbody > tr.Mui-selected > td, & > tbody > tr.Mui-selected:hover > td': {
      backgroundColor: `${theme.palette.primary.highlight} !important`,
    },
    '& > tbody > tr:hover > td': {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    '& td, & th': {
      '& > :first-child': {
        alignItems: 'center',
        display: 'flex',
      },
      '&.action-item-cell': {
        '& > button': {
          margin: '0 auto',
          padding: `${theme.spacing(1)} !important`,
        },
        '&::before': {
          backgroundColor: theme.palette.secondary.main,
          bottom: 0,
          content: '""',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '1px',
        },
        backgroundColor: theme.palette.background.light,
        padding: 0,
        position: 'sticky',
        right: 0,
        zIndex: 2,
      },
      '&.spend-item-cell': {
        textTransform: 'capitalize',
      },
      '&.tool-item-cell': {
        '& + td, & + th': {
          paddingLeft: theme.spacing(2),
        },
        '&::after': {
          backgroundColor: theme.palette.secondary.main,
          bottom: 0,
          content: '""',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          width: '1px',
        },
        backgroundColor: theme.palette.background.light,
        left: '58px',
        position: 'sticky',
        zIndex: 2,
      },

      height: theme.spacing(7),
      padding: theme.spacing(0, 1),
    },
    '&::-webkit-scrollbar': {
      height: theme.spacing(1),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: theme.spacing(0.5),
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.secondary.main,
    },
  },
}));
