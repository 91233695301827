/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
  const history = useHistory();

  const audience = process.env.AUTH0_AUDIENCE;
  const clientId = process.env.AUTH0_CLIENT_ID || '';
  const domain = process.env.AUTH0_DOMAIN || '';

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
