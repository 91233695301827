import { Box, Divider } from '@mui/material';
import { Fragment, ReactElement, useEffect, useMemo } from 'react';

import { LogoAndUser } from './logo-and-user/logo-and-user.molecule';
import { NavItem } from './nav-item/nav-item.molecule';
import { NavigationItemType, NavigationProps } from './navigation.props';
import { StyledNavigationContainer } from './navigation.styles';
import { NavList } from './navigation-list/nav-list.molecule';
import { UserInformation } from './user-information/user-information.molecule';

export const Navigation = (props: NavigationProps) => {
  const { badgeGetter, className, expanded, items, onBeforeNavigation, selected, setExpanded, setSelected, settings } =
    props;

  const allItems = useMemo(() => [...(items || []), ...(settings || [])], [items, settings]);

  useEffect(() => {
    if (!selected) return;

    for (let i = 0, l = allItems.length; i < l; i++) {
      const item = allItems[i];
      if (Array.isArray(item?.items)) {
        if (item.key === selected) {
          setExpanded?.(selected);
          break;
        } else {
          const selectedChild = item.items.find((child) => child.key === selected);
          if (selectedChild) {
            setExpanded?.(item.key);
            break;
          }
        }
      }
    }
  }, [allItems, selected, setExpanded, setSelected]);

  const handleExpanded = (key: string) => setExpanded?.(key && key === expanded ? undefined : key || undefined);

  const handleSelected = (item: NavigationItemType, headerKey?: string, force?: boolean) => {
    const { key, onClick } = item;

    if (!force) {
      let shouldContinue = true;

      if (typeof onBeforeNavigation === 'function') shouldContinue = onBeforeNavigation(item, headerKey) !== false;
      if (shouldContinue && typeof onClick === 'function') shouldContinue = onClick(item, headerKey) !== false;
      if (shouldContinue === false) return;
    }

    if (typeof props.onNavigate === 'function') {
      const shouldSelectItem = props.onNavigate(item, headerKey);
      if (shouldSelectItem !== false) setSelected?.(key || undefined);
    } else {
      setSelected?.(key || undefined);
    }
  };

  const renderNavItems = (items: NavigationItemType[]): ReactElement[] =>
    items
      ?.filter((item) => !!item)
      .map((item, index) => {
        const expandable = !!item.items;
        const isExpanded = item.key === expanded;
        const getName = (item: NavigationItemType) => (typeof item.name === 'function' ? item.name() : item.name);

        return (
          <Fragment key={`nav-section-${index + 1}`}>
            <NavItem
              key={item.key || `nav-item--${getName(item).replace(/\s/g, '')}`}
              item={item}
              text={typeof item.name === 'function' ? item.name() : item.name}
              iconName={item.iconName}
              badge={item.badge}
              badgeColor={item.badgeColor}
              badgeGetter={badgeGetter}
              customBadgeColor={item.customBadgeColor}
              customBadge={item.customBadge}
              expanded={isExpanded}
              onClick={() => (expandable ? handleExpanded(item.key) : handleSelected(item))}
              isHeader={item.isLeave !== true}
              expandable={expandable}
              selected={item.key === selected}
              data-testid={`nav-item--${item.key}`}
              {...(item.getNavItemProps?.(item) || {})}
            />
            {expandable && item.items?.length && (
              <NavList key='children-items' expanded={isExpanded} expandable>
                {item.items
                  ?.filter((subitem) => !!subitem)
                  .map((subitem) => (
                    <NavItem
                      key={subitem.key}
                      item={subitem}
                      text={getName(subitem)}
                      badge={subitem.badge}
                      badgeColor={subitem.badgeColor}
                      badgeGetter={badgeGetter}
                      customBadgeColor={subitem.customBadgeColor}
                      customBadge={item.customBadge}
                      onClick={() => handleSelected(subitem, item.key)}
                      selected={subitem.key === selected}
                      data-testid={`nav-item--${subitem.key}`}
                      {...(subitem.getNavItemProps?.(subitem) || {})}
                    />
                  ))}
              </NavList>
            )}
          </Fragment>
        );
      });

  return (
    <StyledNavigationContainer className={className}>
      <Box key='navigation-items'>
        <LogoAndUser
          key='logo-and-user'
          email={props.email}
          companyName={props.companyName}
          onLogout={props.onLogout}
          src={props.src}
          initials={props.initials}
          middlePlaceholder={props.middlePlaceholder}
        />
        <NavList key='items'>{renderNavItems(items as NavigationItemType[])}</NavList>
      </Box>
      <Box key='navigation-settings'>
        <NavList key='items'>{renderNavItems(settings as NavigationItemType[])}</NavList>
        <Divider key='divider' />
        <Box sx={{ paddingBottom: 1, paddingTop: 2, paddingX: 1 }}>
          <UserInformation key='user-info' email={props.email} companyName={props.companyName} />
          {props.footerPlaceholder}
        </Box>
      </Box>
    </StyledNavigationContainer>
  );
};

export default Navigation;
