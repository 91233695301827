/* eslint-disable camelcase */
import error404 from 'assets/images/errors/error-404.svg';
import { StatusDisplay } from 'components/design-system/status-display/status-display.component';
import { InviteUserInitialValue } from 'components/drawer/drawer.component.props';
import { DrawerRequestTool } from 'components/drawer-request-tool';
import { PRICING_PLANS_FEATURES } from 'libs/constants';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { COMMENT_TEMPLATE_SCOPES, DrawerInfoType, toolStoreBundleDrawerTypes } from 'shared/common.definitions';
import { decodeIdFromRoute } from 'shared/helpers/common.helper';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { CommentTemplate } from 'shared/models/comment-template.model';
import { useAppSelector } from 'shared/store/hooks';

import {
  DrawerAddNewToolComponent,
  DrawerDocumentUploadComponent,
  DrawerInitiativeDetailsWrapper,
  DrawerNewWorkflowStepperComponent,
  DrawerRequestHelpComponent,
  DrawerRequestWorkflow,
  DrawerSastricloudAccessRequest,
  DrawerUserInviteComponent,
  DrawerWorkflowInitiativeWrapper,
} from '..';
import { DrawerUploadSectionProps } from '.';
import { DrawerUploadSectionWrapper } from './drawer-upload-section.styles';

export const DrawerUploadSectionComponent: FC<DrawerUploadSectionProps> = (props) => {
  const {
    closeDrawer,
    closeModal,
    commentTemplates,
    drawerType: drawerTypeProp,
    inviteUserInitialValue,
    isCompanyDocument,
    isSubscriptionInDiscovery,
    isUploadingDocument,
    onSuccess,
    openSuccessConfirmationModal,
    setIsUploadingDocument,
    showModal,
    showNotification,
    toggleDrawer,
  } = props;

  const { t } = useTranslation();

  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isToolStoreBundleEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);
  const isSastriCloudEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.SASTRICLOUD);

  const location = useLocation();
  const history = useHistory();
  const subscriptionId = decodeIdFromRoute(location.pathname);

  useEffect(() => {
    return () => {
      const queryParams = new URLSearchParams(location.search);

      if (queryParams.has('supportTicketId')) {
        queryParams.delete('supportTicketId');
        history.replace({ search: queryParams.toString() });
      }
      if (queryParams.has('toolId')) {
        queryParams.delete('toolId');
        history.replace({ search: queryParams.toString() });
      }
    };
  }, [history, location.search]);

  const drawerState = useAppSelector((state) => state.common.appDrawer);
  const { data: appDrawerData, type: appDrawerType } = drawerState;
  const drawerType = drawerTypeProp || appDrawerType;

  const alignItemsStyle =
    drawerType === DrawerInfoType.SUBSCRIPTION_DOCUMENTS || drawerType === DrawerInfoType.COMPANY_DOCUMENTS
      ? 'center'
      : 'flex-start';

  const getCommentTemplateScope = (commentTemplates: CommentTemplate[] | undefined) => {
    switch (drawerType) {
      case DrawerInfoType.PROCUREMENT_HELP:
      case DrawerInfoType.REQUEST_HELP:
        return commentTemplates?.filter(
          (item) => item?.scope === COMMENT_TEMPLATE_SCOPES.PROCUREMENT_HELP || !item?.scope
        );
      case DrawerInfoType.SASTRIFY_APP_HELP:
        return commentTemplates?.filter((item) => item?.scope === COMMENT_TEMPLATE_SCOPES.APP_HELP);
      default:
    }
  };

  const featureNotAvailable = () => {
    return (
      <StatusDisplay
        title={t(`routes:error_${404}:title`)}
        description={t(`routes:error_${404}:description`)}
        icon={error404}
        primaryButton={{
          children: t('routes:back_button_label'),
          dataTestId: 'back-button',
          onClick: () => {
            history.replace(`${location.pathname}`);
            toggleDrawer();
          },
        }}
      />
    );
  };

  const uploadSection = () => {
    const isSubscription = drawerType === DrawerInfoType.USER_INVITE_SUBSCRIPTION;
    const inviteUserValue =
      inviteUserInitialValue ||
      (appDrawerData as { inviteUserInitialValue?: InviteUserInitialValue })?.inviteUserInitialValue;

    switch (drawerType) {
      case DrawerInfoType.SUBSCRIPTION_DOCUMENTS:
      case DrawerInfoType.COMPANY_DOCUMENTS:
        return (
          <DrawerDocumentUploadComponent
            isCompanyDocument={isCompanyDocument || appDrawerData?.companyUpload}
            isSubscriptionInDiscovery={isSubscriptionInDiscovery}
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            isUploadingDocument={isUploadingDocument}
            setIsUploadingDocument={setIsUploadingDocument}
            commentTemplates={commentTemplates}
            onSuccess={onSuccess}
          />
        );
      case DrawerInfoType.REQUEST_HELP:
      case DrawerInfoType.PROCUREMENT_HELP:
      case DrawerInfoType.SASTRIFY_APP_HELP:
        return (
          <DrawerRequestHelpComponent
            subscriptionId={subscriptionId}
            commentTemplates={getCommentTemplateScope(commentTemplates)}
            toggleDrawer={toggleDrawer}
            showNotification={showNotification}
            showModal={showModal}
            closeModal={closeModal}
            isGlobalRequestHelpDrawer={drawerType === DrawerInfoType.PROCUREMENT_HELP}
            openSuccessConfirmationModal={openSuccessConfirmationModal}
            drawerInfoType={drawerType}
          />
        );
      case DrawerInfoType.USER_INVITE:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTION:
      case DrawerInfoType.USER_INVITE_SUBSCRIPTIONS:
        return (
          <DrawerUserInviteComponent
            inviteUserInitialValue={inviteUserValue}
            toggleDrawer={toggleDrawer}
            showNotification={showNotification}
            isSubscription={isSubscription}
          />
        );
      case DrawerInfoType.NEW_SUBSCRIPTION:
      case DrawerInfoType.NEW_SOLUTION:
        return (
          <DrawerAddNewToolComponent
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            drawerType={drawerType}
            openSuccessConfirmationModal={openSuccessConfirmationModal}
            onAdd={appDrawerData?.onAdd}
          />
        );
      case DrawerInfoType.TOOL_REQUEST:
        return (
          <DrawerRequestTool showNotification={showNotification} toggleDrawer={toggleDrawer} drawerType={drawerType} />
        );
      case DrawerInfoType.WORKFLOW_REQUEST:
      case DrawerInfoType.WORKFLOW_REQUEST_EDIT:
        return (
          <DrawerWorkflowInitiativeWrapper
            toggleDrawer={toggleDrawer}
            drawerType={drawerType}
            showNotification={showNotification}
          />
        );
      case DrawerInfoType.REQUEST_DETAILS:
        return (
          <DrawerInitiativeDetailsWrapper
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            drawerType={drawerType}
            subscriptionId={subscriptionId}
            closeDrawer={closeDrawer}
          />
        );
      case DrawerInfoType.NEW_REQUEST_WORKFLOW:
      case DrawerInfoType.NEW_REQUEST_WORKFLOW_EDIT:
      case DrawerInfoType.NEW_REQUEST_WORKFLOW_DETAILS:
        return (
          <DrawerRequestWorkflow
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            drawerType={drawerType}
          />
        );
      case DrawerInfoType.NEW_WORKFLOW:
        return (
          <DrawerNewWorkflowStepperComponent
            toggleDrawer={toggleDrawer}
            showNotification={showNotification}
            data={appDrawerData}
          />
        );
      case DrawerInfoType.SASTRICLOUD_OPTIMIZE:
        return <DrawerSastricloudAccessRequest toggleDrawer={toggleDrawer} showNotification={showNotification} />;
      default:
        return null;
    }
  };

  const renderUploadSection = () => {
    if (!isToolStoreBundleEnabled && drawerType && toolStoreBundleDrawerTypes.includes(drawerType)) {
      return featureNotAvailable();
    }
    if (!isSastriCloudEnabled && drawerType === DrawerInfoType.SASTRICLOUD_OPTIMIZE) {
      return featureNotAvailable();
    }

    return uploadSection();
  };

  return <DrawerUploadSectionWrapper alignItems={alignItemsStyle}>{renderUploadSection()}</DrawerUploadSectionWrapper>;
};
