import { Button } from '@mui/material';
import classnames from 'classnames';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { trackUsedWorkflowTemplate } from 'shared/logic/event-tracking/workflow.events';
import { createWorkflow, formValuesToCreateNewWorkflow } from 'shared/logic/workflows.logic';
import { Company } from 'shared/models';
import { useAppDispatch } from 'shared/store/hooks';
import { setIsUpdating } from 'shared/store/views/workflows/workflows-list.slice';

import { DrawerNewWorkflowStepperControlComponentProps } from './drawer-new-workflow-stepper.component.props';

export const DrawerNewWorkflowStepperControlsComponent: FC<DrawerNewWorkflowStepperControlComponentProps> = ({
  getValues,
  isFinalStep,
  isValid,
  setActiveStep,
  setCurrentValues,
  showNotification,
  step,
  toggleDrawer,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyId = queryClient.getQueryData<Company>('company')?.id;

  // TODO: Extract to a generic custom hook
  const { mutateAsync: createWorkflowAsync } = useMutation(createWorkflow, {
    onError: () => {
      showNotification?.(t('workflows_view:new_workflow_drawer.error'), NotificationAlertType.Error);
      dispatch(setIsUpdating(false));
    },
    onMutate: () => {
      dispatch(setIsUpdating(true));
    },
    onSuccess: () => {
      const { templateTitle } = getValues();
      if (templateTitle) trackUsedWorkflowTemplate(templateTitle);
      showNotification?.(t('workflows_view:new_workflow_drawer.success'), NotificationAlertType.Success);
      queryClient.invalidateQueries(['workflows', companyId]).then(() => {
        dispatch(setIsUpdating(false));
      });
    },
  });

  const handleNext = () => {
    if (isFinalStep) {
      if (toggleDrawer) {
        setTimeout(toggleDrawer, 0); // Required to not reset form when drawer is collapsed
      }

      createWorkflowAsync(formValuesToCreateNewWorkflow(getValues()));
    } else {
      setCurrentValues(getValues());
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentValues(getValues());
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    if (toggleDrawer) {
      toggleDrawer();
    }
  };

  const finalStepButtonText = t(
    'workflows_view:task_automation.drawers.new_workflow.upload_section.stepper_controls.create'
  );

  return (
    <div className={classnames('new-workflow--controls')}>
      <Button onClick={handleCancel} className={classnames('new-workflow--cancel-button')}>
        {t('workflows_view:new_workflow_drawer.stepper_controls.cancel')}
      </Button>
      <Button disabled={step === 0} onClick={handleBack} className={classnames('new-workflow--back-button')}>
        {t('workflows_view:new_workflow_drawer.stepper_controls.back')}
      </Button>
      <Button
        className={classnames('new-workflow--next-button')}
        data-testid='create-new-workflow'
        variant='contained'
        color='primary'
        onClick={handleNext}
        disabled={isFinalStep && !isValid}>
        {isFinalStep ? finalStepButtonText : t('workflows_view:new_workflow_drawer.stepper_controls.continue')}
      </Button>
    </div>
  );
};
