export enum PRICING_PLANS_FEATURES {
  MULTIPLE_CONNECTIONS_ERP = 'multiple-connections-erp',
  MULTIPLE_CONNECTIONS_HRIS = 'multiple-connections-hris',
  SASTRICLOUD = 'sastricloud',
  COMMON = 'common',
  // vendor insights include pricing benchmarks
  VENDOR_INSIGHTS = 'vendor-insights',
  SASTRIFY_MARKET = 'sastrify-market',
  TOOL_STORE_BUNDLE = 'tool-store-bundle',
  SSO_AUTHENTICATION_OKTA = 'sso-auth-okta',
  SSO_AUTHENTICATION_MICROSOFT = 'sso-auth-microsoft',
  SSO_AUTHENTICATION_GOOGLE = 'sso-auth-google',
  SSO_AUTHENTICATION_JUMPCLOUD = 'sso-auth-jumpcloud',
  SSO_AUTHENTICATION_CISCO_DUO = 'sso-auth-ciscoduo',
  USER_ROLES = 'user-roles',
  USAGE_ANALYTICS = 'usage-analytics',
  ERP_NETSUITE_INTEGRATION = 'erp-acc-int-netsuite',
  ERP_SAGE_INTACCT_INTEGRATION = 'erp-acc-int-sage-intacct',
}

export enum PRICING_PLANS_FEATURE_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  PURCHASABLE = 'PURCHASABLE',
}
