import { Avatar, CircularProgress } from '@mui/material';
import classnames from 'classnames';
import { UnassignedToolOwnerIcon, WrappedEditPenIcon } from 'components/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFirstAndLastInitialsFromText } from 'shared/helpers/common.helper';

import { Typeahead } from '../typeahead';
import { EditToolOwnerProps } from './edit-tool-owner.props';

export const EditToolOwner: FC<EditToolOwnerProps> = ({
  assignToolOwnerFeatureRef,
  avatarUrl,
  classes,
  containerWithoutToolOwnerClassName,
  editContainerDataTestid,
  handleOnHideVisibility,
  handleToolOwnerSelection,
  hasHoverStyle,
  isCondensed,
  isRequester,
  isSetToEditToolOwner,
  isSubscriptionDetailPage,
  isSubscriptionInDiscovery,
  isToolOwnerNameEdited,
  isUpdatingToolOwner,
  isViewOnly,
  name,
  onEnterOrSpaceKeyUp,
  setIsEditingToolOwner,
  setIsToolOwnerNameEdited,
  setToolOwnerName,
  subscription,
  toolOwnerName,
}) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={editContainerDataTestid}
      className={classnames('', {
        'mt-3': isSubscriptionDetailPage,
      })}>
      {isSetToEditToolOwner && !isCondensed && (
        <Typeahead
          name={name}
          subscription={subscription}
          toolOwnerName={toolOwnerName}
          isRequester={isRequester}
          isSubscriptionInDiscovery={isSubscriptionInDiscovery}
          isToolOwnerNameEdited={isToolOwnerNameEdited}
          leftIconClassName={classes.avatar}
          handleOnHideVisibility={handleOnHideVisibility}
          handleToolOwnerSelection={handleToolOwnerSelection}
          componentRef={assignToolOwnerFeatureRef}
          onClearButtonClick={() => {
            assignToolOwnerFeatureRef.current?.handleClearDefaultOwnerNameValue();
            setToolOwnerName('');

            if (!isToolOwnerNameEdited) setIsToolOwnerNameEdited(true);
          }}
          onInputChange={(event) => {
            setToolOwnerName(event.target.value);
            if (!isToolOwnerNameEdited) setIsToolOwnerNameEdited(true);
          }}
        />
      )}
      {!isSetToEditToolOwner && (
        <div
          data-testid='container-without-tool-owner'
          tabIndex={0}
          role='button'
          onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => setIsEditingToolOwner(true))}
          onClick={(event) => {
            if (isCondensed) event.stopPropagation();
            setIsEditingToolOwner(true);
          }}
          className={containerWithoutToolOwnerClassName}>
          {isCondensed ? (
            <span>
              <UnassignedToolOwnerIcon />
            </span>
          ) : (
            <>
              <UnassignedToolOwnerIcon width={30} height={30} />
              <span className='owner-name-text'>
                {!isRequester
                  ? t('common:assign_tool_owner_feature.assign_tool_owner_text')
                  : t('common:assign_tool_owner_feature.assign_requester_text')}
              </span>
              {!isViewOnly && <WrappedEditPenIcon width={18} height={18} />}
            </>
          )}
          {isUpdatingToolOwner && (
            <span>
              <CircularProgress
                classes={{ colorPrimary: classes.circularProgress }}
                size={isCondensed ? '' : '1rem'}
                className='ml-3'
              />
            </span>
          )}
          {hasHoverStyle && <div className='avatar-overlay' />}
        </div>
      )}

      {isSetToEditToolOwner && isCondensed && (
        <div>
          {name ? (
            <Avatar src={avatarUrl} className={classes.toolOwner}>
              {getFirstAndLastInitialsFromText(name, true)}
            </Avatar>
          ) : (
            <UnassignedToolOwnerIcon />
          )}
          <section className={classes.toolOwnerCard}>
            <Typeahead
              name={name}
              subscription={subscription}
              toolOwnerName={toolOwnerName}
              isRequester={isRequester}
              isSubscriptionInDiscovery={isSubscriptionInDiscovery}
              isToolOwnerNameEdited={isToolOwnerNameEdited}
              leftIconClassName={classes.avatar}
              handleOnHideVisibility={handleOnHideVisibility}
              handleToolOwnerSelection={handleToolOwnerSelection}
              componentRef={assignToolOwnerFeatureRef}
              isCondensed={isCondensed}
              onClearButtonClick={() => {
                assignToolOwnerFeatureRef.current?.handleClearDefaultOwnerNameValue();
                setToolOwnerName('');

                if (!isToolOwnerNameEdited) setIsToolOwnerNameEdited(true);
              }}
              onInputChange={(event) => {
                setToolOwnerName(event.target.value);
                if (!isToolOwnerNameEdited) setIsToolOwnerNameEdited(true);
              }}
            />
          </section>
        </div>
      )}
    </div>
  );
};
