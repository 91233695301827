import DynamicsIcon from 'assets/images/icons/icon-dynamics.png';
import ExactOnlineIcon from 'assets/images/icons/icon-exact-online.svg';
import FreshbooksIcon from 'assets/images/icons/icon-freshbooks.png';
import NetsuiteIcon from 'assets/images/icons/icon-netsuite.png';
import QuickbooksIcon from 'assets/images/icons/icon-quickbooks.png';
import SageIcon from 'assets/images/icons/icon-sage.png';
import SageIntacctIcon from 'assets/images/icons/icon-sageintacct.png';
import XeroIcon from 'assets/images/icons/icon-xero.png';
import ZohobooksIcon from 'assets/images/icons/icon-zohobooks.png';
import { CodatPlatformType } from 'libs/models';
import { CodatPlatformName } from 'shared/models/integration-item.model';

export const getCodatPlatformLogo = (platformName: CodatPlatformName): string => {
  switch (platformName) {
    case CodatPlatformName.Netsuite:
      return 'card-img-netsuite';

    case CodatPlatformName.Xero:
      return 'card-img-xero';

    case CodatPlatformName.QuickBooksOnline:
      return 'card-img-quickbooks';

    case CodatPlatformName.Sage:
      return 'card-img-sage';

    case CodatPlatformName.SageIntacct:
      return 'card-img-sageintacct';

    case CodatPlatformName.Dynamics365:
      return 'card-img-dynamics365';

    case CodatPlatformName.ZohoBooks:
      return 'card-img-zohobooks';

    case CodatPlatformName.FreshBooks:
      return 'card-img-freshbooks';

    case CodatPlatformName.ExactOnlineNL:
    case CodatPlatformName.ExactOnlineUK:
      return 'card-img-exact-online';

    default:
      return 'card-img-codat';
  }
};

export const getCodatPlatformType = (platformName: CodatPlatformName): CodatPlatformType => {
  let platformType;

  if (platformName === CodatPlatformName.Netsuite) {
    platformType = CodatPlatformType.NETSUITE;
  }

  if (platformName === CodatPlatformName.Xero) {
    platformType = CodatPlatformType.XERO;
  }

  if (platformName === CodatPlatformName.Sage) {
    platformType = CodatPlatformType.SAGE;
  }

  if (platformName === CodatPlatformName.SageIntacct) {
    platformType = CodatPlatformType.SAGE_INTACCT;
  }

  if (platformName === CodatPlatformName.Dynamics365) {
    platformType = CodatPlatformType.DYNAMICS_365;
  }

  if (platformName === CodatPlatformName.FreshBooks) {
    platformType = CodatPlatformType.FRESHBOOKS;
  }

  if (platformName === CodatPlatformName.ZohoBooks) {
    platformType = CodatPlatformType.ZOHO_BOOKS;
  }

  if (platformName === CodatPlatformName.ExactOnlineNL) {
    platformType = CodatPlatformType.EXACT_ONLINE_NL;
  }

  if (platformName === CodatPlatformName.ExactOnlineUK) {
    platformType = CodatPlatformType.EXACT_ONLINE_UK;
  }

  if (platformName === CodatPlatformName.QuickBooksOnline) {
    const env = process.env.NODE_ENV;

    if (env === 'production') {
      platformType = CodatPlatformType.QUICKBOOKS_ONLINE;
    } else {
      platformType = CodatPlatformType.QUICKBOOKS_ONLINE_SANDBOX;
    }
  }

  if (!platformName) {
    throw new Error(`Platform ${platformName} is not recognized`);
  }

  return platformType as CodatPlatformType;
};

export const getPlatformName = (platformType?: CodatPlatformType): CodatPlatformName | undefined => {
  switch (platformType) {
    case CodatPlatformType.NETSUITE:
      return CodatPlatformName.Netsuite;

    case CodatPlatformType.XERO:
      return CodatPlatformName.Xero;

    case CodatPlatformType.QUICKBOOKS_ONLINE:
    case CodatPlatformType.QUICKBOOKS_ONLINE_SANDBOX:
      return CodatPlatformName.QuickBooksOnline;

    case CodatPlatformType.SAGE:
      return CodatPlatformName.Sage;
    case CodatPlatformType.SAGE_INTACCT:
      return CodatPlatformName.SageIntacct;

    case CodatPlatformType.DYNAMICS_365:
      return CodatPlatformName.Dynamics365;

    case CodatPlatformType.ZOHO_BOOKS:
      return CodatPlatformName.ZohoBooks;

    case CodatPlatformType.FRESHBOOKS:
      return CodatPlatformName.FreshBooks;

    case CodatPlatformType.EXACT_ONLINE_NL:
      return CodatPlatformName.ExactOnlineNL;

    case CodatPlatformType.EXACT_ONLINE_UK:
      return CodatPlatformName.ExactOnlineUK;

    default:
  }
};

export const getCodatIcon = (type: CodatPlatformType | null) => {
  switch (type) {
    case CodatPlatformType.FRESHBOOKS:
      return FreshbooksIcon;
    case CodatPlatformType.NETSUITE:
      return NetsuiteIcon;
    case CodatPlatformType.QUICKBOOKS_ONLINE:
    case CodatPlatformType.QUICKBOOKS_ONLINE_SANDBOX:
      return QuickbooksIcon;
    case CodatPlatformType.SAGE:
      return SageIcon;
    case CodatPlatformType.SAGE_INTACCT:
      return SageIntacctIcon;
    case CodatPlatformType.DYNAMICS_365:
      return DynamicsIcon;
    case CodatPlatformType.XERO:
      return XeroIcon;
    case CodatPlatformType.ZOHO_BOOKS:
      return ZohobooksIcon;
    case CodatPlatformType.EXACT_ONLINE_NL:
    case CodatPlatformType.EXACT_ONLINE_UK:
      return ExactOnlineIcon;
    default:
      return XeroIcon;
  }
};
