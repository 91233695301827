import './drawer-new-workflow-stepper.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Step, StepLabel, Stepper } from '@mui/material';
import classnames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewWorkflow } from 'shared/models';
import * as yup from 'yup';

import { DrawerNewWorkflowStepperComponentProps } from './drawer-new-workflow-stepper.component.props';
import { DrawerNewWorkflowStepperControlsComponent } from './drawer-new-workflow-stepper-controls.component';
import { DrawerNewWorkflowStepperCriteriaStepComponent } from './drawer-new-workflow-stepper-criteria-step.component';
import { DrawerNewWorkflowStepperTaskStepComponent } from './drawer-new-workflow-stepper-task-step.component';

export const DrawerNewWorkflowStepperComponent: FC<DrawerNewWorkflowStepperComponentProps> = ({
  data,
  showNotification,
  toggleDrawer,
}) => {
  const { t } = useTranslation();

  // ////////////////////////////////////////////////////////////////
  // Form control
  const addNewWorkflowSchema = yup.object().shape({
    assignToDefaultToolOwner: yup.boolean(),
    assignee: yup.string(),
    description: yup.string().required().max(255),
    dueDate: yup
      .string()
      .trim()
      .nullable()
      .matches(/^([1-9][0-9]{0,2})([dwmy])$/i, {
        excludeEmptyString: true,
        message: t('workflows_view:new_workflow_drawer.task_step.field_due_date_error'),
      }),
    dueDateAmount: yup
      .number()
      .positive()
      .nullable()
      .optional()
      .when('dueDatePeriod', {
        is: (fld: string) => !!fld,
        then: yup
          .number()
          .typeError(t('workflows_view:new_workflow_drawer.task_step.field_duedateamount_error'))
          .positive(t('workflows_view:new_workflow_drawer.task_step.field_duedateamount_error'))
          .min(1, t('workflows_view:new_workflow_drawer.task_step.field_duedateamount_error'))
          .required(),
      }),
    dueDatePeriod: yup.string().nullable().optional(),
    name: yup.string().trim().min(2),
    triggerCriteria: yup.string(),
  });

  const {
    clearErrors,
    control,
    formState: { errors, isValid },
    getValues,
    register,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm<NewWorkflow>({
    defaultValues: {
      assignToDefaultToolOwner: true,
      description: '',
      dueDate: '',
      name: '',
      triggerCriteria: 'new_subscription',
      ...(data || {}),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(addNewWorkflowSchema),
    shouldUnregister: true,
  });

  // State to store current form values to prevent losing them when changing steps
  const [currentValues, setCurrentValues] = useState<object | null>();

  // Resets form data to stored state values when changing steps to fill fields with original values
  useEffect(() => {
    reset(currentValues as object);
  }, [currentValues, reset]);

  // ////////////////////////////////////////////////////////////////
  // Stepper steps
  const steps = [
    t('workflows_view:new_workflow_drawer.stepper_steps.criteria'),
    t('workflows_view:new_workflow_drawer.stepper_steps.task'),
  ];

  const [activeStep, setActiveStep] = useState(0);
  const isFinalStep = activeStep === steps.length - 1;

  const renderStepIcons = () => (
    <Stepper className={classnames('new-workflow--steps-icons')} activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );

  const setSelectedTrigger = (newTrigger: string) => {
    setValue('triggerCriteria', newTrigger);
  };

  const renderStepBody = () => {
    switch (activeStep) {
      case 0:
        return (
          <DrawerNewWorkflowStepperCriteriaStepComponent control={control} setSelectedTrigger={setSelectedTrigger} />
        );
      case 1:
        return (
          <DrawerNewWorkflowStepperTaskStepComponent
            clearErrors={clearErrors}
            control={control}
            register={register}
            errors={errors}
            trigger={trigger}
            values={getValues()}
            triggerCriteria={getValues().triggerCriteria}
            setValue={setValue}
            watch={watch}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={classnames('new-workflow')}>
      {renderStepIcons()}

      <section className={classnames('new-workflow--step-body')}>{renderStepBody()}</section>

      <DrawerNewWorkflowStepperControlsComponent
        step={activeStep}
        isFinalStep={isFinalStep}
        isValid={isValid}
        getValues={getValues}
        toggleDrawer={toggleDrawer}
        showNotification={showNotification}
        setActiveStep={setActiveStep}
        setCurrentValues={setCurrentValues}
      />
    </Box>
  );
};
