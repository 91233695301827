import { ListSubheader, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Virtuoso } from 'react-virtuoso';

const LISTBOX_PADDING = 8;

interface ListboxComponentProps {
  children: React.ReactNode;
}

export const ListboxComponent = React.forwardRef<HTMLDivElement, ListboxComponentProps>((props, ref) => {
  const { children, ...other } = props;

  const itemData = React.Children.toArray(children);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smallScreen ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref} {...other}>
      <Virtuoso
        data-testid='virtual-list'
        style={{ height: getHeight() + 2 * LISTBOX_PADDING, overflowY: 'scroll' }}
        className='virtual-list'
        data={itemData}
        itemContent={(index) => {
          return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <section>{React.cloneElement(itemData[index] as React.DetailedReactHTMLElement<any, HTMLElement>)}</section>
          );
        }}
      />
    </div>
  );
});
