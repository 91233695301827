import { SxProps } from '@mui/material';

import { StatusDotStatus } from '../status-dot/status-dot.component.props';

export enum Types {
  ticket = 'ticket',
  subscription = 'subscription',
  card = 'card',
}

export enum Statuses {
  open = 'open',
  pending = 'pending',
  inProgress = 'inProgress',
  alert = 'alert',
  success = 'success',
  error = 'error',
  inactive = 'inactive',
  active = 'active',
  inEvaluation = 'inEvaluation',
  inNegotiation = 'inNegotiation',
  renewal = 'renewal',
  discovered = 'discovered',
  connected = 'connected',
  connectionExpired = 'connectionExpired',
  disconnected = 'disconnected',
  expired = 'expired',
  resolved = 'resolved',
  onHold = 'onHold',
}

export type StatusCommonProps = {
  title?: string;
  subtitle?: string;
};

export type StatusProps = {
  status: StatusDotStatus;
  statusTitle: string;
  subtitle?: string;
  loading?: boolean;
  sx?: SxProps;
};
