export enum HRISGroupType {
  DEPARTMENT = 'DEPARTMENT',
  TEAM = 'TEAM',
  COST_CENTER = 'COST_CENTER',
}

/**
 * These are statuses defined in Kombo HRIS API.
 * Reference: https://docs.kombo.dev/hris/v1/get-employees
 *
 * ACTIVE: the employee is actively employed
 * PENDING: the employee is not actively employed yet (but they signed their contract or are part of an onboarding process)
 * INACTIVE: a full-time employee is no longer employed, or, for a contract worker when their contract runs out
 * LEAVE: the employee is still employed but currently on leave (note that not all HR systems support this status — use our absences API for detailed information)
 */
export enum HRISEmploymentStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  LEAVE = 'LEAVE',
}
