import { Box } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { SectorComponentContainer } from './sector.styles';

const defaultSize = 100;
const defaultThickness = 6;

export interface SectorComponentProps {
  progress?: number;
  children?: React.ReactNode;
  className?: string;
  size?: number;
  thickness?: number;
  contentsPadding?: 'standard' | 'small' | 'none';
}

const getPadding = (thickness: number, contentsPadding: SectorComponentProps['contentsPadding'] = 'standard') => {
  const defaultPadding = thickness + 4;

  switch (contentsPadding) {
    case 'standard':
      return defaultPadding;
    case 'small':
      return thickness + 1;
    case 'none':
      return 0;
    default:
      return defaultPadding;
  }
};

export const SectorComponent = (props: SectorComponentProps) => {
  const progress = typeof props.progress === 'number' ? props.progress : 100;
  const thickness = props.thickness || defaultThickness;
  const thickness2 = thickness / 2;
  const padding = getPadding(thickness, props.contentsPadding);
  const width = props.size || defaultSize;
  const height = props.size || defaultSize;
  const width2 = width / 2;
  const height2 = height / 2;

  const r = width2 - thickness2;
  const circumference = Math.PI * r * 2;
  const getPct = useCallback(() => ((100 - progress) / 100) * circumference, [circumference, progress]);
  const [pct, setPct] = useState(getPct());
  const sectorRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    setPct(getPct());
    if (sectorRef.current) {
      sectorRef.current.style.strokeDashoffset = `${pct}px`;
    }
  }, [getPct, progress, pct]);

  return (
    <SectorComponentContainer $padding={padding} className={`sector ${props.className || ''}`}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        width={width || 'auto'}
        height={height || 'auto'}
        viewBox={`0 0 ${width} ${height}`}>
        <circle
          className='sector-bg'
          cx={width2}
          cy={height2}
          r={r}
          fill='none'
          style={{ strokeWidth: `${thickness}px` }}
        />
        <circle
          className='sector-fg'
          cx={width2}
          cy={height2}
          r={r}
          fill='none'
          style={{ strokeDasharray: circumference, strokeDashoffset: circumference, strokeWidth: `${thickness}px` }}
          ref={sectorRef}
        />
      </svg>

      <Box className='sector-content'>{props.children}</Box>
    </SectorComponentContainer>
  );
};
