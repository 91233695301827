import { Typography } from '@mui/material';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { CsvBuilder } from 'filefy';
import { useModalAndNotification } from 'hooks/index';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY } from 'shared/common.definitions';
import {
  SubscriptionUsageAnalyticsTrackingParams,
  trackDownloadUsersListClicked,
  trackViewUsersListClicked,
} from 'shared/logic/event-tracking/usage-analytics.events';
import { Subscription } from 'shared/models';
import { WorkatoLicenseHolderData } from 'shared/models/tool-usage-analytics.model';

import { HeaderBox } from '../components/usage-analytics/usage-analytics.styles';
import { UsageAnalyticsLicenseHolders } from '../components/usage-analytics/usage-analytics-license-holders/usage-analytics-license-holders.component';

export type UsageAnalyticsLicenseHoldersHookProps = {
  subscriptionId?: string;
  showNotification?: (message: React.ReactNode, type: NotificationAlertType) => void;
  data?: WorkatoLicenseHolderData[];
  trackClickData: SubscriptionUsageAnalyticsTrackingParams;
};

export function useUsageAnalyticsLicenseHoldersModal(props: UsageAnalyticsLicenseHoldersHookProps) {
  const { onCloseAsteroidsModal, onShowAsteroidsModal } = useModalAndNotification();
  const closeLicenseHoldersModal = () => {
    onCloseAsteroidsModal();
  };

  const { data, showNotification, subscriptionId, trackClickData } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const subscription = queryClient.getQueryData<Subscription>(['subscription', subscriptionId]);

  const handleDownloadCSV = () => {
    trackDownloadUsersListClicked(trackClickData);

    const currentDate = new Date().toISOString().split('T')[0];
    const csvFileName = `Sastrify(${subscription?.name})-usage-data-${currentDate}.csv`;

    const tableColumns = [
      { id: 'appUserName', name: 'Name' },
      { id: 'isActive', name: 'Active' },
      { id: 'appUserEmail', name: 'Email' },
    ];

    if (data) {
      new CsvBuilder(csvFileName)
        .setColumns(tableColumns.map((col) => String(col.name)))
        .addRows(
          data
            .map((item) => {
              return {
                ...item,
                isActive: item.isActive
                  ? t('subscription_detail_view:usage_analytics_section:license_holders_modal:active')
                  : t('subscription_detail_view:usage_analytics_section:license_holders_modal:inactive'),
              };
            })
            .map((item) => tableColumns.map((col) => String(item[col.id as keyof WorkatoLicenseHolderData])))
        )
        .exportFile();
      showNotification?.(MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY, NotificationAlertType.Success);
    }
  };

  const openLicenseHoldersModal = () => {
    trackViewUsersListClicked(trackClickData);
    if (data) {
      onShowAsteroidsModal({
        cancelButtonText: t('common_labels:close'),
        children: <UsageAnalyticsLicenseHolders data={data} />,
        confirmButtonText: t('subscription_detail_view:usage_analytics_section:license_holders_modal:download_csv'),
        customTitle: (
          <HeaderBox>
            <Typography variant='h2'>
              {t('subscription_detail_view:usage_analytics_section:license_holders_modal:title')}
            </Typography>
          </HeaderBox>
        ),
        onCancelButtonClick: closeLicenseHoldersModal,
        onConfirmButtonClick: handleDownloadCSV,
        triggerCancelButtonClick: true,
        width: 'large',
      });
    }
  };

  return [openLicenseHoldersModal];
}
