import { AxiosError } from 'axios';
import { useFetchData } from 'hooks/useFetchData';
import { SOURCE_NAME, TYPE } from 'libs/constants/index';
import { ToolUsageSsoProviderDto } from 'libs/dtos';
import { SSOIntegrationStatus, TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums';
import { FC, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DrawerInfoType } from 'shared/common.definitions';
import { getCompanyGoogleConnectionState, getMicrosoftConnectionStatus } from 'shared/logic/company.logic';
import {
  BaseUsageAnalyticsTrackingParams,
  trackConnectViewed,
} from 'shared/logic/event-tracking/usage-analytics.events';
import {
  getLicenseUsageV2,
  getToolUsageSsoProviders,
  getToolUsageVendorById,
} from 'shared/logic/tool-usage-analytics.logic';
import { Company, Subscription } from 'shared/models';
import { SSOCompanyConnectionState } from 'shared/models/integration-item.model';
import { ToolUsageActivityDataV2 } from 'shared/models/tool-usage-analytics.model';
import { show as showDrawer } from 'shared/store/common/appDrawer.slice';
import { setSelectedVendor } from 'shared/store/views/connect/usage-analytics-item.slice';
import { useUser } from 'src/app/hooks/useUser';

import { GOOGLE_WORKSPACE, MICROSOFT_AZURE, UsageAnalyticsProps } from './usage-analytics.props';
import { UsageAnalyticsSkeleton } from './usage-analytics.skeleton';
import { UsageAnalyticsCardError } from './usage-analytics-card-error/usage-analytics-card-error.component';
import { UsageAnalyticsCardConnect } from './usage-analytics-card-v2/usage-analytics-card-connect.component';
import { UsageAnalyticsCardConnected } from './usage-analytics-card-v2/usage-analytics-card-connected.component';

const getSsoProvider = (
  ssoProviderList: ToolUsageSsoProviderDto[],
  usageAnalyticsData: ToolUsageActivityDataV2,
  isProviderConnected: (ssoProvider: ToolUsageSsoProviderDto) => boolean
): ToolUsageSsoProviderDto | undefined => {
  if (!ssoProviderList || !usageAnalyticsData.totalUsers) {
    return;
  }
  const availableSsoProviders = ssoProviderList.filter(
    (ssoProvider: ToolUsageSsoProviderDto) =>
      ssoProvider.state === TOOL_USAGE_INTEGRATION_STATE.CONNECTED ||
      (ssoProvider.state === TOOL_USAGE_INTEGRATION_STATE.AVAILABLE && isProviderConnected(ssoProvider))
  );

  if (!availableSsoProviders) {
    return;
  }

  const ssoSources = new Set(
    usageAnalyticsData.totalUsers
      .filter((activityItem) => activityItem.type === TYPE.SSO && activityItem.source)
      .map((activityItem) => activityItem.source)
  );

  const [ssoProvider] = availableSsoProviders.filter(
    (ssoProvider) => ssoProvider.toolUsageSource && ssoSources.has(ssoProvider.toolUsageSource)
  );
  return ssoProvider;
};

export const UsageAnalytics: FC<UsageAnalyticsProps> = (props: UsageAnalyticsProps) => {
  const { showNotification, subscriptionId, vendorId } = props;

  const [workatoError, setWorkatoError] = useState(false);

  const queryClient = useQueryClient();
  const company = queryClient.getQueryData('company') as Company;
  const subscription = queryClient.getQueryData(['subscription', subscriptionId]) as Subscription;

  const user = useUser();
  const location = useLocation();
  const baseTrackingEventProps: BaseUsageAnalyticsTrackingParams = {
    analyticsProviderName: subscription?.vendorName ?? '',
    analyticsProviderType: TYPE.DIRECT,
    analyticsSourceName: SOURCE_NAME.WORKATO,
    companyId: user.companyId ?? '',
    userId: user.id ?? '',
  };

  const dispatch = useDispatch();

  // Get vendor usage analytics data V2
  const {
    data: usageAnalyticsData,
    isLoading: isLoadingUsageAnalyticsDataV2,
    refetch: refetchLicenseUsageV2,
  } = useQuery(
    ['license-usage-v2', company?.id, subscriptionId],
    () => getLicenseUsageV2(String(company?.id), subscriptionId),
    {
      enabled: company?.id !== undefined,
      onError: (error: AxiosError) => {
        if (error?.response?.status !== 404) {
          setWorkatoError(true);
        }
      },
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

  // Is the vendor in the list of the supported tool usage vendors
  const {
    data: vendorUsage,
    isError: isVendorUsageError,
    refetch: refetchVendorUsage,
  } = useQuery(['vendor-usage', vendorId], () => getToolUsageVendorById(String(vendorId || '')), {
    enabled: Boolean(vendorId),
    onError: (error: AxiosError) => {
      if (error?.response?.status !== 404) {
        setWorkatoError(true);
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const directUsageAnalyticsConnected = vendorUsage?.state === TOOL_USAGE_INTEGRATION_STATE.CONNECTED;
  const directUsageAnalyticsAvailable = vendorUsage?.state === TOOL_USAGE_INTEGRATION_STATE.AVAILABLE;

  const { data: googleConnectionStateResponse } = useFetchData<SSOCompanyConnectionState>(
    'google-sso-connection-state',
    () => getCompanyGoogleConnectionState(String(company?.id)),
    {
      enabled: Boolean(company?.id) && !directUsageAnalyticsConnected,
      refetchOnWindowFocus: false,
    }
  );

  const { data: microsoftConnectionStateResponse } = useFetchData<SSOCompanyConnectionState>(
    'ms-connection-state',
    () => getMicrosoftConnectionStatus(String(company?.id)),
    {
      enabled: Boolean(company?.id) && !directUsageAnalyticsConnected,
      refetchOnWindowFocus: false,
    }
  );

  // Get SSO Providers
  const { data: ssoProviderList } = useQuery('workato-sso-providers', getToolUsageSsoProviders, {
    enabled: !directUsageAnalyticsConnected,
    onError: (error: AxiosError) => {
      if (error?.response?.status !== 404) {
        setWorkatoError(true);
      }
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  let ssoProvider: ToolUsageSsoProviderDto | undefined;

  if (ssoProviderList && usageAnalyticsData) {
    const isGoogleConnected = (ssoProvider: ToolUsageSsoProviderDto): boolean =>
      ssoProvider.ssoProviderName === GOOGLE_WORKSPACE &&
      googleConnectionStateResponse?.status === SSOIntegrationStatus.CONNECTED &&
      !!googleConnectionStateResponse.usageAnalyticsEnabled;

    const isMicrosoftConnected = (ssoProvider: ToolUsageSsoProviderDto): boolean =>
      ssoProvider.ssoProviderName === MICROSOFT_AZURE &&
      microsoftConnectionStateResponse?.status === SSOIntegrationStatus.CONNECTED &&
      !!microsoftConnectionStateResponse.usageAnalyticsEnabled;

    const getIsSSOConnected = (ssoProvider: ToolUsageSsoProviderDto): boolean =>
      isGoogleConnected(ssoProvider) || isMicrosoftConnected(ssoProvider);

    ssoProvider = getSsoProvider(ssoProviderList, usageAnalyticsData, getIsSSOConnected);
  }

  const onConnect = useCallback(() => {
    if (!vendorUsage) {
      return;
    }
    dispatch(showDrawer(DrawerInfoType.USAGE_ANALYTICS_DIRECT));

    dispatch(
      setSelectedVendor({
        clickHereUrl: vendorUsage?.clickHereUrl,
        companyId: String(company?.id) || '',
        companyName: company?.name || '',
        learnMoreUrl: vendorUsage?.learnMoreUrl,
        requiredPlanDescription: vendorUsage?.requiredPlanDescription,
        requiredPlanTitle: vendorUsage?.requiredPlanTitle,
        subscriptionId: subscriptionId || '',
        toolUsageSource: vendorUsage?.toolUsageSource,
        type: vendorUsage?.type || TYPE.DIRECT,
        vendorId: subscription?.vendorId || '',
        vendorName: subscription?.vendorName || '',
      })
    );
    trackConnectViewed(baseTrackingEventProps, location.pathname);
  }, [
    company?.id,
    company?.name,
    dispatch,
    subscription?.vendorId,
    subscription?.vendorName,
    subscriptionId,
    vendorUsage?.clickHereUrl,
    vendorUsage?.learnMoreUrl,
    vendorUsage?.requiredPlanDescription,
    vendorUsage?.requiredPlanTitle,
  ]);

  if (isLoadingUsageAnalyticsDataV2) {
    return <UsageAnalyticsSkeleton />;
  }

  if (workatoError) {
    return (
      <UsageAnalyticsCardError
        onRefresh={() => {
          if (isVendorUsageError) {
            refetchVendorUsage();
          } else {
            refetchLicenseUsageV2();
          }
        }}
      />
    );
  }

  if (usageAnalyticsData && company?.id && (directUsageAnalyticsConnected || ssoProvider)) {
    const toolUsageProvider = directUsageAnalyticsConnected ? vendorUsage : ssoProvider;
    const ssoSourceName = directUsageAnalyticsConnected ? subscription?.vendorName : ssoProvider?.ssoProviderName;

    return (
      <UsageAnalyticsCardConnected
        data={usageAnalyticsData}
        toolName={ssoSourceName ?? ''}
        toolUsageProvider={toolUsageProvider as ToolUsageSsoProviderDto}
        isDirectIntegration={directUsageAnalyticsConnected}
        isDirectUsageAnalyticsAvailable={directUsageAnalyticsAvailable}
        onConnect={onConnect}
        showNotification={showNotification}
        subscriptionId={subscriptionId}
        subscriptionVendorName={subscription.vendorName ?? ''}
      />
    );
  }

  if (directUsageAnalyticsAvailable && company?.id) {
    return <UsageAnalyticsCardConnect onConnect={onConnect} />;
  }

  return null;
};
