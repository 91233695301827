import { Tooltip, Typography } from '@mui/material';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { CsvBuilder } from 'filefy';
import { Subscription } from 'libs/models';
import { useTranslation } from 'react-i18next';
import { MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY, TableColumnNames } from 'shared/common.definitions';
import { formatFullDate } from 'shared/helpers/common.helper';
import { getSubscriptionSpendCategoryMappings } from 'shared/logic/subscription-item.logic';
import { Company } from 'shared/models';
import { SubscriptionSpendAndInvoices } from 'shared/models/subscription-spend-and-invoices.model';
import { useModalAndNotification } from 'src/app/hooks';

import { getItemAmount, getItemConversion } from '../spend-table/helpers';

export type SpendActionsProps = {
  subscription: Subscription;
  selectedRows: SubscriptionSpendAndInvoices[];
  hideConversionColumn: boolean;
  company: Company | undefined;
  legends: string[];
};

export interface SubscriptionSpendAndInvoicesCSV extends SubscriptionSpendAndInvoices {
  type?: string;
  spendSource?: string;
}

export type FieldKeys = {
  id: number;
  name: string;
  categoryType: string;
  date: string;
  amountCents: string;
};

export function useBulkSpendActions({
  company,
  hideConversionColumn,
  legends,
  selectedRows,
  subscription,
}: SpendActionsProps) {
  const { t } = useTranslation();
  const { onShowNotification } = useModalAndNotification();
  const translationPath = 'tool_details_view:spend_tab.spend_and_invoices_table';

  const headerColumns = [
    {
      className: 'tool-item-cell',
      id: TableColumnNames.NAME,
      label: t(`${translationPath}.header_section.name_label`),
      minWidth: 240,
      name: t(`${translationPath}.header_section.name_label`),
    },
    {
      id: TableColumnNames.DATE,
      label: t(`${translationPath}.header_section.date_label`),
      minWidth: 120,
      name: t(`${translationPath}.header_section.date_label`),
    },
    {
      id: TableColumnNames.AMOUNT,
      label: t(`${translationPath}.header_section.amount_label`),
      minWidth: 120,
      name: t(`${translationPath}.header_section.amount_label`),
    },
    {
      'data-testid': 'spends-company-amount',
      hide: hideConversionColumn,
      id: TableColumnNames.COMPANY_AMOUNT,
      label: (
        <Tooltip placement='top' title={t(`${translationPath}.header_section.company_amount_description`)}>
          <Typography variant='label'>{t(`${translationPath}.header_section.company_amount_label`)}</Typography>
        </Tooltip>
      ),
      minWidth: 120,
      name: t(`${translationPath}.header_section.company_amount_label`),
    },
    {
      className: 'action-item-cell',
      id: 'edit',
      label: '',
    },
  ].filter((item) => !item.hide);

  const handleDownloadSpendDataCSV = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const csvFileName = `Sastrify(${
      subscription?.name ?? subscription?.vendorName
    })-spend-data-export-${currentDate}.csv`;

    const modifiedTableColumns = [...headerColumns].map((item) => ({ id: item.id, name: item.name }));
    modifiedTableColumns.pop();
    modifiedTableColumns.unshift({ id: 'id', name: 'Id' });

    const modifiedSelectedRowsData = (
      JSON.parse(JSON.stringify(selectedRows)) as SubscriptionSpendAndInvoicesCSV[]
    ).map((item) => {
      item.name = item.name ? item.name : '--';
      item.type = getSubscriptionSpendCategoryMappings(legends)[Number(item.category)];
      item.date = formatFullDate(item.date as string);
      item.amountFormatted = getItemAmount(item) ?? '';
      item.companyAmountCents = getItemConversion(item, company) ?? '';

      return item;
    });

    new CsvBuilder(csvFileName)
      .setColumns(modifiedTableColumns.map((col) => String(col.name)))
      .addRows(
        modifiedSelectedRowsData.map((item) =>
          modifiedTableColumns.map((col) => String(item[col.id as keyof FieldKeys]))
        )
      )
      .exportFile();
    onShowNotification?.(MESSAGE_CSV_FILE_EXPORTED_SUCCESSFULLY, NotificationAlertType.Success);
  };

  return {
    handleDownloadSpendDataCSV,
  };
}
