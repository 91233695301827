import { useTranslation } from 'react-i18next';

import { StatusDotColors, StatusDotProps } from '../../status-dot/status-dot.component.props';
import { Status } from '../status.component';
import { Statuses } from '../status.component.props';
import { StatusSubscriptionProps } from './subscription-status.component.props';

export const SubscriptionStatus: React.FC<StatusSubscriptionProps> = ({ status, subtitle, title }) => {
  const { t } = useTranslation();

  let statusColor: StatusDotProps['status'];
  let statusTitle!: string;

  switch (status) {
    case Statuses.discovered:
      statusColor = StatusDotColors.warning;
      statusTitle = t('asteroids:status.discovered');
      break;
    case Statuses.inNegotiation:
      statusColor = StatusDotColors.info;
      statusTitle = t('asteroids:status.inNegotiation');
      break;
    case Statuses.inEvaluation:
      statusColor = StatusDotColors.tertiary;
      statusTitle = t('asteroids:status.inEvaluation');
      break;
    case Statuses.active:
      statusColor = StatusDotColors.success;
      statusTitle = t('asteroids:status.active');
      break;
    case Statuses.renewal:
      statusColor = StatusDotColors.error;
      statusTitle = t('asteroids:status.renewal');
      break;
    case Statuses.inactive:
      statusColor = StatusDotColors.inactive;
      statusTitle = t('asteroids:status.inactive');
      break;
    case Statuses.expired:
      statusColor = StatusDotColors.error;
      statusTitle = t('asteroids:status.expired');
      break;
    default:
      statusColor = StatusDotColors.inactive;
      statusTitle = t('asteroids:status.inactive');
  }

  if (title) statusTitle = title;

  return <Status status={statusColor} subtitle={subtitle} statusTitle={statusTitle} />;
};

export default SubscriptionStatus;
