/* eslint-disable jsx-a11y/media-has-caption */
import './video.component.scss';

import { useEffect, useRef } from 'react';
import videojs from 'video.js';

export interface VideoComponentProps extends videojs.PlayerOptions {
  className?: string;
  type?: string;
  onReady?: (player: unknown) => void;
  onPlay?: () => void;
}

const defaultVideoOptions: videojs.PlayerOptions = {
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
  controls: true,
};

export const Video = (props: VideoComponentProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);

  useEffect(() => {
    if (playerRef.current) return;
    const videoElement = videoRef.current;
    if (!videoElement) return;

    // Player initialization
    const options = { ...defaultVideoOptions, ...props };
    if (!options.sources && props.src) {
      options.sources = [{ src: props.src, type: props.type }];
    }

    playerRef.current = videojs(videoElement, options, () => {
      if (typeof props.onReady === 'function') {
        props.onReady(playerRef.current);
      }
    });

    // Callbacks
    if (props.onPlay) {
      playerRef.current.one('play', () => {
        if (props.onPlay) props.onPlay();
      });
    }
  }, [playerRef, props, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (!player) return;
      player.dispose();
      playerRef.current = null;
    };
  }, [playerRef]);

  return (
    <div className={`data-vjs-player ${props.className || ''}`} data-testid='video-js'>
      <video
        ref={videoRef}
        className='video-js vjs-big-play-centered'
        src={props.src || ''}
        data-setup='{"fluid": true}'
      />
    </div>
  );
};
