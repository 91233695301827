import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useRef } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getOtherBillingFrequency, subscriptionAutoRenewalLogic } from 'shared/logic/subscription-item.logic';
import { Subscription } from 'shared/models';
import { updateFooterState } from 'shared/store/modal';
import { getOffsetDateISOString } from 'views/overview/calendar/subscription-indicator/helpers';
import * as yup from 'yup';

export type RenewalDate = {
  billingFrequency: number;
  renewalDate?: string;
  autoRenewal: number;
  cancellationPeriod: number;
  customCancellationPeriod: string;
  customCancellationPeriodType: number;
  otherBillingFrequencyType?: 'years' | 'months';
  otherBillingFrequency?: number;
};

export function useChangeRenewalDateForm(
  subscription: Subscription,
  billingFrequency?: number,
  renewalDate?: Date | undefined
): UseFormReturn<RenewalDate> {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const areFormValuesInitialized = useRef<boolean>(false);

  const changeRenewalDateSchema = useMemo(
    () =>
      yup.object().shape({
        autoRenewal: yup.number(),
        billingFrequency: yup.number().min(0).max(3),
        cancellationPeriod: yup.mixed(),
        customCancellationPeriod: yup.mixed().when('cancellationPeriod', {
          is: (cancellationPeriod: number) => Number(cancellationPeriod) === -2,
          then: yup
            .number()
            .min(1, t('common:modals.change_renewal_date_feature.body_section.form.error_other_billing_frequency'))
            .integer()
            .required()
            .typeError(t('common:modals.change_renewal_date_feature.body_section.form.error_other_billing_frequency')),
        }),
        customCancellationPeriodType: yup.number(),
        otherBillingFrequency: yup
          .number()
          .nullable()
          .optional()
          .min(0)
          .when('otherBillingFrequencyType', {
            is: (otherBillingFrequencyType: string) => !!otherBillingFrequencyType,
            then: yup
              .number()
              .min(1, t('common:modals.change_renewal_date_feature.body_section.form.error_other_billing_frequency'))
              .integer()
              .required()
              .typeError(
                t('common:modals.change_renewal_date_feature.body_section.form.error_other_billing_frequency')
              ),
          }),
        otherBillingFrequencyType: yup.string(),
        renewalDate: yup.date().when('billingFrequency', {
          is: (billingFrequency: number) => {
            return Number(billingFrequency) > 0;
          },
          then: yup
            .date()
            .min(new Date(), 'Invalid date (dd/mm/yyyy)')
            .typeError(t('common:modals.change_renewal_date_feature.body_section.form.error_renewal_date'))
            .test(
              'renewalDate',
              t('common:modals.change_renewal_date_feature.body_section.form.error_renewal_date_invalid'),
              (value) => {
                if (!value) {
                  return false;
                }
                return new Date(value).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
              }
            )
            .required(),
        }),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const form = useForm<RenewalDate>({
    mode: 'onChange',
    resolver: yupResolver(changeRenewalDateSchema),
    shouldUnregister: true,
  });

  const { isDirty, isValid } = form.formState;

  useEffect(() => {
    if (isDirty) {
      dispatch(updateFooterState({ isFormValid: isValid }));
    }
  }, [dispatch, isDirty, isValid]);

  // Initializes field values depending on the subscription data on open
  useEffect(() => {
    if (areFormValuesInitialized.current) {
      return;
    }

    areFormValuesInitialized.current = true;

    if (typeof billingFrequency === 'undefined') {
      return;
    }

    form.setValue('billingFrequency', billingFrequency === null || billingFrequency === -1 ? -1 : billingFrequency, {
      shouldDirty: true,
      shouldValidate: true,
    });

    if (Number(billingFrequency) !== 0) {
      const { customCancellationPeriod, customCancellationPeriodType } = subscriptionAutoRenewalLogic(subscription);
      const autoRenewalValue = subscription.autoRenewal ? 1 : 0;
      const otherBillingFrequency = getOtherBillingFrequency(
        subscription?.renewalInterval?.years,
        subscription?.renewalInterval?.months
      );

      const otherBillingFrequencyType = subscription?.renewalInterval?.months ? 'months' : 'years';

      let modifiedCustomCancellationPeriod = '';
      let modifiedCustomCancellationPeriodType = 0;

      if (customCancellationPeriod) {
        modifiedCustomCancellationPeriod = customCancellationPeriodType
          ? String((subscription?.cancellationPeriod?.days as number) / 30)
          : String(subscription?.cancellationPeriod?.days);
        modifiedCustomCancellationPeriodType = customCancellationPeriodType ? 1 : 0;
      }

      const formValues: RenewalDate = {
        autoRenewal: subscription.autoRenewal !== null ? autoRenewalValue : -1,
        billingFrequency,
        cancellationPeriod: customCancellationPeriod ? -2 : subscription?.cancellationPeriod?.days || -1,
        customCancellationPeriod: modifiedCustomCancellationPeriod,
        customCancellationPeriodType: modifiedCustomCancellationPeriodType,
        otherBillingFrequency,
        otherBillingFrequencyType,
        renewalDate:
          typeof renewalDate !== 'undefined' && !Number.isNaN(Number(renewalDate))
            ? getOffsetDateISOString(renewalDate)
            : '',
      };

      form.reset(formValues); // Set all values and after this we trigger validation to update form validity state
      form.trigger().then((isValid) => dispatch(updateFooterState({ isFormValid: isValid })));
    }
  }, [billingFrequency, dispatch, form, renewalDate, subscription]);

  return form;
}

export default useChangeRenewalDateForm;
