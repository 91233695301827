import { Icon, InputField, MaterialReactTable } from 'asteroids';
import { useDebounce } from 'hooks/useDebounce';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { MRT_ColumnDef, MRT_RowData } from 'material-react-table';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InitiativeListComponentProps } from './initiative-list.component.props';
import { NoInitiativeFound } from './no-initiative-found.component';

export const InitiativeListComponent: FC<InitiativeListComponentProps> = ({
  columns,
  enableColumnDragging = true,
  enableColumnFilters = true,
  enableColumnResizing = true,
  enablePagination = true,
  enableSorting = true,
  isSubscriptionInitiatives,
  onItemClick,
  rows,
  withSearch,
}) => {
  const { t } = useTranslation();
  const searchInputRef = useRef<{ focus: () => void }>(null);
  const [searchString, setSearchString] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useDebounce(setDebouncedValue, searchString, 400);

  const getType = useCallback(
    (requestWorkflowTypeId?: REQUEST_WORKFLOW_TYPE) => {
      let type = '';
      if (requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL) {
        type = t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.tool_request_label');
      }
      if (requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL) {
        type = t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.renewal_label');
      }

      return type;
    },
    [t]
  );

  const filteredInitiatives = useMemo(
    () =>
      rows.filter(
        ({ assigneeFullName, name, requestWorkflowTypeId, requesterFullName, tools, vendorName }) =>
          (name || '').toLowerCase().includes(debouncedValue.toLowerCase()) ||
          (vendorName || '').toLowerCase().includes(debouncedValue.toLowerCase()) ||
          (tools || [])
            .map((tool) => tool.vendorName)
            .join(' ')
            .toLowerCase()
            .includes(debouncedValue.toLowerCase()) ||
          requesterFullName?.toLowerCase().includes(debouncedValue.toLowerCase()) ||
          assigneeFullName?.toLowerCase().includes(debouncedValue.toLowerCase()) ||
          getType(requestWorkflowTypeId).toLowerCase().includes(debouncedValue.toLowerCase())
      ),
    [debouncedValue, rows, getType]
  );

  const onSearchChange = (event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newSearchString = event ? event.target.value : '';
    setSearchString(newSearchString);

    if (!newSearchString) {
      searchInputRef?.current?.focus();
    }
  };

  return (
    <>
      {withSearch && (
        <InputField
          key='input'
          data-testid='search-field'
          value={searchString}
          inputRef={searchInputRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onSearchChange(event)}
          onClearValue={() => onSearchChange()}
          label={t('requests_view:search_label')}
          startAdornment={
            <Icon key='start-adornment' variant='Outlined'>
              search
            </Icon>
          }
          sx={{ height: '36px', width: '420px' }}
        />
      )}
      {filteredInitiatives.length ? (
        <MaterialReactTable
          data={filteredInitiatives}
          columns={columns as MRT_ColumnDef<object>[]}
          state={{ isFullScreen }}
          enableColumnPinning
          enableColumnActions={false}
          enableColumnDragging={enableColumnDragging}
          enableColumnOrdering
          enableColumnResizing={enableColumnResizing}
          enableFullScreenToggle
          enableColumnFilters={enableColumnFilters}
          enableSorting={enableSorting}
          layoutMode='grid'
          enableDensityToggle={false}
          enableGlobalFilter={false}
          enablePagination={enablePagination}
          onIsFullScreenChange={(value: unknown) => setIsFullScreen(value as boolean)}
          muiTableBodyRowProps={({ row }: { row: MRT_RowData }) => {
            const { requestId } = row?.original as never;

            return {
              'data-testid': `requestId-${requestId || ''}`,
              onClick: () => {
                setIsFullScreen(false);

                if (!isFullScreen) onItemClick?.(row?.original as never);
                else setTimeout(() => onItemClick?.(row?.original as never), 200);
              },
              sx: { cursor: 'pointer' },
            };
          }}
        />
      ) : (
        <NoInitiativeFound isSubscriptionInitiatives={isSubscriptionInitiatives} />
      )}
    </>
  );
};
