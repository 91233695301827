import { styled } from '@mui/material';
import { colors } from 'shared/theme';

export const ToolRequestWrapper = styled('section')(({ theme }) => ({
  '& .comment': {
    '&:focus-visible': {
      outlineColor: colors.primary,
    },
    borderColor: colors.alto,
    borderRadius: '0.25rem',
    fontFamily: 'inherit',
    fontSize: '1rem',
    lineHeight: '1rem',
    padding: '0.813rem 0.5rem',
    width: '100%',
  },
  '& .label': {
    marginBottom: theme.spacing(2),
  },
  '& .react-tags__search-input': {
    color: colors.greyText,
    height: '2.5rem',
  },
  '& .action-button': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  width: '100%',
  marginBottom: theme.spacing(5),
}));
