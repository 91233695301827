import { useCallback } from 'react';
import { User } from 'shared/models';
import { useUser } from 'src/app/hooks';

export const useFilterRequester = (requesterEmail: string, mode: 'create' | 'edit') => {
  const loggedInUser = useUser();

  const filterRequester = useCallback(
    (user: User) => {
      if (mode === 'create') {
        return loggedInUser?.email !== user.email;
      }

      if (mode === 'edit') {
        return requesterEmail !== user.email;
      }

      return true;
    },
    [loggedInUser?.email, mode, requesterEmail]
  );

  return {
    filterRequester,
  };
};
