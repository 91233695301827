import { Box, Skeleton, styled, Typography } from '@mui/material';
import { Avatar } from 'asteroids';
import { FC } from 'react';
import { getFirstAndLastInitialsFromText } from 'shared/helpers/common.helper';

interface SsoToolsListItemProps {
  id: string;
  name: string;
  logoUrl?: string;
  loading?: boolean;
}

const LogoImage = styled('img')(({ theme }) => ({
  height: theme.spacing(4),
  objectFit: 'contain',
  width: theme.spacing(4),
}));

export const SsoToolsListItem: FC<SsoToolsListItemProps> = ({ id, loading, logoUrl, name }: SsoToolsListItemProps) => {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', mb: 3 }} key={id}>
      {loading ? (
        <>
          <Skeleton variant='rounded' height='32px' width='32px' animation='wave' />
          <Skeleton variant='text' height='32px' width='30%' animation='wave' sx={{ ml: 2 }} />
        </>
      ) : (
        <>
          {logoUrl && <LogoImage src={logoUrl} alt={name} />}
          {!logoUrl && (
            <Avatar alt={name} variant='rounded' sx={{ height: 4, width: 4 }}>
              {getFirstAndLastInitialsFromText(name || '', true)}
            </Avatar>
          )}
          <Typography pl={2} mb={0} variant='h3'>
            {name}
          </Typography>
        </>
      )}
    </Box>
  );
};
