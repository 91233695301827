import './schema-field.styles.scss';

import { t } from 'i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SchemaFieldTypes } from 'shared/models/schema-field';

import { SchemaSelectField } from './SchemaSelectField.component';
import { SchemaTextField } from './SchemaTextField.component';

interface SchemaFieldProps {
  fieldName: string;
  fieldOptions?: UseFormRegisterReturn;
  fieldValue: string;
  fieldType: SchemaFieldTypes;
  error?: FieldError;
  isEditing: boolean;
  selectOptions?: string[];
}

const field = {
  [SchemaFieldTypes.Text]: SchemaTextField,
  [SchemaFieldTypes.Email]: SchemaTextField,
  [SchemaFieldTypes.Select]: SchemaSelectField,
};

export const SchemaField: React.FC<SchemaFieldProps> = ({
  error,
  fieldName,
  fieldOptions,
  fieldType,
  fieldValue,
  isEditing,
  ...props
}) => {
  if (!fieldName) return null;

  const Component = field[fieldType];

  if (!Component) {
    // eslint-disable-next-line no-console
    console.warn(`Unexpected: Tried to render non-existing schema field '${fieldType}'. Aborting.`);
    return null;
  }

  return (
    <Component
      isEditing={isEditing}
      error={error}
      field_name={fieldName}
      field_value={fieldValue}
      field_options={fieldOptions}
      label={t(`subscription_detail_view:details_view.fields.${fieldName}.label`)}
      placeholder={t(`subscription_detail_view:details_view.fields.${fieldName}.placeholder`)}
      {...props}
    />
  );
};
