import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/store/hooks';

import { Link } from '../../link/index';
import { UsageAnalyticsInformation } from '../components/usage-analytics-information.component';

const UnorderedList = styled('ul')(({ theme }) => ({
  listStylePosition: 'inside',
  listStyleType: 'disc',
  marginLeft: theme.spacing(1),
}));

const UsageAnalyticsInformationDirect: FC = () => {
  const { t } = useTranslation();
  const selectedVendor = useAppSelector((state) => state.views.connect.usageAnalyticsItem.selectedVendor);

  const selectedProvider = {
    clickHereUrl: selectedVendor?.clickHereUrl || '',
    name: selectedVendor?.vendorName || '',
    requiredPlanDescription: selectedVendor?.requiredPlanDescription,
    requiredPlanTitle: selectedVendor?.requiredPlanTitle,
  };

  return (
    <UsageAnalyticsInformation
      selectedProvider={selectedProvider}
      usageSectionTitle={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_1.title')}
      usageSectionCustomContent={
        <>
          <Typography variant='body1' color='text.primary' sx={{ display: 'block', marginBottom: 1 }}>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_1.direct.paragraph1', {
              vendorName: selectedVendor?.vendorName,
            })}
          </Typography>
          <Typography variant='body1' color='text.primary'>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_1.direct.paragraph2')}
          </Typography>
        </>
      }
      infoCollectedSectionTitle={t(
        'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.direct.title'
      )}
      infoCollectedSectionCustomContent={
        <>
          <Typography variant='body1' color='text.primary' sx={{ marginBottom: 1 }}>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.direct.paragraph1')}
            <UnorderedList>
              <li>
                {t(
                  'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.direct.paragraph1_point1'
                )}
              </li>
              <li>
                {t(
                  'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.direct.paragraph1_point2'
                )}
              </li>
            </UnorderedList>
          </Typography>
          <Typography variant='body1' color='text.primary' sx={{ marginBottom: 1 }}>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.direct.paragraph2', {
              vendorName: selectedVendor?.vendorName,
            })}{' '}
            <Box sx={{ display: 'inline-block' }}>
              <Link
                href={selectedVendor?.learnMoreUrl || ''}
                text={t(
                  'connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_2.direct.link_label'
                )}
                withIcon
              />
            </Box>
          </Typography>
        </>
      }
    />
  );
};

export default React.memo(UsageAnalyticsInformationDirect);
