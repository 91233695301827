export const MAX_CONTENT_WIDTH = 1500;

const breakpoints = (breakpoint: string) => {
  if (breakpoint === 'sm') {
    return {
      maxWidth: '100%',
    };
  }
  if (breakpoint === 'xl') {
    return {
      maxWidth: '100%',
      minWidth: '1216px',
      width: `${MAX_CONTENT_WIDTH}px`,
    };
  }
};

export default breakpoints;
