import { SOURCE_NAME, TYPE } from 'libs/constants/index';
import { TOOL_USAGE_INTEGRATION_STATE } from 'libs/enums/vendor-tool-usage-integration.enum';

export interface CreateWorkatoIntegrationParams {
  companyId: string;
  companyName: string;
  signal?: AbortSignal;
}

export interface CreateDirectWorkatoIntegrationParams extends CreateWorkatoIntegrationParams {
  subscriptionId: string;
  vendorId: string;
}

export interface CreateSsoWorkatoIntegrationParams extends CreateWorkatoIntegrationParams {
  ssoName: string;
  usageAnalyticsEnabled?: boolean;
}

export type DirectWorkatoIntegrationParams = {
  companyId: string;
  subscriptionId: string;
};

export type GetWorkatoIntegrationParams = {
  companyId: string;
  ssoName: string;
};

export type SsoWorkatoIntegrationParams = {
  companyId: string;
  ssoName: string;
};

export interface SsoWorkatoData {
  customerId: number;
  recipeIds: number[];
  connectionIds: number[];
  isRunning: boolean;
  usageAnalyticsEnabled: boolean;
}

export type WorkatoIntegrationPayload = {
  connectionIds: number[];
  customerId: number;
  isRunning: boolean;
  recipeIds: number[];
};

export interface ToolUsageProvider {
  clickHereUrl?: string;
  description?: string;
  learnMoreUrl?: string;
  requiredPlanDescription?: string;
  requiredPlanTitle?: string;
  state: TOOL_USAGE_INTEGRATION_STATE;
  type: TYPE;
  toolUsageSource: SOURCE_NAME;
  hideActiveUsers?: boolean;
}

export interface ToolUsageVendor extends ToolUsageProvider {
  subscriptionId: string;
  vendorName: string;
  vendorId: string;
  vendorImageUrl?: string;
  usageMetric?: string;
}

export type CreateWorkatoIntegrationResponse = {
  connection: WorkatoIntegrationPayload;
  token: string;
};

export enum LicenseUsageLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export type ToolUsageActivityItem = {
  date: string;
  count: number;
  source: SOURCE_NAME;
  type: TYPE;
};

export type ToolUsageActivityDataV2 = {
  activeUsers: ToolUsageActivityItem[];
  totalUsers: ToolUsageActivityItem[];
  isProcessing?: boolean;
};

export type WorkatoLicenseHolderData = {
  activityDate: string | Date;
  appUserEmail: string;
  appUserId: string;
  appUserName: string;
  source: SOURCE_NAME;
  type: TYPE;
  isActive: boolean;
};

export type WorkatoLicenceHoldersWithDepartment = WorkatoLicenseHolderData & {
  department?: string;
};

export type SsoTool = {
  vendorId: string;
  vendorName: string;
  vendorLogoUrl?: string;
};
