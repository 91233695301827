import classnames from 'classnames';
import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';
import { getUsersList as getGoogleDirectoryUsersList } from 'shared/store/common/googleDirectory.slice';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { AssignToolOwnerFeatureProps } from './assign-tool-owner.feature.props';
import { classes, Root } from './assign-tool-owner.styles';
import { EditToolOwner } from './components/edit-tool-owner';
import { ToolOwner } from './components/tool-owner';

export const AssignToolOwnerFeature: FC<AssignToolOwnerFeatureProps> = (props) => {
  const {
    handleOnHideVisibility,
    handleToolOwnerSelection,
    hasHoverStyle,
    isCondensed,
    isEditingToolOwner,
    isRequester = false,
    isSubscriptionDetailPage,
    isSubscriptionInDiscovery,
    isUpdatingToolOwner,
    isViewOnly,
    setIsEditingToolOwner,
    setToolOwnerName,
    subscription,
    toolOwnerName,
  } = props;
  const [isToolOwnerNameEdited, setIsToolOwnerNameEdited] = useState<boolean>(false);
  const assignToolOwnerFeatureRef = useRef<{ handleClearDefaultOwnerNameValue: () => void }>();
  const [isStatusTooltipOpen, setIsStatusTooltipOpen] = useState<boolean>(false);

  const isSetToEditToolOwner = !isViewOnly ? subscription && isEditingToolOwner : false;

  const name = isSubscriptionInDiscovery ? subscription?.creatorName : subscription?.ownerName;
  const avatarUrl = isSubscriptionInDiscovery ? subscription?.creatorAvatarUrl : subscription?.ownerAvatarUrl;

  const dispatch = useAppDispatch();

  const {
    connected: isConnectedToGoogle,
    hasFetchedUsers,
    isFetchingUsers,
    isGoogleDirectoryError,
  } = useAppSelector((state) => state.common.googleDirectory);

  useEffect(() => {
    if (!isSetToEditToolOwner) {
      setIsToolOwnerNameEdited(false);
    }

    return () => {
      setToolOwnerName('');
    };
  }, [isSetToEditToolOwner, setToolOwnerName]);

  const handleUnassignedToolOwnerClick = (event?: MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isCondensed && event) event.stopPropagation();
    setIsEditingToolOwner(true);
  };

  const handleGoogleDirectoryUsersFetch = useCallback(() => {
    if (!hasFetchedUsers && !isFetchingUsers) {
      dispatch(getGoogleDirectoryUsersList({}));
    }
  }, [dispatch, hasFetchedUsers, isFetchingUsers]);

  const isGoogleDirectoryConnected = useRef<boolean>(false);

  useEffect(() => {
    if (isConnectedToGoogle && !isGoogleDirectoryError) {
      handleGoogleDirectoryUsersFetch();
    }
    if (isConnectedToGoogle && hasFetchedUsers && !isGoogleDirectoryConnected.current) {
      isGoogleDirectoryConnected.current = true;
    }
  }, [isConnectedToGoogle, handleGoogleDirectoryUsersFetch, isGoogleDirectoryError, hasFetchedUsers]);

  const [ownerNameTooltipOpen, setOwnerNameTooltipOpen] = useState<boolean>(false);

  const containerWithoutToolOwnerClassName = classnames(
    'is-flex is-flex-row is-align-items-center is-clickable',
    classes.toolOwnerWrapper,
    { 'my-3': isSubscriptionDetailPage }
  );

  const containerDataTestid = isRequester ? 'container-with-requester' : 'container-with-tool-owner';
  const editContainerDataTestid = isRequester ? 'edit-requester-container' : 'edit-toolowner-container';

  return (
    <Root hasAvatar={Boolean(avatarUrl)} isSubscriptionDetailPage={isSubscriptionDetailPage}>
      {name && !isSetToEditToolOwner ? (
        <ToolOwner
          avatarUrl={avatarUrl}
          classes={classes}
          containerDataTestid={containerDataTestid}
          handleUnassignedToolOwnerClick={handleUnassignedToolOwnerClick}
          hasHoverStyle={hasHoverStyle}
          isRequester={isRequester}
          isStatusTooltipOpen={isStatusTooltipOpen}
          isSubscriptionDetailPage={isSubscriptionDetailPage}
          isUpdatingToolOwner={isUpdatingToolOwner}
          isViewOnly={isViewOnly}
          isSubscriptionInDiscovery={isSubscriptionInDiscovery}
          isCondensed={isCondensed}
          name={name}
          onEnterOrSpaceKeyUp={onEnterOrSpaceKeyUp}
          ownerNameTooltipOpen={ownerNameTooltipOpen}
          setIsStatusTooltipOpen={setIsStatusTooltipOpen}
          setOwnerNameTooltipOpen={setOwnerNameTooltipOpen}
          subscription={subscription}
        />
      ) : (
        <EditToolOwner
          assignToolOwnerFeatureRef={assignToolOwnerFeatureRef}
          avatarUrl={avatarUrl}
          classes={classes}
          containerWithoutToolOwnerClassName={containerWithoutToolOwnerClassName}
          editContainerDataTestid={editContainerDataTestid}
          handleOnHideVisibility={handleOnHideVisibility}
          handleToolOwnerSelection={handleToolOwnerSelection}
          hasHoverStyle={hasHoverStyle}
          isCondensed={isCondensed}
          isRequester={isRequester}
          isSetToEditToolOwner={isSetToEditToolOwner}
          isSubscriptionDetailPage={isSubscriptionDetailPage}
          isSubscriptionInDiscovery={isSubscriptionInDiscovery}
          isToolOwnerNameEdited={isToolOwnerNameEdited}
          isUpdatingToolOwner={isUpdatingToolOwner}
          isViewOnly={isViewOnly}
          name={name}
          onEnterOrSpaceKeyUp={onEnterOrSpaceKeyUp}
          setIsEditingToolOwner={setIsEditingToolOwner}
          setIsToolOwnerNameEdited={setIsToolOwnerNameEdited}
          setToolOwnerName={setToolOwnerName}
          subscription={subscription}
          toolOwnerName={toolOwnerName}
        />
      )}
    </Root>
  );
};
