import { Box, Button, Stack } from '@mui/material';
import { Avatar } from 'asteroids';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RichTextEditorComponent } from '../../rich-text-editor/rich-text-editor.component';

interface ChatInputProps {
  avatarSrc: string;
  avatarAlt: string;
  value: string;
  onAction?: () => void;
  onCancel?: () => void;
  onChange: (value: string) => void;
  cancelBtnLabel?: string;
  actionBtnLabel?: string;
  testId?: string;
  isSubmitButtonDisabled?: boolean;
}

export const ChatInputComponent: FC<ChatInputProps> = ({
  actionBtnLabel,
  avatarAlt,
  avatarSrc,
  cancelBtnLabel,
  isSubmitButtonDisabled = false,
  onAction,
  onCancel,
  onChange,
  testId,
  value,
}) => {
  const { t } = useTranslation();
  const isEmptyValue = !value || value === '<p><br></p>';

  return (
    <Stack direction='row' spacing={1} width='100%' mb={2}>
      <Avatar src={avatarSrc} alt={avatarAlt} size='small' />
      <Stack direction='column' width='100%'>
        <Box mb={1}>
          <RichTextEditorComponent
            value={value}
            onChange={onChange}
            placeholder={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.comment_placeholder'
            )}
            dataTestId={`${testId}-input`}
          />
        </Box>
        <Stack direction='row' justifyContent='flex-end'>
          {onCancel && (
            <Button
              variant='text'
              color='secondary'
              size='small'
              onClick={onCancel}
              data-testid={`${testId}-cancel-button`}>
              {cancelBtnLabel ||
                t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.comment_cancel_button_label'
                )}
            </Button>
          )}
          <Button
            color='primary'
            size='small'
            onClick={onAction}
            data-testid={`${testId}-save-button`}
            disabled={isEmptyValue || isSubmitButtonDisabled}>
            {actionBtnLabel ||
              t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.comment_button_label'
              )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
