import { SPEND_CATEGORIES } from 'libs/enums';
import { EXTENSION_TO_MIME_TYPE_MAP } from 'shared/helpers/html5-file-selector.helper';
import * as yup from 'yup';

export const getPreviewUrlExpireSeconds = (previewUrl: string) => {
  const params = new URLSearchParams(previewUrl);
  const expireSeconds = Number(params.get('X-Amz-Expires'));
  return expireSeconds;
};

export const supportedFileTypes = [
  EXTENSION_TO_MIME_TYPE_MAP.pdf,
  EXTENSION_TO_MIME_TYPE_MAP.jpg,
  EXTENSION_TO_MIME_TYPE_MAP.jpeg,
  EXTENSION_TO_MIME_TYPE_MAP.png,
];

export const NO_FAILED_INVOICE_FOUND_MESSAGE = 'No Failed Invoice found';

export const invoiceSchema = yup.object().shape({
  amount: yup.number().required(),
  currency: yup.string().required(),
  invoiceDate: yup.string().required(),
  name: yup.string().required(),
  tool: yup.string(),
});

export const invoiceFixSchema = yup.object().shape({
  amount: yup.number().required(),
  currency: yup.string().required(),
  invoiceDate: yup.string().required(),
  name: yup.string().required(),
  tool: yup.string().required(),
});

export const transformSpendCategoryToSource = (category: number) => {
  switch (category) {
    case SPEND_CATEGORIES.AUTOMATED_IMPORT:
      return 'Automated Import';
    case SPEND_CATEGORIES.ESTIMATED_INVOICE:
      return 'Estimated Spend';
    case SPEND_CATEGORIES.SUBSCRIPTION_DOCUMENT:
    case SPEND_CATEGORIES.PROCESSED_SUBSCRIPTION_DOCUMENT:
      return 'Uploaded Invoice';
    case SPEND_CATEGORIES.ACCOUNTING_INFORMATION:
      return 'Accounting Import';
    case SPEND_CATEGORIES.SPREADSHEET_UPLOAD:
      return 'Spreadsheet Upload';
    default:
  }
};
