import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, shadows } from 'asteroids';

export const Wrapper = styled(Box)(() => ({
  height: 'calc(100vh - 5rem)',
  position: 'relative',
  width: '100%',
}));

export const Content = styled(Box)<{ isStepperVisible?: boolean; isProcurementSupportEnabled?: boolean }>(
  ({ isProcurementSupportEnabled, isStepperVisible, theme }) => ({
    height: 'calc(100vh - 8rem)',
    overflowY: 'auto',
    padding: theme.spacing(2),
    ...(isStepperVisible && { height: 'calc(100vh - 10.5rem)' }),
    ...(isProcurementSupportEnabled && { height: 'calc(100vh - 16.5rem)', padding: theme.spacing(7, 1) }),
  })
);

export const Footer = styled(Box)<{ isProcurementSupportEnabled?: boolean }>(
  ({ isProcurementSupportEnabled, theme }) => ({
    bottom: theme.spacing(-5),
    left: theme.spacing(-4),
    position: 'absolute',
    width: 'calc(100% + 4rem)',
    height: isProcurementSupportEnabled ? 'unset' : theme.spacing(8.75),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 5),
    display: isProcurementSupportEnabled ? 'inherit' : 'flex',
    justifyContent: isProcurementSupportEnabled ? 'inherit' : 'space-between',
    alignItems: isProcurementSupportEnabled ? 'inherit' : 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    ...(isProcurementSupportEnabled && { zIndex: 1000 }),
  })
);

export const Header = styled(Box)<{ isProcurementSupportEnabled?: boolean }>(
  ({ isProcurementSupportEnabled, theme }) => ({
    ...(!isProcurementSupportEnabled && { paddingBottom: '2rem' }),
    ...(isProcurementSupportEnabled && {
      backgroundColor: colors.background.default,
      borderBottom: `1px solid ${colors.divider}`,
      margin: theme.spacing(-5, -4),
      padding: theme.spacing(4, 5),
      position: 'relative',
      zIndex: 1000,
    }),
  })
);

export const ClickableCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: shadows.card,
  borderRadius: theme.spacing(0.5),
  '&:hover': {
    boxShadow: shadows.cardHover,
  },
  cursor: 'pointer',
}));
