import { useEffect } from 'react';

export const useExternalScript = (url: string) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.setAttribute('src', url);
    script.setAttribute('type', 'module');
    script.setAttribute('async', 'true');
    head?.appendChild(script);
  }, [url]);
};
