import { LoadingButtonProps } from '@mui/lab';
import { DialogProps as MUIDialogProps, ModalProps } from '@mui/material';

export enum ActionOrder {
  cancel = 1,
  unset = 2,
  confirm = 3,
}

export interface ActionButtonProps extends LoadingButtonProps {
  label?: string;
  actionType?: 'confirm' | 'cancel';
  order?: ActionOrder;
}

export interface DialogProps extends Omit<MUIDialogProps, 'open'> {
  open?: ModalProps['open'];
  onClose?: () => void;
  width?: number | 'default' | 'small' | 'medium' | 'large'; // Width presets from design
  height?: number | string; // Height in px or %
  title?: string;
  titleIcon?: string;
  customTitle?: React.ReactNode;
  bodyContent?: React.ReactNode;
  actions?: ActionButtonProps[];
  clickAwayToClose?: boolean;
  variant?: 'scrollable';
  leftActionComponent?: React.ReactNode;
  titleAndActionsDivider?: boolean;
  attachContent?: boolean;
}
