import React from 'react';
import { colors } from 'shared/theme';

export const ArrowRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill, height, width }) => (
  <svg width={width} height={height} viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.1716 0.46447L15.3536 3.64645C15.5488 3.84171 15.5488 4.1583 15.3536 4.35356L12.1716 7.53554C11.9763 7.7308 11.6597 7.7308 11.4645 7.53554C11.2692 7.34028 11.2692 7.02369 11.4645 6.82843L13.7929 4.5H0V3.5H13.7929L11.4645 1.17158C11.2692 0.976315 11.2692 0.659733 11.4645 0.46447C11.6597 0.269208 11.9763 0.269208 12.1716 0.46447Z'
      fill={fill}
    />
  </svg>
);

ArrowRightIcon.defaultProps = {
  fill: colors.rollingStone,
  height: 16,
  width: 8,
};
