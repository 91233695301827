import { Box, useTheme } from '@mui/material';

import { PaginationV2 } from './pagination-v2/pagination.component';
import { TableV2 } from './table-v2/table.component';
import { GenericDataType, TableWithPaginationProps } from './table-with-pagination.component.props';

export const TableWithPaginationV2 = <T extends GenericDataType>({
  className,
  currentPage,
  errorStateComponent,
  isError,
  isLoading,
  onPageChange,
  pagination,
  searchFilters,
  skeletonComponent,
  table: tableData,
}: TableWithPaginationProps<T>) => {
  const { customLabel, itemsLabel } = pagination;
  const { data, ...table } = tableData;

  const theme = useTheme();

  const getPaginationLabel = () => {
    if (customLabel) {
      return customLabel;
    }

    return {
      from: data?.from || 0,
      itemsLabel,
      to: data?.to || 0,
      totalItems: data?.total || 0,
    };
  };

  if (isLoading) {
    return skeletonComponent;
  }
  if (isError) {
    return errorStateComponent;
  }

  return (
    <Box className={className}>
      <TableV2
        {...table}
        data={data?.items || []}
        sortBy={searchFilters?.sortBy}
        sortOrder={searchFilters?.sortOrder}
        sx={{ marginBottom: theme.spacing(3) }}
      />
      <PaginationV2
        {...pagination}
        page={currentPage}
        label={getPaginationLabel()}
        count={data?.numberOfPages}
        onPageChanged={onPageChange}
      />
    </Box>
  );
};

export default TableWithPaginationV2;
