import { FC } from 'react';

export const EditIcon: FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.6667 4.32666C13.6672 4.23892 13.6503 4.15194 13.6172 4.07072C13.584 3.98949 13.5351 3.91562 13.4733 3.85332L10.6467 1.02666C10.5844 0.96487 10.5105 0.915986 10.4293 0.88281C10.348 0.849634 10.2611 0.832817 10.1733 0.833324C10.0856 0.832817 9.99861 0.849634 9.91739 0.88281C9.83616 0.915986 9.76228 0.96487 9.69999 1.02666L7.81332 2.91332L0.526657 10.2C0.46487 10.2623 0.415986 10.3362 0.38281 10.4174C0.349634 10.4986 0.332817 10.5856 0.333324 10.6733V13.5C0.333324 13.6768 0.403562 13.8464 0.528586 13.9714C0.65361 14.0964 0.82318 14.1667 0.999991 14.1667H3.82666C3.91994 14.1717 4.01325 14.1571 4.10054 14.1238C4.18782 14.0905 4.26713 14.0393 4.33332 13.9733L11.58 6.68666L13.4733 4.83332C13.5342 4.76871 13.5838 4.69435 13.62 4.61332C13.6264 4.56018 13.6264 4.50646 13.62 4.45332C13.6231 4.42229 13.6231 4.39102 13.62 4.35999L13.6667 4.32666ZM3.55332 12.8333H1.66666V10.9467L8.28666 4.32666L10.1733 6.21332L3.55332 12.8333ZM11.1133 5.27332L9.22666 3.38666L10.1733 2.44666L12.0533 4.32666L11.1133 5.27332Z'
      fill={fill}
    />
  </svg>
);
