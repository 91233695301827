import { Box, styled, TextField } from '@mui/material';
import { shadows } from 'asteroids';

export const CurrencySelectInputWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .currency-select-button': {
    width: '100%',
    height: theme.spacing(4.775),
    lineHeight: '1rem',
  },
  '& .option': {
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
    alignItems: 'flex-start',
    minHeight: 'auto',
    padding: theme.spacing(1),
  },
  '& .paper': {
    boxShadow: 'none',
    margin: 0,
  },
  '& .popper': {
    '& .MuiAutocomplete-listbox': {
      overflowY: 'hidden !important',
    },
    '& > .MuiAutocomplete-popper': {
      transform: 'none !important',
      position: 'relative !important',
    },
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    boxShadow: shadows.card,
    position: 'absolute',
    width: '100%',
    zIndex: 10000,
  },
  '& .popperDisablePortal': {
    position: 'relative',
  },
  '& .root': {
    '& .is-danger': {
      border: `1px solid ${theme.palette.error}`,
    },
    width: '100%',
  },
}));

export const LabeledTextField = styled(TextField)(({ theme }) => ({
  caretColor: 'transparent',
  width: theme.spacing(9),
  right: theme.spacing(1),
  marginBottom: theme.spacing(0.2),
  label: {
    textTransform: 'none',
    left: theme.spacing(-1),
  },
  input: {
    padding: theme.spacing(1, 0.8),
  },
  fieldset: {
    border: 'none',
  },
}));
