import { Box, Typography } from '@mui/material';
import { Alert, Autocomplete, AutocompleteOption, InputField } from 'asteroids';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { getRequestDetails } from 'shared/logic/requests.logic';
import { RequestDetails } from 'shared/models/request-workflow.model';
import { useAppSelector } from 'shared/store/hooks';
import { RequestType } from 'shared/store/views/benchmarks/benchmark-request.slice';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { DrawerWorkflowInitiativeProps } from '.';
import { RenewalForm, RenewalMultiToolForm, ToolRequestForm, ToolRequestMultiToolForm } from './components/forms';

export const DrawerWorkflowInitiative: FC<DrawerWorkflowInitiativeProps> = ({ showNotification, toggleDrawer }) => {
  const { t } = useTranslation();
  const { hash, pathname } = useLocation();

  const hasBenchmarkResults = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.hasResults);
  const benchmarkRequestType = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.benchmarkRequestType);

  const translations = useMemo(
    () => ({
      createTitle: t('requests_view:modify_initiative_drawer.right_side.titles.create'),
      editTitle: t('requests_view:modify_initiative_drawer.right_side.titles.edit'),
      initiativeName: t('requests_view:modify_initiative_drawer.right_side.initiative_name.label'),
      initiativeNameErrorMessage: t('requests_view:modify_initiative_drawer.right_side.initiative_name.error_message'),
      initiativeNamePlaceholder: t('requests_view:modify_initiative_drawer.right_side.initiative_name.placeholder'),
      multiToolPurchaseLabel: t(
        'requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.multi_tool_purchase_label'
      ),
      renewalLabel: t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.renewal_label'),
      title: t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.title'),
      toolRequestLabel: t(
        'requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.tool_request_label'
      ),
    }),
    [t]
  );

  const requestId = hash.includes(DRAWER_VARIANT.EDIT_REQUEST)
    ? hash.split(DRAWER_VARIANT.EDIT_REQUEST).slice(-1)[0]
    : '';

  const isEditMode = !!hash.includes(DRAWER_VARIANT.EDIT_REQUEST);
  const isNewRequestMode = !!hash.includes(DRAWER_VARIANT.NEW_REQUEST);

  const options: AutocompleteOption[] = useMemo(() => {
    const mainPath = `/${pathname.split('/')[1]}`;
    const routesWithToolRequestEnabled = [
      '/requests',
      '/tool-store',
      '/pricing-benchmarks',
      hash.includes('#newRequest') && hash === '#newRequest' && NAVIGATION_URLS.OVERVIEW,
    ];
    const routesWithRenewalEnabled = [
      '/requests',
      NAVIGATION_URLS.SUBSCRIPTIONS,
      '/pricing-benchmarks',
      hash.includes('#newRequest') && hash !== '#newRequest' && NAVIGATION_URLS.OVERVIEW,
    ];

    return [
      ...(routesWithToolRequestEnabled.includes(mainPath)
        ? [
            {
              id: 'tool-request',
              label: translations.multiToolPurchaseLabel,
            },
          ]
        : []),
      ...(routesWithRenewalEnabled.includes(mainPath) ? [{ id: 'renewal', label: translations.renewalLabel }] : []),
    ];
  }, [pathname, translations, hash]);

  const [initialData, setInitialData] = useState<RequestDetails>();
  const [initiativeName, setInitiativeName] = useState<string>('');
  const [initiativeNameError, setInitiativeNameError] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<AutocompleteOption>(
    benchmarkRequestType === RequestType.RENEWAL ? options[1] : options[0]
  );

  useQuery(['workflow-request-details', requestId], () => getRequestDetails(requestId), {
    enabled: isEditMode,
    onSuccess: (data) => {
      setInitialData(data);
    },
    refetchOnWindowFocus: false,
  });

  const renderInitiativeNameField = useMemo(
    () => (
      <Box display='flex' flexDirection='column' width='100%' data-testid='initiative-name'>
        <Typography variant='h3' mb={1.5}>
          {translations.initiativeName}*
        </Typography>
        <InputField
          id='initiativeName'
          value={initiativeName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            setInitiativeName(value);
            setInitiativeNameError(!value);
          }}
          onClearValue={() => setInitiativeName('')}
          label={translations.initiativeNamePlaceholder}
          helperText={initiativeNameError ? translations.initiativeNameErrorMessage : ''}
          error={initiativeNameError}
          size='small'
        />
      </Box>
    ),
    [initiativeName, translations, initiativeNameError]
  );

  const renderRequestTypeDropdown = useMemo(
    () => (
      <Box display='flex' flexDirection='column' width='100%' data-testid='initiative-type'>
        <Typography variant='h3' mb={1.5}>
          {translations.title}
        </Typography>
        <Autocomplete
          id='initiative-type'
          disablePortal
          disableClearable
          label=''
          disabled={options.length === 1}
          fullWidth
          value={requestType}
          options={options}
          onChange={(_: unknown, value: unknown) => value && setRequestType(value as AutocompleteOption)}
          size='small'
        />
      </Box>
    ),
    [options, requestType, translations.title]
  );

  const renderNewRequest = useMemo(
    () => (
      <>
        {!hasBenchmarkResults && (
          <Alert
            sx={{ width: '100%' }}
            description={t('requests_view:involve_sastrify_alert:description')}
            severity='info'
            title={t('requests_view:involve_sastrify_alert:title')}
          />
        )}
        {hasBenchmarkResults && (
          <Alert
            description={t('requests_view:benchmarks_alert:description')}
            severity='info'
            title={t('requests_view:benchmarks_alert:title')}
          />
        )}
        {renderInitiativeNameField}
        {renderRequestTypeDropdown}
        {requestType?.id === 'tool-request' && (
          <ToolRequestMultiToolForm
            mode='create'
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            initiativeName={initiativeName}
            onEmptyInitiativeName={() => setInitiativeNameError(true)}
          />
        )}
        {requestType?.id === 'renewal' && (
          <RenewalMultiToolForm
            mode='create'
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            initiativeName={initiativeName}
            onEmptyInitiativeName={() => setInitiativeNameError(true)}
          />
        )}
      </>
    ),
    [
      renderInitiativeNameField,
      renderRequestTypeDropdown,
      requestType?.id,
      showNotification,
      toggleDrawer,
      initiativeName,
      hasBenchmarkResults,
      t,
    ]
  );

  const renderEditOldRequest = useMemo(
    () => (
      <>
        {initialData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL && (
          <ToolRequestForm
            mode='edit'
            initialData={initialData}
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
          />
        )}
        {initialData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL && (
          <RenewalForm
            mode='edit'
            initialData={initialData}
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
          />
        )}
      </>
    ),
    [initialData, showNotification, toggleDrawer]
  );

  const renderEditMultiToolRequest = useMemo(
    () => (
      <>
        {initialData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.APPROVAL && (
          <ToolRequestMultiToolForm
            mode='edit'
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            initiativeName={initiativeName}
            initialData={initialData}
            onEmptyInitiativeName={() => setInitiativeNameError(true)}
          />
        )}
        {initialData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL && (
          <RenewalMultiToolForm
            mode='edit'
            showNotification={showNotification}
            toggleDrawer={toggleDrawer}
            initiativeName={initiativeName}
            initialData={initialData}
            onEmptyInitiativeName={() => setInitiativeNameError(true)}
          />
        )}
      </>
    ),
    [initialData, initiativeName, showNotification, toggleDrawer]
  );

  const renderEditRequest = useMemo(
    () => <>{!initialData?.childRequests ? renderEditOldRequest : renderEditMultiToolRequest}</>,
    [renderEditOldRequest, renderEditMultiToolRequest, initialData]
  );

  return (
    <>
      <Typography variant='h2' className='request-title'>
        {isEditMode ? translations.editTitle : translations.createTitle}
      </Typography>
      {isNewRequestMode && renderNewRequest}
      {isEditMode && renderEditRequest}
    </>
  );
};
