import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { createContext } from 'react';
import { ModalType } from 'shared/common.definitions';
import { Subscription } from 'shared/models';

import { AsteroidsModalComponentProps, ModalComponentProps } from '../components';

export type MutationErrorType = {
  response: {
    data: {
      error?: string;
      data: string[];
    };
    status: number;
  };
};

export interface ModalNotificationsContextType {
  onShowAsteroidsModal(modalProps: AsteroidsModalComponentProps): void;
  onShowModal(modalProps: ModalComponentProps): void;
  onShowNotification(message: React.ReactNode, type: NotificationAlertType): void;
  onCloseAsteroidsModal(): void;
  onCloseModal(): void;
  onOpenSuccessConfirmationModal(subscription: Subscription, modalType?: ModalType, templateId?: string): void;
  processMutationError(error: MutationErrorType | { message: string }): void;
}

export const ModalAndNotificationsContext = createContext<ModalNotificationsContextType>({
  onCloseAsteroidsModal: () => {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  },
  onCloseModal: () => {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  },
  onOpenSuccessConfirmationModal: () => {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  },
  onShowAsteroidsModal: () => {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  },
  onShowModal: () => {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  },
  onShowNotification: () => {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  },
  processMutationError: () => {
    throw new Error('Using ModalAndNotificationsContext without a provider');
  },
} as ModalNotificationsContextType);
