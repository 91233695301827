import { format } from 'date-fns';

export function formatDate(date?: Date | string) {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return format(date, 'd MMM yyyy');
}

export function formatDateWithoutTimeZone(date?: Date | string, dateFormat = 'd MMM yyyy') {
  if (!date) return '';

  if (typeof date === 'string') {
    const isoStringDate = new Date(date).toISOString();
    const [isoDate] = isoStringDate.split('T');
    const [year, month, day] = isoDate.split('-');

    date = new Date(Number(year), Number(month) - 1, Number(day));
  }

  return format(date, dateFormat);
}

export function getOffsetDateISOString(renewalDate?: Date) {
  if (!renewalDate) return;

  const current = new Date();
  const timezoneOffset = current.getTimezoneOffset() / 60;
  const offset = timezoneOffset > 0 ? timezoneOffset : 0;

  renewalDate.setHours(renewalDate.getHours() + offset);

  return renewalDate.toISOString();
}
