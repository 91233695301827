import { Box, Grid, styled } from '@mui/material';

export const RequestHistoryContainer = styled(Box)(({ theme }) => ({
  '& > .MuiDivider-root': {
    borderColor: theme.palette.secondary.main,
  },
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  margin: `${theme.spacing(3)} 0`,
}));

export const RequestHistoryHeader = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

export const RequestHistoryContent = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
}));

export const RequestHistoryHeaderInfo = styled(Grid)(({ theme }) => ({
  '& > *:not(:last-child)': {
    marginRight: `${theme.spacing(2)} !important`,
  },
  '& > .MuiIcon-root': {
    color: theme.palette.primary.main,
  },
  '& > .MuiTypography-small': {
    color: theme.palette.text.secondary,
  },
  alignItems: 'center',
  display: 'flex',
}));

export const RequestHistoryHeaderActions = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));
