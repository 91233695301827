import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Icon } from 'asteroids';
import { DetailsItem } from 'components/drawer-workflow-request-details/components';
import {
  formatDetailsDate,
  formatText,
  getAmountWithCurrency,
} from 'components/drawer-workflow-request-details/components/details-item/details-item.utils';
import { AvatarGroupStyled } from 'components/drawer-workflow-request-details/components/request-details-card/request-details-card.component.styles';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { trackSlackInitiativeRedirect } from 'shared/logic/event-tracking/slack-redirect.events';
import { CustomFormComponent } from 'shared/models';
import { borders } from 'shared/theme';
import { formatDate } from 'views/overview/calendar/subscription-indicator/helpers';
import { Avatar } from 'views/requests/components/request-list/request-list.styles';

import { InitiativeDetailsProps } from './initiative-details.component.props';
import { IntakeFormDetailsWrapper, SeeMoreButtonWrapper } from './initiative-details.component.styles';

interface DetailsSegmentProps {
  title: string;
  content: React.ReactNode;
  testId: string;
  noDivider?: boolean;
  pr?: number;
  noDefaultWidth?: boolean;
}

const DetailsSegment: React.FC<DetailsSegmentProps> = ({ content, noDefaultWidth, noDivider, pr, testId, title }) => {
  return (
    <Stack
      borderRight={noDivider ? '' : borders.actionMenuBorder}
      pr={pr}
      data-testid={testId}
      width={noDefaultWidth ? 'inherit' : 175}
      alignItems='flex-start'>
      <Typography fontWeight={600} mb='0.5rem'>
        {title}
      </Typography>
      {content}
    </Stack>
  );
};

export const InitiativeDetails: React.FC<InitiativeDetailsProps> = ({
  detailsData,
  onDeleteRequest,
  onEditRequest,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { hash } = useLocation();
  const [showIntakeFormDetails, setShowIntakeFormDetails] = useState(false);

  useEffect(() => {
    if (hash.includes('source=slack')) {
      const tag = hash;
      trackSlackInitiativeRedirect({
        initiativeName: String(detailsData?.name),
        requestId: String(detailsData?.requestId),
        tag: tag.replace('#requestDetails', ''),
      });
    }
  }, [detailsData?.name, detailsData?.requestId, hash]);

  const hasIntakeFormDetails = detailsData?.customFormData?.definition && detailsData.customFormData.data;

  const getInitiativeTitle = () => {
    switch (detailsData?.requestWorkflowTypeId) {
      case REQUEST_WORKFLOW_TYPE.RENEWAL:
        return `${t('requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.renewal_label')} ${t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.initiative_details'
        )}`;
      case REQUEST_WORKFLOW_TYPE.APPROVAL:
        return `${t(
          'requests_view:modify_initiative_drawer.right_side.initiative_type_dropdown.multi_tool_purchase_label'
        )} ${t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.initiative_details'
        )}`;
      default:
        return '';
    }
  };

  const renderMainButtons = () => {
    const disabled = detailsData?.currentStep !== 1 || detailsData?.currentStepStatus !== 'pending';

    return (
      <Stack direction='row' spacing={2} alignItems='center'>
        {detailsData?.permissions?.edit && (
          <IconButton disabled={disabled} onClick={onEditRequest} data-testid='edit-button'>
            <Icon>edit</Icon>
          </IconButton>
        )}

        {detailsData?.permissions?.delete && (
          <IconButton onClick={onDeleteRequest} data-testid='delete-button'>
            <Icon>delete</Icon>
          </IconButton>
        )}
      </Stack>
    );
  };

  const renderTitle = () => (
    <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
      <Typography variant='h2' data-testid='initiative-title'>
        {getInitiativeTitle()}
      </Typography>
      {(detailsData?.permissions?.edit || detailsData?.permissions?.delete) && renderMainButtons()}
    </Stack>
  );

  const renderRequesterInfo = (secondaryText = true) => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Tooltip title={detailsData?.requesterFullName}>
        <Box>
          <Avatar alt={detailsData?.requesterFullName} src={detailsData?.requesterAvatarUrl} size='normal' />
        </Box>
      </Tooltip>
      <Stack direction='column'>
        <Typography data-testid='requester' noWrap maxWidth={120}>
          {detailsData?.requesterFullName || detailsData?.requesterEmail}
        </Typography>
        {secondaryText && (
          <Typography color='textSecondary' variant='small'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.requester_text'
            )}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  const renderAssigneeInfo = (secondaryText = true) => (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Tooltip title={detailsData?.assigneeFullName}>
        <Box>
          <Avatar alt={detailsData?.assigneeFullName} src={detailsData?.assigneeAvatarUrl} size='normal' />
        </Box>
      </Tooltip>
      <Stack direction='column'>
        <Typography noWrap maxWidth={120}>
          {detailsData?.assigneeFullName || detailsData?.assigneeEmail}
        </Typography>
        {secondaryText && (
          <Typography color='textSecondary' variant='small'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.assignee_text'
            )}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  const renderContributorsInfo = () => {
    if (detailsData?.contributors && detailsData?.contributors?.length > 0) {
      return (
        <AvatarGroupStyled max={4} sx={{ ml: theme.spacing(1) }}>
          {detailsData?.contributors?.map(({ avatar, email, id, name }, index) => (
            <Tooltip title={name || email} data-testid={`collaborator-${index}`}>
              <Box ml={theme.spacing(-1)}>
                <Avatar key={id} alt={name} src={avatar || ''} size='normal' />
              </Box>
            </Tooltip>
          ))}
        </AvatarGroupStyled>
      );
    }
    return '-';
  };

  const renderDetailsInfo = () => (
    <>
      <Stack component='div' direction='row' spacing={2} alignItems='flex-start' py={1} flexWrap='nowrap'>
        <DetailsSegment
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.owner'
          )}
          content={renderRequesterInfo(false)}
          testId='owner'
          pr={2}
        />
        <DetailsSegment
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.assignee_text'
          )}
          content={renderAssigneeInfo(false)}
          testId='assignee'
          pr={2}
        />
        <DetailsSegment
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.contributors'
          )}
          content={renderContributorsInfo()}
          testId='collaborators'
          pr={2}
        />
        <DetailsSegment
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.due_date_label'
          )}
          content={
            <Typography fontWeight={300}>
              {detailsData?.dueDate ? formatDate(detailsData?.dueDate as string) : '-'}
            </Typography>
          }
          testId='due-date'
          noDivider
          noDefaultWidth
        />
      </Stack>

      <Stack component='div' direction='row' spacing={2} alignItems='flex-start' mb={1} py={1}>
        <DetailsSegment
          title={
            detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL
              ? t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.cost_label'
                )
              : t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.budget_label'
                )
          }
          content={
            <Typography fontWeight={300}>
              {detailsData?.licenseCents && detailsData?.licenseCurrency
                ? getAmountWithCurrency(detailsData?.licenseCents, detailsData?.licenseCurrency)
                : '-'}
            </Typography>
          }
          testId='cost'
          pr={2}
        />
        <DetailsSegment
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.number_licenses_label'
          )}
          content={<Typography fontWeight={300}>{detailsData?.licenseNumber || '-'}</Typography>}
          testId='license-number'
          pr={2}
        />
        <DetailsSegment
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.payment_freq_label'
          )}
          content={<Typography fontWeight={300}>{formatText(detailsData?.licensePaymentFrequency) || '-'}</Typography>}
          testId='license-payment-frequency'
          noDefaultWidth
          noDivider
        />
      </Stack>

      {hasIntakeFormDetails ? (
        renderDescriptionWithIntakeFormDetails()
      ) : (
        <Stack component='div' direction='row' spacing={2} alignItems='flex-start' mb={2} py={1}>
          <DetailsItem
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.desc_label'
            )}
            content={<Typography fontWeight={300}>{detailsData?.description || '-'}</Typography>}
            testId='description'
          />
        </Stack>
      )}
    </>
  );

  const renderIntakeFormDetails = () => {
    const getContentText = (componentKey: string) => {
      let dataValue = detailsData?.customFormData?.data[componentKey];

      if (componentKey.includes('radio')) {
        detailsData?.customFormData?.definition.components.forEach((component: CustomFormComponent) => {
          if (component.key === componentKey && component.values) {
            dataValue = component.values.find((item) => item.value === dataValue)?.label;
          }
        });
      } else if (componentKey.includes('selectBoxes')) {
        const chosenValues = Object.keys(dataValue || {}).filter((key) => dataValue?.[key as keyof typeof dataValue]);

        dataValue = chosenValues
          .map((key) => {
            const component = detailsData?.customFormData?.definition.components.find(
              (item) => item.key === componentKey
            );
            const value = component?.values?.find((item) => item.value === key)?.label;

            return value;
          })
          .map((item, index) => (index > 0 ? `, ${item}` : item))
          .join('');
      }
      if (typeof dataValue === 'boolean') {
        dataValue = dataValue ? 'Yes' : 'No';
      }

      return dataValue || '-';
    };

    return (
      <>
        {detailsData?.customFormData?.definition.components
          .filter((component: CustomFormComponent) => !component.key.includes('content'))
          .map((component: CustomFormComponent) => (
            <DetailsItem
              title={component.label}
              content={
                <Typography fontWeight={300}>
                  {component.key !== 'datetime'
                    ? getContentText(component.key)
                    : formatDetailsDate(getContentText(component.key))}
                </Typography>
              }
            />
          ))}
      </>
    );
  };

  const renderDescriptionWithIntakeFormDetails = () => (
    <>
      <IntakeFormDetailsWrapper direction='column' spacing={2}>
        <DetailsItem
          title={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.desc_label'
          )}
          content={<Typography fontWeight={300}>{detailsData?.description || '-'}</Typography>}
          testId='description'
        />
        {showIntakeFormDetails && renderIntakeFormDetails()}
      </IntakeFormDetailsWrapper>
      <SeeMoreButtonWrapper>
        <Button
          size='small'
          variant='text'
          endIcon={<Icon>{showIntakeFormDetails ? 'expand_less' : 'expand_more'}</Icon>}
          onClick={() => (showIntakeFormDetails ? setShowIntakeFormDetails(false) : setShowIntakeFormDetails(true))}>
          {showIntakeFormDetails
            ? t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.see_less_details_btn'
              )
            : t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.details_info.see_more_details_btn'
              )}
        </Button>
      </SeeMoreButtonWrapper>
      <Divider sx={{ mt: 6 }} />
    </>
  );

  return (
    <>
      {renderTitle()}
      <Box px={1}>{renderDetailsInfo()}</Box>
    </>
  );
};
