import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WorkatoConnect } from '../../models/okta-connect.model';
import { CreateWorkatoIntegrationResponse } from '../../models/tool-usage-analytics.model';

const initialState: WorkatoConnect = {
  connected: false,
  connecting: false,
  connectionDetails: undefined,
  isLoadingIframe: true,
  startIntegrationError: false,
  startIntegrationSuccess: false,
  usageAnalyticsEnabled: false,
  workatoRecipeError: false,
};

const workatoSSOConnectSlice = createSlice({
  initialState,
  name: 'workatoSSOConnectSlice',
  reducers: {
    disconnect(state) {
      if (!state.connected) {
        return;
      }

      state.startIntegrationError = false;
      state.workatoRecipeError = false;
      state.connecting = false;
      state.connected = false;
    },
    setConnectingState(state) {
      state.connecting = true;
    },
    setConnectionDetails(state, action: PayloadAction<CreateWorkatoIntegrationResponse>) {
      state.connectionDetails = action.payload;
    },
    setConnectionState(state, action: PayloadAction<boolean>) {
      if (state.connected !== action.payload) {
        state.connected = action.payload;
        state.connecting = false;
      }
    },
    setEnableUsageAnalytics(state, action: PayloadAction<boolean>) {
      state.usageAnalyticsEnabled = action.payload;
    },
    setIsLoadingIframe(state, action: PayloadAction<boolean>) {
      state.isLoadingIframe = action.payload;
    },
    setStartIntegrationError(state, action: PayloadAction<boolean>) {
      state.workatoRecipeError = action.payload;
      state.connecting = false;
    },
    setStartIntegrationSuccess(state, action: PayloadAction<boolean>) {
      state.startIntegrationSuccess = action.payload;
    },
    setWorkatoRecipeError(state, action: PayloadAction<boolean>) {
      state.workatoRecipeError = action.payload;
      state.connecting = false;
    },
  },
});

export const {
  disconnect,
  setConnectingState,
  setConnectionDetails,
  setConnectionState,
  setEnableUsageAnalytics,
  setIsLoadingIframe,
  setStartIntegrationError,
  setStartIntegrationSuccess,
  setWorkatoRecipeError,
} = workatoSSOConnectSlice.actions;

export default workatoSSOConnectSlice.reducer;
