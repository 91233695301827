import { Box } from '@mui/material';
import { backgrounds, Icon, NavigationItemType } from 'asteroids';
import { PRICING_PLANS_FEATURES } from 'libs/constants/pricing-plans-features.constants';
import { PERMISSION } from 'libs/enums';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { featureFlags, SUBSCRIPTIONS_TEXT, UserRoles } from 'shared/common.definitions';
import { usePlanEnabledFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-enabled-features.hook';
import { usePlanPurchasableFeatures } from 'shared/helpers/plan-features-statuses-hooks/use-plan-purchasable-features.hook';
import { SastrifyStore } from 'shared/models';
import { useCheckUserPermission } from 'src/app/hooks';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { NAVIGATION_URLS } from 'src/constants/navigation';

import { SastrifyLogoIcon } from '../../icons/sastrify-logo.icon';

export const useNavigationMainItems = () => {
  const { t } = useTranslation();
  const { search } = window.location;

  const user = useSelector((state: SastrifyStore) => state.authentication.user);

  // Feature flags
  const isInAppBenchmarkingEnabled = useCompanyFeatureFlag(featureFlags.IN_APP_BENCHMARKING);
  const disableSpendReview = useCompanyFeatureFlag(featureFlags.DISABLE_SPEND_REVIEW);
  const isProcurementSupportEnabled = !useCompanyFeatureFlag(featureFlags.PROCUREMENT_SUPPORT_EXCLUDED);
  const hrIntegrationsEnabled = useCompanyFeatureFlag(featureFlags.HR_INTEGRATIONS);
  const isUserAndCompanyDataEnabled = useCompanyFeatureFlag(featureFlags.USER_AND_COMPANY_DATA);
  const isToolMatchingEnabled = useCompanyFeatureFlag(featureFlags.TOOL_MATCHING);

  // Pricing plan features
  const { isFeatureEnabled } = usePlanEnabledFeatures();
  const isToolStoreBundleEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);
  const isSastriCloudFeatureEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.SASTRICLOUD);
  const isVendorInsightsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);
  const isUsageAnalyticsEnabled = isFeatureEnabled(PRICING_PLANS_FEATURES.USAGE_ANALYTICS);
  const { isFeaturePurchasable } = usePlanPurchasableFeatures();
  const isToolStoreBundlePurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.TOOL_STORE_BUNDLE);
  const isSastriCloudFeaturePurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.SASTRICLOUD);
  const isVendorInsightsPurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.VENDOR_INSIGHTS);
  const isUsageAnalyticsPurchasable = isFeaturePurchasable(PRICING_PLANS_FEATURES.USAGE_ANALYTICS);

  // User roles and permissions
  const canViewUserAndCompanyData = useCheckUserPermission(PERMISSION.VIEW_HRIS_DATA);
  const isAdminUser = useMemo(() => user?.role === UserRoles.Admin, [user?.role]);
  const isViewerUser = useMemo(() => user?.role === UserRoles.Viewer, [user?.role]);

  const premiumFeatureBadge = useMemo(
    () => (
      <Icon data-testid='premium-feature-badge' variant='Filled' size='inherit' color='text'>
        star
      </Icon>
    ),
    []
  );

  // Navigation items
  const dashboardEntry = useMemo(
    () =>
      !isViewerUser && {
        iconName: 'dashboard',
        key: 'overview',
        name: t('navigation:navItems.overview'),
        url: NAVIGATION_URLS.OVERVIEW,
      },
    [isViewerUser, t]
  );

  const toolStackEntry = useMemo(() => {
    const items = [
      {
        badge: true,
        getNavigationState: () => {
          const params = new URLSearchParams(search);
          const searchQuery = params.get('q');
          if (searchQuery) {
            return SUBSCRIPTIONS_TEXT.toLowerCase();
          }
        },
        key: 'tools',
        name: t('navigation:navItems.tools'),
        url: NAVIGATION_URLS.SUBSCRIPTIONS,
      } as NavigationItemType,
      ...(isToolMatchingEnabled && isAdminUser
        ? [
            {
              badge: true,
              key: 'tool_matching',
              name: t('navigation:navItems.tool_matching'),
              url: NAVIGATION_URLS.TOOL_MATCHING,
            } as NavigationItemType,
          ]
        : []),
    ];

    if (disableSpendReview) {
      return (
        !isViewerUser && {
          expanded: true,
          iconName: 'layers',
          items: items.filter((item) => !!item),
          key: 'tool_stack',
          name: t('navigation:navItems.tool_stack'),
        }
      );
    }

    return (
      !isViewerUser && {
        expanded: true,
        iconName: 'layers',
        items: [
          ...items,
          {
            badge: true,
            key: 'spend_import',
            name: t('navigation:navItems.spend_import'),
            url: '/spend-import',
          },
        ].filter((item) => !!item),
        key: 'tool_stack',
        name: t('navigation:navItems.tool_stack'),
      }
    );
  }, [disableSpendReview, isViewerUser, search, t, isToolMatchingEnabled, isAdminUser]);

  const initiativesEntry = useMemo(
    () =>
      isToolStoreBundleEnabled &&
      ({
        iconName: 'rocket_launch',
        key: 'request',
        name: t('navigation:navItems.initiatives'),
        url: '/requests',
      } as NavigationItemType),
    [isToolStoreBundleEnabled, t]
  );

  const toolStoreEntry = useMemo(
    () =>
      (isToolStoreBundleEnabled || isToolStoreBundlePurchasable) && {
        badge: isToolStoreBundlePurchasable && premiumFeatureBadge,
        customBadgeColor: isToolStoreBundlePurchasable && backgrounds.successToWarningGradient,
        iconName: 'search',
        key: 'tool_store',
        name: t('navigation:navItems.tool_store'),
        url: '/tool-store',
      },
    [isToolStoreBundleEnabled, isToolStoreBundlePurchasable, premiumFeatureBadge, t]
  );

  const optimizationEntry = useMemo(
    () =>
      !isViewerUser && {
        iconName: 'auto_graph',
        items: [
          (isVendorInsightsEnabled || isVendorInsightsPurchasable) &&
            isInAppBenchmarkingEnabled &&
            ({
              badge: isVendorInsightsPurchasable ? premiumFeatureBadge : t('benchmarks:navigation_badge_text'),
              badgeColor: isVendorInsightsPurchasable ? undefined : 'info',
              customBadgeColor: isVendorInsightsPurchasable && backgrounds.successToWarningGradient,
              key: 'benchmarks',
              name: t('navigation:navItems.benchmarks'),
              url: '/pricing-benchmarks',
            } as NavigationItemType),

          {
            badge: true,
            key: 'savings',
            name: t('navigation:navItems.savings'),
            url: '/savings',
          },
          (isSastriCloudFeatureEnabled || isSastriCloudFeaturePurchasable) && {
            badge: isSastriCloudFeaturePurchasable && premiumFeatureBadge,
            customBadgeColor: isSastriCloudFeaturePurchasable && backgrounds.successToWarningGradient,
            key: 'sastricloud',
            name: t('navigation:navItems.cloud_optimization'),
            url: '/sastricloud',
          },
        ].filter((item) => !!item) as NavigationItemType[],
        key: 'optimization',
        name: t('navigation:navItems.optimization'),
      },
    [
      isViewerUser,
      isVendorInsightsPurchasable,
      isVendorInsightsEnabled,
      isInAppBenchmarkingEnabled,
      premiumFeatureBadge,
      t,
      isSastriCloudFeatureEnabled,
      isSastriCloudFeaturePurchasable,
    ]
  );

  const userAndCompanyDataEntry = useMemo(
    () =>
      canViewUserAndCompanyData &&
      isUserAndCompanyDataEnabled && {
        iconName: 'folder_shared',
        key: 'user_and_company_data',
        name: t('navigation:navItems.user_and_company_data'),
        url: NAVIGATION_URLS.USER_AND_COMPANY_DATA,
      },
    [canViewUserAndCompanyData, isUserAndCompanyDataEnabled, t]
  );

  const supportEntry = useMemo(
    () =>
      !isViewerUser && {
        iconName: 'quiz',
        key: 'support',
        name: t('navigation:navItems.request_support'),
        url: '/sastrify-support',
      },
    [isViewerUser, t]
  );

  const procurementSupportEntry = useMemo(
    () => ({
      iconName: (
        <Box component='span' display='flex'>
          <SastrifyLogoIcon width='22' height='24' />
        </Box>
      ),
      key: 'procurement-support',
      name: t('navigation:navItems.procurement_support'),
      url: '/procurement-support',
    }),
    [t]
  );

  const navSettingsList = useMemo(
    () =>
      ({
        iconName: 'settings',
        items: [
          isAdminUser && {
            key: 'user_management',
            name: t('navigation:navItems.users_and_authentication'),
            url: NAVIGATION_URLS.USERS_MANAGEMENT,
          },
          canViewUserAndCompanyData &&
            hrIntegrationsEnabled && {
              key: 'company_data',
              name: t('navigation:navItems.company_data'),
              url: NAVIGATION_URLS.COMPANY_DATA,
            },
          isToolStoreBundleEnabled &&
            isAdminUser && {
              key: 'request-workflows',
              name: t('navigation:navItems.workflows'),
              url: '/request-workflows',
            },
          isToolStoreBundleEnabled &&
            isAdminUser && {
              key: 'workflow',
              name: t('navigation:navItems.task_automation'),
              url: '/workflows',
            },
          {
            key: 'notification-settings',
            name: t('navigation:navItems.notifications'),
            url: '/notification-settings',
          },
          (isUsageAnalyticsEnabled || isUsageAnalyticsPurchasable) &&
            !isViewerUser && {
              badge: isUsageAnalyticsPurchasable && premiumFeatureBadge,
              customBadgeColor: isUsageAnalyticsPurchasable && backgrounds.successToWarningGradient,
              key: 'usage-analytics',
              name: t('navigation:navItems.usage_analytics'),
              url: '/usage-analytics',
            },
          !isViewerUser && {
            key: 'discovery',
            name: t('navigation:navItems.discovery'),
            url: '/discovery',
          },
          !isViewerUser &&
            hrIntegrationsEnabled && {
              key: 'hris',
              name: t('navigation:navItems.hris'),
              url: '/hris',
            },
        ].filter((item) => !!item) as NavigationItemType[],
        key: 'settings',
        name: t('navigation:navItems.settings'),
      } as NavigationItemType),
    [
      isAdminUser,
      t,
      canViewUserAndCompanyData,
      hrIntegrationsEnabled,
      isToolStoreBundleEnabled,
      isUsageAnalyticsEnabled,
      isUsageAnalyticsPurchasable,
      isViewerUser,
      premiumFeatureBadge,
    ]
  );

  // Builds the entires based on the user role
  const entries = useMemo(
    () =>
      [
        dashboardEntry,
        toolStackEntry,
        initiativesEntry,
        ...(isProcurementSupportEnabled ? [procurementSupportEntry] : [supportEntry]),
        toolStoreEntry,
        optimizationEntry,
        userAndCompanyDataEntry,
        navSettingsList,
      ].filter((item) => !!item) as NavigationItemType[],
    [
      dashboardEntry,
      initiativesEntry,
      isProcurementSupportEnabled,
      navSettingsList,
      optimizationEntry,
      userAndCompanyDataEntry,
      procurementSupportEntry,
      supportEntry,
      toolStackEntry,
      toolStoreEntry,
    ]
  );

  return entries;
};
