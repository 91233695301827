import { EventTracking } from 'shared/services';

import { trackEventName } from '../../common.definitions';
import { RenewalAction } from '../renewal-reminders.logic';

export type RenewalActionEventType = 'InApp' | 'Email' | 'Slack';

export const trackRenewalReminderAction = (data: {
  action: RenewalAction;
  renewalReminderId: string;
  subscriptionId: string;
  source: RenewalActionEventType;
}) => {
  EventTracking.trackEvent(trackEventName.RenewalReminderAction, data);
};
