import { MutableRefObject, useEffect } from 'react';
import { useHistory } from 'react-router';

export const useRelativeNotificationsLinks = ({
  container,
  onNotificationClick,
  onNotificationReadActionClick,
}: {
  container: MutableRefObject<HTMLDivElement | undefined>;
  onNotificationClick?: (title: string, link?: string) => void;
  onNotificationReadActionClick?: (title: string) => void;
}) => {
  const history = useHistory();

  useEffect(() => {
    let notificationsPopupOpen = false;

    const markReadUnreadHandler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      // Not a user click
      if (!e.isTrusted) return;

      if (target.className?.indexOf?.('MarkRead') > -1 || target.closest('[class*="MarkRead"]')) {
        const wrapper = target.closest('[class^="actions"]')?.parentNode;
        const notificationTitle = wrapper?.querySelector('[class*="Title"]')?.textContent as string;

        wrapper?.querySelector('[class*="MessageContainer"]')?.classList.add('read');
        onNotificationReadActionClick?.(notificationTitle);
      }

      if (target.className?.indexOf?.('MarkUnread') > -1 || target.closest('[class*="MarkUnread"]')) {
        target
          .closest('[class^="actions"]')
          ?.parentNode?.querySelector('[class*="MessageContainer"]')
          ?.classList.remove('read');
      }
    };

    const markAllReadHandler = () => {
      container.current?.querySelectorAll('[class*="MessageContainer"]').forEach((messageContainer) => {
        messageContainer.classList.add('read');
      });
    };

    const linkSelector = 'a[class*="ClickableContainer"]';

    const fixReadIndicator = (wrapper?: HTMLDivElement) => {
      wrapper?.querySelectorAll('[class*="MarkUnread"]').forEach((el) => {
        el.closest('[class^="actions"]')
          ?.parentNode?.querySelector('[class*="MessageContainer"]')
          ?.classList.add('read');
      });
    };

    // Detect when the notifications popup is in the DOM
    const callback = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const addedNode = Array.from(mutation.addedNodes).find((node: Node) => {
            return (
              typeof (node as HTMLElement).closest === 'function' && (node as HTMLElement).closest('.inbox--tippy-main')
            );
          });

          if (addedNode && !notificationsPopupOpen) {
            notificationsPopupOpen = true;
            fixReadIndicator(container.current);

            container.current?.querySelectorAll(linkSelector).forEach((link) => {
              // Remove old and add new click events from the links
              if ((link as HTMLLinkElement).href) {
                const newElement = link.cloneNode(true) as HTMLLinkElement;
                const { href } = newElement;

                (newElement as HTMLLinkElement).removeAttribute('href');
                newElement.addEventListener('click', () => {
                  const notificationTitle = newElement.querySelector('[class*="Title"]')?.textContent as string;
                  const link = href.replace(/^.*?sastrify\.com/, '');

                  // Mark it as read
                  (newElement.parentNode?.querySelector('[class*="MarkRead"]') as HTMLButtonElement | null)?.click();

                  onNotificationClick?.(notificationTitle, link);
                  history.push(link);
                });

                link.parentNode?.replaceChild(newElement, link);
              }
            });

            container.current?.removeEventListener('mouseup', markReadUnreadHandler);
            container.current?.addEventListener('mouseup', markReadUnreadHandler);

            container.current
              ?.querySelector('[class*="MarkAllRead"]')
              ?.removeEventListener('click', markAllReadHandler);
            container.current?.querySelector('[class*="MarkAllRead"]')?.addEventListener('click', markAllReadHandler);
          }

          if (!addedNode && notificationsPopupOpen) {
            notificationsPopupOpen = false;
          }
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(container.current as HTMLDivElement, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [container, history, onNotificationClick, onNotificationReadActionClick]);
};
