import { Button, Typography } from '@mui/material';
import { Alert, Icon } from 'asteroids';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UsageAnalyticsInformationSection } from './usage-analytics-information-section.component';

interface SelectedProvider {
  name: string;
  clickHereUrl: string;
  requiredPlanTitle?: string;
  requiredPlanDescription?: string;
}

interface IProps {
  selectedProvider: SelectedProvider;
  usageSectionTitle: string;
  usageSectionText?: string;
  usageSectionCustomContent?: JSX.Element;
  infoCollectedSectionTitle: string;
  infoCollectedSectionText?: string;
  infoCollectedSectionCustomContent?: JSX.Element;
}

export const UsageAnalyticsInformation: FC<IProps> = (props: IProps) => {
  const {
    infoCollectedSectionCustomContent,
    infoCollectedSectionText,
    infoCollectedSectionTitle,
    selectedProvider,
    usageSectionCustomContent,
    usageSectionText,
    usageSectionTitle,
  } = props;

  const { t } = useTranslation();
  return (
    <>
      <UsageAnalyticsInformationSection
        title={usageSectionTitle}
        text={usageSectionText}
        customContent={usageSectionCustomContent}
      />
      <UsageAnalyticsInformationSection
        title={infoCollectedSectionTitle}
        text={infoCollectedSectionText}
        customContent={infoCollectedSectionCustomContent}
      />
      <UsageAnalyticsInformationSection
        title={t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_3.title')}
        customContent={
          <>
            <Typography variant='body1' color='text.primary' sx={{ display: 'block', marginBottom: 1 }}>
              {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_3.paragraph1')}
            </Typography>
            <Button
              startIcon={<Icon>account_balance</Icon>}
              variant='text'
              onClick={() => window.open(selectedProvider?.clickHereUrl)}>
              {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.section_3.button', {
                name: selectedProvider?.name,
              }).toUpperCase()}
            </Button>
          </>
        }
      />
      {(selectedProvider?.requiredPlanTitle || selectedProvider?.requiredPlanDescription) && (
        <Alert
          description={selectedProvider.requiredPlanDescription || ''}
          severity='info'
          title={selectedProvider.requiredPlanTitle || ''}
          sx={{ mb: 3, mt: 1 }}
        />
      )}
    </>
  );
};
