import { FC, lazy } from 'react';
import { Route } from 'react-router';
import { featureFlags, UserRoles } from 'shared/common.definitions';
import { RoutedProps } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';
import { NAVIGATION_URLS } from 'src/constants/navigation';

const ProcurementSupportView = lazy(() => import('../views/procurement-support/procurement-support.view'));
const ProcurementSupportDetailsView = lazy(
  () => import('../views/procurement-support-details/procurement-support-details.view')
);
const SastrifySupportView = lazy(() => import('../views/sastrify-support/sastrify-support.view'));
const ErrorView = lazy(() => import('../views/error/error.view'));

export const ProcurementSupportRoutes: FC<RoutedProps> = (props) => {
  const userRole = useAppSelector((state) => state.authentication.user?.role);
  const isViewerRole = userRole === UserRoles.Viewer;

  // Feature flags
  const isProcurementSupportEnabled = !useCompanyFeatureFlag(featureFlags.PROCUREMENT_SUPPORT_EXCLUDED);

  return (
    <>
      <Route exact path={NAVIGATION_URLS.PROCUREMENT_SUPPORT}>
        {isProcurementSupportEnabled ? <ProcurementSupportView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.PROCUREMENT_SUPPORT_DETAILS}>
        {isProcurementSupportEnabled ? <ProcurementSupportDetailsView {...props} /> : <ErrorView code={404} />}
      </Route>
      <Route exact path={NAVIGATION_URLS.SASTRIFY_SUPPORT}>
        {!isViewerRole && !isProcurementSupportEnabled ? <SastrifySupportView {...props} /> : <ErrorView code={404} />}
      </Route>
    </>
  );
};
