import { alpha } from '@mui/system';

import { colors } from './color.styles';

export const shadows = {
  outline: `0px 0px 0px 1px ${colors.secondary.main}`,
  buttonHover: '0px 4px 10px 1px rgba(0, 0, 0, 0.1)' /* Hover states of contained buttons */,
  card: `0px 1px 4px -1px ${alpha(colors.info.dark, 0.2)}` /* CARD (Default) */,
  cardHover: '0px 4px 10px 1px rgba(31, 30, 78, 0.1)' /* Any clickable cards, on hover */,
  dialog:
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)' /* Modals, used over Backdrop Overlay */,
  list: '0px 4px 5px 2px rgba(31, 30, 78, .2)' /* Dropdowns triggered from button groups or input fields */,
  switch:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)' /* Switch component */,
};
