import { OpenInNew } from '@mui/icons-material';
import { Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { colors } from 'shared/theme';

type IProps = {
  href: string;
  text: string;
  withIcon?: boolean;
};

export const Link: FC<IProps> = ({ href, text, withIcon }) => {
  return (
    <Typography variant='body1'>
      <MuiLink
        sx={{
          '&:hover': { color: `${colors.newPrimary} !important`, textDecoration: 'underline !important' },
          color: colors.newPrimary,
        }}
        href={href}
        underline='hover'
        target='_blank'
        rel='noopener'>
        {text}
      </MuiLink>
      {withIcon && <OpenInNew sx={{ color: colors.newPrimary, fontSize: '14px' }} />}
    </Typography>
  );
};
