import { Box, ButtonProps, Typography } from '@mui/material';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { LoadingButton } from 'src/asteroids';

import { AdditionalButtonProps, ArticleSmallProps } from './article-small.props';

const PrimaryButton: FC<ButtonProps & AdditionalButtonProps> = ({ dataTestId, ...props }) => (
  <LoadingButton variant='contained' color='primary' data-testid={dataTestId ?? 'article-small-primary-btn'} {...props}>
    {props?.children}
  </LoadingButton>
);

const SecondaryButton: FC<ButtonProps & AdditionalButtonProps> = ({ dataTestId, ...props }) => (
  <LoadingButton variant='text' color='secondary' data-testid={dataTestId ?? 'article-small-secondary-btn'} {...props}>
    {props?.children}
  </LoadingButton>
);

const getContainerStyles = (variant: 'small' | 'large') => ({
  paddingY: variant === 'small' ? 0 : 3,
});

const getContentStyles = (variant: 'small' | 'large') => ({
  marginX: variant === 'small' ? 6 : 0,
  marginY: variant === 'small' ? 0 : 5,
  p: variant === 'small' ? 2 : 0,
});

export const ArticleSmall: FC<ArticleSmallProps> = ({
  dataTestId = 'article-small',
  description,
  image,
  primaryButton,
  secondaryButton,
  size = 'large',
  sx,
  title,
  titleVariant = 'h3',
}) => {
  const { dataTestId: primaryButtonLinkDataTestId, ...primaryButtonLinkProps } = primaryButton?.link || {};
  const { dataTestId: secondaryButtonLinkDataTestId, ...secondaryButtonLinkProps } = secondaryButton?.link || {};

  const buttonSize = size === 'small' ? 'small' : 'normal';
  const imageMaxHeight = size === 'small' ? '102px' : '340px';
  const containerBox = getContainerStyles(size);
  const contentBox = getContentStyles(size);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        marginX: 'auto',
        maxWidth: '650px',
        ...containerBox,
        ...sx,
      }}
      data-testid={dataTestId}>
      <Box
        sx={{
          '& > img': {
            display: 'block',
            height: image.height ?? 'auto',
            maxHeight: imageMaxHeight,
          },
        }}>
        <img src={image.src} alt={image.alt} data-testid='article-small-image' />
      </Box>
      <Box
        sx={{
          ...contentBox,
          '.article-small-title, .article-small-description': {
            textWrap: 'balance',
          },
          textAlign: 'center',
        }}>
        <Typography
          color='text.primary'
          variant={titleVariant}
          sx={{ marginBottom: 1 }}
          data-testid='article-small-title'>
          <div className='article-small-title' dangerouslySetInnerHTML={{ __html: title }} />
        </Typography>
        {description &&
          (typeof description === 'string' ? (
            <Typography variant='body1' data-testid='article-small-description'>
              <div className='article-small-description' dangerouslySetInnerHTML={{ __html: description }} />
            </Typography>
          ) : (
            description
          ))}
      </Box>
      {primaryButton?.children &&
        (primaryButton.link ? (
          <Link {...(primaryButtonLinkProps as LinkProps)} data-testid={primaryButtonLinkDataTestId}>
            <PrimaryButton {...primaryButton} size={primaryButton.size ?? buttonSize} />
          </Link>
        ) : (
          <PrimaryButton {...primaryButton} size={primaryButton.size ?? buttonSize} />
        ))}
      {secondaryButton?.children &&
        (secondaryButton.link ? (
          <Link {...(secondaryButtonLinkProps as LinkProps)} data-testid={secondaryButtonLinkDataTestId}>
            <SecondaryButton
              {...secondaryButton}
              size={secondaryButton.size ?? buttonSize}
              sx={{ ...(primaryButton ? { marginTop: 1 } : {}) }}
            />
          </Link>
        ) : (
          <SecondaryButton
            {...secondaryButton}
            size={secondaryButton.size ?? buttonSize}
            sx={{ ...(primaryButton ? { marginTop: 1 } : {}) }}
          />
        ))}
    </Box>
  );
};
