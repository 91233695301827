/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Alert, Autocomplete, AutocompleteOption } from 'asteroids';
import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { format } from 'date-fns';
import { RequestSupportTicketDto } from 'libs/dtos';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { DocumentCategory, DRAWER_VARIANT } from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { trackSastrifyInvolvementCreated } from 'shared/logic/event-tracking/workflow-requests.events';
import { createSastrifySupportTicket } from 'shared/logic/requests.logic';
import { getCompanyDocumentCategory, uploadSubscriptionDocument } from 'shared/logic/subscription-item.logic';
import { Company, CompanyDocument, CreateSastrifySupportTicketPayload, SubscriptionDocument } from 'shared/models';
import { AppUrl } from 'src/constants/appurl';
import { useAddRequestDrawer, useWorkflowRequestDetailsData } from 'views/requests/hooks';

import { NegotiationSupportFormComponent } from './components/negotiation-support-form/negotiation-support-form.component';
import { RequestBenchmarkFormComponent } from './components/request-benchmark-form/request-benchmark-form.component';
import { RequestRenewalAndNegotiationSupportFormComponent } from './components/request-renewal-and-negotiation-support-form/request-renewal-and-negotiation-support-form.component';
import { RequestRenewalBenchmarkFormComponent } from './components/request-renewal-benchmark-form/request-renewal-benchmark-form.component';
import { RequestRenewalOtherFormComponent } from './components/request-renewal-other-form/request-renewal-other-form.component';
import { ContactSastrifyFormProps } from './contact-sastrify-form.component.props';
import {
  ContactSastrifyFormFooter,
  ContactSastrifyFormHeader,
  ContactSastrifyFormScrollableContent,
  HeaderCloseIcon,
} from './contact-sastrify-form.component.styles';
import {
  CLOSE_ICON,
  pathToTranslation,
  renewalRequestTypes,
  requestTypes,
  requestTypesMapping,
} from './contact-sastrify-form.constants';
import { formValidationSchema } from './contact-sastrify-form.validation-schema';

export const ContactSastrifyFormComponent: React.FC<ContactSastrifyFormProps> = ({
  closeDialog,
  closeDialogAndOpenDrawer,
  excludedTypes,
  parentRequestId,
  requestId,
  showNotification,
  subscriptionId,
  vendorName,
  workflowTypeId,
}) => {
  const { t } = useTranslation();
  const formRef = useRef<any>(null);
  const { data: requestDetails } = useWorkflowRequestDetailsData(requestId);

  const [isDocumentsUploadInProgress, setIsDocumentsUploadInProgress] = useState(false);
  const {
    control,
    formState: { errors, isValid },
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      requestType: '',
    },
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });
  const requestType = watch('requestType');
  const { onAddRequestDrawer } = useAddRequestDrawer();
  const queryClient = useQueryClient();

  const isRenewalInitiative = REQUEST_WORKFLOW_TYPE.RENEWAL === workflowTypeId;

  const company = queryClient.getQueryData<Company>('company');

  const { isLoading: isCreatingSupportTicket, mutate: createSupportTicket } = useMutation(
    (payload: CreateSastrifySupportTicketPayload) => createSastrifySupportTicket(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Create Sastrify support ticket error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: (data: RequestSupportTicketDto) => {
        if (data.requestId && data.id && closeDialog) {
          onAddRequestDrawer(
            `?supportTicketId=${data.id}#${DRAWER_VARIANT.REQUEST_DETAILS}${parentRequestId || requestId}`
          );
          closeDialog();
          trackSastrifyInvolvementCreated({
            companyId: company?.id,
            companyName: company?.name,
            requestType: isRenewalInitiative ? 'renewal' : 'newPurchase',
            toolName: vendorName,
          });
        }
      },
    }
  );

  const { mutateAsync: uploadDocumentAsync } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      setIsDocumentsUploadInProgress(false);
    },
  });

  const newPurchaseInvolvementTypes = useMemo(() => {
    if (excludedTypes && excludedTypes.length > 0) {
      return requestTypes.filter((type) => !excludedTypes.includes(type.label));
    }
    return requestTypes;
  }, [excludedTypes]);

  const renewalInvolvementTypes = useMemo(() => {
    if (excludedTypes && excludedTypes.length > 0) {
      return renewalRequestTypes.filter((type) => !excludedTypes.includes(type.label));
    }
    return renewalRequestTypes;
  }, [excludedTypes]);

  useEffect(() => {
    setValue(
      'requestType',
      isRenewalInitiative ? renewalInvolvementTypes[0].label : newPurchaseInvolvementTypes[0].label
    );
  }, [isRenewalInitiative, newPurchaseInvolvementTypes, renewalInvolvementTypes, setValue]);

  const handleDocumentsUpload = async (files: File[]): Promise<CompanyDocument[]> => {
    setIsDocumentsUploadInProgress(true);

    const uploads: unknown[] = [];
    const category = getCompanyDocumentCategory(DocumentCategory.other_documents);
    const companyId = String(company?.id);

    files.forEach((file: File) => {
      const payload = {
        category,
        companyId,
        file,
        isCompanyDocument: true,
      };

      uploads.push(uploadDocumentAsync(payload));
    });

    return (await Promise.all(uploads)) as CompanyDocument[];
  };

  const handleOnSend = async () => {
    trigger();

    const isSubFormValid = await formRef.current?.trigger();

    if (isValid && isSubFormValid) {
      const formData = {
        ...formRef.current.getValues(),
        name: requestType,
      };

      if (formData.decisionDate) {
        formData.decisionDate = format(new Date(formData.decisionDate), 'yyyy-MM-dd');
      }
      if (formData.renewalDate) {
        formData.renewalDate = format(new Date(formData.renewalDate), 'yyyy-MM-dd');
      }
      if (formData.documents) {
        formData.documents = await handleDocumentsUpload(formData.documents);
      }
      if (formData.existingDocuments) {
        const existingDocumentsFormated = formData.existingDocuments.map((document: SubscriptionDocument) => ({
          category: Number(document.category),
          companyId: company?.id,
          id: document.id,
          subscriptionId: document.subscriptionId,
        }));

        formData.documents = [...formData.documents, ...existingDocumentsFormated];
        delete formData.existingDocuments;
      }

      createSupportTicket({
        form: formData,
        requestId,
        vendorName,
      } as CreateSastrifySupportTicketPayload);
    }
  };

  const renderRequestBenchmarkForm = () => {
    if (requestType && requestType === requestTypesMapping.request_benchmark && !isRenewalInitiative)
      return <RequestBenchmarkFormComponent formRef={formRef} />;
  };

  const renderNegotiationSupportForm = () => {
    if (requestType && requestType === requestTypesMapping.negotiation_support && !isRenewalInitiative)
      return <NegotiationSupportFormComponent formRef={formRef} />;
  };

  const renderRenewalBenchmarkForm = () => {
    if (requestType && requestType === requestTypesMapping.request_benchmark && isRenewalInitiative)
      return <RequestRenewalBenchmarkFormComponent formRef={formRef} />;
  };

  const renderRenewalAndNegotiationSupportForm = () => {
    if (requestType && requestType === requestTypesMapping.renewal_and_negotiation_support && isRenewalInitiative)
      return <RequestRenewalAndNegotiationSupportFormComponent formRef={formRef} subscriptionId={subscriptionId} />;
  };

  const renderRenewalOtherForm = () => {
    if (requestType && requestType === requestTypesMapping.other && isRenewalInitiative)
      return <RequestRenewalOtherFormComponent formRef={formRef} />;
  };

  return (
    <Box>
      <ContactSastrifyFormHeader>
        <Typography variant='h2' data-testid='involvements-title'>
          {t(`${pathToTranslation}.involvement_title`)}
        </Typography>
        <HeaderCloseIcon onClick={closeDialogAndOpenDrawer}>{CLOSE_ICON}</HeaderCloseIcon>
      </ContactSastrifyFormHeader>
      <ContactSastrifyFormScrollableContent component='form'>
        {isRenewalInitiative && (
          <Alert
            actionIcon=''
            description={t(`${pathToTranslation}.vendor_insights_alert.message`)}
            actionText={t(`${pathToTranslation}.vendor_insights_alert.action_text`)}
            isIconVisible
            severity='info'
            title={t(`${pathToTranslation}.vendor_insights_alert.title`)}
            sx={{ mt: 3 }}
            onAction={() =>
              window.open(
                AppUrl.getToolDetailsUrl(
                  { id: requestDetails?.subscriptionId, name: requestDetails?.name },
                  undefined,
                  'insight'
                ),
                '_blank'
              )
            }
          />
        )}
        <Grid container spacing={2} my={1}>
          <Grid item xs={12} mb={1}>
            {isRenewalInitiative && (
              <Typography component='p' variant='h3' mb={2}>
                {t(`${pathToTranslation}.form_sections.what_can_we_help_you_with_section`)}
              </Typography>
            )}
            <Typography component='p' variant='label' mb={2}>
              {t(`${pathToTranslation}.fields.type_of_request.involvement_label`)}
            </Typography>
            <Controller
              name='requestType'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    id='requestType'
                    label={t(`${pathToTranslation}.fields.type_of_request.placeholder`)}
                    value={value}
                    options={isRenewalInitiative ? renewalInvolvementTypes : newPurchaseInvolvementTypes}
                    onChange={(_, value) => onChange((value as AutocompleteOption)?.label || '')}
                    helperText={errors.requestType ? t(`${pathToTranslation}.errors.required_field`) : ''}
                    error={Boolean(errors.requestType)}
                  />
                );
              }}
            />
          </Grid>
          {renderRequestBenchmarkForm()}
          {renderNegotiationSupportForm()}
          {renderRenewalBenchmarkForm()}
          {renderRenewalAndNegotiationSupportForm()}
          {renderRenewalOtherForm()}
        </Grid>
      </ContactSastrifyFormScrollableContent>
      <ContactSastrifyFormFooter>
        <Button
          variant='text'
          color='secondary'
          sx={{ marginRight: '1rem' }}
          onClick={closeDialogAndOpenDrawer}
          data-testid='cancel-button'>
          {t(`${pathToTranslation}.actions.cancel_button_label`)}
        </Button>
        <Button
          variant='contained'
          onClick={handleOnSend}
          disabled={isCreatingSupportTicket || isDocumentsUploadInProgress}
          data-testid='send-button'>
          {isCreatingSupportTicket || isDocumentsUploadInProgress ? (
            <CircularProgress color='inherit' size={20} />
          ) : (
            t(`${pathToTranslation}.actions.send_button_label`)
          )}
        </Button>
      </ContactSastrifyFormFooter>
    </Box>
  );
};
