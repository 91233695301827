import { useEffectOnce } from 'hooks/useEffectOnce';
import useQueryString from 'hooks/useQueryString';

export function useUnfollowWhenQueryStringIsPresent(onSubscriptionUnfollow: () => void) {
  const [query] = useQueryString();

  useEffectOnce(() => {
    if (query.utm_term === 'unfollow') {
      onSubscriptionUnfollow();
    }
  });
}
