import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inProgress: false,
};

const currentViewSlice = createSlice({
  initialState,
  name: 'currentView',
  reducers: {
    resetInProgress(state) {
      state.inProgress = initialState.inProgress;
    },
    setInProgress(state) {
      state.inProgress = true;
    },
  },
});

export const { resetInProgress, setInProgress } = currentViewSlice.actions;
export default currentViewSlice.reducer;
