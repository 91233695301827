import { AuthenticationStore } from 'shared/models/authentication-store.model';

import { DefaultAuthenticationStore } from '../../default.store';
import { UserAction, UserActionTypes } from '../actions';

export const UserReducer = (state = DefaultAuthenticationStore, action: UserAction): AuthenticationStore => {
  switch (action.type) {
    case UserActionTypes.AuthenticationRequest:
      return {
        ...state,
        error: '',
        isAuthenticated: false,
        isAuthenticatedError: false,
        isLoading: true,
        user: null,
      };
    case UserActionTypes.AuthenticationComplete: {
      return {
        ...state,
        error: '',
        isAuthenticated: true,
        isAuthenticatedError: false,
        isLoading: false,
        user: action.payload?.user,
      };
    }
    case UserActionTypes.AuthenticationError:
      return {
        ...state,
        error: action.payload.error,
        isAuthenticatedError: true,
        isLoading: false,
        user: action.payload?.user,
      };
    case UserActionTypes.ForcedLogout:
      return {
        ...state,
        error: action.payload?.error,
        isAuthenticated: false,
        isAuthenticatedError: false,
        isLoading: false,
        user: null,
      };
    case UserActionTypes.Logout:
      return { ...state, error: '', isAuthenticated: false, isAuthenticatedError: false, isLoading: false, user: null };
    default:
      return state;
  }
};
