import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, IconButton, ListItemText } from '@mui/material';
import { Icon } from 'asteroids';
import { AxiosError } from 'axios';
import classnames from 'classnames';
import { ExploreData } from 'components/explore-search-result-card/explore-search-result-card.component.props';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tag } from 'react-tag-autocomplete';
import { Currency, CurrencyOptions, DrawerInfoType, googleTagManagerEvents } from 'shared/common.definitions';
import { formatCompanyTags } from 'shared/helpers/common.helper';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { fireGTagManagerEvent } from 'shared/logic/company.logic';
import { addSubscriptionComment } from 'shared/logic/subscription-item.logic';
import { NewSubscription, postNewSolution, postSubscription } from 'shared/logic/subscriptions.logic';
import { Company, FilterTag, SastrifyStore, Subscription, ToolOwnerFilter, User, VendorType } from 'shared/models';
import { AppUrl } from 'src/constants/appurl';
import * as yup from 'yup';

import { SubscriptionStatusFeature, UploadSubscriptionDocumentsFeature } from '../../features/subscription';
import { useDebounce } from '../../hooks';
import {
  DatePickerComponent,
  NumberFormatInput,
  TagEditorComponent,
  TypeaheadComponent,
  VendorSelectInputComponent,
} from '..';
import { DrawerAddNewToolComponentProps } from '.';
import { AddNewSubscriptionWrapper } from './drawer-add-new-tool.styles';

const addNewSubscriptionSchema = yup.object().shape({
  billingFrequency: yup.number(),
  currentYearCost: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  lastYearCost: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  name: yup.string().required(),
  otherBillingFrequency: yup.number().min(0),
  otherBillingFrequencyType: yup.string(),
  renewalDate: yup.date().when('billingFrequency', {
    is: (billingFrequency: number) => Number(billingFrequency) > 0,
    then: yup.date().required(),
  }),
});

type ToolOwner = { avatar?: string; id?: string; name: string; value?: string };

const newSolutionSchema = yup.object().shape({
  budgetCents: yup
    .number()
    .transform((value) => {
      return Number.isNaN(value) ? 0 : value;
    })
    .min(0),
  decisionDate: yup.string(),
  name: yup.string().required(),
  state: yup.number().required(),
});

export const DrawerAddNewToolComponent: FC<DrawerAddNewToolComponentProps> = ({
  drawerType,
  showNotification,
  toggleDrawer,
}) => {
  const history = useHistory();
  const isSubscriptionInDiscovery = drawerType === DrawerInfoType.NEW_SOLUTION;

  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;

  const getSchema = () => {
    if (isSubscriptionInDiscovery) return newSolutionSchema;
    return addNewSubscriptionSchema;
  };

  const {
    control,
    formState: { isValid },
    getValues,
    register,
    reset,
    setValue,
    watch,
  } = useForm<NewSubscription>({
    mode: 'onChange',
    resolver: yupResolver(getSchema()),
    shouldUnregister: true,
  });

  const queryClient = useQueryClient();

  const company = queryClient.getQueryData<Company>('company');

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [vendorName, setVendorName] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>();
  const [selectedVendor, setSelectedVendor] = useState<VendorType>();
  const [tags, setTags] = useState<FilterTag[]>([]);
  const [tagSuggestions, setTagSuggestions] = useState<FilterTag[]>([]);
  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState<ToolOwnerFilter>({ avatar: '', id: '', name: '' });
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

  const isMounted = useRef(true);

  const { t } = useTranslation();
  const uploadDocumentRef = useRef<{ onSubmit: (id?: string) => Promise<void> }>();

  useDebounce(setDebouncedValue, vendorName, 500);

  const comment = watch('comment');

  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');
  const initialSelectedVendor = queryClient.getQueryData<ExploreData>('initial-selected-vendor');

  useEffect(() => {
    if (company?.companyTags) {
      const formattedTags = formatCompanyTags(company) as FilterTag[];
      setTagSuggestions(formattedTags);
    }
  }, [company, setTagSuggestions]);

  useEffect(() => {
    if (isMounted.current && initialSelectedVendor && initialSelectedVendor.name) {
      setSelectedVendor(initialSelectedVendor as VendorType);
      setVendorName(initialSelectedVendor.name);
      setValue('name', initialSelectedVendor.name, { shouldValidate: true });
      queryClient.setQueryData('initial-selected-vendor', undefined);
      isMounted.current = false;
    }
  }, [initialSelectedVendor, setValue, queryClient]);

  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList.map((user: User) => ({
        avatar: user?.avatar,
        name: user.name,
        value: user.id || user.name?.replaceAll(' ', '-'),
      }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  const updateSubscriptionsCacheAndCloseModal = (data: Subscription, isInvalidateQueries?: boolean) => {
    const queryKey = isSubscriptionInDiscovery ? ['subscriptions-in-discovery', true] : 'subscriptions';
    queryClient.setQueryData(queryKey, (cacheData) => {
      if (cacheData) {
        const subscription = cacheData as Array<Subscription>;
        return [...subscription, data];
      }
    });
    if (isInvalidateQueries) {
      queryClient.invalidateQueries('subscriptions');
      queryClient.invalidateQueries('subscriptions-todos');
    }

    queryClient.invalidateQueries('subscriptions-and-new-solutions');
    reset();

    toggleDrawer();
    const message = `${isSubscriptionInDiscovery ? 'New solution' : 'Subscription'} successfully created`;
    showNotification?.(message, NotificationAlertType.Success);
    history.push(AppUrl.getToolUrl(data, isSubscriptionInDiscovery));
  };

  const handleUploadDocument = async (id: string) => {
    if (isFileSelected) {
      await uploadDocumentRef?.current?.onSubmit(id);
    }
  };

  const { isLoading, mutate } = useMutation(isSubscriptionInDiscovery ? postNewSolution : postSubscription, {
    onError: (err: AxiosError) => {
      const message =
        getErrorCodeMessage(err) ||
        t('common:modals.add_new_subscription_feature_modal.error_message', {
          value: isSubscriptionInDiscovery ? 'New solution' : 'Subscription',
        });

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: async (data) => {
      if (!isSubscriptionInDiscovery) {
        await handleUploadDocument(String(data.id));
        updateSubscriptionsCacheAndCloseModal(data, true);
        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.SubscriptionCreated);
      } else {
        if (comment) {
          await addSubscriptionComment({
            comment,
            subscriptionId: data?.id,
          });
        }
        updateSubscriptionsCacheAndCloseModal(data);
        fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.NewSolutionCreated);
      }
    },
  });

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner({ avatar: '', id: '', name: '' });
      setToolOwnerName('');
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleClearButtonClicked = () => {
    setToolOwner({ avatar: '', id: '', name: '' });
    setToolOwnerName('');
  };

  const onSubmit = async () => {
    const selectedTags = tags?.length ? tags.map((item) => item.name) : [];
    const formValues = getValues();
    const subscriptionClone = { ...formValues };
    const vendorId = selectedVendor && selectedVendor.name === getValues().name ? selectedVendor.id : undefined;

    const { otherBillingFrequency, otherBillingFrequencyType } = subscriptionClone;

    const renewalInterval = otherBillingFrequencyType
      ? { [otherBillingFrequencyType as string]: Number(otherBillingFrequency) }
      : null;

    const { billingFrequency } = subscriptionClone;
    const budgetCents = Number(getValues('budgetCents')) * 100 || undefined;
    if (billingFrequency === 0 && subscriptionClone.renewalDate) delete subscriptionClone.renewalDate;
    else if (String(billingFrequency) === '-1') delete subscriptionClone.billingFrequency;
    else if (billingFrequency && billingFrequency > 0 && subscriptionClone.renewalDate) {
      const renewalDate = new Date(subscriptionClone?.renewalDate);
      let dateFormatted =
        renewalDate && new Date(renewalDate?.getTime() - renewalDate?.getTimezoneOffset() * 60000)?.toISOString();
      dateFormatted = dateFormatted?.split('T')?.[0];

      subscriptionClone.renewalDate = dateFormatted || renewalDate?.toISOString();
    }

    if (renewalInterval) {
      delete subscriptionClone.otherBillingFrequencyType;
      delete subscriptionClone.otherBillingFrequency;
    }

    let newSubscription: NewSubscription = {
      ...subscriptionClone,
      ownerId: toolOwner.value,
      ownerName: toolOwner.name ? toolOwner.name : undefined,
      tags: selectedTags,
      vendorId,
    };

    if (isSubscriptionInDiscovery) {
      newSubscription = {
        ...newSubscription,
        budgetCents,
        budgetCurrency: DEFAULT_CURRENCY,
        state: Number(getValues('state')),
      };
    } else {
      const currentYearCost = Math.round(Number(subscriptionClone?.currentYearCost) * 100) || undefined;
      const lastYearCost = Math.round(Number(subscriptionClone?.lastYearCost) * 100) || undefined;
      newSubscription = {
        ...newSubscription,
        currentYearCost,
        lastYearCost,
        renewalInterval,
      };
    }
    mutate(newSubscription);
  };

  const billingFrequency = watch('billingFrequency');

  const handleItemSelected = (item: VendorType) => {
    if (item) {
      setValue('name', item.name, { shouldValidate: true });
      setSelectedVendor(item);
    } else {
      setSelectedVendor(undefined);
    }
  };

  const onRemoveTag = (tagIndex: number) => {
    let updatedTags: FilterTag[];
    if (tags.length === 1) {
      updatedTags = [];
    } else {
      updatedTags = tags.filter((_tag, index) => index !== tagIndex);
    }
    const removedTag = tags[tagIndex] as FilterTag;
    const updatedSuggestions: FilterTag[] = [...(tagSuggestions as FilterTag[]), removedTag];
    setTags(updatedTags);
    setTagSuggestions(updatedSuggestions);
  };

  const onAddTag = (tag: Tag) => {
    const newTag: FilterTag = { id: Number(tag.id), name: tag.name };

    const updatedTags = [...tags, newTag];
    const updatedSuggestions = tagSuggestions?.filter((tag) => tag.id !== newTag.id);

    setTags(updatedTags);
    setTagSuggestions(updatedSuggestions);
  };

  return (
    <AddNewSubscriptionWrapper>
      {!isSubscriptionInDiscovery ? (
        <>
          <Box my={2} display='flex' flexDirection='column' data-testid='subscription-name'>
            <ListItemText className='label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.subscription_name_label_text'
              )}
              <span className='mandatory'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
              </span>
            </ListItemText>
            <Controller
              name='name'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <VendorSelectInputComponent
                    handleItemSelected={handleItemSelected}
                    setDebouncedValue={setDebouncedValue}
                    vendorName={vendorName}
                    debouncedValue={debouncedValue}
                    onChange={onChange}
                    setVendorName={setVendorName}
                    placeholder={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.subscription_name_placeholder'
                    )}
                    value={value}
                  />
                );
              }}
            />
          </Box>
          <Box my={2} display='flex' flexDirection='column' data-testid='subscription-cost'>
            <ListItemText className='label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.subscription_cost_label_text'
              )}
            </ListItemText>
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Box display='flex' flexDirection='column' width='48%' data-testid='last-year-cost'>
                <ListItemText className='sub-label'>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.cost_last_year_number_input_label'
                  )}
                </ListItemText>
                <Controller
                  name='lastYearCost'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <NumberFormatInput
                        decimalScale={2}
                        decimalSeparator='.'
                        fixedDecimalScale
                        step='0.1'
                        thousandSeparator
                        prefix={
                          company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY
                        }
                        value={value}
                        onValueChange={(value) => onChange(value.floatValue)}
                        placeholder={t(
                          'common:modals.add_new_subscription_feature_modal.body_section.form.cost_last_year_number_input_placeholder'
                        )}
                      />
                    );
                  }}
                />
              </Box>
              <Box display='flex' flexDirection='column' width='48%' data-testid='current-year-cost'>
                <ListItemText className='sub-label'>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.cost_this_year_number_input_label'
                  )}
                </ListItemText>
                <Controller
                  name='currentYearCost'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <NumberFormatInput
                        decimalScale={2}
                        decimalSeparator='.'
                        fixedDecimalScale
                        thousandSeparator
                        prefix={
                          company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY
                        }
                        value={value}
                        onValueChange={(value) => onChange(value.floatValue)}
                        placeholder={t(
                          'common:modals.add_new_subscription_feature_modal.body_section.form.cost_this_year_number_input_placeholder'
                        )}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box my={2} display='flex' flexDirection='column' data-testid='renewal-frequency'>
            <ListItemText className='label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.contract_renewal_frequency_label_text'
              )}
            </ListItemText>
            <ListItemText className='sub-label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.contract_renewal_frequency_sub_label_text'
              )}
            </ListItemText>
            <div className='select is-fullwidth is-expanded'>
              <select
                className={classnames('select-billingfrequency is-expanded', {
                  placeholderText: billingFrequency && billingFrequency < 0,
                })}
                data-testid='select-renewal-frequency'
                {...register('billingFrequency')}>
                <option value={-1} data-testid='non-selected'>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.default_option_text'
                  )}
                </option>
                <option value={0} data-testid='option-monthly'>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.monthly_option_text'
                  )}
                </option>
                <option value={1} data-testid='option-yearly'>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.yearly_option_text'
                  )}
                </option>
                <option value={3} data-testid='option-other'>
                  {t(
                    'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_frequency_select_input_section.other_option_text'
                  )}
                </option>
              </select>
            </div>
          </Box>
          {billingFrequency && billingFrequency > 2 && (
            <Box my={2} display='flex'>
              <Box mr={1} width='50%'>
                <div className='select is-fullwidth is-expanded'>
                  <select
                    {...register('otherBillingFrequencyType', {
                      required: true,
                    })}>
                    <option value='months' data-testid='option-months'>
                      {t(
                        'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_interval.months_option_text'
                      )}
                    </option>
                    <option value='years' data-testid='option-years'>
                      {t(
                        'common:modals.add_new_subscription_feature_modal.body_section.form.renewal_interval.years_option_text'
                      )}
                    </option>
                  </select>
                </div>
              </Box>
              <Box width='50%'>
                <input
                  {...register('otherBillingFrequency', {
                    required: true,
                  })}
                  className='input is-fullwidth'
                  type='number'
                  min={0}
                />
              </Box>
            </Box>
          )}

          {billingFrequency && billingFrequency > 0 && (
            <Box my={1} display='flex' flexDirection='column' className='sastrify-datepicker'>
              <ListItemText className='label'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.renewal_date_input_label')}
                <span className='mandatory'>
                  {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
                </span>
              </ListItemText>
              <Controller
                name='renewalDate'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePickerComponent
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setOpenDatePicker(false);
                    }}
                    openDatePicker={openDatePicker}
                    setOpenDatePicker={setOpenDatePicker}
                    minDate={new Date()}
                  />
                )}
                data-testid='renewal-date'
              />
            </Box>
          )}

          <Box my={2} display='flex' flexDirection='column' data-testid='owner'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.label_text'
              )}
            </ListItemText>
            <ListItemText className='sub-label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.owner_sub_label_text'
              )}
            </ListItemText>
            <TypeaheadComponent
              data={toolOwners}
              onItemSelected={onToolOwnerSelected}
              onBlurToolOwnerFilter={onToolOwnerSelected}
              value={toolOwnerName}
              fields={['name', 'email']}
              isToolOwnerFilter
              onTextChanged={onChangeToolOwner}
              placeholder={t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.placeholder_text'
              )}
              isSubscriptionInDiscovery
              rightIcon={
                <IconButton onClick={handleClearButtonClicked} onMouseDown={(e) => e.preventDefault()}>
                  <Icon>clear</Icon>
                </IconButton>
              }
            />
          </Box>

          <Box my={1} display='flex' flexDirection='column' className='sastrify-tags' data-testid='tags'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.label_text'
              )}
            </ListItemText>
            <ListItemText className='sub-label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.tags_sub_label_text'
              )}
            </ListItemText>
            <TagEditorComponent
              isCustom
              tags={tags}
              placeholderText={t(
                'common:modals.add_new_subscription_feature_modal.body_section.form.tags_input_placeholder_text'
              )}
              suggestions={tagSuggestions}
              isFilterTag
              allowNew={false}
              onAddition={(tag) => onAddTag(tag)}
              onDelete={(tagIndex) => onRemoveTag(tagIndex)}
            />
          </Box>

          <Box my={2} display='flex' flexDirection='column' data-testid='upload-documents'>
            <ListItemText className='label'>
              {t('common:modals.add_new_subscription_feature_modal.body_section.form.upload_document_label')}
            </ListItemText>
            <ListItemText className='sub-label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.documents_sub_label_text'
              )}
            </ListItemText>
            <UploadSubscriptionDocumentsFeature
              isAddNewSubscription
              ref={uploadDocumentRef}
              companyId={String(company?.id)}
              setIsFileSelected={setIsFileSelected}
            />
          </Box>
        </>
      ) : (
        <>
          <Box my={2} display='flex' flexDirection='column' data-testid='tool-name'>
            <ListItemText className='label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_solution_section.new_solution_name_label_text'
              )}
              <span className='mandatory'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
              </span>
            </ListItemText>
            <Controller
              name='name'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <VendorSelectInputComponent
                    handleItemSelected={handleItemSelected}
                    setDebouncedValue={setDebouncedValue}
                    vendorName={vendorName}
                    debouncedValue={debouncedValue}
                    onChange={onChange}
                    setVendorName={setVendorName}
                    placeholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tool_name_input_section.placeholder_text'
                    )}
                    value={value}
                  />
                );
              }}
            />
          </Box>

          <Box my={2} display='flex' flexDirection='column' data-testid='status'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.status_input_section.label_text'
              )}
              <span className='mandatory'>
                {t('common:modals.add_new_subscription_feature_modal.body_section.form.required_input_text')}
              </span>
            </ListItemText>
            <Controller
              name='state'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SubscriptionStatusFeature
                    isFilterDrawer
                    handleStatusChange={(value) => onChange(value)}
                    value={value ? String(value) : ''}
                    customPlaceholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.status_input_section.placeholder_text'
                    )}
                    isSubscriptionInDiscovery
                  />
                );
              }}
            />
          </Box>

          <Box my={1} display='flex' flexDirection='column' data-testid='owner'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.label_text'
              )}
            </ListItemText>
            <ListItemText className='sub-label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_solution_section.owner_sub_label_text'
              )}
            </ListItemText>

            <TypeaheadComponent
              data={toolOwners}
              onItemSelected={onToolOwnerSelected}
              onBlurToolOwnerFilter={onToolOwnerSelected}
              value={toolOwnerName}
              fields={['name', 'email']}
              isToolOwnerFilter
              onTextChanged={onChangeToolOwner}
              placeholder={t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.owner_input_section.placeholder_text'
              )}
              isSubscriptionInDiscovery
              rightIcon={
                <IconButton onClick={handleClearButtonClicked} onMouseDown={(e) => e.preventDefault()}>
                  <Icon>clear</Icon>
                </IconButton>
              }
            />
          </Box>
          <Box my={2} display='flex' flexDirection='column' data-testid='yearly-budget'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.label_text'
              )}
            </ListItemText>
            <Controller
              name='budgetCents'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <NumberFormatInput
                    decimalScale={2}
                    decimalSeparator='.'
                    fixedDecimalScale
                    thousandSeparator
                    prefix={company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY}
                    value={value}
                    onValueChange={(value) => onChange(value.floatValue)}
                    placeholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.yearly_budget_input_section.placeholder_text'
                    )}
                  />
                );
              }}
            />
          </Box>
          <Box my={2} display='flex' flexDirection='column' className='sastrify-datepicker' data-testid='decision-date'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.decision_date_input_section.label_text'
              )}
            </ListItemText>
            <ListItemText className='sub-label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_solution_section.decision_date_label_text'
              )}
            </ListItemText>

            <Controller
              name='decisionDate'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePickerComponent
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setOpenDatePicker(false);
                    }}
                    openDatePicker={openDatePicker}
                    setOpenDatePicker={setOpenDatePicker}
                    minDate={new Date()}
                    placeholder={t(
                      'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.decision_date_input_section.placeholder_text'
                    )}
                  />
                );
              }}
            />
          </Box>
          <Box my={2} display='flex' flexDirection='column' className='sastrify-tags' data-testid='tags'>
            <ListItemText className='label'>
              {t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.label_text'
              )}
            </ListItemText>
            <ListItemText className='sub-label'>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_solution_section.tags_sub_label_text'
              )}
            </ListItemText>

            <TagEditorComponent
              isCustom
              tags={tags}
              placeholderText={t(
                'subscriptions_in_discovery_view:add_subscriptions_in_discovery_modal.body_section.tags_input_section.placeholder_text'
              )}
              suggestions={tagSuggestions}
              isFilterTag
              allowNew={false}
              onAddition={(tag) => onAddTag(tag)}
              onDelete={(tagIndex) => onRemoveTag(tagIndex)}
            />
          </Box>
        </>
      )}
      <Box display='flex' sx={{ justifyContent: 'flex-end', mt: 4 }}>
        <Button
          sx={{ mr: 1 }}
          variant='text'
          color='secondary'
          onClick={() => toggleDrawer()}
          data-testid='cancel-button'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.add_new_subscription_section.cancel_button_text'
          )}
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={onSubmit}
          startIcon={isLoading ? <CircularProgress color='inherit' size={16} /> : null}
          data-testid='add-new-tool-button'
          disabled={!isValid || isLoading}>
          {t(
            `subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.${
              !isSubscriptionInDiscovery ? 'add_new_subscription_section' : 'add_new_solution_section'
            }.add_button_text`
          )}
        </Button>
      </Box>
    </AddNewSubscriptionWrapper>
  );
};
