import './workflow-header.style.scss';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkflowsCategory } from 'shared/common.definitions';
import { DropdownMenuIcon } from 'shared/helpers/styles.helper';

import { CardHeader } from '..';
import { DropdownComponent } from '../drop-down';
import { VerticalThreeDotsIcon } from '../icons';
import { WorkflowHeaderProps } from './workflow-header.component.props';

const workflowIconClass = {
  [WorkflowsCategory.null]: '',
  [WorkflowsCategory.new_solution]: 'new-solution-icon',
  [WorkflowsCategory.new_subscription]: 'subscription-created-icon',
  [WorkflowsCategory.new_solution_to_subscription]: 'solution-to-subscription-icon',
  [WorkflowsCategory.subscription_is_up_for_renewal]: 'up-for-renewal-icon',
};

export const WorkflowHeader: FC<WorkflowHeaderProps> = ({
  canManageWorkflows,
  category,
  openDeleteWorkflowModal,
  openEditWorkflowActionItem,
  workflowItem,
}) => {
  const { t } = useTranslation();

  const onEdit = () => openEditWorkflowActionItem?.(workflowItem.actions, workflowItem.triggerType);
  const onDelete = () => openDeleteWorkflowModal?.(workflowItem.id);
  const onActionsClick = () => actionMenuRef.current?.handleToggle();

  const actionMenuRef = useRef<{ handleToggle: () => void }>();

  const renderDropDownMenu = () => {
    if (!canManageWorkflows) {
      return null;
    }

    return (
      <DropdownComponent
        dataTestId='workflow-header-dropdown'
        ref={actionMenuRef}
        hasPadding
        menuMinWidth='8.5rem'
        menuTriggerElement={
          <DropdownMenuIcon disableRipple className='workflow-actions-icon' onClick={onActionsClick}>
            <VerticalThreeDotsIcon width={20} height={20} />
          </DropdownMenuIcon>
        }>
        <Box className='actions-menu-dropdown'>
          <Box component={Button} onClick={onEdit}>
            {t('workflows_view:action_menu.edit_text')}
          </Box>
          <Box component={Button} onClick={onDelete}>
            {t('workflows_view:action_menu.delete_text')}
          </Box>
        </Box>
      </DropdownComponent>
    );
  };

  return (
    <CardHeader
      className='workflow-header'
      cardIcon={
        <div
          className={classNames('card-icon', {
            [workflowIconClass[category]]: category !== WorkflowsCategory.null,
          })}
        />
      }
      action={renderDropDownMenu()}
    />
  );
};
