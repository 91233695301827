import { Box, styled } from '@mui/material';

export const WorkflowRequestWrapper = styled(Box)(({ theme }) => ({
  '& .MuiTypography-h3': {
    marginBottom: theme.spacing(1.5),
  },
  '& .request-fields': {
    width: '100%',
  },
  '& .request-title': {
    marginBottom: theme.spacing(3),
  },
  width: '100%',
}));
