import { Box, Typography } from '@mui/material';
import woops from 'assets/images/woops.svg';
import { Dialog } from 'asteroids';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UsageAnalyticsLicenseHoldersErrorProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const UsageAnalyticsLicenseHoldersError: FC<UsageAnalyticsLicenseHoldersErrorProps> = (
  props: UsageAnalyticsLicenseHoldersErrorProps
) => {
  const { onClose, onConfirm } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      clickAwayToClose={false}
      actions={[
        {
          actionType: 'cancel',
          label: t('subscription_detail_view:usage_analytics_section:error_dialog:close_button'),
          onClick: () => onClose(),
        },
        {
          actionType: 'confirm',
          label: t('subscription_detail_view:usage_analytics_section:error_dialog:retry_button'),
          onClick: () => onConfirm(),
        },
      ]}
      bodyContent={
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginBottom: 4 }}>
          <Box mt={3}>
            <img src={woops} alt='woops' />
          </Box>
          <Typography mt={1} variant='h3' alignSelf='start'>
            {t('subscription_detail_view:usage_analytics_section:error_dialog:subtitle')}
          </Typography>
          <Typography mt={2} variant='body'>
            {t('subscription_detail_view:usage_analytics_section:error_dialog:description')}
          </Typography>
        </Box>
      }
      title={t('subscription_detail_view:usage_analytics_section:error_dialog:title')}
      width={444}
    />
  );
};
