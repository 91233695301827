import { Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material';

export const StyledBreadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  '& a': {
    '&:hover': {
      textDecoration: 'underline !important',
      color: theme.palette.text.secondary,
      backgroundColor: 'transparent',
    },
  },
  marginBottom: theme.spacing(2),
}));
