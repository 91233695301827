import { ModalStore } from '../../../models';
import { DefaultModalStore } from '../../default.store';
import { ModalAction, ModalActionTypes } from '..';

const initialState: ModalStore = { ...DefaultModalStore };

// eslint-disable-next-line @typescript-eslint/default-param-last
export const ModalReducer = (state = initialState, action: ModalAction): ModalStore => {
  switch (action.type) {
    case ModalActionTypes.UPDATE_FOOTER_STATE:
      return {
        ...state,
        [Object.keys(action.payload)[0]]: Object.values(action.payload)[0],
      };
    case ModalActionTypes.RESET_FOOTER_STATE:
      return { ...initialState };
    case ModalActionTypes.OPEN_SUBSCRIPTION_RENEWAL_ACTION_RENEWAL_MODAL:
      return {
        ...state,
        isSubscriptionRenewalActionRenewalModalOpen: true,
      };
    case ModalActionTypes.CLOSE_SUBSCRIPTION_RENEWAL_ACTION_RENEWAL_MODAL:
      return {
        ...state,
        isSubscriptionRenewalActionRenewalModalOpen: false,
      };
    case ModalActionTypes.OPEN_SUBSCRIPTION_RENEWAL_ACTION_CANCEL_MODAL:
      return {
        ...state,
        isSubscriptionRenewalActionCancelModalOpen: true,
      };
    case ModalActionTypes.CLOSE_SUBSCRIPTION_RENEWAL_ACTION_CANCEL_MODAL:
      return {
        ...state,
        isSubscriptionRenewalActionCancelModalOpen: false,
      };
    default:
      return state;
  }
};
