import React from 'react';
import { colors } from 'shared/theme';

export const CollectionIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill }) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.65597 6.19004L6.71847 9.11504C6.80398 9.16441 6.90098 9.1904 6.99972 9.1904C7.09846 9.1904 7.19546 9.16441 7.28097 9.11504L12.3435 6.19004C12.4287 6.14059 12.4995 6.06962 12.5487 5.98423C12.5978 5.89884 12.6237 5.80202 12.6237 5.70348C12.6237 5.60493 12.5978 5.50812 12.5487 5.42273C12.4995 5.33733 12.4287 5.26636 12.3435 5.21691L7.28097 2.29191C7.19546 2.24254 7.09846 2.21655 6.99972 2.21655C6.90098 2.21655 6.80398 2.24254 6.71847 2.29191L1.65597 5.21691C1.57073 5.26636 1.49997 5.33733 1.45079 5.42273C1.4016 5.50812 1.37572 5.60493 1.37572 5.70348C1.37572 5.80202 1.4016 5.89884 1.45079 5.98423C1.49997 6.06962 1.57073 6.14059 1.65597 6.19004ZM6.99972 3.42816L10.9372 5.67816L6.99972 7.95629L3.06222 5.70066L6.99972 3.42816ZM11.781 7.81004L6.99972 10.5719L2.21847 7.81004C2.15461 7.77261 2.08398 7.74816 2.01065 7.73809C1.93731 7.72801 1.86271 7.73251 1.79112 7.75132C1.71953 7.77013 1.65236 7.80289 1.59345 7.84772C1.53454 7.89254 1.48506 7.94855 1.44784 8.01254C1.41065 8.07674 1.38653 8.14766 1.37686 8.22123C1.3672 8.29479 1.37218 8.36954 1.39153 8.44117C1.41087 8.5128 1.4442 8.57989 1.48958 8.63858C1.53497 8.69728 1.59151 8.74642 1.65597 8.78316L6.71847 11.7082C6.80398 11.7575 6.90098 11.7835 6.99972 11.7835C7.09846 11.7835 7.19546 11.7575 7.28097 11.7082L12.3435 8.78316C12.4079 8.74642 12.4645 8.69728 12.5099 8.63858C12.5552 8.57989 12.5886 8.5128 12.6079 8.44117C12.6273 8.36954 12.6322 8.29479 12.6226 8.22123C12.6129 8.14766 12.5888 8.07674 12.5516 8.01254C12.5144 7.94855 12.4649 7.89254 12.406 7.84772C12.3471 7.80289 12.2799 7.77013 12.2083 7.75132C12.1367 7.73251 12.0621 7.72801 11.9888 7.73809C11.9155 7.74816 11.8448 7.77261 11.781 7.81004Z'
      fill={fill}
    />
  </svg>
);

CollectionIcon.defaultProps = {
  fill: colors.white,
};
