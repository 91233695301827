import classNames from 'classnames';
import { useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SchemaField } from 'shared/models/schema-field';

interface SchemaSelectFieldProps extends Pick<SchemaField, 'field_name' | 'field_value'> {
  field_options?: UseFormRegisterReturn;
  label: string;
  error?: FieldError;
  isEditing?: boolean;
  selectOptions?: string[];
  placeholder?: string;
}

export const SchemaSelectField: React.FC<SchemaSelectFieldProps> = (props) => {
  const {
    error,
    field_name: name,
    field_options: options,
    field_value: value,
    isEditing,
    label,
    placeholder = '',
    selectOptions,
  } = props;

  const [selectedValue, setSelectedValue] = useState<string>(value);

  const handleChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLSelectElement;

    setSelectedValue(target.value);
    if (options?.onChange) options.onChange(e);
  };

  return (
    <div className={`schema-field ${props.field_name}`} data-testid={`${props.field_name}`}>
      <label
        className={classNames('schema-field__label', !isEditing && 'schema-field__label--disabled')}
        htmlFor={name}>
        {label}
      </label>
      <div className={classNames('select is-fullwidth is-expanded', selectedValue ? '' : 'non-selected')}>
        <select
          id={name}
          disabled={!isEditing}
          defaultValue={selectedValue}
          {...(options || {})}
          onChange={handleChange}>
          <option key={placeholder} value='' disabled>
            {placeholder}
          </option>
          {selectOptions?.map((selectItem) => (
            <option key={selectItem} value={selectItem}>
              {selectItem}
            </option>
          ))}
        </select>
      </div>

      {error && <div className='field-error'>{error?.message}</div>}
    </div>
  );
};
