import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AllowedDomainsNotification {
  visible: boolean;
}

export const initialState: AllowedDomainsNotification = {
  visible: true,
};

const allowedDomainsNotificationSlice = createSlice({
  initialState,
  name: 'allowedDomainsNotification',
  reducers: {
    hideCard(state, action: PayloadAction<boolean>) {
      state.visible = !action.payload;
    },
  },
});

export const { hideCard } = allowedDomainsNotificationSlice.actions;
export default allowedDomainsNotificationSlice.reducer;
