import { styled } from '@mui/material/styles';

import pageBreakpoints from '../../helpers/page-breakpoints';

export const StyledPageFooterContainer = styled('div')(({ theme }) => ({
  '& .page-footer-content': {
    [theme.breakpoints.down('xl')]: {
      ...pageBreakpoints('sm'),
    },
    [theme.breakpoints.up('xl')]: {
      ...pageBreakpoints('xl'),
    },
    padding: theme.spacing(2, 4),
    margin: '0 auto',
    '&:empty': {
      display: 'none',
    },
  },
  '.sastrify-app &': {
    left: '256px',
    width: 'calc(100% - 256px - var(--scrollbar-width))',
  },
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  background: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  zIndex: theme.zIndex.appBar, // workaround so that tables pagination would not overlap the footer component
}));
