import { useFetchData } from 'hooks/index';
import { useQueryClient } from 'react-query';
import { featureFlags } from 'shared/common.definitions';
import { fetchDuplicatesCount } from 'shared/logic/invoices.logic';
import { Company } from 'shared/models';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';

export const useDuplicatesSpendCount = () => {
  const queryClient = useQueryClient();
  const company = queryClient.getQueryData<Company>('company');
  const disableSpendReview = useCompanyFeatureFlag(featureFlags.DISABLE_SPEND_REVIEW);

  const { data: duplicatesCount = 0, isLoading } = useFetchData('spend_duplicates_count', fetchDuplicatesCount, {
    enabled: Boolean(company?.id) && !disableSpendReview,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return {
    duplicatesCount,
    isLoading,
  };
};
