import { ActivateToolPayload, MultiToolRequestToolDto, RequestActivity, RequestSupportTicketDto } from 'libs/dtos';
import { RequestDraft } from 'shared/models/request-draft.model';

import { SubscriptionStates, SubscriptionStatus, UPDATE_REQUEST_TYPE } from '../common.definitions';
import { computeChecksumMd5 } from '../helpers/common.helper';
import {
  CreateRequestInitiativeCommentPayload,
  CreateSastrifySupportTicketPayload,
  GetDocumentLinkResponse,
  Initiative,
  InitiativeSubscription,
  ModifyRequestCommentPayload,
  ModifyRequestCommentResponse,
  PresignedUploadUrl,
  RequestDetails,
  RequestDocument,
  RequestInitiativeDocument,
  RequestPayload,
  RequestResponse,
  RequestWorkflowItem,
  Subscription,
  UpdateMultiToolsRequestStatusPayload,
  UpdateRequestDocumentPayload,
  UpdateRequestInitiativeDocumentPayload,
  UpdateRequestInvolvementCommentPayload,
  UpdateRequestStatusPayload,
  UploadDocumentPayload,
  VendorItemResponse,
  VendorType,
} from '../models';
import { apiService, apiUrl } from '../services';
import { getCommaStrippedFile, getPresignedUploadUrl, uploadFileToPresignedUrl } from './subscription-item.logic';

export const createNewRequest = async (payload: RequestPayload): Promise<RequestResponse> => {
  const response = await apiService.post(`${apiUrl.createNewRequest()}`, payload);

  return response.data;
};

export const createDraft = async (payload: RequestPayload): Promise<RequestResponse> => {
  const response = await apiService.post(`${apiUrl.modifyDraft()}`, payload);

  return response.data;
};

export const updateDraft = async (payload: RequestPayload): Promise<RequestResponse> => {
  const response = await apiService.patch(`${apiUrl.modifyDraft()}`, payload);

  return response.data;
};

export const editRequest = async (payload: RequestPayload): Promise<RequestResponse> => {
  const response = await apiService.patch(`${apiUrl.editRequest()}`, payload);

  return response.data;
};

export const getRequestWorkflows = async (): Promise<RequestWorkflowItem[]> => {
  const response = await apiService.get(`${apiUrl.getRequestWorkflows()}`);

  return response.data;
};

export const getRequestDetails = async (requestId: string | number): Promise<RequestDetails> => {
  const response = await apiService.get(`${apiUrl.getRequestDetails(requestId)}`);

  return response.data;
};

export const updateRequestStatus = async (
  type: UPDATE_REQUEST_TYPE,
  payload: UpdateRequestStatusPayload
): Promise<{ status: string }> => {
  const response = await apiService.patch(`${apiUrl.updateRequestStatus(type)}`, payload);

  return response.data;
};

export const updateMultiToolsRequestStatus = async (
  payload: UpdateMultiToolsRequestStatusPayload
): Promise<{ status: string }> => {
  const response = await apiService.patch(`${apiUrl.updateMultiToolsRequestStatus()}`, payload);

  return response.data;
};

export const deleteRequest = async (requestId: string | number): Promise<boolean> => {
  const response = await apiService.delete(`${apiUrl.deleteRequest(requestId)}`);

  return response.data;
};

export const getRequestActivities = async (requestId: string | number): Promise<RequestActivity[]> => {
  const response = await apiService.get(`${apiUrl.getRequestActivities(requestId)}`);

  return response.data;
};

export const createRequestComment = async (
  payload: ModifyRequestCommentPayload
): Promise<ModifyRequestCommentResponse> => {
  const response = await apiService.post(`${apiUrl.createRequestComment()}`, payload);

  return response.data;
};

export const deleteRequestComment = async (
  requestId: string | number,
  commentId: string | number
): Promise<unknown> => {
  const response = await apiService.delete(`${apiUrl.deleteRequestComment(requestId, commentId)}`);

  return response.data;
};

export const updateRequestComment = async (
  payload: ModifyRequestCommentPayload
): Promise<ModifyRequestCommentResponse> => {
  const response = await apiService.patch(`${apiUrl.updateRequestComment()}`, payload);

  return response.data;
};

export const checkToolRequestStatus = (
  tool?: VendorType,
  requests?: RequestWorkflowItem[]
): RequestWorkflowItem | undefined => {
  if (!tool || !requests || (Array.isArray(requests) && requests.length === 0)) {
    return undefined;
  }

  const parentAndChildRequestsArray = requests.reduce((acc: RequestWorkflowItem[], request: RequestWorkflowItem) => {
    if (request.childRequests && request.childRequests.length > 0) {
      return [...acc, ...request.childRequests];
    }
    return [...acc, request];
  }, []);

  return parentAndChildRequestsArray.find((request) => request.vendorName === tool.name);
};

export const checkSubscriptionStatus = (
  tool?: VendorType,
  subscriptions?: Subscription[] | InitiativeSubscription[]
): Partial<Subscription> | undefined => {
  if (!tool || !subscriptions || (Array.isArray(subscriptions) && subscriptions.length === 0)) {
    return undefined;
  }

  return subscriptions.find(
    (subscription: Subscription | InitiativeSubscription) =>
      (subscription.vendorId === tool.id || subscription.vendorName === tool.name || subscription.name === tool.name) &&
      [
        ...SubscriptionStates,
        SubscriptionStatus.rejected,
        SubscriptionStatus.requested,
        SubscriptionStatus.in_trial,
        SubscriptionStatus.under_consideration,
      ].includes(subscription.state as SubscriptionStatus)
  );
};

export const activateTool = async (payload: ActivateToolPayload): Promise<Subscription> => {
  const response = await apiService.post(`${apiUrl.activateTool()}`, payload);

  return response.data;
};

export const getVendorItem = async (toolId: string | number): Promise<VendorItemResponse> => {
  const response = await apiService.get(`${apiUrl.getVendor(toolId)}`);

  return response.data;
};

export const updateRequestDocuments = async (payload: UpdateRequestDocumentPayload): Promise<RequestDocument> => {
  const response = await apiService.post(`${apiUrl.updateRequestDocuments()}`, payload);

  return response.data;
};

export const uploadRequestDocument = async (payload: UploadDocumentPayload): Promise<RequestDocument> => {
  const { category, companyId, file, referenceId } = payload;

  const commaStrippedFile = getCommaStrippedFile(file);
  const checksum = await computeChecksumMd5(commaStrippedFile);

  try {
    const presignedUploadUrl: PresignedUploadUrl = await getPresignedUploadUrl(String(companyId));

    await uploadFileToPresignedUrl(presignedUploadUrl?.url as string, file);

    return await updateRequestDocuments({
      category,
      checksum,
      contentType: file.type,
      fileKey: presignedUploadUrl.key,
      fileName: file.name,
      requestId: referenceId,
    });
  } catch (error) {
    throw Error(`Failed to upload request document: ${error}`);
  }
};

export const deleteRequestDocument = async (documentId: number): Promise<unknown> => {
  const response = await apiService.delete(`${apiUrl.deleteRequestDocument(documentId)}`);

  return response.data;
};

export const reopenRequest = async (requestId: string | number): Promise<boolean> => {
  const response = await apiService.patch(`${apiUrl.reopenRequest(requestId)}`);

  return response.data;
};

export const createSastrifySupportTicket = async (
  payload: CreateSastrifySupportTicketPayload
): Promise<RequestSupportTicketDto> => {
  const response = await apiService.post(`${apiUrl.createSastrifySupportTicket()}`, payload);

  return response.data;
};

export const getRequestInitiativeTickets = async (requestId: string | number): Promise<Initiative[]> => {
  const response = await apiService.get(`${apiUrl.getRequestInitiativeTickets(requestId)}?page=0`);

  return response.data.items;
};

export const handleFileDownload = (fileName: string, fileUrl: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;

  document.body.appendChild(link);
  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  document.body.removeChild(link);
};

export const updateRequestInitiativeDocuments = async (
  payload: UpdateRequestInitiativeDocumentPayload
): Promise<RequestInitiativeDocument> => {
  const response = await apiService.post(`${apiUrl.updateRequestInitiativeDocuments()}`, payload);

  return response.data;
};

export const uploadRequestInitiativeDocument = async (
  payload: UploadDocumentPayload
): Promise<RequestInitiativeDocument> => {
  const { category, companyId, file, referenceId } = payload;

  const commaStrippedFile = getCommaStrippedFile(file);
  const checksum = await computeChecksumMd5(commaStrippedFile);

  try {
    const presignedUploadUrl: PresignedUploadUrl = await getPresignedUploadUrl(String(companyId));

    await uploadFileToPresignedUrl(presignedUploadUrl?.url as string, file);

    return await updateRequestInitiativeDocuments({
      category,
      checksum,
      contentType: file.type,
      fileKey: presignedUploadUrl.key,
      fileName: file.name,
      requestInitiativeTicketId: referenceId,
    });
  } catch (error) {
    throw Error(`Failed to upload request initiative document: ${error}`);
  }
};

export const createRequestInitiativeComment = async (
  payload: CreateRequestInitiativeCommentPayload
): Promise<ModifyRequestCommentResponse> => {
  const response = await apiService.post(`${apiUrl.createRequestInitiativeComment()}`, payload);

  return response.data;
};

export const updateRequestInvolvementComment = async (
  payload: UpdateRequestInvolvementCommentPayload
): Promise<ModifyRequestCommentResponse> => {
  // The same url route as when creating involvement comment
  const response = await apiService.patch(`${apiUrl.createRequestInitiativeComment()}`, payload);

  return response.data;
};

export const deleteRequestInvolvementComment = async (ticketId: number, commentId: string): Promise<boolean> => {
  const response = await apiService.delete(`${apiUrl.deleteRequestInvolvementComment(ticketId, commentId)}`);

  return response.data;
};

export const deactivateTool = async (requestId: number): Promise<Subscription> => {
  const response = await apiService.patch(`${apiUrl.deactivateTool(requestId)}`);

  return response.data;
};

export const addInitiativeTool = async (payload: MultiToolRequestToolDto): Promise<RequestResponse> => {
  const response = await apiService.post(`${apiUrl.addInitiativeTool()}`, payload);

  return response.data;
};

export const getDraftRequests = async (): Promise<RequestDraft[]> => {
  const response = await apiService.get(`${apiUrl.getDraftRequests()}`);

  return response.data;
};

export const getDocumentLink = async (documentId: string): Promise<GetDocumentLinkResponse> => {
  const response = await apiService.get(`${apiUrl.getDocumentLink(documentId)}`);

  return response.data;
};

export const downloadDocument = async (
  fileName: string,
  fileUrl: string,
  fileId: string | undefined
): Promise<void> => {
  let link = fileUrl;
  if (fileId) {
    const response = await getDocumentLink(fileId);

    if (response.link) {
      link = response.link;
    }
  }
  handleFileDownload(fileName, link);
};

export const getInitiativeSubscriptions = async (): Promise<InitiativeSubscription[]> => {
  const response = await apiService.get(`${apiUrl.getInitiativeSubscriptions()}`);

  return response.data;
};
