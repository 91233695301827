import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import classNames from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { SastrifyStore } from 'shared/models';

import { CancelIcon, SuccessModalIcon } from '../icons';
import { ModalComponentProps } from './modal.component.props';

export const ModalComponent: FC<ModalComponentProps> = (props: ModalComponentProps) => {
  const {
    cancelButtonColor = 'secondary',
    cancelButtonText,
    children,
    closeModal,
    confirmButtonColor = 'primary',
    confirmButtonText,
    customModalContent,
    hasCustomFooter = false,
    hasCustomHeight = false,
    hasCustomWidth = false,
    isConfirmationV2Modal = false,
    isScrollable,
    isSuccessModal = false,
    isUsageLicenseModal = false,
    message,
    onCancelButtonClick,
    onClickAway,
    onConfirmButtonClick,
    overlayClass = '',
    showHeader = true,
    showSuccessIcon = false,
    subTitle,
    title,
    triggerCancelButtonClick,
  } = props;

  const modalFooterState = useSelector((state: SastrifyStore) => state.modal);
  const handleDismissModal = () => {
    if (triggerCancelButtonClick) {
      onCancelButtonClick?.();
    }
    if (!onClickAway) {
      closeModal?.();
    } else {
      onClickAway();
    }
  };

  const isPrimaryButtonDisabled = !modalFooterState?.isFormValid || modalFooterState?.isFormSubmitting;

  return (
    <DialogOverlay className={`modal-background modal-docs-background ${overlayClass}`} onDismiss={handleDismissModal}>
      <DialogContent
        aria-label='DocumentUpload'
        className={classNames('sastrify-modal', {
          'modal-card': !hasCustomWidth,
          'modal-card-confirmation-width': isConfirmationV2Modal,
          'modal-card-custom-height': hasCustomHeight,
          'modal-card-custom-width': hasCustomWidth,
          'modal-flex': isSuccessModal,
          'remove-modal-padding': !title,
          'success-modal-width': isSuccessModal,
          'usage-license-modal-width': isUsageLicenseModal,
        })}
        data-testid='modal-window'>
        {customModalContent || (
          <>
            {showHeader && (
              <header className='modal-header-section'>
                <section className='modal-card-head'>
                  <section style={{ position: 'absolute', right: '0px', top: '0px' }}>
                    <IconButton onClick={onCancelButtonClick} size='large'>
                      <CancelIcon />
                    </IconButton>
                  </section>
                </section>
                {showSuccessIcon && (
                  <Grid container alignItems='center' justifyContent='center'>
                    <SuccessModalIcon />
                  </Grid>
                )}
                {title && <h6 className='modal-card-title'>{title}</h6>}
                {subTitle && <p className='modal-card-sub-title'>{subTitle}</p>}
              </header>
            )}

            <section
              data-modal-body='modal-body'
              className={classNames({
                'border-radius-sm': !showHeader,
                'modal-card-body': !hasCustomWidth,
                'modal-card-body-custom': hasCustomWidth,
                'modal-message': message,
                'scrollable-modal-card': isScrollable,
                'success-modal-body': isSuccessModal,
              })}>
              {children || message}
            </section>

            {!hasCustomFooter && (
              <footer className='modal-card-foot is-grouped-right' style={{ paddingRight: '1.875rem' }}>
                {cancelButtonText ? (
                  <Button
                    variant='text'
                    color={cancelButtonColor}
                    sx={{ mr: 1 }}
                    data-testid='modal-cancel-button'
                    onClick={onCancelButtonClick}>
                    {cancelButtonText}
                  </Button>
                ) : undefined}
                <Button
                  variant='contained'
                  color={confirmButtonColor}
                  data-testid='modal-submit-button'
                  startIcon={modalFooterState?.isFormSubmitting && <CircularProgress color='inherit' size={16} />}
                  disabled={isPrimaryButtonDisabled}
                  className={classNames({
                    'is-loading': modalFooterState?.isFormSubmitting,
                  })}
                  onClick={onConfirmButtonClick}>
                  {confirmButtonText}
                </Button>
              </footer>
            )}
          </>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};
