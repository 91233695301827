import './subscription-covered-badge.component.scss';

import { styled } from '@mui/material/styles';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TodoCategories, TodoStates } from 'shared/common.definitions';
import { getSubscriptionSastrifyCoveredProgress, hasPendingSastrifyTasks } from 'shared/logic/subscriptions.logic';

import { SectorComponent, type SectorComponentProps } from '..';
import { SubscriptionCoveredBadgeProps } from '.';
import { BadgeComponent } from './components/badge';

const PREFIX = 'SubscriptionCoveredBadgeComponent';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
});

export const SubscriptionCoveredBadgeComponent: FC<SubscriptionCoveredBadgeProps> = (props) => {
  const {
    isSubscriptionDetailPage,
    isSubscriptionInDiscovery,
    progress: subProgress,
    size,
    subscription,
    withText,
    withTooltip,
  } = props;

  const todoCategoriesArray = [TodoCategories.ASSIGN_TOOL_OWNER, TodoCategories.RENEWAL_DATE];
  const isVisible = subscription?.todos?.filter(
    (todo) => todo.state === TodoStates.DONE && todoCategoriesArray.includes(todo.category)
  );

  // Progress control
  const subscriptionHasPendingSastrifyTasks = hasPendingSastrifyTasks(subscription);
  const showBadge = !isSubscriptionDetailPage
    ? !subscriptionHasPendingSastrifyTasks && !isSubscriptionInDiscovery
    : isVisible?.length === 2;
  const hasProgress = typeof subProgress === 'number' || subscription?.todoStatistics?.totalSastrifyCoveredBadgeTodos;

  const getProgress = useCallback(() => {
    if (typeof subProgress === 'number') {
      return Number(subProgress);
    }
    if (subscription) {
      return Number(getSubscriptionSastrifyCoveredProgress(subscription));
    }
    return 100;
  }, [subProgress, subscription]);

  const [progress, setProgress] = useState(getProgress());
  const isCompleted = hasProgress && Math.round(Number(progress)) >= 100;

  useEffect(() => {
    setProgress(getProgress());
  }, [subProgress, subscription, getProgress]);

  let thickness;
  let padding: SectorComponentProps['contentsPadding'];
  if (size && size <= 20) {
    thickness = 3;
    padding = 'small';
  }

  // Confetti control
  const confettiRef = useRef<HTMLDivElement | null>(null);
  const [confettiShown, setConfettiShown] = useState(false);

  const useProgress = (oldProgress: number) => {
    // Tracks previous/initial progress value store to detect when progress goes from <100% to 100% to show the confetti just once
    const ref = useRef<number>();
    useEffect(() => {
      ref.current = oldProgress;
    });
    return ref.current;
  };

  const previousProgress = useProgress(progress);
  const hasConfetti = typeof previousProgress !== 'undefined' && (previousProgress || 0) < 100 && progress === 100;

  useEffect(() => {
    if (hasConfetti && !confettiShown && confettiRef.current) {
      setConfettiShown(true);
      setTimeout(() => {
        const confettiComp = confettiRef.current;
        if (confettiComp) {
          confettiComp.classList.add('confetti', 'show');
        }
      }, 1000);
    }
  }, [confettiRef, confettiShown, hasConfetti]);

  return (
    <Root className='subscription-covered-badge'>
      {showBadge &&
        (hasProgress && progress < 100 ? (
          <SectorComponent size={size} progress={progress} thickness={thickness} contentsPadding={padding}>
            <BadgeComponent
              progress={progress}
              isCompleted={Boolean(isCompleted)}
              withText={Boolean(withText)}
              withTooltip={Boolean(withTooltip)}
              classes={classes}
              size={size}
              isSubscriptionDetailPage={isSubscriptionDetailPage}
            />
          </SectorComponent>
        ) : (
          <BadgeComponent
            progress={progress}
            isCompleted={Boolean(isCompleted)}
            withText={Boolean(withText)}
            withTooltip={Boolean(withTooltip)}
            classes={classes}
            size={size}
            isSubscriptionDetailPage={isSubscriptionDetailPage}
          />
        ))}

      {showBadge ? <div ref={confettiRef} /> : null}
    </Root>
  );
};
