import { combineReducers } from 'redux';

import appDrawerReducer from './appDrawer.slice';
import currentViewReducer from './currentView.slice';
import featureFlagsReducer from './featureFlags.slice';
import googleConnectReducer from './googleConnect.slice';
import googleDirectoryReducer from './googleDirectory.slice';
import jumpCloudConnectReducer from './jumpCloudConnect.slice';
import microsoftConnectReducer from './microsoftConnect.slice';
import toolsDisplayDataReducer from './toolsDisplayData.slice';
import workatoSSOConnectReducer from './workatoSSOConnect.slice';

export const CommonReducer = combineReducers({
  appDrawer: appDrawerReducer,
  currentView: currentViewReducer,
  featureFlags: featureFlagsReducer,
  googleConnect: googleConnectReducer,
  googleDirectory: googleDirectoryReducer,
  jumpCloudConnect: jumpCloudConnectReducer,
  microsoftConnect: microsoftConnectReducer,
  toolsDisplayData: toolsDisplayDataReducer,
  workatoSSOConnect: workatoSSOConnectReducer,
});
