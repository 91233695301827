import { Box, styled } from '@mui/material';

export const ItemsListWrapper = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  maxHeight: theme.spacing(35),
  width: theme.spacing(52),
  marginTop: theme.spacing(1.25),
  borderRadius: theme.spacing(0.5),
  background: theme.palette.background.paper,
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  h2: {
    margin: theme.spacing(1),
    color: 'rgba(27, 36, 43, 0.6)',
    fontWeight: '500',
    fontSize: theme.spacing(1.75),
  },
}));
