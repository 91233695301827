import { Box } from '@mui/material';
import type { NavigationItemType } from 'asteroids';
import { Dialog, LinearGradientChip, Navigation } from 'asteroids';
import { NotificationsInbox } from 'components/notifications-inbox';
import { escape } from 'libs/tools/regex-lib.tools';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MY_SUBSCRIPTIONS_STATE, MY_SUBSCRIPTIONS_TEXT, MY_TOOLS_TEXT } from 'shared/common.definitions';
import { getFirstAndLastInitialsFromText } from 'shared/helpers/common.helper';
import { Company, RoutedProps, SastrifyStore } from 'shared/models';
import { resetInProgress } from 'shared/store/common/currentView.slice';
import { useAppSelector } from 'shared/store/hooks';

import { useGetDynamicNavigationBadges } from './hooks/use-get-dynamic-navigation-badges';
import { useNavigationMainItems } from './hooks/use-navigation-main-items';
import { useNavigationSettingsItems } from './hooks/use-navigation-settings-items';
import { NavigationComponentProps } from './navigation.props';

const getSelectedAndExpandedItems = (
  items: NavigationItemType[] | undefined,
  settings: NavigationItemType[] | undefined,
  location: string
): { selectedItem: NavigationItemType | null; expandedItem: NavigationItemType | null } => {
  const isOverview = location === '/' || location === '/overview';
  const allItems = [...(items || []), ...(settings || [])];

  // Get selected item based on navigation items list data
  const selectedItems: NavigationItemType[] = [];

  // eslint-disable-next-line security/detect-non-literal-regexp
  const getUrlMatch = (url: string) => Boolean(String(location).match(new RegExp(escape(url), 'gi')));
  const getItemUrl = (item: NavigationItemType) => (typeof item.url === 'function' ? item.url(item) : item.url);

  const getSelectedItems = (items: NavigationItemType[]) => {
    items.forEach((item) => {
      const url = getItemUrl(item);
      const isUrlInCurrentAddress = url && getUrlMatch(url);

      if (isUrlInCurrentAddress) {
        selectedItems.push(item);
        return;
      }

      if (item.items) getSelectedItems(item.items);
    });
  };

  getSelectedItems(allItems);

  const selectedItem = selectedItems[0] || null; // Restricts the selected item to be the first one of all the selected items only

  // Get expanded item based on navigation items list data
  const expandedItem = isOverview ? allItems?.find((item) => item.expanded) || null : null;

  return {
    expandedItem,
    selectedItem,
  };
};

export const NavigationComponent: FC<NavigationComponentProps & RoutedProps> = (
  props: NavigationComponentProps & RoutedProps
) => {
  const { history, location, logout, pricingPlanName } = props;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const company = queryClient.getQueryData('company') as Company;
  const user = useSelector((state: SastrifyStore) => state.authentication.user);
  const { inProgress: viewIsInProgress } = useAppSelector((state) => state.common.currentView);

  const { badgeGetter } = useGetDynamicNavigationBadges();

  const [navigationConfirmationDialogOpen, setNavigationConfirmationDialogOpen] = useState(false);

  const onBeforeNavigation = (item: NavigationItemType, headerKey: string | undefined) => {
    const shouldConfirmNavigation = viewIsInProgress;
    if (shouldConfirmNavigation) {
      setCurrentNav({ headerKey, item });
      setNavigationConfirmationDialogOpen(true);
      return false;
    }
  };

  const onNavigate: (navigationItem: NavigationItemType, parentName?: string) => void = (navigationItem) => {
    if (!navigationItem) return;

    const name = typeof navigationItem.name === 'function' ? navigationItem.name() : navigationItem.name;
    const url = typeof navigationItem.url === 'function' ? navigationItem.url() : navigationItem.url;

    // Specific entries navigation logic
    const isMySubscriptionsPage = [MY_SUBSCRIPTIONS_TEXT, MY_TOOLS_TEXT].includes(name);
    if (isMySubscriptionsPage) {
      history.replace(`${url}?sort=0&toolOwner=${user?.id}`, MY_SUBSCRIPTIONS_STATE);
      return;
    }

    // General navigation logic
    if (url) {
      if (url.match(/^https?:\/\//gi)) {
        window.open(url, '_blank');
        return false;
      }

      const navigationState =
        typeof navigationItem.getNavigationState === 'function'
          ? navigationItem.getNavigationState(navigationItem)
          : undefined;

      if (!navigationState && location.pathname === url) return; // Prevents unnecessary navigation

      history.push(url, navigationState);
    }
  };

  const navHeaderList: NavigationItemType[] = useNavigationMainItems();
  const navSettingsList: NavigationItemType[] = useNavigationSettingsItems();

  // TODO: Move selected and expanded to redux store and navigate with new navigate action
  const [expanded, setExpanded] = useState<string | null | undefined>(null);
  const [selected, setSelected] = useState<string | null | undefined>(null);

  useEffect(() => {
    const { expandedItem, selectedItem } = getSelectedAndExpandedItems(
      navHeaderList,
      navSettingsList,
      location.pathname
    );

    if ((selectedItem?.key || null) !== selected) {
      setSelected(selectedItem?.key || null);
    }

    if (expandedItem) setExpanded(expandedItem?.key || null);
  }, [location.pathname, navHeaderList, navSettingsList, selected]);

  const [currentNav, setCurrentNav] = useState<{ item: NavigationItemType; headerKey: string | undefined } | null>(
    null
  );

  return (
    <>
      <Dialog
        open={navigationConfirmationDialogOpen}
        title={t('app:navigation.confirm_before_navigate.title')}
        bodyContent={t('app:navigation.confirm_before_navigate.body')}
        actions={[
          {
            actionType: 'cancel',
            label: t('app:navigation.confirm_before_navigate.cancel'),
            onClick: () => {
              setNavigationConfirmationDialogOpen(false);
              setCurrentNav(null);
            },
          },
          {
            actionType: 'confirm',
            color: 'error',
            label: t('app:navigation.confirm_before_navigate.confirm'),
            onClick: () => {
              setNavigationConfirmationDialogOpen(false);
              if (currentNav) onNavigate(currentNav?.item, currentNav?.headerKey);
              setCurrentNav(null);
              dispatch(resetInProgress());
            },
          },
        ]}
      />

      <Box sx={{ '& hr': { opacity: 1 }, zIndex: 999 }} data-testid='main-navigation'>
        <Navigation
          className='sastrify-app--navigation'
          companyName={company?.name}
          email={user?.email}
          initials={getFirstAndLastInitialsFromText(user?.name, true)}
          items={navHeaderList}
          settings={navSettingsList}
          badgeGetter={badgeGetter}
          src={user?.avatar}
          onNavigate={onNavigate}
          onLogout={logout}
          onBeforeNavigation={onBeforeNavigation}
          middlePlaceholder={<NotificationsInbox key='notifications-inbox' />}
          selected={selected}
          setSelected={setSelected}
          expanded={expanded}
          setExpanded={setExpanded}
          footerPlaceholder={
            pricingPlanName ? (
              <Box
                sx={{
                  '& .MuiChip-label': {
                    padding: 0,
                  },
                  '& .MuiTypography-root': {
                    cursor: 'pointer',
                  },
                  '& a': {
                    display: 'inline-block',
                  },
                  marginTop: 1,
                  paddingLeft: '44px',
                  textTransform: 'capitalize',
                }}
                data-testid='pricing-plan-name'>
                <Link
                  to={{ pathname: 'https://www.sastrify.com/plans' }}
                  target='_blank'
                  rel='noreferrer'
                  data-testid='prompt-view-link'>
                  <LinearGradientChip
                    withIcon
                    title={`${t(`pricing_plans:plans.${pricingPlanName}`)} ${t('pricing_plans:plans.plan')}`}
                    size='small'
                  />
                </Link>
              </Box>
            ) : undefined
          }
        />
      </Box>
    </>
  );
};
