import { SchemaField } from 'shared/models/schema-field';

type KeyValue = { [key: string]: string | undefined };

export function parseFieldSchema(schema: SchemaField[], currentValues: KeyValue = {}): SchemaField[] {
  return schema.reduce((acc: SchemaField[], item) => {
    if (!item.field_visible) {
      acc.push(item);
      return acc;
    }

    const shouldBeVisible = item.field_visible.some((rule) => {
      return Object.keys(rule).some((fieldName) => {
        let currentVerdict = true;
        const lookedUpField = getSchemaField(schema, fieldName);
        if (lookedUpField && rule[fieldName]) {
          const value = currentValues[fieldName] ?? lookedUpField.field_value;
          currentVerdict = value === rule[fieldName];
        }

        return currentVerdict;
      });
    });

    if (shouldBeVisible) {
      acc.push(item);
    }

    return acc;
  }, []);
}

function getSchemaField(fields: SchemaField[], fieldName: string): SchemaField | undefined {
  return fields.find((field) => {
    return field.field_name === fieldName;
  });
}
