import { TextField, TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { SchemaField } from 'shared/models/schema-field';

interface SchemaTextFieldProps extends Pick<SchemaField, 'field_name' | 'field_value'> {
  field_options?: TextFieldProps;
  label: string;
  error?: FieldError;
  isEditing?: boolean;
  placeholder?: string;
}

export const SchemaTextField: React.FC<SchemaTextFieldProps> = (props) => {
  const {
    error,
    field_name: name,
    field_options: options,
    field_value: value,
    isEditing,
    label,
    placeholder = '',
  } = props;

  return (
    <div className={`schema-field ${props.field_name}`} data-testid={`${props.field_name}`}>
      <label
        className={classNames('schema-field__label', !isEditing && 'schema-field__label--disabled')}
        htmlFor={name}>
        {label}
      </label>
      <TextField
        id={name}
        variant='outlined'
        size='small'
        disabled={!isEditing}
        defaultValue={isEditing ? options?.value : value}
        placeholder={placeholder}
        {...(options || {})}
      />

      {error && <div className='field-error'>{error?.message}</div>}
    </div>
  );
};
