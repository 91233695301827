import { Box, Divider, Typography } from '@mui/material';

import { UserInformationProps } from './menu-user-information.props';

export const MenuUserInformation = (props: UserInformationProps) => {
  const { companyName, email } = props;

  if (!email && !companyName) return null;

  return (
    <>
      {(email || companyName) && <Divider />}

      <Box p={2} pt={1} pb={0} data-testid='user-information-dropdown'>
        {email && (
          <Typography variant='body' data-testid='email'>
            {email}
            <br />
          </Typography>
        )}
        {companyName && (
          <Typography variant='body' color='text.secondary' data-testid='company-name'>
            {companyName}
          </Typography>
        )}
      </Box>
    </>
  );
};
