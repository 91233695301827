import './schema-field-renderer.styles.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SchemaField } from 'shared/models/schema-field';
import { DetailsSaveSchema } from 'shared/models/subscription-details.model';

import { SchemaField as SchemaFieldComponent } from './components/SchemaField.component';
import { parseFieldSchema } from './parse-fields-schema';
import { buildValidationSchema } from './validation/build-validation-schema';

interface SchemaFormFieldsRendererProps {
  schemata?: SchemaField[] | null;
  onCancelClicked(callback: () => void): void; // a bit dirty but necessary to keep this component generic
  onSaveClicked(values: DetailsSaveSchema): void;
  onFormDirtyStateUpdate(isDirty: boolean): void;
  isSaving: boolean;
  isEditing?: boolean;
  showCancelButton?: boolean;
  children: React.ReactNode;
}

export const SchemaFormFieldsRendererComponent: React.FC<SchemaFormFieldsRendererProps> = (props) => {
  const { isEditing, isSaving, onCancelClicked, onFormDirtyStateUpdate, onSaveClicked, schemata, showCancelButton } =
    props;

  const { t } = useTranslation();

  const validationSchema = useMemo(() => {
    return buildValidationSchema(schemata);
  }, [schemata]);

  const getSelectOptions = (schema: SchemaField) => {
    if (schema.field_options?.select_options) {
      return schema.field_options.select_options as string[];
    }
  };

  const [fields, setFields] = React.useState(schemata);

  const defaultValues = useMemo(() => {
    return schemata?.reduce((acc, schema) => {
      acc[String(schema.field_name)] = schema.field_value;
      return acc;
    }, {} as DetailsSaveSchema);
  }, [schemata]);

  const {
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({
    defaultValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleSave = handleSubmit(onSaveClicked);

  watch((values) => {
    const parsedFields = parseFieldSchema(schemata ?? [], values);
    setFields(parsedFields);
  });

  useEffect(() => {
    const parsedFields = parseFieldSchema(schemata ?? [], {});
    setFields(parsedFields);
  }, [schemata]);

  useEffect(() => {
    onFormDirtyStateUpdate(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  const handleCancelClick = () => {
    // reset form to last known defaultValues on cancel confirmed
    onCancelClicked(() => reset(defaultValues));
  };

  return (
    <div
      data-testid='subscription-overview-details-form' // if the component will be used in other places, this test id should be changed
      data-test-id='SchemaFormFieldsRenderer'
      className='SchemaFieldsRenderer'>
      <form onSubmit={handleSave}>
        <div className='SchemaFieldsRenderer__container'>
          {fields?.map((data) => {
            return (
              <SchemaFieldComponent
                key={data.field_name}
                fieldName={data.field_name}
                fieldValue={data.field_value}
                fieldType={data.field_type}
                isEditing={isEditing || false}
                error={errors[data.field_name]}
                fieldOptions={register(data.field_name)}
                selectOptions={getSelectOptions(data)}
              />
            );
          })}
        </div>
        {props.children}

        {isEditing && (
          <div className='SchemaFieldsRenderer__action-buttons'>
            {showCancelButton && ( // one extra rule for cancel button for more flexibility on defining show/hide logic
              <Button
                variant='text'
                color='secondary'
                className='SchemaFieldsRenderer__cancel'
                onClick={handleCancelClick}
                data-testid='cancel-button'
                disabled={isSaving}>
                {t('common_labels:cancel')}
              </Button>
            )}
            <Button
              variant='contained'
              color='primary'
              startIcon={isSaving ? <CircularProgress color='inherit' size={16} /> : null}
              onClick={handleSave}
              disabled={isSaving}
              data-testid='save-button'>
              {isSaving ? t('common_labels:saving') : t('common_labels:save')}
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};
