import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Outcome = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.secondary.main}`,
  marginRight: `-${theme.spacing(10)}`,
  padding: theme.spacing(1),
}));

export const InitiativeDetailsDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(2)} -${theme.spacing(10)} ${theme.spacing(2)} 0`,
}));

export const DetailsWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(10),
  padding: theme.spacing(1),
}));
