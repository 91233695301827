import './confirmation-modal.component.scss';

import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModalComponentProps } from './confirmation-modal.component.props';

export const ConfirmationModalComponent: FC<ConfirmationModalComponentProps> = (props) => {
  const { t } = useTranslation();
  const {
    cancelButtonLabel = t('common:modals.confirmation_modal.cancel_button_text'),
    confirmButtonLabel = t('common:modals.confirmation_modal.confirm_button_text'),
    description,
    level = 'info',
    onCancel,
    onClose,
    onConfirm,
    title,
  } = props;

  const handleOnCancelClick = () => {
    onClose?.();
    onCancel?.();
  };

  const handleOnConfirmClick = () => {
    onClose?.();
    onConfirm?.();
  };

  return (
    <Box className={`confirmation-modal-wrapper ${level}`}>
      <p className='modal-title'>{title}</p>
      <p className='modal-description'>{description}</p>
      <Box className='action-buttons-wrapper'>
        <Button
          onClick={handleOnCancelClick}
          className='action-button-cancel'
          variant='text'
          data-testid='modal-cancel-button'>
          {cancelButtonLabel}
        </Button>
        <Button
          variant='contained'
          color='tertiary'
          onClick={handleOnConfirmClick}
          className='action-button'
          data-testid='modal-confirm-button'
          disableRipple>
          {confirmButtonLabel}
        </Button>
      </Box>
    </Box>
  );
};
