import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useModalAndNotification } from 'hooks/useModalAndNotification';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ReplyableType, RESPONSE_CODE } from 'shared/common.definitions';
import { updateReplyable } from 'shared/logic/replyable.logic';
import { queryClient } from 'shared/reactQuery';

interface UpdateReplyPayload {
  text: string;
  replyId: string;
}

interface UpdateReplyCallbacksPayload {
  onSuccess?(): void;
  onError?(): void;
  onSettled?(): void;
}

export function useEditReply(commentId: string, payload: UpdateReplyCallbacksPayload = {}) {
  const { onShowNotification } = useModalAndNotification();
  const { t } = useTranslation();

  return useMutation(
    (payload: UpdateReplyPayload) =>
      updateReplyable({
        replyId: payload.replyId,
        replyable: {
          comment: payload.text,
        },
        replyableId: commentId,
        replyableType: ReplyableType.SubscriptionComments,
      }),
    {
      onError: (error: any) => {
        if (payload.onError) {
          payload.onError();
        }

        if (error?.response?.status === RESPONSE_CODE.Forbidden.code) {
          onShowNotification?.(
            t('subscription_detail_view:reply_toast.edit_forbidden_error'),
            NotificationAlertType.Error
          );
        } else {
          onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
        }
      },
      onSettled: () => {
        if (payload.onSettled) {
          payload.onSettled();
        }
      },
      onSuccess: () => {
        if (payload.onSuccess) {
          payload.onSuccess();
        }

        onShowNotification(t('subscription_detail_view:reply_toast.edit_success'), NotificationAlertType.Success);

        queryClient.invalidateQueries([ReplyableType.SubscriptionComments, commentId]);
        queryClient.invalidateQueries('subscription-history');
      },
    }
  );
}
