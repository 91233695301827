import { sub } from 'date-fns';
import * as yup from 'yup';

import { alternativeToolsDefaultValue } from './tool-request-multitool-form.constants';
import { FormValues } from './tool-request-multitool-form.props';

export const defaultFormValues: FormValues = {
  alternativeTools: alternativeToolsDefaultValue,
  collaborators: [],
  cost: '',
  description: '',
  dueDate: undefined,
  mainTool: null,
  numberOfLicenses: '',
  paymentFrequency: null,
};

export const formValidationSchema = yup.object().shape({
  dueDate: yup.date().min(sub(new Date(), { days: 1 })),
  mainTool: yup.mixed().required(),
});
