import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const defaultLng = 'en';
const supportedLngs = ['en'];
// eslint-disable-next-line no-undef
const ns = __translationNamespaces; // __translationNamespaces is coming from webpack.config.js/vite.config.js

const resources = ns.reduce((acc, n) => {
  supportedLngs.forEach(async (lng) => {
    const json = await import(`./locales/${lng}/${n}.json`);

    if (!acc[lng]) acc[lng] = {};

    if (n === 'translations') {
      acc[lng] = {
        ...acc[lng],
        ...(json || {}),
      };
    } else {
      acc[lng] = {
        ...acc[lng],
        [n]: json || {},
      };
    }
  });

  return acc;
}, {});

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'translations',
    fallbackLng: defaultLng,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    ns: ['translations'],
    preload: [defaultLng],
    resources,
    supportedLngs,
  });

export default i18next;
