/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Autocomplete, AutocompleteOption, Avatar, DatePicker, InputField, SubscriptionStatus } from 'asteroids';
import { AxiosError } from 'axios';
import { NumberInput } from 'components/drawer-workflow-request/components/number-input/number-input.component';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { format } from 'date-fns';
import { useFetchSubscription } from 'hooks/useFetchSubscription';
import { ActivateToolPayload } from 'libs/dtos';
import { REQUEST_STATES, REQUEST_WORKFLOW_TYPE } from 'libs/enums';
import { RENEWAL_FREQUENCY, RENEWAL_PERIOD } from 'libs/enums/subscription.enum';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { CSM_USER_EMAIL } from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { activateTool } from 'shared/logic/requests.logic';
import { Company, RequestDetails, User } from 'shared/models';
import { useWorkflowRequestDetailsData, useWorkflowRequestsData } from 'views/requests/hooks';

const PREFIX = 'ActivateToolComponent';

const classes = {
  formWrapper: `${PREFIX}-formWrapper`,
};

const StyledBox = styled(Box)({
  [`& .${classes.formWrapper}`]: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
});

interface ActivateToolProps {
  closeDialog: (() => void) | undefined;
  showNotification: ((message: string, type: NotificationAlertType) => void) | undefined;
  requestId: string;
  initiativeDetails?: RequestDetails;
}

interface FormValues {
  owner: AutocompleteOption | null;
  renewalDate: Date | null;
  renewalFrequency: AutocompleteOption | null;
  renewalPeriod: AutocompleteOption | null;
  renewalPeriodLength: string;
  tags: AutocompleteOption[];
}

const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

const renewalFrequencyItems: AutocompleteOption[] = [
  {
    id: RENEWAL_FREQUENCY.MONTHLY,
    label: capitalize(RENEWAL_FREQUENCY.MONTHLY),
  },
  {
    id: RENEWAL_FREQUENCY.YEARLY,
    label: capitalize(RENEWAL_FREQUENCY.YEARLY),
  },
  {
    id: RENEWAL_FREQUENCY.OTHER,
    label: capitalize(RENEWAL_FREQUENCY.OTHER),
  },
];

const renewalPeriodItems: AutocompleteOption[] = [
  {
    id: RENEWAL_PERIOD.MONTHS,
    label: capitalize(RENEWAL_PERIOD.MONTHS),
  },
  {
    id: RENEWAL_PERIOD.YEARS,
    label: capitalize(RENEWAL_PERIOD.YEARS),
  },
];

const defaultFormValues: FormValues = {
  owner: null,
  renewalDate: null,
  renewalFrequency: null,
  renewalPeriod: renewalPeriodItems[0],
  renewalPeriodLength: '',
  tags: [],
};

export const ActivateToolComponent: React.FC<ActivateToolProps> = ({
  closeDialog,
  initiativeDetails,
  requestId,
  showNotification,
}) => {
  const [companyTags, setCompanyTags] = useState<AutocompleteOption[]>([]);
  const [renewalFrequency, setRenewalFrequency] = useState<AutocompleteOption | null>(null);
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  const trackRenewalFrequency = getValues().renewalFrequency;

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const subscriptionId = (initiativeDetails?.childRequests || []).find(
    (request) => String(request.requestId) === String(requestId)
  )?.subscriptionId;

  const { data: subscription, refetch: refetchSubscription } = useFetchSubscription(subscriptionId || '');

  const company = queryClient.getQueryData<Company>('company');
  const users = queryClient.getQueryData<User[]>('users');

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section';

  const formattedCompanyTags = useMemo(() => {
    if (!company?.companyTags) return [];
    return company.companyTags.map((tag, index) => ({
      id: String(index),
      label: tag,
    }));
  }, [company]);

  const { refetch: refetchDetails } = useWorkflowRequestDetailsData(requestId, false);

  const { refetch: refetchInitiatives } = useWorkflowRequestsData();

  const { isLoading: isActivating, mutate: setActivateTool } = useMutation(
    (payload: ActivateToolPayload) => activateTool(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Activate tool error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async () => {
        await refetchSubscription();
        await refetchDetails();

        const shouldRefetchInitiatives =
          (initiativeDetails?.childRequests || []).filter(
            ({ requestStateId }) => requestStateId === String(REQUEST_STATES.APPROVED)
          ).length === 1;

        if (shouldRefetchInitiatives) {
          await refetchInitiatives();
        }

        closeDialog?.();
      },
    }
  );

  const sortedUsers = useMemo(() => users?.filter((user: User) => !user.isDeleted).sort() || [], [users]);

  const getUsers = useMemo(
    () =>
      sortedUsers
        ?.filter((user: User) => !user.email?.includes(CSM_USER_EMAIL))
        .map(({ avatar, avatarUrl, email, id, name }: User) => ({
          id,
          label: `${sortedUsers.filter((user) => user.name === name).length > 1 ? `${name} - ${email}` : name}`,
          prefix: <Avatar src={avatarUrl || avatar} sx={{ mr: 2 }} />,
        })),
    [sortedUsers]
  );

  useEffect(() => {
    if (subscription) {
      if (subscription.tags?.length) {
        const formattedTags = formattedCompanyTags.filter((tag) => !subscription.tags?.includes(tag.label));
        const newCompanyTags = subscription.tags.map((tag, index) => ({
          id: String(formattedTags.length + index),
          label: tag,
        }));

        setCompanyTags([...formattedTags, ...newCompanyTags]);
        setValue('tags', newCompanyTags);
      }

      if (subscription.ownerName && subscription.ownerId) {
        const user = getUsers.find((user) => user.id === subscription.ownerId);
        if (user) {
          setValue('owner', user);
        }
      }

      switch (subscription.billingFrequency) {
        case 0:
          setRenewalFrequency(renewalFrequencyItems[0]);
          setValue('renewalFrequency', renewalFrequencyItems[0]);
          break;
        case 1:
          setRenewalFrequency(renewalFrequencyItems[1]);
          setValue('renewalFrequency', renewalFrequencyItems[1]);
          if (subscription?.renewalDate) setValue('renewalDate', new Date(subscription?.renewalDate));
          break;
        case 3:
          setRenewalFrequency(renewalFrequencyItems[2]);
          setValue('renewalFrequency', renewalFrequencyItems[2]);
          if (subscription.renewalInterval) {
            const [period, length] = Object.entries(subscription.renewalInterval)[0];
            setValue('renewalPeriod', period === 'months' ? renewalPeriodItems[0] : renewalPeriodItems[1]);
            setValue('renewalPeriodLength', String(length));
          }
          if (subscription.renewalDate) setValue('renewalDate', new Date(subscription.renewalDate));
          break;

        default:
          break;
      }
    }
  }, [subscription, formattedCompanyTags, setValue, getUsers]);

  useEffect(() => {
    setCompanyTags(formattedCompanyTags);
  }, [formattedCompanyTags]);

  useEffect(() => {
    trigger();
  }, [trackRenewalFrequency, trigger]);

  const handleActivateTool = useCallback(() => {
    const formValues = getValues();

    const payload = {
      ...(formValues.owner && {
        ownerUserId: Number(formValues.owner.id),
      }),
      ...(formValues.renewalDate && {
        renewalDate: format(new Date(formValues.renewalDate), 'yyyy-MM-dd'),
      }),
      requestId: Number(requestId),
      status: 'active',
      ...(getValues().tags.length > 0 && {
        tags: [...getValues().tags].map((tag) => tag.label),
      }),
      ...(formValues.renewalFrequency && { renewalFrequency: formValues.renewalFrequency.id }),
      ...(formValues.renewalFrequency?.id === 'other' &&
        formValues.renewalPeriod && { renewalPeriod: formValues.renewalPeriod.id }),
      ...(formValues.renewalFrequency?.id === 'other' &&
        formValues.renewalPeriodLength && { renewalPeriodLength: formValues.renewalPeriodLength }),
    };

    if (!Object.keys(errors).length) {
      setActivateTool(payload);
    }
  }, [getValues, requestId, setActivateTool, errors]);

  const renderTitleAndDescription = useMemo(() => {
    const initiativesTitle =
      initiativeDetails?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL
        ? t(`${pathToTranslation}.request_details_section.activate_tool_form.title_initiatives_renewal`)
        : t(`${pathToTranslation}.request_details_section.activate_tool_form.title_initiatives_purchase`);
    const initiativesMessage =
      initiativeDetails?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL
        ? t(`${pathToTranslation}.request_details_section.activate_tool_form.message_initiatives_renewal`)
        : t(`${pathToTranslation}.request_details_section.activate_tool_form.message_initiatives_purchase`);

    return (
      <>
        <Typography variant='h2' mb={2} data-testid='initiatives-activate-modal-title'>
          {initiativesTitle}
        </Typography>
        <Typography
          component='p'
          variant='body1'
          color='success'
          fontWeight={300}
          mb={3}
          data-testid='activate-tool-modal-description'>
          {initiativesMessage}
        </Typography>
      </>
    );
  }, [t, initiativeDetails?.requestWorkflowTypeId]);

  const renderStatusField = useMemo(
    () => (
      <InputField
        label={t(`${pathToTranslation}.request_details_section.activate_tool_form.fields.status.label`)}
        size='medium'
        startAdornment={<SubscriptionStatus status={'active' as any} title='Active' />}
        readOnly
        disabled
      />
    ),
    [t]
  );

  const renderTagsField = useMemo(
    () => (
      <Controller
        name='tags'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='tags'
              filterSelectedOptions
              value={value}
              options={companyTags}
              label={t(`${pathToTranslation}.request_details_section.activate_tool_form.fields.tags.label`)}
              multiple
              freeSolo
              onChange={(_, newTags, reason) => {
                if (reason === 'createOption' && Array.isArray(newTags)) {
                  const autocompleteOptionTags = newTags.slice(0, -1) as AutocompleteOption[];
                  const lastItem = newTags[newTags.length - 1] as string;
                  const lastAutocompleteOptionTag: AutocompleteOption = {
                    id: `${autocompleteOptionTags.length}-${lastItem}`,
                    label: lastItem,
                  };
                  const tags: AutocompleteOption[] = [...autocompleteOptionTags, lastAutocompleteOptionTag];

                  return onChange(tags);
                }

                onChange(newTags);
              }}
            />
          );
        }}
      />
    ),
    [companyTags, control, t]
  );

  const renderToolOwnerField = useMemo(
    () => (
      <Controller
        name='owner'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='owner'
              label={t(`${pathToTranslation}.request_details_section.activate_tool_form.fields.owner.label`)}
              value={value}
              size='medium'
              options={getUsers as AutocompleteOption[]}
              onChange={(_, value) => value && onChange(value)}
            />
          );
        }}
      />
    ),
    [control, getUsers, t]
  );

  const renderRenewalFrequencyField = useMemo(
    () => (
      <Controller
        name='renewalFrequency'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='renewal-frequency'
              data-testid='renewal-frequency-select'
              label={t(
                `${pathToTranslation}.request_details_section.activate_tool_form.fields.renewal_frequency.label`
              )}
              value={value}
              size='medium'
              options={renewalFrequencyItems}
              onChange={(_, value) => {
                setRenewalFrequency(value as any);
                setValue('renewalDate', null);
                setValue('renewalPeriod', renewalPeriodItems[0]);
                setValue('renewalPeriodLength', '');
                onChange(value);
              }}
            />
          );
        }}
      />
    ),
    [control, setValue, t]
  );

  const renderRenewalPeriodField = useMemo(
    () => (
      <Controller
        name='renewalPeriod'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='paymentFrequency'
              data-testid='payment-frequency-select'
              fullWidth
              disableClearable
              label={t(`${pathToTranslation}.request_details_section.activate_tool_form.fields.renewal_period.label`)}
              value={value}
              size='medium'
              options={renewalPeriodItems}
              onChange={(_, value) => onChange(value)}
            />
          );
        }}
      />
    ),
    [control, t]
  );

  const renderRenewalPeriodLengthField = useMemo(
    () => (
      <Controller
        name='renewalPeriodLength'
        control={control}
        rules={{
          validate: {
            required: (value) => {
              const { renewalFrequency } = getValues();

              return renewalFrequency?.id === 'other' && Boolean(value);
            },
          },
        }}
        render={({ field: { onChange, value }, fieldState }) => {
          return (
            <NumberInput
              name='renewalPeriodLength'
              value={value}
              onValueChange={({ formattedValue: value }) => onChange(value)}
              onClear={() => onChange('')}
              label={t(
                `${pathToTranslation}.request_details_section.activate_tool_form.fields.renewal_period_length.label`
              )}
              error={fieldState.invalid}
            />
          );
        }}
      />
    ),
    [control, t, getValues]
  );

  const renderRenewalDateField = useMemo(
    () => (
      <StyledBox data-testid='renewal-date'>
        <Controller
          name='renewalDate'
          control={control}
          rules={{
            validate: {
              required: (value) => {
                const { renewalFrequency } = getValues();

                return (renewalFrequency?.id === 'yearly' || renewalFrequency?.id === 'other') && Boolean(value);
              },
            },
          }}
          render={({ field: { onChange, value }, fieldState }) => (
            <DatePicker
              value={value as any}
              onChange={(newValue) => onChange(newValue)}
              minDate={new Date() as any}
              label={t(`${pathToTranslation}.request_details_section.activate_tool_form.fields.renewal_date.label`)}
              inputFormat='dd/MM/yyyy'
              error={fieldState.invalid}
            />
          )}
        />
      </StyledBox>
    ),
    [control, t, getValues]
  );

  const renderRenewalField = useMemo(
    () => (
      <>
        {renderRenewalFrequencyField}
        {renewalFrequency?.id === 'other' && (
          <Stack direction='row' spacing={2}>
            {renderRenewalPeriodField}
            {renderRenewalPeriodLengthField}
          </Stack>
        )}
        {(renewalFrequency?.id === 'yearly' || renewalFrequency?.id === 'other') && renderRenewalDateField}
      </>
    ),
    [
      renewalFrequency?.id,
      renderRenewalDateField,
      renderRenewalFrequencyField,
      renderRenewalPeriodField,
      renderRenewalPeriodLengthField,
    ]
  );

  const renderActionButtons = useMemo(() => {
    const buttonLabel = t(
      `${pathToTranslation}.request_details_section.activate_tool_form.action_btn_label_initiatives`
    );
    return (
      <Stack direction='row-reverse' spacing={1} mt={4}>
        <Button
          data-testid='activate-tool-modal-btn'
          color='primary'
          variant='contained'
          onClick={handleActivateTool}
          disabled={isActivating}>
          {isActivating ? <CircularProgress color='inherit' size={20} /> : buttonLabel}
        </Button>
        <Button data-testid='dismiss-btn' color='secondary' variant='text' onClick={() => closeDialog?.()}>
          {t(`${pathToTranslation}.request_details_section.activate_tool_form.cancel_btn_label`)}
        </Button>
      </Stack>
    );
  }, [handleActivateTool, closeDialog, isActivating, t]);

  return (
    <Box m={1}>
      {renderTitleAndDescription}
      <Stack spacing={3} className={classes.formWrapper}>
        {renderStatusField}
        {renderTagsField}
        {renderToolOwnerField}
        {renderRenewalField}
      </Stack>
      {renderActionButtons}
    </Box>
  );
};
