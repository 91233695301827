/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert } from 'asteroids';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoBoxWrapper } from './new-details-look-info-box.styles';

const SastriverseProcurementInitiativesLink =
  'https://support.sastrify.com/support/solutions/articles/101000503985-beta-how-to-include-multiple-tools-in-procurement-initiatives';

export const NewDetailsLookInfoBox = () => {
  const { t } = useTranslation();
  const [isInfoBoxDismissed, setIsInfoBoxDismissed] = useState(
    localStorage.getItem('newInitiativeDetailsLookInfoBoxDismissed') === 'true'
  );

  const handleOnDismiss = () => {
    setIsInfoBoxDismissed(true);
    localStorage.setItem('newInitiativeDetailsLookInfoBoxDismissed', 'true');
  };

  return (
    <>
      {!isInfoBoxDismissed && (
        <InfoBoxWrapper mb={3}>
          <Alert
            description={
              (
                <Trans
                  i18nKey='subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.new_details_look_info_box.message'
                  components={{
                    Link: (
                      <a target='_blank' rel='noreferrer' href={SastriverseProcurementInitiativesLink}>
                        {t(
                          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.new_details_look_info_box.link_placeholder_text'
                        )}
                      </a>
                    ),
                  }}
                />
              ) as any
            }
            actionText=''
            isIconVisible
            severity='info'
            title={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.new_details_look_info_box.title'
            )}
            onAction={() => handleOnDismiss()}
          />
        </InfoBoxWrapper>
      )}
    </>
  );
};
