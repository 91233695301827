import React from 'react';
import { colors } from 'shared/theme';

export const AccountIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill }) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.0869 7.39929C9.63838 6.96541 10.0409 6.37044 10.2385 5.69713C10.4361 5.02383 10.4189 4.30569 10.1893 3.64262C9.95971 2.97954 9.52917 2.40451 8.95757 1.99752C8.38597 1.59053 7.70172 1.37183 7.00003 1.37183C6.29834 1.37183 5.61409 1.59053 5.04249 1.99752C4.47089 2.40451 4.04035 2.97954 3.81077 3.64262C3.5812 4.30569 3.564 5.02383 3.76158 5.69713C3.95916 6.37044 4.36168 6.96541 4.91315 7.39929C3.9682 7.77788 3.14369 8.4058 2.52753 9.21612C1.91137 10.0264 1.52666 10.9888 1.4144 12.0005C1.40628 12.0744 1.41278 12.1492 1.43354 12.2205C1.4543 12.2919 1.48892 12.3584 1.5354 12.4164C1.62929 12.5335 1.76584 12.6085 1.91503 12.6249C2.06421 12.6413 2.21381 12.5978 2.3309 12.5039C2.44799 12.41 2.52299 12.2735 2.5394 12.1243C2.66292 11.0247 3.18723 10.0092 4.01215 9.27174C4.83708 8.53429 5.90479 8.12662 7.01128 8.12662C8.11777 8.12662 9.18548 8.53429 10.0104 9.27174C10.8353 10.0092 11.3596 11.0247 11.4832 12.1243C11.4984 12.2625 11.5644 12.3902 11.6683 12.4826C11.7722 12.5751 11.9066 12.6258 12.0457 12.6249H12.1075C12.255 12.608 12.3898 12.5334 12.4825 12.4175C12.5752 12.3016 12.6183 12.1537 12.6025 12.0062C12.4897 10.9915 12.103 10.0267 11.4836 9.21516C10.8643 8.40359 10.0358 7.77587 9.0869 7.39929ZM7.00003 6.99992C6.55502 6.99992 6.12001 6.86796 5.75 6.62072C5.37999 6.37349 5.0916 6.02209 4.9213 5.61096C4.751 5.19982 4.70645 4.74742 4.79326 4.31096C4.88008 3.87451 5.09437 3.4736 5.40904 3.15893C5.72371 2.84426 6.12462 2.62997 6.56108 2.54315C6.99753 2.45633 7.44993 2.50089 7.86107 2.67119C8.2722 2.84149 8.6236 3.12987 8.87084 3.49988C9.11807 3.86989 9.25003 4.30491 9.25003 4.74992C9.25003 5.34665 9.01298 5.91895 8.59102 6.34091C8.16906 6.76287 7.59677 6.99992 7.00003 6.99992Z'
      fill={fill}
    />
  </svg>
);

AccountIcon.defaultProps = {
  fill: colors.white,
};
