import { Tooltip } from '@mui/material';
import { Icon } from 'asteroids';
import { useTranslation } from 'react-i18next';
import { RequestDocument } from 'shared/models';

import { FileItemComponentProps } from './file-item.component.props';
import { FileIcon, FileItem, FileName, FlexBox, FlexBoxAlignRight } from './file-item.component.styles';

const FILE_ICON = 'feed';
const FILE_DOWNLOAD_ICON = 'file_download';
const DELETE_ICON = 'delete';

export const FileItemComponent: React.FC<FileItemComponentProps> = ({
  disableDownloadButton,
  file,
  onDownload,
  onRemove,
  textTruncate,
}) => {
  const { t } = useTranslation();
  const fileName = (file as File).name || (file as RequestDocument).fileName || '';
  const fileUrl = (file as RequestDocument).url || '';
  const fileId = (file as RequestDocument).id || '';
  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section';

  return (
    <FileItem>
      <FlexBox>
        <FileIcon>
          <Icon size='small' color='success'>
            {FILE_ICON}
          </Icon>
        </FileIcon>
        <Tooltip placement='bottom' title={textTruncate ? fileName : ''}>
          <FileName data-testid='file-name' variant='label' noWrap width={textTruncate ? 160 : 'unset'}>
            {fileName}
          </FileName>
        </Tooltip>
      </FlexBox>
      <FlexBoxAlignRight id='file-item__actions'>
        {onDownload && fileName && fileUrl && (
          <Tooltip
            title={
              !disableDownloadButton
                ? t(`${pathToTranslation}.right_section.workflow_request_section.documents_section.download_btn_label`)
                : t(
                    `${pathToTranslation}.right_section.workflow_request_section.documents_section.download_active_btn_label`
                  )
            }
            placement='bottom'>
            <FlexBox>
              <Icon
                onClick={() => (!disableDownloadButton ? onDownload(fileName, fileUrl, fileId) : null)}
                size='small'
                data-testid='download-file'>
                {FILE_DOWNLOAD_ICON}
              </Icon>
            </FlexBox>
          </Tooltip>
        )}
        {onRemove && (
          <Tooltip
            title={t(`${pathToTranslation}.right_section.workflow_request_section.documents_section.remove_btn_label`)}
            placement='bottom'>
            <FlexBox>
              <Icon onClick={onRemove} size='small' data-testid='delete-file'>
                {DELETE_ICON}
              </Icon>
            </FlexBox>
          </Tooltip>
        )}
      </FlexBoxAlignRight>
    </FileItem>
  );
};
