import { ConnectedSystemEntityMatchListDto } from 'libs/dtos';
import { ModifyToolMatchingPayload } from 'shared/models/tool-matching.model';

import { apiService, apiUrl } from '../services';

export const getToolMatchings = async (): Promise<ConnectedSystemEntityMatchListDto> => {
  const response = await apiService.get(`${apiUrl.toolMatchings()}`);

  return response.data;
};

export const modifyToolMatchings = async (payload: ModifyToolMatchingPayload[]): Promise<boolean> => {
  const response = await apiService.patch(apiUrl.toolMatchings(), payload);

  return response.data;
};
