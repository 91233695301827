import { TextField } from '@mui/material';
import { Autocomplete, AutocompleteOption, Avatar } from 'asteroids';
import { ListboxComponent } from 'components/listbox';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Subscription } from 'shared/models';

type SubscriptionAutocompleteItem = AutocompleteOption & {
  subscription?: Subscription | null;
};

interface SubscriptionAutocompleteProps extends Omit<React.ComponentProps<typeof Autocomplete>, 'options' | 'label'> {
  onSelectSubscription: (
    _e: SyntheticEvent<Element, Event>,
    option: SubscriptionAutocompleteItem | SubscriptionAutocompleteItem[] | null
  ) => void;
  subscriptionValue: Subscription | Subscription[] | undefined | null;
  enableValidation?: boolean;
  useNewSolutions?: boolean;
  allToolsOption?: boolean;
  label?: React.ComponentProps<typeof Autocomplete>['label'];
}

export const SubscriptionAutocomplete: FC<SubscriptionAutocompleteProps> = (props) => {
  const {
    allToolsOption,
    disableClearable = true,
    enableValidation = false,
    helperText,
    label,
    multiple,
    onSelectSubscription,
    size = 'small',
    subscriptionValue,
    useNewSolutions = false,
    ...rest
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const subscriptionsData = queryClient.getQueryData<Subscription[]>('subscriptions');
  const allSubscriptionsData = queryClient.getQueryData<Subscription[]>('subscriptions-and-new-solutions');
  const data = useNewSolutions ? allSubscriptionsData : subscriptionsData;

  const getOptionFromSubscription = (subscription: Subscription) =>
    ({
      id: subscription.id,
      label: subscription.name,
      prefix: <Avatar fitImage alt={subscription.name} src={subscription.vendorLogoUrl} variant='circular' />,
      sublabel: subscription.categoryName,
      subscription,
    } as SubscriptionAutocompleteItem);

  const subscriptionsList = useMemo(() => {
    const allData =
      data?.sort((a, b) => String(a.name).localeCompare(String(b.name))).map(getOptionFromSubscription) || [];
    if (allToolsOption) {
      allData.unshift({
        id: '__allTools__',
        label: t('spend_import_view:to_review.modals.review_automated_import.table.all_tools'),
      });
    }
    return allData;
  }, [allToolsOption, data, t]);

  const inputLabel = label || t('spend_import_view:to_review.modals.review_automated_import.table.select_tool_name');
  const inputLabelId = `${String(inputLabel).toLowerCase().replace(' ', '-')}-select`;
  const inputPlaceholder =
    label || t('spend_import_view:to_review.modals.review_automated_import.table.select_tool_name');

  const renderInput = useCallback(
    (params) => (
      <TextField
        ref={params.InputProps.ref}
        {...params}
        label={inputLabel}
        helperText={helperText}
        placeholder={inputPlaceholder}
        variant='outlined'
        error={enableValidation && !subscriptionValue}
        InputProps={{
          ...params.InputProps,
        }}
      />
    ),
    [inputLabel, helperText, inputPlaceholder, enableValidation, subscriptionValue]
  );

  const getOptionForSubscription = (subscription: Subscription) => {
    const { id } = subscription;
    return subscriptionsList.find((option) => option.id === id);
  };

  let value = null;

  if (multiple) {
    if (!subscriptionValue) {
      value = [];
    } else if (!Array.isArray(subscriptionValue)) {
      value = [getOptionForSubscription(subscriptionValue as Subscription)];
    } else {
      value = subscriptionValue.map(getOptionForSubscription).filter((o) => !!o);
    }
  } else if (subscriptionValue) {
    value = getOptionForSubscription(subscriptionValue as Subscription);
  } else {
    value = null;
  }

  return (
    <Autocomplete
      {...rest}
      multiple={multiple}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      className='tool-column--select'
      data-testid='tool-name-select'
      data-pw-id={inputLabelId}
      label={inputLabel}
      value={value}
      size={size}
      options={subscriptionsList}
      disableClearable={disableClearable}
      onChange={onSelectSubscription as React.ComponentProps<typeof Autocomplete>['onChange']}
      renderInput={renderInput}
    />
  );
};
