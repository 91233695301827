/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, CircularProgress, FormGroup, Typography } from '@mui/material';
import { Alert } from 'asteroids';
import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useUser } from 'hooks/useUser';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { DRAWER_VARIANT, REQUEST_STATUS, TOOLS_TYPE_FILTER, UPDATE_REQUEST_TYPE } from 'shared/common.definitions';
import { getHash } from 'shared/helpers/common.helper';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import {
  trackMultitoolInitiativeToolRemoved,
  trackWorkflowRequestCommentAdded,
} from 'shared/logic/event-tracking/workflow-requests.events';
import {
  createRequestComment,
  deleteRequest,
  deleteRequestComment,
  deleteRequestDocument,
  getRequestWorkflows,
  reopenRequest,
  updateMultiToolsRequestStatus,
  updateRequestComment,
  updateRequestStatus,
  uploadRequestDocument,
  uploadRequestInitiativeDocument,
} from 'shared/logic/requests.logic';
import {
  Company,
  ModifyRequestCommentPayload,
  RequestDetails,
  UpdateMultiToolsRequestStatusPayload,
  UpdateRequestStatusPayload,
} from 'shared/models';
import { AppUrl } from 'src/constants/appurl';
import { invalidateRenewalReminder } from 'views/subscription-detail/components/renewal-status-alert/helpers';

import { useAsteroidsDialog } from '../../context/asteroids-dialog.context';
import { usePersistentSearchFilters } from '../../hooks';
import {
  useAddRequestDrawer,
  useRequestInitiativesData,
  useWorkflowActivitiesData,
  useWorkflowRequestDetailsData,
} from '../../views/requests/hooks';
import { Checkbox } from '../checkbox';
import {
  ActivitiesComponent,
  DialogComponent,
  DialogComponentProps,
  InitiativeToolsComponent,
  RichTextEditorComponent,
} from '../drawer-workflow-request-details/components';
import { ActivateToolComponent } from './components/activate-tool/activate-tool.component';
import { InitiativeDetails } from './components/initiative-details/initiative-details.component';
import { TransitionTool } from './components/transition-tool/transition-tool.component';
import { DrawerMultiToolInitiativeDetailsProps } from './drawer-multi-tool-initiative-details.props';

type ToolStoreSearchFilters = {
  searchTerm: string;
  toolTypeFilter: TOOLS_TYPE_FILTER;
};

type ToolType = {
  id: string;
  label: string;
  name: string;
  checked: boolean;
  disabled: boolean;
};

export const DrawerMultiToolInitiativeDetails: FC<DrawerMultiToolInitiativeDetailsProps> = ({
  closeDrawer,
  showNotification,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const { hash, pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const user = useUser();
  const { onAddRequestDrawer } = useAddRequestDrawer();
  const { closeDialog: closeAsteroidsDialog, openDialog: openAsteroidsDialog } = useAsteroidsDialog();

  const [expandedTools, setExpandedTools] = useState(new Map());
  const [expandedInvolvements, setExpandedInvolvements] = useState(new Map());
  const [actionText, setActionText] = useState('');
  const [commentText, setCommentText] = useState('');
  const [errorActionText, setErrorActionText] = useState('');
  const [dialog, setDialog] = useState<DialogComponentProps>({
    open: false,
  });
  const [tools, setTools] = useState<ToolType[]>([]);
  const toolsRef = useRef<HTMLDivElement>(null);

  const requestId = hash.split(DRAWER_VARIANT.REQUEST_DETAILS).slice(-1)[0].split('?')[0];

  const defaultErrorActionText = t(
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.error_text'
  );

  const queryClient = useQueryClient();

  const [searchFilters] = usePersistentSearchFilters<ToolStoreSearchFilters>(
    null,
    pathname,
    { searchTerm: '', toolTypeFilter: TOOLS_TYPE_FILTER.ALL_TOOLS },
    (searchFilters) =>
      new URLSearchParams({
        tab: searchFilters.toolTypeFilter.toLowerCase(),
        ...(searchFilters.searchTerm && { searchTerm: searchFilters.searchTerm }),
      }).toString(),
    (search) => {
      const searchParams = new URLSearchParams(search);
      return {
        searchTerm: searchParams.get('searchTerm') || '',
        toolTypeFilter: searchParams.get('tab')?.toUpperCase() as TOOLS_TYPE_FILTER,
      };
    }
  );

  const invalidateTools = () => {
    if (pathname === '/tool-store') {
      queryClient.invalidateQueries({ queryKey: ['tools', 1, searchFilters] });
    }
  };

  const invalidateTickets = () => {
    if (pathname === '/sastrify-support') {
      queryClient.invalidateQueries({ queryKey: ['tools', 1, {}] });
    }
  };

  const handleCloseDrawer = () => {
    const includedHash = getHash(hash);
    replace(`${pathname}${search}${includedHash}`);
    toggleDrawer();
  };

  const {
    data: detailsData,
    error: detailsError,
    isLoading: isDetailsLoading,
    refetch: refetchDetails,
  } = useWorkflowRequestDetailsData(requestId);

  const {
    data: activitiesData,
    isLoading: isActivitiesLoading,
    refetch: refetchActivities,
  } = useWorkflowActivitiesData(requestId);
  const { data: initiativesData, refetch: refetchInitiatives } = useRequestInitiativesData(requestId, true);

  const company = queryClient.getQueryData<Company>('company');

  const { isLoading: isMultiToolsNextStageLoading, mutate: setMultiToolsNextStage } = useMutation(
    (payload: UpdateMultiToolsRequestStatusPayload) => updateMultiToolsRequestStatus(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Next stage error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async () => {
        handleActionCancel();
        const { data } = await refetchDetails();

        const allToolsRejected = !(data?.childRequests || []).find(
          ({ requestStateId }) => requestStateId !== REQUEST_STATUS.REJECTED
        );

        invalidateRenewalReminder(queryClient, data?.subscriptionId as string);
        queryClient.invalidateQueries(['subscription', detailsData?.subscriptionId]);

        refetchActivities();
        await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });
        showNotification?.(
          t(
            allToolsRejected
              ? 'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_multi_tools_reject_all_text'
              : 'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_multi_tools_next_stage_text'
          ),
          NotificationAlertType.Success
        );
      },
    }
  );

  const { mutate: setReject } = useMutation(
    (payload: UpdateRequestStatusPayload) => updateRequestStatus(UPDATE_REQUEST_TYPE.REJECT, payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Reject error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: async (_, { requestId: id }) => {
        handleActionCancel();

        invalidateRenewalReminder(queryClient, detailsData?.subscriptionId as string);
        queryClient.invalidateQueries(['subscription', detailsData?.subscriptionId]);

        await refetchDetails();
        refetchActivities();
        await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });

        const tool = (detailsData?.childRequests || []).find(({ requestId }) => String(requestId) === String(id));

        const notificationText = tool
          ? t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_reject_tool_text',
              {
                toolName: tool.vendorName,
              }
            )
          : t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_new_reject_text'
            );

        showNotification?.(notificationText, NotificationAlertType.Success);
      },
    }
  );

  const { mutate: setDelete } = useMutation((id: string) => deleteRequest(id), {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'Delete error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: async (_, id) => {
      invalidateTickets();
      invalidateRenewalReminder(queryClient, detailsData?.subscriptionId as string);
      queryClient.invalidateQueries(['subscription', detailsData?.subscriptionId]);

      const tool = (detailsData?.childRequests || []).find(({ requestId }) => String(requestId) === String(id));

      if (id !== requestId) {
        await refetchDetails();
        await refetchActivities();
      } else {
        invalidateTools();
        await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });
        handleCloseDrawer();
      }

      const notificationText = tool
        ? t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_delete_tool_text',
            {
              toolName: tool.vendorName,
            }
          )
        : t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_delete_new_text'
          );

      showNotification?.(notificationText, NotificationAlertType.Success);

      trackMultitoolInitiativeToolRemoved({
        companyId: company?.id,
        companyName: company?.name,
        initiativeId: requestId,
        initiativeName: detailsData?.name || '',
        initiativeType:
          detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL ? 'renewal' : 'newPurchase',
        toolName: tool?.vendorName || 'unknown',
      });
    },
  });

  const { isLoading: isCreateCommentLoading, mutate: setCreateComment } = useMutation(
    (payload: ModifyRequestCommentPayload) => createRequestComment(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Create request comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchActivities();
        setCommentText('');

        trackWorkflowRequestCommentAdded({
          companyId: company?.id,
          companyName: company?.name,
          requestId: String(detailsData?.requestId) || '',
          requestType: detailsData?.requestWorkflowTypeId === REQUEST_WORKFLOW_TYPE.RENEWAL ? 'renewal' : 'newPurchase',
          toolName: detailsData?.vendorName || 'unknown',
        });
      },
    }
  );

  const { mutate: setDeleteComment } = useMutation(
    (commentId: string | number) => deleteRequestComment(requestId, commentId),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Delete request comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchActivities();
        setCommentText('');
      },
    }
  );

  const { isLoading: isUpdateCommentLoading, mutate: setUpdateComment } = useMutation(
    (payload: ModifyRequestCommentPayload) => updateRequestComment(payload),
    {
      onError: (err: AxiosError) => {
        const message = getErrorCodeMessage(err) || 'Update request comment error';

        showNotification?.(message, NotificationAlertType.Error);
      },
      onSuccess: () => {
        refetchActivities();
        setCommentText('');
      },
    }
  );

  const { isLoading: isReopenLoading, mutate: setReopenRequest } = useMutation(() => reopenRequest(requestId), {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'Reopen error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: async () => {
      invalidateRenewalReminder(queryClient, detailsData?.subscriptionId as string);
      queryClient.invalidateQueries(['subscription', detailsData?.subscriptionId]);

      await refetchDetails();
      refetchActivities();
      await queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });

      showNotification?.(
        t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.success_reopen_new_text'
        ),
        NotificationAlertType.Success
      );
    },
  });

  const { mutate: deleteDocument } = useMutation(deleteRequestDocument, {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'Delete request document error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSettled: () => {
      setDialog({ open: false });
    },
    onSuccess: () => {
      refetchDetails();
      refetchActivities();
      showNotification?.(
        t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.document_delete_confirm_dialog.successfully_deleted_message'
        ),
        NotificationAlertType.Success
      );
    },
  });

  const activeToolsCount = useMemo(
    () => detailsData?.childRequests?.filter((child) => child.requestStateId === REQUEST_STATUS.ACTIVE).length || 0,
    [detailsData?.childRequests]
  );

  const transitionTools = useMemo(
    () =>
      detailsData?.childRequests?.filter(
        (child) =>
          child.requestStateId === REQUEST_STATUS.APPROVED ||
          child.requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED
      ) || [],
    [detailsData?.childRequests]
  );

  const allToolsRejected = useMemo(
    () =>
      detailsData?.childRequests &&
      !detailsData?.childRequests.find((child) => child.requestStateId !== REQUEST_STATUS.REJECTED),
    [detailsData?.childRequests]
  );

  const rejectCheckboxItem = useMemo(
    () => ({
      id: '0',
      label:
        activeToolsCount > 1
          ? t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.reject_all_checkbox_label'
            )
          : t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.reject_single_checkbox_label'
            ),
    }),
    [t, activeToolsCount]
  );

  const disabledDeleteTools = useMemo(
    () =>
      detailsData?.currentStep === 1
        ? []
        : (detailsData?.childRequests || []).map(({ requestId }) => String(requestId)),
    [detailsData]
  );

  const mainToolId = useMemo(() => {
    const mainTool = (detailsData?.childRequests || []).find((tool) => tool.vendorName === detailsData?.vendorName);

    return mainTool ? String(mainTool.requestId) : '';
  }, [detailsData]);

  useEffect(() => {
    if (detailsError && (detailsError as AxiosError).response?.status === 400) {
      toggleDrawer();
      push('/request-not-found');
    }

    if (detailsData && !!detailsData?.childRequests?.length) {
      let tools = detailsData.childRequests
        .filter((tool) => tool.requestStateId !== REQUEST_STATUS.REJECTED)
        .map(({ requestId, vendorName }) => ({
          checked: false,
          disabled: false,
          id: String(requestId),
          label: vendorName,
          name: String(requestId),
        }));
      tools = [
        ...tools,
        {
          checked: false,
          disabled: false,
          id: rejectCheckboxItem.id,
          label: rejectCheckboxItem.label,
          name: rejectCheckboxItem.id,
        },
      ];
      setTools(tools);
    }
  }, [detailsData, detailsError, push, toggleDrawer, rejectCheckboxItem]);

  useEffect(() => {
    if (initiativesData && initiativesData.length > 0) {
      const searchParams = new URLSearchParams(search);

      if (searchParams.has('supportTicketId')) {
        const supportTicketId = searchParams.get('supportTicketId');

        if (supportTicketId && !expandedInvolvements.get(supportTicketId)) {
          const newExpanded = new Map(expandedInvolvements);

          newExpanded.set(supportTicketId, true);
          setExpandedInvolvements(newExpanded);

          window.requestAnimationFrame(() => {
            toolsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          });

          searchParams.delete('supportTicketId');
          replace(`${pathname}?${searchParams.toString()}#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`);
        }
      }
    }
  }, [expandedInvolvements, initiativesData, search, pathname, replace, requestId]);

  useEffect(() => {
    if (detailsData?.childRequests && detailsData.childRequests.length > 0) {
      const searchParams = new URLSearchParams(search);

      if (searchParams.has('toolId')) {
        const toolId = searchParams.get('toolId');

        if (toolId && !expandedTools.get(toolId)) {
          const newExpanded = new Map(expandedTools);

          newExpanded.set(toolId, true);
          setExpandedTools(newExpanded);

          window.requestAnimationFrame(() => {
            toolsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          });

          searchParams.delete('toolId');
          replace(`${pathname}?${searchParams.toString()}#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`);
        }
      }
    }
  }, [expandedTools, detailsData, search, pathname, replace, requestId]);

  const handleActionCancel = () => {
    setActionText('');
    setErrorActionText('');
  };

  const handleToolReject = (tool: RequestDetails) => {
    const payload = { requestId: tool?.requestId, stepId: tool?.currentStepId };

    setReject(payload);
  };

  const handleActionConfirm = () => {
    if (!actionText) {
      setErrorActionText(defaultErrorActionText);
    } else if (detailsData?.childRequests && !!detailsData?.childRequests.length) {
      const selectedChildRequestIds = tools
        .filter((tool) => tool.checked && tool.id !== rejectCheckboxItem.id)
        .map((tool) => Number(tool.id));
      const payload = {
        comment: actionText,
        currentStepId: Number(detailsData?.currentStepId),
        requestId: Number(detailsData?.requestId),
        selectedChildRequestIds,
      };

      return setMultiToolsNextStage(payload);
    }
  };

  const handleActionTextChange = (text: string) => {
    setActionText(!text || text === '<p><br></p>' ? '' : text);
    setErrorActionText(!text || text === '<p><br></p>' ? defaultErrorActionText : '');
  };

  const handleSendComment = () => {
    const payload = {
      comment: commentText,
      requestId: Number(requestId),
    };

    setCreateComment(payload);
  };

  const handleEditRequest = () => {
    const includedHash = getHash(hash);
    onAddRequestDrawer(`${includedHash}#${DRAWER_VARIANT.EDIT_REQUEST}${requestId}`);
  };

  const toggleInvolvementAccordions = (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const newExpanded = new Map(expandedInvolvements);

    if (newExpanded.get(id)) {
      newExpanded.delete(id);
    } else {
      newExpanded.set(id, isExpanded);
    }

    setExpandedInvolvements(newExpanded);
  };

  const toggleToolAccordions = (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const newExpanded = new Map(expandedTools);

    if (newExpanded.get(id)) {
      newExpanded.delete(id);
    } else {
      newExpanded.set(id, isExpanded);
    }

    setExpandedTools(newExpanded);
  };

  const handleCloseActivateDialog = () => {
    closeAsteroidsDialog?.();
    onAddRequestDrawer(`#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`);
  };

  const openActivateToolDialog = (transitionRequestId: string) => {
    toggleDrawer();
    openAsteroidsDialog?.({
      bodyContent: (
        <ActivateToolComponent
          closeDialog={handleCloseActivateDialog}
          showNotification={showNotification}
          requestId={transitionRequestId}
          initiativeDetails={detailsData}
        />
      ),
      onClose: handleCloseActivateDialog,
      width: 600,
    });
  };

  const viewActivatedTool = (tool: RequestDetails): void => {
    if (detailsData) {
      toggleDrawer();
      push(AppUrl.getToolDetailsUrl({ id: tool.subscriptionId, name: tool.subscriptionName }, 'refetch=true'));
    }
  };

  const openRequestDeleteConfirmationDialog = () => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.action_btn_label'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.cancel_btn_label'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.text'
      ),
      onAction: () => setDelete(requestId),
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.confirm_dialog.new_title'
      ),
    });
  };

  const renderDetails = () => (
    <Box mb={5}>
      <InitiativeDetails
        detailsData={detailsData}
        onEditRequest={handleEditRequest}
        onDeleteRequest={openRequestDeleteConfirmationDialog}
      />
    </Box>
  );

  const renderTools = () => (
    <Box mb={5} ref={toolsRef}>
      <InitiativeToolsComponent
        tools={detailsData?.childRequests || []}
        expanded={Array.from(expandedTools.keys())}
        setExpanded={toggleToolAccordions}
        deleteDocument={(documentId) => deleteDocument(documentId)}
        deleteTool={(id) => setDelete(String(id))}
        closeDrawer={closeDrawer}
        parentRequestId={Number(detailsData?.requestId)}
        refetchData={async () => {
          await queryClient.invalidateQueries({ queryKey: ['workflow-requests'] });
          await refetchDetails();
          await refetchActivities();
        }}
        uploadDocuments={uploadRequestDocument}
        showNotification={showNotification}
        companyId={company?.id}
        disabledDeleteTools={disabledDeleteTools}
        disabledDeleteToolsTooltipText={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.disabled_delete_tooltip'
        )}
        mainToolId={mainToolId}
        disabledAddTool={detailsData?.currentStep !== 1}
        disabledAddToolTooltipText={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.initiative_tools.actions.disabled_add_tool_tooltip'
        )}
        expandedInvolvements={Array.from(expandedInvolvements.keys())}
        setExpandedInvolvements={toggleInvolvementAccordions}
        involvements={initiativesData || []}
        uploadInvolvementDocuments={uploadRequestInitiativeDocument}
        refetchInvolvements={async () => {
          await refetchInitiatives();
        }}
      />
    </Box>
  );

  const renderActionsTitle = () => (
    <Typography variant='h2' mb='1rem'>
      {t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.title'
      )}
    </Typography>
  );

  const getNextStepName = () => {
    const currentStepIndex = (detailsData?.timeline || []).findIndex(
      ({ stepId }) => stepId === String(detailsData?.currentStepId)
    );
    const nextStep = (detailsData?.timeline || [])[currentStepIndex + 1];

    return nextStep ? nextStep.stepName : '';
  };

  const renderToolsGroup = () => {
    const isFinalStep = detailsData?.currentStep === detailsData?.totalNumberOfSteps;
    const nextStepName = getNextStepName();
    const toolGroupsTitle = isFinalStep
      ? `${t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.form_group_final_step_label_multi_tool'
        )}:`
      : `${t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.form_group_label_multi_tool'
        )} - ${nextStepName}:`;

    return (
      <Box mb={3}>
        <Box mb={1}>
          <Typography color='textSecondary' data-testid='actions-subtitle'>
            {toolGroupsTitle}
          </Typography>
        </Box>
        <FormGroup row>
          {tools.map(({ checked, disabled, id, label, name }) => (
            <Checkbox
              key={id}
              name={name}
              label={label}
              disabled={disabled}
              checked={checked}
              onChange={({ target: { checked, name } }: React.ChangeEvent<HTMLInputElement>) => {
                let newTools = [...tools];
                if (name === rejectCheckboxItem.id) {
                  newTools = newTools.map((tool) =>
                    tool.name !== name
                      ? { ...tool, disabled: checked, ...(checked && { checked: false }) }
                      : { ...tool, checked }
                  );
                } else {
                  newTools = newTools.map((tool) => (tool.name === name ? { ...tool, checked } : tool));
                }

                setTools(newTools);
              }}
            />
          ))}
        </FormGroup>
      </Box>
    );
  };

  const renderTextEditor = () => (
    <Box mb={4}>
      <RichTextEditorComponent
        value={actionText}
        onChange={handleActionTextChange}
        placeholder={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.text_area_placeholder_multi_tool'
        )}
        dataTestId='actions-details-input'
        error={!!errorActionText}
        helperText={errorActionText}
        disabled={detailsData?.requestStateId !== REQUEST_STATUS.ACTIVE}
        autoFocus={false}
      />
    </Box>
  );

  const renderActionAlert = () => (
    <Box mb={3}>
      <Alert
        description={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.alert_info_message_multi_tool'
        )}
        isIconVisible
        severity='info'
      />
    </Box>
  );

  const renderMultiToolActionButton = () => {
    const isParentRequestRejected = detailsData?.requestStateId === REQUEST_STATUS.REJECTED;
    const isRejectCheckboxItem = tools.find((tool) => tool.id === rejectCheckboxItem.id)?.checked;

    return (
      <Box display='flex' justifyContent='flex-end'>
        {!isParentRequestRejected && (
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={handleActionConfirm}
            disabled={isMultiToolsNextStageLoading}
            startIcon={isMultiToolsNextStageLoading ? <CircularProgress color='inherit' size={16} /> : null}
            data-testid={isRejectCheckboxItem ? 'close-initiative-button' : 'multi-tools-next-stage-button'}>
            {isRejectCheckboxItem
              ? t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.confirm_button_label_close_initiative'
                )
              : t(
                  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.confirm_button_label_next_stage'
                )}
          </Button>
        )}
      </Box>
    );
  };

  const renderMoveToNextStageSection = () => {
    const enabledTextEditor = !!tools.filter(({ checked }) => checked).length;
    const enabledAlert = !tools.find((tool) => tool.id === rejectCheckboxItem.id)?.checked && activeToolsCount > 1;
    const enabledActionBtn = !!tools.filter((tool) => tool.checked === true).length;

    return (
      <>
        {renderToolsGroup()}
        {enabledTextEditor && renderTextEditor()}
        {enabledAlert && renderActionAlert()}
        {enabledActionBtn && renderMultiToolActionButton()}
      </>
    );
  };

  const renderReopenInitiativeSection = () => (
    <>
      <Typography component='div' data-testid='reopen-initiative-subtitle' mb={2}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.reopen_request_new_text'
        )}
      </Typography>
      <Box mb={4}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={() => setReopenRequest()}
          disabled={isReopenLoading}
          startIcon={isReopenLoading ? <CircularProgress color='inherit' size={16} /> : null}
          data-testid='reopen-request-btn'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.reopen_request_new_btn'
          )}
        </Button>
      </Box>
    </>
  );

  const renderTransitionSection = () => {
    const isTransitionMode = !!transitionTools.find((tool) => tool.requestStateId === REQUEST_STATUS.APPROVED);

    return (
      <>
        {isTransitionMode ? (
          <>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.initiative_approved_transition_text'
            )
              .split('\n')
              .map((text: string, index: number) => (
                <Typography component='div' mb={2} data-testid={`actions-final-step-subtitle-${index}`}>
                  {text}
                </Typography>
              ))}
          </>
        ) : (
          <Typography component='div' mb={3}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.actions.initiative_subscription_created_transition_text'
            )}
          </Typography>
        )}
        {transitionTools.map((tool) => (
          <TransitionTool
            key={tool.requestId}
            tool={tool}
            isMainTool={String(tool.requestId) === mainToolId}
            onReject={(tool) => handleToolReject(tool)}
            onSubmit={() => openActivateToolDialog(String(tool.requestId))}
            onView={(tool) => viewActivatedTool(tool)}
          />
        ))}
      </>
    );
  };

  const renderActions = () => (
    <Box mb={5}>
      {(detailsData?.permissions?.approve ||
        detailsData?.permissions?.canReopenRequest ||
        detailsData?.permissions?.canActivateRequest) &&
        renderActionsTitle()}
      {detailsData?.permissions?.approve && !!activeToolsCount && renderMoveToNextStageSection()}
      {detailsData?.permissions?.canReopenRequest && !!allToolsRejected && renderReopenInitiativeSection()}
      {detailsData?.permissions?.canActivateRequest && !!transitionTools.length && renderTransitionSection()}
    </Box>
  );

  const renderActivities = () => (
    <ActivitiesComponent
      activitiesTitle={t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.title'
      )}
      commentTestId='add-comment'
      commentAvatarSrc={user.avatar}
      commentAvatarAlt={user.name}
      commentText={commentText}
      setCommentText={(value) => setCommentText(value)}
      onCommentAction={() => handleSendComment()}
      logItems={activitiesData || []}
      onLogItemDelete={(id) => id && setDeleteComment(id)}
      onLogItemSend={(val, id) => {
        setUpdateComment({
          comment: val || '',
          id: id || '',
          requestId: Number(requestId || 0),
        });
      }}
      isSubmitButtonDisabled={isCreateCommentLoading || isUpdateCommentLoading}
      alertInfo={!!initiativesData?.length}
    />
  );

  return (
    <>
      {!isDetailsLoading && !isActivitiesLoading && (
        <Box width='100%' pr='4rem'>
          {renderDetails()}
          {renderTools()}
          {renderActions()}
          {activitiesData && renderActivities()}
        </Box>
      )}
      {/*
        Asteroids dialog doesn't accept disableEnforceFocus prop and therefore has an issue when opened over a Drawer.
        Until component is updated use this local instead.
      */}
      <DialogComponent {...dialog} />
    </>
  );
};
