import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

interface UsageAnalyticsInformationSectionProps {
  title: string;
  text?: string;
  customContent?: JSX.Element;
}

export const UsageAnalyticsInformationSection: FC<UsageAnalyticsInformationSectionProps> = (
  props: UsageAnalyticsInformationSectionProps
) => {
  const { customContent, text, title } = props;

  const theme = useTheme();

  return (
    <Box sx={{ marginBottom: theme.spacing(3) }}>
      <Typography variant='section' color='text.primary' sx={{ marginBottom: 1 }}>
        {title}
      </Typography>
      {text && (
        <Typography variant='body1' color='text.primary' sx={{ display: 'block', marginBottom: 1 }}>
          {text}
        </Typography>
      )}
      {customContent}
    </Box>
  );
};
