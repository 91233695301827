import { useAuth0 } from '@auth0/auth0-react';
import { useFlag } from '@unleash/proxy-client-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { featureFlags, RESPONSE_CODE } from 'shared/common.definitions';
import { isLoginMethodErrorMessage } from 'shared/helpers/common.helper';
import { AuthenticationOptionsName, AuthorizationSettings } from 'shared/models/company.model';
import { apiService } from 'shared/services';
import { useAppSelector } from 'shared/store/hooks';

type ErrorProps = {
  title: string;
  paragraph: string;
  onButtonClick: () => void;
};

const getAuthMethod = (method: AuthorizationSettings) => {
  if (method.googleAuth) return AuthenticationOptionsName.GOOGLE_AUTH;
  if (method.microsoftAuth) return AuthenticationOptionsName.MICROSOFT_AUTH;
  if (method.oktaAuth) return AuthenticationOptionsName.OKTA_AUTH;
  if (method.jumpCloudAuth) return AuthenticationOptionsName.JUMP_CLOUD_AUTH;
  return undefined;
};

export const useDisplayAuthErrorView = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const { isAuthenticatedError, isLoading } = useAppSelector((state) => state.authentication);
  const enableEnforceSSO = useFlag(featureFlags.ENFORCE_SSO_LOGIN);

  const onButtonClick = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const [authErrorProps, setAuthErrorProps] = useState<ErrorProps>();
  const [enforceLoginError, setEnforceLoginError] = useState<boolean>(false);
  const [ssoMethod, setSsoMethod] = useState<AuthenticationOptionsName>();

  useEffect(() => {
    apiService.interceptors().response.use(
      (response) => response,
      (error) => {
        if (
          error.response?.status === RESPONSE_CODE.Unauthorized.code &&
          isLoginMethodErrorMessage(String(error.response.data?.error))
        ) {
          setSsoMethod(getAuthMethod(error.response.data?.data?.authorizationSettings as AuthorizationSettings));
        }
        return Promise.reject(error);
      }
    );
  }, [enableEnforceSSO]);

  useEffect(() => {
    if (enableEnforceSSO && isAuthenticatedError && !isLoading) {
      setEnforceLoginError(true);
    }
  }, [enableEnforceSSO, isAuthenticatedError, ssoMethod, isLoading]);

  useEffect(() => {
    if (isAuthenticatedError && !isLoading) {
      if (enforceLoginError) {
        // correct error message
        setAuthErrorProps({
          onButtonClick,
          paragraph: ssoMethod
            ? t('auth_error_view:enforce_login_error.body_text', {
                ssoMethod,
              })
            : t('auth_error_view:enforce_login_error.paragraph_text'),
          title: t('auth_error_view:enforce_login_error.title_text'),
        });
      } else {
        // generic
        setAuthErrorProps({
          onButtonClick,
          paragraph: t('auth_error_view:auth_error_body.login_error_paragraph'),
          title: t('auth_error_view:auth_error_title'),
        });
      }
    }
  }, [isAuthenticatedError, enableEnforceSSO, enforceLoginError, onButtonClick, ssoMethod, t, isLoading]);

  return {
    authErrorProps,
  };
};
