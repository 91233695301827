import { useCallback, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DRAWER_VARIANT, DrawerInfoType } from 'shared/common.definitions';
import {
  isBenchmarkingRoute,
  isDashboardRoute,
  isSastrifySupportRoute,
  isSubscriptionDetailsRoute,
  isToolDetailsRoute,
  isToolStoreRoute,
  isWorkflowRequestsRoute,
} from 'shared/helpers/common.helper';
import { show as showDrawer } from 'shared/store/common/appDrawer.slice';
import { useAppDispatch } from 'shared/store/hooks';

export const useAddRequestDrawer = () => {
  const { replace } = useHistory();
  const { hash, pathname, search } = useLocation();

  const dispatch = useAppDispatch();
  const isMounted = useRef(true);

  const isDashboard = isDashboardRoute(pathname);
  const isWorkflowRequests = isWorkflowRequestsRoute(pathname);
  const isToolStore = isToolStoreRoute(pathname);
  const isSubscriptionDetails = isSubscriptionDetailsRoute(pathname);
  const isToolDetails = isToolDetailsRoute(pathname);
  const isSastrifySupport = isSastrifySupportRoute(pathname);
  const isBenchmarking = isBenchmarkingRoute(pathname);

  const getDrawerType = useCallback(
    (drawerVariant?: string) => {
      if (
        (isDashboard && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isWorkflowRequests && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isSubscriptionDetails && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isToolDetails && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isToolStore && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isBenchmarking && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST))
      ) {
        return DrawerInfoType.WORKFLOW_REQUEST;
      }
      if (
        (isDashboard && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isWorkflowRequests && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isToolStore && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isSubscriptionDetails && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isToolDetails && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isSastrifySupport && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isBenchmarking && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST))
      ) {
        return DrawerInfoType.WORKFLOW_REQUEST_EDIT;
      }
      if (
        (isDashboard && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isWorkflowRequests && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isToolStore && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isSubscriptionDetails && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isToolDetails && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isSastrifySupport && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isBenchmarking && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS))
      ) {
        return DrawerInfoType.REQUEST_DETAILS;
      }
    },
    [
      isDashboard,
      isWorkflowRequests,
      isSubscriptionDetails,
      isToolDetails,
      isToolStore,
      isBenchmarking,
      isSastrifySupport,
    ]
  );

  const onAddRequestDrawer = useCallback(
    (drawerVariant?: string) => {
      let urlQueryString = search;

      if (drawerVariant?.includes('?')) {
        const drawerVariantWithQueryString = drawerVariant;
        drawerVariant = `#${drawerVariant?.split('#')[1]}`;
        if (urlQueryString) {
          const passedQueryString = drawerVariantWithQueryString?.split('#')[0].replace('?', '&');
          urlQueryString += passedQueryString;
        } else {
          urlQueryString = drawerVariantWithQueryString?.split('#')[0];
        }
      }

      const drawerType = getDrawerType(drawerVariant);

      if (
        (isDashboard && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isDashboard && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isDashboard && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isWorkflowRequests && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isWorkflowRequests && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isWorkflowRequests && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isToolStore && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isToolStore && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isToolStore && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isSubscriptionDetails && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isSubscriptionDetails && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isSubscriptionDetails && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isToolDetails && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isToolDetails && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isToolDetails && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isSastrifySupport && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isSastrifySupport && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
        (isBenchmarking && drawerVariant?.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
        (isBenchmarking && drawerVariant?.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
        (isBenchmarking && drawerVariant?.includes(DRAWER_VARIANT.REQUEST_DETAILS))
      ) {
        replace(`${pathname}${urlQueryString}${drawerVariant}`);
      }

      if (drawerType) {
        dispatch(showDrawer(drawerType));
      }
    },
    [
      search,
      getDrawerType,
      isDashboard,
      isWorkflowRequests,
      isToolStore,
      isSubscriptionDetails,
      isToolDetails,
      isSastrifySupport,
      isBenchmarking,
      replace,
      pathname,
      dispatch,
    ]
  );

  useEffect(() => {
    if (
      (isDashboard && hash.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
      (isDashboard && hash.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
      (isDashboard && hash.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
      (isWorkflowRequests && hash.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
      (isWorkflowRequests && hash.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
      (isWorkflowRequests && hash.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
      (isToolStore && hash.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
      (isToolStore && hash.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
      (isToolStore && hash.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
      (isSubscriptionDetails && hash.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
      (isSubscriptionDetails && hash.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
      (isSubscriptionDetails && hash.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
      (isToolDetails && hash.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
      (isToolDetails && hash.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
      (isToolDetails && hash.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
      (isSastrifySupport && hash.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
      (isSastrifySupport && hash.includes(DRAWER_VARIANT.REQUEST_DETAILS)) ||
      (isBenchmarking && hash.includes(DRAWER_VARIANT.NEW_REQUEST)) ||
      (isBenchmarking && hash.includes(DRAWER_VARIANT.EDIT_REQUEST)) ||
      (isBenchmarking && hash.includes(DRAWER_VARIANT.REQUEST_DETAILS))
    ) {
      onAddRequestDrawer(hash);
      isMounted.current = false;
    }
  }, [
    hash,
    onAddRequestDrawer,
    search,
    isToolStore,
    isWorkflowRequests,
    isSubscriptionDetails,
    isSastrifySupport,
    isDashboard,
    isBenchmarking,
    isToolDetails,
  ]);

  return { onAddRequestDrawer };
};
