import { Alert as MUIAlert, AlertTitle, Box, Typography } from '@mui/material';
import React from 'react';

import { Icon } from '../icon/icon.component';
import { AlertProps } from './alert.component.props';

export const Alert: React.FC<AlertProps> = ({
  action,
  actionIcon = true,
  actionText,
  description,
  isIconVisible = true,
  onAction,
  title,
  ...props
}) => {
  return (
    <MUIAlert
      data-testid='asteroid-alert'
      {...props}
      variant='filled'
      {...(!(description && title) && { sx: { ...props.sx, alignItems: 'center' } })}
      iconMapping={{
        error: <Icon variant='Outlined'>warning_amber</Icon>,
        info: <Icon variant='Outlined'>info</Icon>,
        success: (
          <Icon data-testid='asteroid-alert-icon' variant='Outlined'>
            check_circle
          </Icon>
        ),
        warning: <Icon variant='Outlined'>warning_amber</Icon>,
      }}
      icon={!isIconVisible ? false : undefined}
      action={
        action || (
          <Box
            tabIndex={0}
            role='button'
            onKeyUp={(e) => {
              if (e.key === 'Enter' || e.key === 'Space') onAction?.();
            }}
            onClick={onAction}
            data-testid='asteroid-alert-action-button'
            sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex', whiteSpace: 'nowrap' }}>
            {!!actionText && <Typography variant='buttonSmall'>{actionText}</Typography>}
            {!!onAction && actionIcon && (
              <Icon variant='Outlined' sx={{ fontSize: 'body.lineHeight', ml: 0.5 }}>
                {typeof actionIcon === 'string' ? actionIcon : 'close'}
              </Icon>
            )}
          </Box>
        )
      }>
      {title && (
        <AlertTitle sx={{ mb: 0 }}>
          <Typography variant='h3' mb={description ? 0.5 : 0} data-testid='asteroid-alert-title'>
            {title}
          </Typography>
        </AlertTitle>
      )}
      <Typography variant='body'>{description}</Typography>
    </MUIAlert>
  );
};

export default Alert;
