import { Box, Button, Typography } from '@mui/material';
import InitiativesDrawerImg from 'assets/images/requests/procurement-initiatives.svg';
import { Icon } from 'asteroids';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type IProps = {
  handleCloseDrawer: () => void;
};

export const InitiativeSection: FC<IProps> = ({ handleCloseDrawer }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ px: 6, py: 8 }} data-testid='left-section-desc'>
      <Button variant='text' color='primary' startIcon={<Icon>west</Icon>} onClick={handleCloseDrawer}>
        {t('requests_view:modify_initiative_drawer.left_side.back_text')}
      </Button>

      <Box sx={{ my: 3 }}>
        <Typography variant='h2' data-testid='tool-request-title'>
          {t('requests_view:modify_initiative_drawer.left_side.title')}
        </Typography>
      </Box>

      <Box component='img' src={InitiativesDrawerImg} alt='' />

      <Box sx={{ my: 3 }}>
        <Typography variant='h3' data-testid='request-infoBodyText' mb={1}>
          {t('requests_view:modify_initiative_drawer.left_side.subtitle')}
        </Typography>
        <Typography variant='body1' data-testid='request-infoBodyText-desc'>
          {t('requests_view:modify_initiative_drawer.left_side.description')}
        </Typography>
      </Box>
    </Box>
  );
};
