import CloseIcon from '@mui/icons-material/Close';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import { DialogComponentProps } from '.';

export const DialogComponent: FC<DialogComponentProps> = ({ handleClose, icon, message, open, title }) => (
  <MuiDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} className='dialog-component'>
    <header>
      <IconButton aria-label='close' onClick={handleClose} className='close-button' size='large'>
        <CloseIcon />
      </IconButton>
    </header>
    <MuiDialogContent className='dialog-content'>
      <section className='icon'>{icon}</section>
      <Typography className='title'>{title}</Typography>
      <Typography className='message'>{message}</Typography>
    </MuiDialogContent>
  </MuiDialog>
);
