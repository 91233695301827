import './vendor-knowledge-item.style.scss';

import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { featureFlags } from 'shared/common.definitions';
import { useCompanyFeatureFlag } from 'src/company-feature-flag.provider';

import { StoryBlokRenderer } from '../vendor-insight-modal-content/vendor-insight-content/storyblok.renderer.component';
import { VendorInsightReadWatchButton } from '../vendor-insight-read-watch-button/vendor-insight-read-watch-button.component';
import { VendorKnowledgeItemProps } from './vendor-knowledge-item.props';

export const VendorKnowledgeItem: FC<VendorKnowledgeItemProps> = ({
  category,
  isNew,
  onItemClicked,
  publishedAt,
  vendorKnowledgeItem,
}) => {
  const { content, title } = vendorKnowledgeItem;
  const { t } = useTranslation();

  const renderNewBadge = useCompanyFeatureFlag(featureFlags.NEW_INDICATOR_VENDOR_INSIGHTS);

  const [isNotSeen, setIsNotSeen] = useState<boolean>(renderNewBadge && isNew);

  const handleClick = () => {
    setIsNotSeen(false);
    onItemClicked();
  };

  return (
    <div className='vendor-knowledge-item' data-testid='vendor-knowledge-item'>
      {category?.image && (
        <div className='vendor-knowledge-item__image' data-testid='vendor-knowledge-category-image'>
          <img src={category?.image} alt={vendorKnowledgeItem.title} />
        </div>
      )}
      <div className='vendor-knowledge-item-wrapper'>
        <div className='vendor-knowledge-item-content' data-testid='vendor-knowledge-item-content'>
          <div className='vendor-knowledge-item__details'>
            {isNotSeen && (
              <span data-testid='vendor-knowledge-item-new-badge' className='vendor-knowledge-item__new-indicator'>
                {t('subscription_detail_view:tabs_component_section.insight_tab.new')}
              </span>
            )}
            {publishedAt && (
              <span className='vendor-knowledge-item__date' data-testid='vendor-knowledge-item-date'>
                {format(new Date(publishedAt), 'd MMMM yyyy')}
              </span>
            )}
          </div>
          <h3 className='vendor-knowledge-item__title' data-testid='vendor-knowledge-item-title'>
            {title}
          </h3>
          <div className='vendor-knowledge-item__paragraph' data-testid='vendor-knowledge-item-paragraph'>
            <StoryBlokRenderer content={content} renderFirstBlockOnly />
          </div>
        </div>

        <div className='vendor-knowledge-item__actions'>
          <VendorInsightReadWatchButton onClick={handleClick} />
          {category && (
            <span className='vendor-knowledge-item__label' data-testid='vendor-knowledge-category-label'>
              {category?.label}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
