import { Box, Button, Divider, Link as MUILink, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITION_LINK } from 'shared/common.definitions';
import { RootState } from 'shared/store';
import { hide as hideDrawer } from 'shared/store/common/appDrawer.slice';
import { useAppSelector } from 'shared/store/hooks';

interface ManageSSOCardFooterProps {
  onDisconnect: (isEnableUsageAnalytics: boolean) => void;
}

export const ManageSSOCardFooter: FC<ManageSSOCardFooterProps> = ({ onDisconnect }) => {
  const dispatch = useDispatch();
  const handleCloseDrawer = useCallback(() => dispatch(hideDrawer()), [dispatch]);
  const { isConnected, usageAnalyticsEnabled } = useAppSelector(
    (state: RootState) => state.common.appDrawer.data as { isConnected: boolean; usageAnalyticsEnabled: boolean }
  );

  const renderConnected = () => (
    <>
      <Divider orientation='horizontal' textAlign='center' sx={{ mx: -5 }} />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'end',
          pb: 2,
          pt: 2,
          width: '100%',
        }}>
        <Button variant='text' color='secondary' onClick={handleCloseDrawer} key='close-connected-card'>
          {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.close_button_text')}
        </Button>
        <Button
          key='disconnect-sso-card'
          onClick={() => {
            onDisconnect(usageAnalyticsEnabled);
            handleCloseDrawer();
          }}
          variant='contained'
          color='error'
          data-testid='modal-confirm-button'
          disableRipple
          disableElevation>
          {t('connect_view:tab_section.disconnect')}
        </Button>
      </Box>
    </>
  );

  const renderConnect = () => (
    <>
      <Divider orientation='horizontal' textAlign='center' sx={{ mx: -5 }} />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          pb: 2,
          pt: 2,
        }}>
        <Typography variant='body1' color='text.primary'>
          {`${t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.connection_agreement')} `}
          <MUILink href={TERMS_AND_CONDITION_LINK} target='_blank'>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.terms_and_condition')}
          </MUILink>
          {` ${t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.and')} `}
          <MUILink href={PRIVACY_POLICY_LINK} target='_blank'>
            {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.privacy_policy')}
          </MUILink>
        </Typography>
        <Button variant='text' color='secondary' onClick={handleCloseDrawer} key='close-not-connected-card'>
          {t('connect_view:tab_section.tabs.usage_analytics.usage_analytics_drawer.close_button_text')}
        </Button>
      </Box>
    </>
  );

  return <Box sx={{ flexDirection: 'column', mt: 1 }}>{isConnected ? renderConnected() : renderConnect()}</Box>;
};
