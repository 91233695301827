export enum PERMISSION {
  VIEW_USER_ACCOUNTS = 'view:user-accounts',
  CREATE_USER_ACCOUNT = 'create:user-account',
  DELETE_USER_ACCOUNT = 'delete:user-account',
  UPDATE_USER_ACCOUNT = 'update:user-account',

  CREATE_SUBSCRIPTION = 'create:subscription',
  DELETE_SUBSCRIPTION = 'delete:subscription',

  CREATE_NEW_SOLUTION = 'create:new-solution',
  DELETE_NEW_SOLUTION = 'delete:new-solution',
  UPDATE_NEW_SOLUTION_STATUS = 'update:new-solution-status',

  ACCESS_ALL_SUBSCRIPTIONS = 'access:all-subscriptions',
  LIST_ALL_SUBSCRIPTIONS = 'list:all-subscriptions',

  MANAGE_INTEGRATIONS = 'manage:integrations',
  MANAGE_COMPANY = 'manage:company',

  ASSIGN_ADMIN_ROLE = 'assign:admin',
  ASSIGN_CONTRIBUTOR_ROLE = 'assign:contributor',

  MANAGE_WORKFLOWS = 'manage:workflows',

  UPDATE_REQUESTER = 'update:requester',

  VIEW_BUDGET = 'view:budget',

  VIEW_REQUEST_SUPPORT = 'view:csm-support',

  ACCESS_TOOL_REQUESTS_VIEW = 'access:tool-requests',
  ACCESS_TOOL_REQUEST_DETAILS = 'access:tool-request-details',

  MANAGE_SUBSCRIPTION_HRIS = 'manage:subscription-hris',
  MANAGE_HRIS_DATA = 'manage:hris-data',
  VIEW_HRIS_DATA = 'view:hris-data',
}
