import { RequestDocument } from 'shared/models';

import { FileItemComponent } from '../file-item/file-item.component';
import { FilesListComponentProps } from './files-list.component.props';
import { FilesList } from './files-list.component.styles';

export const FilesListComponent: React.FC<FilesListComponentProps> = ({
  disableDownloadButton,
  files,
  onDownload,
  onRemove,
  textTruncate,
}) => {
  const getKey = (file: File | RequestDocument) => {
    if (file instanceof File) {
      return `${file?.name}-${file?.lastModified}`;
    }
    if ((file as RequestDocument).fileName !== undefined) {
      return `${file?.fileName}-${file?.id}`;
    }
  };

  const handleRemoveFile = (file: File | RequestDocument, index: number) => {
    if (file instanceof File) onRemove?.(index);
    else onRemove?.(Number((file as RequestDocument).id), (file as RequestDocument).fileName || '');
  };

  return (
    <FilesList data-testid='attachments-list' spacing={1}>
      {files.map((file, index) => (
        <FileItemComponent
          key={getKey(file)}
          file={file}
          textTruncate={textTruncate}
          onRemove={onRemove && (() => handleRemoveFile(file, index))}
          onDownload={onDownload}
          disableDownloadButton={disableDownloadButton}
        />
      ))}
    </FilesList>
  );
};
