import { Box, Tooltip, Typography } from '@mui/material';
import { REQUEST_STATES } from 'libs/enums';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { RequestWorkflowItem } from 'shared/models/request-workflow.model';
import { useAddRequestDrawer } from 'views/requests/hooks';

import {
  NewInitiativeButton,
  SubscriptionInitiativeBannerDescription,
  SubscriptionInitiativeBannerHeader,
  SubscriptionInitiativeBannerWrapper,
} from './subscription-initiative-banner.styles';

interface BannerProps {
  initiativesData?: RequestWorkflowItem[];
  subscriptionId?: string;
}

export const SubscriptionInitiativesBanner: React.FC<BannerProps> = ({ initiativesData = [], subscriptionId }) => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const { onAddRequestDrawer } = useAddRequestDrawer();

  const disableCreateInitiative = useMemo(
    () =>
      Boolean(
        initiativesData.find(
          ({ requestStateId }) =>
            requestStateId === String(REQUEST_STATES.ACTIVE) || requestStateId === String(REQUEST_STATES.APPROVED)
        )
      ),
    [initiativesData]
  );

  return (
    <SubscriptionInitiativeBannerWrapper>
      <SubscriptionInitiativeBannerHeader>
        <Typography variant='h3' data-testid='initiatives-title'>
          {t('subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.banner.title')}
        </Typography>
        <Tooltip
          title={
            disableCreateInitiative
              ? t(
                  'subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.banner.btn_tooltip'
                )
              : ''
          }
          arrow
          placement='top'
          data-testid='tooltip'>
          <Box>
            <NewInitiativeButton
              data-testid='new-initiative-button'
              variant='text'
              disabled={disableCreateInitiative}
              onClick={() => onAddRequestDrawer(`${hash}#${DRAWER_VARIANT.NEW_REQUEST}${subscriptionId}`)}>
              {t(
                'subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.banner.btn_text'
              )}
            </NewInitiativeButton>
          </Box>
        </Tooltip>
      </SubscriptionInitiativeBannerHeader>
      <SubscriptionInitiativeBannerDescription variant='body' data-testid='initiatives-subtitle'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_procurement_initiatives_tab.banner.description'
        )}
      </SubscriptionInitiativeBannerDescription>
    </SubscriptionInitiativeBannerWrapper>
  );
};
