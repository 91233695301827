import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const VerticallyCenteredBox = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
}));

export const HorizontallyCenteredBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));
