import * as yup from 'yup';

export const formValidationSchema = yup.object().shape({
  benchmarkGoal: yup.string().required(),
  changeInPlans: yup.string().required(),
  commitmentLengthValue: yup.number().required(),
  newBenchmarkVolume: yup.string().required(),
  preferredCommitmentLength: yup.string().required(),
  preferredPaymentCycle: yup.string().required(),
});
