import { CircularProgress } from '@mui/material';
import { ActionButtonProps, Dialog } from 'asteroids';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalStore, SastrifyStore } from 'shared/models';

import { AsteroidsModalComponentProps } from './modal.component.props';

export const AsteroidsModalComponent: FC<AsteroidsModalComponentProps> = (props: AsteroidsModalComponentProps) => {
  const {
    cancelButton,
    cancelButtonColor,
    cancelButtonText,
    children,
    clickAwayToClose: clickAwayToCloseProp = true,
    closeModal,
    confirmButton,
    confirmButtonColor,
    confirmButtonStartIcon,
    confirmButtonText,
    confirmButtonVariant,
    customModalContent,
    customTitle,
    enableSubmitLoader = false,
    height,
    message,
    onCancelButtonClick,
    onClickAway,
    onConfirmButtonClick,
    open = true,
    sx = {},
    title,
    titleIcon,
    triggerCancelButtonClick,
    width: widthProp,
    ...rest
  } = props;

  const width = widthProp === false || widthProp === 0 ? undefined : widthProp || 'default';
  const getActionsList = useCallback(() => {
    const actionsList: ActionButtonProps[] = [];

    if (confirmButtonText || confirmButton) {
      actionsList.push({
        actionType: 'confirm',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color: confirmButtonColor,
        label: confirmButtonText,
        onClick: onConfirmButtonClick,
        startIcon: confirmButtonStartIcon,
        variant: confirmButtonVariant,
        ...confirmButton,
      });
    }

    if (cancelButtonText || cancelButton) {
      actionsList.push({
        actionType: 'cancel',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color: cancelButtonColor,
        label: cancelButtonText,
        onClick: onCancelButtonClick,
        ...cancelButton,
      });
    }

    return actionsList;
  }, [
    confirmButtonText,
    confirmButton,
    cancelButtonText,
    cancelButton,
    confirmButtonColor,
    onConfirmButtonClick,
    confirmButtonStartIcon,
    confirmButtonVariant,
    cancelButtonColor,
    onCancelButtonClick,
  ]);

  const [actions, setActions] = useState<ActionButtonProps[]>(getActionsList);
  const [clickAwayToClose, setClickAwayToClose] = useState(clickAwayToCloseProp);
  const modalFooterState: ModalStore = useSelector((state: SastrifyStore) => state.modal);
  const footerState = useRef<ModalStore>(modalFooterState);

  useEffect(() => {
    setActions(getActionsList());
  }, [getActionsList]);

  const renderSubmitLoader = useCallback(
    (action: ActionButtonProps) => {
      if (!(action.actionType === 'confirm')) return {};

      if (enableSubmitLoader && confirmButtonStartIcon) {
        return {
          startIcon: modalFooterState.isFormSubmitting ? (
            <CircularProgress color='inherit' size={16} />
          ) : (
            confirmButtonStartIcon
          ),
        };
      }

      if (enableSubmitLoader) {
        return {
          startIcon: modalFooterState.isFormSubmitting && <CircularProgress color='inherit' size={16} />,
        };
      }

      return { startIcon: confirmButtonStartIcon };
    },
    [confirmButtonStartIcon, enableSubmitLoader, modalFooterState.isFormSubmitting]
  );

  // Form submitting state change
  useEffect(() => {
    const prevIsFormSubmitting = footerState.current?.isFormSubmitting;

    if (modalFooterState.isFormSubmitting !== prevIsFormSubmitting) {
      if (modalFooterState.isFormSubmitting) {
        footerState.current.isFormSubmitting = modalFooterState.isFormSubmitting;
        if (clickAwayToClose) setClickAwayToClose(false);

        setActions(
          actions.map((action) => ({
            ...action,
            disabled: true,
            ...renderSubmitLoader(action),
          }))
        );
      } else if (!clickAwayToClose && clickAwayToCloseProp) {
        setClickAwayToClose(true);
        setActions(
          actions.map((action) => ({
            ...action,
            disabled: false,
            ...renderSubmitLoader(action),
          }))
        );
      }
    }
  }, [actions, clickAwayToClose, clickAwayToCloseProp, modalFooterState.isFormSubmitting, open, renderSubmitLoader]);

  // Form validation state change
  useEffect(() => {
    const confirmActions = actions.filter((item) => item.actionType === 'confirm');
    if (!confirmActions) return;

    const prevIsFormValid = footerState.current?.isFormValid;

    if (modalFooterState.isFormValid !== prevIsFormValid) {
      let changedEnableState = false;

      footerState.current.isFormValid = modalFooterState.isFormValid;

      if (!modalFooterState.isFormValid && confirmActions.length) {
        confirmActions.forEach((action) => {
          if (!action.disabled) {
            changedEnableState = true;
            action.disabled = true;
          }
        });
      }

      if (modalFooterState.isFormValid && confirmActions.length) {
        confirmActions.forEach((action) => {
          if (action.disabled) {
            changedEnableState = true;
            action.disabled = false;
          }
        });
      }

      if (changedEnableState) {
        setActions(actions.slice());
      }
    }
  }, [actions, modalFooterState.isFormValid, open]);

  const handleDismissModal = useCallback(() => {
    if (triggerCancelButtonClick) {
      onCancelButtonClick?.();
    }
    if (!onClickAway) {
      closeModal?.();
    } else {
      onClickAway();
    }
  }, [closeModal, onCancelButtonClick, onClickAway, triggerCancelButtonClick]);

  const renderDialogContent = () => {
    return customModalContent || children || message;
  };

  return (
    <Dialog
      data-testid='modal-window'
      open={!!open}
      clickAwayToClose={clickAwayToClose}
      actions={actions}
      onClose={handleDismissModal}
      bodyContent={renderDialogContent()}
      title={title}
      titleIcon={titleIcon}
      customTitle={customTitle}
      width={width}
      height={height}
      sx={{
        ...(!open && {
          '.dialog-wrapper': {
            paddingBottom: '0 !important',
          },
        }),
        ...sx,
      }}
      {...rest}
    />
  );
};
