import { Divider, Grid, Typography } from '@mui/material';
import { colors } from 'asteroids';

import { BulkSelectionFloatingMenuComponentProps } from './bulk-selection-floating-menu.component.props';

export const BulkSelectionFloatingMenuComponent: React.FC<BulkSelectionFloatingMenuComponentProps> = ({
  actionComponents,
  selectedText,
}) => {
  return (
    <Grid
      container
      sx={{ background: colors.background.paper, borderBottom: `1px solid ${colors.outlineBorder}`, px: 2, py: 1 }}
      justifyContent='space-between'>
      <Grid display='flex' alignItems='center' item data-testid='number-of-selected-invoices'>
        <Typography>{selectedText}</Typography>
      </Grid>
      <Grid item display='flex' justifyContent='space-between'>
        {actionComponents.map((actionComponent, index) => (
          <>
            {actionComponent.label && <Typography>{actionComponent.label}</Typography>}
            {actionComponent.component}
            {index !== actionComponents.length - 1 && (
              <Divider orientation='vertical' sx={{ display: 'flex', mx: 2 }} />
            )}
          </>
        ))}
      </Grid>
    </Grid>
  );
};
