import { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

import { ModifyToolMatchings, useUpdateToolMatching } from '../hooks/use-update-tool-matching';

export interface ActionsContextValue {
  isLoading?: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  updateToolMatching?: (data: ModifyToolMatchings) => Promise<void>;
}

export const ActionsContext = createContext<ActionsContextValue>({});

export const ActionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { updateToolMatching } = useUpdateToolMatching(setIsLoading);

  const contextValue = useMemo(
    () => ({ isLoading, setIsLoading, updateToolMatching }),
    [isLoading, setIsLoading, updateToolMatching]
  );

  return <ActionsContext.Provider value={contextValue}>{children}</ActionsContext.Provider>;
};
