import { SlackAuthDto } from 'libs/dtos';
import { AuthenticationOptionsType } from 'libs/enums';
import { USER_ROLES } from 'libs/enums/user-roles.enum';

import { ConversionRates } from './conversion-rates.model';
import { CustomerSuccessManager } from './customer-success-manager';

type CustomFilter = {
  name: string;
  filter: string;
};

export enum AuthenticationOptionsName {
  USERNAME = 'username & password',
  GOOGLE_AUTH = 'Google',
  MICROSOFT_AUTH = 'Microsoft',
  OKTA_AUTH = 'Okta',
  JUMP_CLOUD_AUTH = 'JumpCloud',
  CISCO_DUO_AUTH = 'Duo',
}

export type AuthenticationOptionsMapType = {
  [key in AuthenticationOptionsType]: string;
};

export const AuthenticationOptionsMap: AuthenticationOptionsMapType = {
  [AuthenticationOptionsType.USERNAME]: AuthenticationOptionsName.USERNAME,
  [AuthenticationOptionsType.GOOGLE_AUTH]: AuthenticationOptionsName.GOOGLE_AUTH,
  [AuthenticationOptionsType.MICROSOFT_AUTH]: AuthenticationOptionsName.MICROSOFT_AUTH,
  [AuthenticationOptionsType.OKTA_AUTH]: AuthenticationOptionsName.OKTA_AUTH,
  [AuthenticationOptionsType.JUMP_CLOUD_AUTH]: AuthenticationOptionsName.JUMP_CLOUD_AUTH,
  [AuthenticationOptionsType.CISCO_DUO_AUTH]: AuthenticationOptionsName.CISCO_DUO_AUTH,
};

export type AuthorizationSettings = {
  googleAuth?: boolean;
  microsoftAuth?: boolean;
  oktaAuth?: boolean;
  jumpCloudAuth?: boolean;
  ciscoDuoAuth?: boolean;
};

export type Company = {
  alertDaysInterval?: number;
  id?: string;
  accountingGmiHandoffDate?: string;
  allowedUrls?: string[] | null;
  authorizationSettings?: AuthorizationSettings | null;
  currency?: string;
  defaultUserRole?: USER_ROLES;
  gmiApiKey?: string;
  companyTags?: string[];
  isLoading?: boolean;
  inviteOnly?: boolean | null;
  lastGmiInvoiceDate?: string;
  name?: string;
  invoiceEmail?: string;
  customerSuccessManager?: CustomerSuccessManager;
  customFilters?: CustomFilter[];
  uploadsEmail?: string;
  conversionRates?: ConversionRates;
  slackAuthentication?: SlackAuthDto;
};
