import React from 'react';

export const UnassignedToolOwnerIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ height, width }) => (
  <svg width={width} height={height} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='.25' y='.25' width='39.5' height='39.5' rx='19.75' fill='#FDE2D6' />
    <path
      d='M22.55 20.22a4.92 4.92 0 001.7-3.72 5 5 0 10-10 0A4.92 4.92 0 0016 20.22a8 8 0 00-4.7 7.28 1 1 0 002 0 6 6 0 0112 0 1 1 0 002 0 8 8 0 00-4.75-7.28zm-3.3-.72a3 3 0 110-6 3 3 0 010 6zm8.5-5a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM27 19.79a1.05 1.05 0 00-.29.71 1.002 1.002 0 00.29.71c.097.088.209.16.33.21a.94.94 0 00.76 0 .9.9 0 00.54-.54.84.84 0 00.08-.38 1 1 0 00-1.71-.71z'
      fill='#F67031'
    />
    <rect x='.25' y='.25' width='39.5' height='39.5' rx='19.75' stroke='#F67031' strokeWidth='.5' />
  </svg>
);

UnassignedToolOwnerIcon.defaultProps = {
  height: '40',
  width: '40',
};
