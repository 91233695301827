import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import { allFeaturesEnabledList, getCompleteFeaturesList } from 'shared/helpers/pricing-plans-features.helper';
import { useAppDispatch } from 'shared/store/hooks';
import { setPricingPlansFeatures } from 'shared/store/pricing-plans-features';

export const usePricingPlans = (): { pricingPlanName?: string } => {
  const auth0 = useAuth0();
  const dispatch = useAppDispatch();
  const keyPrefix = 'http://sastrify.com';

  const auth0Cypress = useMemo(() => {
    return localStorage.getItem('auth0Cypress');
  }, []);

  useEffect(() => {
    let user;

    if (window.Cypress && auth0Cypress) {
      user = JSON.parse(auth0Cypress).body.decodedToken.user;
    } else {
      user = auth0.user;
    }

    const enableAllFeatures = user?.[`${keyPrefix}/enable_all_features`] || false;
    const pricingPlanFeatures = user?.[`${keyPrefix}/pricing_plans_features`] || [];
    const features = enableAllFeatures ? allFeaturesEnabledList : getCompleteFeaturesList(pricingPlanFeatures);

    dispatch(setPricingPlansFeatures(features));
  }, [auth0.user, auth0Cypress, dispatch]);

  return {
    pricingPlanName: auth0.user?.[`${keyPrefix}/pricing_plan_name`],
  };
};
