import { UserNameAndAvatarComponent } from 'components/index';
import { useTranslation } from 'react-i18next';
import Tooltip from 'react-tooltip-lite';
import { formatFullDate } from 'shared/helpers/common.helper';
import { dateToHumanReadableString } from 'shared/helpers/dates.helper';

interface IProps {
  authorName?: string | null;
  authorAvatarUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  showAvatar?: boolean;
  isCommentDeleted?: boolean;
}

export const AuthorDateInfo: React.FC<IProps> = ({
  authorAvatarUrl,
  authorName,
  createdAt,
  isCommentDeleted,
  showAvatar,
  updatedAt,
}) => {
  const { t } = useTranslation();

  if (!createdAt) {
    return null;
  }

  const hasBeenEdited = updatedAt ? new Date(updatedAt) > new Date(createdAt) : false;
  const dateFormatted = new Date(createdAt).toISOString();
  const updateAtDate = updatedAt ? dateToHumanReadableString(updatedAt) : null;

  return (
    <>
      {authorName && (
        <div className='subscription-comment__author-name'>
          <UserNameAndAvatarComponent
            height='24px'
            width='24px'
            avatarUrl={authorAvatarUrl || undefined}
            name={String(authorName)}
            hideAvatar={!showAvatar}
          />
        </div>
      )}
      <div className='comment-info'>
        {dateFormatted && (
          <Tooltip content={formatFullDate(dateFormatted)}>
            {!isCommentDeleted && <span className='date-separator'>&nbsp;-&nbsp;</span>}

            <span className='comment-date'>{dateToHumanReadableString(dateFormatted)}</span>
          </Tooltip>
        )}
      </div>
      {!isCommentDeleted && hasBeenEdited && (
        <Tooltip content={updateAtDate}>
          <span className='ml-2'>
            ({t('subscription_detail_view:subscription_comment_section.edited_comment_text')})
          </span>
        </Tooltip>
      )}
    </>
  );
};
