/* eslint-disable no-console */

import { Box, Button, styled, Typography } from '@mui/material';
import emptyState from 'assets/images/empty-integration-state.svg';
import { Alert, Icon } from 'asteroids';
import { CODAT_INTEGRATION_STATUS, CodatIntegrationDetailsRes, CodatPlatformType } from 'libs/models';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { capitalizeString } from 'shared/helpers/common.helper';
import { Company } from 'shared/models';
import {
  createCodatConnection,
  disconnectFromCodatV2,
  updateCodatConnectionName,
} from 'shared/store/common/connect-v2';
import { useAppSelector } from 'shared/store/hooks';
import { clearSelectedIntegration } from 'shared/store/views/connect/multiple-integrations-item.slice';

import { AccountingConnection, AccountingConnectionViewOnly } from '../../accounting-connection';

type Props = {
  handleCloseDrawer: () => void;
};

const Wrapper = styled(Box)(() => ({
  '& .text-align-center': {
    textAlign: 'center',
  },
  '.connection-drawer--title': {
    textTransform: 'capitalize',
  },
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
}));

export const DrawerManageIntegration: FC<Props> = ({ handleCloseDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [addNewConnection, setAddNewConnection] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState<CodatIntegrationDetailsRes>();
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const user = useAppSelector((state) => state.authentication.user);
  const company = queryClient.getQueryData<Company>('company');

  const queryData = queryClient.getQueryData<CodatIntegrationDetailsRes[]>('codat-integration-details');
  const { refetch } = useQuery('codat-integration-details');

  const { selectedItem } = useAppSelector((state) => state.views.connect.multipleIntegrationsItem);
  const codatConnections = useMemo(() => queryData || [], [queryData]);

  const currentCodatConnections: CodatIntegrationDetailsRes[] | undefined = codatConnections
    ?.filter((connection) => connection.platformKey === selectedItem?.platformType)
    .sort((a, b) => new Date(a.createdAt as string).getTime() - new Date(b.createdAt as string).getTime());

  const newConnectionName = useMemo(() => {
    let sastrifyConnectionName;
    if (!currentCodatConnections?.length) {
      sastrifyConnectionName = `${selectedItem?.platformName}`;
    } else {
      sastrifyConnectionName = `${selectedItem?.platformName} ${currentCodatConnections?.length + 1}`;
    }
    return capitalizeString(sastrifyConnectionName);
  }, [selectedItem, currentCodatConnections]);

  const platformName = capitalizeString(selectedItem?.platformName);

  const [canAddNewConnection, setCanAddNewConnection] = useState<boolean>(true);
  const [alertText, setAlertText] = useState('');

  const displayText = useMemo(() => {
    if (isDisconnecting) {
      return {
        alert: t('connect_view:tab_section.manage_integrations_drawer.disconnect_confirmation_text', {
          name: currentIntegration?.sastrifyConnectionName,
        }),
        heading: t('connect_view:tab_section.manage_integrations_drawer.disconnect_header_text'),
        subHeading: t('connect_view:tab_section.manage_integrations_drawer.disconnect_sub_header_text', {
          name: platformName,
        }),
      };
    }
    return {
      alert: t('connect_view:tab_section.manage_integrations_drawer.delete_confirmation_text', {
        name: currentIntegration?.sastrifyConnectionName,
      }),
      heading: t('connect_view:tab_section.manage_integrations_drawer.header_text', { name: platformName }),
      subHeading: t('connect_view:tab_section.manage_integrations_drawer.sub_header_text'),
    };
  }, [isDisconnecting, platformName, currentIntegration, t]);

  const getConnectionById = (id: string | number) => {
    const connection = codatConnections?.find(
      (connection) => connection.codatConnectionId === id
    ) as CodatIntegrationDetailsRes;
    return connection;
  };

  const handleCreateConnection = async (name: string) => {
    await dispatch(
      createCodatConnection({
        companyId: String(user?.companyId),
        companyName: String(company?.name),
        platformType: selectedItem?.platformType as CodatPlatformType,
        refetch,
        sastrifyConnectionName: name,
      })
    );
    setAddNewConnection(false);
  };

  const handleConnect = (linkUrl: string) => {
    if (linkUrl && linkUrl.length) {
      window.open(linkUrl);
    }

    handleCloseDrawer();

    queryClient.invalidateQueries('codat-integration-details');
  };

  const handleDelete = async (connectionId: string | number) => {
    const data = getConnectionById(connectionId);
    const { codatCompanyId, companyId } = data;
    setCurrentIntegration(data);

    await dispatch(
      disconnectFromCodatV2({
        codatCompanyId,
        companyId,
        refetch,
      })
    );

    setAlertText(
      t('connect_view:tab_section.manage_integrations_drawer.delete_confirmation_text', {
        name: data.sastrifyConnectionName,
      })
    );
  };

  const handleDisconnect = (connectionId: string | number) => {
    const data = getConnectionById(connectionId);
    setCurrentIntegration(data);
    setIsDisconnecting(true);
  };

  const confirmDisconnect = async () => {
    const { codatCompanyId, companyId, sastrifyConnectionName } = currentIntegration as CodatIntegrationDetailsRes;

    await dispatch(
      disconnectFromCodatV2({
        codatCompanyId,
        companyId,
        refetch,
      })
    );
    setAlertText(
      t('connect_view:tab_section.manage_integrations_drawer.disconnect_confirmation_text', {
        name: sastrifyConnectionName,
      })
    );
    setIsDisconnecting(false);
  };

  const handleSave = (connectionId: string | number, name: string): void => {
    const codatConnection = getConnectionById(connectionId);
    if (addNewConnection) {
      handleCreateConnection(name);
      return;
    }

    dispatch(
      updateCodatConnectionName({
        codatCompanyId: codatConnection.codatCompanyId,
        companyId: codatConnection.companyId,
        name,
        refetch,
      })
    );
  };

  const handleClose = () => {
    dispatch(clearSelectedIntegration());
    handleCloseDrawer();
  };

  const handleCancelEdit = () => {
    setAddNewConnection(false);
    setCanAddNewConnection(true);
  };

  const renderEmptyState = () => (
    <>
      <img alt='empty-state' src={emptyState} />
      <Typography className='text-align-center' mt={3} color='text.secondary' variant='body'>
        {t('connect_view:tab_section.manage_integrations_drawer.empty_state_text1', { name: platformName })}
        <br />
        {t('connect_view:tab_section.manage_integrations_drawer.empty_state_text2', { name: platformName })}
      </Typography>
    </>
  );

  const renderConnectionsList = () => {
    const addNewConnectionComponent = (
      <Box key='new-connection'>
        <AccountingConnection
          type={selectedItem?.platformType as CodatPlatformType}
          connectionId='new-connection'
          connectionTitle={newConnectionName}
          isConnected={false}
          handleDelete={handleDelete}
          handleSave={handleSave}
          handleConnect={() => handleConnect('')}
          handleDisconnect={handleDisconnect}
          editConnectionName
          handleCancel={handleCancelEdit}
          connectionsList={currentCodatConnections}
          onEditMode={(value) => {
            setCanAddNewConnection(!value);
          }}
        />
      </Box>
    );

    if (isDisconnecting && currentIntegration) {
      const { platformKey, sastrifyConnectionName } = currentIntegration;
      return (
        <Box mt={8}>
          <AccountingConnectionViewOnly
            connectionTitle={sastrifyConnectionName}
            type={platformKey as CodatPlatformType}
          />
        </Box>
      );
    }

    if (currentCodatConnections?.length) {
      return (
        <Box mt={8} mb={2}>
          {currentCodatConnections?.map((connection) => {
            const { codatConnectionId, linkUrl, platformKey, sastrifyConnectionName, status } = connection;
            const isConnected = status === CODAT_INTEGRATION_STATUS.CONNECTED;

            return (
              <Box mb={3}>
                {renderAccountingConnection(
                  isConnected,
                  sastrifyConnectionName,
                  codatConnectionId,
                  platformKey,
                  linkUrl
                )}
              </Box>
            );
          })}
          {addNewConnection && addNewConnectionComponent}
        </Box>
      );
    }

    if (addNewConnection) {
      return (
        <Box mt={8} mb={2}>
          {addNewConnectionComponent}
        </Box>
      );
    }

    return null;
  };

  const renderAccountingConnection = (
    isConnected: boolean,
    connectionTitle: string,
    connectionId: string,
    type: string,
    linkUrl: string
  ) => (
    <Box key={connectionId}>
      <AccountingConnection
        type={type as CodatPlatformType}
        connectionId={connectionId}
        connectionTitle={connectionTitle}
        isConnected={isConnected}
        connectionsList={currentCodatConnections}
        handleDelete={handleDelete}
        handleSave={handleSave}
        handleConnect={() => handleConnect(linkUrl)}
        handleDisconnect={handleDisconnect}
        editConnectionName={false}
        onEditMode={(value: boolean) => {
          setCanAddNewConnection(!value);
        }}
      />
    </Box>
  );

  return (
    <Wrapper p={5} data-testid='connection-drawer'>
      <Box>
        <Button variant='text' startIcon={<Icon>ClearOutlined</Icon>} onClick={handleClose}>
          {t('connect_view:tab_section.manage_integrations_drawer.close')}
        </Button>
        <Typography
          className='connection-drawer--title'
          mb={3}
          mt={4}
          color='text.primary'
          variant='h2'
          data-testid='title'>
          {displayText.heading}
        </Typography>
        <Typography color='text.primary' variant='body' data-testid='sub-title'>
          {displayText.subHeading}
        </Typography>
        <Box data-testi='connection-list'>{renderConnectionsList()}</Box>
      </Box>
      {!currentCodatConnections?.length && !addNewConnection && (
        <Box display='flex' alignItems='center' flexDirection='column' data-testid='empty-page'>
          {renderEmptyState()}
        </Box>
      )}
      <Box data-testid='footer'>
        <Box mb={4}>{!!alertText && <Alert description={alertText} onClose={() => setAlertText('')} />}</Box>
        {isDisconnecting ? (
          <Box display='flex' justifyContent='flex-end'>
            <Button variant='text' color='secondary' onClick={() => setIsDisconnecting(false)}>
              {t('connect_view:tab_section.manage_integrations_drawer.cancel_text')}
            </Button>
            <Button onClick={confirmDisconnect} sx={{ ml: 1 }} color='error'>
              {t('connect_view:tab_section.manage_integrations_drawer.disconnect_text')}
            </Button>
          </Box>
        ) : (
          <Box display='flex' justifyContent='flex-end'>
            <Button
              startIcon={<Icon>add</Icon>}
              variant='text'
              disabled={!canAddNewConnection}
              onClick={() => setAddNewConnection(true)}>
              {t('connect_view:tab_section.manage_integrations_drawer.add_new_text')}
            </Button>
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};
