import { PRICING_PLANS_FEATURE_STATUS, PRICING_PLANS_FEATURES } from 'libs/constants';
import { PricingPlanFeature } from 'libs/models';

export const allFeatureNamesList = Object.values(PRICING_PLANS_FEATURES).map((featureName) => featureName);

export const allFeaturesEnabledList: PricingPlanFeature[] = Object.values(PRICING_PLANS_FEATURES).map(
  (featureName) => ({
    name: featureName,
    status: PRICING_PLANS_FEATURE_STATUS.ENABLED,
  })
);

export const getCompleteFeaturesList = (receivedFeatureList: PricingPlanFeature[]) => {
  const missingFeatures = allFeatureNamesList.map((featureName) => {
    const feature = receivedFeatureList?.find((feature) => feature.name === featureName);
    if (!feature) {
      return { name: featureName, status: PRICING_PLANS_FEATURE_STATUS.DISABLED };
    }

    return feature;
  });

  return [...missingFeatures, ...receivedFeatureList];
};
