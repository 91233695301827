import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from 'asteroids';

export const FileItem = styled(Box)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.highlight,
  },
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: `${theme.spacing(1.5)} !important`,
  padding: theme.spacing(1.5),
}));

export const FlexBox = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
}));

export const FlexBoxAlignRight = styled(FlexBox)(({ theme }) => ({
  '& span': {
    color: colors.text.secondary,
  },
  '& > *': {
    '&:hover': {
      cursor: 'pointer',
    },
    '&:last-child': {
      marginRight: theme.spacing(1),
    },
    marginRight: theme.spacing(2),
  },
  justifyContent: 'flex-end',
}));

export const FileName = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const RegularText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
