import { Box, Skeleton, Stack } from '@mui/material';
import { FC } from 'react';

export const UsageAnalyticsSkeleton: FC = () => {
  return (
    <Stack spacing={1} sx={{ mb: 2 }}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Skeleton variant='text' width={210} height={45} />
        <Skeleton variant='text' width={100} height={45} />
      </Box>
      <Skeleton variant='rectangular' width='100%' height={95} />
    </Stack>
  );
};
