import { Chip, type Theme, Typography, useTheme } from '@mui/material';

import { backgrounds } from '../../theme/color.styles';
import { Icon } from '../icon/icon.component';
import { LinearGradientChipProps } from './linear-gradient-chip.props';

const getParameters = (size: 'small' | 'medium' | 'large', theme: Theme) => {
  switch (size) {
    case 'small':
      return {
        chipSpacing: 1,
        height: 3,
        iconSize: theme.spacing(1.5),
        labelVariant: 'small',
        rawLabelSpacing: 0.5,
      };
    case 'large':
      return {
        chipSpacing: 3,
        height: 7,
        iconSize: theme.spacing(4.5),
        labelVariant: 'h2',
        rawLabelSpacing: 1.5,
      };
    default:
      return {
        chipSpacing: 2,
        height: 5,
        iconSize: theme.spacing(3),
        labelVariant: 'h3',
        rawLabelSpacing: 1,
      };
  }
};

const LinearGradientChip: React.FC<LinearGradientChipProps> = (props: LinearGradientChipProps) => {
  const { icon, size = 'medium', sx, title = '', withIcon = true } = props;
  const theme = useTheme();
  const { chipSpacing, height, iconSize, labelVariant, rawLabelSpacing } = getParameters(size, theme);

  return (
    <Chip
      sx={{
        '& .MuiChip-icon': { fontSize: iconSize, m: 0 },
        '& .MuiChip-label': { p: 0 },
        '& .MuiSvgIcon-root': { fontSize: iconSize },
        background: backgrounds.successToWarningGradient,
        borderRadius: `calc(${theme.spacing(height)} / 2)`,
        height: theme.spacing(height),
        px: chipSpacing,
        ...sx,
      }}
      icon={withIcon ? icon || <Icon variant='Filled'>star</Icon> : undefined}
      label={
        title && (
          <Typography
            variant={labelVariant as React.ComponentProps<typeof Typography>['variant']}
            sx={{ display: 'flex', ml: withIcon ? rawLabelSpacing : 0 }}>
            {title}
          </Typography>
        )
      }
    />
  );
};

export default LinearGradientChip;
