import * as React from 'react';
import { SVGProps } from 'react';

export const InviteTeamIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={105} height={100} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M64.735 19.86c-19.657.17-27.406 14.44-34.941 22.876C22.032 51.423 2.167 63.089.247 76.286c-1.92 13.198 7.99 18.219 7.99 18.219s39.246 5.728 66.353 5.488c10.69-.093 19.496-1.113 23.257-3.776 13.3-9.416 2.065-22.784 1.155-27.229-.909-4.444 8.199-23.14-1.698-28.41-9.896-5.27-9.742-19.97-30.746-20.694a42.881 42.881 0 0 0-1.821-.025'
      fill='#E5FAF5'
    />
    <path
      d='M55.992 51.648a20.435 20.435 0 0 1 6.724 12.612c1.08 8.175 11.536 2.016 14.54-6.929l2.88 1.91S75.7 74.498 62.717 75.127c-12.983.63-17.645-20.087-6.724-23.48Z'
      fill='#0DA17D'
    />
    <path
      d='M58.737 54.608c2.027 4.94-2.096 10.548-3.767 13.7-.485.917-.845 1.988-1.109 3.115 2.061 2.227 4.865 3.713 8.273 3.713.19 0 .383 0 .576-.014 12.988-.63 17.424-15.885 17.424-15.885l-2.882-1.91c-2.019 6.011-7.393 10.76-11.035 10.76-1.783 0-3.152-1.14-3.507-3.827a20.42 20.42 0 0 0-3.973-9.653'
      fill='#9CD4C7'
    />
    <path
      d='M51.672 39.901c.523.98.913 2.026 1.16 3.108a1 1 0 0 1-.18.746 1.01 1.01 0 0 1-.657.402h-.009a2.228 2.228 0 0 1-2.267-.849l-1.244-1.37a1.788 1.788 0 0 1-.03-2.074c.728-1.188 2.702-1.072 3.227.033'
      fill='#F5A38C'
    />
    <path d='m49.529 42.365-1.448 5.833 3.29.56-.26-4.357-1.582-2.036Z' fill='#F5A38C' />
    <path d='m50.905 44.136.206.265.129 2.146-.107-2.362a2.015 2.015 0 0 1-.224-.049' fill='#EBD1CF' />
    <path d='m50.889 44.114.016.022c.073.02.148.036.224.049v-.013s-.095-.02-.24-.057' fill='#E89985' />
    <path
      d='M49.519 43.536s.107 2.733 1.724 3.09v-.08l-.128-2.145-.206-.265-.016-.022c-.374-.096-1.081-.306-1.37-.578'
      fill='#E89985'
    />
    <path
      d='M48.988 41.814s-.989-.318-1.158.447c-.17.766.931 1.087 1.494.518a1.48 1.48 0 0 1-.339-.965'
      fill='#F5A38C'
    />
    <path d='M56.886 80.952h-21.37v15.181h21.37v-15.18Z' fill='#E5E5E5' />
    <path d='M51.258 95.904v.004l5.62.469.001-.003-5.62-.47Z' fill='#E0E0E0' />
    <path
      d='M52.825 82.853c.633 1.1.904 2.369.774 3.629l-.826 8.003h-1.4l-.126 1.648h5.64l-.02-8.658-.305-4.06-3.737-.562Z'
      fill='#D4D4D4'
    />
    <path
      d='M51.78 94.38s.461 1.265 1.608 1.363c1.148.098 1.453 1.216.42 1.504-1.031.289-3.703-1.029-3.703-1.029l.074-1.65 1.601-.187ZM66.316 94.514s1.263.476 2.086-.336c.823-.811 1.872-.31 1.421.674-.45.983-3.189 2.13-3.189 2.13l-1.494-1.39 1.176-1.078ZM51.652 48.695l-3.291-.565s-10.946 0-12.866 3.742c-1.92 3.743-1.514 17.595 4.817 28.98l12.966.37s-.722-8.36 1.692-12.914c2.414-4.554 9.931-14.228-3.318-19.613Z'
      fill='#0DA17D'
    />
    <path
      d='m40.316 80.851 6.342-.955a6.103 6.103 0 0 1 5.088 1.6 6.028 6.028 0 0 1 1.504 2.276c.32.863.439 1.787.345 2.701l-.826 8.003H49.66l.268-7.495c.025-.75-.25-1.479-.764-2.028a2.861 2.861 0 0 0-2.54-.868c-2.7.438-6.041.249-6.31-3.23'
      fill='#26333B'
    />
    <path
      d='M41.964 53.737c.018.106.032.212.041.318.06.624.088 1.273.088 1.948 0 5.362-1.758 12-2.938 15.859a2.82 2.82 0 0 0-.126.834c0 .69.25 1.356.705 1.877a2.886 2.886 0 0 0 1.77.958l5.87.843a19.638 19.638 0 0 1-3.435-14.6c.122-.742.244-1.412.362-1.971.544-2.607-1.364-5.026-2.337-6.06v-.006ZM49.03 78.42v1.418l-9.549-.6.835 1.62 6.34-.955a6.066 6.066 0 0 1 3.712.616l.495-.017-1.833-2.082Z'
      fill='#0D9473'
    />
    <path
      d='M47.571 79.83c-.306 0-.611.024-.913.07l-6.341.954 10.052-.336a6.066 6.066 0 0 0-2.798-.684'
      fill='#243036'
    />
    <path
      d='M35.316 52.216c-2.4 3.365-4.537 10.769-2.682 21.227a7 7 0 0 0 2.245 3.999 7.075 7.075 0 0 0 4.25 1.767l9.906.623v-3.215l-7.526-1.08a2.896 2.896 0 0 1-1.9-1.118 2.859 2.859 0 0 1-.546-2.126c.021-.144.053-.286.096-.425 1.328-4.342 3.386-12.2 2.85-17.806-.31-3.402-4.695-4.628-6.689-1.841'
      fill='#0DA17D'
    />
    <path
      d='M38.275 50.676a3.588 3.588 0 0 0-1.67.405 3.563 3.563 0 0 0-1.286 1.135c-1.763 2.47-3.383 7.121-3.385 13.558a43.93 43.93 0 0 0 .7 7.67 7 7 0 0 0 2.245 3.998 7.074 7.074 0 0 0 4.25 1.767l9.906.623v-3.214l-7.526-1.08a2.886 2.886 0 0 1-1.774-.958 2.854 2.854 0 0 1-.58-2.713c1.179-3.858 2.937-10.502 2.938-15.859 0-.671-.028-1.324-.087-1.948-.193-2.107-1.952-3.38-3.735-3.38'
      fill='#7DCCB8'
    />
    <path d='M49.045 77.398s4.02-.1 3.447 1.983c-.572 2.084-3.447.45-3.447.45v-2.433Z' fill='#F5A38C' />
    <path d='m62.42 80.579 1.62-9.117H52.532l-.998 7.774-5.275.07v1.273h16.16Z' fill='#F7DE0D' />
    <path
      d='M57.835 74.69a1.157 1.157 0 0 1 1.064.706 1.137 1.137 0 0 1-.25 1.247 1.153 1.153 0 0 1-1.965-.81c0-.303.121-.594.337-.808.216-.215.509-.336.814-.336Z'
      fill='#fff'
    />
    <path
      d='M22.51 88.7a5.342 5.342 0 0 0-.942.077c.684 2.918 3.842 3.773 3.842 3.773l1.185.265a6.79 6.79 0 0 0-.038-.574h-.76a7.885 7.885 0 0 1-1.016-3.004c-.768-.432-1.629-.53-2.265-.53'
      fill='#73C7B2'
    />
    <path
      d='M25.527 85.543a5.744 5.744 0 0 0-.747 3.69c.854.484 1.595 1.379 1.776 3.008h1.037c1.663-4.349-2.062-6.703-2.062-6.703'
      fill='#4FBA9E'
    />
    <path d='M24.775 89.234a7.891 7.891 0 0 0 1.013 3.003h.76c-.18-1.63-.92-2.525-1.776-3.007' fill='#29AB8C' />
    <path d='M26.36 92.24s1.162-3.654 4.68-4.35c0 0 .66 2.374-2.28 4.35h-2.4Z' fill='#0DA17D' />
    <path d='m24.074 92.158.636 4.346 4.026.016.593-4.341-5.255-.02Z' fill='#26333B' />
    <path
      d='M49.073 44.195s-3.415-.9-2.333-3.156c0 0-1.207-1.91.797-2.888 1.182-.576 2.932-.88 4.172-1.035a.91.91 0 0 1 .868.406.895.895 0 0 1 .015.952 4.415 4.415 0 0 1-1.355 1.416 4.448 4.448 0 0 1-1.838.694 4.137 4.137 0 0 1-.326 3.607'
      fill='#8C5229'
    />
    <path
      d='M49.997 42.194s-.291-.643-.762-.385c-.47.26-.05 1.193.654 1.01l.108-.626ZM77.724 57.638s1.225-4.183 2.126-4.244c.9-.06 0 2.39 0 2.39s3.308.834.302 3.463l-2.428-1.61Z'
      fill='#F5A38C'
    />
    <path
      d='m56.732 80.438 10.096 13.585-1.971 1.804s-8.782-10.195-13.495-13.065l-1.646-2.19 7.016-.135Z'
      fill='#26333B'
    />
    <path
      d='M72.545 20.045a10.752 10.752 0 0 1 3.149-7.592 10.884 10.884 0 0 1 7.614-3.188c5.816-.047 10.679 4.53 10.93 10.307a10.725 10.725 0 0 1-.74 4.422c-.552 1.553-1.436 2.918-2.74 4.439-1.972 2.296-2.656 5.796-2.894 7.887a1.967 1.967 0 0 1-.648 1.25 1.99 1.99 0 0 1-1.321.502h-4.996c-.488 0-.96-.18-1.323-.504a1.967 1.967 0 0 1-.646-1.252c-.238-2.091-.922-5.59-2.894-7.888-1.305-1.52-2.189-2.885-2.741-4.438a10.7 10.7 0 0 1-.748-3.945'
      fill='#F7DD0E'
    />
    <path
      d='M77.735 22.091c-.417.067-.477.169-.477.169s.017.077.116.184c.3.327 1.005.722 1.864 1.081-.315-.66-.609-1.03-.862-1.231a.857.857 0 0 0-.533-.212.78.78 0 0 0-.107.008l-.001.001Zm10.724.075c-.335.221-.64.7-.927 1.365.998-.44 1.726-.922 1.779-1.23.016-.106-.2-.189-.294-.22a.618.618 0 0 0-.2-.036.642.642 0 0 0-.358.12Zm-3.085 8.633c.38-2.736.818-4.87 1.312-6.4-1.184.408-2.375.643-3.29.643-.986 0-2.23-.3-3.278-.65 1.524 4.306 2.156 12.266 2.264 13.778h2.241c.059-.873.29-4.063.747-7.37h.004Zm-3.479 7.638c0-.035-.24-3.581-.8-7.34-.514-3.438-1.067-5.586-1.584-6.917l-.225-.088c-.921-.367-2.474-1.088-2.53-1.787-.021-.25.119-.575.896-.7a1.296 1.296 0 0 1 1.032.305c.445.353.843 1.013 1.197 1.872 1.191.432 2.548.759 3.515.759 1.067 0 2.384-.331 3.502-.755.395-1.05.823-1.732 1.281-2.036a1.103 1.103 0 0 1 1-.142c.62.212.656.575.622.772-.127.757-1.745 1.485-2.434 1.76l-.083.035c-1.281 3.67-1.984 11.056-2.175 14.253a.247.247 0 0 1-.25.233h-2.71a.25.25 0 0 1-.25-.232'
      fill='#FAE965'
    />
    <path
      d='M82.624 44.25h1.544a.81.81 0 0 1 .571.234.8.8 0 0 1 .237.568v.025a.8.8 0 0 1-.237.568.81.81 0 0 1-.57.235h-1.545a.81.81 0 0 1-.572-.236.8.8 0 0 1-.236-.567v-.025a.8.8 0 0 1 .236-.568.81.81 0 0 1 .572-.235Z'
      fill='#666F73'
    />
    <path
      d='M79.719 43.605c.049.452.399.849.925 1.046a8.67 8.67 0 0 0 5.506 0c.526-.195.876-.594.925-1.046l.447-4.15c.08-.743-.677-1.387-1.641-1.387h-4.968c-.96 0-1.722.636-1.642 1.386l.448 4.151Z'
      fill='#0DA07C'
    />
    <path d='m79.6 42.518.058.53-.057-.53Zm7.556.342-.02.19.02-.19Z' fill='#CCD1D1' />
    <path d='M87.19 42.518h-3.906v.532h3.85l.02-.19.037-.342Z' fill='#47B79C' />
    <path d='m79.516 41.726-.057-.53.056.53Z' fill='#CCD1D1' />
    <path
      d='M87.334 41.192h-4.05v.532h3.992l.058-.532ZM87.477 39.867h-4.193v.533h4.135l.02-.178.038-.355Z'
      fill='#47B79C'
    />
    <path d='m79.374 40.4-.058-.53.058.53Z' fill='#CCD1D1' />
    <path
      d='M83.284 38.067h-2.372c-.916 0-1.647.58-1.646 1.278a.986.986 0 0 0 0 .106l.044.413h3.968l.006-1.797Z'
      fill='#36B092'
    />
    <path
      d='M81.82 44.965v.087a.77.77 0 0 1 0-.086Zm0 .107v.17a.635.635 0 0 0 .64.637h.17a.81.81 0 0 1-.57-.235.8.8 0 0 1-.237-.568'
      fill='#DEDEDE'
    />
    <path
      d='M81.82 44.966V45.077a.8.8 0 0 0 .236.568.81.81 0 0 0 .571.235h.66v-.78a8.906 8.906 0 0 1-1.468-.134Z'
      fill='#525D61'
    />
    <path
      d='M83.284 40.4h-3.912l.087.792h3.825V40.4Zm0 1.325h-3.769l.085.793h3.684v-.793Zm0 1.326h-3.63l.06.554c.05.452.4.849.926 1.046h.016c.376.136.762.239 1.156.308.483.085.972.13 1.463.134l.009-2.042Z'
      fill='#3DB396'
    />
    <path
      d='M83.284 42.518H79.6l.057.532h3.626v-.532ZM83.284 41.192h-3.827l.058.532h3.769v-.532ZM83.284 39.867h-3.97l.059.533h3.911v-.533Z'
      fill='#0DA07C'
    />
    <path
      d='M87.809 11.617a.836.836 0 0 0-.816.673.824.824 0 0 0 .509.925c.132.054 2.881 1.243 4.062 6.216a.825.825 0 0 0 .676.643.838.838 0 0 0 .85-.388.823.823 0 0 0 .094-.635c-1.402-5.896-4.924-7.32-5.073-7.377a.84.84 0 0 0-.302-.057ZM85.768 10.736a.864.864 0 0 0-.8.525.85.85 0 0 0 .186.935.861.861 0 0 0 1.468-.611.852.852 0 0 0-.252-.598.862.862 0 0 0-.602-.25Z'
      fill='#F9E54A'
    />
    <path
      d='M83.402 0a.636.636 0 0 0-.585.39.627.627 0 0 0-.047.24v5.492a.628.628 0 0 0 .633.604.636.636 0 0 0 .633-.604V.63a.628.628 0 0 0-.634-.63Z'
      fill='#35D0BA'
    />
    <path
      d='M69.171 5.666a.637.637 0 0 0-.585.389.626.626 0 0 0 .137.686l3.907 3.883a.634.634 0 0 0 1.033-.686.629.629 0 0 0-.137-.204L69.619 5.85a.627.627 0 0 0-.448-.184Z'
      fill='#0DA07C'
    />
    <path
      d='M68.663 19.675h-5.52a.636.636 0 0 0-.608.63.628.628 0 0 0 .608.629h5.525a.637.637 0 0 0 .609-.383.627.627 0 0 0-.359-.836.64.64 0 0 0-.25-.04'
      fill='#35D0BA'
    />
    <path
      d='M72.748 29.937a.642.642 0 0 0-.448.183l-3.906 3.884a.628.628 0 0 0 .447 1.074.635.635 0 0 0 .448-.184l3.906-3.883a.628.628 0 0 0-.447-1.074ZM97.635 5.666a.635.635 0 0 0-.449.184L93.28 9.734a.628.628 0 0 0 .453 1.059.636.636 0 0 0 .444-.169l3.907-3.883a.629.629 0 0 0-.448-1.075Z'
      fill='#0DA07C'
    />
    <path
      d='M103.666 19.675h-5.525a.64.64 0 0 0-.465.175.63.63 0 0 0-.144.701.63.63 0 0 0 .609.383h5.525a.638.638 0 0 0 .609-.383.618.618 0 0 0 0-.494.635.635 0 0 0-.609-.383Z'
      fill='#35D0BA'
    />
    <path
      d='M94.06 29.937a.635.635 0 0 0-.586.388.625.625 0 0 0 .138.686l3.907 3.883a.635.635 0 0 0 1.081-.445.627.627 0 0 0-.185-.445l-3.908-3.884a.642.642 0 0 0-.448-.183Z'
      fill='#0DA07C'
    />
  </svg>
);
