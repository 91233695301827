import WestRoundedIcon from '@mui/icons-material/WestRounded';
import { Box, Button, Typography } from '@mui/material';
import { Alert } from 'asteroids';
import { AxiosError } from 'axios';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { WorkflowTemplateItemDto } from 'libs/dtos';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { DRAWER_VARIANT } from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import {
  trackActiveWorkflowEdited,
  trackWorkflowActivated,
  trackWorkflowActivatedWithDefaultStagesChanged,
} from 'shared/logic/event-tracking/workflow-requests.events';
import { createRequestWorkflow, editRequestWorkflow } from 'shared/logic/request-workflow-templates.logic';
import { Company, User, WorkflowListItem } from 'shared/models';
import { REQUEST_WORKFLOW_QUERY } from 'views/request-workflows/request-workflows.view';

import { DialogComponent } from '../drawer-workflow-request-details/components/dialog/dialog.component';
import { DialogComponentProps } from '../drawer-workflow-request-details/components/dialog/dialog.component.props';
import { TemplateForm } from './components/template-form.component';
import { DefaultFormI, StageItem, TemplateFormValues } from './components/template-form.props';
import {
  CreateWorkflowPayload,
  DrawerRequestWorkflowProps,
  EditWorkflowPayload,
} from './drawer-request-workflow.props';

export enum WORKFLOW_KEYWORDS {
  REQUEST_APPROVER = 'Request Approver',
  WORKFLOW_NAME = 'workflowName',
}

enum WorkflowState {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
}

export const DrawerRequestWorkflow: FC<DrawerRequestWorkflowProps> = ({ showNotification, toggleDrawer }) => {
  const [dialog, setDialog] = useState<DialogComponentProps>({
    open: false,
  });
  const { t } = useTranslation();
  const { hash, pathname, search } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const users = queryClient.getQueryData<User[]>('users');
  const templates = queryClient.getQueryData<WorkflowTemplateItemDto[]>(REQUEST_WORKFLOW_QUERY.TEMPLATES);
  const workflows = queryClient.getQueryData<WorkflowListItem[]>(REQUEST_WORKFLOW_QUERY.WORKFLOWS_LIST);
  const company = queryClient.getQueryData<Company>('company');

  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.new_request_workflow';

  const { isLoading: isCreatingWorkflow, mutate: createWorkflow } = useMutation(createRequestWorkflow, {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: (data) => {
      const isDraft = data?.state === WorkflowState.DRAFT;
      const defaultTemplateStages = getNewTemplateDefaultValues().stages.map(({ name }) => ({ name }));
      const createdWorkflowStages = data?.steps?.map((step: { name: string; stepOwnerRoleFullName: string }) => ({
        name: step.name,
      }));
      const isDefaultStagesChanged = JSON.stringify(defaultTemplateStages) !== JSON.stringify(createdWorkflowStages);

      queryClient.invalidateQueries('request-workflows-list');
      queryClient.invalidateQueries('request-workflow-templates');

      if (isDraft) {
        openDraftFeedbackDialog();
      } else {
        openActivateFeedbackDialog();
        trackWorkflowActivated({
          companyId: company?.id,
          workflowName: data?.name,
        });

        if (isDefaultStagesChanged) {
          trackWorkflowActivatedWithDefaultStagesChanged({
            companyId: company?.id,
            companyName: company?.name,
            defaultStagesNumber: defaultTemplateStages.length,
            newStagesNumber: createdWorkflowStages?.length,
            workflowType: data?.type,
          });
        }
      }
    },
  });

  const { isLoading: isEditingWorkflow, mutate: editWorkflow } = useMutation(editRequestWorkflow, {
    onError: (err: AxiosError) => {
      const message = getErrorCodeMessage(err) || 'error';

      showNotification?.(message, NotificationAlertType.Error);
    },
    onSuccess: (data) => {
      const isDraft = data?.state === WorkflowState.DRAFT;

      queryClient.invalidateQueries('request-workflows-list');
      queryClient.invalidateQueries('request-workflow-templates');

      if (isDraft) {
        openDraftFeedbackDialog();
      } else if (entity?.state === WorkflowState.ACTIVE && data?.state === WorkflowState.ACTIVE) {
        openUpdateFeedbackDialog();
      } else {
        openActivateFeedbackDialog();
        trackActiveWorkflowEdited({
          companyId: company?.id,
          workflowName: data?.name,
        });
      }
    },
  });

  const getTemplateOrWorkflow = () => {
    const drawerHashCreate = hash.includes(DRAWER_VARIANT.NEW_REQUEST_WORKFLOW);
    const drawerHashEdit = hash.includes(DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_EDIT);
    const drawerHashDetails = hash.includes(DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_DETAILS);

    let drawerType = '';
    if (drawerHashCreate) drawerType = DRAWER_VARIANT.NEW_REQUEST_WORKFLOW;
    if (drawerHashEdit) drawerType = DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_EDIT;
    if (drawerHashDetails) drawerType = DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_DETAILS;

    const drawerHash = drawerHashDetails || drawerHashEdit || drawerHashCreate;
    const entityId = drawerHash ? hash.split(drawerType).slice(-1)[0] : '';

    return {
      entity:
        drawerHashEdit || drawerHashDetails
          ? workflows?.find(({ id }) => String(id) === entityId)
          : templates?.find(({ id }) => String(id) === entityId),
      entityType: drawerType,
    };
  };

  const { entity, entityType } = getTemplateOrWorkflow();
  const isEdit = entityType === DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_EDIT;
  const isDetails = entityType === DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_DETAILS;
  const isActive = entity?.state?.toLowerCase() === WorkflowState.ACTIVE.toLowerCase();

  const sortedUsers = useMemo(() => users?.filter((user: User) => !user.isDeleted).sort() || [], [users]);

  const getAvailableStages = (typeId: number) => [
    ...new Set((templates?.length && templates.find((template) => template.typeId === typeId)?.availableSteps) || []),
  ];

  const getNewTemplateDefaultValues = (): DefaultFormI => ({
    stages: entity?.steps
      ? entity.steps.map(({ name, stepOwnerId, stepOwnerRoleFullName, stepOwnerRoleId }): StageItem => {
          let owner = '';

          if (stepOwnerRoleId && stepOwnerRoleFullName) {
            owner = stepOwnerRoleFullName;
          } else if (stepOwnerId) {
            const stepOwner = sortedUsers.find((user) => user.id === String(stepOwnerId));

            if (stepOwner) {
              owner =
                sortedUsers?.filter((user) => user.name === stepOwner?.name).length > 1
                  ? `${stepOwner.name} - ${stepOwner.email}`
                  : stepOwner.name || '';
            }
          }

          return {
            name,
            owner,
          };
        })
      : [],
    workflowName: (isEdit || isDetails ? entity?.name : '') || '',
  });

  const getCreateWorkflowPayload = (formValues: TemplateFormValues): CreateWorkflowPayload => ({
    name: formValues.form.workflowName,
    state: formValues.type,
    steps: formValues.form.stages.map(({ name, owner }) => {
      const [ownerName, ownerEmail] = owner.split(' - ');
      const user = users?.find(({ email, name }) => (ownerEmail ? email === ownerEmail : name === ownerName));

      return {
        name,
        ...(user && { stepOwnerId: Number(user.id) }),
        ...(!user && owner === WORKFLOW_KEYWORDS.REQUEST_APPROVER && { stepOwnerRoleId: 1 }),
      };
    }),
    typeId: Number((entity as WorkflowListItem).typeId),
  });

  const getEditWorkflowPayload = (formValues: TemplateFormValues): EditWorkflowPayload => ({
    ...getCreateWorkflowPayload(formValues),
    id: Number((entity as WorkflowListItem).id),
  });

  const getTypeId = useMemo(() => {
    const isEdit = hash.includes(DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_EDIT);

    if (isEdit) {
      const workflowId = hash.split(DRAWER_VARIANT.NEW_REQUEST_WORKFLOW_EDIT)[1];
      const workflow = workflows?.find(({ id }) => id === Number(workflowId));

      return Number(workflow?.typeId) || 0;
    }

    const templateId = hash.split(DRAWER_VARIANT.NEW_REQUEST_WORKFLOW)[1];
    const template = templates?.find(({ id }) => id === Number(templateId));

    return template?.typeId || 0;
  }, [hash, workflows, templates]);

  const handleCloseDrawer = () => {
    history.replace(`${pathname}${search}`);
    toggleDrawer();
  };

  const openDraftFeedbackDialog = () => {
    setDialog({
      cancelBtnLabel: t(`${pathToTranslation}.approval_template.draft_on_success_dialog.cancel_button_text`),
      message: t(`${pathToTranslation}.approval_template.draft_on_success_dialog.message`),
      noActionBtn: true,
      onClose: handleRedirectToWorkflows,
      open: true,
      successIcon: true,
      title: t(`${pathToTranslation}.approval_template.draft_on_success_dialog.title`),
    });
  };

  const openUpdateFeedbackDialog = () => {
    setDialog({
      cancelBtnLabel: t(`${pathToTranslation}.approval_template.update_on_success_dialog.cancel_button_text`),
      message: t(`${pathToTranslation}.approval_template.update_on_success_dialog.message`),
      noActionBtn: true,
      onClose: handleRedirectToWorkflows,
      open: true,
      successIcon: true,
      title: t(`${pathToTranslation}.approval_template.update_on_success_dialog.title`),
    });
  };

  const openActivateFeedbackDialog = () => {
    setDialog({
      cancelBtnLabel: t(`${pathToTranslation}.approval_template.activate_on_success_dialog.cancel_button_text`),
      message: t(`${pathToTranslation}.approval_template.activate_on_success_dialog.message`),
      noActionBtn: true,
      onClose: handleRedirectToWorkflows,
      open: true,
      successIcon: true,
      title: t(`${pathToTranslation}.approval_template.activate_on_success_dialog.title`),
    });
  };

  const openBackConfirmationDialog = () => {
    setDialog({
      actionBtnColor: 'primary',
      actionBtnLabel: t(`${pathToTranslation}.approval_template.back_confirmation_dialog.confirm_button_text`),
      cancelBtnLabel: t(`${pathToTranslation}.approval_template.back_confirmation_dialog.cancel_button_text`),
      message: t(`${pathToTranslation}.approval_template.back_confirmation_dialog.message`),
      onAction: handleCloseDrawer,
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(`${pathToTranslation}.approval_template.back_confirmation_dialog.title`),
    });
  };

  const handleRedirectToWorkflows = () => {
    if (dialog.open) {
      setDialog({ open: false });
    }
    toggleDrawer();
    history.push('/request-workflows?tab=workflows');
  };

  const renderButton = () => (
    <Box>
      <Button
        variant='text'
        startIcon={<WestRoundedIcon />}
        data-testid='back-button'
        onClick={() => (isDetails ? handleCloseDrawer() : openBackConfirmationDialog())}>
        {t(`${pathToTranslation}.approval_template.back_text`)}
      </Button>
    </Box>
  );

  const renderTitleArea = () => (
    <Box mb={2}>
      <Typography variant='h3' mb={1} data-testid='workflow-approval-title'>
        {t(`${pathToTranslation}.approval_template.title_section.title`, {
          title: entity?.name,
        })}
      </Typography>
      <Typography data-testid='workflow-approval-subtitle'>
        {t(`${pathToTranslation}.approval_template.title_section.subtitle_initiatives`)}
      </Typography>
      {isDetails && (
        <Alert
          description={t('request_workflows_view:tab_section.tabs.workflows.view_only.alert_description_initiatives')}
          isIconVisible
          severity='info'
          title={t('request_workflows_view:tab_section.tabs.workflows.view_only.alert_title')}
          sx={{ my: 3 }}
        />
      )}
    </Box>
  );

  return (
    <>
      {renderButton()}
      {entity && (
        <Box width='85%'>
          {renderTitleArea()}
          <TemplateForm
            availableStages={getAvailableStages(getTypeId)}
            users={sortedUsers}
            defaultValues={getNewTemplateDefaultValues()}
            onFormSubmit={(formValues) =>
              isEdit
                ? editWorkflow(getEditWorkflowPayload(formValues))
                : createWorkflow(getCreateWorkflowPayload(formValues))
            }
            isLoading={isCreatingWorkflow || isEditingWorkflow}
            isDetails={isDetails}
            isActive={isActive}
            typeId={getTypeId}
          />
        </Box>
      )}
      <DialogComponent {...dialog} />
    </>
  );
};
