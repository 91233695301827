import { Box, Button, Typography } from '@mui/material';
import ToolRequestDrawerIcon from 'assets/images/tool-request/drawer-icon.svg';
import { Icon } from 'asteroids';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '..';

type IProps = {
  handleCloseDrawer: () => void;
};

export const ToolRequestSection: FC<IProps> = ({ handleCloseDrawer }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ px: 6, py: 8 }} data-testid='left-section-desc'>
      <Button variant='text' color='primary' startIcon={<Icon>west</Icon>} onClick={handleCloseDrawer}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.back_text'
        )}
      </Button>

      <Box sx={{ mb: 4, mt: 3 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant='h2' data-testid='tool-request-title'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.left_drawer_title.add_tool_request_title'
            )}
          </Typography>
        </Box>

        <Typography variant='body1' className='text' data-testid='toolRequest-infoBodyText'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.body_text_section.add_new_solution_body_text'
          )}
        </Typography>
      </Box>

      <Box sx={{ mb: 4.5 }}>
        <Box sx={{ mb: 0.5 }}>
          <Typography variant='body'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.help_text_section.add_request_tool_text'
            )}
          </Typography>
        </Box>

        <Link
          href='https://support.sastrify.com/support/solutions/articles/101000384363--new-solutions-vs-subscriptions-the-difference'
          text={t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.left_section.footer_section.external_link_text'
          )}
          withIcon
        />
      </Box>

      <Box component='img' src={ToolRequestDrawerIcon} alt='Tool Request' width='337px' height='254px' />
    </Box>
  );
};
