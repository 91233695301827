import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChartData } from '../../../app/views/company-tools/company-tools.view';

type SpendDetail = {
  currentYearSpend: number;
  lastYearSpend: number;
  currency: string;
};

export interface ToolsDisplayData {
  statusChartData: ChartData[];
  spendDetail?: SpendDetail;
}

const initialState: ToolsDisplayData = {
  statusChartData: [],
};

const toolsDisplayDataSlice = createSlice({
  initialState,
  name: 'toolsDisplayData',
  reducers: {
    setSpendDetail(state, action: PayloadAction<SpendDetail>) {
      if (state.spendDetail !== action.payload) {
        state.spendDetail = action.payload;
      }
    },
    setStatusChartData(state, action: PayloadAction<ChartData[]>) {
      if (state.statusChartData !== action.payload) {
        state.statusChartData = action.payload;
      }
    },
  },
});

export const { setSpendDetail, setStatusChartData } = toolsDisplayDataSlice.actions;

export default toolsDisplayDataSlice.reducer;
