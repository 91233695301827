import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { trackAddonRequest } from 'shared/logic/event-tracking/upselling-prompt.events';
import { useCompany, useModalAndNotification, useUser } from 'src/app/hooks';

import { UpsellingPromptCard, UpsellingPromptView } from './components';
import { useUpsellingPrompt } from './hooks/use-upselling-prompt';
import { REQUESTED_ADDONS_KEY, UpsellingPromptProps, UpsellingPromptVariant } from './upselling-prompt.props';

export const UpsellingPrompt = ({
  description,
  feature,
  featureName,
  image,
  learnMoreLink,
  sx,
  variant = UpsellingPromptVariant.VIEW,
}: UpsellingPromptProps) => {
  const { t } = useTranslation();
  const { onShowAsteroidsModal, onShowNotification } = useModalAndNotification();

  const requestedAddons = JSON.parse(localStorage.getItem(REQUESTED_ADDONS_KEY) || '[]');
  const [isAddonRequested, setIsAddonRequested] = useState(requestedAddons.includes(feature));

  const [submitting, setSubmitting] = useState(false);

  const company = useCompany();
  const location = useLocation();
  const user = useUser();

  const showModal = () =>
    onShowAsteroidsModal({
      cancelButtonText: t('pricing_plans:upselling_prompt.modal.closeBtn'),
      children: (
        <div data-testid='modal-content'>{t('pricing_plans:upselling_prompt.modal.description', { featureName })}</div>
      ),
      maxWidth: 'xs',
      title: t('pricing_plans:upselling_prompt.modal.title'),
    });

  const { mutate } = useUpsellingPrompt(company?.id as string);

  const handleTrackingEvent = () => {
    trackAddonRequest({
      companyId: company?.id || '',
      customerName: company?.name || '',
      feature,
      page: location.pathname,
      userId: user?.id || '',
      userName: user?.name || '',
    });
  };

  const handleAddonRequest = () => {
    setSubmitting(true);
    mutate(
      { featureName: feature },
      {
        onError: () => onShowNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE}`, NotificationAlertType.Error),
        onSettled: () => setSubmitting(false),
        onSuccess: () => {
          requestedAddons.push(feature);
          localStorage.setItem(REQUESTED_ADDONS_KEY, JSON.stringify(requestedAddons));
          setIsAddonRequested(true);

          showModal();
          handleTrackingEvent();
        },
      }
    );
  };

  const childComponentProps = {
    description,
    featureName,
    handleAddonRequest,
    image,
    isAddonRequested,
    learnMoreLink,
    submitting,
    sx,
  };

  if (variant === UpsellingPromptVariant.CARD) {
    return <UpsellingPromptCard {...childComponentProps} />;
  }

  return <UpsellingPromptView {...childComponentProps} />;
};
