import { IntegrationDto } from '../dtos/integration.dto';

export type KomboIntegrationConnectionData = {
  end_user_email: string;
  end_user_organization_name: string;
  end_user_origin_id: string;
  integration_tool: KomboIntegratedTool;
  integration_category: KomboIntegrationCategory;
};

type KomboIntegrationLink = {
  link: string;
};

type KomboIntegrationResponseData = {
  tool: string;
  id: string;
  end_user_origin_id: string;
  end_user_organization_name: string;
  end_user_email: string;
};

export type KomboError = {
  message: string;
};

export type KomboApiResponse<T> = {
  status: 'success' | 'error';
  data: T;
  error?: KomboError;
};

export type KomboCreateIntegrationResponse = KomboApiResponse<KomboIntegrationLink>;

export type KomboIntegrationResponse = KomboApiResponse<KomboIntegrationResponseData>;

export type KomboDeleteIntegrationResponse = KomboApiResponse<object>;

export type KomboActivationResponse = {
  status: string;
  tool: string;
};

export enum KomboIntegratedTool {
  PERSONIO = 'personio',
  WORKDAY = 'workday',
  BAMBOOHR = 'bamboohr',
  HIBOB = 'hibob',
  SUCCESS_FACTORS = 'successfactors',
  DEEL = 'deel',
  FACTORIAL = 'factorial',
  REMOTE = 'remotecom',
  GOOGLE_WORKSPACE = 'googleworkspace',
  SAGE_HR = 'sagehr',
  ENTRA_ID = 'entraid',
  OKTA = 'okta',
  ZOHO_PEOPLE = 'zohopeople',
  DATEV_HR = 'datevhr',
}

export enum KomboIntegrationCategory {
  HRIS = 'HRIS',
}

export type KomboIntegratedToolDto = {
  integratedTool: KomboIntegratedTool;
};

export type KomboActivationDto = {
  integrationId: string;
  activationToken: string;
};

export type KomboDisconnectDto = {
  integrationId: string;
};

export type IntegrationDetails = Omit<IntegrationDto, 'id' | 'companyId' | 'status'>;
