import { ERROR_STATUS } from 'libs/constants/failed-invoice.status';
import {
  FailedInvoicesTotals,
  FixSpendDuplicate,
  FixSpendDuplicateResponse,
  InvoicesErrorDetails,
  PaginatedResponse,
  SpendDuplicateResponse,
  SpendDuplicateSubscriptionResponse,
  UnmatchedCompanySupplierCount,
} from 'libs/models';
import { SpendSourceName } from 'libs/util/invoiceHelper.util';
import { Invoice } from 'shared/models';
import { apiService, apiUrl } from 'shared/services';

export const fetchAllInvoices = async (query: string): Promise<PaginatedResponse<Invoice>> => {
  const response = await apiService.get(apiUrl.getAllInvoices(query));
  return response.data;
};

export const getUnmatchedSuppliersTotals = async (): Promise<UnmatchedCompanySupplierCount[] | undefined> => {
  const response = await apiService.get(apiUrl.getUnmatchedSuppliersTotals());
  return response.data;
};

export const getFailedInvoicesTotals = async (companyId: string): Promise<FailedInvoicesTotals> => {
  const response = await apiService.get(apiUrl.getFailedInvoicesTotals(companyId));
  return response.data;
};

export const getFailedInvoicesList = async (
  companyId: string,
  subscriptionIds?: string[]
): Promise<InvoicesErrorDetails[]> => {
  const response = await apiService.post(apiUrl.getFailedInvoicesList(companyId, ERROR_STATUS.FAILED), {
    subscriptionIds,
  });
  return response.data;
};

export const getSpendSourceOptions = async (): Promise<SpendSourceName[]> => {
  const response = await apiService.get(apiUrl.GetSpendSourceOptions);
  return response.data || [];
};

export const fetchDuplicates = async (): Promise<SpendDuplicateSubscriptionResponse[] | undefined> => {
  const response = await apiService.get(apiUrl.getSpendDuplicatesSubscriptionList());
  return response.data;
};

export const fixDuplicates = async (payload: FixSpendDuplicate[]): Promise<FixSpendDuplicateResponse | undefined> => {
  const response = await apiService.post(apiUrl.fixSpendDuplicates(), payload);
  return response.data;
};

export const fetchDuplicatesBySubscriptionId = async (
  subscriptionId: string
): Promise<SpendDuplicateResponse | undefined> => {
  const response = await apiService.get(apiUrl.getSpendDuplicatesBySubscriptionId(subscriptionId));
  return response.data;
};

export const fetchDuplicatesCount = async (): Promise<number> => {
  const response = await apiService.get(apiUrl.GetSpendDuplicatesCount);

  return response.data || 0;
};
