/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useCallback, useState } from 'react';

import { Icon } from '../icon/icon.component';
import { DatePickerProps } from './date-picker.component.props';

export const DatePicker: React.FC<DatePickerProps> = ({
  error,
  helperText,
  minDate,
  onClose,
  open,
  size = 'medium',
  value,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open || false);

  const getOpenPickerIcon = useCallback(
    (isOpen: boolean): any => (
      <Icon onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
    ),
    []
  );

  const getLeftIcon = useCallback((): any => <Icon>arrow_left</Icon>, []);

  const getRightIcon = useCallback((): any => <Icon>arrow_right</Icon>, []);

  const handleOnClose = useCallback((): void => {
    if (onClose) onClose();
    setIsOpen(false);
  }, [onClose]);

  const renderInput = (params: TextFieldProps) => (
    <TextField data-testid='datepicker-input' {...params} error={error} helperText={helperText} size={size} />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        value={value as any} // NOSONAR
        minDate={minDate as any} // NOSONAR
        open={open}
        onClose={handleOnClose}
        components={{
          LeftArrowIcon: () => getLeftIcon(),
          OpenPickerIcon: () => getOpenPickerIcon(isOpen),
          RightArrowIcon: () => getRightIcon(),
        }}
        renderInput={renderInput}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
