/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { Avatar, theme } from 'asteroids';
import { memo, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { fetchTopVendors } from 'shared/logic/explore.logic';
import { fetchVendors } from 'shared/logic/subscriptions.logic';
import { VendorType } from 'shared/models';

import { VendorSelectAsyncProps } from './vendor-select-async.component.props';

const VendorSelectAsyncUnmemorizedComponent: React.FC<VendorSelectAsyncProps> = ({
  disabled,
  error,
  excludeVendors,
  getValue,
  helperText,
  loadDefaultVendors,
  noOptionsText,
  numberOfDefaultVendors = 5,
  onBlur,
  placeholder,
  shouldHandleInputChange = true,
  size = 'medium',
  value,
}) => {
  const queryClient = useQueryClient();

  const [vendors, setVendors] = useState<VendorType[]>([]);
  const [defaultVendors, setDefaultVendors] = useState<VendorType[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<VendorType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>('');
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (loadDefaultVendors && defaultVendors.length === 0) {
      (async () => {
        setIsLoading(true);
        const vendors = await queryClient.fetchQuery('vendors', () =>
          fetchTopVendors({ pageParam: numberOfDefaultVendors })
        );
        const uniqueVendors = new Map();

        vendors.data.forEach((vendor: VendorType) => {
          uniqueVendors.set(vendor.name, vendor);
        });

        setDefaultVendors(Array.from(uniqueVendors.values()));
        setIsLoading(false);
      })();
    }
  }, [loadDefaultVendors, defaultVendors.length, queryClient, numberOfDefaultVendors]);

  useEffect(() => {
    if (!isFirstLoad) return;
    if (value && typeof value === 'string' && Boolean(value.trim())) {
      (async () => {
        const vendors = await queryClient.fetchQuery(`search-vendors-${value}`, () => fetchVendors(value));
        if (vendors.length > 0) {
          setSelectedVendor(vendors[0]);
        } else {
          setSelectedVendor({
            categoryId: '',
            categoryName: '',
            description: '',
            id: '',
            name: value,
            vendorLogoUrl: '',
          });
        }
      })();
      setIsFirstLoad(false);
    } else if (value && typeof value === 'object') {
      setSelectedVendor(value);
      setIsFirstLoad(false);
    }
  }, [value, queryClient, isFirstLoad]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText.length > 2) {
      (async () => {
        setIsLoading(true);
        const vendors = await queryClient.fetchQuery('search-vendors', () => fetchVendors(debouncedSearchText));

        const uniqueVendors = new Map();
        vendors
          .filter((vendor: VendorType) => !excludeVendors?.includes(vendor.id))
          .forEach((vendor: VendorType) => {
            uniqueVendors.set(vendor.name, vendor);
          });

        setVendors(Array.from(uniqueVendors.values()));
        setIsLoading(false);
      })();
    } else if (!debouncedSearchText) {
      const filteredDefaultVendors = defaultVendors.filter(
        (vendor: VendorType) => !excludeVendors?.includes(vendor.id)
      );
      setVendors(filteredDefaultVendors);
    }
  }, [debouncedSearchText, queryClient, defaultVendors, excludeVendors]);

  return (
    <Autocomplete
      id='vendors'
      options={vendors}
      getOptionLabel={(option: VendorType) => option.name}
      isOptionEqualToValue={(option: VendorType, value: VendorType) => option.name === value.name}
      filterOptions={(x) => x}
      filterSelectedOptions
      value={selectedVendor}
      loading={isLoading}
      clearOnBlur={false}
      noOptionsText={noOptionsText}
      onBlur={onBlur}
      onChange={(_, newValue: VendorType | null) => {
        setSelectedVendor(newValue);
        getValue?.(newValue);
        setIsFirstLoad(false);
      }}
      onInputChange={(_, newInputValue) => {
        setSearchText(newInputValue);
        if (!disabled && shouldHandleInputChange) getValue?.(newInputValue);

        setIsFirstLoad(false);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={placeholder || 'Search...'}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack direction='row' spacing={1}>
            <Avatar
              src={option.vendorLogoUrl || ''}
              imgProps={{ sx: { background: theme.palette.background.light, objectFit: 'contain' } }}
            />
            <Typography variant='body2'>{option.name}</Typography>
          </Stack>
        </li>
      )}
      disabled={disabled}
      size={size}
    />
  );
};

export const VendorSelectAsyncComponent = memo(VendorSelectAsyncUnmemorizedComponent, (prevProps, nextProps) => {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.error === nextProps.error &&
    prevProps.helperText === nextProps.helperText &&
    prevProps.loadDefaultVendors === nextProps.loadDefaultVendors &&
    prevProps.noOptionsText === nextProps.noOptionsText &&
    prevProps.numberOfDefaultVendors === nextProps.numberOfDefaultVendors &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.shouldHandleInputChange === nextProps.shouldHandleInputChange &&
    prevProps.size === nextProps.size &&
    prevProps.value === nextProps.value &&
    JSON.stringify(prevProps.excludeVendors) === JSON.stringify(nextProps.excludeVendors)
  );
});
