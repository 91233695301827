import { Box, Button, Typography } from '@mui/material';
import { Alert } from 'asteroids';
import { format, formatDistance, isBefore, sub } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChatComponent } from '../chat/chat.component';
import { ChatInputComponent } from '../chat/components/chat-input.component';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogComponentProps } from '../dialog/dialog.component.props';
import { ActivitiesComponentProps } from './activities.component.props';
import { ViewOlderRepliesButtonWrapper } from './activities.component.styles';

const formatActivityDate = (date = '') =>
  isBefore(new Date(date), sub(new Date(), { years: 1 }))
    ? format(new Date(date), 'MMMM dd, yyyy')
    : formatDistance(new Date(date), new Date(), { addSuffix: true });

const DEFAULT_NUMBER_OF_VISIBLE_LOGS = 5;

export const ActivitiesComponent: FC<ActivitiesComponentProps> = ({
  activitiesTitle,
  alertInfo = false,
  commentAvatarAlt,
  commentAvatarSrc,
  commentTestId,
  commentText,
  isSingleToolInitiative = false,
  isSubmitButtonDisabled = false,
  logItems,
  onCommentAction,
  onLogItemDelete,
  onLogItemSend,
  setCommentText,
}) => {
  const { t } = useTranslation();
  const [numberOfVisibleLogs, setNumberOfVisibleLogs] = useState(DEFAULT_NUMBER_OF_VISIBLE_LOGS);
  const [dialog, setDialog] = useState<DialogComponentProps>({ open: false });

  const totalNumberOfLogs = logItems?.length || 0;
  const showViewOlderRepliesButton = numberOfVisibleLogs < totalNumberOfLogs;
  const numberOfRepliesLeft = totalNumberOfLogs - numberOfVisibleLogs;

  const handleShowOlderLogs = () => {
    setNumberOfVisibleLogs(totalNumberOfLogs);
  };

  const openDeleteConfirmationDialog = (id: string) => {
    setDialog({
      actionBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.delete_confirmation.actions.confirm'
      ),
      cancelBtnLabel: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.delete_confirmation.actions.cancel'
      ),
      message: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.delete_confirmation.message'
      ),
      onAction: () => {
        onLogItemDelete?.(id);
        setDialog({ open: false });
      },
      onClose: () => setDialog({ open: false }),
      open: true,
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.delete_confirmation.title'
      ),
    });
  };

  const renderComment = () => (
    <ChatInputComponent
      avatarSrc={commentAvatarSrc || ''}
      avatarAlt={commentAvatarAlt || ''}
      value={commentText || ''}
      onChange={(value) => setCommentText?.(value)}
      onAction={onCommentAction}
      testId={commentTestId}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
    />
  );

  const renderViewOlderRepliesButton = () => {
    if (showViewOlderRepliesButton) {
      return (
        <ViewOlderRepliesButtonWrapper>
          <Button color='secondary' size='small' variant='text' onClick={handleShowOlderLogs}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.view_older_replies',
              { numberOfReplies: numberOfRepliesLeft }
            )}
          </Button>
        </ViewOlderRepliesButtonWrapper>
      );
    }
  };

  const renderLogs = () => (
    <>
      {(logItems || []).map(
        ({ avatarUrl, canModify, created, description, id, name, performedBy, prefixTestId }, index) => {
          if (index < numberOfVisibleLogs) {
            return (
              <ChatComponent
                testId={`${prefixTestId || 'activities-item'}-${index}`}
                key={id || (created as string)}
                title={name}
                description={description}
                avatarSrc={avatarUrl || ''}
                avatarAlt={performedBy || ''}
                createdAt={formatActivityDate(created as string)}
                canModify={canModify || false}
                onDelete={() => openDeleteConfirmationDialog(id as string)}
                onSend={(val) => onLogItemSend?.(val, id)}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
              />
            );
          }
          return null;
        }
      )}
      {renderViewOlderRepliesButton()}
    </>
  );

  const renderAlert = () => (
    <Box mb={4}>
      <Alert
        title={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.alert_info_title'
        )}
        description={
          isSingleToolInitiative
            ? t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.alert_info_single_tool_description'
              )
            : t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.activities.alert_info_description'
              )
        }
        isIconVisible
        severity='info'
      />
    </Box>
  );

  return (
    <>
      <Box>
        {activitiesTitle && (
          <Typography variant='h2' mb={alertInfo ? 3 : 4}>
            {activitiesTitle}
          </Typography>
        )}
        {alertInfo && renderAlert()}
        {renderComment()}
        {renderLogs()}
      </Box>
      <DialogComponent {...dialog} />
    </>
  );
};
