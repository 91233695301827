import { Box, Tooltip } from '@mui/material';
import { SubscriptionBadgeIcon } from 'components/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/theme';

import { BadgeComponentProps } from './badge.props';

export const BadgeComponent: FC<BadgeComponentProps> = ({
  classes,
  isCompleted,
  isSubscriptionDetailPage,
  progress,
  size,
  withText,
  withTooltip,
}) => {
  const { t } = useTranslation();

  const width = size ?? (isSubscriptionDetailPage ? 69 : 19);
  const height = size ?? (isSubscriptionDetailPage ? 69 : 19);
  const badgeIconWidth = progress === 100 ? width : '100%';
  const badgeIconHeight = progress === 100 ? height : '100%';

  const getTooltip = () => {
    if (!withTooltip) {
      return '';
    }
    if (isCompleted) {
      return t('common:subscription_covered_badge_component.tooltip_title_text');
    }
    return t('common:subscription_covered_badge_component.tooltip_title_progress_text', { progress });
  };

  return (
    <Tooltip title={getTooltip()} placement='top' arrow>
      <section data-testid='covered-badge' className={classes.root}>
        <SubscriptionBadgeIcon width={badgeIconWidth} height={badgeIconHeight} />

        {withText && (
          <Box
            fontSize='0.625rem'
            mt='0.5rem'
            fontWeight='500'
            color={colors.white}
            py='0.125rem'
            px='0.25rem'
            bgcolor={colors.secondary}
            borderRadius='0.25rem'>
            {t('common:subscription_covered_badge_component.sastrify_covered_text')}
          </Box>
        )}
      </section>
    </Tooltip>
  );
};
