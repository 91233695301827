/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Autocomplete, AutocompleteOption, DatePicker, Icon } from 'asteroids';
import { AxiosError } from 'axios';
import { WorkflowRequestWrapper } from 'components/drawer-workflow-request/drawer-workflow-request.component.styles';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { TypeaheadComponent } from 'components/typeahead';
import { VendorSelectInputComponent } from 'components/vendor-select-input';
import { DialogContext } from 'context/dialog.context';
import { format, sub } from 'date-fns';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { REQUEST_WORKFLOW_TYPE } from 'libs/enums/request.enum';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CSM_USER_EMAIL,
  Currency,
  CurrencyOptions,
  DocumentCategory,
  DRAWER_VARIANT,
  REQUEST_STATUS,
  SubscriptionStatus,
  UserRoles,
} from 'shared/common.definitions';
import { getErrorCodeMessage } from 'shared/helpers/errors.helper';
import { trackWorkflowRequestCreated } from 'shared/logic/event-tracking/workflow-requests.events';
import {
  checkSubscriptionStatus,
  checkToolRequestStatus,
  createNewRequest,
  editRequest,
  getRequestDetails,
  getRequestWorkflows,
  getVendorItem,
} from 'shared/logic/requests.logic';
import {
  getCompanyDocumentCategory,
  isAToolRequest,
  uploadSubscriptionDocument,
} from 'shared/logic/subscription-item.logic';
import { fetchVendors } from 'shared/logic/subscriptions.logic';
import {
  Company,
  CompanyDocument,
  RequestPayload,
  RequestWorkflowItem,
  Subscription,
  ToolOwnerFilter,
  User,
  VendorType,
} from 'shared/models';
import { RequestResponse } from 'shared/models/request-model';
import { RequestDetails } from 'shared/models/request-workflow.model';
import { useAppSelector } from 'shared/store/hooks';
import { clearBenchmarkRequestVendorName } from 'shared/store/views/benchmarks/benchmark-request.slice';
import { useDebounce } from 'src/app/hooks';
import { AppUrl } from 'src/constants/appurl';
import { useAddRequestDrawer } from 'views/requests/hooks';
import * as yup from 'yup';

import { DropzoneComponent } from '../../dropzone/dropzone.component';
import { NumberInput } from '../../number-input/number-input.component';
import { RequestToolAlert } from '../../request-tool-alert/request-tool-alert.component';
import { ToolRequestFormProps } from './tool-request-form.props';

export const DRAWER_FROM_URL = 'routeFrom';

type ToolOwner = { avatar?: string; id?: string; name: string; value?: string };

const paymentFrequencyItems: AutocompleteOption[] = [
  {
    id: 'monthly',
    label: 'Monthly',
  },
  {
    id: 'quarterly',
    label: 'Quarterly',
  },
  {
    id: 'yearly',
    label: 'Yearly',
  },
];

type FormValues = {
  cost: string;
  description: string;
  dueDate?: Date | string;
  numberOfLicenses: string;
  paymentFrequency: AutocompleteOption | null;
  toolName: string;
};

const defaultFormValues: FormValues = {
  cost: '',
  description: '',
  dueDate: undefined,
  numberOfLicenses: '',
  paymentFrequency: null,
  toolName: '',
};

const defaultToolOwner: ToolOwnerFilter = { avatar: '', id: '', name: '' };

const formValidationSchema = yup.object().shape({
  dueDate: yup.date().min(sub(new Date(), { days: 1 })),
  toolName: yup.string().required(),
});

export const ToolRequestForm: FC<ToolRequestFormProps> = ({ initialData, mode, showNotification, toggleDrawer }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { hash, pathname, search } = useLocation();
  const { push, replace } = useHistory();
  const { openDialog } = useContext(DialogContext);
  const { onAddRequestDrawer } = useAddRequestDrawer();
  const dispatch = useDispatch();

  const selectedBenchmarkVendorName = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.vendorName);

  const [selectedVendor, setSelectedVendor] = useState<VendorType>();
  const [vendorName, setVendorName] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>();
  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState(defaultToolOwner);
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [toolOwnerError, setToolOwnerError] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [showToolSelectionError, setShowToolSelectionError] = useState(false);
  const [existingRequestOrTool, setExistingRequestOrTool] = useState<
    Partial<Subscription> | RequestWorkflowItem | null
  >(null);
  const [isDocumentsUploadInProgress, setIsDocumentsUploadInProgress] = useState(false);
  const dropzoneComponentRef = useRef<any>(null);

  const requestId = hash.includes(DRAWER_VARIANT.EDIT_REQUEST)
    ? hash.split(DRAWER_VARIANT.EDIT_REQUEST).slice(-1)[0]
    : '';
  const toolId = hash.includes(DRAWER_VARIANT.NEW_REQUEST) ? hash.split(DRAWER_VARIANT.NEW_REQUEST).slice(-1)[0] : '';
  const isEditMode = mode === 'edit';
  const isNewRequestMode = mode === 'create';

  const company = queryClient.getQueryData<Company>('company');
  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');
  const tools = queryClient.getQueryData<Subscription[]>('subscriptions-and-new-solutions');
  const requests = queryClient.getQueryData<RequestWorkflowItem[]>('workflow-requests');

  const toolQuery = queryClient.getQueriesData('tools');
  const toolQueryParams = toolQuery?.[toolQuery.length - 1]?.[0];

  const isToolAlreadyRequestedOrInUse =
    existingRequestOrTool && (existingRequestOrTool as any).state !== SubscriptionStatus.rejected;

  const invalidateTools = () => {
    if (pathname === '/tool-store') {
      queryClient.invalidateQueries({ queryKey: toolQueryParams });
    }
  };

  const handleCloseDrawer = () => {
    replace(`${pathname}${search}`);
    toggleDrawer();
  };

  const onModifySuccess = async (data: RequestResponse) => {
    invalidateTools();
    handleCloseDrawer();
    queryClient.fetchQuery({ queryFn: getRequestWorkflows, queryKey: 'workflow-requests' });

    openDialog({
      confirmBtnText: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.button_initiative_text'
      ),
      content: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_text'
      ),
      icon: (
        <Icon color='success' sx={{ mr: 1 }}>
          check_circle_outline
        </Icon>
      ),
      onConfirm: () => {
        onAddRequestDrawer(`#${DRAWER_VARIANT.REQUEST_DETAILS}${data.id}`);
      },
      title: t(
        'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.success_dialog.initiative_title'
      ),
    });

    const requestDetails = await queryClient.fetchQuery('workflow-request-details', () => getRequestDetails(data.id));
    trackWorkflowRequestCreated({
      companyId: company?.id,
      companyName: company?.name,
      numberOfContributors: requestDetails?.contributors?.length || 0,
      requestType: 'newPurchase',
      toolName: selectedVendor?.name || vendorName || '',
      totalStages: requestDetails?.timeline.length || 0,
    });
  };

  const onModifyError = (err: AxiosError) => {
    const message =
      getErrorCodeMessage(err) ||
      t('common:modals.add_new_subscription_feature_modal.error_message', {
        value: 'New initiative',
      });

    showNotification?.(message, NotificationAlertType.Error);
  };

  const { isLoading: isCreateLoading, mutate: mutateCreate } = useMutation(
    (payload: RequestPayload) => createNewRequest(payload),
    {
      onError: onModifyError,
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { isLoading: isEditLoading, mutate: mutateEdit } = useMutation(
    (payload: RequestPayload) => editRequest(payload),
    {
      onError: onModifyError,
      onSettled: () => setIsDocumentsUploadInProgress(false),
      onSuccess: onModifySuccess,
    }
  );

  const { mutate: mutateVendors } = useMutation((debouncedValue: string) => fetchVendors(debouncedValue), {
    onSuccess: (data: VendorType[]) => {
      const requestDetails = queryClient.getQueryData<RequestDetails>(['workflow-request-details', requestId]);

      let vendor: VendorType | undefined;
      if (isNewRequestMode && selectedBenchmarkVendorName) {
        vendor = data.find(({ name }) => name === selectedBenchmarkVendorName);
        if (vendor) {
          handleToolSelection(vendor);
        }
      } else {
        vendor = data.find(({ name }) => name === requestDetails?.vendorName);
        if (vendor) {
          setSelectedVendor(vendor);
          setVendorName(vendor.name);
        }
      }
    },
  });

  const { mutateAsync: uploadDocumentAsync } = useMutation(uploadSubscriptionDocument, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      setIsDocumentsUploadInProgress(false);
    },
  });

  const {
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  const getPaymentFrequencyItem = (id: string) => paymentFrequencyItems.find((item) => item.id === id);

  useQuery(['vendor-item', toolId], () => getVendorItem(toolId), {
    enabled: Boolean(toolId),
    onSuccess: (vendor) => {
      if (vendor) {
        handleToolSelection(vendor as any);
      }
    },
    refetchOnWindowFocus: false,
  });

  useDebounce(setDebouncedValue, vendorName, 500);

  useEffect(() => {
    if (isEditMode && initialData) {
      const setFormValues = (data: RequestDetails) => {
        setValue('cost', String((data?.licenseCents || 0) / 100));
        setValue('description', String(data.description));
        setValue('dueDate', new Date(data.dueDate || ''));
        setValue('numberOfLicenses', String(data?.licenseNumber || 0));
        if (data.licensePaymentFrequency) {
          setValue('paymentFrequency', getPaymentFrequencyItem(data.licensePaymentFrequency || '') || null);
        }

        setValue('toolName', String(data.vendorName));
      };

      const toolOwner =
        usersAndToolOwnersList
          ?.filter(({ name }) => name === initialData.assigneeFullName)
          .map(({ avatar, id, name }) => ({ avatar: avatar || '', id: id || '', name: name || '' }))[0] ||
        defaultToolOwner;

      setToolOwner(toolOwner);
      setToolOwnerName(toolOwner.name);
      setFormValues(initialData);

      mutateVendors(initialData.vendorName);
    }
    if (isNewRequestMode && selectedBenchmarkVendorName) {
      mutateVendors(selectedBenchmarkVendorName);
    }
  }, [
    selectedBenchmarkVendorName,
    isEditMode,
    initialData,
    mutateVendors,
    usersAndToolOwnersList,
    setValue,
    isNewRequestMode,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries<Subscription[]>(['subscriptions-and-new-solutions']);
  }, [queryClient]);

  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList
        .filter((user: User) => user.role !== UserRoles.Viewer)
        .filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && user.id)
        .map((user: User) => ({
          avatar: user?.avatar,
          name: user.name,
          value: user.id || user.name?.replaceAll(' ', '-'),
        }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  const handleToolSelection = (item: VendorType) => {
    if (item) {
      setValue('toolName', item.name);
      setSelectedVendor(item);
      setShowToolSelectionError(false);
    } else {
      setSelectedVendor(undefined);
    }

    const subscription = checkSubscriptionStatus(item, tools);
    let existingRequestOrTool: Partial<Subscription> | RequestWorkflowItem | null = subscription || null;
    if (!existingRequestOrTool) {
      const tool = checkToolRequestStatus(item, requests);
      if (tool) {
        existingRequestOrTool = tool;

        if (tool.requestStateId === REQUEST_STATUS.REJECTED) {
          (existingRequestOrTool as any).state = SubscriptionStatus.rejected;
        } else if (tool.requestStateId === REQUEST_STATUS.ACTIVE || tool.requestStateId === REQUEST_STATUS.APPROVED) {
          (existingRequestOrTool as any).state = SubscriptionStatus.requested;
        } else {
          (existingRequestOrTool as any).state = SubscriptionStatus.active;
        }
      }
    }
    setExistingRequestOrTool(existingRequestOrTool);
  };

  const handleOnViewRequest = useCallback(() => {
    let url = '';
    if ((existingRequestOrTool as Subscription)?.companyId) {
      const isToolRequest = isAToolRequest((existingRequestOrTool as Subscription)?.state as number);

      url = AppUrl.getToolUrl(existingRequestOrTool as Partial<Subscription>, false, isToolRequest, false);
    } else {
      url = `/requests#${DRAWER_VARIANT.REQUEST_DETAILS}${(existingRequestOrTool as RequestWorkflowItem)?.requestId}`;
    }

    window.open(url, '_blank', 'noopener noreferrer');
  }, [existingRequestOrTool]);

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
      setToolOwnerError(false);
      return;
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner(defaultToolOwner);
      setToolOwnerName('');
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
    setToolOwnerError(false);
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleDocumentsUpload = async (): Promise<CompanyDocument[]> => {
    setIsDocumentsUploadInProgress(true);

    const uploads: unknown[] = [];
    const files = dropzoneComponentRef.current.getFiles();
    const category = getCompanyDocumentCategory(DocumentCategory.other_documents);
    const companyId = String(company?.id);

    files.forEach((file: File) => {
      const payload = {
        category,
        companyId,
        file,
        isCompanyDocument: true,
      };

      uploads.push(uploadDocumentAsync(payload));
    });

    return (await Promise.all(uploads)) as CompanyDocument[];
  };

  const onModifyRequest = async () => {
    trigger();

    if (!isValid) {
      if (!toolOwnerName) {
        setToolOwnerError(true);
      }
      return;
    }
    if (!toolOwnerName) {
      setToolOwnerError(true);
      return;
    }

    const formValues = getValues();
    const value = formValues.toolName.trim();

    if (!value) {
      return setShowToolSelectionError(true);
    }

    const vendorId = selectedVendor && selectedVendor.name === getValues().toolName ? selectedVendor.id : undefined;
    const documents = !isEditMode ? await handleDocumentsUpload() : [];

    const modifyRequestPayload: RequestPayload = {
      ...(isEditMode && { id: Number(requestId || 0) || undefined }),
      description: formValues.description,
      documents,
      dueDate: formValues.dueDate
        ? format(new Date(formValues.dueDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd'),
      licenseCurrency: company?.currency || '',
      ...(formValues.cost && { licenseCents: Number(formValues.cost) * 100 }),
      ...(formValues.numberOfLicenses && { licenseNumber: Number(formValues.numberOfLicenses) }),
      ...(formValues.paymentFrequency && { licensePaymentFrequency: formValues.paymentFrequency.id }),
      requestWorkflowTypeId: REQUEST_WORKFLOW_TYPE.APPROVAL,
      steps: [
        {
          stepOwnerId: Number(toolOwner.value) || Number(toolOwner.id),
        },
      ],
      tools: [
        ...(vendorId
          ? [
              {
                vendorId: Number(vendorId),
              },
            ]
          : [
              {
                toolName: value,
              },
            ]),
      ],
    };

    return isEditMode ? mutateEdit(modifyRequestPayload) : mutateCreate(modifyRequestPayload);
  };

  const renderToolField = () => (
    <Box display='flex' flexDirection='column' data-testid='tool-name'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.input_label'
        )}
        *
      </Typography>
      <Controller
        name='toolName'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <VendorSelectInputComponent
              isMuiInput
              isWorkflowRequest
              handleItemSelected={(item) => {
                dispatch(clearBenchmarkRequestVendorName());
                handleToolSelection(item);
              }}
              setDebouncedValue={setDebouncedValue}
              vendorName={vendorName}
              debouncedValue={debouncedValue}
              onChange={onChange}
              setVendorName={setVendorName}
              placeholder={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.placeholder_text'
              )}
              value={value}
              disabled={isEditMode || Boolean(toolId)}
              error={Boolean(errors.toolName) || showToolSelectionError}
              helperText={
                errors.toolName || showToolSelectionError
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:tool_name_section:error_message'
                    )
                  : ''
              }
            />
          );
        }}
      />
      {existingRequestOrTool ? (
        <RequestToolAlert
          toolName={existingRequestOrTool?.vendorName as string}
          onViewRequest={handleOnViewRequest}
          subscriptionStatus={(existingRequestOrTool as Subscription)?.state as SubscriptionStatus}
        />
      ) : null}
    </Box>
  );

  const renderLicenseFields = () => (
    <Box sx={{ mt: '1.5rem' }}>
      <Grid container>
        <Grid item xs={4} data-testid='value' sx={{ display: 'flex', flexDirection: 'column', paddingRight: '1rem' }}>
          <Typography variant='h3'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.value_section.input_label'
            )}
          </Typography>
          <Controller
            name='cost'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <NumberInput
                  name='cost'
                  label={t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.value_section.placeholder_text'
                  )}
                  decimalScale={2}
                  decimalSeparator='.'
                  fixedDecimalScale
                  thousandSeparator
                  prefix={company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY}
                  value={value || ''}
                  onValueChange={(value) => onChange(value.floatValue)}
                  onClear={() => onChange('')}
                  error={Boolean(errors.cost)}
                  helperText={
                    errors.cost
                      ? t(
                          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:value_section:error_message'
                        )
                      : ''
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          data-testid='number-of-licenses'
          sx={{ display: 'flex', flexDirection: 'column', paddingRight: '1rem' }}>
          <Typography variant='h3'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.number_of_licenses_section.input_label'
            )}
          </Typography>
          <Controller
            name='numberOfLicenses'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <NumberInput
                  name='numberOfLicenses'
                  thousandSeparator
                  value={value || ''}
                  onValueChange={(value) => onChange(value.floatValue)}
                  onClear={() => onChange('')}
                  label={t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.number_of_licenses_section.placeholder_text'
                  )}
                  error={Boolean(errors.numberOfLicenses)}
                  helperText={
                    errors.numberOfLicenses
                      ? t(
                          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:number_of_licenses_section:error_message'
                        )
                      : ''
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4} data-testid='payment-frequency' sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h3'>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.payment_frequency_section.input_label'
            )}
          </Typography>
          <Controller
            name='paymentFrequency'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Autocomplete
                  id='paymentFrequency'
                  data-testid='payment-frequency-select'
                  label={t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.payment_frequency_section.placeholder_text'
                  )}
                  value={value}
                  size='medium'
                  options={paymentFrequencyItems}
                  onChange={(_, value) => onChange(value)}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const renderAssigneeField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='assignee'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.input_label'
        )}
        *
      </Typography>
      <TypeaheadComponent
        data={toolOwners}
        onItemSelected={onToolOwnerSelected}
        onBlurToolOwnerFilter={onToolOwnerSelected}
        value={toolOwnerName}
        fields={['name', 'email']}
        isToolOwnerFilter
        onTextChanged={onChangeToolOwner}
        placeholder={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.placeholder_initiative_text'
        )}
        isSubscriptionInDiscovery
        renderCustomInput={({
          autoFocus,
          disabled,
          inputTextRef,
          onChange,
          onClick,
          onFocus,
          onKeyDown,
          onKeyUp,
          value,
        }) => (
          <TextField
            id='assignee'
            inputRef={inputTextRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onFocus={(ev) => {
              onFocus?.(ev);
              setFocused(true);
            }}
            onBlur={() => setFocused(false)}
            onClick={onClick}
            disabled={disabled}
            value={value || ''}
            label={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.placeholder_initiative_text'
            )}
            autoFocus={autoFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                  {!isFocused ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>}
                </InputAdornment>
              ),
            }}
            error={toolOwnerError}
            helperText={
              toolOwnerError
                ? t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.assignee_section.error_message'
                  )
                : ''
            }
            fullWidth
          />
        )}
      />
    </Box>
  );

  const renderDateField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='due-date'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.input_label'
        )}
      </Typography>
      <Controller
        name='dueDate'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <DatePicker
              value={value as any}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              minDate={new Date() as any}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.placeholder_initiative_text'
              )}
              error={Boolean(errors.dueDate)}
              helperText={
                errors.dueDate
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.due_date_section.error_message'
                    )
                  : ''
              }
              inputFormat='dd/MM/yyyy'
            />
          );
        }}
      />
    </Box>
  );

  const renderDescField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='description'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.input_label'
        )}
      </Typography>
      <Controller
        name='description'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              id='description'
              data-testid='description-input'
              onChange={onChange}
              value={value}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.placeholder_initiative_text'
              )}
              multiline
              rows={4}
              error={!!errors.description}
              helperText={
                errors.description
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:description_section:error_message'
                    )
                  : ''
              }
            />
          );
        }}
      />
    </Box>
  );

  const renderDocumentsField = () => (
    <Box display='flex' flexDirection='column' sx={{ mt: '1.5rem' }} data-testid='documents'>
      <Typography variant='h3'>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.documents_section.input_label'
        )}
      </Typography>
      <DropzoneComponent dropzoneRef={dropzoneComponentRef} />
    </Box>
  );

  const renderButtons = () => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: '3rem' }}>
      <Box sx={{ mr: '1rem' }}>
        <Button
          variant='text'
          color='secondary'
          onClick={() => {
            if (isEditMode) {
              push(`${pathname}${search}#${DRAWER_VARIANT.REQUEST_DETAILS}${requestId}`);
            } else {
              handleCloseDrawer();
            }
          }}
          data-testid='cancel-button'>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.action_buttons.cancel_button_text'
          )}
        </Button>
      </Box>
      <Button
        variant='contained'
        role='search'
        onClick={onModifyRequest}
        data-testid={isEditMode ? 'update-request-button' : 'send-request-button'}
        disabled={
          Boolean(isToolAlreadyRequestedOrInUse) || isCreateLoading || isEditLoading || isDocumentsUploadInProgress
        }>
        {isCreateLoading || isEditLoading || isDocumentsUploadInProgress ? (
          <CircularProgress color='inherit' size={20} />
        ) : (
          t(
            `subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.action_buttons.${
              isEditMode ? 'update_initiative_button_text' : 'submit_initiative_button_text'
            }`
          )
        )}
      </Button>
    </Box>
  );

  return (
    <WorkflowRequestWrapper>
      <section className='request-fields'>
        {renderToolField()}
        {renderLicenseFields()}
        {renderAssigneeField()}
        {renderDescField()}
        {renderDateField()}
        {!isEditMode && renderDocumentsField()}
      </section>
      {(isEditMode || isNewRequestMode) && renderButtons()}
    </WorkflowRequestWrapper>
  );
};
