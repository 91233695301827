/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@mui/material';
import { Autocomplete, InputField } from 'asteroids';
import { DropzoneComponent } from 'components/drawer-workflow-request/components/dropzone/dropzone.component';
import { NumberInput } from 'components/drawer-workflow-request/components/number-input/number-input.component';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FlexColumn } from '../../contact-sastrify-form.component.styles';
import { billingCycles, commitmentLengths, pathToTranslation } from '../../contact-sastrify-form.constants';
import { RequestBenchmarkFormProps } from './request-benchmark-form.component.props';
import { formValidationSchema } from './request-benchmark-form.validation-schema';

export const RequestBenchmarkFormComponent: React.FC<RequestBenchmarkFormProps> = ({ formRef }) => {
  const { t } = useTranslation();
  const dropzoneRef = useRef<any>(null);
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      billingCycle: '',
      commitmentLength: '',
      expectedOutcome: '',
      licenseType: '',
      numberOfLicenses: '',
      otherInformation: '',
    },
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  formRef.current = {
    getValues: () => ({
      ...getValues(),
      documents: dropzoneRef.current?.getFiles(),
    }),
    trigger,
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.expected_outcome.label`)}
        </Typography>
        <Controller
          name='expectedOutcome'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='expectedOutcome'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.expected_outcome.placeholder`)}
                multiline
                rows={2}
                error={!!errors.expectedOutcome}
                helperText={
                  errors.expectedOutcome
                    ? t(`${pathToTranslation}.errors.required_field`)
                    : t(`${pathToTranslation}.fields.expected_outcome.helper_text`)
                }
                fullWidth
              />
            );
          }}
        />
      </Grid>
      <FlexColumn item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.license_type.label`)}
        </Typography>
        <Controller
          name='licenseType'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <InputField
                id='benchmark-license-type'
                value={value}
                onChange={(value: any) => onChange(value)}
                onClearValue={() => onChange('')}
                label={t(`${pathToTranslation}.fields.license_type.placeholder`)}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.number_of_licenses.label`)}
        </Typography>
        <Controller
          name='numberOfLicenses'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <NumberInput
                name='numberOfLicenses'
                thousandSeparator
                value={value || ''}
                onValueChange={(value) => onChange(value.floatValue)}
                onClear={() => onChange('')}
                label={t(`${pathToTranslation}.fields.number_of_licenses.placeholder`)}
                helperText={errors.numberOfLicenses ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.numberOfLicenses)}
              />
            );
          }}
        />
      </FlexColumn>
      <Grid item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.commitment_length.label`)}
        </Typography>
        <Controller
          name='commitmentLength'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='commitmentLength'
                label={t(`${pathToTranslation}.fields.commitment_length.placeholder`)}
                value={value}
                options={commitmentLengths}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                onInputChange={(_, value: any) => {
                  onChange(value);
                }}
                helperText={errors.commitmentLength ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.commitmentLength)}
                creatable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.billing_cycle.label`)}
        </Typography>
        <Controller
          name='billingCycle'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='billingCycle'
                label={t(`${pathToTranslation}.fields.billing_cycle.placeholder`)}
                value={value}
                options={billingCycles}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                onInputChange={(_, value: any) => {
                  onChange(value);
                }}
                helperText={errors.billingCycle ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.billingCycle)}
                creatable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} data-testid='vendor-proposal'>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.vendor_proposal.label`)}
        </Typography>
        <DropzoneComponent dropzoneRef={dropzoneRef} />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.other_information.label`)}
        </Typography>
        <Controller
          name='otherInformation'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='otherInformation'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.other_information.placeholder`)}
                multiline
                rows={3}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </>
  );
};
