import useSessionStorage from 'hooks/useSessionStorage';
import { useCallback, useEffect, useRef } from 'react';
import { AuditLogFieldNames } from 'shared/common.definitions';
import { trackCommentThreadToggled } from 'shared/logic/event-tracking/comment-replies.events';

export function useThreadState(commentId: string) {
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const hasScrolledIntoContent = useRef<boolean>(false);

  const queryString = new URLSearchParams(window.location.search);
  const queryStringCommentId = queryString.get('commentId');
  const queryStringReplyId = queryString.get('replyId');

  const shouldThreadBeExpanded = commentId === queryStringCommentId;

  const [isThreadExpanded, setThreadExpanded] = useSessionStorage<boolean>(`isThreadExpanded:${commentId}`, false);

  useEffect(() => {
    if (shouldThreadBeExpanded) {
      setThreadExpanded(true);
    }
  }, [shouldThreadBeExpanded, setThreadExpanded]);

  const handleToggleThreadCollapsed = () => {
    setThreadExpanded(!isThreadExpanded);

    trackCommentThreadToggled(!isThreadExpanded);
  };

  const handleScrollThreadIntoViewport = useCallback(() => {
    window.requestAnimationFrame(() => {
      // Scrolls to specific reply
      if (queryStringCommentId && queryStringReplyId) {
        // We are not storing all refs for replies, as it would take too much memory. We're checking the DOM directly.
        const commentRef: HTMLElement | null = document.querySelector(
          `#history-${AuditLogFieldNames.COMMENT}-${queryStringCommentId}`
        );
        if (!commentRef) {
          return;
        }

        const replyRef: HTMLElement | null = document.querySelector(
          `#comment-${queryStringCommentId}-${queryStringReplyId}`
        );

        if (replyRef && !hasScrolledIntoContent.current) {
          // We have received a reply as parameter
          replyRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });

          hasScrolledIntoContent.current = true;

          // Make comment blink
          const target: HTMLElement | null = replyRef.querySelector('.subscription-comment__content');
          if (target) {
            target.classList.add('blink');
            setTimeout(() => target.classList.remove('blink'), 1000);
          }

          return;
        }
      }

      // Scrolls to the reply editor
      if (!queryStringCommentId && !queryStringReplyId && !hasScrolledIntoContent.current) {
        editorContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        hasScrolledIntoContent.current = true;
      }
    });
  }, [editorContainerRef, queryStringReplyId, queryStringCommentId]);

  return { editorContainerRef, handleScrollThreadIntoViewport, handleToggleThreadCollapsed, isThreadExpanded };
}
