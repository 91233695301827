import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { FC } from 'react';

import { InvoiceTableSkeleton } from './invoice-table.skeleton';

export const SpendTabSkeleton: FC = () => {
  return (
    <Box px={6}>
      <Skeleton variant='rectangular' animation='wave' height={50} />
      <Box mt={3}>
        <Skeleton variant='rectangular' height={55} animation='wave' />
      </Box>
      <Box display='flex' px={6} mt={14} gap={6} alignItems='flex-end' justifyContent='center'>
        <Skeleton variant='rectangular' height={220} width={50} />
        <Skeleton variant='rectangular' height={150} width={50} />
        <Skeleton variant='rectangular' height={90} width={50} />
        <Skeleton variant='rectangular' height={80} width={50} />
        <Skeleton variant='rectangular' height={200} width={50} />
        <Skeleton variant='rectangular' height={55} width={50} />
        <Skeleton variant='rectangular' height={80} width={50} />
        <Skeleton variant='rectangular' height={40} width={50} />
      </Box>
      <InvoiceTableSkeleton />
    </Box>
  );
};
