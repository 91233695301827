import { CreateSliceOptions, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { type MRT_RowData, type MRT_TableState } from 'material-react-table';

export const recordsPerPage = 50;

export interface MaterialUITableInterface {
  columnFilters?: MRT_TableState<MRT_RowData>['columnFilters'];
  columnOrder?: MRT_TableState<MRT_RowData>['columnOrder'];
  columnPinning?: MRT_TableState<MRT_RowData>['columnPinning'];
  columnVisibility?: MRT_TableState<MRT_RowData>['columnVisibility'];
  pagination?: MRT_TableState<MRT_RowData>['pagination'];
  rowSelection?: MRT_TableState<MRT_RowData>['rowSelection'];
  sorting?: MRT_TableState<MRT_RowData>['sorting'];
}

export const initialState: MaterialUITableInterface = {
  columnFilters: undefined,
  columnOrder: undefined,
  columnPinning: undefined,
  columnVisibility: undefined,
  pagination: { pageIndex: 0, pageSize: recordsPerPage },
  rowSelection: undefined,
  sorting: undefined,
};

// Slice config factory
export const mrtTableSliceConfigFactory = (parentComponent: string) =>
  ({
    initialState: { ...initialState },
    name: `${parentComponent}Table`,
    reducers: {
      reset: (_: MaterialUITableInterface, action: PayloadAction<Partial<MaterialUITableInterface>>) => ({
        ...initialState,
        ...(action?.payload || {}),
      }),
      setColumnFilters(
        state: MaterialUITableInterface,
        action: PayloadAction<MRT_TableState<MRT_RowData>['columnFilters']>
      ) {
        state.columnFilters = action.payload;
      },
      setColumnOrder(
        state: MaterialUITableInterface,
        action: PayloadAction<MRT_TableState<MRT_RowData>['columnOrder']>
      ) {
        state.columnOrder = action.payload;
      },
      setColumnPinning(
        state: MaterialUITableInterface,
        action: PayloadAction<MRT_TableState<MRT_RowData>['columnPinning']>
      ) {
        state.columnPinning = action.payload;
      },
      setColumnVisibility(
        state: MaterialUITableInterface,
        action: PayloadAction<MRT_TableState<MRT_RowData>['columnVisibility']>
      ) {
        state.columnVisibility = action.payload;
      },
      setPagination(state: MaterialUITableInterface, action: PayloadAction<MRT_TableState<MRT_RowData>['pagination']>) {
        state.pagination = action.payload;
      },
      setRowSelection(
        state: MaterialUITableInterface,
        action: PayloadAction<MRT_TableState<MRT_RowData>['rowSelection']>
      ) {
        state.rowSelection = action.payload;
      },
      setSorting(state: MaterialUITableInterface, action: PayloadAction<MRT_TableState<MRT_RowData>['sorting']>) {
        state.sorting = action.payload;
      },
    },
  } as CreateSliceOptions<MaterialUITableInterface, SliceCaseReducers<MaterialUITableInterface>, string>);
