import {
  Autocomplete as MuiAutocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  PopperProps,
  TextField,
  Typography,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

import { AutocompleteOption, AutocompleteProps } from './autocomplete.props';
import { PopperComponent, StyledListItem } from './autocomplete.styles';

const filter = createFilterOptions<AutocompleteOption>();

export const Autocomplete: React.FC<AutocompleteProps> = ({
  checkboxes = false,
  creatable,
  error,
  helperText,
  isOptionEqualToValue,
  label,
  loading,
  loadingText,
  size,
  suffix,
  ...rest
}) => {
  const { t } = useTranslation();
  let suffixElement = suffix;

  if (loading)
    suffixElement = <CircularProgress color='inherit' size={20} data-testid='autocomplete-loading-spinner' />;

  loadingText = loadingText || t('asteroids:autocomplete.loadingText');

  const renderOption = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any,
    option: AutocompleteOption,
    { selected }: { selected: boolean }
  ) => {
    const {
      helperText,
      helperTextColor = 'text.secondary',
      helperTextSx,
      helperTextVariant,
      key,
      label,
      prefix,
      prepend,
      sublabel,
      sublabelColor = 'text.secondary',
      sublabelSx,
      sublabelVariant,
      ...rest
    } = option;

    rest.variant = rest.variant || 'subtitle';

    return (
      <StyledListItem {...props} key={key || props.key}>
        {loading ? (
          <Typography key='loading' variant='subtitle'>
            {loadingText}
          </Typography>
        ) : (
          <li style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div key='label' style={{ alignItems: 'center', display: 'flex' }}>
              {prefix && (
                <span key='prefix' style={{ display: 'flex' }}>
                  {prefix}
                </span>
              )}
              {prepend && (
                <span key='prepend' style={{ display: 'flex' }}>
                  {prepend}
                </span>
              )}
              <Box key='label' display='flex' flexDirection='column' sx={{ flexGrow: 1, marginBottom: 0 }}>
                {sublabel ? (
                  <>
                    <Typography key='text' {...rest}>
                      {label}
                    </Typography>
                    <Typography key='sublabel' color={sublabelColor} variant={sublabelVariant} sx={sublabelSx}>
                      {sublabel}
                    </Typography>
                  </>
                ) : (
                  <Typography key='text' {...rest}>
                    {label}
                  </Typography>
                )}
              </Box>
              {checkboxes && <Checkbox key='checkbox' checked={selected} />}
            </div>
            {helperText && (
              <div key='helper-text'>
                <Typography key='helperText' color={helperTextColor} variant={helperTextVariant} sx={helperTextSx}>
                  {helperText}
                </Typography>
              </div>
            )}
          </li>
        )}
      </StyledListItem>
    );
  };

  const renderPopper = (props: PopperProps, helperText: AutocompleteProps['helperText']) => (
    <PopperComponent {...props} hasHelperText={Boolean(helperText)} />
  );

  return (
    <MuiAutocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || ''}
          helperText={helperText}
          error={error}
          autoComplete='new-password'
          sx={{
            ...(size === 'small'
              ? { '.MuiFormLabel-root': { lineHeight: '28px' }, '.MuiInputBase-input': { height: '28px' } }
              : {}),
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <Box key='suffix' sx={{ display: 'flex' }}>
                  {suffixElement || null}
                </Box>
                <Box key='end-adornment'>{params.InputProps.endAdornment}</Box>
              </>
            ),
          }}
        />
      )}
      PopperComponent={(props: PopperProps) => renderPopper(props, helperText)}
      renderOption={renderOption}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return String(option?.inputValue ?? (option?.label || ''));
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (creatable) {
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`,
            });
          }
        }

        return filtered;
      }}
      isOptionEqualToValue={
        isOptionEqualToValue || ((option, value) => (value ? option?.label === value?.label : !option?.label))
      }
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option.label} avatar={option.tagAvatar} size={size} />
        ));
      }}
      size={size}
      loading={loading}
      {...rest}
    />
  );
};

export default Autocomplete;
