import { Alert, AlertTitle, Button, styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionStatus } from 'shared/common.definitions';

export type RequestToolAlertProps = {
  subscriptionStatus: SubscriptionStatus;
  toolName: string;
  onViewRequest: () => void;
};

const StyledAlert = styled(Alert)(({ theme }) => ({
  '.MuiAlert-action .MuiButton-root': {
    '&:hover': {
      backgroundColor: theme.palette.warning.highlight,
    },
    color: theme.palette.warning.dark,
  },
  '.MuiAlert-message, .MuiAlert-icon': {
    color: theme.palette.warning.dark,
  },
  backgroundColor: theme.palette.tertiary.light,
}));

export const RequestToolAlert: FC<RequestToolAlertProps> = ({ onViewRequest, subscriptionStatus, toolName }) => {
  const { t } = useTranslation();
  const pathToTranslation =
    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section';
  const isRequested =
    subscriptionStatus === SubscriptionStatus.requested ||
    subscriptionStatus === SubscriptionStatus.in_trial ||
    subscriptionStatus === SubscriptionStatus.under_consideration;
  const isRejected = subscriptionStatus === SubscriptionStatus.rejected;

  const getContent = () => {
    if (isRequested) {
      return {
        body: t(
          `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.requested_error_description`
        ),
        title: t(
          `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.requested_error_title`,
          {
            toolName,
          }
        ),
        viewButtonText: t(
          `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.alert.view_button_text`
        ),
      };
    }
    if (isRejected) {
      return {
        body: t(
          `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.rejected_error_description`
        ),
        title: t(`${pathToTranslation}.right_section.workflow_request_section.tool_name_section.rejected_error_title`, {
          toolName,
        }),
        viewButtonText: t(
          `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.alert.view_rejected_button_text`
        ),
      };
    }

    return {
      body: t(
        `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.already_in_use_error_description`,
        { toolName }
      ),
      title: t(
        `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.already_in_use_error_title`,
        {
          toolName,
        }
      ),
      viewButtonText: t(
        `${pathToTranslation}.right_section.workflow_request_section.tool_name_section.alert.view_button_text`
      ),
    };
  };

  return (
    <StyledAlert
      severity='warning'
      action={
        <Button variant='text' color='secondary' size='small' onClick={onViewRequest}>
          {getContent().viewButtonText}
        </Button>
      }
      sx={{ mt: 1 }}>
      <AlertTitle>{getContent().title}</AlertTitle>
      {getContent().body}
    </StyledAlert>
  );
};
