import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { Box, TextField, Typography } from '@mui/material';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { TodoStates } from 'shared/common.definitions';
import { getSubscriptionName, updateSubscriptionTodo } from 'shared/logic/subscription-item.logic';
import { sendTaskCompletedEvent } from 'shared/logic/track-events.logic';
import { Company, Subscription } from 'shared/models';
import { ModalActionTypes, updateFooterState } from 'shared/store/modal';

import { UploadSubscriptionDocumentsFeature } from '..';
import { MarkTaskAsDoneFormProps } from '.';

export const MarkTaskAsDoneForm = forwardRef((props: MarkTaskAsDoneFormProps, ref) => {
  const { closeModal, showNotification, subscription, task } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { getValues, register } = useForm();

  const uploadDocumentRef = useRef<{ onSubmit: (id?: string, todoId?: string) => Promise<void> }>();

  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

  const subscriptionId = String(subscription?.id);
  const todoId = String(task?.id);
  const company = queryClient.getQueryData<Company>('company');

  useEffect(() => {
    dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    return () => {
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    };
  }, [dispatch]);

  const { mutate } = useMutation(updateSubscriptionTodo, {
    onError: (error) => {
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      dispatch({ type: ModalActionTypes.RESET_FOOTER_STATE });
    },
    onSuccess: async () => {
      if (isFileSelected) {
        await uploadDocumentRef?.current?.onSubmit(subscriptionId, todoId);
      }

      showNotification?.(
        t('common:modals.mark_task_as_done_feature_modal.success_message'),
        NotificationAlertType.Success
      );
      queryClient.invalidateQueries('subscriptionTodos');
      queryClient.invalidateQueries('subscriptions-todos');
      queryClient.invalidateQueries(['subscription', subscriptionId]);

      sendTaskCompletedEvent({ subscriptionName: getSubscriptionName(subscription as Subscription) as string });

      closeModal?.();
    },
  });

  const onSubmit = () => {
    dispatch(updateFooterState({ isFormSubmitting: true }));
    const formValue = getValues();

    mutate({
      message: formValue?.comment || null,
      state: TodoStates.DONE,
      subscriptionId,
      todoId,
    });
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  return (
    <section>
      <Box display='flex' flexDirection='column' sx={{ mb: 2 }}>
        <Typography variant='subtitle' paragraph>
          {t('common:modals.mark_task_as_done_feature_modal.title_text')}
        </Typography>
        <Typography variant='body' paragraph>
          {t('common:modals.mark_task_as_done_feature_modal.sub_title_text')}
        </Typography>
        <TextField
          id='comment'
          variant='outlined'
          size='small'
          label={t('common:modals.mark_task_as_done_feature_modal.body_section.form.comment_placeholder_text')}
          multiline
          rows={3}
          {...register('comment')}
        />
      </Box>

      <Typography variant='subtitle' paragraph>
        {t('common:modals.mark_task_as_done_feature_modal.body_section.form.upload_document_label_text')}
      </Typography>
      <UploadSubscriptionDocumentsFeature
        isAddNewSubscription
        ref={uploadDocumentRef}
        companyId={String(company?.id)}
        setIsFileSelected={setIsFileSelected}
      />
    </section>
  );
});
