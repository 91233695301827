export const CLOSE_ICON = 'close';

export const pathToTranslation =
  'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.request_details_section.contact_sastrify_form';

export const commitmentLengths = [
  { id: 'n/a', label: 'N/A' },
  { id: 'monthly', label: 'Monthly' },
  { id: 'annual', label: 'Annual' },
  { id: '2-year', label: '2-year' },
  { id: '3-year', label: '3-year' },
];

export const billingCycles = [
  { id: 'n/a', label: 'N/A' },
  { id: 'monthly', label: 'Monthly' },
  { id: 'quarterly', label: 'Quarterly' },
  { id: 'bi-yearly', label: 'Bi-yearly' },
  { id: 'yearly_upfront', label: 'Yearly upfront' },
];

export const existingSolutionRadioGroupOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const requestTypesMapping = {
  find_alternative: 'Find Alternative',
  negotiation_support: 'Negotiation support',
  other: 'Other',
  renewal_and_negotiation_support: 'Renewal and Negotiation Support',
  request_benchmark: 'Request benchmark',
};

export const benchmarkGoalsMapping = {
  is_good_price_current_volume: 'I want to know if I’m paying a good price for my current volume',
  what_is_good_price_new_requirements: 'I want to know what would be a good price for my new requirements',
};

export const requestTypes = [
  { id: 'request_benchmark', label: requestTypesMapping.request_benchmark },
  { id: 'negotiation_support', label: requestTypesMapping.negotiation_support },
];

export const renewalRequestTypes = [
  { id: 'request_benchmark', label: requestTypesMapping.request_benchmark },
  { id: 'renewal_and_negotiation_support', label: requestTypesMapping.renewal_and_negotiation_support },
  { id: 'other', label: requestTypesMapping.other },
];

export const benchmarkGoals = [
  { id: 'is_good_price_current_volume', label: benchmarkGoalsMapping.is_good_price_current_volume },
  {
    id: 'what_is_good_price_new_requirements',
    label: benchmarkGoalsMapping.what_is_good_price_new_requirements,
  },
];

export const benchmarkChangeInPlans = [
  { id: 'keep_current_plan', label: 'I want to keep my current plan' },
  { id: 'downgrade_plan', label: 'I want to downgrade my plan (and volume)' },
  { id: 'upgrade_plan', label: 'I want to upgrade my plan (and volume) ' },
];

export const benchmarkCommitmentLengths = [
  { id: 'months', label: 'Months' },
  { id: 'years', label: 'Years' },
];

export const benchmarkPaymentCycles = [
  { id: 'upfront', label: 'Upfront' },
  { id: 'monthly', label: 'Monthly' },
  { id: 'quarterly', label: 'Quarterly' },
  { id: 'bi-yearly', label: 'Bi-yearly' },
  { id: 'yearly', label: 'Yearly' },
];

export const renewalFrequencies = [
  { id: 'months', label: 'Months' },
  { id: 'years', label: 'Years' },
];

export const cancelationPeriods = [
  { id: 'days', label: 'Days' },
  { id: 'months', label: 'Months' },
];

export const autoRenewals = [
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
];

export const renewalGoals = [
  { id: 'better_terms_and_conditions', label: 'Better Terms & Conditions' },
  { id: 'cost_savings', label: 'Cost Savings' },
  { id: 'fair_market_price', label: 'Fair market price' },
  { id: 'help_with_price_increase', label: 'Help with a recent price increase' },
  { id: 'being_out_of_contract_risk', label: 'Mitigate the risk of being out of contract' },
];

export const currencies = [
  { id: 'eur', label: 'EUR' },
  { id: 'usd', label: 'USD' },
];

export const negotiationInvolvementsMapping = {
  all_on_sastrify: 'I want to leave it all on Sastrify',
  be_involved: 'I want to be involved in the negotiation',
};

export const negotiationInvolvements = [
  { id: 'all_on_sastrify', label: negotiationInvolvementsMapping.all_on_sastrify },
  { id: 'be_involved', label: negotiationInvolvementsMapping.be_involved },
];

export const involvementUrgencies = [
  { id: 'standard', label: '5 working days (Sastrify standard)' },
  { id: 'sooner', label: 'Sooner, if possible (not guaranteed)' },
];

export const CONTRACT_OR_ORDER_FORM_CATEGORY = 3;

export const subscriptionBillingFrequencyMapping = {
  monthly: 0,
  other: 3,
  yearly: 1,
};
