import { Box, styled } from '@mui/material';
import { colors as asteroidsColors } from 'asteroids';
import { colors } from 'shared/theme';

export const DrawerUploadSectionWrapper = styled(Box)(({ theme }) => ({
  '& .base-style': {
    background: 'transparent',
    border: `1px dotted ${colors.silverSand}`,
    borderRadius: '0.4rem',
    height: '3.5rem',
    padding: 0,
  },
  '& .category': {
    color: colors.rollingStone,
    display: 'block',
    fontSize: '1rem',
    fontWeight: 600,
  },
  '& .category-sub-text': {
    color: colors.osloGray,
    fontSize: '0.75rem',
  },
  '& .circular-progress': {
    color: colors.white,
  },
  '& .close-button-active': {
    cursor: 'pointer',
  },
  '& .delete-icon-wrapper': {
    alignItems: 'center',
    display: 'flex',
  },
  '& .dropzone-message': {
    '& span:first-child': {
      color: asteroidsColors.primary.main,
      textDecoration: 'underline',
    },
    color: colors.rollingStone,
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  '& .dropzone-rejected-section': {
    '& .MuiLinearProgress-bar': {
      backgroundColor: colors.errorFeedback,
    },
    '& .dropzone-error-message': {
      color: colors.errorFeedback,
      fontSize: '0.75rem',
      marginLeft: '0.625rem',
    },
  },
  '& .file-types-allowed-text': {
    fontSize: '0.75rem',
    fontWeight: 600,
  },
  '& .filename': {
    color: colors.bahamaBlue,
    fontSize: '0.75rem',
    textDecoration: 'underline',
  },

  '& .is-flex': {
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },

  '& .upload-complete-line': {
    background: theme.palette.primary.light,
    borderRadius: '5px',
    height: '0.33rem',
    marginTop: '0.2rem',
  },
  '& .upload-rejected-line': {
    background: theme.palette.error.main,
    borderRadius: '5px',
    height: '0.33rem',
    marginTop: '0.2rem',
  },
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  padding: '2.5rem 2rem 2rem 2rem',
}));
