import { Box, ButtonProps, Dialog as MuiDialog, PaperProps, Typography, useTheme } from '@mui/material';
import classnames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'throttle-debounce';

import { Icon } from '../icon/icon.component';
import { LoadingButton } from '../loading-button/loading-button.component';
import { ActionButtonProps, ActionOrder, DialogProps } from './dialog.props';
import { StyledPaperContainer } from './dialog.styles';

const DEFAULT_WIDTH_IN_SPACING_UNITS = 75;
const WIDTH_PRESETS = {
  LARGE: '75%',
  MEDIUM: '50%',
  SMALL: '33%',
};

const sortActionButtons = (actions?: ActionButtonProps[]) =>
  actions
    ?.map(({ actionType, order, ...rest }) => ({
      ...(order && { order }),
      ...(!order && !actionType && { order: ActionOrder.unset }),
      ...(!order && actionType === 'cancel' && { order: ActionOrder.cancel }),
      ...(!order && actionType === 'confirm' && { order: ActionOrder.confirm }),
      actionType,
      ...rest,
    }))
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0)) ?? [];

const getSingleActionProps = ({ actionType, color, size, variant, ...rest }: ActionButtonProps): ButtonProps => ({
  ...rest,
  color: color ?? 'primary',
  size: size ?? 'medium',
  variant: variant ?? 'contained',
  ...(actionType === 'cancel' && {
    color: color ?? 'secondary',
    'data-testid': 'dialog-cancel-button',
    size: size ?? 'medium',
    variant: variant ?? 'text',
  }),
  ...(actionType === 'confirm' && {
    color: color ?? 'primary',
    'data-testid': 'dialog-submit-button',
    size: size ?? 'medium',
    variant: variant ?? 'contained',
  }),
});

export const Dialog: React.FC<DialogProps> = (props) => {
  const {
    PaperProps,
    actions,
    attachContent,
    bodyContent,
    children,
    clickAwayToClose = true,
    customTitle,
    height,
    leftActionComponent,
    onClose,
    open = true,
    title,
    titleAndActionsDivider,
    titleIcon,
    variant,
    width,
    ...rest
  } = props;

  const theme = useTheme();
  const [contentElement, setContentElement] = useState<HTMLDivElement>();
  const currentObserver = useRef<ResizeObserver>();

  // Content overflow control
  const [hasOverflow, setHasOverflow] = useState<boolean | undefined>(undefined);

  const checkOverflow = useCallback(
    (element?: HTMLElement) => {
      if (!element) return undefined;
      const newOverflow: boolean | undefined = (element?.scrollHeight || 0) > (element?.clientHeight || 0);
      if (typeof newOverflow === 'undefined' || newOverflow === hasOverflow) return;
      setHasOverflow(newOverflow);
    },
    [hasOverflow]
  );

  useEffect(() => {
    if (!contentElement || currentObserver.current) return;

    const observer = new ResizeObserver(
      throttle(1000 / 16, (entries: ResizeObserverEntry[]) => {
        const node = entries[0].target as HTMLElement;
        checkOverflow(node);
      })
    );

    observer.observe(contentElement);

    currentObserver.current = observer;

    return () => {
      currentObserver.current = undefined;
      return contentElement && observer.disconnect();
    };
  }, [checkOverflow, currentObserver, contentElement]);

  // Dimensions control
  const dialogHeight = useMemo(
    () =>
      height &&
      // eslint-disable-next-line no-nested-ternary
      (typeof height === 'number' || height.match(/^\d+$/g)
        ? `${height}px` // Pixels number
        : `${height}`.match(/^\d+%$/g) // Percentage
        ? height
        : null),
    [height]
  );

  const dialogWidth: string | number | null = useMemo(() => {
    if (width && (typeof width === 'number' || width.match(/^\d+$/g))) {
      return `${width}px`;
    }

    if (width && typeof width === 'string') {
      switch (width) {
        case 'default':
          return theme.spacing(DEFAULT_WIDTH_IN_SPACING_UNITS); // Default width
        case 'small':
          return WIDTH_PRESETS.SMALL;
        case 'medium':
          return WIDTH_PRESETS.MEDIUM;
        case 'large':
          return WIDTH_PRESETS.LARGE;
        default:
          return null;
      }
    }
    return null;
  }, [theme, width]);

  const getPaperProps = useCallback((): Partial<PaperProps> => {
    const props = PaperProps ?? {};

    if (dialogWidth ?? dialogHeight) {
      props.sx = { ...(props.sx ?? {}), height: dialogHeight ?? undefined, width: dialogWidth ?? undefined };
    }

    if (titleAndActionsDivider || hasOverflow || variant === 'scrollable') {
      props.className = 'show-divider';
    }

    return props;
  }, [PaperProps, dialogHeight, dialogWidth, hasOverflow, titleAndActionsDivider, variant]);

  // Handlers
  const handleCloseDialog = useCallback(
    (evt: unknown, reason: string) => {
      if (clickAwayToClose === false && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
        return null;
      }
      onClose?.();
    },
    [clickAwayToClose, onClose]
  );

  // Renders
  const renderTitle = useMemo(
    () => (
      <Box key='dialog-default-title' className='dialog-default-title'>
        {titleIcon && (
          <Icon key='icon' sx={{ mr: 1 }}>
            {titleIcon}
          </Icon>
        )}
        <Typography variant='h2'>{title}</Typography>
      </Box>
    ),
    [title, titleIcon]
  );

  const renderActions = useMemo(
    () => (
      <Box component='section' className='dialog-actions-wrapper' key='dialog-actions-wrapper'>
        <Box>{leftActionComponent}</Box>
        <Box className='dialog-actions' key='dialog-actions'>
          {sortActionButtons(actions).map(({ label, order, ...action }, index) => (
            <Box ml={1} key={`action-${order}-${index + 1}`}>
              <LoadingButton key={`button-${order}-${index + 1}`} {...getSingleActionProps(action)}>
                {label ?? action.actionType ?? 'Action'}
              </LoadingButton>
            </Box>
          ))}
        </Box>
      </Box>
    ),
    [actions, leftActionComponent]
  );

  return (
    <MuiDialog
      key='dialog-window'
      data-testid='dialog-window'
      open={open}
      onClose={handleCloseDialog}
      PaperComponent={StyledPaperContainer}
      PaperProps={getPaperProps()}
      {...rest}>
      <Box key='dialog-wrapper' className='dialog-wrapper'>
        {(customTitle || title) && (
          <Box component='section' key='dialog-title-wrapper' className='dialog-title'>
            {customTitle ?? renderTitle}
          </Box>
        )}
        {(bodyContent || children) && (
          <Box
            key='dialog-content-wrapper'
            ref={(node: HTMLDivElement) => {
              setContentElement(node);
              checkOverflow(node);
            }}
            className={classnames('dialog-content-scroll-wrapper', { 'attach-content': !!attachContent })}>
            <Box component='section' key='dialog-content' className='dialog-content'>
              <>
                {bodyContent}
                {children}
              </>
            </Box>
          </Box>
        )}
        {!!actions?.length && renderActions}
      </Box>
    </MuiDialog>
  );
};

export default Dialog;
