import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, InputField } from 'src/asteroids';

import { TableSearchProps } from './table-search.props';

export const TableSearch: FC<TableSearchProps> = ({ initialSearchText, label, onChange }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialSearchText ?? '');
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      onChange?.(value);
    }, 400);

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [onChange, value]);

  return (
    <InputField
      key='input'
      value={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      onClearValue={() => setValue('')}
      label={label ?? t('asteroids:mrt.search_label')}
      startAdornment={
        <Icon key='start-adornment' variant='Outlined'>
          search
        </Icon>
      }
      sx={{ height: '36px', width: '420px' }}
      data-testid='search-field'
    />
  );
};
