import { CreateSliceOptions, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

interface FiltersState {
  decisionDate: string | null;
  missingDocument: boolean;
  openTask: boolean;
  renewalDate: string | null;
  renewalFrequency: string | null;
  requester: string | null;
  spend: number | null;
  status: string | null;
  subscriptionAccessLevel: string | null;
  tags: string[];
  toolOwner: string | null;
  withComments: boolean;
}

const initialState: FiltersState = {
  decisionDate: null,
  missingDocument: false,
  openTask: false,
  renewalDate: null,
  renewalFrequency: null,
  requester: null,
  spend: null,
  status: null,
  subscriptionAccessLevel: null,
  tags: [],
  toolOwner: null,
  withComments: false,
};

export const toolFilterSliceConfigFactory = (parentComponent: string) =>
  ({
    initialState: { ...initialState },
    name: `${parentComponent}Filters`,
    reducers: {
      reset: () => ({ ...initialState }),
      setDecisionDate: (state: FiltersState, action: PayloadAction<string | null>) => {
        state.decisionDate = action.payload;
      },
      setMissingDocument: (state: FiltersState, action: PayloadAction<boolean>) => {
        state.missingDocument = action.payload;
      },
      setOpenTask: (state: FiltersState, action: PayloadAction<boolean>) => {
        state.openTask = action.payload;
      },
      setRenewalDate: (state: FiltersState, action: PayloadAction<string | null>) => {
        state.renewalDate = action.payload;
      },
      setRenewalFrequency: (state: FiltersState, action: PayloadAction<string | null>) => {
        state.renewalFrequency = action.payload;
      },
      setRequester: (state: FiltersState, action: PayloadAction<string | null>) => {
        state.requester = action.payload;
      },
      setSpend: (state: FiltersState, action: PayloadAction<number | null>) => {
        state.spend = action.payload;
      },
      setStatus: (state: FiltersState, action: PayloadAction<string | null>) => {
        state.status = action.payload;
      },
      setSubscriptionAccessLevel: (state: FiltersState, action: PayloadAction<string | null>) => {
        state.subscriptionAccessLevel = action.payload;
      },
      setTags: (state: FiltersState, action: PayloadAction<string[]>) => {
        state.tags = action.payload;
      },
      setToolOwner: (state: FiltersState, action: PayloadAction<string | null>) => {
        state.toolOwner = action.payload;
      },
      setWithComments: (state: FiltersState, action: PayloadAction<boolean>) => {
        state.withComments = action.payload;
      },
    },
  } as CreateSliceOptions<FiltersState, SliceCaseReducers<FiltersState>, string>);
