import React from 'react';
import { colors } from 'shared/theme';

export const HumanExploreIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, height, width }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox='0 0 307 198'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2168:20537)'>
      <path
        d='M201.763 39.7404C201.763 39.7404 229.65 19.1156 257.528 21.9216C285.407 24.7275 313.574 60.663 313.574 60.663L304.549 67.5018C304.549 67.5018 257.772 28.7063 232.439 57.3879C207.106 86.0695 201.763 39.7404 201.763 39.7404Z'
        fill={colors.aquamarineBlue}
      />
      <path
        opacity='0.2'
        d='M201.763 39.7404C201.763 39.7404 229.65 19.1156 257.528 21.9216C285.407 24.7275 313.574 60.663 313.574 60.663L304.549 67.5018C304.549 67.5018 257.772 28.7063 232.439 57.3879C207.106 86.0695 201.763 39.7404 201.763 39.7404Z'
        fill={colors.white}
      />
      <path d='M222.827 36.8623H318.583V98.7096H222.827V36.8623Z' fill={colors.rajah} />
      <path
        opacity='0.45'
        d='M67.85 168.93C69.6141 168.573 71.2769 167.829 72.7177 166.751C74.1585 165.672 75.3412 164.287 76.1801 162.695V162.605L119.5 82.5L142.433 97.8976L125.799 150.172C125.361 151.547 125.262 153.007 125.51 154.428C125.758 155.849 126.347 157.189 127.226 158.333C128.105 159.478 129.247 160.392 130.556 161C131.865 161.607 133.302 161.888 134.743 161.82C147.378 161.243 160.816 160.918 174.778 160.918C246.906 160.918 305.379 169.318 305.379 179.684C305.379 190.051 246.906 198.459 174.778 198.459C102.65 198.459 44.2225 190.06 44.2225 179.711C44.2225 175.687 52.9677 171.979 67.85 168.93Z'
        fill={colors.primary}
        fillOpacity='0.19'
      />
      <path
        d='M203.748 173.558C203.748 173.558 202.413 178.069 198.451 178.602C194.489 179.134 193.658 183.113 197.296 183.925C200.933 184.737 209.931 179.639 209.931 179.639L209.362 173.91L203.748 173.558Z'
        fill={colors.aquamarineBlue}
      />
      <path
        d='M280.885 172.791C280.885 172.791 285.47 173.783 286.3 177.69C287.13 181.597 291.155 182.12 291.715 178.43C292.275 174.74 286.472 166.178 286.472 166.178L280.804 167.197L280.885 172.791Z'
        fill={colors.aquamarineBlue}
      />
      <path
        d='M280.271 105.431C280.271 105.431 284.495 125.136 269.288 133.58C254.081 142.025 206.113 126.823 213.522 174.686H202.819C202.819 174.686 188.983 134.988 224.876 120.877L235.769 108.526L280.271 105.431Z'
        fill={colors.tetapaGreen}
      />
      <path
        d='M164.029 41.969C164.029 41.969 168.1 47.8785 172.053 50.7837C172.436 51.0629 172.87 51.2638 173.332 51.375C173.793 51.4862 174.271 51.5055 174.74 51.4318C175.208 51.358 175.657 51.1927 176.062 50.9453C176.467 50.6979 176.818 50.3732 177.097 49.9897C178.532 47.9778 179.886 44.8381 178.09 41.4096L175.762 35.2113C175.158 34.0267 174.203 33.0577 173.026 32.4374C171.85 31.8171 170.51 31.5759 169.192 31.7468C164.255 32.0355 161.376 38.4954 164.029 41.969Z'
        fill={colors.apricotPeach}
      />
      <path
        d='M174.336 36.4834L192.702 43.8816L190.446 56.9458L177.206 46.1462L174.336 36.4834Z'
        fill={colors.apricotPeach}
      />
      <path
        d='M165.591 41.0664C167.357 39.7215 168.962 38.1776 170.374 36.4651C170.502 37.6249 170.965 38.7223 171.707 39.6229C172.449 40.5235 173.438 41.1882 174.552 41.5356C175.626 41.8891 176.79 41.8583 177.844 41.4484C178.897 41.0386 179.776 40.2752 180.328 39.2891L174.372 32.2517C173.558 31.2103 172.477 30.4088 171.244 29.9322C170.011 29.4556 168.672 29.3218 167.369 29.545C166.243 29.7389 165.13 30.0041 164.038 30.339C161.331 31.2412 159.445 35.0486 156.466 35.1749C156.167 35.1895 155.876 35.2816 155.623 35.4422C155.37 35.6027 155.163 35.8262 155.022 36.0907C154.881 36.3553 154.811 36.652 154.819 36.9516C154.827 37.2513 154.913 37.5437 155.068 37.8004C156.53 40.2274 159.724 43.313 163.632 42.0769C164.336 41.8501 164.998 41.5087 165.591 41.0664V41.0664Z'
        fill={colors.tetapaGreen}
      />
      <path
        d='M173.822 41.8604C173.822 41.8604 172.107 39.6951 173.822 38.5312C175.536 37.3674 178.054 40.4891 176.114 42.5732L173.822 41.8604Z'
        fill={colors.apricotPeach}
      />
      <path
        d='M166.782 45.5596L166.999 48.9609C167.017 49.1513 167.082 49.3342 167.189 49.4931C167.295 49.652 167.439 49.7821 167.608 49.8715C167.777 49.961 167.966 50.007 168.157 50.0056C168.349 50.0041 168.537 49.9552 168.704 49.8631L171.15 48.5098L166.782 45.5596Z'
        fill={colors.apricotPeach}
      />
      <path
        opacity='0.31'
        d='M178.695 47.3461C178.695 47.3461 178.145 43.241 176.664 41.6802C176.664 41.6802 183.352 43.9357 183.505 51.2889L178.695 47.3461Z'
        fill={colors.piper}
      />
      <path
        opacity='0.08'
        d='M299.937 97.1125C299.937 97.1125 300.226 54.5547 249.09 45.5596L279.134 98.7095H299.892L299.937 97.1125Z'
        fill={colors.riverBed}
      />
      <path
        d='M188.009 61.4657L189.525 52.651C190.118 49.0561 191.614 45.6705 193.873 42.8112C196.131 39.9519 199.079 37.7125 202.44 36.3027C205.365 35.1337 208.424 34.3306 211.546 33.9119C215.553 33.3525 221.167 33.9119 227.448 35.5629C254.505 42.6634 274.36 65.5798 278.466 93.2059L280.271 105.431L235.769 108.526C235.769 108.526 238.025 95.8945 222.232 95.8945C206.438 95.8945 186.438 96.0569 188.009 61.4657Z'
        fill={colors.aquamarineBlue}
      />
      <path
        opacity='0.08'
        d='M231.618 47.7974C231.618 47.7974 253.214 51.5957 276.95 85.7899L280.271 105.431L246.5 107.94L231.618 47.7974Z'
        fill={colors.black}
      />
      <path
        d='M206.853 63.523C202.638 49.4664 218.983 38.0984 230.634 47.0304C240.381 54.4918 251.391 67.9259 251.825 90.5987C252.574 131.514 213.369 124.197 160.311 108.526L161.999 96.6075C161.999 96.6075 225.535 108.616 216.907 87.9732C213.297 79.339 209.091 71.0024 206.853 63.523Z'
        fill={colors.aquamarineBlue}
      />
      <path
        opacity='0.2'
        d='M206.853 63.523C202.638 49.4664 218.983 38.0984 230.634 47.0304C240.381 54.4918 251.391 67.9259 251.825 90.5987C252.574 131.514 213.369 124.197 160.311 108.526L161.999 96.6075C161.999 96.6075 225.535 108.616 216.907 87.9732C213.297 79.339 209.091 71.0024 206.853 63.523Z'
        fill={colors.white}
      />
      <path
        d='M229.154 120.977C229.154 120.977 227.845 166.223 280.804 174.686L282.853 165.095C282.853 165.095 240.435 154.584 267.835 126.426L229.154 120.977Z'
        fill={colors.tetapaGreen}
      />
      <path
        opacity='0.23'
        d='M145.736 181.822C142.556 181.822 139.978 180.869 139.978 179.693C139.978 178.517 142.556 177.564 145.736 177.564C148.916 177.564 151.494 178.517 151.494 179.693C151.494 180.869 148.916 181.822 145.736 181.822Z'
        fill={colors.primary}
      />
      <path
        opacity='0.23'
        d='M123.715 176.31C120.535 176.31 117.957 175.356 117.957 174.181C117.957 173.005 120.535 172.051 123.715 172.051C126.895 172.051 129.473 173.005 129.473 174.181C129.473 175.356 126.895 176.31 123.715 176.31Z'
        fill={colors.primary}
      />
      <path
        opacity='0.23'
        d='M101.54 180.568C98.3602 180.568 95.7823 179.615 95.7823 178.439C95.7823 177.263 98.3602 176.31 101.54 176.31C104.72 176.31 107.298 177.263 107.298 178.439C107.298 179.615 104.72 180.568 101.54 180.568Z'
        fill={colors.primary}
      />
      <path
        opacity='0.23'
        d='M67.5883 182.707C64.4082 182.707 61.8303 181.753 61.8303 180.577C61.8303 179.402 64.4082 178.448 67.5883 178.448C70.7683 178.448 73.3462 179.402 73.3462 180.577C73.3462 181.753 70.7683 182.707 67.5883 182.707Z'
        fill={colors.primary}
      />
      <path
        opacity='0.23'
        d='M167.567 175.832C164.317 175.832 161.683 174.854 161.683 173.648C161.683 172.442 164.317 171.465 167.567 171.465C170.817 171.465 173.451 172.442 173.451 173.648C173.451 174.854 170.817 175.832 167.567 175.832Z'
        fill={colors.primary}
      />
      <path
        d='M307.148 98.7098C307.148 98.7098 305.045 107.227 296.625 105.431C288.204 103.636 294.215 93.1882 307.148 98.7098Z'
        fill={colors.apricotPeach}
      />
      <path
        d='M141.726 96.0397L139.972 98.0537L143.496 101.124L145.251 99.1099L141.726 96.0397Z'
        fill={colors.grayChateau}
      />
      <path
        d='M142.735 103.361C142.049 102.708 142.022 101.624 142.674 100.938L144.913 98.583C145.566 97.8971 146.651 97.8699 147.336 98.5222L155.774 106.547C156.575 107.308 156.606 108.574 155.845 109.374L154 111.315C153.239 112.115 151.973 112.147 151.172 111.386L142.735 103.361Z'
        fill={colors.primary}
      />
      <path
        d='M141.721 97.4081C146.118 92.8233 145.26 84.8644 139.803 79.6313C134.347 74.3982 126.359 73.8726 121.962 78.4573C117.565 83.0421 118.423 91.0011 123.879 96.2342C129.336 101.467 137.323 101.993 141.721 97.4081Z'
        fill={colors.ironV2}
      />
      <path
        d='M140.295 96.0007C143.937 92.2029 143.114 85.5027 138.457 81.0354C133.799 76.5682 127.07 76.0254 123.428 79.8232C119.785 83.621 120.608 90.3212 125.266 94.7884C129.924 99.2557 136.652 99.7984 140.295 96.0007Z'
        fill={colors.bridesmaidV2}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M126.136 82.4632C128.061 80.4554 131.25 80.3889 133.258 82.3145C133.656 82.6968 134.289 82.6836 134.672 82.285C135.054 81.8864 135.041 81.2534 134.642 80.8711C131.837 78.1809 127.382 78.2738 124.692 81.0788C124.31 81.4774 124.323 82.1104 124.722 82.4927C125.12 82.875 125.753 82.8618 126.136 82.4632Z'
        fill={colors.cadetBlue}
        fillOpacity='0.28'
      />
      <path
        d='M161.674 98.8992C161.674 98.8992 147.071 97.3564 145.79 103.058C144.508 108.76 147.306 111.783 160.446 107.57L161.674 98.8992Z'
        fill={colors.apricotPeach}
      />
    </g>
    <defs>
      <clipPath id='clip0_2168:20537'>
        <rect width='307' height='198' fill={colors.white} transform='matrix(-1 0 0 1 307 0)' />
      </clipPath>
    </defs>
  </svg>
);

HumanExploreIcon.defaultProps = {
  height: '198',
  width: '307',
};
