import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DrawersList } from './drawers-list';

type DrawerStateType = {
  hash: string;
  open: boolean;
};

type InitialStateType = {
  [key in DrawersList]: DrawerStateType;
};

type PayloadActionType = {
  type: DrawersList;
  hash?: string;
};

const initialState: InitialStateType = Object.values(DrawersList || {}).reduce((acc, drawer) => {
  return {
    ...acc,
    [drawer]: {
      hash: '',
      open: false,
    },
  };
}, {}) as Record<DrawersList, DrawerStateType>;

export const drawerSlice = createSlice({
  initialState,
  name: 'drawer',
  reducers: {
    hideDrawer(state, action: PayloadAction<DrawersList>) {
      state[action.payload] = {
        ...state[action.payload],
        open: false,
      };
    },
    showDrawer(state, action: PayloadAction<PayloadActionType>) {
      state[action.payload.type] = {
        ...state[action.payload.type],
        hash: action.payload.hash || '',
        open: true,
      };
    },
  },
});

export const { hideDrawer, showDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
