/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, TextField, Typography } from '@mui/material';
import { Autocomplete, DatePicker, InputField } from 'asteroids';
import { DropzoneComponent } from 'components/drawer-workflow-request/components/dropzone/dropzone.component';
import { FilesListComponent } from 'components/drawer-workflow-request/components/files-list/files-list.component';
import { NumberInput } from 'components/drawer-workflow-request/components/number-input/number-input.component';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getSubscription, getSubscriptionDocuments } from 'shared/logic/subscription-item.logic';
import { Document, Subscription } from 'shared/models';

import { Alert, FlexColumn } from '../../contact-sastrify-form.component.styles';
import {
  autoRenewals,
  benchmarkChangeInPlans,
  benchmarkCommitmentLengths,
  benchmarkPaymentCycles,
  cancelationPeriods,
  CONTRACT_OR_ORDER_FORM_CATEGORY,
  currencies,
  involvementUrgencies,
  negotiationInvolvements,
  negotiationInvolvementsMapping,
  pathToTranslation,
  renewalFrequencies,
  renewalGoals,
  subscriptionBillingFrequencyMapping,
} from '../../contact-sastrify-form.constants';
import { RequestRenewalAndNegotiationSupportFormProps } from './request-renewal-and-negotiation-support-form.component.props';
import { formValidationSchema } from './request-renewal-and-negotiation-support-form.validation-schema';

export const RequestRenewalAndNegotiationSupportFormComponent: React.FC<
  RequestRenewalAndNegotiationSupportFormProps
> = ({ formRef, subscriptionId }) => {
  const { t } = useTranslation();
  const latestDocumentsDropzoneRef = useRef<any>(null);
  const {
    control,
    formState: { errors, isDirty },
    getValues,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      autoRenewal: 'No',
      budget: '',
      cancelationPeriod: 'Days',
      cancelationPeriodValue: '',
      changeInPlans: '',
      commitmentLengthValue: '',
      contactEmail: '',
      contactName: '',
      currency: 'EUR',
      involvementUrgency: '5 working days (Sastrify standard)',
      negotiationInvolvement: '',
      newVolume: '',
      preferredCommitmentLength: 'Months',
      preferredPaymentCycle: '',
      renewalDate: '',
      renewalDetails: '',
      renewalFrequency: 'Months',
      renewalFrequencyValue: '1',
      renewalGoal: '',
    },
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });
  const [isReminderSeen, setIsReminderSeen] = useState(false);
  const [existingDocuments, setExistingDocuments] = useState<Document[]>([]);
  const [isExistingDocumentsRemoved, setIsExistingDocumentsRemoved] = useState(false);

  const negotiationInvolvement = watch('negotiationInvolvement');

  const { data: subscription } = useQuery<Subscription>('subscription', () => getSubscription(String(subscriptionId)));
  const { data: subscriptionDocuments } = useQuery<Document[]>('subscription-documents', () =>
    getSubscriptionDocuments(String(subscriptionId))
  );

  useEffect(() => {
    if (isDirty) return;

    if (subscription) {
      reset();
      if (subscription.billingFrequency === subscriptionBillingFrequencyMapping.monthly) {
        setValue('renewalFrequency', 'Months');
        setValue('renewalFrequencyValue', '1');
      }
      if (subscription.billingFrequency === subscriptionBillingFrequencyMapping.yearly) {
        setValue('renewalFrequency', 'Years');
        setValue('renewalFrequencyValue', '1');
      }
      if (subscription.billingFrequency === subscriptionBillingFrequencyMapping.other && subscription.renewalInterval) {
        const renewalFrequency = renewalFrequencies.find(
          (renewalFrequency) => renewalFrequency.id === Object.keys(subscription.renewalInterval || {})[0]
        );

        setValue('renewalFrequency', renewalFrequency?.label || '');
        setValue('renewalFrequencyValue', `${Object.values(subscription.renewalInterval || {})[0]}`);
      }
      if (subscription.renewalDate) {
        setValue('renewalDate', subscription.renewalDate);
      }
      if (subscription.cancellationPeriod) {
        const cancelationPeriod = cancelationPeriods.find(
          (cancelationPeriod) => cancelationPeriod.id === Object.keys(subscription.cancellationPeriod || {})[0]
        );

        setValue('cancelationPeriod', cancelationPeriod?.label || '');
        setValue('cancelationPeriodValue', `${Object.values(subscription.cancellationPeriod || {})[0]}`);
      }
      if (subscription.autoRenewal) {
        setValue('autoRenewal', subscription.autoRenewal ? 'Yes' : 'No');
      }
    }
  }, [
    subscription?.renewalDate,
    subscription?.renewalInterval,
    subscription?.cancellationPeriod,
    subscription?.autoRenewal,
    subscription,
    isDirty,
    setValue,
    reset,
  ]);

  useEffect(() => {
    if (subscriptionDocuments && subscriptionDocuments.length > 0 && !isExistingDocumentsRemoved) {
      const documentsSorterByUploadDateDesc = subscriptionDocuments.sort((a: Document, b: Document) => {
        return a.uploadDate && b.uploadDate ? new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime() : 0;
      });
      const contractsOrOrderForms = documentsSorterByUploadDateDesc.filter(
        (document) => document.category === CONTRACT_OR_ORDER_FORM_CATEGORY
      );

      if (contractsOrOrderForms.length > 0) {
        setExistingDocuments([contractsOrOrderForms[0]]);
      }
    }
  }, [subscriptionDocuments, isExistingDocumentsRemoved]);

  formRef.current = {
    getValues: () => ({
      ...getValues(),
      documents: latestDocumentsDropzoneRef.current?.getFiles(),
      existingDocuments,
    }),
    trigger,
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography component='p' variant='h3' my={2}>
          {t(`${pathToTranslation}.form_sections.documents_section`)}
        </Typography>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.latest_documents.label`)}
        </Typography>
        <DropzoneComponent dropzoneRef={latestDocumentsDropzoneRef} />
        {existingDocuments.length > 0 && !isExistingDocumentsRemoved && (
          <FilesListComponent
            files={existingDocuments as any}
            onRemove={() => {
              setExistingDocuments([]);
              setIsExistingDocumentsRemoved(true);
            }}
          />
        )}
      </Grid>
      <FlexColumn item xs={4}>
        <Typography component='p' variant='h3' my={2}>
          {t(`${pathToTranslation}.form_sections.contact_termination_section`)}
        </Typography>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.renewal_frequency.label`)}
        </Typography>
        <Controller
          name='renewalFrequency'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='renewalFrequency'
                label={t(`${pathToTranslation}.fields.renewal_frequency.placeholder`)}
                value={value}
                options={renewalFrequencies}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={errors.renewalFrequency ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.renewalFrequency)}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={4} mt={10}>
        <Controller
          name='renewalFrequencyValue'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <NumberInput
                name='renewalFrequencyValue'
                thousandSeparator
                value={value || ''}
                onValueChange={(value) => onChange(value.floatValue)}
                onClear={() => onChange('')}
                label={t(`${pathToTranslation}.fields.renewal_frequency_number.placeholder`)}
                error={Boolean(errors.renewalFrequencyValue)}
                helperText={errors.renewalFrequencyValue ? t(`${pathToTranslation}.errors.required_field`) : ''}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={4} mt={6.25}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.renewal_date.label`)}
        </Typography>
        <Controller
          name='renewalDate'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                value={value as any}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                minDate={new Date() as any}
                label=''
                error={Boolean(errors.renewalDate)}
                helperText={errors.renewalDate ? t(`${pathToTranslation}.errors.required_field`) : ''}
                inputFormat='dd/MM/yyyy'
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={4}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.cancelation_period.label`)}
        </Typography>
        <Controller
          name='cancelationPeriod'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='cancelationPeriod'
                label={t(`${pathToTranslation}.fields.cancelation_period.placeholder`)}
                value={value}
                options={cancelationPeriods}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={errors.cancelationPeriod ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.cancelationPeriod)}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={4} mt={3.75}>
        <Controller
          name='cancelationPeriodValue'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <NumberInput
                name='cancelationPeriodValue'
                thousandSeparator
                value={value || ''}
                onValueChange={(value) => onChange(value.floatValue)}
                onClear={() => onChange('')}
                label={t(`${pathToTranslation}.fields.cancelation_period_number.placeholder`)}
                error={Boolean(errors.cancelationPeriodValue)}
                helperText={errors.cancelationPeriodValue ? t(`${pathToTranslation}.errors.required_field`) : ''}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={4}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.auto_renewal.label`)}
        </Typography>
        <Controller
          name='autoRenewal'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='autoRenewal'
                label={t(`${pathToTranslation}.fields.auto_renewal.placeholder`)}
                value={value}
                options={autoRenewals}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={errors.autoRenewal ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.autoRenewal)}
              />
            );
          }}
        />
      </FlexColumn>
      <Grid item xs={12}>
        <Typography component='p' variant='h3' my={2}>
          {t(`${pathToTranslation}.form_sections.questionnaire_section`)}
        </Typography>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.renewal_goal.label`)}
        </Typography>
        <Controller
          name='renewalGoal'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='renewalGoal'
                label={t(`${pathToTranslation}.fields.renewal_goal.placeholder`)}
                value={value}
                options={renewalGoals}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={errors.renewalGoal ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.renewalGoal)}
              />
            );
          }}
        />
      </Grid>
      <FlexColumn item xs={6}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.benchmark_change_in_plans.label`)}
        </Typography>
        <Controller
          name='changeInPlans'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='changeInPlans'
                label={t(`${pathToTranslation}.fields.benchmark_change_in_plans.placeholder`)}
                value={value}
                options={benchmarkChangeInPlans}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={errors.changeInPlans ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.changeInPlans)}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={2}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.currency.label`)}
        </Typography>
        <Controller
          name='currency'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='currency'
                label={t(`${pathToTranslation}.fields.currency.placeholder`)}
                value={value}
                options={currencies}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={4}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.renewal_budget.label`)}
        </Typography>
        <Controller
          name='budget'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <NumberInput
                name='budget'
                thousandSeparator
                value={value || ''}
                onValueChange={(value) => onChange(value.floatValue)}
                onClear={() => onChange('')}
                label={t(`${pathToTranslation}.fields.renewal_frequency_number.placeholder`)}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.renewal_new_volume.label`)}
        </Typography>
        <Controller
          name='newVolume'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <InputField
                id='newVolume'
                value={value}
                onChange={(value: any) => onChange(value)}
                onClearValue={() => onChange('')}
                label={t(`${pathToTranslation}.fields.renewal_new_volume.placeholder`)}
                helperText={
                  errors.newVolume
                    ? t(`${pathToTranslation}.errors.required_field`)
                    : t(`${pathToTranslation}.fields.renewal_new_volume.helper_text`)
                }
                error={Boolean(errors.newVolume)}
                fullWidth
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={6}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.benchmark_commitment_length.label`)}
        </Typography>
        <Controller
          name='preferredCommitmentLength'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='preferredCommitmentLength'
                label={t(`${pathToTranslation}.fields.benchmark_commitment_length.placeholder`)}
                value={value}
                options={benchmarkCommitmentLengths}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={
                  errors.preferredCommitmentLength
                    ? t(`${pathToTranslation}.errors.required_field`)
                    : t(`${pathToTranslation}.fields.benchmark_commitment_length.helper_text`)
                }
                error={Boolean(errors.preferredCommitmentLength)}
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={6} mt={3.75}>
        <Controller
          name='commitmentLengthValue'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <NumberInput
                name='commitmentLengthValue'
                thousandSeparator
                value={value || ''}
                onValueChange={(value) => onChange(value.floatValue)}
                onClear={() => onChange('')}
                label={t(`${pathToTranslation}.fields.benchmark_commitment_length_number.placeholder`)}
                error={Boolean(errors.commitmentLengthValue)}
                helperText={errors.commitmentLengthValue ? t(`${pathToTranslation}.errors.required_field`) : ''}
              />
            );
          }}
        />
      </FlexColumn>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.benchmark_payment_cycle.label`)}
        </Typography>
        <Controller
          name='preferredPaymentCycle'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='preferredPaymentCycle'
                label={t(`${pathToTranslation}.fields.benchmark_payment_cycle.placeholder`)}
                value={value}
                options={benchmarkPaymentCycles}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
                helperText={
                  errors.preferredPaymentCycle
                    ? t(`${pathToTranslation}.errors.required_field`)
                    : t(`${pathToTranslation}.fields.benchmark_payment_cycle.helper_text`)
                }
                error={Boolean(errors.preferredPaymentCycle)}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.negotiation_involvement.label`)}
        </Typography>
        <Controller
          name='negotiationInvolvement'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='negotiationInvolvement'
                label={t(`${pathToTranslation}.fields.negotiation_involvement.placeholder`)}
                value={value}
                options={negotiationInvolvements}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                  setIsReminderSeen(false);
                }}
                helperText={errors.negotiationInvolvement ? t(`${pathToTranslation}.errors.required_field`) : ''}
                error={Boolean(errors.negotiationInvolvement)}
              />
            );
          }}
        />
      </Grid>
      {negotiationInvolvement === negotiationInvolvementsMapping.all_on_sastrify && !isReminderSeen && (
        <Grid item xs={12}>
          <Alert
            actionIcon=''
            description={t(`${pathToTranslation}.reminder_alert.message`)}
            actionText={t(`${pathToTranslation}.reminder_alert.action_text`)}
            isIconVisible
            severity='info'
            title={t(`${pathToTranslation}.reminder_alert.title`)}
            onAction={() => setIsReminderSeen(true)}
          />
        </Grid>
      )}
      <FlexColumn item xs={6}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.vendor_contact.label`)}
        </Typography>
        <Controller
          name='contactName'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <InputField
                id='contactName'
                value={value}
                onChange={(value: any) => onChange(value)}
                onClearValue={() => onChange('')}
                label={t(`${pathToTranslation}.fields.vendor_contact.placeholder`)}
                fullWidth
              />
            );
          }}
        />
      </FlexColumn>
      <FlexColumn item xs={6}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.vendor_email.label`)}
        </Typography>
        <Controller
          name='contactEmail'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <InputField
                id='contactEmail'
                value={value}
                onChange={(value: any) => onChange(value)}
                onClearValue={() => onChange('')}
                label={t(`${pathToTranslation}.fields.vendor_email.placeholder`)}
                fullWidth
              />
            );
          }}
        />
      </FlexColumn>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.involvement_urgency.label`)}
        </Typography>
        <Controller
          name='involvementUrgency'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id='involvementUrgency'
                label={t(`${pathToTranslation}.fields.involvement_urgency.placeholder`)}
                value={value}
                options={involvementUrgencies}
                onChange={(_, data: any) => {
                  onChange(data?.inputValue ? data?.inputValue : data?.label || '');
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component='p' variant='label' mb={1.5}>
          {t(`${pathToTranslation}.fields.benchmark_details.label`)}
        </Typography>
        <Controller
          name='renewalDetails'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id='renewalDetails'
                onChange={onChange}
                value={value}
                label={t(`${pathToTranslation}.fields.benchmark_details.placeholder`)}
                multiline
                rows={3}
                fullWidth
              />
            );
          }}
        />
      </Grid>
    </>
  );
};
