/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { Autocomplete, AutocompleteOption, Avatar, DatePicker, Icon } from 'asteroids';
import { useFilterRequester, useWorkflowInitiativeWizardContext } from 'components/drawer-workflow-request/hooks';
import { TypeaheadComponent } from 'components/typeahead';
import { VendorSelectAsyncComponent } from 'components/vendor-select-async/vendor-select-async.component';
import { DEFAULT_CURRENCY } from 'libs/enums';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  CSM_USER_EMAIL,
  Currency,
  CurrencyOptions,
  DRAWER_VARIANT,
  REQUEST_STATUS,
  UserRoles,
} from 'shared/common.definitions';
import {
  checkSubscriptionStatus,
  checkToolRequestStatus,
  getInitiativeSubscriptions,
  getVendorItem,
} from 'shared/logic/requests.logic';
import {
  Company,
  InitiativeSubscription,
  RequestDetails,
  RequestWorkflowItem,
  Subscription,
  ToolOwnerFilter,
  User,
  VendorType,
} from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import { clearBenchmarkRequestVendorId } from 'shared/store/views/benchmarks/benchmark-request.slice';
import { AppUrl } from 'src/constants/appurl';
import { draftParamPrefix } from 'views/requests/requests.view';

import { DropzoneComponent } from '../../../dropzone/dropzone.component';
import { MultiRequestToolAlert } from '../../../multi-requests-tool-alert/multi-request-tool-alert.component';
import { NumberInput } from '../../../number-input/number-input.component';
import {
  alternativeToolsDefaultValue,
  defaultToolOwner,
  NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW,
  paymentFrequencyItems,
} from './purchase-default-form.constants';
import { PurchaseDefaultFormProps, ToolOwner } from './purchase-default-form.props';
import { defaultFormValues, formValidationSchema } from './purchase-default-form.validation';

export const DRAWER_FROM_URL = 'routeFrom';

export const PurchaseDefaultForm: FC<PurchaseDefaultFormProps> = ({
  defaultFormRef,
  fetchedData,
  initFiles,
  initialData,
  mode,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { hash } = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setIsToolBusy } = useWorkflowInitiativeWizardContext();

  const selectedBenchmarkVendorId = useAppSelector((state) => state.views.benchmarks.benchmarkRequest.vendorId);

  const [toolOwners, setToolOwners] = useState<ToolOwnerFilter[]>([]);
  const [toolOwner, setToolOwner] = useState(defaultToolOwner);
  const [toolOwnerName, setToolOwnerName] = useState('');
  const [toolOwnerError, setToolOwnerError] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [existingRequestsOrSubscriptions, setExistingRequestsOrSubscriptions] = useState<Map<string, any>>(new Map([]));

  const dropzoneComponentRef = useRef<any>(null);

  const [toolId = '']: (string | undefined)[] = (
    hash.includes(DRAWER_VARIANT.NEW_REQUEST) ? hash.split(DRAWER_VARIANT.NEW_REQUEST).slice(-1)[0] : ''
  ).split(`-${draftParamPrefix}-`);

  const isEditMode = mode === 'edit';
  const isNewRequestMode = mode === 'create';
  const { filterRequester } = useFilterRequester(String(fetchedData?.requesterEmail), mode);

  const company = queryClient.getQueryData<Company>('company');
  const usersAndToolOwnersList: User[] | undefined = queryClient.getQueryData('tool-owners');
  const requests = queryClient.getQueryData<RequestWorkflowItem[]>('workflow-requests');
  const { data: subscriptions } = useQuery<InitiativeSubscription[]>('initiative-subscriptions', () =>
    getInitiativeSubscriptions()
  );

  const isCreateInitiativeDisabled = [...existingRequestsOrSubscriptions.values()].some((requestOrSubscription) => {
    return (
      (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.ACTIVE ||
      (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.APPROVED ||
      Boolean((requestOrSubscription as Subscription).id) ||
      (requestOrSubscription as RequestWorkflowItem).requestStateId === REQUEST_STATUS.SUBSCRIPTION_CREATED
    );
  });

  const userOptions: AutocompleteOption[] = useMemo(
    () =>
      usersAndToolOwnersList
        ?.filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && filterRequester(user) && user.id)
        .map(({ avatar, email, id, name }) => ({
          id: id || '',
          label: name || email || '',
          prefix: (
            <Avatar
              src={avatar}
              imgProps={{ sx: { background: theme.palette.background.light, objectFit: 'contain' } }}
            />
          ),
        })) || [],
    [filterRequester, theme.palette.background.light, usersAndToolOwnersList]
  );

  const {
    control,
    formState: { errors, isValid },
    getValues,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver: yupResolver(formValidationSchema(!isEditMode)),
    shouldUnregister: true,
  });

  const getDefaultForm = () => ({
    files: dropzoneComponentRef?.current?.getFiles(),
    form: getValues(),
    toolOwner,
    toolOwnerName,
  });

  defaultFormRef.current = {
    getDefaultForm,
    getValues,
    isCreateInitiativeDisabled,
    isValid,
    setToolOwnerError,
    toolOwnerName,
    trigger,
  };

  const { append: addNewAlternativeToolFields, replace: setAlternativeToolFields } = useFieldArray({
    control,
    name: 'alternativeTools',
  });
  const alternativeTools = watch('alternativeTools');

  const isEveryAlternativeToolFieldPopulated = (alternativeTools || []).every((tool: any) => {
    if (!tool) return false;
    if (typeof tool === 'string') return Boolean(tool.trim());
    return true;
  });
  const getPaymentFrequencyItem = (id: string) => paymentFrequencyItems.find((item) => item.id === id);

  useQuery(['vendor-item', toolId], () => getVendorItem(toolId), {
    enabled: Boolean(toolId),
    onSuccess: (vendor) => {
      if (vendor) {
        setValue('mainTool', vendor as VendorType);
      }
    },
    refetchOnWindowFocus: false,
  });

  useQuery(
    ['vendor-item-benchmark', selectedBenchmarkVendorId],
    () => {
      if (selectedBenchmarkVendorId) {
        return getVendorItem(selectedBenchmarkVendorId);
      }
    },
    {
      enabled: Boolean(isNewRequestMode && selectedBenchmarkVendorId),
      onSuccess: (vendor) => {
        if (vendor) {
          handleToolChange(vendor as VendorType, 'mainTool');
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const getSelectedToolsIds = () => {
    const selectedTools = [getValues().mainTool, ...getValues().alternativeTools];

    return selectedTools.filter((tool) => typeof tool === 'object' && tool?.id).map((tool) => (tool as VendorType)?.id);
  };

  // Triggered when going back: custom form --> default form
  useEffect(() => {
    if (initialData) {
      setToolOwner(initialData?.toolOwner);
      setToolOwnerName(initialData?.toolOwnerName);
      reset(initialData?.form);
    }
  }, [initialData, reset]);

  // Triggered when fetched api data used for pre-populating default form - only in edit mode
  useEffect(() => {
    if (isEditMode && fetchedData && !initialData) {
      const setFormValues = (data: RequestDetails) => {
        const collaborators = userOptions.filter(({ id }) =>
          data.contributors?.map(({ id }) => id).includes(Number(id))
        );
        setValue('collaborators', collaborators);
        setValue('cost', String((data?.licenseCents || 0) / 100));
        setValue('description', String(data.description));
        setValue('dueDate', new Date(data.dueDate || ''));
        setValue('numberOfLicenses', String(data?.licenseNumber || 0));
        if (data.licensePaymentFrequency) {
          setValue('paymentFrequency', getPaymentFrequencyItem(data.licensePaymentFrequency || '') || null);
        }
        if (data.childRequests) {
          const additionalToolFields: string[] = [];
          data.childRequests.forEach((childRequest, index) => {
            if (index === 0) {
              setValue('mainTool', childRequest.vendorName);
            } else {
              additionalToolFields.push(childRequest.vendorName);
            }
          });
          setAlternativeToolFields(additionalToolFields);
        }
      };

      const toolOwner =
        usersAndToolOwnersList
          ?.filter(({ name }) => name === fetchedData.assigneeFullName)
          .map(({ avatar, id, name }) => ({ avatar: avatar || '', id: id || '', name: name || '' }))[0] ||
        defaultToolOwner;

      setToolOwner(toolOwner);
      setToolOwnerName(toolOwner.name);
      setFormValues(fetchedData);
    }
  }, [isEditMode, fetchedData, initialData, usersAndToolOwnersList, setValue, setAlternativeToolFields, userOptions]);

  useEffect(() => {
    queryClient.invalidateQueries<Subscription[]>(['subscriptions-and-new-solutions']);
  }, [queryClient]);

  // Triggered if users list has been changed
  useEffect(() => {
    if (usersAndToolOwnersList) {
      const filteredUsers = usersAndToolOwnersList
        .filter((user: User) => user.role !== UserRoles.Viewer)
        .filter((user: User) => !user.email?.includes(CSM_USER_EMAIL) && user.id)
        .map((user: User) => ({
          avatar: user?.avatar,
          name: user.name,
          value: user.id || user.name?.replaceAll(' ', '-'),
        }));
      setToolOwners(filteredUsers as ToolOwnerFilter[]);
    }
  }, [usersAndToolOwnersList]);

  // Triggered if the selected tool (main or alternative) exists in the company (in tool stack or other initiatives)
  useEffect(() => {
    setIsToolBusy?.(isCreateInitiativeDisabled);
  }, [isCreateInitiativeDisabled, setIsToolBusy]);

  const onToolOwnerSelected = (item?: ToolOwner) => {
    if (item?.name && item.value) {
      setToolOwnerName(item.name);
      setToolOwner(item);
      setToolOwnerError(false);
      return;
    }

    if (toolOwner.name && !toolOwnerName) {
      setToolOwner(defaultToolOwner);
      setToolOwnerName('');
    } else if (toolOwner.name && toolOwner.name !== toolOwnerName) {
      setToolOwnerName(toolOwner.name);
    }
    setToolOwnerError(false);
  };

  const onChangeToolOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToolOwnerName(e.target.value);
  };

  const handleToolChange = (value: string | VendorType | null, field: string) => {
    setValue(field as any, value);

    const newExistingRequestsOrSubscriptions = new Map(existingRequestsOrSubscriptions);
    if (value && typeof value !== 'string') {
      const existingRequest = checkToolRequestStatus(value, requests);
      const existingSubscription = checkSubscriptionStatus(value, subscriptions);

      if (existingSubscription) {
        newExistingRequestsOrSubscriptions.set(String(field), existingSubscription);
      } else if (existingRequest) {
        newExistingRequestsOrSubscriptions.set(String(field), existingRequest);
      } else {
        newExistingRequestsOrSubscriptions.delete(String(field));
      }
    } else {
      const selectedTools = [getValues().mainTool, ...getValues().alternativeTools];
      const existingRequestsOrSubscriptionsValues = Array.from(newExistingRequestsOrSubscriptions.values());
      const existingRequestsOrSubscriptionsKeys = Array.from(newExistingRequestsOrSubscriptions.keys());

      existingRequestsOrSubscriptionsValues.forEach((requestOrSubscription, index) => {
        if (
          !selectedTools.find(
            (tool) => (typeof tool === 'string' ? tool : tool?.name) === requestOrSubscription?.vendorName
          )
        ) {
          newExistingRequestsOrSubscriptions.delete(existingRequestsOrSubscriptionsKeys[index]);
        }
      });
    }
    setExistingRequestsOrSubscriptions(newExistingRequestsOrSubscriptions);
  };

  const handleToolAlertOnView = (requestOrSubscription: RequestWorkflowItem | Subscription) => {
    let url = '';
    if ((requestOrSubscription as Subscription).id) {
      url = `${AppUrl.getToolDetailsUrl(requestOrSubscription as Partial<Subscription>)}`;
    } else if ((requestOrSubscription as RequestWorkflowItem).parentRequestId) {
      url = `/requests#requestDetails${(requestOrSubscription as RequestWorkflowItem).parentRequestId}`;
    } else if ((requestOrSubscription as RequestWorkflowItem).requestId) {
      url = `/requests#requestDetails${(requestOrSubscription as RequestWorkflowItem).requestId}`;
    }
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const renderToolAlerts = () => {
    return Array.from(existingRequestsOrSubscriptions.values()).map((requestOrSubscription) => (
      <Box mb={3} key={requestOrSubscription?.requestId || requestOrSubscription?.id}>
        <MultiRequestToolAlert
          requestOrSubscription={requestOrSubscription}
          onView={() => handleToolAlertOnView(requestOrSubscription)}
        />
      </Box>
    ));
  };

  const renderAssigneeField = () => (
    <Box display='flex' flexDirection='column' mb={3} data-testid='assignee'>
      <Typography variant='h3' mb={1.5}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.input_label'
        )}
        *
      </Typography>
      <TypeaheadComponent
        data={toolOwners}
        onItemSelected={onToolOwnerSelected}
        onBlurToolOwnerFilter={onToolOwnerSelected}
        value={toolOwnerName}
        fields={['name', 'email']}
        isToolOwnerFilter
        onTextChanged={onChangeToolOwner}
        isSubscriptionInDiscovery
        renderCustomInput={({
          autoFocus,
          disabled,
          inputTextRef,
          onChange,
          onClick,
          onFocus,
          onKeyDown,
          onKeyUp,
          value,
        }) => (
          <TextField
            id='assignee'
            inputRef={inputTextRef}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onFocus={(ev) => {
              onFocus?.(ev);
              setFocused(true);
            }}
            onBlur={() => setFocused(false)}
            onClick={onClick}
            disabled={disabled}
            value={value || ''}
            label={t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.assignee_section.multitool_placeholder_text'
            )}
            autoFocus={autoFocus}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
                  {!isFocused ? <Icon>arrow_drop_down</Icon> : <Icon>arrow_drop_up</Icon>}
                </InputAdornment>
              ),
            }}
            error={toolOwnerError}
            helperText={
              toolOwnerError
                ? t(
                    'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.assignee_section.error_message'
                  )
                : ''
            }
            fullWidth
            size='small'
          />
        )}
      />
    </Box>
  );

  const renderDescField = () => (
    <Box display='flex' flexDirection='column' mb={3} data-testid='description'>
      <Typography variant='h3' mb={1.5}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.input_label'
        )}
      </Typography>
      <Controller
        name='description'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <TextField
              id='description'
              data-testid='description-input'
              onChange={onChange}
              value={value}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.description_section.multitool_placeholder_text'
              )}
              multiline
              rows={3}
              error={!!errors.description}
              helperText={
                errors.description
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:description_section:error_message'
                    )
                  : ''
              }
              size='small'
            />
          );
        }}
      />
    </Box>
  );

  const renderMainToolField = () => (
    <Box display='flex' flexDirection='column' mb={3} data-testid='tool-name'>
      <Typography variant='h3' mb={1.5}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.input_label'
        )}
        *
      </Typography>
      <Controller
        control={control}
        name='mainTool'
        render={({ field: { onChange } }) => {
          return (
            <VendorSelectAsyncComponent
              shouldHandleInputChange={!selectedBenchmarkVendorId}
              value={getValues('mainTool') as string}
              getValue={(value: string | VendorType | null) => {
                onChange(value);
                handleToolChange(value, 'mainTool');
                if (selectedBenchmarkVendorId) {
                  dispatch(clearBenchmarkRequestVendorId());
                }
              }}
              loadDefaultVendors={false}
              noOptionsText={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.no_options_text'
              )}
              error={!!errors.mainTool}
              placeholder={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.placeholder_text'
              )}
              helperText={
                errors.mainTool
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.error_message'
                    )
                  : t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.helper_text'
                    )
              }
              excludeVendors={getSelectedToolsIds()}
              disabled={isEditMode || Boolean(toolId)}
              size='small'
            />
          );
        }}
      />
    </Box>
  );

  const renderVendorsEvaluationFields = () => (
    <Box mb={3} data-testid='vendor-evaluation'>
      <Typography variant='h3' mb={1.5}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.vendor_evaluation_new_section.label'
        )}
      </Typography>
      <Box>
        {Array(Math.ceil(alternativeTools.length / NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW))
          .fill('')
          .map((_, rowIndex) => (
            <Stack direction='row' spacing={2} key={`alternativeToolsRow-${rowIndex}`} mb={2}>
              {alternativeTools
                .slice(
                  rowIndex * NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW,
                  rowIndex * NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW + NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW
                )
                .map((_, cellIndex) => {
                  const toolIndex = rowIndex * NUMBER_OF_ALTERNATIVE_TOOLS_PER_ROW + cellIndex;
                  const name =
                    typeof `alternativeTools.${toolIndex}` === 'string'
                      ? `alternativeTools.${toolIndex}`
                      : `alternativeTools.${toolIndex}.name`;

                  return (
                    <Controller
                      key={`alternativeTool-${toolIndex}`}
                      control={control}
                      name={name as any}
                      render={() => {
                        return (
                          <Grid item xs={4}>
                            <VendorSelectAsyncComponent
                              value={getValues(`alternativeTools.${toolIndex}`) as string}
                              getValue={(value: string | VendorType | null) => handleToolChange(value, name)}
                              loadDefaultVendors={false}
                              noOptionsText={t(
                                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tool_name_section.no_options_text'
                              )}
                              placeholder={t(
                                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.tools_section.alternative_placeholder_text'
                              )}
                              excludeVendors={getSelectedToolsIds()}
                              size='small'
                            />
                          </Grid>
                        );
                      }}
                    />
                  );
                })}
            </Stack>
          ))}
      </Box>
      <Box>
        <Button
          variant='text'
          startIcon={<Icon>add</Icon>}
          disabled={!isEveryAlternativeToolFieldPopulated}
          onClick={() => addNewAlternativeToolFields(alternativeToolsDefaultValue)}>
          {t(
            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.vendor_evaluation_new_section.add_more_tools'
          )}
        </Button>
      </Box>
    </Box>
  );

  const renderLicenseFields = () => (
    <Box mb={3}>
      <Grid container>
        <Grid item xs={4} data-testid='value'>
          <Typography variant='h3' mb={1.5}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.value_section.multitool_input_label'
            )}
          </Typography>
          <Controller
            name='cost'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Box component='div' display='flex' flexDirection='column' mr={2}>
                  <NumberInput
                    name='cost'
                    label={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.value_section.placeholder_text'
                    )}
                    decimalScale={2}
                    decimalSeparator='.'
                    fixedDecimalScale
                    thousandSeparator
                    prefix={company?.currency ? CurrencyOptions[company.currency as keyof Currency] : DEFAULT_CURRENCY}
                    value={value || ''}
                    onValueChange={(value) => onChange(value.floatValue)}
                    onClear={() => onChange('')}
                    error={Boolean(errors.cost)}
                    helperText={
                      errors.cost
                        ? t(
                            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:value_section:error_message'
                          )
                        : ''
                    }
                    size='small'
                  />
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item xs={4} data-testid='number-of-licenses'>
          <Typography variant='h3' mb={1.5}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.number_of_licenses_section.input_label'
            )}
          </Typography>
          <Controller
            name='numberOfLicenses'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Box component='div' display='flex' flexDirection='column' mr={2}>
                  <NumberInput
                    name='numberOfLicenses'
                    thousandSeparator
                    value={value || ''}
                    onValueChange={(value) => onChange(value.floatValue)}
                    onClear={() => onChange('')}
                    label={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.number_of_licenses_section.placeholder_text'
                    )}
                    error={Boolean(errors.numberOfLicenses)}
                    helperText={
                      errors.numberOfLicenses
                        ? t(
                            'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section:number_of_licenses_section:error_message'
                          )
                        : ''
                    }
                    size='small'
                  />
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item xs={4} data-testid='payment-frequency'>
          <Typography variant='h3' mb={1.5}>
            {t(
              'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.payment_frequency_section.input_label'
            )}
          </Typography>
          <Controller
            name='paymentFrequency'
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Box component='div' display='flex' flexDirection='column'>
                  <Autocomplete
                    id='paymentFrequency'
                    data-testid='payment-frequency-select'
                    label={t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.payment_frequency_section.placeholder_text'
                    )}
                    value={value}
                    size='small'
                    options={paymentFrequencyItems}
                    onChange={(_, value) => onChange(value)}
                  />
                </Box>
              );
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );

  const renderCollaboratorsField = () => (
    <Box display='flex' flexDirection='column' width='100%' mb={3} data-testid='collaborators'>
      <Typography variant='h3' mb={1.5}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.input_label'
        )}
      </Typography>
      <Controller
        name='collaborators'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              id='collaborators'
              filterSelectedOptions
              value={value}
              options={userOptions}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.multitool_placeholder_text'
              )}
              helperText={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.collaborators_section.multitool_helper_text'
              )}
              multiple
              onChange={(_, newCollaborators, reason) => {
                if (reason === 'createOption' && Array.isArray(newCollaborators)) {
                  const autocompleteOptionCollaborators = newCollaborators.slice(0, -1) as AutocompleteOption[];
                  const lastItem = newCollaborators[newCollaborators.length - 1] as string;
                  const lastAutocompleteOptionCollaborator: AutocompleteOption = {
                    id: `${autocompleteOptionCollaborators.length}-${lastItem}`,
                    label: lastItem,
                  };
                  const collaborators: AutocompleteOption[] = [
                    ...autocompleteOptionCollaborators,
                    lastAutocompleteOptionCollaborator,
                  ].map(({ id, label }) => ({ id, label }));

                  return onChange(collaborators);
                }
                onChange(
                  newCollaborators
                    ? (newCollaborators as AutocompleteOption[]).map(({ id, label }) => ({ id, label }))
                    : null
                );
              }}
              size='small'
            />
          );
        }}
      />
    </Box>
  );

  const renderDateField = () => (
    <Box display='flex' flexDirection='column' mb={3} data-testid='due-date'>
      <Typography variant='h3' mb={1.5}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.input_label'
        )}
      </Typography>
      <Controller
        name='dueDate'
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <DatePicker
              value={value as any}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              minDate={new Date() as any}
              label={t(
                'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.due_date_section.placeholder_initiative_text'
              )}
              error={Boolean(errors.dueDate)}
              helperText={
                errors.dueDate
                  ? t(
                      'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section:right_section:workflow_request_section.due_date_section.error_message'
                    )
                  : ''
              }
              inputFormat='dd/MM/yyyy'
              InputProps={{ size: 'small' }}
            />
          );
        }}
      />
    </Box>
  );

  const renderDocumentsField = () => (
    <Box display='flex' flexDirection='column' mb={3} data-testid='documents'>
      <Typography variant='h3' mb={1.5}>
        {t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.documents_section.input_label'
        )}
      </Typography>
      <DropzoneComponent
        dropzoneRef={dropzoneComponentRef}
        helperText={t(
          'subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.workflow_request_section.documents_section.helper_default_form_text'
        )}
        initFiles={initFiles}
      />
    </Box>
  );

  return (
    <Box component='form'>
      {renderAssigneeField()}
      {renderDescField()}
      {!isEditMode && renderMainToolField()}
      {!isEditMode && renderVendorsEvaluationFields()}
      {!isEditMode && renderToolAlerts()}
      {renderLicenseFields()}
      {renderCollaboratorsField()}
      {renderDateField()}
      {!isEditMode && renderDocumentsField()}
    </Box>
  );
};
