import { type LinkProps, Tooltip } from '@mui/material';

import { ActionableLink } from './actionable-link.component';

export interface NavigateToUrlLinkProps extends LinkProps {
  tooltip?: string;
  href: string;
  icon?: boolean | string;
}

export const NavigateToUrlLink = (props: NavigateToUrlLinkProps) => {
  const { children, href = '', icon = true, tooltip = '', ...rest } = props;

  const renderLink = () => (
    <span>
      <ActionableLink
        {...rest}
        href={href || '#'}
        icon={(typeof icon === 'string' && icon !== '') || icon === false ? icon : 'open_in_new'}>
        {children}
      </ActionableLink>
    </span>
  );

  return tooltip ? <Tooltip title={tooltip}>{renderLink()}</Tooltip> : renderLink();
};

export default NavigateToUrlLink;
