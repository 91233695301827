import { AxiosResponse } from 'axios';
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { CreateUserData, UpdateUserType } from 'shared/helpers/user-drawer.helper';

import { CommentMentions, FormattedGoogleDirectoryUser, GoogleDirectoryUser, User } from '../models';
import { apiService, apiUrl } from '../services';

export const fetchUsers = (): Promise<User[] | unknown> => {
  return apiService.get(apiUrl.Users).then((response: AxiosResponse) => {
    const { data } = response;

    data.forEach((user: User) => {
      user.lastSignInAtFormatted = user.lastSignInAt ? new Date(user.lastSignInAt)?.toDateString?.() : '';
    });
    return data;
  });
};

export const createUser = async (createUserData: Partial<CreateUserData>): Promise<User> => {
  const { data } = await apiService.post(apiUrl.Users, createUserData);
  return data as User;
};

export const updateUser = async (updateUserData: Partial<UpdateUserType>): Promise<User> => {
  const { userData, userId } = updateUserData;
  const { data } = await apiService.patch(`${apiUrl.Users}/${userId}`, userData);
  return data as User;
};

export const formatGoogleDirectoryUsers = (users: GoogleDirectoryUser[]): FormattedGoogleDirectoryUser[] => {
  if (!users.length) {
    return [];
  }

  try {
    const filteredUsers = users.filter((user) => user.emailAddresses?.[0]?.value);

    const formattedUsers = filteredUsers.map(({ emailAddresses, names, photos, resourceName }) => {
      return {
        avatarUrl: photos?.[0].url || '',
        email: emailAddresses[0].value,
        familyName: names?.[0].familyName || emailAddresses[0].value,
        givenName: names?.[0].givenName || emailAddresses[0].value,
        id: resourceName.split('/')[1],
        name: names?.[0].displayName || emailAddresses[0].value,
      };
    });

    return sortFormattedUsers(formattedUsers);
  } catch (error) {
    return [];
  }
};

const sortFormattedUsers = (users: FormattedGoogleDirectoryUser[]) => {
  return users.sort((a, b) => {
    if (a.givenName === b.givenName) {
      return a.familyName.localeCompare(b.familyName);
    }
    return a.givenName.localeCompare(b.givenName);
  });
};

export const getInvitedUsersEmail = (appUsers: User[]) => {
  return appUsers.map((appUser) => appUser.email).filter((user) => !!user);
};

export const deleteUser = async (userId: string): Promise<unknown> => apiService.delete(apiUrl.Users, userId);

export const reInviteUser = async (userId: string): Promise<unknown> => apiService.post(apiUrl.ReInviteUser(userId));

export const getMyCommentMentions = async (): Promise<CommentMentions[]> => {
  const { data } = await apiService.get(apiUrl.getLoggedInUserCommentsMentions());
  return data;
};

const getDateValues = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return new Date(year, month, day, hours, minutes, seconds);
};

export const getFormattedMentionsTime = (date: string): string | null => {
  const newDate = new Date();
  const oldDate = new Date(date);

  const daysDifference = differenceInDays(getDateValues(newDate), getDateValues(oldDate));
  const hoursDifference = differenceInHours(getDateValues(newDate), getDateValues(oldDate));
  const minutesDifference = differenceInMinutes(getDateValues(newDate), getDateValues(oldDate));
  const secondsDifference = differenceInSeconds(getDateValues(newDate), getDateValues(oldDate));

  if (secondsDifference > 0 && secondsDifference < 60) {
    return '<1m ago';
  }

  if (minutesDifference > 0 && minutesDifference < 60) {
    return `${minutesDifference}m ago`;
  }
  if (minutesDifference === 60) {
    return '1h ago';
  }

  if (hoursDifference > 0 && hoursDifference < 24) {
    return `${hoursDifference}h ago`;
  }

  if (hoursDifference === 24) {
    return '1d ago';
  }

  if (daysDifference > 0) {
    return `${daysDifference}d ago`;
  }

  return null;
};

export const getLocaleCode = (locale: string): string => {
  const formattedLocale = locale.split('-');

  if (formattedLocale.length === 2) {
    return formattedLocale.join('');
  }

  return locale;
};
