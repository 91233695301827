import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { AlertColor, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Alert } from 'asteroids';
import { DocumentType } from 'components/email-bridge/email-bridge.component.props';
import { EmailBridgeModalComponent } from 'components/email-bridge-modal';
import { DropzoneComponent, EmailBridgeComponent } from 'components/index';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { SpendTabSkeleton } from 'components/skeletons';
import { useFetchData } from 'hooks/index';
import { CodatIntegrationDetailsRes } from 'libs/models/codat-service.model';
import Plotly from 'plotly.js-basic-dist-min';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Currency,
  CurrencyOptions,
  DocumentCategory,
  ExtendedBlobType,
  googleTagManagerEvents,
} from 'shared/common.definitions';
import { fireGTagManagerEvent, getCompanyCodatIntegrationDetails } from 'shared/logic/company.logic';
import {
  generateSpendAndInvoicesStackedData,
  getSortedGroupedSpendAndInvoicesByMonth,
  getSubscriptionSpendAndInvoices,
  uploadSubscriptionDocument,
} from 'shared/logic/subscription-item.logic';
import { Company, SastrifyStore, SubscriptionDocument, SubscriptionSpendAndInvoices, User } from 'shared/models';
import { useAppSelector } from 'shared/store/hooks';
import {
  selectAllUploads,
  setUploadedFiles,
  SubscriptionInvoiceUpload,
  SubscriptionInvoiceUploadStatus,
} from 'shared/store/views/subscription/subscription-invoice-upload.slice';

import { SubscriptionSpendAndInvoicesTableFeature } from '../subscription-spend-and-invoices-table/subscription-spend-and-invoices-table.feature';
import { EmptySpendFeature } from './empty-spend.feature';
import { SubscriptionSpendAndInvoicesProps } from './subscription-spend-and-invoices.feature.props';

const PREFIX = 'SubscriptionSpendAndInvoicesFeature';

const classes = {
  alertLoader: `${PREFIX}-alertLoader`,
  spendAndInvoicesPlot: `${PREFIX}-spendAndInvoicesPlot`,
  uploadWrapper: `${PREFIX}-uploadWrapper`,
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled('section')({
  [`& .${classes.alertLoader}`]: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  [`& .${classes.spendAndInvoicesPlot}`]: {
    height: '100%',
    marginBottom: '1rem',
    width: '100%',
  },
  [`& .${classes.uploadWrapper}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    width: '100%',
  },
  [`&.${classes.wrapper}`]: {
    '@media screen and (min-width: 1024px)': {
      maxWidth: '816px',
    },
    '@media screen and (min-width: 1280px)': {
      maxWidth: '816px',
    },
    '@media screen and (min-width: 1536px)': {
      maxWidth: '898px',
    },
  },
});

export const SubscriptionSpendAndInvoicesFeature: FC<SubscriptionSpendAndInvoicesProps> = ({
  closeModal,
  showModal,
  showNotification,
  subscription,
  subscriptionId,
  subscriptionSpendAndInvoiceCache,
  updateSubscriptionTodosCache,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const spendAndInvoicesTranslationPath = 'subscription_detail_view:tabs_component_section.subscription_spend_tab';
  const pathToDocumentTabTranslation = 'subscription_detail_view:tabs_component_section.subscription_document_tab';
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state: SastrifyStore) => state.authentication.user) as User;
  const uploadedInvoices = useAppSelector((state) => selectAllUploads(state.views.subscriptions.invoiceUploads));

  const alertRef = useRef<HTMLDivElement>(null);
  const scrolledIntoView = useRef(false);

  const alertText = useMemo(() => {
    const { completed, delayed, failed, processing } = uploadedInvoices.reduce(
      (acc, curr) => {
        if (acc[curr.status]) {
          acc[curr.status] += 1;
        } else {
          acc[curr.status] = 1;
        }
        return acc;
      },
      {
        completed: 0,
        delayed: 0,
        failed: 0,
        processing: 0,
        uploaded: 0,
      }
    );

    if (delayed && !processing)
      return {
        description: t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.invoice_upload_alert_text.taking_too_long_stage.description'
        ),
        showLoader: true,
        title: t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.invoice_upload_alert_text.taking_too_long_stage.title'
        ),
      };
    if (failed && failed + completed === uploadedInvoices.length)
      return {
        description: t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.invoice_upload_alert_text.failed_stage.description'
        ),
        severity: 'error',
        title: t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.invoice_upload_alert_text.failed_stage.title',
          { total: failed }
        ),
      };
    if (processing)
      return {
        showLoader: true,
        title: t(
          'subscription_detail_view:tabs_component_section.subscription_spend_tab.invoice_upload_alert_text.processing_stage.title'
        ),
      };
    if (failed + completed === uploadedInvoices.length) return undefined;
  }, [uploadedInvoices, t]);

  const { data: codatConnectionDetails } = useFetchData<CodatIntegrationDetailsRes[]>(
    'codat-integration-details',
    () => getCompanyCodatIntegrationDetails(String(user?.companyId)),
    {
      enabled: Boolean(user?.companyId),
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (alertText && !scrolledIntoView.current) {
      alertRef.current?.scrollIntoView({ behavior: 'smooth' });
      scrolledIntoView.current = true;
    }
  }, [alertText]);

  useEffect(() => {
    if (uploadedInvoices.length) {
      scrolledIntoView.current = false;
    }
  }, [uploadedInvoices.length]);

  const Plot = createPlotlyComponent(Plotly);

  const { mutateAsync } = useMutation(uploadSubscriptionDocument);

  const company = queryClient.getQueryData<Company>('company');

  const [isUploadingInvoice, setIsUploadingInvoice] = useState<boolean>(false);

  const [subscriptionSpendAndInvoices, setSubscriptionSpendAndInvoices] = useState<SubscriptionSpendAndInvoices[]>([]);

  const { isFetching } = useFetchData<SubscriptionSpendAndInvoices[]>(
    'subscription-spend-and-invoices',
    () => getSubscriptionSpendAndInvoices(subscriptionId as string),
    {
      cacheTime: 0,
      initialData: subscriptionSpendAndInvoiceCache,
      onError: (error: unknown) => {
        showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      },
      onSuccess: (data) => setSubscriptionSpendAndInvoices(data),
      refetchOnWindowFocus: false,
    }
  );

  const legends = [
    t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_chart.chart_legends_section.estimated_spend_text`),
    t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_chart.chart_legends_section.accounting_import_text`),
    t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_chart.chart_legends_section.automated_import`),
    t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_chart.chart_legends_section.uploaded_invoice_text`),
    t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_chart.chart_legends_section.spreadsheet_upload_text`),
  ];
  const spendAndInvoicesChartTitle = t(`${spendAndInvoicesTranslationPath}.spend_and_invoices_chart.chart_title_text`);

  const renderSubscriptionSpendAndInvoicesChart = () => {
    const validSpendAndInvoices = subscriptionSpendAndInvoices.filter(
      ({ amountCents }) => amountCents !== 'processing'
    );
    const sortedData = getSortedGroupedSpendAndInvoicesByMonth(legends, validSpendAndInvoices);

    type SpendGraphDataType = {
      marker: { color: string };
      name: string;
      type: string;
      x: string[];
      y: number[];
    };

    const sortedDataKeys = Object.keys(sortedData);

    if (sortedDataKeys.length === 0) return null;
    const spendGraphData = generateSpendAndInvoicesStackedData(legends, sortedData);
    const last12Months = (spendGraphData[0] as SpendGraphDataType).x;
    const hasSpendDataWithin12Months = sortedDataKeys.some((key) => last12Months.includes(key));

    if (!hasSpendDataWithin12Months) return null;

    return (
      <Plot
        data={spendGraphData}
        layout={{
          autosize: true,
          barmode: 'group',
          legend: {
            orientation: 'h',
            x: -0.01,
            y: -0.19,
          },
          title: spendAndInvoicesChartTitle,
          transition: {
            duration: 1000,
            easing: 'cubic-in-out',
          },
          xaxis: {
            color: '#A2A4A1',
          },
          yaxis: {
            color: '#A2A4A1',
            hoverformat: ',.2f',
            tickformat: ',',
            tickprefix: CurrencyOptions[company?.currency as keyof Currency],
          },
        }}
        config={{
          autosizable: true,
          displaylogo: false,
          editable: false,
          modeBarButtonsToRemove: [
            'pan2d',
            'select2d',
            'lasso2d',
            'resetScale2d',
            'zoom2d',
            'autoScale2d',
            'toggleSpikelines',
          ],
          responsive: true,
        }}
        useResizeHandler
        className={classes.spendAndInvoicesPlot}
      />
    );
  };

  const onUploadDocument = async (files: Array<ExtendedBlobType>) => {
    try {
      setIsUploadingInvoice(true);
      const message = t(`${spendAndInvoicesTranslationPath}.notification_messages.upload_invoices.success_message`);

      let response = [] as SubscriptionDocument[];
      const filesPerChunk = Number.isNaN(Number(process.env.REACT_APP_UPLOAD_FILES_PER_CHUNK))
        ? 20
        : Number(process.env.REACT_APP_UPLOAD_FILES_PER_CHUNK);
      const chunksArray = new Array(Math.ceil(files.length / filesPerChunk)).fill(0);

      // eslint-disable-next-line no-restricted-syntax
      for await (const [chunkIndex] of Object.entries(chunksArray)) {
        const uploads: Array<Promise<SubscriptionDocument>> = [];
        const filesChunk = files.slice(Number(chunkIndex) * filesPerChunk, (Number(chunkIndex) + 1) * filesPerChunk);

        filesChunk.forEach((file) => {
          const value = {
            category: DocumentCategory.invoice,
            companyId: String(company?.id),
            file,
            subscriptionId,
          };
          uploads.push(mutateAsync(value));
        });
        response = [...response, ...(await Promise.all(uploads))];
      }

      const uploadedFiles: SubscriptionInvoiceUpload[] = response.map((document) => ({
        id: document.id as string,
        status: SubscriptionInvoiceUploadStatus.uploaded,
      }));
      dispatch(setUploadedFiles(uploadedFiles));

      if (((location.state as { type?: string })?.type as string) === 'upload-latest-invoice') {
        updateSubscriptionTodosCache?.(String((location.state as { todoId?: string })?.todoId));
      }

      queryClient.invalidateQueries('subscription-spend-and-invoices');
      queryClient.invalidateQueries(['subscription', subscriptionId]);
      queryClient.invalidateQueries('auditLogs');
      showNotification?.(message, NotificationAlertType.Success);
    } catch {
      const message = t(`${spendAndInvoicesTranslationPath}.notification_messages.upload_invoices.error_message`);
      showNotification?.(message, NotificationAlertType.Error);
    } finally {
      setIsUploadingInvoice(false);
    }
  };

  const renderUploadInvoiceSection = () => {
    return (
      <Box mb='1.5rem'>
        <DropzoneComponent
          isPaperClipIcon
          hasCustomMessage
          isUploading={isUploadingInvoice}
          message={
            <span className='dropzone-message'>
              <Trans
                i18nKey='subscription_detail_view:tabs_component_section.subscription_document_tab.document_upload_drawer_section.right_section.document_category_section.drag_and_drop_text'
                values={{ clickHere: 'click here' }}
                components={[<span />]}
              />
            </span>
          }
          name='file'
          onDrop={(value) => {
            onUploadDocument(value as Array<ExtendedBlobType>);
          }}
          onDropRejected={() => {
            const message = t(`${pathToDocumentTabTranslation}.upload_file_type_error_message`);
            showNotification?.(message, NotificationAlertType.Error);
          }}
          multiple
        />
      </Box>
    );
  };

  const handleLinkClick = () => {
    showModal?.({
      children: (
        <EmailBridgeModalComponent
          subscriptionName={subscription?.name || subscription?.vendorName}
          subscriptionInvoiceEmail={subscription?.subscriptionInvoiceEmail}
          subscriptionsEmail={subscription?.subscriptionEmail}
          vendorLogoUrl={subscription?.vendorLogoUrl}
          companyEmail={company?.uploadsEmail}
          closeModal={closeModal}
        />
      ),
      hasCustomFooter: true,
      hasCustomWidth: true,
      showHeader: false,
    });
    fireGTagManagerEvent(window, String(user.email), googleTagManagerEvents.ForwardYourInvoicesLinkClicked);
  };

  return isFetching ? (
    <SpendTabSkeleton />
  ) : (
    <Root className={classes.wrapper}>
      <EmailBridgeComponent type={DocumentType.invoices} handleClick={handleLinkClick} />
      {renderUploadInvoiceSection()}
      {subscriptionSpendAndInvoices?.length > 0 ? (
        <>
          {renderSubscriptionSpendAndInvoicesChart()}
          <div ref={alertRef} />
          {!!alertText && !!uploadedInvoices.length && (
            <Box mb={3}>
              <Alert
                description={alertText?.description || ''}
                title={alertText?.title}
                severity={(alertText?.severity || 'info') as AlertColor}
                {...(alertText?.showLoader && {
                  action: (
                    <Box className={classes.alertLoader}>
                      <CircularProgress size={32} color='info' />
                    </Box>
                  ),
                })}
              />
            </Box>
          )}
          <SubscriptionSpendAndInvoicesTableFeature
            spendCategoryTypes={legends}
            showNotification={showNotification}
            subscriptionId={subscriptionId}
            showModal={showModal}
            closeModal={closeModal}
            codatConnectionDetails={codatConnectionDetails}
          />
        </>
      ) : (
        <EmptySpendFeature user={user} />
      )}
    </Root>
  );
};
