import * as yup from 'yup';

import { FormValues } from './add-new-tool-form.component.props';

export const defaultFormValues: FormValues = {
  cost: '',
  notes: '',
  tool: null,
};

export const formValidationSchema = yup.object().shape({
  notes: yup.string().max(500, 'Notes must be at most 500 characters.'),
  tool: yup.mixed().required(),
});
