import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FileUploadWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

export const FileInput = styled('input')(() => ({
  display: 'none',
}));
