import { DEFAULT_UNKNOWN_ERROR_MESSAGE } from '@constants/common';
import { NotificationAlertType } from 'components/notification-alert/notification-alert.component';
import { ModalAndNotificationsContext } from 'context/modal-notification.context';
import { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { TodoStates } from 'shared/common.definitions';

import { updateSubscriptionTodo } from '../../shared/logic/subscription-item.logic';
import { Todo, UpdateSubscriptionTodoState } from '../../shared/models';

export const useMarkTaskAsDone = () => {
  const queryClient = useQueryClient();
  const { onShowNotification: showNotification } = useContext(ModalAndNotificationsContext);

  const { mutate: mutateSubscriptionTodo } = useMutation(updateSubscriptionTodo, {
    onError: (error, _, context) => {
      const clonedContext = context as { previousTodos: Todo[] };
      showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
      queryClient.setQueryData('subscriptionTodos', clonedContext.previousTodos);
    },
  });

  const markTodoAsDone = useCallback(
    (subscriptionId: string, todoId?: string) => {
      if (todoId) {
        const data: UpdateSubscriptionTodoState = {
          state: TodoStates.DONE,
          subscriptionId,
          todoId,
        };

        mutateSubscriptionTodo(data, {
          onError: (error) => {
            showNotification?.(`${DEFAULT_UNKNOWN_ERROR_MESSAGE} ${error}`, NotificationAlertType.Error);
          },
          onSuccess: () => {
            showNotification?.('Todo completed successfully!', NotificationAlertType.Success);
            queryClient.invalidateQueries('subscriptionTodos');
            queryClient.invalidateQueries('subscriptions-todos');
            queryClient.invalidateQueries(['subscription', subscriptionId]);
          },
        });
      }
    },
    [mutateSubscriptionTodo, queryClient, showNotification]
  );

  return { markTodoAsDone };
};
