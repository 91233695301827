import React, { memo, useEffect, useRef } from 'react';
import { onEnterOrSpaceKeyUp } from 'shared/helpers/keyboard-events-handlers';

import { NotificationAlertComponentProps } from './notification-alert.component.props';

export enum NotificationAlertType {
  Default = 'is-primary',
  Error = 'is-danger',
  Info = 'is-info',
  Link = 'is-link',
  Success = 'is-success',
  Warning = 'is-warning',
}

export const NotificationAlertComponent: React.FC<NotificationAlertComponentProps> = memo(
  (props: NotificationAlertComponentProps) => {
    const { alert, onClick, onTimeElapsed } = props;
    const timeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
      if (alert.interval != null && alert.interval > 0) {
        timeout.current = setTimeout(() => {
          if (onTimeElapsed != null) onTimeElapsed(alert);
        }, alert.interval);
      }

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
      };
    }, [alert, onTimeElapsed]);

    return (
      <div
        data-testid='notification-alert'
        className={`sastrify-notification-alert article message ${alert.type}`}
        tabIndex={0}
        role='button'
        onKeyUp={(e) => onEnterOrSpaceKeyUp(e, () => onClick?.(alert))}
        onClick={() => onClick?.(alert)}>
        <div className='alert-message message-body'>{alert.text}</div>
      </div>
    );
  }
);
