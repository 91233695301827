import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { updateSubscriptionTags } from 'shared/logic/subscription-item.logic';
import { Company, Subscription } from 'shared/models';

type OnDeleteTag = (index: number) => void;
type OnAddTag = (tag: Tag) => void;
interface TagEditorComponentProps {
  isFilterTag?: boolean;
  subscription?: Subscription;
  updateSubscription?: (
    updatedSubscription: Subscription,
    message: string,
    todoId?: string | undefined
  ) => Promise<void>;
  placeholderText?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  allowNew?: boolean;
  isCustom?: boolean;
  onDelete?: OnDeleteTag;
  onAddition?: OnAddTag;
  tags?: Tag[];
  suggestions?: Tag[];
}

const TagEditorComponent: React.FC<TagEditorComponentProps> = (props) => {
  const {
    isCustom,
    isFilterTag,
    onAddition,
    onDelete,
    placeholderText: customPlaceholderText,
    subscription,
    suggestions,
    tags: customTags,
    updateSubscription,
  } = props;
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const tags = React.useMemo(
    (): Tag[] => subscription?.tags?.map((tag: string) => ({ id: tag, name: tag })) || [],
    [subscription?.tags]
  );

  const company: Company | undefined = queryClient.getQueryData('company');

  const PLACEHOLDER_NO_TAGS = t('common:tag_editor_component.add_new_tag_placeholder.placeholder_text_no_tag');
  const PLACEHOLDER_WITH_TAGS = t('common:tag_editor_component.add_new_tag_placeholder.placeholder_text_with_tag');
  const NO_MATCHING_TAGS = t('common:tag_editor_component.no_matching_tag_text');
  const PLACEHOLDER_TAGS_FILTER = t('common:tag_editor_component.filter_tag_placeholder_text');

  const [placeholderText, setPlaceholderText] = useState<string>(PLACEHOLDER_NO_TAGS);

  useEffect(() => {
    if (!isFilterTag) {
      if (tags?.length === 0) {
        setPlaceholderText(PLACEHOLDER_NO_TAGS);
      } else {
        setPlaceholderText(PLACEHOLDER_WITH_TAGS);
      }
    } else {
      setPlaceholderText(PLACEHOLDER_TAGS_FILTER);
    }
  }, [tags, isFilterTag, PLACEHOLDER_NO_TAGS, PLACEHOLDER_WITH_TAGS, PLACEHOLDER_TAGS_FILTER]);

  const companyTags = React.useMemo((): Tag[] | undefined => {
    const subscriptionTags = subscription?.tags as string[];
    const filteredTags = company?.companyTags?.filter((tag) => !subscriptionTags?.includes(tag));
    const formattedTags = filteredTags?.map((name, id) => ({
      id,
      name,
    }));

    return formattedTags;
  }, [company?.companyTags, subscription?.tags]);

  const updateSubscriptionTagsOnAddOrDelete = (clonedTags: Tag[], message: string) => {
    updateSubscriptionTags(subscription as Subscription, clonedTags).then((updatedSubscription: Subscription) => {
      updateSubscription?.(updatedSubscription, message);
      queryClient.invalidateQueries('auditLogs');
      queryClient.invalidateQueries(['subscription', subscription?.id]);
    });
  };

  const onAddSubscriptionTag = (tag: Tag): void => {
    if (subscription && !tags.filter(({ id }: Tag) => id === tag.id).length) {
      const clonedTags = [...tags, tag];

      updateSubscriptionTagsOnAddOrDelete(clonedTags, 'Tag added successfully!');
    }
  };

  const onDeleteSubscriptionTag = (tagIndex: number): void => {
    if (subscription && tags[tagIndex]) {
      const clonedTags = tags.filter((_tag: Tag, index: number) => index !== tagIndex);
      updateSubscriptionTagsOnAddOrDelete(clonedTags, 'Tag removed successfully!');
    }
  };

  const onDeleteTag = isCustom ? (onDelete as OnDeleteTag) : onDeleteSubscriptionTag;
  const onAdditionTag = isCustom ? (onAddition as OnAddTag) : onAddSubscriptionTag;

  const tagSuggestions = isCustom ? suggestions : companyTags;

  const mainTags = isCustom ? customTags : tags;

  return (
    <div className='sastrify-tageditor' data-testid='tags-input'>
      <ReactTags
        tags={mainTags}
        allowBackspace={false}
        noSuggestionsText={NO_MATCHING_TAGS}
        placeholderText={customPlaceholderText || placeholderText}
        suggestions={tagSuggestions}
        allowNew
        onDelete={onDeleteTag}
        onAddition={onAdditionTag}
      />
    </div>
  );
};

TagEditorComponent.defaultProps = {
  isFilterTag: false,
};

export { TagEditorComponent };
