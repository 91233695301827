import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ReactMarkdown from 'react-markdown';

/* eslint-disable no-underscore-dangle */
interface ITableCell {
  _uid: string;
  value: string;
  component: string;
}

interface ITableRow {
  _uid: string;
  body: ITableCell[];
  component: string;
}

interface ITableHeaderCell {
  _uid: string;
  value: string;
  component: string;
}

interface ITableData {
  tbody: ITableRow[];
  thead: ITableHeaderCell[];
  fieldtype: string;
}

interface TableRendererProps {
  // we can never trust the data we get from external service
  table?: ITableData;
}

const TableRenderer: React.FC<TableRendererProps> = ({ table }) => {
  if (!table) {
    return null;
  }

  const { tbody, thead } = table;

  return (
    <Table sx={{ mb: 1, minWidth: 500 }} aria-label='simple table' data-testid='table-renderer-table'>
      <TableHead>
        <TableRow>
          {thead.map((headCell) => (
            <TableCell key={headCell._uid}>{headCell.value}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tbody.map((row) => (
          <TableRow key={row._uid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {row.body.map((cell) => (
              <TableCell key={cell._uid} data-testid={cell._uid}>
                <ReactMarkdown
                  allowedElements={['strong', 'a', 'b', 'p']}
                  components={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    a({ node, ...props }) {
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      return <a {...props} target='_blank' rel='noreferrer noopener' />;
                    },
                  }}>
                  {cell.value}
                </ReactMarkdown>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableRenderer;
