import { useScrollToTop } from 'hooks/useScrollToTop';
import { createContext, FC, RefObject } from 'react';

export const AppContainerContext = createContext<RefObject<HTMLDivElement> | undefined>(undefined);

interface AppContainerProps {
  children: React.ReactNode;
}

export const AppContainer: FC<AppContainerProps> = ({ children }) => {
  const appContainerRef = useScrollToTop();

  return (
    <AppContainerContext.Provider value={appContainerRef}>
      <div ref={appContainerRef} className='app-container' data-testid='app-container'>
        {children}
      </div>
    </AppContainerContext.Provider>
  );
};
